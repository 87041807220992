import { AutoClosingDropdown, CancelIcon } from "@wit/mpesa-ui-components";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ILanguage } from "../../../../shared/models/language.model";
import { Column, Row } from "../../../../shared/shared.styled";
import { ILanguageTranslations, LanguageTranslationStatusEnum } from "../language-translations.model";
import { renderVersionRow } from "../language-translations.utils";

interface ICheckLanguageTranslationsLastVersionProps {
  hideModal: () => void;
  lastVersion: ILanguageTranslations;
  availableLanguages: ILanguage[];
}

/**
 * Check last version of language translations
 * @param {ILanguageTranslations} lastVersion
 * @param {() => void} hideModal
 * @param {ILanguage[]} availableLanguages
 * @returns {JSX.Element}
 * @constructor
 */
const CheckLanguageTranslationsLastVersion = ({
  lastVersion,
  hideModal,
  availableLanguages,
}: ICheckLanguageTranslationsLastVersionProps) => {
  const [t] = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = React.useState<SharedDropdownOption>({
    key: availableLanguages[0].code,
    label: availableLanguages[0].name,
  });

  /**
   * Returns the available languages list for the dropdown selector
   * @returns {SharedDropdownOption[]}
   */
  const getAvailableLanguageOptions = (): SharedDropdownOption[] => {
    return availableLanguages.map(l => {
      return { key: l.code, label: l.name };
    });
  };

  return (
    <ModalContainer>
      <ModalTitleContainer>
        <ModalTitle>{t("pages.languageTranslations.lastVersionPublished")}</ModalTitle>
        <Column style={{ marginLeft: "auto" }}>
          <CloseIconContainer onClick={hideModal} id="close-last-version-modal">
            <CancelIcon color={"grey"} />
          </CloseIconContainer>
          <LanguageDropdown>
            <DropdownWrapper id={"last-version-language-selector"}>
              <AutoClosingDropdown
                hasValue={false}
                label={selectedLanguage.label}
                options={getAvailableLanguageOptions()}
                selectOption={opt => {
                  setSelectedLanguage(opt);
                }}
              />
            </DropdownWrapper>
          </LanguageDropdown>
        </Column>
      </ModalTitleContainer>
      <DocumentContainer>
        <Row style={{ alignItems: "normal" }}>
          <Panel>
            {lastVersion.translations ? (
              <>
                {lastVersion.translations.map(languageTranslation => {
                  return renderVersionRow(languageTranslation, t, selectedLanguage.key);
                })}
              </>
            ) : null}
          </Panel>
        </Row>
      </DocumentContainer>
    </ModalContainer>
  );
};

export default CheckLanguageTranslationsLastVersion;

const CloseIconContainer = styled("div")`
  stroke: ${props => props.theme.palette.midGrey};
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  svg {
    width: 24px;
  }
`;

const ModalContainer = styled("div")`
  border-radius: 6px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
`;

const ModalTitleContainer = styled("div")`
  width: 100%;
  padding: 24px 24px 0px 24px;
  display: flex;
`;

const ModalTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 13px;
`;

const DocumentContainer = styled("div")`
  font-family: Vodafone Rg;
  max-height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
`;

const Panel = styled("div")<{ background?: boolean }>`
  font-family: Vodafone Rg;
  overflow: auto;
  display: flex;
  width: 100%;
  padding: 24px;
  background-color: ${props => (props.background ? props.theme.palette.lightGrey : props.theme.palette.white)};
  flex-direction: column;
  max-height: 300px;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  b,
  strong {
    margin: 0;
  }
`;

const LanguageDropdown = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-top: 8px;
`;

const DropdownWrapper = styled.div`
  > button {
    height: auto;
    border: none;
    color: ${props => props.theme.palette.turquoiseBlue};
    padding: 0;
    justify-content: normal;
    :hover {
      border: none;
    }

    > svg {
      stroke: ${props => props.theme.palette.turquoiseBlue};
    }

    > div {
      right: 0px;
      left: auto;
    }

    span {
      max-width: unset !important;
    }
  }
`;

export const VersionLabel = styled("span")`
  padding-top: 5px;
`;

export const StatusText = styled("span")<{ status: LanguageTranslationStatusEnum }>`
  ${props => {
    let color = props.theme.palette.digitalGreen;
    switch (props.status) {
      case LanguageTranslationStatusEnum.LIVE:
        color = props.theme.palette.digitalGreen;
        break;
      case LanguageTranslationStatusEnum.EDIT:
        color = props.theme.palette.freshOrange;
        break;
      case LanguageTranslationStatusEnum.DELETE:
        color = props.theme.palette.vodafoneRed;
        break;
    }
    return `color: ${color};display: flex;justify-content: flex-end;`;
  }}
`;
