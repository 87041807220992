import { produce } from "immer";
import { IPayloadAction } from "../../../shared/shared.interfaces";
import { IClientDocSubmission, IClientDocSubmissionEntry } from "./doc-validation.interface";
import { getDocSubmissionStatus } from "../ekyc.shared.utils";

export const DocValidationActions = {
  types: {
    FETCH_DOCS_SUCCESS: "FETCH_DOCS_SUCCESS",
  },
  creators: {
    fetchDocsSuccessAction: (clientSubmissionDocs: IClientDocSubmissionEntry[], count: number) => ({
      type: DocValidationActions.types.FETCH_DOCS_SUCCESS,
      payload: {
        clientSubmissionDocs,
        count,
      },
    }),
  },
};

export interface IDocValidationReducerInterface {
  clientSubmissionDocs: IClientDocSubmission[];
  totalSubmissions: number;
}

const docsInitialState: IDocValidationReducerInterface = {
  clientSubmissionDocs: [],
  totalSubmissions: 0,
};

export const docValidationReducer = produce((draft: IDocValidationReducerInterface, action: IPayloadAction<any>) => {
  switch (action.type) {
    case DocValidationActions.types.FETCH_DOCS_SUCCESS:
      draft.clientSubmissionDocs = action.payload.clientSubmissionDocs.map(function(entry: IClientDocSubmissionEntry) {
        return {
          remoteId: entry.submissionRequestId,
          requestId: entry.requestId,
          fullName: `${entry.firstName} ${entry.lastName}`,
          phoneNumber: entry.msisdn,
          idCardNumber: entry.documentNumber,
          requestTime: entry.creationDate,
          agentShortcode: entry?.agent?.agentShortCode || "-",
          statusTime: entry.updateStatusDate,
          status: getDocSubmissionStatus(entry.status),
        };
      });
      draft.totalSubmissions = action.payload.count;
      return draft;
    default:
      return draft;
  }
}, docsInitialState);
