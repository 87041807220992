import React from "react";

const SdkIcon = (props: React.HTMLProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.576 9.326 3.67 18.233a1.5 1.5 0 0 0 0 2.125h0a1.5 1.5 0 0 0 2.125 0l8.907-8.907m-.46 3.231a1.5 1.5 0 0 1 2.122 0l3.551 3.552a1.5 1.5 0 0 1-2.121 2.12l-3.552-3.55a1.5 1.5 0 0 1 0-2.122zm3.595-10.631L15.89 6.073a.5.5 0 0 0-.14.346v1.354a.5.5 0 0 0 .5.5h1.404a.5.5 0 0 0 .354-.146l1.93-1.936m.007.008a4 4 0 0 1-5.242 5.25m-2.125-2.126a4 4 0 0 1 5.238-5.25m-7.128 7.143L3.684 4.213m10.514 10.513-1.392-1.392"
        stroke="#9C2AA0"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SdkIcon;
