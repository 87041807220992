import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IAuditingLogDetails } from "../../auditing.model";
import moment from "moment";
import { OperationText, PurpleText } from "../auditing-table.component";

interface IAuditingDetailsHeaderInterface {
  details: IAuditingLogDetails;
}
const AuditingDetailsHeader = ({ details }: IAuditingDetailsHeaderInterface) => {
  const [t] = useTranslation();
  return (
    <HeaderContainer>
      <HeaderColumn>
        <HeaderLabel>{t("pages.auditing.details.updatedOn")}</HeaderLabel>
        <HeaderValue>{moment(details.date).format("DD/MM/YYYY HH:mm:ss")}</HeaderValue>
      </HeaderColumn>

      <HeaderColumn>
        <HeaderLabel>{t("pages.auditing.details.operatorName")}</HeaderLabel>
        <HeaderValue>{details.author}</HeaderValue>
      </HeaderColumn>

      <HeaderColumn>
        <HeaderLabel>{t("pages.auditing.details.operatorRole")}</HeaderLabel>
        <HeaderValue>{t(`userRoles.${details.role}`)}</HeaderValue>
      </HeaderColumn>

      <HeaderColumn>
        <HeaderLabel>{t("pages.auditing.details.typeOfUpdate")}</HeaderLabel>
        <HeaderValue>
          <OperationText operation={details.operation}>
            {t(`pages.auditing.operations.${details.operation}`)}
          </OperationText>
        </HeaderValue>
      </HeaderColumn>

      <HeaderColumn>
        <HeaderLabel>{t("pages.auditing.details.updatedOn")}</HeaderLabel>
        <HeaderValue>
          <PurpleText>{t(`pages.auditing.sections.${details.entity}`)}</PurpleText>
        </HeaderValue>
      </HeaderColumn>
    </HeaderContainer>
  );
};
const HeaderContainer = styled("div")`
  width: 100%;
  background-color: ${props => props.theme.palette.whiteTwo};
  padding: 32px 37px 0px 68px;
  min-height: 150px;
  display: flex;
  flex-wrap: wrap;
`;
const HeaderColumn = styled("div")`
  flex-grow: 0;
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;
const HeaderLabel = styled("span")`
  color: ${props => props.theme.palette.midGrey};
  font-weight: bold;
  font-family: "Vodafone Rg";
  font-size: 14px;
  margin-bottom: 2px;
`;
const HeaderValue = styled("span")`
  color: ${props => props.theme.palette.darkGrey};
  font-family: "Vodafone Rg";
  font-size: 14px;
`;
export default AuditingDetailsHeader;
