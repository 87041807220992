import { MemberIcon, useAlert, CheckIcon, CancelIcon } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { RoutesEnum } from "../../../routes/routes.constants";
import { Column, Row } from "../../shared.styled";
import { INotification } from "./notification.model";
import NotificationsApi from "./notifications.api";
import { TaskStatusEnum } from "../../../features/admin/tasks/task.model";
import { useSelector } from "react-redux";
import { IStoreInterface } from "../../../configs/store.config";
import { ILoggedUser } from "../../../features/authentication/authentication.interfaces";
import { UserRolesDefault, UserRolesVDF } from "../../../features/admin/users/users.interfaces";
import { useHistory } from "react-router-dom";

interface INotificationInterface {
  notification: INotification;
  hideDrawer: () => void;
  getNotifications: () => void;
}
const NotificationItem = ({ notification, hideDrawer, getNotifications }: INotificationInterface) => {
  const [t] = useTranslation();
  const [showAlert, , setAlertProps] = useAlert();
  const loggedUser = useSelector((state: IStoreInterface) => state.authenticationReducer.loggedUser) as ILoggedUser;
  const history = useHistory();

  /**
   * navigate to the task details
   */
  const navigateToTask = () => {
    if (
      notification.task &&
      notification.task.status === TaskStatusEnum.PENDING_APPROVAL &&
      (loggedUser.role === UserRolesDefault.APPROVER || loggedUser.role === UserRolesVDF.APPROVER_SERVICE_MANAGEMENT)
    ) {
      history.push(RoutesEnum.TASK_DETAILS.replace(":taskId", notification.task.id as string));
      hideDrawer();
    }
  };

  const onClickTaskNotification = () => {
    if (!notification.read) {
      NotificationsApi.methods.markNotificationAsRead(notification.id).then(
        () => {
          getNotifications();
        },
        () => {
          setAlertProps({
            title: t("notifications.markAsReadError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
    }
    navigateToTask();
  };

  return (
    <>
      {notification.task ? (
        <NotificationContainer
          key={notification.task.id}
          onClick={onClickTaskNotification}
          id={`notification-${notification.id}`}
        >
          <Row style={{ width: "100%" }}>
            <Column style={{ height: "100%" }}>
              {notification.task.status === TaskStatusEnum.PENDING_APPROVAL ? (
                <MemberIcon
                  fullName={notification.task.author.name ? notification.task.author.name : notification.task.author.id}
                />
              ) : (
                <IconWrapper
                  color={
                    notification.task.status === TaskStatusEnum.APPROVED
                      ? "rgba(0, 153, 0, 0.1)"
                      : "rgba(255, 0, 0, 0.1)"
                  }
                  iconColor={notification.task.status === TaskStatusEnum.APPROVED ? "#009900" : "#e60000"}
                >
                  {notification.task.status === TaskStatusEnum.APPROVED ? (
                    <CheckIcon></CheckIcon>
                  ) : (
                    <CancelIcon></CancelIcon>
                  )}
                </IconWrapper>
              )}
            </Column>
            <Column style={{ width: "100%" }}>
              <NotificationDescription read={notification.read}>
                {notification.description} {notification.read ? <div></div> : <UnreadIcon></UnreadIcon>}
              </NotificationDescription>
              <Row style={{ width: "100%", marginTop: "5px" }}>
                <NotificationInfoLabel>
                  {t(`pages.myTasks.sectionEnum.${notification.task.section}`)}
                </NotificationInfoLabel>
                <NotificationInfoLabel>
                  {`${t("notifications.by")} ${
                    notification.task.status !== TaskStatusEnum.PENDING_APPROVAL && notification.task.approver
                      ? notification.task.approver.name
                        ? notification.task.approver.name
                        : notification.task.approver.id
                      : notification.task.author.name
                      ? notification.task.author.name
                      : notification.task.author.id
                  }`}
                </NotificationInfoLabel>
              </Row>
              {notification.task.status === TaskStatusEnum.DECLINED && notification.task && notification.task.reason ? (
                <Row>
                  <NotificationReasonLabel>
                    {`${t("notifications.reason")} ${notification.task.reason}`}
                  </NotificationReasonLabel>
                </Row>
              ) : null}
            </Column>
          </Row>
        </NotificationContainer>
      ) : (
        <span>TBI: Other type of notification</span>
      )}
    </>
  );
};

const NotificationContainer = styled("div")`
  font-family: "Vodafone Rg";
  display: flex;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  padding: 16px;
  margin-bottom: 24px;
  [data-testid="member-icon-container"] {
    width: 48px;
    height: 48px;
    border-radius: 48px;
  }
`;

const NotificationDescription = styled("div")<{ read: Boolean }>`
  font-size: 18px;
  font-family: "Vodafone Rg";
  color: ${props => props.theme.palette.darkGrey};
  font-weight: ${props => (props.read ? "normal" : "bold")};
  display: flex;
  flex-direction: row;
`;

const NotificationInfoLabel = styled("span")`
  font-size: 15px;
  font-family: "Vodafone Rg";
  color: ${props => props.theme.palette.anthracite};
  :not(:last-child) {
    margin-right: 16px;
  }
`;

const NotificationReasonLabel = styled("span")`
  margin-top: 8px;
  font-size: 15px;
  font-family: "Vodafone Rg";
  color: ${props => props.theme.palette.darkGrey};
`;

const IconWrapper = styled("div")<{ color: string; iconColor: string }>`
  width: 48px;
  height: 48px;
  background-color: ${props => props.color};
  border-radius: 50%;
  padding: 12px;
  margin-right: 13px;
  svg {
    stroke: ${props => props.iconColor};
  }
`;

export const UnreadIcon = styled("div")<{ size?: string }>`
  background-color: ${props => props.theme.palette.vodafoneRed};
  width: ${props => (props.size ? props.size : "10px")};
  height: ${props => (props.size ? props.size : "10px")};
  border-radius: ${props => (props.size ? props.size : "10px")};
  flex-shrink: 0;
  margin-top: 5px;
  margin-left: auto;
`;

export default NotificationItem;
