import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { ColumnProps } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import i18next from "i18next";
import moment from "moment";
import React from "react";
import { Row } from "../../../shared/shared.styled";
import { IBussinessSearchForm, ICustomerSearchForm } from "./identity-converter.model";

export const IdentityConverterUtils = {
  getTableColumns: (): ColumnProps[] => {
    return [
      {
        ratio: 1.2 / 3,
        label: i18next.t("pages.tools.identityConverter.table.customerId"),
        formKey: "userId",
        isEditable: false,
        changeFunction: content => {
          return (
            <Row>
              <span>{content}</span>
            </Row>
          );
        },
      },
      {
        ratio: 2 / 12,
        label: i18next.t("pages.tools.identityConverter.table.createdOn"),
        formKey: "createdOn",
        isEditable: false,
        changeFunction: content => {
          return <span className="status-content">{moment(content).format("DD MMM YYYY")}</span>;
        },
      },
      {
        ratio: 2 / 12,
        label: i18next.t("pages.tools.identityConverter.table.expiredOn"),
        formKey: "expiredOn",
        isEditable: false,
        changeFunction: content => {
          return <span className="status-content">{moment(content).format("DD MMM YYYY")}</span>;
        },
      },
    ];
  },

  /**
   * This functime adds time to a date
   * @param dateToAdd
   * @param timeToAdd
   * @returns
   */
  addTimeToDate: (dateToAdd: string, timeToAdd: string): [string, boolean] => {
    const period = timeToAdd.slice(-2);
    const time = timeToAdd.slice(0, timeToAdd.length - 3).split(":");
    if (time.length > 0) {
      let hour: number = (time[0] as unknown) as number;
      const minute: number = (time[1] as unknown) as number;
      if (period === "pm" && hour < 12) {
        hour = parseInt(hour.toString()) + parseInt("12");
      }

      return [
        moment(dateToAdd)
          .set("hours", hour)
          .set("minutes", minute)
          .set("seconds", 0)
          .format("YYYY-MM-DD[T]HH:mm:ssZ"),
        true,
      ];
    }
    return [moment(dateToAdd).format("YYYY-MM-DD[T]HH:mm:ssZ"), false];
  },
  timeOptions: (): SharedDropdownOption[] => {
    const options: SharedDropdownOption[] = [];

    for (let i = 0; i <= 11; i++) {
      options.push({ label: `${i}:00 am`, key: `${i}:00` });

      options.push({ label: `${i}:30 am`, key: `${i}:30` });
    }

    options.push({ label: `12:00 pm`, key: `12:00` });
    options.push({ label: `12:30 pm`, key: `12:30` });

    for (let i = 1; i <= 11; i++) {
      options.push({ label: `${i}:00 pm`, key: `${i}:00` });

      options.push({ label: `${i}:30 pm`, key: `${i}:30` });
    }

    return options;
  },

  /**
   *  This function check if the dates and times are valid taking in consideration the minimum time difference
   * @param setFieldValue
   * @param values
   * @param startDate
   * @param endDate
   */
  validateDatesAndTimes: (
    values: IBussinessSearchForm | ICustomerSearchForm,
    startDate: string | undefined,
    endDate: string | undefined,
  ) => {
    startDate = startDate ? startDate : values.createdOn;
    endDate = endDate ? endDate : values.expiredOn;
    const dateDifferenceInMinutes = moment.duration(moment(endDate).diff(moment(startDate))).asMinutes();
    return !(dateDifferenceInMinutes < 0 && values.expiredOn);
  },

  /**
   * method to set start date and set a default end date if is not defined yet
   */
  setStartDate: (
    setFieldValue: (key: string, value: any, shouldValidate?: boolean) => void,
    startDate: string,
    values: IBussinessSearchForm | ICustomerSearchForm,
  ) => {
    setFieldValue("startDate", startDate);
    if (!values.expiredOn) {
      //set default endDate
      const endDateInitialValue = moment(startDate)
        .add(1, "days")
        .format("YYYY-MM-DD[T]HH:mm:ssZ");

      setFieldValue("endDate", endDateInitialValue);
    }
  },

  /**
   * This returs the inital data for start and end dates and start and end time
   * @returns
   */
  getInitialDatesAndTimes: () => {
    return {
      createdOn: moment()
        .subtract(1, "days")
        .startOf("day")
        .format("YYYY-MM-DD[T]HH:mm:ssZ")
        .toString(),
      startTime: "0:00 am",
      expiredOn: moment()
        .format("YYYY-MM-DD[T]HH:mm:ssZ")
        .toString(),
      endTime: moment().format("hh:mm a"),
    };
  },
};
