import i18next from "i18next";
import store from "../../../configs/store.config";
import { array, object, string } from "yup";
import { IAllowedScope } from "./allowed-scopes.model";

/**
 * filters single sign on scopes by name
 * @param {IAllowedScope[]} scopes list of single sign on scopes to be filtered
 * @param {string} searchString the filter string
 * @returns {IAllowedScope[]} filtered scopes
 */
const allowedScopesFilterBySearch = (scopes: IAllowedScope[], searchString: string, lang: string): IAllowedScope[] => {
  if (searchString) {
    return scopes.filter(scope => {
      if (scope.nameTranslations[lang]) {
        return scope.nameTranslations[lang].toLowerCase().includes(searchString.toLowerCase());
      }
    });
  }
  return scopes;
};

/**
 * filters categories by muiltiple filters
 * @param {IAllowedScope[]} scopes list of single sign on scopes to be filtered
 * @param {Map<string, string[]>} filters filters to be applied to the scopes
 * @returns {IAllowedScope[]} filtered scopes
 */
export const allowedScopesFilter = (
  scopes: IAllowedScope[],
  filters: Map<string, string[]>,
  lang: string,
): IAllowedScope[] => {
  let newScopes = scopes;
  const search = filters.get("search");

  if (search) {
    newScopes = allowedScopesFilterBySearch(scopes, search[0], lang);
  }

  return newScopes;
};

/**
 * Get available languages
 */
export const getAvailableLanguagesOptions = () => {
  const antServiceManagerStore = store.getState().antServiceManagerReducer;

  const languages = Object.keys(
    antServiceManagerStore.allowedScopes.reduce((keys: any, item: any) => {
      const itemKeys = Object.keys(item.nameTranslations);
      itemKeys.forEach(key => {
        keys[key] = true;
      });
      return keys;
    }, {}),
  ).map(key => {
    return { label: i18next.t(`commons.languages.${key}`), key: key };
  });

  if (languages) {
    return languages.sort((a, b) => {
      const labelA = a.label.toLowerCase();
      const labelB = b.label.toLowerCase();

      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    });
  } else {
    return [
      {
        label: i18next.t(`commons.languages.en`),
        key: "en",
      },
    ];
  }
};
