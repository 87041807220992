export interface IRateCards {
  chargeProfiles: IChargeProfile[];
  hasPreviousVersion: boolean;
  hasUnpublishedChanges: boolean;
  status: RateCardsStatusEnum;
  hasInvalids?: boolean;
  submittedForApproval?: boolean;
}
export interface IChargeProfile {
  chargeType: string;
  currencyCode: string;
  externalId: string;
  hasPreviousVersion: boolean;
  hasUnpublishedChanges: boolean;
  id: string;
  name: string;
  ranges: IRange[];
  rangeValuesRequired: boolean;
  reasonType: string;
  status: ChargeProfileStatusEnum;
  invalid: boolean;
}

export interface IRange {
  id?: string | null;
  rangeBegin?: number;
  rangeEnd?: number;
  rateValue: number;
  rateValueType: RateValueTypeEnum;
}

export enum ChargeProfileStatusEnum {
  DELETE = "DELETE",
  DISABLE = "DISABLE",
  DISABLED = "DISABLED",
  EDIT = "EDIT",
  ENABLE = "ENABLE",
  LIVE = "LIVE",
}

export enum RateCardsStatusEnum {
  EDIT = "EDIT",
  LIVE = "LIVE",
}

export enum RateValueTypeEnum {
  PERCENTAGE = "PERCENTAGE",
  NUMBER = "NUMBER",
}

export interface IAddChargeProfileRequest {
  name: string;
  reasonType: string;
  chargeType: string;
  currencyCode: string;
  externalId: string;
}
