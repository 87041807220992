import { FullNameValidationRuleType } from "../features/admin/users/users.interfaces";

export const NEW_FEATURE_DAYS = 90;
export const MAX_CSV_FILE_SIZE = 1000000;

export const FULL_NAME_VALIDATION_RULES: FullNameValidationRuleType = {
  MZ: {
    REGEX: /^[a-zA-Z0-9\s\-]{3,255}$/,
    MIN: 3,
    MAX: 255,
  },
  LS: {
    REGEX: /^[a-zA-Z0-9\s\-]{3,255}$/,
    MIN: 3,
    MAX: 255,
  },
  TZ: {
    REGEX: /^[a-zA-Z0-9\s\-]{3,255}$/,
    MIN: 3,
    MAX: 255,
  },
  GH: {
    REGEX: /^[a-zA-Z0-9\s\-]{3,255}$/,
    MIN: 3,
    MAX: 255,
  },
  DRC: {
    REGEX: /^[a-zA-Z\s\-]{3,255}$/,
    MIN: 3,
    MAX: 255,
  },
  DRC_EKYC: {
    REGEX: /^[a-zA-Z\s\-]{3,255}$/,
    MIN: 3,
    MAX: 255,
  },
  KE: {
    REGEX: /^[a-zA-Z0-9\s\-]{3,255}$/,
    MIN: 3,
    MAX: 255,
  },
  ET: {
    REGEX: /^[a-zA-Z0-9\s\-]{3,255}$/,
    MIN: 3,
    MAX: 255,
  },
};
