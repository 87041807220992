import { ModalTypeEnum, SecondaryPageTitle, useAlert, useModal } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { RoutesEnum } from "../../../../routes/routes.constants";
import { ILanguage } from "../../../../shared/models/language.model";
import LanguagesApi from "../../../../shared/services/languages.api";
import { LoadingText, PageContainer } from "../../../../shared/shared.styled";
import ReferralCampaignsApi from "../referral-campaigns.api";
import {
  IReferralCampaign,
  ReceiverTypeRewardEnum,
  ReferralCampaign,
  SenderTypeRewardEnum,
} from "../referral-campaigns.model";
import { ReferralCampaignsActions } from "../referral-campaigns.store";
import CreateCampaignDetails from "./components/create-campaign-details.component";
import CreateReceiverScreen from "./components/create-receiver-screen.component";
import CreateRewardDetails from "./components/create-reward-details.component";
import CreateSenderScreen from "./components/create-sender-screen.component";

/**
 * CreateReferralCampaign page component
 */
const CreateReferralCampaign = () => {
  const [t] = useTranslation();
  const [showAlert, , setAlertProps] = useAlert();
  const history = useHistory();
  const [availableLanguages, setAvailableLanguages] = React.useState<ILanguage[]>([
    { id: "1", code: "en", name: "English", urlIcon: "ic_flag_uk.png" },
  ]);
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>("en");
  const [formValues, setFormValues] = React.useState<IReferralCampaign>();
  const [step, setStep] = React.useState(1);
  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );

  const dispatch = useDispatch();

  dispatch(ReferralCampaignsActions.creators.removeReferralCampaign());
  React.useEffect(() => {
    setupLanguages();
  }, []);

  /**
   * Sets up language toggle and the default form values
   */
  const setupLanguages = () => {
    let languages: ILanguage[];
    LanguagesApi.methods
      .getAvailableLanguages()
      .then(
        res => {
          languages = res.data.availableLanguages;
        },
        () => {
          languages = [
            { id: "1", code: "en", name: "English", urlIcon: "ic_flag_uk.png" },
            { id: "2", code: "sw", name: "Swahili (Tanzania)", urlIcon: "ic_flag_tanzania.png" },
          ];
          setAlertProps({
            type: AlertTypeEnum.ERROR,
            title: t("commons.getLanguagesError"),
          });
          showAlert();
        },
      )
      .finally(() => {
        const langCodes = languages.map((lang: ILanguage) => {
          return lang.code;
        });
        setAvailableLanguages(languages);
        setSelectedLanguage(langCodes.includes("en") ? "en" : langCodes[0]);
        setFormValues(new ReferralCampaign(langCodes));
      });
  };

  /**
   * Deletes invalid fields on rewards before saving/editing
   * @param {IReferralCampaign} values
   * @returns {IReferralCampaign}
   */
  const removeInvalidRewardFields = (values: IReferralCampaign) => {
    values.rewards.forEach(r => {
      if (r.type === ReceiverTypeRewardEnum.NONE || r.type === SenderTypeRewardEnum.NONE) {
        delete r.maxInvites;
        delete r.amount;
        delete r.prizeCategory;
      }
    });
    return values;
  };

  /**
   * method to create a referral campaign
   */
  const createReferralCampaign = (values: IReferralCampaign) => {
    setConfirmationProps({
      title: t("pages.referralCampaigns.createCampaign.titleModal"),
      description: t("pages.referralCampaigns.createCampaign.descriptionModal"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      primaryAction: () => {
        values.startDate = moment(values.startDate, "DD/MM/YYYY").valueOf();
        values.endDate = moment(values.endDate, "DD/MM/YYYY").valueOf();
        removeInvalidRewardFields(values);
        ReferralCampaignsApi.methods.createReferralCampaign(values).then(
          () => {
            setAlertProps({
              type: AlertTypeEnum.SUCCESS,
              title: t("pages.referralCampaigns.createCampaign.getCreateCampaignSuccess"),
            });
            showAlert();
            history.push(RoutesEnum.REFERRAL_CAMPAIGNS);
          },
          () => {
            setAlertProps({
              title: t("pages.referralCampaigns.createCampaign.getCreateCampaignError"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          },
        );
      },
      secondaryAction: () => hideConfirmationModal(),
    } as BaseModalProps);
    showConfirmationModal();
  };

  /**
   * method to advance to the next step
   */
  const advanceToNextStep = (values: IReferralCampaign) => {
    setFormValues(values);
    if (step === 4) {
      createReferralCampaign(values);
    } else {
      setStep(step + 1);
    }
  };

  /**
   * function go back
   */
  const goBack = () => {
    history.push(RoutesEnum.REFERRAL_CAMPAIGNS);
  };

  React.useEffect(() => {
    if (availableLanguages.length > 0) {
      setSelectedLanguage(availableLanguages[0].code);
    }
  }, [step]);

  /**
   * Manually change step
   * @param {number} step
   * @param {IReferralCampaign} values
   */
  const changeStep = (step: number, values: IReferralCampaign) => {
    setFormValues(values);
    setStep(step);
  };

  return (
    <PageContainer>
      <SecondaryPageTitle
        displayInitialsCircle={false}
        title={t("pages.referralCampaigns.createCampaign.title")}
        goBackFn={() => goBack()}
      />
      {availableLanguages && formValues ? (
        step === 1 ? (
          <CreateCampaignDetails
            languages={availableLanguages}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
            formValues={formValues}
            advanceToNextStep={advanceToNextStep}
            setStep={changeStep}
          ></CreateCampaignDetails>
        ) : step === 2 ? (
          <CreateRewardDetails
            languages={availableLanguages}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
            formValues={formValues}
            advanceToNextStep={advanceToNextStep}
            setStep={changeStep}
          ></CreateRewardDetails>
        ) : step === 3 ? (
          <CreateSenderScreen
            languages={availableLanguages}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
            formValues={formValues}
            onSubmit={advanceToNextStep}
            setStep={changeStep}
          ></CreateSenderScreen>
        ) : (
          <CreateReceiverScreen
            languages={availableLanguages}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
            formValues={formValues}
            onSubmit={advanceToNextStep}
            setStep={changeStep}
          ></CreateReceiverScreen>
        )
      ) : (
        <ReferralCampaignContainer>
          <LoadingText>{t("pages.dynamicScreen.loadingResults")}</LoadingText>
        </ReferralCampaignContainer>
      )}
    </PageContainer>
  );
};

export default CreateReferralCampaign;

const ReferralCampaignContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: calc(128px + 28px - 50px);
  margin-left: calc(278px - 118px + 44px);
  margin-right: calc(278px - 118px + 44px);
`;
