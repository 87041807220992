import React from "react";
import styled from "styled-components";
import { RatioContainer } from "../../../../../shared/shared.styled";
import { RecurringIcon, DownloadIcon, SmallButton } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { ReferralAnalyticsEnums } from "../../referral-campaigns.model";

interface IRefreshAndExport {
  refreshLabel: string;
  refreshCurrentTab: () => void;
  exportLabel: string;
  exportFile: () => void;
  hidden?: string[];
  totals: {
    totalRewards: number;
    totalInvites: number;
    isFetching?: boolean;
  };
}

const RefreshAndExportActions = ({
  refreshLabel,
  refreshCurrentTab,
  exportLabel,
  exportFile,
  hidden,
  totals,
}: IRefreshAndExport) => {
  return (
    <>
      <RatioContainerCustom ratio={8 / 12}>
        <SmallButton
          disabled={hidden && !!hidden.find(hide => hide === ReferralAnalyticsEnums.REFRESH_DATA)}
          titleLabel={refreshLabel}
          onClick={() => refreshCurrentTab()}
          iconComponent={
            <SmallButtonIconContainer color={styleTheme.palette.turquoiseBlue}>
              <RecurringIcon />
            </SmallButtonIconContainer>
          }
        />

        {(!!totals.totalInvites || !!totals.totalRewards) && (
          <SmallButton
            disabled={hidden && !!hidden.find(hide => hide === ReferralAnalyticsEnums.EXPORT_EXCEL)}
            titleLabel={exportLabel}
            onClick={() => exportFile()}
            iconComponent={
              <SmallButtonIconContainer color={styleTheme.palette.vodafoneRed}>
                <DownloadIcon />
              </SmallButtonIconContainer>
            }
          />
        )}
      </RatioContainerCustom>
    </>
  );
};

export default RefreshAndExportActions;

const RatioContainerCustom = styled(RatioContainer)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 20px;
`;

const SmallButtonIconContainer = styled.div<{ color: string }>`
  width: 16px;
  height: 16px;
  stroke: ${props => props.color};

  svg {
    width: 16px;
    height: 16px;
    stroke: ${props => props.color};
  }
`;
