import {
  ClientDocSubmissionNextStatusEnum,
  ClientDocSubmissionNextStatusEnumKeys,
  ClientDocSubmissionStatusEnum,
  ClientDocSubmissionStatusEnumKeys,
} from "../ekyc.shared.enums";

export interface IClientDocSubmissionDetailsRequest {
  remoteId: string;
  username: string;
  previousDuplicates: string[];
}

export interface IClientImagesRequest {
  submissionRequestId: string;
  imageType: ImageType;
}

export enum EFieldStatus {
  REVIEW = "REVIEW",
  ACCEPTED = "ACCEPTED",
}

export interface IField {
  value: string | number;
  status: EFieldStatus;
}
export interface IClientDocSubmissionDetailsResponse {
  // TBD: Some properties of this interface are outdated,
  // we're still waiting for the market to inform if records of previous versions will implement this interface
  id: string;
  requestId: string;
  firstName: IField;
  lastName: IField;
  dateOfBirth: IField;
  placeOfBirth?: IField;
  gender?: IField;
  documentType: string;
  documentNumber: IField;
  nationality: IField;
  commune?: IField;
  street?: IField;
  province?: IField;
  town?: IField;
  msisdn: string;
  creationDate: number;
  updateStatusDate?: number;
  status: ClientDocSubmissionStatusEnum;
  comment?: string;
  expirationDate?: number;
  language: string;
  numberOfPhoneNumberDuplications: number;
  numberOfIdNumberDuplications: number;
  signature?: string;
  frontPhoto?: string;
  backPhoto?: string;
  selfie?: string;
  visa?: string;
  lastGetUser?: string;
  lastGetTime?: number;
  penultGetUser?: string;
  penultGetTime?: number;
  registrationHistories?: IStatusHistoryEntry[];
  duplicateFaces?: string[] | null;
  duplicateDocuments?: string[] | null;
  g2ErrorComment?: string;
  agent: {
    agentName: string;
    middleName: string;
    lastName: string;
    agentOperatorId: string;
    agentShortCode: number;
  };
  g2Status?: string;
}

export interface IClientDocSubmissionChangeRequest {
  status: ClientDocSubmissionNextStatusEnum;
  comment?: string;
  customComment?: string;
}

export interface IStatusHistoryEntry {
  operator?: string;
  status?: ClientDocSubmissionStatusEnum;
  statusDate?: number;
}

export interface IClientDocSubmissionDetailsImagesResponse {
  content: string;
}

export interface IPhotoInfo {
  context: string;
  extension: string;
  url: string;
}

export enum ImageType {
  FRONT = "FRONT",
  BACK = "BACK",
  SELFIE = "SELFIE",
}

export interface IDocValidationDetailsPageProps {
  remoteId: string;
}

export interface ICustomReasonCharactersLimit {
  max: number;
  min: number;
}

export interface IDocStatusItemProps {
  status: ClientDocSubmissionNextStatusEnumKeys | ClientDocSubmissionStatusEnumKeys;
}

export interface IDisableOptionProps {
  currentOption: ClientDocSubmissionNextStatusEnumKeys;
  optionToBeDisabled: ClientDocSubmissionNextStatusEnumKeys;
  currentStatus: ClientDocSubmissionStatusEnumKeys;
  forbiddenStatuses: ClientDocSubmissionStatusEnumKeys[];
}

export interface IDocValidationDetailsReducerInterface {
  remoteId: string;
  requestId: string;
  fullName: string;
  firstName?: IField;
  lastName?: IField;
  dateOfBirth: IField;
  placeOfBirth?: IField;
  gender?: IField;
  documentType: string;
  idNumber: IField;
  nationality: IField;
  addressCommune?: IField;
  addressStreet?: IField;
  addressProvince?: IField;
  addressTown?: IField;
  phoneNumber: string;
  requestTime: number;
  lastStatusTime?: number;
  status: ClientDocSubmissionStatusEnumKeys;
  reason?: string;
  expirationDate?: number;
  userLanguage: string;
  signatureUrl?: string;
  numberOfIdNumberDuplications: number;
  numberOfPhoneNumberDuplications: number;
  lastSeenBy?: string;
  lastSeenTime?: number;
  penultSeenBy?: string;
  penultSeenTime?: number;
  statusHistory: IClientDocStatusHistoryEntry[];
  duplicateFaces?: string[] | null;
  duplicateDocuments?: string[] | null;
  g2Error?: string;
  agent: {
    firstName: string;
    middleName: string;
    lastName: string;
    operatorId: string;
    shortcode: number | string;
  };
  g2Status?: string;
}

export interface IDocValidationDetailsImagesReducerInterface {
  context: string;
  extension: string;
  url: string;
}

export interface IClientDocStatusHistoryEntry {
  username?: string;
  status: ClientDocSubmissionStatusEnumKeys;
  statusDate: number;
}
