import {
  AutoClosingDropdown,
  Checkbox,
  OrganizationIcon,
  PrimaryButton,
  TextInput,
  useAlert,
} from "@wit/mpesa-ui-components";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikHelpers } from "formik";
import React, { useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import * as Yup from "yup";
import { ConfigContext } from "../../../app.component";
import { IStoreInterface } from "../../../configs/store.config";
import useCheckFeatureAvailable from "../../../shared/hooks/use-check-available-feature";
import { IBusiness } from "../../../shared/models/business.model";
import { CLIENTS, FEATURES } from "../../../shared/renderer.utils";
import { Column, RatioContainer, Row } from "../../../shared/shared.styled";
import { fileToBase64, isHttp, isSFCMarket, srcToFile, verifyImageUrl } from "../../../shared/shared.utils";
import { BusinessStatusEnum, getNewData } from "./business-utils";
import { BusinessesAPI } from "./business.api";

interface IEditBusinessModalProps {
  onCancelClick: () => void;
  selectedBusiness: IBusiness;
  setSelectedBusiness: React.Dispatch<React.SetStateAction<IBusiness | undefined>>;
}

const FILE_SIZE = !isSFCMarket() ? 5476 : 256000;
const SUPPORTED_FORMATS = !isSFCMarket() ? ["image/svg+xml"] : ["image/png"];

const _URL = window.URL || window.webkitURL;

/**
 * Edit business modal
 * @param param0
 */
const EditBusinessModal = ({ onCancelClick, selectedBusiness, setSelectedBusiness }: IEditBusinessModalProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const categories = useSelector((state: IStoreInterface) => state.businessesReducer.categories);
  const popularCount = useSelector((state: IStoreInterface) => state.businessesReducer.businesses.popularCount);

  const [showAlert, , setAlertProps] = useAlert();

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [image, setImage] = useState<string>();
  const [imageName, setImageName] = useState<string>();
  const [cantUploud, setCantUploud] = useState<boolean>(false);

  /**
   * verify image size
   */
  const verifyImageSize = async (file: any, setFieldValue: any) => {
    if (SUPPORTED_FORMATS.indexOf(file.type) < 0) {
      setImageUrl(undefined);
      setFieldValue("urlIcon", undefined);
      setCantUploud(true);
      setImage(undefined);
      setImageName(undefined);
      return;
    }
    const img = new Image();
    const objectUrl = _URL.createObjectURL(file);
    const base64Image = await fileToBase64(file);

    img.onload = function(e) {
      if (!isSFCMarket() && (img.width > 74 || img.height > 74)) {
        setFieldValue("urlIcon", undefined);
        setCantUploud(true);
        setImage(undefined);
        setImageName(undefined);
        setImageUrl(undefined);
        return;
      } else {
        setCantUploud(false);
      }
      const url = _URL.createObjectURL(file);
      setImage(base64Image);
      setImageName(file.name);
      setImageUrl(url);
    };
    img.src = objectUrl;
  };

  const { config } = useContext(ConfigContext);
  const showCategories = useCheckFeatureAvailable(FEATURES.BUSINESS_CATEGORIES);

  /**
   * Change status
   */
  const changeStatus = (currentStatus: any, setFieldValue: any) => {
    if (currentStatus === BusinessStatusEnum.LIVE || currentStatus === BusinessStatusEnum.ENABLE) {
      setFieldValue("status", BusinessStatusEnum.DISABLE);
    } else {
      setFieldValue("status", BusinessStatusEnum.ENABLE);
    }
  };

  /**
   * Get business validation schema
   */
  const getBusinessValidationSchema = () => {
    return Yup.object().shape({
      shortCode: Yup.string().required(t("pages.configurations.businessTab.createBusiness.form.shortCode.error")),
      name: Yup.string().required(t("pages.configurations.businessTab.createBusiness.form.name.error")),
      categoryId: Yup.string().required(t("pages.configurations.businessTab.createBusiness.form.category.error")),
      urlIcon: Yup.mixed()
        .test("fileSize", "", value => value && value.size <= FILE_SIZE)
        .test("fileFormat", "", value => value && SUPPORTED_FORMATS.includes(value.type)),
    });
  };

  /**
   * Get category dropdown options
   */
  const getCategoryDropdownOptions = (): SharedDropdownOption[] => {
    return categories.data.map(category => {
      return {
        key: category.id,
        label: (category && category.name && category.name.en) || "",
      };
    });
  };

  const billType =
    config && config.client === CLIENTS.VFGROUP
      ? [{ key: "CONSUMER", label: t("pages.configurations.businessTab.modal.edit.category.consumerOnly") }]
      : [
          { key: "ALL", label: t("pages.configurations.businessTab.modal.edit.category.all") },
          { key: "CONSUMER", label: t("pages.configurations.businessTab.modal.edit.category.consumerOnly") },
          { key: "ORGANIZATION", label: t("pages.configurations.businessTab.modal.edit.category.organizationOnly") },
        ];

  /**
   * get dropdown label
   * @param categoryId
   */
  const getDropdownLabel = (categoryId: string) => {
    const categorySelected = categories.data.find(c => c.id === categoryId);

    return categorySelected && categorySelected.name ? categorySelected.name.en : "";
  };

  /**
   * get popular dropdown label
   * @param bill
   */
  const getPopularDropdownLabel = (bill: string) => {
    const billSelected = billType.find(b => b.key === bill);
    return billSelected ? billSelected.label : t("pages.configurations.businessTab.modal.edit.popularBill.placeholder");
  };

  /**
   * handle submit
   * @param val
   * @param _actions
   */
  const _handleSubmit = async (val: IBusiness, _actions: FormikHelpers<IBusiness>) => {
    if (selectedBusiness && val.popular) {
      if (
        selectedBusiness.popularBillType !== val.popularBillType &&
        popularCount &&
        (popularCount as any)[val.popularBillType] + 1 > 3
      ) {
        setAlertProps({
          title: `${t("pages.configurations.businessTab.errors.maximumPopular")} ${getPopularDropdownLabel(
            val.popularBillType,
          )}`,
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        return;
      }
    }

    const requestData: any = {
      ...val,
      imageName: undefined,
      base64Image: undefined,
    };

    if (!image && !selectedBusiness) {
      try {
        const a = await srcToFile("./images/organization.svg", "default.svg", "image/svg+xml");
        requestData.base64Image = await fileToBase64(a);
        requestData.imageName = a.name;
      } catch (err) {
        setAlertProps({
          title: err as string,
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        return;
      }
    } else {
      requestData.imageName = imageName;
      requestData.base64Image = image;
    }

    setIsLoading(true);
    if (selectedBusiness) {
      BusinessesAPI.methods
        .editBusiness(val.id, requestData)
        .then(
          _res => {
            setSelectedBusiness(undefined);
            setAlertProps({
              title: t("pages.configurations.businessTab.success.editBusinessesSuccess"),
              type: AlertTypeEnum.SUCCESS,
            });
            showAlert();
            onCancelClick();
          },
          err => {
            if (err.data.status.businessCode === 4094) {
              setCantUploud(true);
              setAlertProps({
                title: err.data.status.message,
                type: AlertTypeEnum.ERROR,
              });
            } else {
              setAlertProps({
                title: t("pages.configurations.businessTab.errors.editBusinessesError"),
                type: AlertTypeEnum.ERROR,
              });
            }
            showAlert();
          },
        )
        .then(() => getNewData(dispatch, showAlert, setAlertProps, t))
        .finally(() => setIsLoading(false));
    } else {
      BusinessesAPI.methods
        .createBusiness(requestData)
        .then(
          _res => {
            setSelectedBusiness(undefined);
            setAlertProps({
              title: t("pages.configurations.businessTab.success.createBusinessesSuccess"),
              type: AlertTypeEnum.SUCCESS,
            });
            showAlert();
            onCancelClick();
          },
          err => {
            if (err.data.status.businessCode === 4094) {
              setCantUploud(true);
            }
            if (err.data.status.message) {
              setAlertProps({
                title: err.data.status.message,
                type: AlertTypeEnum.ERROR,
              });
            } else {
              setAlertProps({
                title: t("pages.configurations.businessTab.errors.createBusinessesError"),
                type: AlertTypeEnum.ERROR,
              });
            }
            showAlert();
          },
        )
        .then(() => getNewData(dispatch, showAlert, setAlertProps, t))
        .finally(() => setIsLoading(false));
    }
  };

  /**
   * change popular
   */
  const changePopular = (popular: boolean, setFieldValue: (field: string, value: any) => void) => {
    setFieldValue("popular", popular);
    if (popular) {
      setFieldValue("popularBillType", billType[0].key);
    }
  };

  /**
   * Get initial values
   */
  const getInitialValues = (): any => {
    return (
      selectedBusiness ||
      (isSFCMarket()
        ? {
            categoryId: categories.data[0]?.id,
            popularBillType: "ALL",
          }
        : {
            categoryId: categories.data[0]?.id,
          })
    );
  };

  return (
    <ModalContainer>
      <ModalTitle>
        {selectedBusiness
          ? t("pages.configurations.businessTab.modal.edit.title")
          : t("pages.configurations.businessTab.modal.edit.titleAdd")}
      </ModalTitle>
      <Formik
        onSubmit={_handleSubmit}
        validationSchema={getBusinessValidationSchema()}
        validateOnChange={false}
        initialValues={getInitialValues()}
        render={({ isSubmitting, handleSubmit, values, setFieldValue, errors, handleChange }) => {
          if (values && values.urlIcon && typeof values.urlIcon === "string") {
            if (isHttp(values.urlIcon)) {
              verifyImageUrl(values.urlIcon, (url: string) => setImageUrl(url));
            } else if (values.urlIcon.startsWith("data") && isSFCMarket()) {
              setImageUrl(values.urlIcon);
            } else {
              setImageUrl(`./imagesuploaded/${values.urlIcon}`);
            }
          }

          const isNewImage = imageUrl && (imageUrl as string).includes("blob");
          return (
            <form onSubmit={handleSubmit}>
              <Column>
                <FormInfoContainer>
                  <ImageUpload className="container">
                    {/* "?time=${new Date().valueOf()}" avoids image cache */}
                    {imageUrl &&
                      (!isNewImage && values.urlIcon.startsWith("data") && isSFCMarket() ? (
                        <Logo src={`${imageUrl}`} alt="logo"></Logo>
                      ) : (
                        <Logo
                          src={`${imageUrl}${!isNewImage ? `?time=${new Date().valueOf()}` : ""}`}
                          alt="logo"
                        ></Logo>
                      ))}
                    {!imageUrl && (
                      <Placeholder>
                        <OrganizationIcon width="74px" height="74px"></OrganizationIcon>
                      </Placeholder>
                    )}
                    <DropImage {...getRootProps({ className: "dropzone" })}>
                      <input
                        {...getInputProps()}
                        onChange={event => {
                          setFieldValue("urlIcon", event.currentTarget.files ? event.currentTarget.files[0] : null);
                          if (event.currentTarget.files && event.currentTarget.files[0]) {
                            verifyImageSize(event.currentTarget.files[0], setFieldValue);
                          }
                        }}
                      />
                      <EditPicture missing={false}>
                        {t("pages.configurations.businessTab.modal.edit.editPicture")}
                      </EditPicture>
                      <PictureDescription missing={false}>
                        {!isSFCMarket() && (
                          <p>
                            {t("pages.configurations.businessTab.modal.edit.dimension", { height: "72", width: "72" })}
                          </p>
                        )}
                        <p>
                          {t("pages.configurations.businessTab.modal.edit.format", {
                            format: isSFCMarket() ? "png" : "svg",
                          })}
                        </p>
                      </PictureDescription>
                    </DropImage>
                    {cantUploud && (
                      <UploadError>{t("pages.configurations.businessTab.modal.edit.uploadError")}</UploadError>
                    )}
                  </ImageUpload>
                </FormInfoContainer>
                <FormInfoContainer>
                  <RatioContainer style={{ marginRight: 24 }} ratio={1 / 2}>
                    <TextInput
                      title={t("pages.configurations.businessTab.modal.edit.name.label")}
                      placeholder={t("pages.configurations.businessTab.modal.edit.name.placeholder")}
                      name="name"
                      onChange={handleChange}
                      value={values.name || ""}
                      error={errors.name}
                      maxLength={100}
                      required
                    />
                  </RatioContainer>
                  <RatioContainer ratio={1 / 2}>
                    <TextInput
                      name="shortCode"
                      title={t("pages.configurations.businessTab.modal.edit.shortCode.label")}
                      placeholder={t("pages.configurations.businessTab.modal.edit.shortCode.placeholder")}
                      onChange={handleChange}
                      value={values.shortCode || ""}
                      error={errors.shortCode}
                      maxLength={100}
                      required
                    />
                  </RatioContainer>
                </FormInfoContainer>
                {!isSFCMarket() && (
                  <FormInfoContainer>
                    <RatioContainer style={{ marginRight: 24 }} ratio={1 / 2}>
                      <TextInput
                        title={t("pages.configurations.businessTab.modal.edit.amount.label")}
                        placeholder={t("pages.configurations.businessTab.modal.edit.amount.placeholder")}
                        name="amount"
                        onChange={handleChange}
                        value={values.amount || ""}
                        error={errors.amount}
                        maxLength={100}
                      />
                    </RatioContainer>
                    <RatioContainer ratio={1 / 2}>
                      <TextInput
                        title={t("pages.configurations.businessTab.modal.edit.billReference.label")}
                        placeholder={t("pages.configurations.businessTab.modal.edit.billReference.placeholder")}
                        name="billReference"
                        onChange={handleChange}
                        value={values.billReference || ""}
                        error={errors.billReference}
                        maxLength={100}
                      />
                    </RatioContainer>
                  </FormInfoContainer>
                )}
                <FormInfoContainer>
                  <RatioContainer style={{ marginRight: 24 }} ratio={1 / 2}>
                    {!isSFCMarket ? (
                      <Column>
                        <PopularLabel>
                          <Checkbox
                            value={values.popular}
                            onValueChange={e => changePopular(e, setFieldValue)}
                          ></Checkbox>
                          <Label>{t("pages.configurations.businessTab.modal.edit.popularBill.label")}</Label>
                        </PopularLabel>
                        <DropDownGuard activated={!values.popular} />
                        <DropDownWrapper disabled={!values.popular} id="popularBill-dropdown">
                          <AutoClosingDropdown
                            hasValue={values.popular}
                            label={getPopularDropdownLabel(values.popularBillType)}
                            options={billType}
                            selectOption={opt => setFieldValue("popularBillType", opt.key)}
                          />
                        </DropDownWrapper>
                      </Column>
                    ) : (
                      <Column>
                        <PopularLabelTarget>
                          <Label>{t("pages.configurations.businessTab.modal.edit.popularBill.labelTarget")}</Label>
                        </PopularLabelTarget>
                        <DropDownWrapper id="popularBill-dropdown">
                          <AutoClosingDropdown
                            hasValue={values.popular}
                            label={getPopularDropdownLabel(values.popularBillType)}
                            options={billType}
                            selectOption={opt => setFieldValue("popularBillType", opt.key)}
                          />
                        </DropDownWrapper>
                      </Column>
                    )}
                  </RatioContainer>

                  {(showCategories || isSFCMarket()) && (
                    <RatioContainer ratio={1 / 2}>
                      <Column>
                        <LabelRequired>{t("pages.configurations.businessTab.modal.edit.category.label")}</LabelRequired>
                        <DropDownWrapper id="category-dropdown">
                          <AutoClosingDropdown
                            hasValue={values.categoryId !== ""}
                            label={getDropdownLabel(values.categoryId)}
                            options={getCategoryDropdownOptions()}
                            error={errors.categoryId}
                            selectOption={opt => setFieldValue("categoryId", opt.key)}
                          />
                        </DropDownWrapper>
                      </Column>
                    </RatioContainer>
                  )}
                </FormInfoContainer>
                {selectedBusiness && (
                  <FormInfoContainer>
                    <Label>{t("pages.configurations.businessTab.modal.status.label")}</Label>

                    <div onClick={() => changeStatus(values.status, setFieldValue)} style={{ cursor: "pointer" }}>
                      {values.status === BusinessStatusEnum.LIVE ||
                      values.status === BusinessStatusEnum.ENABLE ||
                      values.status === BusinessStatusEnum.EDIT ? (
                        <LiveStatus>
                          <span>{t("pages.configurations.businessTab.modal.status.live")}</span>
                        </LiveStatus>
                      ) : (
                        <DisabledStatus>
                          <span>{t("pages.configurations.businessTab.modal.status.disabled")}</span>
                        </DisabledStatus>
                      )}
                    </div>
                  </FormInfoContainer>
                )}
              </Column>
              <ButtonsContainer>
                <div>
                  <PrimaryButton
                    id="cancel-button"
                    type="button"
                    disabled={isLoading}
                    onClick={onCancelClick}
                    titleLabel={t("pages.configurations.businessTab.modal.edit.cancelBtnLabel")}
                  />
                </div>
                <div>
                  <PrimaryButton
                    id={selectedBusiness ? "save-changes-button" : "add-business-button"}
                    redTheme={true}
                    type="submit"
                    loading={isLoading}
                    titleLabel={
                      selectedBusiness
                        ? t("pages.configurations.businessTab.modal.edit.saveChangesBtnLabel")
                        : t("pages.configurations.businessTab.modal.edit.addBusinessBtnLabel")
                    }
                  />
                </div>
              </ButtonsContainer>
            </form>
          );
        }}
      />
    </ModalContainer>
  );
};

export default EditBusinessModal;

const ModalContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 24px 18px 24px 24px;
  font-family: Vodafone Rg;
`;

const ModalTitle = styled("span")`
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 21px;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 50px;

  > div {
    width: "fit-content";

    :first-of-type {
      margin-right: 12px;
    }
  }
`;

const Label = styled("div")`
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
  margin-bottom: 8px;
  min-height: 21px;
`;

const FormInfoContainer = styled(Row)`
  margin-bottom: 22px;
`;

const DropDownWrapper = styled.div<{ disabled?: Boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  button {
    border-radius: 6px;
    border: 1px solid #ebebeb;
  }
`;

const PopularLabel = styled(Row)`
  div {
    &:first-child {
      margin-bottom: 10px;
      height: 16px;
      width: 16px;
      margin-right: 8px;
    }
  }
`;
const PopularLabelTarget = styled(Row)`
  div {
    &:first-child {
      margin-bottom: 10px;
      height: 16px;
      width: 100%;
      margin-right: 8px;
    }
  }
`;

const LabelRequired = styled(Label)`
  ::after {
    content: " *";
    color: ${props => props.theme.palette.errorRed};
  }
`;

const DropDownGuard = styled.div<{ activated: Boolean }>`
  ${({ activated }) =>
    activated ? " height: 50px;width: 300px;z-index: 100;position: absolute;margin-top: 30px;opacity: 0.5" : ""}
`;

const Status = styled.div`
  border-radius: 10px;
  padding-left: 12px;
  padding-right: 12px;
  height: 21px;
  line-height: 21px;
  font-weight: bold;
  display: inline-block;
  font-size: 14px;
  text-align: center;
  color: ${props => props.theme.palette.white};
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-left: 10px;
  > span {
    position: relative;
    bottom: 1px;
  }
`;

const LiveStatus = styled(Status)`
  color: ${props => props.theme.palette.successGreen};
  border: 1px solid;
  border-color: ${props => props.theme.palette.successGreen};
`;
const DisabledStatus = styled(Status)`
  color: black;
  border: 1px solid black;
  opacity: 0.5;
`;

const ImageUpload = styled.div`
  display: flex;
`;

const DropImage = styled.div`
  margin-left: 25px;
`;

const Logo = styled.img`
  width: 63px;
  height: 63px;
  border-radius: 100px;
  padding: 10px;
  border: solid 1px;
  border-color: ${props => props.theme.palette.aluminium};
`;

const Placeholder = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  stroke: ${props => props.theme.palette.darkGrey};
  opacity: 0.3;
  border-radius: 6px;
  border: solid 1px;
  border-color: ${props => props.theme.palette.aluminium};
  background-color: ${props => props.theme.palette.greyLight};
  width: 63px;
  height: 63px;
  border-radius: 100px;

  svg {
    width: 25px;
    height: 25px;
  }
`;

const EditPicture = styled.div<{ missing: Boolean | undefined }>`
  height: 27px;
  border-radius: 20px;
  border: solid 1px;
  border-color: ${props => props.theme.palette.aluminium};
  font-size: 14px;
  line-height: 1.36;
  color: ${props => (props.missing ? "red" : props.theme.palette.darkGrey)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const PictureDescription = styled.div<{ missing: Boolean | undefined }>`
  margin-top: 15px;
  color: ${props => (props.missing ? "red" : props.theme.palette.midGrey)};

  p {
    margin: 0;
    font-size: 13px;
  }
`;

const UploadError = styled.span`
  color: ${props => props.theme.palette.digitalRed};
  font-family: Vodafone Rg;
  font-size: 13px;
  margin-left: 8px;
`;
