import { PrimaryButton } from "@wit/mpesa-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import VersionItemStatusChip from "../../../../shared/hooks/use-version-diff/version-item-status-chip.component";
import useVersionsDiff, { VersionItem } from "../../../../shared/hooks/use-version-diff/use-version-diff.hook";
import { IReleasedVersion, IReleases } from "../../../../shared/models/app.model";
import { Row } from "../../../../shared/shared.styled";

interface IPublishAppReleaseVersionsProps {
  hideModal: () => void;
  isLoading: boolean;
  publishChanges: () => void;
  currentVersion: IReleases | undefined;
  lastVersion: IReleases | undefined;
}

/**
 * Publish App Release Versions
 * @param param0
 */
const PublishAppReleaseVersions = ({
  currentVersion,
  lastVersion,
  isLoading,
  publishChanges,
  hideModal,
}: IPublishAppReleaseVersionsProps) => {
  const [t] = useTranslation();
  const { prev, current } = useVersionsDiff(lastVersion!.releasedVersions, currentVersion!.releasedVersions);

  /**
   * Publish request
   * */
  const publish = () => {
    publishChanges();
  };

  return (
    <ModalContainer>
      <ModalTitle>{t("pages.appDetails.releaseVersions.publishChanges")}</ModalTitle>
      <ModalSubtitle>{t("pages.appDetails.releaseVersions.publishChangesModalTitle")}</ModalSubtitle>
      <DocumentContainer>
        <Row style={{ alignItems: "normal" }}>
          <Panel background={true}>
            <PanelTitle>{t("pages.appDetails.releaseVersions.previousVersion")}</PanelTitle>
            {lastVersion ? (
              <>
                {prev.map((rv: VersionItem<IReleasedVersion>) => {
                  return (
                    <>
                      <div>
                        <VersionName>{rv.name}</VersionName>
                        <VersionItemStatusChip status={rv.versionItemStatus} />
                      </div>
                      <Version>{rv.version}</Version>
                    </>
                  );
                })}
              </>
            ) : null}
          </Panel>
          <Panel>
            <PanelTitle>{t("pages.appDetails.releaseVersions.editedVersion")}</PanelTitle>
            {currentVersion ? (
              <>
                {current.map((rv: VersionItem<IReleasedVersion>) => {
                  return (
                    <>
                      <div>
                        <VersionName>{rv.name}</VersionName>
                        <VersionItemStatusChip status={rv.versionItemStatus} />
                      </div>
                      <Version>{rv.version}</Version>
                    </>
                  );
                })}
              </>
            ) : null}
          </Panel>
        </Row>

        <Row
          style={{
            justifyContent: "flex-end",
            width: "100%",
            marginTop: "auto",
            paddingRight: "24px",
            overflow: "visible",
            paddingTop: "24px",
          }}
        >
          <div style={{ marginRight: "12px" }}>
            <PrimaryButton
              onClick={hideModal}
              titleLabel={t("pages.appDetails.releaseVersions.cancel")}
              disabled={isLoading}
              id="cancel-publish-button"
            />
          </div>
          <div>
            <PrimaryButton
              redTheme={true}
              type="submit"
              onClick={publish}
              disabled={isLoading}
              titleLabel={t("pages.appDetails.releaseVersions.confirm")}
              id="confirm-button"
            />
          </div>
        </Row>
      </DocumentContainer>
    </ModalContainer>
  );
};

export default PublishAppReleaseVersions;

const ModalContainer = styled("div")`
  border-radius: 6px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  padding-top: 24px;
  padding-bottom: 24px;
`;

const ModalTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 13px;
  padding-left: 24px;
`;

const ModalSubtitle = styled("p")`
  font-family: Vodafone Rg;
  font-size: 18px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 24px;
  padding-left: 24px;
`;

const DocumentContainer = styled("div")`
  font-family: Vodafone Rg;
  max-height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const Panel = styled("div")<{ background?: boolean }>`
  font-family: Vodafone Rg;
  overflow: auto;
  display: flex;
  width: 50%;
  padding: 24px;
  background-color: ${props => (props.background ? props.theme.palette.lightGrey : props.theme.palette.white)};
  flex-direction: column;
  max-height: 300px;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  b,
  strong {
    margin: 0;
  }
`;

const PanelTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  margin-bottom: 12px;
`;

const VersionName = styled("span")`
  font-family: Vodafone Rg;
  font-size: 14px;
  color: ${props => props.theme.palette.midGrey};
  font-weight: bold;
  padding-bottom: 2px;
`;

const Version = styled("span")`
  font-family: Vodafone Rg;
  font-size: 14px;
  color: ${props => props.theme.palette.darkGrey};
  padding-bottom: 10px;
`;
