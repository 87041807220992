import { Toggle } from "@wit/mpesa-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { INetworkItem } from "../networks.model";
import { getStatus } from "../networks.page";

interface IBuyAirtimePageProps {
  networkList: INetworkItem[];
  saveChanges: (networks: INetworkItem, value: boolean) => void;
}

const BuyAirtimePage = ({ networkList, saveChanges }: IBuyAirtimePageProps) => {
  const [t] = useTranslation();

  return (
    <Content>
      <LeftColumn>
        <LeftTitle>{t("pages.networks.buyAirtimePage.title")}</LeftTitle>
        <LeftDescription>{t("pages.networks.buyAirtimePage.description")}</LeftDescription>
      </LeftColumn>
      <RightColumn>
        {networkList.map((value: INetworkItem, index) => {
          return (
            <NetworkItemDiv key={index} id={value.networkType}>
              <NetworkText>{t(`commons.networks.${value.networkType}`)}</NetworkText>
              <ToggleItem id={`toggle-${t(`commons.networks.${value.networkType}`)}`}>
                <Toggle
                  cb={(newValue: boolean) => {
                    saveChanges(value, newValue);
                  }}
                  isDisabled={false}
                  initialValue={getStatus(value.status)}
                />
              </ToggleItem>
            </NetworkItemDiv>
          );
        })}
      </RightColumn>
    </Content>
  );
};

export default BuyAirtimePage;

const Content = styled.div`
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    margin-top: 72px;
  }
  @media (max-width: 600px) {
    margin-top: 112px;
  }
`;

const LeftColumn = styled.div`
  flex: 1;
  width: 293px;
  min-width: 293px;
  margin-right: 102px;
`;
const RightColumn = styled.div`
  flex: 1;
`;
const NetworkItemDiv = styled.div`
  border-bottom: solid 1px #ebebeb;
  padding-top: 18px;
  padding-bottom: 18px;
`;
const NetworkText = styled.span`
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: bold;
  color: #afafaf;
`;

const LeftTitle = styled.div`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 4px;
`;
const LeftDescription = styled.div`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: #afafaf;
`;

const ToggleItem = styled.div`
  float: right;
`;
