import {
  CheckIcon,
  EditIcon,
  ModalTypeEnum,
  SecondaryPageTitle,
  TrashIcon,
  UndoIcon,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { IStoreInterface } from "../../../../configs/store.config";
import { RoutesEnum } from "../../../../routes/routes.constants";
import { ILanguage } from "../../../../shared/models/language.model";
import LanguagesApi from "../../../../shared/services/languages.api";
import { LoadingText, PageContainer } from "../../../../shared/shared.styled";
import { IDynamicScreenCategory } from "../../../configurations/dynamic-screens/dynamic-screens.model";
import { GeneralCategoriesActions } from "../general-categories.store";
import GeneralCategoriesApi from "../general-categories.api";
import GeneralCategoryForm from "../components/general-category-form.component";
import { GeneralCategoriesTabNumber } from "../general-categories.model";
import { PageContent } from "../../../../shared/responsive-ui.styled";

interface IGeneralCategoryEditProps {
  isAntCategories?: boolean;
}

/**
 * EditCategoryPage component
 */
const EditGeneralCategoryPage = ({ isAntCategories = false }: IGeneralCategoryEditProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showAlert, , setAlertProps] = useAlert();
  const { category } = useSelector((state: IStoreInterface) => state.generalCategoriesReducer);
  const query = new URLSearchParams(useLocation().search);
  const [errorIds, setErrorIds] = React.useState<string[]>([]);
  const [isEditing, setIsEditing] = React.useState(query.get("editing") === "true" || false);
  const [showDeleteModal, hideDeleteModal, setDeleteProps] = useModal(ModalTypeEnum.ConfirmationModal);
  const [availableLanguages, setAvailableLanguages] = useState<ILanguage[]>();

  React.useEffect(() => {
    LanguagesApi.methods.getAvailableLanguages().then(
      res => {
        if (res.data.availableLanguages.length === 0) {
          setAvailableLanguages([
            { id: "1", code: "en", name: "English", urlIcon: "ic_flag_uk.png" },
            { id: "2", code: "sw", name: "Swahili (Tanzania)", urlIcon: "ic_flag_tanzania.png" },
          ]);
        } else {
          setAvailableLanguages(res.data.availableLanguages);
        }
      },
      () => {
        setAlertProps({
          type: AlertTypeEnum.ERROR,
          title: t("commons.getLanguagesError"),
        });
        showAlert();
        setAvailableLanguages([
          { id: "1", code: "en", name: "English", urlIcon: "ic_flag_uk.png" },
          { id: "2", code: "sw", name: "Swahili (Tanzania)", urlIcon: "ic_flag_tanzania.png" },
        ]);
      },
    );
  }, []);

  /**
   * function go back
   */
  const goBack = () => {
    !isAntCategories
      ? history.push(`${RoutesEnum.CATEGORIES}`)
      : history.push(`${RoutesEnum.ANT_SERVICE_MANAGER}?tabIdx=${GeneralCategoriesTabNumber.CATEGORIES}`);
  };

  /**
   * function on submit form
   */
  const onSubmit = (values: IDynamicScreenCategory) => {
    const editValues = {
      id: values.id,
      name: values.name,
      externalCategoryIds: values.externalCategoryIds,
      iconId: values.iconId,
    };

    GeneralCategoriesApi.methods.editCategory(editValues).then(
      res => {
        dispatch(GeneralCategoriesActions.creators.setCategory(res.data));
        setIsEditing(false);
        setAlertProps({
          title: t("pages.generalCategories.categoryChanged"),
          type: AlertTypeEnum.SUCCESS,
        });
        showAlert();
        goBack();
      },
      error => {
        if (error?.data?.status?.httpCode === 409) {
          if (error.data?.status?.businessCode === 8) {
            if (error.data?.additionalInfo?.invalidExternalCategoryIds) {
              let ids = error.data.additionalInfo.invalidExternalCategoryIds;
              ids = ids.invalidExternalCategoryIds ? ids.invalidExternalCategoryIds : ids;
              setErrorIds(ids.split(","));
            }
            setAlertProps({
              title: t("pages.generalCategories.duplicatedIdsError"),
              type: AlertTypeEnum.ERROR,
            });
          } else if (error.data.status?.businessCode === 7 || error.data.status?.businessCode === 40919) {
            setAlertProps({
              title: t("pages.generalCategories.categoryAlreadyExistsError"),
              type: AlertTypeEnum.ERROR,
            });
          } else {
            setAlertProps({
              title: t("pages.generalCategories.editCategoriesError"),
              type: AlertTypeEnum.ERROR,
            });
          }
        } else {
          setAlertProps({
            title: t("pages.generalCategories.editCategoriesError"),
            type: AlertTypeEnum.ERROR,
          });
        }
        showAlert();
      },
    );
  };

  /**
   * function on delete category
   */
  const onDelete = (category: IDynamicScreenCategory) => {
    setDeleteProps({
      secondaryAction: () => hideDeleteModal(),
      primaryAction: () => {
        GeneralCategoriesApi.methods
          .deleteCategory(category.id)
          .then(
            res => {
              setAlertProps({
                title: t("pages.generalCategories.categoryDeleted"),
                type: AlertTypeEnum.SUCCESS,
              });
              showAlert();
              goBack();
            },
            () => {
              setAlertProps({
                title: t("pages.generalCategories.deleteCategoriesError"),
                type: AlertTypeEnum.ERROR,
              });
              showAlert();
            },
          )
          .finally(() => {
            hideDeleteModal();
          });
      },
      title: t("pages.generalCategories.categoryForm.deleteTitleModal"),
      description: t("pages.generalCategories.categoryForm.deleteDescriptionModal").replace(
        "{category}",
        category.name.translations["en"],
      ),
      primaryBtnLabel: t("pages.generalCategories.categoryForm.deleteLabelBtn"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      primaryBtnMaxWidth: 160,
    });

    showDeleteModal();
  };

  /**
   * function to reset form
   */
  const resetForm = () => {
    const form = document.getElementById("category-form") as HTMLFormElement;
    form.reset();
  };

  return (
    <>
      {!availableLanguages ? (
        <LoadingText style={{ marginLeft: "75px" }}>{t("commons.loadingResults")}</LoadingText>
      ) : (
        availableLanguages && (
          <PageContainer>
            <SecondaryPageTitle
              displayInitialsCircle={false}
              title={`${category.name.translations["en"]} Category`}
              goBackFn={() => goBack()}
            />
            <EditCategoryContainer className={"no-reverse"}>
              <GeneralCategoryForm
                initialValues={category}
                isEditing={isEditing}
                onSubmitFn={onSubmit}
                errorIds={errorIds}
                availableLanguages={availableLanguages}
                setAvailableLanguages={setAvailableLanguages}
                isAntCategories={isAntCategories}
              />
              <SideMenu className={"side-menu"}>
                {!isEditing ? (
                  <LinkContainer
                    id="edit-category"
                    onClick={() => {
                      setIsEditing(!isEditing);
                    }}
                  >
                    <IconContainer color={styleTheme.palette.turquoiseBlue}>
                      <EditIcon />
                    </IconContainer>
                    <span>{t("pages.generalCategories.categoryForm.editCategory")}</span>
                  </LinkContainer>
                ) : (
                  <>
                    <LinkContainerSave id="save-changes" type="submit" form={`category-form`}>
                      <IconContainer color={styleTheme.palette.successGreen}>
                        <CheckIcon />
                      </IconContainer>
                      <span>{t("pages.generalCategories.categoryForm.saveChanges")}</span>
                    </LinkContainerSave>
                    <LinkContainer
                      id="cancel-changes"
                      onClick={() => {
                        setIsEditing(false);
                        resetForm();
                      }}
                    >
                      <IconContainer color={styleTheme.palette.errorRed}>
                        <UndoIcon />
                      </IconContainer>
                      <span>{t("pages.generalCategories.categoryForm.cancelChanges")}</span>
                    </LinkContainer>
                  </>
                )}

                {!isAntCategories && (
                  <LinkContainer id="delete-category" disabled={isEditing} onClick={() => onDelete(category)}>
                    <IconContainer color={styleTheme.palette.errorRed}>
                      <TrashIcon />
                    </IconContainer>
                    <span>{t("pages.generalCategories.categoryForm.deleteCategory")}</span>
                  </LinkContainer>
                )}
              </SideMenu>
            </EditCategoryContainer>
          </PageContainer>
        )
      )}
    </>
  );
};

export default EditGeneralCategoryPage;

const EditCategoryContainer = styled(PageContent)`
  @media (min-width: 1366px) {
    margin-left: 204px;
    margin-right: 204px;
  }
`;

const SideMenu = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 75px !important;
  width: 40%;
`;

const LinkContainer = styled("div")<{ disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-family: Vodafone Rg;
  color: ${styleTheme.palette.darkGrey};
  font-size: 16px;
  margin-bottom: 15px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? "none" : "inherit")};
  width: fit-content;
  > div {
    margin-right: 8px;
  }
`;
const LinkContainerSave = styled("button")<{ disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-family: Vodafone Rg;
  color: ${styleTheme.palette.darkGrey};
  font-size: 16px;
  margin-bottom: 15px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? "none" : "inherit")};
  border: none;
  background: none;
  padding: 0;
  width: fit-content;
  > div {
    margin-right: 8px;
  }
`;

const IconContainer = styled("div")<{ color: string }>`
  width: 20px;
  height: 20px;
  > svg {
    stroke: ${props => props.color};
  }
`;
