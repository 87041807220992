import { AxiosPromise } from "axios";
import ApiClient from "../../../configs/axios.config";
import { IAddChargeProfileRequest, IChargeProfile } from "./rate-cards.model";

/*
 * Rate cards API methods
 * */
const RateCardsApi = {
  routes: {
    getRateCards: "/v1/charge_profiles?previousVersion=false",
    getLastVersion: "v1/charge_profiles?previousVersion=true",
    revertChanges: "/v1/charge_profiles/revert",
    publish: "/v1/charge_profiles/publish",
    submitForApproval: "/v1/charge_profiles/submitforapproval",
    createChargeProfile: "/v1/ratecards",
    editChargeProfile: "/v1/ratecards/:id",
    deleteChargeProfile: "/v1/charge_profiles/:id",
    getChargeProfile: "/v1/charge_profiles/:id",
    updateChargeProfileTiers: "/v1/charge_profiles",
    getReasonTypes: "/reasonTypes",
    getChargeTypes: "/chargeTypes?reasonType=",
  },
  methods: {
    getRateCards: (): AxiosPromise => {
      return ApiClient.get(RateCardsApi.routes.getRateCards, {});
    },
    getLastVersion: (): AxiosPromise => {
      return ApiClient.get(RateCardsApi.routes.getLastVersion, {});
    },
    createChargeProfile: (chargeProfile: IAddChargeProfileRequest): AxiosPromise => {
      return ApiClient.post(RateCardsApi.routes.createChargeProfile, chargeProfile);
    },
    editChargeProfile: (chargeProfile: IAddChargeProfileRequest, id: string): AxiosPromise => {
      return ApiClient.put(RateCardsApi.routes.editChargeProfile.replace(":id", id), chargeProfile);
    },
    deleteChargeProfile: (id: string): AxiosPromise => {
      return ApiClient.delete(RateCardsApi.routes.deleteChargeProfile.replace(":id", id));
    },
    publish: (): AxiosPromise => {
      return ApiClient.put(RateCardsApi.routes.publish);
    },
    submitForApproval: (): AxiosPromise => {
      return ApiClient.put(RateCardsApi.routes.submitForApproval);
    },
    revertChanges: (): AxiosPromise => {
      return ApiClient.put(RateCardsApi.routes.revertChanges);
    },
    getChargeProfile: (id: string): AxiosPromise => {
      return ApiClient.get(RateCardsApi.routes.getChargeProfile.replace(":id", id));
    },
    updateChargeProfileTiers: (id: string, chargeProfile: IChargeProfile): AxiosPromise => {
      return ApiClient.post(RateCardsApi.routes.updateChargeProfileTiers, chargeProfile);
    },
    createChargeProfileTiers: (id: string, chargeProfile: IChargeProfile): AxiosPromise => {
      return ApiClient.post(RateCardsApi.routes.updateChargeProfileTiers, chargeProfile);
    },
    getReasonTypes: (): AxiosPromise => {
      return ApiClient.get(RateCardsApi.routes.getReasonTypes);
    },
    getChargeTypes: (reason: string): AxiosPromise => {
      return ApiClient.get(RateCardsApi.routes.getChargeTypes + reason);
    },
  },
};

export default RateCardsApi;
