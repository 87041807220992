import {
  AutoClosingDropdown,
  CancelIcon,
  DownloadIcon,
  FastActionButton,
  PlusIcon,
  TabChild,
  Tabs,
  TextArea,
  TextInput,
  Toggle,
  useAlert,
} from "@wit/mpesa-ui-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { SafaricomTheme } from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { FormikErrors } from "formik";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import LoadingComponent from "../../../shared/components/loading-component/LoadingComponent";
import { StatusType } from "../../../shared/shared.enums";
import { formatBytes } from "../../../shared/shared.utils";
import AnnouncementsApi from "../announcements.api";
import {
  AnnouncementFormSteps,
  AudienceType,
  CallToActionType,
  IActionInterface,
  IAnnouncement,
  ICallToActionInterface,
  ICallToActionOption,
  ICallToActionOptions,
  IStoryInterface,
  IValueOption,
} from "../announcements.model";
import { AnnouncementsMainContent, CallToActionSelection, SpanForm } from "../announcements.styled";
import ColorOptions from "../components/color-options.component";
import DropzoneBackground from "../components/dropzone-background.component";
import MobileSchema from "../components/mobile-schema-component";
import SideMenuDetails from "../components/sidemenu-details.component";
import StoryPreview from "../components/story-preview.component";

interface IAnnouncementStorysProps {
  valuesAnnouncementNotEditing: IAnnouncement;
  valuesAnnouncement: IAnnouncement;
  setAnnoucement(values: IAnnouncement): void;
  setAnnoucementNotEditing(values: IAnnouncement): void;
  setValues(field: string, value: any, shouldValidate?: boolean): void;
  errors: FormikErrors<IAnnouncement>;
  isEditing: boolean;
  setEditing?: React.Dispatch<React.SetStateAction<boolean>>;
  onChangeValues?(values: IAnnouncement): void;
  detailsPage?: boolean;
  selectedAnnouncementSavedData?: IAnnouncement;
}

export const colorDefaultToTitle = "#00b56a";
export const colorDefaultToDescription = "#1074ff";

/**
 * Component created to stories for announcement
 */
const AnnouncementStorys = ({
  valuesAnnouncementNotEditing,
  setAnnoucement,
  setAnnoucementNotEditing,
  valuesAnnouncement,
  setValues,
  errors,
  isEditing,
  onChangeValues = () => null,
  setEditing = () => null,
  detailsPage = false,
  selectedAnnouncementSavedData,
}: IAnnouncementStorysProps) => {
  const [t] = useTranslation();
  const maxStorys = 4;
  const MAX_FILE_SIZE = 10000000;
  const minWidth = 720;
  const minHeight = 1280;

  const [reachMaxStorys, setReachMaxStorys] = useState(false);
  const [storySelected, setStorySelected] = useState(0);
  const [showToogleTitle, setShowToogleTitle] = useState(false);
  const [showToogleDesc, setShowToogleDesc] = useState(false);
  const [uploadImage, setUploadImage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorUpload, setErrorUpload] = useState(false);
  const [removedStory, setRemovedStory] = useState(false);
  const [tabStory, setTabStory] = React.useState(0);
  const [tabDropColor, setTabDropColor] = useState(0);
  const [showCallAction, setShowCallAction] = useState(
    isEditing
      ? valuesAnnouncement.stories[tabStory].callAction
        ? valuesAnnouncement.stories[tabStory].callAction
        : false
      : valuesAnnouncementNotEditing.stories[tabStory].callAction
      ? valuesAnnouncementNotEditing.stories[tabStory].callAction
      : false,
  );
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const [callToActionOptions, setCallToActionOptions] = useState<ICallToActionOptions>();

  const onDrop = useCallback(
    acceptedImage => {
      if (!acceptedImage[0]) {
        setAlertProps({
          type: AlertTypeEnum.ERROR,
          title: t("pages.announcements.detailPage.rows.imageError"),
        });
        showAlert();
      } else if (acceptedImage[0] && acceptedImage[0].size > MAX_FILE_SIZE) {
        setAlertProps({
          type: AlertTypeEnum.ERROR,
          title: t("pages.announcements.detailPage.rows.imageError"),
          content: t("pages.announcements.detailPage.rows.maxSizeError"),
        });
        showAlert();
      } else {
        const file: File = acceptedImage[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadstart = function() {
          setUploadImage(true);
        };
        reader.onloadend = function(e) {
          const img = new Image();
          img.src = e.target !== null && e.target.result !== null ? (e.target.result as string) : "";

          img.onload = function() {
            const width = img.naturalWidth || img.width;
            const height = img.naturalHeight || img.height;

            if (width >= minWidth && height >= minHeight) {
              setValues(`stories[${tabStory}].backgroundImage`, reader.result);
              setValues(`stories[${tabStory}].fileInfo`, {
                name: file.name,
                size: file.size,
              });

              const stories = [...valuesAnnouncement.stories];
              stories[tabStory] = { ...stories[tabStory], backgroundImage: reader.result as string };

              setAnnoucement({ ...valuesAnnouncement, stories: stories });
              onChangeValues({ ...valuesAnnouncement, stories: stories });
              setDefaultColors({ ...valuesAnnouncement, stories: stories });
            } else {
              setAlertProps({
                type: AlertTypeEnum.ERROR,
                title: t("pages.announcements.detailPage.rows.imageError"),
                content: t("pages.announcements.detailPage.rows.minDimensionSize"),
              });
              showAlert();
            }
            setUploadImage(false);
          };
        };
      }
    },
    [valuesAnnouncement],
  );

  /**
   * function to add one more story to the array
   */
  const addStoryToArray = () => {
    setValues("stories", [
      ...valuesAnnouncement.stories,
      { callAction: false, callToAction: { action: {} } } as IStoryInterface,
    ]);
  };

  /**
   * function to add one tab to the array of tabs
   */
  const addStoryTab = () => {
    if (storySelected <= maxStorys - 2) {
      addStoryToArray();
      setStorySelected(curr => curr + 1);
      setShowCallAction(false);
      setTabStory(curr => curr + 1);
      if (storySelected === maxStorys - 2) {
        setReachMaxStorys(true);
      }
      process.nextTick(() =>
        ((document.querySelector("#tabs>div>div>div") as HTMLElement).childNodes[
          valuesAnnouncement.stories.length
        ] as HTMLElement).click(),
      );
    }
  };

  /**
   * function to remove one story from the array of tabs
   */
  const removeStoryTab = () => {
    if (storySelected === maxStorys - 1) {
      setReachMaxStorys(false);
    }

    let newSelectedStory = storySelected;
    if (storySelected === valuesAnnouncement.stories.length - 1) {
      newSelectedStory -= 1;
      process.nextTick(() =>
        ((document.querySelector("#tabs>div>div>div") as HTMLElement).childNodes[
          storySelected - 1
        ] as HTMLElement).click(),
      );
    }
    const newStorys: IStoryInterface[] = valuesAnnouncement.stories.filter((story, index) => index !== storySelected);
    setValues("stories", newStorys);
    setShowCallAction(newStorys[newSelectedStory].callAction ? newStorys[newSelectedStory].callAction : false);
    setStorySelected(newSelectedStory);
    setTabStory(newSelectedStory);
    setRemovedStory(!removedStory);
  };

  /**
   * function to clear image selected
   */
  const clearImage = () => {
    setValues(`stories[${tabStory}].backgroundImage`, "");
  };

  /**
   * function to return the list of option to the dropdown of actions availables
   */
  const getCallToAction = () => {
    const options = [] as SharedDropdownOption[];

    if (callToActionOptions) {
      Object.entries(callToActionOptions).forEach(([key, option]: [string, ICallToActionOption]) => {
        options.push({
          label: "",
          key: key,
          labelElement: (
            <DivCallActionOption>
              <div>{option.description ? option.description : t(`pages.announcements.actionEnum.${key}`)}</div>
            </DivCallActionOption>
          ),
        });
      });
    } else {
      options.push({
        label: "",
        key: "not-available",
        disabled: true,
        labelElement: (
          <DivCallActionOption>
            <span style={{ color: "red" }}>{t("pages.announcements.errors.callToActionOptionsError")}</span>
          </DivCallActionOption>
        ),
      });
    }
    return options;
  };

  /**
   * function to return the list of option to the dropdown of features availables
   */
  const getFeature = () => {
    if (callToActionOptions && callToActionOptions.FEATURE && callToActionOptions.FEATURE.values) {
      return callToActionOptions.FEATURE.values.map(feature => ({
        label: feature.description,
        key: feature.name,
      }));
    }
    return [
      {
        label: "",
        key: "not-available",
        disabled: true,
        labelElement: (
          <DivCallActionOption>
            <span style={{ color: "red" }}>{t("pages.announcements.errors.callToActionOptionsFeatureError")}</span>
          </DivCallActionOption>
        ),
      },
    ];
  };

  /**
   * function to call when are changing tabs of story's
   * @param tab index of the tab selected
   */
  const navigateStory = (tab: number) => {
    getStoryImage(tab);
    setShowCallAction(valuesAnnouncement.stories[tab].callAction ? valuesAnnouncement.stories[tab].callAction : false);
    setTabStory(tab);
    setStorySelected(tab);
  };

  const storysTabs = valuesAnnouncement.stories.map((story, index) => (
    <TabChild key={`tab_${index}`} label={t(`pages.announcements.detailPage.rows.story${index}`)}>
      <></>
    </TabChild>
  ));

  /**
   * Sets the default colors for title and description
   */
  const setDefaultColors = (announcement: IAnnouncement) => {
    const storiesAnnouncement = [...announcement.stories];
    const [colorTitle, colorDescription] = [
      announcement.stories[tabStory].colorTitle || colorDefaultToTitle,
      announcement.stories[tabStory].colorDescription || colorDefaultToDescription,
    ];
    storiesAnnouncement[tabStory] = {
      ...announcement.stories[tabStory],
      colorTitle,
      colorDescription,
    };

    if (!announcement.stories[tabStory].colorDescription) {
      setValues(`stories[${tabStory}].colorDescription`, colorDefaultToDescription);
    }

    if (!announcement.stories[tabStory].colorTitle) {
      setValues(`stories[${tabStory}].colorTitle`, colorDefaultToTitle);
    }

    onChangeValues({ ...announcement, stories: storiesAnnouncement });
  };

  React.useEffect(() => {
    setDefaultColors(valuesAnnouncement);
  }, [tabStory]);

  React.useEffect(() => {
    if (errors.stories) {
      let foundError = false;
      (errors.stories as FormikErrors<IStoryInterface>[]).forEach((story, index) => {
        if (story && !foundError) {
          foundError = true;
          navigateStory(index);
          process.nextTick(() =>
            ((document.querySelector("#tabs>div>div>div") as HTMLElement).childNodes[index] as HTMLElement).click(),
          );
        }
      });
    }
  }, [errors]);

  React.useEffect(() => {
    setShowCallAction(
      valuesAnnouncementNotEditing.stories[tabStory].callAction
        ? valuesAnnouncementNotEditing.stories[tabStory].callAction
        : false,
    );
    getStoryImage(tabStory);
  }, [isEditing]);

  /**
   * request image to backend
   * @param tab number of tab
   */
  const getStoryImage = (tab: number) => {
    if (
      valuesAnnouncementNotEditing.stories[tab] &&
      (valuesAnnouncementNotEditing.stories[tab].backgroundImage === null ||
        valuesAnnouncementNotEditing.stories[tab].backgroundImage === "")
    ) {
      setIsLoading(true);
      setErrorUpload(false);
      AnnouncementsApi.methods
        .getImage(valuesAnnouncementNotEditing.stories[tab].backgroundImageUrl)
        .then(
          res => {
            const storiesAnnouncementNotEditing = [...valuesAnnouncementNotEditing.stories];
            storiesAnnouncementNotEditing[tab] = {
              ...valuesAnnouncementNotEditing.stories[tab],
              backgroundImage: res.data,
            };
            setAnnoucementNotEditing({
              ...valuesAnnouncementNotEditing,
              stories: storiesAnnouncementNotEditing,
            });
            const storiesAnnouncement = [...valuesAnnouncement.stories];
            storiesAnnouncement[tab] = {
              ...valuesAnnouncement.stories[tab],
              backgroundImage: res.data,
            };
            setAnnoucement({ ...valuesAnnouncement, stories: storiesAnnouncement });
            setValues(`stories[${tabStory}].backgroundImage`, res.data);
          },
          () => {
            setErrorUpload(true);
          },
        )
        .finally(() => {
          setIsLoading(false);
        });
    } else if (
      valuesAnnouncementNotEditing.stories[tab] &&
      valuesAnnouncementNotEditing.stories[tab].backgroundImage &&
      !valuesAnnouncement.stories[tab]?.backgroundImage
    ) {
      const storiesAnnouncement = [...valuesAnnouncement.stories];
      storiesAnnouncement[tab] = {
        ...valuesAnnouncement.stories[tab],
        backgroundImage: valuesAnnouncementNotEditing.stories[tab].backgroundImage,
      };
      setAnnoucement({ ...valuesAnnouncement, stories: storiesAnnouncement });
      setValues(`stories[${tabStory}].backgroundImage`, valuesAnnouncementNotEditing.stories[tab].backgroundImage);
    } else {
      setIsLoading(false);
      setErrorUpload(false);
    }
  };

  React.useEffect(() => {
    if (!isEditing) {
      getStoryImage(tabStory);
    }
  }, [tabStory, valuesAnnouncementNotEditing]);

  React.useEffect(() => {
    AnnouncementsApi.methods.getCallToActionOptions(valuesAnnouncement.audience as AudienceType).then(res => {
      const { EXTERNAL_LINK, INTERNAL_LINK, ...resOfValues } = res.data as ICallToActionOptions;

      setCallToActionOptions(resOfValues);
    });
  }, []);

  /**
   * This return the feature description
   * @param featureName
   * @returns
   */
  const getFeatureDescription = (featureName: string | undefined): string => {
    if (featureName && callToActionOptions && callToActionOptions.FEATURE && callToActionOptions.FEATURE.values) {
      const result = callToActionOptions.FEATURE.values?.find(element => element.name === featureName);

      return result ? result.description : "";
    }

    return "";
  };

  return (
    <AnnouncementsMainContent>
      <AnnouncementsMain detailPage={detailsPage}>
        <SectionForm id="tabs">
          <Tabs controlledSelectedTab={tabStory} controlledSetSelectedTab={navigateStory} isSecondaryTabs={true}>
            {storysTabs}
          </Tabs>
        </SectionForm>
        <SectionForm>
          <DropzoneTitle mandatory={isEditing}>
            {t("pages.announcements.detailPage.rows.backgroundImage")}
          </DropzoneTitle>
          {!valuesAnnouncement.stories[tabStory]?.backgroundImage && isEditing && !uploadImage && !isLoading ? (
            <>
              <DropzoneBackground
                inputName="backgroundImage"
                onDrop={(file: any) => {
                  onDrop(file);
                }}
                accept={".jpg, .jpeg, .png, .bmp"}
                multiple={false}
                dropText={t("pages.announcements.detailPage.rows.dropImage")}
                releaseText={t("pages.announcements.detailPage.rows.uploadImage")}
                error={
                  errors.stories && errors.stories[tabStory]
                    ? !!(errors.stories[tabStory] as FormikErrors<IStoryInterface>).backgroundImage
                    : false
                }
              />
              <ImageInfo
                isEditing={isEditing}
                hasValue={
                  valuesAnnouncement &&
                  valuesAnnouncement.stories &&
                  valuesAnnouncement.stories[tabStory] &&
                  !!valuesAnnouncement.stories[tabStory].backgroundImage
                }
              >
                <ImageInfoTitle>{t("pages.announcements.detailPage.rows.recommendations")}</ImageInfoTitle>
                <ImageInfoDescription>
                  {t("pages.announcements.detailPage.rows.imageRequirements")}
                </ImageInfoDescription>
              </ImageInfo>
            </>
          ) : isLoading || uploadImage ? (
            <LoadingComponent />
          ) : !isEditing && errorUpload ? (
            <ImageDiv>
              <ErrorUploadImages>
                <DownloadIcon />
              </ErrorUploadImages>
              <DescriptionErrorImage>
                {t("pages.announcements.detailPage.rows.imageLoadingError")}
              </DescriptionErrorImage>
            </ImageDiv>
          ) : (
            <ImageDiv>
              <ImageWrapper
                backgroundImage={
                  isEditing
                    ? valuesAnnouncement.stories[tabStory].backgroundImage
                    : valuesAnnouncementNotEditing.stories[tabStory].backgroundImage
                }
                isEditing={isEditing}
              >
                <a onClick={clearImage}>
                  <CancelIcon />
                </a>
              </ImageWrapper>
              <DescriptionImage isEditing={isEditing}>
                <NameImage>
                  {valuesAnnouncement.stories[tabStory].fileInfo
                    ? valuesAnnouncement.stories[tabStory].fileInfo.name
                    : `${t(`pages.announcements.detailPage.rows.story${tabStory}`)}.${
                        valuesAnnouncement.stories[tabStory].backgroundImage
                          ? valuesAnnouncement.stories[tabStory].backgroundImage
                              .split(",")[0]
                              .split("/")[1]
                              .split(";")[0]
                          : "png"
                      }`}
                </NameImage>
                <SizeImage>
                  {formatBytes(
                    isEditing
                      ? valuesAnnouncement.stories[tabStory].fileInfo
                        ? valuesAnnouncement.stories[tabStory].fileInfo.size
                        : valuesAnnouncement.stories[tabStory].backgroundImage
                        ? valuesAnnouncement.stories[tabStory].backgroundImage.length
                        : 0
                      : valuesAnnouncementNotEditing.stories[tabStory].backgroundImage
                      ? valuesAnnouncementNotEditing.stories[tabStory].backgroundImage.length
                      : 0,
                  )}
                </SizeImage>
              </DescriptionImage>
            </ImageDiv>
          )}
          <TextInputSection>
            {!isEditing ? (
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <DefineTitle required={isEditing}>{t("pages.announcements.detailPage.rows.title")}</DefineTitle>
                  <SmallestColorBadges
                    backgroundColor={
                      valuesAnnouncementNotEditing.stories[tabStory].colorTitle
                        ? (valuesAnnouncementNotEditing.stories[tabStory].colorTitle as string)
                        : colorDefaultToTitle
                    }
                  />
                </div>
                <NotEditingText>
                  {valuesAnnouncementNotEditing.stories[tabStory].title
                    ? valuesAnnouncementNotEditing.stories[tabStory].title
                    : ""}
                </NotEditingText>
              </>
            ) : (
              <>
                <SmallColorBadges
                  backgroundColor={
                    valuesAnnouncement.stories[tabStory].colorTitle
                      ? (valuesAnnouncement.stories[tabStory].colorTitle as string)
                      : colorDefaultToTitle
                  }
                  onClick={() => setShowToogleTitle(!showToogleTitle)}
                />
                <TextInput
                  title={t("pages.announcements.detailPage.rows.title")}
                  placeholder={t("pages.announcements.detailPage.rows.placeholderStoryTitle")}
                  value={valuesAnnouncement.stories[tabStory].title ? valuesAnnouncement.stories[tabStory].title : ""}
                  maxLength={20}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setValues(`stories[${tabStory}].title`, e.target.value);
                    valuesAnnouncement.stories[tabStory].title = e.target.value;
                    onChangeValues(valuesAnnouncement);
                  }}
                  //@ts-ignore
                  error={
                    errors.stories &&
                    errors.stories[tabStory] &&
                    (errors.stories[tabStory] as FormikErrors<IStoryInterface>).title ? (
                      <span style={{ marginRight: "35px" }}>
                        {(errors.stories[tabStory] as FormikErrors<IStoryInterface>).title}
                      </span>
                    ) : errors.stories &&
                      errors.stories[tabStory] &&
                      (errors.stories[tabStory] as FormikErrors<IStoryInterface>).colorTitle ? (
                      <span style={{ marginRight: "35px" }}>
                        {(errors.stories[tabStory] as FormikErrors<IStoryInterface>).colorTitle}
                      </span>
                    ) : (
                      undefined
                    )
                  }
                />
              </>
            )}
            {showToogleTitle ? (
              <ColorToogle onBlur={() => setShowToogleTitle(!showToogleTitle)}>
                <Arrow />
                <ColorOptions
                  tab={tabDropColor}
                  setTab={setTabDropColor}
                  setColor={setValues}
                  color={
                    valuesAnnouncement.stories[tabStory].colorTitle !== undefined
                      ? (valuesAnnouncement.stories[tabStory].colorTitle as string)
                      : colorDefaultToTitle
                  }
                  show={showToogleTitle}
                  setShow={setShowToogleTitle}
                  textToSave={`stories[${tabStory}].colorTitle`}
                />
              </ColorToogle>
            ) : (
              <></>
            )}
          </TextInputSection>
          <TextAreaSection>
            {!isEditing ? (
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <DefineTitle required={isEditing}>{t("pages.announcements.detailPage.rows.description")}</DefineTitle>
                  <SmallestColorBadges
                    backgroundColor={
                      valuesAnnouncementNotEditing.stories[tabStory].colorDescription
                        ? (valuesAnnouncementNotEditing.stories[tabStory].colorDescription as string)
                        : colorDefaultToDescription
                    }
                  />
                </div>
                <NotEditingText>
                  {valuesAnnouncementNotEditing.stories[tabStory].description
                    ? valuesAnnouncementNotEditing.stories[tabStory].description
                    : ""}
                </NotEditingText>
              </>
            ) : (
              <>
                <SmallColorBadges
                  backgroundColor={
                    valuesAnnouncement.stories[tabStory].colorDescription
                      ? (valuesAnnouncement.stories[tabStory].colorDescription as string)
                      : colorDefaultToDescription
                  }
                  onClick={() => setShowToogleDesc(!showToogleDesc)}
                />
                <TextArea
                  //@ts-ignore
                  title={<DefineTextArea>{t("pages.announcements.detailPage.rows.description")}</DefineTextArea>}
                  style={{ color: "#333333" }}
                  maxLength={40}
                  placeholder={t("pages.announcements.detailPage.rows.placeholderDescription")}
                  value={
                    valuesAnnouncement.stories[tabStory].description
                      ? valuesAnnouncement.stories[tabStory].description
                      : ""
                  }
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setValues(`stories[${tabStory}].description`, e.target.value);
                    valuesAnnouncement.stories[tabStory].description = e.target.value;
                    onChangeValues(valuesAnnouncement);
                  }}
                  //@ts-ignore
                  error={
                    errors.stories &&
                    errors.stories[tabStory] &&
                    (errors.stories[tabStory] as FormikErrors<IStoryInterface>).description ? (
                      <span style={{ marginRight: "35px" }}>
                        {(errors.stories[tabStory] as FormikErrors<IStoryInterface>).description}
                      </span>
                    ) : errors.stories &&
                      errors.stories[tabStory] &&
                      (errors.stories[tabStory] as FormikErrors<IStoryInterface>).colorDescription ? (
                      <span style={{ marginRight: "35px" }}>
                        {(errors.stories[tabStory] as FormikErrors<IStoryInterface>).colorDescription}
                      </span>
                    ) : (
                      undefined
                    )
                  }
                />
              </>
            )}
            {showToogleDesc ? (
              <ColorToogle>
                <Arrow />
                <ColorOptions
                  tab={tabDropColor}
                  setTab={setTabDropColor}
                  setColor={setValues}
                  color={
                    valuesAnnouncement.stories[tabStory].colorDescription !== undefined
                      ? (valuesAnnouncement.stories[tabStory].colorDescription as string)
                      : colorDefaultToDescription
                  }
                  show={showToogleDesc}
                  setShow={setShowToogleDesc}
                  textToSave={`stories[${tabStory}].colorDescription`}
                />
              </ColorToogle>
            ) : (
              <></>
            )}
          </TextAreaSection>
          <CallToActionSelection>{t("pages.announcements.detailPage.rows.callToActionHeader")}</CallToActionSelection>
          <CallToActionToogle isEditing={isEditing}>
            <Toggle
              key={`toggle${tabStory}-${isEditing}-${removedStory}`}
              cb={(newValue: boolean) => {
                setShowCallAction(newValue);
                setValues(`stories[${tabStory}].callAction`, newValue);
                setValues(`stories[${tabStory}].callToAction`, { action: {} });
              }}
              isDisabled={!isEditing}
              initialValue={
                isEditing
                  ? valuesAnnouncement.stories[tabStory].callAction
                    ? valuesAnnouncement.stories[tabStory].callAction
                    : false
                  : valuesAnnouncementNotEditing.stories[tabStory].callAction
                  ? valuesAnnouncementNotEditing.stories[tabStory].callAction
                  : false
              }
            />
            <SpanForm>{t("pages.announcements.detailPage.rows.callToActionCheck")}</SpanForm>
          </CallToActionToogle>
          {showCallAction ? (
            <SectionFormRow>
              <CallTitleSection>
                {!isEditing ? (
                  <>
                    <DefineTitle required={isEditing}>
                      {t("pages.announcements.detailPage.rows.callToActionTitle")}
                    </DefineTitle>
                    <NotEditingText>
                      {valuesAnnouncementNotEditing.stories[tabStory].callToAction.title
                        ? valuesAnnouncementNotEditing.stories[tabStory].callToAction.title
                        : ""}
                    </NotEditingText>
                  </>
                ) : (
                  <TextInput
                    required={true}
                    maxLength={25}
                    title={t("pages.announcements.detailPage.rows.callToActionTitle")}
                    placeholder={t("pages.announcements.detailPage.rows.callToActionCheck")}
                    value={
                      valuesAnnouncement.stories[tabStory].callToAction.title
                        ? valuesAnnouncement.stories[tabStory].callToAction.title
                        : ""
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setValues(`stories[${tabStory}].callToAction.title`, e.target.value);
                      onChangeValues(valuesAnnouncement);
                    }}
                    error={
                      errors.stories &&
                      errors.stories[tabStory] &&
                      ((errors.stories[tabStory] as FormikErrors<IStoryInterface>).callToAction as FormikErrors<
                        ICallToActionInterface
                      >) &&
                      ((errors.stories[tabStory] as FormikErrors<IStoryInterface>).callToAction as FormikErrors<
                        ICallToActionInterface
                      >).title
                        ? t("commons.mandatoryField")
                        : undefined
                    }
                  />
                )}
              </CallTitleSection>
              <DefineActionSection>
                <DefineTitle required={isEditing}>
                  {t("pages.announcements.detailPage.rows.defineCallAction")}
                </DefineTitle>
                {!isEditing ? (
                  <NotEditingText>
                    {valuesAnnouncementNotEditing.stories[tabStory].callToAction.action.type
                      ? t(
                          `pages.announcements.actionEnum.${valuesAnnouncementNotEditing.stories[tabStory].callToAction.action.type}`,
                        )
                      : ""}
                  </NotEditingText>
                ) : (
                  <AutoClosingDropdown
                    options={getCallToAction()}
                    selectOption={(opt: SharedDropdownOption) => {
                      setValues(`stories[${tabStory}].callToAction.action.resource`, "");
                      setValues(`stories[${tabStory}].callToAction.action.type`, opt.key);
                      onChangeValues(valuesAnnouncement);
                    }}
                    label={
                      valuesAnnouncement.stories[tabStory] &&
                      valuesAnnouncement.stories[tabStory].callToAction.action.type
                        ? t(
                            `pages.announcements.actionEnum.${valuesAnnouncement.stories[tabStory].callToAction.action.type}`,
                          )
                        : t("pages.announcements.detailPage.rows.selectCallToAction")
                    }
                    dropdownType={DropdownType.RECTANGULAR_NORMAL}
                    hasValue={false}
                    error={
                      errors.stories &&
                      errors.stories[tabStory] &&
                      ((errors.stories[tabStory] as FormikErrors<IStoryInterface>).callToAction as FormikErrors<
                        ICallToActionInterface
                      >) &&
                      ((errors.stories[tabStory] as FormikErrors<IStoryInterface>).callToAction as FormikErrors<
                        ICallToActionInterface
                      >).action &&
                      (((errors.stories[tabStory] as FormikErrors<IStoryInterface>).callToAction as FormikErrors<
                        ICallToActionInterface
                      >).action as FormikErrors<IActionInterface>).type
                        ? (((errors.stories[tabStory] as FormikErrors<IStoryInterface>).callToAction as FormikErrors<
                            ICallToActionInterface
                          >).action as FormikErrors<IActionInterface>).type
                        : undefined
                    }
                  />
                )}
              </DefineActionSection>
            </SectionFormRow>
          ) : (
            <></>
          )}
          {valuesAnnouncement.stories[tabStory].callToAction.action.type === CallToActionType.LINK && showCallAction ? (
            <TextInputSection>
              {!isEditing ? (
                <>
                  <DefineTitle required={isEditing}>{t("pages.announcements.detailPage.rows.linkUrl")}</DefineTitle>
                  <NotEditingText>
                    {valuesAnnouncementNotEditing.stories[tabStory].callToAction.action.resource
                      ? valuesAnnouncementNotEditing.stories[tabStory].callToAction.action.resource
                      : ""}
                  </NotEditingText>
                </>
              ) : (
                <TextInput
                  required={true}
                  title={t("pages.announcements.detailPage.rows.linkUrl")}
                  placeholder={t("pages.announcements.detailPage.rows.placeholderLinkUrl")}
                  value={
                    valuesAnnouncement.stories[tabStory].callToAction.action.resource
                      ? valuesAnnouncement.stories[tabStory].callToAction.action.resource
                      : ""
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setValues(`stories[${tabStory}].callToAction.action.resource`, e.target.value);
                    onChangeValues(valuesAnnouncement);
                  }}
                  error={
                    errors.stories &&
                    errors.stories[tabStory] &&
                    ((errors.stories[tabStory] as FormikErrors<IStoryInterface>).callToAction as FormikErrors<
                      ICallToActionInterface
                    >) &&
                    ((errors.stories[tabStory] as FormikErrors<IStoryInterface>).callToAction as FormikErrors<
                      ICallToActionInterface
                    >).action &&
                    (((errors.stories[tabStory] as FormikErrors<IStoryInterface>).callToAction as FormikErrors<
                      ICallToActionInterface
                    >).action as FormikErrors<IActionInterface>).resource
                      ? (((errors.stories[tabStory] as FormikErrors<IStoryInterface>).callToAction as FormikErrors<
                          ICallToActionInterface
                        >).action as FormikErrors<IActionInterface>).resource
                      : undefined
                  }
                />
              )}
            </TextInputSection>
          ) : valuesAnnouncement.stories[tabStory].callToAction.action.type === CallToActionType.FEATURE &&
            showCallAction ? (
            <SectionFormRow>
              <CallTitleSection>
                <DefineTitle required={isEditing}>{t("pages.announcements.detailPage.rows.feature")}</DefineTitle>
                {!isEditing ? (
                  <NotEditingText>
                    {valuesAnnouncementNotEditing.stories[tabStory].callToAction.action.resource
                      ? getFeatureDescription(
                          valuesAnnouncementNotEditing.stories[tabStory].callToAction.action.resource,
                        )
                      : ""}
                  </NotEditingText>
                ) : (
                  <AutoClosingDropdown
                    options={getFeature()}
                    selectOption={(opt: SharedDropdownOption) => {
                      setValues(`stories[${tabStory}].callToAction.action.resource`, opt.key);
                      valuesAnnouncement.stories[tabStory].callToAction.action.resource = opt.key;
                      onChangeValues(valuesAnnouncement);
                    }}
                    label={
                      valuesAnnouncement.stories[tabStory].callToAction.action.resource &&
                      callToActionOptions &&
                      callToActionOptions.FEATURE &&
                      callToActionOptions.FEATURE.values
                        ? (callToActionOptions.FEATURE.values.find(
                            value => value.name === valuesAnnouncement.stories[tabStory].callToAction.action.resource,
                          ) as IValueOption)?.description
                        : t("pages.announcements.detailPage.rows.selectCallToAction")
                    }
                    dropdownType={DropdownType.RECTANGULAR_NORMAL}
                    hasValue={false}
                    error={
                      errors.stories &&
                      errors.stories[tabStory] &&
                      ((errors.stories[tabStory] as FormikErrors<IStoryInterface>).callToAction as FormikErrors<
                        ICallToActionInterface
                      >) &&
                      ((errors.stories[tabStory] as FormikErrors<IStoryInterface>).callToAction as FormikErrors<
                        ICallToActionInterface
                      >).action &&
                      (((errors.stories[tabStory] as FormikErrors<IStoryInterface>).callToAction as FormikErrors<
                        ICallToActionInterface
                      >).action as FormikErrors<IActionInterface>).resource
                        ? t("commons.mandatoryField")
                        : undefined
                    }
                  />
                )}
              </CallTitleSection>
            </SectionFormRow>
          ) : valuesAnnouncement.stories[tabStory].callToAction.action.type === CallToActionType.MINIAPP &&
            showCallAction ? (
            <SectionFormRow>
              <CallTitleSection>
                {!isEditing ? (
                  <>
                    <DefineTitle required={isEditing}>{t("pages.announcements.detailPage.rows.miniApps")}</DefineTitle>
                    <NotEditingText>
                      {valuesAnnouncementNotEditing.stories[tabStory].callToAction.action.resource
                        ? valuesAnnouncementNotEditing.stories[tabStory].callToAction.action.resource
                        : ""}
                    </NotEditingText>
                  </>
                ) : (
                  <TextInput
                    required={true}
                    title={t("pages.announcements.detailPage.rows.miniApps")}
                    placeholder={t("pages.announcements.detailPage.rows.placeholderMiniApp")}
                    value={
                      valuesAnnouncement.stories[tabStory].callToAction.action.resource
                        ? valuesAnnouncement.stories[tabStory].callToAction.action.resource
                        : ""
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setValues(`stories[${tabStory}].callToAction.action.resource`, e.target.value);
                      onChangeValues(valuesAnnouncement);
                    }}
                    error={
                      errors.stories &&
                      errors.stories[tabStory] &&
                      ((errors.stories[tabStory] as FormikErrors<IStoryInterface>).callToAction as FormikErrors<
                        ICallToActionInterface
                      >) &&
                      ((errors.stories[tabStory] as FormikErrors<IStoryInterface>).callToAction as FormikErrors<
                        ICallToActionInterface
                      >).action &&
                      (((errors.stories[tabStory] as FormikErrors<IStoryInterface>).callToAction as FormikErrors<
                        ICallToActionInterface
                      >).action as FormikErrors<IActionInterface>).resource
                        ? t("commons.mandatoryField")
                        : undefined
                    }
                  />
                )}
              </CallTitleSection>
            </SectionFormRow>
          ) : (
            <></>
          )}
          {isEditing ? (
            <SectionFormRowButtons>
              <RemoveStorySection>
                <FastActionWrapper isDisabled={valuesAnnouncement.stories.length === 1}>
                  <FastActionButton
                    label={t("pages.announcements.detailPage.rows.removeStory")}
                    onClick={removeStoryTab}
                    type="button"
                    iconComponent={
                      <div
                        style={{
                          stroke: "#ff2a58",
                          width: 16,
                          height: 16,
                        }}
                      >
                        <CancelIcon />
                      </div>
                    }
                  />
                </FastActionWrapper>
              </RemoveStorySection>
              <AddStorySection>
                <FastActionWrapper
                  isDisabled={storySelected === maxStorys - 1 || valuesAnnouncement.stories.length === maxStorys}
                >
                  <FastActionButton
                    label={t("pages.announcements.detailPage.rows.addStory")}
                    onClick={addStoryTab}
                    type="button"
                    iconComponent={
                      <div
                        style={{
                          stroke: "#2fc56d",
                          width: 16,
                          height: 16,
                        }}
                      >
                        <PlusIcon />
                      </div>
                    }
                  />
                </FastActionWrapper>
              </AddStorySection>
              <LabelErrorSection>
                {reachMaxStorys ? t("pages.announcements.detailPage.rows.maxStories") : ""}
              </LabelErrorSection>
            </SectionFormRowButtons>
          ) : (
            <></>
          )}
        </SectionForm>
      </AnnouncementsMain>
      <MainSideBarColumn detailPage={detailsPage}>
        <div>
          <MobileSchema>
            <StoryPreview values={valuesAnnouncement} tabStory={tabStory} />
          </MobileSchema>
        </div>
      </MainSideBarColumn>
      {!detailsPage ? null : (
        <DivSidemenu detailPage={detailsPage}>
          {valuesAnnouncementNotEditing.status === StatusType.LIVE ||
          valuesAnnouncementNotEditing.status === StatusType.EXPIRED ? (
            <></>
          ) : (
            <SideMenuDetails
              isEditing={isEditing}
              setEditing={setEditing}
              textEditing={t("pages.announcements.detailPage.sidemenu.storyDetails")}
              form={AnnouncementFormSteps.STEP_FOUR}
              onCancelSideMenu={() => {
                if (selectedAnnouncementSavedData) {
                  setAnnoucement(selectedAnnouncementSavedData);
                  const storiesLength = selectedAnnouncementSavedData?.stories.length;
                  const storiesLengthNew = valuesAnnouncement?.stories.length;
                  const lastStoryIndex = storySelected - (storiesLengthNew - storiesLength);
                  setTabStory(lastStoryIndex);
                  if (lastStoryIndex < 3) {
                    setReachMaxStorys(false);
                  }
                  process.nextTick(() =>
                    ((document.querySelector("#tabs>div>div>div") as HTMLElement).childNodes[
                      lastStoryIndex
                    ] as HTMLElement).click(),
                  );
                }
              }}
            />
          )}
        </DivSidemenu>
      )}
    </AnnouncementsMainContent>
  );
};

export default AnnouncementStorys;

const AnnouncementsMain = styled("div")<{ detailPage: boolean }>`
  min-width: 20%;
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const DivSidemenu = styled("div")<{ detailPage: boolean }>`
  min-width: 10%;
  width: ${props => (props.detailPage ? "30%" : "")};
  div {
    margin-top: 0;
  }
`;

const MainSideBarColumn = styled("div")<{ detailPage: boolean }>`
  display: flex;
  flex-direction: column;
  min-width: 30%;
  width: ${props => (props.detailPage ? "20%" : "40%")};
  > div {
    width: 300px;
    margin: auto;
    margin-top: 0px;
    > div > div {
      margin-left: 4.5%;
      margin-right: 4.5%;
      > div.hOffif {
        background-color: ${SafaricomTheme.palette.vodafoneRed};
      }
    }
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SectionForm = styled("div")`
  margin-right: 20%;
  @media (max-width: 1024px) {
    margin-right: 0;
  }
`;

const SectionFormRowButtons = styled("div")`
  margin-top: 3vh;
  display: flex;
`;

const SectionFormRow = styled("div")`
  margin-top: 3vh;
  display: flex;
  justify-content: space-between;
`;

const Arrow = styled("div")`
  left: 0;
  position: absolute;
  top: 7px;
  background-color: #fff;
  border-bottom: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
  transform: translate(-50%, 50%) rotate(45deg);
  height: 16px;
  width: 16px;
`;

const RemoveStorySection = styled("div")`
  margin-bottom: 3vh;
  margin-right: 12px;
`;

const AddStorySection = styled("div")`
  margin-bottom: 3vh;
  margin-right: 12px;
`;

const LabelErrorSection = styled("div")`
  flex: 0.52;
  font-family: Vodafone Rg;
  font-size: 14px;
  color: #ff2a58;
  padding-top: 0.5vh;
`;

const FastActionWrapper = styled("div")<{ isDisabled?: boolean }>`
  min-width: max-content;
  ${props =>
    props.isDisabled
      ? "button { pointer-events: none; opacity:0.5;background-color: #ffffff;}:hover{cursor:not-allowed;}"
      : null}

  margin-bottom: 100px;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

const DropzoneTitle = styled("div")<{ mandatory: boolean }>`
  min-height: 21px;
  font-family: Vodafone Rg;
  font-weight: bold;
  line-height: 21px;
  font-size: 16px;
  color: #999999;
  margin-bottom: 8px;
  width: fit-content;
  display: inline-flex;
  ${props =>
    props.mandatory ? "::after {content: ' *'; color: #ff0000; font-weight: 400; padding-left: 2px;}" : null};
`;

const CallToActionToogle = styled("div")<{ isEditing: boolean }>`
  margin-top: 3vh;
  margin-bottom: 3vh;
  ${props => (!props.isEditing ? "label{background-color: white;opacity: 0.5;}" : null)};
`;

const CallTitleSection = styled("div")`
  flex: 0.6;
`;
const DefineActionSection = styled("div")`
  flex: 0.35;
`;

const TextAreaSection = styled("div")`
  margin-top: 3vh;
  font-family: Vodafone RgBd;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #999;
  position: relative;
  > div > span > span:last-child {
    color: #ff2a58;
    margin-left: 0.2vw;
    font-weight: normal;
  }
`;

const TextInputSection = styled("div")`
  position: relative;
  margin-top: 3vh;
  font-family: Vodafone RgBd;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #999;

  input {
    ::placeholder {
      color: #999999;
    }
  }
`;

const DefineTitle = styled("div")<{ required: boolean }>`
  min-height: 21px;
  font-family: Vodafone Rg;
  font-weight: bold;
  line-height: 21px;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
  margin-bottom: 8px;
  ${props => (props.required ? `::after {content: " *";color: #ff0000;font-weight: 400;padding-left: 2px;}` : null)};
`;

const ImageDiv = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ImageWrapper = styled("div")<{
  backgroundImage: string;
  isEditing: boolean;
}>`
    position: relative;
    width: ${props => (props.isEditing ? "56" : "90")}px;
    height: ${props => (props.isEditing ? "56" : "90")}px;
    background-image: url("${props => props.backgroundImage}");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 3px;
    flex: ${props => (props.isEditing ? "0.08" : "0.125")};
    >a{
      position: absolute;
      background: #aaa;
      z-index: 1;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      right: -0.5vw;
      top: -0.5vh;
      display:${props => (props.isEditing ? "flex" : "none")};
      justify-content: center;
      align-items: center;
      cursor: pointer;
      svg{
        stroke: #fff;
        width: 15px;
        height: 15px;
      }
    }
`;

const DescriptionImage = styled("div")<{
  isEditing: boolean;
}>`
  flex: ${props => (props.isEditing ? "0.88" : "0.835")};
  align-self: center;
`;

const NameImage = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 4px;
`;
const SizeImage = styled("div")`
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #afafaf;
`;

const SmallColorBadges = styled("div")<{ backgroundColor: string }>`
  width: 24px;
  height: 24px;
  border: 1px solid #ebebeb;
  background-color: ${props => props.backgroundColor};
  border-radius: 50%;
  position: absolute;
  right: 0;
  cursor: pointer;
`;

const SmallestColorBadges = styled("div")<{ backgroundColor: string }>`
  width: 20px;
  height: 20px;
  border: 1px solid #ebebeb;
  background-color: ${props => props.backgroundColor};
  border-radius: 50%;
  margin-left: 0.5vw;
`;

const ColorToogle = styled("div")`
  position: absolute;
  top: -20%;
  right: -37%;
  border: 1px solid #ebebeb;
  border-radius: 0.5vw;
  width: 35%;
  background-color: #fff;
`;

const DivCallActionOption = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const NotEditingText = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: ${props => props.theme.palette.darkGrey};
`;

const DefineTextArea = styled("span")`
  min-height: 21px;
  font-family: Vodafone Rg;
  font-weight: bold;
  line-height: 21px;
  font-size: 16px;
  color: #999999;
  margin-bottom: 8px;
`;

const ErrorUploadImages = styled("div")`
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  flex: 0.08;
  align-items: center;
  justify-content: center;
  svg {
    width: 32px;
    height: 32px;
    stroke: ${props => props.theme.palette.errorRed};
  }
`;

const DescriptionErrorImage = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 0.9;
  justify-content: center;
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: ${props => props.theme.palette.errorRed};
`;

const ImageInfo = styled("div")<{ isEditing: boolean; hasValue: boolean }>`
  display: ${props => (!props.isEditing || props.hasValue ? "none" : "flex")};
  flex-direction: column;
`;

const ImageInfoTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${props => props.theme.palette.midGrey};
`;

const ImageInfoDescription = styled("span")`
  font-family: Vodafone Rg;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${props => props.theme.palette.midGrey};
`;
