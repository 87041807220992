import { AxiosPromise } from "axios";
import ApiClient from "../../../configs/axios.config";
import {
  IAddBundleRequest,
  IAddSubBundleRequest,
  IBundleType,
  IEditBundleRequest,
  IEditSubBundleRequest,
  ISubBundle,
  IAddMiddlewareSubBundleRequest,
  IMiddlewareSubBundle,
  IEditMiddlewareSubBundleRequest,
} from "./bundles.model";

/*
 * Bundles API methods
 * */
const BundlesApi = {
  routes: {
    getProviders: "/bundles/providers",
    getBundles: "/bundles/types",
    deleteBundle: "/bundles/types/:id",
    addBundle: "/bundles/types",
    editBundle: "/bundles/types/:id",
    getBundleDetails: "/bundles/types/:id",
    getConfigurationFields: "/bundles/configuration/fields",
    currencies: "currencies",
    addSubBundle: "/bundles/types/:bundleId/items/",
    deleteSubBundle: "/bundles/types/:bundleId/items/:subBundleId",
    editSubBundle: "/bundles/types/:bundleId/items/:subBundleId",
    restoreSubBundle: "/bundles/types/:bundleId/items/:subBundleId/restore",
    revertBundle: "/bundles/types/:bundleId/revert",
    getPreviousVersion: "/bundles/types/:bundleId?previousVersion=true",
    publish: "/bundles/types/:bundleId/publish",
    sortBundles: "/bundles/types/reorder",
  },
  methods: {
    getProviders: (): AxiosPromise => {
      return ApiClient.get(BundlesApi.routes.getProviders, {});
    },
    getBundles: (): AxiosPromise => {
      return ApiClient.get(BundlesApi.routes.getBundles, {});
    },
    deleteBundle: (id: string): AxiosPromise => {
      return ApiClient.delete(BundlesApi.routes.deleteBundle.replace(":id", id), {});
    },
    addBundle: (bundle: IAddBundleRequest): AxiosPromise<IBundleType> => {
      return ApiClient.post(BundlesApi.routes.addBundle, bundle);
    },
    editBundle: (bundle: IEditBundleRequest): AxiosPromise<IBundleType> => {
      return ApiClient.put(BundlesApi.routes.editBundle.replace(":id", bundle.id), bundle);
    },
    getBundleDetails: (id: string): AxiosPromise => {
      return ApiClient.get(BundlesApi.routes.getBundleDetails.replace(":id", id), {});
    },
    getConfigurationFields: (): AxiosPromise => {
      return ApiClient.get(BundlesApi.routes.getConfigurationFields, {});
    },
    getCurrencies: (): AxiosPromise => {
      return ApiClient.get(BundlesApi.routes.currencies, {});
    },
    deleteSubBundle: (bundleId: string, subBundleId: number): AxiosPromise => {
      return ApiClient.delete(
        BundlesApi.routes.deleteSubBundle.replace(":bundleId", bundleId).replace(":subBundleId", `${subBundleId}`),
        {},
      );
    },
    restoreSubBundle: (bundleId: string, subBundleId: number): AxiosPromise => {
      return ApiClient.put(
        BundlesApi.routes.restoreSubBundle.replace(":bundleId", bundleId).replace(":subBundleId", `${subBundleId}`),
        {},
      );
    },
    addSubBundle: (
      subBundle: IAddSubBundleRequest | IAddMiddlewareSubBundleRequest,
      bundleId: string,
    ): AxiosPromise<ISubBundle> => {
      return ApiClient.post(BundlesApi.routes.addSubBundle.replace(":bundleId", bundleId), subBundle);
    },
    editSubBundle: (
      subBundle: IEditSubBundleRequest | IEditMiddlewareSubBundleRequest,
      bundleId: string,
      subBundleId: number,
    ): AxiosPromise<ISubBundle | IMiddlewareSubBundle> => {
      return ApiClient.put(
        BundlesApi.routes.editSubBundle.replace(":bundleId", bundleId).replace(":subBundleId", `${subBundleId}`),
        subBundle,
      );
    },
    revertBundle: (bundleId: string): AxiosPromise => {
      return ApiClient.put(BundlesApi.routes.revertBundle.replace(":bundleId", bundleId), {});
    },
    getPreviousVersion: (bundleId: string): AxiosPromise => {
      return ApiClient.get(BundlesApi.routes.getPreviousVersion.replace(":bundleId", bundleId), {});
    },
    publish: (bundleId: string): AxiosPromise => {
      return ApiClient.put(BundlesApi.routes.publish.replace(":bundleId", bundleId), {});
    },
    sortBundles: (bundles: string[]): AxiosPromise<ISubBundle> => {
      return ApiClient.put(BundlesApi.routes.sortBundles, bundles);
    },
  },
};

export default BundlesApi;
