import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  isCustomerSurveyTabActive,
  isFeedbackTabActive,
  isProblemReportsTabActive,
} from "../feedback-and-problems.utils";
import { IStoreInterface } from "../../../../configs/store.config";
import { ITableResultList } from "../feedback-and-problems.model";

/**
 * Custom Hook - useResults
 * [!important] This hook can be used within
 * feedback tab components, problem reports tab components, customer satisfaction survey tab
 * @param sectionId number
 * @returns results
 */
export function useFeedbackAndProblemsResults(sectionId: number) {
  const { feedbacks, problemReports, customerSurvey } = useSelector(
    (state: IStoreInterface) => state.feedbackAndProblemsReportReducer,
  );

  const results = useMemo(() => {
    if (isFeedbackTabActive(sectionId)) {
      if (feedbacks && feedbacks.list) {
        return feedbacks.list as ITableResultList[];
      }
    }
    if (isProblemReportsTabActive(sectionId)) {
      if (problemReports && problemReports.list) {
        return problemReports.list as ITableResultList[];
      }
    }
    if (isCustomerSurveyTabActive(sectionId)) {
      if (customerSurvey && customerSurvey.list) {
        return customerSurvey.list as ITableResultList[];
      }
    }
    return [];
  }, [feedbacks, problemReports, customerSurvey]);

  return results;
}
