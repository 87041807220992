import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Formik, FormikHelpers } from "formik";
import { Row } from "../../../../shared/shared.styled";
import { TextInput, PrimaryButton, useAlert, Checkbox } from "@wit/mpesa-ui-components";
import { IEnvProperty } from "../../../../shared/models/env-property.model";
import { object, string } from "yup";
import i18next from "i18next";
import { SupportActions } from "../../support.store";
import SupportApi from "../../support.api";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { useDispatch } from "react-redux";

const getValidationSchema = () => {
  return object().shape({
    propertyName: string().required(i18next.t("pages.support.envProperties.newProperty.error")),
    propertyValue: string().required(i18next.t("pages.support.envProperties.newProperty.error")),
  });
};

/**
 * New Property Section Component
 * @returns {JSX.Element}
 */
const NewPropertySection = () => {
  // Hooks initialization
  const [t] = useTranslation();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const dispatch = useDispatch();

  /**
   * Function that allows the user to save a new property.
   * This functions sets the formik in the loading state, calls the API request, and then updates the redux store in case of success.
   * Shows a SUCCESS alert if the request is sucessful, or a ERROR alert in case something bad happened
   * @param values EnvProperty
   * @param actions FormikHelpers<EnvProperty>
   */
  function persistNewProperty(values: IEnvProperty, actions: FormikHelpers<IEnvProperty>) {
    actions.setSubmitting(true);
    SupportApi.methods
      .addProperty(values)
      .finally(() => actions.setSubmitting(false))
      .then(
        () => {
          dispatch(SupportActions.Creators.addPropertyAction(values));
          actions.resetForm();
          setAlertProps({
            title: t("pages.support.envProperties.alerts.createProperty.success"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
        },
        err => {
          setAlertProps({
            title:
              err && err.data && err.data.status && err.data.status.message
                ? err.data.status.message
                : t("pages.support.envProperties.alerts.createProperty.error"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
  }

  return (
    <NewPropertySectionContainer>
      <NewPropertySectionTitle>{t("pages.support.envProperties.newProperty.title")}</NewPropertySectionTitle>
      <Formik
        onSubmit={persistNewProperty as any}
        initialValues={{ propertyName: "", propertyValue: "", propertyType: "PLAIN_TEXT" }}
        validationSchema={getValidationSchema()}
        render={({ handleChange, values, handleSubmit, errors, isSubmitting, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Row
              style={{
                gap: "32px",
                alignItems: "flex-end",
                flexWrap: "wrap",
              }}
            >
              <FieldSection style={{ flex: "1 1 20%" }}>
                <TextInput
                  id="propertyName"
                  title={t("pages.support.envProperties.newProperty.propertyName.label")}
                  placeholder={t("pages.support.envProperties.newProperty.propertyName.placeholder")}
                  required
                  name="propertyName"
                  onChange={handleChange}
                  value={values.propertyName}
                  error={errors.propertyName}
                  autoComplete="off"
                />
              </FieldSection>
              <FieldSection style={{ flex: "1 1 20%", marginRight: 120 }}>
                <TextInput
                  id="propertyValue"
                  title={t("pages.support.envProperties.newProperty.propertyValue.label")}
                  placeholder={t("pages.support.envProperties.newProperty.propertyValue.placeholder")}
                  required
                  name="propertyValue"
                  onChange={handleChange}
                  value={values.propertyValue}
                  error={errors.propertyValue}
                  autoComplete="off"
                />
              </FieldSection>
              <FieldSection style={{ flex: "1 1 20%", display: "flex", flexDirection: "row", paddingBottom: "8px" }}>
                <Checkbox
                  id="encryptedValue"
                  value={values.propertyType !== "PLAIN_TEXT"}
                  onValueChange={e => {
                    if (e) {
                      setFieldValue("propertyType", "TO_ENCRYPT");
                    } else {
                      setFieldValue("propertyType", "PLAIN_TEXT");
                    }
                  }}
                ></Checkbox>
                <Label>{t("pages.support.envProperties.modals.editPropertyModal.encryptedValue.label")}</Label>
              </FieldSection>
              <ButtonContainer>
                <PrimaryButton
                  id="addNewProperty"
                  loading={isSubmitting}
                  titleLabel={t("pages.support.envProperties.newProperty.buttonLabel")}
                  type="submit"
                  redTheme={true}
                  style={{ width: "fit-content" }}
                />
              </ButtonContainer>
            </Row>
          </form>
        )}
      />
    </NewPropertySectionContainer>
  );
};

export default NewPropertySection;

const NewPropertySectionContainer = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 29px 47px;
  margin: 20px -47px 24px -47px;
  background-color: ${props => props.theme.palette.greyLight};
`;

const NewPropertySectionTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.4px;
  color: ${props => props.theme.palette.midGrey};
  text-transform: uppercase;
  margin-bottom: 21px;
`;

const FieldSection = styled("div")``;

const ButtonContainer = styled("div")`
  width: fit-content;
  @media (max-width: 1024px) {
    flex: 1 1 20%;
  }
`;

const Label = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
  margin-bottom: 0;
  margin-left: 10px;
`;
