import React from "react";
import styled from "styled-components";
import { Row } from "./../../../shared/shared.styled";
import { SearchBar, MultipleOptionsDropdown } from "@wit/mpesa-ui-components";
import { useTranslation } from "react-i18next";
import { getServiceManagerServiceStatus } from "../../../shared/shared.utils";
import { FilterFuncts } from "@wit/mpesa-ui-components/lib/hooks/use-filters.hook";
import { SelectedView } from "../../../shared/shared.enums";

export enum ServiceManagerFiltersEnum {
  SEARCH = "search",
  STATUS = "status",
}

interface IServicesFiltersProps extends FilterFuncts {
  selectedView: SelectedView;
  setSelectedView: (selectedView: SelectedView) => any;
}

/**
 *
 * Service manager filters
 * @param param0
 * @returns
 */
const ServiceManagerFilters = ({ filters, clearFilter, isFilterActive, updateFilter }: IServicesFiltersProps) => {
  // Hook initializations
  const [t] = useTranslation();

  /**
   * This funtion retrieve labels for the services items for labeling purposes
   * @param itemsList
   * @param path
   * @returns array of labels
   */
  const getServiceTypeDropdownLabel = (itemsList: string[], path: string) => {
    return itemsList.map((item: string) => t(`${path}.${item}`));
  };

  /**
   * Retrieve the values used to compose the label regarding ServiceManagerServiceStatusSearchEnum
   * @param itemsList
   * @returns array of allowed items to compose the label
   */
  const getServiceManagerServiceStatusSearchEnumForLabel = (itemsList: string[]) => {
    return itemsList.filter(item => getServiceManagerServiceStatus().find(itemList => itemList.key === item));
  };

  return (
    <ServicesFilterContainer>
      <div style={{ flex: 4 / 12, marginRight: 24 }}>
        <SearchBar
          placeholderLabel={t("pages.serviceBuilder.filters.searchPlaceholder")}
          value={
            filters.has(ServiceManagerFiltersEnum.SEARCH)
              ? (filters.get(ServiceManagerFiltersEnum.SEARCH) as string[])[0]
              : ""
          }
          onChange={e => updateFilter(ServiceManagerFiltersEnum.SEARCH, e.target.value, true)}
          clearValue={() => clearFilter(ServiceManagerFiltersEnum.SEARCH)}
        />
      </div>
      <div style={{ flex: 2 / 12, marginRight: 24 }} id={"status-dropdown"}>
        <MultipleOptionsDropdown
          label={
            filters.has(ServiceManagerFiltersEnum.STATUS)
              ? `${getServiceTypeDropdownLabel(
                  getServiceManagerServiceStatusSearchEnumForLabel(
                    filters.get(ServiceManagerFiltersEnum.STATUS) as string[],
                  ),
                  "commons.serviceBuilder.enums.status",
                )}`
              : t("pages.serviceBuilder.filters.statusDropdownPlaceholder")
          }
          options={getServiceManagerServiceStatus()}
          toggleOption={val => updateFilter(ServiceManagerFiltersEnum.STATUS, val.key)}
          hasValue={filters.has(ServiceManagerFiltersEnum.STATUS)}
          isOptionSelected={val => isFilterActive(ServiceManagerFiltersEnum.STATUS, val.key)}
          clearAllFilters={() => clearFilter(ServiceManagerFiltersEnum.STATUS)}
        />
      </div>
    </ServicesFilterContainer>
  );
};

const ServicesFilterContainer = styled(Row)`
  margin-bottom: 49px;
  margin-top: 41px;
`;

export default ServiceManagerFilters;
