import { AxiosPromise } from "axios";
import ApiClient from "../../configs/axios.config";
import { IApp } from "../../shared/models/app.model";
import { ICreateReleaseVersionInterface, IAppsStatus } from "./apps.interfaces";

const AppsApi = {
  routes: {
    app: "/v1/apps/:appId",
    apps: "/v1/apps?type=APP",
    appReleases: "/v1/apps/:appId?releases=true",
    exportApp: "/apps/:appId/exportApp",
    deleteApp: "/apps/:appId",
    createReleaseVersion: "/v1/apps/:appId/releases/version",
    getLastVersion: "/v1/apps/:appId/releases?previousVersion=true",
    revertToLastReleaseVersion: "/v1/apps/:appId/releases/revert",
    publishReleaseVersion: "/v1/apps/:appId/releases/publish",
    deleteReleaseVersion: "/v1/apps/:appId/releases/version/:id",
    mpesaAppsStatus: "/v1/mpesaStatus",
  },
  methods: {
    getApps: (): AxiosPromise<IApp[]> => {
      return ApiClient.get(AppsApi.routes.apps);
    },
    getApp: (id: string): AxiosPromise<IApp> => {
      return ApiClient.get(AppsApi.routes.app.replace(":appId", id));
    },
    // TODO: Change this any
    exportApp: (app: IApp): AxiosPromise<any> => {
      return ApiClient.get(AppsApi.routes.exportApp.replace(":appId", app.id));
    },
    deleteApp: (app: IApp): AxiosPromise<void> => {
      return ApiClient.delete(AppsApi.routes.deleteApp.replace(":appId", app.id));
    },
    getAppReleases: (id: string): AxiosPromise<any> => {
      return ApiClient.get(AppsApi.routes.appReleases.replace(":appId", id));
    },
    createReleaseVersion: (releaseVersion: ICreateReleaseVersionInterface, id: string): AxiosPromise<any> => {
      return ApiClient.post(AppsApi.routes.createReleaseVersion.replace(":appId", id), releaseVersion);
    },
    getLastVersion: (id: string): AxiosPromise<any> => {
      return ApiClient.get(AppsApi.routes.getLastVersion.replace(":appId", id), {
        params: {
          previousVersion: true,
        },
      });
    },
    revertToLastReleaseVersion: (id: string): AxiosPromise<any> => {
      return ApiClient.put(AppsApi.routes.revertToLastReleaseVersion.replace(":appId", id));
    },
    publishReleaseVersion: (id: string): AxiosPromise<any> => {
      return ApiClient.put(AppsApi.routes.publishReleaseVersion.replace(":appId", id));
    },
    deleteReleaseVersion: (appId: string, releaseVersionId: string): AxiosPromise<any> => {
      return ApiClient.delete(
        AppsApi.routes.deleteReleaseVersion.replace(":appId", appId).replace(":id", releaseVersionId),
      );
    },
    getMpesaAppsStatus: (): AxiosPromise<IAppsStatus> => {
      return ApiClient.get(AppsApi.routes.mpesaAppsStatus);
    },
    setMpesaAppsStatus: (status: boolean, reason?: string): AxiosPromise<IAppsStatus> => {
      return ApiClient.post(AppsApi.routes.mpesaAppsStatus, !status ? { status, reason } : { status, reason: "" });
    },
  },
};

export default AppsApi;
