import React from "react";
import { SearchBar } from "@wit/mpesa-ui-components";
import { RatioContainer } from "../../../../../shared/shared.styled";
import { onEnterPressed } from "../../../../../shared/shared.utils";

interface INumberSearch {
  searchLabel: string;
  searchString: string;
  onChangeFn: (value: string) => void;
  onKeyDownFn: () => void;
}

const searchInputTextHandler = (value: string) => {
  let val = value;
  if (value.indexOf("+") >= 1) {
    val = value.replace("+", "");
    val = `+${val}`;
  }
  return val.replace(/([^0-9+])/gi, "");
};

export const SearchByMsisdn = (props: INumberSearch) => {
  const setSearchString = props.onChangeFn;

  return (
    <RatioContainer ratio={7 / 10}>
      <SearchBar
        value={props.searchString}
        onChange={e => setSearchString(searchInputTextHandler(e.target.value))}
        placeholderLabel={props.searchLabel}
        maxLength={25}
        clearValue={() => props.onChangeFn("")}
        onKeyDown={e => {
          onEnterPressed(e, props.onKeyDownFn);
        }}
      />
    </RatioContainer>
  );
};

export default SearchByMsisdn;
