import { IServiceManagerServiceDetails } from "../../../shared/models/ant-service-manager.model";
import produce from "immer";
import { IPayloadAction } from "../../../shared/shared.interfaces";
import { AuthenticationActions } from "../../authentication/authentication.store";

export const AntServiceManagerServiceDetailsActions = {
  types: {
    FETCHING_ANT_SM_SERVICE_DETAILS: "FETCHING_ANT_SM_SERVICE_DETAILS",
    FETCH_ANT_SM_SERVICE_DETAILS_SUCCESS: "FETCH_ANT_SM_SERVICE_DETAILS_SUCCESS",
    FETCH_ANT_SM_SERVICE_DETAILS_ERROR: "FETCH_ANT_SM_SERVICE_DETAILS_ERROR",
    FETCH_ANT_SM_SERVICE_DETAILS_LEAVE: "FETCH_ANT_SM_SERVICE_DETAILS_LEAVE",
    UPDATE_ANT_SM_SERVICE_DETAILS: "UPDATE_ANT_SM_SERVICE_DETAILS",
    UPDATE_ANT_SM_SERVICE_STATUS: "UPDATE_ANT_SM_SERVICE_STATUS",
    UPDATE_ANT_SM_SERVICE_ADD_GROUP: "UPDATE_ANT_SM_SERVICE_ADD_GROUP",
    UPDATE_ANT_SM_SERVICE_REMOVE_GROUP: "UPDATE_ANT_SM_SERVICE_REMOVE_GROUP",
    DELETE_ANT_SM_SERVICE_IN_DETAILS: "DELETE_ANT_SM_SERVICE_IN_DETAILS",
    UPDATE_ANT_SM_SERVICE_IN_DETAILS: "UPDATE_ANT_SM_SERVICE_IN_DETAILS",
  },
  creators: {
    fetchingServiceDetailsAction: () => ({
      type: AntServiceManagerServiceDetailsActions.types.FETCHING_ANT_SM_SERVICE_DETAILS,
    }),
    fetchServiceDetailsSuccessAction: (serviceDetails: IServiceManagerServiceDetails) => ({
      type: AntServiceManagerServiceDetailsActions.types.FETCH_ANT_SM_SERVICE_DETAILS_SUCCESS,
      payload: {
        serviceDetails,
      },
    }),
    fetchingServiceDetailsLeaveAction: () => ({
      type: AntServiceManagerServiceDetailsActions.types.FETCH_ANT_SM_SERVICE_DETAILS_LEAVE,
    }),
    updateServiceManagerServiceDetails: (serviceId: string, values: any) => ({
      type: AntServiceManagerServiceDetailsActions.types.UPDATE_ANT_SM_SERVICE_DETAILS,
      payload: {
        serviceId,
        values,
      },
    }),
    updateServiceManagerServiceStatus: (serviceId: string) => ({
      type: AntServiceManagerServiceDetailsActions.types.UPDATE_ANT_SM_SERVICE_STATUS,
      payload: {
        serviceId,
      },
    }),
    updateServiceManagerAddGroup: (serviceId: string, values: any) => ({
      type: AntServiceManagerServiceDetailsActions.types.UPDATE_ANT_SM_SERVICE_ADD_GROUP,
      payload: {
        serviceId,
        values,
      },
    }),
    updateServiceManagerRemoveGroup: (serviceId: string, values: any) => ({
      type: AntServiceManagerServiceDetailsActions.types.UPDATE_ANT_SM_SERVICE_REMOVE_GROUP,
      payload: {
        serviceId,
        values,
      },
    }),
  },
};

export interface IAntServiceManagerServiceDetailsReducerInterface {
  serviceDetails: IServiceManagerServiceDetails;
  isLoadingServiceDetails: boolean;
}

const initialState: IAntServiceManagerServiceDetailsReducerInterface = {
  serviceDetails: {} as IServiceManagerServiceDetails,
  isLoadingServiceDetails: true,
};

export const antServiceManagerServiceDetailsReducer = produce(
  (draft: IAntServiceManagerServiceDetailsReducerInterface, action: IPayloadAction<any>) => {
    switch (action.type) {
      case AntServiceManagerServiceDetailsActions.types.FETCHING_ANT_SM_SERVICE_DETAILS:
        draft.isLoadingServiceDetails = true;
        return;
      case AntServiceManagerServiceDetailsActions.types.FETCH_ANT_SM_SERVICE_DETAILS_SUCCESS:
        draft.isLoadingServiceDetails = false;
        draft.serviceDetails = action.payload.serviceDetails;
        return;
      case AntServiceManagerServiceDetailsActions.types.FETCH_ANT_SM_SERVICE_DETAILS_ERROR:
        draft.isLoadingServiceDetails = false;
        return;
      case AntServiceManagerServiceDetailsActions.types.DELETE_ANT_SM_SERVICE_IN_DETAILS: {
        return;
      }
      case AntServiceManagerServiceDetailsActions.types.UPDATE_ANT_SM_SERVICE_IN_DETAILS:
      case AntServiceManagerServiceDetailsActions.types.UPDATE_ANT_SM_SERVICE_DETAILS:
      case AntServiceManagerServiceDetailsActions.types.UPDATE_ANT_SM_SERVICE_STATUS:
      case AntServiceManagerServiceDetailsActions.types.UPDATE_ANT_SM_SERVICE_ADD_GROUP:
      case AntServiceManagerServiceDetailsActions.types.UPDATE_ANT_SM_SERVICE_REMOVE_GROUP:
        draft.serviceDetails = {
          ...draft.serviceDetails,
          ...action.payload.serviceDetails,
        };
        return;
      case AuthenticationActions.types.LOGOUT:
      case AntServiceManagerServiceDetailsActions.types.FETCH_ANT_SM_SERVICE_DETAILS_LEAVE:
        return initialState;
      default:
        return draft;
    }
  },
  initialState,
);
