import { PrimaryButton } from "@wit/mpesa-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import useVersionsDiff, { VersionItem } from "../../../../../shared/hooks/use-version-diff/use-version-diff.hook";
import VersionItemStatusChip from "../../../../../shared/hooks/use-version-diff/version-item-status-chip.component";
import { Column, Row } from "../../../../../shared/shared.styled";
import { AppPropertyStatusEnum, IAppProperties, IAppProperty } from "../app-properties.model";

interface IPublishAppPropertiesProps {
  hideModal: () => void;
  publishChanges: () => void;
  isLoading: boolean;
  appProperties: IAppProperties;
  previousAppProperties: IAppProperties;
}

/**
 * Publish App properties
 * @param param0
 */
const PublishAppProperties = ({
  isLoading,
  appProperties,
  previousAppProperties,
  publishChanges,
  hideModal,
}: IPublishAppPropertiesProps) => {
  const [t] = useTranslation();
  const { prev, current } = useVersionsDiff(previousAppProperties!.properties, appProperties!.properties);

  /**
   * Publish request
   * */
  const publish = () => {
    publishChanges();
  };

  /**
   * Render row
   * @param appProp
   */
  const renderRow = (appProp: VersionItem<IAppProperty>) => {
    return (
      <Row key={appProp.id} style={{ marginBottom: "33px", alignItems: "flex-start" }}>
        <Column>
          <Label style={{ wordBreak: "break-word" }}>
            <b>{appProp.fieldName}</b>
            <VersionItemStatusChip status={appProp.versionItemStatus} />
          </Label>
          <Label style={{ wordBreak: "break-word" }}>
            {t("pages.appProperties.value")}: {appProp.value}
          </Label>
        </Column>
        <Column style={{ flexGrow: 1, alignItems: "flex-end", paddingTop: "5px" }}>
          <StatusText status={appProp.status as AppPropertyStatusEnum}>
            {t(`pages.appProperties.appPropertiesStatusEnum.${appProp.status}`)}
          </StatusText>
        </Column>
      </Row>
    );
  };

  return (
    <ModalContainer>
      <ModalTitle>{t("pages.appProperties.publishChanges")}</ModalTitle>
      <ModalSubtitle>{t("pages.appProperties.publishChangesModalTitle")}</ModalSubtitle>
      <DocumentContainer>
        <Row style={{ alignItems: "normal" }}>
          <Panel background={true}>
            <PanelTitle>{t("pages.appProperties.previousVersion")}</PanelTitle>
            {prev ? (
              <>
                {prev.map(appProp => {
                  return renderRow(appProp);
                })}
              </>
            ) : null}
          </Panel>
          <Panel>
            <PanelTitle>{t("pages.appProperties.editedVersion")}</PanelTitle>
            {current ? (
              <>
                {current.map(appProp => {
                  return renderRow(appProp);
                })}
              </>
            ) : null}
          </Panel>
        </Row>

        <Row
          style={{
            justifyContent: "flex-end",
            width: "100%",
            marginTop: "auto",
            paddingRight: "24px",
            overflow: "visible",
            paddingTop: "24px",
          }}
        >
          <div style={{ marginRight: "12px" }}>
            <PrimaryButton
              disabled={isLoading}
              onClick={hideModal}
              titleLabel={t("pages.appProperties.cancel")}
              id="cancel-button"
            />
          </div>
          <div>
            <PrimaryButton
              id="confirm-button"
              redTheme={true}
              type="submit"
              onClick={publish}
              disabled={isLoading}
              titleLabel={t("pages.appProperties.confirm")}
            />
          </div>
        </Row>
      </DocumentContainer>
    </ModalContainer>
  );
};

export default PublishAppProperties;

export const StatusText = styled("span")<{ status: AppPropertyStatusEnum }>`
  ${props => {
    let color = props.theme.palette.digitalGreen;
    switch (props.status) {
      case AppPropertyStatusEnum.LIVE:
        color = props.theme.palette.digitalGreen;
        break;
      case AppPropertyStatusEnum.DISABLE:
      case AppPropertyStatusEnum.DISABLED:
        color = props.theme.palette.freshOrange;
        break;
      case AppPropertyStatusEnum.ENABLE:
      case AppPropertyStatusEnum.EDIT:
        color = props.theme.palette.digitalGreen;
        break;
      case AppPropertyStatusEnum.DELETE:
        color = props.theme.palette.vodafoneRed;
        break;
    }
    return `color: ${color};display: flex;justify-content: flex-end;`;
  }}
`;

const ModalContainer = styled("div")`
  border-radius: 6px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  padding-top: 24px;
  padding-bottom: 24px;
`;

const ModalTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 13px;
  padding-left: 24px;
`;

const ModalSubtitle = styled("p")`
  font-family: Vodafone Rg;
  font-size: 18px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 24px;
  padding-left: 24px;
`;

const DocumentContainer = styled("div")`
  font-family: Vodafone Rg;
  max-height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const Panel = styled("div")<{ background?: boolean }>`
  font-family: Vodafone Rg;
  overflow: auto;
  display: flex;
  width: 50%;
  padding: 24px;
  background-color: ${props => (props.background ? props.theme.palette.lightGrey : props.theme.palette.white)};
  flex-direction: column;
  max-height: 300px;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  b,
  strong {
    margin: 0;
  }
`;

const PanelTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  margin-bottom: 12px;
`;

const Label = styled("span")`
  padding-top: 5px;
`;
