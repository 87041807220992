import { EditIcon, FastActionButton, TrashIcon } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { Breadcrumb } from "@wit/mpesa-ui-components/lib/types";
import i18next from "i18next";
import React from "react";
import { TFunction } from "react-i18next";
import { RoutesEnum } from "../../../routes/routes.constants";
import { ICurrency } from "../../../shared/shared.interfaces";
import { IconContainer } from "../../../shared/shared.styled";
import { TableButtonsContainer } from "../../../shared/shared.utils";
import { RateCardsFiltersEnum } from "./components/rate-cards-filters.component";
import { ChargeProfileStatusEnum, IChargeProfile } from "./rate-cards.model";

export const getColumns = (t: any, chargeProfile: IChargeProfile | undefined) => {
  return [
    {
      formKey: "rangeBegin",
      label: t("pages.rateCards.table.tierBegin"),
      isEditable: true,
      ratio: 1,
      changeFunction: (content: string) => {
        if (chargeProfile && chargeProfile.rangeValuesRequired) {
          return <span style={{ color: "#9c2aa0" }}>{content}</span>;
        } else {
          return <span></span>;
        }
      },
    },
    {
      formKey: "rangeEnd",
      label: t("pages.rateCards.table.tierEnd"),
      isEditable: true,
      ratio: 1,
      changeFunction: (content: string) => {
        if (chargeProfile && chargeProfile.rangeValuesRequired) {
          return <span style={{ color: "#9c2aa0" }}>{content}</span>;
        } else {
          return <span></span>;
        }
      },
    },
    {
      formKey: "rateValueType",
      label: t("pages.rateCards.table.rateValueType"),
      isEditable: true,
      ratio: 1,
      changeFunction: (content: string) => {
        return <span>{t(`pages.rateCards.rateValueTypes.${content}`)}</span>;
      },
    },
    {
      formKey: "rateValue",
      label: t("pages.rateCards.table.rateValue"),
      isEditable: true,
      ratio: 1,
      changeFunction: (content: string) => {
        return <span>{content}</span>;
      },
    },
  ];
};

/*
 * Tiers list quick actions
 * */
export const renderTiersActions = <T extends unknown>(
  value: T,
  buttonActions: {
    edit: { onClick: (row: T) => any };
    delete: { onClick: (row: T) => any };
    restore: { onClick: (row: T) => any };
  },
  themeContext: any,
) => {
  return (
    <TableButtonsContainer>
      <>
        <FastActionButton
          iconComponent={
            <IconContainer size={16} color={themeContext.palette.turquoiseBlue} id={"edit-tier"}>
              <EditIcon />
            </IconContainer>
          }
          onClick={() => buttonActions.edit.onClick(value)}
          label={i18next.t("pages.rateCards.edit")}
        />
        <FastActionButton
          iconComponent={
            <IconContainer size={16} color={themeContext.palette.errorRed} id={"delete-tier"}>
              <TrashIcon />
            </IconContainer>
          }
          onClick={() => buttonActions.delete.onClick(value)}
          label={i18next.t("pages.rateCards.delete")}
        />
      </>
    </TableButtonsContainer>
  );
};

const groupBy = (array: any[], key: string) => {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
    return result;
  }, {});
};

export const getOptions = (arr: any, key: string) => {
  return Object.keys(groupBy(arr, key)).map(e => {
    return {
      key: e,
      label: e,
    };
  });
};

export const getCurrenciesDropdown = (currencies: ICurrency[] | undefined, t: any) => {
  if (currencies) {
    return currencies.map((i: any) => {
      return {
        key: i,
        label: t(`commons.currencies.${i}`),
      };
    });
  } else {
    return [{ key: "", label: "" }];
  }
};

export const getBreadcrumbs = (t: TFunction): Breadcrumb[] => {
  return [
    {
      label: t("pages.configuration.title"),
      url: RoutesEnum.CONFIGS,
    },
    {
      label: t("pages.rateCards.rateCards"),
      url: "",
    },
  ];
};

export const filterChargeProfilesByChargeType = (
  chargeProfiles: IChargeProfile[],
  chargeType?: string,
): IChargeProfile[] => {
  if (chargeType) {
    return chargeProfiles.filter(c => c.chargeType === chargeType);
  }
  return chargeProfiles;
};

export const filterChargeProfileBySearch = (chargeProfiles: IChargeProfile[], searchString: string) => {
  if (!!searchString) {
    return chargeProfiles.filter(dc => dc.name.toLowerCase().includes(searchString.toLowerCase()));
  }
  return chargeProfiles;
};

/**
 * Filter an array of charge profiles using a search string
 */
export const filterChargeProfiles = (
  chargeProfiles: IChargeProfile[],
  filters: Map<string, string[]>,
): IChargeProfile[] => {
  let newChargeProfiles: IChargeProfile[];
  const chargeType = filters.get(RateCardsFiltersEnum.CHARGE_TYPE) as any;
  const search = filters.get(RateCardsFiltersEnum.SEARCH);

  newChargeProfiles = filterChargeProfilesByChargeType(chargeProfiles, chargeType ? chargeType[0] : undefined);

  if (search) {
    newChargeProfiles = filterChargeProfileBySearch(newChargeProfiles, search[0]);
  }

  return newChargeProfiles;
};

export const getStatusColor = (status: ChargeProfileStatusEnum) => {
  switch (status) {
    case ChargeProfileStatusEnum.LIVE:
    case ChargeProfileStatusEnum.ENABLE:
      return styleTheme.palette.successGreen;
    case ChargeProfileStatusEnum.DISABLE:
    case ChargeProfileStatusEnum.DISABLED:
      return styleTheme.palette.greyDarker;
    case ChargeProfileStatusEnum.DELETE:
      return styleTheme.palette.digitalRed;
  }

  return styleTheme.palette.black;
};
