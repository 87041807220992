import {
  Checkbox,
  FormSection,
  MultipleOptionsDropdown,
  PrimaryButton,
  SecondaryPageTitle,
  TextArea,
  TextInput,
  useAlert,
  TextInputWithTag,
} from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikErrors, FormikHelpers, yupToFormErrors } from "formik";
import React, { useCallback, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";
import { RoutesEnum } from "../../../../routes/routes.constants";
import { LoadingText, PageContainer, DropDownWrapper } from "../../../../shared/shared.styled";
import { RegExEpressions } from "../../../../shared/shared.utils";
import AntServiceManagerServiceDetailsApi from "../ant-service-manager-service-details.api";
import Dropzone from "../utils/dropzone.component";
import LanguagesApi from "../../../../shared/services/languages.api";
import HelpIcon from "../../../../shared/icons/help.icon";
import { ILanguage } from "../../../../shared/models/language.model";
import { object, Ref, string, StringSchema } from "yup";
import i18next from "i18next";
import { IServiceManagerAddService } from "../../../../shared/models/ant-service-manager.model";
import { IDynamicScreenCategory } from "../../../configurations/dynamic-screens/dynamic-screens.model";
import DynamicScreensApi from "../../../configurations/dynamic-screens/dynamic-screens.api";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { useDispatch } from "react-redux";
import { ServiceManagerTabNumber } from "../../pages/ant-service-manager.page";
import ReactTooltip from "react-tooltip";
import GenericLanguageSelector from "../../../../shared/components/generic-language-selector.component";
import useEmptyLanguageWarningModal from "../../../../shared/hooks/use-empty-language-warning-modal.hook";
import AllowedScopesApi from "../../allowed-scope/allowed-scopes.api";
import { IAllowedScope } from "../../allowed-scope/allowed-scopes.model";

/**
 * Ant service manager details page
 * @returns
 */
const AntServiceManagerAddPage = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const MAX_FILE_SIZE = 2000000;

  const [iconFile, setIconFile] = useState<string>();
  const [imageFile, setImageFile] = useState<string>();
  const [icon, setIcon] = useState<string>();
  const [image, setImage] = useState<string>();

  const [availableLanguages, setAvailableLanguages] = useState<ILanguage[]>();
  const [language, setLanguage] = useState("en");

  const [categories, setCategories] = React.useState<IDynamicScreenCategory[]>([]);
  const [selectedCategories, setSelectedCategories] = React.useState<string[]>([]);

  const [allowedScopes, setAllowedScopes] = React.useState<IAllowedScope[]>([]);

  const tagsSuggestions = useRef(new Array<string>());
  const [tags, setTags] = useState<string[]>([]);
  const [canBeHighlighted, setCanBeHighlighted] = useState<boolean>(false);
  const [tagInputText, setTagInputText] = useState<string>("");
  const [tagErrorText, setTagErrorText] = useState<string>("");

  const { warningModalOpen, showWarning, setShowWarning } = useEmptyLanguageWarningModal(availableLanguages || []);
  /**
   * Submit method wrapper to show warning if needed
   * @param values
   */
  const submitWrapper = (values: any, actions: FormikHelpers<any>) => {
    if (showWarning) {
      setShowWarning(false);
      actions.setSubmitting(false);
      warningModalOpen(addService, values, actions);
    } else {
      addService(values, actions);
    }
  };

  const onDrop = useCallback(
    acceptedIcon => {
      if (acceptedIcon.length > 0) {
        const file: File = acceptedIcon[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = function() {
          if (reader.result) {
            setIconFile(reader.result.toString());
            setIcon(window.URL.createObjectURL(file));
          }
        };
      }
    },
    [iconFile, icon],
  );

  const onDropImage = useCallback(
    acceptedImage => {
      if (acceptedImage.length > 0) {
        const file: File = acceptedImage[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = function() {
          if (reader.result) {
            setImageFile(reader.result.toString());
            setImage(window.URL.createObjectURL(file));
          }
        };
      }
    },
    [imageFile, image],
  );

  React.useEffect(() => {
    return () => {
      window.URL.revokeObjectURL(icon as string);
      window.URL.revokeObjectURL(image as string);
    };
  }, []);

  /**
   * Persists the service
   * @param serviceInfo
   * @param actions
   */
  const persistService = (serviceInfo: IServiceManagerAddService, actions: FormikHelpers<any>) => {
    AntServiceManagerServiceDetailsApi.methods
      .addAntService(imageFile as string, iconFile as string, serviceInfo)
      .finally(() => actions.setSubmitting(false))
      .then(
        () => {
          setAlertProps({
            title: t("pages.antServiceManager.serviceAdd.success.addingService"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          actions.setSubmitting(false);
          history.push(RoutesEnum.ANT_SERVICE_MANAGER);
        },
        err => {
          if (
            err?.response?.data?.code === 20001 ||
            err?.response?.data?.code === 40003 //20001 = already exist | 40003 = max highlighted reached
          ) {
            setAlertProps({
              title: err.response.data.message,
              type: AlertTypeEnum.ERROR,
            });
          } else {
            setAlertProps({
              title: t("pages.antServiceManager.serviceAdd.errors.addingService"),
              type: AlertTypeEnum.ERROR,
            });
          }

          showAlert();
          actions.setSubmitting(false);
        },
      );
  };

  /**
   * Update service function
   * @param values
   * @param actions
   */
  const addService = (values: IServiceManagerAddService, actions: FormikHelpers<any>) => {
    const addDetails = {
      name: values.name,
      description: values.description,
      highlighted: values.highlighted,
      allowedScopes: values.allowedScopes,
      antId: values.antId,
      categories: values.categories,
      tags: tags,
    } as IServiceManagerAddService;

    persistService(addDetails, actions);
  };

  React.useEffect(() => {
    LanguagesApi.methods.getAvailableLanguages().then(
      res => {
        if (res.data.availableLanguages.length === 0) {
          setAvailableLanguages([
            { id: "1", code: "en", name: "English", urlIcon: "ic_flag_uk.png" },
            { id: "2", code: "sw", name: "Swahili (Tanzania)", urlIcon: "ic_flag_tanzania.png" },
          ]);
        } else {
          setAvailableLanguages(res.data.availableLanguages);
        }
      },
      () => {
        setAlertProps({
          type: AlertTypeEnum.ERROR,
          title: t("commons.getLanguagesError"),
        });
        showAlert();
        setAvailableLanguages([
          { id: "1", code: "en", name: "English", urlIcon: "ic_flag_uk.png" },
          { id: "2", code: "sw", name: "Swahili (Tanzania)", urlIcon: "ic_flag_tanzania.png" },
        ]);
      },
    );

    DynamicScreensApi.methods.getDynamicScreensCategories().then(
      res => {
        setCategories(res.data.items);
      },
      () => {
        setAlertProps({
          title: t("pages.serviceBuilder.errors.categoriesError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );

    AntServiceManagerServiceDetailsApi.methods.getCanBeHighlighted().then(res => {
      setCanBeHighlighted(res.data.canBeHighlighted);
    });

    AllowedScopesApi.methods.getAllowedScopes().then(
      res => {
        setAllowedScopes(res.data);
      },
      () => {
        setAlertProps({
          title: t("pages.antServiceManager.allowedScopes.getAllowedScopesError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  }, []);

  /**
   * Creates the language validators for the translatable fields
   * @param {string[]} languages
   * @returns {{[p: string]: Ref | StringSchema<string>}}
   */
  const getTranslationValidators = (languages: ILanguage[]) => {
    let validators: { [x: string]: Ref | StringSchema<string> } = {};
    languages.forEach((lang: ILanguage) => {
      if (lang.mandatory) {
        validators = {
          ...validators,
          [lang.code]: string()
            .trim()
            .required(i18next.t("pages.serviceBuilder.formErrors.required"))
            .max(255, i18next.t("pages.serviceBuilder.formErrors.maxLen255")),
        };
      } else {
        validators = {
          ...validators,
          [lang.code]: string().max(255, i18next.t("pages.serviceBuilder.formErrors.maxLen255")),
        };
      }
    });
    return validators;
  };

  /**
   * Retrieves the form validation schema
   * @param {string[]} availableLanguages
   * @returns {ObjectSchema<Shape<object, {name: Shape<object, {}>, serviceImage: string | null, serviceIcon: string | null, description: Shape<object, {}>}>>}
   */
  const getValidationSchema = (availableLanguages: ILanguage[]) => {
    return object().shape({
      serviceImage: string()
        .required(i18next.t("pages.serviceBuilder.modals.addService.image.errorRequired"))
        .nullable(),
      serviceIcon: string()
        .required(i18next.t("pages.serviceBuilder.modals.addService.icon.errorRequired"))
        .nullable(),
      name: object().shape(getTranslationValidators(availableLanguages)),
      description: object().shape(getTranslationValidators(availableLanguages)),
      antId: string()
        .required(i18next.t("pages.serviceBuilder.modals.addService.icon.errorRequired"))
        .nullable(),
    });
  };

  /**
   * Validate form promise
   * @param values
   * @param {string[]} languages
   * @returns {Promise<FormikErrors<IServiceManagerAddService>>}
   */
  const validateSchema = (
    values: any,
    languages: ILanguage[],
    setShowWarning?: React.Dispatch<React.SetStateAction<boolean>>,
  ): Promise<FormikErrors<IServiceManagerAddService>> => {
    return new Promise<FormikErrors<IServiceManagerAddService>>(resolve => {
      getValidationSchema(languages)
        .validate(values, {
          abortEarly: false,
        })
        .then(() => {
          setShowWarning && setShowWarning(false);
          //Validate if any optional language is empty
          for (let l = 0; l < languages.length; l++) {
            if (!values.name[languages[l].code] || !values.description[languages[l].code]) {
              setShowWarning && setShowWarning(true);
            }
          }
          resolve({});
        })
        .catch(errors => {
          const langErrors: string[] = [];
          errors.inner.forEach((err: { path: string }) => {
            if ((err.path as string).includes("name") || (err.path as string).includes("description")) {
              langErrors.push(err.path.replace("name.", "").replace("description.", ""));
            }
          });
          if (langErrors.length > 0) {
            langErrors.sort();
            setLanguage(langErrors[0]);
          }
          resolve(yupToFormErrors(errors));
        });
    }).then(r => {
      return r;
    });
  };

  /**
   * Toggles a category in the dropdown
   * @param {SharedDropdownOption} opt
   * @param {(field: string, value: any, shouldValidate?: (boolean | undefined)) => void} setFieldValue
   */
  const toggleCategory = (
    opt: SharedDropdownOption,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  ) => {
    if (selectedCategories.includes(opt.key)) {
      setFieldValue(
        "categories",
        selectedCategories.filter(c => c !== opt.key),
      );
      setSelectedCategories(selectedCategories.filter(c => c !== opt.key));
    } else {
      setFieldValue("categories", [...selectedCategories, opt.key]);
      setSelectedCategories([...selectedCategories, opt.key]);
    }
  };

  /**
   * Checks if a category is already selected on the dropdown
   * @param {SharedDropdownOption} opt
   * @returns {boolean}
   */
  const isCategorySelected = (opt: SharedDropdownOption) => {
    return selectedCategories.includes(opt.key);
  };

  /**
   * Returns a label for the categories dropdown
   * @returns {string}
   */
  const getCategoriesLabel = () => {
    if (selectedCategories.length > 0) {
      let label = "";
      selectedCategories.forEach(c => {
        const nameTranslation = (categories.find(cat => {
          return cat.id === c;
        }) as IDynamicScreenCategory).name.translations;
        label += `${nameTranslation[language] ? nameTranslation[language] : nameTranslation["en"]}, `;
      });
      label = label.substring(0, label.length - 2);
      return label;
    } else {
      return t("pages.serviceBuilder.modals.addService.category.placeholder");
    }
  };

  /**
   * Returns a label for the allowed single sign on scopes dropdown
   * @returns {string}
   */
  const getAllowedScopeName = (allowedScopesIds: string[]) => {
    const scopesLabel = [] as string[];
    allowedScopes.forEach(scope => {
      allowedScopesIds.forEach(id => {
        if (scope.id === id) {
          scopesLabel.push(
            scope.nameTranslations[language] ? scope.nameTranslations[language] : scope.nameTranslations["en"],
          );
        }
      });
    });

    return scopesLabel.join(", ");
  };

  /**
   * Return allowed scope options
   */
  const getAllowedScopesOptions = (allowedScopes: IAllowedScope[]): SharedDropdownOption[] => {
    const options: SharedDropdownOption[] = allowedScopes.map(scope => {
      return {
        label: scope.nameTranslations[language] ? scope.nameTranslations[language] : scope.nameTranslations["en"],
        key: scope.id,
      };
    });

    return options;
  };

  /**
   * returns array of actual selectedAllowedValues keys
   * @param selectedAllowedScopes
   */
  const getSelectedAllowedScopeKeys = (selectedAllowedScopes: string | any[]) => {
    return getAllowedScopesOptions(allowedScopes)
      .map(item => {
        if (item && selectedAllowedScopes && selectedAllowedScopes.includes(item.key) && item.label !== "") {
          return item.key;
        }
      })
      .filter(item => item !== undefined);
  };

  return (
    <>
      {!availableLanguages ? (
        <LoadingText style={{ marginLeft: "75px" }}>{t("commons.loadingResults")}</LoadingText>
      ) : (
        availableLanguages && (
          <PageContainer>
            <SecondaryPageTitle
              title={t("pages.antServiceManager.serviceAdd.title")}
              goBackFn={() =>
                history.push(`${RoutesEnum.ANT_SERVICE_MANAGER}?tabIdx=${ServiceManagerTabNumber.SERVICE_LIST}`)
              }
              displayInitialsCircle={false}
            />
            <Formik
              onSubmit={submitWrapper}
              initialValues={{
                name: undefined as any,
                description: undefined as any,
                antId: "",
                allowedScopes: [] as string[],
                highlighted: false,
                serviceIcon: "",
                serviceImage: "",
                tags: [],
              }}
              validateOnBlur={false}
              validateOnChange={false}
              validate={values => validateSchema(values, availableLanguages, setShowWarning)}
              enableReinitialize
              render={({
                values,
                handleChange,
                setFieldValue,
                handleSubmit,
                resetForm,
                errors,
                setErrors,
                isSubmitting,
              }) => (
                <EditServiceForm>
                  <TopFormDiv>
                    <EditServiceFormLeft>
                      <div style={{ marginLeft: "auto" }}>
                        <GenericLanguageSelector
                          selectedLanguage={language}
                          availableLanguages={availableLanguages.map(lang => lang.code)}
                          changeSelectedLanguage={(lang: any) => setLanguage(lang.key)}
                        ></GenericLanguageSelector>
                      </div>
                      <FormSection
                        title={t("pages.antServiceManager.serviceAdd.description")}
                        isEditing={true}
                        rows={[
                          {
                            label: (
                              <DropzoneTitle
                                mandatory={availableLanguages.find(l => l.code === language)?.mandatory || false}
                              >
                                {t("pages.antServiceManager.serviceAdd.formRows.name.label")}
                              </DropzoneTitle>
                            ),
                            displayComponent: values.name ? values.name[language] : "",
                            editingComponent: (
                              <TextInput
                                name={`name.${language}`}
                                value={(values.name && values.name[language]) || ""}
                                error={errors.name && ((errors.name as { [x: string]: string })[language] as string)}
                                onChange={handleChange}
                                placeholder={t("pages.antServiceManager.serviceAdd.formRows.name.placeholder")}
                                required={availableLanguages.find(l => l.code === language)?.mandatory}
                              />
                            ),
                          },
                          {
                            label: (
                              <DropzoneTitle
                                mandatory={availableLanguages.find(l => l.code === language)?.mandatory || false}
                              >
                                {t("pages.antServiceManager.serviceAdd.formRows.description.label")}
                              </DropzoneTitle>
                            ),
                            displayComponent: values.description ? values.description[language] : "",
                            editingComponent: (
                              <TextAreaWrapper
                                mandatory={availableLanguages.find(l => l.code === language)?.mandatory || false}
                              >
                                <TextArea
                                  style={{ fontSize: "16px", fontFamily: "Vodafone Rg" }}
                                  placeholder={t("pages.antServiceManager.serviceAdd.formRows.description.placeholder")}
                                  name={`description.${language}`}
                                  onChange={handleChange}
                                  value={(values.description && values.description[language]) || ""}
                                  error={
                                    errors.description &&
                                    ((errors.description as { [x: string]: string })[language] as string)
                                  }
                                  autoComplete="off"
                                  maxHeight="330px"
                                />
                                <TextAreaErrorHandling>
                                  {errors.description &&
                                    ((errors.description as { [x: string]: string })[language] as string)}
                                </TextAreaErrorHandling>
                              </TextAreaWrapper>
                            ),
                          },
                          {
                            label: (
                              <DropzoneTitle mandatory={false}>
                                {t("pages.antServiceManager.serviceAdd.formRows.tags.label")}
                              </DropzoneTitle>
                            ),
                            displayComponent: values.tags ? values.tags : [],
                            editingComponent: (
                              <TextInputWithTag
                                error={tagErrorText}
                                addTagButtonText={t("pages.antServiceManager.serviceAdd.formRows.tags.createTag")}
                                tags={tags}
                                setTags={setTags}
                                inputValue={tagInputText}
                                setInputValue={setTagInputText}
                                tagsSuggestions={tagsSuggestions.current}
                                minTagTextLength={3}
                                maxTagTextLength={25}
                                placeholder={t("pages.antServiceManager.serviceAdd.formRows.tags.tagsPlaceholder")}
                                regexExp={RegExEpressions.especialCharacters}
                              />
                            ),
                          },
                          {
                            label: (
                              <DropzoneTitle mandatory={true}>
                                {t("pages.antServiceManager.serviceAdd.formRows.antID.label")}
                              </DropzoneTitle>
                            ),
                            displayComponent: <NonEditableText>{values.antId}</NonEditableText>,
                            editingComponent: (
                              <TextInput
                                name={`antId`}
                                maxLength={16}
                                value={values.antId}
                                onChange={handleChange}
                                placeholder={t("pages.antServiceManager.serviceAdd.formRows.antID.label")}
                                error={errors.antId || ""}
                                required
                              />
                            ),
                          },
                          {
                            label: (
                              <DropzoneTitle mandatory={false}>
                                {t("pages.antServiceManager.serviceAdd.formRows.sso.ssoAllowedScopes")}
                              </DropzoneTitle>
                            ),
                            displayComponent: values.allowedScopes ? getAllowedScopeName(values.allowedScopes) : [],
                            editingComponent: (
                              <DropDownWrapper id="selectable-option">
                                <MultipleOptionsDropdown
                                  showClearAllFilters={false}
                                  options={getAllowedScopesOptions(allowedScopes)}
                                  clearAllFilters={() => {
                                    setFieldValue("allowedScopes", []);
                                  }}
                                  dropdownType={DropdownType.RECTANGULAR_NORMAL}
                                  hasValue={values.allowedScopes && values.allowedScopes.length > 0}
                                  isOptionSelected={(opt: SharedDropdownOption) => {
                                    return values.allowedScopes && values.allowedScopes.length > 0
                                      ? values.allowedScopes.includes(opt.key)
                                      : false;
                                  }}
                                  label={
                                    values.allowedScopes &&
                                    getSelectedAllowedScopeKeys(values.allowedScopes).length > 0 &&
                                    getAllowedScopeName(getSelectedAllowedScopeKeys(values.allowedScopes))
                                      ? getAllowedScopeName(getSelectedAllowedScopeKeys(values.allowedScopes))
                                      : t("pages.antServiceManager.serviceAdd.formRows.sso.placeholder")
                                  }
                                  toggleOption={(opt: SharedDropdownOption) => {
                                    if (values.allowedScopes && values.allowedScopes.includes(opt.key)) {
                                      const index = values.allowedScopes.indexOf(opt.key);
                                      const valuesHelper = [...values.allowedScopes];

                                      valuesHelper.splice(index, 1);

                                      setFieldValue("allowedScopes", getSelectedAllowedScopeKeys(valuesHelper));
                                    } else {
                                      const allowedScopes = values.allowedScopes ? values.allowedScopes : [];
                                      setFieldValue(
                                        "allowedScopes",
                                        getSelectedAllowedScopeKeys([...allowedScopes, opt.key]),
                                      );
                                    }
                                  }}
                                />
                              </DropDownWrapper>
                            ),
                          },
                          {
                            label: (
                              <DropzoneTitle mandatory={false}>
                                {t("pages.antServiceManager.serviceAdd.formRows.highlighted.title")}
                              </DropzoneTitle>
                            ),
                            displayComponent: (
                              <SelectableOption>
                                {values.highlighted ? (
                                  <HighlightedLabel>
                                    {t("pages.antServiceManager.serviceAdd.formRows.highlighted.highlightedService")}
                                  </HighlightedLabel>
                                ) : (
                                  <NonHighlightedLabel>
                                    {t("pages.antServiceManager.serviceAdd.formRows.highlighted.nonHighlightedService")}
                                  </NonHighlightedLabel>
                                )}
                              </SelectableOption>
                            ),
                            editingComponent: (
                              <SelectableOption>
                                <Checkbox
                                  data-testid={"highlighted-checkbox"}
                                  name={"highlighted"}
                                  onValueChange={() => {
                                    if (values.highlighted) {
                                      setCanBeHighlighted(true);
                                    }
                                    setFieldValue("highlighted", !values.highlighted);
                                  }}
                                  value={values.highlighted}
                                  disabled={canBeHighlighted ? false : !values!.highlighted}
                                />
                                <Label>{t("pages.antServiceManager.serviceAdd.formRows.highlighted.label")}</Label>
                                {!canBeHighlighted && !values!.highlighted && (
                                  <TooltipContainer data-tip data-for="highlighted-tooltip" id={"highlighted-tooltip"}>
                                    <HelpIcon />
                                    <ReactTooltip
                                      event="mouseover"
                                      eventOff="mouseleave mousedown"
                                      place="top"
                                      type="dark"
                                      effect="solid"
                                      id={"highlighted-tooltip"}
                                      className={"tooltip-container"}
                                    >
                                      <TooltipText>
                                        {t("pages.antServiceManager.serviceAdd.formRows.highlighted.tooltip")}
                                      </TooltipText>
                                    </ReactTooltip>
                                  </TooltipContainer>
                                )}
                              </SelectableOption>
                            ),
                          },
                        ].concat(
                          categories.length > 0
                            ? [
                                {
                                  label: (
                                    <DropzoneTitle mandatory={false}>
                                      {t("pages.antServiceManager.serviceAdd.formRows.categories.label")}
                                    </DropzoneTitle>
                                  ),
                                  displayComponent: getCategoriesLabel(),
                                  editingComponent: (
                                    <DropDownWrapper id="selectable-option">
                                      <MultipleOptionsDropdown
                                        showClearAllFilters={false}
                                        options={categories.map(c => {
                                          return {
                                            label: c.name.translations[language]
                                              ? c.name.translations[language]
                                              : c.name.translations["en"],
                                            key: c.id,
                                          };
                                        })}
                                        clearAllFilters={() => {
                                          setSelectedCategories([]);
                                          setFieldValue("categories", []);
                                        }}
                                        dropdownType={DropdownType.RECTANGULAR_NORMAL}
                                        hasValue={selectedCategories.length > 0}
                                        isOptionSelected={opt => isCategorySelected(opt)}
                                        label={getCategoriesLabel()}
                                        toggleOption={opt => toggleCategory(opt, setFieldValue)}
                                      />
                                    </DropDownWrapper>
                                  ),
                                },
                              ]
                            : [],
                        )}
                      />
                    </EditServiceFormLeft>
                    <EditServiceFormRight>
                      <div>
                        <DropzoneTitle mandatory={true}>
                          {t("pages.serviceBuilder.modals.addService.icon.title")}
                        </DropzoneTitle>
                        {errors.serviceIcon ? <ImageError>{errors.serviceIcon}</ImageError> : null}
                        <Dropzone
                          inputName="serviceIcon"
                          onDrop={(file: any) => {
                            if (!file[0]) {
                              setErrors({
                                ...errors,
                                serviceIcon: t("pages.serviceBuilder.modals.addService.icon.imageError"),
                              });
                              if (values.serviceIcon === null) {
                                setFieldValue("serviceIcon", null);
                              }
                            } else if (file[0] && file[0].size > MAX_FILE_SIZE) {
                              setErrors({
                                ...errors,
                                serviceIcon: t("pages.serviceBuilder.modals.updateService.icon.maxSizeError").replace(
                                  "{0}",
                                  (MAX_FILE_SIZE / 1000000).toString(),
                                ),
                              });
                              if (values.serviceIcon === null) {
                                setFieldValue("serviceIcon", null);
                              }
                            } else {
                              setFieldValue("serviceIcon", file);
                              onDrop(file);
                            }
                          }}
                          accept={"image/*"}
                          imageSrc={icon as string}
                          multiple={false}
                          dropText={t("pages.serviceBuilder.modals.updateService.icon.drop")}
                          releaseText={t("pages.serviceBuilder.modals.updateService.icon.release")}
                          error={!!errors.serviceIcon}
                        />
                      </div>

                      <div>
                        <DropzoneTitle mandatory={true}>
                          {t("pages.serviceBuilder.modals.addService.image.title")}
                        </DropzoneTitle>
                        {errors.serviceImage ? <ImageError>{errors.serviceImage}</ImageError> : null}
                        <Dropzone
                          inputName="serviceImage"
                          onDrop={(file: any) => {
                            if (!file[0]) {
                              setErrors({
                                ...errors,
                                serviceImage: t("pages.serviceBuilder.modals.addService.icon.imageError"),
                              });
                              if (values.serviceImage === null) {
                                setFieldValue("serviceImage", null);
                              }
                            } else if (file[0] && file[0].size > MAX_FILE_SIZE) {
                              setErrors({
                                ...errors,
                                serviceImage: t("pages.serviceBuilder.modals.updateService.icon.maxSizeError").replace(
                                  "{0}",
                                  (MAX_FILE_SIZE / 1000000).toString(),
                                ),
                              });
                              if (values.serviceImage === null) {
                                setFieldValue("serviceImage", null);
                              }
                            } else {
                              setFieldValue("serviceImage", file);
                              onDropImage(file);
                            }
                          }}
                          accept={"image/*"}
                          imageSrc={image as string}
                          multiple={false}
                          roundedImage={false}
                          dropText={t("pages.serviceBuilder.modals.updateService.icon.drop")}
                          releaseText={t("pages.serviceBuilder.modals.updateService.icon.release")}
                          error={!!errors.serviceImage}
                        />
                      </div>
                    </EditServiceFormRight>
                  </TopFormDiv>

                  <ButtonsWrapper>
                    <ButtonDiv>
                      <PrimaryButton
                        id="create-new-service-button"
                        loading={isSubmitting}
                        titleLabel={t("pages.serviceBuilder.modals.addService.save.title")}
                        onClick={() => {
                          handleSubmit();
                        }}
                        redTheme={true}
                      />
                    </ButtonDiv>
                  </ButtonsWrapper>
                </EditServiceForm>
              )}
            />
          </PageContainer>
        )
      )}
    </>
  );
};

export default AntServiceManagerAddPage;

const EditServiceForm = styled("div")`
  display: flex;
  margin: auto;
  flex-direction: column;
  max-width: 62%;
  margin-left: 204px;
  margin-right: 204px;
  margin-top: 110px;
  @media (max-width: 1024px) {
    min-width: 88%;
    margin-left: 44px;
    margin-right: 44px;
  }
`;

const TopFormDiv = styled("div")`
  display: flex;
  flex-direction: row;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
`;

const EditServiceFormLeft = styled("div")`
  flex: 1;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  > div {
    margin-bottom: 10px;
  }
  @media (max-width: 1024px) {
    padding-right: 0;
    min-width: 100%;
    > div {
      margin-bottom: 40px;
    }
  }
`;

const EditServiceFormRight = styled("div")`
  width: 250px;
  overflow: hidden;
  min-width: 200px;
  @media (max-width: 1024px) {
    padding-bottom: 32px;
    > div {
      margin-bottom: 48px;
    }
  }
`;

const DropzoneTitle = styled("div")<{ mandatory: boolean }>`
  min-height: 21px;
  font-family: Vodafone Rg;
  font-weight: bold;
  line-height: 21px;
  font-size: 16px;
  color: #999999;
  margin-bottom: 8px;
  width: fit-content;
  display: inline-flex;
  ${props =>
    props.mandatory ? "::after {content: ' *'; color: #ff0000; font-weight: 400; padding-left: 2px;}" : null};
`;

export const TooltipContainer = styled("div")`
  display: flex;
  margin-left: 8px;
  cursor: pointer;
`;

export const TooltipText = styled("div")`
  font-size: 12px;
  line-height: 14px;
  font-family: "Vodafone Rg";
  color: white;
  display: flex;
  width: 210px;
  text-align: center;
`;

const ButtonsWrapper = styled("div")`
  margin-top: auto;
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  @media (max-width: 1024px) {
    width: auto;
    position: fixed;
    right: 60px;
    top: 46px;
    z-index: 1;
  }
`;

const ButtonDiv = styled("div")`
  width: 200px;
  margin-right: 20px;
`;

const NonEditableText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${styleTheme.palette.darkGrey};
`;

const Label = styled("div")`
  font-family: Vodafone Rg;
  margin-left: 8px;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 19px;
  letter-spacing: normal;
  color: ${props => props.theme.palette.midGrey};
`;

const HighlightedLabel = styled(Label)`
  margin-left: 0;
  color: ${props => props.theme.palette.warningYellow};
`;

const NonHighlightedLabel = styled(Label)`
  margin-left: 0;
  color: ${props => props.theme.palette.black};
`;

const SelectableOption = styled("div")`
  display: flex;
`;

const ImageError = styled("div")`
  width: fit-content;
  display: inline-flex;
  height: 19px;
  color: #ff0000;
  font-family: Vodafone Rg;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  float: right;
  font-weight: 400;
`;

export const ToogleDiv = styled("div")``;

const TextAreaWrapper = styled("div")<{ mandatory: boolean }>`
  > div > textarea {
    color: #333333 !important;
  }

  ${({ mandatory }) =>
    mandatory &&
    css`
      > div > span > span:first-of-type:after {
        content: " *";
        width: 7px;
        color: #ff0000;
        font-family: Vodafone Rg;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
      }
    `};
`;

const TextAreaErrorHandling = styled("div")`
  font-family: Vodafone Rg;
  font-size: 14px;
  color: #ff0000;
  margin-top: 4px;
`;
