import { Error500Page } from "@wit/mpesa-ui-components";
import { t } from "i18next";
import React, { Component, ErrorInfo } from "react";
import { StaticContext } from "react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ConfigContext } from "../../../app.component";

interface IState {
  hasError: boolean;
}

class ErrorBoundary extends Component<RouteComponentProps> {
  public state: IState = {
    hasError: false,
  };

  /**
   * Listen to changes on history and update the hasError
   *constructor
   */
  constructor(props: Readonly<RouteComponentProps<{}, StaticContext, unknown>>) {
    super(props);
    const { history } = this.props;
    history.listen((location, action) => {
      if (this.state.hasError) {
        this.setState({
          hasError: false,
        });
      }
    });
  }

  /**
   * Update state so the next render will show the fallback UI.
   * @param _
   * @returns
   */
  public static getDerivedStateFromError(_: Error): IState {
    return { hasError: true };
  }

  /**
   * Log the error
   * @param error
   * @param errorInfo
   */
  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  /**
   * Renders the children or Error page
   * @returns
   */
  public render() {
    if (this.state.hasError) {
      const { history } = this.props;
      return (
        <ConfigContext.Consumer>
          {({ config }) => {
            return (
              <Error500Page
                title={t("pages.500.title")}
                description={t("pages.500.description")}
                buttonLabel={t("pages.500.buttonLabel")}
                onButtonClick={() => {
                  config && config.defaultRoute ? history.push(`/${config.defaultRoute}`) : history.goBack();
                }}
              ></Error500Page>
            );
          }}
        </ConfigContext.Consumer>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
