import React from "react";
import { useDropzone, DropzoneOptions } from "react-dropzone";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import UploadImagePlaceHolderIcon from "../../../shared/icons/upload-image-placeholder.icon";

interface IDropzoneOptionsProps extends DropzoneOptions {
  dropText?: string;
  releaseText?: string;
  inputName?: string;
  error?: boolean;
}

/**
 * Component to dropdown and upload files
 * @param param0 Dropzone interface
 */
const DropzoneBackground = ({
  onDrop,
  accept,
  multiple,
  dropText = "",
  releaseText = "",
  inputName = "dropzoneInput",
  error = false,
}: IDropzoneOptionsProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple,
  });
  const [t] = useTranslation();
  return (
    <DropzoneDiv active={isDragActive} hasError={error} {...getRootProps()}>
      <DropzoneInput name={inputName} {...getInputProps()} />
      <DropzoneInsideDiv>
        <TopDiv>
          <UploadImage>
            <IconDiv isDrag={isDragActive}>
              <UploadImagePlaceHolderIcon />
            </IconDiv>
            <TextAreaDiv>
              {isDragActive ? (
                <span>{releaseText}</span>
              ) : (
                <div>
                  <span>{dropText}</span>
                  <div>
                    {t("pages.announcements.detailPage.rows.textDropDescription")}
                    <b>
                      <u>{t("pages.announcements.detailPage.rows.browse")}</u>
                    </b>
                  </div>
                </div>
              )}
            </TextAreaDiv>
          </UploadImage>
        </TopDiv>
      </DropzoneInsideDiv>
    </DropzoneDiv>
  );
};

export default DropzoneBackground;

const DropzoneDiv = styled("div")<{ active: boolean; hasError: boolean }>`
  height: 116px;
  border: 2px dashed ${props =>
    !props.hasError ? `${props.theme.palette.aluminium};` : `${props.theme.palette.vodafoneRed};`}
  border-radius: 6px;
  outline: none;
  cursor: pointer;
  display: flex;
  margin-bottom: 10px;
  ${props =>
    props.active
      ? `background-color: ${props.theme.palette.vodafoneRed}66; border: 3px dashed ${props.theme.palette.vodafoneRed}66;`
      : null};
`;

const DropzoneInput = styled("input")``;

const DropzoneInsideDiv = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Vodafone Rg;
`;

const TopDiv = styled("div")`
  background-color: transparent;
  border-radius: 7px 7px 0px 0px;
`;
const UploadImage = styled("div")`
  display: flex;
`;
const IconDiv = styled("div")<{ isDrag: boolean }>`
  margin: 40px;
  display: flex;
  align-items: center;
  > svg {
    width: 38px;
    height: 38px;
    > path {
      stroke: ${props => (props.isDrag ? props.theme.palette.darkGrey : props.theme.palette.midGrey)};
      opacity: 1;
    }
  }
`;

const TextAreaDiv = styled("div")`
  display: flex;
  align-items: center;
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #afafaf;
  > span {
    color: #333;
    font-size: 22px;
  }
  > div {
    > div {
      margin-bottom: 0.2vw;
    }
    > span {
      color: #666;
      margin-bottom: 0.2vw;
    }
    b {
      color: #333;
    }
    div:last-child {
      font-size: 14px;
    }
  }
`;
