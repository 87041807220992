import React from "react";
import { PageContainer, PageTitle } from "../../../shared/shared.styled";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Tabs, TabChild } from "@wit/mpesa-ui-components";
import AnnouncementsListPage from "./announcements-list.page";
import { useParams } from "react-router-dom";

/**
 * Component to the page of announcement
 */
const AnnouncementsPage = () => {
  const [t] = useTranslation();
  const { tabIdx } = useParams<any>();
  const [tab, setTab] = React.useState(tabIdx || 0);

  return (
    <PageContainer>
      <PageTitle>{t("pages.announcements.title")}</PageTitle>
      <TabsContainer>
        <Tabs controlledSelectedTab={tab} controlledSetSelectedTab={setTab} isResponsive={true}>
          <TabChild label={t("pages.announcements.tabs.all")}>
            <></>
          </TabChild>
          <TabChild label={t("pages.announcements.tabs.live")}>
            <></>
          </TabChild>
          <TabChild label={t("pages.announcements.tabs.past")}>
            <></>
          </TabChild>
        </Tabs>
        <AnnouncementsListPage filterTab={tab} />
      </TabsContainer>
    </PageContainer>
  );
};

export default AnnouncementsPage;

const TabsContainer = styled("div")`
  margin-top: 43px;
`;
