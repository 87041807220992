import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { RoutesEnum } from "../../../routes/routes.constants";
import { useHistory } from "react-router-dom";
import { SelectedView } from "../../../shared/shared.enums";

interface IAddServiceItemProps {
  selectedView: SelectedView;
}

/**
 * Add service item
 * @param param0
 * @returns
 */
const AddServiceItem = ({ selectedView }: IAddServiceItemProps) => {
  const [t] = useTranslation();
  const history = useHistory();
  return (
    <AddServiceItemContainer
      id="add-new-service"
      gridView={selectedView === SelectedView.GRID}
      // TODO: add new service
      onClick={() => {
        history.push(RoutesEnum.SERVICE_ADD);
      }}
    >
      <AddServiceItemIconContainer gridView={selectedView === SelectedView.GRID}>
        <img src="./images/rounded-plus.svg" alt="add-service button" />
      </AddServiceItemIconContainer>
      <AddServiceItemTitle gridView={selectedView === SelectedView.GRID}>
        {t("pages.serviceBuilder.addServiceButton.title")}
      </AddServiceItemTitle>
    </AddServiceItemContainer>
  );
};

export default AddServiceItem;

const AddServiceItemContainer = styled("div")<{ gridView: boolean }>`
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  height: ${props => (props.gridView ? "auto" : "75px")};
  display: flex;
  flex-direction: ${props => (props.gridView ? "column" : "row")};
  align-items: center;
  justify-content: ${props => (props.gridView ? "center" : "flex-start")};

  padding-left: ${props => (props.gridView ? 0 : 24)}px;
  padding-right: ${props => (props.gridView ? 0 : 35)}px;
  margin-bottom: ${props => (props.gridView ? 0 : 24)}px;
  :hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  }
  padding-top: ${props => (props.gridView ? 20 : 0)}px;
  padding-bottom: ${props => (props.gridView ? 20 : 0)}px;
  max-height: 300px;
`;

const AddServiceItemIconContainer = styled("div")<{ gridView: boolean }>`
  width: ${props => (props.gridView ? 74 : 45)}px;
  height: ${props => (props.gridView ? 74 : 45)}px;
  margin-bottom: ${props => (props.gridView ? 19 : 0)}px;
  margin-right: 0px;
  ${props => (!props.gridView ? "min-width: 60px; margin-right: 20px; " : null)}

  img {
    width: ${props => (props.gridView ? 74 : 45)}px;
    height: ${props => (props.gridView ? 74 : 45)}px;
  }
`;

const AddServiceItemTitle = styled("span")<{ gridView: boolean }>`
  font-family: Vodafone Rg;
  font-size: 22px;
  text-align: ${props => (props.gridView ? "center" : "left")};
  color: ${props => props.theme.palette.midGrey};
`;
