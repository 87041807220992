import { Table } from "@wit/mpesa-ui-components";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import TablePagination from "../../../shared/components/table-pagination/table-pagination-component";
import { IAuditingLog, OperationEnum } from "../auditing.model";
import styled from "styled-components";

interface IAuditingTableInterface {
  auditingLogs: IAuditingLog[];
  handlePageChange: (page: number) => void;
  rowClicked: (auditingLogId: string) => void;
  count: number;
  page: number;
  pageSize: number;
}
const AuditingTable = ({
  auditingLogs,
  handlePageChange,
  rowClicked,
  count,
  page,
  pageSize,
}: IAuditingTableInterface) => {
  const [t] = useTranslation();
  return (
    <>
      <Table
        //rowClickAction={(idx, auditingLog) => rowClicked(auditingLog.id)}
        columns={[
          {
            formKey: "id",
            label: t("pages.auditing.table.id"),
            isEditable: false,
            ratio: 2 / 12,
            changeFunction: (content, row) => {
              return <GreyText>{content}</GreyText>;
            },
          },
          {
            formKey: "date",
            label: t("pages.auditing.table.updatedOn"),
            isEditable: false,
            ratio: 2 / 12,
            changeFunction: content => {
              return <span>{moment(content).format("DD/MM/YYYY HH:mm:ss")}</span>;
            },
          },
          {
            formKey: "author",
            label: t("pages.auditing.table.operatorName"),
            isEditable: false,
            ratio: 2 / 12,
          },
          {
            formKey: "role",
            label: t("pages.auditing.table.operatorRole"),
            isEditable: false,
            ratio: 2 / 12,
            changeFunction: content => {
              return <span>{t(`userRoles.${content}`)}</span>;
            },
          },
          {
            formKey: "entity",
            label: t("pages.auditing.table.section"),
            isEditable: false,
            ratio: 2 / 12,
            changeFunction: content => {
              return <PurpleText>{t(`pages.auditing.sections.${content}`)}</PurpleText>;
            },
          },
          {
            formKey: "typeOfUpdate",
            label: t("pages.auditing.table.typeOfUpdate"),
            isEditable: false,
            ratio: 2 / 12,
            changeFunction: content => {
              return <OperationText operation={content}>{t(`pages.auditing.operations.${content}`)}</OperationText>;
            },
          },
        ]}
        values={auditingLogs}
      />
      {count ? (
        <TablePagination handlePageChange={handlePageChange} totalItems={count} activePage={page} pageSize={pageSize} />
      ) : null}
    </>
  );
};

const GreyText = styled("span")`
  color: ${props => props.theme.palette.midGrey};
`;

export const PurpleText = styled("span")`
  color: ${props => props.theme.palette.redViolet};
`;

export const OperationText = styled("span")<{ operation: string }>`
  ${props => {
    let color = props.theme.palette.darkGrey;
    switch (props.operation) {
      case OperationEnum.UPDATE:
        color = props.theme.palette.darkGrey;
        break;
      case OperationEnum.CREATE:
        color = props.theme.palette.turquoiseBlue;
        break;
      case OperationEnum.DELETE:
        color = props.theme.palette.vodafoneRed;
        break;
      case OperationEnum.SUBMIT_FOR_APPROVAL:
        color = "#eb8b00";
        break;
      case OperationEnum.APPROVE:
        color = props.theme.palette.digitalGreen;
        break;
      case OperationEnum.REJECT:
        color = props.theme.palette.vodafoneRed;
        break;
    }
    return `color: ${color};`;
  }}
`;

export default AuditingTable;
