import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { CancelIcon } from "@wit/mpesa-ui-components";
import { useSelector } from "react-redux";
import { IStoreInterface } from "../../../../configs/store.config";
import { Column, Row } from "../../../../shared/shared.styled";
import RateCardsVersionItem from "./version-item.component";

interface ILastVersionModalProps {
  onCancelClick: () => void;
}

const LastVersionModal = ({ onCancelClick }: ILastVersionModalProps) => {
  const [t] = useTranslation();

  const { lastVersion } = useSelector((state: IStoreInterface) => state.rateCardsReducer);

  return (
    <ModalContainer>
      <Header>
        <ModalTitle>{t("pages.rateCards.lastVersionModal.title")}</ModalTitle>
        <RightContainer>
          <CloseIconContainer onClick={onCancelClick} id="close-last-version-modal">
            <CancelIcon color={"grey"} />
          </CloseIconContainer>
        </RightContainer>
      </Header>
      <ItemsContainer>
        {lastVersion &&
          lastVersion.chargeProfiles.map(lastVersionItem => {
            return (
              <Item key={lastVersionItem.id}>
                {lastVersionItem ? <RateCardsVersionItem item={lastVersionItem} /> : <ItemColumn />}
              </Item>
            );
          })}
      </ItemsContainer>
    </ModalContainer>
  );
};

export default LastVersionModal;

const ModalContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 24px;
  font-family: Vodafone Rg;
`;

const ModalTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 21px;
`;

const ItemsContainer = styled.div`
  max-height: 250px;
  overflow: scroll;
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${props => props.theme.palette.darkGrey};
  overflow-x: hidden;
`;

const Item = styled(Row)`
  display: flex;
  /* justify-content: space-between; */
`;

const ItemColumn = styled(Column)<{ disabled?: boolean }>`
  flex: 1;
  padding: 16px 0 16px 0;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  border-bottom: 1px solid #d8d8d8;
  margin-right: 34px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CloseIconContainer = styled("div")`
  left: 23px;
  margin-top: -10px;
  stroke: ${props => props.theme.palette.midGrey};
  cursor: pointer;
  svg {
    width: 24px;
  }
`;

const RightContainer = styled.div`
  display: flex;
`;
