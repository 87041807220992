import { CheckIcon, Dropdown, TabChild, Tabs, TextInput } from "@wit/mpesa-ui-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import React, { useEffect, useState } from "react";
import { CustomPicker } from "react-color";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tinycolor from "tinycolor2";

interface IColorOptionsProps {
  color: string;
  setColor(field: string, value: string, shouldValidate?: boolean): void;
  name: string;
  error: string | undefined;
  isCustomizable?: boolean;
  colors?: string[];
  notSelectedColor?: string;
  inputPlaceholder?: string;
}

interface ICustomizeTabColorPros {
  hexCode: string;
  onChange: (value: IColorType | string) => void;
}

interface IColorType {
  hsl: IColorChannelsHSL;
  hsv: IColorChannelsHSV;
  hex: string;
}

interface IColorChannelsHSL {
  h: number;
  s: number;
  l: number;
}

interface IColorChannelsHSV {
  h: number;
  s: number;
  v: number;
}

/**
 * Component to dropdown of color ou small badges
 */
const ColorPicker = ({
  setColor,
  color,
  name,
  error,
  isCustomizable = true,
  colors,
  notSelectedColor,
  inputPlaceholder,
}: IColorOptionsProps) => {
  const swatchesColors = colors || [
    "#47b933",
    "#2fc56d",
    "#12c59f",
    "#00c3ff",
    "#1074ff",
    "#142c52",
    "#6338eb",
    "#b138eb",
    "#ff7bac",
    "#ff2a58",
    "#ff2a2a",
    "#ff6319",
    "#ffaa0f",
    "#55461b",
    "#666666",
  ];
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);
  const badgesColors = swatchesColors.map((color, index) => (
    <ColorBadges
      key={index}
      backgroundColor={color}
      onClick={() => {
        setColor(name, color);
        setOpen(false);
      }}
    />
  ));

  React.useEffect(() => {
    setColor(name, color);
  }, []);

  return (
    <Dropdown
      hasValue={false}
      label={
        <DropdownLabelContainer>
          <ColorContainer backgroundColor={color || notSelectedColor || "#ebebebeb"} />
          <ColorText>{color || inputPlaceholder}</ColorText>
        </DropdownLabelContainer>
      }
      dropdownType={DropdownType.RECTANGULAR_NORMAL}
      isOpen={open}
      controlledToggleOpen={setOpen}
      error={error}
    >
      <ColorDropdown>
        <Tabs isSecondaryTabs={true} tabHeaderStyle={{ display: "flex" }}>
          <TabChild label={t("commons.colorSwatches")}>
            <BadgesSection>{badgesColors}</BadgesSection>
          </TabChild>
          {isCustomizable ? (
            <TabChild label={t("commons.colorCustomize")}>
              <CustomizeTabColor
                hexCode={color}
                onChange={(color: any) => {
                  setColor(name, color.hex);
                }}
              />
            </TabChild>
          ) : (
            <></>
          )}
        </Tabs>
        <hr />
        <ColorInput>
          <ColorBadges backgroundColor={color} />
          <TextInput
            onChange={e => setColor(name, e.target.value)}
            value={color}
            style={{ width: "88px", marginLeft: "12px" }}
            disabled={isCustomizable ? false : true}
          />
          <ColorCheck onClick={() => setOpen(false)}>
            <CheckIcon />
          </ColorCheck>
        </ColorInput>
      </ColorDropdown>
    </Dropdown>
  );
};

export default ColorPicker;

/**
 * Component to tab customize
 */
const CustomizeTabColor = CustomPicker(({ hexCode, onChange }: ICustomizeTabColorPros) => {
  const [colorTypes, setColorTypes] = useState<IColorType>({
    hsl: { h: 0, s: 0, l: 0 },
    hsv: { h: 0, s: 0, v: 0 },
    hex: "aaaaaa",
  });
  const { Saturation, Hue } = require("react-color/lib/components/common");

  useEffect(() => {
    const colorToSet = tinycolor(hexCode);
    setColorTypes({ hsv: colorToSet.toHsv(), hsl: colorToSet.toHsl(), hex: colorToSet.toHex() });
    return () => {};
  }, [setColorTypes, hexCode]);

  /**
   * function to handleHue
   */
  const handleHueChange = (hue: tinycolor.ColorInput) => {
    const colorToSet = tinycolor(hue);
    setColorTypes({ hsv: colorToSet.toHsv(), hsl: colorToSet.toHsl(), hex: colorToSet.toHex() });
    onChange(colorToSet.toHex());
  };

  /**
   * function to handleHue
   */
  const handleSaturationChange = (hsv: tinycolor.ColorInput) => {
    const colorToSet = tinycolor(hsv);
    setColorTypes({ hsv: colorToSet.toHsv(), hsl: colorToSet.toHsl(), hex: colorToSet.toHex() });
    onChange(colorToSet.toHex());
  };

  /**
   * Pointer to select custom
   */
  const CustomPointer = () => {
    const inlineStyles = {
      pointer: {
        width: "18px",
        height: "18px",
        borderRadius: "50%",
        transform: "translate(-9px, -1px)",
        backgroundColor: hexCode,
        border: "1px solid #ebebeb",
        boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.37)",
      },
    };
    return <div style={inlineStyles.pointer} />;
  };

  return (
    <DivContainerColorCustomize>
      <DivSaturation>
        <Saturation
          hsl={colorTypes.hsl}
          hsv={colorTypes.hsv}
          onChange={handleSaturationChange}
          pointer={CustomPointer}
        />
      </DivSaturation>
      <DivHue>
        <Hue hsl={colorTypes.hsl} onChange={handleHueChange} direction={"horizontal"} pointer={CustomPointer} />
      </DivHue>
    </DivContainerColorCustomize>
  );
});

const DivContainerColorCustomize = styled("div")`
  border-top: 1px solid #ebebeb;
`;
const DivSaturation = styled("div")`
  position: relative;
  height: 140px;
  margin: 0.5vh 0.5vw 0.25vh 0.5vw;
`;

const DivHue = styled("div")`
  position: relative;
  margin: 0.5vh 0.5vw 0.25vh 0.5vw;
  height: 16px;
`;

const ColorDropdown = styled("div")`
  min-width: 256px;
  width: 256px;
  > div > div:first-child {
    padding-top: 1vh;
    padding-left: 0.5vw;
  }
  > div > div:last-child {
    margin-top: 0;
  }
  hr {
    border-bottom: none;
    border-top: 1px solid ${props => props.theme.palette.aluminium};
  }
`;
const ColorInput = styled("div")`
  display: flex;
  flex-direction: row;
  margin: 12px;
  align-items: center;

  input {
    font-family: Vodafone Rg;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    color: ${props => props.theme.palette.midGrey};
  }
`;
const ColorBadges = styled("div")<{ backgroundColor: string }>`
  width: 32px;
  height: 32px;
  background-color: ${props => props.backgroundColor};
  border-radius: 50%;
  cursor: pointer;
  margin: 4px;
`;

const ColorCheck = styled("a")`
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin-left: auto;
  svg {
    stroke: ${props => props.theme.palette.vodafoneRed};
    width: 25px;
    height: 25px;
  }
`;

const BadgesSection = styled("div")`
  border-top: 1px solid ${props => props.theme.palette.aluminium};
  padding: 12px 8px;
  display: flex;
  flex-wrap: wrap;
`;

const DropdownLabelContainer = styled("div")`
  display: flex;
  align-items: center;
  margin-left: -12px;
  > span {
    margin-left: 0.5vw;
  }
`;

const ColorContainer = styled("div")<{ backgroundColor: string }>`
  background-color: ${props => props.backgroundColor};
  width: 37px;
  height: 34px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
`;

const ColorText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: ${props => props.theme.palette.midGrey};
`;
