import { Table, useAlert, useFilters } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IStoreInterface } from "../../../configs/store.config";
import TasksFilters from "./components/tasks-filters.component";
import { TasksActions } from "./tasks.store";
import { ITask } from "./task.model";
import TasksApi from "./tasks.api";
import { filterTasks, getTasksColumns, renderTableTasksButtons } from "./tasks.utils";
import { RoutesEnum } from "../../../routes/routes.constants";
import { useHistory } from "react-router-dom";

const TasksPage = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const history = useHistory();
  const [showAlert, , setAlertProps] = useAlert();
  const { tasks } = useSelector((state: IStoreInterface) => state.tasksReducer);
  const [filtersResult, setFiltersResult] = useState<ITask[]>();
  const {
    filters,
    updateFilter,
    isFilterActive,
    clearFilter,
    resetFilters,
    getFilterValue,
    getFiltersQueryString,
    updateMultipleFilters,
  } = useFilters();

  React.useEffect(() => {
    if (filters.size > 0) {
      const newTasks = filterTasks(tasks ? tasks : [], filters);
      setFiltersResult(newTasks);
    } else {
      setFiltersResult(undefined);
    }
  }, [filters, tasks]);

  React.useEffect(() => {
    getTasks();
  }, []);

  /*
   * Gets the list of tasks
   * */
  const getTasks = () => {
    TasksApi.methods.getMyTasks().then(
      res => {
        dispatch(TasksActions.creators.getTasksSuccess(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.myTasks.getTasksError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /*
   * See details handler
   * */
  const seeDetails = (task: ITask) => {
    history.push(RoutesEnum.TASK_DETAILS.replace(":taskId", task.id as string));
  };

  return (
    <>
      <TasksFilters
        filters={filters}
        isFilterActive={isFilterActive}
        updateFilter={updateFilter}
        clearFilter={clearFilter}
        resetFilters={resetFilters}
        getFilterValue={getFilterValue}
        getFiltersQueryString={getFiltersQueryString}
        updateMultipleFilters={updateMultipleFilters}
      />
      {filtersResult || tasks ? (
        <Table
          columns={getTasksColumns(t)}
          values={filtersResult || tasks}
          displayActions={task =>
            renderTableTasksButtons<ITask>(task, {
              seeDetails: { onClick: seeDetails },
            })
          }
        />
      ) : null}
    </>
  );
};

export default TasksPage;
