import { AutoClosingDropdown, PlusIcon, SearchBar, SmallButton } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { FilterFuncts } from "@wit/mpesa-ui-components/lib/hooks/use-filters.hook";

import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { RoutesEnum } from "../../../../routes/routes.constants";
import { Column, Row } from "../../../../shared/shared.styled";
import { DiscoverCardsStatusEnum } from "../discover-cards.model";

export enum DiscoverCardsFiltersEnum {
  SEARCH = "search",
  STATUS = "status",
}

interface IDiscoverCardsFiltersProps extends FilterFuncts {
  hasSubmittedVersion?: boolean;
}

const DiscoverCardsFilters = ({
  filters,
  clearFilter,
  isFilterActive,
  resetFilters,
  updateFilter,
  hasSubmittedVersion = false,
}: IDiscoverCardsFiltersProps) => {
  const [t] = useTranslation();
  const history = useHistory();

  /**
   * Returns the discover cards status options to be used on the status filter
   */
  const getStatusOptions = () => {
    const status: {
      label: string;
      key: DiscoverCardsStatusEnum | undefined;
    }[] = Object.values(DiscoverCardsStatusEnum).map(status => ({
      label: i18next.t(`commons.discoverCards.discoverCardsStatusEnumDropdown.${status}`),
      key: status,
    }));
    status.push({
      label: i18next.t(`commons.apps.enums.platform.seeAll`),
      key: undefined,
    });
    return status;
  };

  return (
    <DiscoverCardsFilterContainer>
      <div style={{ flex: "30%", flexShrink: 0, flexGrow: 1 }} id={"search-discover-cards"}>
        <SearchBar
          placeholderLabel={t("pages.discoverCards.search")}
          value={
            filters.has(DiscoverCardsFiltersEnum.SEARCH)
              ? (filters.get(DiscoverCardsFiltersEnum.SEARCH) as string[])[0]
              : ""
          }
          onChange={e => {
            if (e.target.value) {
              updateFilter(DiscoverCardsFiltersEnum.SEARCH, e.target.value, true);
            } else {
              clearFilter(DiscoverCardsFiltersEnum.SEARCH);
            }
          }}
          clearValue={() => clearFilter(DiscoverCardsFiltersEnum.SEARCH)}
        />
      </div>

      <div style={{ flex: "20%", flexShrink: 0, flexGrow: 1 }} id={"status-dropdown"}>
        <AutoClosingDropdown
          label={
            filters.has(DiscoverCardsFiltersEnum.STATUS)
              ? t(
                  `commons.discoverCards.discoverCardsStatusEnumDropdown.${
                    (filters.get(DiscoverCardsFiltersEnum.STATUS) as string[])[0]
                  }`,
                )
              : t("pages.discoverCards.status")
          }
          options={getStatusOptions()}
          selectOption={val => {
            if (val.key) {
              updateFilter(DiscoverCardsFiltersEnum.STATUS, val.key, true);
            } else {
              clearFilter(DiscoverCardsFiltersEnum.STATUS);
            }
          }}
          hasValue={filters.has(DiscoverCardsFiltersEnum.STATUS)}
        />
      </div>
      <Column
        style={{ flex: "30%", flexShrink: 0, flexGrow: 1, display: "flex", alignItems: "flex-end" }}
        id={"add-new-discover-card"}
      >
        <SmallButton
          disabled={hasSubmittedVersion}
          titleLabel={t("pages.discoverCards.addDiscoverCard")}
          onClick={() => {
            history.push(RoutesEnum.ADD_DISCOVER_CARD);
          }}
          iconComponent={
            <SmallButtonIconContainer color={styleTheme.palette.vodafoneRed}>
              <PlusIcon />
            </SmallButtonIconContainer>
          }
        />
      </Column>
    </DiscoverCardsFilterContainer>
  );
};

export default DiscoverCardsFilters;

const DiscoverCardsFilterContainer = styled(Row)`
  margin-bottom: 49px;
  margin-top: 48px;
  width: 100%;
  flex-wrap: wrap;
  gap: 24px;
  @media (max-width: 768px) {
    > div {
      flex: 45% !important;
    }
  }
`;

const SmallButtonIconContainer = styled("div")<{ color: string }>`
  width: 16px;
  height: 16px;
  stroke: ${props => props.color};

  svg {
    width: 16px;
    height: 16px;
    stroke: ${props => props.color};
  }
`;
