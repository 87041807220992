import React from "react";
import { Table, useAlert, useFilters } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { useTranslation } from "react-i18next";
import TablePagination from "../../../../../shared/components/table-pagination/table-pagination-component";
import { RequestMoneyUtils } from "../../request-money.utils";
import RequestMoneyApi from "../../request-money.api";
import { IReport, IReportedUsersParameters } from "../../request-money.interfaces";
import { LoadingText } from "../../../../../shared/shared.styled";
import { RoutesEnum } from "../../../../../routes/routes.constants";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ReportsActions } from "../../request-money.store";
import ReportedUsersFilters from "./reported-users-filters.component";
import { ReportedUsersFilterKeys } from "./reported-users-filters.component";
import moment from "moment";

const mockedSearchData = (reports: IReport[], requestParams: IReportedUsersParameters): IReport[] => {
  return (
    //search 'All'
    requestParams.reportedNumber && requestParams.reportedBy
      ? reports.filter(
          report =>
            report.reportedNumber.includes(requestParams.reportedNumber as string) ||
            report.reportedBy.includes(requestParams.reportedBy as string),
        )
      : //search only reported numbers
      requestParams.reportedNumber
      ? reports.filter(report => report.reportedNumber.includes(requestParams.reportedNumber as string))
      : //search only reported by numbers
      requestParams.reportedBy
      ? reports.filter(report => report.reportedBy.includes(requestParams.reportedBy as string))
      : reports
  );
};

const mockedFilterReportingDate = (reports: IReport[], requestParams: IReportedUsersParameters): IReport[] => {
  let startDate: moment.Moment;
  let endDate: moment.Moment;
  if (requestParams.reportingDateDateRange && requestParams.reportingDateDateRange.includes("LAST_WEEK")) {
    startDate = moment().subtract(1, "week");
    endDate = moment();
  } else if (requestParams.reportingDateDateRange && requestParams.reportingDateDateRange.includes("LAST_MONTH")) {
    startDate = moment().subtract(1, "month");
    endDate = moment();
  } else if (
    requestParams.reportingDateDateRange &&
    requestParams.reportingDateDateRange.includes("CUSTOM") &&
    requestParams.reportingDateStartDate &&
    requestParams.reportingDateEndDate
  ) {
    startDate = moment(requestParams.reportingDateStartDate[0]);
    endDate = moment(requestParams.reportingDateEndDate[0]);
  } else {
    return reports;
  }
  return reports.filter(report => moment(report.reportingDate, "DD/MM/YYYY").isBetween(startDate, endDate));
};

const mockedFilterUpdatedOn = (reports: IReport[], requestParams: IReportedUsersParameters): IReport[] => {
  let startDate: moment.Moment;
  let endDate: moment.Moment;
  if (requestParams.updatedOnDateRange && requestParams.updatedOnDateRange.includes("LAST_WEEK")) {
    startDate = moment().subtract(1, "week");
    endDate = moment();
  } else if (requestParams.updatedOnDateRange && requestParams.updatedOnDateRange.includes("LAST_MONTH")) {
    startDate = moment().subtract(1, "month");
    endDate = moment();
  } else if (
    requestParams.updatedOnDateRange &&
    requestParams.updatedOnDateRange.includes("CUSTOM") &&
    requestParams.updatedOnStartDate &&
    requestParams.updatedOnEndDate
  ) {
    startDate = moment(requestParams.updatedOnStartDate[0]);
    endDate = moment(requestParams.updatedOnEndDate[0]);
  } else {
    return reports;
  }
  return reports.filter(report => moment(report.updatedOn, "DD/MM/YYYY").isBetween(startDate, endDate));
};

const mockedFilterReason = (reports: IReport[], requestParams: IReportedUsersParameters): IReport[] => {
  return reports.filter(report => requestParams.reason && requestParams.reason.includes(report.reason));
};

const mockedFilterStatus = (reports: IReport[], requestParams: IReportedUsersParameters): IReport[] => {
  return reports.filter(report => requestParams.status && requestParams.status.includes(report.status));
};

const ReportedUsersTab = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const [reports, setReports] = React.useState<IReport[]>([]);
  const [isFetchingReports, setIsFetchingReports] = React.useState(true);
  const [page, setActivePage] = React.useState(1);
  const pageSize = 20;
  const [totalReports, setTotalReports] = React.useState(0);
  const [requestParams, setRequestParams] = React.useState<IReportedUsersParameters>({
    page: 1,
    pageSize: 20,
  });
  const {
    filters,
    updateFilter,
    isFilterActive,
    clearFilter,
    resetFilters,
    getFilterValue,
    getFiltersQueryString,
    updateMultipleFilters,
  } = useFilters();

  /*
   * Get reported users method
   * */
  const getReportedUsers = () => {
    setIsFetchingReports(true);
    RequestMoneyApi.methods
      .getReportedUsers()
      .finally(() => setIsFetchingReports(false))
      .then(
        res => {
          //TODO: change when BE integration
          //fake pagination, search and filters with mocked data
          let allReports = res.data.reports;

          //search method when BE integration is not done
          allReports =
            requestParams.reportedNumber || requestParams.reportedBy
              ? mockedSearchData(allReports, requestParams)
              : allReports;
          allReports = requestParams.reportingDateDateRange
            ? mockedFilterReportingDate(allReports, requestParams)
            : allReports;
          allReports = requestParams.updatedOnDateRange ? mockedFilterUpdatedOn(allReports, requestParams) : allReports;
          allReports = requestParams.reason ? mockedFilterReason(allReports, requestParams) : allReports;
          allReports = requestParams.status ? mockedFilterStatus(allReports, requestParams) : allReports;

          setTotalReports(allReports.length);
          const reportsPage = allReports.slice((page - 1) * pageSize, page * pageSize);
          setReports(reportsPage);
        },
        () => {
          setAlertProps({
            title: t("pages.tools.requestMoney.reportedUsers.getReportsError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
  };

  /*
   * Open details page method
   * */
  const openDetailsPage = (report: IReport) => {
    dispatch(ReportsActions.creators.setReportDetailAction(report));
    history.push(RoutesEnum.REQUEST_MONEY_REPORT.replace(":id", report.reportID));
  };

  const refreshResults = () => {
    setActivePage(1);
    getReportedUsers();
  };

  /*
   * Handles the pagination change
   * */
  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
  };

  const getReportedUsersFilterKey = (filterKey: ReportedUsersFilterKeys) => {
    if (filters.get(filterKey)) {
      return { [filterKey]: filters.get(filterKey) as string[] };
    }
    return null;
  };

  React.useEffect(() => {
    getReportedUsers();
  }, [page]);

  React.useEffect(() => {
    let request: IReportedUsersParameters = { page, pageSize };
    request = {
      ...request,
      ...getReportedUsersFilterKey(ReportedUsersFilterKeys.REPORTED_NUMBER),
      ...getReportedUsersFilterKey(ReportedUsersFilterKeys.REPORTED_BY),
      ...getReportedUsersFilterKey(ReportedUsersFilterKeys.REPORTING_DATE_DATE_RANGE),
      ...getReportedUsersFilterKey(ReportedUsersFilterKeys.REPORTING_DATE_START_DATE),
      ...getReportedUsersFilterKey(ReportedUsersFilterKeys.REPORTING_DATE_END_DATE),
      ...getReportedUsersFilterKey(ReportedUsersFilterKeys.UPDATED_ON_DATE_RANGE),
      ...getReportedUsersFilterKey(ReportedUsersFilterKeys.UPDATED_ON_START_DATE),
      ...getReportedUsersFilterKey(ReportedUsersFilterKeys.UPDATED_ON_END_DATE),
      ...getReportedUsersFilterKey(ReportedUsersFilterKeys.REASON),
      ...getReportedUsersFilterKey(ReportedUsersFilterKeys.STATUS),
    };
    setRequestParams(request);
  }, [filters]);

  React.useEffect(() => {
    refreshResults();
  }, [requestParams]);

  return (
    <>
      <ReportedUsersFilters
        filters={filters}
        isFilterActive={isFilterActive}
        updateFilter={updateFilter}
        clearFilter={clearFilter}
        resetFilters={resetFilters}
        getFilterValue={getFilterValue}
        getFiltersQueryString={getFiltersQueryString}
        updateMultipleFilters={updateMultipleFilters}
        reportedUsersCount={totalReports}
        refreshResults={refreshResults}
      ></ReportedUsersFilters>
      {isFetchingReports ? (
        <LoadingText>{t("commons.loadingResults")}</LoadingText>
      ) : (
        <>
          <Table
            values={reports}
            columns={RequestMoneyUtils.getReportedUsersColumns()}
            rowClickAction={(idx, log) => openDetailsPage(log)}
          />
          {totalReports > pageSize ? (
            <TablePagination
              handlePageChange={handlePageChange}
              totalItems={totalReports}
              activePage={page}
              pageSize={pageSize}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default ReportedUsersTab;
