import { Table } from "@wit/mpesa-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { LoadingText } from "../../../../shared/shared.styled";
import SupportApi from "../../support.api";
import {
  EComponentTypes,
  IComponentVersion,
  getComponentIcon,
  parseComponentVersion,
} from "./component-versions.utils";

const ComponentVersionsTab = () => {
  const [t] = useTranslation();
  const [componentVersions, setComponentVersions] = React.useState<IComponentVersion[]>([
    { component: EComponentTypes.FRONTEND, version: process.env.REACT_APP_VERSION as string },
  ]);
  const [isLoading, setIsLoading] = React.useState(false);

  /**
   * Fetch every component version
   */
  React.useEffect(() => {
    setIsLoading(true);
    SupportApi.methods
      .getVersions()
      .then(res => {
        setComponentVersions(prev => [...prev, ...res.data]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingText>{t("commons.loadingResults")}</LoadingText>
      ) : (
        <Table
          isStripedTable={true}
          rowShouldntHighlight={true}
          columns={[
            {
              formKey: "component",
              label: t("pages.support.componentVersions.components"),
              isEditable: false,
              ratio: 6 / 12,
              changeFunction: content => {
                return (
                  <ComponentContainer>
                    <IconContainer>{getComponentIcon(content)}</IconContainer>
                    <ComponentName>{t(`pages.support.componentVersions.${content}`)}</ComponentName>
                  </ComponentContainer>
                );
              },
            },
            {
              formKey: "version",
              label: t("pages.support.componentVersions.version"),
              isEditable: false,
              ratio: 6 / 12,
              changeFunction: content => {
                return <ComponentVersion>{parseComponentVersion(content)}</ComponentVersion>;
              },
            },
          ]}
          values={componentVersions}
        />
      )}
    </>
  );
};
const ComponentName = styled("span")`
  font-family: Vodafone Rg;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: ${props => props.theme.palette.darkGrey};
`;

const ComponentVersion = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  line-height: 21px;
  color: ${props => props.theme.palette.darkGrey};
`;

const ComponentContainer = styled("div")`
  display: flex;
  align-items: center;
  height: 44px;
`;

const IconContainer = styled("div")`
  display: flex;
  margin-right: 8px;
`;

export default ComponentVersionsTab;
