import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Magnifier } from "@wit/mpesa-ui-components";

const EmptySearchResult = ({ title }: { title?: string }) => {
  const [t] = useTranslation();

  return (
    <NoSearchResult>
      <ImageIcon>
        <Magnifier />
      </ImageIcon>
      <TextLabel>{title ? title : t("components.emptySearchResult.title")}</TextLabel>
    </NoSearchResult>
  );
};

export default EmptySearchResult;

const NoSearchResult = styled.div`
  margin-top: 192px;
  margin-bottom: 192px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const ImageIcon = styled("span")`
  margin-bottom: 24px;
  svg {
    width: 140px;
    height: 140px;
  }
`;

const TextLabel = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.midGrey};
`;
