import React, { HTMLProps } from "react";

const Document = (props?: HTMLProps<SVGSVGElement>) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="192" height="192" viewBox="0 0 192 192">
      <defs>
        <path
          id="A"
          d="M0 28v116c0 8.837 7.163 16 16 16h80c8.837 0 16-7.163 16-16V40H88c-8.837 0-16-7.163-16-16V0H16C7.163 0 0 7.163 0 16v12"
        ></path>
        <filter id="B" width="118.8%" height="113.1%" x="-9.4%" y="-4.7%">
          <feOffset dy="3" in="SourceAlpha"></feOffset>
          <feGaussianBlur stdDeviation="3"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.556640625 0 0 0 0 0.556640625 0 0 0 0 0.556640625 0 0 0 0.23 0"></feColorMatrix>
        </filter>
        <linearGradient id="C" x1="-23.953%" x2="75.61%" y1="24.29%" y2="124.265%">
          <stop offset="0%" stopOpacity="0.571"></stop>
          <stop offset="100%" stopOpacity="0"></stop>
        </linearGradient>
      </defs>
      <g fillRule="evenodd" transform="translate(40 16)">
        <use filter="url(#B)" href="#A"></use>
        <use fill="#f4f4f4" href="#A"></use>
        <g fillRule="nonzero">
          <path fill="#ebebeb" d="M112 40H88c-8.837 0-16-7.163-16-16V0l40 40z"></path>
          <path d="M88 38c-8.837 0-16-7.163-16-16v2c0 8.837 7.163 16 16 16h24l-2-2H88z" opacity="0.06"></path>
        </g>
        <path
          fill="url(#C)"
          d="M88 40c-.53 0-1.05 0-1.57-.08l-.62-.09c-.3 0-.6-.08-.89-.14l-.79-.19-.65-.17-.9-.3-.47-.17-1-.43-.32-.16a10.73 10.73 0 01-1-.56l-.2-.12a9.48 9.48 0 01-1-.7l-.09-.07c-.36-.27-.71-.55-1-.84L112 70.56V40H88z"
          opacity="0.15"
        ></path>
        <g fillRule="nonzero">
          <g fill="#4a4d4e">
            <rect width="64" height="4" x="24" y="97" rx="1.41"></rect>
            <rect width="64" height="4" x="24" y="113" rx="1.41"></rect>
          </g>
          <rect width="64" height="4" x="24" y="81" fill="#e90000" rx="1.41"></rect>
          <path
            d="M96 158H16c-8.837 0-16-7.163-16-16v2c0 8.837 7.163 16 16 16h80c8.837 0 16-7.163 16-16v-2c0 8.837-7.163 16-16 16z"
            opacity="0.06"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default Document;
