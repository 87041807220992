import React from "react";
import styled from "styled-components";
import { ICardsComponentProps } from "../feedback-and-problems.model";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";

const CardsComponent = ({ title, data, footer, titleColor, dataColor }: ICardsComponentProps) => {
  return (
    <CardContainer>
      <Title color={titleColor}>{title}</Title>
      <Data color={dataColor}>{data}</Data>
      <Footer>{footer}</Footer>
    </CardContainer>
  );
};

export default CardsComponent;

const CardContainer = styled("div")`
  width: 270px;
  height: 190px;
  display: flex;
  font-family: "Vodafone Rg";
  padding: 18px;
  text-align: center;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0px 3px 6px rgba(142, 142, 142, 0.25), 0px 3px 10px rgba(0, 0, 0, 0.1);

  p {
    margin: 0;
  }
`;

const Title = styled("p")<{ color?: string }>`
  color: ${props => (props.color ? props.color : styleTheme.palette.midGrey)};
  font-size: 13px;
`;

const Data = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: ${props => (props.color ? props.color : styleTheme.palette.midGrey)};

  p {
    font-size: 42px;
  }
`;

const Footer = styled("div")`
  padding-top: 18px;
  font-size: 14px;
  color: ${styleTheme.palette.greyDarker};
  border-top: 1px solid ${styleTheme.palette.greyLightest};
`;
