import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { PrimaryButton, TextArea } from "@wit/mpesa-ui-components";
import { useTranslation } from "react-i18next";
import Dropzone from "../../../shared/components/csv-dropzone.component";
import { Formik } from "formik";
import ServiceDetailsApi from "../service-details.api";

interface IWhitelistModalProps {
  whitelist?: string;
  hideModal: () => void;
  onConfirm: (validMsisdn: string[], invalidMsisdn: string[], suggestion: string) => void;
  onError: () => void;
}

/**
 * WhitelistModal component
 */
const WhitelistModal = ({ whitelist = "", hideModal, onConfirm, onError }: IWhitelistModalProps) => {
  // Hooks initialization
  const [t] = useTranslation();
  const [actualWhitelist, setActualWhitelist] = useState(whitelist);
  const [whitelistError, setWhitelistError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [csvFile, setCsvFile] = useState<any>();
  const onDrop = useCallback(
    acceptedFile => {
      if (acceptedFile.length > 0) {
        const file: File = acceptedFile[0];
        setCsvFile(file);
        return file;
      }
    },
    // eslint-disable-next-line
    [],
  );

  const parseWhitelist = () => {
    if (csvFile) {
      ServiceDetailsApi.methods
        .msisdnCsvValidation(csvFile)
        .finally()
        .then(
          res => {
            onConfirm(
              res.data.validMsisdn,
              res.data.invalidMsisdn,
              res.data.msisdnSuggestion ? res.data.msisdnSuggestion.join() : "",
            );
            setIsSubmitting(false);
          },
          err => {
            onError();
            setIsSubmitting(false);
          },
        );
    } else {
      const whitelistAux = actualWhitelist.split(",").map((str: string) => {
        return str.trim();
      });
      ServiceDetailsApi.methods
        .msisdnTextValidation(whitelistAux)
        .finally()
        .then(
          res => {
            onConfirm(
              res.data.validMsisdn,
              res.data.invalidMsisdn,
              res.data.msisdnSuggestion ? res.data.msisdnSuggestion.join() : "",
            );
            setIsSubmitting(false);
          },
          err => {
            onError();
            setIsSubmitting(false);
          },
        );
    }
    hideModal();
  };

  return (
    <WhitelistDiv>
      <ModalTitle>{t("pages.serviceBuilder.modals.whitelist.title")}</ModalTitle>
      <TopDiv>
        <WhitelistDivWrapper>
          <TextArea
            title={t("pages.serviceBuilder.modals.whitelist.whitelistText.title")}
            placeholder={t("pages.serviceBuilder.modals.whitelist.whitelistText.placeholder")}
            name="version"
            value={actualWhitelist}
            error={whitelistError}
            autoComplete="off"
            onChange={evt => {
              if (evt.target.value !== "") {
                setWhitelistError("");
              }
              setActualWhitelist(evt.target.value);
            }}
            maxHeight="400px"
          />

          <Formik
            onSubmit={() => {}}
            validateOnChange={false}
            initialValues={{
              csvFile: null,
            }}
            render={({ setFieldValue, errors, setErrors }) => (
              <AddCsvForm>
                <div>
                  <DropzoneTitle mandatory={false}>
                    {t("pages.serviceBuilder.modals.whitelist.whitelistText.dropzoneTitle")}
                  </DropzoneTitle>
                  {errors.csvFile ? <CsvError>{errors.csvFile}</CsvError> : null}
                </div>
                <div id="whitelistFile">
                  <Dropzone
                    fileName={csvFile ? csvFile.name : ""}
                    inputName="serviceVersion"
                    onDrop={(file: any) => {
                      if (!file[0]) {
                        setErrors({
                          ...errors,
                          csvFile: t("pages.serviceBuilder.modals.whitelist.whitelistText.errorFile"),
                        });
                      } else {
                        setErrors({
                          ...errors,
                          csvFile: "",
                        });
                        setFieldValue("serviceVersion", file);
                        onDrop(file);
                      }
                    }}
                    accept={".csv"}
                    multiple={false}
                    dropTitle={t("pages.serviceBuilder.modals.whitelist.dropzone.dropTitle")}
                    dropDescription={t("pages.serviceBuilder.modals.whitelist.dropzone.dropDescription")}
                    releaseText={t("pages.serviceBuilder.modals.whitelist.dropzone.release")}
                  />
                </div>
              </AddCsvForm>
            )}
          />

          <ButtonsWrapper>
            <ButtonDiv>
              <PrimaryButton
                id={"cancel-button"}
                titleLabel={t("pages.serviceBuilder.modals.whitelist.cancel.title")}
                type="button"
                redTheme={false}
                onClick={() => {
                  hideModal();
                }}
              />
            </ButtonDiv>
            <ButtonDiv>
              <PrimaryButton
                id={"continue-button"}
                loading={isSubmitting}
                titleLabel={t("pages.serviceBuilder.modals.whitelist.save.title")}
                type="submit"
                onClick={() => {
                  if (actualWhitelist === "" && !csvFile) {
                    setWhitelistError(t("pages.serviceBuilder.modals.whitelist.whitelistText.errorRequired"));
                  } else {
                    setIsSubmitting(true);
                    parseWhitelist();
                  }
                }}
                redTheme={true}
              />
            </ButtonDiv>
          </ButtonsWrapper>
        </WhitelistDivWrapper>
      </TopDiv>
    </WhitelistDiv>
  );
};

export default WhitelistModal;

const WhitelistDiv = styled("div")`
  display: flex;
  padding: 20px;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const WhitelistDivWrapper = styled("div")`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: 10px;
  }
`;

const TopDiv = styled("div")`
  display: flex;
  flex-direction: row;
`;
const ModalTitle = styled("div")`
  font-size: 22px;
  font-family: Vodafone Rg;
  color: inherit;
  margin-bottom: 13px;
`;

const ButtonsWrapper = styled("div")`
  margin-top: auto;
  width: 100%;
  display: flex;
  margin-top: 40px;
  justify-content: flex-end;
`;
const ButtonDiv = styled("div")`
  width: 200px;
  margin-right: 20px;
  &:last-of-type {
    margin-right: 0px;
  }
`;

const AddCsvForm = styled("form")`
  display: flex;
  padding-top: 10px;
  width: 100%;
  flex-direction: column;
  margin-top: 10px;
`;

const DropzoneTitle = styled("div")<{ mandatory: boolean }>`
  min-height: 21px;
  font-family: Vodafone Rg;
  font-weight: bold;
  line-height: 21px;
  font-size: 16px;
  color: #999999;
  margin-bottom: 8px;
  width: fit-content;
  display: inline-flex;
  ${props =>
    props.mandatory ? "::after {content: ' *'; color: #ff0000; font-weight: 400; padding-left: 2px;}" : null};
`;

const CsvError = styled("div")`
  width: fit-content;
  display: inline-flex;
  height: 19px;
  color: #ff0000;
  font-family: Vodafone Rg;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  float: right;
  font-weight: 400;
`;
