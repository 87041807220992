import { ModalTypeEnum, useModal } from "@wit/mpesa-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IConfig, ConfigContext } from "../app.component";
import { IStoreInterface } from "../configs/store.config";
import AuthenticationApi from "../features/authentication/authentication.api";
import { ILoginResponse } from "../features/authentication/authentication.interfaces";
import { AuthenticationActions } from "../features/authentication/authentication.store";
import NavigationBar from "../shared/components/navigation-bar.component";
import { INotification } from "../shared/components/notifications-area/notification.model";
import NotificationsDrawer from "../shared/components/notifications-area/notifications-drawer.component";
import NotificationsApi from "../shared/components/notifications-area/notifications.api";
import UserDrawer from "../shared/components/user-area/user-drawer.component";
import { loginSuccessHandler, logoutSuccessHandler } from "../shared/utils/login.utils";
import { RoutesEnum } from "./routes.constants";

const AuthenticatedRoutes = ({ children }: React.PropsWithChildren<{}>) => {
  const [drawerVisible, setDrawerVisible] = React.useState(false);
  const [notificationsVisible, setNotificationsVisible] = React.useState(false);
  const [notifications, setNotifications] = React.useState<INotification[]>([]);
  const [hasUnreadNotifications, setHasUnreadNotifications] = React.useState(false);
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const loginFromModal: boolean = true;

  const modalState = useSelector((state: IStoreInterface) => state.authenticationReducer.showModal);

  const { username, is2FAEnabled } = useSelector(
    (state: IStoreInterface) => state.authenticationReducer.loggedUser as ILoginResponse,
  );

  /**
   * A function that dispatches a payload to our store
   * @param {any} payload
   */
  const dispatcher = (payload: any): void => dispatch(payload);

  const renewSession = (password: string) => {
    if (is2FAEnabled) {
      localStorage.setItem("twoFactorAuthenticated", "false");
    }
    AuthenticationApi.methods.login({ username, password }).then(
      res => {
        loginSuccessHandler(res, dispatcher, loginFromModal).then(() => {
          if (res.data.is2FAEnabled) {
            hideModal();
            show2FAModal();
          } else {
            loginSuccessful();
          }
        });
      },
      err => {
        setModalProps({
          title: t("commons.modals.sessionExpired.title"),
          description: t("commons.modals.sessionExpired.description"),
          primaryAction: renewSession,
          secondaryAction: logout,
          usernameLabel: t("commons.modals.sessionExpired.usernameLabel"),
          passwordLabel: t("commons.modals.sessionExpired.passwordLabel"),
          inputPlaceholder: t("commons.modals.sessionExpired.passwordPlaceholder"),
          primaryBtnLabel: t("commons.modals.sessionExpired.submitButton"),
          secondaryBtnLabel: t("commons.modals.sessionExpired.changeAccountButton"),
          username: username,
          error:
            err && err.data && err.data.status && err.data.status.message
              ? err.data.status.message
              : t("commons.modals.sessionExpired.loginError"),
        });
      },
    );
  };

  const loginSuccessful = () => {
    dispatch(AuthenticationActions.creators.toggleModalAction(false));

    /*
      TODO: Restore history.push once the React Router is upgraded to v6 - for now we will just refresh the page
      --> history.push(window.location.pathname.replace(process.env.REACT_APP_BASE_HREF as string, ""));
    */
    window.location.reload();
  };

  const validate2FA = (otpCode: string) => {
    AuthenticationApi.methods.validate2FAOTPCode(otpCode).then(
      () => {
        loginSuccessful();
      },
      () => {
        set2FAModalProps({
          title: t("commons.modals.sessionExpired.title"),
          description: t("commons.modals.sessionExpired.description"),
          primaryAction: validate2FA,
          secondaryAction: logout,
          usernameLabel: "",
          username: t("commons.modals.sessionExpired.twoFactorAuthenticationDescription"),
          passwordLabel: t("commons.modals.sessionExpired.verificationCode"),
          inputPlaceholder: t("commons.modals.sessionExpired.verificationCodePlaceholder"),
          inputType: "text",
          primaryBtnLabel: t("commons.modals.sessionExpired.submitButton"),
          secondaryBtnLabel: t("commons.modals.sessionExpired.changeAccountButton"),
          error: t("commons.modals.sessionExpired.validate2FAError"),
        });
      },
    );
  };

  const [showModal, hideModal, setModalProps] = useModal(ModalTypeEnum.SessionExpiredModal, {
    title: t("commons.modals.sessionExpired.title"),
    description: t("commons.modals.sessionExpired.description"),
    usernameLabel: t("commons.modals.sessionExpired.usernameLabel"),
    passwordLabel: t("commons.modals.sessionExpired.passwordLabel"),
    inputPlaceholder: t("commons.modals.sessionExpired.passwordPlaceholder"),
    primaryBtnLabel: t("commons.modals.sessionExpired.submitButton"),
    secondaryBtnLabel: t("commons.modals.sessionExpired.changeAccountButton"),
    primaryAction: renewSession,
    secondaryAction: logout,
    username: username,
  } as any);

  const [show2FAModal, hide2FAModal, set2FAModalProps] = useModal(ModalTypeEnum.SessionExpiredModal, {
    title: t("commons.modals.sessionExpired.title"),
    description: t("commons.modals.sessionExpired.description"),
    primaryAction: validate2FA,
    secondaryAction: logout,
    usernameLabel: "",
    username: t("commons.modals.sessionExpired.twoFactorAuthenticationDescription"),
    passwordLabel: t("commons.modals.sessionExpired.verificationCode"),
    inputPlaceholder: t("commons.modals.sessionExpired.verificationCodePlaceholder"),
    primaryBtnLabel: t("commons.modals.sessionExpired.submitButton"),
    secondaryBtnLabel: t("commons.modals.sessionExpired.changeAccountButton"),
    inputType: "text",
  } as any);

  React.useEffect(() => {
    if (modalState) {
      showModal();
    } else {
      hideModal();
      hide2FAModal();
    }
  }, [modalState, showModal, hideModal]);

  const getNotifications = () => {
    NotificationsApi.methods.getNotifications().then(res => {
      setNotifications(res.data);
      if (res.data && res.data.length) {
        res.data.some((n: INotification) => !n.read)
          ? setHasUnreadNotifications(true)
          : setHasUnreadNotifications(false);
      }
    });
  };

  function logout() {
    AuthenticationApi.methods
      .logout()
      .then(() => {})
      .finally(() => {
        logoutSuccessHandler(dispatcher);
        hideModal();
        history.push(RoutesEnum.LOGIN);
      });
  }

  return (
    <>
      <ConfigContext.Consumer>
        {({ config }) => (
          <>
            <NavigationBar
              config={config as IConfig}
              setDrawerVisible={setDrawerVisible}
              setNotificationsVisible={setNotificationsVisible}
              setNotifications={setNotifications}
              getNotifications={getNotifications}
              hasUnreadNotifications={hasUnreadNotifications}
            />
            <UserDrawer isVisible={drawerVisible} onLogoutClick={logout} hideDrawer={() => setDrawerVisible(false)} />
            <NotificationsDrawer
              isVisible={notificationsVisible}
              hideDrawer={() => setNotificationsVisible(false)}
              notifications={notifications as INotification[]}
              getNotifications={getNotifications}
            />
            {children}
          </>
        )}
      </ConfigContext.Consumer>
    </>
  );
};

export default AuthenticatedRoutes;
