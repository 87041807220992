import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { VersionItem } from "../../../../shared/hooks/use-version-diff/use-version-diff.hook";
import VersionItemStatusChip from "../../../../shared/hooks/use-version-diff/version-item-status-chip.component";
import { Column, Row } from "../../../../shared/shared.styled";
import { ChargeProfileStatusEnum, IChargeProfile, IRange } from "../rate-cards.model";
import { getStatusColor } from "../rate-cards.utils";

interface IVersionItemProps {
  item: VersionItem<IChargeProfile>;
}

const renderRanges = (ranges: IRange[], t: TFunction) => {
  return ranges.map(range => {
    return (
      <RangeItem>
        <Row style={{ justifyContent: "space-between" }}>
          <div style={{ whiteSpace: "nowrap" }}>
            {t("pages.rateCards.rateValueType")}: {t(`pages.rateCards.rateValueTypes.${range.rateValueType}`)}
          </div>
          <div style={{ whiteSpace: "nowrap", color: "#9c2aa0" }}>
            {t("pages.rateCards.rangeBegin")}: {range.rangeBegin}
          </div>
        </Row>
        <Row style={{ justifyContent: "space-between" }}>
          <div style={{ whiteSpace: "nowrap" }}>
            {t("pages.rateCards.rateValue")}: {range.rateValue}
          </div>
          <div style={{ whiteSpace: "nowrap", color: "#9c2aa0" }}>
            {t("pages.rateCards.rangeEnd")}: {range.rangeEnd}
          </div>
        </Row>
      </RangeItem>
    );
  });
};

const RateCardsVersionItem = ({ item }: IVersionItemProps) => {
  const [t] = useTranslation();
  return (
    <ItemColumn disabled={false}>
      <Row>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <ItemInfo>{item.name}</ItemInfo>
          <VersionItemStatusChip status={item.versionItemStatus} />
        </div>
        <StatusContainer>
          {item.status === ChargeProfileStatusEnum.LIVE && (
            <StatusRow color={getStatusColor(ChargeProfileStatusEnum.LIVE)}>
              {t("pages.rateCards.status.live")}
            </StatusRow>
          )}
          {item.status === ChargeProfileStatusEnum.DELETE && (
            <StatusRow color={getStatusColor(ChargeProfileStatusEnum.DELETE)}>
              {t("pages.rateCards.status.delete")}
            </StatusRow>
          )}
          {item.status === ChargeProfileStatusEnum.DISABLE && (
            <StatusRow color={getStatusColor(ChargeProfileStatusEnum.DISABLE)}>
              {t("pages.rateCards.status.deactivate")}
            </StatusRow>
          )}
          {item.status === ChargeProfileStatusEnum.DISABLED && (
            <StatusRow color={getStatusColor(ChargeProfileStatusEnum.DISABLED)}>
              {t("pages.rateCards.status.deactivated")}
            </StatusRow>
          )}
        </StatusContainer>
      </Row>
      {renderRanges(item.ranges, t)}
    </ItemColumn>
  );
};

export default RateCardsVersionItem;

const ItemColumn = styled(Column)<{ disabled?: boolean }>`
  flex: 1;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  padding: 16px 0px 16px 24px;
  &:first-child {
    padding-right: 74px;
  }

  &:last-child {
    padding-left: 30px;
    padding-right: 44px;
  }
`;

const ItemInfo = styled(Column)`
  flex: 20;
  font-weight: bold;
`;
const StatusContainer = styled(Column)`
  padding-left: 24px;
  display: flex;
  align-self: baseline;
  flex: 1;
`;

const StatusRow = styled(Row)<{ color: string }>`
  color: ${props => props.color};
  display: flex;
  justify-content: flex-end;
`;

const RangeItem = styled.div`
  flex: 1;
  padding: 6px 0 6px 0;
`;
