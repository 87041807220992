import React from "react";

const SentForLdapApprovalIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="140"
      height="140"
      viewBox="0 0 140 140"
    >
      <defs>
        <filter id="6mp1hk9yla" width="128.3%" height="140.4%" x="-14.1%" y="-16.5%" filterUnits="objectBoundingBox">
          <feOffset dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5" />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feOffset dy="3" in="SourceAlpha" result="shadowOffsetOuter2" />
          <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="3" />
          <feColorMatrix
            in="shadowBlurOuter2"
            result="shadowMatrixOuter2"
            values="0 0 0 0 0.556640625 0 0 0 0 0.556640625 0 0 0 0 0.556640625 0 0 0 0.23 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="shadowMatrixOuter2" />
          </feMerge>
        </filter>
        <filter id="r77jrdkwqd" width="128.3%" height="173%" x="-14.1%" y="-29.9%" filterUnits="objectBoundingBox">
          <feOffset dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5" />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feOffset dy="3" in="SourceAlpha" result="shadowOffsetOuter2" />
          <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="3" />
          <feColorMatrix
            in="shadowBlurOuter2"
            result="shadowMatrixOuter2"
            values="0 0 0 0 0.556640625 0 0 0 0 0.556640625 0 0 0 0 0.556640625 0 0 0 0.23 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="shadowMatrixOuter2" />
          </feMerge>
        </filter>
        <filter id="qghnmgxx3g" width="136.9%" height="136.9%" x="-18.5%" y="-13.2%" filterUnits="objectBoundingBox">
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.556862745 0 0 0 0 0.556862745 0 0 0 0 0.556862745 0 0 0 0.23 0"
          />
        </filter>
        <linearGradient id="pgc0tn21tc" x1="-24.296%" x2="75.729%" y1="40.745%" y2="76.735%">
          <stop offset="0%" stop-opacity=".571" />
          <stop offset="100%" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="iewhqf4u2h" x1="7.316%" x2="63.055%" y1="31.819%" y2="77.611%">
          <stop offset="0%" stop-opacity=".571" />
          <stop offset="100%" stop-opacity="0" />
        </linearGradient>
        <path
          id="fosg24f6lb"
          d="M105 81.667H11.667C5.223 81.667 0 76.443 0 70V5.833C0 2.612 2.612 0 5.833 0h105c3.222 0 5.834 2.612 5.834 5.833V70c0 6.443-5.224 11.667-11.667 11.667z"
        />
        <path
          id="yr1vefm29e"
          d="M110.833 0h-105C2.612 0 0 2.612 0 5.833v5.834l49.583 30.625s4.251 2.916 8.75 2.916c4.5 0 8.75-2.916 8.75-2.916l49.584-30.625V5.833c0-3.221-2.612-5.833-5.834-5.833z"
        />
        <circle id="zt8uvua8kf" cx="18.958" cy="18.958" r="18.958" />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path
                  fill="#EBEBEB"
                  fill-rule="nonzero"
                  d="M105 81.667H11.667C5.223 81.667 0 76.443 0 70V5.833C0 2.612 2.612 0 5.833 0h105c3.222 0 5.834 2.612 5.834 5.833V70c0 6.443-5.224 11.667-11.667 11.667z"
                  transform="translate(-246 -363) translate(117 152) translate(129 211) translate(10.938 25.52)"
                />
                <g transform="translate(-246 -363) translate(117 152) translate(129 211) translate(10.938 25.52)">
                  <use fill="#000" filter="url(#6mp1hk9yla)" xlinkHref="#fosg24f6lb" />
                  <use fill="#FFF" xlinkHref="#fosg24f6lb" />
                </g>
                <path
                  fill="url(#pgc0tn21tc)"
                  d="M0 11.667l70 70h35c6.443 0 11.667-5.224 11.667-11.667V11.667H0z"
                  opacity=".15"
                  transform="translate(-246 -363) translate(117 152) translate(129 211) translate(10.938 25.52)"
                />
                <g transform="translate(-246 -363) translate(117 152) translate(129 211) translate(10.938 25.52)">
                  <use fill="#000" filter="url(#r77jrdkwqd)" xlinkHref="#yr1vefm29e" />
                  <use fill="#FFF" xlinkHref="#yr1vefm29e" />
                </g>
                <path
                  fill="#000"
                  fill-rule="nonzero"
                  d="M58.333 43.75c-4.499 0-8.75-2.917-8.75-2.917L0 10.208v1.459l49.583 30.625s4.251 2.916 8.75 2.916c4.5 0 8.75-2.916 8.75-2.916l49.584-30.625v-1.459L67.083 40.833s-4.25 2.917-8.75 2.917zM105 80.208H11.667C5.223 80.208 0 74.985 0 68.542V70c0 6.443 5.223 11.667 11.667 11.667H105c6.443 0 11.667-5.224 11.667-11.667v-1.458c0 6.443-5.224 11.666-11.667 11.666z"
                  opacity=".06"
                  transform="translate(-246 -363) translate(117 152) translate(129 211) translate(10.938 25.52)"
                />
              </g>
              <g transform="translate(-246 -363) translate(117 152) translate(129 211) translate(97.708 76.563)">
                <mask id="6rrrqm352i" fill="#fff">
                  <use xlinkHref="#zt8uvua8kf" />
                </mask>
                <g fill-rule="nonzero">
                  <use fill="#000" filter="url(#qghnmgxx3g)" xlinkHref="#zt8uvua8kf" />
                  <use fill="#FF2A58" xlinkHref="#zt8uvua8kf" />
                </g>
                <path
                  fill="url(#iewhqf4u2h)"
                  d="M18.355 17.884L26.592 11.537 47.236 31.747 40.833 40.833 28.149 43.639 11.786 27.055z"
                  mask="url(#6rrrqm352i)"
                  opacity=".25"
                />
                <g stroke-linecap="round" stroke-linejoin="round" mask="url(#6rrrqm352i)">
                  <g>
                    <g>
                      <path
                        stroke="#E5E5E5"
                        stroke-width="3.636"
                        d="M8.75 17.5L8.75 0M0 8.75L17.5 8.75"
                        transform="rotate(45 1.532 26.541) translate(.31 .334)"
                      />
                    </g>
                    <g>
                      <path
                        stroke="#FFF"
                        stroke-width="3.636"
                        d="M8.75 17.5L8.75 0M0 8.75L17.5 8.75"
                        transform="rotate(45 1.532 26.541)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SentForLdapApprovalIcon;
