import { PageTitle, TabChild, Tabs } from "@wit/mpesa-ui-components";
import { Breadcrumb } from "@wit/mpesa-ui-components/lib/types";
import { TFunction } from "i18next";
import React, { ReactElement, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { ConfigContext } from "../../../app.component";
import { RoutesEnum } from "../../../routes/routes.constants";
import { FEATURES } from "../../../shared/renderer.utils";
import { PageContainer } from "../../../shared/shared.styled";
import BlacklistTab from "./components/blacklist/blacklist.tab";
import ReportedUsersTab from "./components/reported-users/reported-users.tab";

const getBreadcrumbs = (t: TFunction): Breadcrumb[] => {
  return [
    {
      label: t("pages.tools.title"),
      url: RoutesEnum.TOOLS,
    },
    {
      label: t("pages.tools.requestMoney.title"),
      url: "",
    },
  ];
};

const RequestMoneyPage = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const { config } = useContext(ConfigContext);
  const [selectedTabs, setSelectedTabs] = React.useState<ReactElement[]>([]);

  const tabsToRender = () => {
    const tabs = [] as ReactElement[];
    const features = [
      {
        feature: FEATURES.REQUEST_MONEY_TAB_REPORTED_USERS,
        label: t("pages.tools.requestMoney.reportedUsers.title"),
        component: <ReportedUsersTab />,
      },
      {
        feature: FEATURES.REQUEST_MONEY_TAB_BLACKLIST,
        label: t("pages.tools.requestMoney.blacklist.title"),
        component: <BlacklistTab />,
      },
      {
        feature: FEATURES.REQUEST_MONEY_TAB_WHITELIST,
        label: t("pages.tools.requestMoney.whitelist.title"),
        component: <></>,
      },
    ];

    if (config) {
      features.forEach(elem => {
        if (!config.disabledFeatures || !config.disabledFeatures.includes(elem.feature)) {
          tabs.push(
            <TabChild label={elem.label} key={elem.label}>
              {elem.component}
            </TabChild>,
          );
        }
      });
    }

    setSelectedTabs(tabs);
  };

  React.useEffect(() => {
    tabsToRender();
  }, []);

  return (
    <PageContainer>
      <PageTitle
        breadcrumbs={getBreadcrumbs(t)}
        title={t("pages.tools.requestMoney.title")}
        navigateFunction={history.push}
        goBackFn={() => window.history.back()}
        separatorStyle={{ display: "none" }}
        titleContainerStyle={{ marginTop: "13px", marginBottom: "34px" }}
        iconContainerStyle={{ stroke: themeContext.palette.vodafoneRed }}
      />
      {selectedTabs.length > 1 ? (
        <Tabs isResponsive={true}>{selectedTabs}</Tabs>
      ) : selectedTabs.length === 1 ? (
        <>{selectedTabs[0]}</>
      ) : (
        <TabsDisabledText>{t("pages.tools.requestMoney.allTabsDisable")}</TabsDisabledText>
      )}
    </PageContainer>
  );
};

export default RequestMoneyPage;

export const TabsDisabledText = styled("span")`
  font-family: Vodafone Rg;
  color: #333333;
  font-size: 16px;
  padding: 20px 0px 20px 0px;
`;
