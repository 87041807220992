import { CancelIcon, TextArea, TextInput } from "@wit/mpesa-ui-components";
import { Formik } from "formik";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { INotification } from "../push-notifications.modal";
import CallToActionComponent from "./call-to-action.component";
import UploadImageComponent from "./upload-image.component";
import NotificationPreviewComponent from "./notification-preview.component";
import PushNotificationsApi from "../push-notifications.api";
import { formatBytes } from "../../../shared/shared.utils";
import ImagePreview from "../../../shared/components/image-preview.component";
import OnDemandNotificationUtil from "../utils/on-demand-notification.util";

interface IProps {
  notificationValues: INotification;
  onChangeValues: (value: INotification) => void;
  isEditing?: boolean;
  isDetailsPage?: boolean;
}

/**
 * Renders notification details fields
 * @returns {React.Component}
 */
const NotificationsDetails = ({ notificationValues, onChangeValues, isEditing, isDetailsPage }: IProps) => {
  const [t] = useTranslation();
  const [backgroundImage, setBackgroundImage] = React.useState("");
  /**
   * request image to backend
   * @param tab number of tab
   */
  const getStoryImage = () => {
    let imageId = notificationValues.backgroundImageId;
    if (!imageId && notificationValues.backgroundImageUrl && notificationValues.backgroundImageUrl !== "") {
      imageId = OnDemandNotificationUtil.getImageIdfromImageUrl(notificationValues.backgroundImageUrl);
    }

    if (imageId && imageId !== "") {
      PushNotificationsApi.methods.getImage(imageId).then(res => {
        setBackgroundImage(res.data);
      });
    }
  };

  React.useEffect(() => {
    getStoryImage();
  }, []);

  const getImageName = () => {
    const imgName = `${notificationValues.campaignName}.${
      backgroundImage
        ? backgroundImage
            .split(",")[0]
            .split("/")[1]
            .split(";")[0]
        : ""
    }`;

    return imgName;
  };
  const getImageSize = () => {
    return formatBytes(backgroundImage ? backgroundImage.length : 0);
  };
  return (
    <Container>
      <StepTitle>{t("pages.notifications.createPage.notificationDetails")}</StepTitle>

      <Formik initialValues={notificationValues} onSubmit={() => {}}>
        {({ values, errors, setFieldValue }) => {
          return (
            <Grid style={{ display: "flex" }} isDetailsPage>
              <form>
                {!isDetailsPage && (
                  <UploadImageComponent
                    values={values}
                    notificationValues={notificationValues}
                    errors={errors}
                    isEditing={!!isEditing}
                    setFieldValue={setFieldValue}
                    onChangeValues={onChangeValues}
                  />
                )}
                <div style={{ marginTop: "1.5rem" }}>
                  {isEditing ? (
                    <>
                      <TextInput
                        value={values.title ? values.title : ""}
                        required={true}
                        title={t("pages.announcements.detailPage.rows.title")}
                        placeholder={t(
                          "pages.announcements.detailPage.rows.placeholderNotificationHomeDescriptionConsumer",
                          { maxCharacters: 40 },
                        )}
                        onChange={e => {
                          setFieldValue("title", e.target.value);
                          onChangeValues({ ...notificationValues, title: e.target.value });
                        }}
                        error={errors.title}
                        style={{ color: "#333333", marginBottom: "1rem " }}
                        maxLength={40}
                        // disabled={!isEditing}
                      />
                      <TextInput
                        value={values.body ? values.body : ""}
                        required={true}
                        title="Body"
                        placeholder={t(
                          "pages.announcements.detailPage.rows.placeholderNotificationHomeDescriptionConsumer",
                          { maxCharacters: 150 },
                        )}
                        onChange={e => {
                          setFieldValue("body", e.target.value);
                          onChangeValues({ ...notificationValues, body: e.target.value });
                        }}
                        error={errors.body}
                        disabled={!isEditing}
                        type="textarea"
                        maxLength={150}
                      />
                    </>
                  ) : (
                    <>
                      <FieldDisplayMode>
                        <FieldLabel>Image</FieldLabel>
                      </FieldDisplayMode>
                      {backgroundImage !== "" ? (
                        <ImagePreview
                          backgroundImage={backgroundImage}
                          imageName={getImageName()}
                          imageSize={getImageSize()}
                          isEditing={isEditing}
                        />
                      ) : (
                        <NoImageUploaded>No image selected</NoImageUploaded>
                      )}
                      <FieldDisplayMode>
                        <FieldLabel>{t("pages.announcements.detailPage.rows.title")}</FieldLabel>
                        <FieldValue>{notificationValues.title}</FieldValue>
                      </FieldDisplayMode>
                      <FieldDisplayMode>
                        <FieldLabel>Body</FieldLabel>
                        <FieldValue>{notificationValues.body || "No body added."}</FieldValue>
                      </FieldDisplayMode>
                    </>
                  )}
                </div>
                <CallToActionComponent
                  values={values}
                  errors={errors}
                  notificationValues={notificationValues}
                  setFieldValue={setFieldValue}
                  onChangeValues={onChangeValues}
                  isEditing={!!isEditing}
                />
              </form>
              <NotificationPreviewComponent title={values.title} body={values.body || ""} />
            </Grid>
          );
        }}
      </Formik>
    </Container>
  );
};

export default NotificationsDetails;

const Container = styled.section`
  width: 100%;
  padding-bottom: 150px;
`;

const Grid = styled.div<{ isDetailsPage: boolean }>`
  display: flex;
  column-gap: 60px;
  row-gap: 30px;

  > form {
    width: 66% !important;
  }

  > div {
    width: 44%;
    margin-top: ${props => (props.isDetailsPage ? "20px" : "200px")};
  }

  @media (max-width: 768px) {
    flex-direction: column;

    > form {
      width: 100% !important;
    }

    > div {
      margin-top: 0;
      width: 100%;
    }
  }
`;

const StepTitle = styled.h2`
  font-size: 22px;
  font-family: Vodafone Rg;
`;

const FieldDisplayMode = styled.div`
  font-family: Vodafone Rg;
  margin-top: 1.5rem;
`;

const FieldLabel = styled.div`
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.4px;
  color: #999;
  margin-bottom: 0.5rem;
`;

const FieldValue = styled.div`
  color: #333;
`;

const NoImageUploaded = styled("div")`
  font-family: Vodafone Rg;
  font-size: 0.85rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  padding: 0 1rem;
  margin-bottom: 1rem;
`;
