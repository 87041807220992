import {
  Checkbox,
  CheckIcon,
  EditIcon,
  ModalTypeEnum,
  MultipleOptionsDropdown,
  PlusIcon,
  PrimaryButton,
  SecondaryPageTitle,
  SmallButton,
  TextInput,
  TrashIcon,
  useAlert,
  useModal,
  TextInputWithTag,
} from "@wit/mpesa-ui-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { FieldArray, Formik, FormikErrors, FormikHelpers, yupToFormErrors } from "formik";
import i18next from "i18next";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { object, Ref, string, StringSchema } from "yup";
import { IStoreInterface } from "../../../configs/store.config";
import HelpIcon from "../../../shared/icons/help.icon";
import { RoutesEnum } from "../../../routes/routes.constants";
import Dropzone from "../../../shared/components/image-dropzone.component";
import { ILanguage } from "../../../shared/models/language.model";
import { IServiceAddDetails, IServiceUpdateDetails } from "../../../shared/models/service-builder.model";
import LanguagesApi from "../../../shared/services/languages.api";
import { LoadingText, PageContainer, DropDownWrapper } from "../../../shared/shared.styled";
import { RegExEpressions } from "../../../shared/shared.utils";
import DynamicScreensApi from "../../configurations/dynamic-screens/dynamic-screens.api";
import { IDynamicScreenCategory } from "../../configurations/dynamic-screens/dynamic-screens.model";
import isLive from "../helpers/service.helpers";
import ServiceDetailsApi from "../service-details.api";
import { ServiceDetailsActions } from "../service-details.store";
import GenericLanguageSelector from "../../../shared/components/generic-language-selector.component";
import useEmptyLanguageWarningModal from "../../../shared/hooks/use-empty-language-warning-modal.hook";

const MAX_FILE_SIZE = 2000000;

/**
 * ServiceDetailsEditPage component
 */
const ServiceDetailsEditPage = () => {
  // Hooks initialization
  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { serviceId } = useParams<any>();

  const { serviceDetails, isLoadingServiceDetails } = useSelector(
    (state: IStoreInterface) => state.serviceDetailsReducer,
  );

  const [showAlert, hideAlert, setAlertProps] = useAlert();

  const [image, setImage] = useState<string | undefined>();
  const [icon, setIcon] = useState<string | undefined>();
  const [imageUrl, setImageUrl] = useState(serviceDetails.imageUrl);
  const [iconUrl, setIconUrl] = useState(serviceDetails.iconUrl);

  // change this mocked urls
  const [whitelistURLs, setWhitelistURLs] = useState(
    serviceDetails.urlWhiteList !== undefined ? serviceDetails.urlWhiteList : [],
  );

  // change this mocked state array
  const [isEditingEntry, setIsEditingEntry] = useState([] as boolean[]);

  const [showDeleteModal, hideDeleteModal, setDeleteProps] = useModal(ModalTypeEnum.ConfirmationModal);

  const [availableLanguages, setAvailableLanguages] = useState<ILanguage[]>();

  const [canBeHighlighted, setCanBeHighlighted] = useState<boolean>(false);

  const [categories, setCategories] = React.useState<IDynamicScreenCategory[]>([]);
  const [selectedCategories, setSelectedCategories] = React.useState<string[]>([]);

  // tags stuffs
  const tagsSuggestions = useRef(new Array<string>());
  const [tags, setTags] = useState<string[]>([]);
  const [tagInputText, setTagInputText] = useState<string>("");
  const [tagErrorText, setTagErrorText] = useState<string>("");

  // translation

  const [language, setLanguage] = useState("en");

  const { warningModalOpen, showWarning, setShowWarning } = useEmptyLanguageWarningModal(availableLanguages || []);
  /**
   * Submit method wrapper to show warning if needed
   * @param values
   */
  const submitWrapper = (values: any, actions: FormikHelpers<any>) => {
    if (showWarning) {
      setShowWarning(false);
      actions.setSubmitting(false);
      warningModalOpen(editService, values, actions);
    } else {
      editService(values, actions);
    }
  };

  const removeEntry = (index: number, arrayHelpers: any, setFieldValue: any) => {
    isEditingEntry.splice(index, 1);
    setFieldValue("editingWhitelist", isEditingEntry);
    arrayHelpers.remove(index);
    hideDeleteModal();
  };

  function _showDeleteModal(index: number, arrayHelpers: any, setFieldValue: any) {
    setDeleteProps({
      title: t("pages.serviceBuilder.editService.removeModal.title"),
      description: t("pages.serviceBuilder.editService.removeModal.description"),
      primaryAction: () => {
        removeEntry(index, arrayHelpers, setFieldValue);
      },
      primaryBtnLabel: t("pages.serviceBuilder.modals.deleteVersion.primaryButtonLabel"),
      secondaryAction: hideDeleteModal,
      secondaryBtnLabel: t("pages.serviceBuilder.modals.deleteVersion.secondaryButtonLabel"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
    });
    showDeleteModal();
  }

  useEffect(() => {
    LanguagesApi.methods.getAvailableLanguages().then(
      res => {
        if (res.data.availableLanguages.length === 0) {
          setAvailableLanguages([
            { id: "1", code: "en", name: "English", urlIcon: "ic_flag_uk.png" },
            {
              id: "2",
              code: "sw",
              name: "Swahili (Tanzania)",
              urlIcon: "ic_flag_tanzania.png",
            },
          ]);
        } else {
          setAvailableLanguages(res.data.availableLanguages);
        }
      },
      () => {
        setAlertProps({
          type: AlertTypeEnum.ERROR,
          title: t("commons.getLanguagesError"),
        });
        showAlert();
        setAvailableLanguages([
          { id: "1", code: "en", name: "English", urlIcon: "ic_flag_uk.png" },
          {
            id: "2",
            code: "sw",
            name: "Swahili (Tanzania)",
            urlIcon: "ic_flag_tanzania.png",
          },
        ]);
      },
    );
    whitelistURLs.map(() => {
      isEditingEntry.push(false);
    });
    dispatch(ServiceDetailsActions.creators.fetchingServiceDetailsAction());

    ServiceDetailsApi.methods.getServiceDetails(serviceId).then(
      res => {
        setSelectedCategories(res.data.categories.map(c => c.id));

        dispatch(ServiceDetailsActions.creators.fetchServiceDetailsSuccessAction(res.data));

        if (res.data.tags) {
          setTags(res.data.tags);
        }

        if (res.data.tagsSuggestions) {
          tagsSuggestions.current.push(...res.data.tagsSuggestions);
        }
      },
      () => {
        setAlertProps({
          title: t("pages.serviceBuilder.errors.noServiceDetails"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        history.push(RoutesEnum.SERVICE_DETAILS.replace(":serviceId", serviceId));
      },
    );

    DynamicScreensApi.methods.getDynamicScreensCategories().then(
      res => {
        setCategories(res.data.items);
      },
      () => {
        setAlertProps({
          title: t("pages.serviceBuilder.errors.categoriesError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );

    ServiceDetailsApi.methods.getCanBeHighlighted().then(res => {
      setCanBeHighlighted(res.data.canBeHighlighted);
    });

    return () => {};
  }, []);

  React.useEffect(() => {
    setImage(undefined);
    setImageUrl(serviceDetails.imageUrl);
    return () => {};
  }, [serviceDetails.imageUrl]);

  React.useEffect(() => {
    setIcon(undefined);
    setIconUrl(serviceDetails.iconUrl);
    return () => {};
  }, [serviceDetails.iconUrl]);

  /**
   * Creates the language validators for the translatable fields
   * @param {string[]} languages
   * @returns {{[p: string]: Ref | StringSchema<string>}}
   */
  const getTranslationValidators = (languages: ILanguage[]) => {
    let validators: { [x: string]: Ref | StringSchema<string> } = {};
    languages.map((lang: ILanguage) => {
      if (lang.mandatory) {
        validators = {
          ...validators,
          [lang.code]: string()
            .trim()
            .required(i18next.t("pages.serviceBuilder.formErrors.required"))
            .max(255, i18next.t("pages.serviceBuilder.formErrors.maxLen255")),
        };
      } else {
        validators = {
          ...validators,
          [lang.code]: string().max(255, i18next.t("pages.serviceBuilder.formErrors.maxLen255")),
        };
      }
    });
    return validators;
  };

  /**
   * Retrieves the form validation schema
   * @param {ILanguage[]} availableLanguages
   * @returns {ObjectSchema<Shape<object, {name: Shape<object, {}>, serviceImage: string | null, serviceIcon: string | null, description: Shape<object, {}>}>>}
   */
  const getValidationSchema = (availableLanguages: ILanguage[]) => {
    return object().shape({
      serviceImage: string()
        .required(i18next.t("pages.serviceBuilder.modals.addService.image.errorRequired"))
        .nullable(),
      serviceIcon: string()
        .required(i18next.t("pages.serviceBuilder.modals.addService.icon.errorRequired"))
        .nullable(),
      name: object().shape(getTranslationValidators(availableLanguages)),
      description: object().shape(getTranslationValidators(availableLanguages)),
    });
  };

  /**
   * Validate form promise
   * @param values
   * @param {ILanguage[]} languages
   * @returns {Promise<FormikErrors<IServiceAddDetails>>}
   */
  const validateSchema = (
    values: any,
    languages: ILanguage[],
    setShowWarning?: React.Dispatch<React.SetStateAction<boolean>>,
  ): Promise<FormikErrors<IServiceAddDetails>> => {
    return new Promise<FormikErrors<IServiceAddDetails>>(resolve => {
      getValidationSchema(languages)
        .validate(values, {
          abortEarly: false,
        })
        .then(() => {
          setShowWarning && setShowWarning(false);
          //Validate if any optional language is empty
          for (let l = 0; l < languages.length; l++) {
            if (!values.name[languages[l].code] || !values.description[languages[l].code]) {
              setShowWarning && setShowWarning(true);
            }
          }
          resolve({});
        })
        .catch(errors => {
          const langErrors: string[] = [];
          errors.inner.forEach((err: { path: string }) => {
            if ((err.path as string).includes("name") || (err.path as string).includes("description")) {
              langErrors.push(err.path.replace("name.", "").replace("description.", ""));
            }
          });
          if (langErrors.length > 0) {
            langErrors.sort();
            setLanguage(langErrors[0]);
          }
          resolve(yupToFormErrors(errors));
        });
    }).then(r => {
      return r;
    });
  };

  const editServiceWithId = useCallback(
    (id: string, updateDetails: IServiceUpdateDetails, actions: FormikHelpers<any>) => {
      ServiceDetailsApi.methods
        .editService(
          imageUrl !== serviceDetails.imageUrl && image !== null ? image : undefined,
          iconUrl !== serviceDetails.iconUrl && icon !== null ? icon : undefined,
          serviceDetails.id,
          updateDetails,
          isLive(serviceDetails),
        )
        .then(
          (res: any) => {
            dispatch(ServiceDetailsActions.creators.updateServiceAction(serviceDetails.id, res.data));
            setAlertProps({
              title: t(
                `pages.serviceBuilder.success.updatingService.${
                  isLive(serviceDetails) ? "titleSubmitForApproval" : "title"
                }`,
              ),
              type: AlertTypeEnum.SUCCESS,
            });
            showAlert();
            actions.setSubmitting(false);
            window.history.back();
          },
          res => {
            if (
              res &&
              res.data &&
              res.data.code &&
              res.data.message &&
              (res.data.code === 20001 || res.data.code === 40003) //20001 = already exist | 40003 = max highlighted reached
            ) {
              setAlertProps({
                title: res.data.message,
                type: AlertTypeEnum.ERROR,
              });
            } else {
              setAlertProps({
                title: t("pages.serviceBuilder.errors.updatingService.title"),
                content: t("pages.serviceBuilder.errors.updatingService.content"),
                type: AlertTypeEnum.ERROR,
              });
            }
            showAlert();
            actions.setSubmitting(false);
          },
        );
    },
    [
      dispatch,
      icon,
      iconUrl,
      image,
      imageUrl,
      serviceDetails.iconUrl,
      serviceDetails.id,
      serviceDetails.imageUrl,
      setAlertProps,
      showAlert,
      t,
    ],
  );

  const editService = (values: any, actions: FormikHelpers<any>) => {
    if (isEditingEntry.includes(true)) {
      setAlertProps({
        title: t("pages.serviceBuilder.errors.updatingService.whitelistUnsavedTitle"),
        content: t("pages.serviceBuilder.errors.updatingService.whitelistUnsavedDescription"),
        type: AlertTypeEnum.ERROR,
      });
      showAlert();
      actions.setSubmitting(false);
      return;
    }

    actions.setSubmitting(true);

    let updateDetails = {
      description: values.description,
      highlighted: values.highlighted,
      categories: values.categories,
      tags: values.tags,
    } as IServiceUpdateDetails;

    if (values.name !== serviceDetails.name) {
      updateDetails = { ...updateDetails, name: values.name };
    }

    if (values.whitelist !== serviceDetails.urlWhiteList) {
      updateDetails = { ...updateDetails, urlWhiteList: values.whitelist };
    }

    editServiceWithId(serviceDetails.id, updateDetails, actions);
  };

  const onDrop = useCallback(
    acceptedIcon => {
      if (acceptedIcon.length > 0) {
        const file: File = acceptedIcon[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        if (icon && iconUrl) {
          window.URL.revokeObjectURL(iconUrl);
        }

        reader.onloadend = function() {
          if (reader.result) {
            setIcon(reader.result.toString());
            setIconUrl(window.URL.createObjectURL(file));
          }
        };
      }
    },
    [icon, iconUrl],
  );

  const onDropImage = useCallback(
    acceptedImage => {
      if (acceptedImage.length > 0) {
        const file: File = acceptedImage[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        if (image && imageUrl) {
          window.URL.revokeObjectURL(imageUrl);
        }

        reader.onloadend = function() {
          if (reader.result) {
            setImage(reader.result.toString());
            setImageUrl(window.URL.createObjectURL(file));
          }
        };
      }
    },
    [image, imageUrl],
  );

  useEffect(() => {
    return () => {
      window.URL.revokeObjectURL(iconUrl);
      window.URL.revokeObjectURL(imageUrl);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isValidURL = (str: string) => {
    try {
      new URL(str);
      return true;
    } catch (_) {
      return false;
    }
  };

  const stringAlreadyExists = (values: string[], index: number) => {
    return (
      values.filter(val => {
        return val === values[index];
      }).length > 1
    );
  };

  /**
   * Toggles a category in the dropdown
   * @param {SharedDropdownOption} opt
   * @param {(field: string, value: any, shouldValidate?: (boolean | undefined)) => void} setFieldValue
   */
  const toggleCategory = (
    opt: SharedDropdownOption,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  ) => {
    if (selectedCategories.includes(opt.key)) {
      setFieldValue(
        "categories",
        selectedCategories.filter(c => c !== opt.key),
      );
      setSelectedCategories(selectedCategories.filter(c => c !== opt.key));
    } else {
      setFieldValue("categories", [...selectedCategories, opt.key]);
      setSelectedCategories([...selectedCategories, opt.key]);
    }
  };

  /**
   * Checks if a category is already selected on the dropdown
   * @param {SharedDropdownOption} opt
   * @returns {boolean}
   */
  const isCategorySelected = (opt: SharedDropdownOption) => {
    return selectedCategories.includes(opt.key);
  };

  /**
   * Returns a label for the categories dropdown
   * @returns {string}
   */
  const getCategoriesLabel = () => {
    if (selectedCategories.length > 0) {
      let label = "";
      selectedCategories.forEach(c => {
        const nameTranslation = (categories.find(cat => {
          return cat.id === c;
        }) as IDynamicScreenCategory).name.translations;
        label += `${nameTranslation[language] ? nameTranslation[language] : nameTranslation["en"]}, `;
      });
      label = label.substring(0, label.length - 2);
      return label;
    } else {
      return t("pages.serviceBuilder.modals.addService.category.placeholder");
    }
  };

  return (
    <>
      {isLoadingServiceDetails && !availableLanguages ? (
        <LoadingText>{t("commons.loadingResults")}</LoadingText>
      ) : (
        availableLanguages && (
          <PageContainer>
            <SecondaryPageTitle
              title={t("pages.serviceBuilder.editService.title")}
              goBackFn={() => window.history.back()}
              displayInitialsCircle={false}
            />

            <Formik
              onSubmit={submitWrapper}
              validateOnBlur={false}
              validateOnChange={false}
              validate={values => validateSchema(values, availableLanguages, setShowWarning)}
              enableReinitialize
              initialValues={{
                name: serviceDetails.name,
                description: serviceDetails.description,
                highlighted: serviceDetails.highlighted,
                serviceImage: serviceDetails.imageUrl,
                serviceIcon: serviceDetails.iconUrl,
                whitelist: whitelistURLs,
                editingWhitelist: isEditingEntry,
                tags: tags,
              }}
              render={({ handleChange, values, handleSubmit, errors, isSubmitting, setFieldValue, setErrors }) => (
                <EditServiceForm onSubmit={handleSubmit} onChange={handleChange}>
                  <TopFormDiv>
                    <EditServiceFormLeft>
                      <div style={{ marginTop: "auto", marginLeft: "auto" }}>
                        <GenericLanguageSelector
                          selectedLanguage={language}
                          availableLanguages={availableLanguages.map(lang => lang.code)}
                          changeSelectedLanguage={(lang: any) => setLanguage(lang.key)}
                        ></GenericLanguageSelector>
                      </div>
                      <TextInputWrapper>
                        <Row>
                          <Title
                            required={availableLanguages.find(l => l.code === language)?.mandatory}
                            style={{ alignSelf: "center" }}
                          >
                            {t("pages.serviceBuilder.modals.addService.name.title")}
                          </Title>
                          <TextInput
                            placeholder={t("pages.serviceBuilder.modals.addService.name.placeholder")}
                            required={availableLanguages.find(l => l.code === language)?.mandatory}
                            name={`name.${language}`}
                            onChange={handleChange}
                            value={(values.name && values.name[language]) || ""}
                            error={errors.name && ((errors.name as { [x: string]: string })[language] as string)}
                            autoComplete="off"
                          />
                        </Row>
                      </TextInputWrapper>
                      <TextInputWrapper>
                        <Row>
                          <Title
                            required={availableLanguages.find(l => l.code === language)?.mandatory}
                            style={{ alignSelf: "center" }}
                          >
                            {t("pages.serviceBuilder.modals.addService.description.title")}
                          </Title>
                          <TextInput
                            placeholder={t("pages.serviceBuilder.modals.addService.description.placeholder")}
                            name={`description.${language}`}
                            required={availableLanguages.find(l => l.code === language)?.mandatory}
                            onChange={handleChange}
                            value={(values.description && values.description[language]) || ""}
                            error={
                              errors.description &&
                              ((errors.description as { [x: string]: string })[language] as string)
                            }
                            autoComplete="off"
                          />
                        </Row>
                      </TextInputWrapper>
                      <TextInputWrapper>
                        <Row>
                          <Title required={false} style={{ alignSelf: "center" }}>
                            {t("pages.serviceBuilder.tags.label")}
                          </Title>
                          <div id="tags-input">
                            <TextInputWithTag
                              error={tagErrorText}
                              addTagButtonText={t("pages.serviceBuilder.tags.addTag")}
                              tags={tags}
                              setTags={setTags}
                              inputValue={tagInputText}
                              setInputValue={setTagInputText}
                              tagsSuggestions={tagsSuggestions.current}
                              minTagTextLength={3}
                              maxTagTextLength={25}
                              placeholder={t("pages.serviceBuilder.tags.tagsPlaceholder")}
                              regexExp={RegExEpressions.especialCharacters}
                            />
                          </div>
                        </Row>
                      </TextInputWrapper>
                      <TextInputWrapper>
                        {categories.length > 0 && (
                          <Row>
                            <Title>{t("pages.serviceBuilder.modals.addService.category.title")}</Title>
                            <DropDownWrapper id={"selectable-option"}>
                              <MultipleOptionsDropdown
                                showClearAllFilters={false}
                                options={categories.map(c => {
                                  return {
                                    label: c.name.translations[language]
                                      ? c.name.translations[language]
                                      : c.name.translations["en"],
                                    key: c.id,
                                  };
                                })}
                                clearAllFilters={() => {
                                  setSelectedCategories([]);
                                  setFieldValue("categories", []);
                                }}
                                dropdownType={DropdownType.RECTANGULAR_NORMAL}
                                hasValue={selectedCategories.length > 0}
                                isOptionSelected={opt => isCategorySelected(opt)}
                                label={getCategoriesLabel()}
                                toggleOption={opt => toggleCategory(opt, setFieldValue)}
                              />
                            </DropDownWrapper>
                          </Row>
                        )}
                      </TextInputWrapper>
                      <Row id={"highlighted"}>
                        <Title>{t("pages.serviceBuilder.modals.addService.highlighted.title")}</Title>
                        <div id={"selectable-option"}>
                          <Checkbox
                            data-testid={"highlighted-checkbox"}
                            name={"highlighted"}
                            onValueChange={() => {
                              if (values.highlighted) {
                                setCanBeHighlighted(true);
                              }
                              setFieldValue("highlighted", !values.highlighted);
                            }}
                            value={values.highlighted}
                            disabled={canBeHighlighted ? false : !values!.highlighted}
                          />
                          <Label>{t("pages.serviceBuilder.modals.addService.highlighted.label")}</Label>
                          {!canBeHighlighted && !values!.highlighted && (
                            <TooltipContainer data-tip data-for="highlighted-tooltip" id={"highlighted-tooltip"}>
                              <HelpIcon />
                              <ReactTooltip
                                event="mouseover"
                                eventOff="mouseleave mousedown"
                                place="top"
                                type="dark"
                                effect="solid"
                                id={"highlighted-tooltip"}
                                className={"tooltip-container"}
                              >
                                <TooltipText>
                                  {t("pages.serviceBuilder.modals.addService.highlighted.tooltip")}
                                </TooltipText>
                              </ReactTooltip>
                            </TooltipContainer>
                          )}
                        </div>
                      </Row>
                      <ExternalAPITitle>{t("pages.serviceBuilder.editService.externalAPI")}</ExternalAPITitle>
                      <FieldArray
                        name="whitelist"
                        render={arrayHelpers => (
                          <div>
                            <WhitelistDiv>
                              {values.whitelist && values.whitelist.length > 0
                                ? values.whitelist.map((url, index) => {
                                    return (
                                      <WhitelistElementWrapper id={`elementWrapper.${index}`} key={index}>
                                        {values.editingWhitelist[index] === false ? (
                                          <WhitelistLabel id={`whitelistDiv.${index}`}>
                                            <WhitelistLabelText>{url}</WhitelistLabelText>
                                          </WhitelistLabel>
                                        ) : (
                                          <InputWrapper id={`whitelist.${index}`}>
                                            <TextInput
                                              name={`whitelist.${index}`}
                                              onChange={handleChange}
                                              value={url}
                                              placeholder={t("pages.serviceBuilder.editService.placeholder")}
                                              autoComplete="off"
                                            />
                                          </InputWrapper>
                                        )}
                                        <div
                                          style={{
                                            display: isEditingEntry[index] === false ? "none" : "flex",
                                          }}
                                        >
                                          <SmallButtonWrapper
                                            style={{ marginLeft: "14px" }}
                                            color="#00b0ca"
                                            id={`whitelistEditIcon.${index}`}
                                            height={"20px"}
                                            display={isEditingEntry[index] === false ? "block" : "none"}
                                          >
                                            <SmallButton
                                              onClick={() => {
                                                isEditingEntry[index] = true;
                                                setFieldValue("editingWhitelist", isEditingEntry);
                                                process.nextTick(() => {
                                                  ((((document.getElementById(`whitelist.${index}`) as HTMLElement)
                                                    .firstElementChild as HTMLElement).firstElementChild as HTMLElement)
                                                    .firstElementChild as HTMLInputElement).focus();
                                                });
                                              }}
                                              titleLabel=""
                                              iconComponent={<EditIcon width={24} height={24} />}
                                            />
                                          </SmallButtonWrapper>
                                          <SmallButtonWrapper
                                            style={{ marginLeft: "14px" }}
                                            color="#009900"
                                            id={`whitelistSaveIcon.${index}`}
                                            display={isEditingEntry[index] === true ? "block" : "none"}
                                            opacity={isEditingEntry[index] === true ? "1" : "0"}
                                          >
                                            <SmallButton
                                              onClick={() => {
                                                if (!isValidURL(values.whitelist[index])) {
                                                  setAlertProps({
                                                    title: t(
                                                      "pages.serviceBuilder.errors.updatingService.whitelistSaveTitle",
                                                    ),
                                                    type: AlertTypeEnum.ERROR,
                                                  });
                                                  showAlert();
                                                } else if (stringAlreadyExists(values.whitelist, index)) {
                                                  setAlertProps({
                                                    title: t(
                                                      "pages.serviceBuilder.errors.updatingService.whitelistSaveDuplicatedTitle",
                                                    ),
                                                    type: AlertTypeEnum.ERROR,
                                                  });
                                                  showAlert();
                                                } else {
                                                  isEditingEntry[index] = false;
                                                  setFieldValue("editingWhitelist", isEditingEntry);
                                                }
                                              }}
                                              titleLabel=""
                                              iconComponent={<CheckIcon width={24} height={24} />}
                                            />
                                          </SmallButtonWrapper>
                                          <SmallButtonWrapper
                                            color="#e60000"
                                            id={`whitelistDeleteIcon.${index}`}
                                            display={"block"}
                                            opacity={"1"}
                                          >
                                            <SmallButton
                                              onClick={() => {
                                                _showDeleteModal(index, arrayHelpers, setFieldValue);
                                              }}
                                              titleLabel=""
                                              iconComponent={<TrashIcon width={24} height={24} />}
                                            />
                                          </SmallButtonWrapper>
                                        </div>
                                      </WhitelistElementWrapper>
                                    );
                                  })
                                : null}
                            </WhitelistDiv>
                            <SmallButtonWrapper
                              color="#e60000"
                              display="block"
                              opacity="1"
                              style={{ marginTop: "24px", float: "right" }}
                              id="add-new-url-button"
                            >
                              <SmallButton
                                onClick={() => {
                                  arrayHelpers.insert(values.whitelist.length + 1, "");
                                  isEditingEntry.push(true);
                                  setFieldValue("editingWhitelist", isEditingEntry);
                                  process.nextTick(() => {
                                    ((((document.getElementById(`whitelist.${values.whitelist.length}`) as HTMLElement)
                                      .firstElementChild as HTMLElement).firstElementChild as HTMLElement)
                                      .firstElementChild as HTMLInputElement).focus();
                                  });
                                }}
                                titleLabel={t("pages.serviceBuilder.editService.addNewUrl")}
                                iconComponent={<PlusIcon width={24} height={24} />}
                              />
                            </SmallButtonWrapper>
                          </div>
                        )}
                      />

                      <ButtonsWrapper>
                        <ButtonDiv>
                          <PrimaryButton
                            id="cancel-button"
                            titleLabel={t("pages.serviceBuilder.editService.cancelButton")}
                            type="button"
                            redTheme={false}
                            onClick={() => {
                              window.history.back();
                            }}
                          />
                        </ButtonDiv>
                        <ButtonDiv>
                          <PrimaryButton
                            id="submit-button"
                            loading={isSubmitting}
                            titleLabel={t(
                              `pages.serviceBuilder.editService.${
                                isLive(serviceDetails) ? "sendForApprove" : "saveButton"
                              }`,
                            )}
                            type="submit"
                            redTheme={true}
                          />
                        </ButtonDiv>
                      </ButtonsWrapper>
                    </EditServiceFormLeft>
                    <EditServiceFormRight>
                      <div>
                        <DropzoneTitle mandatory={true}>
                          {t("pages.serviceBuilder.modals.addService.icon.title")}
                        </DropzoneTitle>
                        {errors.serviceIcon ? <ImageError>{errors.serviceIcon}</ImageError> : null}
                        <Dropzone
                          inputName="serviceIcon"
                          onDrop={(file: any) => {
                            if (!file[0]) {
                              setErrors({
                                ...errors,
                                serviceIcon: t("pages.serviceBuilder.modals.addService.icon.imageError"),
                              });
                              if (values.serviceIcon === null) {
                                setFieldValue("serviceIcon", null);
                              }
                            } else if (file[0] && file[0].size > MAX_FILE_SIZE) {
                              setErrors({
                                ...errors,
                                serviceIcon: t("pages.serviceBuilder.modals.addService.icon.maxSizeError").replace(
                                  "{0}",
                                  (MAX_FILE_SIZE / 1000000).toString(),
                                ),
                              });
                              if (values.serviceIcon === null) {
                                setFieldValue("serviceIcon", null);
                              }
                            } else {
                              setFieldValue("serviceIcon", file);
                              onDrop(file);
                            }
                          }}
                          accept={"image/*"}
                          imageSrc={iconUrl}
                          multiple={false}
                          dropText={t("pages.serviceBuilder.modals.addService.icon.drop")}
                          releaseText={t("pages.serviceBuilder.modals.addService.icon.release")}
                          error={!!errors.serviceIcon}
                        />
                      </div>

                      <div>
                        <DropzoneTitle mandatory={true}>
                          {t("pages.serviceBuilder.modals.addService.image.title")}
                        </DropzoneTitle>
                        {errors.serviceImage ? <ImageError>{errors.serviceImage}</ImageError> : null}
                        <Dropzone
                          inputName="serviceImage"
                          onDrop={(file: any) => {
                            if (!file[0]) {
                              setErrors({
                                ...errors,
                                serviceImage: t("pages.serviceBuilder.modals.addService.image.imageError"),
                              });
                              if (values.serviceImage === null) {
                                setFieldValue("serviceImage", null);
                              }
                            } else if (file[0] && file[0].size > MAX_FILE_SIZE) {
                              setErrors({
                                ...errors,
                                serviceImage: t("pages.serviceBuilder.modals.addService.image.maxSizeError").replace(
                                  "{0}",
                                  (MAX_FILE_SIZE / 1000000).toString(),
                                ),
                              });
                              if (values.serviceImage === null) {
                                setFieldValue("serviceImage", null);
                              }
                            } else {
                              setFieldValue("serviceImage", file);
                              onDropImage(file);
                            }
                          }}
                          accept={"image/*"}
                          imageSrc={imageUrl}
                          multiple={false}
                          roundedImage={false}
                          dropText={t("pages.serviceBuilder.modals.addService.image.drop")}
                          releaseText={t("pages.serviceBuilder.modals.addService.image.release")}
                          error={!!errors.serviceImage}
                        />
                      </div>
                    </EditServiceFormRight>
                  </TopFormDiv>
                </EditServiceForm>
              )}
            />
          </PageContainer>
        )
      )}
    </>
  );
};

export default ServiceDetailsEditPage;

const EditServiceForm = styled("form")`
  display: flex;
  margin: auto;
  flex-direction: column;
  max-width: 62%;
  margin-left: 204px;
  margin-right: 204px;
  margin-top: 110px;
  @media (max-width: 1024px) {
    min-width: 88%;
    margin-left: 44px;
    margin-right: 44px;
  }
`;

const TopFormDiv = styled("div")`
  display: flex;
  flex-direction: row;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
`;

const EditServiceFormLeft = styled("div")`
  flex: 1;
  overflow-x: hidden;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  > div {
    margin-bottom: 10px;
  }
  @media (max-width: 1024px) {
    padding-right: 0;
    min-width: 100%;
    > div {
      margin-bottom: 40px;
    }
  }
`;

const EditServiceFormRight = styled("div")`
  width: 250px;
  overflow: hidden;
  min-width: 200px;
  @media (max-width: 1024px) {
    padding-bottom: 32px;
    > div {
      margin-bottom: 48px;
    }
  }
`;

export const TooltipContainer = styled("div")`
  display: flex;
  margin-left: 8px;
  cursor: pointer;
`;

export const TooltipText = styled("div")`
  font-size: 12px;
  line-height: 14px;
  font-family: "Vodafone Rg";
  color: white;
  display: flex;
  width: 210px;
  text-align: center;
`;

const DropzoneTitle = styled("div")<{ mandatory: boolean }>`
  min-height: 21px;
  font-family: Vodafone Rg;
  font-weight: bold;
  line-height: 21px;
  font-size: 16px;
  color: #999999;
  margin-bottom: 8px;
  width: fit-content;
  display: inline-flex;
  ${props =>
    props.mandatory ? "::after {content: ' *'; color: #ff0000; font-weight: 400; padding-left: 2px;}" : null};
`;

const ButtonsWrapper = styled("div")`
  margin-top: auto;
  width: 100%;
  display: flex;
  position: fixed;
  top: 46px;
  right: 69px;
  z-index: 1;
  width: auto;
`;

const ButtonDiv = styled("div")`
  width: auto;
  margin-right: 12px;
`;

const ImageError = styled("div")`
  width: fit-content;
  display: inline-flex;
  height: 19px;
  color: #ff0000;
  font-family: Vodafone Rg;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  float: right;
  font-weight: 400;
`;

const WhitelistDiv = styled("div")`
  height: 155px;
  max-height: 155px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const WhitelistLabel = styled("div")`
  flex: 1;
  overflow: hidden;
`;

const WhitelistLabelText = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: #333333;
  line-height: 21px;
  padding: 12px 0 11px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const InputWrapper = styled("div")`
  flex: 1;
  > div > div > input {
    height: 36px;
    border-radius: 6px;
    border: solid 1px #ebebeb;
    margin: 9px 0 8px 0;
  }
  > div > div > input:hover {
    border: solid 1px #333333;
  }
`;

const WhitelistElementWrapper = styled("div")`
  border-bottom: 1px solid #ebebeb;
  display: flex;
  min-height: 44px;
  &:hover {
    > div:nth-of-type(1) > div {
      font-weight: bold;
    }
  }

  &:hover {
    > div:nth-of-type(2) {
      display: flex !important;
      > span {
        opacity: 1;
      }
    }
  }
`;

const SmallButtonWrapper = styled("span")<{ color: string; height?: string; display?: string; opacity?: string }>`
  opacity: ${(props: any) => (props.opacity !== undefined ? props.opacity : "0")};
  display: ${(props: any) => (props.display !== undefined ? props.display : "none")};
  height: auto;
  > button {
    width: auto;
    height: 100%;
    > svg {
      width: 24px;
      height: ${(props: any) => (props.height !== undefined ? props.height : "24px")};
    }
  }
  > button:first-of-type {
    svg {
      stroke: ${(props: any) => props.color};
    }
  }
`;

const TextInputWrapper = styled("div")`
  margin-bottom: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #ebebeb;

  > div {
    display: flex;
    flex-direction: row;
    > span {
      width: 210px;
      margin-top: auto;
      margin-bottom: auto;
      padding-right: 3px;
    }
    > div {
      flex: 1;
    }
  }
`;

const ExternalAPITitle = styled("div")`
  color: #333333;
  font-family: Vodafone Rg;
  font-size: 22px;
  margin-top: 40px;
  margin-bottom: 14px;
`;

const Label = styled("div")`
  font-family: Vodafone Rg;
  margin-left: 8px;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 19px;
  letter-spacing: normal;
  color: ${props => props.theme.palette.midGrey};
  display: inline-block;
`;

export const Title = styled.span<{ required?: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Vodafone Rg;
  font-size: 16px;
  line-height: 21px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};

  ${props =>
    props.required
      ? `
::after {
    content: ' *';
    font-family: Vodafone Rg;
    font-size: 16px;
    font-weight: normal;
    color: ${props.theme.palette.errorRed};
}
`
      : ""}
`;

export const Row = styled("div")`
  display: grid !important;
  grid-template-columns: minmax(170.65px, 210px) minmax(168px, 1fr);

  div#selectable-option {
    display: flex;
  }
`;
