import React from "react";
import styled from "styled-components";
import { TabChild, Tabs, SearchBar, CancelIcon, SearchIcon, CloudUploadIcon, useAlert } from "@wit/mpesa-ui-components";
import { useTranslation } from "react-i18next";
import { IIcon } from "../announcements.model";
import UploadImagePlaceHolderIcon from "../../../shared/icons/upload-image-placeholder.icon";
import DropzoneIcon from "./dropzone-icon.component";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import AnnouncementsApi from "../announcements.api";

enum TabState {
  ACTION = 0,
  CATEGORY = 1,
  CUSTOMIZED = 2,
  SEARCH = 3,
  UPLOAD = 4,
}

enum IconCategory {
  ACTION = "ACTION",
  CATEGORY = "CATEGORY",
}

interface IIconOptionsProps {
  iconID: string;
  setIconID(field: string, value: any, shouldValidate?: boolean): void;
  setIcon(icon: IIcon): void;
  textToSave: string;
  customIcons?: boolean;
  formats?: string;
  iconMinWidth?: number;
  iconMinHeight?: number;
  maxSize?: number;
}

/**
 * Component to be call in dropdown of icons
 * @param param0 Icon Options Interface
 */
const IconOptions = ({
  iconID,
  setIconID,
  textToSave,
  setIcon,
  formats = ".png",
  iconMinWidth = 24,
  iconMinHeight = 24,
  maxSize = 100000,
  customIcons = false,
}: IIconOptionsProps) => {
  const [t] = useTranslation();
  const [searching, setSearching] = React.useState(false);
  const iconsStorage: IIcon[] = JSON.parse(sessionStorage.getItem("icons") as string);
  const [icons, setIcons] = React.useState<IIcon[]>(iconsStorage.filter(icon => icon.category === IconCategory.ACTION));
  const [filterIcons, setFilterIcons] = React.useState<IIcon[]>([]);
  const [search, setSearch] = React.useState("");
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const [uploadedImage, setUploadedImage] = React.useState<IIcon>();
  const [uploadedImageSize, setUploadedImageSize] = React.useState(0);

  /**
   * function called when changes the tab
   * @param tabValue value of the tab selected
   */
  const controlledSetSelectedTab = (tabValue: number) => {
    setSelectedTab(tabValue);
    switch (tabValue) {
      case TabState.ACTION:
        setIcons(iconsStorage.filter(icon => icon.category === IconCategory.ACTION));
        break;
      case TabState.CATEGORY:
        setIcons(iconsStorage.filter(icon => icon.category === IconCategory.CATEGORY));
        break;
      case TabState.CUSTOMIZED:
        setIcons(iconsStorage.filter(icon => icon.category === null));
        break;
      case TabState.SEARCH:
        setSearching(true);
        setFilterIcons(iconsStorage);
        break;
    }
  };

  /**
   * Update icon selected
   * @param index inex of icon choosed
   */
  const selectIcon = (index: number) => {
    if (filterIcons.length > 0 && selectedTab === TabState.SEARCH) {
      setIconID(textToSave, filterIcons[index].id);
      setIcon(filterIcons[index]);
    } else {
      setIconID(textToSave, icons[index].id);
      setIcon(icons[index]);
    }
  };

  /**
   * onDrop method to process image uploaded
   */
  const onDrop = (acceptedImage: any) => {
    if (!acceptedImage[0]) {
      setAlertProps({
        type: AlertTypeEnum.ERROR,
        title: t("pages.announcements.detailPage.rows.imageError"),
      });
      showAlert();
    } else if (acceptedImage[0] && acceptedImage[0].size > maxSize) {
      setAlertProps({
        type: AlertTypeEnum.ERROR,
        title: t("pages.announcements.detailPage.rows.imageError"),
        content: t("pages.announcements.detailPage.rows.maxSizeIconError", { size: `${maxSize / 1000}` }),
      });
      showAlert();
    } else {
      const file: File = acceptedImage[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function(e) {
        const img = new Image();
        img.src = e.target !== null && e.target.result !== null ? (e.target.result as string) : "";
        img.onload = function() {
          const width = img.naturalWidth || img.width;
          const height = img.naturalHeight || img.height;
          const aspectRatio = width / height;
          if (width >= iconMinWidth && height >= iconMinHeight) {
            if (aspectRatio !== 1 / 1) {
              //aspect ratio allowed 1:1
              setAlertProps({
                type: AlertTypeEnum.ERROR,
                title: t("pages.announcements.detailPage.rows.imageError"),
                content: t("pages.announcements.detailPage.rows.iconAspectRatioError"),
              });
              showAlert();
            } else {
              processUploadIcon(reader.result, file);
            }
          } else {
            setAlertProps({
              type: AlertTypeEnum.ERROR,
              title: t("pages.announcements.detailPage.rows.imageError"),
              content: t("pages.announcements.detailPage.rows.minIconDimensionSize"),
            });
            showAlert();
          }
        };
      };
    }
  };

  /**
   * process uploaded icon method
   */
  const processUploadIcon = (base64: string | ArrayBuffer | null, file: any) => {
    AnnouncementsApi.methods
      .addIcon(file.path, base64)
      .then(res => {
        const iconID = res.data;
        const icon = { id: iconID, label: file.path, category: null, base64 } as IIcon;
        setIcon(icon);
        setIconID(textToSave, iconID);
        setUploadedImage(icon);
        sessionStorage.setItem("icons", JSON.stringify([...iconsStorage, icon]));
        setUploadedImageSize(file.size);
      })
      .catch(() => {
        setAlertProps({
          type: AlertTypeEnum.ERROR,
          title: t("pages.announcements.detailPage.rows.imageError"),
        });
        showAlert();
      });
  };

  React.useEffect(() => {
    if (search.length > 0) {
      setFilterIcons(
        iconsStorage
          .filter(icon => icon.label.toLowerCase().includes(search.toLowerCase()))
          .filter(icon => customIcons || icon.category !== null),
      );
    } else {
      setFilterIcons(icons);
    }
  }, [search, setFilterIcons]);

  React.useEffect(() => {
    setIcons(icons);
  }, [setFilterIcons]);

  return (
    <IconsDropdown>
      {!searching ? (
        <Tabs
          isSecondaryTabs={true}
          controlledSetSelectedTab={controlledSetSelectedTab}
          controlledSelectedTab={selectedTab}
          key={iconsStorage.length}
        >
          {iconsStorage.filter(icon => icon.category === IconCategory.ACTION).length > 0 ? (
            <TabChild label={t("pages.announcements.detailPage.rows.iconDropdown.actions")}>
              <IconSelectorSection>
                {icons.map((icon, index) => (
                  <IconContainer
                    key={index}
                    logo={icon.base64}
                    onClick={() => selectIcon(index)}
                    selected={icon.id === iconID}
                  />
                ))}
              </IconSelectorSection>
            </TabChild>
          ) : (
            <></>
          )}

          {iconsStorage.filter(icon => icon.category === IconCategory.CATEGORY).length > 0 ? (
            <TabChild label={t("pages.announcements.detailPage.rows.iconDropdown.categories")}>
              <IconSelectorSection>
                {icons.map((icon, index) => (
                  <IconContainer
                    key={index}
                    logo={icon.base64}
                    onClick={() => selectIcon(index)}
                    selected={icon.id === iconID}
                  />
                ))}
              </IconSelectorSection>
            </TabChild>
          ) : (
            <></>
          )}

          {customIcons && iconsStorage.filter(icon => icon.category === null).length > 0 ? (
            <TabChild label={t("pages.announcements.detailPage.rows.iconDropdown.customized")}>
              <IconSelectorSection>
                {icons.map((icon, index) => (
                  <IconContainer
                    key={index}
                    logo={icon.base64}
                    onClick={() => selectIcon(index)}
                    selected={icon.id === iconID}
                  />
                ))}
              </IconSelectorSection>
            </TabChild>
          ) : (
            <></>
          )}

          {
            //@ts-ignore
            <TabChild label={<SearchIcon width="19px" height="19px" style={{ stroke: "#999999" }} />}>
              <IconSelectorSection>
                {icons.map((icon, index) => (
                  <IconContainer
                    key={index}
                    logo={icon.base64}
                    onClick={() => selectIcon(index)}
                    selected={icon.id === iconID}
                  />
                ))}
              </IconSelectorSection>
            </TabChild>
          }
          {customIcons ? (
            <TabChild
              //@ts-ignore
              label={
                <CloudUploadIcon
                  width="19px"
                  height="19px"
                  style={{ stroke: selectedTab === TabState.UPLOAD ? "black" : "#999999" }}
                />
              }
            >
              <DropzoneIconContainer>
                {!uploadedImage ? (
                  <DropzoneIcon
                    onDrop={(file: any) => {
                      onDrop(file);
                    }}
                    accept={formats}
                    multiple={false}
                    dropText={t("pages.announcements.detailPage.rows.dropImage")}
                    releaseText={t("pages.announcements.detailPage.rows.uploadImage")}
                    requirementsText={t("pages.announcements.detailPage.rows.requirementsImage")
                      .replace("{format}", formats)
                      .replace("{dimensions}", `${iconMinWidth}x${iconMinHeight}`)
                      .replace("{size}", `${maxSize / 1000}`)}
                  />
                ) : (
                  <IconUploadedContainer style={{ display: "flex", alignItems: "center" }}>
                    <IconUploaded style={{ display: "flex" }}>
                      <img src={uploadedImage.base64} />
                      <CancelIcon onClick={() => setUploadedImage(undefined)} />
                    </IconUploaded>
                    <IconUploadedTextContainer
                      style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}
                    >
                      <IconUploadedText>{uploadedImage.label}</IconUploadedText>
                      <IconUploadedSubtext>{`${Math.round(uploadedImageSize / 1000)}KB`}</IconUploadedSubtext>
                    </IconUploadedTextContainer>
                  </IconUploadedContainer>
                )}
              </DropzoneIconContainer>
            </TabChild>
          ) : (
            <></>
          )}
        </Tabs>
      ) : (
        <div>
          <CustomRow>
            <SearchBar
              placeholderLabel={t("pages.announcements.addPage.searchIconFilter")}
              value={search}
              onChange={e => setSearch(e.target.value)}
              clearValue={() => null}
            />
            <CancelIcon
              width={"24px"}
              height={"24px"}
              style={{ stroke: "#999999", marginRight: "12px" }}
              onClick={() => {
                setSearching(false);
                setSearch("");
                controlledSetSelectedTab(0);
              }}
            />
          </CustomRow>
          <IconSelectorSection>
            {filterIcons.length > 0 ? (
              filterIcons.map((icon, index) => (
                <IconContainer
                  key={index}
                  logo={icon.base64}
                  onClick={() => selectIcon(index)}
                  selected={icon.id === iconID}
                />
              ))
            ) : (
              <NoIconFound>
                <IconNotFound>
                  <UploadImagePlaceHolderIcon />
                </IconNotFound>
                <LabelNotFound>{t("pages.announcements.addPage.noFoundIcons")}</LabelNotFound>
              </NoIconFound>
            )}
          </IconSelectorSection>
        </div>
      )}
    </IconsDropdown>
  );
};

export default IconOptions;

const IconsDropdown = styled("div")`
  height: 201px;
  width: 100%;
  > button {
    background: red;
  }

  > div {
    height: 100%;
    width: 100%;
  }
  > div > div:first-child {
    display: flex;
    padding-top: 1vh;
    padding-left: 0.5vw;
    height: 36px;
    > div:first-child {
      width: 100%;
      display: flex;
      flex: none;
      > div:nth-last-child(-n + 2) {
        margin-left: auto;
        margin-right: 14px;
      }
      > div:last-child {
        margin-left: 0;
      }
    }
  }
  > div > div:last-child {
    margin-top: 0;
    height: 164px;
    width: 100%;
  }
`;

const IconSelectorSection = styled("div")`
  border-top: 1px solid #ebebeb;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  padding: 6px;
  overflow-y: scroll;
`;

const IconContainer = styled("div")<{
  logo: string;
  selected: boolean;
}>`
  background-image: ${props => (props.logo ? `url("${props.logo}")` : "none")};
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: center;
  background-color: #fafafa;
  stroke: #8d99a7;
  width: 32px;
  height: 32px;
  margin: 4px 4px;
  border-radius: 3px;
  ${props =>
      props.selected
        ? `stroke: ${props.theme.palette.white};background-color: ${props.theme.palette.vodafoneRed};`
        : null}
    :hover {
    stroke: ${props => props.theme.palette.white};
    background-color: ${props => props.theme.palette.vodafoneRed};
  }
`;

const CustomRow = styled("span")`
  display: flex;
  align-items: center;
  > div {
    > div {
      display: none;
    }
    border: none;
    border-radius: inherit;
    :hover {
      border: none;
    }
    > input {
      font-size: 14px;
    }
    > svg {
      stroke: #333333;
      width: 14px;
      height: 14px;
    }
  }
`;

const NoIconFound = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const LabelNotFound = styled("div")`
  margin-top: 1vh;
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: ${props => props.theme.palette.midGrey};
`;
const IconNotFound = styled("div")`
  svg {
    width: 36px;
    height: 36px;
  }
`;

const DropzoneIconContainer = styled("div")`
  padding: 8px;
  width: 100%;
  height: 100%;
  border-top: solid 1px ${props => props.theme.palette.aluminium};
`;

const IconUploadedContainer = styled("div")`
  padding: 8px;
  width: 100%;
  height: 100%;
  diplay: flex !important;
  align-item: center;
`;

const IconUploaded = styled("div")`
  width: 56px;
  height: 56px;
  diplay: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 2.8px;
  border: solid 0.9px ${props => props.theme.palette.aluminium};
  background-color: ${props => props.theme.palette.greyLight};
  margin-left: 50px;
  margin-right: 36px;
  position: relative;
  img {
    width: 48px;
    height: 48px;
  }
  svg {
    width: 14px;
    height: 14px;
    background-color: grey;
    border-radius: 50%;
    position: absolute;
    stroke: white;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }
`;

const IconUploadedSubtext = styled("span")`
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: ${props => props.theme.palette.grey};
`;

const IconUploadedText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  word-break: break-word;
  text-align: left;
  line-height: normal;
`;

const IconUploadedTextContainer = styled("div")`
  margin-right: 50px;
`;
