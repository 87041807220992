import { Table, useAlert, useFilters, useModal, ModalTypeEnum } from "@wit/mpesa-ui-components";
import { ColumnProps, SortDirectionEnum } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import { Buffer } from "buffer";
import moment from "moment/moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IStoreInterface } from "../../../../configs/store.config";
import { DocValidationActions } from "../doc-validation.store";
import { DocValidationUtils } from "../doc-validation.utils";
import { PageContainer, PageTitle } from "../../../../shared/shared.styled";
import {
  IClientDocSubmissionsRequest,
  IClientDocSubmissionsResponse,
  ColumnKeysToSortParam,
  SortDirectionToSortParam,
  ISortParams,
  IClientDocSubmission,
} from "../doc-validation.interface";
import { LoadingText } from "../../../../shared/shared.styled";
import styled from "styled-components";
import TablePagination from "../../../../shared/components/table-pagination/table-pagination-component";
import DocValidationFilters, { DocValidationFilterKeys } from "../components/doc-validation-filters.component";
import DocValidationApi from "../doc-validation.api";
import DocBulkApi from "../../doc-bulk/doc-bulk.api";
import { IDocBulkCreateRequest } from "../../doc-bulk/doc-bulk.interface";
import { RoutesEnum } from "../../../../routes/routes.constants";
import { ClientDocSubmissionStatusEnum, ClientDocSubmissionStatusEnumKeys } from "../../ekyc.shared.enums";
import { useHistory, useLocation } from "react-router-dom";
import { ILoggedUser } from "../../../authentication/authentication.interfaces";
import EmptyTable from "@wit/mpesa-ui-components/lib/components/table/empty-states/empty-table.component";

const DocValidationPage = () => {
  const history = useHistory();

  // Hooks init
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const [showConfirmModal, hideConfirmModal, setConfirmModalProps] = useModal(ModalTypeEnum.ConfirmationModal);
  const {
    filters,
    updateFilter,
    isFilterActive,
    clearFilter,
    resetFilters,
    getFilterValue,
    getFiltersQueryString,
    updateMultipleFilters,
  } = useFilters();

  const previousFilters = {} as any;
  const { state } = useLocation<any>();
  const submissionSavedSuccessfully = Boolean(state?.successfulUpdate);
  const pageSize = 10;

  // Local state definitions
  const [isFetchingDocs, setIsFetchingDocs] = React.useState(false);
  const [firstLoadRequest, setFirstLoadRequest] = React.useState(true);
  const [activePage, setActivePage] = React.useState((previousFilters && previousFilters.activePage) || 1);
  const [sortColumnFormKey, setSortColumnFormKey] = React.useState(
    (previousFilters && previousFilters.sortColKey) || "status",
  );
  const [sortColumnDirection, setSortColumnDirection] = React.useState(
    (previousFilters && previousFilters.sortDirection) || SortDirectionEnum.ASC,
  );
  const [requestParams, setRequestParams] = React.useState<IClientDocSubmissionsRequest>({
    page: 1,
    pageSize: pageSize,
    sort: {
      fieldName: "status",
      directionType: "ASC",
    },
  });
  const [requestParamsTemp, setRequestParamsTemp] = React.useState<IClientDocSubmissionsRequest>({
    page: 1,
    pageSize: pageSize,
    sort: {
      fieldName: "status",
      directionType: "ASC",
    },
  });

  // Redux state
  const { clientSubmissionDocs, totalSubmissions } = useSelector(
    (store: IStoreInterface) => store.docValidationReducer,
  );
  const user = useSelector((s: IStoreInterface) => s.authenticationReducer.loggedUser as ILoggedUser);

  /*
   * Shows the "Success" modal when a registration is updated
   */
  React.useEffect(() => {
    if (submissionSavedSuccessfully) {
      setAlertProps({
        title: t("pages.docSubmissionDetails.changeDetailsSuccess"),
        type: AlertTypeEnum.SUCCESS,
      });
      showAlert();
    }
  }, []);

  /*
   * Queries the server for the filtered docs and updates the table,
   * every time the "requestParams" change.
   */
  React.useEffect(() => {
    if (firstLoadRequest) {
      setFirstLoadRequest(false);

      const previousFilters = null as any;
      if (previousFilters) {
        updateMultipleFilters(previousFilters.uiFilters || []);
        setRequestParams(previousFilters.request);
        return;
      }
    }

    getClientDocSubmissions();
  }, [requestParams]);

  /*
   * Updates the temporary request parameters, to be used to query the server,
   * every time the filters are changed
   */
  React.useEffect(() => {
    let request: IClientDocSubmissionsRequest = { page: activePage, pageSize };

    request = {
      ...request,
      ...getSearchFilterParams(),
      ...getStatusFilterParams(),
      ...getDateFilterParams(),
      sort: { ...request.sort, ...getSortParam(sortColumnFormKey, sortColumnDirection) },
    };
    setRequestParamsTemp(request);
  }, [filters]);

  /*
   * Handles the pagination change
   */
  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
    refreshResults(undefined, pageNumber);
  };

  /*
   * Evaluates the free text search filter parameters for the server request
   */
  const getSearchFilterParams = () => {
    if (!filters.get(DocValidationFilterKeys.SEARCH)) {
      return null;
    }

    let obj;
    const searchParam = ((filters.get(DocValidationFilterKeys.SEARCH_PARAM) || []) as string[])[0];
    const searchValue = ((filters.get(DocValidationFilterKeys.SEARCH) || []) as string[])[0];

    switch (searchParam) {
      case "name":
        obj = { searchString: searchValue };
        break;
      case "requestId":
      default:
        obj = { searchString: searchValue };
        break;
    }
    return obj;
  };

  /*
   * Evaluates the status filter parameters for the server request
   */
  // eslint-disable-next-line require-jsdoc
  const getStatusFilterParams = () => {
    if (!filters.get(DocValidationFilterKeys.STATUS)) {
      return null;
    }

    const multipleStatusStr = filters.get(DocValidationFilterKeys.STATUS) as ClientDocSubmissionStatusEnum[];

    const multipleStatus = multipleStatusStr.map(function(status: string) {
      return ClientDocSubmissionStatusEnum[status as ClientDocSubmissionStatusEnumKeys];
    });

    return { status: multipleStatus };
  };

  /*
   * Evaluates the date filter parameters for the server request
   */
  const getDateFilterParams = () => {
    if (!filters.get(DocValidationFilterKeys.START_DATE) && !filters.get(DocValidationFilterKeys.END_DATE)) {
      return null;
    }

    const startDateStr = filters.get(DocValidationFilterKeys.START_DATE) as string[];
    let startTimestamp;
    if (startDateStr) {
      const startDate: Date = new Date(Number.parseInt(startDateStr[0]));
      startDate.setMilliseconds(0);
      startDate.setSeconds(0);
      startDate.setMinutes(0);
      startDate.setHours(0);
      startTimestamp = startDate.getTime();
    }

    const endDateStr = filters.get(DocValidationFilterKeys.END_DATE) as string[];
    let endTimestamp;

    if (endDateStr) {
      const endDate: Date = new Date(Number.parseInt(endDateStr[0]));
      endDate.setMilliseconds(999);
      endDate.setSeconds(59);
      endDate.setMinutes(59);
      endDate.setHours(23);
      endTimestamp = endDate.getTime();
    }

    return {
      dateRangeIni: startTimestamp,
      dateRangeEnd: endTimestamp,
    };
  };

  /*
   * Evaluates the sort parameter for the server request
   */
  const getSortParam = (sortColumnFormKey?: string, sortDirection?: SortDirectionEnum) => {
    let sort: ISortParams | undefined = requestParamsTemp.sort;
    if (sortColumnFormKey && sortDirection) {
      sort = {
        ...sort,
        fieldName: `${(ColumnKeysToSortParam as any)[sortColumnFormKey || ""]}`,
        directionType: SortDirectionToSortParam[sortDirection],
      };
    }

    return sort;
  };

  /*
   * Get client doc submissions
   */
  const getClientDocSubmissions = () => {
    setIsFetchingDocs(true);
    DocValidationApi.methods
      .getClientDocSubmissions(requestParams)
      .finally(() => setIsFetchingDocs(false))
      .then(
        res => {
          const response: IClientDocSubmissionsResponse = res.data;
          dispatch(
            DocValidationActions.creators.fetchDocsSuccessAction(response.registrations, response.totalElements),
          );
        },
        () => {
          setAlertProps({
            title: t("pages.docValidation.getDocsError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
  };

  /*
   * Refresh results handler
   */
  const refreshResults = (requestParams?: IClientDocSubmissionsRequest, pageNumber?: number) => {
    let params = requestParams || requestParamsTemp;
    params = { ...params, page: pageNumber || 1 };
    setActivePage(pageNumber || 1);
    setRequestParams(params);
  };

  /**
   * Export registrations to csv
   */
  const exportRegistrations = () => {
    DocValidationApi.methods.exportRegistrations(requestParams).then(
      res => {
        const csvData = res.data.content;
        const file = Buffer.from(csvData, "base64").toString();
        const filename = `registrations_export_${moment(new Date()).format("YYYYMMDD_Hmmss")}.csv`;
        const fileDownload = require("js-file-download");
        fileDownload(file, filename);
      },
      () => {
        setAlertProps({
          title: t("pages.docValidation.getDocsError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /*
   * Handles sort changes
   */
  const handleSortChange = (sortColumn: ColumnProps, sortDirection: SortDirectionEnum) => {
    const sortCol = sortColumn.formKey || "";
    setSortColumnFormKey(sortCol);
    setSortColumnDirection(sortDirection);

    let request: IClientDocSubmissionsRequest = { page: activePage, pageSize };
    request = {
      ...request,
      ...getSearchFilterParams(),
      ...getStatusFilterParams(),
      ...getDateFilterParams(),
      sort: { ...request.sort, ...getSortParam(sortCol, sortDirection) },
    };
    setRequestParamsTemp(request);
    refreshResults(request);
  };

  const createBulk = () => {
    // TBD: For the current version the bulk functionality is not being used, however it is going to be used in the future(or not 😁)
    setConfirmModalProps({
      title: t("pages.docValidation.bulk.confirmModal.title"),
      description: t("pages.docValidation.bulk.confirmModal.description"),
      primaryAction: () => {
        hideConfirmModal();

        const bulkCreateParams: IDocBulkCreateRequest = {
          searchString: requestParams.searchString,
          dateRangeIni: requestParams.dateRangeIni,
          dateRangeEnd: requestParams.dateRangeEnd,
          sort: requestParams.sort ? requestParams?.sort?.fieldName?.split(", ") : undefined,
          status: [ClientDocSubmissionStatusEnum.ACCEPTED],
        };

        setAlertProps({
          title: t("pages.docValidation.bulk.creatingBulk"),
          type: AlertTypeEnum.SUCCESS,
        });
        showAlert();

        DocBulkApi.methods
          .createBulk(bulkCreateParams, user.username)
          .finally(() => {
            hideAlert();
          })
          .then(
            res => {
              const bulkName = res.data && res.data.bulkName;
              setAlertProps({
                title: t("pages.docValidation.bulk.creationSuccess", { name: bulkName }),
                type: AlertTypeEnum.SUCCESS,
              });
              showAlert();
            },
            error => {
              let errorMsgKey = "pages.docValidation.bulk.creationError";
              if (error && error.data) {
                switch (error.data.errorCode) {
                  case 408:
                    errorMsgKey = "pages.docValidation.bulk.creationErrorMultipleMsisdn";
                    break;
                  case 409:
                    errorMsgKey = "pages.docValidation.bulk.creationErrorInvalidStates";
                    break;
                }
              }

              setAlertProps({
                title: t(errorMsgKey),
                type: AlertTypeEnum.ERROR,
              });
              showAlert();
            },
          );
      },
      secondaryAction: () => hideConfirmModal(),
    } as BaseModalProps);

    showConfirmModal();
  };

  // Renders
  return (
    <PageContainer>
      <PageTitle style={{ marginBottom: 43 }}>{t("pages.docValidation.title")}</PageTitle>
      <DocValidationListContainer style={{ position: "relative" }}>
        <DocValidationFilters
          filters={filters}
          isFilterActive={isFilterActive}
          updateFilter={updateFilter}
          clearFilter={clearFilter}
          resetFilters={resetFilters}
          getFilterValue={getFilterValue}
          getFiltersQueryString={getFiltersQueryString}
          updateMultipleFilters={updateMultipleFilters}
          entriesCount={totalSubmissions}
          refreshResults={refreshResults}
          exportRegistrations={exportRegistrations}
        />
        {isFetchingDocs ? (
          <LoadingText>{t("commons.loadingResults")}</LoadingText>
        ) : (
          <>
            <Table<IClientDocSubmission>
              columns={DocValidationUtils.getTableColumns()}
              values={clientSubmissionDocs}
              rowClickAction={(_, rowData) =>
                history.push({
                  pathname: RoutesEnum.DOC_VALIDATION_DETAILS,
                  state: {
                    remoteId: rowData.remoteId,
                  },
                })
              }
              sortable={true}
              initialSortColumnFormKey={sortColumnFormKey}
              initialSortDirection={sortColumnDirection}
              emptyStateComponent={<EmptyTable text={t("commons.emptyTable")} />}
              sortClickAction={(sortColumn: ColumnProps, sortDirection: SortDirectionEnum) => {
                handleSortChange(sortColumn, sortDirection);
              }}
            />
            {totalSubmissions ? (
              <TablePagination
                handlePageChange={handlePageChange}
                totalItems={totalSubmissions}
                activePage={activePage}
                pageSize={pageSize}
              />
            ) : null}
          </>
        )}
      </DocValidationListContainer>
    </PageContainer>
  );
};

export default DocValidationPage;

const DocValidationListContainer = styled("div")`
  display: flex;
  flex-direction: column;
  visibility: none;
`;
