import { ChevronDownIcon } from "@wit/mpesa-ui-components";
import { highlightBlock } from "highlight.js";
import React, { PropsWithChildren } from "react";
import styled from "styled-components";

interface IGroupLabelInterface {
  label: string;
  value: string;
}
interface IDrawerAccordionInterface {
  header: IGroupLabelInterface[];
}

const DrawerAccordion = ({ children, header }: PropsWithChildren<IDrawerAccordionInterface>) => {
  const [isOpen, setIsOpen] = React.useState(false);

  /*
   * Highlights the request and response json and XML
   * */
  React.useEffect(() => {
    document.querySelectorAll("pre code").forEach(block => {
      highlightBlock(block as HTMLElement);
    });
  });
  return (
    <div style={{ paddingTop: "14px", paddingBottom: "14px", marginTop: "23px" }}>
      <AccordionHeader
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {header.map((h, i) => {
          return (
            <>
              <AccordionHeaderItem key={i}>
                <Label>{h.label}</Label>
                <Value>{h.value}</Value>
              </AccordionHeaderItem>
            </>
          );
        })}
        <AccordionChevron open={isOpen}>
          <ChevronDownIcon />
        </AccordionChevron>
      </AccordionHeader>
      {isOpen ? <AccordionBody>{children}</AccordionBody> : null}
    </div>
  );
};

export default DrawerAccordion;

const Label = styled("span")`
  font-family: Vodafone Rg;
  font-size: 14px;
  color: ${props => props.theme.palette.midGrey};
  font-weight: bold;
  margin-bottom: 14px;
`;
const Value = styled("span")`
  font-family: Vodafone Rg;
  font-size: 14px;
  color: ${props => props.theme.palette.black};
`;

const AccordionHeader = styled("div")`
  display: flex;
  padding-left: 68px;
  padding-right: 68px;
  &:hover {
    cursor: pointer;
  }
`;

const AccordionHeaderItem = styled("div")`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  flex: 1;
`;

export const AccordionChevron = styled("div")<{ open: boolean }>`
  &:hover {
    cursor: pointer;
  }
  display: flex;
  svg {
    width: 18px;
    height: 18px;
    stroke: ${props => props.theme.palette.vodafoneRed};
    margin-left: 4px;
    transition: transform 0.25s ease-in-out;
  }
  ${props => (props.open ? "svg { transform: rotate(180deg); }" : null)}
`;

const AccordionBody = styled("div")`
  background-color: ${props => props.theme.palette.greyLight};
  margin-top: 14px;
  margin-bottom: 14px;
  padding: 24px 68px 24px 68px;
`;
