import {
  EditIcon,
  ModalTypeEnum,
  PageTitle,
  PlusIcon,
  SmallButton,
  Table,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import { Breadcrumb } from "@wit/mpesa-ui-components/lib/types";

import i18next from "i18next";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { RoutesEnum } from "../../../../routes/routes.constants";
import { WrappedRow } from "../../../../shared/responsive-ui.styled";
import {
  Column,
  FlexContainer,
  IconContainer,
  LoadingText,
  PageContainer,
  RatioContainer,
  Row,
} from "../../../../shared/shared.styled";
import SubBundleStatusChip from "../../bundles/bundle-details/components/sub-bundle-status.component";
import RateCardsApi from "../rate-cards.api";
import { IChargeProfile, IRange } from "../rate-cards.model";
import { getColumns, renderTiersActions } from "../rate-cards.utils";
import AddChargeProfileTierModal from "./components/add-charge-profile-tier.modal";

const ChargeProfileDetails = () => {
  const [t] = useTranslation();
  const { id } = useParams<any>();
  const [chargeProfile, setChargeProfileDetails] = React.useState<IChargeProfile>();
  const [showAlert, , setAlertProps] = useAlert();
  const [isLoading, setIsLoading] = React.useState<any>();
  const [selectedTier, setSelectedTier] = React.useState<any>();
  const themeContext = useContext(ThemeContext);
  const history = useHistory();
  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );

  React.useEffect(() => {
    getChargeProfileDetails();
  }, []);

  /*
   * Gets the bundle details data
   * */
  const getChargeProfileDetails = () => {
    setIsLoading(true);
    RateCardsApi.methods
      .getChargeProfile(id)
      .then(
        res => {
          setChargeProfileDetails(res.data);
        },
        () => {
          setAlertProps({
            title: t("pages.rateCards.getChargeProfileDetailsError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  /*
   * Request to delete a tier
   * */
  const deleteTier = (tier: IRange) => {
    const index = (chargeProfile as IChargeProfile).ranges.findIndex(r => tier.id === r.id);
    const tempChargeProfile = { ...(chargeProfile as IChargeProfile) };
    tempChargeProfile.ranges = [...(chargeProfile as IChargeProfile).ranges];
    tempChargeProfile.ranges.splice(index, 1);
    RateCardsApi.methods
      .updateChargeProfileTiers(tempChargeProfile.id, tempChargeProfile)
      .then(
        () => {
          setAlertProps({
            type: AlertTypeEnum.SUCCESS,
            title: t("pages.rateCards.deleteTierSuccess"),
          });

          showAlert();
          getChargeProfileDetails();
        },
        err => {
          setAlertProps({
            type: AlertTypeEnum.ERROR,
            title:
              err && err.data && err.data.status && err.data.status.message
                ? err.data.status.message
                : t("pages.rateCards.deleteTierError"),
          });
          showAlert();
        },
      )
      .finally(() => {
        hideConfirmationModal();
      });
  };
  const showDeleteTierModal = (tier: IRange) => {
    setConfirmationProps({
      title: t("pages.rateCards.deleteTierTitle"),
      description: t("pages.rateCards.deleteTierMessage"),
      primaryBtnLabel: t("pages.rateCards.delete"),
      primaryAction: () => deleteTier(tier),
      secondaryAction: () => hideConfirmationModal(),
    } as BaseModalProps);
    showConfirmationModal();
  };

  /*
   * Breadcrumbs fn
   * */
  const getBreadcrumbs = (): Breadcrumb[] => {
    return [
      {
        label: t("pages.configuration.title"),
        url: RoutesEnum.CONFIGS,
      },
      {
        label: t("pages.rateCards.rateCards"),
        url: RoutesEnum.RATE_CARDS,
      },
      {
        label: chargeProfile ? chargeProfile.name : "",
        url: "",
      },
    ];
  };

  /*
   * Edit tier modal
   * */
  const _hideEditTierModal = () => {
    hideEditTierModal();
  };
  const [showEditTierModal, hideEditTierModal] = useModal(
    ModalTypeEnum.CustomModal,
    { modalStyles: { width: 615, padding: 0 } } as any,
    <AddChargeProfileTierModal
      dismissModal={_hideEditTierModal}
      chargeProfile={chargeProfile as IChargeProfile}
      reloadData={getChargeProfileDetails}
      selectedTier={selectedTier}
    />,
  );
  const showEditTier = (item: IRange) => {
    setSelectedTier(item);
    showEditTierModal();
  };

  /*
   * Add new tier modal
   * */
  const _hideAddNewTierModal = () => {
    hideAddNewTierModal();
  };
  const [showAddNewTierModal, hideAddNewTierModal] = useModal(
    ModalTypeEnum.CustomModal,
    { modalStyles: { width: 615, padding: 0 } } as any,
    <AddChargeProfileTierModal
      dismissModal={_hideAddNewTierModal}
      chargeProfile={chargeProfile as IChargeProfile}
      reloadData={getChargeProfileDetails}
      selectedTier={undefined}
    />,
  );
  const showAddNewTier = () => {
    showAddNewTierModal();
  };

  return (
    <PageContainer style={{ fontFamily: "Vodafone Rg" }}>
      <PageTitle
        breadcrumbs={getBreadcrumbs()}
        title={chargeProfile ? chargeProfile.name : ""}
        navigateFunction={history.push}
        goBackFn={() => history.push(RoutesEnum.RATE_CARDS)}
        separatorStyle={{ display: "none" }}
        titleContainerStyle={{ marginTop: "13px", marginBottom: "20px", height: "60px" }}
        rightComponentContainerStyle={{ display: "flex", alignContent: "center", height: "100%", marginRight: "0px" }}
      />

      {chargeProfile && !isLoading ? (
        <>
          <Row style={{ marginTop: 35 }}>
            <RatioContainer ratio={1 / 2}>
              <LabelTitle>{chargeProfile.name}</LabelTitle>{" "}
            </RatioContainer>
          </Row>
          <WrappedRow style={{ marginTop: 24, alignItems: "flex-start" }}>
            <FlexContainer ratio={1 / 4} style={{ flexDirection: "column" }}>
              <Label>{t("pages.rateCards.chargeId")}</Label>
              <Value>{chargeProfile.id}</Value>
            </FlexContainer>
            <FlexContainer ratio={1 / 4} style={{ flexDirection: "column" }}>
              <Label>{t("pages.rateCards.currency")} </Label>
              <Value>{chargeProfile.currencyCode}</Value>
            </FlexContainer>
            <FlexContainer ratio={1 / 4} style={{ flexDirection: "column" }}>
              <Label>{t("pages.rateCards.chargeProfileStatus")} </Label>
              <SubBundleStatusChip style={{ width: "fit-content" }} type={chargeProfile.status as any}>
                {i18next.t(`commons.rateCards.rateCardsStatusEnum.${chargeProfile.status}`)}
              </SubBundleStatusChip>
            </FlexContainer>
            <SmallButtonContainer>
              <SmallButton
                iconComponent={
                  <IconContainer size={16} color={styleTheme.palette.turquoiseBlue} style={{ marginLeft: "auto" }}>
                    <EditIcon />
                  </IconContainer>
                }
                onClick={() => history.push(RoutesEnum.CHARGE_PROFILE_EDIT.replace(":id", id))}
                titleLabel={t("pages.rateCards.editChargeProfile")}
              />
            </SmallButtonContainer>
          </WrappedRow>
        </>
      ) : (
        <LoadingText>{t("commons.loadingResults")}</LoadingText>
      )}
      <Row>
        <AddTierContainer id={"add-new-tier"}>
          <SmallButton
            titleLabel={t("pages.rateCards.addTier")}
            onClick={showAddNewTier}
            iconComponent={
              <IconContainer size={16} color={styleTheme.palette.vodafoneRed}>
                <PlusIcon />
              </IconContainer>
            }
          />
        </AddTierContainer>
      </Row>
      <TableWrapper>
        <Table
          columns={getColumns(t, chargeProfile)}
          values={chargeProfile && chargeProfile.ranges ? chargeProfile.ranges : []}
          displayActions={tier =>
            renderTiersActions<IRange>(
              tier,
              {
                edit: {
                  onClick: () => {
                    showEditTier(tier);
                  },
                },
                delete: {
                  onClick: () => {
                    showDeleteTierModal(tier);
                  },
                },
                restore: { onClick: () => {} },
              },
              themeContext,
            )
          }
        />
      </TableWrapper>
    </PageContainer>
  );
};

const SmallButtonContainer = styled("div")`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex: 25%;
  @media (max-width: 768px) {
    flex: 100%;
    justify-content: flex-start;
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;

const AddTierContainer = styled("div")`
  display: flex;
  margin-left: auto;
  @media (max-width: 768px) {
    margin-left: unset;
  }
`;
const Label = styled("div")`
  font-family: "Vodafone Rg";
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
  margin-bottom: 8px;
  min-height: 21px;
`;

const LabelTitle = styled("div")`
  font-family: "Vodafone Rg";
  font-size: 18px;
  font-weight: bold;
  color: black;
  margin-bottom: 8px;
  min-height: 21px;
`;

const Value = styled("div")`
  font-family: "Vodafone Rg";
  margin-top: 14.5px;
`;

const TableWrapper = styled("div")`
  margin-top: 60px;
`;

export default ChargeProfileDetails;
