import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { PrimaryButton, TextArea } from "@wit/mpesa-ui-components";

interface IDisableAppsModalProps {
  cancelAction: () => void;
  confirmAction: (reason: string) => void;
}

/**
 * DisableAppsModal component
 */
const DisableAppsModal = ({ cancelAction, confirmAction }: IDisableAppsModalProps) => {
  // Hooks initialization
  const [t] = useTranslation();
  const [reason, setReason] = React.useState("");

  return (
    <DisableAppsForm id="disableAppsForm">
      <Title>{t("pages.apps.modals.disableApps.titleDisable")}</Title>
      <Description>{t("pages.apps.modals.disableApps.descriptionDisable")}</Description>
      <div>
        <TextArea
          title={t("pages.apps.modals.disableApps.reasonTitle")}
          placeholder={t("pages.apps.modals.disableApps.reasonPlaceholder")}
          name="reason"
          value={reason}
          verticalResize={false}
          style={{ minHeight: "79px", fontSize: "16px" }}
          onChange={evt => {
            setReason(evt.target.value);
          }}
        />
      </div>
      <DisableAppsButtonsWrapper>
        <DisableAppsButtonDiv>
          <PrimaryButton
            titleLabel={t("pages.apps.modals.disableApps.cancel")}
            onClick={() => {
              cancelAction();
            }}
            redTheme={false}
            loading={false}
            style={{ width: "111px" }}
            id="cancel-button"
          />
        </DisableAppsButtonDiv>
        <DisableAppsButtonDiv>
          <PrimaryButton
            titleLabel={t("pages.apps.modals.disableApps.confirm")}
            onClick={() => {
              confirmAction(reason);
            }}
            disabled={!reason}
            redTheme={true}
            loading={false}
            style={{ width: "100px" }}
            id="confirm-button"
          />
        </DisableAppsButtonDiv>
      </DisableAppsButtonsWrapper>
    </DisableAppsForm>
  );
};

export default DisableAppsModal;

const DisableAppsForm = styled("div")`
  display: flex;
  padding: 24px;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const DisableAppsButtonDiv = styled("div")`
  margin-right: 12px;
  &:last-of-type {
    margin-right: 0;
  }
`;
const DisableAppsButtonsWrapper = styled("div")`
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
`;

const Title = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 13px;
`;
const Description = styled("div")`
  font-family: Vodafone Rg;
  font-size: 18px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 24px;
`;
