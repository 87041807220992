import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import {
  TextInput,
  PrimaryButton,
  Checkbox,
  TextArea,
  AutoClosingDropdown,
  TrashIcon,
  PlusIcon,
  SmallButton,
  EditIcon,
  Toggle,
  SecondaryPageTitle,
} from "@wit/mpesa-ui-components";
import { useTranslation } from "react-i18next";
import { IServiceVersion, ServiceStatusEnum, VersionLanguagesEnum } from "../../../shared/models/service-builder.model";
import { useDispatch } from "react-redux";
import { Formik, FormikHelpers } from "formik";
import { object, string, boolean } from "yup";
import i18next from "i18next";
import ServiceDetailsApi from "../service-details.api";
import { ServiceDetailsActions } from "../service-details.store";
import Dropzone from "../../../shared/components/file-dropzone.component";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { formatBytes } from "../../../shared/shared.utils";
import { IFileLanguageMapping } from "../service-details-version-management/service-details-version-management.component";
import { SERVICE_BUILDER_DEFAULT_LANGUAGE } from "../service-details-version-management/service-details-version-management.component";
import { ILanguage } from "../../../shared/models/language.model";

interface IAddVersionModalProps {
  serviceId: string;
  serviceName: string;
  versionFileZip: IFileLanguageMapping;
  hideModal: () => void;
  showAlert: () => void;
  setAlertProps: (props: any) => void;
  onDrop: (acceptedFile: any) => void;
  clearFields: () => void;
  lastVersion?: string;
  langVersions: ILanguage[];
}

const AddVersionModal = ({
  serviceId,
  serviceName,
  versionFileZip,
  hideModal,
  showAlert,
  setAlertProps,
  onDrop,
  clearFields,
  lastVersion = undefined,
  langVersions = [],
}: IAddVersionModalProps) => {
  // Hooks initialization
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [releaseNotes, setReleaseNotes] = useState("");
  const [versionFiles, setVersionFiles] = useState([versionFileZip] as IFileLanguageMapping[]);

  const getAvailableVersionLanguages = () => {
    //let langArray = getVersionLanguages();
    const filterLang = langVersions.filter((lang: ILanguage) => {
      const usedLanguages = versionFiles.map((file: IFileLanguageMapping) => {
        if (file.language !== undefined) {
          return file.language;
        }
      });
      return !usedLanguages.includes(lang.code as VersionLanguagesEnum);
    });

    return filterLang.map(l => {
      return { key: l.code, label: l.name };
    });
  };

  const onDropNewFile = useCallback(
    (acceptedFile: IFileLanguageMapping, index: number) => {
      if (acceptedFile.file !== undefined && acceptedFile.file.size > 0) {
        const file: File = acceptedFile.file;
        const versionAux = [...versionFiles];
        if (versionAux[index]) {
          versionAux[index].file = acceptedFile.file;
        } else {
          versionAux.push(acceptedFile);
        }

        setVersionFiles(versionAux);
        return file;
      }
    },
    [versionFiles],
  );

  const [modalDims, setModalDims] = useState([0, 0]);
  const openReleaseNotes = () => {
    setModalDims([
      (document.getElementById("addVersionForm") as HTMLElement).clientWidth,
      (document.getElementById("addVersionForm") as HTMLElement).clientHeight,
    ]);
    setReleaseNotesOpened(true);
  };
  const [useRenderer, setUseRenderer] = useState(
    versionFileZip &&
      versionFileZip.file !== undefined &&
      versionFileZip.file.name &&
      versionFileZip.file.name.endsWith(".json")
      ? true
      : false,
  );

  const createServiceVersion = useCallback(
    (versionFiles: IFileLanguageMapping[], versionDetails: IServiceVersion, actions: FormikHelpers<any>) => {
      ServiceDetailsApi.methods
        .addServiceVersion(versionFiles, versionDetails)
        .finally()
        .then(
          res => {
            dispatch(ServiceDetailsActions.creators.fetchingServiceVersionAddAction(res.data));
            setAlertProps({
              title: t("pages.serviceBuilder.success.addingVersion.title"),
              type: AlertTypeEnum.SUCCESS,
            });
            showAlert();
            actions.setSubmitting(false);
            hideModal();
            window.scrollTo(0, 0);
          },
          res => {
            if (
              res.response &&
              res.response.data &&
              res.response.data.code &&
              res.response.data.message &&
              (res.response.data.code === 20002 || res.response.data.code === 10000)
            ) {
              setAlertProps({
                title:
                  res.response.data.code === 20002
                    ? t("pages.serviceBuilder.errors.addingVersion.existsError")
                        .replace("{0}", serviceName)
                        .replace("{1}", versionDetails.version ? versionDetails.version : "")
                    : res.response.data.message,
                content: res.response.data.details ? res.response.data.details : null,
                type: AlertTypeEnum.ERROR,
              });
            } else {
              setAlertProps({
                title: t("pages.serviceBuilder.errors.addingVersion.title"),
                content: t("pages.serviceBuilder.errors.addingVersion.content"),
                type: AlertTypeEnum.ERROR,
              });
            }
            showAlert();
            actions.setSubmitting(false);
          },
        );
    },
    [dispatch, hideModal, serviceName, setAlertProps, showAlert, t],
  );

  const addZip = (values: any, actions: FormikHelpers<any>) => {
    actions.setSubmitting(true);

    let versionDetails: IServiceVersion = {
      version: values.version,
      serviceId: serviceId,
      status: ServiceStatusEnum.UNDEPLOYED,
      useRenderer: useRenderer,
    };
    if (!useRenderer) {
      versionDetails = { ...versionDetails, landingPage: values.landingPage };
    }
    if (releaseNotes && releaseNotes !== undefined) {
      versionDetails = { ...versionDetails, releaseNotes: releaseNotes };
    }

    if (!toggleValue || !useRenderer) {
      createServiceVersion(
        [{ file: versionFileZip.file, language: SERVICE_BUILDER_DEFAULT_LANGUAGE }] as IFileLanguageMapping[],
        versionDetails,
        actions,
      );
    } else {
      createServiceVersion(versionFiles as IFileLanguageMapping[], versionDetails, actions);
    }
  };

  const checkAllFilesInserted = () => {
    for (let i = 0; i < versionFiles.length; i++) {
      if (versionFiles[i].file === undefined) {
        return false;
      }
    }
    return true;
  };
  const removeFileFromIndex = (index: number) => {
    const files = [...versionFiles];
    files.splice(index, 1);
    setVersionFiles([...files]);
  };

  const getValidationSchema = () => {
    if (useRenderer && toggleValue) {
      return object().shape({
        defaultLanguageExists: boolean().oneOf(
          [true],
          i18next.t("pages.serviceBuilder.modals.addServiceVersion.multiLanguage.errorNoDefaultLanguage"),
        ),
        serviceVersionFile: string()
          .required(i18next.t("pages.serviceBuilder.modals.addServiceVersion.versionFile.errorAddAllFiles"))
          .nullable(),
        version: string().required(i18next.t("pages.serviceBuilder.modals.addServiceVersion.version.errorRequired")),
      });
    } else if (useRenderer) {
      return object().shape({
        serviceVersionFile: string()
          .required(i18next.t("pages.serviceBuilder.modals.addServiceVersion.versionFile.errorAddAllFiles"))
          .nullable(),
        version: string().required(i18next.t("pages.serviceBuilder.modals.addServiceVersion.version.errorRequired")),
      });
    } else {
      return object().shape({
        serviceVersionFile: string()
          .required(i18next.t("pages.serviceBuilder.modals.addServiceVersion.versionFile.errorAddAllFiles"))
          .nullable(),
        landingPage: string().required(
          i18next.t("pages.serviceBuilder.modals.addServiceVersion.landingPage.errorRequired"),
        ),
        version: string().required(i18next.t("pages.serviceBuilder.modals.addServiceVersion.version.errorRequired")),
      });
    }
  };

  const [toggleValue, setToggleValue] = useState(false);
  const [releaseNotesOpened, setReleaseNotesOpened] = useState(false);

  useEffect(() => {
    return () => {
      clearFields();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!releaseNotesOpened ? (
        <Formik
          onSubmit={addZip}
          validateOnChange={false}
          initialValues={{
            serviceVersionFile: versionFileZip.file as File,
            landingPage: "",
            version: "",
            useRenderer: useRenderer,
            defaultLanguageExists: true,
          }}
          validationSchema={getValidationSchema()}
          render={({
            handleChange,
            values,
            handleSubmit,
            errors,
            isSubmitting,
            setFieldValue,
            setErrors,
            setValues,
          }) => (
            <AddVersionForm id="addVersionForm" onSubmit={handleSubmit} onChange={handleChange}>
              <ModalTitle>
                <span>{t("pages.serviceBuilder.modals.addServiceVersion.title")}</span>
                <CheckboxWrapper>
                  <Checkbox
                    name="useRenderer"
                    value={useRenderer}
                    onValueChange={(val: boolean) => {
                      setUseRenderer(val);
                    }}
                  />
                  <FieldTitle style={{ margin: "auto", marginLeft: "10px" }}>
                    {t("pages.serviceBuilder.modals.addServiceVersion.useRenderer.title")}
                  </FieldTitle>
                </CheckboxWrapper>
              </ModalTitle>
              <TopFormDiv>
                <EditServiceFormLeft>
                  {!useRenderer ? (
                    <div style={{ display: "flex", paddingLeft: "26px", paddingRight: "26px" }}>
                      <div style={{ flex: 1 }}>
                        <TextInput
                          title={t("pages.serviceBuilder.modals.addServiceVersion.landingPage.title")}
                          placeholder={t("pages.serviceBuilder.modals.addServiceVersion.landingPage.placeholder")}
                          required
                          name="landingPage"
                          onChange={handleChange}
                          value={values.landingPage}
                          error={errors.landingPage}
                          autoComplete="off"
                          style={{ marginBottom: "24px" }}
                        />
                      </div>
                    </div>
                  ) : null}
                  <div style={{ display: "flex", paddingLeft: "26px", paddingRight: "26px" }}>
                    <div style={{ flex: 1 }}>
                      <TextInput
                        title={t("pages.serviceBuilder.modals.addServiceVersion.version.title")}
                        placeholder={t("pages.serviceBuilder.modals.addServiceVersion.version.placeholder")}
                        required
                        name="version"
                        onChange={handleChange}
                        value={values.version}
                        error={errors.version}
                        autoComplete="off"
                        style={{ marginBottom: "0px" }}
                      />
                      {lastVersion !== undefined ? (
                        <FieldHint>
                          <span>Last version: {lastVersion}</span>
                        </FieldHint>
                      ) : null}
                    </div>
                    {releaseNotes === "" ? (
                      <ReleaseButtonDiv>
                        <SmallButton
                          onClick={openReleaseNotes}
                          titleLabel={t("pages.serviceBuilder.modals.addServiceVersion.releaseNotes.addReleaseNotes")}
                          iconComponent={<PlusIcon />}
                        />
                      </ReleaseButtonDiv>
                    ) : (
                      <ReleaseButtonDiv>
                        <SmallButton
                          onClick={openReleaseNotes}
                          titleLabel={t("pages.serviceBuilder.modals.addServiceVersion.releaseNotes.editReleaseNotes")}
                          iconComponent={<EditIcon />}
                        />
                      </ReleaseButtonDiv>
                    )}
                  </div>
                  <div style={{ display: "flex", paddingLeft: "26px", paddingRight: "26px" }}>
                    <FieldTitle mandatory={true} style={{ marginRight: "auto" }}>
                      {t("pages.serviceBuilder.modals.addServiceVersion.versionFile.title")}
                    </FieldTitle>
                    {errors.defaultLanguageExists ? (
                      <ImageError>{errors.defaultLanguageExists}</ImageError>
                    ) : errors.serviceVersionFile ? (
                      <ImageError>{errors.serviceVersionFile}</ImageError>
                    ) : null}
                    {useRenderer ? (
                      <LanguageButtonDiv>
                        <div style={{ display: "flex", marginLeft: "auto", width: "fit-content" }}>
                          <Toggle
                            cb={(newValue: boolean) => {
                              setToggleValue(newValue);
                            }}
                            isDisabled={false}
                            initialValue={toggleValue}
                          />
                          <span
                            style={{
                              fontFamily: "Vodafone Rg",
                              fontSize: "16px",
                              color: "#333333",
                              marginLeft: "11px",
                              lineHeight: "20px",
                            }}
                          >
                            {t("pages.serviceBuilder.modals.addServiceVersion.multiLanguage.toggleLabel")}
                          </span>
                        </div>
                      </LanguageButtonDiv>
                    ) : null}
                  </div>
                  {useRenderer && toggleValue ? (
                    <DropzoneWrapperMultiple
                      id="dropzoneDiv"
                      overflow={
                        (versionFiles.length > 3 && getAvailableVersionLanguages().length > 0) ||
                        versionFiles.length > 4
                          ? "scroll"
                          : "visible"
                      }
                    >
                      {versionFiles.map((fileMapping: IFileLanguageMapping, idx: number) => {
                        return (
                          <>
                            <DropzoneElement>
                              <Dropzone
                                fileName={
                                  fileMapping && fileMapping.file !== undefined && fileMapping.file.name
                                    ? fileMapping.file.name
                                    : ""
                                }
                                inputName="serviceVersionFile"
                                onDrop={(file: any) => {
                                  if (!file[0]) {
                                    setErrors({
                                      ...errors,
                                      serviceVersionFile: t(
                                        "pages.serviceBuilder.modals.addServiceVersion.versionFile.errorFileJson",
                                      ),
                                    });
                                  } else {
                                    let errorFileName = false;
                                    versionFiles.map((fileMapping: IFileLanguageMapping, index: number) => {
                                      if (fileMapping.file && fileMapping.file.name === file[0].name && idx !== index) {
                                        setAlertProps({
                                          title: t("pages.serviceBuilder.errors.fileExists.title"),
                                          content: t("pages.serviceBuilder.errors.fileExists.content"),
                                          type: AlertTypeEnum.ERROR,
                                        });
                                        showAlert();
                                        errorFileName = true;
                                      }
                                    });
                                    if (!errorFileName) {
                                      onDropNewFile({ file: file[0], language: fileMapping.language }, idx);
                                      if (checkAllFilesInserted()) {
                                        setErrors({
                                          ...errors,
                                          serviceVersionFile: "",
                                        });

                                        setFieldValue("serviceVersionFile", file);
                                      }
                                    }
                                  }
                                }}
                                accept={".json"}
                                multiple={false}
                                dropTitle={t("pages.serviceBuilder.modals.addServiceVersion.multiLanguage.addNew")}
                                dropDescription={
                                  fileMapping.file !== undefined
                                    ? fileMapping && fileMapping.file && fileMapping.file.size
                                      ? formatBytes(fileMapping.file.size, 2)
                                      : t("pages.serviceBuilder.addVersion.dropDescription")
                                    : ""
                                }
                                releaseText={t("pages.serviceBuilder.addVersion.release")}
                                multipleDropzones={true}
                              />
                              <LanguageSelectWrapper needScroll={getAvailableVersionLanguages().length > 3}>
                                <AutoClosingDropdown
                                  options={
                                    getAvailableVersionLanguages().length > 0
                                      ? getAvailableVersionLanguages()
                                      : [
                                          {
                                            label: t(
                                              "pages.serviceBuilder.modals.addServiceVersion.multiLanguage.noAvailable",
                                            ),
                                            key: t(
                                              "pages.serviceBuilder.modals.addServiceVersion.multiLanguage.noAvailable",
                                            ),
                                          },
                                        ]
                                  }
                                  dropdownType={DropdownType.RECTANGULAR_NORMAL}
                                  selectOption={opt => {
                                    if (getAvailableVersionLanguages().length > 0) {
                                      const versionAux = [...versionFiles];

                                      if (versionAux[idx]) {
                                        versionAux[idx].language = opt.key;
                                      }

                                      setVersionFiles(versionAux);
                                    }

                                    if (
                                      getAvailableVersionLanguages().some(
                                        (e: any) => e.key === SERVICE_BUILDER_DEFAULT_LANGUAGE,
                                      )
                                    ) {
                                      values.defaultLanguageExists = false;
                                      setValues(values);
                                    } else {
                                      values.defaultLanguageExists = true;
                                      setValues(values);
                                    }
                                  }}
                                  label={t(`commons.serviceBuilder.enums.languages.${fileMapping.language}`)}
                                  hasValue={getAvailableVersionLanguages().length > 0 ? true : false}
                                />
                                {versionFiles.length > 1 ? (
                                  <TrashButtonWrapper
                                    onClick={() => {
                                      removeFileFromIndex(idx);
                                      if (
                                        (versionFiles[idx].language as VersionLanguagesEnum) ===
                                        SERVICE_BUILDER_DEFAULT_LANGUAGE
                                      ) {
                                        values.defaultLanguageExists = false;
                                        setValues(values);
                                      }
                                    }}
                                    type="button"
                                  >
                                    <TrashIcon style={{ width: "20px", height: "20px", stroke: "#999999" }} />
                                  </TrashButtonWrapper>
                                ) : null}
                              </LanguageSelectWrapper>
                            </DropzoneElement>
                          </>
                        );
                      })}
                      {getAvailableVersionLanguages().length > 0 ? (
                        <DropzoneElement>
                          <Dropzone
                            inputName="serviceVersionFile"
                            onDrop={(file: any) => {
                              if (!file[0]) {
                                setErrors({
                                  ...errors,
                                  serviceVersionFile: t(
                                    "pages.serviceBuilder.modals.addServiceVersion.versionFile.errorFileJson",
                                  ),
                                });
                              } else {
                                let errorFileName = false;
                                versionFiles.map((fileMapping: IFileLanguageMapping) => {
                                  if (fileMapping.file && fileMapping.file.name === file[0].name) {
                                    setAlertProps({
                                      title: t("pages.serviceBuilder.errors.fileExists.title"),
                                      content: t("pages.serviceBuilder.errors.fileExists.content"),
                                      type: AlertTypeEnum.ERROR,
                                    });
                                    showAlert();
                                    errorFileName = true;
                                  }
                                });
                                if (!errorFileName) {
                                  setVersionFiles([
                                    ...versionFiles,
                                    {
                                      file: file[0],
                                      language: getAvailableVersionLanguages()[0].key,
                                    } as IFileLanguageMapping,
                                  ]);
                                  setFieldValue("serviceVersionFile", null);
                                  setTimeout(() => {
                                    (document.getElementById(
                                      "dropzoneDiv",
                                    ) as HTMLDivElement).scrollTop = (document.getElementById(
                                      "dropzoneDiv",
                                    ) as HTMLDivElement).scrollHeight;
                                  }, 0);
                                  if (checkAllFilesInserted()) {
                                    setErrors({
                                      ...errors,
                                      serviceVersionFile: "",
                                    });

                                    setFieldValue("serviceVersionFile", file);
                                  }

                                  if (getAvailableVersionLanguages()[0].key === SERVICE_BUILDER_DEFAULT_LANGUAGE) {
                                    values.defaultLanguageExists = true;
                                    setValues(values);
                                  }
                                }
                              }
                            }}
                            accept={".json"}
                            multiple={false}
                            dropTitle={t("pages.serviceBuilder.modals.addServiceVersion.multiLanguage.addNew")}
                            releaseText={t("pages.serviceBuilder.addVersion.release")}
                            multipleDropzones={true}
                          />
                        </DropzoneElement>
                      ) : null}
                    </DropzoneWrapperMultiple>
                  ) : (
                    <DropzoneWrapper id="dropzoneDiv">
                      <div style={{ marginBottom: "24px" }}>
                        <Dropzone
                          fileName={
                            versionFiles[0] && versionFiles[0].file !== undefined && versionFiles[0].file.name
                              ? versionFiles[0].file.name
                              : ""
                          }
                          inputName="serviceVersionFile"
                          onDrop={(file: any) => {
                            if (!file[0]) {
                              setErrors({
                                ...errors,
                                serviceVersionFile: useRenderer
                                  ? t("pages.serviceBuilder.modals.addServiceVersion.versionFile.errorFileJson")
                                  : t("pages.serviceBuilder.modals.addServiceVersion.versionFile.errorFileZip"),
                              });
                            } else {
                              onDropNewFile({ file: file[0], language: versionFiles[0].language }, 0);
                              if (checkAllFilesInserted()) {
                                setErrors({
                                  ...errors,
                                  serviceVersionFile: "",
                                });

                                setFieldValue("serviceVersionFile", file);
                              }
                            }
                          }}
                          accept={useRenderer ? ".json" : ".zip"}
                          multiple={false}
                          dropTitle={t("pages.serviceBuilder.addVersion.dropTitle")}
                          dropDescription={
                            versionFiles[0] && versionFiles[0].file && versionFiles[0].file.size
                              ? formatBytes(versionFiles[0].file.size, 2)
                              : t("pages.serviceBuilder.addVersion.dropDescription")
                          }
                          releaseText={t("pages.serviceBuilder.addVersion.release")}
                        />
                      </div>
                    </DropzoneWrapper>
                  )}

                  <ButtonsWrapper>
                    <ButtonDiv style={{ width: "179px" }}>
                      <PrimaryButton
                        id="createVersionButton"
                        loading={isSubmitting}
                        titleLabel={t("pages.serviceBuilder.modals.addServiceVersion.save.title")}
                        type="submit"
                        redTheme={true}
                        style={{ width: "179px" }}
                      />
                    </ButtonDiv>
                    <ButtonDiv>
                      <PrimaryButton
                        id="cancelButton"
                        titleLabel={t("pages.serviceBuilder.modals.addServiceVersion.cancel.title")}
                        type="button"
                        redTheme={false}
                        onClick={() => {
                          hideModal();
                        }}
                        style={{ width: "117px" }}
                      />
                    </ButtonDiv>
                  </ButtonsWrapper>
                </EditServiceFormLeft>
              </TopFormDiv>
            </AddVersionForm>
          )}
        />
      ) : (
        <Formik
          onSubmit={() => {}}
          validateOnChange={false}
          initialValues={{
            releaseNotes: releaseNotes,
          }}
          render={({ handleChange, values }) => (
            <ReleaseNotesForm style={{ width: modalDims[0], height: modalDims[1], display: "flex" }}>
              <ReleaseNotesPageTitle>
                <SecondaryPageTitle
                  title={t("pages.serviceBuilder.modals.addServiceVersion.releaseNotes.title")}
                  goBackFn={() => {
                    values.releaseNotes = releaseNotes;
                    setReleaseNotes(releaseNotes);
                    setReleaseNotesOpened(false);
                  }}
                  displayInitialsCircle={false}
                  titleFontSize={22}
                />
              </ReleaseNotesPageTitle>

              <TextAreaDivWrapper>
                <TextArea
                  placeholder={t("pages.serviceBuilder.modals.addServiceVersion.releaseNotes.placeholder")}
                  name="releaseNotes"
                  autoComplete="off"
                  verticalResize={false}
                  horizontalResize={false}
                  style={{ flex: "1" }}
                  onChange={handleChange}
                  value={values.releaseNotes}
                />
              </TextAreaDivWrapper>
              <ReleaseNotesButtonsWrapper>
                <ReleaseNotesButtonDiv>
                  <PrimaryButton
                    titleLabel={t("pages.serviceBuilder.modals.addServiceVersion.releaseNotes.addButton")}
                    onClick={() => {
                      setReleaseNotes(values.releaseNotes);
                      setReleaseNotesOpened(false);
                    }}
                    redTheme={true}
                    loading={false}
                    style={{ width: "123px" }}
                  />
                </ReleaseNotesButtonDiv>
                <ReleaseNotesButtonDiv>
                  <PrimaryButton
                    titleLabel={t("pages.serviceBuilder.modals.addServiceVersion.releaseNotes.cancelButton")}
                    onClick={() => {
                      values.releaseNotes = releaseNotes;
                      setReleaseNotes(releaseNotes);
                      setReleaseNotesOpened(false);
                    }}
                    redTheme={false}
                    loading={false}
                    style={{ width: "103px" }}
                  />
                </ReleaseNotesButtonDiv>
              </ReleaseNotesButtonsWrapper>
            </ReleaseNotesForm>
          )}
        />
      )}
    </>
  );
};

export default AddVersionModal;

const AddVersionForm = styled("form")`
  display: flex;
  padding: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const EditServiceFormLeft = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: visible;
`;

const FieldTitle = styled("div")<{ mandatory?: boolean }>`
  min-height: 21px;
  font-family: Vodafone Rg;
  font-weight: bold;
  line-height: 21px;
  font-size: 16px;
  color: #999999;
  margin-bottom: 8px;
  width: fit-content;
  display: inline-flex;
  ${props =>
    props.mandatory ? "::after {content: ' *'; color: #ff0000; font-weight: 400; padding-left: 2px;}" : null};
`;
const TopFormDiv = styled("div")`
  display: flex;
  flex-direction: row;
`;
const ModalTitle = styled("div")`
  font-size: 22px;
  font-family: Vodafone Rg;
  color: inherit;
  margin-bottom: 19px;
  display: flex;
  padding-top: 26px;
  padding-left: 26px;
  padding-right: 26px;
`;

const ButtonsWrapper = styled("div")`
  margin-top: auto;
  width: 100%;
  display: flex;
  padding-left: 26px;
  padding-right: 26px;
  padding-bottom: 26px;
`;
const ButtonDiv = styled("div")`
  margin-right: 12px;
`;
const LanguageButtonDiv = styled("div")`
  margin-left: 20px;
`;
const ReleaseButtonDiv = styled("div")`
  margin-top: 36px;
  margin-left: 24px;
  > button {
    svg {
      width: 20px;
      height: 20px;
      stroke: ${props => props.theme.palette.vodafoneRed};
    }
  }
`;

const ImageError = styled("div")`
  width: fit-content;
  display: inline-flex;
  height: 19px;
  color: #ff0000;
  font-family: Vodafone Rg;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  float: right;
  font-weight: 400;
`;
const FieldHint = styled("div")`
  min-height: 21px;
  font-family: Vodafone Rg;
  line-height: 21px;
  font-size: 14px;
  color: #999999;
  margin-top: 3px;
  width: fit-content;
  display: inline-flex;
  width: 100%;
  > span {
    margin-left: auto;
  }
`;

const DropzoneWrapperMultiple = styled("div")<{ overflow?: string }>`
  max-height: 358px;
  overflow-y: ${props => props.overflow};
  display: flex;
  flex-wrap: wrap;
  padding-left: 26px;
  padding-right: 26px;
  padding-top: 9px;

  ${props => (props.overflow === "scroll" ? "margin-right: 11px; padding-right: 12px;" : null)} ::-webkit-scrollbar {
    width: 4px;
    border-radius: 3px;
    background: #d8d8d8;
  }
  ::-webkit-scrollbar-thumb {
    background: #9d9d9d;
    border-radius: 3px;
  }
`;
const DropzoneWrapper = styled("div")`
  max-height: 290px;
  overflow-y: visible;
  padding-left: 26px;
  padding-right: 26px;
  padding-top: 8px;
`;

const TrashButtonWrapper = styled("button")`
  width: auto;
  border: none;
  background: none;
  padding: 0;
  margin-left: auto;
  > svg:hover {
    cursor: pointer;
    stroke: ${props => props.theme.palette.vodafoneRed} !important;
  }
`;

const CheckboxWrapper = styled("div")`
  display: flex;
  margin: auto;
  margin-right: 0;
  > div:first-of-type {
    margin: auto;
    width: 16px;
    height: 16px;
    display: flex;
  }
`;

const DropzoneElement = styled("div")`
  flex: 1;
  max-width: 311px;
  margin-bottom: 16px;
  :nth-child(2n + 1) {
    margin-right: 26px;
  }
`;

const LanguageSelectWrapper = styled("div")<{ needScroll?: boolean }>`
  margin-top: 8px;
  height: fit-content;
  display: flex;
  max-height: 98px;
  > button {
    width: fit-content;
    height: fit-content;
    border: none;
    background: transparent;
    padding: 0;
    &:hover,
    &:active {
      border: none;
    }
    > div:first-of-type {
      top: 20px !important;
      min-width: 173px !important;
      max-width: 100%;
      margin-bottom: 5px;
      > div {
        max-height: 98px;
        ${props => (props.needScroll ? "margin-right: 3px;" : null)}
        ::-webkit-scrollbar {
          width: 4px;
          border-radius: 3px;
          background: #d8d8d8;
        }
        ::-webkit-scrollbar-thumb {
          background: #9d9d9d;
          border-radius: 3px;
        }
      }
    }
    > span {
      color: ${props => props.theme.palette.midGrey};
      max-width: 100% !important;
      font-family: Vodafone Rg;
      font-size: 14px;
      line-height: 1.36;
    }
    > svg {
      stroke: ${props => props.theme.palette.midGrey};
    }
  }
`;

const ReleaseNotesForm = styled("form")`
  display: flex;
  padding: 24px 28px 24px 28px;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const ReleaseNotesButtonDiv = styled("div")`
  margin-right: 12px;
`;
const ReleaseNotesButtonsWrapper = styled("div")`
  width: 100%;
  display: flex;
  margin-top: 20px;
`;

const TextAreaDivWrapper = styled("div")`
  display: flex;
  height: 100%;
  > div {
    flex: 1;
  }
`;

const ReleaseNotesPageTitle = styled("div")`
  margin-bottom: 21px;
  > div {
    position: relative;
    background: none;
    padding: 0;
    height: auto;
    > span {
      margin-left: 10px !important;
      font-family: Vodafone Rg !important;
    }
  }
`;
