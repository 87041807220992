import { EditIcon, FastActionButton, TrashIcon } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { fadeInKeyframe, IconContainer, RatioContainer, Row } from "../../../../shared/shared.styled";
import { IDynamicScreenCategory } from "../../../configurations/dynamic-screens/dynamic-screens.model";
import { IIconCategory } from "../general-categories.model";

interface IGeneralCategoryListItemProps {
  item: IDynamicScreenCategory;
  itemIcon?: IIconCategory;
  onDelete: (category: IDynamicScreenCategory) => void;
  onEdit: (id: string) => void;
  onClickItemFn: () => void;
  isAntCategories?: boolean;
}

/**
 * CategoryListItem component
 */
const GeneralCategoryListItem = ({
  item,
  itemIcon,
  onDelete,
  onEdit,
  onClickItemFn,
  isAntCategories = false,
}: IGeneralCategoryListItemProps) => {
  const [t] = useTranslation();

  return (
    <>
      <CategoryListItemContainer
        onClick={onClickItemFn}
        id={`category-${
          item.name && item.name.translations && item.name.translations["en"] ? item.name.translations["en"] : item.id
        }`}
        hoverAction={true}
      >
        <ItemTitle ratio={10 / 12}>
          <CategoryIconContainer color="transparent">
            {itemIcon && itemIcon.base64 ? <img src={itemIcon.base64} alt={"category-icon"} /> : null}
          </CategoryIconContainer>
          {item.name && item.name.translations && item.name.translations["en"]}
        </ItemTitle>
        <RatioContainer className="toggable-container" ratio={2 / 12}>
          <Row>
            <FastActionButton
              onClick={e => {
                e.stopPropagation();
                onEdit(item.id as string);
              }}
              iconComponent={
                <IconContainer
                  size={16}
                  color={styleTheme.palette.turquoiseBlue}
                  id={`edit-category${item.name.translations["en"]}`}
                >
                  <EditIcon />
                </IconContainer>
              }
              label={t("pages.generalCategories.table.edit")}
            />

            {!isAntCategories && (
              <FastActionButton
                onClick={e => {
                  e.stopPropagation();
                  onDelete(item);
                }}
                label={t("pages.generalCategories.table.delete")}
                iconComponent={
                  <IconContainer
                    id={`delete-category${item.name.translations["en"]}`}
                    size={16}
                    color={styleTheme.palette.vodafoneRed}
                  >
                    <TrashIcon />
                  </IconContainer>
                }
              />
            )}
          </Row>
        </RatioContainer>
      </CategoryListItemContainer>
    </>
  );
};

export default GeneralCategoryListItem;

const CategoryListItemContainer = styled("li")<{ hoverAction: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 75px;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};

  margin-bottom: 24px;
  padding: 0 35px 0 24px;

  .status-container {
    display: flex;
    justify-content: flex-end;
  }

  .toggable-container {
    display: none;
  }

  :hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    .status-container {
      display: ${props => (props.hoverAction ? "none" : "flex")};
    }
    .toggable-container {
      display: ${props => (!props.hoverAction ? "none" : "flex")};
      justify-content: flex-end;
      animation: ${fadeInKeyframe} 0.25s linear;
      button:first-child {
        margin-right: 12px;
      }
    }
  }
`;

const ItemTitle = styled(RatioContainer)`
  font-family: Vodafone Rg;
  font-size: 22px;
  font-weight: bold;
  color: ${props => props.theme.palette.darkGrey};
  display: flex;
  align-items: center;
`;

const CategoryIconContainer = styled("div")<{ color: string }>`
  width: 55px;
  height: 55px;
  background-color: ${props => (props.color ? props.color : props.theme.palette.midGrey)};
  border-radius: 50%;
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 32px;
    height: 32px;
  }
`;
