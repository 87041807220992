import {
  CheckIcon,
  FastActionButton,
  ModalTypeEnum,
  PageTitle,
  PrimaryButton,
  UndoIcon,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import { Breadcrumb } from "@wit/mpesa-ui-components/lib/types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { IStoreInterface } from "../../../configs/store.config";
import { RoutesEnum } from "../../../routes/routes.constants";
import LoadingIconWhite from "../../../shared/icons/loading-white.icon";
import { ILanguage } from "../../../shared/models/language.model";
import { VersionControlButtonsContainer, VersionControlButtonsRow } from "../../../shared/responsive-ui.styled";
import LanguagesApi from "../../../shared/services/languages.api";
import {
  ButtonContainer,
  Column,
  LoadingText,
  PageContainer,
  RatioContainer,
  Row,
} from "../../../shared/shared.styled";
import CheckLanguageTranslationsLastVersion from "./components/check-language-translations-last-version.modal";
import LanguageTranslationsStatusChip from "./components/language-translations-status-chip.component";
import LanguageTranslationsTable from "./components/language-translations-table.component";
import PublishLanguageTranslationsModal from "./components/publish-language-translations.modal";
import LanguageTranslationsApi from "./language-translations.api";
import { LanguageTranslationsActions } from "./language-translations.store";

/**
 * LanguageTranslations Page
 * @returns {JSX.Element}
 */
const LanguageTranslations = () => {
  const [t] = useTranslation();
  const [showAlert, , setAlertProps] = useAlert();
  const { version } = useParams<any>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [lastVersionLoading, setLastVersionLoading] = React.useState(false);
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [revertLoading, setRevertLoading] = useState(false);
  const { languageTranslations, lastVersion } = useSelector(
    (state: IStoreInterface) => state.languageTranslationsReducer,
    shallowEqual,
  );
  const [availableLanguages, setAvailableLanguages] = React.useState<ILanguage[]>([]);
  const [loadingTranslations, setLoadingTranslations] = useState(true);

  /**
   * Init effect
   */
  React.useEffect(() => {
    getAvailableLanguages();
  }, []);

  /**
   * Get the available languages
   */
  const getAvailableLanguages = () => {
    LanguagesApi.methods.getAvailableLanguages().then(
      res => {
        setAvailableLanguages(res.data.availableLanguages);
        getLanguageTranslationsList();
      },
      () => {
        setAlertProps({
          title: t("pages.languageTranslations.getLanguagesError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        history.push(RoutesEnum.CONFIGS);
        setLoadingTranslations(false);
      },
    );
  };

  /**
   * Fetches the translations list
   */
  const getLanguageTranslationsList = () => {
    LanguageTranslationsApi.methods.getLanguageTranslations(version).then(
      res => {
        dispatch(LanguageTranslationsActions.creators.getLanguageTranslationsSuccess(res.data));
        if (res.data.hasPreviousVersion) {
          getLastVersion();
        }
        setLoadingTranslations(false);
      },
      () => {
        setAlertProps({
          title: t("pages.languageTranslations.getLanguageTranslationsError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        setLoadingTranslations(false);
      },
    );
  };

  /**
   * Fetches last version of the translations list
   */
  const getLastVersion = () => {
    LanguageTranslationsApi.methods.getLastVersion(version).then(
      res => {
        dispatch(LanguageTranslationsActions.creators.getLanguageTranslationsLastVersion(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.languageTranslations.getLastVersionError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * Sends the publish changes to approval
   */
  const publishChanges = () => {
    setApprovalLoading(true);
    LanguageTranslationsApi.methods
      .submitForApproval(version)
      .then(
        res => {
          setAlertProps({
            title: t(
              `pages.languageTranslations.${
                lastVersion.hasPreviousVersion ? "publishSuccess" : "publishNewVersionSuccess"
              }`,
            ),
            type: AlertTypeEnum.SUCCESS,
          });

          showAlert();
          dispatch(LanguageTranslationsActions.creators.getLanguageTranslationsSuccess(res.data));
        },
        () => {
          setAlertProps({
            title: t("pages.languageTranslations.publishError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        setLastVersionLoading(false);
        setApprovalLoading(false);
        dismissPublishModal();
      });
  };

  /**
   * Dismisses the publish modal
   */
  const dismissPublishModal = () => {
    setLastVersionLoading(false);
    hidePublishModal();
  };

  const [showPublishModal, hidePublishModal] = useModal(
    ModalTypeEnum.CustomModal,
    { modalStyles: { width: 825, padding: 0 } } as any,
    <PublishLanguageTranslationsModal
      currentVersion={languageTranslations}
      lastVersion={lastVersion}
      hideModal={dismissPublishModal}
      isLoading={approvalLoading}
      publishChanges={publishChanges}
      availableLanguages={availableLanguages}
    />,
  );

  /**
   * Dismisses the publish modal
   */
  const dismissCheckLastVersionModal = () => {
    hideCheckLastVersionModal();
  };

  const [showCheckLastVersionModal, hideCheckLastVersionModal] = useModal(
    ModalTypeEnum.CustomModal,
    { modalStyles: { width: 482, overflow: "initial" } } as any,
    <CheckLanguageTranslationsLastVersion
      lastVersion={lastVersion}
      availableLanguages={availableLanguages}
      hideModal={dismissCheckLastVersionModal}
    />,
  );

  /**
   * Confirmation modal def
   */
  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );

  /**
   * Reverts the list to last version
   */
  const revertToLastVersion = () => {
    const confirmationProps = {
      title: t("pages.languageTranslations.confirmRevert"),
      description: t("pages.languageTranslations.confirmRevertMessage"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      secondaryAction: () => hideConfirmationModal(),
      primaryAction: () => {
        setRevertLoading(true);
        setConfirmationProps({ ...confirmationProps, isLoading: true });
        LanguageTranslationsApi.methods
          .revertLanguageTranslations(version)
          .then(
            res => {
              setAlertProps({
                title: t("pages.languageTranslations.revertSuccess"),
                type: AlertTypeEnum.SUCCESS,
              });
              showAlert();
              hideConfirmationModal();
              dispatch(LanguageTranslationsActions.creators.getLanguageTranslationsSuccess(res.data));
            },
            () => {
              setAlertProps({
                title: t("pages.languageTranslations.revertError"),
                type: AlertTypeEnum.ERROR,
              });
              showAlert();
            },
          )
          .finally(() => {
            setRevertLoading(false);
            setConfirmationProps({ ...confirmationProps, isLoading: false });
          });
      },
    };

    setConfirmationProps(confirmationProps as BaseModalProps);
    showConfirmationModal();
  };

  /**
   * Get breadcrumbs
   * @returns {Breadcrumb[]}
   */
  const getBreadcrumbs = (): Breadcrumb[] => {
    return [
      {
        label: t("pages.configuration.title"),
        url: RoutesEnum.CONFIGS,
      },
      {
        label: t("pages.languageTranslations.title"),
        url: RoutesEnum.LANGUAGE_TRANSLATIONS,
      },
      {
        label: `${t("pages.languageTranslations.version")} ${version}`,
        url: "",
      },
    ];
  };

  /**
   * Decides the status text of the current version
   * @returns {TFuncReturn<"translation", string, string, undefined> | TFuncReturn<"translation", string, string, undefined>}
   */
  const getVersionStatusText = () => {
    if (languageTranslations.submittedForApproval) {
      return t(`pages.languageTranslations.languageTranslationsListStatusEnum.submittedForApproval`);
    } else {
      return t(`pages.languageTranslations.languageTranslationsListStatusEnum.live`);
    }
  };

  return (
    <PageContainer>
      <PageTitle
        breadcrumbs={getBreadcrumbs()}
        title={`${t("pages.languageTranslations.version")} ${version}`}
        navigateFunction={history.push}
        goBackFn={() => history.push(RoutesEnum.LANGUAGE_TRANSLATIONS)}
        separatorStyle={{ display: "none" }}
        titleContainerStyle={{ marginTop: "13px", marginBottom: "20px", height: "60px" }}
        rightComponentContainerStyle={{ display: "flex", alignContent: "center", height: "100%", marginRight: "0px" }}
        rightComponent={
          <>
            {languageTranslations ? (
              <ButtonContainer style={{ position: "relative", top: "10px" }}>
                <PrimaryButton
                  id={"publish-changes"}
                  disabled={
                    !languageTranslations.hasUnpublishedChanges ||
                    languageTranslations.submittedForApproval ||
                    lastVersionLoading
                  }
                  titleLabel={t("pages.languageTranslations.submitForApproval")}
                  type="submit"
                  redTheme={true}
                  style={{ width: "fit-content" }}
                  onClick={() => {
                    setLastVersionLoading(true);
                    showPublishModal();
                  }}
                />
              </ButtonContainer>
            ) : null}
          </>
        }
      />
      {!languageTranslations ? <LoadingText>{t("commons.loadingResults")}</LoadingText> : null}
      {languageTranslations && availableLanguages?.length > 0 && (
        <LanguageTranslationsContainer>
          <VersionControlButtonsContainer>
            <RatioContainer ratio={2 / 12} style={{ marginRight: "24px" }}>
              <Column>
                <Label>{t("pages.languageTranslations.status")}</Label>
                <LanguageTranslationsStatusChip
                  id={"status-chip"}
                  style={{ marginTop: "16px", width: "max-content" }}
                  type={languageTranslations.status}
                >
                  {getVersionStatusText()}
                </LanguageTranslationsStatusChip>
              </Column>
            </RatioContainer>
            {!languageTranslations.submittedForApproval &&
            languageTranslations.hasUnpublishedChanges &&
            languageTranslations.hasPreviousVersion ? (
              <VersionControlButtonsRow>
                <VersionControlButtonsContainer>
                  <div id={"check-last-version"}>
                    <FastActionButton
                      label={t("pages.languageTranslations.checkLastVersion")}
                      onClick={showCheckLastVersionModal}
                      iconComponent={
                        <FastIconWrapper color={styleTheme.palette.successGreen}>
                          <CheckIcon />
                        </FastIconWrapper>
                      }
                    />
                  </div>
                  <div id={"revert-to-last-version"}>
                    <FastActionButton
                      label={!revertLoading ? t("pages.languageTranslations.revertChanges") : t("commons.loading")}
                      onClick={revertToLastVersion}
                      iconComponent={
                        <FastIconWrapper color={styleTheme.palette.midGrey}>
                          {revertLoading && <LoadingIconWhite strokeColor={styleTheme.palette.midGrey} />}
                          {!revertLoading && <UndoIcon />}
                        </FastIconWrapper>
                      }
                    />
                  </div>
                </VersionControlButtonsContainer>
              </VersionControlButtonsRow>
            ) : null}
          </VersionControlButtonsContainer>
          <LanguageTranslationsTable
            languageTranslations={languageTranslations}
            availableLanguages={availableLanguages}
            loadingTranslations={loadingTranslations}
          />
        </LanguageTranslationsContainer>
      )}
    </PageContainer>
  );
};

const LanguageTranslationsContainer = styled("div")`
  margin-bottom: 34px;
  display: flex;
  flex-direction: column;
`;

const Label = styled("span")`
  font-family: Vodafone Rg;
  font-weight: bold;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
`;

const FastIconWrapper = styled("span")<{ color: string }>`
  width: 16px;
  height: 16px;
  stroke: ${props => props.color};
  svg {
    width: inherit;
    height: inherit;
  }
`;

export default LanguageTranslations;
