import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { IServiceManagerService } from "../../../shared/models/service-manager.model";
import { useSelector, useDispatch } from "react-redux";
import { IStoreInterface } from "../../../configs/store.config";
import ServiceListItem from "./service-list-item.component";
import ServiceManagerApi from "../service-manager.api";
import { ServiceManagerActions } from "../service-manager.store";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { useAlert } from "@wit/mpesa-ui-components";
import { useTranslation } from "react-i18next";
import { ServiceType } from "../service-details/pages/details.interfaces";
import styled from "styled-components";
import Document from "../../configurations/rate-cards/components/document.component";
import { UserType } from "../section-manager/section-manager.interface";
import { FEATURES } from "../../../shared/renderer.utils";
import useCheckFeatureAvailable from "../../../shared/hooks/use-check-available-feature";
import { useParams } from "react-router-dom";
import LoadingComponent from "../../../shared/components/loading-component/LoadingComponent";

interface IListItemsProps {
  groupName?: string;
  items: IServiceManagerService[];
  serviceType?: ServiceType;
  promotedOptions?: boolean;
  userType?: UserType;
  isMiniAppsWithinCategory?: boolean;
  setShouldUpdateLocalCategories?(): void;
  loadingCategories?: boolean;
}

/**
 * Returns a list of mini-apps cards
 * @functions VerticalListComponent
 * @param  {string} [groupName]
 * @param {IServiceManagerService[]} items - array of mini-apps
 * @param {ServiceType} [serviceType]
 * @param {boolean} [promotedOptions]
 * @param {UserType} [userType]
 * @param {boolean} [isMiniAppsWithinCategory] - Is the VerticalListComponent is being called in the category details page
 */
const VerticalListComponent = ({
  items,
  groupName,
  serviceType,
  promotedOptions,
  userType,
  isMiniAppsWithinCategory,
  setShouldUpdateLocalCategories,
  loadingCategories,
}: IListItemsProps) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const params = useParams() as { categoryId: string };
  const { services } = useSelector((state: IStoreInterface) => state.serviceManagerReducer);
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const serviceUserTypeEnabled = useCheckFeatureAvailable(FEATURES.SERVICE_USER_TYPE);

  const [canRequest, setCanRequest] = useState(true);
  const [orderedMiniApps, setOrderedMiniApps] = useState(items);
  const [loadingOrdering, setLoadingOrdering] = useState(false);

  useEffect(() => {
    setOrderedMiniApps(items);
  }, [items]);

  /** function to reorder */
  const reorder = (list: ArrayLike<IServiceManagerService>, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const filteredResult = result.filter(item => item !== undefined);

    return filteredResult;
  };

  /**
   * Handle the dragend event
   * @function
   * @name onDragEnd
   * @fires OnDragEnd
   * @param {Object} result - The result object of the dragend event
   */
  const onDragEnd = (result: any): void => {
    const { source, destination, draggableId: miniAppId } = result;

    if (!destination) {
      return;
    }

    if (isMiniAppsWithinCategory) {
      const itemsList = reorder(orderedMiniApps, source.index, destination.index);
      setOrderedMiniApps([...itemsList]);
      setLoadingOrdering(true);

      ServiceManagerApi.methods
        .reorderMiniAppWithinCategory({
          categoryId: params.categoryId,
          miniAppId,
          fromPosition: source.index + 1,
          toPosition: destination.index + 1,
        })
        .then(
          () => {
            setShouldUpdateLocalCategories && setShouldUpdateLocalCategories();
            setLoadingOrdering(false);
          },
          () => {
            setLoadingOrdering(false);
            setAlertProps({
              title: t("pages.serviceManager.categories.miniApps.errorReordering"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          },
        );
      return;
    }

    orderedMiniApps.forEach(element => {
      if (element.id === result.draggableId) {
        element.groups.forEach(group => {
          if (
            group.name === groupName &&
            group.serviceType === serviceType &&
            (!serviceUserTypeEnabled || group.userType === userType)
          ) {
            editServiceOrder(result.draggableId, destination.index, group.serviceType, group.promotedOrder);
          }
        });
      }
    });

    const itemsList = reorder(orderedMiniApps, source.index, destination.index);
    setOrderedMiniApps([...itemsList]);
  };

  /** function editServiceOrder */
  const editServiceOrder = (
    identifier: string,
    toPosition: number,
    serviceType: ServiceType,
    promotedPositon?: number | undefined,
  ) => {
    ServiceManagerApi.methods
      .updateGroupOrder(
        identifier,
        groupName!,
        toPosition + 1,
        serviceType,
        serviceUserTypeEnabled ? userType : undefined,
        promotedPositon,
      )
      .then(() => {
        dispatch(ServiceManagerActions.creators.updateSectionManagerOrderList());
        ServiceManagerActions.creators.fetchingServicesAction();
        ServiceManagerApi.methods.getServices().then(
          res => {
            dispatch(ServiceManagerActions.creators.fetchServicesSuccessAction(res.data));
          },
          () => {
            setAlertProps({
              title: t("pages.serviceBuilder.errors.searchServices"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          },
        );
      });
  };

  function getPromotedSize() {
    let promotedSize: number = 0;
    services.forEach(item => {
      item.groups.forEach(group => {
        if (
          group.name === groupName &&
          group.promotedOrder &&
          group.serviceType === serviceType &&
          (!serviceUserTypeEnabled || group.userType === userType) &&
          group.promotedOrder > 0
        ) {
          promotedSize++;
        }
      });
    });
    return promotedSize;
  }

  /** function to Promote Service */
  const promoteService = (item: IServiceManagerService, setIsLoading: (value: boolean) => void) => {
    let toPosition: number;
    if (canRequest) {
      if (getPromotedSize() < 3) {
        setCanRequest(false);
        item.groups.forEach(group => {
          if (
            group.name === groupName &&
            group.serviceType === serviceType &&
            group.order &&
            (!serviceUserTypeEnabled || group.userType === userType)
          ) {
            toPosition = group.order;
            if (!group.promotedOrder) {
              promoteServiceCall(item.id, group.serviceType, toPosition, setIsLoading);
            } else {
              setAlertProps({
                title: t("pages.serviceManager.serviceListItem.alertProps.promoteSizeMinor.title"),
                type: AlertTypeEnum.ERROR,
              });
              showAlert();
            }
          }
        });
      } else {
        setAlertProps({
          title: t("pages.serviceManager.serviceListItem.alertProps.promoteSizeLimit.title"),
          content: t("pages.serviceManager.serviceListItem.alertProps.promoteSizeLimit.content"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      }
    }
  };

  /** function to promoteServiceCall */
  const promoteServiceCall = (
    identifier: string,
    serviceType: ServiceType,
    toPosition: number,
    setIsLoading: (value: boolean) => void,
  ) => {
    if (getPromotedSize() < 3) {
      setIsLoading(true);
      ServiceManagerApi.methods
        .updateGroupOrder(
          identifier,
          groupName!,
          toPosition,
          serviceType,
          serviceUserTypeEnabled ? userType : undefined,
          getPromotedSize() + 1,
        )
        .then(
          () => {
            setAlertProps({
              title: t("pages.serviceManager.serviceListItem.success.updateGroup"),
              type: AlertTypeEnum.SUCCESS,
            });
            showAlert();
            ServiceManagerActions.creators.fetchingServicesAction();
            ServiceManagerApi.methods.getServices().then(res => {
              dispatch(ServiceManagerActions.creators.fetchServicesSuccessAction(res.data));
              setIsLoading(false);
            });
          },
          () => {
            setIsLoading(false);
            setAlertProps({
              title: t("pages.serviceManager.serviceListItem.errors.updateGroup"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          },
        )
        .finally(() => {
          setCanRequest(true);
        });
    } else {
      setAlertProps({
        title: t("pages.serviceManager.serviceListItem.alertProps.promoteSizeLimit.title"),
        content: t("pages.serviceManager.serviceListItem.alertProps.promoteSizeLimit.content"),
        type: AlertTypeEnum.ERROR,
      });
      showAlert();
    }
  };

  /** function to umpromoteService */
  const unpromoteService = (item: IServiceManagerService, setIsLoading: (value: boolean) => void) => {
    let toPosition: number;

    item.groups.forEach(group => {
      if (
        group.name === groupName &&
        group.serviceType === serviceType &&
        (!serviceUserTypeEnabled || group.userType === userType) &&
        group.order
      ) {
        toPosition = group.order;
        if (group.promotedOrder) {
          if (!toPosition) {
            setIsLoading(true);
            ServiceManagerApi.methods
              .unpromoteDeleteService(
                item.id,
                groupName,
                group.serviceType,
                serviceUserTypeEnabled ? userType : undefined,
              )
              .then(() => {
                setAlertProps({
                  title: t("pages.serviceManager.servicePromoted.success.unpromoteDelete"),
                  type: AlertTypeEnum.SUCCESS,
                });
                showAlert();
                ServiceManagerActions.creators.fetchingServicesAction();
                ServiceManagerApi.methods.getServices().then(res => {
                  dispatch(ServiceManagerActions.creators.fetchServicesSuccessAction(res.data));
                  setIsLoading(false);
                });
              });
          } else {
            unpromoteServiceCall(item.id, group.serviceType, toPosition, setIsLoading);
          }
        } else {
          setAlertProps({
            title: t("pages.serviceManager.servicePromoted.errors.unpromoteService"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        }
      }
    });
  };

  /** function to unpromoteServiceCall */
  const unpromoteServiceCall = (
    identifier: string,
    serviceType: ServiceType,
    toPosition: number,
    setIsLoading: (value: boolean) => void,
  ) => {
    setIsLoading(true);
    ServiceManagerApi.methods
      .unpromoteService(identifier, groupName!, serviceType, toPosition, serviceUserTypeEnabled ? userType : undefined)
      .then(
        () => {
          setAlertProps({
            title: t("pages.serviceManager.serviceListItem.success.updateService"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          ServiceManagerActions.creators.fetchingServicesAction();
          ServiceManagerApi.methods.getServices().then(res => {
            dispatch(ServiceManagerActions.creators.fetchServicesSuccessAction(res.data));
            setIsLoading(false);
          });
        },
        () => {
          setIsLoading(false);
          setAlertProps({
            title: t("pages.serviceManager.serviceListItem.errors.updateGroup"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
  };

  return orderedMiniApps.length > 0 ? (
    <Container>
      <DragDropContext onDragEnd={result => onDragEnd(result)}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <ItensContainer {...provided.droppableProps} ref={provided.innerRef}>
              {orderedMiniApps.map((service, index) => (
                <Draggable key={service.id} draggableId={service.id} index={index}>
                  {(providedDrag, snapshotDrag) => (
                    <div ref={providedDrag.innerRef} {...providedDrag.draggableProps} {...providedDrag.dragHandleProps}>
                      <ServiceListItem
                        key={service.id}
                        item={service}
                        dragprops={snapshotDrag}
                        groupName={groupName}
                        serviceType={serviceType}
                        promoteCall={promoteService}
                        unpromoteCall={unpromoteService}
                        promotedOption={promotedOptions}
                        userType={userType}
                        isMiniAppsWithinCategory={isMiniAppsWithinCategory}
                        setShouldUpdateLocalCategories={setShouldUpdateLocalCategories}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ItensContainer>
          )}
        </Droppable>
      </DragDropContext>
      {(loadingCategories || loadingOrdering) && (
        <LoadingContainer>
          <div>
            <LoadingComponent />
          </div>
        </LoadingContainer>
      )}
    </Container>
  ) : (
    <EmptyState>
      <Document />
      <SelectText>{t("pages.serviceManager.serviceListItem.errors.noServices")}</SelectText>
    </EmptyState>
  );
};

export default VerticalListComponent;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin-bottom: 50px;
`;

const EmptyState = styled("div")`
  margin-top: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const SelectText = styled("div")`
  font-family: "Vodafone Rg";
  width: 400px;
  height: 51px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  margin-top: 35px;
`;

const ItensContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const LoadingContainer = styled("div")`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff00;
  z-index: 1;
`;
