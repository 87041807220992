import styled from "styled-components";

export const PageContent = styled("div")`
  margin-top: 78px;
  margin-left: 130px;
  margin-right: 130px;
`;

export const StepSection = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 28px;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
`;

export const ProgressBarInfo = styled("div")<{ step: number }>`
  display: flex;
  justify-content: center;
  > div {
    width: 296px;

    > div > div {
      &:nth-child(-n + ${props => props.step - 1}) {
        background-color: ${props => props.theme.palette.vodafoneRed};
      }
    }

    > div:nth-child(2) {
      background: ${props => props.theme.palette.greyDarkest};
      opacity: 0.5;
    }
    > div:last-child {
      > div > div > div {
        background-color: ${props => props.theme.palette.vodafoneRed};
      }
    }
  }
`;

export const ButtonSection = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`;

export const ButtonInfo = styled("div")`
  min-width: max-content;
`;
export const DetailTitle = styled("div")`
  font-family: Vodafone RgBd;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #333333;
  margin-bottom: 0.5vh;
`;
export const DetailSteps = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #afafaf;
`;

export const MainContent = styled("div")`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-top: 4vh;
  > div > div > div > span {
    :after {
      color: #ff2a58;
    }
  }
`;

export const HomeMainContent = styled("div")`
  display: flex;
  width: 100%;
  margin-top: 4vh;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 32px;
  }
`;

export const AnnouncementsMainContent = styled("div")`
  display: flex;
  width: 100%;
  margin-top: 2vh;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 32px;
  }
`;

export const SpanForm = styled.span<{ disabled?: boolean }>`
  margin-left: 0.5vw;
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #333;
  opacity: ${props => (props.disabled ? 0.5 : "")};
`;

export const AudienceSelection = styled.div`
  margin-top: 1.5vh;
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #999;
`;

export const CallToActionSelection = styled.div`
  margin-top: 1.5vh;
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #999;
`;

export const TextButton = styled("label")`
  display: flex;
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.darkGrey};
  cursor: pointer;
  align-items: center;
  &:hover {
    font-weight: bold;
  }
  > input {
    display: none;
  }
`;

export const CalendarSection = styled("div")`
  > div {
    margin: 1vh auto;
  }
`;

export const DropdownLabelContainer = styled("div")`
  display: flex;
  align-items: center;
  margin-left: -12px;
  > span {
    margin-left: 0.5vw;
  }
`;
export const IconLabelContainer = styled("div")`
  background-color: #ebebeb;
  width: 37px;
  height: 34px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  opacity: 0.5;
  svg {
    width: 20px;
    height: 22px;
    stroke: #333;
    margin-top: 17%;
  }
`;

export const ColorContainer = styled("div")<{ backgroundColor: string }>`
  background-color: ${props => props.backgroundColor};
  width: 37px;
  height: 34px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
`;
export const InformativeText = styled("span")`
  margin-top: 8px;
  width: 500px;
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${props => props.theme.palette.midGrey};
`;
export const LabelForm = styled.span<{ disabled?: boolean }>`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  opacity: ${props => (props.disabled ? 0.5 : "")};
  color: ${props => props.theme.palette.midGrey};
`;

export const DescriptionText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
`;
export const DescriptionTextContainer = styled("div")`
  position: relative;
  padding-bottom: 15px;
`;
export const IconDropDownContainer = styled("div")<{
  logo: string;
}>`
  background-color: #fff;
  background-image: ${props => (props.logo ? `url("${props.logo}")` : "none")};
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: center;
  width: 37px;
  height: 34px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border-right: 1px solid #ebebeb;
`;
