export enum ClientDocSubmissionStatusEnum {
  UNKNOWN = "Unknown",
  PENDING = "10", // System approving
  PENDING_MANUAL_REVIEW = "11",
  ERROR_ON_CRITICAL_FIELDS = "20",
  ERROR_ON_ID_VS_SELFIE = "21",
  ERROR_ON_ID_DUPLICATIONS = "22",
  ERROR_ON_FACE_DUPLICATIONS = "23",
  ACCEPTED = "30",
  REJECTED = "40",
  ERROR_ON_G2 = "50",
  NOT_VALIDATED = "1",
  PENDING_G2_VALIDATION = "60",
}

export enum ClientDocSubmissionNextStatusEnum {
  MOVE_ON = "MOVE_ON",
  REJECT_CRITICAL_FIELDS = "REJECTED",
  REJECT_ID_VS_SELFIE = "REJECTED",
  REJECT_FACE_DUPLICATIONS = "REJECTED",
  REJECT_ID_DUPLICATIONS = "REJECTED",
  REJECT_ON_G2 = "REJECTED",
  REJECT_ON_MANUAL_REVIEW = "REJECTED",
  ERROR = "REJECTED",
}

export enum ClientDocBulkStatusEnum {
  UNKNOWN = "Unknown",
  PENDING_SUBMISSION = "23",
  SUBMITTED_ON_G2 = "26",
  COMPLETE = "60",
}

export enum G2StatusEnum {
  LITE_ACCOUNT = "LITE_ACCOUNT",
  PREMIUM_ACCOUNT = "PREMIUM_ACCOUNT",
  NOT_REGISTERED = "NOT_REGISTERED",
  UNKNOWN = "UNKNOWN",
}

export type ClientDocSubmissionNextStatusEnumKeys = keyof typeof ClientDocSubmissionNextStatusEnum;

export type ClientDocSubmissionStatusEnumKeys = keyof typeof ClientDocSubmissionStatusEnum;

export type ClientDocBulkStatusEnumKeys = keyof typeof ClientDocBulkStatusEnum;
