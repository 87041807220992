import { IIcon } from "../../../shared/components/icon-picker.component";
import {
  IDynamicScreenCategory,
  ITranslatableObject,
} from "../../configurations/dynamic-screens/dynamic-screens.model";

export enum GeneralCategoriesTabNumber {
  SERVICE_LIST = 0,
  EXTERNAL_SERVICES = 1,
  CATEGORIES = 2,
}

export class GeneralCategory implements IDynamicScreenCategory {
  id!: string;
  name!: ITranslatableObject;
  externalCategoryIds!: string[];
}

export interface IIconCategory extends IIcon {
  format?: string;
  hash?: string;
  hashMatches?: boolean;
  imageType?: string;
}
