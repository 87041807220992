import { useAlert, useFilters } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IStoreInterface } from "../../configs/store.config";
import { LoadingText, PageContainer, PageTitle } from "../../shared/shared.styled";
import AuditingApi from "./auditing.api";
import { IAuditingLogsRequest, OperationEnum, SectionsEnum } from "./auditing.model";
import { AuditingLogsActions } from "./auditing.store";
import AuditingDetails from "./components/auditing-details.component";
import AuditingLogsFilters, { AuditingLogsFilterKeys } from "./components/auditing-filters.component";
import AuditingTable from "./components/auditing-table.component";

const AuditingPage = () => {
  const PAGE_SIZE = 20;
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const firstUpdate = React.useRef(true);
  const [showAlert, , setAlertProps] = useAlert();
  const [isLoading, setIsLoading] = React.useState(false);
  const [drawerIsOpen, setDrawerIsOpen] = React.useState(false);
  const [selectedAuditingLogId, setSelectedAuditingLogId] = React.useState<any>();
  const [page, setPage] = React.useState(1);
  const [requestParams, setRequestParams] = React.useState<IAuditingLogsRequest>({
    page: page,
    pageSize: PAGE_SIZE,
  });
  const [requestParamsTemp, setRequestParamsTemp] = React.useState<IAuditingLogsRequest>({
    page: page,
    pageSize: PAGE_SIZE,
  });
  const auditingLogs = useSelector((state: IStoreInterface) => state.auditingLogsReducer.auditingLogs);
  const auditingLogsCount = useSelector((state: IStoreInterface) => state.auditingLogsReducer.auditingLogsCount);
  const {
    filters,
    updateFilter,
    isFilterActive,
    clearFilter,
    resetFilters,
    getFilterValue,
    getFiltersQueryString,
    updateMultipleFilters,
  } = useFilters();

  /**
   * Request params changed effect
   */
  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    getAuditLogs();
  }, [requestParams]);

  /**
   * Page changed effect
   */
  React.useEffect(() => {
    setRequestParams({ ...requestParamsTemp, page: page });
    setRequestParamsTemp({ ...requestParamsTemp, page: page });
  }, [page]);

  /**
   * On filters update, sets the temporary filters object
   */
  React.useEffect(() => {
    let request: IAuditingLogsRequest = { page, pageSize: PAGE_SIZE };
    request = {
      ...request,
      ...getDateFilter(),
      ...getSearchFilter(),
      ...getUpdateTypeFilter(),
      ...getSectionFilter(),
      ...getUserRoleFilter(),
    };
    setRequestParamsTemp(request);
  }, [filters]);

  /**
   * Returns the search filter value
   * @returns {{"[AuditingLogsFilterKeys.SEARCH]": string} | null}
   */
  const getSearchFilter = () => {
    if (filters.get(AuditingLogsFilterKeys.SEARCH)) {
      return { [AuditingLogsFilterKeys.SEARCH]: (filters.get(AuditingLogsFilterKeys.SEARCH) as string[])[0] };
    }
    return null;
  };

  /**
   *
   * @returns {null | {operation: any}}
   */
  const getUpdateTypeFilter = () => {
    if (filters.get(AuditingLogsFilterKeys.UPDATE_TYPE)) {
      const type = (filters.get(AuditingLogsFilterKeys.UPDATE_TYPE) as string[])[0] as OperationEnum;
      return { typeOfUpdate: OperationEnum[type] };
    }
    return null;
  };

  /**
   *
   * @returns {null | {entity: any}}
   */
  const getSectionFilter = () => {
    if (filters.get(AuditingLogsFilterKeys.ENTITY)) {
      const type = (filters.get(AuditingLogsFilterKeys.ENTITY) as string[])[0] as SectionsEnum;
      return { entity: SectionsEnum[type] };
    }
    return null;
  };

  /**
   *
   * @returns {null | {userRole: any}}
   */
  const getUserRoleFilter = () => {
    if (filters.get(AuditingLogsFilterKeys.USER_ROLE)) {
      const userRole = (filters.get(AuditingLogsFilterKeys.USER_ROLE) as string[])[0];
      return { userRole: userRole };
    }
    return null;
  };

  /**
   * Returns the date filter values
   * @returns null || {{endDate: any, startDate: any}}
   */
  const getDateFilter = () => {
    if (filters.get(AuditingLogsFilterKeys.START_DATE)) {
      let startDate,
        endDate = null;
      startDate = moment((filters.get(AuditingLogsFilterKeys.START_DATE) as string[])[0])
        .utcOffset(0)
        .hours(0)
        .minutes(0)
        .seconds(0);
      endDate = moment((filters.get(AuditingLogsFilterKeys.END_DATE) as string[])[0])
        .utcOffset(0)
        .hours(0)
        .minutes(0)
        .seconds(0)
        .add(1, "d");
      return { startDate: startDate.valueOf(), endDate: endDate.valueOf() };
    }
    return null;
  };

  /**
   * Get audit logs
   */
  const getAuditLogs = () => {
    setIsLoading(true);
    AuditingApi.methods
      .getAuditing(requestParamsTemp)
      .finally(() => setIsLoading(false))
      .then(
        res => {
          const { auditList, count } = res.data;
          dispatch(AuditingLogsActions.creators.getAuditingLogsSuccess(auditList, count));
        },
        () => {
          setAlertProps({
            title: t("pages.auditing.getAuditingError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
  };

  /**
   * Pagination navigation
   */
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  /**
   * Refreshes the data set
   */
  const refreshResults = () => {
    setPage(1);
    setRequestParams({ ...requestParamsTemp, page: 1 });
  };

  /**
   * Row clicked handler to open the side drawer with the auditing log details
   * @param {string} auditingLogId
   */
  const rowClickedHandler = (auditingLogId: string) => {
    setDrawerIsOpen(true);
    setSelectedAuditingLogId(auditingLogId);
  };

  return (
    <PageContainer>
      <PageTitle style={{ marginBottom: 43 }}>{t("pages.auditing.title")}</PageTitle>

      <AuditingLogsFilters
        filters={filters}
        isFilterActive={isFilterActive}
        updateFilter={updateFilter}
        clearFilter={clearFilter}
        resetFilters={resetFilters}
        getFilterValue={getFilterValue}
        getFiltersQueryString={getFiltersQueryString}
        updateMultipleFilters={updateMultipleFilters}
        count={auditingLogsCount}
        refreshResults={refreshResults}
      />
      {isLoading ? (
        <LoadingText>{t("commons.loadingResults")}</LoadingText>
      ) : (
        <>
          <AuditingTable
            auditingLogs={auditingLogs}
            pageSize={PAGE_SIZE}
            page={page}
            count={auditingLogsCount}
            handlePageChange={handlePageChange}
            rowClicked={rowClickedHandler}
          />
          {drawerIsOpen && selectedAuditingLogId ? (
            <>
              <AuditingDetails auditingLogId={selectedAuditingLogId} hideDrawer={() => setDrawerIsOpen(false)} />
            </>
          ) : null}
        </>
      )}
    </PageContainer>
  );
};

export default AuditingPage;
