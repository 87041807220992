import { Reducer } from "react";

interface ISetNotificationsListAction {
  type: string;
  payload: any[];
}

export const NotificationsActions = {
  types: {
    FETCH_SFC_NOTIFICATIONS_SUCCESS: "FETCH_SFC_NOTIFICATIONS_SUCCESS",
  },
  creators: {
    fetchNotificationsSuccess: (notificationsList: any[]): ISetNotificationsListAction => {
      return {
        type: NotificationsActions.types.FETCH_SFC_NOTIFICATIONS_SUCCESS,
        payload: [...notificationsList],
      };
    },
  },
};

export interface ISFCPushNotificationsReducerInterface {
  notificationsList: any[];
}

const initialState: ISFCPushNotificationsReducerInterface = {
  notificationsList: [],
};

/**
 * Notifications reducer
 */
export const sfcPushNotificationsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case NotificationsActions.types.FETCH_SFC_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationsList: action.payload,
      };

    default:
      return { ...state };
  }
};
