import {
  AutoClosingDropdown,
  CheckIcon,
  EditIcon,
  EmailIcon,
  FormSection,
  ModalTypeEnum,
  ProfileIcon,
  QrCodeIcon,
  SecondaryPageTitle,
  SecurityIcon,
  TextInput,
  TrashIcon,
  UndoIcon,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import { Formik, FormikErrors, FormikHelpers } from "formik";

import i18next from "i18next";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import store, { IStoreInterface } from "../../../../configs/store.config";
import { RoutesEnum } from "../../../../routes/routes.constants";
import SafaricomDeleteModal from "../../../../shared/components/safaricom-delete-modal/safaricom-delete-modal.component";
import { IUser } from "../../../../shared/models/user.model";
import { PageContent } from "../../../../shared/responsive-ui.styled";
import { DateFormatEnum } from "../../../../shared/shared.enums";
import { LoadingText, PageContainer } from "../../../../shared/shared.styled";
import { getRoles, getStatusColor, isSFCMarket, renderDate } from "../../../../shared/shared.utils";
import AuthenticationApi from "../../../authentication/authentication.api";
import { LoginErrorCodes } from "../../../authentication/authentication.utils";
import UsersApi from "../users.api";
import "../users.css";
import { AuthenticationTypeEnum, IUpdateUserRequest, UserRolesDefault, UserRolesVDF } from "../users.interfaces";
import { UsersActions } from "../users.store";
import { object, string } from "yup";
import { useUsersValidationRules } from "../hooks/use-users-validation-rules.hook";

/**
 * User profile page
 */
const UserProfilePage = () => {
  const { t } = useTranslation(["private", "market_values"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const user = useSelector((store: IStoreInterface) => store.usersReducer.selectedUser as IUser);

  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );
  const [showRemoveUserModalSFC, hideRemoveUserModalSFC] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <SafaricomDeleteModal
      secondaryAction={() => hideRemoveUserModalSFC()}
      primaryAction={() => removeUserRequest(hideRemoveUserModalSFC)}
      title={t("pages.userProfile.links.removeOperator")}
      description={t("pages.userProfile.modals.removeUserConfirmation", {
        user: user && user.fullName,
      })}
      primaryBtnLabel={t("pages.userProfile.modals.removeUserBtn")}
    />,
  );
  const [showDisableUserModalSFC, hideDisableUserModalSFC] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <SafaricomDeleteModal
      secondaryAction={() => hideDisableUserModalSFC()}
      primaryAction={() => disableUserRequest(hideDisableUserModalSFC)}
      title={t("pages.users.popperActions.disableUser")}
      description={t("pages.users.disableUserConfirmation", {
        user: user && user.fullName,
      })}
      primaryBtnLabel={t("pages.users.disableUserBtn")}
    />,
  );

  const [isEditing, setIsEditing] = React.useState(false);
  const [isSendingEmail, setIsSendingEmail] = React.useState(false);

  const loggedUser = store.getState().authenticationReducer.loggedUser;

  const { fullName: FULL_NAME_VALIDATION_RULES } = useUsersValidationRules();

  /**
   * Since we no longer pass the user id by query parameter (due to a security issue concern),
   * if the user reloads the page we will lose the redux store and, therefore, the selected user pointer;
   * To avoid breaking the UI, the user will be redirected to the admin page again to be able to select a user again
   */
  useEffect(() => {
    if (user === null) {
      history.push(RoutesEnum.ADMIN);
    }
  });

  /**
   * Remove user
   * */
  const removeUser = () => {
    if (isSFCMarket()) {
      showRemoveUserModalSFC();
    } else {
      setConfirmationProps({
        title: t("pages.userProfile.links.removeOperator"),
        description: t("pages.userProfile.modals.removeUserConfirmation", {
          user: user.fullName,
        }),
        primaryBtnLabel: t("pages.userProfile.modals.primaryBtnLabel"),
        secondaryBtnLabel: t("pages.userProfile.modals.secondaryBtnLabel"),
        primaryBtnId: "confirm-button",
        secondaryBtnId: "cancel-button",
        primaryAction: () => removeUserRequest(hideConfirmationModal),
        secondaryAction: () => hideConfirmationModal(),
      } as BaseModalProps);
      showConfirmationModal();
    }
  };

  /**
   * Remove user API request
   * */
  const removeUserRequest = (hideModal: () => void) => {
    UsersApi.methods
      .deleteUser(user.username)
      .then(
        () => {
          setAlertProps({
            title: t("pages.userProfile.alerts.removeUserSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          history.push(RoutesEnum.ADMIN);
        },
        () => {
          setAlertProps({
            title: t("pages.userProfile.alerts.removeUserError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(hideModal);
  };

  /**
   * Disable user request
   * */
  const disableUser = () => {
    if (isSFCMarket()) {
      showDisableUserModalSFC();
    } else {
      setConfirmationProps({
        title: t("pages.users.popperActions.disableUser"),
        description: t("pages.users.disableUserConfirmation", {
          user: user.fullName,
        }),
        primaryBtnLabel: t("pages.userProfile.modals.primaryBtnLabel"),
        secondaryBtnLabel: t("pages.userProfile.modals.secondaryBtnLabel"),
        primaryBtnId: "confirm-button",
        secondaryBtnId: "cancel-button",
        primaryAction: () => disableUserRequest(hideConfirmationModal),
        secondaryAction: () => hideConfirmationModal(),
      } as BaseModalProps);
      showConfirmationModal();
    }
  };

  /**
   * Disable user request
   * */
  const disableUserRequest = (hideModal: () => void) => {
    UsersApi.methods
      .disableUser(user.username)
      .then(
        () => {
          setAlertProps({
            title: t("pages.users.disableUserSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          dispatch(UsersActions.creators.disableUserAction(user.username));
          dispatch(UsersActions.creators.selectUser(user.username));
        },
        () => {
          setAlertProps({
            title: t("pages.users.disableUserError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(hideModal);
  };

  /**
   * Enable user request
   * */
  const enableUser = () => {
    setConfirmationProps({
      title: t("pages.users.popperActions.enableUser"),
      description: t("pages.users.enableUserConfirmation", {
        user: user.fullName,
      }),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      primaryBtnLabel: t("pages.userProfile.modals.primaryBtnLabel"),
      secondaryBtnLabel: t("pages.userProfile.modals.secondaryBtnLabel"),
      primaryAction: () => {
        UsersApi.methods
          .enableUser(user.username)
          .then(
            () => {
              setAlertProps({
                title: t("pages.users.enableUserSuccess"),
                type: AlertTypeEnum.SUCCESS,
              });
              showAlert();
              dispatch(UsersActions.creators.enableUserAction(user.username));
              dispatch(UsersActions.creators.selectUser(user.username));
            },
            () => {
              setAlertProps({
                title: t("pages.users.enableUserError"),
                type: AlertTypeEnum.ERROR,
              });
              showAlert();
            },
          )
          .finally(hideConfirmationModal);
      },
      secondaryAction: () => hideConfirmationModal(),
    } as BaseModalProps);
    showConfirmationModal();
  };

  /**
   * Resends the registration email for a given user
   * */
  const resendRegEmail = () => {
    setIsSendingEmail(true);
    UsersApi.methods
      .resendRegEmail(user.username)
      .then(
        () => {
          setAlertProps({
            title: t("pages.users.resendRegistrationEmailSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
        },
        () => {
          setAlertProps({
            title: t("pages.users.resendRegistrationEmailError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        setIsSendingEmail(false);
      });
  };

  /**
   * Shows a modal confirming if the user wants to resend the confirmation email
   * */
  const confirmResendRegEmail = () => {
    setConfirmationProps({
      title: t("pages.users.resendRegistrationEmailConfirmationTitle"),
      primaryBtnLabel: t("pages.userProfile.modals.primaryBtnLabel"),
      secondaryBtnLabel: t("pages.userProfile.modals.secondaryBtnLabel"),
      description: t("pages.users.resendRegistrationEmailConfirmationMessage", {
        user: user.fullName,
      }),
      primaryAction: () => {
        resendRegEmail();
        hideConfirmationModal();
      },
      secondaryAction: () => hideConfirmationModal(),
    } as BaseModalProps);
    showConfirmationModal();
  };

  /**
   * Reset 2FA
   * */
  const reset2FA = () => {
    setConfirmationProps({
      title: t("pages.userProfile.links.reset2FA"),
      description: t("pages.userProfile.modals.reset2FAConfirmation"),
      primaryBtnLabel: t("pages.userProfile.modals.primaryBtnLabel"),
      secondaryBtnLabel: t("pages.userProfile.modals.secondaryBtnLabel"),
      primaryAction: () => {
        AuthenticationApi.methods.reset2FA(user.username).then(
          () => {
            setAlertProps({
              title: t("pages.users.reset2FASuccess"),
              type: AlertTypeEnum.SUCCESS,
            });
            showAlert();
            hideConfirmationModal();
          },
          () => {
            setAlertProps({
              title: t("pages.users.reset2FAError"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          },
        );
      },
      secondaryAction: () => hideConfirmationModal(),
    } as BaseModalProps);
    showConfirmationModal();
  };

  /**
   * Reset password
   * */
  const resetPassword = () => {
    setConfirmationProps({
      title: t("pages.userProfile.links.resetPassword"),
      description: t("pages.userProfile.modals.resetPasswordConfirmation", {
        user: user.fullName,
      }),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      primaryBtnLabel: t("pages.userProfile.modals.primaryBtnLabel"),
      secondaryBtnLabel: t("pages.userProfile.modals.secondaryBtnLabel"),
      primaryAction: () => {
        UsersApi.methods.resendRegEmail(user.username).then(
          () => {
            setAlertProps({
              title: t("pages.users.resetPasswordSuccess"),
              type: AlertTypeEnum.SUCCESS,
            });
            showAlert();
            hideConfirmationModal();
          },
          e => {
            console.log(e);
            if (e.data.status.businessCode === LoginErrorCodes.INACTIVE_USER) {
              setAlertProps({
                title: t("pages.users.resetPasswordInactiveError"),
                type: AlertTypeEnum.ERROR,
              });
              showAlert();
            } else {
              setAlertProps({
                title: t("pages.users.resetPasswordError"),
                type: AlertTypeEnum.ERROR,
              });
              showAlert();
            }
          },
        );
      },
      secondaryAction: () => hideConfirmationModal(),
    } as BaseModalProps);
    showConfirmationModal();
  };

  /**
   * Edit user request
   * @param values
   * @param actions
   */
  const editUser = (values: IUpdateUserRequest, actions: FormikHelpers<any>) => {
    setConfirmationProps({
      title: t("pages.userProfile.links.saveChanges"),
      description: t("pages.userProfile.modals.confirmEdit", {
        user: user.fullName,
      }),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      primaryBtnLabel: t("pages.userProfile.modals.primaryBtnLabel"),
      secondaryBtnLabel: t("pages.userProfile.modals.secondaryBtnLabel"),
      primaryAction: () => {
        actions.setSubmitting(true);
        UsersApi.methods
          .updateUser(user.username, values)
          .finally(() => actions.setSubmitting(false))
          .then(
            () => {
              setAlertProps({
                title: t("pages.userProfile.alerts.editSuccess"),
                type: AlertTypeEnum.SUCCESS,
              });
              showAlert();
              setIsEditing(false);
              dispatch(UsersActions.creators.editUserAction(user.username, values));
              dispatch(UsersActions.creators.selectUser(user.username));
            },
            () => {
              setAlertProps({
                title: t("pages.userProfile.alerts.editError"),
                type: AlertTypeEnum.ERROR,
              });
              showAlert();
            },
          )
          .finally(() => {
            actions.setSubmitting(false);
            hideConfirmationModal();
          });
      },
      secondaryAction: () => hideConfirmationModal(),
    } as BaseModalProps);
    showConfirmationModal();
  };

  /**
   * method to return profile page form rows
   */
  const getProfileFormRows = (
    values: { fullName: string; role: string },
    handleChange: { (e: React.ChangeEvent<HTMLElement>): void },
    setFieldValue: (field: string, value: { fullName: string; role: string }) => void,
    errors: FormikErrors<IUpdateUserRequest>,
  ) => {
    const rows = [
      {
        label: <ProfileLabel id="name">{t("pages.userProfile.formSection.nameLabel")}</ProfileLabel>,
        displayComponent: (
          <TextOverflowWrapper>
            <NonEditableText id="name-value">{user.fullName}</NonEditableText>
          </TextOverflowWrapper>
        ),
        editingComponent: (
          <TextInput
            name="fullName"
            id="name-value"
            value={values.fullName}
            onChange={handleChange}
            error={errors.fullName}
            placeholder={t("pages.userProfile.formSection.namePlaceholder")}
          />
        ),
      },
      {
        label: <ProfileLabel id="username">{t("pages.userProfile.formSection.usernameLabel")}</ProfileLabel>,
        displayComponent: (
          <TextOverflowWrapper>
            <NonEditableText id="username-value">{user.username}</NonEditableText>
          </TextOverflowWrapper>
        ),
        editingComponent: (
          <TextOverflowWrapper>
            <NonEditableText id="username-value">{user.username}</NonEditableText>
          </TextOverflowWrapper>
        ),
      },
      {
        label: <ProfileLabel id="last-login">{t("pages.userProfile.formSection.lastLoginLabel")}</ProfileLabel>,
        displayComponent: user.lastLoginTs ? (
          <TextOverflowWrapper>
            <NonEditableText id="last-login-value">
              {renderDate({ date: moment(user.lastLoginTs), printHour: false, dateFormat: DateFormatEnum.DD })}
            </NonEditableText>
          </TextOverflowWrapper>
        ) : (
          <TextOverflowWrapper>
            <NonEditableText id="last-login-value">
              {t("pages.userProfile.formSection.withoutLastLogin")}
            </NonEditableText>
          </TextOverflowWrapper>
        ),
        editingComponent: user.lastLoginTs ? (
          <TextOverflowWrapper>
            <NonEditableText id="last-login-value">
              {renderDate({ date: moment(user.lastLoginTs), printHour: false, dateFormat: DateFormatEnum.DD })}
            </NonEditableText>
          </TextOverflowWrapper>
        ) : (
          <TextOverflowWrapper>
            <NonEditableText id="last-login-value">
              {t("pages.userProfile.formSection.withoutLastLogin")}
            </NonEditableText>
          </TextOverflowWrapper>
        ),
      },
      {
        label: <ProfileLabel id="creation-date">{t("pages.userProfile.formSection.creationDate")}</ProfileLabel>,
        displayComponent: (
          <TextOverflowWrapper>
            <NonEditableText id="creation-date-value">
              {renderDate({ date: moment(user.registerCreationTime), printHour: false, dateFormat: DateFormatEnum.DD })}
            </NonEditableText>
          </TextOverflowWrapper>
        ),
        editingComponent: (
          <TextOverflowWrapper>
            <NonEditableText id="creation-date-value">
              {renderDate({ date: moment(user.registerCreationTime), printHour: false, dateFormat: DateFormatEnum.DD })}
            </NonEditableText>
          </TextOverflowWrapper>
        ),
      },
      {
        label: <ProfileLabel id="role">{t("pages.userProfile.formSection.roleLabel")}</ProfileLabel>,
        displayComponent: (
          <TextOverflowWrapper>
            <NonEditableText id="role-value">{t(`userRoles.${user.role}`)}</NonEditableText>
          </TextOverflowWrapper>
        ),
        editingComponent:
          user.username !== loggedUser.username ? (
            <div id={"role-dropdown"}>
              <AutoClosingDropdown
                options={(getRoles(t) as {
                  key: UserRolesDefault | UserRolesVDF;
                  label: string;
                }[]).map(role => {
                  return {
                    label: <span id={role.key}>{role.label}</span>,
                    key: role.key,
                  } as {
                    key: UserRolesDefault | UserRolesVDF;
                    label: any;
                  };
                })}
                dropdownType={DropdownType.RECTANGULAR_NORMAL}
                selectOption={opt => setFieldValue("role", opt.key)}
                label={user.role ? t(`userRoles.${values.role}`) : t("pages.addUser.formSection.dropdownPlaceholder")}
                hasValue={!!values.role}
              />
            </div>
          ) : (
            <TextOverflowWrapper>
              <NonEditableText id="role-not-editable">{t(`userRoles.${values.role}`)}</NonEditableText>
            </TextOverflowWrapper>
          ),
      },
    ];
    if (isSFCMarket()) {
      rows.push({
        label: (
          <ProfileLabel id="authentication-type">{t("pages.userProfile.formSection.authenticationLabel")}</ProfileLabel>
        ),
        displayComponent: (
          <TextOverflowWrapper>
            <NonEditableText id="authentication-type-value">
              {user.authenticationType
                ? t(`pages.userProfile.formSection.authenticationType.${user.authenticationType.toLowerCase()}`)
                : t("pages.userProfile.formSection.authenticationType.local")}
            </NonEditableText>
          </TextOverflowWrapper>
        ),
        editingComponent: (
          <TextOverflowWrapper>
            <NonEditableText id="authentication-type-value">
              {user.authenticationType
                ? t(`pages.userProfile.formSection.authenticationType.${user.authenticationType.toLowerCase()}`)
                : t("pages.userProfile.formSection.authenticationType.local")}
            </NonEditableText>
          </TextOverflowWrapper>
        ),
      });
    }
    rows.push({
      label: <ProfileLabel id="status">{t("pages.userProfile.formSection.statusLabel")}</ProfileLabel>,
      displayComponent: (
        <UserStatus id="status-value" color={getStatusColor(user.status)}>
          {i18next.t(`commons.userStatus.${user.status}`)}
        </UserStatus>
      ),
      editingComponent: (
        <UserStatus id="status-value" color={getStatusColor(user.status)}>
          {i18next.t(`commons.userStatus.${user.status}`)}
        </UserStatus>
      ),
    });
    return rows;
  };

  const validationSchema = object({
    fullName: string()
      .matches(
        FULL_NAME_VALIDATION_RULES.REGEX,
        t("validationRules.fullName", {
          ns: "market_values",
          minChars: FULL_NAME_VALIDATION_RULES.MIN,
          maxChars: FULL_NAME_VALIDATION_RULES.MAX,
        }),
      )
      .required("Required"),
  });

  return (
    <PageContainer>
      {user !== null ? (
        <>
          <SecondaryPageTitle goBackFn={() => window.history.back()} title={user.fullName} />
          <PageContent>
            <Formik
              initialValues={{
                fullName: user.fullName,
                role: user.role,
              }}
              onSubmit={editUser}
              validationSchema={validationSchema}
              validateOnChange={false}
              render={({ values, handleChange, setFieldValue, handleSubmit, resetForm, errors }) => (
                <>
                  <MainContent className="main-content">
                    <form onSubmit={handleSubmit}>
                      <FormSection
                        isEditing={isEditing}
                        title={t("pages.userProfile.formSection.title")}
                        rows={getProfileFormRows(values, handleChange, setFieldValue, errors)}
                      />
                    </form>
                  </MainContent>
                  <SideMenu className="side-menu">
                    {!isEditing ? (
                      <LinkContainer
                        id="edit-operator-profile"
                        onClick={() => {
                          setIsEditing(!isEditing);
                        }}
                      >
                        <IconContainer color={styleTheme.palette.turquoiseBlue}>
                          <EditIcon />
                        </IconContainer>
                        <span>{t("pages.userProfile.links.editOperatorProfile")}</span>
                      </LinkContainer>
                    ) : (
                      <>
                        <LinkContainer id="save-changes-button" onClick={() => handleSubmit()}>
                          <IconContainer color={styleTheme.palette.successGreen}>
                            <CheckIcon />
                          </IconContainer>
                          <span>{t("pages.userProfile.links.saveChanges")}</span>
                        </LinkContainer>

                        <LinkContainer
                          id="cancel-changes-button"
                          onClick={() => {
                            resetForm();
                            setIsEditing(false);
                          }}
                        >
                          <IconContainer color={styleTheme.palette.vodafoneRed}>
                            <UndoIcon />
                          </IconContainer>
                          <span>{t("pages.userProfile.links.cancelChanges")}</span>
                        </LinkContainer>
                      </>
                    )}

                    {user.status === 2 ? (
                      <LinkContainer
                        id="resend-registration-email-button"
                        onClick={confirmResendRegEmail}
                        disabled={isEditing}
                        className={isSendingEmail ? "disabled" : ""}
                      >
                        <IconContainer color={styleTheme.palette.successGreen}>
                          <EmailIcon />
                        </IconContainer>
                        <span>{t("pages.userProfile.links.resendRegistrationEmail")}</span>
                      </LinkContainer>
                    ) : null}

                    {(user.status === 1 || user.status === 0) &&
                    user.authenticationType !== AuthenticationTypeEnum.LDAP ? (
                      <LinkContainer id="reset-password-button" onClick={resetPassword} disabled={isEditing}>
                        <IconContainer color={styleTheme.palette.successGreen}>
                          <SecurityIcon />
                        </IconContainer>
                        <span>{t("pages.userProfile.links.resetPassword")}</span>
                      </LinkContainer>
                    ) : null}

                    {user.has2FA && (user.status === 1 || user.status === 0) ? (
                      <LinkContainer id="reset-2fa-button" onClick={reset2FA} disabled={isEditing}>
                        <IconContainer color={styleTheme.palette.lemonYellow}>
                          <QrCodeIcon />
                        </IconContainer>
                        <span>{t("pages.userProfile.links.reset2FA")}</span>
                      </LinkContainer>
                    ) : null}

                    {user.status === 1 ? (
                      <LinkContainer id="disable-user-button" onClick={disableUser} disabled={isEditing}>
                        <IconContainer color={styleTheme.palette.midGrey}>
                          <ProfileIcon />
                        </IconContainer>
                        <span>{t("pages.userProfile.links.disableUser")}</span>
                      </LinkContainer>
                    ) : null}

                    {user.status === 0 ? (
                      <LinkContainer id="enable-user-button" onClick={enableUser} disabled={isEditing}>
                        <IconContainer color={styleTheme.palette.midGrey}>
                          <ProfileIcon />
                        </IconContainer>
                        <span>{t("pages.userProfile.links.enableUser")}</span>
                      </LinkContainer>
                    ) : null}

                    <LinkContainer id="remove-operator-button" onClick={removeUser} disabled={isEditing}>
                      <IconContainer color={styleTheme.palette.vodafoneRed}>
                        <TrashIcon />
                      </IconContainer>
                      <span>{t("pages.userProfile.links.removeOperator")}</span>
                    </LinkContainer>
                  </SideMenu>
                </>
              )}
            />
          </PageContent>
        </>
      ) : (
        <LoadingText>{t("commons.loadingResults")}</LoadingText>
      )}
    </PageContainer>
  );
};

export default UserProfilePage;

const MainContent = styled("div")`
  display: flex;
  flex-direction: column;
  width: 60%;
`;

const SideMenu = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 55px;
  width: 40%;
  margin-left: 75px;
`;

const LinkContainer = styled("div")<{ disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-family: Vodafone Rg;
  color: ${styleTheme.palette.darkGrey};
  font-size: 16px;
  margin-bottom: 15px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? "none" : "inherit")};

  > div {
    margin-right: 8px;
  }
`;

const IconContainer = styled("div")<{ color: string }>`
  width: 20px;
  height: 20px;
  > svg {
    stroke: ${props => props.color};
  }
`;

const UserStatus = styled("div")<{ color: any }>`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.color};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
`;

const NonEditableText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${styleTheme.palette.darkGrey};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProfileLabel = styled("span")`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: #999999;
`;

const TextOverflowWrapper = styled("div")`
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Test = styled("div")`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 128px;
  background-color: #fafafa;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 278px;
  z-index: 1;
`;

const TestSpan = styled("span")`
  font-family: Vodafone Lt;
  font-size: 38px;
  color: #333333;
  margin-left: 0px;
`;
