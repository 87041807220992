import { produce } from "immer";
import { IPayloadAction } from "../../../shared/shared.interfaces";
import { IPushNotifications } from "./push-notifications.model";

export const PushNotificationsActions = {
  types: {
    GET_PUSH_NOTIFICATIONS_SUCCESS: "GET_PUSH_NOTIFICATIONS_SUCCESS",
  },
  creators: {
    getPushNotificationsSuccess: (pushNotifications: IPushNotifications) => ({
      type: PushNotificationsActions.types.GET_PUSH_NOTIFICATIONS_SUCCESS,
      payload: {
        pushNotifications,
      },
    }),
  },
};
export interface IPushNotificationsReducerInterface {
  pushNotifications: IPushNotifications;
}

const initialState: IPushNotificationsReducerInterface = {
  pushNotifications: {} as IPushNotifications,
};

export const pushNotificationsReducer = produce(
  (draft: IPushNotificationsReducerInterface, action: IPayloadAction<any>) => {
    switch (action.type) {
      case PushNotificationsActions.types.GET_PUSH_NOTIFICATIONS_SUCCESS:
        draft.pushNotifications = action.payload.pushNotifications;
        return;
      default:
        return draft;
    }
  },
  initialState,
);
