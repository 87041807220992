import { IServiceManagerService, ServiceManagerStatusEnum } from "../../shared/models/ant-service-manager.model";
import { ServiceManagerFiltersEnum } from "./components/ant-service-manager-filters.component";
import { object, string } from "yup";
import i18next from "i18next";

/**
 * Filters an array of Services using a ServiceStatusEnum[]
 * @param services ServiceManagerService[]
 * @param status ServiceManagerStatusEnum[]
 */
export const filterServiceManagerServicesByStatus = (
  services: IServiceManagerService[],
  status: ServiceManagerStatusEnum[],
): IServiceManagerService[] => {
  if (!!status && !!status.length) {
    const newStatus = [...status];
    if (status.includes(ServiceManagerStatusEnum.DISABLED)) {
      newStatus.push(ServiceManagerStatusEnum.UNDEPLOYED);
    }
    return services.filter(service => newStatus.includes(service.status));
  }
  return services;
};

/**
 * Filter an array of Services using a search string
 * @param services ServiceManagerService[]
 * @param searchString string
 */
export const filterServiceManagerServiceByString = (services: IServiceManagerService[], searchString: string) => {
  if (!!searchString) {
    return services.filter(
      service => service.name && (service.name as string).toLowerCase().includes(searchString.toLowerCase()),
    );
  }
  return services;
};

/**
 * Filter an array of Services using a search string, an array of ServiceStatusEnum
 * @param services ServiceManagerService[]
 * @param filters ServiceManagerFilters
 */
export const filterServiceManagerServices = (
  services: IServiceManagerService[],
  filters: Map<string, string[]>,
): IServiceManagerService[] => {
  let newServices = services;
  const status = filters.get(ServiceManagerFiltersEnum.STATUS);
  const search = filters.get(ServiceManagerFiltersEnum.SEARCH);

  if (status) {
    newServices = filterServiceManagerServicesByStatus(newServices, status as ServiceManagerStatusEnum[]);
  }
  if (search) {
    newServices = filterServiceManagerServiceByString(newServices, search[0]);
  }

  return newServices;
};

/** validation schema */
export const getServiceDetailsValidationSchema = () => {
  return object().shape({
    name: string().required(i18next.t("pages.serviceManager.serviceDetails.validateSchema.name")),
  });
};
