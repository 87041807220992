import { EditIcon, FastActionButton, TrashIcon } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { string } from "yup";
import { RoutesEnum } from "../../../../routes/routes.constants";
import { IIcon } from "../../../../shared/components/icon-picker.component";
import { fadeInKeyframe, IconContainer, RatioContainer, Row, WidthContainer } from "../../../../shared/shared.styled";
import { getDefaultTranslation } from "../../../../shared/shared.utils";
import { ServiceListHighlighted } from "../../../service-builder/components/service-list-item.component";
import { IDynamicScreen } from "../dynamic-screens.model";
import DynamicScreenStatusChip from "./dynamic-screen-status-chip.component";

interface IDynamicScreenListItemProps {
  item: IDynamicScreen;
  deleteDynamicScreen: (item: IDynamicScreen) => void;
  editDynamicScreen: (item: IDynamicScreen) => void;
  icons: IIcon[];
}

const DynamicScreenListItem = ({
  item,
  deleteDynamicScreen,
  editDynamicScreen,
  icons,
}: IDynamicScreenListItemProps) => {
  const [t] = useTranslation();
  const history = useHistory();

  /**
   * Returns a base64 of the selected icon
   * @param {string} iconId
   * @returns {any}
   */
  const getIconBase64 = (iconId: string) => {
    const ic = icons.find(icon => icon.id === iconId);
    return ic ? ic.base64 : "";
  };

  return (
    <>
      <DynamicScreenListItemContainer
        onClick={() => {
          history.push(RoutesEnum.DYNAMIC_SCREEN.replace(":id", item.id));
        }}
        id={`dynamic-screen-${
          item.name.translations.en ? item.name.translations.en : Object.keys(item.name.translations)[0]
        }`}
        hoverAction={true}
      >
        <ItemTitle ratio={3 / 12}>
          {icons && icons.length > 0 && item.icon && item.color && (
            <IconPreview backgroundColor={item.color}>
              <img src={getIconBase64(item.icon)} />
            </IconPreview>
          )}
          {item.name.translations.en ? item.name.translations.en : Object.keys(item.name.translations)[0]}
        </ItemTitle>
        <ItemNormalText ratio={2 / 12}>
          {item.description.translations.en
            ? item.description.translations.en
            : Object.keys(item.description.translations)[0]}
        </ItemNormalText>
        <ItemNormalText ratio={2 / 12} style={{ color: styleTheme.palette.redViolet }}>
          {item.categories && item.categories.length ? (
            <>
              {item.categories.map((c, i) => {
                return c.name
                  ? getDefaultTranslation(c.name.translations) + (i < item.categories.length - 1 ? ", " : "")
                  : "";
              })}
            </>
          ) : null}
        </ItemNormalText>
        <ItemNormalText className="status-container" ratio={2 / 12} style={{ color: styleTheme.palette.midGrey }}>
          {t(`pages.dynamicScreens.sectionsEnum.${item.section}`)}
        </ItemNormalText>
        <ItemNormalText
          className="status-container"
          ratio={2 / 12}
          style={{ color: styleTheme.palette.turquoiseBlue, fontWeight: "bold" }}
        >
          {item.eventId}
        </ItemNormalText>
        <WidthContainer className="toggable-container" ratio={1 / 12}>
          <Row>
            <>
              <FastActionButton
                iconComponent={
                  <IconContainer
                    size={16}
                    color={styleTheme.palette.turquoiseBlue}
                    id={`edit-button-${
                      item.name.translations.en ? item.name.translations.en : Object.keys(item.name.translations)[0]
                    }`}
                  >
                    <EditIcon />
                  </IconContainer>
                }
                onClick={e => {
                  e.stopPropagation();
                  editDynamicScreen(item);
                }}
                label={t("pages.dynamicScreens.edit")}
              />
              <FastActionButton
                onClick={e => {
                  e.stopPropagation();
                  deleteDynamicScreen(item);
                }}
                label={t("pages.dynamicScreens.delete")}
                iconComponent={
                  <div
                    id={`delete-button-${
                      item.name.translations.en ? item.name.translations.en : Object.keys(item.name.translations)[0]
                    }`}
                    style={{
                      width: 16,
                      height: 16,
                      stroke: styleTheme.palette.vodafoneRed,
                    }}
                  >
                    <TrashIcon />
                  </div>
                }
              />
            </>
          </Row>
        </WidthContainer>
        <WidthContainer className="status-container" style={{ textAlign: "left", alignItems: "center" }} ratio={1 / 12}>
          <DynamicScreenStatusChip style={{ width: "fit-content" }} isActive={item.isActive}>
            {t(`pages.dynamicScreens.statusEnum.${item.isActive ? "live" : "disabled"}`)}
          </DynamicScreenStatusChip>

          <ServiceListHighlighted>{item.highlighted ? String.fromCodePoint(9733) : null}</ServiceListHighlighted>
        </WidthContainer>
      </DynamicScreenListItemContainer>
    </>
  );
};

export default DynamicScreenListItem;

const DynamicScreenListItemContainer = styled("li")<{ hoverAction: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 75px;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};

  margin-bottom: 24px;
  padding: 0 24px 0 24px;

  .status-container {
    display: flex;
    justify-content: flex-start;
  }

  .toggable-container {
    display: none;
  }

  :hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    .status-container {
      display: ${props => (props.hoverAction ? "none" : "flex")};
    }
    .toggable-container {
      display: ${props => (!props.hoverAction ? "none" : "flex")};
      margin-left: auto;
      justify-content: flex-end;
      animation: ${fadeInKeyframe} 0.25s linear;
      button:first-child {
        margin-right: 12px;
      }
    }
  }
`;

const ItemTitle = styled(WidthContainer)`
  font-family: Vodafone Rg;
  font-size: 22px;
  font-weight: bold;
  color: ${props => props.theme.palette.darkGrey};
  display: flex;
  align-items: center;
  overflow: hidden;
  word-break: break-word;
  padding-right: 8px;
  @media (max-width: 1024px) {
    font-size: 20px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
    padding-right: 4px;
  }
`;

const ItemNormalText = styled(WidthContainer)`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
  padding-right: 4px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const IconPreview = styled("div")<{
  backgroundColor: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.backgroundColor ? `${props.backgroundColor}` : "#ffffff")};
  width: 48px;
  height: 48px;
  border: 1px solid ${props => props.theme.palette.aluminium};
  border-radius: 50%;
  margin-right: 24px;
  flex-shrink: 0;

  img {
    width: 32px;
    height: 32px;
    filter: brightness(500%);
    -webkit-filter: brightness(500%);
  }
  @media (max-width: 1024px) {
    margin-right: 12px;
  }
  @media (max-width: 768px) {
    margin-right: 8px;
    width: 32px;
    height: 32px;
    img {
      width: 24px;
      height: 24px;
    }
  }
`;
