import produce from "immer";
import { IPayloadAction } from "../../../shared/shared.interfaces";
import { AuthenticationActions } from "../../authentication/authentication.store";
import { IDynamicScreenCategory } from "../dynamic-screens/dynamic-screens.model";
import { IIconCategory } from "./general-categories.model";

export const GeneralCategoriesActions = {
  types: {
    FETCH_GENERAL_CATEGORIES: "FETCH_GENERAL_CATEGORIES",
    SET_GENERAL_CATEGORY: "SET_GENERAL_CATEGORY",
    FETCHING_ICONS_CATEGORIES: "FETCHING_ICONS_CATEGORIES",
  },
  creators: {
    fetchCategories: (categories: IDynamicScreenCategory[]) => ({
      type: GeneralCategoriesActions.types.FETCH_GENERAL_CATEGORIES,
      payload: {
        categories,
      },
    }),
    setCategory: (category: IDynamicScreenCategory) => ({
      type: GeneralCategoriesActions.types.SET_GENERAL_CATEGORY,
      payload: {
        category,
      },
    }),
    fectionsIconsCategories: (icons: IIconCategory[]) => ({
      type: GeneralCategoriesActions.types.FETCHING_ICONS_CATEGORIES,
      payload: {
        icons,
      },
    }),
  },
};

export interface IGeneralCategoriesReducerInterface {
  categories: IDynamicScreenCategory[];
  category: IDynamicScreenCategory;
  icons: IIconCategory[];
}

const initialState: IGeneralCategoriesReducerInterface = {
  categories: [] as IDynamicScreenCategory[],
  category: {} as IDynamicScreenCategory,
  icons: [] as IIconCategory[],
};

export const generalCategoriesReducer = produce(
  (draft: IGeneralCategoriesReducerInterface, action: IPayloadAction<any>) => {
    switch (action.type) {
      case GeneralCategoriesActions.types.FETCH_GENERAL_CATEGORIES:
        draft.categories = action.payload.categories;
        return;
      case GeneralCategoriesActions.types.SET_GENERAL_CATEGORY:
        draft.category = action.payload.category;
        return;
      case AuthenticationActions.types.LOGOUT:
        return initialState;
      case GeneralCategoriesActions.types.FETCHING_ICONS_CATEGORIES:
        draft.icons = action.payload.icons;
        return;
      default:
        return draft;
    }
  },
  initialState,
);
