import React from "react";

function ClockIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1099 -359)">
          <path fill="#FFF" d="M0 0H1360V768H0z"></path>
          <g transform="translate(118 347)">
            <path d="M0 0H1160V44H0z"></path>
            <g stroke="#00C3FF" strokeLinecap="round" strokeLinejoin="round" transform="translate(981 12)">
              <path d="M12 21.5a9.5 9.5 0 100-19 9.5 9.5 0 000 19zm6.5-9.5H12V5.5"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ClockIcon;
