import { ArrowDownIcon, SearchBar, TabChild, Table, Tabs } from "@wit/mpesa-ui-components";
import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { SafaricomTheme } from "../../../safaricom.theme";
import { IconContainer } from "../../../shared/shared.styled";
import { useTranslation } from "react-i18next";
import { ColumnProps } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import { AudienceType, ETargetAudienceStatus, ITargetAudienceDrawerInterface } from "../announcements.model";
import { AnnouncementsUtils } from "../announcements.utils";

interface ITargetAudienceProps {
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isVisible: Boolean;
  validTargets: string[];
  invalidTargets: string[];
  allTotalTargets: string[];
  audience: AudienceType;
  audienceSelected: AudienceType | undefined;
  refConsumer: MutableRefObject<null>;
  refBusiness: MutableRefObject<null>;
}

/**
 * Drawer component to target audience
 */
const TargetAudienceDrawer = ({
  setIsVisible,
  isVisible,
  validTargets,
  invalidTargets,
  allTotalTargets,
  audience,
  audienceSelected,
  refConsumer,
  refBusiness,
}: ITargetAudienceProps) => {
  const [t] = useTranslation();
  let valid: ITargetAudienceDrawerInterface[] = validTargets.map(value => {
    return { target: value, status: ETargetAudienceStatus.VALID };
  });

  let invalid: ITargetAudienceDrawerInterface[] = invalidTargets.map(value => {
    return { target: value, status: ETargetAudienceStatus.INVALID };
  });

  let totalTargets: ITargetAudienceDrawerInterface[] =
    allTotalTargets.length > 0
      ? allTotalTargets.map(value => {
          return {
            target: value,
            status: validTargets.includes(value) ? ETargetAudienceStatus.VALID : ETargetAudienceStatus.INVALID,
          };
        })
      : valid;
  const [allTargets, setAllTargets] = useState<ITargetAudienceDrawerInterface[]>(totalTargets);
  const [tab, setTab] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [total, setTotal] = useState<number>(allTargets.length);
  const [label, setLabel] = useState<string>(t("pages.announcements.detailPage.targetsDrawer.label.total"));
  const ref = useRef<HTMLDivElement>(null);
  const refForInput = useRef(null);

  /**
   * function than will return one array with the information about the columns
   */
  const getTargetsColumns = (): ColumnProps[] => {
    return [
      {
        formKey: "target",
        label: AnnouncementsUtils.isConsumerAudience(audience)
          ? t("pages.announcements.detailPage.targetsDrawer.columns.msisdn")
          : t("pages.announcements.detailPage.targetsDrawer.columns.shortcode"),
        ratio: 8 / 12,
        isEditable: false,
        changeFunction: (content, row) => {
          return content;
        },
      },
      {
        formKey: "status",
        label: t("pages.announcements.detailPage.targetsDrawer.columns.status"),
        ratio: 4 / 12,
        sortable: true,
        isEditable: false,
        changeFunction: (content, row) => {
          return (
            <span
              style={{
                color:
                  content === ETargetAudienceStatus.VALID
                    ? SafaricomTheme.palette.vodafoneRed
                    : SafaricomTheme.palette.errorRed,
              }}
            >
              {t(`pages.announcements.detailPage.targetsDrawer.status.${content}`)}
            </span>
          );
        },
      },
    ];
  };

  /** function to onchange tab */
  const onTabChange = (tab: number) => {
    setTab(tab);
    let targets: ITargetAudienceDrawerInterface[] = [];
    switch (tab) {
      case 0:
        targets =
          searchString.length > 0
            ? totalTargets.filter(t => t.target.toLowerCase().includes(searchString.toLowerCase()))
            : totalTargets;
        setLabel(t("pages.announcements.detailPage.targetsDrawer.label.total"));
        break;
      case 1:
        targets =
          searchString.length > 0
            ? invalid.filter(t => t.target.toLowerCase().includes(searchString.toLowerCase()))
            : invalid;
        setLabel(t("pages.announcements.detailPage.targetsDrawer.label.invalid"));
        break;
      case 2:
        targets =
          searchString.length > 0
            ? valid.filter(t => t.target.toLowerCase().includes(searchString.toLowerCase()))
            : valid;
        setLabel(t("pages.announcements.detailPage.targetsDrawer.label.valid"));
        break;
    }
    setAllTargets(targets);
    setTotal(targets.length);
  };

  useEffect(() => {
    let targets = [...(tab === 0 ? totalTargets : tab === 1 ? invalid : valid)];
    if (searchString.length > 0) {
      targets = targets.filter(t => t.target.toLowerCase().includes(searchString.toLowerCase()));
    } else {
      targets = tab === 0 ? totalTargets : tab === 1 ? invalid : valid;
    }
    setAllTargets(targets);
    setTotal(targets.length);
  }, [setAllTargets, setTotal, searchString, tab]);

  useEffect(() => {
    valid = validTargets.map(value => {
      return { target: value, status: ETargetAudienceStatus.VALID };
    });

    invalid = invalidTargets.map(value => {
      return { target: value, status: ETargetAudienceStatus.INVALID };
    });

    totalTargets =
      allTotalTargets.length > 0
        ? allTotalTargets.map(value => {
            return {
              target: value,
              status: validTargets.includes(value) ? ETargetAudienceStatus.VALID : ETargetAudienceStatus.INVALID,
            };
          })
        : valid;

    setAllTargets(tab === 0 ? totalTargets : tab === 1 ? invalid : valid);
    setTotal(tab === 0 ? totalTargets.length : tab === 1 ? invalid.length : valid.length);
    setSearchString("");
  }, [validTargets, invalidTargets, allTotalTargets]);

  const clickListener = useCallback(
    (e: MouseEvent) => {
      if (audienceSelected !== undefined) {
        if ((ref.current! as HTMLDivElement).contains(e.target as Node)) {
          return;
        }
        if (AnnouncementsUtils.isBusinessAudience(audienceSelected)) {
          if ((refBusiness.current! as HTMLDivElement).contains(e.target as Node)) {
            return;
          }
        }
        if (AnnouncementsUtils.isConsumerAudience(audienceSelected)) {
          if ((refConsumer.current! as HTMLDivElement).contains(e.target as Node)) {
            return;
          }
        }
        setIsVisible(false);
      }
    },
    [ref.current, refBusiness.current, refConsumer.current, audienceSelected],
  );

  useEffect(() => {
    document.addEventListener("click", clickListener);
    return () => {
      document.removeEventListener("click", clickListener);
    };
  }, []);

  return (
    <>
      <DrawerContainer isVisible={isVisible} ref={ref}>
        <Header>
          <div
            onClick={() => {
              setIsVisible(false);
            }}
          >
            <IconBack size={20} color={SafaricomTheme.palette.vodafoneRed}>
              <ArrowDownIcon />
            </IconBack>
          </div>
          <ModalTitle>{t("pages.announcements.detailPage.targetsDrawer.title")}</ModalTitle>
        </Header>
        <Body>
          <SearchFilter ref={refForInput}>
            <SearchBar
              placeholderLabel={
                AnnouncementsUtils.isConsumerAudience(audience)
                  ? t("pages.announcements.filters.searchMsisdns")
                  : t("pages.announcements.filters.searchShortcodes")
              }
              value={searchString}
              onChange={e => setSearchString(e.target.value)}
              clearValue={() => {
                process.nextTick(() => {
                  setSearchString("");
                });
              }}
            />
          </SearchFilter>
          <Content>
            <Tabs controlledSelectedTab={tab} controlledSetSelectedTab={onTabChange} isSecondaryTabs={true}>
              <TabChild label={t("pages.announcements.detailPage.targetsDrawer.tabs.total")}>
                <></>
              </TabChild>
              <TabChild label={t("pages.announcements.detailPage.targetsDrawer.tabs.invalid")}>
                <></>
              </TabChild>
              <TabChild label={t("pages.announcements.detailPage.targetsDrawer.tabs.valid")}>
                <></>
              </TabChild>
            </Tabs>
            <DivTotalEntries>
              <TotalOfEntries>{total}</TotalOfEntries>
              <LabelOfEntries>{label}</LabelOfEntries>
            </DivTotalEntries>
            <Table<ITargetAudienceDrawerInterface> columns={getTargetsColumns()} values={allTargets} />
          </Content>
        </Body>
      </DrawerContainer>
    </>
  );
};

export default TargetAudienceDrawer;

const slideIn = keyframes`
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }

  `;

const slideOut = keyframes`
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(100%);
    }
  `;

const slideInAnimation = css`
  animation: ${slideIn} 0.25s ease-out forwards;
`;

const slideOutAnimation = css`
  animation: ${slideOut} 0.25s ease-out forwards;
`;

const DrawerContainer = styled.div<{ isVisible: Boolean }>`
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  width: 718px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  height: 100%;
  padding: 67px 75px;
  left: calc(100% - 711px);
  ${props => (props.isVisible ? slideInAnimation : slideOutAnimation)};
`;

const ModalTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 30px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 21px;
  margin-left: 16px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const IconBack = styled(IconContainer)`
  display: flex;
  width: 16px;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 65px;
  svg {
    transform: rotate(90deg);
  }
  cursor: pointer;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
`;

const SearchFilter = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3vh;
  width: 100%;
  height: 75vh;
  > div:last-child {
    overflow-y: scroll;
    overflow-x: hidden;

    > div:first-child {
      width: 80%
      position: fixed;
      background-color: white;
      z-index: 1;
    }

    > div:nth-child(2) {
      margin-top: 42px;
    }

    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${props => props.theme.palette.white};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${props => props.theme.palette.silver};
      border-radius: 2.5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${props => props.theme.palette.midGrey};
      border-radius: 2.5px;
    }
  }
`;

const DivTotalEntries = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  color: ${props => props.theme.palette.midGrey};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const TotalOfEntries = styled.span`
  font-family: Vodafone RgBd;
`;

const LabelOfEntries = styled.span`
  margin-left: 5px;
  font-family: Vodafone Rg;
`;
