import React from "react";
import { RecurringIcon, DownloadIcon, SmallButton } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IExportAndRefreshComponentProps } from "../feedback-and-problems.model";
import {
  isFeedbackTabActive,
  feedbacksStringPath,
  problemsStringPath,
  customerStringPath,
  isProblemReportsTabActive,
} from "../feedback-and-problems.utils";

const ExportAndRefreshComponent = ({
  resultSetLength,
  sectionId,
  onClickRefresh,
  onClickExport,
}: IExportAndRefreshComponentProps) => {
  const [t] = useTranslation();

  return (
    <ExportAndRefreshContainer width={resultSetLength === 0 ? "120px" : "280px"}>
      <SmallButton
        id={"refresh-results"}
        titleLabel={
          isFeedbackTabActive(sectionId)
            ? t(`${feedbacksStringPath}.filtersArea.refresh`)
            : isProblemReportsTabActive(sectionId)
            ? t(`${problemsStringPath}.filtersArea.refresh`)
            : t(`${customerStringPath}.filtersArea.refresh`)
        }
        onClick={onClickRefresh}
        iconComponent={
          <SmallButtonIconContainer color={styleTheme.palette.turquoiseBlue}>
            <RecurringIcon />
          </SmallButtonIconContainer>
        }
      />

      {resultSetLength > 0 && (
        <SmallButton
          id={"export-results"}
          disabled={resultSetLength === 0}
          titleLabel={
            isFeedbackTabActive(sectionId)
              ? t(`${feedbacksStringPath}.filtersArea.export`)
              : isProblemReportsTabActive(sectionId)
              ? t(`${problemsStringPath}.filtersArea.export`)
              : t(`${customerStringPath}.filtersArea.export`)
          }
          onClick={onClickExport}
          iconComponent={
            <SmallButtonIconContainer color={styleTheme.palette.vodafoneRed}>
              <DownloadIcon />
            </SmallButtonIconContainer>
          }
        />
      )}
    </ExportAndRefreshContainer>
  );
};

export default ExportAndRefreshComponent;

const ExportAndRefreshContainer = styled("div")<{ width: string }>`
  width: ${props => props.width};
  gap: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const SmallButtonIconContainer = styled("div")<{ color: string }>`
  width: 16px;
  height: 16px;
  stroke: ${props => props.color};

  svg {
    width: 16px;
    height: 16px;
    stroke: ${props => props.color};
  }
`;
