import { EditIcon, FastActionButton, TrashIcon } from "@wit/mpesa-ui-components";
import { SortDirectionEnum } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import i18next from "i18next";
import moment from "moment";
import React from "react";
import { TFunction } from "react-i18next";
import styled from "styled-components";
import { string } from "yup";
import { IConfig } from "../app.component";
import { UserRolesDefault, UserRolesSFC, UserRolesVDF } from "../features/admin/users/users.interfaces";
import { TrustedRolesEnum } from "../features/safaricom-service-manager/service-details/pages/details.interfaces";
import { IServiceManagerService } from "../shared/models/ant-service-manager.model";
import { AppPlatformEnum, AppTypeEnum, IApp } from "./models/app.model";
import { ServiceStatusSearchEnum, VersionLanguagesEnum, IService } from "./models/service-builder.model";
import {
  ExternalServicePermitionsEnum,
  IMiniAppRole,
  ServiceManagerServiceStatusSearchEnum,
  ServiceManagerServiceTypeSearchEnum,
} from "./models/service-manager.model";
import { AREAS } from "./renderer.utils";
import {
  DateFormatEnum,
  ReportStatusEnum,
  StatusEnum,
  BackOfficeMarketsEnums,
  FieldTypeEnum,
  StatusType,
} from "./shared.enums";
import { IDateRenderer } from "./shared.interfaces";
import { IconContainer } from "./shared.styled";
import { ILanguage } from "./models/language.model";

/**
 * Shared Object with RegEx Expressions to be used
 * within the BackOffice
 */
export const RegExEpressions = {
  especialCharacters: /[!@$%^#&*(),.?"':{}|<>]|(\s\s)/g,
  uri: /^(?!^\s|\s$)\S.*\S$/i,
} as const;

/**
 * verify image url
 * @param url: string
 * @param func: Function
 */
export const verifyImageUrl = async (url: string, func: Function) => {
  const imageSetter = func;
  const loadImageUrl = (uri: string) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = uri;
      img.onload = () => {
        resolve(img);
      };
      img.onerror = error => {
        reject(new Error(`Failed loading image ${uri}`));
      };
    });
  };

  try {
    const image = await loadImageUrl(url);
    imageSetter(url);
  } catch (error) {
    console.error(error);
  }
};

/**
 * Transforms components string ids to the right Translation [t] file string key
 * Example: General-Categories will be generalCategories
 * @param componentId: string
 * @returns string
 */
export const toTranslationStringKey = (componentId: string) => {
  const pre = componentId
    .toLowerCase()
    .split("-")
    .map(string => string.charAt(0).toUpperCase() + string.slice(1))
    .join("");

  return pre.charAt(0).toLowerCase() + pre.slice(1);
};

/**
 * verify if the given val starts with the prefix http
 * @param val: string
 */
export const isHttp = (val: string) => val.startsWith("http");

/**
 * Concatenates given props to the given route (route parameterize)
 * Note: the params keys must be the same as those inside route,
 * e.g.: /{id}/{userName} and param.id and param.userName
 * @param params: { [x: string]: string | number }
 * @param endpoint: string
 * @returns string
 */
export const getParametrizedRoute = <T extends {}>(params: T, route: string): string => {
  let uri = route;
  Object.keys(params).forEach(key => {
    const param = `${params[key as keyof typeof params]}`;
    uri = uri.includes(`{${key}}`) ? uri.replace(`{${key}}`, encodeURIComponent(param)) : uri;
  });
  uri = uri.replace(/\{(.*?)\}/g, "");
  return uri;
};

/**
 * Check if the given system name is available in the market
 * @param apps: IApp[];
 * @param system: string;
 * @returns Boolean
 */
export const isOSDisabled = (appsResponse: IApp[], platform: string): boolean => {
  return !appsResponse.find(app => app.system === platform);
};

/**
 * Check and return true if the market on Config is equal to the Market that
 * it is intended to
 * @param config: IConfig | undefined
 * @param market: BackOfficeMarketsEnums
 * @returns boolean
 */
export const isEnabledOnMarket = (config: IConfig | undefined, market: BackOfficeMarketsEnums): Boolean => {
  return (config as IConfig).market === market;
};

/**
 * Formats UnixTimestamp within an object list (on key x) to a readable date
 * For example, the dateOfBirth on the List --> [{name: "Jane Doe", dateOfBirth: 1610713545000}]
 * @param list: T[]
 * @param key: string
 * @param targetDateFormat: string
 * @returns data: T[]
 */
export const formatUnixTimestampDate = <T extends {}>(list: T[], key: string, targetDateFormat: string) => {
  if (!list.length) {
    return [];
  }

  const data = list.map(item => {
    return {
      ...item,
      [key]: moment(Number(item[key as keyof typeof item])).format(targetDateFormat),
    };
  });
  return [...data];
};

/**
 * Returns a string with all the categories of a certain service mini app/ant mini app
 * @returns string
 */
export const getMiniAppsCategories = (item: IServiceManagerService | IService) => {
  if (item.categories.length > 0) {
    let label = "";
    item.categories.forEach(c => (label += `${c.name.translations["en"]}, `));
    label = label.substring(0, label.length - 2);
    return label;
  }
};

/**
 * A function based in Moment.JS, this function will return the number of days from now...
 * @param date: string
 * @returns number
 */
export const getNumberOfDaysFromNow = (date: string) => {
  return moment().diff(moment(date, "DD-MM-YYYY").startOf("day"), "days");
};

export function onEnterPressed(event: React.KeyboardEvent<HTMLInputElement>, fn: { (): void; (): void }) {
  if (event.keyCode === 13) {
    fn();
  }
}

export const getStatusColor = (status: number) => {
  switch (status) {
    case StatusEnum.DISABLED:
      return styleTheme.palette.vodafoneRed;
    case StatusEnum.ACTIVE:
      return styleTheme.palette.successGreen;
    case StatusEnum.PENDING_ACTIVE:
      return styleTheme.palette.turquoiseBlue;
  }
};

export const getRoles = (t: TFunction) => {
  const config = JSON.parse(localStorage.getItem("config") as string);
  if (isSFCMarket()) {
    return Object.values({ ...UserRolesDefault, ...UserRolesSFC })
      .sort()
      .map(role => ({
        label: t(`userRoles.${role}`),
        key: role,
      }));
  } else {
    if (
      (config.availableAreas as string[]).includes(AREAS.SERVICE_BUILDER) ||
      (config.availableAreas as string[]).includes(AREAS.ANT_SERVICE_MANAGER)
    ) {
      return Object.values({ ...UserRolesDefault, ...UserRolesVDF })
        .sort()
        .map(role => ({
          label: t(`userRoles.${role}`),
          key: role,
        }));
    } else {
      return Object.values(UserRolesDefault)
        .sort()
        .map(role => ({
          label: t(`userRoles.${role}`),
          key: role,
        }));
    }
  }
};

export const getRolesWithClearFilter = (t: TFunction) => {
  const options: { label: string; key: string | null }[] = getRoles(t);
  options.push({
    label: i18next.t(`commons.clearFilter`),
    key: null,
  });
  return options;
};

export const getTrustedRoles = (t: TFunction) => {
  return Object.values(TrustedRolesEnum).map(role => ({
    label: role,
    key: role,
  }));
};

export const getAppTypes = () => {
  const types: {
    label: string;
    key: AppTypeEnum | undefined;
  }[] = Object.values(AppTypeEnum).map(type => ({
    label: i18next.t(`commons.apps.enums.type.${type}`),
    key: type,
  }));
  types.push({
    label: i18next.t(`commons.apps.enums.type.seeAll`),
    key: undefined,
  });
  return types;
};

export const getAppPlatforms = () => {
  const platforms: {
    label: string;
    key: AppPlatformEnum | undefined;
  }[] = Object.values(AppPlatformEnum).map(platform => ({
    label: i18next.t(`commons.apps.enums.platform.${platform}`),
    key: platform,
  }));
  platforms.push({
    label: i18next.t(`commons.apps.enums.platform.seeAll`),
    key: undefined,
  });
  return platforms;
};

export const getServiceStatus = () => {
  return Object.values(ServiceStatusSearchEnum).map(status => ({
    label: i18next.t(`commons.serviceBuilder.enums.status.${status}`),
    key: status,
  }));
};

export const getServiceManagerServiceStatus = () => {
  return Object.values(ServiceManagerServiceStatusSearchEnum).map(status => ({
    label: i18next.t(`commons.serviceBuilder.enums.status.${status}`),
    key: status,
  }));
};

/**
 * Generate a ServiceManagerServiceTypeSearchEnum options list
 */
export const getServiceManagerServiceType = () => {
  return Object.values(ServiceManagerServiceTypeSearchEnum).map(serviceType => ({
    label: i18next.t(`commons.serviceBuilder.enums.servicesType.${serviceType}`),
    key: serviceType,
  }));
};

/**
 * return merchant or roles options
 * @returns
 */
export const getMiniAppRolesOptions = (rawOptions: IMiniAppRole[]) => {
  const options = rawOptions.map(item => ({
    label: item.name,
    key: item.id,
  }));

  return options;
};

export const getServiceManagerStatusColor = (status: string) => {
  if (status === "DISABLED") {
    return styleTheme.palette.vodafoneRed;
  }
  if (status === "LIVE") {
    return styleTheme.palette.successGreen;
  }
};

export const getServiceManagerTrustedLevel = (level: string) => {
  if (level === "UNTRUSTED") {
    return styleTheme.palette.vodafoneRed;
  }
  if (level === "TRUSTED") {
    return styleTheme.palette.successGreen;
  }
};

export const downloadJsonFile = (jsonString: string, fileName: string) => {
  const anchor = document.createElement("a");
  anchor.setAttribute("style", "display: none;");
  document.body.appendChild(anchor);
  const mimeType = "application/json";

  const blob = new Blob([jsonString], { type: `${mimeType}; encoding=utf8` });
  if (window && window.URL && window.URL.createObjectURL) {
    const url = window.URL.createObjectURL(blob);
    anchor.href = url;
    anchor.download = `${fileName}.json`;
    anchor.click();
    window.URL.revokeObjectURL(url);
  }
  (anchor.parentElement as HTMLElement).removeChild(anchor);
};

export const generateUUIDv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Render table buttons
 * @param value
 * @param buttonActions
 * @returns
 */
export const renderTableButtons = <T extends unknown>(
  value: T,
  buttonActions: {
    edit?: { onClick: (row: T) => void; id?: string };
    delete?: { onClick: (row: T) => void; id?: string };
  },
) => {
  const editAction = buttonActions.edit;
  const deleteAction = buttonActions.delete;
  return (
    <TableButtonsContainer>
      {editAction && (
        <span id={editAction.id ? editAction.id : undefined} style={{ marginRight: deleteAction ? "12px" : undefined }}>
          <FastActionButton
            iconComponent={
              <IconContainer size={16} color={styleTheme.palette.turquoiseBlue}>
                <EditIcon />
              </IconContainer>
            }
            onClick={() => editAction.onClick(value)}
            label={i18next.t("pages.support.envProperties.table.buttons.edit")}
          />
        </span>
      )}
      {deleteAction && (
        <span id={deleteAction.id ? deleteAction.id : undefined}>
          <FastActionButton
            iconComponent={
              <IconContainer size={16} color={styleTheme.palette.vodafoneRed}>
                <TrashIcon />
              </IconContainer>
            }
            onClick={() => deleteAction.onClick(value)}
            label={i18next.t("pages.support.envProperties.table.buttons.delete")}
          />
        </span>
      )}
    </TableButtonsContainer>
  );
};

export const formatUTCTimestamp = (timestamp: number | string) => {
  try {
    const date = new Date(timestamp);
    if (isNaN(date.getTime())) {
      return "";
    }
    const dateString = `${date.toLocaleString("en-EN", {
      timeZone: "UTC",
      day: "numeric",
    })} ${date.toLocaleString("en-EN", {
      timeZone: "UTC",
      month: "short",
    })} ${date.toLocaleString("en-EN", {
      timeZone: "UTC",

      year: "numeric",
    })}`;
    return dateString;
  } catch (e) {
    return "";
  }
};

export const areObjSimilar = (a: any, b: any) => {
  // Create arrays of property names
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length !== bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
};

export const TableButtonsContainer = styled("div")`
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    margin-right: 12px;

    :last-of-type {
      margin-right: 0;
    }
  }
`;

export const getVersionLanguages = () => {
  return Object.values(VersionLanguagesEnum).map(status => ({
    label: i18next.t(`commons.serviceBuilder.enums.languages.${status}`),
    key: status,
  }));
};

/*
 * Retrieves the default translation of a backend translatable object
 * */
export const getDefaultTranslation = (obj: { [key: string]: string }) => {
  return obj.en ? obj.en : obj[Object.keys(obj)[0]];
};

export const srcToFile = (src: string, fileName: string, mimeType: string) => {
  return fetch(src)
    .then(function(res) {
      return res.arrayBuffer();
    })
    .then(function(buf) {
      return new File([buf], fileName, { type: mimeType });
    });
};

export const getExternalServicePermissions = () => {
  return Object.values(ExternalServicePermitionsEnum).map(permissions => ({
    label: permissions,
    key: permissions,
  }));
};

export const getReportStatusColor = (status: string) => {
  switch (status) {
    case ReportStatusEnum.ACTION_TAKEN:
      return styleTheme.palette.turquoiseBlue;
    case ReportStatusEnum.PENDING_ANALYSIS:
      return styleTheme.palette.warningYellow;
    case ReportStatusEnum.NO_ACTION_TAKEN:
      return styleTheme.palette.greyDarker;
  }
};

export const getVodafoneColorPalette = () => {
  return [
    "#009900",
    "#a8b400",
    "#eb9700",
    "#fecb00",
    "#007c92",
    "#00b0ca",
    "#5e2750",
    "#9c2aa0",
    "#e60000",
    "#bd0000",
    "#ebebeb",
    "#999999",
    "#cccccc",
    "#ffffff",
  ];
};

const _URL = window.URL || window.webkitURL;

/**
 * Create image
 * @param event
 */
export const createImage = (event: any) => {
  const file =
    (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files[0]) ||
    (event.target.files && event.target.files[0]);
  if (file) {
    const objectUrl = _URL.createObjectURL(file);
    return [objectUrl, file];
  }

  return [undefined, undefined];
};

/**
 *
 * @param {T[]} data
 * @param {keyof T} prop
 * @param {SortDirectionEnum} direction
 * @param {FieldTypeEnum} fieldType
 * @returns {T[]}
 */
export const sortArrayBy = <T,>(
  data: T[],
  prop: keyof T,
  direction: SortDirectionEnum,
  fieldType = FieldTypeEnum.STRING,
) => {
  return data.slice().sort((a, b) => {
    if (
      (fieldType === FieldTypeEnum.STRING && `${a[prop]}`.toLowerCase() === `${b[prop]}`.toLowerCase()) ||
      (fieldType !== FieldTypeEnum.STRING && compareVersions(String(a[prop]), String(b[prop])) === 0)
    ) {
      return 0;
    }
    if (
      (fieldType === FieldTypeEnum.STRING && `${a[prop]}`.toLowerCase() > `${b[prop]}`.toLowerCase()) ||
      (fieldType !== FieldTypeEnum.STRING && compareVersions(String(a[prop]), String(b[prop])) === 1)
    ) {
      return direction === SortDirectionEnum.ASC ? 1 : -1;
    } else {
      return direction === SortDirectionEnum.ASC ? -1 : 1;
    }
  });
};

/**
 * Deep copies an object
 * @param obj
 * @returns {any}
 */
export const deepCopyObject = <T,>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj));
};

/**
 * Promise that converts a file into a base 64 string
 * @param {File | undefined} file
 * @returns {Promise<string>}
 */
export const fileToBase64 = (file: File | undefined): Promise<string> => {
  if (!file) {
    return new Promise<string>((resolve, reject) => {
      resolve("");
    });
  }
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || "");
    reader.onerror = error => reject(error);
  });
};

/**
 * method to compare versions (ex: 1.0.0)
 *
 * This will return:
 * 0: version strings are equal
 * 1: version a is greater than b
 * -1: version b is greater than a
 */
export const compareVersions = (a: string, b: string) => {
  return a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" });
};

/**
 * Retrieves the format of the phone number validation for the current environment
 * @param {IConfig} config
 * @returns {RegExp}
 */
export const getPhoneNumberRegex = (config: IConfig | undefined) => {
  let regex = /^(?:([\d]{3})|\\+([\d]{3})|0|00([\d]{3}))?([\d]{9})$/; //
  try {
    regex =
      config && config.formValidations && config.formValidations.phoneNumberRegex
        ? new RegExp(config.formValidations.phoneNumberRegex)
        : regex;
  } catch (error) {
    console.error("ADD BLACKLIST NUMBER - INVALID REGEX AT CONFIG FILE", error);
  }
  return regex;
};

/**
 * Retrieves the format of the topicname validation for Firebase
 * @returns regular expression object
 */
export const getFirebaseTopicNameRegex = (): RegExp => {
  const regex = /[^a-zA-Z0-9-_.~% ]/;
  return regex;
};

/**
 * method to manipulate the output of the date depending on the language
 * @returns {string} the formatted date
 * @param dateValue
 */
export const renderDate = (dateValue: IDateRenderer): string => {
  if (dateValue) {
    const dateValueClone: IDateRenderer = {
      ...dateValue,
      dateFormat: dateValue.dateFormat || DateFormatEnum.DO,
      printHour: dateValue.printHour ?? true,
    };

    const { date, printHour, dateFormat } = dateValueClone;

    return i18next.language !== "en"
      ? `${date.date()}
        ${i18next.t(`dates.month.${date.month()}`, { ns: "market_values" })}
        ${date.year()}
        ${printHour ? `${date.hour()}:${date.minute()}` : ""}`
      : date.format(`${dateFormat} ${printHour ? "HH:mm" : ""}`);
  }

  return "-";
};

/**
 * method to convert a string to camelCase convention
 * @param {string} stringToBeConverted - the string to be converted
 * @returns {string} - string in camel case
 */
export const convertToCamelCase = (stringToBeConverted: string): string =>
  stringToBeConverted
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase()))
    .replace(/\s+/g, "");

/**
 * Verifies if the BO is for a SFC market or not
 * @returns {boolean}
 */
export const isSFCMarket = () => {
  return (
    (process.env.REACT_APP_MARKET as string).replace(/\s/g, "") === BackOfficeMarketsEnums.KE ||
    (process.env.REACT_APP_MARKET as string).replace(/\s/g, "") === BackOfficeMarketsEnums.ET
  );
};

/**
 * Verifies if the BO is for a SFC market or not
 * @returns {boolean}
 */
export const isKEMarket = () => {
  return (process.env.REACT_APP_MARKET as string).replace(/\s/g, "") === BackOfficeMarketsEnums.KE;
};

/**
 * Remove items from list
 * @param list
 * @param itensToRemove
 * @returns {any[]}
 */
export const removeItemsFromList = (list: any[], itensToRemove: any[]): any[] => {
  return list.filter(function(el) {
    return !itensToRemove.includes(el);
  });
};

/**
 * Remove duplicate items from list
 * @param list
 * @returns {any[]}
 */
export const removeDuplicateItemsFromList = (list: any[]): any[] => {
  return list.filter((value, index) => {
    return (
      index ===
      list.findIndex(obj => {
        return JSON.stringify(obj) === JSON.stringify(value);
      })
    );
  });
};

/**
 * Translations validator helper
 * @param {string[]} langs
 * @returns {{[p: string]: any}}
 */
export const getTranslationValidators = (langs: ILanguage[]) => {
  let validators: { [x: string]: any } = {};
  (langs as ILanguage[]).map((lang: ILanguage) => {
    if (lang.mandatory) {
      validators = {
        ...validators,
        [lang.code]: string()
          .required(i18next.t("commons.mandatoryField"))
          .matches(/^\s*\S+.*/, i18next.t("commons.emptyField")),
      };
    } else {
      validators = {
        ...validators,
        [lang.code]: string().matches(/^\s*\S+.*/, i18next.t("commons.emptyField")),
      };
    }
  });
  return validators;
};

/**
 * Return Color
 * @param status
 */
export const getColorStatus = (status: string) => {
  switch (status) {
    case StatusType.LIVE:
      return "#2fc56d";
    case StatusType.DRAFT:
      return "#eb9700";
    case StatusType.SCHEDULED:
      return "#00c3ff";
    case StatusType.CANCELED:
      return "#ff2a58";
    case StatusType.EXPIRED:
      return "#999";
  }
};

/**
 * Transform a given string to camel case
 * @param str String to be manipulated
 * @returns Camelized word
 */
export const stringToCamelCase = (str: string): string => {
  return str
    .replace(/[^a-z 0-9]/gi, "")
    .split(" ")
    .map((word: string, index: number) => {
      if (index === 0) {
        return word.toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join("");
};
/**
 * This function to update current page url without reloading the page
 * @param url
 * @param paramName
 * @param paramValue
 * @returns
 */
export const updateUrlParam = (url: string, paramName: string, paramValue: string): string => {
  const updatedUrl = new URL(url);
  updatedUrl.searchParams.set(paramName, paramValue);
  return updatedUrl.toString();
};
