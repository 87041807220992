import * as _ from "lodash";

/**
 * Reorder an array
 * @function reorderArray
 * @param {ArrayLike} list
 * @param startIndex
 * @param {number} endIndex
 * @returns {ArrayLike}
 */
export const arrayUtils = {
  reorderArray: <T,>(list: ArrayLike<T>, startIndex: number, endIndex: number): T[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const filteredResult = result.filter(item => item !== undefined);
    return filteredResult;
  },

  /**
   * convert object to key-value string
   * @function objectToKeyValueString
   * @param {object} obj
   * @param {boolean} encodeValue
   * @returns {string}
   */
  objectToKeyValueString: (obj: object, encodeValue: boolean = false): string => {
    if (!obj) {
      return "";
    }
    // eslint-disable-next-line no-const-assign
    const keyValuePair: Array<string> = _.map(obj, (value: string, key: string) => {
      if (encodeValue === true) {
        value = encodeURIComponent(value);
      }
      return `${key}=${value}`;
    });
    return keyValuePair.join("&");
  },

  /**
   * Check if the current index is NOT the index of the last item
   * @param arr Array of ExternalServicePermissions
   * @param index current index
   * @returns boolean
   */
  isNotLastItemInTheList: <T,>(arr: T[], index: number) => arr.length - 1 !== index,
};
