import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Separator, OrbRow } from "../../../shared/shared.styled";
import { StatusChip } from "@wit/mpesa-ui-components";
import { ServiceItemTitle } from "../ant-service-manager.styled";
import { RoutesEnum } from "../../../routes/routes.constants";
import { getMiniAppsCategories } from "../../../shared/shared.utils";
import { IServiceManagerService, ServiceManagerStatusEnum } from "../../../shared/models/ant-service-manager.model";
import { useHistory } from "react-router-dom";

interface IServiceListItemProps {
  item: IServiceManagerService;
}

/**
 * Component Item for ListItem
 * @param param0 iterface
 */
const ServiceManagerListItem = ({ item }: IServiceListItemProps) => {
  const [t] = useTranslation();
  const history = useHistory();
  if (item.status === ServiceManagerStatusEnum.UNDEPLOYED) {
    item = { ...item, status: ServiceManagerStatusEnum.DISABLED };
  }

  return (
    <ServiceGridItemContainer
      onClick={() => {
        history.push(RoutesEnum.ANT_SERVICE_MANAGER_DETAILS.replace(":serviceId", item.id));
      }}
      id={item.name}
    >
      <HorizontalPaddedContainer>
        <ServiceItemImage backgroundImage={item.iconUrl}></ServiceItemImage>
      </HorizontalPaddedContainer>
      <HorizontalPaddedContainer>
        <ServiceItemTitle ratio={1}>{item.name}</ServiceItemTitle>
        {item.description ? (
          <InfoContainer>
            <Identifier>{t("pages.serviceBuilder.item.description")}</Identifier>
            <Value>{item.description}</Value>
          </InfoContainer>
        ) : (
          <InfoContainer></InfoContainer>
        )}
      </HorizontalPaddedContainer>
      <Separator />
      <HorizontalPaddedContainer
        style={{
          padding: "24px",
          maxHeight: "72px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <ChipContainer>
          {item.status !== undefined ? (
            <StatusChip type={item.status as any}>{t(`commons.serviceBuilder.enums.status.${item.status}`)}</StatusChip>
          ) : null}
        </ChipContainer>
        {item.categories && item.categories.length > 0 && (
          <CategoriesText>{getMiniAppsCategories(item)}</CategoriesText>
        )}
      </HorizontalPaddedContainer>
      {item.highlighted ? <Highlighted id={`${item.name}-highlighted-star`}>&#9733;</Highlighted> : null}
    </ServiceGridItemContainer>
  );
};

export default ServiceManagerListItem;

const ServiceItemImage = styled("div")<{ backgroundImage: string }>`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: solid 1px #ebebeb;
  margin-bottom: 10px;
  background-image: url("${props => props.backgroundImage}");
`;
const HorizontalPaddedContainer = styled("div")`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
`;

const ServiceGridItemContainer = styled("div")`
  position: relative;
  max-height: 300px;
  max-width: 415px;
  height: fit-content;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  :hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 6px 6px 0 rgba(0, 0, 0, 0.16);
  }
`;

const InfoContainer = styled("span")`
  margin-top: 6px;
  margin-bottom: 6px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  hyphens: auto;
  min-height: 36px;
  :last-of-type {
    margin-bottom: 24px;
  }
`;

const Identifier = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.black};
  margin-right: 3px;
`;

const Value = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.greyDarker};
`;

const ChipContainer = styled("div")`
  margin-left: 0px;
`;

const Highlighted = styled("span")`
  position: absolute;
  left: 16px;
  top: 16px;
  font-size: 30px;
  line-height: 30px;
  color: ${props => props.theme.palette.freshOrange};
`;

const CategoriesText = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  line-height: 21px;
  color: #9c2aa0;
  margin-left: auto;
  font-weight: bold;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 8px;
`;
