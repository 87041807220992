import { ConfigContext } from "../../app.component";
import { useContext } from "react";

/** */
const useCheckComponentAvailable = (component: React.ReactNode, feature: string): React.ReactNode => {
  const { config } = useContext(ConfigContext);
  if (config) {
    if (config.disabledFeatures !== undefined && config.disabledFeatures.includes(feature)) {
      return null;
    }
  }

  return component;
};

export default useCheckComponentAvailable;
