import { IService, ServiceStatusEnum } from "../../shared/models/service-builder.model";
import { IPayloadAction } from "../../shared/shared.interfaces";
import produce from "immer";
import { AuthenticationActions } from "../authentication/authentication.store";

const sortByStatus = (status: string) => {
  switch (status) {
    case ServiceStatusEnum.LIVE:
      return 1;
    case ServiceStatusEnum.BETA:
      return 2;
    case ServiceStatusEnum.UNDEPLOYED:
      return 3;
    case ServiceStatusEnum.DISABLED:
      return 4;
    default:
      return -1;
  }
};

export const ServicesActions = {
  types: {
    FETCHING_SERVICES: "FETCHING_SERVICES",
    FETCH_SERVICES_SUCCESS: "FETCH_SERVICES_SUCCESS",
    FETCH_SERVICES_ERROR: "FETCH_SERVICES_ERROR",
  },
  creators: {
    fetchingServicesAction: () => ({
      type: ServicesActions.types.FETCHING_SERVICES,
    }),
    fetchServicesSuccessAction: (services: IService[]) => ({
      type: ServicesActions.types.FETCH_SERVICES_SUCCESS,
      payload: {
        services,
      },
    }),
    fetchServicesErrorAction: () => ({
      type: ServicesActions.types.FETCH_SERVICES_ERROR,
    }),
  },
};

export interface IServicesReducerInterface {
  services: IService[];
  isLoadingServices: boolean;
}

const initialState: IServicesReducerInterface = {
  services: [],
  isLoadingServices: false,
};

export const servicesReducer = produce((draft: IServicesReducerInterface, action: IPayloadAction<any>) => {
  switch (action.type) {
    case ServicesActions.types.FETCHING_SERVICES:
      draft.isLoadingServices = true;
      return;
    case ServicesActions.types.FETCH_SERVICES_SUCCESS:
      draft.isLoadingServices = false;
      draft.services = action.payload.services.sort(
        (a: any, b: any) => sortByStatus(a.status) - sortByStatus(b.status),
      );
      return;
    case ServicesActions.types.FETCH_SERVICES_ERROR:
      draft.isLoadingServices = false;
      return;
    case AuthenticationActions.types.LOGOUT:
      return initialState;
    default:
      return draft;
  }
}, initialState);
