import React from "react";
import { ColumnProps } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import i18next from "i18next";
import { IUser } from "../../../shared/models/user.model";
import { getStatusColor } from "../../../shared/shared.utils";
import { MemberIcon } from "@wit/mpesa-ui-components";
import { Row } from "../../../shared/shared.styled";
import styled from "styled-components";

export const AdminUtils = {
  getTableColumns: (): ColumnProps[] => {
    return [
      {
        ratio: 3 / 12,
        label: i18next.t("pages.users.table.username"),
        formKey: "username",
        isEditable: false,
        changeFunction: (content, row) => {
          return (
            <Row>
              <MemberIconContainer>
                <MemberIcon fullName={row.fullName} />
              </MemberIconContainer>
              <span>{content}</span>
            </Row>
          );
        },
      },
      {
        ratio: 3 / 12,
        label: i18next.t("pages.users.table.fullName"),
        formKey: "fullName",
        isEditable: false,
        changeFunction: content => {
          if (!content) {
            return "-";
          }
          return <>{content}</>;
        },
      },
      {
        ratio: 2 / 12,
        label: i18next.t("pages.users.table.status"),
        formKey: "status",
        isEditable: false,
        changeFunction: (content, rowData: IUser) => (
          <span style={{ color: getStatusColor(rowData.status) }}>{i18next.t(`commons.userStatus.${content}`)}</span>
        ),
      },
      {
        ratio: 3 / 12,
        label: i18next.t("pages.users.table.role"),
        formKey: "role",
        isEditable: false,
        changeFunction: content => {
          if (!content) {
            return "-";
          }
          return <>{i18next.t(`userRoles.${content}`)}</>;
        },
      },
    ];
  },
};

export const MemberIconContainer = styled("div")`
  @media (max-width: 768px) {
    display: none;
  }
`;
