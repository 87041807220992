import {
  CancelIcon,
  ChevronRightIcon,
  EditIcon,
  ModalTypeEnum,
  SecondaryPageTitle,
  SmallButton,
  StatusChip,
  TrashIcon,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { RoutesEnum } from "../../../routes/routes.constants";
import { IServiceDetails, ServiceStatusEnum } from "../../../shared/models/service-builder.model";

import { formatUTCTimestamp } from "../../../shared/shared.utils";
import ServiceDetailsApi from "../service-details.api";
import { ServiceDetailsActions } from "../service-details.store";

export interface IServiceDetailsDrawerProps {
  serviceDetails: IServiceDetails;
  toggleDrawer: () => void;
  open: boolean;
  pending: boolean;
  showAdminActions: boolean;
  refreshPage: () => void;
}

/**
 * Service details drawer
 * @param param0
 */
const ServiceDetailsDrawer = ({
  serviceDetails,
  toggleDrawer,
  open,
  pending,
  showAdminActions,
  refreshPage,
}: IServiceDetailsDrawerProps) => {
  // Hooks initialization
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const [showDeleteModal, hideDeleteModal, setDeleteProps] = useModal(ModalTypeEnum.ConfirmationModal);
  const [showDisableModal, hideDisableModal, setDisableProps] = useModal(ModalTypeEnum.ConfirmationModal);
  const activeURLs = serviceDetails.urlWhiteList !== undefined ? serviceDetails.urlWhiteList.length : 0;
  const history = useHistory();
  const selectedLanguage = "en";

  /**
   * get translation
   * @param obj
   */
  const getTranslation = (obj: { [x: string]: any }) => {
    return obj && obj[selectedLanguage]
      ? obj[selectedLanguage]
      : obj && obj[Object.keys(obj)[0]]
      ? obj[Object.keys(obj)[0]]
      : "N/A";
  };

  const deleteServiceWithId = useCallback(() => {
    //methods to delete service

    /**
     * need aprove for delete
     */
    const needApproveForDelete = () => {
      return [ServiceStatusEnum.LIVE, ServiceStatusEnum.BETA].includes(serviceDetails.status as ServiceStatusEnum);
    };

    ServiceDetailsApi.methods
      .deleteService(serviceDetails.id, needApproveForDelete())
      .finally(() => hideDeleteModal())
      .then(
        () => {
          dispatch(ServiceDetailsActions.creators.deleteServiceAction(serviceDetails.id));
          setAlertProps({
            title: needApproveForDelete()
              ? t("pages.serviceBuilder.success.deletingService.title")
              : t("pages.serviceBuilder.success.deletingService.titleNow"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          history.push("/service-builder");
        },
        () => {
          setAlertProps({
            title: t("pages.serviceBuilder.errors.deletingService.title"),
            content: t("pages.serviceBuilder.errors.deletingService.content"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
  }, [dispatch, hideDeleteModal, serviceDetails.id, setAlertProps, showAlert, t]);

  const disableServiceWithId = useCallback(() => {
    //methods to disable service

    /**
     * need approve for disable
     */
    const needApproveForDisable = () => {
      return [ServiceStatusEnum.LIVE, ServiceStatusEnum.BETA].includes(serviceDetails.status as ServiceStatusEnum);
    };
    ServiceDetailsApi.methods
      .disableService(serviceDetails.id, needApproveForDisable())
      .finally(() => hideDisableModal())
      .then(
        () => {
          setAlertProps({
            title: needApproveForDisable()
              ? t("pages.serviceBuilder.success.disablingService.title")
              : t("pages.serviceBuilder.success.disablingService.titleNow"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          refreshPage();
        },
        () => {
          setAlertProps({
            title: t("pages.serviceBuilder.errors.disablingService.title"),
            content: t("pages.serviceBuilder.errors.disablingService.content"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
  }, [hideDisableModal, refreshPage, serviceDetails.id, setAlertProps, showAlert, t]);

  function _showDeleteModal() {
    setDeleteProps({
      setInnerHtml: true,
      title: t("pages.serviceBuilder.modals.deleteService.title"),
      description: `<div style="display: -webkit-box; overflow-wrap: break-word; word-break: break-word; hyphens: auto">${t(
        "pages.serviceBuilder.modals.deleteService.description",
        {
          serviceName: getTranslation(serviceDetails.name),
        },
      )}</div>`,
      primaryAction: deleteServiceWithId,
      primaryBtnLabel: t("pages.serviceBuilder.modals.deleteService.primaryButtonLabel"),
      secondaryAction: hideDeleteModal,
      secondaryBtnLabel: t("pages.serviceBuilder.modals.deleteService.secondaryButtonLabel"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
    });
    showDeleteModal();
  }

  function _showDisableModal() {
    setDisableProps({
      setInnerHtml: true,
      title: t("pages.serviceBuilder.modals.disableService.title"),
      description: `<div style="display: -webkit-box; overflow-wrap: break-word; word-break: break-word; hyphens: auto">${t(
        "pages.serviceBuilder.modals.disableService.description",
        {
          serviceName: getTranslation(serviceDetails.name),
        },
      )}</div>`,
      primaryAction: disableServiceWithId,
      primaryBtnLabel: t("pages.serviceBuilder.modals.disableService.primaryButtonLabel"),
      secondaryAction: hideDisableModal,
      secondaryBtnLabel: t("pages.serviceBuilder.modals.disableService.secondaryButtonLabel"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
    });
    showDisableModal();
  }

  /**
   * Returns a string with all the categories in this service
   * @returns {string}
   */
  const getCategories = () => {
    if (serviceDetails.categories.length > 0) {
      let label = "";
      serviceDetails.categories.forEach(c => (label += `${c.name.translations[selectedLanguage]}, `));
      label = label.substring(0, label.length - 2);
      return label;
    }
  };

  return (
    <ServiceLeftContainer open={open}>
      <SecondaryPageTitle
        goBackFn={() => {
          history.push("/service-builder");
        }}
        height={100}
        title={"Go back"}
        paddingLeft={48}
        displayInitialsCircle={false}
        titleFontSize={18}
        circleMarginLeft={0}
      />
      <ServiceInfo>
        <ServiceMainDetails>
          <AvatarContainer>
            <AvatarDiv backgroundImage={serviceDetails.iconUrl}></AvatarDiv>
          </AvatarContainer>
          <ServiceName>{getTranslation(serviceDetails.name)}</ServiceName>
          {serviceDetails.status !== undefined ? (
            <ServiceStatus>
              <StatusChip type={serviceDetails.status as any}>
                {t(`commons.serviceBuilder.enums.status.${serviceDetails.status}`)}
              </StatusChip>
            </ServiceStatus>
          ) : null}
          <TimeDetail>Creation: {formatUTCTimestamp(serviceDetails.creationDate)}</TimeDetail>
          {serviceDetails.description ? (
            <TimeDetail>Description: {getTranslation(serviceDetails.description)}</TimeDetail>
          ) : null}
          {serviceDetails.highlighted && (
            <HighlightedText>{t(`pages.serviceBuilder.highlightedService.title`)}</HighlightedText>
          )}
          {serviceDetails.categories && serviceDetails.categories.length > 0 && (
            <CategoriesText style={{ marginTop: serviceDetails.highlighted ? "8px" : "13px" }}>
              {t(`pages.serviceBuilder.categories.title`)} <span className="purple">{getCategories()}</span>
            </CategoriesText>
          )}
          {serviceDetails.tags && serviceDetails.tags.length > 0 && (
            <TagsText>
              {t(`pages.serviceBuilder.tags.title`)}:
              <span className="turquoise">{serviceDetails.tags.toString().replace(/[,]/g, ", ")}</span>
            </TagsText>
          )}
        </ServiceMainDetails>
        <Separator />
        <URLInformationWrapper>
          <URLInformationTitle>{t(`pages.serviceBuilder.externalURLCalls.title`)}</URLInformationTitle>
          <IndicatorWrapper>
            <IndicatorElement></IndicatorElement>
            {t(`pages.serviceBuilder.externalURLCalls.description`).replace("{0}", activeURLs.toString())}
          </IndicatorWrapper>
        </URLInformationWrapper>
        <Separator />
        {showAdminActions ? (
          <Actions>
            {!pending ? (
              <>
                <div id="edit-service-button">
                  <SmallButton
                    onClick={() => {
                      history.push(RoutesEnum.SERVICE_DETAILS_EDIT.replace(":serviceId", serviceDetails.id));
                    }}
                    titleLabel={t(`pages.serviceBuilder.updateServiceButton.title`)}
                    iconComponent={<EditIcon />}
                  />
                </div>
                <div id="disable-service-button">
                  <SmallButton
                    onClick={() => {
                      if (serviceDetails.status !== ServiceStatusEnum.UNDEPLOYED) {
                        _showDisableModal();
                      }
                    }}
                    titleLabel={t(`pages.serviceBuilder.disableServiceButton.title`)}
                    iconComponent={<CancelIcon />}
                    disabled={serviceDetails.status !== ServiceStatusEnum.UNDEPLOYED ? false : true}
                  />
                </div>
                <div id="delete-service-button">
                  <SmallButton
                    onClick={() => {
                      _showDeleteModal();
                    }}
                    titleLabel={t(`pages.serviceBuilder.deleteServiceButton.title`)}
                    iconComponent={<TrashIcon />}
                  />
                </div>
              </>
            ) : null}
          </Actions>
        ) : null}
      </ServiceInfo>
      <ToggleButton open={open} onClick={toggleDrawer}>
        <ChevronRightIcon />
      </ToggleButton>
    </ServiceLeftContainer>
  );
};

export default ServiceDetailsDrawer;

const ServiceLeftContainer = styled("div")<{ open: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 320px;
  background-color: #fbfbfb;
  box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.1);
  transform: translateX(-294px);
  transition: transform 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  margin-left: 70px;

  > div:first-of-type {
    position: unset;

    > svg {
      height: 24px;
      width: 24px;
      margin-right: 11px;
    }
  }

  ${props => (props.open ? "transform: translateX(0px);" : null)};
  will-change: transform;
`;

const ServiceInfo = styled("div")`
  padding-left: 48px;
  padding-right: 23px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
`;

const ServiceMainDetails = styled("div")`
  align-items: center;
`;

const AvatarContainer = styled("div")`
  position: relative;
  margin: auto;
  width: 100px;
`;
const AvatarDiv = styled("div")<{ backgroundImage: string }>`
  border-radius: 50%;
  height: 100px;
  border: 1px solid ${props => props.theme.palette.aluminium};
  overflow: hidden;
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const ServiceName = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: ${props => props.theme.palette.darkGrey};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-word;
  hyphens: auto;
  margin-top: 10px;
`;

const ServiceStatus = styled("div")`
  display: flex;
  justify-content: center;
  > div {
    margin-top: 10px;
    margin-bottom: 15px;
  }
`;

const TimeDetail = styled("div")`
  font-family: Vodafone Rg;
  font-size: 14px;
  line-height: 1.36;
  text-align: center;
  color: ${props => props.theme.palette.midGrey};
  margin-bottom: 3px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  word-break: break-word;
  hyphens: auto;
`;

const Separator = styled("div")`
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-image-source: radial-gradient(
    circle at 50% 0,
    ${props => props.theme.palette.aluminium},
    rgba(204, 204, 204, 0)
  );
  border-image-slice: 1;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Actions = styled("div")`
  > div {
    width: fit-content;
    button {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  > div:not(:last-of-type) {
    margin-bottom: 12px;
  }

  > div:first-of-type {
    button {
      svg {
        stroke: ${props => props.theme.palette.secondary.blueLight};
      }
    }
  }

  > div:nth-of-type(2) {
    button {
      svg {
        stroke: ${props => props.theme.palette.midGrey};
      }
    }
  }

  > div:nth-of-type(3) {
    button {
      svg {
        stroke: ${props => props.theme.palette.vodafoneRed};
      }
    }
  }
`;

const TagsText = styled("div")`
  margin-top: 8px;
  font-family: Vodafone Rg;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  text-align: center;
  > .turquoise {
    font-weight: bold;
    color: color: ${props => props.theme.palette.turquoise};
    text-transform: capitalize;
    margin-left: 3px;
  }
`;

const ToggleButton = styled("button")<{ open: boolean }>`
  width: 36px;
  height: 36px;
  box-shadow: 0 3px 5px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  border-radius: 50%;
  position: absolute;
  top: 34px;
  right: calc(36px / -2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  cursor: pointer;

  svg {
    transform: rotate(180deg);
    transition: transform 0.25s ease-in-out;
    width: 24px;
    height: 24px;
    stroke: ${props => props.theme.palette.vodafoneRed};
  }
  ${props => (!props.open ? "svg {transform: rotate(0deg);}" : null)};
`;

const URLInformationWrapper = styled("div")``;

const URLInformationTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: #999999;
  line-height: 21px;
  margin-bottom: 14px;
`;

const IndicatorWrapper = styled("div")`
  display: flex;
  font-family: Vodafone Rg;
  font-size: 16px;
  color: #333333;
`;
const IndicatorElement = styled("div")`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: green;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 7px;
`;

const CategoriesText = styled("div")`
  margin-top: 13px;
  font-family: Vodafone Rg;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  text-align: center;
  > .purple {
    font-weight: bold;
    color: #9c2aa0;
  }
`;

const HighlightedText = styled("div")`
  margin-top: 5px;
  font-family: Vodafone Rg;
  font-size: 14px;
  line-height: 19px;
  color: #eb9700;
  text-align: center;
`;
