import React from "react";
import styled from "styled-components";
import { TabChild, Tabs, SearchBar, CancelIcon, SearchIcon, Dropdown } from "@wit/mpesa-ui-components";
import { useTranslation } from "react-i18next";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import UploadImagePlaceHolderIcon from "../../../../shared/icons/upload-image-placeholder.icon";
import { createBase64 } from "../allowed-scopes.utils";
import { IIconAllowedScope } from "../allowed-scopes.model";

enum TabState {
  ACTIONS = 0,
  SEARCH = 1,
}

interface IIconPickerProps {
  setIconID(field: string, value: any, shouldValidate?: boolean): void;
  name: string;
  color?: string;
  backgroundColor?: string;
  error: string | undefined;
  icons: IIconAllowedScope[];
  icon: IIconAllowedScope | undefined;
  isLoading: boolean;
}

export interface IIcon {
  id: string;
  label: string;
  base64: string;
}

/**
 * Component to be call in dropdown of icons
 * @param param0 Icon Options Interface
 */
const IconPicker = ({
  setIconID,
  name,
  color = "grey",
  backgroundColor = "white",
  error,
  icons,
  icon,
  isLoading,
}: IIconPickerProps) => {
  const [t] = useTranslation();
  const [searching, setSearching] = React.useState(false);
  const [availableIcons, setAvailableIcons] = React.useState<IIcon[]>([]);
  const [filterIcons, setFilterIcons] = React.useState<IIcon[]>([]);
  const [search, setSearch] = React.useState("");
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [selectedIcon, setSelectedIcon] = React.useState<IIconAllowedScope>(
    icon
      ? icon
      : {
          id: "000",
          label: t("commons.iconPlaceholder"),
          name: t("commons.iconPlaceholder"),
          base64:
            "data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAABCUExURUdwTP////////////////////////////////////////////////////////////////////////////////////VtkI8AAAAVdFJOUwAEL/YVfkKZDnE8HWLvj9R1uOLNVtGZGB8AAAClSURBVCjPndFJDsMgDAVQM5oZMnD/qzYJwQGlm/bv+E9CxgD8HRtrTyxDz0Qdwp7ejH2VrTXa2hPWZQaHiCesDNQE6oYNYJ/A3lBTWKtI8XXVFQWFwBAsORowgqYqNyzA3HFEAt1A+HbkBLIB9tfmDsfA17i6JYgOTD9TTSsBj2UG029VCbahz7TcHZ20qsdKAo8YHOfcv/7vXGQKnL3Ah/1L+1s+30kQlUuz75oAAAAASUVORK5CYII=",
          content:
            "iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAABCUExURUdwTP////////////////////////////////////////////////////////////////////////////////////VtkI8AAAAVdFJOUwAEL/YVfkKZDnE8HWLvj9R1uOLNVtGZGB8AAAClSURBVCjPndFJDsMgDAVQM5oZMnD/qzYJwQGlm/bv+E9CxgD8HRtrTyxDz0Qdwp7ejH2VrTXa2hPWZQaHiCesDNQE6oYNYJ/A3lBTWKtI8XXVFQWFwBAsORowgqYqNyzA3HFEAt1A+HbkBLIB9tfmDsfA17i6JYgOTD9TTSsBj2UG029VCbahz7TcHZ20qsdKAo8YHOfcv/7vXGQKnL3Ah/1L+1s+30kQlUuz75oAAAAASUVORK5CYII=",
        },
  );

  /**
   * function called when changes the tab
   * @param tabValue value of the tab selected
   */
  const controlledSetSelectedTab = (tabValue: number) => {
    setSelectedTab(tabValue);
    switch (tabValue) {
      case TabState.ACTIONS:
        setAvailableIcons(icons);
        break;
      case TabState.SEARCH:
        setSearching(true);
        setFilterIcons(icons);
        break;
    }
  };

  /**
   * Update icon selected
   * @param index index of icon choosen
   */
  const selectIcon = (index: number) => {
    if (filterIcons.length > 0 && selectedTab === TabState.SEARCH) {
      setIconID(name, filterIcons[index].id);
      setSelectedIcon(filterIcons[index]);
    } else {
      setIconID(name, availableIcons[index].id);
      setSelectedIcon(availableIcons[index]);
    }
    setOpen(false);
  };

  React.useEffect(() => {
    if (search.length > 0) {
      setFilterIcons(
        icons.filter(icon => (icon && icon.name ? icon.name.toLowerCase().includes(search.toLowerCase()) : null)),
      );
    } else {
      setFilterIcons(availableIcons);
    }
  }, [search, setFilterIcons]);

  React.useEffect(() => {
    setAvailableIcons(availableIcons);
  }, [setFilterIcons]);

  React.useEffect(() => {
    if (icons && icons.length > 0) {
      setAvailableIcons(icons);
    }
  }, [icons]);

  return (
    <Dropdown
      hasValue={false}
      isOpen={open}
      controlledToggleOpen={setOpen}
      label={
        <DropdownLabelContainer>
          <IconDropDownContainer backgroundColor={backgroundColor}>
            <IconDropdownMask icon={createBase64(selectedIcon)} color={color} />
          </IconDropDownContainer>
          <ColorText>{selectedIcon.name}</ColorText>
        </DropdownLabelContainer>
      }
      dropdownType={DropdownType.RECTANGULAR_NORMAL}
      error={error}
    >
      <IconsDropdown>
        {!isLoading ? (
          !searching ? (
            <Tabs
              isSecondaryTabs={true}
              controlledSetSelectedTab={controlledSetSelectedTab}
              controlledSelectedTab={selectedTab}
            >
              <TabChild label={t("commons.iconActions")}>
                <IconSelectorSection>
                  {availableIcons.map((icon, index) => (
                    <IconContainer
                      key={index}
                      onClick={() => selectIcon(index)}
                      selected={icon.id === selectedIcon.id}
                      backgroundColor={backgroundColor}
                    >
                      <IconMask
                        logo={createBase64(icon)}
                        onClick={() => selectIcon(index)}
                        selected={icon.id === selectedIcon.id}
                        color={color}
                      ></IconMask>
                    </IconContainer>
                  ))}
                </IconSelectorSection>
              </TabChild>
              {
                //@ts-ignore
                <TabChild label={<SearchIcon width="19px" height="19px" style={{ stroke: "#999999" }} />}>
                  <IconSelectorSection>
                    {availableIcons.map((icon, index) => (
                      <IconContainer
                        key={index}
                        onClick={() => selectIcon(index)}
                        selected={icon.id === selectedIcon.id}
                        backgroundColor={backgroundColor}
                      >
                        <IconMask
                          logo={createBase64(icon)}
                          onClick={() => selectIcon(index)}
                          selected={icon.id === selectedIcon.id}
                          color={color}
                        ></IconMask>
                      </IconContainer>
                    ))}
                  </IconSelectorSection>
                </TabChild>
              }
            </Tabs>
          ) : (
            <div>
              <CustomRow>
                <SearchBar
                  placeholderLabel={t("commons.searchIconFilter")}
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  clearValue={() => null}
                />
                <CancelIcon
                  width={"24px"}
                  height={"24px"}
                  style={{ stroke: "#999999", marginRight: "12px" }}
                  onClick={() => {
                    setSearching(false);
                    setSearch("");
                    controlledSetSelectedTab(0);
                  }}
                />
              </CustomRow>
              <IconSelectorSection>
                {filterIcons.length > 0 ? (
                  filterIcons.map((icon, index) => (
                    <IconContainer
                      key={index}
                      onClick={() => selectIcon(index)}
                      selected={icon.id === selectedIcon.id}
                      backgroundColor={backgroundColor}
                    >
                      <IconMask
                        logo={createBase64(icon)}
                        onClick={() => selectIcon(index)}
                        selected={icon.id === selectedIcon.id}
                        color={color}
                      ></IconMask>
                    </IconContainer>
                  ))
                ) : (
                  <NoIconFound>
                    <IconNotFound>
                      <UploadImagePlaceHolderIcon />
                    </IconNotFound>
                    <LabelNotFound>{t("commons.noFoundIcons")}</LabelNotFound>
                  </NoIconFound>
                )}
              </IconSelectorSection>
            </div>
          )
        ) : (
          <LoadingIcons>
            <LabelLoading>{t("commons.loadingIcons")}</LabelLoading>
          </LoadingIcons>
        )}
      </IconsDropdown>
    </Dropdown>
  );
};

export default IconPicker;

const IconsDropdown = styled("div")`
  height: 201px;
  > div {
    height: 100%;
  }
  > div > div:first-child {
    display: flex;
    padding-top: 1vh;
    padding-left: 0.5vw;
    height: 36px;
    > div {
      width: 100%;
      display: flex;
      flex: none;
      > div:last-child {
        margin-left: auto;
        margin-right: 14px;
      }
    }
  }
  > div > div:last-child {
    margin-top: 0;
    height: 164px;
  }
`;

const IconSelectorSection = styled("div")`
  border-top: 1px solid #ebebeb;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  height: 100%;
  padding: 10px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const IconContainer = styled("div")<{
  selected: boolean;
  backgroundColor?: string;
}>`
  background-color: ${props => props.theme.palette.whiteTwo};
  stroke: #8d99a7;
  width: 32px;
  height: 32px;
  margin: 2px 2px;
  border-radius: 3px;
  ${props =>
      props.selected
        ? `background-color: ${props.backgroundColor ? props.backgroundColor : props.theme.palette.vodafoneRed};`
        : null}
    :hover {
    background-color: ${props => (props.backgroundColor ? props.backgroundColor : props.theme.palette.vodafoneRed)};
  }
`;

const IconMask = styled("div")<{ selected: boolean; logo?: string; color?: string }>`
  width: 100%;
  height: 100%;
  mask-image: ${props => (props.logo ? `url("${props.logo}")` : "none")};
  mask-repeat: no-repeat;
  mask-size: 24px 24px;
  mask-position: center;
  background-color: ${props => props.theme.palette.midGrey};
  ${props =>
      props.selected ? `background-color: ${props.color ? props.color : props.theme.palette.vodafoneRed};` : null}
    :hover {
    background-color: ${props => (props.color ? props.color : props.theme.palette.vodafoneRed)};
  }
`;

const CustomRow = styled("span")`
  display: flex;
  align-items: center;
  > div {
    > div {
      display: none;
    }
    border: none;
    border-radius: inherit;
    :hover {
      border: none;
    }
    > input {
      font-size: 14px;
    }
    > svg {
      stroke: #333333;
      width: 14px;
      height: 14px;
    }
  }
`;

const NoIconFound = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LabelNotFound = styled("div")`
  margin-top: 1vh;
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: ${props => props.theme.palette.midGrey};
`;

const LoadingIcons = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const LabelLoading = styled("span")`
  margin: 3vh;
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: ${props => props.theme.palette.midGrey};
`;

const IconNotFound = styled("div")`
  svg {
    width: 36px;
    height: 36px;
  }
`;

const DropdownLabelContainer = styled("div")`
  display: flex;
  align-items: center;
  margin-left: -12px;
  > span {
    margin-left: 0.5vw;
  }
`;

const ColorText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: ${props => props.theme.palette.midGrey};
`;

const IconDropDownContainer = styled("div")<{
  backgroundColor: string;
}>`
  background-color: ${props => (props.backgroundColor ? `${props.backgroundColor}` : "#ffffff")};
  width: 37px;
  height: 34px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border-right: 1px solid #ebebeb;
`;

const IconDropdownMask = styled("div")<{
  color: string;
  icon: string;
}>`
  mask-image: ${props => (props.icon ? `url("${props.icon}")` : "none")};
  mask-repeat: no-repeat;
  mask-size: 24px 24px;
  mask-position: center;
  background-color: ${props => (props.color ? `${props.color}` : "#ffffff")};
  width: 37px;
  height: 34px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border-right: 1px solid #ebebeb;
`;
