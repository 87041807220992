import {
  IServiceManagerAddService,
  IServiceManagerServiceDetails,
  ServiceManagerStatusEnum,
} from "../../../shared/models/ant-service-manager.model";
import { AxiosPromise } from "axios";
import { IUpdateServiceRequest } from "./ant-service-manager-service-details.model";
import SBApiClient from "../../../configs/axios.service-builder.config";
import ApiClient from "../../../configs/axios.service-builder.config";
import SBApiClientBO from "../../../configs/axios.config";

const AntServiceManagerServiceDetailsApi = {
  routes: {
    serviceDetails: "/service/:serviceId?isAppAnt=true",
    updateServiceDetails: "/service/:serviceId?isAppAnt=true", //when we need to add maker checker this URL needs to be changed to /v1/services/...
    addAntService: "/service?isAppAnt=true",
    getCanBeHighlighted: "/v1/ipg_services/canbehighlighted",
  },
  methods: {
    getServiceManagerServiceDetails: (serviceId: string): AxiosPromise<IServiceManagerServiceDetails> => {
      return SBApiClient.get(AntServiceManagerServiceDetailsApi.routes.serviceDetails.replace(":serviceId", serviceId));
    },
    deleteServiceManagerServiceDetails: (serviceId: string): AxiosPromise<any> => {
      return SBApiClient.delete(
        AntServiceManagerServiceDetailsApi.routes.serviceDetails.replace(":serviceId", serviceId),
      );
    },
    updateServiceManagerServiceDetails: (
      serviceId: string,
      imageService: string | undefined,
      iconService: string | undefined,
      values: IUpdateServiceRequest,
    ): AxiosPromise<void> => {
      const data = new FormData();

      if (imageService) {
        const image = new Blob([imageService], { type: "text/plain;charset=utf-8" });
        data.append("imageService", image);
      }

      if (iconService) {
        const icon = new Blob([iconService], { type: "text/plain;charset=utf-8" });
        data.append("iconService", icon);
      }

      data.append("metadata", JSON.stringify(values));

      return SBApiClient.patch(
        AntServiceManagerServiceDetailsApi.routes.updateServiceDetails.replace(":serviceId", serviceId),
        data,
      );
    },
    updateServiceManagerServiceStatus: (serviceId: string, status: ServiceManagerStatusEnum): AxiosPromise<void> => {
      const data = new FormData();
      data.append("metadata", JSON.stringify({ status: status }));
      return SBApiClient.patch(
        AntServiceManagerServiceDetailsApi.routes.updateServiceDetails.replace(":serviceId", serviceId),
        data,
      );
    },
    addAntService: (
      imageService: string,
      iconService: string,
      serviceToAdd: IServiceManagerAddService,
    ): AxiosPromise<void> => {
      const data = new FormData();
      const image = new Blob([imageService], { type: "text/plain;charset=utf-8" });
      const icon = new Blob([iconService], { type: "text/plain;charset=utf-8" });
      data.append("imageService", image);
      data.append("iconService", icon);
      data.append("metadata", JSON.stringify(serviceToAdd));

      return ApiClient.post(AntServiceManagerServiceDetailsApi.routes.addAntService, data);
    },
    getCanBeHighlighted: (): AxiosPromise<{ canBeHighlighted: boolean }> => {
      return SBApiClientBO.get(AntServiceManagerServiceDetailsApi.routes.getCanBeHighlighted);
    },
  },
};

export default AntServiceManagerServiceDetailsApi;
