import React from "react";
import styled from "styled-components";

interface IMobileSchemaInterface {
  children: JSX.Element;
}

/**
 * Component that will build the mobile frame and add child to frame
 * @param param0 interface that will be receive a child element
 */
const MobileSchema = ({ children }: IMobileSchemaInterface) => {
  return (
    <MobileFrame>
      <SpeakerFrame />
      <ScreenFrame>{children}</ScreenFrame>
    </MobileFrame>
  );
};

const MobileFrame = styled("div")`
  background-color: #fafafa;
  border-radius: 35px;
  width: 282px;
  height: 560px;
  position: relative;
  top: 0;
`;

const SpeakerFrame = styled("div")`
  width: 44px;
  height: 6px;
  border-radius: 3.2px;
  background-color: #d8d8d8;
  margin: auto;
  position: relative;
  top: 1.9vh;
`;
const ScreenFrame = styled("div")`
  width: 252px;
  height: 490px;
  margin: auto;
  position: relative;
  top: 3.4vh;
`;

export default MobileSchema;
