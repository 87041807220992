import React from "react";
import styled from "styled-components";
import { AutoClosingDropdown } from "@wit/mpesa-ui-components";

interface IStatusDropDownComponentProps {
  itemId: number | string;
  labelStyle: React.CSSProperties;
  options: { key: string; label: string }[];
  selectedOption: string;
  onSelectedRowDataStatusChange: (data: { itemId: number | string; value: string }) => void;
}

/**
 * React component to display status on a table.
 * It gives the possibility to update the status right on the table being displayed
 */
const StatusDropDownComponent = ({
  itemId,
  labelStyle,
  selectedOption,
  options,
  onSelectedRowDataStatusChange,
}: IStatusDropDownComponentProps) => {
  return (
    <StatusDropDownWrapper
      id="status-dropdown"
      onClick={eve => {
        eve.stopPropagation();
      }}
    >
      <AutoClosingDropdown
        hasValue={true}
        label={<span style={labelStyle}>{selectedOption}</span>}
        options={options}
        selectOption={option =>
          onSelectedRowDataStatusChange({
            itemId,
            value: option.key,
          })
        }
      />
    </StatusDropDownWrapper>
  );
};

export default StatusDropDownComponent;

const StatusDropDownWrapper = styled("div")<{ disabled?: Boolean }>`
  min-width: 150px;
  height: 100%;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  font-weight: inherit;
  margin: 4px 0;
  position: absolute;

  button {
    border: 0;
    font-weight: inherit;
    padding-left: 0;
    &:hover {
      border: 0;
    }
  }

  div {
    width: 100%;
    font-weight: 400 !important;
  }
`;
