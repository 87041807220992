import React from "react";
import styled from "styled-components";
import { Row } from "./../../../shared/shared.styled";
import { SearchBar, AutoClosingDropdown, GridViewIcon, ListViewIcon } from "@wit/mpesa-ui-components";
import { useTranslation } from "react-i18next";
import { getAppPlatforms, getAppTypes } from "../../../shared/shared.utils";
import { FilterFuncts } from "@wit/mpesa-ui-components/lib/hooks/use-filters.hook";
import { SelectedView } from "../../../shared/shared.enums";

export enum AppsFiltersEnum {
  SEARCH = "search",
  PLATFORM = "platform",
  STATUS = "status",
  TYPE = "type",
}

interface IAppsFiltersProps extends FilterFuncts {
  selectedView: SelectedView;
  setSelectedView: (selectedView: SelectedView) => any;
}

const AppsFilters = ({
  filters,
  clearFilter,
  isFilterActive,
  resetFilters,
  updateFilter,
  selectedView,
  setSelectedView,
}: IAppsFiltersProps) => {
  // Hook initializations
  const [t] = useTranslation();

  return (
    <AppsFilterContainer>
      <div style={{ flex: "30%", flexShrink: 0, flexGrow: 1 }}>
        <SearchBar
          placeholderLabel={t("pages.apps.filters.searchPlaceholder")}
          value={filters.has(AppsFiltersEnum.SEARCH) ? (filters.get(AppsFiltersEnum.SEARCH) as string[])[0] : ""}
          onChange={e => updateFilter(AppsFiltersEnum.SEARCH, e.target.value, true)}
          clearValue={() => clearFilter(AppsFiltersEnum.SEARCH)}
        />
      </div>

      <div style={{ flex: "20%", flexShrink: 0, flexGrow: 1 }}>
        <AutoClosingDropdown
          label={
            filters.has(AppsFiltersEnum.PLATFORM)
              ? t(`commons.apps.enums.platform.${(filters.get(AppsFiltersEnum.PLATFORM) as string[])[0]}`)
              : t("pages.apps.filters.platformDropdownPlaceholder")
          }
          options={getAppPlatforms()}
          selectOption={val => {
            if (val.key) {
              updateFilter(AppsFiltersEnum.PLATFORM, val.key, true);
            } else {
              clearFilter(AppsFiltersEnum.PLATFORM);
            }
          }}
          hasValue={filters.has(AppsFiltersEnum.PLATFORM)}
        />
      </div>
      <div style={{ flex: "20%", flexShrink: 0, flexGrow: 1 }}>
        <AutoClosingDropdown
          label={
            filters.has(AppsFiltersEnum.TYPE)
              ? t(`commons.apps.enums.type.${(filters.get(AppsFiltersEnum.TYPE) as string[])[0]}`)
              : t("pages.apps.filters.typeDropdownPlaceholder")
          }
          options={getAppTypes()}
          selectOption={val => {
            if (val.key) {
              updateFilter(AppsFiltersEnum.TYPE, val.key, true);
            } else {
              clearFilter(AppsFiltersEnum.TYPE);
            }
          }}
          hasValue={filters.has(AppsFiltersEnum.TYPE)}
        />
      </div>
      <ToggleViewContainer>
        <ViewIconContainer
          onClick={() => setSelectedView(SelectedView.GRID)}
          isSelectedView={selectedView === SelectedView.GRID}
        >
          <GridViewIcon />
        </ViewIconContainer>
        <ViewIconContainer
          onClick={() => setSelectedView(SelectedView.LIST)}
          isSelectedView={selectedView === SelectedView.LIST}
        >
          <ListViewIcon />
        </ViewIconContainer>
      </ToggleViewContainer>
    </AppsFilterContainer>
  );
};

export default AppsFilters;

const AppsFilterContainer = styled(Row)`
  margin-bottom: 49px;
  margin-top: 41px;
  flex-wrap: wrap;
  gap: 24px;
  @media (max-width: 768px) {
    > div {
      flex: 45% !important;
    }
  }
`;

const ToggleViewContainer = styled(Row)`
  margin-left: auto;
  flex: 20%;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: flex-end;
  > div {
    :first-of-type {
      margin-right: 12px;
    }
  }
`;

const ViewIconContainer = styled("div")<{ isSelectedView: boolean }>`
  width: 24px;
  height: 24px;
  cursor: pointer;

  svg {
    stroke: ${props => (props.isSelectedView ? props.theme.palette.black : props.theme.palette.midGrey)};
  }
`;
