import React from "react";
import { AxiosPromise } from "axios";
import ApiClient from "../../../configs/axios.config";
import { useDispatch } from "react-redux";
import { BanksActions } from "./banks.store";
import { useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { useTranslation } from "react-i18next";
import { IBank, BanksStatusEnum } from "./bank.model";

/**
 * function with all banks api methods
 */
const useBankAPI = () => {
  const dispatch = useDispatch();
  const [showAlert, , setAlertProps] = useAlert();
  const [t] = useTranslation();

  const methods = {
    getBanks: (): AxiosPromise<{ banks: IBank[]; status: BanksStatusEnum }> => {
      return ApiClient.get(`/v1/banks?previousVersion=false`).then(
        res => {
          return res;
        },
        err => {
          setAlertProps({
            title: t("pages.banks.errors.getBanks"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
          return err;
        },
      );
    },
    getLastVersion: (): AxiosPromise<{ banks: IBank[]; status: BanksStatusEnum }> => {
      return ApiClient.get(`/v1/banks?previousVersion=true`).then(
        res => {
          return res;
        },
        err => {
          setAlertProps({
            title: t("pages.banks.errors.getBanks"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
          return err;
        },
      );
    },
    getData: () => {
      return methods.getBanks().then(res => {
        dispatch(BanksActions.creators.getBanksAction(res.data.banks, res.data.status));
        return methods.getLastVersion().then(lastVersion => {
          dispatch(BanksActions.creators.getBanksLastVersionAction(lastVersion.data.banks));
          return lastVersion;
        });
      });
    },
    createBank: (bank: IBank): AxiosPromise<{ banks: IBank[]; status: BanksStatusEnum }> => {
      return ApiClient.post(`/v1/banks`, bank).then(
        res => {
          methods.getData();
          setAlertProps({
            title: t("pages.banks.success.createBank"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          return res;
        },
        err => {
          setAlertProps({
            title:
              err.status === 409
                ? t("pages.banks.errors.shortcodeExists").replace(
                    "{0}",
                    //if shortcode is too long (more than 25 chars) cut and add ellipsis
                    (bank.shortCode = bank.shortCode.replace(/(.{24})..+/, "$1…")),
                  )
                : t("pages.banks.errors.createBank"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
          return err;
        },
      );
    },
    editBank: (id: string, bank: IBank): AxiosPromise<{ banks: IBank[]; status: BanksStatusEnum }> => {
      return ApiClient.put(`/v1/banks/${id}`, bank).then(
        res => {
          methods.getData();
          setAlertProps({
            title: t("pages.banks.success.editBank"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          return res;
        },
        err => {
          setAlertProps({
            title:
              err.status === 409
                ? t("pages.banks.errors.shortcodeExists").replace(
                    "{0}",
                    //if shortcode is too long (more than 25 chars) cut and add ellipsis
                    (bank.shortCode = bank.shortCode.replace(/(.{24})..+/, "$1…")),
                  )
                : t("pages.banks.errors.editBank"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
          return err;
        },
      );
    },
    deleteBank: (bank: IBank): AxiosPromise<{ banks: IBank[]; status: BanksStatusEnum }> => {
      return ApiClient.delete(`/v1/banks/${bank.id}`).then(
        res => {
          methods.getData();
          setAlertProps({
            title: t("pages.banks.success.deleteBank"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          return res;
        },
        err => {
          setAlertProps({
            title: t("pages.banks.errors.deleteBank"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
          return err;
        },
      );
    },
    restoreBank: (bank: IBank): AxiosPromise<{ banks: IBank[]; status: BanksStatusEnum }> => {
      return ApiClient.put(`/v1/banks/${bank.id}/restore`).then(
        res => {
          methods.getData();
          setAlertProps({
            title: t("pages.banks.success.restoreBank"),
            type: AlertTypeEnum.SUCCESS,
          });
          return res;
        },
        err => {
          setAlertProps({
            title: t("pages.banks.errors.restoreBank"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
          return err;
        },
      );
    },
    publishBanks: (): AxiosPromise<{ banks: IBank[]; status: BanksStatusEnum }> => {
      return ApiClient.put(`/v1/banks/publish`).then(
        res => {
          methods.getData();
          setAlertProps({
            title: t("pages.banks.success.publishBanks"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();

          return res;
        },
        err => {
          setAlertProps({
            title: t("pages.banks.errors.publishBanks"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
          return err;
        },
      );
    },
    revertBanks: (): AxiosPromise<{ banks: IBank[]; status: BanksStatusEnum }> => {
      return ApiClient.put(`/v1/banks/revert`).then(
        res => {
          methods.getData();
          setAlertProps({
            title: t("pages.banks.success.revertBanks"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();

          return res;
        },
        err => {
          setAlertProps({
            title: t("pages.banks.errors.revertBanks"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
          return err;
        },
      );
    },
  };
  return methods;
};

export default useBankAPI;
