import { AxiosPromise } from "axios";
import ApiClient from "../../../configs/axios.config";
import {
  IGalleryImage,
  IReferralCampaign,
  IReferralCampaigns,
  IAvailablePeriodRequest,
  IAnalyticsRewardsResponse,
  IAnalyticsInvitesResponse,
  IReferralAnalyticsRequest,
  IReferralAnalyticsDashboardRequest,
} from "./referral-campaigns.model";
import { getParametrizedRoute } from "../../../shared/shared.utils";

/*
 * Referral campaigns API methods
 * */
const ReferralCampaignsApi = {
  routes: {
    referralCampaigns: "/v1/referral-campaigns",
    hasDefaultCampaign: "/v1/referral-campaigns/has-default",
    referralCampaign: "/v1/referral-campaigns/{id}",
    duplicateReferralCampaign: "/v1/referral-campaigns/{id}/duplicate",
    gallery: "/gallery?type=REFERRAL_CAMPAIGNS",
    availablePeriod: "/v1/referral-campaigns/available-period",
    analyticsDashboard: "/v1/referral-campaigns/analytics/{id}/{type}/statistic",
    acceptedInvites:
      "/v1/referral-campaigns/analytics/{id}/invites?page={page}&pageSize={pageSize}&msisdn={msisdn}&startDate={startDate}&endDate={endDate}",
    rewardsEarned:
      "/v1/referral-campaigns/analytics/{id}/rewards?page={page}&pageSize={pageSize}&msisdn={msisdn}&startDate={startDate}&endDate={endDate}",
    senders:
      "/v1/referral-campaigns/analytics/{id}/senders?page={page}&pageSize={pageSize}&msisdn={msisdn}&startDate={startDate}&endDate={endDate}",
    receivers:
      "/v1/referral-campaigns/analytics/{id}/receivers?page={page}&pageSize={pageSize}&msisdn={msisdn}&startDate={startDate}&endDate={endDate}",
    analyticStatus: "/v1/referral-campaigns/analytics/{id}/{type}",
    exportDataFile:
      "/v1/referral-campaigns/analytics/{id}/{type}/export?startDate={startDate}&endDate={endDate}&timezone={timeZone}&msisdn={msisdn}",
  },
  methods: {
    getReferralCampaigns: (): AxiosPromise<IReferralCampaigns> => {
      return ApiClient.get(ReferralCampaignsApi.routes.referralCampaigns);
    },
    hasDefaultCampaign: (): AxiosPromise<boolean> => {
      return ApiClient.get(ReferralCampaignsApi.routes.hasDefaultCampaign);
    },
    createReferralCampaign: (campaign: IReferralCampaign): AxiosPromise<IReferralCampaign> => {
      return ApiClient.post(ReferralCampaignsApi.routes.referralCampaigns, campaign);
    },
    getReferralCampaign: (campaignID: string): AxiosPromise<IReferralCampaign> => {
      return ApiClient.get(ReferralCampaignsApi.routes.referralCampaign.replace("{id}", campaignID));
    },
    deleteReferralCampaign: (campaignID: string): AxiosPromise<IReferralCampaign> => {
      return ApiClient.delete(ReferralCampaignsApi.routes.referralCampaign.replace("{id}", campaignID));
    },
    editReferralCampaign: (campaignID: string, value: IReferralCampaign): AxiosPromise<IReferralCampaign> => {
      return ApiClient.put(ReferralCampaignsApi.routes.referralCampaign.replace("{id}", campaignID), value);
    },
    duplicateReferralCampaign: (
      campaignID: string,
      startDate: number,
      endDate: number,
    ): AxiosPromise<IReferralCampaign> => {
      return ApiClient.post(ReferralCampaignsApi.routes.duplicateReferralCampaign.replace("{id}", campaignID), {
        startDate,
        endDate,
      });
    },
    getImageGallery: (): AxiosPromise<IGalleryImage[]> => {
      return ApiClient.get(ReferralCampaignsApi.routes.gallery);
    },
    areDatesAvailable: (data: IAvailablePeriodRequest): AxiosPromise<boolean> => {
      return ApiClient.get(ReferralCampaignsApi.routes.availablePeriod, { params: data });
    },
    getInvitesList: (params: IReferralAnalyticsRequest): AxiosPromise<IAnalyticsInvitesResponse> => {
      return ApiClient.get(getParametrizedRoute(params, ReferralCampaignsApi.routes.acceptedInvites));
    },
    getRewardsList: (params: IReferralAnalyticsRequest): AxiosPromise<IAnalyticsRewardsResponse> => {
      return ApiClient.get(getParametrizedRoute(params, ReferralCampaignsApi.routes.rewardsEarned));
    },
    getSendersList: (params: IReferralAnalyticsRequest): AxiosPromise<IAnalyticsInvitesResponse> => {
      return ApiClient.get(getParametrizedRoute(params, ReferralCampaignsApi.routes.senders));
    },
    getReceiversList: (params: IReferralAnalyticsRequest): AxiosPromise<IAnalyticsRewardsResponse> => {
      return ApiClient.get(getParametrizedRoute(params, ReferralCampaignsApi.routes.receivers));
    },
    updateStatus: (params: any, { itemId, value }: { itemId: number | string; value: string }): AxiosPromise => {
      return ApiClient.put(getParametrizedRoute(params, ReferralCampaignsApi.routes.analyticStatus), {
        inviteId: itemId,
        status: value,
      });
    },
    getDashboardData: (params: IReferralAnalyticsDashboardRequest): AxiosPromise => {
      return ApiClient.get(getParametrizedRoute(params, ReferralCampaignsApi.routes.analyticsDashboard));
    },
    getExcelFile: (params: IReferralAnalyticsRequest): AxiosPromise => {
      return ApiClient.get(getParametrizedRoute(params, ReferralCampaignsApi.routes.exportDataFile), {
        responseType: "blob",
      });
    },
  },
};

export default ReferralCampaignsApi;
