import { AutoClosingDropdown, PrimaryButton } from "@wit/mpesa-ui-components";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import useVersionsDiff, {
  VersionItemStatusEnum,
} from "../../../../shared/hooks/use-version-diff/use-version-diff.hook";
import { ILanguage } from "../../../../shared/models/language.model";
import { Row } from "../../../../shared/shared.styled";
import {
  ILanguageTranslation,
  ILanguageTranslations,
  LanguageTranslationStatusEnum,
} from "../language-translations.model";
import { renderVersionRow, getOrderedTranslations, selectedLanguageEvaluator } from "../language-translations.utils";

interface IPublishLanguageTranslationsModalProps {
  hideModal: () => void;
  publishChanges: () => void;
  isLoading: boolean;
  currentVersion: ILanguageTranslations;
  lastVersion: ILanguageTranslations;
  availableLanguages: ILanguage[];
}

export interface IOrderedTranslations extends ILanguageTranslation {
  versionItemStatus?: VersionItemStatusEnum;
}

/**
 * Submits the changes of language translations for approval
 * @param {ILanguageTranslation[]} currentVersion
 * @param {ILanguageTranslation[]} lastVersion
 * @param {() => void} publishChanges
 * @param {() => void} hideModal
 * @returns {JSX.Element}
 * @constructor
 */
const PublishLanguageTranslationsModal = ({
  currentVersion,
  lastVersion,
  publishChanges,
  isLoading,
  hideModal,
  availableLanguages,
}: IPublishLanguageTranslationsModalProps) => {
  const [t] = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = React.useState<SharedDropdownOption>();
  const { prev, current } = useVersionsDiff(lastVersion.translations, currentVersion.translations);
  const { previousTranslations, currentTranslations } = getOrderedTranslations(prev, current);

  /**
   * Publish request
   * */
  const publish = () => {
    publishChanges();
  };

  /**
   * Returns the available languages list for the dropdown selector
   * @returns {SharedDropdownOption[]}
   */
  const getAvailableLanguageOptions = (): SharedDropdownOption[] => {
    return availableLanguages.map(l => {
      return { key: l.code, label: l.name };
    });
  };

  React.useEffect(() => {
    setSelectedLanguage(selectedLanguageEvaluator(prev, current, availableLanguages));
  }, []);

  return selectedLanguage ? (
    <ModalContainer>
      <ModalTitleContainer>
        <ModalTitle>{t("pages.languageTranslations.submitForApproval")}</ModalTitle>
        <Row>
          <ModalSubtitle>{t("pages.languageTranslations.submitForApprovalTitle")}</ModalSubtitle>
          <LanguageDropdown>
            <DropdownWrapper id={"publish-translations-language-selector"}>
              <AutoClosingDropdown
                hasValue={false}
                label={selectedLanguage.label}
                options={getAvailableLanguageOptions()}
                selectOption={opt => {
                  setSelectedLanguage(opt);
                }}
              />
            </DropdownWrapper>
          </LanguageDropdown>
        </Row>
      </ModalTitleContainer>
      <DocumentContainer>
        <Row style={{ alignItems: "normal" }}>
          <RowItem>
            <Panel>
              <PanelTitle background={true}>{t("pages.languageTranslations.previousVersion")}</PanelTitle>
              {previousTranslations.length ? (
                <>
                  {previousTranslations.map(languageTranslation => {
                    return renderVersionRow(languageTranslation, t, selectedLanguage.key, true, false);
                  })}
                </>
              ) : null}
            </Panel>
            <Panel>
              <PanelTitle>{t("pages.languageTranslations.editedVersion")}</PanelTitle>
              {currentTranslations.length ? (
                <>
                  {currentTranslations.map(languageTranslation => {
                    return renderVersionRow(languageTranslation, t, selectedLanguage.key, false, true);
                  })}
                </>
              ) : null}
            </Panel>
          </RowItem>
        </Row>

        <Row
          style={{
            justifyContent: "flex-end",
            width: "100%",
            marginTop: "auto",
            padding: "24px",
            overflow: "visible",
          }}
        >
          <div style={{ marginRight: "12px" }}>
            <PrimaryButton
              disabled={isLoading}
              onClick={hideModal}
              titleLabel={t("pages.languageTranslations.cancel")}
              id="cancel-button"
            />
          </div>
          <div>
            <PrimaryButton
              id="confirm-button"
              redTheme={true}
              type="submit"
              onClick={publish}
              disabled={isLoading}
              titleLabel={t("pages.languageTranslations.confirm")}
            />
          </div>
        </Row>
      </DocumentContainer>
    </ModalContainer>
  ) : null;
};

export default PublishLanguageTranslationsModal;

const RowItem = styled("div")`
  width: 100%;
  max-height: 300px;
  overflow-x: auto;
  font-family: Vodafone Rg;
  display: flex;
  flex-direction: row;
  position: relative;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  b,
  strong {
    margin: 0;
  }
`;

const Panel = styled("div")`
  width: 50%;
`;

const PanelTitle = styled("div")<{ background?: boolean }>`
  padding: 24px 24px 20px 24px;
  background: ${props => (props.background ? props.theme.palette.lightGrey : props.theme.palette.white)};
  font-family: Vodafone Rg;
  font-size: 16px;
  width: 100%;
`;

const ModalContainer = styled("div")`
  border-radius: 6px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
`;

const ModalTitleContainer = styled("div")`
  width: 100%;
  padding: 24px;
`;

const ModalTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 13px;
`;

const ModalSubtitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 18px;
  color: ${props => props.theme.palette.darkGrey};
`;

const DocumentContainer = styled("div")`
  font-family: Vodafone Rg;
  max-height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const LanguageDropdown = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
`;

const DropdownWrapper = styled.div`
  > button {
    height: auto;
    border: none;
    color: ${props => props.theme.palette.turquoiseBlue};
    padding: 0;
    justify-content: normal;
    :hover {
      border: none;
    }

    > svg {
      stroke: ${props => props.theme.palette.turquoiseBlue};
    }

    > div {
      right: 0px;
      left: auto;
    }

    span {
      max-width: unset !important;
    }
  }
`;

export const StatusText = styled("span")<{ status: LanguageTranslationStatusEnum }>`
  ${props => {
    let color = props.theme.palette.digitalGreen;
    switch (props.status) {
      case LanguageTranslationStatusEnum.LIVE:
        color = props.theme.palette.digitalGreen;
        break;
      case LanguageTranslationStatusEnum.EDIT:
        color = props.theme.palette.freshOrange;
        break;
      case LanguageTranslationStatusEnum.DELETE:
        color = props.theme.palette.vodafoneRed;
        break;
    }
    return `color: ${color};display: flex;justify-content: flex-end;`;
  }}
`;
