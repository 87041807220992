import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import styled from "styled-components";
import { RatioContainer } from "../../shared/shared.styled";

export const ServiceItemTitle = styled(RatioContainer)`
  font-family: Vodafone Rg;
  font-size: 22px;
  font-weight: bold;
  color: ${props => props.theme.palette.black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SafaricomServiceItemTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
`;

export const NonEditableText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${styleTheme.palette.darkGrey};
`;
export const LightFormLabel = styled("span")`
  font-weight: normal;
`;

export const LoadingContainer = styled("div")`
  height: 100vh;
  width: calc(100% - 70px);
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
`;
