export interface IDynamicScreens {
  items: IDynamicScreen[];
}

export interface IDynamicScreen {
  id: string;
  name: ITranslatableObject;
  isActive: boolean;
  description: ITranslatableObject;
  section: string;
  categories: IDynamicScreenCategory[];
  tags?: string[];
  tagsSuggestions?: [];
  eventId: string;
  highlighted: boolean;
  color: string;
  icon: string;
  urlImage?: string;
  imageName?: string;
  urlIcon?: string;
  iconName?: string;
  ussdCode?: string;
  ussdRedirect?: boolean;
}

export interface ITranslatableObject {
  key: string;
  translations: any;
}

export interface IDynamicScreenCategory {
  id: string;
  name: ITranslatableObject;
  externalCategoryIds?: string[];
  iconId?: string;
}

export interface IDynamicScreenAddCategory {
  name: ITranslatableObject;
  externalCategoryIds?: string[];
  iconId?: string;
}

/*
 * Create and edit requests
 * */
export interface IDynamicScreenRequest extends Omit<IDynamicScreen, "id" | "name" | "description" | "categories"> {
  name: { translations: any };
  description: { translations: any };
  categories: string[];
}

export enum DynamicScreenStatusEnum {
  LIVE = "LIVE",
  DELETE = "DELETE",
  DISABLED = "DISABLED",
  DISABLE = "DISABLE",
  ENABLE = "ENABLE",
  EDIT = "EDIT",
}
