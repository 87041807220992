import React from "react";
import useRandomBackground from "../shared/hooks/use-random-background.hook";
import { getCorrectBackgroundImage } from "../features/authentication/authentication.utils";
import styled from "styled-components";
import RendererUtils from "../shared/renderer.utils";
import { IConfig } from "../app.component";

export const BackgroundImageContext = React.createContext("");

const UnAuthenticatedRoutes = ({ children, config }: React.PropsWithChildren<{ config: IConfig }>) => {
  const { imageUrl } = useRandomBackground();
  return (
    <>
      <BackgroundImageContext.Provider value={imageUrl}>
        <div style={{ height: "100%", position: "relative" }}>
          {children}
          <StyledLogo src={getCorrectBackgroundImage(RendererUtils.getLoginLogoUsingConfig(config))} alt="logo" />
        </div>
      </BackgroundImageContext.Provider>
    </>
  );
};

export default UnAuthenticatedRoutes;

const StyledLogo = styled("img")`
  position: absolute;
  top: 64px;
  right: 60px;
  @media (max-width: 768px) {
    top: 16px;
    left: 60px;
  }
`;
