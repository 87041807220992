import styled from "styled-components";
import { RatioContainer } from "../../shared/shared.styled";

export const ServiceItemTitle = styled(RatioContainer)`
  font-family: Vodafone Rg;
  font-size: 22px;
  font-weight: bold;
  color: ${props => props.theme.palette.black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SafaricomServiceItemTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
`;
