import {
  AutoClosingDropdown,
  Checkbox,
  FormSection,
  PrimaryButton,
  SecondaryPageTitle,
  TextInput,
  useAlert,
} from "@wit/mpesa-ui-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikHelpers } from "formik";

import i18next, { TFunction } from "i18next";
import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { object, string } from "yup";
import { ConfigContext } from "../../../../app.component";
import { RoutesEnum } from "../../../../routes/routes.constants";
import { ILanguage } from "../../../../shared/models/language.model";
import { PageContent } from "../../../../shared/responsive-ui.styled";
import LanguagesApi from "../../../../shared/services/languages.api";
import { FormLabel, PageContainer } from "../../../../shared/shared.styled";
import BundlesApi from "../bundles.api";
import { IAddBundleRequest, BundleTypeProvider } from "../bundles.model";
import { BundlesActions } from "../bundles.store";
import useEmptyLanguageWarningModal from "../../../../shared/hooks/use-empty-language-warning-modal.hook";

/**
 *
 * @param value
 * @param setLanguage
 * @param availableLanguages
 */
const validateLanguages = (
  value: any,
  availableLanguages: ILanguage[],
  setShowWarning: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  if (!value) {
    return false;
  }
  for (let l = 0; l < availableLanguages.length; l++) {
    if (!value[availableLanguages[l].code]) {
      setShowWarning(true);
    }
  }
  return true;
};

/**
 * Add bundle validation schemas
 * @param t
 * @param languages
 */
const addBundleValidationSchema = (
  t: TFunction,
  languages: ILanguage[],
  setShowWarning: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  let langValidators: { [x: string]: any } = {};

  (languages as ILanguage[])?.map((lang: ILanguage) => {
    if (lang.mandatory) {
      langValidators = {
        ...langValidators,
        [lang.code]: string()
          .trim()
          .required(i18next.t("pages.addBundle.requiredField"))
          .max(255, i18next.t("pages.addBundle.maxLenDescription")),
      };
    } else {
      langValidators = {
        ...langValidators,
        [lang.code]: string().max(255, i18next.t("pages.addBundle.maxLenDescription")),
      };
    }
  });

  return object().shape({
    name: object().shape({
      translations: object()
        .shape(langValidators)
        .test("name-lang", i18next.t("pages.addBundle.requiredField"), value =>
          validateLanguages(value, languages, setShowWarning),
        ),
    }),
    description: string()
      .required(i18next.t("pages.addBundle.requiredField"))
      .max(255, i18next.t("pages.addBundle.maxLenDescription")),
  });
};

/**
 * Add bundle page
 */
const AddBundlePage = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [showAlert, , setAlertProps] = useAlert();
  const history = useHistory();
  const [formValues, setFormValues] = useState<IAddBundleRequest>();
  const [providers, setProviders] = useState<string[]>(Object.values(BundleTypeProvider));
  const [availableLanguages, setAvailableLanguages] = useState<ILanguage[]>();

  const { config } = useContext(ConfigContext);

  const { warningModalOpen, showWarning, setShowWarning } = useEmptyLanguageWarningModal(availableLanguages || []);
  /**
   * Submit method wrapper to show warning if needed
   * @param values
   */
  const submitWrapper = (values: IAddBundleRequest, actions: FormikHelpers<IAddBundleRequest>) => {
    if (showWarning) {
      setShowWarning(false);
      actions.setSubmitting(false);
      warningModalOpen(addBundle, values, actions);
    } else {
      setShowWarning(false);
      addBundle(values, actions);
    }
  };

  /*
   * Sets the initial state of the form
   * */
  React.useEffect(() => {
    BundlesApi.methods.getProviders().then(
      res => {
        setProviders(res.data.providers && res.data.providers);
        getLanguages();
      },
      err => {
        getLanguages();
      },
    );
  }, []);

  /**
   * Get languages function
   */
  const getLanguages = () => {
    let availableLanguagesAux: ILanguage[];
    LanguagesApi.methods
      .getAvailableLanguages()
      .then(
        res => {
          availableLanguagesAux = res.data.availableLanguages;
          setAvailableLanguages(availableLanguagesAux);
        },
        () => {
          availableLanguagesAux = [
            { id: "1", code: "en", name: "English", urlIcon: "ic_flag_uk.png" },
            { id: "2", code: "sw", name: "Swahili (Tanzania)", urlIcon: "ic_flag_tanzania.png" },
          ];
          setAvailableLanguages(availableLanguagesAux);
          setAlertProps({
            type: AlertTypeEnum.ERROR,
            title: t("commons.getLanguagesError"),
          });
          showAlert();
        },
      )
      .finally(() => {
        const trans: { [x: string]: string } = {};
        availableLanguagesAux.forEach((lang: ILanguage) => {
          trans[lang.code] = "";
        });
        setFormValues({
          name: { translations: trans },
          provider: BundleTypeProvider.MPESA,
          description: "",
          upsellPromo: false,
          buyForFriend: false,
        });
      });
  };

  /**
   * Form submit
   */
  const addBundle = (values: IAddBundleRequest, actions: FormikHelpers<IAddBundleRequest>) => {
    actions.setSubmitting(true);
    BundlesApi.methods.addBundle(values).then(
      res => {
        dispatch(BundlesActions.creators.addBundleSuccessAction(res.data));
        setAlertProps({
          type: AlertTypeEnum.SUCCESS,
          title: t("pages.addBundle.addSuccessTitle", {
            name: values.name.translations.en ? values.name.translations.en : Object.keys(values.name.translations)[0],
          }),
          content: t("pages.addBundle.addSuccessDescription", {
            name: values.name.translations.en ? values.name.translations.en : Object.keys(values.name.translations)[0],
          }),
        });
        showAlert();
        history.push(RoutesEnum.BUNDLES);
      },
      err => {
        setAlertProps({
          type: AlertTypeEnum.ERROR,
          title:
            err && err.data && err.data.status && err.data.status.message
              ? err.data.status.message
              : t("pages.addBundle.addBundleError"),
        });
        actions.setSubmitting(false);
        showAlert();
      },
    );
  };

  /**
   * Returns the dropdown options for the providers list
   */
  const getProviders = () => {
    return Object.values(providers).map(prov => ({
      label: t(`pages.addBundle.${prov}`),
      key: prov,
    }));
  };

  /**
   * Gets the form inputs for each lang
   */
  const getFormInputs = (
    values: IAddBundleRequest,
    handleChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined,
    setFieldValue: any,
    errors: any,
  ) => {
    const rows = [];
    for (const key in values.name.translations) {
      rows.push({
        label: (
          <FormLabel required={availableLanguages?.find((l: ILanguage) => l.code === key)?.mandatory}>
            {t("pages.addBundle.bundleTypeName", { language: key.toUpperCase() })}
          </FormLabel>
        ),
        displayComponent: null,
        editingComponent: (
          <TextInput
            maxLength={100}
            autoComplete={"false"}
            type={"text"}
            required={availableLanguages?.find((l: ILanguage) => l.code === key)?.mandatory}
            error={
              errors.name && errors.name.translations && errors.name.translations[key]
                ? errors.name.translations[key]
                : undefined
            }
            value={values.name.translations[key]}
            onChange={e => setFieldValue(`name.translations[${key}]`, e.target.value)}
            placeholder={t("pages.addBundle.bundleTypeNamePlaceholder", { language: key.toUpperCase() })}
          />
        ),
      });
    }
    rows.push(
      {
        label: <FormLabel required={true}>{t("pages.addBundle.description")}</FormLabel>,
        displayComponent: null,
        editingComponent: (
          <TextInput
            maxLength={100}
            name="description"
            value={values.description}
            onChange={handleChange}
            required={true}
            error={errors.description}
            placeholder={t("pages.addBundle.descriptionPlaceholder")}
          />
        ),
      },
      {
        label: t("pages.addBundle.provider"),
        displayComponent: null,
        editingComponent: (
          <>
            <div id={"providers"}>
              <AutoClosingDropdown
                options={getProviders()}
                dropdownType={DropdownType.RECTANGULAR_NORMAL}
                selectOption={opt => setFieldValue("provider", opt.key)}
                label={
                  values.provider ? t(`pages.addBundle.${values.provider}`) : t("pages.addBundle.providerPlaceholder")
                }
                hasValue={!!values.provider}
              />
            </div>
          </>
        ),
      },
    );
    if (values.provider === BundleTypeProvider.MPESA && config!.bundles?.useUpsell) {
      rows.push({
        label: t("pages.addBundle.promotions"),
        displayComponent: null,
        editingComponent: (
          <>
            <CheckboxContainer id={"upsell-promo"}>
              <Checkbox
                name={"upsellPromo"}
                data-testid={"upsell-checkbox"}
                setFieldValue={setFieldValue}
                value={values.upsellPromo}
              />
              <Label>{t("pages.addBundle.allowUpsellPromo")}</Label>
            </CheckboxContainer>
          </>
        ),
      });
    }
    rows.push({
      label: t("pages.addBundle.buyForFriend"),
      displayComponent: null,
      editingComponent: (
        <CheckboxContainer id="buy-for-friend">
          <Checkbox
            data-testid={"buy-for-friend-checkbox"}
            name={"buyForFriend"}
            setFieldValue={setFieldValue}
            value={values.buyForFriend}
          />
          <Label>{t("pages.addBundle.allowBuyForFriend")}</Label>
        </CheckboxContainer>
      ),
    });
    return <FormSection title={t("pages.addBundle.bundleTypeDetails")} rows={rows} isEditing={true} />;
  };
  return (
    <PageContainer>
      <SecondaryPageTitle
        displayInitialsCircle={false}
        title={t("pages.addBundle.title")}
        goBackFn={() => window.history.back()}
      />
      {formValues ? (
        <Formik
          initialValues={formValues}
          validateOnBlur={true}
          validateOnChange={false}
          onSubmit={submitWrapper}
          validationSchema={addBundleValidationSchema(t, availableLanguages || [], setShowWarning)}
          render={({ values, handleChange, isSubmitting, handleSubmit, setFieldValue, errors }) => (
            <AddBundleContainer>
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                {getFormInputs(values, handleChange, setFieldValue, errors)}
                <div
                  style={{
                    width: "max-content",
                    marginLeft: "auto",
                    marginTop: 36,
                  }}
                >
                  <PrimaryButton
                    id={"submit-button"}
                    redTheme={true}
                    titleLabel={t("pages.addBundle.saveBtn")}
                    type="submit"
                    loading={isSubmitting}
                  />
                </div>
              </form>
            </AddBundleContainer>
          )}
        />
      ) : null}
    </PageContainer>
  );
};

export default AddBundlePage;

const AddBundleContainer = styled(PageContent)`
  @media (min-width: 1366px) {
    margin-left: 204px;
    margin-right: 204px;
  }
`;

const CheckboxContainer = styled("div")`
  display: flex;
`;

const Label = styled("div")`
  font-family: Vodafone Rg;
  margin-left: 8px;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: ${props => props.theme.palette.midGrey};
`;
