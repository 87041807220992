import React from "react";
import styled from "styled-components";
import { AppPlacement } from "../announcements.model";

interface IMobileSchemaInterface {
  children: JSX.Element;
  appPlacement?: AppPlacement;
}

/**
 * Component that will build the mobile frame and add child to frame
 * @param param0 interface that will be receive a child element
 */
const MobileSchema = ({ children, appPlacement }: IMobileSchemaInterface) => {
  return (
    <MobileFrame appPlacement={appPlacement}>
      <SpeakerFrame />
      <ScreenFrame appPlacement={appPlacement}>{children}</ScreenFrame>
    </MobileFrame>
  );
};

const MobileFrame = styled("div")<{
  appPlacement: AppPlacement | undefined;
}>`
  background-color: #fafafa;
  border-radius: 35px;
  width: ${props => (props.appPlacement && props.appPlacement === AppPlacement.HOMESCREEN ? "299px" : "282px")};
  height: ${props => (props.appPlacement && props.appPlacement === AppPlacement.HOMESCREEN ? "633px" : "520px")};
  position: relative;
  top: 0;
  margin: auto;
`;

const SpeakerFrame = styled("div")`
  width: 44px;
  height: 6px;
  border-radius: 3.2px;
  background-color: #d8d8d8;
  margin: auto !important;
  position: relative;
  top: 1.9vh;
`;
const ScreenFrame = styled("div")<{
  appPlacement: AppPlacement | undefined;
}>`
  width: ${props => (props.appPlacement && props.appPlacement === AppPlacement.HOMESCREEN ? "272px" : "252px")};
  height: ${props => (props.appPlacement && props.appPlacement === AppPlacement.HOMESCREEN ? "572px" : "448px")};
  margin: auto;
  position: relative;
  top: 3.4vh;
`;

export default MobileSchema;
