import React from "react";
import { useTranslation } from "react-i18next";
import {
  IReferralCampaign,
  IReward,
  IScreen,
  ReferralCampaignStatus,
  SenderTypeRewardEnum,
  TargetEnum,
  TypeScreenEnum,
  Screen,
} from "../../referral-campaigns.model";
import { Formik, FormikHelpers } from "formik";
import styled from "styled-components";
import SenderScreenForm from "../../components/sender-screen-form.component";
import SenderScreenPreview from "../../components/sender-screen-preview.component";
import { Row } from "../../../../../shared/shared.styled";
import QuickActionsMenu from "./quick-actions-menu.component";
import { validateSchemaSenderScreen, validateTranslationsSenderScreen } from "../../referral-campaigns.utils";
import GenericLanguageSelector from "../../../../../shared/components/generic-language-selector.component";
import { ILanguage } from "../../../../../shared/models/language.model";
import useEmptyLanguageWarningModal from "../../../../../shared/hooks/use-empty-language-warning-modal.hook";

interface IEditSenderScreenProps {
  languages: ILanguage[];
  selectedLanguage: string;
  setSelectedLanguage: React.Dispatch<React.SetStateAction<string>>;
  referralCampaign: IReferralCampaign;
  onSubmitFn: (values: IReferralCampaign) => void;
  onCancelCampaignFn: () => void;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * EditSenderScreen component
 */
const EditSenderScreen = ({
  languages,
  selectedLanguage,
  setSelectedLanguage,
  referralCampaign,
  onSubmitFn,
  onCancelCampaignFn,
  isEditing,
  setIsEditing,
}: IEditSenderScreenProps) => {
  const [t] = useTranslation();
  const [tab, setTab] = React.useState(0);

  const { warningModalOpen, showWarning, setShowWarning } = useEmptyLanguageWarningModal(languages || []);
  /**
   * Submit method wrapper to show warning if needed
   * @param values
   */
  const submitWrapper = (values: IReferralCampaign) => {
    if (showWarning) {
      setShowWarning(false);
      warningModalOpen(handleSubmitSenderScreen, values);
    } else {
      handleSubmitSenderScreen(values);
    }
  };

  /**
   * get initial values
   * this ones could be different depending on last form
   */
  const getInitialValues = () => {
    //if screen were not initialized yet
    if (!referralCampaign.screens) {
      const screens =
        referralCampaign.rewards &&
        referralCampaign.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) &&
        (referralCampaign.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) as IReward).type ===
          SenderTypeRewardEnum.NONE
          ? [new Screen(TypeScreenEnum.SENDER_INITIAL, languages)]
          : [new Screen(TypeScreenEnum.SENDER_INITIAL, languages), new Screen(TypeScreenEnum.SENDER_FINAL, languages)];

      referralCampaign.screens = screens;
    } else {
      //if the user change backwards and change the sender reward to NONE
      //this value has to be removed
      const receiverScreen = {
        ...(referralCampaign.screens.find(screen => screen.type === TypeScreenEnum.RECEIVER) as IScreen),
      };

      if (
        (referralCampaign.rewards &&
          referralCampaign.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) &&
          (referralCampaign.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) as IReward).type ===
            SenderTypeRewardEnum.NONE) ||
        ((referralCampaign.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) as IReward).type ===
          SenderTypeRewardEnum.SINGLE &&
          (referralCampaign.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) as IReward)
            .maxInvites === -1)
      ) {
        referralCampaign.screens = [
          referralCampaign.screens.find(screen => screen.type === TypeScreenEnum.SENDER_INITIAL) as IScreen,
        ];
      } else if (!referralCampaign.screens.find(screen => screen.type === TypeScreenEnum.SENDER_FINAL)) {
        referralCampaign.screens = [
          referralCampaign.screens.find(screen => screen.type === TypeScreenEnum.SENDER_INITIAL) as IScreen,
          new Screen(TypeScreenEnum.SENDER_FINAL, languages),
        ];
      }
      if (
        receiverScreen.type &&
        !(referralCampaign.screens.find(screen => screen.type === TypeScreenEnum.RECEIVER) as IScreen)
      ) {
        referralCampaign.screens = referralCampaign.screens.concat(receiverScreen);
      }
    }
    return referralCampaign;
  };

  /**
   * Performs the create request
   * @param {IReferralCampaign} values
   * @param {FormikHelpers<IReferralCampaign>} actions
   */
  const handleSubmitSenderScreen = (values: IReferralCampaign) => {
    onSubmitFn(values);
    setIsEditing(false);
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={submitWrapper}
      validate={values => validateSchemaSenderScreen(values, languages, setShowWarning)}
      render={({ values, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue, errors, resetForm }) => (
        <form onSubmit={handleSubmit}>
          <SenderScreenContainer>
            <FormContainer>
              <SenderScreenForm
                firstTitle={
                  <TitlesContainer>
                    <Row>
                      <div style={{ marginTop: "auto", marginLeft: "auto" }}>
                        <GenericLanguageSelector
                          selectedLanguage={selectedLanguage}
                          availableLanguages={languages.map(lang => lang.code)}
                          changeSelectedLanguage={(lang: any) => setSelectedLanguage(lang.key)}
                        ></GenericLanguageSelector>
                      </div>
                    </Row>
                    <FormSubTitle>
                      {t("pages.referralCampaigns.createCampaign.senderScreen.initialScreen")}
                    </FormSubTitle>
                  </TitlesContainer>
                }
                secondTitle={
                  <FormSubTitle>{t("pages.referralCampaigns.createCampaign.senderScreen.finalScreen")}</FormSubTitle>
                }
                isEditing={isEditing}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                language={selectedLanguage}
                setTab={setTab}
                isLanguageRequired={languages.find(l => l.code === selectedLanguage)?.mandatory}
              ></SenderScreenForm>
            </FormContainer>
            <SenderScreenPreviewContainer>
              <SenderScreenPreview
                values={values}
                language={selectedLanguage}
                tab={tab}
                setTab={setTab}
              ></SenderScreenPreview>
            </SenderScreenPreviewContainer>
            {referralCampaign.status && referralCampaign.status !== ReferralCampaignStatus.DISABLED ? (
              <QuickActionsMenu
                canEdit={true}
                isEditing={isEditing}
                doneFn={() => {
                  handleSubmit();
                  validateTranslationsSenderScreen(values, languages, setSelectedLanguage, setShowWarning);
                }}
                cancelFn={() => {
                  resetForm();
                  setIsEditing(false);
                }}
                canCancel={referralCampaign.status !== ReferralCampaignStatus.SCHEDULED}
                editCampaignFn={() => setIsEditing(true)}
                cancelCampaignFn={onCancelCampaignFn}
              ></QuickActionsMenu>
            ) : (
              <EmptySpace />
            )}
          </SenderScreenContainer>
        </form>
      )}
    />
  );
};

export default EditSenderScreen;

const FormSubTitle = styled("span")`
  font-family: "Vodafone Rg";
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.darkGrey};
`;

const SenderScreenContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  > div > div:first-child {
    > div {
      justify-content: flex-end;
      > span {
        width: 100%;
      }
    }
  }
`;

const FormContainer = styled("div")`
  flex: 2;
`;

const SenderScreenPreviewContainer = styled("div")`
  flex: 1;
  margin-left: 30px;
`;

const TitlesContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;

const EmptySpace = styled("div")`
  flex: 1;
`;
