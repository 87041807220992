import React, { useEffect, useState } from "react";
import { PageContainer } from "../../../shared/shared.styled";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { MultipleOptionsDropdown, PrimaryButton, SearchBar, Table } from "@wit/mpesa-ui-components";
import { ColumnProps } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import moment from "moment";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { useHistory } from "react-router-dom";
import TablePagination from "../../../shared/components/table-pagination/table-pagination-component";
import { TOPICS_DATA } from "./mock-topics-data";
import TopicsIcon from "../../../shared/icons/Topics.icon";

enum AppsEnum {
  CONSUMER = "CONSUMER",
  BUSINESS = "BUSINESS",
}

const APPS = [
  {
    key: AppsEnum.CONSUMER,
    label: "consumer",
  },
  {
    key: AppsEnum.BUSINESS,
    label: "business",
  },
];

/** */
const TopicsListPage = () => {
  const [t] = useTranslation();
  const history = useHistory();

  const [searchString, setSearchString] = useState("");
  const [appsSelected, setAppSelected] = useState<string[]>([]);
  const [filteredTopics, setFilteredTopics] = useState([...TOPICS_DATA]);
  const [activePage, setActivePage] = useState(1);
  const [activePageContent, setActivePageContent] = useState([...TOPICS_DATA]);
  const [requestParams, setRequestParams] = useState({ page: 1, pageSize: 10 }); // TODO: use when integrate with the BE
  const [totalTopicsNumber, setTotalTopicsNumber] = useState(TOPICS_DATA.length);
  const pageSize = 10;

  function isOptionSelected(opt: SharedDropdownOption) {
    return appsSelected.includes(opt.key);
  }

  function toggleOption(opt: SharedDropdownOption) {
    if (isOptionSelected(opt)) {
      setAppSelected(appsSelected.filter(c => c !== opt.key));
    } else {
      setAppSelected([...appsSelected, opt.key]);
    }
  }

  function resetDropdown() {
    setAppSelected([]);
  }

  /** */
  const mapAppValueToLabel = (value: string): string => {
    if (value === AppsEnum.CONSUMER) {
      return "Consumer app";
    }
    if (value === AppsEnum.BUSINESS) {
      return "Business app";
    }
    return value;
  };

  /** */
  const getTableColumns = (): ColumnProps[] => {
    return [
      {
        formKey: "topicName",
        ratio: 5 / 12,
        isEditable: false,
        label: t("pages.topics.listPage.topicName"),
      },
      {
        formKey: "topicDescription",
        ratio: 5 / 12,
        isEditable: false,
        label: t("pages.topics.listPage.topicDescription"),
      },
      {
        formKey: "createdOn",
        ratio: 5 / 12,
        isEditable: false,
        label: t("pages.topics.listPage.createdOn"),
        changeFunction: content => <span>{moment(content).format("DD/MM/YYYY")}</span>,
      },
      {
        formKey: "app",
        ratio: 5 / 12,
        isEditable: false,
        label: t("pages.topics.listPage.app"),
        changeFunction: content => mapAppValueToLabel(content),
      },
    ];
  };

  useEffect(() => {
    const sliceEdge = (activePage - 1) * pageSize;
    setActivePageContent(TOPICS_DATA.slice(sliceEdge + 1, sliceEdge + 10));
  }, [activePage]);

  useEffect(() => {
    if (TOPICS_DATA) {
      let filteredTopics = [...activePageContent];

      if (searchString) {
        filteredTopics = filteredTopics.filter(
          es =>
            es.topicName.toLowerCase().includes(searchString.toLowerCase()) ||
            es.topicDescription.includes(searchString.toLowerCase()),
        );
      }
      if (appsSelected.length > 0) {
        if (appsSelected.includes(AppsEnum.CONSUMER)) {
          filteredTopics = filteredTopics.filter(es => es.app !== undefined && es.app.includes(AppsEnum.CONSUMER));
        }

        if (appsSelected.includes(AppsEnum.BUSINESS)) {
          filteredTopics = filteredTopics.filter(es => es.app !== undefined && es.app.includes(AppsEnum.BUSINESS));
        }
      }
      setFilteredTopics(filteredTopics);
    } else {
      setFilteredTopics([]);
    }
  }, [TOPICS_DATA, searchString, appsSelected, activePageContent]);

  /**
   * Handles the pagination change
   * @param pageNumber
   */
  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
  };

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>{t("pages.topics.listPage.title")}</PageTitle>
        <IconWrapper>
          <TopicsIcon />
        </IconWrapper>
        <ButtonWrapper>
          <PrimaryButton
            titleLabel={t("pages.topics.createPage.title")}
            redTheme
            onClick={() => history.push("topics/add")}
          />
        </ButtonWrapper>
      </PageHeader>

      <FiltersContainer>
        <SearchBarWrapper>
          <SearchBar
            placeholderLabel={t("pages.externalServices.filters.placeholderSearch")}
            value={searchString}
            onChange={e => setSearchString(e.target.value)}
            clearValue={() => setSearchString("")}
          />
        </SearchBarWrapper>

        <DropdownWrapper>
          <MultipleOptionsDropdown
            label={t("pages.topics.listPage.app")}
            toggleOption={opt => toggleOption(opt)}
            clearAllFilters={() => resetDropdown()}
            hasValue={false}
            isOptionSelected={opt => isOptionSelected(opt)}
            options={APPS}
          />
        </DropdownWrapper>
      </FiltersContainer>
      <TableWrapper className="tableWrapper">
        <Table
          values={filteredTopics}
          columns={getTableColumns()}
          rowClickAction={() => console.log("click - route to topic details")}
        />
        {totalTopicsNumber > pageSize ? (
          <TablePagination
            handlePageChange={handlePageChange}
            totalItems={totalTopicsNumber}
            activePage={activePage}
            pageSize={pageSize}
          />
        ) : null}
      </TableWrapper>
    </PageContainer>
  );
};

export default TopicsListPage;

export const PageHeader = styled("h1")`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-right: 7%;
`;

export const PageTitle = styled("h1")`
  font-family: Vodafone Lt;
  font-size: 42px;
  color: #333;
  margin: 0;
  font-weight: normal;
`;

const ButtonWrapper = styled("div")``;

const TableWrapper = styled.div`
  > div:first-child {
    > div:last-child {
      > div {
        padding: 0;
        border-bottom: 1px solid;
        border-bottom-color: ${props => props.theme.palette.lightGrey};
      }
    }
  }
`;

export const FiltersContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 32px 0;
  flex-wrap: wrap;
  > div {
    margin-top: 4px;
  }
`;

const SearchBarWrapper = styled.div`
  width: 35%;
  min-width: 220px;
  margin-right: 30px;
`;

const DropdownWrapper = styled.div`
  width: 20%;
  min-width: 100px;
`;

const IconWrapper = styled.div`
  > svg {
    /* color: white; */
  }
`;
