import { AxiosPromise } from "axios";
import ApiClient from "../../../configs/axios.config";

/*
 * Shortcuts API methods
 * */
const ShortcutsApi = {
  routes: {
    getShortcuts: "v1/actionitems",
    getLastVersion: "v1/actionitems?previousVersion=true",
    sortShortcuts: "/v1/actionitems/reorder",
    revertChanges: "/v1/actionitems/revert",
    publish: "/v1/actionitems/publish",
    submitForApproval: "/v1/actionitems/submitforapproval",
  },
  methods: {
    getShortcuts: (): AxiosPromise => {
      return ApiClient.get(ShortcutsApi.routes.getShortcuts, {});
    },
    revertChanges: (): AxiosPromise => {
      return ApiClient.put(ShortcutsApi.routes.revertChanges, {});
    },
    sortShortcuts: (ids: string[]): AxiosPromise => {
      return ApiClient.put(ShortcutsApi.routes.sortShortcuts, ids);
    },
    getShortcut: (id: any): AxiosPromise => {
      return ApiClient.get(`${ShortcutsApi.routes.getShortcuts}/${id}`, {});
    },
    editShortcut: (card: any, id: any): AxiosPromise => {
      return ApiClient.put(`${ShortcutsApi.routes.getShortcuts}/${id}`, card);
    },
    getLastVersion: (): AxiosPromise => {
      return ApiClient.get(ShortcutsApi.routes.getLastVersion, {});
    },
    publishShortcuts: (): AxiosPromise => {
      return ApiClient.put(ShortcutsApi.routes.publish, {});
    },
    submitShortcuts: (): AxiosPromise => {
      return ApiClient.put(ShortcutsApi.routes.submitForApproval, {});
    },
    restoreShortcut: (id: string): AxiosPromise => {
      return ApiClient.put(`${ShortcutsApi.routes.getShortcuts}/${id}/restore`, {});
    },
  },
};

export default ShortcutsApi;
