import { FormSection, PageTitle, PrimaryButton, TextInput, useAlert } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { IStoreInterface } from "../../../../configs/store.config";
import { RoutesEnum } from "../../../../routes/routes.constants";
import { PageContent } from "../../../../shared/responsive-ui.styled";
import { LoadingText, PageContainer } from "../../../../shared/shared.styled";
import AllowedScopesApi from "../allowed-scopes.api";
import { IAllowedScope, IIconAllowedScope } from "../allowed-scopes.model";
import { AntServiceManagerActions } from "../../ant-service-manager.store";
import { Formik, FormikErrors, FormikProps, yupToFormErrors } from "formik";
import IconPicker from "../../../../shared/components/icon-picker.component";
import LanguagesApi from "../../../../shared/services/languages.api";
import { ILanguage } from "../../../../shared/models/language.model";
import { object, string } from "yup";

/**
 * EditAllowedScopePage component
 */
const EditAllowedScopePage = () => {
  const { allowedScopeId } = useParams<any>();
  const formRef = useRef<FormikProps<IAllowedScope>>(null);
  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showAlert, , setAlertProps] = useAlert();
  const [availableLanguages, setAvailableLanguages] = useState<ILanguage[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [hasFormChanged, setHasFormChanged] = useState<boolean>(false);
  const [isLoadingIcons, setIsLoadingIcons] = React.useState(false);
  const { allowedScope, allowedScopesIcons } = useSelector((state: IStoreInterface) => state.antServiceManagerReducer);
  const [isEditing] = React.useState(true);

  /**
   * Get list of available icons
   */
  const getIcons = () => {
    AllowedScopesApi.methods.getAllowedScopesIcons().then(res => {
      dispatch(AntServiceManagerActions.creators.fetchAllowedScopesIcons(res.data));
      setIsLoadingIcons(false);
    });
  };

  useEffect(() => {
    LanguagesApi.methods.getAvailableLanguages().then(
      res => {
        setAvailableLanguages(res.data.availableLanguages);
      },
      () => {
        setAlertProps({
          type: AlertTypeEnum.ERROR,
          title: t("commons.getLanguagesError"),
        });
        showAlert();
        setAvailableLanguages([
          {
            name: t("commons.languages.en"),
            code: "en",
            id: "en",
            urlIcon: "",
          },
        ]);
      },
    );

    if (!allowedScopesIcons || !allowedScopesIcons.length) {
      getIcons();
    } else {
      setIsLoadingIcons(false);
    }

    if (!allowedScope || !Object.keys(allowedScope).length || allowedScope.id !== allowedScopeId) {
      getAllowedScope();
    } else {
      setIsDataLoading(false);
    }
  }, []);

  /**
   * Check form for submit validity
   * @param values
   */
  const checkFormSubmitButton = (values: IAllowedScope) => {
    if (JSON.stringify(allowedScope) === JSON.stringify(values)) {
      return setHasFormChanged(false);
    }

    return setHasFormChanged(true);
  };

  /**
   * Validator helper for name and description
   * @returns {{[p: string]: any}}
   */
  const getTranslationValidators = () => {
    let validators: { [x: string]: any } = {};
    availableLanguages.map(lang => {
      validators = {
        ...validators,
        [lang.code]: lang.mandatory
          ? string()
              .trim()
              .required(t("pages.antServiceManager.allowedScopes.form.requiredName"))
          : string().nullable(true),
      };
    });
    return validators;
  };

  /**
   * Form validation schema
   * @type {ObjectSchema<Shape<object, {eventId: string, category: string, name: object, section: string, description: object}>>}
   */
  const schema = object().shape({
    nameTranslations: object().shape(getTranslationValidators()),
  });

  /**
   * Fn to validate the form every stroke
   * @param values
   */
  const validateSchema = (values: any): Promise<FormikErrors<IAllowedScope>> => {
    return new Promise<FormikErrors<IAllowedScope>>(resolve => {
      schema
        .validate(values, {
          abortEarly: false,
        })
        .then(() => {
          resolve({});
        })
        .catch(y => {
          resolve(yupToFormErrors(y));
        });
    }).then(r => {
      return r;
    });
  };

  /**
   * method to get the allowed scopes list
   */
  const getAllowedScope = (): void => {
    setIsDataLoading(true);
    AllowedScopesApi.methods.getAllowedScope(allowedScopeId).then(
      res => {
        dispatch(AntServiceManagerActions.creators.setAllowedScope(res.data));
        setIsDataLoading(false);
      },
      () => {
        setAlertProps({
          title: t("pages.antServiceManager.allowedScopes.form.errorLoadingDetails"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * function go back
   */
  const goBack = () => {
    history.push(`${RoutesEnum.ANT_SERVICE_MANAGER}?tabIdx=3`);
  };

  /**
   * function on submit form
   */
  const onSubmitFn = (values: IAllowedScope) => {
    const { ...payload } = values;
    const iconBase64 = allowedScopesIcons.find((icon: IIconAllowedScope) => icon.id === payload.iconId)?.base64;
    const updatedPayload = { ...payload, iconBase64 };

    setIsLoading(true);
    AllowedScopesApi.methods
      .editAllowedScope(updatedPayload)
      .then(
        res => {
          dispatch(AntServiceManagerActions.creators.setAllowedScope(res.data));
          setAlertProps({
            title: t("pages.antServiceManager.allowedScopes.form.allowedScopeChanged"),
            type: AlertTypeEnum.SUCCESS,
          });
          setTimeout(() => goBack(), 50);
        },
        error => {
          setAlertProps({
            title: t("pages.antServiceManager.allowedScopes.form.editAllowedScopeError"),
            type: AlertTypeEnum.ERROR,
          });
        },
      )
      .finally(() => {
        showAlert();
        setIsLoading(false);
      });
  };

  return (
    <PageContainer>
      <PageTitle
        title={!isDataLoading ? `${allowedScope.nameTranslations["en"]}` : ""}
        goBackFn={() => goBack()}
        separatorStyle={{ display: "none" }}
        titleStyle={{
          fontSize: "42px",
        }}
        containerStyle={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          paddingLeft: "278px",
          paddingRight: "70px",
          paddingTop: "40px",
          paddingBottom: "40px",
          backgroundColor: styleTheme.palette.greyLight,
        }}
        rightComponent={
          <PrimaryButton
            id={"publish-changes"}
            disabled={isLoading || !hasFormChanged}
            titleLabel={t("pages.antServiceManager.allowedScopes.form.publishChanges")}
            type="submit"
            redTheme={true}
            style={{ width: "fit-content" }}
            onClick={() => formRef.current?.handleSubmit()}
          />
        }
      />

      <EditAllowedScopeContainer>
        {!isDataLoading ? (
          <Formik
            innerRef={formRef}
            initialValues={allowedScope}
            onSubmit={onSubmitFn}
            validateOnChange={true}
            validateOnBlur={true}
            validate={validateSchema}
            render={({ values, handleChange, setFieldValue, handleSubmit, touched, errors, resetForm }) => (
              <form
                id="allowed-scope-form"
                onKeyUp={e => {
                  checkFormSubmitButton(values);
                  handleChange(e);
                }}
                onSubmit={handleSubmit}
                onReset={() => resetForm()}
                style={{ width: "60%" }}
              >
                <FormContainer>
                  <FormSection
                    isEditing={isEditing}
                    title={t("pages.antServiceManager.allowedScopes.form.ssoDetails")}
                    rows={[
                      {
                        label: (
                          <FormLabel id="allowed-scope-label" required={false}>
                            {t("pages.antServiceManager.allowedScopes.form.scope")}
                          </FormLabel>
                        ),
                        displayComponent: <NonEditableText id="allowed-scope-value">{values.scope}</NonEditableText>,
                        editingComponent: <NonEditableText id="allowed-scope-value">{values.scope}</NonEditableText>,
                      },
                      {
                        label: (
                          <FormLabel id="allowed-scope-icon" required={isEditing}>
                            {t("pages.antServiceManager.allowedScopes.form.icon")}
                          </FormLabel>
                        ),
                        displayComponent: "Icon",
                        editingComponent: (
                          <IconPickerContainer>
                            <IconPicker
                              icons={allowedScopesIcons}
                              icon={
                                allowedScopesIcons && allowedScopesIcons.find((icon: any) => icon.id === values.iconId)
                                  ? allowedScopesIcons.find((icon: any) => icon.id === values.iconId)
                                  : undefined
                              }
                              name="iconId"
                              setIconID={(field, value) => {
                                setFieldValue(field, value);
                                checkFormSubmitButton({ ...values, [field]: value });
                              }}
                              error={errors.iconId && touched.iconId ? errors.iconId : undefined}
                              isLoading={isLoadingIcons}
                            />
                          </IconPickerContainer>
                        ),
                        required: true,
                      },
                    ]}
                  />
                  <NameContainer>
                    <FormSection
                      isEditing={isEditing}
                      title={t("pages.antServiceManager.allowedScopes.form.friendlyName")}
                      rows={
                        availableLanguages
                          ? availableLanguages.map((lang: ILanguage, idx) => {
                              return {
                                label: (
                                  <FormLabel id="allowed-scope-name-label" required={lang.mandatory}>
                                    {lang.name}
                                  </FormLabel>
                                ),
                                displayComponent: (
                                  <NonEditableText id="allowed-scope-name-value">
                                    {values.nameTranslations[lang.code]}
                                  </NonEditableText>
                                ),
                                editingComponent: (
                                  <TextInput
                                    id={`allowed-scope-name-${lang.code}-input`}
                                    name={`nameTranslations[${lang.code}]`}
                                    required={lang.mandatory}
                                    value={values.nameTranslations[lang.code] || ""}
                                    onChange={handleChange}
                                    error={
                                      errors.nameTranslations &&
                                      errors.nameTranslations[lang.code] &&
                                      touched.nameTranslations &&
                                      touched.nameTranslations[lang.code]
                                        ? errors.nameTranslations[lang.code]
                                        : undefined
                                    }
                                    maxLength={100}
                                  />
                                ),
                              };
                            })
                          : []
                      }
                    />
                  </NameContainer>
                </FormContainer>
              </form>
            )}
          />
        ) : (
          <LoadingText>{t("commons.loadingDetails")}</LoadingText>
        )}
      </EditAllowedScopeContainer>
    </PageContainer>
  );
};

export default EditAllowedScopePage;

const FormContainer = styled("div")`
  width: 100%;
`;

const EditAllowedScopeContainer = styled(PageContent)`
  margin-top: 140px;
  @media (min-width: 1366px) {
    margin-left: 204px;
    margin-right: 204px;
  }
`;

const FormLabel = styled("span")<{ required?: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.greyDarker};

  ${props =>
    props.required
      ? `
  ::after {
    content: ' *';
    font-family: Vodafone Rg;
    font-size: 16px;
    font-weight: normal;
    color: ${props.theme.palette.errorRed};
  }`
      : ""}
`;

const NonEditableText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.darkGrey};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IconPickerContainer = styled("div")`
  > button {
    > div {
      width: -webkit-fill-available;
    }
  }
`;

const NameContainer = styled("div")`
  margin-top: 40px;
`;
