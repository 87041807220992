import { ChevronDownIcon, ModalTypeEnum, useModal } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { IStoreInterface } from "../../../../configs/store.config";
import { ICategory } from "../../../../shared/models/category.model";

import { fadeInKeyframe, IconContainer, RatioContainer } from "../../../../shared/shared.styled";
import { renderStatus, renderTableBusinessButtons } from "../business-utils";
import { BusinessStatusEnum } from "../business.store";

interface ICategoryGroupProps {
  groupItem: {
    groupCategory: any;
    children: any[];
  };
  language: {
    label: string;
    key: string;
  };
  onDelete: (cat: ICategory) => void;
  onEdit: (cat: ICategory) => void;
  onRestore: (cat: ICategory) => void;
}

/**
 * category group
 */
const CategoryGroup = ({ groupItem, onDelete, onEdit, onRestore, language }: ICategoryGroupProps) => {
  const { groupCategory, children } = groupItem;

  const [open, setOpen] = useState(false);

  const [t] = useTranslation();

  const [showModal, hideModal, setProps] = useModal(ModalTypeEnum.ConfirmationModal);
  const { categories } = useSelector((state: IStoreInterface) => state.businessesReducer);

  /**
   * Get icon name
   */
  const getIconName = (name: string) => {
    return name?.toLowerCase().replace(/ /g, "_");
  };

  /**
   * Delete a category
   */
  const deleteCategory = (cat: ICategory) => {
    setProps({
      title: t("pages.categories.warnings.removeParent.title"),
      description: t("pages.categories.warnings.removeParent.description"),
      primaryAction: () => {
        onDelete(cat);
        hideModal();
      },
      primaryBtnLabel: t("pages.categories.warnings.removeParent.saveChangesBtnLabel"),
      secondaryAction: hideModal,
      secondaryBtnLabel: t("pages.categories.warnings.removeParent.cancelBtnLabel"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
    });
    showModal();
  };

  /**
   * Edit category group
   */
  const editCategoryGroup = (cat: ICategory) => {
    onEdit(cat);
  };

  /**
   * Get disabled category
   */
  const getDisabled = (status: string) => {
    return (
      status === BusinessStatusEnum.DELETE.toString() ||
      status === BusinessStatusEnum.DISABLE.toString() ||
      status === BusinessStatusEnum.DISABLED.toString()
    );
  };

  /**
   * find last version
   */
  const findLastVersion = (id: string | undefined) => {
    if (!id) {
      return undefined;
    }

    return categories.lastVersion.find(b => b.id === id) || null;
  };

  /**
   * Category item
   */
  const CategoryItem = ({ item }: any) => {
    return (
      <Item id={`category-${item.name[language.key]}`}>
        <GroupText ratio={0.5 / 12} disabled={getDisabled(groupCategory.status)}>
          <img
            src={`./images/categoryIcons/${getIconName(item.urlIcon)}`}
            onError={e => {
              const img = e.target as HTMLImageElement;
              if (!img.src.includes("organization.png")) {
                img.src = "./images/organization.png";
              }
            }}
            alt={item.urlIcon}
          />
        </GroupText>
        <GroupText disabled={getDisabled(groupCategory.status)} ratio={6.5 / 12}>
          {item.name[language.key] || "n.a."}
        </GroupText>
        <GroupText ratio={5 / 12} className="status-container">
          {item.status === "DELETE" ? (
            <DeleteText> {t("pages.configurations.businessTab.deleted")}</DeleteText>
          ) : (
            <StatusWrapper disabled={getDisabled(groupCategory.status)} className="status-wrapper">
              {renderStatus(item.status, t)}
            </StatusWrapper>
          )}

          <div className="action-buttons">
            {renderTableBusinessButtons<ICategory>(item, {
              edit: { onClick: onEdit },
              delete: { onClick: onDelete },
              revert: findLastVersion(item.id) ? { onClick: onRestore } : undefined,
            })}
          </div>
        </GroupText>
      </Item>
    );
  };

  return (
    <GroupContainer>
      <GroupInfo id={`category-${groupCategory.name[language.key]}`}>
        <GroupTitle ratio={0.5 / 12} disabled={getDisabled(groupCategory.status)}>
          <Logo
            src={`./images/categoryIcons/${getIconName(groupCategory.urlIcon)}`}
            onError={e => {
              const img = e.target as HTMLImageElement;
              if (!img.src.includes("organization.png")) {
                img.src = "./images/organization.png";
              }
            }}
            alt={groupCategory.urlIcon}
          />
        </GroupTitle>
        <GroupTitle ratio={6.5 / 12} disabled={getDisabled(groupCategory.status)}>
          {groupCategory.name[language.key] || "n.a."}
        </GroupTitle>
        <GroupText ratio={2 / 12} className="size-container" disabled={getDisabled(groupCategory.status)}>
          {children.length || ""}
        </GroupText>
        <GroupText ratio={3 / 12} style={{ display: "flex", justifyContent: "flex-end" }}>
          {groupCategory.status === "DELETE" ? (
            <DeleteText> {t("pages.configurations.businessTab.deleted")}</DeleteText>
          ) : (
            <StatusWrapper disabled={getDisabled(groupCategory.status)}>
              {renderStatus(groupCategory.status, t)}
            </StatusWrapper>
          )}
          {groupCategory.name.en !== "Others" && (
            <div className="action-buttons-group">
              {renderTableBusinessButtons<ICategory>(groupCategory, {
                edit: { onClick: editCategoryGroup },
                delete: { onClick: deleteCategory },
                revert: findLastVersion(groupCategory.id) ? { onClick: onRestore } : undefined,
              })}
            </div>
          )}

          <ToogleContainer onClick={() => setOpen(!open)}>
            {!open && (
              <IconContainer color={children.length ? styleTheme.palette.midGrey : "transparent"} size={24}>
                <ChevronDownIcon />
              </IconContainer>
            )}
            {open && (
              <InvertIconContainer color={children.length ? styleTheme.palette.midGrey : "transparent"} size={24}>
                <ChevronDownIcon />
              </InvertIconContainer>
            )}
          </ToogleContainer>
        </GroupText>
      </GroupInfo>
      {open && children.length ? children.map((item: any) => <CategoryItem key={item.id} item={item} />) : <></>}
    </GroupContainer>
  );
};

export default CategoryGroup;

const GroupContainer = styled.div<{ disabled?: boolean }>`
  border-radius: 8px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  :hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);

    .status-container {
      display: none;
    }

    .toggable-container {
      display: flex;
      justify-content: flex-end;
      animation: ${fadeInKeyframe} 0.25s linear;

      button:first-child {
        margin-right: 12px;
      }
    }
  }
`;

const GroupInfo = styled("li")<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  height: 75px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};

  margin-top: 24px;
  padding: 0 35px 0 24px;

  .status-container {
    display: flex;
    justify-content: flex-end;
  }

  .toggable-container {
    display: none;
  }

  .action-buttons-group {
    display: none;
    position: relative;
    left: 5px;
  }
  :hover {
    .action-buttons-group {
      display: flex;
    }
  }
`;

const GroupTitle = styled(RatioContainer)<{ disabled?: boolean }>`
  font-family: Vodafone Rg;
  font-size: 22px;
  font-weight: bold;
  color: ${props => props.theme.palette.darkGrey};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`;

const GroupText = styled(RatioContainer)<{ disabled?: boolean }>`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
  opacity: ${props => (props.disabled ? 0.5 : 1)};

  &&.size-container {
    font-size: 16px;
    font-weight: bold;
    color: #00b0ca;
  }

  &&.status-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 48px;
  }
`;

const Item = styled.div<{ disabled?: boolean }>`
  width: 100%;
  height: 44px;
  border-bottom: 1px solid #ececec;
  background-color: #fafafa;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding: 0 30px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  .action-buttons {
    display: none;
    position: relative;
    left: 5px;
  }
  :hover {
    .action-buttons {
      display: flex;
      position: relative;
    }
  }
`;

const ToogleContainer = styled.div`
  margin-left: 20px;
  cursor: pointer;
`;

const InvertIconContainer = styled(IconContainer)`
  transform: rotate(180deg);
`;

const Logo = styled.img`
  height: 24px;
  padding-left: 16px;
  border-left: 3px solid transparent;
`;

const DeleteText = styled.span`
  color: ${props => props.theme.palette.digitalRed};
`;

const StatusWrapper = styled.div<{ disabled?: boolean }>`
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`;
