import React from "react";
import moment from "moment";
import { ColumnProps } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import i18next from "i18next";
import { IClientDocBulk } from "./doc-bulk.model";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";

export const DocBulkUtils = {
  getBulkStatusColor: (status: string): string => {
    switch (status) {
      case "PENDING_SUBMISSION":
        return styleTheme.palette.aubergine;
      case "SUBMITTED_ON_G2":
        return styleTheme.palette.turquoiseBlue;
      case "COMPLETE":
        return styleTheme.palette.digitalGreen;
      default:
        return styleTheme.palette.grey;
    }
  },

  getTableColumns: (): ColumnProps[] => {
    return [
      {
        ratio: 4 / 12,
        label: i18next.t("pages.docBulk.table.bulkName"),
        formKey: "bulkName",
        isEditable: false,
        sortable: true,
        changeFunction: content => {
          return !content ? "-" : <>{content}</>;
        },
      },
      {
        ratio: 3 / 12,
        label: i18next.t("pages.docBulk.table.createdOn"),
        formKey: "creationDate",
        isEditable: false,
        sortable: true,
        changeFunction: content => {
          if (!content) {
            return "-";
          }

          const requestDate = new Date(content);
          const requestDateStr = moment(requestDate).format("DD/MM/YYYY H:mm:ss");
          return <>{requestDateStr}</>;
        },
      },
      {
        ratio: 2 / 12,
        label: i18next.t("pages.docBulk.table.numRequests"),
        formKey: "numRequests",
        isEditable: false,
        sortable: true,
        changeFunction: content => {
          return content < 0 ? "-" : <>{content}</>;
        },
      },
      {
        ratio: 3 / 12,
        label: i18next.t("pages.docBulk.table.status"),
        formKey: "status",
        isEditable: false,
        sortable: true,
        changeFunction: (content, row: IClientDocBulk) => (
          <span style={{ color: DocBulkUtils.getBulkStatusColor(row.status) }}>
            {i18next.t(`commons.clientDocBulkStatus.${row.status}`)}
          </span>
        ),
      },
    ];
  },
};

export function onEnterPressed(event: React.KeyboardEvent<HTMLInputElement>, fn: { (): void; (): void }) {
  if (event.keyCode === 13) {
    fn();
  }
}
