import { PageTitle, TabChild, Tabs } from "@wit/mpesa-ui-components";
import { Breadcrumb } from "@wit/mpesa-ui-components/lib/types";
import { TFunction } from "i18next";
import React, { ReactNode, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { RoutesEnum } from "../../../routes/routes.constants";
import { PageContainer } from "../../../shared/shared.styled";
import BusinessIdentityConverter from "./components/business-identity-converter.component";
import CustomerIdentityConverter from "./components/customer-identity-converter.component";
import IdentityTableComponent from "./components/identity-table.component";
import { IIdentity } from "./identity-converter.model";

export enum IdentityConvertTabNumber {
  CUSTOMER = 0,
  BUSINESS = 1,
}

/**
 * Identity Converter Breadcrumbs
 * @param t
 * @returns
 */
const getBreadcrumbs = (t: TFunction): Breadcrumb[] => {
  return [
    {
      label: t("pages.tools.title"),
      url: RoutesEnum.TOOLS,
    },
    {
      label: t("pages.tools.identityConverter.title"),
      url: "",
    },
  ];
};

/**
 * Identity Converter page
 * @returns
 */
export const IdentityConverterPage = () => {
  const themeContext = useContext(ThemeContext);
  const [t] = useTranslation();
  const history = useHistory();
  const [tableData, setTableData] = React.useState<IIdentity[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [emptyTableComponent, setEmptyTableComponent] = useState<ReactNode>();
  const query = new URLSearchParams(useLocation().search);

  const [tabIndex, setTabIdx] = React.useState(query.get("tabIdx") || IdentityConvertTabNumber.CUSTOMER);

  return (
    <PageContainer>
      <PageTitle
        breadcrumbs={getBreadcrumbs(t)}
        title={t("pages.tools.identityConverter.title")}
        navigateFunction={history.push}
        goBackFn={() => window.history.back()}
        separatorStyle={{ display: "none" }}
        titleContainerStyle={{ marginTop: "13px" }}
        iconContainerStyle={{ stroke: themeContext.palette.vodafoneRed }}
      />

      <TabsContainer>
        <Tabs
          controlledSelectedTab={Number(tabIndex) || IdentityConvertTabNumber.CUSTOMER}
          controlledSetSelectedTab={setTabIdx}
        >
          <TabChild label={t("pages.tools.identityConverter.customer.title")}>
            <CustomerIdentityConverter
              setTableData={setTableData}
              setIsLoading={setIsLoading}
              setEmptyTableComponent={setEmptyTableComponent}
            />
          </TabChild>
          <TabChild label={t("pages.tools.identityConverter.business.title")}>
            <BusinessIdentityConverter
              setTableData={setTableData}
              setIsLoading={setIsLoading}
              setEmptyTableComponent={setEmptyTableComponent}
            />
          </TabChild>
        </Tabs>
      </TabsContainer>
      <IdentityTableComponent
        tableData={tableData}
        emptyStateComponent={emptyTableComponent}
        isLoading={isLoading}
      ></IdentityTableComponent>
    </PageContainer>
  );
};

const TabsContainer = styled("div")`
  margin-top: 14px;
`;
