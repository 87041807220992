import { ArrowDownIcon } from "@wit/mpesa-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import DoughnutChart, {
  IDoughnutChartDataProps,
  IDoughnutChartLegendProps,
} from "../../../../shared/components/doughnut-chart.component";
import { IPushNotificationCompletionProgress } from "../push-notifications.model";
import { getStatusColor } from "../push-notifications.utils";

interface IPushNotificationDrawerProps {
  hideDrawer: () => void;
  selectedPushNotification?: IPushNotificationCompletionProgress;
}

/**
 *
 * @param hideDrawer Function to close the drawer
 * @param selectedPushNotification Push Notifications selected to show the current status
 * @returns
 */
const PushNotificationDrawer = ({ hideDrawer, selectedPushNotification }: IPushNotificationDrawerProps) => {
  const [t] = useTranslation();

  //TODO: Change this with integration
  const chartInformation = [
    {
      label: "In progress",
      data: 5,
      backgroundColor: "#EB9700",
    },
    {
      weight: 1,
    },
    {
      label: "Failed",
      data: 2,
      backgroundColor: "#E60000",
    },
    {
      weight: 1,
    },
    {
      label: "Completed",
      data: 10,
      backgroundColor: "#A8B400",
    },
  ] as IDoughnutChartDataProps[];

  const chartLegend = [
    {
      label: "In progress",
      description: "10.000",
      descriptionColor: "#EB9700",
    },
    {
      label: "Failed",
      description: "500",
      descriptionColor: "#E60000",
      extraItemStyle: { marginTop: "8px" },
    },
    {
      label: "Completed",
      description: "1.000",
      descriptionColor: "#A8B400",
      extraItemStyle: { marginTop: "8px" },
    },
  ] as IDoughnutChartLegendProps[];

  return (
    <>
      <DrawerContainer>
        <TitleContainer>
          <CloseIconContainer onClick={() => hideDrawer()}>
            <ArrowDownIcon />
          </CloseIconContainer>
          <Title>
            {selectedPushNotification?.pushNotification.title
              ? selectedPushNotification?.pushNotification.title
              : t("pages.pushNotifications.campaignStatus.drawerTitle")}
          </Title>
        </TitleContainer>
        <Container>
          <Label>{t("pages.pushNotifications.campaignStatus.message")}</Label>
          <Value>
            {selectedPushNotification?.pushNotification.message
              ? selectedPushNotification?.pushNotification.message
              : "-"}
          </Value>
          <Label>{t("pages.pushNotifications.campaignStatus.users")}</Label>
          <Value>
            {selectedPushNotification?.pushNotification.users
              ? t(`pages.pushNotifications.userEnum.${selectedPushNotification?.pushNotification.users}`)
              : "-"}
          </Value>
          <Label>{t("pages.pushNotifications.campaignStatus.status")}</Label>
          <Value
            color={
              selectedPushNotification?.pushNotification.status
                ? getStatusColor(selectedPushNotification?.pushNotification.status)
                : ""
            }
          >
            {selectedPushNotification?.pushNotification.status
              ? t(`pages.pushNotifications.statusEnum.${selectedPushNotification?.pushNotification.status}`)
              : "-"}
          </Value>
          <Label style={{ marginTop: "12px" }}>{t("pages.pushNotifications.campaignStatus.completionTitle")}</Label>
          <DoughnutChart
            dataLabel={t("pages.pushNotifications.campaignStatus.dataLabel")}
            chartData={chartInformation}
            centerText={
              <>
                <p style={{ margin: 0, fontFamily: "Vodafone Rg", fontSize: 13 }}>
                  {t("pages.pushNotifications.campaignStatus.totalLabel")}
                </p>
                <p style={{ margin: 0, fontFamily: "Vodafone Rg", fontSize: 16 }}>20.000</p>
              </>
            }
            chartExtraStyle={{ margin: "auto" }}
            chartLegendData={chartLegend}
            chartLegendExtraStyle={{ marginLeft: "12px" }}
            chartWrapperExtraStyle={{ marginTop: "20px" }}
          ></DoughnutChart>
        </Container>
      </DrawerContainer>
      <DrawerOverlay onClick={() => hideDrawer()} />
    </>
  );
};

const TitleContainer = styled("div")`
  display: flex;
  padding: 66px 76px 51px 48px;
  align-items: center;
`;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 0px 40px 33px 76px;
`;

const DrawerOverlay = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(51, 51, 51, 0.4);
`;

const DrawerContainer = styled("div")`
  z-index: 11;
  position: fixed;
  top: 0;
  right: 0;
  width: 552px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  height: 100%;
  overflow: auto;
`;

const CloseIconContainer = styled("div")`
  stroke: ${props => props.theme.palette.vodafoneRed};
  transform: rotate(90deg);
  cursor: pointer;
  svg {
    width: 16px;
  }
`;

const Title = styled("span")`
  font-family: Vodafone Rg;
  font-size: 24px;
  color: ${props => props.theme.palette.darkGrey};
  margin-left: 11.5px;
`;

const Label = styled("span")`
  font-family: Vodafone Rg;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: ${props => props.theme.palette.midGrey};
`;

const Value = styled("span")<{ color?: string }>`
  font-family: Vodafone Rg;
  font-size: 16px;
  line-height: 18px;
  color: ${props => (props.color ? props.color : props.theme.palette.darkGrey)};
  margin-top: 16px;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${props => props.theme.palette.aluminium};
`;

export default PushNotificationDrawer;
