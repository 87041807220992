import { FormSection, TextInput, PlusIcon, TrashIcon, useAlert } from "@wit/mpesa-ui-components";
import { Formik, FormikErrors, yupToFormErrors } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { IDynamicScreenCategory } from "../../../configurations/dynamic-screens/dynamic-screens.model";
import i18next from "i18next";
import { StringSchema, string, object, Ref } from "yup";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { IStoreInterface } from "../../../../configs/store.config";
import GeneralCategoriesApi from "../general-categories.api";
import { GeneralCategoriesActions } from "../general-categories.store";
import { IIconCategory } from "../general-categories.model";
import IconPicker from "../../../../shared/components/icon-picker.component";
import GenericLanguageSelector from "../../../../shared/components/generic-language-selector.component";
import { ILanguage } from "../../../../shared/models/language.model";
import useEmptyLanguageWarningModal from "../../../../shared/hooks/use-empty-language-warning-modal.hook";

interface IGeneralCategoryForm {
  initialValues: IDynamicScreenCategory;
  isEditing: boolean;
  onSubmitFn: (values: IDynamicScreenCategory) => void;
  availableLanguages: ILanguage[];
  setAvailableLanguages: React.Dispatch<React.SetStateAction<ILanguage[] | undefined>>;
  isAntCategories?: boolean;
  errorIds: string[];
}

/**
 * CategoryForm component
 */
const GeneralCategoryForm = ({
  initialValues,
  isEditing,
  onSubmitFn,
  errorIds,
  availableLanguages,
  setAvailableLanguages,
  isAntCategories = false,
}: IGeneralCategoryForm) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [language, setLanguage] = useState("en");
  const [numberIdsRows, setNumberIdsRows] = React.useState(
    initialValues.externalCategoryIds ? initialValues.externalCategoryIds.length : 1,
  );

  const { icons } = useSelector((state: IStoreInterface) => state.serviceManagerReducer);
  const defaultColor = "#FAFAFA";
  const [isLoadingIcons, setIsLoadingIcons] = React.useState(true);

  const { warningModalOpen, showWarning, setShowWarning } = useEmptyLanguageWarningModal(availableLanguages || []);
  /**
   * Submit method wrapper to show warning if needed
   * @param values
   */
  const submitWrapper = (values: any) => {
    if (showWarning) {
      setShowWarning(false);
      warningModalOpen(onSubmitFn, values);
    } else {
      onSubmitFn(values);
    }
  };
  /**
   * method to get the icons list
   */
  const getIcons = () => {
    GeneralCategoriesApi.methods.getIconsList().then(res => {
      dispatch(GeneralCategoriesActions.creators.fectionsIconsCategories(res.data));
      setIsLoadingIcons(false);
    });
  };

  /**
   * Creates the language validators for the translatable fields
   * @param {ILanguage[]} languages
   * @returns {{[p: string]: Ref | StringSchema<string>}}
   */
  const getTranslationValidators = (languages: ILanguage[]) => {
    let validators: { [x: string]: Ref | StringSchema<string> } = {};
    languages.map((lang: ILanguage) => {
      if (lang.mandatory) {
        validators = {
          ...validators,
          [lang.code]: string()
            .trim()
            .required(i18next.t("pages.serviceBuilder.formErrors.required"))
            .max(255, i18next.t("pages.serviceBuilder.formErrors.maxLen255")),
        };
      } else {
        validators = {
          ...validators,
          [lang.code]: string().max(255, i18next.t("pages.serviceBuilder.formErrors.maxLen255")),
        };
      }
    });
    return validators;
  };

  /**
   * method to return the externals ids rows
   */
  const getExternalIdsRows = (
    values: IDynamicScreenCategory,
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    setFieldValue: (field: string, value: any) => void,
    errors: FormikErrors<IDynamicScreenCategory>,
  ) => {
    return [...Array(numberIdsRows)].map((elem, index) => ({
      label: <span style={{ display: "none" }} />,
      displayComponent: (
        <NonEditableText id="color-value">
          {values.externalCategoryIds ? values.externalCategoryIds[index] : undefined}
        </NonEditableText>
      ),
      editingComponent: (
        <ExternalIdInputContainer>
          <TextInput
            id="external-category-id-input"
            style={{
              maxWidth: "100%",
              borderColor:
                values.externalCategoryIds &&
                values.externalCategoryIds[index] &&
                errorIds.includes(values.externalCategoryIds[index])
                  ? styleTheme.palette.errorRed
                  : styleTheme.palette.aluminium,
            }}
            maxLength={16}
            name={`externalCategoryIds[${index}]`}
            value={
              values.externalCategoryIds && values.externalCategoryIds[index] ? values.externalCategoryIds[index] : ""
            }
            onChange={e => setANTID(values, setFieldValue, index, e.target.value)}
            placeholder={t("pages.generalCategories.categoryForm.categoryANTIdPlaceholder")}
            error={index === 0 ? (errors.externalCategoryIds as string) : undefined}
          />
          <TrashIcon
            onClick={() => {
              setNumberIdsRows(numberIdsRows - 1);
              if (values.externalCategoryIds) {
                setFieldValue(
                  "externalCategoryIds",
                  values.externalCategoryIds.filter(value => values.externalCategoryIds?.indexOf(value) !== index),
                );
              }
            }}
          ></TrashIcon>
        </ExternalIdInputContainer>
      ),
      required: true,
    }));
  };

  /**
   * method to set the ANT ID
   * array must have at least one id, but the string value must not be empty
   */
  const setANTID = (
    values: IDynamicScreenCategory,
    setFieldValue: (field: string, value: any) => void,
    index: number,
    value: string,
  ) => {
    if (value.length > 0) {
      setFieldValue(`externalCategoryIds[${index}]`, value);
    } else {
      const newExternalIds = values.externalCategoryIds && [...values.externalCategoryIds];
      newExternalIds?.splice(index, 1);
      setFieldValue(`externalCategoryIds`, newExternalIds);
    }
  };

  React.useEffect(() => {
    if (!icons || (icons && icons.length === 0)) {
      getIcons();
    } else {
      getIcons();
      setIsLoadingIcons(false);
    }
  }, []);

  /**
   * Retrieves the form validation schema
   * @param {ILanguage[]} availableLanguages
   * @returns {ObjectSchema<Shape<object, {name: Shape<object, {}>, serviceImage: string | null, serviceIcon: string | null, description: Shape<object, {}>}>>}
   */
  const getValidationSchema = (availableLanguages: ILanguage[]) => {
    return object().shape({
      name: object().shape({
        translations: object().shape(getTranslationValidators(availableLanguages)),
      }),
    });
  };

  /**
   * Validate form promise
   * @param values
   * @param {ILanguage[]} languages
   * @returns {Promise<FormikErrors<IDynamicScreenCategory>>}
   */
  const validateSchema = (
    values: any,
    languages: ILanguage[],
    setShowWarning?: React.Dispatch<React.SetStateAction<boolean>>,
  ): Promise<FormikErrors<IDynamicScreenCategory>> => {
    return new Promise<FormikErrors<IDynamicScreenCategory>>(resolve => {
      getValidationSchema(languages)
        .validate(values, {
          abortEarly: false,
        })
        .then(() => {
          setShowWarning && setShowWarning(false);
          //Validate if any optional language is empty
          for (let l = 0; l < languages.length; l++) {
            if (!values.name?.translations[languages[l].code]) {
              setShowWarning && setShowWarning(true);
            }
          }
          resolve({});
        })
        .catch(errors => {
          const langErrors: string[] = [];
          errors?.inner?.forEach((err: { path: string }) => {
            if ((err.path as string).includes("name.translations")) {
              langErrors.push(err.path.replace("name.translations.", ""));
            }
          });
          if (langErrors.length > 0) {
            langErrors.sort();
            setLanguage(langErrors[0]);
          }
          resolve(yupToFormErrors(errors));
        });
    }).then(r => {
      return r;
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitWrapper}
      validateOnChange={false}
      validateOnBlur={true}
      validate={values => validateSchema(values, availableLanguages, setShowWarning)}
      enableReinitialize
      render={({ values, handleChange, isSubmitting, setFieldValue, handleSubmit, errors, resetForm }) => (
        <form
          id="category-form"
          onSubmit={handleSubmit}
          onReset={() => {
            resetForm();
            setNumberIdsRows(initialValues.externalCategoryIds ? initialValues.externalCategoryIds.length : 0);
          }}
          style={{ width: "60%" }}
        >
          <FormContainer>
            <FormSection
              isEditing={isEditing}
              title={
                <div style={{ marginTop: "auto", marginLeft: "auto" }}>
                  <GenericLanguageSelector
                    selectedLanguage={language}
                    availableLanguages={availableLanguages.map(lang => lang.code)}
                    changeSelectedLanguage={(lang: any) => setLanguage(lang.key)}
                  ></GenericLanguageSelector>
                </div>
              }
              rows={[
                {
                  label: (
                    <FormLabel
                      id="category-name"
                      required={isEditing && availableLanguages.find(l => l.code === language)?.mandatory}
                    >
                      {t("pages.generalCategories.categoryForm.categoryName")}
                    </FormLabel>
                  ),
                  displayComponent: (
                    <NonEditableText id="category-name-value">
                      {(values.name && values.name.translations && values.name.translations[language]) || ""}
                    </NonEditableText>
                  ),
                  editingComponent: !isAntCategories ? (
                    <TextInput
                      id="category-name-input"
                      name={`name.translations.${language}`}
                      value={(values.name && values.name.translations && values.name.translations[language]) || ""}
                      onChange={handleChange}
                      placeholder={t("pages.generalCategories.categoryForm.categoryNamePlaceholder")}
                      error={
                        errors.name &&
                        errors.name.translations &&
                        ((errors.name.translations as { [x: string]: string })[language] as string)
                      }
                      maxLength={20}
                    />
                  ) : (
                    <NonEditableText id="category-name-value">
                      {(values.name && values.name.translations && values.name.translations[language]) || ""}
                    </NonEditableText>
                  ),
                  required: availableLanguages.find(l => l.code === language)?.mandatory,
                },
                {
                  label: (
                    <FormLabel id="category-icon">
                      {t("pages.serviceManager.categories.categoryForm.categoryIcon")}
                    </FormLabel>
                  ),
                  displayComponent: (
                    <NonEditableContainer>
                      <NonEditableIcon color={defaultColor}>
                        {icons && icons.find(icon => icon.id.toString() === values.iconId?.toString()) ? (
                          <img
                            src={
                              (icons.find(icon => icon.id.toString() === values.iconId?.toString()) as IIconCategory)
                                .base64
                            }
                          />
                        ) : null}
                      </NonEditableIcon>
                      <NonEditableText id="icon-value">
                        {icons.find(icon => icon.id.toString() === values.iconId?.toString())
                          ? (icons.find(icon => icon.id.toString() === values.iconId?.toString()) as IIconCategory)
                              .label
                          : t("pages.serviceManager.categories.categoryForm.defaultIconName")}
                      </NonEditableText>
                    </NonEditableContainer>
                  ),
                  editingComponent: !isAntCategories ? (
                    <IconPickerContainer>
                      <IconPicker
                        icons={icons}
                        icon={icons.find(icon => icon.id.toString() === values.iconId?.toString())}
                        name="iconId"
                        setIconID={setFieldValue}
                        backgroundColor={defaultColor}
                        displayBgColor={defaultColor}
                        borderColor={"#ebebeb"}
                        activeBorderColor={"#e60000"}
                        error={errors.iconId}
                        isLoading={isLoadingIcons}
                      ></IconPicker>
                    </IconPickerContainer>
                  ) : (
                    <NonEditableContainer>
                      <NonEditableIcon color={defaultColor}>
                        {icons && icons.find(icon => icon.id.toString() === values.iconId?.toString()) ? (
                          <img
                            src={
                              (icons.find(icon => icon.id.toString() === values.iconId?.toString()) as IIconCategory)
                                .base64
                            }
                          />
                        ) : null}
                      </NonEditableIcon>
                      <NonEditableText id="icon-value">
                        {icons.find(icon => icon.id.toString() === values.iconId?.toString())
                          ? (icons.find(icon => icon.id.toString() === values.iconId?.toString()) as IIconCategory)
                              .label
                          : t("pages.serviceManager.categories.categoryForm.defaultIconName")}
                      </NonEditableText>
                    </NonEditableContainer>
                  ),
                },
              ]}
            />

            {isAntCategories && (
              <CustomFormSection>
                <FormSection
                  isEditing={isEditing}
                  title={t("pages.generalCategories.categoryForm.categoryANTId")}
                  rows={getExternalIdsRows(values, handleChange, setFieldValue, errors)}
                ></FormSection>
                {isEditing && numberIdsRows <= 20 ? (
                  <AddIDRowContainer>
                    <AddIDContainer onClick={() => setNumberIdsRows(numberIdsRows + 1)}>
                      <PlusIcon></PlusIcon>
                      <AddIDText>{t("pages.generalCategories.categoryForm.addId")}</AddIDText>
                    </AddIDContainer>
                  </AddIDRowContainer>
                ) : (
                  <div style={{ marginBottom: "50px" }} />
                )}
              </CustomFormSection>
            )}
          </FormContainer>
        </form>
      )}
    />
  );
};

export default GeneralCategoryForm;

const FormContainer = styled("div")`
  width: 100%;
  > div > div:first-of-type {
    flex-flow: row-reverse;
  }
`;

const FormLabel = styled("span")<{ required?: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.greyDarker};

  ${props =>
    props.required
      ? `
  ::after {
    content: ' *';
    font-family: Vodafone Rg;
    font-size: 16px;
    font-weight: normal;
    color: ${props.theme.palette.errorRed};
  }`
      : ""}
`;

const NonEditableText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.darkGrey};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CustomFormSection = styled("div")`
  margin-top: 40px;

  > div > div > div {
    &:nth-child(odd) {
      display: none;
    }

    &:nth-child(even) {
      max-width: 100%;
    }
  }
`;

const AddIDText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.darkGrey};
  line-height: 1.31;
`;

const AddIDRowContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  margin-bottom: 50px;
`;

const AddIDContainer = styled("div")`
  display: flex;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    stroke: ${props => props.theme.palette.vodafoneRed};
    margin-right: 8px;
  }
`;

const ExternalIdInputContainer = styled("div")`
  display: flex;
  align-items: center;

  > div {
    width: 100%;
  }

  svg {
    width: 24px;
    height: 24px;
    stroke: ${props => props.theme.palette.errorRed};
    margin-left: 8px;
  }
`;

const NonEditableContainer = styled("div")`
  display: flex;
  align-items: center;
`;

const NonEditableIcon = styled("div")<{ color: string }>`
  width: 36px;
  height: 36px;
  margin: 0 11px 0 0;
  padding: 1px;
  border-radius: 6px;
  border: solid 1px ${props => props.theme.palette.aluminium};
  background-color: ${props => (props.color ? props.color : "white")};
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 24px;
    height: 24px;
  }
`;

const IconPickerContainer = styled("div")`
  > button {
    > div {
      width: -webkit-fill-available;
    }
  }
`;
