import React, { useState, useEffect, useRef, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { PageContainer } from "../../../shared/shared.styled";
import styled from "styled-components";
import { JsonEditor as Editor } from "jsoneditor-react";
import Ajv from "ajv";
import "jsoneditor-react/es/editor.min.css";
import { useTranslation } from "react-i18next";
import {
  SecondaryPageTitle,
  PrimaryButton,
  IconButton,
  UndoIcon,
  StatusChip,
  FitScreenIcon,
  ArrowUpIcon,
  useAlert,
  ModalTypeEnum,
  useModal,
} from "@wit/mpesa-ui-components";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/chrome";
import "./custom-style.css";
import { StatusEnum } from "@wit/mpesa-ui-components/lib/enums/status.enum";
import "brace/ext/searchbox";
import ServiceEditorApi from "../service-editor.api";
import ServiceDetailsApi from "../../service-details/service-details.api";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import LanguagesApi from "../../../shared/services/languages.api";
import { ILanguage } from "../../../shared/models/language.model";
import NewVersionModal from "../modals/new-version.modal";
import { ServiceStatusEnum, IServiceVersion, VersionLanguagesEnum } from "../../../shared/models/service-builder.model";
import { IFileLanguageMapping } from "../../service-details/service-details-version-management/service-details-version-management.component";
import { RoutesEnum } from "../../../routes/routes.constants";
import GenericLanguageSelector from "../../../shared/components/generic-language-selector.component";

const schema = {
  type: "object",
  additionalProperties: true,
  required: ["homeScreen", "screens"],
  properties: {
    homeScreen: { type: "string" },
    screens: {
      type: "object",
      patternProperties: {
        "^.*": {
          properties: {
            screenType: {
              type: "string",
            },
            params: {
              type: "object",
            },
          },
          required: ["screenType", "params"],
        },
      },
    },
  },
};

/**
 * ServiceEditorPage component
 */
const ServiceEditorPage = () => {
  const [t] = useTranslation();
  const history = useHistory();

  const { versionId, versionNumber, serviceId } = useParams<any>();

  const { serviceVersion } = history.location.state as any;

  let canCreateNewVersion = true;
  let status = "Undefined";
  let lastVersion = "N/A";

  if (serviceVersion) {
    canCreateNewVersion = !!serviceVersion && serviceVersion.canBeEdited;
    status = serviceVersion.status;
    lastVersion = serviceVersion.version;
  }

  const isInPublishState = () => {
    return [ServiceStatusEnum.LIVE, ServiceStatusEnum.BETA].includes(status as ServiceStatusEnum);
  };

  const [availableLanguages, setAvailableLanguages] = useState<string[]>([]);
  const [language, setLanguage] = useState("en");

  const languagesJson = React.useRef<any>({});
  const languageNotEmpty = React.useRef<any>({});

  const [configLoaded, setConfigLoaded] = useState(false);
  const [loadedError, setLoadedError] = useState(false);
  const [newVersion, setNewVersion] = useState("-1");
  const [showAlert, , setAlertProps] = useAlert();
  const rendererLink =
    process.env.REACT_APP_SERVICE_EDITOR_RENDERER_URL !== undefined
      ? process.env.REACT_APP_SERVICE_EDITOR_RENDERER_URL
      : undefined;

  const changeLanguage = (language: string) => {
    try {
      if (languagesJson.current[language] && rendererLink !== undefined) {
        ((document.getElementById("iframeEl") as HTMLIFrameElement).contentWindow as Window).postMessage(
          {
            type: "MPESA_RENDERER_SET_CONFIG",
            payload: { ...languagesJson.current[language] },
          },
          rendererLink,
        );
        setJsonStr(languagesJson.current[language]);
        (ref.current as any).jsonEditor.set(languagesJson.current[language]);
        process.nextTick(() => {
          setLoadedError(false);
          setConfigLoaded(true);
        });
      }
    } catch (e) {
      setLoadedError(true);
      setConfigLoaded(true);
      console.error(e);
    }
  };

  const getServiceConfiguration = useCallback((availableLanguages: string[]) => {
    availableLanguages.map(lang => {
      ServiceEditorApi.methods
        .getServiceConfiguration(versionId, lang)
        .then(res => {
          try {
            if (res.data && rendererLink !== undefined) {
              languagesJson.current[lang] = res.data;
              if (JSON.stringify(res.data) === JSON.stringify({})) {
                languageNotEmpty.current[lang] = false;
              } else {
                languageNotEmpty.current[lang] = true;
              }

              if (lang === availableLanguages[0]) {
                ((document.getElementById("iframeEl") as HTMLIFrameElement).contentWindow as Window).postMessage(
                  {
                    type: "MPESA_RENDERER_SET_CONFIG",
                    payload: { ...res.data },
                  },
                  rendererLink,
                );
                setJsonStr(res.data);
                (ref.current as any).jsonEditor.set(res.data);
                process.nextTick(() => {
                  setLoadedError(false);
                  setConfigLoaded(true);
                });
              }
            }
          } catch (e) {
            setLoadedError(true);
            setConfigLoaded(true);
            languagesJson.current[lang] = {};
            languageNotEmpty.current[lang] = false;
            console.error(e);
          }
        })
        .catch(err => {
          setLoadedError(true);
          setConfigLoaded(true);
          languagesJson.current[lang] = {};
          languageNotEmpty.current[lang] = false;
          console.error(err);
        });
    });
  }, []);

  // Update version Stuff

  const createServiceVersion = useCallback(
    (versionFiles: IFileLanguageMapping[], versionDetails: IServiceVersion) => {
      ServiceDetailsApi.methods
        .addServiceVersion(versionFiles, versionDetails)
        .finally()
        .then(
          res => {
            //dispatch(ServiceDetailsActions.creators.fetchingServiceVersionAddAction(res.data));
            setAlertProps({
              title: t("pages.serviceBuilder.success.addingVersion.title"),
              type: AlertTypeEnum.SUCCESS,
            });
            showAlert();
            //actions.setSubmitting(false);
            //hideModal();
            window.scrollTo(0, 0);
            history.push(RoutesEnum.SERVICE_DETAILS.replace(":serviceId", serviceId));
          },
          res => {
            if (
              res.response &&
              res.response.data &&
              res.response.data.code &&
              res.response.data.message &&
              (res.response.data.code === 20002 || res.response.data.code === 10000)
            ) {
              setAlertProps({
                title:
                  res.response.data.code === 20002
                    ? t("pages.serviceBuilder.errors.addingVersion.existsError")
                        .replace("{0}", "serviceName")
                        .replace("{1}", versionDetails.version ? versionDetails.version : "")
                    : res.response.data.message,
                content: res.response.data.details ? res.response.data.details : null,
                type: AlertTypeEnum.ERROR,
              });
            } else {
              setAlertProps({
                title: t("pages.serviceBuilder.errors.addingVersion.title"),
                content: t("pages.serviceBuilder.errors.addingVersion.content"),
                type: AlertTypeEnum.ERROR,
              });
            }
            showAlert();
            // actions.setSubmitting(false);
          },
        );
    },
    [setAlertProps, showAlert, t],
  );

  const createNewServiceVersion = (version: string) => {
    const files: IFileLanguageMapping[] = [];

    availableLanguages.map(lang => {
      if (
        languageNotEmpty.current[lang] ||
        (!languageNotEmpty.current[lang] && JSON.stringify(languagesJson.current[lang]) !== JSON.stringify({}))
      ) {
        const jsonse = JSON.stringify(languagesJson.current[lang]);
        const blob = new Blob([jsonse], { type: "application/json" });
        const file = new File([blob], `blob_${lang}.json`, {
          lastModified: new Date().getTime(),
          type: "application/json",
        });
        files.push({
          file: file,
          language: lang as VersionLanguagesEnum,
        });
      }
    });

    const versionDetails: IServiceVersion = {
      version: version,
      serviceId: serviceId,
      status: ServiceStatusEnum.UNDEPLOYED,
      useRenderer: true,
    };

    return createServiceVersion(files, versionDetails);
  };

  React.useEffect(() => {
    console.log("NewVersion", newVersion);

    if (newVersion !== "-1") {
      createNewServiceVersion(newVersion);
    }
  }, [newVersion]);

  const [showAddVersionModal, hideAddVersionModal] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <NewVersionModal
      lastVersion={lastVersion}
      hideModal={() => {
        hideAddVersionModal();
      }}
      handleNewVersionValue={version => {
        setNewVersion(version);
      }}
    ></NewVersionModal>,
  );

  // End update version

  const setServiceConfiguration = useCallback((availableLanguages: string[]) => {
    availableLanguages.map(lang => {
      if (
        languageNotEmpty.current[lang] ||
        (!languageNotEmpty.current[lang] && JSON.stringify(languagesJson.current[lang]) !== JSON.stringify({}))
      ) {
        const jsonse = JSON.stringify(languagesJson.current[lang]);
        const blob = new Blob([jsonse], { type: "application/json" });
        ServiceEditorApi.methods
          .setServiceConfiguration(versionId, lang, blob)
          .then(res => {
            setAlertProps({
              title: t("pages.serviceEditor.alertSuccess.title"),
              type: AlertTypeEnum.SUCCESS,
            });
            showAlert();
          })
          .catch(err => {
            setAlertProps({
              title: t("pages.serviceEditor.alertError.title"),
              content: t("pages.serviceEditor.alertError.description"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
            console.error(err);
          });
      }
    });
  }, []);

  const [jsonStr, setJsonStr] = useState({});
  let counter = 0;
  const [backIndex] = useState(window.history.length);
  const [undoDisabled, setUndoDisabled] = useState(true);
  const [redoDisabled, setRedoDisabled] = useState(true);

  const checkUndoRedo = () => {
    const undoElem = document.getElementsByClassName("jsoneditor-undo")[0] as HTMLInputElement;
    const redoElem = document.getElementsByClassName("jsoneditor-redo")[0] as HTMLInputElement;
    if (undoElem.disabled !== undefined) {
      setTimeout(() => {
        setUndoDisabled(undoElem.disabled);
      }, 100);
    }
    if (redoElem.disabled !== undefined) {
      setTimeout(() => {
        setRedoDisabled(redoElem.disabled);
      }, 100);
    }
  };

  const inputSearchFunc = (event: any) => {
    if ((event.target as HTMLInputElement).value !== "") {
      (document.getElementsByClassName("ace_search_counter")[0] as HTMLElement).style.display = "block";
    } else {
      (document.getElementsByClassName("ace_search_counter")[0] as HTMLElement).style.display = "none";
    }
  };
  useEffect(() => {
    LanguagesApi.methods.getAvailableLanguages().then(
      res => {
        const languagesStr = res.data.availableLanguages.map((lang: ILanguage) => {
          return lang.code;
        });
        setAvailableLanguages(languagesStr);
        setLanguage(languagesStr[0]);
        getServiceConfiguration(languagesStr as string[]);
      },
      () => {
        setAlertProps({
          type: AlertTypeEnum.ERROR,
          title: t("commons.getLanguagesError"),
        });
        showAlert();
        setAvailableLanguages(["en", "sw"]);
        setLanguage("en");
        getServiceConfiguration(["en", "sw"] as string[]);
      },
    );

    const onMessage = (event: any) => {
      const { data } = event;
      if (!data) {
        return;
      }

      if (data.type === "MPESA_RENDERER_GET_CONFIG_RESPONSE") {
        if (
          JSON.stringify(jsonStr) === JSON.stringify({}) &&
          JSON.stringify(data.payload) !== JSON.stringify({}) &&
          counter < 1
        ) {
          setJsonStr(data.payload);
          languagesJson.current[language] = data.payload;
          counter++;
          (ref.current as any).jsonEditor.set(data.payload);
        }
      }
    };
    window.addEventListener("message", onMessage, false);

    const st = setInterval(() => {
      if (
        document.getElementsByClassName("ace_text-input")[0] &&
        document.getElementsByClassName("ace_searchbtn")[3] &&
        document.getElementsByClassName("ace_search_field")[1]
      ) {
        document.getElementsByClassName("ace_text-input")[0].addEventListener("keydown", e => {
          if ((e as KeyboardEvent).keyCode === 27) {
            (document.getElementsByClassName("ace_search")[0] as HTMLElement).removeAttribute("style");
          }
          checkUndoRedo();
        });
        document.getElementsByClassName("ace_search_field")[0].addEventListener("keydown", e => {
          if (
            (e as KeyboardEvent).keyCode === 27 ||
            (((e as KeyboardEvent).ctrlKey || (e as KeyboardEvent).metaKey) && (e as KeyboardEvent).keyCode === 70)
          ) {
            e.stopPropagation();
          }
        });
        document.getElementsByClassName("ace_search_field")[1].addEventListener("keydown", e => {
          if (
            (e as KeyboardEvent).keyCode === 27 ||
            (((e as KeyboardEvent).ctrlKey || (e as KeyboardEvent).metaKey) && (e as KeyboardEvent).keyCode === 70)
          ) {
            e.stopPropagation();
          }
        });
        document.getElementsByClassName("ace_searchbtn")[3].addEventListener("click", checkUndoRedo);
        document.getElementsByClassName("ace_text-input")[0].addEventListener("cut", checkUndoRedo);
        document.getElementsByClassName("ace_text-input")[0].addEventListener("paste", checkUndoRedo);
        clearInterval(st);
      }
    }, 100);

    (ref.current as any).jsonEditor.aceEditor.execCommand("find");
    (ref.current as any).jsonEditor.aceEditor.commands.removeCommand("find");
    document
      .getElementsByClassName("edit-section-inner")[0]
      .insertBefore(
        document.getElementsByClassName("ace_search")[0],
        document.getElementsByClassName("edit-section-inner")[0].firstChild,
      );

    document
      .getElementsByClassName("ace_search_form")[0]
      .appendChild(document.getElementsByClassName("ace_search_counter")[0]);
    (document.getElementsByClassName("ace_replace_form")[0] as HTMLElement).style.display = "flex";
    (document.getElementsByClassName("ace_searchbtn")[3] as HTMLElement).classList.add("replaceBtn");
    (document.getElementsByClassName("ace_searchbtn")[3] as HTMLElement).setAttribute("id", "replaceBtn");
    (document.getElementsByClassName("ace_searchbtn")[3] as HTMLElement).innerHTML = "";
    (document.getElementsByClassName("ace_search_counter")[0] as HTMLElement).style.display = "none";
    document.getElementsByClassName("ace_search_field")[0].addEventListener("input", event => inputSearchFunc(event));

    ((document.getElementById("editSection") as HTMLElement).firstElementChild as HTMLElement).appendChild(
      document.getElementById("undoRedoWrapper") as HTMLElement,
    );
    return () => {
      document.getElementsByClassName("ace_text-input")[0].removeEventListener("keydown", e => {
        if ((e as KeyboardEvent).keyCode === 27) {
          (document.getElementsByClassName("ace_search")[0] as HTMLElement).removeAttribute("style");
        }
        checkUndoRedo();
      });
      document.getElementsByClassName("ace_search_field")[0].removeEventListener("keydown", e => {
        if (
          (e as KeyboardEvent).keyCode === 27 ||
          (((e as KeyboardEvent).ctrlKey || (e as KeyboardEvent).metaKey) && (e as KeyboardEvent).keyCode === 70)
        ) {
          e.stopPropagation();
        }
      });
      document.getElementsByClassName("ace_search_field")[1].removeEventListener("keydown", e => {
        if (
          (e as KeyboardEvent).keyCode === 27 ||
          (((e as KeyboardEvent).ctrlKey || (e as KeyboardEvent).metaKey) && (e as KeyboardEvent).keyCode === 70)
        ) {
          e.stopPropagation();
        }
      });
      document.getElementsByClassName("ace_searchbtn")[3].removeEventListener("click", checkUndoRedo);
      document.getElementsByClassName("ace_text-input")[0].removeEventListener("cut", checkUndoRedo);
      document.getElementsByClassName("ace_text-input")[0].removeEventListener("paste", checkUndoRedo);
      document
        .getElementsByClassName("ace_search_field")[0]
        .removeEventListener("input", event => inputSearchFunc(event));
      window.removeEventListener("message", onMessage, false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (() => {
    let timer: any = null;
    return (val: any) => {
      timer && clearTimeout(timer);
      timer = setTimeout(() => {
        try {
          if (val && rendererLink !== undefined) {
            ((document.getElementById("iframeEl") as HTMLIFrameElement).contentWindow as Window).postMessage(
              {
                type: "MPESA_RENDERER_SET_CONFIG",
                payload: { ...val },
              },
              rendererLink,
            );
            setJsonStr(val);
            languagesJson.current[language] = val;
          }
        } catch (e) {
          console.error(e);
        }
      }, 800);
    };
  })();

  const onIframeLoad = () => {
    // hack to let the iframe fetch the JSON asynchronously.
    // we might revisit this to have the iframe post directly to a specific domain upon load
    setTimeout(() => {
      if (document.getElementById("iframeEl") && rendererLink !== undefined && loadedError) {
        ((document.getElementById("iframeEl") as HTMLIFrameElement).contentWindow as Window).postMessage(
          {
            type: "MPESA_RENDERER_GET_CONFIG",
          },
          rendererLink,
        );
      }
    }, 800);
  };

  const goBackFn = () => {
    return history.go(backIndex - (window.history.length + 1));
  };

  const ajv = new Ajv({ allErrors: true, verbose: true });

  const fitScreen = () => {
    if (document.getElementById("editSection") && document.getElementById("backSection")) {
      if ((document.getElementById("editSection") as HTMLElement).style.display === "none") {
        (document.getElementById("editSection") as HTMLElement).style.display = "block";
        (document.getElementById("backSection") as HTMLElement).style.display = "none";
      } else {
        (document.getElementById("editSection") as HTMLElement).style.display = "none";
        (document.getElementById("backSection") as HTMLElement).style.display = "block";
      }
    }
  };

  const ref = useRef("aceEditor");

  return (
    <PageContainer>
      <Wrapper style={{ overflow: "hidden" }}>
        <EditSection id="editSection">
          <SecondaryPageTitle
            height={71}
            paddingLeft={95}
            titleFontSize={24}
            title={t("pages.serviceEditor.versionPreviewTitle")}
            goBackFn={() => goBackFn()}
            displayInitialsCircle={false}
          />
          <IconWrapper id="undoRedoWrapper">
            <IconButton
              id="undoButton"
              onClick={() => {
                if (document.getElementsByClassName("jsoneditor-undo")[0]) {
                  (document.getElementsByClassName("jsoneditor-undo")[0] as HTMLInputElement).click();
                }
                checkUndoRedo();
              }}
              disabled={undoDisabled}
              style={{ transform: "scaleX(-90deg)" }}
            >
              <UndoIcon />
            </IconButton>
            <IconButton
              id="redoButton"
              onClick={() => {
                if (document.getElementsByClassName("jsoneditor-redo")[0]) {
                  (document.getElementsByClassName("jsoneditor-redo")[0] as HTMLInputElement).click();
                }
                checkUndoRedo();
              }}
              disabled={redoDisabled}
              style={{ marginLeft: "17px", transform: "scaleX(-90deg)" }}
            >
              <UndoIcon />
            </IconButton>
          </IconWrapper>
          {availableLanguages && (
            <LanguageSelectorWrapper>
              <GenericLanguageSelector
                selectedLanguage={language}
                availableLanguages={availableLanguages}
                changeSelectedLanguage={(lang: any) => {
                  setLanguage(lang.key);
                  changeLanguage(lang.key);
                  checkUndoRedo();
                }}
              ></GenericLanguageSelector>
            </LanguageSelectorWrapper>
          )}
          <Editor
            htmlElementProps={{
              className: "edit-section-inner",
            }}
            value={jsonStr}
            ajv={ajv}
            schema={schema}
            mode="code"
            onChange={onChange}
            ace={ace}
            theme="ace/theme/chrome"
            search={true}
            ref={ref}
          />
        </EditSection>
        <BackSection id="backSection">
          <ArrowDivWrapper>
            <ArrowUpIcon onClick={() => fitScreen()}></ArrowUpIcon>
          </ArrowDivWrapper>
        </BackSection>
        <PreviewSection>
          <ButtonRow>
            <ButtonContainer>
              <PrimaryButton
                id="cancel-button"
                disabled={false}
                titleLabel={t("pages.serviceEditor.cancelButton")}
                type="button"
                redTheme={false}
                onClick={() => goBackFn()}
              />
            </ButtonContainer>
            <ButtonContainer>
              <PrimaryButton
                id="submit-button"
                disabled={!canCreateNewVersion}
                titleLabel={t(`pages.serviceEditor.${isInPublishState() ? "createButton" : "saveButton"}`)}
                type="button"
                redTheme={true}
                onClick={() => {
                  if (isInPublishState()) {
                    showAddVersionModal();
                  } else {
                    setServiceConfiguration(availableLanguages);
                  }
                }}
              />
            </ButtonContainer>
          </ButtonRow>
          <AppPreviewText>{t("pages.serviceEditor.appPreviewTitle")}</AppPreviewText>
          <PreviewDiv>
            <IFrameWrapper>
              {rendererLink !== undefined ? (
                <IFrame
                  id="iframeEl"
                  title="renderer-preview"
                  src={rendererLink}
                  onLoad={onIframeLoad}
                  style={{ opacity: configLoaded ? 1 : 0 }}
                ></IFrame>
              ) : null}
            </IFrameWrapper>
          </PreviewDiv>
          <FitScreenWrapper>
            <IconButton
              id="fitScreenBtn"
              style={{ marginLeft: "auto", marginTop: "-46px", marginRight: "24px" }}
              disabled={false}
              onClick={e => {
                fitScreen();
              }}
            >
              <FitScreenIcon />
            </IconButton>
          </FitScreenWrapper>
          <VersionNumberWrapper>
            <StatusChip type={StatusEnum.DEPRECATED}>
              {t("pages.serviceEditor.versionNumber")}
              {unescape(versionNumber)}
            </StatusChip>
          </VersionNumberWrapper>
        </PreviewSection>
      </Wrapper>
    </PageContainer>
  );
};

export default ServiceEditorPage;

const Wrapper = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const EditSection = styled("div")`
  display: block;
  flex: 1 1 auto;
  height: 100%;
  box-sizing: border-box;
  padding-top: 71px;
  padding-left: 70px;
  max-width: 50%;
  width: 50%;
  background: #f4f4f4;
  > :first-child {
    position: fixed;
    top: 0;
    left: 0;
    width: 50%;
    background-color: #f4f4f4;
    border-right: 1px solid #ebebeb;
  }
`;

const PreviewSection = styled("div")`
  height: 100%;
  box-sizing: border-box;
  background: #f4f4f4;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`;

const IFrame = styled("iframe")`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: none;
  border-radius: 3.5px;
`;

const IFrameWrapper = styled("div")`
  max-height: 600px;
  width: 330px;
  flex: 1;
  border-radius: 3.5px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1), 0 3px 7px 0 rgba(142, 142, 142, 0.23);
  background: url(/images/serviceEditor/service_editor_illustration.png);
  background-position: center;
  background-repeat: no-repeat;
`;

const AppPreviewText = styled("div")`
  font-family: Vodafone Rg;
  color: #999999;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  margin-top: 34px;
  width: 330px;
  margin-left: auto;
  margin-right: auto;
`;

const ButtonContainer = styled("div")`
  margin-left: auto;
  align-self: flex-end;
`;

const ButtonRow = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 19px;
  width: 277px;
  margin-right: 25px;
  margin-left: auto;
`;
const IconWrapper = styled("div")`
  display: flex;
  width: 89px;
  top: 0;
  z-index: 2;
  margin: auto;
  margin-right: 24px;

  > button:first-of-type {
    transform: rotate(90deg) scale(-1);
  }
  > button:nth-of-type(2) {
    transform: rotate(-90deg) scaleY(-1);
  }

  > button > svg {
    stroke: #999999;
  }
  > button:hover {
    border-color: #e60000;
  }
`;

const FitScreenWrapper = styled("div")`
  > button:hover {
    border-color: #e60000;
  }
`;

const VersionNumberWrapper = styled("div")`
  width: max-content;
  margin-top: 25px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: 25px;
`;
const BackSection = styled("div")`
  width: 114px;
  height: 100%;
  border-right: 1px solid #ebebeb;
  background-color: #fafafa;
  display: none;
`;
const ArrowDivWrapper = styled("div")`
  margin-top: 26px;
  margin-left: 82px;
  svg {
    transform: rotate(90deg);
    stroke: #e60000;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;
const PreviewDiv = styled("div")`
  width: 330px;
  display: -ms-flexbox;
  display: flex;
  flex: 1;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  margin-bottom: 10px;
`;
const LanguageSelectorWrapper = styled("div")`
  width: max-content;
  margin-left: auto;
  z-index: 1;
  margin-right: 26px;
  margin-bottom: 8px;
  > div {
    z-index: 1;
  }
`;
