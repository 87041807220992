import React from "react";
import { PageContainer } from "../../../shared/shared.styled";
import { PageTitle, Magnifier, useFilters, useAlert, ModalTypeEnum, useModal } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { RoutesEnum } from "../../../routes/routes.constants";
import i18next, { TFunction } from "i18next";
import { Breadcrumb } from "@wit/mpesa-ui-components/lib/types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "styled-components";
import styled from "styled-components";
import { ListHeader, ListHeaders, LoadingText, Row } from "../../../shared/shared.styled";
import { IStoreInterface } from "../../../configs/store.config";
import { useDispatch, useSelector } from "react-redux";
import { IReferralCampaign, IReferralCampaigns } from "./referral-campaigns.model";
import { ReferralCampaignsActions } from "./referral-campaigns.store";
import AddNewReferralCampaign from "./components/add-new-referral-campaign.component";
import Document from "../rate-cards/components/document.component";
import ReferralCampaignListItem from "./components/referral-campaign-list-item.component";
import ReferralCampaignsFilters from "./components/referral-campaigns-filters.component";
import { filterReferralCampaigns } from "./referral-campaigns.utils";
import ReferralCampaignsApi from "./referral-campaigns.api";
import { AxiosResponse } from "axios";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import DuplicateReferralModal from "./components/duplicate-modal.component";
import moment from "moment";

/**
 * method to get the breadcrumbs
 * @param t translations
 */
const getBreadcrumbs = (t: TFunction): Breadcrumb[] => {
  return [
    {
      label: t("pages.configuration.title"),
      url: RoutesEnum.CONFIGS,
    },
    {
      label: t("pages.referralCampaigns.title"),
      url: "",
    },
  ];
};

/**
 * ReferralCampaigns page component
 */
const ReferralCampaigns = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [showAlert, , setAlertProps] = useAlert();
  const themeContext = React.useContext(ThemeContext);
  const history = useHistory();
  const { referralCampaigns } = useSelector((state: IStoreInterface) => state.referralCampaignsReducer);
  const [showableReferralCampaigns, setShowableReferralCampaigns] = React.useState<IReferralCampaign[]>(
    referralCampaigns.campaigns ? referralCampaigns.campaigns : [],
  );
  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );
  const [idForDuplicate, setSelectedIdForDuplicate] = React.useState("");
  const [showDuplicateReferralModal, hideDuplicateReferralModal] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <DuplicateReferralModal
      cancelAction={() => hideDuplicateReferralModal()}
      confirmAction={(startDate: string, endDate: string) => onDuplicate(idForDuplicate, startDate, endDate)}
    />,
  );
  const {
    filters,
    updateFilter,
    isFilterActive,
    clearFilter,
    resetFilters,
    getFilterValue,
    getFiltersQueryString,
    updateMultipleFilters,
  } = useFilters();

  React.useEffect(() => {
    getReferralCampaigns();
  }, []);

  React.useEffect(() => {
    if (filters.size > 0) {
      setShowableReferralCampaigns(
        filterReferralCampaigns(referralCampaigns.campaigns ? [...referralCampaigns.campaigns] : [], filters),
      );
    } else {
      setShowableReferralCampaigns(referralCampaigns.campaigns);
    }
  }, [filters]);

  /**
   * method to get the referral campaigns list
   */
  const getReferralCampaigns = () => {
    ReferralCampaignsApi.methods.getReferralCampaigns().then(
      (res: AxiosResponse<IReferralCampaigns>) => {
        dispatch(ReferralCampaignsActions.creators.getReferralCampaignsSuccess(res.data));
        if (filters.size > 0) {
          setShowableReferralCampaigns(
            filterReferralCampaigns(res.data.campaigns ? [...res.data.campaigns] : [], filters),
          );
        } else {
          setShowableReferralCampaigns(res.data.campaigns);
        }
      },
      () => {
        setAlertProps({
          title: t("pages.referralCampaigns.getReferralCampaignsError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * method to delete a referral campaign
   */
  const onDelete = (id: string) => {
    setConfirmationProps({
      title: t("pages.referralCampaigns.deleteCampaignModal.title"),
      description: t("pages.referralCampaigns.deleteCampaignModal.description"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      primaryAction: () => {
        ReferralCampaignsApi.methods.deleteReferralCampaign(id).then(
          () => {
            getReferralCampaigns();
            hideConfirmationModal();
            setAlertProps({
              type: AlertTypeEnum.SUCCESS,
              title: t("pages.referralCampaigns.deleteCampaignModal.success"),
            });
            showAlert();
          },
          () => {
            setAlertProps({
              title: t("pages.referralCampaigns.deleteCampaignModal.error"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          },
        );
      },
      secondaryAction: () => hideConfirmationModal(),
    } as BaseModalProps);
    showConfirmationModal();
  };

  /**
   * method to delete a referral campaign
   */
  const onDuplicate = (id: string, startDate: string, endDate: string) => {
    ReferralCampaignsApi.methods
      .duplicateReferralCampaign(id, moment(startDate, "DD/MM/YYYY").valueOf(), moment(endDate, "DD/MM/YYYY").valueOf())
      .then(
        () => {
          getReferralCampaigns();
          setAlertProps({
            type: AlertTypeEnum.SUCCESS,
            title: t("pages.referralCampaigns.duplicateCampaignModal.success"),
          });
          showAlert();
        },
        () => {
          setAlertProps({
            title: t("pages.referralCampaigns.duplicateCampaignModal.error"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(hideDuplicateReferralModal);
  };

  return (
    <PageContainer>
      <PageTitle
        breadcrumbs={getBreadcrumbs(t)}
        title={t("pages.referralCampaigns.title")}
        navigateFunction={history.push}
        goBackFn={() => history.push(RoutesEnum.CONFIGS)}
        separatorStyle={{ display: "none" }}
        titleContainerStyle={{ marginTop: "13px", marginBottom: "34px" }}
        iconContainerStyle={{ stroke: themeContext.palette.vodafoneRed }}
      />

      {!referralCampaigns.campaigns ? (
        <LoadingText>{t("commons.loadingResults")}</LoadingText>
      ) : (
        <ReferralCampaignsContainer>
          {referralCampaigns.campaigns && referralCampaigns.campaigns.length ? (
            <>
              <Row>
                <ReferralCampaignsFilters
                  filters={filters}
                  isFilterActive={isFilterActive}
                  updateFilter={updateFilter}
                  clearFilter={clearFilter}
                  resetFilters={resetFilters}
                  getFilterValue={getFilterValue}
                  getFiltersQueryString={getFiltersQueryString}
                  updateMultipleFilters={updateMultipleFilters}
                ></ReferralCampaignsFilters>
              </Row>
            </>
          ) : null}
          <ReferralCampaignsListContainer>
            <>
              <ListHeaders>
                <ListHeader ratio={5 / 12}>{t("pages.referralCampaigns.table.headers.campaignName")}</ListHeader>
                <ListHeader ratio={3 / 12}>{t("pages.referralCampaigns.table.headers.rewardType")}</ListHeader>
                <ListHeader ratio={2 / 12}>{t("pages.referralCampaigns.table.headers.rewardAmount")}</ListHeader>
                <ListHeader ratio={2 / 12} style={{ textAlign: "right" }}>
                  {t("pages.referralCampaigns.table.headers.status")}
                </ListHeader>
              </ListHeaders>
              {filters.size === 0 ? (
                <AddNewReferralCampaign
                  addNewReferralCampaignFn={() => history.push(RoutesEnum.CREATE_REFERRAL_CAMPAIGN)}
                />
              ) : null}
              {referralCampaigns.campaigns && referralCampaigns.campaigns.length && showableReferralCampaigns ? (
                <>
                  {showableReferralCampaigns.map((rf: IReferralCampaign) => {
                    return (
                      <ReferralCampaignListItem
                        onDelete={onDelete}
                        onDuplicate={id => {
                          setSelectedIdForDuplicate(id);
                          showDuplicateReferralModal();
                        }}
                        onClickItemFn={() => {
                          dispatch(ReferralCampaignsActions.creators.setReferralCampaign(rf));
                          history.push(RoutesEnum.EDIT_REFERRAL_CAMPAIGN.replace(":id", rf.id as string));
                        }}
                        key={rf.id}
                        item={rf}
                      />
                    );
                  })}
                  {showableReferralCampaigns.length === 0 ? (
                    <MagnifierContainer>
                      <Magnifier />
                      <NoResultsFoundText>{t("pages.referralCampaigns.noSearchResults")}</NoResultsFoundText>
                    </MagnifierContainer>
                  ) : null}
                </>
              ) : (
                <EmptyState>
                  <Document />
                  <SelectText>{t("pages.referralCampaigns.emptyState")}</SelectText>
                </EmptyState>
              )}
            </>
          </ReferralCampaignsListContainer>
        </ReferralCampaignsContainer>
      )}
    </PageContainer>
  );
};

export default ReferralCampaigns;

const ReferralCampaignsContainer = styled("div")`
  margin-bottom: 34px;
  display: flex;
  flex-direction: column;
`;

const MagnifierContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 50px;
  svg {
    width: 140px;
    height: 140px;
    margin-bottom: 24px;
    stroke: red;
  }
`;

const NoResultsFoundText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.midGrey};
`;

const ReferralCampaignsListContainer = styled("div")`
  margin-bottom: 50px;
  margin-top: 31px;
`;

const EmptyState = styled("div")`
  margin-top: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const SelectText = styled("div")`
  font-family: "Vodafone Rg";
  width: 400px;
  height: 51px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  margin-top: 35px;
`;
