import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { IResultsTableCustomerDrawerComponentProps } from "../feedback-and-problems.model";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { ArrowDownIcon } from "@wit/mpesa-ui-components";
import { customerStringPath } from "../feedback-and-problems.utils";

const ResultsTableCustomerDrawerComponent = ({
  drawerBodyData,
  closeDrawer,
}: IResultsTableCustomerDrawerComponentProps) => {
  const [t] = useTranslation();

  return (
    <ComponentContainer>
      <HeaderSection>
        <Icon onClick={closeDrawer} id={"close-drawer"}>
          <ArrowDownIcon />
        </Icon>
        <Title>
          {t(customerStringPath + ".detailsArea.title")} {!!drawerBodyData ? drawerBodyData.feedbackId : ""}
        </Title>
      </HeaderSection>
      {drawerBodyData && (
        <BodySection>
          <ColumnRow direction="column">
            <div className={"row-title"}>{t(`${customerStringPath}.detailsArea.userMsisdn`)}</div>
            <div className={"row-description"}>{drawerBodyData.msisdn}</div>
          </ColumnRow>

          <ColumnRow direction="column">
            <div className={"row-title"}>{t(`${customerStringPath}.detailsArea.date`)}</div>
            <div className={"row-description"}>{drawerBodyData.date}</div>
          </ColumnRow>
          <ColumnRow direction="column">
            <div className={"row-title"}>{t(`${customerStringPath}.detailsArea.experience`)}</div>
            <div className={"row-description"}>{drawerBodyData.ratedExperience}</div>
          </ColumnRow>
          <ColumnRow direction="column">
            <div className={"row-title"}>{t(`${customerStringPath}.detailsArea.finishedTransaction`)}</div>
            <div className={"row-description"}>{drawerBodyData.finishedTransactions}</div>
          </ColumnRow>
          <ColumnRow direction="column">
            <div className={"row-title"}>{t(`${customerStringPath}.detailsArea.customerSatisfaction`)}</div>
            <div className={"row-description"}>{drawerBodyData.customerSatisfaction}</div>
          </ColumnRow>
          <ColumnRow direction="column">
            <div className={"row-title"}>{t(`${customerStringPath}.detailsArea.shareOpinion`)}</div>
            <div className={"row-description"}>{drawerBodyData.opinion}</div>
          </ColumnRow>
        </BodySection>
      )}
    </ComponentContainer>
  );
};

export default ResultsTableCustomerDrawerComponent;

export const ComponentContainer = styled("div")`
  font-family: Vodafone Rg;
  width: 100%;
  padding: 0;
  margin: 0;
`;

export const HeaderSection = styled("div")`
  width: 100%;
  height: auto;
  padding: 65px 48.71px 35px 48.71px;
  display: flex;
  align-items: center;
`;

export const Icon = styled("div")`
  cursor: pointer;
  stroke: ${styleTheme.palette.red.normal};
  margin-right: 8px;
  width: 20px;
  height: 20px;
  svg {
    transform: rotate(90deg);
  }
`;

export const Title = styled("div")`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: ${styleTheme.palette.anthracite};
`;

export const BodySection = styled("div")`
  width: 100%;
  height: auto;
  padding: 0 43px 35px 76px;
  display: flex;
  flex-direction: column;
`;

export const ColumnRow = styled("div")<{ direction?: any }>`
  width: 100%;
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : "row")};
  text-align: left;
  font-size: 16px;
  line-height: 18px;
  border-bottom: 1px solid ${styleTheme.palette.greyLightest};

  div.row-title {
    width: 100%;
    height: 18px;
    display: flex;
    align-items: center;
    color: ${styleTheme.palette.greyDarker};
    font-weight: 700;
    margin: 22px 0 0 0;
  }

  div.row-description {
    width: 100%;
    min-height: 18px;
    display: flex;
    align-items: center;
    color: ${styleTheme.palette.black};
    overflow-wrap: break-word;
    hyphens: auto;
    font-weight: 400;
    margin: 16px 3px 18px 0;
  }
`;
