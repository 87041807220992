import React from "react";

/** Render download SVG icon */
const DownloadFileIcon = () => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Download file</title>
      <g id="11-Topics" stroke="none" fill="none" fillRule="evenodd">
        <g id="11.00.06-Create-Topic:-Done" transform="translate(-833.000000, -416.000000)">
          <rect fill="#FFFFFF" x="0" y="0" width="1360" height="768"></rect>
          <g id="Cards-/-Service-/-Version-management-/-Resting-Copy" transform="translate(493.000000, 377.000000)">
            <path
              d="M6,0 L483,0 C486.313708,-6.08718376e-16 489,2.6862915 489,6 L489,120 C489,123.313708 486.313708,126 483,126 L6,126 C2.6862915,126 -7.58779353e-15,123.313708 0,120 L0,6 C-4.05812251e-16,2.6862915 2.6862915,6.08718376e-16 6,0 Z"
              id="Rectangle"
              stroke="#CCCCCC"
              strokeDasharray="4"
            ></path>
            <g id="Group" transform="translate(94.000000, 25.000000)">
              <g id="Group-2" transform="translate(80.000000, 14.000000)">
                <g id="Icon-/-cancel" transform="translate(166.000000, 0.000000)">
                  <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                  <path
                    d="M21.5,14.5 L21.5,17 C21.5,18.3807119 20.3807119,19.5 19,19.5 L5,19.5 C3.61928813,19.5 2.5,18.3807119 2.5,17 L2.5,14.5 M12,4.5 L12,16.5 M15.25,13 L12,16.5 L8.75,13"
                    id="Path-2"
                    stroke="#2FC56D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DownloadFileIcon;
