import { AxiosPromise } from "axios";
import ApiClient from "../../configs/axios.config";
import {
  IForgotPasswordRequest,
  ILoginRequest,
  ILoginResponse,
  IRegisterUserRequest,
  ISetNewPasswordRequest,
  ITwoFactorAuthenticationResponse,
  IValidateRegisterIdResponse,
} from "./authentication.interfaces";

const AuthenticationApi = {
  routes: {
    login: "login/",
    logout: "session/logout/",
    validateRegisterId: "validateRegisterId",
    validateForgotPasswordId: "validateForgotPasswordId",
    registerUser: "registerUser",
    session: "session",
    changePassword: "updateOwnPassword",
    setNewPassword: "changeUserPassword",
    forgotPassword: "forgotPassword",
    twoFactorAuthentication: "/v1/2fa",
    resetTwoFactorAuthentication: "/v1/2fa/reset",
  },
  methods: {
    login: (data: ILoginRequest): AxiosPromise<ILoginResponse> => {
      return ApiClient.post(AuthenticationApi.routes.login, data);
    },
    logout: (): AxiosPromise<void> => {
      return ApiClient.get(AuthenticationApi.routes.logout);
    },
    session: (): AxiosPromise<ILoginResponse> => {
      return ApiClient.get(AuthenticationApi.routes.session);
    },
    changePassword: (oldPassword: string, newPassword: string): AxiosPromise<void> => {
      return ApiClient.post(AuthenticationApi.routes.changePassword, {
        oldPassword: oldPassword,
        newPassword: newPassword,
      });
    },
    validateRegistrationId: (registerId: string): AxiosPromise<IValidateRegisterIdResponse> => {
      const url = `${AuthenticationApi.routes.validateRegisterId}?id=${registerId}`;
      return ApiClient.get(url);
    },
    validateForgotPasswordId: (pwRegId: string): AxiosPromise<IValidateRegisterIdResponse> => {
      const url = `${AuthenticationApi.routes.validateForgotPasswordId}?id=${pwRegId}`;
      return ApiClient.get(url);
    },
    registerUser: (data: IRegisterUserRequest): AxiosPromise<void> => {
      return ApiClient.post(AuthenticationApi.routes.registerUser, data);
    },
    setNewPassword: (data: ISetNewPasswordRequest): AxiosPromise<void> => {
      return ApiClient.post(AuthenticationApi.routes.setNewPassword, data);
    },
    forgotPassword: (data: IForgotPasswordRequest): AxiosPromise<void> => {
      return ApiClient.post(AuthenticationApi.routes.forgotPassword, data);
    },
    validate2FAOTPCode: (otpCode: string): AxiosPromise<void> => {
      return ApiClient.post(AuthenticationApi.routes.twoFactorAuthentication, { otpCode: otpCode });
    },
    get2FASecret: (): AxiosPromise<ITwoFactorAuthenticationResponse> => {
      return ApiClient.get(AuthenticationApi.routes.twoFactorAuthentication);
    },
    reset2FA: (userId: string): AxiosPromise<void> => {
      return ApiClient.put(AuthenticationApi.routes.resetTwoFactorAuthentication, { username: userId });
    },
  },
};

export default AuthenticationApi;
