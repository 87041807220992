import {
  AutoClosingDropdown,
  CancelIcon,
  PrimaryButton,
  SecondaryPageTitle,
  TextArea,
  TextInput,
  useAlert,
} from "@wit/mpesa-ui-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { IStoreInterface } from "../../../../configs/store.config";
import { useSelector, useDispatch } from "react-redux";
import { useContext } from "react";
import { ConfigContext } from "../../../../app.component";

import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, useFormikContext } from "formik";
import i18next from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DropEvent, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { object, string } from "yup";

import { RoutesEnum } from "../../../../routes/routes.constants";
import ImagePlaceHolderIcon from "../../../../shared/icons/image-placeholder.icon";
import { ILanguage } from "../../../../shared/models/language.model";
import { PageContent } from "../../../../shared/responsive-ui.styled";
import LanguagesApi from "../../../../shared/services/languages.api";
import {
  LoadingText,
  PageContainer,
  IconWrapper,
  DragDropText,
  DragDropTextInfo,
  DragDropFileName,
  ImageDetailsHelper,
} from "../../../../shared/shared.styled";
import DiscoverCardsApi from "../discover-cards.api";
import { DiscoverCardActionTypeEnum, DiscoverCardFlowEnum, IGalleryImage } from "../discover-cards.model";
import DiscoverCardsImageGallery from "./discover-cards-image-gallery.component";
import BundlesApi from "../../bundles/bundles.api";
import { BundlesActions } from "../../bundles/bundles.store";
import DynamicScreensApi from "../../dynamic-screens/dynamic-screens.api";
import { DynamicScreensActions } from "../../dynamic-screens/dynamic-screens.store";
import { ServicesActions } from "../../../service-builder/services.store";
import ServicesApi from "../../../service-builder/services.api";
import { ServiceStatusEnum } from "../../../../shared/models/service-builder.model";
import { filterDropdownOptions, IDropdownOptionsInterface } from "../discover-cards.utils";
import { BundleTypeStatusEnum } from "../../bundles/bundles.model";
import { DynamicScreenStatusEnum } from "../../dynamic-screens/dynamic-screens.model";
import { createImage, fileToBase64 } from "../../../../shared/shared.utils";
import { AntServiceManagerActions } from "../../../ant-service-manager/ant-service-manager.store";
import AntServiceManagerApi from "../../../ant-service-manager/ant-service-manager.api";
import GenericLanguageSelector from "../../../../shared/components/generic-language-selector.component";
import useEmptyLanguageWarningModal from "../../../../shared/hooks/use-empty-language-warning-modal.hook";

/**
 * Validator helper for name and description
 */
const getTranslationValidators = (availableLanguages: ILanguage[]) => {
  let validators: { [x: string]: any } = {};
  (availableLanguages as ILanguage[]).map((lang: ILanguage) => {
    if (lang.mandatory) {
      validators = {
        ...validators,
        [lang.code]: string()
          .trim()
          .required(i18next.t("pages.discoverCards.add.errors.mandatory")),
      };
    }
  });
  return validators;
};

/**
 *
 * @param value
 * @param setLanguage
 * @param availableLanguages
 */
const validateLanguages = (
  value: any,
  setLanguage: React.Dispatch<React.SetStateAction<string>>,
  availableLanguages: ILanguage[],
  setShowWarning: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  if (!value) {
    return false;
  }
  for (let l = 0; l < availableLanguages.length; l++) {
    if (!value[availableLanguages[l].code] && availableLanguages[l].mandatory) {
      setLanguage(availableLanguages[l].code.toString());
      return false;
    } else if (!value[availableLanguages[l].code]) {
      setShowWarning(true);
    }
  }
  return true;
};

/**
 *
 * @param actualType
 * @param setLanguage
 * @param availableLanguages
 */
export const getValidationSchema = (
  actualType: string,
  setLanguage: React.Dispatch<React.SetStateAction<string>>,
  availableLanguages: ILanguage[],
  setShowWarning: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  if (actualType === DiscoverCardActionTypeEnum.NATIVE) {
    return object().shape({
      name: object()
        .shape(getTranslationValidators(availableLanguages))
        .test("name-lang", i18next.t("pages.discoverCards.add.errors.mandatory"), value =>
          validateLanguages(value, setLanguage, availableLanguages, setShowWarning),
        ),
      description: object()
        .shape(getTranslationValidators(availableLanguages))
        .test("desc-lang", i18next.t("pages.discoverCards.add.errors.mandatory"), value =>
          validateLanguages(value, setLanguage, availableLanguages, setShowWarning),
        ),
      urlImage: string().required(i18next.t("pages.discoverCards.add.errors.mandatory")),
      endDate: string()
        .test("format", i18next.t("pages.discoverCards.add.errors.wrongFormat"), function(endDate) {
          if (!endDate) {
            return true;
          }
          const valid = moment(endDate, "DD/MM/YYYY").format("DD/MM/YYYY") === endDate;
          return valid;
        })
        .test("is-greater", i18next.t("pages.discoverCards.add.errors.timeGreater"), function(endDate) {
          if (!endDate) {
            return true;
          }
          var date = new Date();
          var formatedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
          return moment(endDate, "DD/MM/YYYY").isSameOrAfter(moment(formatedDate, "DD/MM/YYYY"));
        }),
      data: object().shape({
        type: string().required(i18next.t("pages.discoverCards.add.errors.mandatory")),
        flow: string().required(i18next.t("pages.discoverCards.add.errors.mandatory")),
      }),
    });
  } else if (actualType === DiscoverCardActionTypeEnum.DYNAMIC_SCREEN) {
    return object().shape({
      name: object()
        .shape(getTranslationValidators(availableLanguages))
        .test("name-lang", i18next.t("pages.discoverCards.add.errors.mandatory"), value =>
          validateLanguages(value, setLanguage, availableLanguages, setShowWarning),
        ),
      description: object()
        .shape(getTranslationValidators(availableLanguages))
        .test("desc-lang", i18next.t("pages.discoverCards.add.errors.mandatory"), value =>
          validateLanguages(value, setLanguage, availableLanguages, setShowWarning),
        ),
      urlImage: string().required(i18next.t("pages.discoverCards.add.errors.mandatory")),
      endDate: string()
        .test("format", i18next.t("pages.discoverCards.add.errors.wrongFormat"), function(endDate) {
          if (!endDate) {
            return true;
          }
          const valid = moment(endDate, "DD/MM/YYYY").format("DD/MM/YYYY") === endDate;
          return valid;
        })
        .test("is-greater", i18next.t("pages.discoverCards.add.errors.timeGreater"), function(endDate) {
          if (!endDate) {
            return true;
          }
          var date = new Date();
          var formatedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
          return moment(endDate, "DD/MM/YYYY").isSameOrAfter(moment(formatedDate, "DD/MM/YYYY"));
        }),
      data: object().shape({
        type: string().required(i18next.t("pages.discoverCards.add.errors.mandatory")),
        eventId: string().required(i18next.t("pages.discoverCards.add.errors.mandatory")),
      }),
    });
  } else if (actualType === DiscoverCardActionTypeEnum.MINI_APP) {
    return object().shape({
      name: object()
        .shape(getTranslationValidators(availableLanguages))
        .test("name-lang", i18next.t("pages.discoverCards.add.errors.mandatory"), value =>
          validateLanguages(value, setLanguage, availableLanguages, setShowWarning),
        ),
      description: object()
        .shape(getTranslationValidators(availableLanguages))
        .test("desc-lang", i18next.t("pages.discoverCards.add.errors.mandatory"), value =>
          validateLanguages(value, setLanguage, availableLanguages, setShowWarning),
        ),
      urlImage: string().required(i18next.t("pages.discoverCards.add.errors.mandatory")),
      endDate: string()
        .test("format", i18next.t("pages.discoverCards.add.errors.wrongFormat"), function(endDate) {
          if (!endDate) {
            return true;
          }
          const valid = moment(endDate, "DD/MM/YYYY").format("DD/MM/YYYY") === endDate;
          return valid;
        })
        .test("is-greater", i18next.t("pages.discoverCards.add.errors.timeGreater"), function(endDate) {
          if (!endDate) {
            return true;
          }
          var date = new Date();
          var formatedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
          return moment(endDate, "DD/MM/YYYY").isSameOrAfter(moment(formatedDate, "DD/MM/YYYY"));
        }),
      data: object().shape({
        type: string().required(i18next.t("pages.discoverCards.add.errors.mandatory")),
        uri: string().required(i18next.t("pages.discoverCards.add.errors.mandatory")),
      }),
    });
  } else if (actualType === DiscoverCardActionTypeEnum.BUNDLE) {
    return object().shape({
      name: object()
        .shape(getTranslationValidators(availableLanguages))
        .test("name-lang", i18next.t("pages.discoverCards.add.errors.mandatory"), value =>
          validateLanguages(value, setLanguage, availableLanguages, setShowWarning),
        ),
      description: object()
        .shape(getTranslationValidators(availableLanguages))
        .test("desc-lang", i18next.t("pages.discoverCards.add.errors.mandatory"), value =>
          validateLanguages(value, setLanguage, availableLanguages, setShowWarning),
        ),
      urlImage: string().required(i18next.t("pages.discoverCards.add.errors.mandatory")),
      endDate: string()
        .test("format", i18next.t("pages.discoverCards.add.errors.wrongFormat"), function(endDate) {
          if (!endDate) {
            return true;
          }
          const valid = moment(endDate, "DD/MM/YYYY").format("DD/MM/YYYY") === endDate;
          return valid;
        })
        .test("is-greater", i18next.t("pages.discoverCards.add.errors.timeGreater"), function(endDate) {
          if (!endDate) {
            return true;
          }
          var date = new Date();
          var formatedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
          return moment(endDate, "DD/MM/YYYY").isSameOrAfter(moment(formatedDate, "DD/MM/YYYY"));
        }),
      data: object().shape({
        type: string().required(i18next.t("pages.discoverCards.add.errors.mandatory")),
        uri: string().required(i18next.t("pages.discoverCards.add.errors.mandatory")),
      }),
    });
  } else if (actualType === DiscoverCardActionTypeEnum.NONE) {
    return object().shape({
      name: object()
        .shape(getTranslationValidators(availableLanguages))
        .test("name-lang", i18next.t("pages.discoverCards.add.errors.mandatory"), value =>
          validateLanguages(value, setLanguage, availableLanguages, setShowWarning),
        ),
      description: object()
        .shape(getTranslationValidators(availableLanguages))
        .test("desc-lang", i18next.t("pages.discoverCards.add.errors.mandatory"), value =>
          validateLanguages(value, setLanguage, availableLanguages, setShowWarning),
        ),
      urlImage: string().required(i18next.t("pages.discoverCards.add.errors.mandatory")),
      endDate: string()
        .test("format", i18next.t("pages.discoverCards.add.errors.wrongFormat"), function(endDate) {
          if (!endDate) {
            return true;
          }
          const valid = moment(endDate, "DD/MM/YYYY").format("DD/MM/YYYY") === endDate;
          return valid;
        })
        .test("is-greater", i18next.t("pages.discoverCards.add.errors.timeGreater"), function(endDate) {
          if (!endDate) {
            return true;
          }
          var date = new Date();
          var formatedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
          return moment(endDate, "DD/MM/YYYY").isSameOrAfter(moment(formatedDate, "DD/MM/YYYY"));
        }),
      data: object().shape({
        type: string().required(i18next.t("pages.discoverCards.add.errors.mandatory")),
      }),
    });
  } else {
    return object().shape({
      name: object()
        .shape(getTranslationValidators(availableLanguages))
        .test("name-lang", i18next.t("pages.discoverCards.add.errors.mandatory"), value =>
          validateLanguages(value, setLanguage, availableLanguages, setShowWarning),
        ),
      description: object()
        .shape(getTranslationValidators(availableLanguages))
        .test("desc-lang", i18next.t("pages.discoverCards.add.errors.mandatory"), value =>
          validateLanguages(value, setLanguage, availableLanguages, setShowWarning),
        ),
      urlImage: string().required(i18next.t("pages.discoverCards.add.errors.mandatory")),
      endDate: string()
        .test("format", i18next.t("pages.discoverCards.add.errors.wrongFormat"), function(endDate) {
          if (!endDate) {
            return true;
          }
          const valid = moment(endDate, "DD/MM/YYYY").format("DD/MM/YYYY") === endDate;
          return valid;
        })
        .test("is-greater", i18next.t("pages.discoverCards.add.errors.timeGreater"), function(endDate) {
          if (!endDate) {
            return true;
          }
          var date = new Date();
          var formatedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
          return moment(endDate, "DD/MM/YYYY").isSameOrAfter(moment(formatedDate, "DD/MM/YYYY"));
        }),
      data: object().shape({
        type: string().required(i18next.t("pages.discoverCards.add.errors.mandatory")),
        uri: string().required(i18next.t("pages.discoverCards.add.errors.mandatory")),
      }),
    });
  }
};

/**
 * Add discover card
 */
const AddDiscoverCardPage = () => {
  const [t] = useTranslation();
  const { id } = useParams<any>();
  const history = useHistory();
  const [language, setLanguage] = useState("en");
  const [img, setImg] = useState<File | undefined>();
  const [imgName, setImgName] = useState<string | undefined>();
  const [imgUrl, setImgUrl] = useState<string | File | undefined>();
  const [actualStatus, setActualStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [availableLanguages, setAvailableLanguages] = useState<ILanguage[]>();
  const bundles = useSelector((store: IStoreInterface) => store.bundlesReducer.bundles);
  const dynamicScreens = useSelector((store: IStoreInterface) => store.dynamicScreensReducer.dynamicScreens.items);
  const miniApps = useSelector((store: IStoreInterface) => store.servicesReducer.services);
  const antMiniApps = useSelector((store: IStoreInterface) => store.antServiceManagerReducer.services);
  const { config } = useContext(ConfigContext);
  const dispatch = useDispatch();

  const [availableMiniApp, setAvailableMiniApp] = useState(true);
  const [availableBundle, setAvailableBundle] = useState(true);
  const [availableDynamicScreen, setAvailableDynamicScreen] = useState(true);
  const [unavailableItem, setUnavailableItem] = useState(false);

  const [actualType, setActualType] = useState("");
  const [showAlert, , setAlertProps] = useAlert();
  const [showImageDetailsError, setShowImageDetailsError] = useState(false);
  const [showGallery, setShowGallery] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [initialValues, setInitialValues] = useState({
    description: undefined,
    name: undefined,
    endDate: "",
    urlImage: "",
    data: {
      type: "",
      uri: "",
      flow: "",
      eventId: "",
    },
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
    maxSize: 2000000,
    onDropAccepted<T extends File>(files: T[], event: DropEvent) {
      const [url, icon] = createImage(event);
      files.map(file => {
        const image = new Image();
        image.addEventListener("load", () => {
          if (image.width >= 500 && image.height >= 500) {
            setImgUrl(url);
            setImg(icon);
            setImgName(file.name);
            setShowImageDetailsError(false);
          } else {
            showImageRequirementsAlert();
            removeImage();
            setShowImageDetailsError(true);
          }
        });
        image.src = URL.createObjectURL(file);
      });
    },
    onDropRejected<T extends File>(files: T[], event: DropEvent) {
      showImageRequirementsAlert();
      setImgUrl(undefined);
      setImg(undefined);
      setShowImageDetailsError(true);
    },
  });

  /**
   * Show image requirements
   */
  const showImageRequirementsAlert = () => {
    setAlertProps({
      type: AlertTypeEnum.ERROR,
      title: t("pages.discoverCards.add.imageDimensionsErrorTitle"),
      content: t("pages.discoverCards.add.imageDimensionsErrorMessage"),
    });
    showAlert();
  };

  /**
   * Fetch type of links send in the configuration file from the backend
   * In case the typeOfLinks object is undefined or empty return a default list
   */
  let typeOptions =
    config && config.discoverCards && config.discoverCards.typeOfLinks
      ? config.discoverCards.typeOfLinks.sort().map(options => ({
          key: options,
          label: t(`commons.typeOfLinkDropDown.${options}`),
        }))
      : [
          {
            key: DiscoverCardActionTypeEnum.NONE,
            label: t("commons.typeOfLinkDropDown.NONE"),
          },
          {
            key: DiscoverCardActionTypeEnum.EXTERNAL_LINK,
            label: t("commons.typeOfLinkDropDown.EXTERNAL_LINK"),
          },
          {
            key: DiscoverCardActionTypeEnum.NATIVE,
            label: t("commons.typeOfLinkDropDown.NATIVE"),
          },
        ];

  const flowOptions = (config && config.discoverCards && config.discoverCards.availableAppFlows
    ? config.discoverCards.availableAppFlows
    : Object.values(DiscoverCardFlowEnum)
  )
    .sort()
    .map(flow => ({
      label: i18next.t(`commons.internalFlowDropDown.${flow}`),
      key: flow,
    }));

  /**
   * Filter bundle accepted options
   */
  const bundleOptions =
    availableLanguages && filterDropdownOptions(bundles, [BundleTypeStatusEnum.LIVE], availableLanguages[0]?.code);

  /**
   * Filter Dynamic screen accepted options
   */
  const dynamicScreenOptions =
    availableLanguages &&
    filterDropdownOptions(
      dynamicScreens,
      [
        DynamicScreenStatusEnum.LIVE,
        DynamicScreenStatusEnum.DISABLED,
        DynamicScreenStatusEnum.DISABLE,
        DynamicScreenStatusEnum.EDIT,
        DynamicScreenStatusEnum.ENABLE,
      ],
      availableLanguages[0].code,
    );

  /**
   * Filter mini apps accepted options
   */
  const miniAppOptions = filterDropdownOptions([...miniApps, ...antMiniApps], [ServiceStatusEnum.LIVE]);

  /**
   * Get labels for each dropdown link
   * @param type
   */
  const getOptionLabel = (type: string) => {
    if (initialValues.data.type !== undefined && id) {
      return t(`commons.typeOfLinkDropDown.${actualType}`);
    } else {
      return type && typeOptions.find(opt => opt.key === type)
        ? (typeOptions.find(opt => opt.key === type) as any).label
        : t("pages.discoverCards.add.chooseLink");
    }
  };

  /**
   * Get flow label
   * @param type
   */
  const getFlowLabel = (type: string) => {
    return type && flowOptions.find(opt => opt.key === type)
      ? (flowOptions.find(opt => opt.key === type) as any).label
      : t("pages.discoverCards.add.chooseFlow");
  };

  /**
   * Get bundle label
   * @param type
   */
  const getBundleLabel = (type: string) => {
    let bundleType: string;
    //Fetch bundle key from url on EDIT
    if (type && id && type.includes("mpesa://bundle")) {
      const formatedType = type.split("&");
      const bundleKey = formatedType[1].split("bundleTypeName=");
      bundleType = bundleKey[1];

      if (bundleOptions && bundleOptions.length === 0) {
        setAvailableBundle(false);
        setUnavailableItem(true);
      } else if (bundleOptions && !bundleOptions.find(bundle => bundle.key === bundleType)) {
        setAvailableBundle(true);
        setUnavailableItem(true);
        return t("pages.discoverCards.add.unableToFindItem");
      } else {
        setAvailableBundle(true);
        setUnavailableItem(false);
      }
    } else {
      setUnavailableItem(false);
      bundleType = type;
    }
    return type && bundleOptions && bundleOptions.find(opt => opt.key === (bundleType as string))
      ? (bundleOptions && (bundleOptions.find(opt => opt.key === bundleType) as any)).label
      : t("pages.discoverCards.add.chooseBundle");
  };

  /**
   * Get dynamic screen label
   * @param type
   */
  const getDynamicScreenLabel = (type: string) => {
    if (id && initialValues.data.type === DiscoverCardActionTypeEnum.DYNAMIC_SCREEN) {
      if (dynamicScreenOptions && dynamicScreenOptions.length === 0) {
        setAvailableDynamicScreen(false);
        setUnavailableItem(true);
      } else if (dynamicScreenOptions && !dynamicScreenOptions.find(dynamic => dynamic.key === type)) {
        setUnavailableItem(true);
        setAvailableDynamicScreen(true);
        return t("pages.discoverCards.add.unableToFindItem");
      } else {
        setAvailableDynamicScreen(true);
        setUnavailableItem(false);
      }
    } else {
      setUnavailableItem(false);
    }

    return type && dynamicScreenOptions && dynamicScreenOptions.find(opt => opt.key === type)
      ? (dynamicScreenOptions && (dynamicScreenOptions.find(opt => opt.key === type) as any)).label
      : t("pages.discoverCards.add.chooseDynamicScreen");
  };

  /**
   * Get mini app label
   * @param type
   */
  const getMiniAppLabel = (type: string) => {
    let miniAppType: string;
    //Fetch mini app key from url on EDIT
    if (type && id && type.includes("mpesa://mini-app")) {
      const formatedType = type.split("?");
      const bundleKey = formatedType[1].split("id=");
      miniAppType = bundleKey[1];

      if (miniAppOptions.length === 0) {
        setAvailableMiniApp(false);
        setUnavailableItem(true);
      } else if (!miniAppOptions.find(app => app.key === miniAppType)) {
        setAvailableMiniApp(true);
        setUnavailableItem(true);
        return t("pages.discoverCards.add.unableToFindItem");
      } else {
        setAvailableMiniApp(true);
        setUnavailableItem(false);
      }
    } else {
      setUnavailableItem(false);
      miniAppType = type;
    }
    return type && miniAppOptions && miniAppOptions.find(opt => opt.key === miniAppType)
      ? (miniAppOptions && (miniAppOptions.find(opt => opt.key === miniAppType) as any)).label
      : t("pages.discoverCards.add.chooseMiniApp");
  };

  /**
   * checkFeaturesLength - Removes the list features if they are empty - Eg: Bundle/Mini apps/Dynamic screens list
   * @returns typeOptions {string[]}
   */
  const checkFeaturesLength = () => {
    if (bundleOptions && bundleOptions.length <= 0) {
      typeOptions = typeOptions.filter(element => element.key !== DiscoverCardActionTypeEnum.BUNDLE);
    }
    if (dynamicScreenOptions === undefined || dynamicScreenOptions.length <= 0) {
      typeOptions = typeOptions.filter(element => element.key !== DiscoverCardActionTypeEnum.DYNAMIC_SCREEN);
    }
    if (miniAppOptions.length <= 0) {
      typeOptions = typeOptions.filter(element => element.key !== DiscoverCardActionTypeEnum.MINI_APP);
    }

    return typeOptions;
  };

  React.useEffect(() => {
    LanguagesApi.methods.getAvailableLanguages().then(
      res => {
        setAvailableLanguages(res.data.availableLanguages);
        const languages = res.data.availableLanguages.map((lang: ILanguage) => lang.code);
      },
      () => {
        setAvailableLanguages([
          { id: "1", code: "en", name: "English", urlIcon: "ic_flag_uk.png" },
          {
            id: "2",
            code: "sw",
            name: "Swahili (Tanzania)",
            urlIcon: "ic_flag_tanzania.png",
          },
        ]);
        setAlertProps({
          type: AlertTypeEnum.ERROR,
          title: t("commons.getLanguagesError"),
        });
        showAlert();
      },
    );
  }, []);

  useEffect(() => {
    if (id) {
      const currentDate = new Date().getTime();
      DiscoverCardsApi.methods
        .getDiscoverCard(id)
        .then(
          res => {
            //Check if the dataType is deepLink, if it is replace the deepLinkType with the data type value
            const deepLink = { ...res.data };
            deepLink.data.type = deepLink.deepLinkType ? deepLink.deepLinkType : deepLink.data.type;
            const values = {
              description: res.data.description,
              name: res.data.name,
              endDate:
                res.data.endDate && res.data.endDate !== 0 ? moment(res.data.endDate, "x").format("DD/MM/YYYY") : "",
              urlImage: `${res.data.urlImage}?time=${currentDate}`,
              data: deepLink.data,
            };
            setActualStatus(res.data.status);
            setInitialValues(values);
            setActualType(values.data.type);
            setImgUrl(`${res.data.urlImage}?time=${currentDate}`);
            setImgName(res.data.imageName ? res.data.imageName : "Image without a name");
          },
          () => {
            setAlertProps({
              title: t("pages.discoverCards.add.errors.get"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          },
        )
        .then(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  /**
   * Load store values if they are empty
   */
  useEffect(() => {
    if (bundles.length === 0 && typeOptions.find(option => option.key === DiscoverCardActionTypeEnum.BUNDLE)) {
      getBundles();
    }
    if (
      dynamicScreens === undefined &&
      typeOptions.find(option => option.key === DiscoverCardActionTypeEnum.DYNAMIC_SCREEN)
    ) {
      getDynamicScreens();
    }
    if (miniApps.length === 0 && typeOptions.find(option => option.key === DiscoverCardActionTypeEnum.MINI_APP)) {
      getMiniApps();
    }
    if (antMiniApps.length === 0 && typeOptions.find(option => option.key === DiscoverCardActionTypeEnum.MINI_APP)) {
      getAntMiniApps();
    }
  }, []);

  /**
   * Gets the list of bundle types
   */
  const getBundles = () => {
    BundlesApi.methods.getBundles().then(
      res => {
        dispatch(BundlesActions.creators.getBundlesSuccessAction(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.bundles.getBundlesError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * Get dynamic screens list
   */
  const getDynamicScreens = () => {
    DynamicScreensApi.methods.getDynamicScreens().then(
      res => {
        dispatch(DynamicScreensActions.creators.getDynamicScreensSuccess(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.dynamicScreens.getDynamicScreensError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * Get mini apps list
   */
  const getMiniApps = () => {
    dispatch(ServicesActions.creators.fetchingServicesAction());
    ServicesApi.methods.getServices().then(
      res => {
        dispatch(ServicesActions.creators.fetchServicesSuccessAction(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.serviceBuilder.errors.searchServices"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * Get ant mini apps list
   */
  const getAntMiniApps = () => {
    dispatch(AntServiceManagerActions.creators.fetchingServicesAction());
    AntServiceManagerApi.methods.getServices().then(
      res => {
        dispatch(AntServiceManagerActions.creators.fetchServicesSuccessAction(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.serviceBuilder.errors.searchServices"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };
  const { warningModalOpen, showWarning, setShowWarning } = useEmptyLanguageWarningModal(availableLanguages || []);
  /**
   * Submit method wrapper to show warning if needed
   * @param values
   */
  const submitWrapper = (values: any) => {
    if (showWarning) {
      setShowWarning(false);
      warningModalOpen(submit, values);
    } else {
      setShowWarning(false);
      submit(values);
    }
  };

  /**
   * Submit method
   * @param values
   */
  const submit = async (values: any) => {
    setIsLoading(true);
    const deepLinkList = [DiscoverCardActionTypeEnum.MINI_APP, DiscoverCardActionTypeEnum.BUNDLE];
    const base64Image = await fileToBase64(img);
    const requestData: any = {
      name: values.name,
      description: values.description,
      endDate: values.endDate === "" ? "" : moment.utc(values.endDate, "DD/MM/YYYY").format("x"),
      type: deepLinkList.includes(values.data.type) ? DiscoverCardActionTypeEnum.DEEP_LINK : values.data.type,
      data: {},
      urlImage: img ? null : imgName,
      base64Image,
      imageName: imgName,
      status: actualStatus,
    };
    //For NATIVE
    if (values.data.type === DiscoverCardActionTypeEnum.NATIVE) {
      requestData.data = { type: values.data.type, flow: values.data.flow };
    } //For DYNAMIC SCREENS
    else if (values.data.type === DiscoverCardActionTypeEnum.DYNAMIC_SCREEN) {
      requestData.data = { type: values.data.type, eventId: values.data.eventId };
    } //For BUNDLES
    else if (values.data.type === DiscoverCardActionTypeEnum.BUNDLE) {
      requestData.data = {
        type: DiscoverCardActionTypeEnum.DEEP_LINK,
        uri: values.data.uri,
      };
      requestData.deepLinkType = DiscoverCardActionTypeEnum.BUNDLE;
    } //For MINI_APP
    else if (values.data.type === DiscoverCardActionTypeEnum.MINI_APP) {
      requestData.data = {
        type: DiscoverCardActionTypeEnum.DEEP_LINK,
        uri: values.data.uri,
      };
      requestData.deepLinkType = DiscoverCardActionTypeEnum.MINI_APP;
    } //For NONE
    else if (values.data.type === DiscoverCardActionTypeEnum.NONE) {
      requestData.data = { type: DiscoverCardActionTypeEnum.NONE };
    } else {
      requestData.data = { type: values.data.type, uri: values.data.uri };
    }
    if (!id) {
      DiscoverCardsApi.methods.createDiscoverCard(requestData).then(
        _res => {
          setAlertProps({
            title: t("pages.discoverCards.add.success"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          setIsLoading(false);
          history.push(RoutesEnum.DISCOVER_CARDS);
        },
        _err => {
          setAlertProps({
            title: t("pages.discoverCards.add.errors.create"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
          setIsLoading(false);
        },
      );
    } else {
      DiscoverCardsApi.methods.EditDiscoverCard(requestData, id).then(
        _res => {
          setAlertProps({
            title: t("pages.discoverCards.edit.success"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          setIsLoading(false);
          history.push(RoutesEnum.DISCOVER_CARDS);
        },
        _err => {
          setAlertProps({
            title: t("pages.discoverCards.edit.error"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
          setIsLoading(false);
        },
      );
    }
  };

  /**
   * Verify image
   */
  const VerifyImage = () => {
    const { values, setFieldValue } = useFormikContext();
    React.useEffect(() => {
      if (imgUrl) {
        setFieldValue("urlImage", imgUrl);
      } else {
        setFieldValue("urlImage", undefined);
      }
    }, [values, imgUrl]);
    return null;
  };

  /**
   * Remove image
   */
  const removeImage = () => {
    setImgUrl(undefined);
    setImg(undefined);
    setImgName(undefined);
  };

  /**
   * display gallery
   */
  const displayGallery = () => {
    setShowGallery(true);
  };

  /**
   * Hide gallery
   */
  const hideGallery = () => {
    setShowGallery(false);
  };

  /**
   * Select gallery
   * @param image
   */
  const selectGalleryImage = (image: IGalleryImage) => {
    setImgUrl(image.url);
    setImgName(image.filename);
    setShowImageDetailsError(false);
  };

  /**
   * Check form
   * @param values
   */
  const checkFormSubmitButton = (values: any) => {
    if (unavailableItem) {
      return true;
    }
    if (JSON.stringify(initialValues) === JSON.stringify(values)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <PageContainer>
      <SecondaryPageTitle
        displayInitialsCircle={false}
        title={id ? t("pages.discoverCards.add.titleEdit") : t("pages.discoverCards.add.title")}
        goBackFn={() => history.push(RoutesEnum.DISCOVER_CARDS)}
      />
      {loading && <LoadingText>{t("commons.loadingResults")}</LoadingText>}
      {!loading && availableLanguages && (
        <FormContainer>
          <Formik<any>
            onSubmit={submitWrapper}
            initialValues={initialValues}
            validateOnChange={false}
            validationSchema={getValidationSchema(
              actualType,
              setLanguage,
              availableLanguages as ILanguage[],
              setShowWarning,
            )}
            render={({ handleChange, values, handleSubmit, setFieldValue, errors }) => {
              return (
                <form className="main-form" onSubmit={handleSubmit}>
                  <VerifyImage />
                  <div className="form-section main-content">
                    <FormSectionContainer>
                      <FormSectionRow key={"title"}>
                        <FormSectionTitle>{t("pages.discoverCards.add.cardDetails")}</FormSectionTitle>
                        <GenericLanguageSelector
                          selectedLanguage={language}
                          availableLanguages={availableLanguages.map(lang => lang.code)}
                          changeSelectedLanguage={lang => setLanguage(lang.key)}
                        ></GenericLanguageSelector>
                      </FormSectionRow>
                      <FormSectionRow>
                        <SectionColumn>
                          <FormLabel required={availableLanguages.find(l => l.code === language)?.mandatory}>
                            {t("pages.discoverCards.add.cardTitle")}
                          </FormLabel>
                        </SectionColumn>
                        <SectionColumn>
                          <TextInput
                            value={(values.name && values.name[language]) || ""}
                            name={`name.${language}`}
                            onChange={handleChange}
                            error={errors.name && (errors.name as any)[language] ? (errors.name as any)[language] : ""}
                            placeholder={`${t("pages.discoverCards.add.cardTitle")} (${language.toLocaleUpperCase()})`}
                          ></TextInput>
                        </SectionColumn>
                      </FormSectionRow>
                      <FormSectionRow>
                        <SectionColumn>
                          <FormLabel required={availableLanguages.find(l => l.code === language)?.mandatory}>
                            {t("pages.discoverCards.add.cardDescription")}
                          </FormLabel>
                          <FormLabelSecondary>
                            (
                            {(values.description &&
                              values.description[language] &&
                              values.description[language].length) ||
                              "0"}
                            /140)
                          </FormLabelSecondary>
                        </SectionColumn>
                        <SectionColumn>
                          <TextArea
                            value={(values.description && values.description[language]) || ""}
                            name={`description.${language}`}
                            onChange={e => {
                              handleChange(e);
                            }}
                            maxHeight={"100px"}
                            maxLength={140}
                            verticalResize={false}
                            placeholder={`${t(
                              "pages.discoverCards.add.cardDescription",
                            )} (${language.toLocaleUpperCase()})`}
                          ></TextArea>
                          <ErrorField>
                            {errors.description && (errors.description as any)[language]
                              ? (errors.description as any)[language]
                              : ""}
                          </ErrorField>
                        </SectionColumn>
                      </FormSectionRow>
                    </FormSectionContainer>
                    {/* Settings */}
                    <FormSectionContainer>
                      <FormSectionRow key={"title"}>
                        <FormSectionTitle>{t("pages.discoverCards.add.cardSettings")}</FormSectionTitle>
                      </FormSectionRow>
                      <FormSectionRow>
                        <SectionColumn>
                          <FormLabel required={false}>{t("pages.discoverCards.add.expiryDate")}</FormLabel>
                        </SectionColumn>
                        <SectionColumn>
                          <TextInput
                            value={values.endDate}
                            name="endDate"
                            onChange={handleChange}
                            error={errors.endDate as string}
                            placeholder={"DD / MM / YY"}
                          ></TextInput>
                        </SectionColumn>
                      </FormSectionRow>
                      <FormSectionRow>
                        <SectionColumn>
                          <FormLabel required={true}>{t("pages.discoverCards.add.typeOfLink")}</FormLabel>
                        </SectionColumn>
                        <SectionColumn id="type-dropdown">
                          <AutoClosingDropdown
                            error={errors.data && ((errors.data as any).type as string)}
                            label={getOptionLabel(values.data.type)}
                            hasValue={values.data.type ? true : false}
                            selectOption={opt => {
                              //reset uri value on change
                              values.data.uri = "";
                              setFieldValue("data.type", opt.key);
                              setActualType(opt.key);
                            }}
                            dropdownType={DropdownType.RECTANGULAR_NORMAL}
                            options={checkFeaturesLength()}
                          ></AutoClosingDropdown>
                        </SectionColumn>
                      </FormSectionRow>
                      <FormSectionRow
                        hide={!(values.data.type && values.data.type === DiscoverCardActionTypeEnum.EXTERNAL_LINK)}
                      >
                        <SectionColumn>
                          <FormLabel required={true}>{t("commons.typeOfLinkDropDown.EXTERNAL_LINK")}</FormLabel>
                        </SectionColumn>
                        <SectionColumn>
                          <TextInput
                            value={values.data.uri}
                            error={errors.data && ((errors.data as any).uri as string)}
                            name="data.uri"
                            placeholder={t("pages.discoverCards.add.addURI")}
                            onChange={handleChange}
                          ></TextInput>
                        </SectionColumn>
                      </FormSectionRow>
                      <FormSectionRow
                        hide={!(values.data.type && values.data.type === DiscoverCardActionTypeEnum.DYNAMIC_SCREEN)}
                      >
                        <SectionColumn>
                          <FormLabel required={true}> {t("commons.typeOfLinkDropDown.DYNAMIC_SCREEN")}</FormLabel>
                        </SectionColumn>
                        <SectionColumn>
                          {availableDynamicScreen ? (
                            <DropdownWrapper id="flow-dropdown">
                              <AutoClosingDropdown
                                error={errors.data && ((errors.data as any).eventId as string)}
                                label={getDynamicScreenLabel(values.data.eventId)}
                                hasValue={values.data.eventId ? true : false}
                                selectOption={opt => setFieldValue("data.eventId", opt.key)}
                                dropdownType={DropdownType.RECTANGULAR_NORMAL}
                                options={dynamicScreenOptions as IDropdownOptionsInterface[]}
                              ></AutoClosingDropdown>
                            </DropdownWrapper>
                          ) : (
                            <FormLabel>{t("pages.discoverCards.add.unableToFindItem")}</FormLabel>
                          )}
                        </SectionColumn>
                      </FormSectionRow>
                      <FormSectionRow
                        hide={!(values.data.type && values.data.type === DiscoverCardActionTypeEnum.NATIVE)}
                      >
                        <SectionColumn>
                          <FormLabel required={true}>{t("commons.typeOfLinkDropDown.NATIVE")}</FormLabel>
                        </SectionColumn>
                        <SectionColumn>
                          <DropdownWrapper id="flow-dropdown">
                            <AutoClosingDropdown
                              error={errors.data && ((errors.data as any).flow as string)}
                              label={getFlowLabel(values.data.flow)}
                              hasValue={values.data.flow ? true : false}
                              selectOption={opt => setFieldValue("data.flow", opt.key)}
                              dropdownType={DropdownType.RECTANGULAR_NORMAL}
                              options={flowOptions}
                            ></AutoClosingDropdown>
                          </DropdownWrapper>
                        </SectionColumn>
                      </FormSectionRow>
                      {/*Bundles & Mini Apps*/}
                      <FormSectionRow
                        hide={!(values.data.type && values.data.type === DiscoverCardActionTypeEnum.MINI_APP)}
                      >
                        <SectionColumn>
                          <FormLabel required={true}>{t("commons.typeOfLinkDropDown.MINI_APP")}</FormLabel>
                        </SectionColumn>
                        <SectionColumn>
                          {availableMiniApp ? (
                            <DropdownWrapper id="flow-dropdown">
                              <AutoClosingDropdown
                                error={errors.data && ((errors.data as any).uri as string)}
                                label={getMiniAppLabel(values.data.uri)}
                                hasValue={values.data.uri ? true : false}
                                selectOption={opt => setFieldValue("data.uri", opt.key)}
                                dropdownType={DropdownType.RECTANGULAR_NORMAL}
                                options={miniAppOptions as IDropdownOptionsInterface[]}
                              ></AutoClosingDropdown>
                            </DropdownWrapper>
                          ) : (
                            <FormLabel>{t("pages.discoverCards.add.unableToFindItem")}</FormLabel>
                          )}
                        </SectionColumn>
                      </FormSectionRow>
                      <FormSectionRow
                        hide={!(values.data.type && values.data.type === DiscoverCardActionTypeEnum.BUNDLE)}
                      >
                        <SectionColumn>
                          <FormLabel required={true}>{t("commons.typeOfLinkDropDown.BUNDLE")}</FormLabel>
                        </SectionColumn>
                        <SectionColumn>
                          {availableBundle ? (
                            <DropdownWrapper id="flow-dropdown">
                              <AutoClosingDropdown
                                error={errors.data && ((errors.data as any).uri as string)}
                                label={getBundleLabel(values.data.uri)}
                                hasValue={values.data.uri ? true : false}
                                selectOption={opt => setFieldValue("data.uri", opt.key)}
                                dropdownType={DropdownType.RECTANGULAR_NORMAL}
                                options={bundleOptions as IDropdownOptionsInterface[]}
                              ></AutoClosingDropdown>
                            </DropdownWrapper>
                          ) : (
                            <FormLabel>{t("pages.discoverCards.add.unableToFindItem")}</FormLabel>
                          )}
                        </SectionColumn>
                      </FormSectionRow>
                    </FormSectionContainer>
                  </div>
                  <div className="image-section side-menu">
                    <SectionColumn>
                      <FormLabel required={true}>{t("pages.discoverCards.add.cardImage")}</FormLabel>
                      <ErrorField>{errors.urlImage}</ErrorField>
                    </SectionColumn>

                    <ImageContainer
                      {...getRootProps({
                        className: "dropzone",
                      })}
                      url={values.urlImage}
                    >
                      <input {...getInputProps()} />
                      {!values.urlImage && (
                        <>
                          <ImagePlaceHolderIcon></ImagePlaceHolderIcon>

                          <DragDropText>
                            Drag and drop, <DragDropTextInfo>browse files</DragDropTextInfo> or{" "}
                            <DragDropTextInfo
                              onClick={e => {
                                e.stopPropagation();
                                displayGallery();
                              }}
                            >
                              open gallery
                            </DragDropTextInfo>
                          </DragDropText>
                        </>
                      )}
                    </ImageContainer>
                    {imgName ? (
                      <div
                        style={{
                          marginTop: "18px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <DragDropFileName>{imgName ? imgName : ""}</DragDropFileName>
                        <IconWrapper color={styleTheme.palette.vodafoneRed}>
                          <CancelIcon onClick={removeImage} />
                        </IconWrapper>
                      </div>
                    ) : null}
                    {!imgUrl ? (
                      <ImageDetailsHelper
                        color={showImageDetailsError ? styleTheme.palette.vodafoneRed : styleTheme.palette.midGrey}
                        dangerouslySetInnerHTML={{
                          __html: t("pages.discoverCards.add.imageDetailsHelper"),
                        }}
                      />
                    ) : null}
                    <ButtonsContainer className="buttons-container">
                      {!id && (
                        <div>
                          <PrimaryButton
                            disabled={checkFormSubmitButton(values) || isLoading}
                            id="create-discover-card-button"
                            redTheme={true}
                            type="button"
                            onClick={() => handleSubmit()}
                            titleLabel={
                              id ? t("pages.discoverCards.add.editButton") : t("pages.discoverCards.add.createButton")
                            }
                          />
                        </div>
                      )}
                      {id && (
                        <>
                          <div>
                            <PrimaryButton
                              id="cancel-button"
                              redTheme={false}
                              type="button"
                              onClick={() => history.push(RoutesEnum.DISCOVER_CARDS)}
                              titleLabel={t("pages.discoverCards.add.cancel")}
                            />
                          </div>
                          <div>
                            <PrimaryButton
                              disabled={checkFormSubmitButton(values) || isLoading}
                              id="save-changes-button"
                              redTheme={true}
                              type="button"
                              onClick={() => handleSubmit()}
                              titleLabel={t("pages.discoverCards.add.editButton")}
                            />
                          </div>
                        </>
                      )}
                    </ButtonsContainer>
                    {showGallery ? (
                      <DiscoverCardsImageGallery selectImage={selectGalleryImage} hideDrawer={hideGallery} />
                    ) : null}
                  </div>
                </form>
              );
            }}
          />
        </FormContainer>
      )}
    </PageContainer>
  );
};

export default AddDiscoverCardPage;

const FormContainer = styled(PageContent)`
  form {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .form-section {
    width: 60%;
  }

  .image-section {
    width: 40%;
    margin-left: 46px;
    margin-top: 20px;
  }

  textarea {
    font-size: 16px;
    height: 100px;
  }
`;

const c = {
  rowMinHeight: 55,
};

export const FormSectionRow = styled("div")<{ hide?: boolean }>`
  display: ${props => (props.hide ? "none" : "flex")};
  justify-content: space-between;
  align-items: center;
  min-height: ${c.rowMinHeight}px;
  padding-top: 9px;
  padding-bottom: 9px;
  flex: 1;
`;

const ImageContainer = styled.div<{ url?: string }>`
  width: 200px;
  height: 200px;
  border-radius: 6px;
  border: solid 1px;
  border-color: ${props => props.theme.palette.aluminium};
  background-image: ${props => (props.url ? `url(${props.url})` : "none")};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 25px;
  background-color: #e9f5ff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
`;

export const FormSectionContainer = styled("div")`
  margin-bottom: 40px;
  ${FormSectionRow}:not(:first-of-type) {
    border-bottom: 1px solid ${props => props.theme.palette.greyLightest};
  }
`;

export const FormSectionTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.black};
`;

export const SectionColumn = styled("div")`
  flex: 1;
  max-width: 50%;
`;

export const FormLabel = styled.span<{ required?: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.greyDarker};

  ${props =>
    props.required
      ? `
::after {
    content: ' *';
    font-family: Vodafone Rg;
    font-size: 16px;
    font-weight: normal;
    color: ${props.theme.palette.errorRed};
}
`
      : ""}
`;

export const FormLabelSecondary = styled(FormLabel)`
  font-size: 14px;
  font-weight: inherit;
  display: inherit;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 300px;

  > div {
    width: "fit-content";

    :first-of-type {
      margin-right: 12px;
    }
  }
`;

const ErrorField = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Vodafone Rg;
  font-size: 14px;
  color: #ff0000;
  margin-top: 4px;
`;

const DropdownWrapper = styled.div`
  > button {
    > div {
      min-width: -webkit-fill-available !important;
    }
  }
`;
