import { produce } from "immer";
import { IPayloadAction } from "../../../shared/shared.interfaces";
import {
  IClientDocDashCounter,
  IDashboardCounterEntry,
  IDocDashboardOverallRatingDetailsResponse,
  IIClientDocDashStates,
} from "./doc-dashboard.interface";
import { getDocSubmissionStatus } from "../ekyc.shared.utils";
import { sortArrayBy } from "../../../shared/shared.utils";
import { DocDashboardUtils } from "./doc-dashboard.utils";
import { DOC_DASHBOARD } from "./doc-dashboard.constants";
import { ClientDocSubmissionStatusEnum, G2StatusEnum } from "../ekyc.shared.enums";

export const DocDashboardActions = {
  types: {
    FETCH_COUNTERS_SUCCESS: "FETCH_COUNTERS_SUCCESS",
    FETCH_OVERALL_RATING_DETAILS: "FETCH_OVERALL_RATING_DETAILS",
  },
  creators: {
    fetchCountersSuccessAction: (counterEntries: IDashboardCounterEntry[]) => ({
      type: DocDashboardActions.types.FETCH_COUNTERS_SUCCESS,
      payload: {
        counterEntries,
      },
    }),
    fetchOverallRatingDetails: (ratingEntries: IDocDashboardOverallRatingDetailsResponse) => ({
      type: DocDashboardActions.types.FETCH_OVERALL_RATING_DETAILS,
      payload: {
        ratingEntries,
      },
    }),
  },
};

export interface IDocDashboardCounterReducerInterface {
  counterEntries: IIClientDocDashStates;
}

const countersInitialState: IDocDashboardCounterReducerInterface = {
  counterEntries: {
    splittedStates: {
      successStates: { children: [], lastUpdate: "", total: NaN },
      pendingStates: { children: [], lastUpdate: "", total: NaN },
      errorStates: { children: [], lastUpdate: "", total: NaN },
    },
    standardStates: [],
  },
};

const overallRatingDetailsState: IDocDashboardOverallRatingDetailsReducerInterface = {
  average: null,
  ratingsCount: null,
  registrationsSubmitted: 0,
  ratingsSubmitted: 0,
  lastFetch: 0,
};

export interface IDocDashboardOverallRatingDetailsReducerInterface {
  average: number | null;
  ratingsCount: any[] | null;
  registrationsSubmitted: number;
  ratingsSubmitted: number;
  lastFetch: string | number;
}

export const docDashboardReducer = produce(
  (draft: IDocDashboardCounterReducerInterface, action: IPayloadAction<any>) => {
    switch (action.type) {
      case DocDashboardActions.types.FETCH_COUNTERS_SUCCESS:
        const statesDetails: IClientDocDashCounter[] = action.payload.counterEntries.statusDetails || [];

        const totalOfRegistrations = statesDetails.reduce((total, state) => total + state.total, 0);

        interface IState {
          children: IClientDocDashCounter[];
          total: number;
        }

        const errorState: IState = {
          children: [],
          total: 0,
        };
        const pendingState: IState = {
          children: [],
          total: 0,
        };
        const successState: IState = {
          children: [],
          total: 0,
        };
        const accountCreatedChildren = [];
        draft.counterEntries.standardStates = [];

        for (let i = 0; i < statesDetails.length; i++) {
          const stateEntry = statesDetails[i];
          const status = getDocSubmissionStatus(stateEntry.status);
          const percentage = DocDashboardUtils.calculatePercentage(stateEntry.total, totalOfRegistrations);

          const newEntry: IClientDocDashCounter = {
            status,
            total: stateEntry.total,
            percentage,
            lastUpdate: stateEntry.lastUpdate,
          };

          switch (true) {
            case (DOC_DASHBOARD.ERROR_STATES as string[]).includes(stateEntry.status):
              errorState.total += newEntry.total;
              errorState.children.push(newEntry);
              break;
            case (DOC_DASHBOARD.PENDING_STATES as string[]).includes(stateEntry.status):
              pendingState.total += newEntry.total;
              pendingState.children.push(newEntry);
              break;
            case stateEntry.status === ClientDocSubmissionStatusEnum.ACCEPTED:
              accountCreatedChildren.push(newEntry);
              break;
            default:
              draft.counterEntries.standardStates.push(newEntry);
          }
        }

        const successStates = action.payload.counterEntries.g2StatusDetails || [];
        const totalOfRegistrationsInSuccessState = successStates.reduce((total: number, state: any) => {
          if ((DOC_DASHBOARD.SUCCESS_STATES as string[]).includes(state.g2Status)) {
            return total + state.total;
          }

          return total;
        }, 0);

        for (let i = 0; i < successStates.length; i++) {
          const stateEntry = successStates[i];
          const status = G2StatusEnum[stateEntry.g2Status as keyof typeof G2StatusEnum] || G2StatusEnum.UNKNOWN;
          const newEntry: IClientDocDashCounter = {
            status,
            total: stateEntry.total,
            lastUpdate: stateEntry.updatedDate,
            percentage: DocDashboardUtils.calculatePercentage(stateEntry.total, totalOfRegistrationsInSuccessState),
          };

          if (status !== G2StatusEnum.UNKNOWN && status !== G2StatusEnum.NOT_REGISTERED) {
            successState.total += newEntry.total;
            successState.children.push(newEntry);
          }
        }
        const errorDates = errorState.children.map((state: IClientDocDashCounter) => state.lastUpdate);
        const pendingDates = pendingState.children.map((state: IClientDocDashCounter) => state.lastUpdate);
        const successDates = successState.children.map((state: IClientDocDashCounter) => state.lastUpdate);

        draft.counterEntries.splittedStates = {
          successStates: {
            children: successState.children,
            lastUpdate: DocDashboardUtils.getMostRecentDate(successDates),
            total: successState.total,
          },
          pendingStates: {
            children: pendingState.children,
            lastUpdate: DocDashboardUtils.getMostRecentDate(pendingDates),
            total: pendingState.total,
          },
          errorStates: {
            children: errorState.children,
            lastUpdate: DocDashboardUtils.getMostRecentDate(errorDates),
            total: errorState.total,
          },
        };

        return draft;
      default:
        return draft;
    }
  },
  countersInitialState,
);

export const docDashboardOverallRatingDetailsReducer = produce(
  (draft: IDocDashboardOverallRatingDetailsReducerInterface, action: IPayloadAction<any>) => {
    switch (action.type) {
      case DocDashboardActions.types.FETCH_OVERALL_RATING_DETAILS:
        const ratingResponse: IDocDashboardOverallRatingDetailsResponse = action.payload.ratingEntries;

        if (!ratingResponse.average || !ratingResponse.ratingsCount) {
          draft = ratingResponse;
        } else {
          draft = {
            ...ratingResponse,
            ratingsCount: sortArrayBy(ratingResponse.ratingsCount, "rating", 2),
            average: Number(ratingResponse.average.toFixed(1)),
          };
        }

        return draft;
      default:
        return draft;
    }
  },
  overallRatingDetailsState,
);
