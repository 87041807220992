import React from "react";

const SentForApprovalIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="192"
      height="192"
      viewBox="0 0 192 192"
    >
      <defs>
        <path
          id="b"
          d="M144 112H16c-8.837 0-16-7.163-16-16V8a8 8 0 0 1 8-8h144a8 8 0 0 1 8 8v88c0 8.837-7.163 16-16 16z"
        />
        <filter id="a" width="113.1%" height="118.8%" x="-6.6%" y="-6.7%" filterUnits="objectBoundingBox">
          <feOffset dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3" />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.556640625 0 0 0 0 0.556640625 0 0 0 0 0.556640625 0 0 0 0.23 0"
          />
        </filter>
        <linearGradient id="c" x1="-24.296%" x2="75.729%" y1="40.745%" y2="76.735%">
          <stop offset="0%" stopOpacity=".571" />
          <stop offset="100%" stopOpacity="0" />
        </linearGradient>
        <path id="e" d="M152 0H8a8 8 0 0 0-8 8v8l68 42s5.83 4 12 4 12-4 12-4l68-42V8a8 8 0 0 0-8-8z" />
        <filter id="d" width="113.1%" height="133.9%" x="-6.6%" y="-12.1%" filterUnits="objectBoundingBox">
          <feOffset dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3" />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.556640625 0 0 0 0 0.556640625 0 0 0 0 0.556640625 0 0 0 0.23 0"
          />
        </filter>
        <circle id="f" cx="26" cy="26" r="26" />
        <filter id="g" width="140.4%" height="140.4%" x="-20.2%" y="-14.4%" filterUnits="objectBoundingBox">
          <feOffset dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3" />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.556862745 0 0 0 0 0.556862745 0 0 0 0 0.556862745 0 0 0 0.23 0"
          />
        </filter>
        <linearGradient id="h" x1="7.316%" x2="63.055%" y1="32.68%" y2="76.303%">
          <stop offset="0%" stopOpacity=".571" />
          <stop offset="100%" stopOpacity="0" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#EBEBEB"
          fillRule="nonzero"
          d="M159 147H31c-8.837 0-16-7.163-16-16V43a8 8 0 0 1 8-8h144a8 8 0 0 1 8 8v88c0 8.837-7.163 16-16 16z"
        />
        <g transform="translate(15 35)">
          <use fill="#000" filter="url(#a)" xlinkHref="#b" />
          <use fill="#F4F4F4" xlinkHref="#b" />
        </g>
        <path
          fill="url(#c)"
          d="M0 16l96 96h48c8.837 0 16-7.163 16-16V16H0z"
          opacity=".15"
          transform="translate(15 35)"
        />
        <g transform="translate(15 35)">
          <use fill="#000" filter="url(#d)" xlinkHref="#e" />
          <use fill="#F4F4F4" xlinkHref="#e" />
        </g>
        <path
          fill="#000"
          fillRule="nonzero"
          d="M95 95c-6.17 0-12-4-12-4L15 49v2l68 42s5.83 4 12 4 12-4 12-4l68-42v-2l-68 42s-5.83 4-12 4zM159 145H31c-8.837 0-16-7.163-16-16v2c0 8.837 7.163 16 16 16h128c8.837 0 16-7.163 16-16v-2c0 8.837-7.163 16-16 16z"
          opacity=".06"
        />
        <path
          fill="#E60000"
          fillRule="nonzero"
          d="M95 67c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.87 15.22a6.29 6.29 0 0 1-1.72 2.08 6.45 6.45 0 0 1-5.79 1.13 6.85 6.85 0 0 1-3.83-2.87 7.32 7.32 0 0 1-.87-1.93v-.16a8 8 0 0 1-.22-1.19 8.41 8.41 0 0 1 0-1.24v-.13a9.46 9.46 0 0 1 .26-1.73 10.41 10.41 0 0 1 3.3-5.36 11.24 11.24 0 0 1 3.29-1.93c.33-.13.66-.24 1-.33l.61-.17a12.16 12.16 0 0 1 1.52-.27 6.11 6.11 0 0 1 1.51 0l.24.06A5 5 0 0 0 96.25 73v.16a7.9 7.9 0 0 1 2.54 1 5.72 5.72 0 0 1 1.66 1.53l.1.14c.316.474.559.993.72 1.54a6.88 6.88 0 0 1 .28 1.74v.23c0 1-.233 1.986-.68 2.88z"
        />
        <g>
          <g transform="translate(134 105)">
            <mask id="i" fill="#fff">
              <use xlinkHref="#f" />
            </mask>
            <g fillRule="nonzero">
              <use fill="#000" filter="url(#g)" xlinkHref="#f" />
              <use fill="#E90000" xlinkHref="#f" />
            </g>
            <path
              fill="url(#h)"
              d="M21.596 31.416L39.284 12 64.21 36.482l-8.043 20.494-18.05 1.323c-8.178-8.705-13.985-14.917-17.42-18.639-3.435-3.72-6.392-7.15-8.871-10.288l9.77 2.044z"
              mask="url(#i)"
              opacity=".25"
            />
          </g>
          <path d="M139 110h42v42h-42z" />
          <path
            fill="#FFF"
            fillRule="nonzero"
            d="M156.268 145c-1.256 0-2.045-.92-2.472-1.424l-8.732-9.671-.027-.03-.177-.22a2.912 2.912 0 0 1-.61-1.855 2.813 2.813 0 0 1 2.809-2.808c.75 0 1.459.324 2.106.965l6.438 5.597 13.939-17.679c.455-.667 1.124-1.422 2.277-1.422a2.57 2.57 0 0 1 2.568 2.568 2.857 2.857 0 0 1-.606 1.64l-11.83 18.091v.011l-3.045 4.487c-.404.577-1.22 1.75-2.638 1.75z"
          />
          <path
            fill="#000"
            fillRule="nonzero"
            d="M173.781 120.183l-11.83 18.09v.011l-3.045 4.494c-.4.57-1.216 1.734-2.625 1.734-1.255 0-2.045-.92-2.472-1.424l-8.745-9.662-.026-.02-.178-.219a2.892 2.892 0 0 1-.602-1.601v.182a2.912 2.912 0 0 0 .611 1.86l.177.218.027.03 8.723 9.658c.438.504 1.217 1.424 2.472 1.424 1.418 0 2.234-1.163 2.625-1.734l3.043-4.491v-.011l11.845-18.09c.369-.47.58-1.045.606-1.641 0-.081 0-.16-.013-.239a3.023 3.023 0 0 1-.593 1.43z"
            opacity=".2"
          />
          <path fill="#E60000" fillRule="nonzero" d="M154.332 144.145l-.219-.22c.07.075.143.147.22.22z" />
          <path
            fill="#FFF"
            fillRule="nonzero"
            d="M173.843 117.438a2.562 2.562 0 0 0-2.03-.985c-1.153 0-1.823.755-2.278 1.422l-2.246 2.86 6.324-3.516.23.219z"
          />
        </g>
      </g>
    </svg>
  );
};

export default SentForApprovalIcon;
