import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { IStoreInterface } from "../../../configs/store.config";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  SearchBar,
  MultipleOptionsDropdown,
  SmallButton,
  PlusIcon,
  useModal,
  ModalTypeEnum,
  useAlert,
} from "@wit/mpesa-ui-components";
import { useTranslation } from "react-i18next";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { useHistory } from "react-router-dom";
import { AntExternalServicePermitionsEnum, IExternalServices } from "../../../shared/models/ant-service-manager.model";
import { AntExternalServicesActions } from "./ant-external-services.store";
import AntExternalServicesApi from "./ant-external-services.api";
import { RoutesEnum } from "../../../routes/routes.constants";
import { getExternalServicePermissions, renderTableButtons } from "../../../shared/shared.utils";
import { getAntExternalServicesColumns } from "./ant-configurations.utils";

/** ExternalServices page */
const AntExternalServicesPage = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const [selectedAntExternalService, setSelectedAntExternalService] = React.useState<IExternalServices | undefined>(
    undefined,
  );
  const [showDeleteModal, hideDeleteModal, setDeleteProps] = useModal(ModalTypeEnum.ConfirmationModal);
  const [searchString, setSearchString] = useState("");
  const [permissionsSelected, setPermissionsSelected] = useState<string[]>([]);

  const isLoadingAntExternalServices = useSelector(
    (state: IStoreInterface) => state.antExternalServicesReducer.isLoadingAntExternalServices,
  );
  const persistedAntExternalServices = useSelector(
    (store: IStoreInterface) => store.antExternalServicesReducer.antExternalServices,
  );
  const [antExternalServices, setAntExternalServices] = useState(persistedAntExternalServices);

  const refreshPage = useCallback(() => {
    dispatch(AntExternalServicesActions.creators.fetchingAntExternalServicesAction());
    AntExternalServicesApi.methods.getAntExternalServices().then(
      res => {
        dispatch(AntExternalServicesActions.creators.fetchAntExternalServicesSuccessAction(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.antExternalServices.errors.searchServices"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  }, [dispatch, t, showAlert, setAlertProps]);

  React.useEffect(() => {
    refreshPage();
    return () => {
      dispatch(AntExternalServicesActions.creators.fetchAntExternalServicesLeaveAction());
    };
  }, [dispatch, t, showAlert, setAlertProps]);

  React.useEffect(() => {
    if (persistedAntExternalServices) {
      let visibleAntExternalServices = [...persistedAntExternalServices];

      if (searchString) {
        visibleAntExternalServices = visibleAntExternalServices.filter(
          es =>
            es.externalServiceId.toLowerCase().includes(searchString.toLowerCase()) ||
            es.url.toLowerCase().includes(searchString.toLowerCase()),
        );
      }
      if (permissionsSelected.length > 0) {
        if (permissionsSelected.includes("PIN")) {
          visibleAntExternalServices = visibleAntExternalServices.filter(
            es => es.permissions !== undefined && es.permissions.includes(AntExternalServicePermitionsEnum.PIN),
          );
        }

        if (permissionsSelected.includes("IDENTITY")) {
          visibleAntExternalServices = visibleAntExternalServices.filter(
            es => es.permissions !== undefined && es.permissions.includes(AntExternalServicePermitionsEnum.IDENTITY),
          );
        }
      }
      setAntExternalServices(visibleAntExternalServices);
    } else {
      setAntExternalServices([]);
    }
  }, [persistedAntExternalServices, searchString, permissionsSelected]);

  /** function to redirect to add new external */
  const addNewExternalService = () => {
    history.push(RoutesEnum.ADD_NEW_ANT_EXTERNAL_SERVICE);
  };

  /** function to redirect to edit external service */
  const editAntExternalService = (antExternalService: IExternalServices) => {
    history.push(
      RoutesEnum.ANT_EXTERNAL_SERVICES_DETAILS.replace(":externalServiceID", antExternalService.externalServiceId),
    );
  };

  /** function deleteRequest */
  const deleteRequest = (selectedAntExternalService: IExternalServices) => {
    if (selectedAntExternalService) {
      AntExternalServicesApi.methods
        .deleteAntExternalService(selectedAntExternalService.externalServiceId)
        .then(
          () => {
            setAlertProps({
              title: t("pages.antExternalServices.deleteRequest.titleDone"),
              type: AlertTypeEnum.SUCCESS,
            });
            showAlert();
            AntExternalServicesActions.creators.fetchingAntExternalServicesAction();
            AntExternalServicesApi.methods.getAntExternalServices().then(res => {
              dispatch(AntExternalServicesActions.creators.fetchAntExternalServicesSuccessAction(res.data));
            });
          },
          () => {
            setAlertProps({
              title: t("pages.antExternalServices.deleteRequest.titleError"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          },
        )
        .finally(hideDeleteModal);
    }
  };

  /** function to call function to delete external service */
  const deleteAntExternalService = (externalService: IExternalServices) => {
    setDeleteProps({
      secondaryAction: () => hideDeleteModal(),
      primaryAction: () => deleteRequest(externalService),
      title: t("pages.antExternalServices.deleteModal.title"),
      description: t("pages.antExternalServices.deleteModal.description"),
      primaryBtnLabel: t("pages.antExternalServices.deleteModal.btnLabel"),
      primaryBtnId: "delete-service-button",
      secondaryBtnId: "cancel-button",
      primaryBtnMaxWidth: 160,
    });
    setSelectedAntExternalService(externalService);
    showDeleteModal();
  };

  const isOptionSelected = (opt: SharedDropdownOption) => {
    return permissionsSelected.includes(opt.key);
  };

  const toggleOption = (opt: SharedDropdownOption) => {
    if (isOptionSelected(opt)) {
      setPermissionsSelected(permissionsSelected.filter(c => c !== opt.key));
    } else {
      setPermissionsSelected([...permissionsSelected, opt.key]);
    }
  };

  const resetDropdown = () => {
    setPermissionsSelected([]);
  };

  return (
    <>
      {isLoadingAntExternalServices ? (
        <LoadingContainer>
          <LoadingText>{t("commons.loadingResults")}</LoadingText>
        </LoadingContainer>
      ) : (
        <>
          <FiltersContainer>
            <div style={{ flex: 4 / 12, marginRight: 24 }}>
              <SearchBar
                placeholderLabel={t("pages.antExternalServices.filters.placeholderSearch")}
                value={searchString}
                onChange={e => setSearchString(e.target.value)}
                clearValue={() => setSearchString("")}
              />
            </div>

            <div style={{ flex: 2 / 12, marginRight: 24 }}>
              <MultipleOptionsDropdown
                label={
                  permissionsSelected.length > 0
                    ? t("pages.antExternalServices.filters.labelOfStatusSelected", {
                        labelOfSelectedStatus: permissionsSelected,
                      })
                    : t("pages.antExternalServices.filters.allPermissions")
                }
                toggleOption={opt => toggleOption(opt)}
                clearAllFilters={() => resetDropdown()}
                hasValue={false}
                isOptionSelected={opt => isOptionSelected(opt)}
                options={getExternalServicePermissions()}
              />
            </div>
            <SmallButtonsContainer>
              <span id="add-new-external-service-button">
                <SmallButton
                  onClick={addNewExternalService}
                  titleLabel={t("pages.antExternalServices.filters.description")}
                  iconComponent={
                    <IconContainer>
                      <PlusIcon />
                    </IconContainer>
                  }
                />
              </span>
            </SmallButtonsContainer>
          </FiltersContainer>
          <TableWrapper className="tableWrapper">
            {antExternalServices && (
              <Table<IExternalServices>
                columns={getAntExternalServicesColumns(t)}
                values={antExternalServices}
                displayActions={service =>
                  renderTableButtons<IExternalServices>(service, {
                    edit: { onClick: editAntExternalService, id: `edit-button-${service.externalServiceId}` },
                    delete: { onClick: deleteAntExternalService, id: `delete-button-${service.externalServiceId}` },
                  })
                }
              />
            )}
          </TableWrapper>
        </>
      )}
    </>
  );
};

export default AntExternalServicesPage;

const TableWrapper = styled("div")`
  > div:first-child {
    > div:last-child {
      > div {
        padding: 0;
      }
    }
  }
`;

const FiltersContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 32px;
`;

const SmallButtonsContainer = styled("div")`
  margin-left: auto;
  display: flex;
  button:first-child {
    margin-right: 20px;
  }
`;

const IconContainer = styled("div")`
  stroke: ${props => props.theme.palette.vodafoneRed};
  width: 20px;
  height: 20px;

  svg {
    height: 20px;
  }
`;

const LoadingContainer = styled("div")`
  display: flex;
`;

export const LoadingText = styled("span")`
  font-family: Vodafone Rg;
  color: #333333;
  font-size: 16px;
  padding: 20px 0px 20px 0px;
`;
