import { produce } from "immer";
import { IPayloadAction } from "../../../../shared/shared.interfaces";
import {
  IAnalyticsDashboardResponse,
  IAnalyticsInvitesResponse,
  IAnalyticsRewardsResponse,
} from "../referral-campaigns.model";

export const ReferralAnalyticsStoreActions = {
  types: {
    FETCHING_REFERRAL_ANALYTICS_INVITES: "FETCHING_REFERRAL_ANALYTICS_INVITES",
    FETCH_REFERRAL_ANALYTICS_INVITES_SUCCESS: "FETCH_REFERRAL_ANALYTICS_INVITES_SUCCESS",
    FETCH_REFERRAL_ANALYTICS_DASBOARD_SUCCESS: "FETCH_REFERRAL_ANALYTICS_DASBOARD_SUCCESS",
    CLEAR_REFERRAL_ANALYTICS_DASHBOARD_DATA: "CLEAR_REFERRAL_ANALYTICS_DASHBOARD_DATA",
    CLEAR_REFERRAL_ANALYTICS_INVITES_DATA: "CLEAR_REFERRAL_ANALYTICS_INVITES_DATA",
    FETCHING_REFERRAL_ANALYTICS_REWARDS: "FETCHING_REFERRAL_ANALYTICS_REWARDS",
    FETCH_REFERRAL_ANALYTICS_REWARDS_SUCCESS: "FETCH_REFERRAL_ANALYTICS_REWARDS_SUCCESS",
    CLEAR_REFERRAL_ANALYTICS_REWARDS_DATA: "CLEAR_REFERRAL_ANALYTICS_REWARDS_DATA",
    DOWNLOAD_REFERRAL_ANALYTICS_EXCEL_FILE: "DOWNLOAD_REFERRAL_ANALYTICS_EXCEL_FILE",
  },
  creators: {
    getReferralAnalyticsExcelFile: () => ({
      type: ReferralAnalyticsStoreActions.types.DOWNLOAD_REFERRAL_ANALYTICS_EXCEL_FILE,
    }),
    getReferralAnalyticsInvites: () => ({
      type: ReferralAnalyticsStoreActions.types.FETCHING_REFERRAL_ANALYTICS_INVITES,
    }),
    setReferralAnalyticsInvites: (referralAnalyticsInvites: IAnalyticsInvitesResponse) => ({
      type: ReferralAnalyticsStoreActions.types.FETCH_REFERRAL_ANALYTICS_INVITES_SUCCESS,
      payload: {
        ...referralAnalyticsInvites,
      },
    }),
    clearReferralAnalyticsInvites: () => ({
      type: ReferralAnalyticsStoreActions.types.CLEAR_REFERRAL_ANALYTICS_INVITES_DATA,
      payload: {},
    }),
    setReferralAnalyticsDashboard: (referralAnalyticsDashboard: IAnalyticsDashboardResponse) => ({
      type: ReferralAnalyticsStoreActions.types.FETCH_REFERRAL_ANALYTICS_DASBOARD_SUCCESS,
      payload: { ...referralAnalyticsDashboard },
    }),
    clearReferralAnalyticsDashboard: () => ({
      type: ReferralAnalyticsStoreActions.types.CLEAR_REFERRAL_ANALYTICS_DASHBOARD_DATA,
      payload: {},
    }),
    getReferralAnalyticsRewards: () => ({
      type: ReferralAnalyticsStoreActions.types.FETCHING_REFERRAL_ANALYTICS_REWARDS,
    }),
    setReferralAnalyticsRewards: (referralAnalyticsRewards: IAnalyticsRewardsResponse) => ({
      type: ReferralAnalyticsStoreActions.types.FETCH_REFERRAL_ANALYTICS_REWARDS_SUCCESS,
      payload: {
        ...referralAnalyticsRewards,
      },
    }),
    clearReferralAnalyticsRewards: () => ({
      type: ReferralAnalyticsStoreActions.types.CLEAR_REFERRAL_ANALYTICS_REWARDS_DATA,
      payload: {},
    }),
  },
};

export interface IReferralAnalyticsReducerInterface {
  analyticsInvites: IAnalyticsInvitesResponse;
  analyticsRewards: IAnalyticsRewardsResponse;
  analyticsDashboard: IAnalyticsDashboardResponse;
}

const initialState: IReferralAnalyticsReducerInterface = {
  analyticsInvites: {} as IAnalyticsInvitesResponse,
  analyticsRewards: {} as IAnalyticsRewardsResponse,
  analyticsDashboard: {} as IAnalyticsDashboardResponse,
};

export const referralAnalyticsReducer = produce(
  (draft: IReferralAnalyticsReducerInterface, action: IPayloadAction<any>) => {
    switch (action.type) {
      case ReferralAnalyticsStoreActions.types.DOWNLOAD_REFERRAL_ANALYTICS_EXCEL_FILE:
      case ReferralAnalyticsStoreActions.types.FETCHING_REFERRAL_ANALYTICS_INVITES:
      case ReferralAnalyticsStoreActions.types.FETCHING_REFERRAL_ANALYTICS_REWARDS:
        return;

      case ReferralAnalyticsStoreActions.types.FETCH_REFERRAL_ANALYTICS_INVITES_SUCCESS:
        draft.analyticsInvites = action.payload as IAnalyticsInvitesResponse;
        return;
      case ReferralAnalyticsStoreActions.types.CLEAR_REFERRAL_ANALYTICS_INVITES_DATA:
        draft.analyticsInvites = {} as IAnalyticsInvitesResponse;
        return;

      case ReferralAnalyticsStoreActions.types.FETCH_REFERRAL_ANALYTICS_DASBOARD_SUCCESS:
        draft.analyticsDashboard = action.payload as IAnalyticsDashboardResponse;
        return;
      case ReferralAnalyticsStoreActions.types.CLEAR_REFERRAL_ANALYTICS_DASHBOARD_DATA:
        draft.analyticsDashboard = {} as IAnalyticsDashboardResponse;
        return;

      case ReferralAnalyticsStoreActions.types.FETCH_REFERRAL_ANALYTICS_REWARDS_SUCCESS:
        draft.analyticsRewards = action.payload as IAnalyticsRewardsResponse;
        return;
      case ReferralAnalyticsStoreActions.types.CLEAR_REFERRAL_ANALYTICS_REWARDS_DATA:
        draft.analyticsRewards = {} as IAnalyticsRewardsResponse;
        return;

      default:
        return draft;
    }
  },
  initialState,
);
