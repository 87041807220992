import styled from "styled-components";

export const ModalContent = styled.div`
  padding: 24px 24px;
  color: ${props => props.theme.palette.black};
  display: flex;
  flex-direction: column;
`;

export const ModalTitle = styled.span`
  font-size: 22px;
  font-family: Vodafone Rg;
  color: inherit;
  margin-bottom: 13px;
`;

export const ModalDescription = styled.span`
  line-height: 23px;
  font-size: 18px;
  font-family: Vodafone Rg;
  color: inherit;
  margin-bottom: 48px;
`;

export const ModelActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonContainer = styled.div`
  :first-of-type {
    margin-right: 12px;
  }
`;
