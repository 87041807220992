import { AxiosPromise } from "axios";
import { ICustomerSearchForm, IBussinessSearchForm } from "./identity-converter.model";
import ApiClient from "../../../configs/axios.config";

/*
 * Identity Converter API methods
 * */
const IdentiyConverterApi = {
  routes: {
    identities: "/v1/identity-provider",
  },
  methods: {
    getCustomerIdentities: (filterData: ICustomerSearchForm): AxiosPromise<any> => {
      const { endTime, startTime, ...data } = filterData;
      return ApiClient.get(IdentiyConverterApi.routes.identities, { params: { ...data } });
    },
    getBusinessIdentities: (filterData: IBussinessSearchForm): AxiosPromise<any> => {
      const { endTime, startTime, ...data } = filterData;

      return ApiClient.get(IdentiyConverterApi.routes.identities, { params: { ...data } });
    },
  },
};

export default IdentiyConverterApi;
