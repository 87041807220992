import React from "react";

const ServiceAdapterIcon = (props: React.HTMLProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.5 7.5h17v9h-17v-9zm7 0v-3m3 3v-3m3 3v-3m3 3v-3m-12 3v-3m-3 3v-3m6 15v-3m3 3v-3m3 3v-3m3 3v-3m-12 3v-3m-3 3v-3"
        stroke="#EB9700"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ServiceAdapterIcon;
