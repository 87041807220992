export interface IDiscoverCards {
  status: DiscoverCardsStatusEnum;
  hasUnpublishedChanges: boolean;
  hasPreviousVersion: boolean;
  discoverCards: IDiscoverCard[];
  submittedForApproval?: boolean;
}

export interface IDiscoverCard {
  id: string;
  name: any;
  description: any;
  expiryDate: string;
  startDate: number;
  endDate: number;
  urlImage: string;
  status: DiscoverCardsStatusEnum;
  deepLinkType: DiscoverCardsDeepLinkTypeEnum;
  imageName: string;
  submittedForApproval: boolean;
  data: {
    type: DiscoverCardActionTypeEnum;
    flow?: string; //NATIVE
    eventId?: string; //DYNAMIC_SCREEN
    uri?: string; //DEEPLINK & EXTERNAL_LINK
  };
}

export enum DiscoverCardsStatusEnum {
  DISABLE = "DISABLE",
  DISABLED = "DISABLED",
  EDIT = "EDIT",
  ENABLE = "ENABLE",
  LIVE = "LIVE",
  DELETE = "DELETE",
}

export enum DiscoverCardsDeepLinkTypeEnum {
  NATIVE = "NATIVE",
  DYNAMIC_SCREEN = "DYNAMIC_SCREEN",
  DEEP_LINK = "DEEP_LINK",
  EXTERNAL_LINK = "EXTERNAL_LINK",
}

export enum DiscoverCardActionTypeEnum {
  NATIVE = "NATIVE",
  DYNAMIC_SCREEN = "DYNAMIC_SCREEN",
  DEEP_LINK = "DEEP_LINK",
  EXTERNAL_LINK = "EXTERNAL_LINK",
  NONE = "NONE",
  BUNDLE = "BUNDLE",
  MINI_APP = "MINI_APP",
}

export enum DiscoverCardFlowEnum {
  SERVICES = "SERVICES",
  QR_CODE = "QR_CODE",
  PAY_PERSON = "PAY_PERSON",
  WITHDRAW_CASH = "WITHDRAW_CASH",
  WITHDRAW_AGENT = "WITHDRAW_AGENT",
  MPESA_POINTS = "MPESA_POINTS",
  PAY_MERCHANT = "PAY_MERCHANT",
  PAY_BUSINESS = "PAY_BUSINESS",
  PAY_LUKU = "PAY_LUKU",
  INTERNATIONAL_TRANSFERS = "INTERNATIONAL_TRANSFERS",
  BANK_TRANSFERS = "BANK_TRANSFERS",
  PAY_GOVERNMENT = "PAY_GOVERNMENT",
  AIRTIME_TOP_UP = "AIRTIME_TOP_UP",
  AIRTIME_TOP_UP_SELF = "AIRTIME_TOP_UP_SELF",
  AIRTIME_BUY_BUNDLE = "AIRTIME_BUY_BUNDLE",
  REVERSE_TRANSACTION = "REVERSE_TRANSACTION",
  SETTINGS = "SETTINGS",
  REFER_A_FRIEND = "REFER_A_FRIEND",
  CUSTOMIZE_MENUS = "CUSTOMIZE_MENUS",
  MPESA_FEES = "MPESA_FEES",
  CONTACT_VODACOM = "CONTACT_VODACOM",

  // Un-used not supported on the apps (remove translations in the future and move this to a shared enum)
  TOP_UP = "TOP_UP",
  WITHDRAW_ATM = "WITHDRAW_ATM",
  PAY_INTO_BANK = "PAY_INTO_BANK",
  BUNDLE = "BUNDLE",
  LUKU = "LUKU",
  DSTV = "DSTV",
  GOVERNMENT_BILL = "GOVERNMENT_BILL",
}

export enum DiscoverCardsErrorCodes {
  MANY_LIVE_CARDS = 40032,
}

export interface IGalleryImage {
  url: string;
  filename: string;
}
