import i18next from "i18next";

export interface IPasswordPolicies {
  restrictMinDigits: boolean;
  minDigits: number;
  restrictMinUpperCaseLetters: boolean;
  minUpperCaseLetters: number;
  restrictMinLowerCaseLetters: boolean;
  minLowerCaseLetters: number;
  restrictMinNonAlphanumericCharacters: boolean;
  minNonAlphanumericCharacters: number;
  restrictSuccessiveIdenticalChars: boolean;
  minSuccessiveIdenticalChars: number;
  minLength: number;
}

export const validatePassword = (rules: IPasswordPolicies, password: string) => {
  const validity = "";
  if (rules.minLength) {
    if (password.length < rules.minLength) {
      return i18next.t("commons.passwordPolicies.minLength", {
        n: rules.minLength,
      });
    }
  }
  if (rules.restrictMinDigits) {
    const hasNumbersRegex = "(?=(.*[0-9]){{minDigits}})".replace("{minDigits}", String(rules.minDigits));
    const hasNumbers = new RegExp(hasNumbersRegex).test(password);
    if (!hasNumbers) {
      return i18next.t("commons.passwordPolicies.minDigits", {
        n: rules.minDigits,
      });
    }
  }
  if (rules.restrictMinUpperCaseLetters) {
    const minUpperCaseLettersRegex = "(?=(.*[A-Z]){{minUpperCaseLetters}})".replace(
      "{minUpperCaseLetters}",
      String(rules.minUpperCaseLetters),
    );
    const minUpperCase = new RegExp(minUpperCaseLettersRegex).test(password);
    if (!minUpperCase) {
      return i18next.t("commons.passwordPolicies.minUpperCaseLetters", {
        n: rules.minUpperCaseLetters,
      });
    }
  }
  if (rules.restrictMinLowerCaseLetters) {
    const minLowerCaseRegex = "(?=(.*[a-z]){{minLowerCaseLetters}})".replace(
      "{minLowerCaseLetters}",
      String(rules.minLowerCaseLetters),
    );
    const minLowerCase = new RegExp(minLowerCaseRegex).test(password);
    if (!minLowerCase) {
      return i18next.t("commons.passwordPolicies.minLowerCaseLetters", {
        n: rules.minLowerCaseLetters,
      });
    }
  }
  if (rules.restrictMinNonAlphanumericCharacters) {
    const minNonAlphanumericCharactersRegex = "(?=(.*[^a-zA-Z0-9]){{minNonAlphanumericCharacters}})".replace(
      "{minNonAlphanumericCharacters}",
      String(rules.minNonAlphanumericCharacters),
    );
    const minNonAlphanumericCharacters = new RegExp(minNonAlphanumericCharactersRegex).test(password);
    if (!minNonAlphanumericCharacters) {
      return i18next.t("commons.passwordPolicies.minNonAlphanumericCharacters", {
        n: rules.minNonAlphanumericCharacters,
      });
    }
  }
  if (rules.restrictSuccessiveIdenticalChars) {
    const consecutiveRegex = "(.)\\1{minSuccessiveIdenticalChars}".replace(
      "minSuccessiveIdenticalChars",
      String(rules.minSuccessiveIdenticalChars),
    );
    const consecutive = new RegExp(consecutiveRegex).test(password);
    if (consecutive) {
      return i18next.t("commons.passwordPolicies.minSuccessiveIdenticalChars", {
        n: rules.minSuccessiveIdenticalChars,
      });
    }
  }
  return validity;
};
