import React from "react";
import styled from "styled-components";
import { AudienceType, IAnnouncement, IIcon } from "../announcements.model";
import { useTranslation } from "react-i18next";
import { CancelIcon } from "@wit/mpesa-ui-components";
import { AnnouncementsUtils } from "../announcements.utils";

interface IHomeScreenDetailInterface {
  valuesAnnouncement: IAnnouncement;
  icon: IIcon;
}

/**
 * component to preview the card of announcement in home screen
 * @param param0 interface with data necessary
 */
const HomeScreenAnnouncementBusiness = ({ valuesAnnouncement, icon }: IHomeScreenDetailInterface) => {
  const [t] = useTranslation();
  const colorDefault = "#2fc56d";

  return (
    <DivBackground
      initial={!valuesAnnouncement.title && !valuesAnnouncement.smallDescription && icon.id === "000"}
      audience={valuesAnnouncement.audience}
    >
      <AnnouncementDiv audience={valuesAnnouncement.audience}>
        <IconDiv color={valuesAnnouncement.color ? valuesAnnouncement.color : colorDefault}>
          <IconDropDownContainer
            color={valuesAnnouncement.color ? valuesAnnouncement.color : colorDefault}
            logo={icon.base64}
          />
        </IconDiv>
        <DetailDiv color={valuesAnnouncement.color ? valuesAnnouncement.color : colorDefault}>
          <TitleDiv>
            <span>
              {valuesAnnouncement.title ? valuesAnnouncement.title : t("pages.announcements.addPage.defaultTitle")}
            </span>
          </TitleDiv>
          <DescriptionDiv>
            <span>
              {valuesAnnouncement.smallDescription
                ? valuesAnnouncement.smallDescription
                : t("pages.announcements.addPage.defaultDescription")}
            </span>
          </DescriptionDiv>
          {valuesAnnouncement.dismissible ? (
            <CancelIconContainer>
              <CancelIcon width={"9px"} height={"9px"} style={{ stroke: "white" }} />
            </CancelIconContainer>
          ) : null}
        </DetailDiv>
      </AnnouncementDiv>
    </DivBackground>
  );
};

export default HomeScreenAnnouncementBusiness;

const DivBackground = styled("div")<{
  initial: boolean;
  audience: AudienceType | undefined;
}>`
  background: ${props =>
    props.audience && AnnouncementsUtils.isConsumerAudience(props.audience)
      ? "url('./images/screensAppSFC/screenConsumerDeprecated.png')"
      : "url('./images/screensAppSFC/screenBusiness.png')"};
  background-size: cover;
  background-color: transparent !important;
  width: 100%;
  height: 100%;
  ${props =>
    props.initial
      ? "webkit-filter: opacity(0.5) drop-shadow(0 0 0 white);filter: opacity(0.5) drop-shadow(0 0 0 white);"
      : null}
`;

const AnnouncementDiv = styled("div")<{
  audience: AudienceType | undefined;
}>`
  position: absolute;
  width: 88%;
  margin: auto;
  height: 45px;
  top: ${props => (props.audience && AnnouncementsUtils.isConsumerAudience(props.audience) ? "39.75%" : "36.75%")};
  left: 6.5%;
  display: flex;
  flex-direction: row;
`;

const IconDiv = styled("div")<{ color: string }>`
  width: 20%;
  height: 100%;
  background-color: ${props => props.color}32;
  border-top-left-radius: 5.6px;
  border-bottom-left-radius: 5.6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DetailDiv = styled("div")<{ color: string }>`
  width: 80%;
  height: 100%;
  background-color: ${props => props.color}16;
  border-bottom-right-radius: 5.6px;
  border-top-right-radius: 5.6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: Vodafone Rg;
  position: relative;
`;

const TitleDiv = styled("div")`
  margin-left: 5px;
  font-size: 9.8px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #666666;
  word-break: break-word;
`;
const DescriptionDiv = styled("div")`
  margin-left: 5px;
  font-size: 8.4px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: normal;
  color: #aaaaaa;
`;

const IconDropDownContainer = styled("div")<{
  logo: string;
  color: string;
}>`
  background-image: ${props => (props.logo ? `url("${props.logo}")` : "none")};
  -webkit-filter: opacity(0.5) drop-shadow(0 0 0 ${props => props.color});
  filter: opacity(0.5) drop-shadow(0 0 0 ${props => props.color});
  background-repeat: no-repeat;
  background-size: 22px 22px;
  background-position: center;
  width: 22px;
  height: 22px;
`;

const CancelIconContainer = styled("div")`
  position: absolute;
  width: 15px;
  height: 15px;
  right: 8px;
  top: 8px;
  border-radius: 50%;
  background: rgb(160, 160, 160);
  display: flex;
  justify-content: center;
  align-items: center;
`;
