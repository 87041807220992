import Axios, { AxiosPromise } from "axios";
import ApiClient from "../../../configs/axios.config";
import {
  IReportsRequest,
  IBlacklistRequest,
  IBlockedNumber,
  INewBlockedNumber,
  IBlacklistParameters,
} from "./request-money.interfaces";

const RequestMoneyApi = {
  routes: {
    reportedUsers: "/requestmoneyreports",
    blacklist: "request-money/blacklist",
  },
  methods: {
    getReportedUsers: (): AxiosPromise<IReportsRequest> => {
      return MocksClient.get(RequestMoneyApi.routes.reportedUsers);
    },
    getBlacklist: (params: IBlacklistParameters): AxiosPromise<IBlacklistRequest> => {
      return ApiClient.post(`${RequestMoneyApi.routes.blacklist}?page=${params.page}&pageSize=${params.pageSize}`, {
        type: 0,
        msisdn: params.blockedNumber ? params.blockedNumber : "",
      });
    },
    addToBlacklist: (number: INewBlockedNumber, params: IBlacklistParameters): AxiosPromise<IBlacklistRequest> => {
      return ApiClient.post(`${RequestMoneyApi.routes.blacklist}?page=${params.page}&pageSize=${params.pageSize}`, {
        type: 1,
        msisdn: number.phoneNumber,
      });
    },
    removeFromBlacklist: (number: IBlockedNumber, params: IBlacklistParameters): AxiosPromise<IBlacklistRequest> => {
      return ApiClient.post(`${RequestMoneyApi.routes.blacklist}?page=${params.page}&pageSize=${params.pageSize}`, {
        type: 2,
        msisdn: number.msisdn,
      });
    },
  },
};

//Request to get mocked data
//TODO: Remove this after BE integration
const MocksClient = Axios.create({
  baseURL: "https://demo3883973.mockable.io/",
  timeout: 10000,
  headers: {
    "Security-Cookie": `${localStorage.getItem("securityCookie")}`,
    "XSRF-TOKEN": `${localStorage.getItem("xsrfToken")}`,
  },
});

export default RequestMoneyApi;
