import React, { useContext } from "react";
import { ConfigContext } from "../../../../app.component";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Toggle } from "@wit/mpesa-ui-components";
import { getStatus } from "../networks.page";
import { INetworkItem } from "../networks.model";
import { BackOfficeMarketsEnums } from "../../../../shared/shared.enums";
import { isEnabledOnMarket } from "../../../../shared/shared.utils";

interface ISendMoneyPageProps {
  networkList: INetworkItem[];
  saveChanges: (networks: INetworkItem, value: boolean) => void;
}
const SendMoneyPage = ({ networkList, saveChanges }: ISendMoneyPageProps) => {
  const { config } = useContext(ConfigContext);
  const [t] = useTranslation();

  return (
    <Content>
      <LeftColumn>
        <LeftTitle>
          {isEnabledOnMarket(config, BackOfficeMarketsEnums.MZ)
            ? t("pages.networks.sendMoneyPage.mzTitle")
            : t("pages.networks.sendMoneyPage.title")}
        </LeftTitle>
        <LeftDescription>
          {isEnabledOnMarket(config, BackOfficeMarketsEnums.MZ)
            ? t("pages.networks.sendMoneyPage.mzDescription")
            : t("pages.networks.sendMoneyPage.description")}
        </LeftDescription>
      </LeftColumn>
      <RightColumn>
        {networkList.map((value: INetworkItem, index) => {
          return (
            <NetworkItemDiv key={index} id={value.networkType}>
              <NetworkDetails>
                <NetworkName>{t(`commons.networks.${value.networkType}`)}</NetworkName>
                {value.validation && <NetworkRegex>Validation Script: {value.validation}</NetworkRegex>}
              </NetworkDetails>
              <div id={`toggle-${t(`commons.networks.${value.networkType}`)}`}>
                <Toggle
                  cb={(newValue: boolean) => {
                    saveChanges(value, newValue);
                  }}
                  isDisabled={false}
                  initialValue={getStatus(value.status)}
                />
              </div>
            </NetworkItemDiv>
          );
        })}
      </RightColumn>
    </Content>
  );
};

export default SendMoneyPage;

const Content = styled.div`
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    margin-top: 72px;
  }
  @media (max-width: 600px) {
    margin-top: 112px;
  }
`;

const LeftColumn = styled.div`
  flex: 0 1 293px;
  margin-right: 102px;
  @media (max-width: 1024px) {
    flex: 100%;
    margin: 0;
  }
`;
const RightColumn = styled.div`
  flex: 1 1 auto;
`;
const NetworkItemDiv = styled.div`
  border-bottom: solid 1px #ebebeb;
  padding-top: 18px;
  padding-bottom: 18px;
  display: flex;
  justify-content: space-between;
  align-items: start;
`;
const NetworkDetails = styled.div`
  font-family: Vodafone Rg;
  display: flex;
  flex-direction: column;
`;
const NetworkName = styled.span`
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  color: #afafaf;
`;
const NetworkRegex = styled.span`
  font-size: 14px;
  line-height: 18px;
  color: #afafaf;
  word-break: break-word;
`;

const LeftTitle = styled.div`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 4px;
`;
const LeftDescription = styled.div`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: #afafaf;
`;
