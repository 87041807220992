import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { AxiosResponse } from "axios";
import { useFilters, TabChild, Tabs, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { useTranslation } from "react-i18next";
import { IStoreInterface } from "../../../../configs/store.config";
import {
  RelativeTableRow,
  RelativeRow,
  AbsoluteRow,
  ContainerHeader,
  ContainerBody,
  ContainerFooter,
  AnalyticsContainer,
  RelativeChartRow,
} from "./referral-campaigns-analytics.styles";
import RefreshAndExportAction from "./components/analytics-refresh-and-export.component";
import SearchByMsisdn from "./components/analytics-number-search.component";
import DateFilter from "./components/analytics-date-filter.component";
import AnalyticsTable from "./components/analytics-table.component";
import { ReferralAnalyticsPageConfigs } from "./referral-campaigns-analytics.utils";
import TablePagination from "../../../../shared/components/table-pagination/table-pagination-component";
import {
  EFormAnalyticsType,
  IReferralAnalyticsDashboardRequest,
  IReferralAnalyticsProps,
  IReferralAnalyticsRequest,
  ReferralAnalyticsDateFilterKey,
} from "../referral-campaigns.model";
import ReferralCampaignsApi from "../referral-campaigns.api";
import { ReferralAnalyticsStoreActions } from "./referral-campaigns-analytics.store";
import DoughnutChart, {
  IDoughnutChartDataProps,
  IDoughnutChartLegendProps,
} from "../../../../shared/components/doughnut-chart.component";
import { BackOfficeMarketsEnums } from "../../../../shared/shared.enums";

/**
 * ReferralAnalytics component
 */
const ReferralAnalytics = ({ sectionTabs, referralCampaignID, referralCampaignStartDate }: IReferralAnalyticsProps) => {
  const pageConfig = ReferralAnalyticsPageConfigs();
  const {
    filters,
    resetFilters,
    updateFilter,
    isFilterActive,
    clearFilter,
    getFilterValue,
    updateMultipleFilters,
  } = useFilters();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const staticTempRequestParams = React.useRef({
    id: referralCampaignID,
    page: pageConfig.INITIAL_PAGE,
    pageSize: pageConfig.PAGE_LIST_ROWS,
  } as IReferralAnalyticsRequest);
  const firstRenderRef = React.useRef({
    dateFilter: 0,
    pageRequest: 0,
    sectionRequest: 0,
  });
  const [sectionId, setSectionId] = React.useState<number>(pageConfig.INITIAL_SECTION);
  const [activePage, setActivePage] = React.useState<number>(pageConfig.INITIAL_PAGE);
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [isFetchingData, setIsFetchingData] = React.useState<boolean>(false);
  const [isFetchingWithParams, setIsFetchingWithParams] = React.useState<boolean>(false);
  const [mainRequestParams, setMainRequestParams] = React.useState<IReferralAnalyticsRequest>({
    id: referralCampaignID,
    page: pageConfig.INITIAL_PAGE,
    pageSize: pageConfig.PAGE_LIST_ROWS,
  });
  const [dashboardParams, setDashboardParams] = React.useState<IReferralAnalyticsDashboardRequest>({
    id: referralCampaignID,
    type: sectionId ? EFormAnalyticsType.RECEIVERS : EFormAnalyticsType.SENDERS,
  });
  const [dashboardLoading, setDashboardLoading] = React.useState<boolean>(false);

  // Analytics Store
  const { analyticsInvites, analyticsRewards, analyticsDashboard } = useSelector(
    (state: IStoreInterface) => state.referralAnalyticsReducer,
  );

  /**
   * Handles the pagination change
   * When the user clicks on page numbers it updates the page number on
   * requestParams and calls the fetchData function
   * @param pageNumber: number
   */
  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
    setMainRequestParams({ ...mainRequestParams, page: pageNumber });
  };

  /**
   * Handles the section change
   * @param id: number
   */
  const handleRequestParamsChange = () => {
    referralAnalyticsRequestActions.clearDasboardData();
    referralAnalyticsRequestActions.getDashboardData();
    if (sectionId === 0) {
      referralAnalyticsRequestActions.clearRewardsData();
      referralAnalyticsRequestActions.getInvitesData();
      return;
    }

    referralAnalyticsRequestActions.clearInvitesData();
    referralAnalyticsRequestActions.getRewardsData();
  };

  /**
   * Refresh results on the table
   */
  const handleRefresh = () => {
    setActivePage(pageConfig.INITIAL_PAGE);
    setMainRequestParams({ ...staticTempRequestParams.current, page: pageConfig.INITIAL_PAGE });
  };

  /**
   * Download file handler
   * @param res
   */
  const handleFileDownload = (response: AxiosResponse<any, any>) => {
    const fileDownload = require("js-file-download");
    const date = {
      start: !!mainRequestParams.startDate
        ? moment(Number(mainRequestParams.startDate) * 1000)
            .format("DDMMYYYY")
            .replace(/\//g, "")
        : "",
      end: !!mainRequestParams.endDate
        ? moment(Number(mainRequestParams.endDate) * 1000)
            .subtract(1, "days")
            .format("DDMMYYYY")
            .replace(/\//g, "")
        : "",
    };

    if (date.start && date.end) {
      fileDownload(response, `analytics_export_${date.start}_${date.end}.xlsx`);
      return;
    }

    fileDownload(response, `analytics_export.xlsx`);
  };

  /**
   * Returns the time filter object
   * This function works alongside with getDateFilter()
   * @returns {object}
   */
  const getTimeFilter = () => {
    if (filters.get(ReferralAnalyticsDateFilterKey.TIME_RANGE)) {
      const startTime = (filters.get(ReferralAnalyticsDateFilterKey.TIME_RANGE) as string[])[0].split(":");
      const endTime = (filters.get(ReferralAnalyticsDateFilterKey.TIME_RANGE) as string[])[1].split(":");
      return { startTime: startTime, endTime: endTime };
    }
    return null;
  };

  /**
   * Returns the date filter object
   * This function needs to be called after getTimeFilter()
   * @returns {object}
   */
  const getDateFilter = () => {
    if (filters.get(ReferralAnalyticsDateFilterKey.START_DATE)) {
      let startDate,
        endDate = null;
      const timeFilter = getTimeFilter();
      if (timeFilter) {
        startDate = moment((filters.get(ReferralAnalyticsDateFilterKey.START_DATE) as string[])[0])
          .utcOffset(0)
          .hours(Number(timeFilter.startTime[0]))
          .minutes(Number(timeFilter.startTime[1]))
          .seconds(0);
        endDate = moment((filters.get(ReferralAnalyticsDateFilterKey.END_DATE) as string[])[0])
          .utcOffset(0)
          .hours(Number(timeFilter.endTime[0]))
          .minutes(Number(timeFilter.endTime[1]))
          .seconds(59);
      } else {
        startDate = moment((filters.get(ReferralAnalyticsDateFilterKey.START_DATE) as string[])[0])
          .utcOffset(0)
          .hours(0)
          .minutes(0)
          .seconds(0);
        endDate = moment((filters.get(ReferralAnalyticsDateFilterKey.END_DATE) as string[])[0])
          .utcOffset(0)
          .hours(0)
          .minutes(0)
          .seconds(0)
          .add(1, "d");
      }
      return { startDate: startDate.unix(), endDate: endDate.unix() };
    }
    return null;
  };

  /**
   * Verify if BO is in MZ market
   * @returns {boolean}
   */
  const isMZMarket = (process.env.REACT_APP_MARKET as string).replace(/\s/g, "") === BackOfficeMarketsEnums.MZ;

  /**
   * A set of referral analytics action functions to invites and rewards, and file export
   */
  const referralAnalyticsRequestActions = {
    getInvitesData: async () => {
      dispatch(ReferralAnalyticsStoreActions.creators.getReferralAnalyticsInvites());
      setIsFetchingData(true);
      try {
        let response;
        if (isMZMarket) {
          response = await ReferralCampaignsApi.methods.getSendersList(mainRequestParams);
        } else {
          response = await ReferralCampaignsApi.methods.getInvitesList(mainRequestParams);
        }
        dispatch(ReferralAnalyticsStoreActions.creators.setReferralAnalyticsInvites(response.data));
        setIsFetchingData(false);
      } catch (error) {
        setIsFetchingData(false);
        setAlertProps({
          title: t("pages.referralCampaigns.analytics.getInvitesError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      }
    },
    getRewardsData: async () => {
      dispatch(ReferralAnalyticsStoreActions.creators.getReferralAnalyticsRewards());
      setIsFetchingData(true);
      try {
        let response;
        if (isMZMarket) {
          response = await ReferralCampaignsApi.methods.getReceiversList(mainRequestParams);
        } else {
          response = await ReferralCampaignsApi.methods.getRewardsList(mainRequestParams);
        }
        dispatch(ReferralAnalyticsStoreActions.creators.setReferralAnalyticsRewards(response.data));
        setIsFetchingData(false);
      } catch (error) {
        setIsFetchingData(false);
        setAlertProps({
          title: t("pages.referralCampaigns.analytics.getRewardsError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      }
    },
    getDashboardData: async () => {
      setDashboardLoading(true);
      try {
        const response = await ReferralCampaignsApi.methods.getDashboardData(dashboardParams);
        dispatch(ReferralAnalyticsStoreActions.creators.setReferralAnalyticsDashboard(response.data));
        setDashboardLoading(false);
      } catch (error) {
        setDashboardLoading(false);
        setAlertProps({
          title: t("pages.referralCampaigns.analytics.dashboard.getDashboardError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      }
    },
    clearDasboardData: () => {
      dispatch(ReferralAnalyticsStoreActions.creators.clearReferralAnalyticsDashboard());
    },
    clearInvitesData: () => {
      dispatch(ReferralAnalyticsStoreActions.creators.clearReferralAnalyticsInvites());
    },
    clearRewardsData: () => {
      dispatch(ReferralAnalyticsStoreActions.creators.clearReferralAnalyticsRewards());
    },
    getExcelFile: async () => {
      setIsFetchingData(true);
      const userDefaultTimezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
      dispatch(ReferralAnalyticsStoreActions.creators.getReferralAnalyticsExcelFile());

      try {
        const response = await ReferralCampaignsApi.methods.getExcelFile({
          ...mainRequestParams,
          type: sectionId ? EFormAnalyticsType.RECEIVERS : EFormAnalyticsType.SENDERS,
          timeZone: userDefaultTimezone,
        });
        handleFileDownload(response.data);
        setAlertProps({
          title: t("pages.referralCampaigns.analytics.exportSuccessful"),
          type: AlertTypeEnum.SUCCESS,
        });
      } catch (error) {
        setAlertProps({
          title: t("pages.referralCampaigns.analytics.exportError"),
          type: AlertTypeEnum.ERROR,
        });
      } finally {
        showAlert();
        setIsFetchingData(false);
      }
    },
  };

  /**
   * Makes an update request to the BE in order to update form data row status
   * fetches according to the current section
   * @param data { itemId: number | string; statusCode: EFormDataStatus }
   */
  const updateFormDataRow = async (data: { itemId: number | string; value: string }) => {
    try {
      await ReferralCampaignsApi.methods.updateStatus(dashboardParams, data);
      const invites = sectionId ? analyticsRewards : analyticsInvites;
      const setAnalytics = sectionId
        ? ReferralAnalyticsStoreActions.creators.setReferralAnalyticsRewards
        : ReferralAnalyticsStoreActions.creators.setReferralAnalyticsInvites;

      /** Update table list after change the status */
      const updatedData = invites.list.map((invite: any) => {
        if (invite.id === data.itemId) {
          return {
            ...invite,
            rewardStatus: data.value,
          };
        }

        return invite;
      });

      dispatch(setAnalytics({ ...invites, list: updatedData }));
      /** Update dashboard data */
      referralAnalyticsRequestActions.getDashboardData();
      setAlertProps({
        title: t("pages.referralCampaigns.analytics.rewardStatus.onUpdateFormRowStatus"),
        type: AlertTypeEnum.SUCCESS,
      });
      showAlert();
    } catch (error) {
      setAlertProps({
        title: t("pages.referralCampaigns.analytics.rewardStatus.onUpdateFormRowStatusError"),
        type: AlertTypeEnum.ERROR,
      });
      showAlert();
    }
  };

  /**
   * Updates the request state each time the filters are changed
   */
  React.useEffect(() => {
    if (firstRenderRef.current.dateFilter <= 0) {
      firstRenderRef.current.dateFilter = ++firstRenderRef.current.dateFilter;
      return;
    }

    const dateFilter = getDateFilter();
    if (dateFilter) {
      staticTempRequestParams.current = { ...staticTempRequestParams.current, ...dateFilter };
    } else {
      const { startDate, endDate, ...rest } = staticTempRequestParams.current;
      staticTempRequestParams.current = { ...rest };
    }
    setActivePage(pageConfig.INITIAL_PAGE);
    setMainRequestParams(staticTempRequestParams.current);
  }, [filters]);

  /**
   * Updates staticRequestParams when searchQuery is updated
   */
  React.useEffect(() => {
    staticTempRequestParams.current = { ...staticTempRequestParams.current, msisdn: searchQuery };
  }, [searchQuery]);

  /**
   * Resets all filters (search, date picker)
   * Reset activePage to default Initial Page
   * Reset tempRequestParams
   * Updates the request state that will trigger a fetch action
   */
  React.useEffect(() => {
    resetFilters();
    setSearchQuery("");
    setIsFetchingWithParams(false);
    setActivePage(pageConfig.INITIAL_PAGE);
    firstRenderRef.current.dateFilter = 0;
    staticTempRequestParams.current = {
      id: referralCampaignID,
      page: pageConfig.INITIAL_PAGE,
      pageSize: pageConfig.PAGE_LIST_ROWS,
    };

    setMainRequestParams({
      id: referralCampaignID,
      page: pageConfig.INITIAL_PAGE,
      pageSize: pageConfig.PAGE_LIST_ROWS,
    });
    setDashboardParams({
      ...dashboardParams,
      type: sectionId ? EFormAnalyticsType.RECEIVERS : EFormAnalyticsType.SENDERS,
    });
  }, [sectionId]);

  /**
   * Fetches Analytics on page first load and also when param changes
   */
  React.useEffect(() => {
    if (firstRenderRef.current.pageRequest <= 0) {
      firstRenderRef.current.pageRequest = ++firstRenderRef.current.pageRequest;
      return;
    }

    if ((mainRequestParams.startDate && mainRequestParams.endDate) || mainRequestParams.msisdn) {
      setIsFetchingWithParams(true);
    } else {
      setIsFetchingWithParams(false);
    }
    handleRequestParamsChange();
  }, [mainRequestParams]);

  return (
    <>
      <AnalyticsContainer>
        <ContainerHeader>
          <div className="container_header_left">{/* Future enhancement: Filter by Date */}</div>
          <div className="container_header_right">{/* Future enhancement: Refresh and Export */}</div>
        </ContainerHeader>

        <ContainerBody>
          <div className="container_body_tabs">
            <Tabs controlledSelectedTab={sectionId} controlledSetSelectedTab={setSectionId}>
              {sectionTabs.map((label, idx) => (
                <TabChild key={idx} label={label}>
                  <AbsoluteRow>
                    <RefreshAndExportAction
                      refreshLabel={t("pages.referralCampaigns.analytics.refresh")}
                      refreshCurrentTab={handleRefresh}
                      exportLabel={t("pages.referralCampaigns.analytics.export")}
                      exportFile={referralAnalyticsRequestActions.getExcelFile}
                      totals={{ totalRewards: analyticsRewards.count, totalInvites: analyticsInvites.count }}
                    />
                  </AbsoluteRow>

                  <RelativeRow>
                    <SearchByMsisdn
                      searchLabel={t("pages.referralCampaigns.analytics.searchPlaceholder")}
                      searchString={searchQuery}
                      onChangeFn={setSearchQuery}
                      onKeyDownFn={handleRefresh}
                    />

                    <DateFilter
                      filters={filters}
                      updateFilter={updateFilter}
                      isFilterActive={isFilterActive}
                      clearFilter={clearFilter}
                      getFilterValue={getFilterValue}
                      updateMultipleFilters={updateMultipleFilters}
                      minSelectableDate={referralCampaignStartDate}
                      translations={{
                        dropdownPlaceholder: t("pages.referralCampaigns.analytics.dateFilterPlaceholder"),
                        clearFilters: t("components.dateFilter.clearFilters"),
                        CUSTOM: t("components.dateFilter.customRange"),
                        LAST_3_MONTH: t("components.dateFilter.last3Months"),
                        LAST_6_MONTH: t("components.dateFilter.last6Months"),
                        LAST_MONTH: t("components.dateFilter.lastMonth"),
                        LAST_WEEK: t("components.dateFilter.lastWeek"),
                        LAST_YEAR: t("components.dateFilter.lastYear"),
                      }}
                    />
                  </RelativeRow>

                  {isMZMarket && (
                    <RelativeChartRow>
                      {!dashboardLoading &&
                        analyticsDashboard &&
                        analyticsDashboard.hasRewards &&
                        Object.keys(analyticsDashboard).length > 1 && (
                          <>
                            <DoughnutChart
                              chartData={
                                [
                                  {
                                    label: t("pages.referralCampaigns.analytics.dashboard.rewardsProgress"),
                                    data: analyticsDashboard.numberRewards.pending,
                                    backgroundColor: "#EB9700",
                                  },
                                  {
                                    label: t("pages.referralCampaigns.analytics.dashboard.rewardsFailed"),
                                    data: analyticsDashboard.numberRewards.failed,
                                    backgroundColor: "#E60000",
                                  },
                                  {
                                    label: t("pages.referralCampaigns.analytics.dashboard.rewardsDistributed"),
                                    data: analyticsDashboard.numberRewards.sent,
                                    backgroundColor: "#A8B400",
                                  },
                                  {
                                    label: t("pages.referralCampaigns.analytics.dashboard.rewardsRejected"),
                                    data: analyticsDashboard.numberRewards.rejected,
                                    backgroundColor: "#00B0CA",
                                  },
                                ] as IDoughnutChartDataProps[]
                              }
                              centerText={
                                <>
                                  <p style={{ margin: 0, fontFamily: "Vodafone Rg", fontSize: 16 }}>
                                    {t("pages.referralCampaigns.analytics.dashboard.numberRewards")}
                                  </p>
                                  <p style={{ margin: 0, fontFamily: "Vodafone Rg", fontSize: 22 }}>
                                    {analyticsDashboard.numberRewards.total}
                                  </p>
                                </>
                              }
                              chartCutOut="83%"
                              chartLegendData={
                                [
                                  {
                                    label: t("pages.referralCampaigns.analytics.dashboard.rewardsDistributed"),
                                    description: analyticsDashboard.numberRewards.sent,
                                    descriptionColor: "#A8B400",
                                  },
                                  {
                                    label: t("pages.referralCampaigns.analytics.dashboard.rewardsProgress"),
                                    description: `${analyticsDashboard.numberRewards.pending}`,
                                    descriptionColor: "#EB9700",
                                    extraItemStyle: { marginTop: "8px" },
                                  },
                                  {
                                    label: t("pages.referralCampaigns.analytics.dashboard.rewardsFailed"),
                                    description: `${analyticsDashboard.numberRewards.failed}`,
                                    descriptionColor: "#E60000",
                                    extraItemStyle: { marginTop: "8px" },
                                  },
                                  {
                                    label: t("pages.referralCampaigns.analytics.dashboard.rewardsRejected"),
                                    description: `${analyticsDashboard.numberRewards.rejected}`,
                                    descriptionColor: "#00B0CA",
                                    extraItemStyle: { marginTop: "8px" },
                                  },
                                ] as IDoughnutChartLegendProps[]
                              }
                              chartLegendExtraStyle={{ marginLeft: "12px" }}
                              chartWrapperExtraStyle={{ marginTop: "20px" }}
                            />

                            <DoughnutChart
                              chartData={
                                [
                                  {
                                    label: t("pages.referralCampaigns.analytics.dashboard.amountProgress"),
                                    data: analyticsDashboard.amountRewards.pending,
                                    backgroundColor: "#EB9700",
                                  },
                                  {
                                    label: t("pages.referralCampaigns.analytics.dashboard.amountFailed"),
                                    data: analyticsDashboard.amountRewards.failed,
                                    backgroundColor: "#E60000",
                                  },
                                  {
                                    label: t("pages.referralCampaigns.analytics.dashboard.amountDistributed"),
                                    data: analyticsDashboard.amountRewards.sent,
                                    backgroundColor: "#A8B400",
                                  },
                                  {
                                    label: t("pages.referralCampaigns.analytics.dashboard.amountRejected"),
                                    data: analyticsDashboard.amountRewards.rejected,
                                    backgroundColor: "#00B0CA",
                                  },
                                ] as IDoughnutChartDataProps[]
                              }
                              centerText={
                                <>
                                  <p style={{ margin: 0, fontFamily: "Vodafone Rg", fontSize: 16 }}>
                                    {!sectionId
                                      ? t("pages.referralCampaigns.analytics.dashboard.rewardAmount")
                                      : t("pages.referralCampaigns.analytics.dashboard.totalRewardAmount")}
                                  </p>
                                  <p style={{ margin: 0, fontFamily: "Vodafone Rg", fontSize: 22 }}>
                                    {`${analyticsDashboard.amountRewards.total} MT`}
                                  </p>
                                </>
                              }
                              chartCutOut="83%"
                              chartLegendData={
                                [
                                  {
                                    label: t("pages.referralCampaigns.analytics.dashboard.amountDistributed"),
                                    description: `${analyticsDashboard.amountRewards.sent} MT`,
                                    descriptionColor: "#A8B400",
                                  },
                                  {
                                    label: t("pages.referralCampaigns.analytics.dashboard.amountProgress"),
                                    description: `${analyticsDashboard.amountRewards.pending} MT`,
                                    descriptionColor: "#EB9700",
                                    extraItemStyle: { marginTop: "8px" },
                                  },
                                  {
                                    label: t("pages.referralCampaigns.analytics.dashboard.amountFailed"),
                                    description: `${analyticsDashboard.amountRewards.failed} MT`,
                                    descriptionColor: "#E60000",
                                    extraItemStyle: { marginTop: "8px" },
                                  },
                                  {
                                    label: t("pages.referralCampaigns.analytics.dashboard.amountRejected"),
                                    description: `${analyticsDashboard.amountRewards.rejected} MT`,
                                    descriptionColor: "#00B0CA",
                                    extraItemStyle: { marginTop: "8px" },
                                  },
                                ] as IDoughnutChartLegendProps[]
                              }
                              chartLegendExtraStyle={{ marginLeft: "12px" }}
                              chartWrapperExtraStyle={{ marginTop: "20px" }}
                            />
                          </>
                        )}

                      {!dashboardLoading && analyticsDashboard && analyticsDashboard.hasRewards === false && (
                        <DoughnutChart
                          chartData={
                            [
                              {
                                label: !sectionId
                                  ? t("pages.referralCampaigns.analytics.dashboard.totalNumberSenders")
                                  : t("pages.referralCampaigns.analytics.dashboard.totalNumberReceivers"),
                                data: !sectionId
                                  ? analyticsDashboard.inviteCount
                                    ? analyticsDashboard.inviteCount
                                    : 1
                                  : analyticsDashboard.inviteCount
                                  ? analyticsDashboard.inviteCount
                                  : 1,
                                backgroundColor: "#A8B400",
                              },
                            ] as IDoughnutChartDataProps[]
                          }
                          centerText={
                            <>
                              <p style={{ margin: 0, fontFamily: "Vodafone Rg", fontSize: 16 }}>
                                {!sectionId
                                  ? t("pages.referralCampaigns.analytics.dashboard.totalNumberSenders")
                                  : t("pages.referralCampaigns.analytics.dashboard.totalNumberReceivers")}
                              </p>
                              <p style={{ margin: 0, fontFamily: "Vodafone Rg", fontSize: 22 }}>
                                {analyticsDashboard.inviteCount ? analyticsDashboard.inviteCount : 0}
                              </p>
                            </>
                          }
                          chartCutOut="94%"
                          borderWidth={0}
                          borderRadius={0}
                        />
                      )}
                    </RelativeChartRow>
                  )}

                  <RelativeTableRow>
                    <AnalyticsTable
                      columnsConfig={
                        !sectionId
                          ? isMZMarket
                            ? pageConfig.SECTION_COLUMN.senders
                            : pageConfig.SECTION_COLUMN.invites
                          : isMZMarket
                          ? pageConfig.SECTION_COLUMN.receivers
                          : pageConfig.SECTION_COLUMN.rewards
                      }
                      noResultsText={
                        !sectionId
                          ? isFetchingWithParams
                            ? t("pages.referralCampaigns.analytics.noInvitesOnSearch")
                            : t("pages.referralCampaigns.analytics.noInvites")
                          : isFetchingWithParams
                          ? t("pages.referralCampaigns.analytics.noRewardsOnSearch")
                          : t("pages.referralCampaigns.analytics.noRewards")
                      }
                      data={!sectionId ? analyticsInvites?.list || [] : analyticsRewards?.list || []}
                      onSelectedRowStatusChange={updateFormDataRow}
                    />
                  </RelativeTableRow>
                </TabChild>
              ))}
            </Tabs>
          </div>
        </ContainerBody>

        {!!analyticsRewards.count || !!analyticsInvites.count ? (
          <ContainerFooter>
            <TablePagination
              handlePageChange={handlePageChange}
              totalItems={sectionId ? analyticsRewards?.count || 0 : analyticsInvites?.count || 0}
              activePage={activePage}
              pageSize={pageConfig.PAGE_LIST_ROWS}
            />
          </ContainerFooter>
        ) : null}
      </AnalyticsContainer>
    </>
  );
};

export default ReferralAnalytics;
