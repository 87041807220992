import React from "react";

function IconPlaceHolderIcon(props: React.HTMLProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.333 5.833h39.334a2.5 2.5 0 0 1 2.5 2.5v39.334a2.5 2.5 0 0 1-2.5 2.5H8.333a2.5 2.5 0 0 1-2.5-2.5V8.333a2.5 2.5 0 0 1 2.5-2.5zm-2.5 33.904 11.734-19.56L31.911 34.52l5.215-5.215 13.04 13.037m-10.5-22.362a3.646 3.646 0 1 0 0-7.292 3.646 3.646 0 0 0 0 7.292z"
        stroke="#999"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconPlaceHolderIcon;
