import {
  MultipleOptionsDropdown,
  DateRangeDropdown,
  RecurringIcon,
  SearchBar,
  SmallButton,
  UndoIcon,
} from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { FilterFuncts } from "@wit/mpesa-ui-components/lib/hooks/use-filters.hook";
import { useTranslation } from "react-i18next";
import React from "react";
import styled from "styled-components";
import { RatioContainer, Row } from "../../../../shared/shared.styled";
import { onEnterPressed } from "../doc-bulk.utils";
import { ClientDocBulkStatusEnum } from "../../ekyc.shared.enums";

export enum DocBulkListFilterKeys {
  SEARCH = "search",
  START_DATE = "startDate",
  END_DATE = "endDate",
  STATUS = "requestStatus",
}

interface IDocBulkListFilterProps extends FilterFuncts {
  entriesCount: number;
  refreshResults: () => void;
}

const DocBulkListFilters = ({
  filters,
  clearFilter,
  isFilterActive,
  resetFilters,
  updateFilter,
  updateMultipleFilters,
  entriesCount,
  refreshResults,
}: IDocBulkListFilterProps) => {
  const [t] = useTranslation();

  return (
    <>
      <CustomRow>
        {/* Free text search */}
        <RatioContainer ratio={4 / 12}>
          <SearchBar
            onKeyDown={e => {
              onEnterPressed(e, refreshResults);
            }}
            value={
              filters.has(DocBulkListFilterKeys.SEARCH)
                ? (filters.get(DocBulkListFilterKeys.SEARCH) as string[])[0]
                : ""
            }
            onChange={e => updateFilter(DocBulkListFilterKeys.SEARCH, e.target.value, true)}
            clearValue={() => {
              clearFilter(DocBulkListFilterKeys.SEARCH);
            }}
            placeholderLabel={t("pages.docBulk.filters.search")}
          />
        </RatioContainer>

        {/* Date search */}
        <RatioContainer ratio={3 / 12}>
          <DateRangeDropdown
            filters={filters}
            isFilterActive={isFilterActive}
            clearFilter={clearFilter}
            updateFilter={updateFilter}
            updateMultipleFilters={updateMultipleFilters}
          />
        </RatioContainer>

        {/* Status search */}
        <RatioContainer ratio={2 / 12}>
          <MultipleOptionsDropdown
            options={Object.keys(ClientDocBulkStatusEnum)
              .filter(s => typeof s === "string" && s !== "UNKNOWN")
              .map(status => {
                return {
                  label: t(`commons.clientDocBulkStatus.${status}`),
                  key: status,
                };
              })}
            toggleOption={opt => updateFilter(DocBulkListFilterKeys.STATUS, opt.key)}
            isOptionSelected={opt => isFilterActive(DocBulkListFilterKeys.STATUS, opt.key)}
            clearAllFilters={() => clearFilter(DocBulkListFilterKeys.STATUS)}
            hasValue={!!filters.get(DocBulkListFilterKeys.STATUS)}
            label={t("pages.docBulk.filters.status")}
          ></MultipleOptionsDropdown>
        </RatioContainer>
      </CustomRow>

      <Row style={{ marginBottom: 12 }}>
        {/* Records counter */}
        <DocBulksCount>
          <span>{entriesCount}</span>
          &nbsp;
          {`${t("pages.docBulk.filters.recordsFound")}`}
        </DocBulksCount>

        {/* Search submit button */}
        <div style={{ marginRight: 24 }}>
          <SmallButton
            titleLabel={t("pages.docBulk.filters.refreshResults")}
            onClick={() => refreshResults()}
            iconComponent={
              <SmallButtonIconContainer color={styleTheme.palette.turquoiseBlue}>
                <RecurringIcon />
              </SmallButtonIconContainer>
            }
          />
        </div>
        <div>
          <SmallButton
            titleLabel={t("pages.docBulk.filters.clearFilters")}
            onClick={() => {
              setTimeout(() => {
                resetFilters();
              }, 0);
            }}
            iconComponent={
              <SmallButtonIconContainer color={styleTheme.palette.vodafoneRed}>
                <UndoIcon />
              </SmallButtonIconContainer>
            }
          />
        </div>
      </Row>
    </>
  );
};

export default DocBulkListFilters;

const CustomRow = styled(Row)`
  margin-bottom: 18px;

  > ${RatioContainer} {
    margin-right: 24px;

    :last-of-type {
      margin-right: 0;
    }
  }
`;

const DocBulksCount = styled("div")`
  display: flex;
  flex-direction: row;
  margin-right: 24px;
  font-family: Vodafone Rg;
  font-size: 14px;
  color: ${props => props.theme.palette.midGrey};

  > span {
    font-weight: bold;
  }
`;

const SmallButtonIconContainer = styled("div")<{ color: string }>`
  width: 16px;
  height: 16px;
  stroke: ${props => props.color};

  svg {
    width: 16px;
    height: 16px;
    stroke: ${props => props.color};
  }
`;
