import Lottie from "lottie-react-web";
import React from "react";
import styled from "styled-components";
import { isSFCMarket } from "../../shared.utils";
import loadingSfc from "./loadingSfc.json";
import loadingVdf from "./loadingVdf.json";

interface ILoadingComponentProps {
  isCentered?: boolean;
  loadingText?: string;
}
const LoadingComponent = ({ isCentered = false, loadingText = "" }: ILoadingComponentProps) => {
  return (
    <LoadingContainer isCentered={isCentered}>
      <Lottie
        options={{
          animationData: isSFCMarket() ? loadingSfc : loadingVdf,
        }}
        width={"60px"}
        height={"60px"}
      />
      {!!loadingText && <LoadingText>{loadingText}</LoadingText>}
    </LoadingContainer>
  );
};
const LoadingContainer = styled("div")<{ isCentered: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  height: 100%;
  justify-content: center;
`;

const LoadingText = styled("span")`
  font-size: 16px;
  font-family: "Vodafone Rg";
`;

export default LoadingComponent;
