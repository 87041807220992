import React, { PropsWithChildren } from "react";
import { Manager, Reference, Popper } from "react-popper";
import styled from "styled-components";
import { ThreeDotsIcon } from "@wit/mpesa-ui-components";
import { IUser } from "../models/user.model";

interface ICustomPopperProps {
  user: IUser;
}

const CustomPopper = ({ children, user }: PropsWithChildren<ICustomPopperProps>) => {
  const [popperVisible, setPopperVisible] = React.useState(false);

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <IconContainer
            className="status-buttons"
            id={`user-${user.username}`}
            ref={ref}
            onClick={e => {
              e.stopPropagation();
              setPopperVisible(c => !c);
            }}
          >
            <ThreeDotsIcon />
          </IconContainer>
        )}
      </Reference>
      {popperVisible ? (
        <Popper placement="bottom-end">
          {({ ref, style, placement, arrowProps }) => (
            <>
              <PopperContainer ref={ref} style={style} data-placement={placement}>
                {children}
              </PopperContainer>
            </>
          )}
        </Popper>
      ) : null}
    </Manager>
  );
};

export default CustomPopper;

const PopperContainer = styled("div")`
  border-radius: 4px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: white;
  z-index: 10;
`;

const IconContainer = styled.div`
  height: 100%;
  svg {
    stroke: #333;
    path {
      fill: #333;
    }
  }
`;
