import { TaskStatusEnum } from "../../../features/admin/tasks/task.model";

export interface INotification {
  id: string | number; // generic for possible other types of notifications in the future
  description: string; // generic for possible other types of notifications in the future (string interpolation | backend string)
  date: number; // generic for possible other types of notifications in the future
  read: boolean; // generic for possible other types of notifications in the future
  task?: {
    // task notification (optional)
    author: { id: string; name: string }; // maker and checker
    approver?: { id: string; name: string }; // maker and checker
    section: MakerCheckerSectionsEnum; // maker and checker
    id: string | number; // maker and checker
    status?: TaskStatusEnum; //checker
    date?: number; // maker notifications
    updateDate?: number;
    reason?: string;
  };
}

export enum MakerCheckerSectionsEnum {
  DISCOVER_CARDS = "DISCOVER_CARDS",
  RATE_CARDS = "RATE_CARDS",
  SERVICES = "SERVICES",
  LANGUAGE_TRANSLATIONS = "APP_TRANSLATIONS",
  ACTION_ITEMS = "ACTION_ITEMS",
}
