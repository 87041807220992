import { AxiosPromise } from "axios";
import ApiClient from "../../../configs/axios.config";
import { IBlacklistResponse, IBlockedTillNumber, IBlacklistParameters } from "./request-payment.interfaces";

const RequestPaymentApi = {
  routes: {
    blacklist: "blacklist-tills",
  },
  methods: {
    getBlacklist: (params: IBlacklistParameters): AxiosPromise<IBlacklistResponse> => {
      return ApiClient.get(
        `${RequestPaymentApi.routes.blacklist}?page=${params.page - 1}&pageSize=${params.pageSize}${
          params.blockedTillNumber ? `&tillNumber=${params.blockedTillNumber}` : ""
        }`,
      );
    },
    addToBlacklist: (number: IBlockedTillNumber, params: IBlacklistParameters): AxiosPromise => {
      return ApiClient.post(`${RequestPaymentApi.routes.blacklist}`, {
        tillNumber: number.tillNumber,
      });
    },
    removeFromBlacklist: (number: IBlockedTillNumber, params: IBlacklistParameters): AxiosPromise => {
      return ApiClient.delete(`${RequestPaymentApi.routes.blacklist}`, {
        data: { tillNumber: number.tillNumber },
      });
    },
  },
};

export default RequestPaymentApi;
