import {
  AutoClosingDropdown,
  CancelIcon,
  Checkbox,
  MultipleOptionsDropdown,
  PrimaryButton,
  SecondaryPageTitle,
  TextInput,
  useAlert,
  TextInputWithTag,
} from "@wit/mpesa-ui-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikHelpers, useFormikContext, yupToFormErrors } from "formik";
import { FormikErrors } from "formik/dist/types";
import i18next from "i18next";
import React, { useContext, useState, useRef } from "react";
import { DropEvent, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import styled, { ThemeContext } from "styled-components";
import { array, object, string } from "yup";
import { RoutesEnum } from "../../../../routes/routes.constants";
import ColorPicker from "../../../../shared/components/color-picker.component";
import IconPicker, { IIcon } from "../../../../shared/components/icon-picker.component";
import HelpIcon from "../../../../shared/icons/help.icon";
import IconPlaceHolderIcon from "../../../../shared/icons/icon-placeholder.icon";
import ImagePlaceHolderIcon from "../../../../shared/icons/image-placeholder.icon";
import { ILanguage } from "../../../../shared/models/language.model";
import { PageContent } from "../../../../shared/responsive-ui.styled";
import LanguagesApi from "../../../../shared/services/languages.api";
import {
  DragDropFileName,
  DragDropText,
  DragDropTextInfo,
  IconWrapper,
  ImageDetailsHelper,
  LoadingText,
  PageContainer,
  Row,
} from "../../../../shared/shared.styled";
import { fileToBase64, createImage, getVodafoneColorPalette, RegExEpressions } from "../../../../shared/shared.utils";
import {
  FormLabel,
  FormSectionContainer,
  FormSectionRow,
  SectionColumn,
} from "../../discover-cards/add-discover-card/add-discover-card.page";
import DynamicScreenStatusChip from "../components/dynamic-screen-status-chip.component";

import DynamicScreensApi from "../dynamic-screens.api";
import { IDynamicScreenCategory, IDynamicScreenRequest } from "../dynamic-screens.model";
import GenericLanguageSelector from "../../../../shared/components/generic-language-selector.component";
import useEmptyLanguageWarningModal from "../../../../shared/hooks/use-empty-language-warning-modal.hook";

/**
 * Dynamic screen page
 * @returns
 */
const DynamicScreenPage = () => {
  const { id } = useParams<any>();
  const [t] = useTranslation();
  const [showAlert, , setAlertProps] = useAlert();
  const history = useHistory();
  const [availableLanguages, setAvailableLanguages] = React.useState<ILanguage[]>();
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>("en");
  const [sections, setSections] = React.useState<string[]>([]);
  const [categories, setCategories] = React.useState<IDynamicScreenCategory[]>([]);
  const [selectedCategories, setSelectedCategories] = React.useState<string[]>([]);
  const [formValues, setFormValues] = useState<IDynamicScreenRequest>();
  const [icons, setIcons] = useState<IIcon[]>();
  const [isLoadingIcons, setIsLoadingIcons] = useState<boolean>(false);
  const [canBeHighlighted, setCanBeHighlighted] = useState<boolean>(false);

  const [selectedDropzoneId, setSelectedDropzoneId] = useState<string | undefined>();
  const [img, setImg] = useState<File | undefined>();
  const [imgName, setImgName] = useState<string | undefined>();
  const [imgUrl, setImgUrl] = useState<string | File | undefined>();
  const [showImageDetailsError, setShowImageDetailsError] = useState(false);

  const [iconLogo, setIconLogo] = useState<File | undefined>();
  const [iconLogoName, setIconLogoName] = useState<string | undefined | null>();
  const [iconLogoUrl, setIconLogoUrl] = useState<string | File | undefined>();
  const [showIconLogoDetailsError, setShowIconLogoDetailsError] = useState(false);

  const tagsSuggestions = useRef(new Array<string>());
  const [tags, setTags] = useState<string[]>([]);
  const [tagInputText, setTagInputText] = useState<string>("");
  const [tagErrorText, setTagErrorText] = useState<string>("");

  const themeContext = useContext(ThemeContext);
  const DEFAULT_COLOR = themeContext.palette.silver;
  const ICON_BG_COLOR = themeContext.palette.silver;
  const DEFAULT_ICON =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwBAMAAAClLOS0AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAnUExURaCgoEdwTJ+fn6CgoKCgoKCgoK2trZ+fn7KysqCgoKKioqCgoKCgoO8GHUsAAAANdFJOU7MA+2jI8gZEC5kchC+AkRIIAAABIElEQVQ4y2MQxAEYqCshZcmAAiYvhEqYqqBKOAVDJIQ1G1FNkZhkCJZYOhHdfMkosIRRI7qEhDJYYgqmkzzBEiguFjNgToSJokikKDC5YZXYdFBGGasEAyoiLCFmhG7UGhyWSzgVYneutIo63DRkmwSXbmMqxOYEQdbGBHVsEuLKgmLIWuASKY6Cgsha4BKbDgqiaIFJSEWDeGAtwgeRJQQiwO4EadkUjixRZCgI1SKt7VqIkBBzgXqNqXCTYUs4QkJaB2pngoKyoARIC1Ri6UZYaDABzUwJh0uwwpNJDig8gVogEqzKqEkEqAUiweSIlqpcC6ESBwXRtWCJc4gWB+wSwPjFISGBSwdUFGeixpkNcGYcnFkNZ+bEnZ1pXZYAAIMJapSnllMzAAAAAElFTkSuQmCC\n";

  const { warningModalOpen, showWarning, setShowWarning } = useEmptyLanguageWarningModal(availableLanguages || []);
  /**
   * Submit method wrapper to show warning if needed
   * @param values
   */
  const submitWrapper = (values: any, actions: FormikHelpers<any>) => {
    if (showWarning) {
      setShowWarning(false);
      actions.setSubmitting(false);
      warningModalOpen(handleSubmit, values, actions);
    } else {
      handleSubmit(values, actions);
    }
  };

  /*
   * Sets the initial state of the form
   * */
  React.useEffect(() => {
    getAvailableLanguages();
    getCanBeHighlighted();
    getIcons();
  }, []);

  /**
   * Fetches the list of icons that can be selected
   */
  const getIcons = () => {
    setIsLoadingIcons(true);
    DynamicScreensApi.methods
      .getIcons()
      .then(
        res => {
          setIcons(res.data);
        },
        () => {
          setAlertProps({
            type: AlertTypeEnum.ERROR,
            title: t("pages.dynamicScreen.getIconsError"),
          });
          showAlert();
        },
      )
      .finally(() => {
        setIsLoadingIcons(false);
      });
  };

  /**
   * Checks if the service can be highlighted or not
   */
  const getCanBeHighlighted = () => {
    DynamicScreensApi.methods.getCanBeHighlighted().then(res => {
      setCanBeHighlighted(res.data.canBeHighlighted);
    });
  };

  /**
   * Fetches the list of categories and sections
   */
  const getDynamicScreenCategories = (languages: ILanguage[]) => {
    DynamicScreensApi.methods.getDynamicScreensCategories().then(
      res => {
        setCategories(res.data.items);
        DynamicScreensApi.methods.getSections().then(
          res2 => {
            const sectionsSorted = res2.data.items;
            sectionsSorted.sort();
            setSections(sectionsSorted);
            if (id) {
              getDynamicScreen();
            } else {
              setupLanguages(languages, res.data.items, res2.data.items);
            }
          },
          () => {
            setAlertProps({
              type: AlertTypeEnum.ERROR,
              title: t("pages.dynamicScreen.getServiceGroupError"),
            });
            showAlert();
          },
        );
      },
      () => {
        setAlertProps({
          type: AlertTypeEnum.ERROR,
          title: t("pages.dynamicScreen.getDynamicScreenCategoriesError"),
        });
        showAlert();
      },
    );
  };

  /**
   * Gets the dynamic screen payload
   */
  const getDynamicScreen = () => {
    DynamicScreensApi.methods.getDynamicScreen(id).then(
      res => {
        setSelectedCategories(res.data.categories.map(c => c.id));
        setSelectedLanguage(
          Object.keys(res.data.name.translations).includes("en") ? "en" : Object.keys(res.data.name.translations)[0],
        );
        setFormValues({
          name: { translations: res.data.name.translations },
          description: { translations: res.data.description.translations },
          section: res.data.section,
          categories: res.data.categories.map(c => c.id),
          isActive: res.data.isActive,
          eventId: res.data.eventId,
          highlighted: res.data.highlighted,
          color: res.data.color,
          icon: res.data.icon,
          urlImage: !!res.data.urlImage ? `${res.data.urlImage}?time=${new Date().getTime()}` : undefined,
          urlIcon: !!res.data.urlIcon ? `${res.data.urlIcon}?time=${new Date().getTime()}` : undefined,
          ussdRedirect: !!res.data.ussdCode,
          ussdCode: res.data.ussdCode,
        });
        setImgUrl(res.data.urlImage ? `${res.data.urlImage}?time=${new Date().getTime()}` : undefined);
        setImgName(res.data.imageName ? res.data.imageName : undefined);

        setIconLogoUrl(res.data.urlIcon ? `${res.data.urlIcon}?time=${new Date().getTime()}` : undefined);
        setIconLogoName(res.data.iconName ? res.data.iconName : undefined);

        if (res.data.tags) {
          setTags(res.data.tags);
        }

        if (res.data.tagsSuggestions) {
          tagsSuggestions.current.push(...res.data.tagsSuggestions);
        }
      },
      () => {
        setAlertProps({
          type: AlertTypeEnum.ERROR,
          title: t("pages.dynamicScreen.getDynamicScreenError"),
        });
        showAlert();
      },
    );
  };

  /**
   * Get and save the available languages
   */
  const getAvailableLanguages = () => {
    LanguagesApi.methods.getAvailableLanguages().then(
      res => {
        setAvailableLanguages(res.data.availableLanguages);
        getDynamicScreenCategories(res.data.availableLanguages);
      },
      () => {
        const defaultLanguages: ILanguage[] = [
          { id: "1", code: "en", name: "English", urlIcon: "ic_flag_uk.png" },
          { id: "2", code: "sw", name: "Swahili (Tanzania)", urlIcon: "ic_flag_tanzania.png" },
        ];
        setAvailableLanguages(defaultLanguages);
        getDynamicScreenCategories(defaultLanguages);
        setAlertProps({
          type: AlertTypeEnum.ERROR,
          title: t("commons.getLanguagesError"),
        });
        showAlert();
      },
    );
  };

  /**
   * Sets up language toggle and the default form values
   * */
  const setupLanguages = (languages: ILanguage[], cats: IDynamicScreenCategory[], sects: string[]) => {
    const langCodes = languages.map((lang: ILanguage) => {
      return lang.code;
    });
    setAvailableLanguages(languages);
    setSelectedLanguage(langCodes.includes("en") ? "en" : langCodes[0]);
    const trans: { [x: string]: string } = {};
    languages.forEach((lang: ILanguage) => {
      trans[lang.code] = "";
    });
    setFormValues({
      name: { translations: trans },
      description: { translations: trans },
      section: sects[0],
      categories: [],
      isActive: true,
      eventId: "",
      highlighted: false,
      color: DEFAULT_COLOR,
      icon: "",
      urlImage: "",
      ussdRedirect: false,
      ussdCode: "",
    });
  };

  /**
   * Performs the edit or create request
   * @param {IDynamicScreenRequest} values
   * @param {FormikHelpers<IDynamicScreenRequest>} actions
   */
  const handleSubmit = async (values: IDynamicScreenRequest, actions: FormikHelpers<IDynamicScreenRequest>) => {
    actions.setSubmitting(true);
    const valuesTemp = {
      ...values,
      tags: tags.length ? tags : null,
    };
    delete valuesTemp.ussdRedirect;
    const base64Image = await fileToBase64(img);
    const base64Icon = await fileToBase64(iconLogo);
    const requestData: any = {
      ...valuesTemp,
      urlImage: img ? null : imgName,
      base64Image: base64Image ? base64Image : null,
      imageName: imgName ? imgName : null,
      urlIcon: iconLogo ? null : iconLogoName,
      base64Icon: iconLogo ? base64Icon : null,
      iconName: iconLogoName ? iconLogoName : null,
    };

    if (id) {
      DynamicScreensApi.methods
        .editDynamicScreen(requestData, id)
        .then(
          () => {
            setAlertProps({
              type: AlertTypeEnum.SUCCESS,
              title: t("pages.dynamicScreen.editSuccess"),
            });
            showAlert();
            history.push(RoutesEnum.DYNAMIC_SCREENS);
          },
          () => {
            setAlertProps({
              type: AlertTypeEnum.ERROR,
              title: t("pages.dynamicScreen.editError"),
            });
            showAlert();
          },
        )
        .finally(() => {
          actions.setSubmitting(false);
        });
    } else {
      DynamicScreensApi.methods
        .createDynamicScreen(requestData)
        .then(
          () => {
            setAlertProps({
              type: AlertTypeEnum.SUCCESS,
              title: t("pages.dynamicScreen.createSuccess"),
            });
            showAlert();
            history.push(RoutesEnum.DYNAMIC_SCREENS);
          },
          () => {
            setAlertProps({
              type: AlertTypeEnum.ERROR,
              title: t("pages.dynamicScreen.createError"),
            });
            showAlert();
          },
        )
        .finally(() => {
          actions.setSubmitting(false);
        });
    }
  };

  /**
   * Validator helper for name and description
   * @returns {{[p: string]: any}}
   */
  const getTranslationValidators = () => {
    let validators: { [x: string]: any } = {};
    (availableLanguages as ILanguage[])?.map((lang: ILanguage) => {
      if (lang.mandatory) {
        validators = {
          ...validators,
          [lang.code]: string()
            .trim()
            .required(i18next.t("pages.dynamicScreen.translationRequired"))
            .max(255, i18next.t("pages.dynamicScreen.maxLenDescription")),
        };
      } else {
        validators = {
          ...validators,
          [lang.code]: string().max(255, i18next.t("pages.dynamicScreen.maxLenDescription")),
        };
      }
    });
    return validators;
  };

  /**
   * Form validation schema
   * @type {ObjectSchema<Shape<object, {eventId: string, category: string, name: object, section: string, description: object}>>}
   */
  const schema = object().shape({
    name: object().shape({
      translations: object().shape(getTranslationValidators()),
    }),
    description: object().shape({
      translations: object().shape(getTranslationValidators()),
    }),
    ussdCode: string().when("ussdRedirect", {
      is: val => val === true,
      then: string()
        .required(i18next.t("pages.dynamicScreen.requiredField"))
        .max(10, i18next.t("pages.dynamicScreen.maxLenEventId")),
    }),
    eventId: string().when("ussdRedirect", {
      is: val => val === false,
      then: string()
        .required(i18next.t("pages.dynamicScreen.requiredField"))
        .max(10, i18next.t("pages.dynamicScreen.maxLenEventId")),
    }),
    section: string().required(i18next.t("pages.dynamicScreen.requiredField")),
    categories: array().when("section", {
      is: val => val === "SERVICES",
      then: array().min(1, i18next.t("pages.dynamicScreen.requiredField")),
    }),
    icon: string().required(i18next.t("pages.dynamicScreen.requiredField")),
    urlImage: string().when("highlighted", {
      is: val => val === true,
      then: string().required(i18next.t("pages.dynamicScreen.requiredField")),
    }),
  });

  /**
   * Validate service name and service description translations
   * @param {IDynamicScreenRequest} values
   */
  const validateTranslations = (
    values: IDynamicScreenRequest,
    setShowWarning?: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    validateSchema(values, setShowWarning).then(errors => {
      if (errors && (errors.description || errors.name)) {
        const langErrors = Object.keys({
          ...(errors.description && errors.description.translations ? (errors.description.translations as Object) : {}),
          ...(errors.name && errors.name.translations ? (errors.name.translations as Object) : {}),
        });
        setSelectedLanguage(langErrors[0]);
      }
    });
  };

  /**
   * Fn to validate the form every stroke
   * @param values
   */
  const validateSchema = (
    values: IDynamicScreenRequest,
    setShowWarning?: React.Dispatch<React.SetStateAction<boolean>>,
  ): Promise<FormikErrors<IDynamicScreenRequest>> => {
    return new Promise<FormikErrors<IDynamicScreenRequest>>(resolve => {
      schema
        .validate(values, {
          abortEarly: false,
        })
        .then(() => {
          setShowWarning && setShowWarning(false);
          //Validate if any optional language is empty
          if (availableLanguages) {
            for (let l = 0; l < availableLanguages.length; l++) {
              if (
                !values.name.translations[availableLanguages[l].code] ||
                !values.description.translations[availableLanguages[l].code]
              ) {
                setShowWarning && setShowWarning(true);
              }
            }
          }

          resolve({});
        })
        .catch(y => {
          resolve(yupToFormErrors(y));
        });
    }).then(r => {
      return r;
    });
  };

  /**
   * Get service groups dropdown
   * @returns {{label: any, key: any}[] | {label: string, key: string}[]}
   */
  const getSectionsDropdown = () => {
    if (sections) {
      return sections.map((i: any) => {
        return {
          key: i,
          label: t(`pages.dynamicScreens.sectionsEnum.${i}`),
        };
      });
    } else {
      return [{ key: "", label: "" }];
    }
  };

  /**
   * Toggles a category in the dropdown
   * @param {SharedDropdownOption} opt
   * @param {(field: string, value: any, shouldValidate?: (boolean | undefined)) => void} setFieldValue
   */
  const toggleCategory = (
    opt: SharedDropdownOption,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  ) => {
    if (selectedCategories.includes(opt.key)) {
      setFieldValue(
        "categories",
        selectedCategories.filter(c => c !== opt.key),
      );
      setSelectedCategories(selectedCategories.filter(c => c !== opt.key));
    } else {
      setFieldValue("categories", [...selectedCategories, opt.key]);
      setSelectedCategories([...selectedCategories, opt.key]);
    }
  };

  /**
   * Checks if a category is already selected on the dropdown
   * @param {SharedDropdownOption} opt
   * @returns {boolean}
   */
  const isCategorySelected = (opt: SharedDropdownOption) => {
    return selectedCategories.includes(opt.key);
  };

  /**
   * Returns a label for the categories dropdown
   * @returns {string}
   */
  const getCategoriesLabel = () => {
    if (selectedCategories.length > 0) {
      let label = "";
      selectedCategories.forEach(c => {
        const nameTranslation = (categories.find(cat => {
          return cat.id === c;
        }) as IDynamicScreenCategory).name.translations;
        label += `${nameTranslation[selectedLanguage] ? nameTranslation[selectedLanguage] : nameTranslation["en"]}, `;
      });
      label = label.substring(0, label.length - 2);
      return label;
    } else {
      return t("pages.dynamicScreen.categoryPlaceholder");
    }
  };

  /**
   * Returns the form for the translatable inputs
   * @param {IDynamicScreenRequest} values
   * @param {((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined} handleChange
   * @param {((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined} handleBlur
   * @param setFieldValue
   * @param errors
   * @returns {JSX.Element}
   */
  const getTranslatableInputs = (
    values: IDynamicScreenRequest,
    handleChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined,
    handleBlur: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined,
    setFieldValue: any,
    errors: any,
  ) => {
    return (
      <div>
        <Row>
          <FormTitle>{t("pages.dynamicScreen.serviceDetailsInfo")}</FormTitle>
          <div style={{ marginTop: "auto", marginLeft: "auto" }}>
            <GenericLanguageSelector
              selectedLanguage={selectedLanguage}
              availableLanguages={availableLanguages?.map(lang => lang.code) || []}
              changeSelectedLanguage={(lang: any) => setSelectedLanguage(lang.key)}
            ></GenericLanguageSelector>
          </div>
        </Row>
        <FormSectionContainer>
          {availableLanguages?.map((l: ILanguage) => {
            const lang = l.code;
            return (
              <div key={`translatable-form-${lang}`}>
                <FormSectionRow
                  key={`name-${lang}`}
                  hide={lang !== selectedLanguage}
                  style={{ borderBottom: "1px solid #ebebeb" }}
                >
                  <SectionColumn>
                    <FormLabel required={l.mandatory}>{t("pages.dynamicScreen.serviceName")}</FormLabel>
                  </SectionColumn>
                  <SectionColumn>
                    <TextInput
                      name={`name.translations[${lang}]`}
                      placeholder={`${t("pages.dynamicScreen.serviceNamePlaceholder")} (${lang.toUpperCase()})`}
                      onBlur={handleBlur}
                      onChange={e => setFieldValue(`name.translations[${lang}]`, e.target.value)}
                      value={values.name.translations[lang] || ""}
                      error={
                        errors.name && errors.name.translations && errors.name.translations[lang]
                          ? errors.name.translations[lang]
                          : undefined
                      }
                    />
                  </SectionColumn>
                </FormSectionRow>
                <FormSectionRow
                  key={`description-${lang}`}
                  hide={lang !== selectedLanguage}
                  style={{ borderBottom: "1px solid #ebebeb" }}
                >
                  <SectionColumn>
                    <FormLabel required={l.mandatory}>{t("pages.dynamicScreen.serviceDescription")}</FormLabel>
                  </SectionColumn>
                  <SectionColumn>
                    <TextInput
                      name={`description.translations[${lang}]`}
                      placeholder={`${t("pages.dynamicScreen.serviceDescriptionPlaceholder")} (${lang.toUpperCase()})`}
                      onBlur={handleBlur}
                      onChange={e => setFieldValue(`description.translations[${lang}]`, e.target.value)}
                      value={values.description.translations[lang] || ""}
                      error={
                        errors.description && errors.description.translations && errors.description.translations[lang]
                          ? errors.description.translations[lang]
                          : undefined
                      }
                    />
                  </SectionColumn>
                </FormSectionRow>
              </div>
            );
          })}

          <FormSectionRow>
            <SectionColumn>
              <FormLabel>{t("pages.dynamicScreen.ussdRedirect.label")}</FormLabel>
            </SectionColumn>
            <SectionColumn id={"ussd-redirect"}>
              <Row>
                <Checkbox
                  data-testid={"ussd-redirect-checkbox"}
                  name={"ussdRedirect"}
                  setFieldValue={setFieldValue}
                  value={values.ussdRedirect}
                  onValueChange={() => {
                    setFieldValue("eventId", "");
                    setFieldValue("ussdCode", "");
                  }}
                />
                <Label>{t("pages.dynamicScreen.ussdRedirect.title")}</Label>
              </Row>
            </SectionColumn>
          </FormSectionRow>
          {values.ussdRedirect ? (
            <FormSectionRow>
              <SectionColumn>
                <FormLabel required={true}>{t("pages.dynamicScreen.ussdCode")}</FormLabel>
              </SectionColumn>
              <SectionColumn>
                <TextInput
                  name={"eventId"}
                  placeholder={t("pages.dynamicScreen.ussdCodePlaceholder")}
                  onBlur={handleBlur}
                  onChange={e => setFieldValue("ussdCode", e.target.value)}
                  value={values.ussdCode || ""}
                  error={errors.ussdCode ? errors.ussdCode : undefined}
                />
              </SectionColumn>
            </FormSectionRow>
          ) : (
            <FormSectionRow style={{ borderBottom: "1px solid #ebebeb" }}>
              <SectionColumn>
                <FormLabel required={true}>{t("pages.dynamicScreen.eventId")}</FormLabel>
              </SectionColumn>
              <SectionColumn>
                <TextInput
                  name={"eventId"}
                  placeholder={t("pages.dynamicScreen.eventIdPlaceholder")}
                  onBlur={handleBlur}
                  onChange={e => setFieldValue("eventId", e.target.value)}
                  value={values.eventId || ""}
                  error={errors.eventId ? errors.eventId : undefined}
                />
              </SectionColumn>
            </FormSectionRow>
          )}

          <FormSectionRow style={{ borderBottom: "1px solid #ebebeb" }}>
            <SectionColumn>
              <FormLabel required={true}>{t("pages.dynamicScreen.section")}</FormLabel>
            </SectionColumn>
            <SectionColumn id={"sections"}>
              <AutoClosingDropdown
                hasValue={!!values.section}
                dropdownType={DropdownType.RECTANGULAR_NORMAL}
                label={
                  values.section
                    ? t(`pages.dynamicScreens.sectionsEnum.${values.section}`)
                    : t("pages.dynamicScreen.sectionPlaceholder")
                }
                options={getSectionsDropdown()}
                error={errors.section}
                selectOption={opt => {
                  setFieldValue("section", opt.key);
                  if (opt.key !== "SERVICES") {
                    removeImage("image-dropzone");
                    setSelectedCategories([]);
                    setFieldValue("categories", []);
                    setFieldValue("highlighted", false);
                  }
                }}
              />
            </SectionColumn>
          </FormSectionRow>

          {values.section === "SERVICES" ? (
            <>
              <FormSectionRow style={{ borderBottom: "1px solid #ebebeb" }}>
                <SectionColumn>
                  <FormLabel required={true}>{t("pages.dynamicScreen.category")}</FormLabel>
                </SectionColumn>
                <SectionColumn id={"categories"}>
                  {categories.length > 0 && (
                    <div>
                      <MultipleOptionsDropdown
                        showClearAllFilters={false}
                        options={categories.map(c => {
                          return {
                            label: c.name.translations[selectedLanguage]
                              ? c.name.translations[selectedLanguage]
                              : c.name.translations["en"],
                            key: c.id,
                          };
                        })}
                        clearAllFilters={() => {
                          setSelectedCategories([]);
                          setFieldValue("categories", []);
                        }}
                        dropdownType={DropdownType.RECTANGULAR_NORMAL}
                        hasValue={selectedCategories.length > 0}
                        isOptionSelected={opt => isCategorySelected(opt)}
                        label={getCategoriesLabel()}
                        error={errors.categories}
                        toggleOption={opt => toggleCategory(opt, setFieldValue)}
                      />
                    </div>
                  )}
                </SectionColumn>
              </FormSectionRow>
              <FormSectionRow
                style={
                  values.highlighted
                    ? { paddingTop: "16px", paddingBottom: "16.5px", borderBottom: "1px solid #ebebeb" }
                    : { borderBottom: "1px solid #ebebeb" }
                }
              >
                <SectionColumn style={values.highlighted ? { alignSelf: "flex-start" } : undefined}>
                  <FormLabel>{t("pages.dynamicScreen.highlighted.title")}</FormLabel>
                </SectionColumn>
                <SectionColumn id={"highlighted"}>
                  <Row>
                    <Checkbox
                      data-testid={"highlighted-checkbox"}
                      name={"highlighted"}
                      setFieldValue={setFieldValue}
                      value={values.highlighted}
                      disabled={canBeHighlighted ? false : !formValues!.highlighted}
                    />
                    <Label>{t("pages.dynamicScreen.highlighted.label")}</Label>
                    {!canBeHighlighted && !formValues!.highlighted && (
                      <TooltipContainer data-tip data-for="highlighted-tooltip" id={"highlighted-tooltip"}>
                        <HelpIcon />
                        <ReactTooltip
                          event="mouseover"
                          eventOff="mouseleave mousedown"
                          place="top"
                          type="dark"
                          effect="solid"
                          id={"highlighted-tooltip"}
                          className={"tooltip-container"}
                        >
                          <TooltipText>{t("pages.dynamicScreen.highlighted.tooltip")}</TooltipText>
                        </ReactTooltip>
                      </TooltipContainer>
                    )}
                  </Row>
                  {values.highlighted && (
                    <div style={{ marginTop: "20.5px", display: "flex", flexDirection: "column" }}>
                      <FormLabel required={true}>{t("pages.dynamicScreen.image.title")}</FormLabel>

                      <ImageContainer
                        {...getRootProps({
                          className: "dropzone",
                          onClick: event => setSelectedDropzoneId((event.currentTarget as HTMLDivElement).id),
                        })}
                        id={"image-dropzone"}
                        url={values.urlImage}
                      >
                        <input {...getInputProps()} />
                        {!values.urlImage && (
                          <>
                            <ImagePlaceHolderIcon />

                            <DragDropText>
                              {t("pages.dynamicScreen.imageDropzone.title")}
                              <DragDropTextInfo>{t("pages.dynamicScreen.imageDropzone.description")}</DragDropTextInfo>
                            </DragDropText>
                          </>
                        )}
                      </ImageContainer>
                      {imgName || values.urlImage ? (
                        <div
                          className={"image-file-name"}
                          style={{
                            width: "150px",
                            display: "flex",
                            alignItems: "center",
                            marginTop: "16px",
                            cursor: "pointer",
                          }}
                          onClick={() => removeImage("image-dropzone")}
                        >
                          <IconWrapper color={styleTheme.palette.vodafoneRed} style={{ marginRight: "8px" }}>
                            <CancelIcon />
                          </IconWrapper>
                          <DragDropFileName>{t("pages.dynamicScreen.image.remove")}</DragDropFileName>
                        </div>
                      ) : null}
                      {!imgUrl ? (
                        <ImageDetailsHelper
                          width={"100%"}
                          marginBottom={"0"}
                          color={
                            showImageDetailsError || errors.urlImage
                              ? styleTheme.palette.vodafoneRed
                              : styleTheme.palette.midGrey
                          }
                          dangerouslySetInnerHTML={{
                            __html: t("pages.dynamicScreen.image.helper"),
                          }}
                        />
                      ) : null}
                    </div>
                  )}
                </SectionColumn>
              </FormSectionRow>
            </>
          ) : null}

          <FormSectionRow style={{ borderBottom: "1px solid #ebebeb" }}>
            <SectionColumn>
              <FormLabel required={true}>{t("pages.dynamicScreen.status")}</FormLabel>
            </SectionColumn>
            <SectionColumn id={"status"}>
              <DynamicScreenStatusChip
                style={{ width: "fit-content", cursor: "pointer" }}
                isActive={values.isActive}
                onClick={() => {
                  setFieldValue("isActive", !values.isActive);
                }}
              >
                {t(`pages.dynamicScreens.statusEnum.${values.isActive ? "live" : "disabled"}`)}
              </DynamicScreenStatusChip>
            </SectionColumn>
          </FormSectionRow>

          <FormSectionRow style={{ borderBottom: "1px solid #ebebeb" }}>
            <SectionColumn>
              <FormLabel required={false}>{t("pages.dynamicScreen.tags")}</FormLabel>
            </SectionColumn>
            <SectionColumn id={"tags"}>
              <TextInputWithTag
                error={tagErrorText}
                addTagButtonText={id ? t("pages.dynamicScreen.addTag") : t("pages.dynamicScreen.createTag")}
                tags={tags}
                setTags={setTags}
                inputValue={tagInputText}
                setInputValue={setTagInputText}
                tagsSuggestions={tagsSuggestions.current}
                minTagTextLength={3}
                maxTagTextLength={25}
                placeholder={t("pages.dynamicScreen.tagsPlaceholder")}
                regexExp={RegExEpressions.especialCharacters}
              />
            </SectionColumn>
          </FormSectionRow>
        </FormSectionContainer>
      </div>
    );
  };

  /**
   * Returns a base64 of the selected icon
   * @param {string} iconId
   * @returns {any}
   */
  const getIconBase64 = (iconId: string) => {
    if (icons) {
      const ic = icons.find(icon => icon.id === iconId);
      return ic ? ic.base64 : "";
    } else {
      return "";
    }
  };

  /**
   * Remove the dynamic screen image
   */
  const removeImage = (dropzoneId: string) => {
    if (dropzoneId === "logo-dropzone") {
      setIconLogo(undefined);
      setIconLogoName(undefined);
      setIconLogoUrl(undefined);
    }

    if (dropzoneId === "image-dropzone") {
      setImg(undefined);
      setImgName(undefined);
      setImgUrl(undefined);
    }
  };

  /**
   * Component to check and set the image
   */
  const VerifyImage = () => {
    const { values, setFieldValue } = useFormikContext();
    React.useEffect(() => {
      if (iconLogoUrl) {
        setFieldValue("urlIcon", iconLogoUrl);
      }
      if (!iconLogoUrl) {
        setFieldValue("urlIcon", undefined);
      }
      if (imgUrl) {
        setFieldValue("urlImage", imgUrl);
      }
      if (!imgUrl) {
        setFieldValue("urlImage", undefined);
      }
    }, [values, imgUrl, iconLogoUrl]);
    return null;
  };

  /**
   * Dropzone props
   */
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
    maxSize: 2000000,
    onDropAccepted<T extends File>(files: T[], event: DropEvent) {
      const [url, icon] = createImage(event);
      files.map(file => {
        const image = new Image();
        image.addEventListener("load", () => {
          if (selectedDropzoneId && selectedDropzoneId === "logo-dropzone") {
            if (image.width >= 64 && image.height >= 64) {
              setIconLogoUrl(url);
              setIconLogo(icon);
              setIconLogoName(file.name);
              setShowIconLogoDetailsError(false);
            } else {
              setShowIconLogoDetailsError(true);
              showImageRequirementsAlert();
              removeImage("logo-dropzone");
            }
            return;
          }

          if (selectedDropzoneId && selectedDropzoneId === "image-dropzone") {
            if (image.width >= 500 && image.height >= 300) {
              setImgUrl(url);
              setImg(icon);
              setImgName(file.name);
              setShowImageDetailsError(false);
            } else {
              setShowImageDetailsError(true);
              showImageRequirementsAlert();
              removeImage("image-dropzone");
            }
          }
        });
        image.src = URL.createObjectURL(file);
      });
    },
    onDropRejected<T extends File>(files: T[], event: DropEvent) {
      if (selectedDropzoneId && selectedDropzoneId === "logo-dropzone") {
        showImageRequirementsAlert();
        removeImage("logo-dropzone");
        setShowIconLogoDetailsError(true);
        return;
      }

      showImageRequirementsAlert();
      removeImage("image-dropzone");
      setShowImageDetailsError(true);
    },
  });

  /**
   * Show image requirements
   */
  const showImageRequirementsAlert = () => {
    setAlertProps({
      type: AlertTypeEnum.ERROR,
      title:
        selectedDropzoneId && selectedDropzoneId === "logo-dropzone"
          ? t("pages.dynamicScreen.image.icon.dimensionsErrorTitle")
          : t("pages.dynamicScreen.image.dimensionsErrorTitle"),
      content: t("pages.dynamicScreen.image.dimensionsErrorMessage"),
    });
    showAlert();
  };

  return (
    <PageContainer>
      <SecondaryPageTitle
        displayInitialsCircle={false}
        title={id ? t("pages.dynamicScreen.titleEdit") : t("pages.dynamicScreen.titleCreate")}
        goBackFn={() => window.history.back()}
      />
      {availableLanguages && categories && sections && formValues ? (
        <DynamicScreenFormContainer>
          <Formik
            initialValues={formValues}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={submitWrapper}
            validate={values => validateSchema(values, setShowWarning)}
            render={({ values, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue, errors }) => (
              <form onSubmit={handleSubmit} className={"main-form"}>
                <SubmitButtonContainer>
                  <div>
                    <PrimaryButton
                      redTheme={false}
                      id={"cancel-btn"}
                      titleLabel={t("pages.dynamicScreen.cancel")}
                      onClick={() => history.push(RoutesEnum.DYNAMIC_SCREENS)}
                      disabled={isSubmitting}
                      type="button"
                    />
                  </div>
                  <div>
                    {id ? (
                      <PrimaryButton
                        redTheme={true}
                        id={"save-changes-button"}
                        titleLabel={t("pages.dynamicScreen.save")}
                        type="submit"
                        onClick={() => validateTranslations(values)}
                        disabled={isSubmitting}
                      />
                    ) : (
                      <PrimaryButton
                        redTheme={true}
                        id={"create-button"}
                        titleLabel={t("pages.dynamicScreen.create")}
                        type="submit"
                        onClick={() => validateTranslations(values)}
                        disabled={isSubmitting}
                      />
                    )}
                  </div>
                </SubmitButtonContainer>
                <VerifyImage />
                <PanelRow>
                  <LeftPanel className={"main-content"}>
                    {getTranslatableInputs(values, handleChange, handleBlur, setFieldValue, errors)}
                  </LeftPanel>
                  <RightPanel className={"side-menu"}>
                    {icons && icons.length > 0 && (
                      <>
                        <div className="form-label-container">
                          <FormLabel required={true}>{t("pages.dynamicScreen.serviceIcon")}</FormLabel>
                        </div>
                        <div style={{ display: "flex" }}>
                          {values.icon && (
                            <IconContainer>
                              <IconPreview backgroundColor={values.color}>
                                <img src={getIconBase64(values.icon)} alt={""} />
                              </IconPreview>
                            </IconContainer>
                          )}
                          {!values.icon && (
                            <EmptyIconContainer backgroundColor={DEFAULT_COLOR}>
                              <IconPlaceHolderIcon style={{ width: "56px", height: "56px" }} />
                            </EmptyIconContainer>
                          )}
                          <PickerContainer>
                            <ColorPicker
                              color={values.color}
                              setColor={setFieldValue}
                              name={"color"}
                              error={errors.color}
                              colors={getVodafoneColorPalette()}
                              isCustomizable={false}
                              notSelectedColor={DEFAULT_COLOR}
                              inputPlaceholder={t("pages.dynamicScreen.color")}
                            />
                            <span id="gallery-button">
                              <IconPicker
                                icons={icons}
                                icon={icons.find(icon => icon.id === values.icon)}
                                name="icon"
                                setIconID={setFieldValue}
                                backgroundColor={values.color ? values.color : DEFAULT_COLOR}
                                error={errors.icon}
                                isLoading={isLoadingIcons}
                                tabLabel={"actions"}
                                defaultIcon={DEFAULT_ICON}
                                inputPlaceholder={t("pages.dynamicScreen.gallery")}
                                displayBgColor={ICON_BG_COLOR}
                                displayPreviewBgColor={false}
                              />
                            </span>
                          </PickerContainer>
                        </div>
                      </>
                    )}

                    <div style={{ marginBottom: "16px", marginTop: "48px", display: "flex", flexDirection: "column" }}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <FormLabel>{t("pages.dynamicScreen.image.icon.title")}</FormLabel>
                        <TooltipContainer
                          data-tip
                          data-for="logo-tooltip"
                          id={"logo-tooltip"}
                          style={{ display: "flex", width: "24px" }}
                        >
                          <HelpIcon />
                          <ReactTooltip
                            place="top"
                            type="dark"
                            effect="solid"
                            event="mouseover"
                            eventOff="mouseleave mousedown"
                            id={"logo-tooltip"}
                            className={"tooltip-container"}
                          >
                            <TooltipText>{t("pages.dynamicScreen.image.icon.tooltip")}</TooltipText>
                          </ReactTooltip>
                        </TooltipContainer>
                      </div>

                      <LogoContainer
                        {...getRootProps({
                          className: "dropzone",
                          onClick: event => setSelectedDropzoneId((event.currentTarget as HTMLDivElement).id),
                        })}
                        id={"logo-dropzone"}
                        url={values.urlIcon}
                      >
                        <input {...getInputProps()} />
                        {!values.urlIcon && (
                          <>
                            <ImagePlaceHolderIcon />

                            <DragDropText>
                              {t("pages.dynamicScreen.imageDropzone.title")}
                              <DragDropTextInfo>{t("pages.dynamicScreen.imageDropzone.description")}</DragDropTextInfo>
                            </DragDropText>
                          </>
                        )}
                      </LogoContainer>
                      {iconLogoName || values.urlIcon ? (
                        <div
                          className={"image-file-name"}
                          style={{
                            width: "150px",
                            display: "flex",
                            alignItems: "center",
                            marginTop: "16px",
                            cursor: "pointer",
                          }}
                          onClick={() => removeImage("logo-dropzone")}
                        >
                          <IconWrapper color={styleTheme.palette.vodafoneRed} style={{ marginRight: "8px" }}>
                            <CancelIcon />
                          </IconWrapper>
                          <DragDropFileName>{t("pages.dynamicScreen.image.remove")}</DragDropFileName>
                        </div>
                      ) : null}
                      {!iconLogoUrl ? (
                        <ImageDetailsHelper
                          width={"100%"}
                          marginBottom={"48px"}
                          color={
                            showIconLogoDetailsError || errors.urlIcon
                              ? styleTheme.palette.vodafoneRed
                              : styleTheme.palette.midGrey
                          }
                          dangerouslySetInnerHTML={{
                            __html: t("pages.dynamicScreen.image.icon.helper"),
                          }}
                        />
                      ) : null}
                    </div>
                  </RightPanel>
                </PanelRow>
              </form>
            )}
          />
        </DynamicScreenFormContainer>
      ) : (
        <PageContent>
          <LoadingText>{t("pages.dynamicScreen.loadingResults")}</LoadingText>
        </PageContent>
      )}
    </PageContainer>
  );
};

export default DynamicScreenPage;

const LeftPanel = styled("div")`
  width: 60%;
`;

const RightPanel = styled("div")`
  width: 40%;
  padding-left: 48px;
  display: flex;
  flex-direction: column;
  .image-file-name {
    margin-top: 16px;
    margin-bottom: 48px;
    display: flex;
    align-items: center;
    @media (max-width: 1024px) {
      margin-bottom: 0;
    }
  }
  .form-label-container {
    margin-top: 45px;
    margin-bottom: 16px;
    @media (max-width: 1024px) {
      margin-top: 0;
    }
  }
  @media (max-width: 1024px) {
    &.side-menu {
      align-items: flex-start;
    }
  }
`;

const PickerContainer = styled("div")`
  width: 275px;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  > span {
    /*Negative margin top because of the flex-gap between elements; in this case we need the error message to be a bit closer to the input */
    margin-top: -8px;
  }
`;

const PanelRow = styled("div")`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
`;

const DynamicScreenFormContainer = styled(PageContent)`
  form {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
`;

const IconPreview = styled("div")<{
  backgroundColor: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.backgroundColor ? `${props.backgroundColor}` : "#ffffff")};
  width: 109px;
  height: 109px;
  border: 1px solid ${props => props.theme.palette.aluminium};
  border-radius: 6px;

  img {
    width: 56px;
    height: 56px;
    filter: brightness(500%);
    -webkit-filter: brightness(500%);
  }
`;

const EmptyIconContainer = styled("div")<{
  backgroundColor: string;
}>`
  background-color: ${props => (props.backgroundColor ? `${props.backgroundColor}` : "#ebebebeb")};
  display: flex;
  width: 109px;
  height: 109px;
  border: 1px solid ${props => props.theme.palette.aluminium};
  border-radius: 6px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
`;

const IconContainer = styled("div")`
  display: flex;
`;

const FormTitle = styled("span")`
  font-family: "Vodafone Rg";
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
`;

const Label = styled("div")`
  font-family: Vodafone Rg;
  margin-left: 8px;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 19px;
  letter-spacing: normal;
  color: ${props => props.theme.palette.midGrey};
`;

export const TooltipContainer = styled("div")`
  display: flex;
  margin-left: 8px;
  cursor: pointer;
`;

export const TooltipText = styled("div")`
  font-size: 12px;
  line-height: 14px;
  font-family: "Vodafone Rg";
  color: white;
  display: flex;
  width: 210px;
  text-align: center;
`;

const ImageContainer = styled.div<{ url?: string }>`
  width: 235px;
  height: 160px;
  border-radius: 6px;
  border: solid 1px;
  border-color: ${props => props.theme.palette.aluminium};
  background-image: ${props => (props.url ? `url(${props.url})` : "none")};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 16px;
  background-color: #e9f5ff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
`;

const LogoContainer = styled.div<{ url?: string }>`
  width: 235px;
  height: 160px;
  border-radius: 6px;
  border: solid 1px;
  border-color: ${props => props.theme.palette.aluminium};
  margin-top: 16px;
  background-color: #e9f5ff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;

  &::after {
    content: "";
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: ${props => (props.url ? `1px solid ${props.theme.palette.aluminium}` : 0)};
    background-image: ${props => (props.url ? `url(${props.url})` : "none")};
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: inline-block;
    position: absolute;
    top: 15px;
    left: 52.5px;
  }
`;

const SubmitButtonContainer = styled("div")`
  display: flex;
  flex-direction: row;
  position: fixed;
  right: 60px;
  top: 46px;
  z-index: 1;
  margin-top: "auto";

  div {
    margin-left: 12px;
  }
`;
