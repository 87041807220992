import { CopyIcon, FastActionButton, TrashIcon } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { fadeInKeyframe, FlexContainer, IconContainer, Row } from "../../../../shared/shared.styled";
import { IReferralCampaign, IReward, ReferralCampaignStatus, TargetEnum } from "../referral-campaigns.model";
import ReferralCampaignStatusChip from "./referral-campaign-status-chip.component";

interface IReferralCampaignListItemProps {
  item: IReferralCampaign;
  onDelete: (id: string) => void;
  onDuplicate: (id: string) => void;
  onClickItemFn: () => void;
}

/**
 * ReferralCampaignListItem component
 */
const ReferralCampaignListItem = ({ item, onDelete, onDuplicate, onClickItemFn }: IReferralCampaignListItemProps) => {
  const [t] = useTranslation();
  return (
    <>
      <ReferralCampaignListItemContainer
        onClick={onClickItemFn}
        id={`referral-campaign-${
          item.name.translations.en ? item.name.translations.en : Object.keys(item.name.translations)[0]
        }`}
        hoverAction={true}
      >
        <ItemTitle ratio={5 / 12}>
          {item.name.translations.en ? item.name.translations.en : Object.keys(item.name.translations)[0]}
          {item.defaultCampaign && (
            <DefaultCampaignText>{t("pages.referralCampaigns.defaultCampaign")}</DefaultCampaignText>
          )}
        </ItemTitle>
        <ItemNormalText ratio={3 / 12}>
          {t(
            `pages.referralCampaigns.table.rewardTypeEnum.${(item.rewards.find(
              reward => reward.target === TargetEnum.SENDER,
            ) as IReward).type.toLocaleLowerCase()}`,
          )}
        </ItemNormalText>
        <ItemNormalText ratio={2 / 12} style={{ color: styleTheme.palette.turquoiseBlue, fontWeight: "bold" }}>
          {(item.rewards.find(reward => reward.target === TargetEnum.SENDER) as IReward).amount}
        </ItemNormalText>

        <FlexContainer className="toggable-container" ratio={2 / 12}>
          <Row>
            <>
              {item.status !== ReferralCampaignStatus.LIVE && (
                <FastActionButton
                  onClick={e => {
                    e.stopPropagation();
                    onDelete(item.id as string);
                  }}
                  label={t("pages.referralCampaigns.table.delete")}
                  iconComponent={
                    <IconContainer id={`delete-button`} size={16} color={styleTheme.palette.vodafoneRed}>
                      <TrashIcon />
                    </IconContainer>
                  }
                />
              )}
              <FastActionButton
                onClick={e => {
                  e.stopPropagation();
                  onDuplicate(item.id as string);
                }}
                iconComponent={
                  <IconContainer
                    size={16}
                    color={styleTheme.palette.freshOrange}
                    id={`duplicate-button-${
                      item.name.translations.en ? item.name.translations.en : Object.keys(item.name.translations)[0]
                    }`}
                  >
                    <CopyIcon />
                  </IconContainer>
                }
                label={t("pages.referralCampaigns.table.duplicate")}
              />
            </>
          </Row>
        </FlexContainer>
        <FlexContainer className="status-container" style={{ textAlign: "right", alignItems: "center" }} ratio={2 / 12}>
          <DateContainer>
            <DateSpan status={item.status}>
              {moment(item.startDate).format("DD/MM/YY")}
              <span style={{ color: "#999" }}>
                {` - ${
                  item.endDate
                    ? moment(item.endDate).format("DD/MM/YY")
                    : t(`pages.referralCampaigns.table.noEndDateDefined`)
                }`}
              </span>
            </DateSpan>
          </DateContainer>
          <ReferralCampaignStatusChip style={{ width: "fit-content" }} status={item.status}>
            {t(`pages.referralCampaigns.table.statusEnum.${item.status.toLocaleLowerCase()}`)}
          </ReferralCampaignStatusChip>
        </FlexContainer>
      </ReferralCampaignListItemContainer>
    </>
  );
};

export default ReferralCampaignListItem;

const DefaultCampaignText = styled("span")`
  font-family: Vodafone Rg;
  font-weight: normal;
  font-size: 16px;
  margin-left: 10px;
  color: ${props => props.theme.palette.vodafoneRed};
`;

const ReferralCampaignListItemContainer = styled("li")<{ hoverAction: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 75px;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};

  margin-bottom: 24px;
  padding: 0 35px 0 24px;

  .status-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: column-reverse;
    align-items: flex-end !important;
  }

  .toggable-container {
    display: none;
  }

  :hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    .status-container {
      display: ${props => (props.hoverAction ? "none" : "flex")};
    }
    .toggable-container {
      display: ${props => (!props.hoverAction ? "none" : "flex")};
      justify-content: flex-end;
      animation: ${fadeInKeyframe} 0.25s linear;
      button:first-child {
        margin-right: 12px;
      }
    }
  }
`;

const ItemTitle = styled(FlexContainer)`
  font-family: Vodafone Rg;
  font-size: 22px;
  font-weight: bold;
  color: ${props => props.theme.palette.darkGrey};
  @media (max-width: 1024px) {
    font-size: 20px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const ItemNormalText = styled(FlexContainer)`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
  @media (max-width: 768px) {
    font-size: 14px;
    padding-right: 8px;
  }
`;

const DateContainer = styled("div")`
  display: flex;
  margin-top: 8px;
  font-family: Vodafone Rg;
  font-size: 14px;
  color: ${props => props.theme.palette.midGrey};
`;
const DateSpan = styled("div")<{ status?: ReferralCampaignStatus }>`
  font-family: Vodafone Rg;
  font-size: 14px;
  text-align: right;
  align-items: center;
  @media (max-width: 768px) {
    font-size: 12px;
  }

  ${props => {
    let color = props.theme.palette.midGrey;
    switch (props.status) {
      case ReferralCampaignStatus.LIVE:
        color = props.theme.palette.digitalGreen;
        break;
      case ReferralCampaignStatus.DISABLED:
        color = props.theme.palette.freshOrange;
        break;
      case ReferralCampaignStatus.SCHEDULED:
        color = props.theme.palette.turquoiseBlue;
        break;
    }
    return `color: ${color};`;
  }}
`;
