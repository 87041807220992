import { AxiosPromise } from "axios";
import ApiClient from "../../../configs/axios.config";

/*
 * Discover cards API methods
 * */
const DiscoverCardsApi = {
  routes: {
    getDiscoverCards: "/v1/discovercards",
    getLastVersion: "v1/discovercards?previousVersion=true",
    sortDiscoverCards: "/v1/discovercards/reorder",
    revertChanges: "/v1/discovercards/revert",
    publish: "/v1/discovercards/publish",
    submitForApproval: "/v1/discovercards/submitforapproval",
    getImageGallery: "/v1/discovercards/gallery",
  },
  methods: {
    getDiscoverCards: (): AxiosPromise => {
      return ApiClient.get(DiscoverCardsApi.routes.getDiscoverCards, {});
    },
    revertChanges: (): AxiosPromise => {
      return ApiClient.put(DiscoverCardsApi.routes.revertChanges, {});
    },
    sortDiscoverCards: (ids: string[]): AxiosPromise => {
      return ApiClient.put(DiscoverCardsApi.routes.sortDiscoverCards, ids);
    },
    getDiscoverCard: (id: any): AxiosPromise => {
      return ApiClient.get(`${DiscoverCardsApi.routes.getDiscoverCards}/${id}`, {});
    },
    createDiscoverCard: (card: any): AxiosPromise => {
      return ApiClient.post(DiscoverCardsApi.routes.getDiscoverCards, card);
    },
    EditDiscoverCard: (card: any, id: any): AxiosPromise => {
      return ApiClient.put(`${DiscoverCardsApi.routes.getDiscoverCards}/${id}`, card);
    },
    getLastVersion: (): AxiosPromise => {
      return ApiClient.get(DiscoverCardsApi.routes.getLastVersion, {});
    },
    publishDiscoverCards: (): AxiosPromise => {
      return ApiClient.put(DiscoverCardsApi.routes.publish, {});
    },
    submitDiscoverCards: (): AxiosPromise => {
      return ApiClient.put(DiscoverCardsApi.routes.submitForApproval, {});
    },
    deleteDiscoverCard: (id: string): AxiosPromise => {
      return ApiClient.delete(`${DiscoverCardsApi.routes.getDiscoverCards}/${id}`, {});
    },
    restoreDiscoverCard: (id: string): AxiosPromise => {
      return ApiClient.put(`${DiscoverCardsApi.routes.getDiscoverCards}/${id}/restore`, {});
    },
    getImageGallery: (): AxiosPromise => {
      return ApiClient.get(DiscoverCardsApi.routes.getImageGallery, {});
    },
  },
};

export default DiscoverCardsApi;
