import React, { useState, useCallback, useContext } from "react";
import { IStoreInterface } from "../../../../configs/store.config";
import { useSelector, useDispatch } from "react-redux";
import {
  useAlert,
  useModal,
  ModalTypeEnum,
  SecondaryPageTitle,
  FormSection,
  TextInput,
  MultipleOptionsDropdown,
  EditIcon,
  CheckIcon,
  TrashIcon,
  UndoIcon,
  PlusIcon,
  DownloadCsvIcon,
  TextArea,
  CancelIcon,
} from "@wit/mpesa-ui-components";
import { IUpdateAntExternalServiceRequest } from "../external-services-add/ant-external-service-add.interface";
import { FormikHelpers, Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
  IExternalServices,
  AntExternalServicePermitionsEnum,
} from "../../../../shared/models/ant-service-manager.model";
import AntExternalServicesApi from "../ant-external-services.api";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { AntExternalServicesActions } from "../ant-external-services.store";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import { PageContainer } from "../../../../shared/shared.styled";
import styled, { ThemeContext } from "styled-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { getExternalServicePermissions, formatBytes } from "../../../../shared/shared.utils";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { RoutesEnum } from "../../../../routes/routes.constants";
import { useHistory, useParams } from "react-router-dom";
import { ServiceManagerTabNumber } from "../../pages/ant-service-manager.page";

/** External Service Profie page */
const AntExternalServicesProfile = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { externalServiceID } = useParams<any>();
  const [isEditing, setIsEditing] = React.useState(false);
  const [showKey, setShowKey] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const [keyFile, setFile] = React.useState<any>();
  const [keyContent, setKeyContent] = useState<string | undefined>();
  const [fileError, setFileError] = React.useState("");
  const themeContext = useContext(ThemeContext);
  const [permissionsSelected, setPermissionsSelected] = useState<AntExternalServicePermitionsEnum[]>([]);
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );
  const [showDeleteModal, hideDeleteModal, setDeleteProps] = useModal(ModalTypeEnum.ConfirmationModal);

  const antExternalService = useSelector(
    (store: IStoreInterface) =>
      store.antExternalServicesReducer.antExternalServices.find(
        service => service.externalServiceId === externalServiceID,
      ) as IExternalServices,
  );

  const refreshPage = useCallback(() => {
    AntExternalServicesApi.methods.getAntExternalServices().then(
      res => {
        dispatch(AntExternalServicesActions.creators.fetchAntExternalServicesSuccessAction(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.serviceBuilder.errors.noServiceDetails"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        history.push(RoutesEnum.ANT_SERVICE_MANAGER);
      },
    );
  }, [dispatch, setAlertProps, showAlert, t]);

  const [isStarting, setIsStarting] = React.useState(true);

  /**
   * Is option selected
   * @param opt
   * @returns
   */
  const isOptionSelected = (opt?: SharedDropdownOption) => {
    let gotPin = false;
    let gotIdentity = false;
    if (isStarting) {
      if (antExternalService.permissions) {
        antExternalService.permissions.forEach(element => {
          if (element && element.includes("PIN")) {
            gotPin = true;
          } else if (element && element.includes("IDENTITY")) {
            gotIdentity = true;
          }
        });

        if (gotPin && gotIdentity) {
          setPermissionsSelected([
            ...permissionsSelected,
            AntExternalServicePermitionsEnum.PIN,
            AntExternalServicePermitionsEnum.IDENTITY,
          ]);
        } else if (gotPin && !gotIdentity) {
          setPermissionsSelected([...permissionsSelected, AntExternalServicePermitionsEnum.PIN]);
        } else if (!gotPin && gotIdentity) {
          setPermissionsSelected([...permissionsSelected, AntExternalServicePermitionsEnum.IDENTITY]);
        }
        setIsStarting(false);
        return permissionsSelected;
      }
    }

    if (opt) {
      return permissionsSelected.includes(opt.key);
    }
  };

  const getDropdownLabel = () => {
    isOptionSelected();
    // If permissionsSelected is duplicated
    if (permissionsSelected.length && permissionsSelected[0] === permissionsSelected[1]) {
      permissionsSelected.shift();
    }
    if (
      permissionsSelected.includes(AntExternalServicePermitionsEnum.PIN) &&
      permissionsSelected.includes(AntExternalServicePermitionsEnum.IDENTITY)
    ) {
      return (
        <div style={{ color: "#333333" }}>
          {t("pages.antExternalServices.configurations.columns.permissionPIN")},
          {t("pages.antExternalServices.configurations.columns.permissionIDENTITY")}
        </div>
      );
    } else if (permissionsSelected.length === 1) {
      if (permissionsSelected.includes(AntExternalServicePermitionsEnum.PIN)) {
        return (
          <div style={{ color: "#333333" }}>{t("pages.antExternalServices.configurations.columns.permissionPIN")}</div>
        );
      } else {
        return (
          <div style={{ color: "#333333" }}>
            {t("pages.antExternalServices.configurations.columns.permissionIDENTITY")}
          </div>
        );
      }
    } else {
      return <div style={{ color: "#333333" }}>-</div>;
    }
  };

  const toggleOption = (opt: SharedDropdownOption) => {
    if (isOptionSelected(opt)) {
      setPermissionsSelected(permissionsSelected.filter(c => c !== opt.key));
    } else {
      if (opt.key === AntExternalServicePermitionsEnum.PIN) {
        setPermissionsSelected([...permissionsSelected, AntExternalServicePermitionsEnum.PIN]);
      } else {
        setPermissionsSelected([...permissionsSelected, AntExternalServicePermitionsEnum.IDENTITY]);
      }
    }
  };

  const resetDropdown = () => {
    setPermissionsSelected([]);
  };

  const displayPermissionsDisplay = () => {
    if (antExternalService.permissions) {
      if (antExternalService.permissions.length === 2) {
        return (
          <>
            <Value style={{ color: "#00c3ff" }}>
              {t("pages.antExternalServices.configurations.columns.permissionPIN")},
            </Value>
            <Value style={{ color: "#6338eb", marginLeft: "5px" }}>
              {t("pages.antExternalServices.configurations.columns.permissionIDENTITY")}
            </Value>
          </>
        );
      } else if (antExternalService.permissions.length === 1) {
        if (antExternalService.permissions.includes(AntExternalServicePermitionsEnum.IDENTITY)) {
          return (
            <Value style={{ color: "#6338eb" }}>
              {t("pages.antExternalServices.configurations.columns.permissionIDENTITY")}
            </Value>
          );
        } else {
          return (
            <Value style={{ color: "#00c3ff" }}>
              {t("pages.antExternalServices.configurations.columns.permissionPIN")}
            </Value>
          );
        }
      } else {
        return <Value>-</Value>;
      }
    } else {
      return <Value>-</Value>;
    }
  };

  /**
   *editExernalService request
   * @param values
   * @param actions
   */
  const editAntExternalService = (values: IUpdateAntExternalServiceRequest, actions: FormikHelpers<any>) => {
    values.permissions = [];
    values.permissions = [...permissionsSelected];

    if (keyContent !== undefined) {
      values.rsaPublicKey = keyContent;
    }

    setConfirmationProps({
      title: t("pages.antExternalServices.links.saveChanges"),
      description: t("pages.antExternalServices.modals.confirmEdit", {
        externalService: antExternalService.externalServiceId,
      }),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      primaryAction: () => {
        actions.setSubmitting(true);
        AntExternalServicesApi.methods
          .updateAntExternalService(values)
          .finally(() => actions.setSubmitting(false))
          .then(
            () => {
              setAlertProps({
                title: t("pages.antExternalServices.alerts.editSuccess"),
                type: AlertTypeEnum.SUCCESS,
              });

              showAlert();
              setIsEditing(false);
              dispatch(AntExternalServicesActions.creators.editAntExternalServiceAction(values));
              AntExternalServicesActions.creators.fetchingAntExternalServicesAction();
            },
            () => {
              setAlertProps({
                title: t("pages.antExternalServices.alerts.editError"),
                type: AlertTypeEnum.ERROR,
              });
              showAlert();
            },
          )
          .finally(() => {
            actions.setSubmitting(false);
            hideConfirmationModal();
            refreshPage();
          });
      },
      secondaryAction: () => hideConfirmationModal(),
    } as BaseModalProps);
    showConfirmationModal();
  };

  /**
   * Remove External Service Request
   */
  const deleteRequest = () => {
    AntExternalServicesApi.methods
      .deleteAntExternalService(antExternalService.externalServiceId)
      .then(
        () => {
          setAlertProps({
            title: t("pages.antExternalServices.deleteRequest.titleDone"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          history.push(`${RoutesEnum.ANT_SERVICE_MANAGER}?tabIdx=${ServiceManagerTabNumber.EXTERNAL_SERVICES}`);
        },
        () => {
          setAlertProps({
            title: t("pages.antExternalServices.deleteRequest.titleError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(hideDeleteModal);
  };

  /** show deleteModal */
  const deleteAntExternalService = () => {
    setDeleteProps({
      secondaryAction: () => hideDeleteModal(),
      primaryAction: () => deleteRequest(),
      title: t("pages.antExternalServices.deleteModal.title"),
      description: t("pages.antExternalServices.deleteModal.description"),
      primaryBtnLabel: t("pages.antExternalServices.deleteModal.btnLabel"),
      primaryBtnMaxWidth: 160,
    });

    showDeleteModal();
  };

  const MAX_FILE_SIZE = 1000000;

  /** function to handleFile */
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file: File = e.target.files[0];
    if (file.size > MAX_FILE_SIZE) {
      setMaxSizeFileError();
    } else {
      if (file.name.match(/.(pem)$/i)) {
        setFileError("");
        setFile(file);
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onloadend = function() {
          if (reader.result) {
            setKeyContent(reader.result.toString());
          }
        };
      } else {
        setFileError(t("pages.antExternalServices.addPage.FileErrorType"));
      }
    }
  };

  /** function to removeFile */
  const removeFile = () => {
    setFile(null);
    setKeyContent(undefined);
  };

  /**
   * function setMaxSizeFileError
   */
  const setMaxSizeFileError = () => {
    setFileError(t("pages.antExternalServices.addPage.MaxSizeFileError"));
  };

  /** function display key */
  const keyContentDisplay = () => {
    setShowKey(!showKey);
  };

  /** function show dialog container */
  const showDialogContainer = () => {
    return (
      <DialogContainer>
        <TextContainer>
          <Text>{t("pages.antExternalServices.profilePage.showRSA")}</Text>
        </TextContainer>
      </DialogContainer>
    );
  };

  return (
    <PageContainer>
      <SecondaryPageTitle
        goBackFn={() =>
          history.push(`${RoutesEnum.ANT_SERVICE_MANAGER}?tabIdx=${ServiceManagerTabNumber.EXTERNAL_SERVICES}`)
        }
        title={t("pages.antExternalServices.profilePage.title")}
        displayInitialsCircle={false}
      />
      <PageContent>
        <Formik
          initialValues={{
            externalServiceId: antExternalService.externalServiceId,
            url: antExternalService.url,
            permissions: [...permissionsSelected],
            rsaPublicKey: antExternalService.rsaPublicKey,
          }}
          onSubmit={editAntExternalService}
          render={({ values, handleChange, isSubmitting, setFieldValue, handleSubmit, resetForm }) => (
            <>
              <MainContent>
                <form onSubmit={handleSubmit}>
                  <FormSection
                    isEditing={isEditing}
                    title={t("pages.antExternalServices.addPage.description")}
                    rows={[
                      {
                        label: (
                          <TitleLabel mandatory={true}>
                            {t("pages.antExternalServices.addPage.getRows.serviceIdentifier")}
                          </TitleLabel>
                        ),
                        displayComponent: antExternalService.externalServiceId,
                        editingComponent: (
                          <TextInput
                            name="externalServiceId"
                            value={values.externalServiceId}
                            onChange={handleChange}
                            placeholder={t("pages.antExternalServices.configurations.columns.serviceId")}
                            disabled={true}
                          />
                        ),
                      },
                      {
                        label: (
                          <TitleLabel mandatory={true}>
                            {t("pages.antExternalServices.configurations.columns.url")}
                          </TitleLabel>
                        ),
                        displayComponent: (
                          <DescriptionContainer>
                            <Value>{antExternalService.url}</Value>
                          </DescriptionContainer>
                        ),
                        editingComponent: (
                          <TextInput
                            name="url"
                            value={values.url}
                            onChange={handleChange}
                            placeholder={t("pages.antExternalServices.configurations.columns.url")}
                          />
                        ),
                      },
                      {
                        label: t("pages.antExternalServices.configurations.columns.permissions"),
                        displayComponent: <DescriptionContainer>{displayPermissionsDisplay()}</DescriptionContainer>,
                        editingComponent: (
                          <span id="permissions-dropdown">
                            <MultipleOptionsDropdown
                              label={getDropdownLabel()}
                              toggleOption={opt => toggleOption(opt)}
                              clearAllFilters={() => resetDropdown()}
                              hasValue={false}
                              isOptionSelected={opt => isOptionSelected(opt)}
                              options={getExternalServicePermissions()}
                              dropdownType={DropdownType.RECTANGULAR_NORMAL}
                            />
                          </span>
                        ),
                      },
                      {
                        label: t("pages.antExternalServices.addPage.getRows.RSApublicKey"),
                        displayComponent: antExternalService.rsaPublicKey ? (
                          <FileNameContainer onClick={() => keyContentDisplay()} style={{ flexDirection: "column" }}>
                            <div
                              style={{ display: "flex", cursor: "pointer" }}
                              onMouseEnter={() => setShowDialog(true)}
                              onMouseLeave={() => setShowDialog(false)}
                            >
                              <FileIconContainer size={16} color={themeContext.palette.vodafoneRed}>
                                <DownloadCsvIcon />
                              </FileIconContainer>
                              <FileNameText>{"key.pem"}</FileNameText>
                              {showDialog && !showKey ? showDialogContainer() : null}
                            </div>
                            {showKey ? (
                              <>
                                <div style={{ marginTop: "20px" }}>
                                  <TextArea
                                    style={{ height: "200px" }}
                                    name="key"
                                    value={antExternalService.rsaPublicKey}
                                    autoComplete="off"
                                    verticalResize={false}
                                  />
                                </div>

                                <TextButton
                                  style={{ marginLeft: "auto", marginTop: "10px" }}
                                  onClick={() => keyContentDisplay()}
                                >
                                  <FileIconContainer
                                    style={{ marginRight: "5px" }}
                                    size={16}
                                    color={themeContext.palette.errorRed}
                                  >
                                    <CancelIcon />
                                  </FileIconContainer>
                                  {t("pages.antExternalServices.profilePage.closeText")}
                                </TextButton>
                              </>
                            ) : null}
                          </FileNameContainer>
                        ) : null,
                        editingComponent: (
                          <div>
                            {fileError ? <FormError>{fileError}</FormError> : null}
                            <div>
                              {!keyFile ? (
                                <TextButton style={{ marginRight: "auto" }}>
                                  <FileIconContainer
                                    style={{ marginRight: "5px" }}
                                    size={16}
                                    color={themeContext.palette.vodafoneRed}
                                  >
                                    <PlusIcon />
                                  </FileIconContainer>
                                  {t("pages.antExternalServices.addPage.getRows.placeholderRSApublicKey")}
                                  <input type="file" onChange={handleFileChange} accept=".pem" />
                                </TextButton>
                              ) : null}
                              {keyFile ? (
                                <FileNameContainer>
                                  <FileNameText>{keyFile.name}</FileNameText>
                                  <FileSizeText>{formatBytes(keyFile.size)}</FileSizeText>
                                  <FileIconContainer size={16} color={themeContext.palette.vodafoneRed}>
                                    <TrashIcon onClick={removeFile} style={{ marginLeft: "5px", cursor: "pointer" }} />
                                  </FileIconContainer>
                                </FileNameContainer>
                              ) : null}
                            </div>
                          </div>
                        ),
                      },
                    ]}
                  />
                </form>
              </MainContent>
              <SideMenu>
                {!isEditing ? (
                  <LinkContainer
                    id="edit-service-details-button"
                    onClick={() => {
                      setIsEditing(!isEditing);
                    }}
                  >
                    <IconContainer color={styleTheme.palette.turquoiseBlue}>
                      <EditIcon />
                    </IconContainer>
                    <span>{t("pages.antExternalServices.profilePage.editDetails")}</span>
                  </LinkContainer>
                ) : (
                  <>
                    <LinkContainer id="save-changes-button" onClick={() => handleSubmit()}>
                      <IconContainer color={styleTheme.palette.successGreen}>
                        <CheckIcon />
                      </IconContainer>
                      <span>{t("pages.antExternalServices.userProfile.links.saveChanges")}</span>
                    </LinkContainer>

                    <LinkContainer
                      id="cancel-changes"
                      onClick={() => {
                        resetForm();
                        resetDropdown();
                        setIsEditing(false);
                        setIsStarting(true);
                      }}
                    >
                      <IconContainer color={styleTheme.palette.vodafoneRed}>
                        <UndoIcon />
                      </IconContainer>
                      <span>{t("pages.antExternalServices.profilePage.cancelText")}</span>
                    </LinkContainer>
                  </>
                )}
                <LinkContainer
                  id="remove-service-button"
                  onClick={() => deleteAntExternalService()}
                  disabled={isEditing}
                >
                  <IconContainer color={styleTheme.palette.vodafoneRed}>
                    <TrashIcon />
                  </IconContainer>
                  <span>{t("pages.antExternalServices.profilePage.removeService")}</span>
                </LinkContainer>
              </SideMenu>
            </>
          )}
        />
      </PageContent>
    </PageContainer>
  );
};

export default AntExternalServicesProfile;

const PageContent = styled("div")`
  display: flex;
  flex-direction: row;
  margin-top: calc(128px + 28px - 50px);
  margin-left: calc(200px + 20px + 24px - 48px);
  min-width: 900px;
`;

const DescriptionContainer = styled("span")`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  hyphens: auto;
`;

const Value = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
`;

const MainContent = styled("div")`
  display: flex;
  flex-direction: column;
  width: 60%;
`;

const SideMenu = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 55px;
  width: 40%;
  margin-left: 75px;
`;

const LinkContainer = styled("div")<{ disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-family: Vodafone Rg;
  color: ${styleTheme.palette.darkGrey};
  font-size: 16px;
  margin-bottom: 15px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? "none" : "inherit")};

  > div {
    margin-right: 8px;
  }
`;

const IconContainer = styled("div")<{ color: string }>`
  width: 20px;
  height: 20px;
  > svg {
    stroke: ${props => props.color};
  }
`;

const TextButton = styled("label")`
  display: flex;
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.darkGrey};
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
  > input {
    display: none;
  }
`;

export const FileIconContainer = styled("div")<{ color: string; size: number }>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  svg {
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    stroke: ${props => props.color};
  }
`;

const FileNameContainer = styled("div")`
  display: flex;
`;

const FileSizeText = styled("span")`
  color: ${props => props.theme.palette.grey};
  font-family: Vodafone Rg;
  font-size: 16px;
  margin-left: 3px;
`;

const FileNameText = styled("span")`
  color: ${props => props.theme.palette.grey};
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.greyDarkest};
  margin-left: 8px;
`;

const TitleLabel = styled("div")<{ mandatory: boolean }>`
  min-height: 21px;
  font-family: Vodafone Rg;
  font-weight: bold;
  line-height: 21px;
  font-size: 16px;
  color: #999999;
  margin-bottom: 8px;
  width: fit-content;
  display: inline-flex;
  ${props =>
    props.mandatory ? "::after {content: ' *'; color: #ff0000; font-weight: 400; padding-left: 2px;}" : null};
`;

const FormError = styled("span")`
  height: 19px;
  color: ${props => props.theme.palette.errorRed};
  font-family: Vodafone Rg;
  font-size: 14px;
  text-align: right;
  float: right;
  font-weight: 400;
`;

const TextContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DialogContainer = styled("div")`
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  background-color: #212121;
  position: absolute;
  width: 80px;
  height: 35px;
  z-index: 9999;
  margin-left: 93px;
  margin-top: -8px;
  &:after {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-right-color: #212121;
    border-width: 10px;
    margin-top: -10px;
  }
`;

const Text = styled("p")`
  font-family: Vodafone Rg;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: white;
  text-align: center;
`;
