import Axios, { AxiosRequestConfig } from "axios";
import store from "./store.config";

// Interfaces
interface IHeader {
  [key: string]: string;
}

// Headers follows -> on development : on production env
const sbHeaders: IHeader =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? {
        "Security-Cookie": `${localStorage.getItem("securityCookie")}`,
        "X-XSRF-TOKEN": `${localStorage.getItem("xsrfToken")}`,
      }
    : {
        "Security-Cookie": `${localStorage.getItem("securityCookie")}`,
      };

// Configuration
const requestConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_SERVICE_BUILDER_BASE,
  headers: {
    ...sbHeaders,
  },
};

const SBApiClient = Axios.create(requestConfig);

// Re-configure Axios BaseURL
export const configureSBAxiosUrl = (apiUrl: string) => {
  SBApiClient.defaults.baseURL = apiUrl;
};

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  SBApiClient.interceptors.request.use(
    res => {
      const loggedUser = store.getState().authenticationReducer.loggedUser;

      if (loggedUser != null) {
        res.headers = {
          ...res.headers,
          "x-dummy-cookie": `username=${loggedUser.username}; role=${loggedUser.role}`,
        };
      }

      return res;
    },
    error => Promise.reject(error),
  );
}

export default SBApiClient;
