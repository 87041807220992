import React from "react";
import { Formik, FormikHelpers } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import CampaignProgressAndSubmit from "./campaign-progress-and-submit.component";
import { Row } from "../../../../../shared/shared.styled";
import { IReferralCampaign, TypeScreenEnum, Screen } from "../../referral-campaigns.model";
import ReceiverScreenForm from "../../components/receiver-screen-form.component";
import ReceiverScreenPreview from "../../components/receiver-screen-preview.component";
import { validateSchemaReceiverScreen, validateTranslationsReceiverScreen } from "../../referral-campaigns.utils";
import { PageContent } from "../../../../../shared/responsive-ui.styled";
import GenericLanguageSelector from "../../../../../shared/components/generic-language-selector.component";
import { ILanguage } from "../../../../../shared/models/language.model";
import useEmptyLanguageWarningModal from "../../../../../shared/hooks/use-empty-language-warning-modal.hook";

interface ICreateReceiverScreenProps {
  languages: ILanguage[];
  selectedLanguage: string;
  setSelectedLanguage: React.Dispatch<React.SetStateAction<string>>;
  formValues: IReferralCampaign;
  onSubmit: (values: IReferralCampaign) => void;
  setStep: (step: number, values: IReferralCampaign) => void;
}

/**
 * CreateReceiverScreen page component
 */
const CreateReceiverScreen = ({
  languages,
  selectedLanguage,
  setSelectedLanguage,
  formValues,
  onSubmit,
  setStep,
}: ICreateReceiverScreenProps) => {
  const [t] = useTranslation();

  const { warningModalOpen, showWarning, setShowWarning } = useEmptyLanguageWarningModal(languages || []);
  /**
   * Submit method wrapper to show warning if needed
   * @param values
   */
  const submitWrapper = (values: IReferralCampaign) => {
    if (showWarning) {
      setShowWarning(false);
      warningModalOpen(handleSubmitSenderScreen, values);
    } else {
      handleSubmitSenderScreen(values);
    }
  };

  /**
   * get initial values
   * this ones could be different depending on last form
   */
  const getInitialValues = () => {
    //if screen were not initializated yet
    if (!formValues.screens) {
      formValues.screens = [new Screen(TypeScreenEnum.RECEIVER, languages)];
    } else if (!formValues.screens.find(screen => screen.type === TypeScreenEnum.RECEIVER)) {
      formValues.screens = [...formValues.screens, new Screen(TypeScreenEnum.RECEIVER, languages)];
    }
    return formValues;
  };

  /**
   * Performs the create request
   * @param {IReferralCampaign} values
   * @param {FormikHelpers<IReferralCampaign>} actions
   */
  const handleSubmitSenderScreen = (values: IReferralCampaign) => {
    onSubmit(values);
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={submitWrapper}
      validate={values => validateSchemaReceiverScreen(values, languages, setShowWarning)}
      render={({ values, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue, errors }) => (
        <PageContent className={"display-column"}>
          <form onSubmit={handleSubmit}>
            <CampaignProgressAndSubmit
              stepTitle={t("pages.referralCampaigns.createCampaign.receiverScreen.createReceiverScreen")}
              stepDescription={t("pages.referralCampaigns.createCampaign.step").replace("{0}", "4")}
              buttonLabel={t("pages.referralCampaigns.createCampaign.create")}
              step={4}
              totalOfSteps={4}
              setStep={step => setStep(step, values)}
              onSubmitFn={() => validateTranslationsReceiverScreen(values, languages, setSelectedLanguage)}
            />
            <ReceiverScreenFormPreviewContainer>
              <ReceiverScreenFormContainer>
                <ReceiverScreenForm
                  title={
                    <TitlesContainer>
                      <Row>
                        <FormTitle>{t("pages.referralCampaigns.createCampaign.receiverScreen.title")}</FormTitle>
                        <div style={{ marginTop: "auto", marginLeft: "auto" }}>
                          <GenericLanguageSelector
                            selectedLanguage={selectedLanguage}
                            availableLanguages={languages.map(lang => lang.code)}
                            changeSelectedLanguage={(lang: any) => setSelectedLanguage(lang.key)}
                          ></GenericLanguageSelector>
                        </div>
                      </Row>
                    </TitlesContainer>
                  }
                  isEditing={true}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  language={selectedLanguage}
                  isLanguageRequired={languages.find(l => l.code === selectedLanguage)?.mandatory}
                ></ReceiverScreenForm>
              </ReceiverScreenFormContainer>
              <ReceiverScreenPreviewContainer>
                <ReceiverScreenPreview values={values} language={selectedLanguage}></ReceiverScreenPreview>
              </ReceiverScreenPreviewContainer>
            </ReceiverScreenFormPreviewContainer>
          </form>
        </PageContent>
      )}
    />
  );
};

export default CreateReceiverScreen;

const ReceiverScreenFormPreviewContainer = styled("div")`
  display: flex;
  flex-direction: row;
  margin-top: 56px;
  flex-wrap: wrap;
`;
const ReceiverScreenFormContainer = styled("div")`
  flex: 2;
  > div > div > span {
    width: 100%;
  }
`;

const ReceiverScreenPreviewContainer = styled("div")`
  flex: 1;
  margin-left: 30px;
`;

const FormTitle = styled("span")`
  font-family: "Vodafone Rg";
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 16px;
`;

const TitlesContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;
