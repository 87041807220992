export enum LoginErrorCodes {
  WRONG_COMBINATION = 4012,
  INACTIVE_USER = 40113,
  ACCOUNT_BLOCKED = 40114,
  PASSWORD_EXPIRED = 40115,
  USER_NOT_ENABLED = 40116,
  PASSWORD_RECENTLY_USED = 40112,
  UNAUTHORIZED_NO_RECAPTCHA = 40119,
  CANCELLED = 0,
}

export function getCorrectBackgroundImage(imageUrl: string) {
  const width = window.innerWidth;
  if (width <= 1367) {
    return imageUrl.replace("{{size}}", "");
  } else if (width <= 2720) {
    return imageUrl.replace("{{size}}", "@2x");
  } else {
    return imageUrl.replace("{{size}}", "@3x");
  }
}

export const MAX_FAILED_LOGIN_ATTEMPTS = 3;

export const RECAPTCHA_KEY = "6LeST70lAAAAAPDwXnxHhpF1oXdO0S8u-q1IuE0L";
