import { Table, useAlert, useFilters } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { IStoreInterface } from "../../../../configs/store.config";
import TablePagination from "../../../../shared/components/table-pagination/table-pagination-component";
import { LoadingText } from "../../../../shared/shared.styled";
import SupportApi from "../../support.api";
import { IAppLog, IAppReportLogsRequest, GatewayLogStatusEnum } from "../../support.interfaces";
import { SupportActions } from "../../support.store";
import { SupportUtils } from "../../support.utils";
import AppLogDrawer from "./app-log-drawer.component";
import AppLogsFilters, { AppLogsFilterKeys } from "./app-logs-filters.component";

/**
 * App logs
 * @returns
 */
const AppLogs = () => {
  // Hooks init
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const {
    filters,
    updateFilter,
    isFilterActive,
    clearFilter,
    resetFilters,
    getFilterValue,
    getFiltersQueryString,
    updateMultipleFilters,
  } = useFilters();

  // Local state
  const firstUpdate = React.useRef(true);
  const [isFetchingLogs, setIsFetchingLogs] = React.useState(false);
  const [drawerIsOpen, setDrawerIsOpen] = React.useState(false);
  const [selectedLog, setSelectedLog] = React.useState<IAppLog | undefined>();
  const [page, setActivePage] = React.useState(1);
  const pageSize = 20;
  const [requestParams, setRequestParams] = React.useState<IAppReportLogsRequest>({
    page: 1,
    pageSize: 20,
  });
  const [requestParamsTemp, setRequestParamsTemp] = React.useState<IAppReportLogsRequest>({
    page: 1,
    pageSize: 20,
  });

  // Redux state
  const appLogs = useSelector((state: IStoreInterface) => state.supportReducer.appLogs);
  const appLogsCount = useSelector((state: IStoreInterface) => state.supportReducer.appLogsCount);

  const showLogDrawer = (appLog: IAppLog) => {
    setSelectedLog(appLog);
    setDrawerIsOpen(true);
  };

  /*
   * Get app logs on page change
   * */
  React.useEffect(() => {
    setRequestParams({ ...requestParams, page: page });
  }, [dispatch, page]);
  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    getAppLogs();
  }, [requestParams]);

  /*
   * Updates the request state each time the filters are changed
   * */
  React.useEffect(() => {
    let request: IAppReportLogsRequest = { page, pageSize };
    const dateFilter = getDateFilter();
    if (!dateFilter) {
      const timeFilter = getTimeFilter();
      request = { ...request, ...formatTimeFilter(timeFilter) };
    } else {
      request = { ...request, ...dateFilter };
    }

    request = {
      ...request,
      ...getAppFilterKey(AppLogsFilterKeys.MSISDN),
      ...getAppFilterKey(AppLogsFilterKeys.SDK_REQUEST),
      ...getAppFilterKey(AppLogsFilterKeys.SDK_RESPONSE),
      ...getAppFilterKey(AppLogsFilterKeys.APP_CONFIG_ID),
      ...getAppFilterKey(AppLogsFilterKeys.REQUEST_ID),
      ...getAppFilterKey(AppLogsFilterKeys.CONNECTION_STATUS),
      ...getAppFilterKey(AppLogsFilterKeys.CONNECTION_TYPE),
      ...getAppFilterKey(AppLogsFilterKeys.CARD_CARRIER),
      ...getAppFilterKey(AppLogsFilterKeys.NETWORK_CARRIER),
      ...getAppFilterKey(AppLogsFilterKeys.OPERATION),
      ...getStatusFilter(),
    };

    setRequestParamsTemp(request);
  }, [filters]);

  /**
   * Refresh results handler
   * */
  const refreshResults = () => {
    setActivePage(1);
    setRequestParams({ ...requestParamsTemp, page: 1 });
  };

  /**
   * Returns the status filter
   * */
  const getStatusFilter = () => {
    if (filters.get(AppLogsFilterKeys.STATUS)) {
      const status = (filters.get(AppLogsFilterKeys.STATUS) as string[])[0];
      // @ts-ignore
      return { requestStatus: GatewayLogStatusEnum[status] };
    }
    return null;
  };

  /**
   * Get App Filter Key
   * @param filterKey
   * @returns
   */
  const getAppFilterKey = (filterKey: AppLogsFilterKeys) => {
    if (filters.get(filterKey)) {
      return { [filterKey]: (filters.get(filterKey) as string[])[0] };
    }
    return null;
  };

  /**
   * Format time filter
   * */
  const formatTimeFilter = (timeFilter: { startTime: string[]; endTime: string[] } | null) => {
    if (timeFilter) {
      const startDate = moment()
          .utcOffset(0)
          .hours(Number(timeFilter.startTime[0]))
          .minutes(Number(timeFilter.startTime[1]))
          .seconds(0),
        endDate = moment()
          .utcOffset(0)
          .hours(Number(timeFilter.endTime[0]))
          .minutes(Number(timeFilter.endTime[1]))
          .seconds(0);
      if (endDate.isBefore(startDate)) {
        endDate.add(1, "d");
      }
      return { startDate: startDate.unix(), endDate: endDate.unix() };
    }
    return null;
  };

  /**
   * Returns the time filter object
   * */
  const getTimeFilter = () => {
    if (filters.get(AppLogsFilterKeys.TIME_RANGE)) {
      const startTime = (filters.get(AppLogsFilterKeys.TIME_RANGE) as string[])[0].split(":");
      const endTime = (filters.get(AppLogsFilterKeys.TIME_RANGE) as string[])[1].split(":");
      return { startTime: startTime, endTime: endTime };
    }
    return null;
  };

  /**
   * Returns the date filter object
   * */
  const getDateFilter = () => {
    if (filters.get(AppLogsFilterKeys.START_DATE)) {
      let startDate,
        endDate = null;
      const timeFilter = getTimeFilter();
      if (timeFilter) {
        startDate = moment((filters.get(AppLogsFilterKeys.START_DATE) as string[])[0])
          .utcOffset(0)
          .hours(Number(timeFilter.startTime[0]))
          .minutes(Number(timeFilter.startTime[1]))
          .seconds(0);
        endDate = moment((filters.get(AppLogsFilterKeys.END_DATE) as string[])[0])
          .utcOffset(0)
          .hours(Number(timeFilter.endTime[0]))
          .minutes(Number(timeFilter.endTime[1]))
          .seconds(59);
      } else {
        startDate = moment((filters.get(AppLogsFilterKeys.START_DATE) as string[])[0])
          .utcOffset(0)
          .hours(0)
          .minutes(0)
          .seconds(0);
        endDate = moment((filters.get(AppLogsFilterKeys.END_DATE) as string[])[0])
          .utcOffset(0)
          .hours(0)
          .minutes(0)
          .seconds(0)
          .add(1, "d");
      }
      return { startDate: startDate.unix(), endDate: endDate.unix() };
    }
    return null;
  };

  /**
   * Get app logs method
   * */
  const getAppLogs = () => {
    setIsFetchingLogs(true);
    SupportApi.methods
      .getAppLogs(requestParams)
      .finally(() => setIsFetchingLogs(false))
      .then(
        res => {
          const { reportLogList, count } = res.data;
          dispatch(SupportActions.Creators.fetchAppLogsSuccessAction(reportLogList, count));
        },
        () => {
          dispatch(SupportActions.Creators.fetchAppLogsErrorAction());
          setAlertProps({
            title: t("pages.support.appLogs.getLogsError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
  };

  /**
   * Handles the pagination change
   * */
  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
  };
  return (
    <>
      <AppLogsContainer>
        <AppLogsFilters
          filters={filters}
          isFilterActive={isFilterActive}
          updateFilter={updateFilter}
          clearFilter={clearFilter}
          resetFilters={resetFilters}
          getFilterValue={getFilterValue}
          getFiltersQueryString={getFiltersQueryString}
          updateMultipleFilters={updateMultipleFilters}
          appLogsCount={appLogsCount}
          refreshResults={refreshResults}
        />
        {isFetchingLogs ? (
          <LoadingText>{t("commons.loadingResults")}</LoadingText>
        ) : (
          <>
            <Table
              rowClickAction={(idx, log) => showLogDrawer(log)}
              values={appLogs}
              columns={SupportUtils.getAppLogsColumns()}
            />
          </>
        )}
        {!isFetchingLogs && appLogsCount ? (
          <TablePagination
            handlePageChange={handlePageChange}
            totalItems={appLogsCount}
            activePage={page}
            pageSize={pageSize}
          />
        ) : null}
      </AppLogsContainer>
      {drawerIsOpen ? (
        <>
          <AppLogDrawer
            appLogId={(selectedLog as IAppLog).id}
            appLogRequestId={(selectedLog as IAppLog).requestId}
            hideDrawer={() => setDrawerIsOpen(false)}
          />
        </>
      ) : null}
    </>
  );
};

export default AppLogs;

const AppLogsContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;
