import { AutoClosingDropdown } from "@wit/mpesa-ui-components";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemeContext } from "styled-components";

interface ILanguageSelectorDropdownInterface {
  availableLanguages: SharedDropdownOption[];
  selectedLanguage: SharedDropdownOption;
  changeSelectedLanguage: (lang: SharedDropdownOption) => void;
  color?: string;
  id?: string;
}

/**
 * Dropdown language selector component
 */
const LanguageSelectorDropdown = ({
  availableLanguages,
  selectedLanguage,
  changeSelectedLanguage,
  color,
  id,
}: ILanguageSelectorDropdownInterface) => {
  const [t] = useTranslation();
  const theme = useContext(ThemeContext);
  return (
    <DropdownWrapper id={id || "language-selector"} color={color || theme.palette.vodafoneRed}>
      <AutoClosingDropdown
        hasValue={false}
        label={t(`commons.languages.${selectedLanguage.key}`)}
        options={availableLanguages}
        selectOption={opt => {
          changeSelectedLanguage(opt);
        }}
      />
    </DropdownWrapper>
  );
};

export default LanguageSelectorDropdown;

const DropdownWrapper = styled.div`
  > button {
    border: none;
    color: ${props => props.color};
    padding-left: 0px;
    justify-content: normal;

    padding: 7px 0;
    background-color: transparent;
    height: auto;

    :hover {
      border: none;
    }

    > svg  {
      stroke: ${props => props.color};
    }

    span {
      max-width: unset !important;
    }
  }
`;
