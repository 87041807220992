import React, { useEffect, useState } from "react";
import { CustomPicker } from "react-color";
import styled from "styled-components";
import tinycolor from "tinycolor2";

/**
 * Component to tab customize
 */
export const CustomizeTabColor = ({ hexCode, onChange }: any) => {
  const [colorTypes, setColorTypes] = useState({ hsl: { h: 0, s: 0, l: 0 }, hsv: { h: 0, s: 0, v: 0 }, hex: "aaaaaa" });
  const { Saturation, Hue } = require("react-color/lib/components/common");

  useEffect(() => {
    const colorToSet = tinycolor(hexCode);
    setColorTypes({ hsv: colorToSet.toHsv(), hsl: colorToSet.toHsl(), hex: colorToSet.toHex() });
    return () => {};
  }, [setColorTypes, hexCode]);

  /**
   * function to handleHue
   */
  const handleHueChange = (hue: any) => {
    const colorToSet = tinycolor(hue);
    setColorTypes({ hsv: colorToSet.toHsv(), hsl: colorToSet.toHsl(), hex: colorToSet.toHex() });
    onChange(colorToSet.toHex());
  };

  /**
   * function to handleHue
   */
  const handleSaturationChange = (hsv: any) => {
    const colorToSet = tinycolor(hsv);
    setColorTypes({ hsv: colorToSet.toHsv(), hsl: colorToSet.toHsl(), hex: colorToSet.toHex() });
    onChange(colorToSet.toHex());
  };

  /**
   * Pointer to select custom
   */
  const CustomPointer = () => {
    const inlineStyles = {
      pointer: {
        width: "18px",
        height: "18px",
        borderRadius: "50%",
        transform: "translate(-9px, -1px)",
        backgroundColor: hexCode,
        border: "1px solid #ebebeb",
        boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.37)",
      },
    };
    return <div style={inlineStyles.pointer} />;
  };

  return (
    <DivContainerColorCustomize>
      <DivSaturation>
        <Saturation
          hsl={colorTypes.hsl}
          hsv={colorTypes.hsv}
          onChange={handleSaturationChange}
          pointer={CustomPointer}
        />
      </DivSaturation>
      <DivHue>
        <Hue hsl={colorTypes.hsl} onChange={handleHueChange} direction={"horizontal"} pointer={CustomPointer} />
      </DivHue>
    </DivContainerColorCustomize>
  );
};

export default CustomPicker(CustomizeTabColor);

const DivContainerColorCustomize = styled("div")`
  border-top: 1px solid #ebebeb;
`;
const DivSaturation = styled("div")`
  position: relative;
  height: 140px;
  margin: 0.5vh 0.5vw 0.25vh 0.5vw;
`;

const DivHue = styled("div")`
  position: relative;
  margin: 0.5vh 0.5vw 0.25vh 0.5vw;
  height: 16px;
`;
