import { isKEMarket } from "../../../shared/shared.utils";
import { ISingleUserRequest, UserRolesDefault, UserRolesSFC } from "../../admin/users/users.interfaces";

/**
 * Check if the user can add, edit, or delete an on-demand notification, apply only for SFCMarket
 * @param userRole user role
 * @returns boolean
 */
export const canUserFromSFCMarketAddOrEditOrDeleteNotification = (userRole: ISingleUserRequest["role"]) => {
  if (process.env.REACT_APP_ENVIRONMENT !== "prod") {
    return (
      userRole === UserRolesDefault.ADMIN ||
      userRole === UserRolesSFC.ANNOUNCEMENTS_MANAGER ||
      userRole === UserRolesDefault.DEVELOPER
    );
  }

  if (isKEMarket()) {
    return userRole === UserRolesDefault.ADMIN || userRole === UserRolesSFC.ANNOUNCEMENTS_MANAGER;
  }

  return true; // Not checking for other market
};

/**
 * Check if the user approve (publish) changes of an on-demand notification, apply only for SFCMarket
 * @param userRole user role
 * @returns boolean
 */
export const canUserFromSFCMarketViewNotification = (userRole: ISingleUserRequest["role"]) => {
  if (isKEMarket()) {
    return (
      userRole === UserRolesDefault.ADMIN ||
      userRole === UserRolesDefault.APPROVER ||
      userRole === UserRolesDefault.DEVELOPER ||
      userRole === UserRolesDefault.SUPPORT
    );
  }

  return true; // Not checking for other market
};
