import { Magnifier, ModalTypeEnum, PageTitle, SearchBar, useAlert, useModal } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import { Breadcrumb } from "@wit/mpesa-ui-components/lib/types";
import i18next, { TFunction } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { IStoreInterface } from "../../../configs/store.config";
import { RoutesEnum } from "../../../routes/routes.constants";
import { IIcon } from "../../../shared/components/icon-picker.component";
import Document from "../rate-cards/components/document.component";
import {
  ListHeader,
  ListHeaders,
  LoadingText,
  PageContainer,
  RatioContainer,
  Row,
} from "../../../shared/shared.styled";
import AddDynamicScreenItem from "./components/add-dynamic-screen-item.component";
import DynamicScreensApi from "./dynamic-screens.api";
import { IDynamicScreen } from "./dynamic-screens.model";
import { DynamicScreensActions } from "./dynamic-screens.store";
import DynamicScreenListItem from "./components/dynamic-screen-list-item.component";
import { SearchBarFilterContainer } from "../../../shared/responsive-ui.styled";

/**
 * Dynamic screens list
 */
const DynamicScreensList = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [searchQuery, setSearchQuery] = React.useState("");
  const { dynamicScreens } = useSelector((state: IStoreInterface) => state.dynamicScreensReducer);
  const [showableDynamicScreens, setShowableDynamicScreens] = React.useState<IDynamicScreen[]>(
    dynamicScreens.items ? dynamicScreens.items : [],
  );

  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );
  const [showAlert, , setAlertProps] = useAlert();
  const history = useHistory();
  const [icons, setIcons] = useState<IIcon[]>([]);
  const [isLoadingIcons, setIsLoadingIcons] = useState<boolean>(false);

  React.useEffect(() => {
    DynamicScreensApi.methods
      .getIcons()
      .then(
        res => {
          setIcons(res.data);
        },
        () => {
          setAlertProps({
            type: AlertTypeEnum.ERROR,
            title: t("pages.dynamicScreen.getIconsError"),
          });
          showAlert();
        },
      )
      .finally(() => {
        setIsLoadingIcons(false);
      });
    getDynamicScreens();
  }, []);

  /*
   * Search
   * */
  React.useEffect(() => {
    let dynamicScreensTemp = [...(dynamicScreens.items ? dynamicScreens.items : [])];
    if (!!searchQuery) {
      dynamicScreensTemp = dynamicScreensTemp.filter(
        dynamicScreen =>
          dynamicScreen.name.translations.en.toLowerCase().includes(searchQuery.toLowerCase()) ||
          dynamicScreen.description.translations.en.toLowerCase().includes(searchQuery.toLowerCase()) ||
          dynamicScreen.eventId.toLowerCase().includes(searchQuery.toLowerCase()),
      );
    }
    setShowableDynamicScreens(dynamicScreensTemp);
  }, [dynamicScreens, searchQuery]);

  /**
   * Get dynamic screens list
   * */
  const getDynamicScreens = () => {
    DynamicScreensApi.methods.getDynamicScreens().then(
      res => {
        dispatch(DynamicScreensActions.creators.getDynamicScreensSuccess(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.dynamicScreens.getDynamicScreensError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * Delete dynamic screen confirmation
   * */
  const showDeleteDynamicScreenConfirmation = (item: IDynamicScreen) => {
    setConfirmationProps({
      title: t("pages.dynamicScreens.deleteTitle"),
      description: t("pages.dynamicScreens.deleteMessage"),
      primaryBtnLabel: t("pages.dynamicScreens.delete"),
      primaryAction: () => deleteDynamicScreen(item),
      secondaryAction: () => hideConfirmationModal(),
      primaryBtnId: "deleteService",
      secondaryBtnId: "hideConfirmation",
    } as BaseModalProps);
    showConfirmationModal();
  };

  /**
   * Delete dynamic screen handler
   * */
  const deleteDynamicScreen = (item: IDynamicScreen) => {
    DynamicScreensApi.methods
      .deleteDynamicScreen(item.id)
      .then(
        () => {
          setAlertProps({
            title: t("pages.dynamicScreens.deleteSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          getDynamicScreens();
        },
        () => {
          setAlertProps({
            title: t("pages.dynamicScreens.deleteError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        hideConfirmationModal();
      });
  };

  /**
   * Breadcrumbs fn
   * */
  const getBreadcrumbs = (t: TFunction): Breadcrumb[] => {
    return [
      {
        label: t("pages.configuration.title"),
        url: RoutesEnum.CONFIGS,
      },
      {
        label: t("pages.dynamicScreens.title"),
        url: "",
      },
    ];
  };

  return (
    <PageContainer>
      <PageTitle
        breadcrumbs={getBreadcrumbs(t)}
        title={t("pages.dynamicScreens.title")}
        navigateFunction={history.push}
        goBackFn={() => history.push(RoutesEnum.CONFIGS)}
        separatorStyle={{ display: "none" }}
        titleContainerStyle={{ marginTop: "13px", marginBottom: "20px", height: "60px" }}
      />

      {!dynamicScreens.items ? (
        <LoadingText>{t("commons.loadingResults")}</LoadingText>
      ) : (
        <DynamicScreensContainer>
          {dynamicScreens.items && dynamicScreens.items.length ? (
            <Row>
              <SearchBarFilterContainer>
                <SearchBar
                  id={"search-bar"}
                  placeholderLabel={t("pages.dynamicScreens.searchPlaceholder")}
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)}
                  clearValue={() => setSearchQuery("")}
                />
              </SearchBarFilterContainer>
            </Row>
          ) : null}
          <DynamicScreensListContainer>
            <>
              <ListHeaders>
                <ListHeader ratio={3 / 12}>{t("pages.dynamicScreens.dynamicScreen")}</ListHeader>
                <ListHeader ratio={2 / 12}>{t("pages.dynamicScreens.description")}</ListHeader>
                <ListHeader ratio={2 / 12}>{t("pages.dynamicScreens.category")}</ListHeader>
                <ListHeader ratio={2 / 12}>{t("pages.dynamicScreens.section")}</ListHeader>
                <ListHeader ratio={2 / 12}>{t("pages.dynamicScreens.eventId")}</ListHeader>
                <ListHeader ratio={1 / 12}>{t("pages.dynamicScreens.status")}</ListHeader>
              </ListHeaders>
              {!searchQuery ? (
                <AddDynamicScreenItem addNewDynamicScreenFn={() => history.push(RoutesEnum.DYNAMIC_SCREEN_CREATE)} />
              ) : null}
              {dynamicScreens.items && dynamicScreens.items.length ? (
                <>
                  {showableDynamicScreens.map((ds: IDynamicScreen) => {
                    return (
                      <DynamicScreenListItem
                        key={ds.id}
                        icons={icons}
                        item={ds}
                        deleteDynamicScreen={showDeleteDynamicScreenConfirmation}
                        editDynamicScreen={() => history.push(RoutesEnum.DYNAMIC_SCREEN.replace(":id", ds.id))}
                      />
                    );
                  })}
                  {showableDynamicScreens.length === 0 ? (
                    <MagnifierContainer>
                      <Magnifier />
                      <NoResultsFoundText>{t("pages.dynamicScreens.noSearchResults")}</NoResultsFoundText>
                    </MagnifierContainer>
                  ) : null}
                </>
              ) : (
                <EmptyState>
                  <Document />
                  <SelectText>{t("pages.dynamicScreens.emptyState")}</SelectText>
                </EmptyState>
              )}
            </>
          </DynamicScreensListContainer>
        </DynamicScreensContainer>
      )}
    </PageContainer>
  );
};

export default DynamicScreensList;

const DynamicScreensContainer = styled("div")`
  margin-bottom: 34px;
  display: flex;
  flex-direction: column;
`;

const MagnifierContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 50px;
  svg {
    width: 140px;
    height: 140px;
    margin-bottom: 24px;
    stroke: red;
  }
`;

const NoResultsFoundText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.midGrey};
`;

const DynamicScreensListContainer = styled("div")`
  margin-bottom: 50px;
  margin-top: 31px;
`;

const EmptyState = styled("div")`
  margin-top: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const SelectText = styled("div")`
  font-family: "Vodafone Rg";
  width: 400px;
  height: 51px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  margin-top: 35px;
`;
