import { ChevronDownIcon, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { IStoreInterface } from "../../../configs/store.config";
import { ILastLogin, ILoggedUser, LoginResultEnum } from "../../../features/authentication/authentication.interfaces";
import DesktopIcon from "../../icons/desktop.icon";
import WarningIcon from "../../icons/warning.icon";
import LastLoginsApi from "./last-logins.api";
import { UserAreaRow, UserAreaRowContent, UserAreaRowTitle } from "./user-area.component";
import { AccordionChevron } from "../drawer-accordion.component";
import { convertToCamelCase, renderDate } from "../../shared.utils";
import i18next from "i18next";
import { DateFormatEnum } from "../../shared.enums";

const LastLogins = () => {
  const { t } = useTranslation(["private", "market_values"]);
  const user = useSelector((s: IStoreInterface) => s.authenticationReducer.loggedUser as ILoggedUser);
  const [isOpen, setIsOpen] = React.useState(false);
  const [lastLogins, setLastLogins] = React.useState<ILastLogin[]>([]);
  const [showAlert, , setAlertProps] = useAlert();

  /**
   * Fetches the list of last logins
   */
  React.useEffect(() => {
    if (isOpen) {
      LastLoginsApi.methods.getLastLogins().then(
        res => {
          setLastLogins(res.data);
        },
        () => {
          setAlertProps({
            title: t("components.userArea.lastLoginsError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
    }
  }, [isOpen]);

  return (
    <>
      <UserAreaRow>
        <UserAreaRowTitle>{t("components.userArea.lastLogin")}</UserAreaRowTitle>
        <UserAreaRowContent>
          <span>{renderDate({ date: moment(user.lastLoginTs), dateFormat: DateFormatEnum.DD })}</span>
          <AccordionChevron
            id={"open-last-login-list"}
            open={isOpen}
            style={{ marginLeft: "auto" }}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <ChevronDownIcon />
          </AccordionChevron>
        </UserAreaRowContent>
      </UserAreaRow>
      {isOpen && (
        <LastLoginsList>
          {lastLogins.map(login => {
            return (
              <LastLoginItem key={login.loginDate} id={`last-login-item-${login.loginDate}`}>
                <Container>
                  {login.result === LoginResultEnum.ERROR ? (
                    <WarningIcon id={`login-error-icon-${login.loginDate}`} />
                  ) : (
                    <DesktopIcon id={`login-success-icon-${login.loginDate}`} />
                  )}
                  <span style={{ marginLeft: "16px" }}>
                    {i18next.exists(`components.userArea.osDevices.${convertToCamelCase(login.deviceOs)}`)
                      ? t(`components.userArea.osDevices.${convertToCamelCase(login.deviceOs)}`)
                      : login.deviceOs}
                  </span>
                </Container>
                <Container>
                  <span className={login.result === LoginResultEnum.ERROR ? "error" : "success"}>
                    {renderDate({ date: moment(login.loginDate), dateFormat: DateFormatEnum.DD })}
                  </span>
                </Container>
              </LastLoginItem>
            );
          })}
        </LastLoginsList>
      )}
    </>
  );
};

const LastLoginsList = styled("div")`
  margin-bottom: 40px;
`;

const Container = styled("div")`
  display: flex;
  flex: 1;
  align-items: center;
`;
const LastLoginItem = styled("div")`
  height: 44px;
  font-family: "Vodafone Rg";
  color: ${props => props.theme.palette.darkGrey};
  font-size: 14px;
  display: flex;
  border-bottom: 1px solid ${props => props.theme.palette.aluminium};
  align-items: center;

  .success {
    color: ${props => props.theme.palette.digitalGreen};
  }
  .error {
    color: ${props => props.theme.palette.freshOrange};
  }
`;

export default LastLogins;
