import { IApp } from "../../shared/models/app.model";
import { IPayloadAction } from "../../shared/shared.interfaces";
import produce from "immer";
import { AuthenticationActions } from "../authentication/authentication.store";

export const AppsActions = {
  types: {
    FETCHING_APPS: "FETCHING_APPS",
    FETCH_APPS_SUCCESS: "FETCH_APPS_SUCCESS",
    FETCH_APPS_ERROR: "FETCH_APPS_ERROR",
    DELETE_APP: "DELETE_APP",
  },
  creators: {
    fetchingAppsAction: () => ({
      type: AppsActions.types.FETCHING_APPS,
    }),
    fetchAppsSuccessAction: (apps: IApp[]) => ({
      type: AppsActions.types.FETCH_APPS_SUCCESS,
      payload: {
        apps,
      },
    }),
    fetchAppsErrorAction: () => ({
      type: AppsActions.types.FETCH_APPS_ERROR,
    }),
    deleteAppAction: (app: IApp) => ({
      type: AppsActions.types.DELETE_APP,
      payload: {
        app,
      },
    }),
  },
};

export interface IAppsReducerInterface {
  apps: IApp[];
  isLoadingApps: boolean;
}

const initialState: IAppsReducerInterface = {
  apps: [],
  isLoadingApps: false,
};

export const appsReducer = produce((draft: IAppsReducerInterface, action: IPayloadAction<any>) => {
  switch (action.type) {
    case AppsActions.types.FETCHING_APPS:
      draft.isLoadingApps = true;
      return;
    case AppsActions.types.FETCH_APPS_SUCCESS:
      draft.isLoadingApps = false;
      draft.apps = action.payload.apps;
      return;
    case AppsActions.types.FETCH_APPS_ERROR:
      draft.isLoadingApps = false;
      return;
    case AppsActions.types.DELETE_APP: {
      draft.apps = draft.apps.filter(app => app.id !== action.payload.app.id);
      return;
    }
    case AuthenticationActions.types.LOGOUT:
      return initialState;
    default:
      return draft;
  }
}, initialState);
