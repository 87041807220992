import { PrimaryButton, TextInput, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikHelpers } from "formik";

import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { object, string } from "yup";
import { RoutesEnum } from "../../../routes/routes.constants";
import AnimatedBackgroundImage from "../../../shared/components/background-image.component";
import PasswordPoliciesDialog from "../../../shared/components/password-rules.component";
import RegisterCompletedIcon from "../../../shared/icons/register-completed.icon";
import { IPasswordPolicies, validatePassword } from "../../../shared/passwords-utils";
import UsersApi from "../../admin/users/users.api";
import AuthenticationApi from "../authentication.api";
import { IValidateRegisterIdResponse } from "../authentication.interfaces";
import { AuthenticationActions } from "../authentication.store";
import { IRegisterValues } from "./register.page";
import { useHistory, useParams } from "react-router-dom";
import { LoginErrorCodes } from "../authentication.utils";

/**
 * Validation schema
 * @param passwordPolicies
 */
const validationSchema = (passwordPolicies: IPasswordPolicies) => {
  let msg = "";
  return object().shape({
    newPassword: string()
      .required(i18next.t("components.changePasswordModal.required"))
      .test("password-rules", "", function(val) {
        if (!val) {
          return false;
        }
        msg = validatePassword(passwordPolicies, val);
        if (msg) {
          return this.createError({ message: msg });
        }
        return false;
      }),
    confirmPassword: string()
      .required(i18next.t("components.changePasswordModal.required"))
      .test("passwords-match", i18next.t("components.changePasswordModal.passwordsShouldMatch"), function(val) {
        return this.parent.newPassword === val;
      }),
  });
};

/**
 * Recovery page
 */
const RecoveryPage = () => {
  // Hooks initialization
  const [t] = useTranslation(["public"]);
  const history = useHistory();
  const { pwRegId } = useParams<any>();
  const [showAlert, , setProps] = useAlert();
  const dispatch = useDispatch();
  const [info, setInfo] = React.useState<IValidateRegisterIdResponse>();
  const [successfulRequest, setSuccessfulRequest] = React.useState(false);
  const [showPasswordPolicies, setShowPasswordPolicies] = React.useState(false);
  const [passwordPolicies, setPasswordPolicies] = React.useState<IPasswordPolicies>({
    restrictMinDigits: true,
    minDigits: 1,
    restrictMinUpperCaseLetters: true,
    minUpperCaseLetters: 1,
    restrictMinLowerCaseLetters: true,
    minLowerCaseLetters: 1,
    restrictMinNonAlphanumericCharacters: true,
    minNonAlphanumericCharacters: 1,
    restrictSuccessiveIdenticalChars: false,
    minSuccessiveIdenticalChars: 0,
    minLength: 6,
  });

  /*
   * GET the password policies
   * */
  React.useEffect(() => {
    UsersApi.methods.passwordPolicies("pwreset", pwRegId).then(
      res => {
        setPasswordPolicies(res.data);
      },
      err => {
        console.error(err);
      },
    );
  }, []);

  // Effects
  React.useEffect(() => {
    AuthenticationApi.methods.validateForgotPasswordId(pwRegId).then(
      res => {
        setInfo(res.data);
      },
      () => {
        setProps({
          title: t("pages.recovery.invalidRegisterIdAlert.title"),
          content: t("pages.recovery.invalidRegisterIdAlert.content"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        history.push(RoutesEnum.LOGIN, true);
      },
    );
  }, [setProps, showAlert, t, pwRegId]);

  /**
   * Submit form
   * @param values
   * @param actions
   */
  const submitForm = (values: IRegisterValues, actions: FormikHelpers<IRegisterValues>) => {
    actions.setSubmitting(true);
    const fullValues = {
      id: pwRegId,
      password: values.newPassword,
    };
    AuthenticationApi.methods.setNewPassword(fullValues).then(
      () => {
        setSuccessfulRequest(true);
      },
      err => {
        if (
          err &&
          err.data &&
          err.data.status &&
          err.data.status.businessCode === LoginErrorCodes.PASSWORD_RECENTLY_USED
        ) {
          setProps({
            title: t("pages.recovery.errorRegisteringPasswordUsedAlert.title"),
            content: t("pages.recovery.errorRegisteringPasswordUsedAlert.content"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        } else {
          setProps({
            title: t("pages.recovery.errorRegisteringAlert.title"),
            content: t("pages.recovery.errorRegisteringAlert.content"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        }
      },
    );
  };

  return (
    <RegisterContainer>
      {successfulRequest ? (
        <RecoverPasswordCompletedContainer>
          <RegisterTitle dangerouslySetInnerHTML={{ __html: t("pages.recovery.title") }} />
          <div style={{ marginLeft: "auto", marginRight: "auto" }}>
            <RegisterCompletedIcon />
          </div>
          <div>
            <SuccessfullyCreatedText>{t("pages.recovery.passwordChanged")}</SuccessfullyCreatedText>
          </div>
          <div>
            <PrimaryButton
              redTheme={true}
              titleLabel={t("pages.recovery.goToLogin")}
              onClick={() => {
                AuthenticationApi.methods.logout().finally(() => {
                  dispatch(AuthenticationActions.creators.logoutAction());
                  history.push(RoutesEnum.LOGIN);
                });
              }}
            />
          </div>
        </RecoverPasswordCompletedContainer>
      ) : (
        <Formik
          onSubmit={submitForm}
          initialValues={{ newPassword: "", confirmPassword: "" }}
          validationSchema={validationSchema(passwordPolicies)}
          render={({ handleChange, values, errors, handleSubmit, isSubmitting, setSubmitting }) => (
            <RegisterFormContainer onSubmit={handleSubmit}>
              <RegisterTitle dangerouslySetInnerHTML={{ __html: t("pages.recovery.title") }} />
              <RegisterSubTitle>{t("pages.recovery.subTitle")}</RegisterSubTitle>
              <InputsContainer>
                <TextInputContainer>
                  <TextInput
                    title={t("pages.recovery.password")}
                    autoFocus
                    placeholder={t("pages.recovery.passwordPlaceholder")}
                    name="newPassword"
                    onChange={val => {
                      handleChange(val);
                      setSubmitting(false);
                    }}
                    onBlur={() => setShowPasswordPolicies(false)}
                    onFocus={() => setShowPasswordPolicies(true)}
                    value={values.newPassword}
                    required
                    error={errors.newPassword}
                    type="password"
                  />
                  {passwordPolicies && showPasswordPolicies ? (
                    <PasswordPoliciesDialog policies={passwordPolicies} topMargin={"135px"} />
                  ) : null}
                </TextInputContainer>
                <TextInputContainer>
                  <TextInput
                    title={t("pages.recovery.confirmPassword")}
                    placeholder={t("pages.recovery.confirmPasswordPlaceholder")}
                    name="confirmPassword"
                    onChange={val => {
                      handleChange(val);
                      setSubmitting(false);
                    }}
                    value={values.confirmPassword}
                    required
                    error={errors.confirmPassword}
                    type="password"
                  />
                </TextInputContainer>
                <ButtonContainer>
                  <PrimaryButton
                    disabled={isSubmitting}
                    titleLabel={t("pages.recovery.activateAccountBtn")}
                    redTheme={true}
                    type="submit"
                  />
                </ButtonContainer>
              </InputsContainer>
            </RegisterFormContainer>
          )}
        />
      )}
      <AnimatedBackgroundImage />
    </RegisterContainer>
  );
};

export default RecoveryPage;

const RegisterTitle = styled("div")`
  font-family: Vodafone Lt;
  font-size: 33px;
  color: #333;
  margin-bottom: 53px;

  > span {
    color: ${props => props.theme.palette.vodafoneRed};
  }
`;

const RegisterSubTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 15px;
`;

const RegisterContainer = styled("div")`
  height: 100%;
`;

const RecoverPasswordCompletedContainer = styled("div")`
  position: absolute;
  width: 397px;
  left: 117px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 1;
  background-color: ${props => props.theme.palette.white};
  padding: 32px 40px 40px 40px;
  border-radius: 6px;

  @media (max-width: 768px) {
    width: calc(100% - 40px);
    left: 20px;
  }
`;

const RegisterFormContainer = styled("form")`
  position: absolute;
  width: 397px;
  left: 117px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 1;
  background-color: ${props => props.theme.palette.white};
  padding: 32px 40px 40px 40px;
  border-radius: 6px;

  @media (max-width: 768px) {
    width: calc(100% - 40px);
    left: 20px;
  }
`;

const InputsContainer = styled("div")``;

const TextInputContainer = styled("div")`
  margin-bottom: 15px;
`;

const ButtonContainer = styled("div")`
  margin-top: 48px;
`;

const SuccessfullyCreatedText = styled("div")`
  font-family: Vodafone Rg;
  font-size: 18px;
  text-align: center;
  color: ${props => props.theme.palette.midGrey};
  margin-top: 14px;
  margin-bottom: 45px;
`;

const UsernameTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
  margin-top: 15px;
  margin-bottom: 5px;
`;
