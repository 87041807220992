import { css } from "styled-components";
const safaricomPalette = {
  red: {
    lighter: "#eaf9f0",
    normal: "#2fc56d",
    darker: "#218a4c",
    darkest: "#990000",
  },
  whiteDark: "#F4F4F4",
  greyLightest: "#EBEBEB",
  greyLighter: "#CCCCCC",
  greyLight: "#FAFAFA",
  grey: "#AFAFAF",
  greyDarker: "#999999",
  greyDarkest: "#666666",
  blackLight: "#4A4D4E",
  black: "#333333",
  secondary: {
    purpleLight: "#9C2AA0",
    purpleDark: "#5E2750",
    blueLight: "#00B0CA",
    blueDark: "#007C92",
    yellowLight: "#FECB00",
    yellowDark: "#EB9700",
    green: "#A8B400",
  },
  warningYellow: "#eb8b00",
  successGreen: "#009900",
  errorRed: "#ff2a58",
  vodafoneRed: "#2fc56d",
  vodafoneGrey: "#4a4d4e",
  lemonYellow: "#fecb00",
  freshOrange: "#eb9700",
  springGreen: "#a8b400",
  turquoiseBlue: "#00b0ca",
  turquoise: "#1074ff" /*blue*/,
  cyanBlue: "#e9f5ff",
  redViolet: "#9c2aa0",
  aubergine: "#5e2750",
  darkGrey: "#333333",
  anthracite: "#666666",
  midGrey: "#999999",
  platinum: "#afafaf",
  silver: "#cccccc",
  aluminium: "#ebebeb",
  lightGrey: "#f4f4f4",
  white: "#ffffff",
  digitalGreen: "#009900",
  digitalRed: "#ff0000",
  digitalDarkRed: "#bd0000",
  digitalMaroon: "#990000",
  veryLightPink: "#f0f0f0",
  whiteTwo: "#fafafa",
  "true-green-10": "#00990019",
  veryLightPinkTwo: "#fce5e5",
  "white-50": "#f4f4f480",
  "digital-green": "#428600",
};

const textStyles = {
  blackLeftH1: css`
    font-family: Vodafone Lt;
    font-size: 42px;
    color: ${safaricomPalette.darkGrey};
  `,
  platinumLeftH1: css`
    font-family: Vodafone Lt;
    font-size: 42px;
    color: ${safaricomPalette.platinum};
  `,
  blackLeft24px: css`
    font-family: Vodafone Lt;
    font-size: 24px;
    color: ${safaricomPalette.darkGrey};
  `,
  blackBoldLeft22px: css`
    font-family: Vodafone Rg;
    font-size: 22px;
    font-weight: bold;
    color: ${safaricomPalette.darkGrey};
  `,
  greenBoldLeft22px: css`
    font-family: Vodafone Rg;
    font-size: 22px;
    font-weight: bold;
    color: ${safaricomPalette.digitalGreen};
  `,
  blackLeft22px: css`
    font-family: Vodafone Rg;
    font-size: 22px;
    color: ${safaricomPalette.darkGrey};
  `,
  errorRedLeft22px: css`
    font-family: Vodafone Rg;
    font-size: 22px;
    color: ${safaricomPalette.digitalRed};
  `,
  greenLeft22px: css`
    font-family: Vodafone Rg;
    font-size: 22px;
    color: ${safaricomPalette.digitalGreen};
  `,
  orangeLeft22px: css`
    font-family: Vodafone Rg;
    font-size: 22px;
    color: ${safaricomPalette.freshOrange};
  `,
  platinumLeft22px: css`
    font-family: Vodafone Rg;
    font-size: 22px;
    color: ${safaricomPalette.platinum};
  `,
  silverSymbolOrganizer: css`
    font-family: SFProText;
    font-size: 20px;
    font-weight: bold;
    text-align: right;
    color: ${safaricomPalette.silver};
  `,
  whiteSymbolOrganizer: css`
    font-family: SFProText;
    font-size: 20px;
    font-weight: bold;
    text-align: right;
    color: ${safaricomPalette.white};
  `,
  blackBoldLeft18px: css`
    font-family: Vodafone Rg;
    font-size: 18px;
    font-weight: bold;
    color: ${safaricomPalette.darkGrey};
  `,
  platinumBoldLeft18px: css`
    font-family: Vodafone Rg;
    font-size: 18px;
    font-weight: bold;
    color: ${safaricomPalette.platinum};
  `,
  redBoldLeft18px: css`
    font-family: Vodafone Rg;
    font-size: 18px;
    font-weight: bold;
    color: ${safaricomPalette.vodafoneRed};
  `,
  blackCenter18px: css`
    font-family: Vodafone Rg;
    font-size: 18px;
    text-align: center;
    color: ${safaricomPalette.darkGrey};
  `,
  blackLeft18px: css`
    font-family: Vodafone Rg;
    font-size: 18px;
    color: ${safaricomPalette.darkGrey};
  `,
  blackRight18px: css`
    font-family: Vodafone Rg;
    font-size: 18px;
    text-align: right;
    color: ${safaricomPalette.darkGrey};
  `,
  blueLeft18px: css`
    font-family: Vodafone Rg;
    font-size: 18px;
    color: ${safaricomPalette.turquoiseBlue};
  `,
  errorRedLeft18px: css`
    font-family: Vodafone Rg;
    font-size: 18px;
    color: ${safaricomPalette.digitalRed};
  `,
  greenLeft18px: css`
    font-family: Vodafone Rg;
    font-size: 18px;
    color: ${safaricomPalette.digitalGreen};
  `,
  midGreyLeft18px: css`
    font-family: Vodafone Rg;
    font-size: 18px;
    color: ${safaricomPalette.midGrey};
  `,
  platinumCenter18px: css`
    font-family: Vodafone Rg;
    font-size: 18px;
    text-align: center;
    color: ${safaricomPalette.platinum};
  `,
  platinumLeft18px: css`
    font-family: Vodafone Rg;
    font-size: 18px;
    color: ${safaricomPalette.platinum};
  `,
  platinumRight18px: css`
    font-family: Vodafone Rg;
    font-size: 18px;
    text-align: right;
    color: ${safaricomPalette.platinum};
  `,
  purpleLeft18px: css`
    font-family: Vodafone Rg;
    font-size: 18px;
    color: ${safaricomPalette.redViolet};
  `,
  redLeft18px: css`
    font-family: Vodafone Rg;
    font-size: 18px;
    color: ${safaricomPalette.vodafoneRed};
  `,
  whiteCenter18px: css`
    font-family: Vodafone Rg;
    font-size: 18px;
    text-align: center;
    color: ${safaricomPalette.white};
  `,
  whiteLeft18px: css`
    font-family: Vodafone Rg;
    font-size: 18px;
    color: ${safaricomPalette.white};
  `,
  blackBoldLeft16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    font-weight: bold;
    color: ${safaricomPalette.darkGrey};
  `,
  midGreyBoldCenter16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: ${safaricomPalette.midGrey};
  `,
  midGreyBoldLeft16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    font-weight: bold;
    color: ${safaricomPalette.midGrey};
  `,
  midgreyCenter16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    line-height: 1.31;
    text-align: center;
    color: ${safaricomPalette.midGrey};
  `,
  platinumBoldLeft16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    font-weight: bold;
    color: ${safaricomPalette.platinum};
  `,
  platinumBoldRight16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    font-weight: bold;
    text-align: right;
    color: ${safaricomPalette.platinum};
  `,
  redBoldLeft16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    font-weight: bold;
    color: ${safaricomPalette.digitalRed};
  `,
  successBoldLeft16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    font-weight: bold;
    color: ${safaricomPalette.digitalGreen};
  `,
  blackCenter16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    text-align: center;
    color: ${safaricomPalette.darkGrey};
  `,
  blackLeft16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    color: ${safaricomPalette.darkGrey};
  `,
  blackRight16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    text-align: right;
    color: ${safaricomPalette.darkGrey};
  `,
  blueLeft16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    color: ${safaricomPalette.turquoiseBlue};
  `,
  buleRight16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    text-align: right;
    color: ${safaricomPalette.turquoiseBlue};
  `,
  darkerOrangeLeft16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    color: ${safaricomPalette.warningYellow};
  `,
  errorRedCenter16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    text-align: center;
    color: ${safaricomPalette.digitalRed};
  `,
  errorRedLeft16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    color: ${safaricomPalette.digitalRed};
  `,
  greenRight16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    text-align: right;
    color: ${safaricomPalette.digitalGreen};
  `,
  midGreyLeft16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    color: ${safaricomPalette.midGrey};
  `,
  orangeLeft16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    color: ${safaricomPalette.freshOrange};
  `,
  platinumCenter16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    text-align: center;
    color: ${safaricomPalette.platinum};
  `,
  platinumLeft16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    color: ${safaricomPalette.platinum};
  `,
  platinumRight16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    text-align: right;
    color: ${safaricomPalette.platinum};
  `,
  purpleLeft16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    color: ${safaricomPalette.redViolet};
  `,
  purpleRight16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    text-align: right;
    color: ${safaricomPalette.redViolet};
  `,
  redCenter16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    text-align: center;
    color: ${safaricomPalette.vodafoneRed};
  `,
  redErrorRight16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    text-align: right;
    color: ${safaricomPalette.digitalRed};
  `,
  redLeft16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    color: ${safaricomPalette.vodafoneRed};
  `,
  successGreenLeft16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    color: ${safaricomPalette.digitalGreen};
  `,
  whiteCenter16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    text-align: center;
    color: ${safaricomPalette.white};
  `,
  whiteLeft16px: css`
    font-family: Vodafone Rg;
    font-size: 16px;
    color: ${safaricomPalette.white};
  `,
  midGreyLeftLabel: css`
    font-family: Vodafone Rg;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: ${safaricomPalette.midGrey};
  `,
  platinumLeftLabel: css`
    font-family: Vodafone Rg;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: ${safaricomPalette.platinum};
  `,
  black3Left14px: css`
    font-family: Vodafone Rg;
    font-size: 14px;
    color: ${safaricomPalette.darkGrey};
  `,
  black3Right14px: css`
    font-family: Vodafone Rg;
    font-size: 14px;
    text-align: right;
    color: ${safaricomPalette.darkGrey};
  `,
  blackCenter14px: css`
    font-family: Vodafone Rg;
    font-size: 14px;
    text-align: center;
    color: ${safaricomPalette.darkGrey};
  `,
  blueLeft14px: css`
    font-family: Vodafone Rg;
    font-size: 14px;
    color: ${safaricomPalette.turquoiseBlue};
  `,
  darkerOrangeLeft14px: css`
    font-family: Vodafone Rg;
    font-size: 14px;
    color: #eb5500;
  `,
  errorRedCenter14px: css`
    font-family: Vodafone Rg;
    font-size: 14px;
    text-align: center;
    color: ${safaricomPalette.digitalRed};
  `,
  grey2Right14px: css`
    font-family: Vodafone Rg;
    font-size: 14px;
    text-align: right;
    color: ${safaricomPalette.platinum};
  `,
  greyCenter14px: css`
    font-family: Vodafone Rg;
    font-size: 14px;
    text-align: center;
    color: #a1a1a1;
  `,
  midGreyLeft14px: css`
    font-family: Vodafone Rg;
    font-size: 14px;
    color: ${safaricomPalette.midGrey};
  `,

  platinumLeft14px: css`
    font-family: Vodafone Rg;
    font-size: 14px;
    color: ${safaricomPalette.platinum};
  `,
  purpleLeft14px: css`
    font-family: Vodafone Rg;
    font-size: 14px;
    color: ${safaricomPalette.redViolet};
  `,
  redErrorLeft14px: css`
    font-family: Vodafone Rg;
    font-size: 14px;
    color: ${safaricomPalette.digitalRed};
  `,
  redErrorRight14px: css`
    font-family: Vodafone Rg;
    font-size: 14px;
    text-align: right;
    color: ${safaricomPalette.digitalRed};
  `,
  redLeft14px: css`
    font-family: Vodafone Rg;
    font-size: 14px;
    color: ${safaricomPalette.vodafoneRed};
  `,
  successGreenLeft14px: css`
    font-family: Vodafone Rg;
    font-size: 14px;
    color: ${safaricomPalette.digitalGreen};
  `,
  whiteCenter14px: css`
    font-family: Vodafone Rg;
    font-size: 14px;
    text-align: center;
    color: ${safaricomPalette.white};
  `,
  whiteLeft14px: css`
    font-family: Vodafone Rg;
    font-size: 14px;
    color: ${safaricomPalette.white};
  `,
  yellowLeft14px: css`
    font-family: Vodafone Rg;
    font-size: 14px;
    color: ${safaricomPalette.freshOrange};
  `,
  greyLeft12px: css`
    font-family: Vodafone Rg;
    font-size: 12px;
    color: ${safaricomPalette.midGrey};
  `,
  redErrorLeft12px: css`
    font-family: Vodafone Rg;
    font-size: 12px;
    color: ${safaricomPalette.digitalRed};
  `,
  whiteCenter12px: css`
    font-family: Vodafone Rg;
    font-size: 12px;
    text-align: center;
    color: ${safaricomPalette.white};
  `,
};

export const SafaricomTheme = {
  palette: safaricomPalette,
  textStyles: textStyles,
};
