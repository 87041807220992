import { PrimaryButton, SecondaryPageTitle } from "@wit/mpesa-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { RoutesEnum } from "../../../../routes/routes.constants";
import { PageContainer } from "../../../../shared/shared.styled";
import { ServiceManagerTabNumber } from "../../pages/safaricom-service-manager.page";
import { AllowedScope, IAllowedScope } from "../allowed-scopes.model";
import AllowedScopeForm from "../components/allowed-scope-form.component";

/**
 * AddAllowedScopePage component
 */
const AddAllowedScopePage = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const [allowedScope] = React.useState<IAllowedScope>(new AllowedScope());
  const [errorIds] = React.useState<string[]>([]);

  /**
   * function go back
   */
  const goBack = () => {
    history.push(`${RoutesEnum.SERVICE_MANAGER}?tabIdx=${ServiceManagerTabNumber.SSO}`);
  };

  /**
   * function on submit form
   */
  const onSubmit = (values: IAllowedScope) => {
    //TODO: Submit request
  };

  return (
    <PageContainer>
      <SecondaryPageTitle
        displayInitialsCircle={false}
        title={t("pages.serviceManager.allowedScopes.form.addNewSSO")}
        goBackFn={() => goBack()}
      />
      <SubmitButtonContainer>
        <PrimaryButton
          titleLabel={t("pages.serviceManager.allowedScopes.form.saveChanges")}
          redTheme={true}
          type="submit"
          form={`category-form`}
        />
      </SubmitButtonContainer>
      <PageContent>
        <AllowedScopeForm
          initialValues={allowedScope}
          isEditing={true}
          onSubmitFn={onSubmit}
          errorIds={errorIds}
        ></AllowedScopeForm>
      </PageContent>
    </PageContainer>
  );
};

export default AddAllowedScopePage;

const PageContent = styled("div")`
  display: flex;
  flex-direction: row;
  margin-top: calc(128px + 28px - 50px);
  margin-left: calc(200px + 20px + 24px - 48px);
`;

const SubmitButtonContainer = styled("div")`
  position: fixed;
  right: 60px;
  top: 46px;
  z-index: 1;
`;
