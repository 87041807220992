import React from "react";
import styled from "styled-components";
import AudienceDetails from "./audience-details.component";
import CampaignBasicDetails from "./campaign-basic-details.component";
import { INotification } from "../push-notifications.modal";

interface IProps {
  notificationValues: INotification;
  onChangeValues: (value: INotification) => void;
  isEditing?: boolean;
  isDetailsPage?: boolean;
}

/** */
const NotificationsCampaignDetails = ({ notificationValues, isEditing, isDetailsPage, onChangeValues }: IProps) => {
  return (
    <Container>
      <CampaignBasicDetails
        values={notificationValues}
        notificationValues={notificationValues}
        isEditing={!!isEditing}
        onChangeValues={onChangeValues}
        isDetailsPage={!!isDetailsPage}
      />
      <AudienceDetails
        notificationValues={notificationValues}
        isEditing={isEditing}
        isDetailsPage={!!isDetailsPage}
        onChangeValues={onChangeValues}
      />
    </Container>
  );
};

export default NotificationsCampaignDetails;

const Container = styled.section`
  width: 100%;
  padding-bottom: 150px;
`;
