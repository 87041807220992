import React from "react";

const BackendIcon = (props: React.HTMLProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m13.5 5-2.969 14M15 9l5.541 3L15 15M9.041 9 3.5 12l5.541 3"
        stroke="#007C92"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BackendIcon;
