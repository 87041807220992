import {
  CancelIcon,
  DownloadCsvIcon,
  ModalTypeEnum,
  PlusIcon,
  PrimaryButton,
  TextInput,
  TrashIcon,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikHelpers, FormikState } from "formik";
import i18next from "i18next";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled, { ThemeContext } from "styled-components";
import { object, string } from "yup";
import { IconContainer, Row } from "../../../../shared/shared.styled";
import { formatBytes } from "../../../../shared/shared.utils";
import SurveysApi, { ISurvey } from "../surveys.api";
import { SurveysActions } from "../surveys.store";
import { SurveysUtils } from "../surveys.utils";
import PublishSurveyModal from "./modals/publish-survey.modal";

/**
 * validation schema for survey
 */
export const getValidationSchema = () => {
  return object().shape({
    name: string().required(i18next.t("pages.tools.surveys.createNewSurvey.requiredField")),
    sms: string()
      .required(i18next.t("pages.tools.surveys.createNewSurvey.requiredField"))
      .max(160, i18next.t("pages.tools.surveys.createNewSurvey.maxLen")),
  });
};

/**
 * Create new survey component
 */
const CreateNewSurvey = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  /*Alert hooks*/
  const [showAlert, , setAlertProps] = useAlert();

  /*Csv file*/
  const [csvFile, setFile] = React.useState<any>();

  /*File string error*/
  const [fileError, setFileError] = React.useState("");

  /*Control var to check if the survey will be published after creation*/
  const [willPublish, setWillPublish] = React.useState(false);
  const MAX_FILE_SIZE = 1000000;

  /*Selected survey*/
  const [selectedSurvey, setSelectedSurvey] = React.useState<ISurvey | undefined>(undefined);

  /**
   * function to publish modal
   */
  const _hidePublishSurveyModal = () => {
    hidePublishSurveyModal();
  };
  const [showPublishSurveyModal, hidePublishSurveyModal] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <PublishSurveyModal survey={selectedSurvey as ISurvey} hideModal={_hidePublishSurveyModal} />,
  );

  /**
   * function to handle the form submit
   * @param values
   * @param actions
   */
  const createNewSurvey = (values: any, actions: FormikHelpers<any>) => {
    if (!isFileValid()) {
      actions.setSubmitting(false);
      return;
    }

    SurveysApi.methods
      .createSurvey(values, csvFile)
      .finally(() => actions.setSubmitting(false))
      .then(
        surveyResponse => {
          setAlertProps({
            title: t("pages.tools.surveys.createNewSurvey.success"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          clearData(actions.resetForm);
          SurveysApi.methods.getSurveys().then(res => {
            dispatch(SurveysActions.creators.fetchSurveysSuccessAction(res.data));
            if (willPublish) {
              const survey = res.data[res.data.findIndex(s => s.id === surveyResponse.data)];
              publishSurveyModal(survey);
            }
          });
        },
        () => {
          setAlertProps({
            title: t("pages.tools.surveys.createNewSurvey.error"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
  };

  /**
   * function to trigger publish survey modal
   * @param survey
   */
  const publishSurveyModal = (survey: ISurvey) => {
    setSelectedSurvey(survey);
    showPublishSurveyModal();
  };

  /**
   * funtion to clear data action
   * @param resetForm
   */
  const clearData = (resetForm: {
    (nextState?: Partial<FormikState<any>> | undefined): void;
    (nextState?: Partial<FormikState<{ name: string; sms: string }>> | undefined): void;
    (): void;
  }) => {
    setWillPublish(false);
    setFileError("");
    setFile(null);
    resetForm();
  };

  /**
   * Max file size error message
   */
  const setMaxSizeFileError = () => {
    setFileError(t("pages.tools.surveys.createNewSurvey.maxSize"));
  };

  /**
   * File required error message
   */
  const setFileRequiredError = () => {
    setFileError(t("pages.tools.surveys.createNewSurvey.requiredFile"));
  };

  /**
   * function to Verify if the file is valid
   * @returns a boolean
   */
  const isFileValid = () => {
    if (!csvFile) {
      setFileRequiredError();
      return false;
    } else if (csvFile.size > MAX_FILE_SIZE) {
      setMaxSizeFileError();
      return false;
    }
    setFileError("");
    return true;
  };

  /**
   * function to remove the file
   */
  const removeFile = () => {
    setFileError(t("pages.tools.surveys.createNewSurvey.requiredFile"));
    setFile(null);
  };

  /**
   * functio to publish action
   */
  const publish = () => {
    if (isFileValid()) {
      setWillPublish(true);
    }
  };

  /*
   * Component render
   * */
  return (
    <CreateNewSurveyContainer>
      <CreateNewSurveyTitle>{t("pages.tools.surveys.createNewSurvey.title")}</CreateNewSurveyTitle>
      <Formik
        onSubmit={createNewSurvey}
        initialValues={{ name: "", sms: "" }}
        validationSchema={getValidationSchema()}
        render={({ handleChange, values, handleSubmit, errors, isSubmitting, resetForm }) => (
          <form onSubmit={handleSubmit}>
            <FieldRow>
              <FieldSection>
                <TextInput
                  title={t("pages.tools.surveys.createNewSurvey.surveyName.label")}
                  placeholder={t("pages.tools.surveys.createNewSurvey.surveyName.placeholder")}
                  required
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  error={errors.name}
                  autoComplete="off"
                />
              </FieldSection>
              <FieldSection>
                <TextInput
                  title={t("pages.tools.surveys.createNewSurvey.surveySms.label")}
                  placeholder={t("pages.tools.surveys.createNewSurvey.surveySms.placeholder")}
                  required
                  name="sms"
                  onChange={handleChange}
                  value={values.sms}
                  error={errors.sms}
                  autoComplete="off"
                />
              </FieldSection>
            </FieldRow>
            <FieldRow style={{ marginTop: "24px" }}>
              <FieldSection>
                <FileInputContainer>
                  <FileInputTitle>
                    {t("pages.tools.surveys.createNewSurvey.csvFileUpload")}
                    <span>*</span>

                    {fileError ? <FormError>{fileError}</FormError> : null}
                  </FileInputTitle>
                  {!csvFile ? (
                    <TextButton style={{ marginRight: "auto" }}>
                      <IconContainer style={{ marginRight: "5px" }} size={16} color={themeContext.palette.vodafoneRed}>
                        <PlusIcon />
                      </IconContainer>
                      {t("pages.tools.surveys.createNewSurvey.uploadNewCsvFile")}
                      <input
                        type="file"
                        onChange={event => {
                          SurveysUtils.handleFileChange(
                            event,
                            setMaxSizeFileError,
                            setFileError,
                            setFile,
                            MAX_FILE_SIZE,
                          );
                        }}
                        accept=".csv"
                      />
                    </TextButton>
                  ) : null}
                  {csvFile ? (
                    <FileNameContainer>
                      <IconContainer size={16} color={themeContext.palette.vodafoneRed}>
                        <DownloadCsvIcon />
                      </IconContainer>
                      <FileNameText>{csvFile.name}</FileNameText>
                      <FileSizeText>{formatBytes(csvFile.size)}</FileSizeText>
                      <IconContainer size={16} color={themeContext.palette.errorRed}>
                        <TrashIcon onClick={removeFile} style={{ marginLeft: "5px", cursor: "pointer" }} />
                      </IconContainer>
                    </FileNameContainer>
                  ) : null}
                </FileInputContainer>
              </FieldSection>
              <TextButton
                onClick={() => {
                  clearData(resetForm);
                }}
                style={{
                  width: "fit-content",
                  paddingTop: "10px",
                  marginRight: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconContainer
                  style={{
                    marginRight: "5px",
                  }}
                  size={16}
                  color={themeContext.palette.errorRed}
                >
                  <CancelIcon />
                </IconContainer>
                {t("pages.tools.surveys.createNewSurvey.clearData")}
              </TextButton>
              <ButtonContainer
                style={{
                  marginRight: "13px",
                  position: "relative",
                  top: "10px",
                }}
              >
                <PrimaryButton
                  disabled={isSubmitting}
                  titleLabel={t("pages.tools.surveys.createNewSurvey.save")}
                  type="submit"
                  onClick={isFileValid}
                  style={{ width: "fit-content" }}
                />
              </ButtonContainer>
              <ButtonContainer style={{ position: "relative", top: "10px" }}>
                <PrimaryButton
                  disabled={isSubmitting}
                  titleLabel={t("pages.tools.surveys.createNewSurvey.publish")}
                  onClick={publish}
                  type="submit"
                  redTheme={true}
                  style={{ width: "fit-content" }}
                />
              </ButtonContainer>
            </FieldRow>
          </form>
        )}
      />
    </CreateNewSurveyContainer>
  );
};

export default CreateNewSurvey;

const FieldRow = styled(Row)`
  align-items: flex-end;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 16px;
  }
`;

const CreateNewSurveyContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 31px 47px;
  margin: 20px -47px 0px -47px;
  background-color: ${props => props.theme.palette.greyLight};
`;

const CreateNewSurveyTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.4px;
  color: ${props => props.theme.palette.midGrey};
  text-transform: uppercase;
  margin-bottom: 21px;
`;

const FieldSection = styled("div")`
  width: 40%;
  &:first-of-type {
    margin-right: 48px;
  }

  @media (max-width: 1024px) {
    &:first-of-type {
      margin-right: 24px;
    }
  }

  @media (max-width: 768px) {
    &:first-of-type {
      margin-right: 0;
    }
    width: 100%;
  }
`;

const ButtonContainer = styled("div")`
  width: fit-content;
`;

const TextButton = styled("label")`
  display: flex;
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.darkGrey};
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
  > input {
    display: none;
  }
`;

const FileInputContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;

const FileInputTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
  margin-bottom: 15px;
  > span {
    font-weight: normal;
    margin-left: 1px;
    color: ${props => props.theme.palette.errorRed};
  }
`;

const FileNameContainer = styled("div")`
  display: flex;
`;

const FileSizeText = styled("span")`
  color: ${props => props.theme.palette.grey};
  font-family: Vodafone Rg;
  font-size: 16px;
  margin-left: 3px;
`;

const FileNameText = styled("span")`
  color: ${props => props.theme.palette.grey};
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.greyDarkest};
  margin-left: 8px;
`;

const FormError = styled("span")`
  height: 19px;
  color: ${props => props.theme.palette.errorRed};
  font-family: Vodafone Rg;
  font-size: 14px;
  text-align: right;
  float: right;
  font-weight: 400;
`;
