import React from "react";
import { PageContainer, PageTitle, ListHeader, ListHeaders, LoadingText } from "../../../shared/shared.styled";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IStoreInterface } from "../../../configs/store.config";
import { AppsActions } from "../apps.store";
import AppsApi from "../apps.api";
import AppGridItem from "../components/app-grid-item.component";
import styled from "styled-components";
import AppsFilters from "../components/apps-filters.component";
import AppListItem from "../components/app-list-item.component";
import { ILoggedUser } from "../../authentication/authentication.interfaces";
import { UserRolesDefault, UserRolesVDF } from "../../admin/users/users.interfaces";
import { useAlert, useFilters } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { filterApps } from "../apps.utils";
import DisableAppsComponent from "../components/disable-apps.component";
import { IAppsStatus } from "../apps.interfaces";
import { AxiosResponse } from "axios";
import { FEATURES } from "../../../shared/renderer.utils";
import { SelectedView } from "../../../shared/shared.enums";
import EmptySearchResult from "../../../shared/components/empty-search-result.component";
import useCheckFeatureAvailable from "../../../shared/hooks/use-check-available-feature";

/**
 * AppsPage component
 */
const AppsPage = () => {
  // Hooks initializations
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [showAlert, , setAlertProps] = useAlert();
  const {
    filters,
    updateFilter,
    isFilterActive,
    clearFilter,
    resetFilters,
    getFilterValue,
    getFiltersQueryString,
    updateMultipleFilters,
  } = useFilters();

  // Redux states
  const { apps, isLoadingApps } = useSelector((state: IStoreInterface) => state.appsReducer);
  const { role } = useSelector((state: IStoreInterface) => state.authenticationReducer.loggedUser) as ILoggedUser;

  // Local states
  const [selectedView, setSelectedView] = React.useState<SelectedView>(SelectedView.GRID);
  const [showFastActions, setShowFastActions] = React.useState(false);
  const [showableApps, setShowableApps] = React.useState(apps);
  const [isAppsEnable, setIsAppsEnable] = React.useState<boolean | undefined>(undefined);
  const [disabledReason, setDisabledReason] = React.useState("reason");
  const disableMobileAppsEnabled = useCheckFeatureAvailable(FEATURES.DISABLE_MOBILE_APPS);

  // Effects
  React.useEffect(() => {
    setShowableApps(apps);
  }, [apps]);

  React.useEffect(() => {
    dispatch(AppsActions.creators.fetchingAppsAction());
    AppsApi.methods.getApps().then(
      res => {
        dispatch(AppsActions.creators.fetchAppsSuccessAction(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.apps.errors.searchApps"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  }, [dispatch, t, showAlert, setAlertProps]);

  React.useEffect(() => {
    if (
      role === UserRolesDefault.ADMIN ||
      role === UserRolesVDF.ADMIN_SERVICE_MANAGEMENT ||
      role === UserRolesDefault.APPROVER ||
      role === UserRolesVDF.APPROVER_SERVICE_MANAGEMENT
    ) {
      setShowFastActions(true);
    } else {
      setShowFastActions(false);
    }
  }, [role]);

  React.useEffect(() => {
    if (filters.size > 0) {
      setShowableApps(filterApps(apps, filters));
    } else {
      setShowableApps(apps);
    }
  }, [filters, apps]);

  React.useEffect(() => {
    AppsApi.methods.getMpesaAppsStatus().then(
      (res: AxiosResponse<IAppsStatus>) => {
        setIsAppsEnable(res.data.status);
        if (res.data.reason) {
          setDisabledReason(res.data.reason);
        }
      },
      () => {
        setIsAppsEnable(undefined);
      },
    );
  }, []);

  /**
   * method to render the app in grid view
   */
  const renderGridView = () => {
    return (
      <>
        <AppGridItemsContainer>
          {showableApps.map(app => (
            <AppGridItem showFastActions={showFastActions} key={app.id} item={app} isEnable={isAppsEnable} />
          ))}
        </AppGridItemsContainer>
        {renderDisableAppsView()}
      </>
    );
  };

  /**
   * method to render the app in list view
   */
  const renderListView = () => {
    return (
      <>
        <AppListItemsContainer>
          <ListHeaders>
            <ListHeader ratio={4 / 12} style={{ marginRight: 24 }}>
              {t("pages.apps.listHeaders.appName")}
            </ListHeader>
            <ListHeader ratio={2 / 12}>{t("pages.apps.listHeaders.lastUpdated")}</ListHeader>
            <ListHeader ratio={3 / 12}>{t("pages.apps.listHeaders.platform")}</ListHeader>
          </ListHeaders>
          {showableApps.map(app => (
            <AppListItem showFastActions={false} key={app.id} item={app} />
          ))}
        </AppListItemsContainer>
        {renderDisableAppsView()}
      </>
    );
  };

  /**
   * method to render the enable/disable apps form
   */
  const renderDisableAppsView = () => {
    if (disableMobileAppsEnabled) {
      return (
        <DisableAppsComponent
          isAppsEnable={isAppsEnable}
          reason={disabledReason}
          setIsAppsEnable={setIsAppsEnable}
          setDisabledReason={setDisabledReason}
        ></DisableAppsComponent>
      );
    } else {
      return <></>;
    }
  };

  return (
    <PageContainer>
      <PageTitle>{t("pages.apps.title")}</PageTitle>
      {isLoadingApps ? (
        <LoadingText>{t("commons.loadingResults")}</LoadingText>
      ) : (
        <>
          <AppsFilters
            filters={filters}
            isFilterActive={isFilterActive}
            updateFilter={updateFilter}
            clearFilter={clearFilter}
            resetFilters={resetFilters}
            getFilterValue={getFilterValue}
            getFiltersQueryString={getFiltersQueryString}
            updateMultipleFilters={updateMultipleFilters}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
          />
          {showableApps.length === 0 ? (
            <EmptySearchResult />
          ) : selectedView === SelectedView.GRID ? (
            renderGridView()
          ) : (
            renderListView()
          )}
        </>
      )}
    </PageContainer>
  );
};

export default AppsPage;

const AppGridItemsContainer = styled("div")`
  margin-top: 49px;
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, minmax(272px, 0));
  column-gap: 24px;
  row-gap: 24px;
  margin-bottom: 32px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(calc(50% - 12px), 0)) !important;
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fit, minmax(100%, 0)) !important;
  }
`;

const AppListItemsContainer = styled("ul")`
  margin-left: 0;
  padding-left: 0;
`;
