import React from "react";
import { IReferralCampaign, ReferralCampaignStatus, TypeScreenEnum, Screen } from "../../referral-campaigns.model";
import { Formik, FormikHelpers } from "formik";
import styled from "styled-components";
import ReceiverScreenForm from "../../components/receiver-screen-form.component";
import ReceiverScreenPreview from "../../components/receiver-screen-preview.component";
import QuickActionsMenu from "./quick-actions-menu.component";
import { validateSchemaReceiverScreen, validateTranslationsReceiverScreen } from "../../referral-campaigns.utils";
import GenericLanguageSelector from "../../../../../shared/components/generic-language-selector.component";
import { ILanguage } from "../../../../../shared/models/language.model";
import useEmptyLanguageWarningModal from "../../../../../shared/hooks/use-empty-language-warning-modal.hook";

interface IEditReceiverScreenProps {
  languages: ILanguage[];
  selectedLanguage: string;
  setSelectedLanguage: React.Dispatch<React.SetStateAction<string>>;
  referralCampaign: IReferralCampaign;
  onSubmitFn: (values: IReferralCampaign) => void;
  onCancelCampaignFn: () => void;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * EditReceiverScreen component
 */
const EditReceiverScreen = ({
  languages,
  selectedLanguage,
  setSelectedLanguage,
  referralCampaign,
  onSubmitFn,
  onCancelCampaignFn,
  isEditing,
  setIsEditing,
}: IEditReceiverScreenProps) => {
  const { warningModalOpen, showWarning, setShowWarning } = useEmptyLanguageWarningModal(languages || []);
  /**
   * Submit method wrapper to show warning if needed
   * @param values
   */
  const submitWrapper = (values: IReferralCampaign) => {
    if (showWarning) {
      setShowWarning(false);
      warningModalOpen(handleSubmitSenderScreen, values);
    } else {
      handleSubmitSenderScreen(values);
    }
  };

  /**
   * get initial values
   * this ones could be different depending on last form
   */
  const getInitialValues = () => {
    //if screen were not initialized yet
    if (!referralCampaign.screens) {
      referralCampaign.screens = [new Screen(TypeScreenEnum.RECEIVER, languages)];
    } else if (!referralCampaign.screens.find(screen => screen.type === TypeScreenEnum.RECEIVER)) {
      referralCampaign.screens = [...referralCampaign.screens, new Screen(TypeScreenEnum.RECEIVER, languages)];
    }
    return referralCampaign;
  };

  /**
   * Performs the create request
   * @param {IReferralCampaign} values
   * @param {FormikHelpers<IReferralCampaign>} actions
   */
  const handleSubmitSenderScreen = (values: IReferralCampaign) => {
    onSubmitFn(values);
    setIsEditing(false);
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={submitWrapper}
      validate={values => validateSchemaReceiverScreen(values, languages, setShowWarning)}
      render={({ values, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue, errors, resetForm }) => (
        <form onSubmit={handleSubmit}>
          <ReceiverScreenContainer>
            <FormContainer>
              <ReceiverScreenForm
                title={
                  <div style={{ marginTop: "auto", marginLeft: "auto" }}>
                    <GenericLanguageSelector
                      selectedLanguage={selectedLanguage}
                      availableLanguages={languages.map(lang => lang.code)}
                      changeSelectedLanguage={(lang: any) => setSelectedLanguage(lang.key)}
                    ></GenericLanguageSelector>
                  </div>
                }
                isEditing={isEditing}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                language={selectedLanguage}
                isLanguageRequired={languages.find(l => l.code === selectedLanguage)?.mandatory}
              ></ReceiverScreenForm>
            </FormContainer>
            <ReceiverScreenPreviewContainer>
              <ReceiverScreenPreview values={values} language={selectedLanguage}></ReceiverScreenPreview>
            </ReceiverScreenPreviewContainer>
            {referralCampaign.status && referralCampaign.status !== ReferralCampaignStatus.DISABLED ? (
              <QuickActionsMenu
                canEdit={true}
                isEditing={isEditing}
                doneFn={() => {
                  handleSubmit();
                  validateTranslationsReceiverScreen(values, languages, setSelectedLanguage, setShowWarning);
                }}
                cancelFn={() => {
                  resetForm();
                  setIsEditing(false);
                }}
                canCancel={referralCampaign.status !== ReferralCampaignStatus.SCHEDULED}
                editCampaignFn={() => setIsEditing(true)}
                cancelCampaignFn={onCancelCampaignFn}
              ></QuickActionsMenu>
            ) : (
              <EmptySpace />
            )}
          </ReceiverScreenContainer>
        </form>
      )}
    />
  );
};

export default EditReceiverScreen;

const ReceiverScreenContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  > div > div:first-child {
    > div {
      justify-content: flex-end;
    }
  }
`;

const FormContainer = styled("div")`
  flex: 2;
`;

const ReceiverScreenPreviewContainer = styled("div")`
  flex: 1;
  margin-left: 30px;
`;

const EmptySpace = styled("div")`
  flex: 1;
`;
