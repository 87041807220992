import React from "react";
import { useTranslation } from "react-i18next";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import styled from "styled-components";
import { EditIcon } from "@wit/mpesa-ui-components";
import { ISectionDetailsComponentProps } from "../feedback-and-problems.model";
import { feedbacksStringPath, problemsStringPath } from "../feedback-and-problems.utils";
import { useFeedbackAndProblemsDetailsActiveCategories } from "../hooks/use--feedback-and-problems-details-active-categories.hook";

const SectionDetailsComponent = ({ sectionId, toggleSideDrawer }: ISectionDetailsComponentProps) => {
  const [t] = useTranslation();
  const activeCategories = useFeedbackAndProblemsDetailsActiveCategories(sectionId);

  return (
    <SectionDetails>
      <SectionDetailsContainer>
        <FirstContentRow>
          <div>
            {sectionId === 0
              ? t(`${feedbacksStringPath}.detailsArea.detailsLabel`)
              : t(`${problemsStringPath}.detailsArea.detailsLabel`)}
          </div>
        </FirstContentRow>

        <SecondContentRow>
          <LeftSideContents>
            <div className="categories-label">
              {sectionId === 0
                ? t(`${feedbacksStringPath}.detailsArea.categoriesLabel`)
                : t(`${problemsStringPath}.detailsArea.categoriesLabel`)}
            </div>
            <SectionCategories>{activeCategories.toString().replace(/,/g, ", ")}</SectionCategories>
          </LeftSideContents>

          <RightSideContents>
            <EditSectionCategoriesButton id={"edit-details"} onClick={toggleSideDrawer}>
              <SmallIconContainer color={styleTheme.palette.turquoiseBlue}>
                <EditIcon />
              </SmallIconContainer>
              {t("pages.feedbackAndProblemsReport.editDetails")}
            </EditSectionCategoriesButton>
          </RightSideContents>
        </SecondContentRow>
      </SectionDetailsContainer>
    </SectionDetails>
  );
};

export default SectionDetailsComponent;

const SectionDetailsContainer = styled("div")`
  width: calc(100% - 48px - 48px);
  position: absolute;
  height: 179px;
  padding: 30px 0 42px 0;
  display: flex;
  flex-direction: column;
`;

const LeftSideContents = styled("div")`
  overflow: hidden;
  flex: 1;
  height: auto;

  div.categories-label {
    color: ${styleTheme.palette.midGrey};
    font-weight: 700;
    margin: 0 0 15px 0;
  }
`;

const SectionCategories = styled("div")`
  color: ${styleTheme.palette.darkGrey};
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-transform: capitalize;
`;

const RightSideContents = styled("div")`
  overflow: hidden;
  text-align: right;
`;

const EditSectionCategoriesButton = styled("div")`
  color: ${styleTheme.palette.darkGrey};
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const SecondContentRow = styled("div")`
  font-size: 16px;
  line-height: 18px;
  width: 100%;
  display: flex;
`;

const SectionDetails = styled.div`
  font-family: Vodafone Rg;
  position: relative;
  background-color: ${styleTheme.palette.lightGrey};
  width: calc(100% + 48px + 48px);
  height: 179px;
  margin: 26px 0 39px -48px;
  padding: 0 0 0 48px;
`;

const FirstContentRow = styled("div")`
  color: ${styleTheme.palette.midGrey};
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin: 0 0 34px 0;
`;

const SmallIconContainer = styled.div<{ color: string }>`
  width: 16px;
  height: 16px;
  stroke: ${props => props.color};
  display: flex;
  margin-right: 8.67px;

  svg {
    width: 16px;
    height: 16px;
    stroke: ${props => props.color};
  }
`;
