import moment from "moment";
import React from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { renderDate } from "../../../../shared/shared.utils";
import { DocValidationUtils } from "../../doc-validation/doc-validation.utils";
import { IClientDocDashCounter } from "../doc-dashboard.interface";

export const DocDashboardStandardCard: FC<{ entry: IClientDocDashCounter }> = ({ entry }) => {
  const { t } = useTranslation();

  if (!entry) {
    entry = ({
      status: entry,
      total: 0,
      percentage: 0,
      lastUpdate: Date.now(),
    } as unknown) as IClientDocDashCounter;
  }

  const color = DocValidationUtils.getClientDocStatusColor(`${entry.status}`);

  // calculate color

  return (
    <>
      <DocCounterCard color={color}>
        <DocCounterStatusWrapper>
          <DocCounterStatusLabel>{t(`commons.clientDocSubmissionStatus.${entry.status}`)}</DocCounterStatusLabel>
          <DocStatusCounter>{entry.total}</DocStatusCounter>
        </DocCounterStatusWrapper>
        <DocStatusPercentage>{entry.percentage}%</DocStatusPercentage>
        <DocLastUpdateLabel>
          {t("pages.docDashboard.lastUpdate")} {renderDate({ date: moment(entry.lastUpdate) })}
        </DocLastUpdateLabel>
      </DocCounterCard>
    </>
  );
};

const DocCounterCard = styled("div")<{ color?: string; height?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 27vw;
  min-width: 351px;
  margin: 0 0 25px 0;
  padding: 20px 24px 12px;
  color: ${({ color }) => color || "inherit"};
  border-radius: 6px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
`;

const DocCounterStatusWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
  border-bottom: 1px solid ${props => props.theme.palette.aluminium};
`;

const DocCounterStatusLabel = styled("div")`
  font-family: "Vodafone Rg";
  font-size: 16px;
  line-height: 1.31;
`;

const DocStatusCounter = styled("div")`
  margin: 34px 0;
  font-family: "Vodafone Rg";
  font-size: 46px;
`;

const DocStatusPercentage = styled("div")`
  font-family: "Vodafone Rg";
  font-size: 16px;
  line-height: 1.19;
  margin-bottom
`;

const DocLastUpdateLabel = styled("div")`
  font-family: "Vodafone Rg";
  font-size: 14px;
  color: ${props => props.theme.palette.midGrey};
  line-height: 1.36;
`;
