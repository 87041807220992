import React from "react";
import styled from "styled-components";
import { Breadcrumbs } from "@wit/mpesa-ui-components";
import ServiceDetailsVersionManagement from "../service-details-version-management/service-details-version-management.component";

import { IServiceVersion } from "../../../shared/models/service-builder.model";
import { useHistory } from "react-router-dom";

export interface IServiceDetailsContainerProps {
  open: boolean;
  serviceName: string;
  serviceVersions: IServiceVersion[] | undefined;
  serviceId: string;
  refreshPage: () => void;
  pending: boolean;
  isServiceLive: boolean;
}

const ServiceDetailsContainer = ({
  open,
  serviceName,
  serviceVersions,
  serviceId,
  refreshPage,
  pending,
  isServiceLive,
}: IServiceDetailsContainerProps) => {
  const history = useHistory();
  return (
    <ServiceRightContainer open={open}>
      <BreadcrumbsWrapper>
        <Breadcrumbs
          breadcrumbs={[
            {
              label: "Services",
              url: "/service-builder",
            },
            { label: serviceName, url: "/" },
          ]}
          navigateFunction={history.push}
        />
      </BreadcrumbsWrapper>
      <ServiceDetailsVersionManagement
        serviceVersions={serviceVersions}
        serviceId={serviceId}
        serviceName={serviceName}
        refreshPage={refreshPage}
        open={open}
        pending={pending}
        isServiceLive={isServiceLive}
      />
    </ServiceRightContainer>
  );
};

export default ServiceDetailsContainer;

const ServiceRightContainer = styled("div")<{ open: boolean }>`
  padding-left: 50px;
  padding-top: 35px;
  padding-right: 82px;
  float: right;
  width: calc(100% - 390px);
  height: 100%;
  transition: width 0.25s ease-in-out;
  ${props => (!props.open ? "width: calc(100% - 96px);" : null)};
`;

const BreadcrumbsWrapper = styled("div")`
  > div > span {
    :nth-of-type(2) {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
