import { Checkbox, PrimaryButton, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { AxiosResponse } from "axios";
import fileDownload from "js-file-download";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import LanguageTranslationsApi from "../language-translations.api";
import AppsApi from "../../../apps/apps.api";
import { AppsActions } from "../../../../features/apps/apps.store";
import { IStoreInterface } from "../../../../configs/store.config";
import { IExportLanguageTranslationRequest, LanguageTranslationModalsEnum } from "../language-translations.model";
import { AppPlatformEnum, IApp } from "../../../../shared/models/app.model";
import { isOSDisabled } from "../../../../shared/shared.utils";

interface IExportLanguageTranslationsModalInterface {
  dismissModal: (modal: string) => void;
  version: string;
}

const ExportLanguageTranslationsModal = ({ dismissModal, version }: IExportLanguageTranslationsModalInterface) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [showAlert, , setAlertProps] = useAlert();
  const { apps } = useSelector((state: IStoreInterface) => state.appsReducer);
  const [appList, setAppList] = React.useState<IApp[]>(apps);
  const [exportState, setExportState] = React.useState<IExportLanguageTranslationRequest>({
    android: false,
    ios: false,
    json: false,
    excel: false,
    version: version,
  });

  /**
   * Hides modal that is visible on the screen
   * @returns function
   */
  const hideModal = () => dismissModal(LanguageTranslationModalsEnum.EXPORT);

  /**
   * Download file handler
   * @param res
   */
  const downloadHandler = (res: AxiosResponse<any, any>) => {
    const fileDownload = require("js-file-download");
    fileDownload(res.data, `version_${version}_language_translations.zip`);
  };

  /**
   * Performs the export request
   */
  const submitRequest = () => {
    LanguageTranslationsApi.methods.exportLanguageTranslation(exportState).then(
      res => {
        downloadHandler(res);
        setAlertProps({
          title: t("pages.languageTranslations.exportModal.exportSuccess"),
          type: AlertTypeEnum.SUCCESS,
        });
        showAlert();
        hideModal();
      },
      () => {
        setAlertProps({
          title: t("pages.languageTranslations.exportModal.exportError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  // Effects
  React.useEffect(() => {
    setAppList(apps);
  }, [apps]);

  React.useEffect(() => {
    dispatch(AppsActions.creators.fetchingAppsAction());
    AppsApi.methods.getApps().then(
      res => {
        dispatch(AppsActions.creators.fetchAppsSuccessAction(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.apps.errors.searchApps"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  }, []);

  return (
    <ModalContainer>
      <ModalTitle>{t("pages.languageTranslations.exportModal.title")}</ModalTitle>
      <ModalMessage>{t("pages.languageTranslations.exportModal.message")}</ModalMessage>
      <div style={{ minHeight: "107px" }}>
        {appList && !isOSDisabled(appList, AppPlatformEnum.ANDROID) ? (
          <CheckboxContainer>
            <Checkbox
              value={exportState.android}
              onValueChange={e => {
                setExportState(prev => {
                  return { ...prev, android: e };
                });
              }}
            />
            <CheckboxLabel>{t("pages.languageTranslations.exportModal.android")}</CheckboxLabel>
          </CheckboxContainer>
        ) : null}

        {appList && !isOSDisabled(appList, AppPlatformEnum.IOS) ? (
          <CheckboxContainer>
            <Checkbox
              value={exportState.ios}
              onValueChange={e => {
                setExportState(prev => {
                  return { ...prev, ios: e };
                });
              }}
            />
            <CheckboxLabel>{t("pages.languageTranslations.exportModal.ios")}</CheckboxLabel>
          </CheckboxContainer>
        ) : null}

        {appList ? (
          <React.Fragment>
            <CheckboxContainer>
              <Checkbox
                value={exportState.excel}
                onValueChange={e => {
                  setExportState(prev => {
                    return { ...prev, excel: e };
                  });
                }}
              />
              <CheckboxLabel>{t("pages.languageTranslations.exportModal.excel")}</CheckboxLabel>
            </CheckboxContainer>

            <CheckboxContainer>
              <Checkbox
                value={exportState.json}
                onValueChange={e => {
                  setExportState(prev => {
                    return { ...prev, json: e };
                  });
                }}
              />
              <CheckboxLabel>{t("pages.languageTranslations.exportModal.json")}</CheckboxLabel>
            </CheckboxContainer>
          </React.Fragment>
        ) : null}
      </div>
      <ButtonsContainer>
        <div>
          <PrimaryButton
            redTheme={false}
            id={"cancel-btn"}
            titleLabel={t("pages.languageTranslations.exportModal.cancel")}
            onClick={hideModal}
            type="button"
          />
        </div>
        <div>
          <PrimaryButton
            redTheme={true}
            id={"create-btn"}
            titleLabel={t("pages.languageTranslations.exportModal.export")}
            type="submit"
            onClick={submitRequest}
            disabled={!exportState.android && !exportState.ios && !exportState.json && !exportState.excel}
          />
        </div>
      </ButtonsContainer>
    </ModalContainer>
  );
};

const ModalContainer = styled("div")`
  border-radius: 6px;
  box-shadow: 0px 4px 4px rgba(142, 142, 142, 0.23), 0px 3px 10px rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.palette.white};
  padding: 24px;
`;

const ModalTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 13px;
`;

const ModalMessage = styled("p")`
  font-family: Vodafone Rg;
  font-size: 18px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 21px;
  margin-top: 13px;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  > div {
    :first-of-type {
      margin-right: 12px;
    }
  }
`;

const CheckboxContainer = styled("div")`
  display: flex;
  margin-bottom: 16px;
  :last-of-type {
    margin-bottom: 49px;
  }
`;

const CheckboxLabel = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
  padding-left: 8px;
`;

export default ExportLanguageTranslationsModal;
