import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import React from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { Column, RatioContainer, Row, IconContainer } from "../../../../shared/shared.styled";
import { IPushNotification } from "../push-notifications.model";
import { getStatusColor } from "../push-notifications.utils";
import PushNotificationsStatusChip from "./push-notification-status-chip.component";
import { TableButtonsContainer } from "../../../../shared/shared.utils";
import { FastActionButton, EditIcon, TrashIcon, useAlert, useModal, ModalTypeEnum } from "@wit/mpesa-ui-components";
import { useHistory } from "react-router-dom";
import { RoutesEnum } from "../../../../routes/routes.constants";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";

interface IPushNotificationListItemProps {
  item: IPushNotification;
  hasFastActions?: boolean;
  isSentOrProcessing?: boolean;
  deletePushNotification: (id: string) => void;
  onClick: () => void;
}

/**
 * Push notifications list item
 * @param param0
 */
const PushNotificationListItem = ({
  item,
  deletePushNotification,
  hasFastActions = true,
  isSentOrProcessing = false,
  onClick,
}: IPushNotificationListItemProps) => {
  const [t] = useTranslation();
  const history = useHistory();
  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );

  /**
   * Show delete confirmation
   * @param id
   */
  const showDeleteConfirmation = (id: string) => {
    setConfirmationProps({
      title: t("pages.pushNotifications.delete.deleteTitle"),
      description: t("pages.pushNotifications.delete.deleteConfirmation"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      primaryAction: () => deletePushNotification(id),
      secondaryAction: () => hideConfirmationModal(),
    } as BaseModalProps);
    showConfirmationModal();
  };

  return (
    <>
      <PushNotificationListItemContainer
        id={`push-notification-${item.title}`}
        hoverAction={hasFastActions}
        onClick={onClick}
        //clickActionAvailable={isSentOrProcessing} TODO: uncomment when completion progress was integrated
        clickActionAvailable={false} //TODO: comment when completion progress was integrated
      >
        <ItemTitle ratio={4 / 12}>{item.title}</ItemTitle>
        <ItemNormalText ratio={3 / 12} style={{ color: styleTheme.palette.midGrey, paddingRight: 20 }}>
          {item.message}
        </ItemNormalText>
        <ItemNormalText ratio={2 / 12} style={{ color: styleTheme.palette.turquoiseBlue, fontWeight: "bold" }}>
          {t(`pages.pushNotifications.userEnum.${item.users}`)}
        </ItemNormalText>
        <RatioContainer
          className="status-container"
          style={{ textAlign: "right", alignItems: "center" }}
          ratio={3 / 12}
        >
          <Column>
            <Row>
              <DateSpan style={{ color: getStatusColor(item.status) }}>{item.date}</DateSpan>
            </Row>
          </Column>
          <PushNotificationsStatusChip style={{ width: "fit-content" }} type={item.status}>
            {t(`pages.pushNotifications.statusEnum.${item.status}`)}
          </PushNotificationsStatusChip>
        </RatioContainer>
        {hasFastActions ? (
          <RatioContainer
            className="edit-remove-wrapper"
            style={{ flexDirection: "column", alignItems: "flex-end" }}
            ratio={3 / 12}
          >
            <Column>
              <Row>
                <TableButtonsContainer style={{ position: "relative" }}>
                  <FastActionButton
                    iconComponent={
                      <IconContainer
                        size={16}
                        color={styleTheme.palette.turquoiseBlue}
                        id={`edit-push-notification-${item.title}`}
                      >
                        <EditIcon />
                      </IconContainer>
                    }
                    onClick={() => history.push(RoutesEnum.EDIT_PUSH_NOTIFICATION.replace(":id", item.id))}
                    label={t("pages.discoverCards.listButtons.edit")}
                  />
                  <FastActionButton
                    iconComponent={
                      <IconContainer
                        size={16}
                        color={styleTheme.palette.vodafoneRed}
                        id={`delete-push-notification-${item.title}`}
                      >
                        <TrashIcon />
                      </IconContainer>
                    }
                    onClick={() => {
                      showDeleteConfirmation(item.id);
                    }}
                    label={t("pages.discoverCards.listButtons.delete")}
                  />
                </TableButtonsContainer>
              </Row>
            </Column>
          </RatioContainer>
        ) : null}
      </PushNotificationListItemContainer>
    </>
  );
};

export default PushNotificationListItem;

const PushNotificationListItemContainer = styled("li")<{ hoverAction: boolean; clickActionAvailable?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 75px;
  border-radius: 8px;
  font-family: Vodafone Rg;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};

  margin-bottom: 24px;
  padding: 0 35px 0 24px;
  ${props => (props.clickActionAvailable ? "cursor: pointer;" : "")};

  .status-container {
    display: flex;
    justify-content: flex-end;
  }
  .edit-remove-wrapper {
    display: none;
  }

  ${props =>
    props.hoverAction ? ":hover {.edit-remove-wrapper { display: flex; } .status-container { display: none;}}" : ""};
`;

const textBox = css`
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const ItemTitle = styled(RatioContainer)`
  font-size: 22px;
  font-weight: bold;
  -webkit-line-clamp: 2;
  color: ${props => props.theme.palette.darkGrey};
  ${textBox}
`;

const ItemNormalText = styled(RatioContainer)`
  font-size: 16px;
  -webkit-line-clamp: 4;
  color: ${props => props.theme.palette.midGrey};
  ${textBox}
`;

const DateSpan = styled("span")`
  margin-right: 20px;
  font-family: Vodafone Rg;
  font-size: 13px;
  letter-spacing: 0.24px;
`;
