import { UserType } from "../../features/safaricom-service-manager/section-manager/section-manager.interface";
import { TrustedRolesEnum } from "../../features/safaricom-service-manager/service-details/pages/details.interfaces";

export interface IServiceManagerService {
  id: string;
  creationDate: string;
  name: string;
  description: string;
  iconUrl: string;
  icon: string;
  image: string;
  imageUrl: string;
  color: string;
  status: ServiceManagerStatusEnum;
  groups: IServiceManagerGroups[];
}

export interface IServiceManagerGroups {
  id: string;
  name: string;
  order?: number;
  promotedOrder?: number;
  serviceType: ServiceType;
  userType: UserType;
}

export interface IServiceManagerServiceDetails {
  id: string;
  creationDate: string;
  name: string;
  description: string;
  iconUrl: string;
  imageUrl: string;
  icon: string;
  image: string;
  color: string;
  status: ServiceManagerStatusEnum;
  groups: IServiceManagerGroups[];
  trustedLevel: TrustedRolesEnum;
  allowedScopes: string[];
  allowedAgentRoles: string[];
  allowedMerchantRoles: string[];
  categories?: string[];
  nameTranslations?: {
    [lang: string]: string;
  };
  descriptionTranslations?: {
    [lang: string]: string;
  };
}

export interface IMiniAppRole {
  id: string;
  name: string;
}

export interface IExternalServices {
  serviceId: string;
  url: string;
  permissions: ExternalServicePermitionsEnum[];
  rsaPublicKey?: string;
}

export interface IPermissions {
  identity: boolean;
  pin: boolean;
}

export enum ServiceManagerStatusEnum {
  LIVE = "LIVE",
  DISABLED = "DISABLED",
  UNDEPLOYED = "UNDEPLOYED",
}

export enum ServiceManagerServiceStatusSearchEnum {
  LIVE = "LIVE",
  DISABLED = "DISABLED",
}

export enum ServiceManagerServiceTypeSearchEnum {
  ALL_SERVICES = "ALL_SERVICES",
  SERVICES_AREA = "SERVICES_AREA",
}

export enum ExternalServicePermitionsEnum {
  PIN = "PIN",
  IDENTITY = "IDENTITY",
}

export enum ServiceDetailsGroupsEnum {
  CONSUMER_SERVICES = "Consumer - Services",
  CONSUMER_GROW = "Consumer - Grow",
  MERCHANT_HUB = "Merchant - Hub",
  AGENT_HUB = "Agent - Hub",
  SERVICES_AREA = "Services Area",
}

export enum ServiceType {
  ORG = "ORG",
  CONSUMER = "CONSUMER",
}

export interface IServiceGroup {
  serviceType: string;
  userType: string;
  label: string;
  value: string;
  id: string;
}
