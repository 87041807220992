import { IClientDocSubmissionsRequest } from "./doc-validation.interface";
import ApiClient, { updateRequestIdHeader } from "../../../configs/axios.kyc.config";

const DocValidationApi = {
  routes: {
    registrations: "/registration/list",
    exportRegistrations: "/registration/export",
    csvExport: "/csv/registrations/details",
  },

  methods: {
    getClientDocSubmissions: (params: IClientDocSubmissionsRequest): Promise<any> => {
      // Pages index are 0 (zero) based in the services
      const { pageSize, page, searchString, status, dateRangeIni, dateRangeEnd, sort } = params;
      const pageClone = Number(page) - 1;
      const sorts = [sort];

      const requestConfig = {
        pagination: {
          page: Number(pageClone),
          size: Number(pageSize),
        },
        sorts,
        searchString: searchString?.toUpperCase(),
        status: status,
        dateRange: {
          start: dateRangeIni,
          end: dateRangeEnd,
        },
      };

      updateRequestIdHeader();
      return ApiClient.post(DocValidationApi.routes.registrations, requestConfig);
    },

    exportRegistrations: (params: IClientDocSubmissionsRequest): Promise<any> => {
      const { searchString, status, dateRangeIni, dateRangeEnd, sort } = params;
      const sorts = [sort];

      const payload = {
        sorts,
        searchString: searchString?.toUpperCase(),
        status: status,
        dateRange: {
          start: dateRangeIni,
          end: dateRangeEnd,
        },
      };

      updateRequestIdHeader();
      return ApiClient.post(DocValidationApi.routes.exportRegistrations, payload);
    },

    getRegistrationsCsv: () => {
      updateRequestIdHeader();
      return ApiClient.get(DocValidationApi.routes.csvExport);
    },
  },
};

export default DocValidationApi;
