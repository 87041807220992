export enum BanksStatusEnum {
  LIVE = "LIVE",
  ENABLE = "ENABLE",
  DISABLE = "DISABLE",
  DISABLED = "DISABLED",
  EDIT = "EDIT",
  DELETE = "DELETE",
}

export interface IBank {
  id: string;
  name: string;
  shortCode: string;
  urlIcon: string;
  accountNumberPrefix?: string;
  status?: BanksStatusEnum;
  accountNameCheck?: boolean;
  base64Image?: string;
  imageName?: string;
}
