import React from "react";

function ImagePlaceHolderIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40">
      <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g stroke="#00B0CA">
          <g>
            <g>
              <path
                d="M24.656 27.5s4.675.18 6.498-.625c2.587-1.172 4.355-3.632 4.642-6.458.273-2.727-.971-5.723-3.321-7.173-1.169-.719-2.533-1.01-3.898-1.13-.778-.02-1.558.014-2.331.105-.217-.691-.515-1.354-.888-1.975-1.501-2.498-4.109-4.124-7.012-4.375-2.842-.207-5.61.964-7.442 3.146-.884 1.057-1.519 2.3-1.856 3.637-.09.354-.267 1.342-.267 1.342-.912.292-1.753.77-2.473 1.402-2.19 1.958-2.723 5.304-1.498 7.937 1.113 2.392 3.503 4.167 6.16 4.167h4.376M20 33.333v-17.5m-5.417 5.834L20 15.833l5.417 5.834"
                transform="translate(-986 -262) translate(923 262) translate(63.5)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ImagePlaceHolderIcon;
