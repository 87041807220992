import { useEffect, useState } from "react";

/**
 * Custom hook to filter the notifications table
 */
const useFilterTable = (searchString: string, statusSelected: string[], notificationsList: any[]) => {
  const [filteredNotifications, setFilteredNotifications] = useState([...notificationsList]);

  useEffect(() => {
    if (notificationsList.length) {
      let filteredNotifications = [...notificationsList];

      if (searchString) {
        filteredNotifications = filteredNotifications.filter(item =>
          item.campaignName?.toLowerCase().includes(searchString.toLowerCase()),
        );
      }

      if (statusSelected.length) {
        filteredNotifications = filteredNotifications.filter(item => statusSelected.includes(item.status));
      }

      setFilteredNotifications(filteredNotifications);
    }
  }, [searchString, statusSelected, notificationsList]);

  return { filteredNotifications };
};

export default useFilterTable;
