import i18n from "i18next";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ConfigContext, IConfig, marketsWithPrivateConfigs } from "./app.component";
import AuthenticationApi from "./features/authentication/authentication.api";
import { ILoggedUser } from "./features/authentication/authentication.interfaces";
import { AuthenticationActions } from "./features/authentication/authentication.store";
import RoutesComponent from "./routes/routes.component";
import ErrorBoundary from "./shared/components/error-boundary/error-boundary.component";
import LoadingComponent from "./shared/components/loading-component/LoadingComponent";
import { BackOfficeMarketsEnums } from "./shared/shared.enums";
import { LoadingText } from "./shared/shared.styled";
import { fetchPrivateConfig } from "./shared/utils/login.utils";

const SessionWrapperComponent = () => {
  const [isFetchingSession, setIsFetchingSession] = React.useState(true);
  const dispatch = useDispatch();
  const configContext = useContext(ConfigContext);

  /**
   * Session success handler
   * @param user
   */
  const fetchSessionHandler = (user: ILoggedUser) => {
    i18n.setDefaultNamespace("private");
    dispatch(AuthenticationActions.creators.updateLoggedUserAction({ ...user }));
    setIsFetchingSession(false);
  };

  React.useEffect(() => {
    setIsFetchingSession(true);
    AuthenticationApi.methods
      .session()
      .then(res => {
        if (marketsWithPrivateConfigs.includes(process.env.REACT_APP_MARKET as BackOfficeMarketsEnums)) {
          fetchPrivateConfig(res.data).then(configRes => {
            configContext.config = configRes ?? configContext.config;
            fetchSessionHandler(res.data);
          });
        } else {
          fetchSessionHandler(res.data);
        }
      })
      .catch(() => {
        setIsFetchingSession(false);
      });
  }, []);

  return isFetchingSession ? (
    <LoadingComponent
      loadingText={
        !process.env.REACT_APP_MARKET!.includes(BackOfficeMarketsEnums.DRC)
          ? "Loading user settings..."
          : "Chargement des paramètres utilisateur"
      }
    />
  ) : (
    <>
      <BrowserRouter basename={process.env.REACT_APP_ROUTER_BASE}>
        <ErrorBoundary>
          <RoutesComponent />
        </ErrorBoundary>
      </BrowserRouter>
    </>
  );
};

export default SessionWrapperComponent;
