import { AxiosPromise } from "axios";
import ApiClient from "../../../configs/axios.config";

/*
 * Surveys API methods
 * */
const SurveysApi = {
  routes: {
    surveys: "/surveys",
  },
  methods: {
    getSurveys: (): AxiosPromise<ISurvey[]> => {
      return ApiClient.get(SurveysApi.routes.surveys);
    },

    createSurvey: (survey: ISurvey, msisdnsFile: File) => {
      const formData = new FormData();
      formData.append("name", survey.name);
      formData.append("sms", survey.sms);
      formData.append("file", msisdnsFile);

      return ApiClient.post(SurveysApi.routes.surveys, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
    },

    editSurvey: (survey: ISurvey, msisdnsFile: File) => {
      const formData = new FormData();
      formData.append("name", survey.name);
      formData.append("sms", survey.sms);
      if (msisdnsFile) {
        formData.append("file", msisdnsFile);
      }

      return ApiClient.put(`${SurveysApi.routes.surveys}/${survey.id}`, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
    },

    deleteSurvey: (surveyId: string) => {
      return ApiClient.delete(`${SurveysApi.routes.surveys}/${surveyId}`);
    },

    publishSurvey: (surveyId: string) => {
      return ApiClient.post(`${SurveysApi.routes.surveys}/${surveyId}/publish`);
    },

    downloadSurvey: (surveyId: string) => {
      return ApiClient.get(`${SurveysApi.routes.surveys}/${surveyId}/csvfile`);
    },
  },
};

export interface ISurvey {
  id: string;
  name: string;
  url: string;
  entries: number;
  published: boolean;
  publishedOn: number | null;
  createdOn: number;
  fileName: string;
  sms: string;
  fileSize: string;
}

export default SurveysApi;
