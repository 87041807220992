import React, { HTMLProps } from "react";

const Document = (props?: HTMLProps<SVGSVGElement>) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="192" height="192" viewBox="0 0 192 192">
      <defs>
        <path
          id="b"
          d="M0 28v116c0 8.837 7.163 16 16 16h80c8.837 0 16-7.163 16-16V40H88c-8.837 0-16-7.163-16-16V0H16C7.163 0 0 7.163 0 16v12"
        />
        <filter id="a" width="118.8%" height="113.1%" x="-9.4%" y="-4.7%" filterUnits="objectBoundingBox">
          <feOffset dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3" />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.556640625 0 0 0 0 0.556640625 0 0 0 0 0.556640625 0 0 0 0.23 0"
          />
        </filter>
        <linearGradient id="c" x1="-23.953%" x2="75.61%" y1="24.29%" y2="124.265%">
          <stop offset="0%" stopOpacity=".571" />
          <stop offset="100%" stopOpacity="0" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(40 16)">
        <use fill="#000" filter="url(#a)" href="#b" />
        <use fill="#F4F4F4" href="#b" />
        <path fill="#EBEBEB" fillRule="nonzero" d="M112 40H88c-8.837 0-16-7.163-16-16V0l40 40z" />
        <path
          fill="#000"
          fillRule="nonzero"
          d="M88 38c-8.837 0-16-7.163-16-16v2c0 8.837 7.163 16 16 16h24l-2-2H88z"
          opacity=".06"
        />
        <path
          fill="url(#c)"
          d="M88 40c-.53 0-1.05 0-1.57-.08l-.62-.09c-.3 0-.6-.08-.89-.14-.29-.06-.53-.12-.79-.19l-.65-.17c-.3-.09-.6-.2-.9-.3l-.47-.17c-.33-.13-.65-.28-1-.43l-.32-.16c-.35-.17-.68-.36-1-.56l-.2-.12a9.48 9.48 0 0 1-1-.7l-.09-.07c-.36-.27-.71-.55-1-.84L112 70.56V40H88z" // tslint:disable-line
          opacity=".15"
        />
        <rect width="64" height="4" x="24" y="97" fill="#4A4D4E" fillRule="nonzero" rx="1.41" />
        <rect width="64" height="4" x="24" y="113" fill="#4A4D4E" fillRule="nonzero" rx="1.41" />
        <rect width="64" height="4" x="24" y="81" fill="#E90000" fillRule="nonzero" rx="1.41" />
        <path
          fill="#000"
          fillRule="nonzero"
          d="M96 158H16c-8.837 0-16-7.163-16-16v2c0 8.837 7.163 16 16 16h80c8.837 0 16-7.163 16-16v-2c0 8.837-7.163 16-16 16z"
          opacity=".06"
        />
      </g>
    </svg>
  );
};

export default Document;
