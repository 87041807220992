import { IServiceDetails, IServiceVersion } from "../../shared/models/service-builder.model";
import { IPayloadAction } from "../../shared/shared.interfaces";
import produce from "immer";
import { AuthenticationActions } from "../authentication/authentication.store";

export const ServiceDetailsActions = {
  types: {
    FETCHING_SERVICE_DETAILS: "FETCHING_SERVICE_DETAILS",
    FETCH_SERVICE_DETAILS_SUCCESS: "FETCH_SERVICE_DETAILS_SUCCESS",
    FETCH_SERVICE_DETAILS_ERROR: "FETCH_SERVICE_DETAILS_ERROR",
    FETCHING_SERVICE_VERSIONS: "FETCHING_SERVICE_VERSIONS",
    FETCH_SERVICE_VERSIONS_SUCCESS: "FETCH_SERVICE_VERSIONS_SUCCESS",
    FETCH_SERVICE_VERSIONS_ERROR: "FETCH_SERVICE_VERSIONS_ERROR",
    DELETE_SERVICE_IN_DETAILS: "DELETE_SERVICE_IN_DETAILS",
    UPDATE_SERVICE_IN_DETAILS: "UPDATE_SERVICE_IN_DETAILS",
    FETCH_SERVICE_DETAILS_LEAVE: "FETCH_SERVICE_DETAILS_LEAVE",
    FETCH_SERVICE_VERSION_ADD: "FETCH_SERVICE_VERSION_ADD",
  },
  creators: {
    fetchingServiceDetailsAction: () => ({
      type: ServiceDetailsActions.types.FETCHING_SERVICE_DETAILS,
    }),
    fetchServiceDetailsSuccessAction: (serviceDetails: IServiceDetails) => ({
      type: ServiceDetailsActions.types.FETCH_SERVICE_DETAILS_SUCCESS,
      payload: {
        serviceDetails,
      },
    }),
    fetchServiceDetailsErrorAction: () => ({
      type: ServiceDetailsActions.types.FETCH_SERVICE_DETAILS_ERROR,
    }),
    fetchingServiceVersionsAction: () => ({
      type: ServiceDetailsActions.types.FETCHING_SERVICE_VERSIONS,
    }),
    fetchServiceVersionsSuccessAction: (serviceVersions: IServiceVersion[]) => ({
      type: ServiceDetailsActions.types.FETCH_SERVICE_VERSIONS_SUCCESS,
      payload: {
        serviceVersions,
      },
    }),
    fetchServiceVersionsErrorAction: () => ({
      type: ServiceDetailsActions.types.FETCH_SERVICE_VERSIONS_ERROR,
    }),
    deleteServiceAction: (serviceId: string) => ({
      type: ServiceDetailsActions.types.DELETE_SERVICE_IN_DETAILS,
      payload: {
        serviceId,
      },
    }),
    updateServiceAction: (serviceId: string, serviceDetails: any) => ({
      type: ServiceDetailsActions.types.UPDATE_SERVICE_IN_DETAILS,
      payload: {
        serviceId,
        serviceDetails,
      },
    }),
    fetchingServiceDetailsLeaveAction: () => ({
      type: ServiceDetailsActions.types.FETCH_SERVICE_DETAILS_LEAVE,
    }),
    fetchingServiceVersionAddAction: (versionDetails: IServiceVersion) => ({
      type: ServiceDetailsActions.types.FETCH_SERVICE_VERSION_ADD,
      payload: {
        versionDetails,
      },
    }),
  },
};

export interface IServiceDetailsReducerInterface {
  serviceDetails: IServiceDetails;
  serviceVersions: IServiceVersion[];
  isLoadingServiceDetails: boolean;
  isLoadingServiceVersions: boolean;
}

const initialState: IServiceDetailsReducerInterface = {
  serviceDetails: {} as IServiceDetails,
  serviceVersions: [] as IServiceVersion[],
  isLoadingServiceDetails: true,
  isLoadingServiceVersions: true,
};

export const serviceDetailsReducer = produce((draft: IServiceDetailsReducerInterface, action: IPayloadAction<any>) => {
  switch (action.type) {
    case ServiceDetailsActions.types.FETCHING_SERVICE_DETAILS:
      draft.isLoadingServiceDetails = true;
      return;
    case ServiceDetailsActions.types.FETCH_SERVICE_DETAILS_SUCCESS:
      draft.isLoadingServiceDetails = false;
      draft.serviceDetails = action.payload.serviceDetails;
      return;
    case ServiceDetailsActions.types.FETCH_SERVICE_DETAILS_ERROR:
      draft.isLoadingServiceDetails = false;
      return;
    case ServiceDetailsActions.types.FETCHING_SERVICE_VERSIONS:
      draft.isLoadingServiceVersions = true;
      return;
    case ServiceDetailsActions.types.FETCH_SERVICE_VERSIONS_SUCCESS:
      draft.isLoadingServiceVersions = false;
      var live = action.payload.serviceVersions
        .filter((item: any) => item.status === "LIVE")
        .sort((a: any, b: any) => parseFloat(b.statusDate) - parseFloat(a.statusDate));
      var beta = action.payload.serviceVersions
        .filter((item: any) => item.status === "BETA")
        .sort((a: any, b: any) => parseFloat(b.statusDate) - parseFloat(a.statusDate));
      var undeployed = action.payload.serviceVersions
        .filter((item: any) => item.status === "UNDEPLOYED")
        .sort((a: any, b: any) => parseFloat(b.statusDate) - parseFloat(a.statusDate));
      //Transition States, added on Feature #13586
      var undeployedLive = action.payload.serviceVersions
        .filter((item: any) => item.status === "UNDEPLOYED_LIVE")
        .sort((a: any, b: any) => parseFloat(b.statusDate) - parseFloat(a.statusDate));
      var undeployedBeta = action.payload.serviceVersions
        .filter((item: any) => item.status === "UNDEPLOYED_BETA")
        .sort((a: any, b: any) => parseFloat(b.statusDate) - parseFloat(a.statusDate));
      var liveUndeployed = action.payload.serviceVersions
        .filter((item: any) => item.status === "LIVE_UNDEPLOYED")
        .sort((a: any, b: any) => parseFloat(b.statusDate) - parseFloat(a.statusDate));
      var betaLive = action.payload.serviceVersions
        .filter((item: any) => item.status === "BETA_LIVE")
        .sort((a: any, b: any) => parseFloat(b.statusDate) - parseFloat(a.statusDate));
      var betaUndeployed = action.payload.serviceVersions
        .filter((item: any) => item.status === "BETA_UNDEPLOYED")
        .sort((a: any, b: any) => parseFloat(b.statusDate) - parseFloat(a.statusDate));
      var liveDeleted = action.payload.serviceVersions
        .filter((item: any) => item.status === "LIVE_DELETED")
        .sort((a: any, b: any) => parseFloat(b.statusDate) - parseFloat(a.statusDate));
      var betaDeleted = action.payload.serviceVersions
        .filter((item: any) => item.status === "BETA_DELETED")
        .sort((a: any, b: any) => parseFloat(b.statusDate) - parseFloat(a.statusDate));

      draft.serviceVersions = live
        .concat(beta)
        .concat(undeployed)
        .concat(undeployedLive)
        .concat(undeployedBeta)
        .concat(liveUndeployed)
        .concat(betaLive)
        .concat(betaUndeployed)
        .concat(liveDeleted)
        .concat(betaDeleted);

      return;
    case ServiceDetailsActions.types.FETCH_SERVICE_VERSIONS_ERROR:
      draft.isLoadingServiceVersions = false;
      return;
    case ServiceDetailsActions.types.DELETE_SERVICE_IN_DETAILS: {
      return;
    }
    case ServiceDetailsActions.types.UPDATE_SERVICE_IN_DETAILS:
      draft.serviceDetails = {
        ...draft.serviceDetails,
        ...action.payload.serviceDetails,
      };
      return;
    case ServiceDetailsActions.types.FETCH_SERVICE_VERSION_ADD:
      draft.serviceVersions.push(action.payload.versionDetails);
      var live1 = draft.serviceVersions
        .filter((item: any) => item.status === "LIVE")
        .sort((a: any, b: any) => parseFloat(b.statusDate) - parseFloat(a.statusDate));
      var beta1 = draft.serviceVersions
        .filter((item: any) => item.status === "BETA")
        .sort((a: any, b: any) => parseFloat(b.statusDate) - parseFloat(a.statusDate));
      var undeployed1 = draft.serviceVersions
        .filter((item: any) => item.status === "UNDEPLOYED")
        .sort((a: any, b: any) => parseFloat(b.statusDate) - parseFloat(a.statusDate));
      draft.serviceVersions = live1.concat(beta1).concat(undeployed1);
      return;
    case AuthenticationActions.types.LOGOUT:
    case ServiceDetailsActions.types.FETCH_SERVICE_DETAILS_LEAVE:
      return initialState;
    default:
      return draft;
  }
}, initialState);
