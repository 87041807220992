import { produce } from "immer";
import { AuthenticationActions } from "../../authentication/authentication.store";
import { IPayloadAction } from "../../../shared/shared.interfaces";
import { IUser } from "../../../shared/models/user.model";
import { IUpdateUserRequest } from "./users.interfaces";

export const UsersActions = {
  types: {
    FETCHING_USERS: "FETCHING_USERS",
    FETCH_USERS_SUCCESS: "FETCH_USERS_SUCCESS",
    FETCH_USERS_ERROR: "FETCH_USERS_ERROR",
    ADD_USER: "ADD_USER",
    EDIT_USER: "EDIT_USER",
    REMOVE_USER: "REMOVE_USER",
    ENABLE_USER: "ENABLE_USER",
    DISABLE_USER: "DISABLE_USER",
    SELECT_USER: "SELECT_USER",
  },
  creators: {
    fetchingUsersAction: () => ({
      type: UsersActions.types.FETCHING_USERS,
    }),
    fetchUsersSuccessAction: (users: IUser[]) => ({
      type: UsersActions.types.FETCH_USERS_SUCCESS,
      payload: {
        users,
      },
    }),
    fetchUsersErrorAction: () => ({
      type: UsersActions.types.FETCH_USERS_ERROR,
    }),
    addUserAction: (user: IUser) => ({
      type: UsersActions.types.ADD_USER,
      payload: {
        user,
      },
    }),
    editUserAction: (username: string, values: IUpdateUserRequest) => ({
      type: UsersActions.types.EDIT_USER,
      payload: {
        username,
        values,
      },
    }),
    removeUserAction: (username: string) => ({
      type: UsersActions.types.REMOVE_USER,
      payload: {
        username,
      },
    }),
    enableUserAction: (username: string) => ({
      type: UsersActions.types.ENABLE_USER,
      payload: {
        username,
      },
    }),
    disableUserAction: (username: string) => ({
      type: UsersActions.types.DISABLE_USER,
      payload: {
        username,
      },
    }),
    selectUser: (username: string) => ({
      type: UsersActions.types.SELECT_USER,
      payload: {
        username,
      },
    }),
  },
};

export interface IUserReducerInterface {
  users: IUser[];
  isLoadingUsers: boolean;
  selectedUser: IUser | null;
}

const initialState: IUserReducerInterface = {
  users: [],
  isLoadingUsers: false,
  selectedUser: null,
};

export const usersReducer = produce((draft: IUserReducerInterface, action: IPayloadAction<any>) => {
  switch (action.type) {
    case UsersActions.types.FETCHING_USERS:
      draft.isLoadingUsers = true;
      return;
    case UsersActions.types.FETCH_USERS_SUCCESS:
      draft.isLoadingUsers = false;
      draft.users = (action.payload.users as IUser[]).sort((a, b) =>
        a.fullName ? a.fullName.localeCompare(b.fullName) : -1,
      );
      return;
    case UsersActions.types.FETCH_USERS_ERROR:
      draft.isLoadingUsers = false;
      return;
    case UsersActions.types.ADD_USER:
      draft.users = [...draft.users, action.payload.user];
      return;
    case UsersActions.types.REMOVE_USER:
      const { username } = action.payload;
      draft.users = draft.users.filter(u => u.username !== username);
      return;
    case UsersActions.types.DISABLE_USER: {
      const userIdx = draft.users.findIndex(u => u.username === action.payload.username);
      if (userIdx > -1) {
        draft.users[userIdx].status = 0;
      }
      return;
    }
    case UsersActions.types.SELECT_USER: {
      const userIdx = draft.users.findIndex(u => u.username === action.payload.username);
      draft.selectedUser = draft.users[userIdx];
      return;
    }
    case UsersActions.types.ENABLE_USER: {
      const userIdx = draft.users.findIndex(u => u.username === action.payload.username);
      if (userIdx > -1) {
        draft.users[userIdx].status = 1;
      }
      return;
    }
    case UsersActions.types.EDIT_USER: {
      const userIdx = draft.users.findIndex(u => u.username === action.payload.username);
      if (userIdx > -1) {
        draft.users[userIdx].role = action.payload.values.role;
        draft.users[userIdx].fullName = action.payload.values.fullName;
      }
      return;
    }
    case AuthenticationActions.types.LOGOUT:
      return initialState;
    default:
      return draft;
  }
}, initialState);
