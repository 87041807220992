import React from "react";

const HelpIcon = (props: React.HTMLProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g stroke="#999">
          <g>
            <g>
              <path
                d="M7.485 6.786S7.826 4.96 10 4.97c2.174.01 2.909 2.678 1.762 3.844-.512.524-.95.955-1.262 1.257h0c-.32.316-.5.747-.5 1.197v.966m6.718-8.952c3.71 3.71 3.71 9.726 0 13.435-3.71 3.71-9.726 3.71-13.435 0-3.71-3.71-3.71-9.725 0-13.435 3.71-3.71 9.725-3.71 13.434 0m-6.54 11.541c.097.098.097.256 0 .354-.098.097-.256.097-.354 0-.097-.098-.097-.256 0-.354.098-.097.256-.097.354 0"
                transform="translate(-533 -391) translate(533 391) translate(2 2)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default HelpIcon;
