import { ServicesFiltersEnum } from "./components/services-filters.component";
import { IService, ServiceStatusEnum } from "../../shared/models/service-builder.model";

/**
 * Filters an array of Services using a ServiceStatusEnum[]
 * @param services Service[]
 * @param status ServiceStatusEnum[]
 */
export const filterServicesByStatus = (services: IService[], status: ServiceStatusEnum[]): IService[] => {
  if (!!status && !!status.length) {
    return services.filter(service => status.includes(service.status));
  }
  return services;
};

/**
 * Filter an array of Services using a search string
 * @param services Service[]
 * @param searchString string
 */
export const filterServicesBySearchString = (services: IService[], searchString: string) => {
  if (!!searchString) {
    return services.filter(
      service => service.name && (service.name as string).toLowerCase().includes(searchString.toLowerCase()),
    );
  }
  return services;
};

/**
 * Filter an array of Services using a search string, an array of ServiceStatusEnum
 * @param services Service[]
 * @param filters ServiceFilters
 */
export const filterServices = (services: IService[], filters: Map<string, string[]>): IService[] => {
  let newServices = services;
  const status = filters.get(ServicesFiltersEnum.STATUS);
  const search = filters.get(ServicesFiltersEnum.SEARCH);
  if (status) {
    newServices = filterServicesByStatus(newServices, status as ServiceStatusEnum[]);
  }

  if (search) {
    newServices = filterServicesBySearchString(newServices, search[0]);
  }

  return newServices;
};

/**
 * Map Transition states, to already used state in ui-components, add new state for submitted for approval
 * @param serviceStatus  any
 */

export const setTransitionStates = (serviceStatus: any) => {
  const transitionStates: Array<string> = [ServiceStatusEnum.BETA_UNDEPLOYED, ServiceStatusEnum.UNDEPLOYED_BETA];
  const forApproval: Array<string> = [
    ServiceStatusEnum.BETA_LIVE,
    ServiceStatusEnum.UNDEPLOYED_LIVE,
    ServiceStatusEnum.BETA_UNDEPLOYED,
    ServiceStatusEnum.LIVE_UNDEPLOYED,
  ];
  transitionStates.map(values => {
    if (serviceStatus.includes(values)) {
      serviceStatus = "UNDEPLOYED";
    }
  });
  forApproval.map(values => {
    if (serviceStatus.includes(values)) {
      serviceStatus = "FOR_APPROVAL";
    }
  });
  return serviceStatus;
};
