import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { CancelIcon } from "@wit/mpesa-ui-components";
import { IDocument } from "./publish-document.modal";

interface IDocumentLastVersionModalProps {
  hideModal: () => void;
  document: { content: IDocument };
  selectedLanguage: string;
}

/**
 *
 * @param param0
 * Document modal
 */
const DocumentLastVersionModal = ({ document, selectedLanguage, hideModal }: IDocumentLastVersionModalProps) => {
  const [t] = useTranslation();
  return (
    <ModalContainer>
      <Header>
        <ModalTitle>{t("pages.termsAndConditions.lastVersionPublished")}</ModalTitle>
        <RightContainer>
          <CloseIconContainer onClick={hideModal} id="close-last-version-modal">
            <CancelIcon color={"grey"} />
          </CloseIconContainer>
        </RightContainer>
      </Header>
      <DocumentContainer>
        <div dangerouslySetInnerHTML={{ __html: document.content[selectedLanguage] }}></div>
      </DocumentContainer>
    </ModalContainer>
  );
};

export default DocumentLastVersionModal;

const ModalContainer = styled("div")`
  border-radius: 6px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  padding: 24px;
`;

const ModalTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 24px;
`;

const DocumentContainer = styled("div")`
  font-family: Vodafone Rg;
  height: 400px;
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const RightContainer = styled.div`
  display: flex;
`;

const CloseIconContainer = styled("div")`
  left: 23px;
  margin-top: -10px;
  stroke: ${props => props.theme.palette.midGrey};
  cursor: pointer;
  svg {
    width: 24px;
  }
`;
