import React from "react";

const UploadIcon = (props: React.HTMLProps<SVGSVGElement>) => {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.8327 7.55566V9.11122C12.8327 9.97033 12.1454 10.6668 11.2976 10.6668H2.7011C1.8533 10.6668 1.16602 9.97033 1.16602 9.11122V7.55566"
        stroke={props.color ? props.color : "#00B0CA"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.00048 8.33325L7.00048 0.866586M7.00048 0.866586L5.00487 3.04436M7.00048 0.866586L8.99609 3.04436"
        stroke={props.color ? props.color : "#00B0CA"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UploadIcon;
