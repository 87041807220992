import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { useTranslation } from "react-i18next";
import { CancelIcon } from "@wit/mpesa-ui-components";
import { useSelector } from "react-redux";
import { IStoreInterface } from "../../../../configs/store.config";
import { Column, Row } from "../../../../shared/shared.styled";
import { IShortcut, ShortcutsStatusEnum } from "../shortcuts.model";
import { getAvailableLanguagesOptions } from "../shortcuts.utils";
import LanguageSelectorDropdown from "../../../../shared/components/language-selector-dropdown.component";
import { IIconCategory } from "../../categories/general-categories.model";

interface ILastVersionModalProps {
  onCancelClick: () => void;
  icons: IIconCategory[];
}

/**
 * last version modal
 * @param param0
 * @returns
 */
const LastVersionModal = ({ onCancelClick, icons }: ILastVersionModalProps) => {
  const [t] = useTranslation();

  const { lastVersion } = useSelector((state: IStoreInterface) => state.shortcutsReducer);

  const [language, setLanguage] = React.useState({
    label: t("commons.languages.en"),
    key: "en",
  });
  const theme = useContext(ThemeContext);

  /**
   * Get used icon by iconId
   * @param item
   * @returns
   */
  const getIconById = (iconId: string | number) => {
    return iconId && icons && icons.find(icon => icon.id.toString() === iconId?.toString()) ? (
      <img src={icons.find(icon => icon.id.toString() === iconId?.toString())?.base64} alt={"category-icon"} />
    ) : null;
  };

  /**
   * Renders each item
   * @param item
   * @returns
   */
  const renderItem = (item: IShortcut) => {
    return (
      <ItemColumn disabled={false}>
        <Row>
          <ImageContainer>{getIconById(item?.iconId)}</ImageContainer>
          <ItemInfo>
            {item.name && (
              <>
                <Row>
                  <span
                    style={{
                      fontWeight: 1000,
                    }}
                  >
                    {" "}
                    {item.name[language.key]}
                  </span>
                </Row>
                {(item.deepLinkType || item.data.type) && (
                  <Row
                    style={{
                      marginTop: 4,
                    }}
                  >
                    {t(`commons.typeOfLinkDropDown.${item.deepLinkType || item.data.type}`)}
                  </Row>
                )}
              </>
            )}
          </ItemInfo>
          <StatusContainer>
            {item.status && (
              <StatusRow type={item.status}>{t(`commons.shortcuts.shortcutsStatusEnum.${item.status}`)}</StatusRow>
            )}
          </StatusContainer>
        </Row>
      </ItemColumn>
    );
  };

  return (
    <ModalContainer>
      <Header>
        <ModalTitle>{t("pages.shortcuts.checkLastVersionModal.title")}</ModalTitle>
        <RightContainer>
          <CloseIconContainer onClick={onCancelClick} id="close-last-version-modal">
            <CancelIcon color={"grey"} />
          </CloseIconContainer>
          <div
            style={{
              paddingRight: "15px",
            }}
          >
            <LanguageSelectorDropdown
              availableLanguages={getAvailableLanguagesOptions()}
              selectedLanguage={language}
              changeSelectedLanguage={setLanguage}
              color={theme.palette.turquoiseBlue}
              id={"last-version-language-selector"}
            ></LanguageSelectorDropdown>
          </div>
        </RightContainer>
      </Header>
      <ItemsContainer>
        {lastVersion &&
          lastVersion.actionItemResponses &&
          lastVersion.actionItemResponses.map((lastVersionItem, index) => {
            return (
              <Item key={lastVersionItem.id}>
                {lastVersionItem ? renderItem(lastVersionItem) : <ItemColumn></ItemColumn>}
              </Item>
            );
          })}
      </ItemsContainer>
    </ModalContainer>
  );
};

export default LastVersionModal;

const ModalContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 24px;
  font-family: Vodafone Rg;
`;

const ModalTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 21px;
`;

const ItemsContainer = styled.div`
  max-height: 250px;
  overflow: scroll;
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${props => props.theme.palette.darkGrey};
  overflow-x: hidden;
`;

const Item = styled(Row)`
  display: flex;
  /* justify-content: space-between; */
`;

const ImageContainer = styled(Column)`
  margin-right: 24px;
  display: flex;
  align-self: baseline;
  flex: 1;
  padding: 4px;
  width: 40px;
  height: 40px;
`;

const ItemColumn = styled(Column)<{ disabled?: Boolean }>`
  flex: 1;
  padding: 16px 0px 16px 0;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  border-bottom: 1px solid #d8d8d8;
  margin-right: 34px;
`;

const ItemInfo = styled(Column)`
  flex: 20;
`;
const StatusContainer = styled(Column)`
  padding-left: 24px;
  display: flex;
  align-self: start;
  flex: 1;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CloseIconContainer = styled("div")`
  left: 23px;
  margin-top: -10px;
  stroke: ${props => props.theme.palette.midGrey};
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  svg {
    width: 24px;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatusRow = styled("div")<{ type: ShortcutsStatusEnum }>`
  ${props => {
    let color = props.theme.palette.digitalGreen;
    switch (props.type) {
      case ShortcutsStatusEnum.LIVE:
        color = props.theme.palette.digitalGreen;
        break;
      case ShortcutsStatusEnum.DISABLE:
        color = props.theme.palette.freshOrange;
        break;
      case ShortcutsStatusEnum.DISABLED:
        color = props.theme.palette.freshOrange;
        break;
      case ShortcutsStatusEnum.ENABLE:
      case ShortcutsStatusEnum.EDIT:
        color = props.theme.palette.digitalGreen;
        break;
      case ShortcutsStatusEnum.DELETE:
        color = props.theme.palette.vodafoneRed;
        break;
    }
    return `color: ${color};display: flex;justify-content: flex-end;`;
  }}
`;
