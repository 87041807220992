import {
  Checkbox,
  MultipleOptionsDropdown,
  PrimaryButton,
  SecondaryPageTitle,
  TextArea,
  TextInput,
  useAlert,
  TextInputWithTag,
} from "@wit/mpesa-ui-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikErrors, FormikHelpers, yupToFormErrors } from "formik";
import i18next from "i18next";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import styled, { css } from "styled-components";
import { object, Ref, string, StringSchema } from "yup";
import HelpIcon from "../../../shared/icons/help.icon";
import { RoutesEnum } from "../../../routes/routes.constants";
import Dropzone from "../../../shared/components/image-dropzone.component";
import { ILanguage } from "../../../shared/models/language.model";
import { IServiceAddDetails } from "../../../shared/models/service-builder.model";
import LanguagesApi from "../../../shared/services/languages.api";
import { LoadingText, PageContainer, DropDownWrapper } from "../../../shared/shared.styled";
import { RegExEpressions } from "../../../shared/shared.utils";
import DynamicScreensApi from "../../configurations/dynamic-screens/dynamic-screens.api";
import { IDynamicScreenCategory } from "../../configurations/dynamic-screens/dynamic-screens.model";
import ServiceAddApi from "../service-add.api";
import GenericLanguageSelector from "../../../shared/components/generic-language-selector.component";
import useEmptyLanguageWarningModal from "../../../shared/hooks/use-empty-language-warning-modal.hook";

const MAX_FILE_SIZE = 2000000;

/**
 * ServiceAddPage component
 */
const ServiceAddPage = () => {
  const [t] = useTranslation();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const history = useHistory();
  const [image, setImage] = useState<any>();
  const [icon, setIcon] = useState<any>();
  const [imageUrl, setImageUrl] = useState<any>();
  const [iconUrl, setIconUrl] = useState<any>();

  const [availableLanguages, setAvailableLanguages] = useState<ILanguage[]>();

  const [canBeHighlighted, setCanBeHighlighted] = useState<boolean>(false);

  const [categories, setCategories] = React.useState<IDynamicScreenCategory[]>([]);
  const [selectedCategories, setSelectedCategories] = React.useState<string[]>([]);

  const tagsSuggestions = useRef(new Array<string>());
  const [tags, setTags] = useState<string[]>([]);
  const [tagInputText, setTagInputText] = useState<string>("");
  const [tagErrorText, setTagErrorText] = useState<string>("");

  const [initialValues, setInitialValues] = useState({
    name: undefined as any,
    description: undefined as any,
    serviceImage: null,
    serviceIcon: null,
    highlighted: false,
    tags: [],
  });

  const [language, setLanguage] = useState("en");

  const { warningModalOpen, showWarning, setShowWarning } = useEmptyLanguageWarningModal(availableLanguages || []);
  /**
   * Submit method wrapper to show warning if needed
   * @param values
   */
  const submitWrapper = (values: any, actions: FormikHelpers<any>) => {
    if (showWarning) {
      setShowWarning(false);
      actions.setSubmitting(false);
      warningModalOpen(addService, values, actions);
    } else {
      addService(values, actions);
    }
  };

  const onDrop = useCallback(
    acceptedIcon => {
      if (acceptedIcon.length > 0) {
        const file: File = acceptedIcon[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        if (icon && iconUrl) {
          window.URL.revokeObjectURL(iconUrl);
        }

        reader.onloadend = function() {
          if (reader.result) {
            setIcon(reader.result.toString());
            setIconUrl(window.URL.createObjectURL(file));
          }
        };
      }
    },
    [icon, iconUrl],
  );

  const onDropImage = useCallback(
    acceptedImage => {
      if (acceptedImage.length > 0) {
        const file: File = acceptedImage[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        if (image && imageUrl) {
          window.URL.revokeObjectURL(imageUrl);
        }

        reader.onloadend = function() {
          if (reader.result) {
            setImage(reader.result.toString());
            setImageUrl(window.URL.createObjectURL(file));
          }
        };
      }
    },
    [image, imageUrl],
  );

  /**
   * Creates the language validators for the translatable fields
   * @param {string[]} languages
   * @returns {{[p: string]: Ref | StringSchema<string>}}
   */
  const getTranslationValidators = (languages: ILanguage[]) => {
    let validators: { [x: string]: Ref | StringSchema<string> } = {};
    languages.map((lang: ILanguage) => {
      if (lang.mandatory) {
        validators = {
          ...validators,
          [lang.code]: string()
            .trim()
            .required(i18next.t("pages.serviceBuilder.formErrors.required"))
            .max(255, i18next.t("pages.serviceBuilder.formErrors.maxLen255")),
        };
      } else {
        validators = {
          ...validators,
          [lang.code]: string().max(255, i18next.t("pages.serviceBuilder.formErrors.maxLen255")),
        };
      }
    });
    return validators;
  };

  /**
   * Retrieves the form validation schema
   * @param {string[]} availableLanguages
   * @returns {ObjectSchema<Shape<object, {name: Shape<object, {}>, serviceImage: string | null, serviceIcon: string | null, description:
   *   Shape<object, {}>}>>}
   */
  const getValidationSchema = (availableLanguages: ILanguage[]) => {
    return object().shape({
      serviceImage: string()
        .required(i18next.t("pages.serviceBuilder.modals.addService.image.errorRequired"))
        .nullable(),
      serviceIcon: string()
        .required(i18next.t("pages.serviceBuilder.modals.addService.icon.errorRequired"))
        .nullable(),
      name: object().shape(getTranslationValidators(availableLanguages)),
      description: object().shape(getTranslationValidators(availableLanguages)),
    });
  };

  /**
   * Validate form promise
   * @param values
   * @param {ILanguage[]} languages
   * @returns {Promise<FormikErrors<IServiceAddDetails>>}
   */
  const validateSchema = (
    values: any,
    languages: ILanguage[],
    setShowWarning?: React.Dispatch<React.SetStateAction<boolean>>,
  ): Promise<FormikErrors<IServiceAddDetails>> => {
    return new Promise<FormikErrors<IServiceAddDetails>>(resolve => {
      getValidationSchema(languages)
        .validate(values, {
          abortEarly: false,
        })
        .then(() => {
          setShowWarning && setShowWarning(false);
          //Validate if any optional language is empty
          for (let l = 0; l < languages.length; l++) {
            if (!values.name[languages[l].code] || !values.description[languages[l].code]) {
              setShowWarning && setShowWarning(true);
            }
          }
          resolve({});
        })
        .catch(errors => {
          const langErrors: string[] = [];
          errors.inner.forEach((err: { path: string }) => {
            if ((err.path as string).includes("name") || (err.path as string).includes("description")) {
              langErrors.push(err.path.replace("name.", "").replace("description.", ""));
            }
          });
          if (langErrors.length > 0) {
            langErrors.sort();
            setLanguage(langErrors[0]);
          }
          resolve(yupToFormErrors(errors));
        });
    }).then(r => {
      return r;
    });
  };

  const persistService = useCallback(
    (serviceToAdd: IServiceAddDetails, actions: FormikHelpers<any>) => {
      ServiceAddApi.methods
        .addService(image, icon, serviceToAdd)
        .finally()
        .then(
          () => {
            setAlertProps({
              title: t("pages.serviceBuilder.success.addingService.title"),
              type: AlertTypeEnum.SUCCESS,
            });
            actions.resetForm();
            setImage(null);
            setIcon(null);
            showAlert();
            actions.setSubmitting(false);
            history.push(RoutesEnum.SERVICE_BUILDER);
          },
          res => {
            if (
              res.response &&
              res.response.data &&
              res.response.data.code &&
              res.response.data.message &&
              (res.response.data.code === 20001 || res.response.data.code === 40003) //20001 = already exist | 40003 = max highlighted reached
            ) {
              setAlertProps({
                title: res.response.data.message,
                type: AlertTypeEnum.ERROR,
              });
            } else {
              setAlertProps({
                title: t("pages.serviceBuilder.errors.addingService.title"),
                content: t("pages.serviceBuilder.errors.addingService.content"),
                type: AlertTypeEnum.ERROR,
              });
            }

            showAlert();
            actions.setSubmitting(false);
          },
        );
    },
    [icon, image, setAlertProps, showAlert, t],
  );

  const addService = (values: any, actions: FormikHelpers<any>) => {
    actions.setSubmitting(true);
    const serviceToAdd = {
      name: values.name,
      description: values.description,
      highlighted: values.highlighted,
      categories: values.categories,
      tags: tags,
    } as IServiceAddDetails;

    persistService(serviceToAdd, actions);
  };

  useEffect(() => {
    LanguagesApi.methods.getAvailableLanguages().then(
      res => {
        if (res.data.availableLanguages.length === 0) {
          setAvailableLanguages([
            { id: "1", code: "en", name: "English", urlIcon: "ic_flag_uk.png" },
            { id: "2", code: "sw", name: "Swahili (Tanzania)", urlIcon: "ic_flag_tanzania.png" },
          ]);
        } else {
          setAvailableLanguages(res.data.availableLanguages);
        }
      },
      () => {
        setAlertProps({
          type: AlertTypeEnum.ERROR,
          title: t("commons.getLanguagesError"),
        });
        showAlert();
        setAvailableLanguages([
          { id: "1", code: "en", name: "English", urlIcon: "ic_flag_uk.png" },
          { id: "2", code: "sw", name: "Swahili (Tanzania)", urlIcon: "ic_flag_tanzania.png" },
        ]);
      },
    );

    DynamicScreensApi.methods.getDynamicScreensCategories().then(
      res => {
        setCategories(res.data.items);
      },
      () => {
        setAlertProps({
          title: t("pages.serviceBuilder.errors.categoriesError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );

    ServiceAddApi.methods.getCanBeHighlighted().then(res => {
      setCanBeHighlighted(res.data.canBeHighlighted);
    });
    return () => {
      window.URL.revokeObjectURL(iconUrl);
      window.URL.revokeObjectURL(imageUrl);
    };
  }, []);

  /**
   * Toggles a category in the dropdown
   * @param {SharedDropdownOption} opt
   * @param {(field: string, value: any, shouldValidate?: (boolean | undefined)) => void} setFieldValue
   */
  const toggleCategory = (
    opt: SharedDropdownOption,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  ) => {
    if (selectedCategories.includes(opt.key)) {
      setFieldValue(
        "categories",
        selectedCategories.filter(c => c !== opt.key),
      );
      setSelectedCategories(selectedCategories.filter(c => c !== opt.key));
    } else {
      setFieldValue("categories", [...selectedCategories, opt.key]);
      setSelectedCategories([...selectedCategories, opt.key]);
    }
  };

  /**
   * Checks if a category is already selected on the dropdown
   * @param {SharedDropdownOption} opt
   * @returns {boolean}
   */
  const isCategorySelected = (opt: SharedDropdownOption) => {
    return selectedCategories.includes(opt.key);
  };

  /**
   * Returns a label for the categories dropdown
   * @returns {string}
   */
  const getCategoriesLabel = () => {
    if (selectedCategories.length > 0) {
      let label = "";
      selectedCategories.forEach(c => {
        const nameTranslation = (categories.find(cat => {
          return cat.id === c;
        }) as IDynamicScreenCategory).name.translations;
        label += `${nameTranslation[language] ? nameTranslation[language] : nameTranslation["en"]}, `;
      });
      label = label.substring(0, label.length - 2);

      return label;
    } else {
      return t("pages.serviceBuilder.modals.addService.category.placeholder");
    }
  };

  return (
    <PageContainer>
      <SecondaryPageTitle
        title={t("pages.serviceBuilder.modals.addService.title")}
        goBackFn={() => window.history.back()}
        displayInitialsCircle={false}
      />

      {!availableLanguages && <LoadingText>{t("commons.loadingResults")}</LoadingText>}
      {availableLanguages && (
        <Formik
          onSubmit={submitWrapper}
          initialValues={initialValues}
          validateOnBlur={false}
          validateOnChange={false}
          validate={values => validateSchema(values, availableLanguages, setShowWarning)}
          render={({ handleChange, values, handleSubmit, errors, isSubmitting, setFieldValue, setErrors }) => (
            <EditServiceForm onSubmit={handleSubmit} onChange={handleChange}>
              <TopFormDiv>
                <EditServiceFormLeft>
                  <LanguageSelectorWrapper>
                    <GenericLanguageSelector
                      selectedLanguage={language}
                      availableLanguages={availableLanguages.map(lang => lang.code)}
                      changeSelectedLanguage={(lang: any) => setLanguage(lang.key)}
                    ></GenericLanguageSelector>
                  </LanguageSelectorWrapper>
                  <TextInput
                    title={t("pages.serviceBuilder.modals.addService.name.title")}
                    placeholder={t("pages.serviceBuilder.modals.addService.name.placeholder")}
                    required={availableLanguages.find(l => l.code === language)?.mandatory}
                    name={`name.${language}`}
                    onChange={handleChange}
                    value={(values.name && values.name[language]) || ""}
                    error={errors.name && ((errors.name as { [x: string]: string })[language] as string)}
                    autoComplete="off"
                  />
                  <TextAreaWrapper mandatory={availableLanguages.find(l => l.code === language)?.mandatory || false}>
                    <TextArea
                      id="descriptionTextArea"
                      style={{ fontSize: "16px", fontFamily: "Vodafone Rg" }}
                      title={t("pages.serviceBuilder.modals.addService.description.title")}
                      placeholder={t("pages.serviceBuilder.modals.addService.description.placeholder")}
                      name={`description.${language}`}
                      onChange={handleChange}
                      value={(values.description && values.description[language]) || ""}
                      error={
                        errors.description && ((errors.description as { [x: string]: string })[language] as string)
                      }
                      autoComplete="off"
                      maxHeight="330px"
                    />
                  </TextAreaWrapper>
                  <TextInputWrapper>
                    <Row>
                      <Title required={false} style={{ alignSelf: "center" }}>
                        {t("pages.serviceBuilder.tags.label")}
                      </Title>
                      <div id="tags-input">
                        <TextInputWithTag
                          error={tagErrorText}
                          addTagButtonText={t("pages.serviceBuilder.tags.createTag")}
                          tags={tags}
                          setTags={setTags}
                          inputValue={tagInputText}
                          setInputValue={setTagInputText}
                          tagsSuggestions={tagsSuggestions.current}
                          minTagTextLength={3}
                          maxTagTextLength={25}
                          placeholder={t("pages.serviceBuilder.tags.tagsPlaceholder")}
                          regexExp={RegExEpressions.especialCharacters}
                        />
                      </div>
                    </Row>
                  </TextInputWrapper>
                  <TextInputWrapper>
                    {categories.length > 0 && (
                      <Row>
                        <Title style={{ alignSelf: "center" }}>
                          {t("pages.serviceBuilder.modals.addService.category.title")}
                        </Title>
                        <DropDownWrapper id="selectable-option">
                          <MultipleOptionsDropdown
                            showClearAllFilters={false}
                            options={categories.map(c => {
                              return {
                                label: c.name.translations[language]
                                  ? c.name.translations[language]
                                  : c.name.translations["en"],
                                key: c.id,
                              };
                            })}
                            clearAllFilters={() => {
                              setSelectedCategories([]);
                              setFieldValue("categories", []);
                            }}
                            dropdownType={DropdownType.RECTANGULAR_NORMAL}
                            hasValue={selectedCategories.length > 0}
                            isOptionSelected={opt => isCategorySelected(opt)}
                            label={getCategoriesLabel()}
                            toggleOption={opt => toggleCategory(opt, setFieldValue)}
                          />
                        </DropDownWrapper>
                      </Row>
                    )}
                  </TextInputWrapper>
                  <Row
                    id={"highlighted"}
                    style={{
                      borderBottom: "1px solid #ebebeb",
                      paddingBottom: "16px",
                      marginTop: "6px",
                    }}
                  >
                    <Title>{t("pages.serviceBuilder.modals.addService.highlighted.title")}</Title>
                    <div id="selectable-option">
                      <Checkbox
                        data-testid={"highlighted-checkbox"}
                        name={"highlighted"}
                        onValueChange={() => {
                          if (values.highlighted) {
                            setCanBeHighlighted(true);
                          }
                          setFieldValue("highlighted", !values.highlighted);
                        }}
                        value={values.highlighted}
                        disabled={canBeHighlighted ? false : !values!.highlighted}
                      />
                      <Label>{t("pages.serviceBuilder.modals.addService.highlighted.label")}</Label>
                      {!canBeHighlighted && !values!.highlighted && (
                        <TooltipContainer data-tip data-for="highlighted-tooltip" id={"highlighted-tooltip"}>
                          <HelpIcon />
                          <ReactTooltip
                            event="mouseover"
                            eventOff="mouseleave mousedown"
                            place="top"
                            type="dark"
                            effect="solid"
                            id={"highlighted-tooltip"}
                            className={"tooltip-container"}
                          >
                            <TooltipText>{t("pages.serviceBuilder.modals.addService.highlighted.tooltip")}</TooltipText>
                          </ReactTooltip>
                        </TooltipContainer>
                      )}
                    </div>
                  </Row>
                </EditServiceFormLeft>
                <EditServiceFormRight>
                  <div>
                    <DropzoneTitle mandatory={true}>
                      {t("pages.serviceBuilder.modals.addService.icon.title")}
                    </DropzoneTitle>
                    {errors.serviceIcon ? <ImageError>{errors.serviceIcon}</ImageError> : null}
                    <Dropzone
                      inputName="serviceIcon"
                      onDrop={(file: any) => {
                        if (!file[0]) {
                          setErrors({
                            ...errors,
                            serviceIcon: t("pages.serviceBuilder.modals.addService.icon.imageError"),
                          });
                          if (values.serviceIcon === null) {
                            setFieldValue("serviceIcon", null);
                          }
                        } else if (file[0] && file[0].size > MAX_FILE_SIZE) {
                          setErrors({
                            ...errors,
                            serviceIcon: t("pages.serviceBuilder.modals.addService.icon.maxSizeError").replace(
                              "{0}",
                              (MAX_FILE_SIZE / 1000000).toString(),
                            ),
                          });
                          if (values.serviceIcon === null) {
                            setFieldValue("serviceIcon", null);
                          }
                        } else {
                          setFieldValue("serviceIcon", file);
                          onDrop(file);
                        }
                      }}
                      accept={"image/*"}
                      imageSrc={iconUrl}
                      multiple={false}
                      dropText={t("pages.serviceBuilder.modals.addService.icon.drop")}
                      releaseText={t("pages.serviceBuilder.modals.addService.icon.release")}
                      error={!!errors.serviceIcon}
                    />
                  </div>

                  <div>
                    <DropzoneTitle mandatory={true}>
                      {t("pages.serviceBuilder.modals.addService.image.title")}
                    </DropzoneTitle>
                    {errors.serviceImage ? <ImageError>{errors.serviceImage}</ImageError> : null}
                    <Dropzone
                      inputName="serviceImage"
                      onDrop={(file: any) => {
                        if (!file[0]) {
                          setErrors({
                            ...errors,
                            serviceImage: t("pages.serviceBuilder.modals.addService.image.imageError"),
                          });
                          if (values.serviceImage === null) {
                            setFieldValue("serviceImage", null);
                          }
                        } else if (file[0] && file[0].size > MAX_FILE_SIZE) {
                          setErrors({
                            ...errors,
                            serviceImage: t("pages.serviceBuilder.modals.addService.image.maxSizeError").replace(
                              "{0}",
                              (MAX_FILE_SIZE / 1000000).toString(),
                            ),
                          });
                          if (values.serviceImage === null) {
                            setFieldValue("serviceImage", null);
                          }
                        } else {
                          setFieldValue("serviceImage", file);
                          onDropImage(file);
                        }
                      }}
                      accept={"image/*"}
                      imageSrc={imageUrl}
                      multiple={false}
                      roundedImage={false}
                      dropText={t("pages.serviceBuilder.modals.addService.image.drop")}
                      releaseText={t("pages.serviceBuilder.modals.addService.image.release")}
                      error={!!errors.serviceImage}
                    />
                  </div>
                </EditServiceFormRight>
              </TopFormDiv>
              <ButtonsWrapper>
                <ButtonDiv>
                  <PrimaryButton
                    id="create-new-service-button"
                    loading={isSubmitting}
                    titleLabel={t("pages.serviceBuilder.modals.addService.save.title")}
                    type="submit"
                    redTheme={true}
                  />
                </ButtonDiv>
              </ButtonsWrapper>
            </EditServiceForm>
          )}
        />
      )}
    </PageContainer>
  );
};

export default ServiceAddPage;

const EditServiceForm = styled("form")`
  display: flex;
  margin: auto;
  flex-direction: column;
  max-width: 62%;
  margin-left: 204px;
  margin-right: 204px;
  margin-top: 110px;
  @media (max-width: 1024px) {
    min-width: 88%;
    margin-left: 44px;
    margin-right: 44px;
  }
`;

const TopFormDiv = styled("div")`
  display: flex;
  flex-direction: row;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
`;

const EditServiceFormLeft = styled("div")`
  flex: 1;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  > div {
    margin-bottom: 10px;
  }
  @media (max-width: 1024px) {
    padding-right: 0;
    min-width: 100%;
    > div {
      margin-bottom: 40px;
    }
  }
`;

const EditServiceFormRight = styled("div")`
  width: 250px;
  overflow: hidden;
  min-width: 200px;
  @media (max-width: 1024px) {
    padding-bottom: 32px;
    > div {
      margin-bottom: 48px;
    }
  }
`;

export const TooltipContainer = styled("div")`
  display: flex;
  margin-left: 8px;
  cursor: pointer;
`;

export const TooltipText = styled("div")`
  font-size: 12px;
  line-height: 14px;
  font-family: "Vodafone Rg";
  color: white;
  display: flex;
  width: 210px;
  text-align: center;
`;

const DropzoneTitle = styled("div")<{ mandatory: boolean }>`
  min-height: 21px;
  font-family: Vodafone Rg;
  font-weight: bold;
  line-height: 21px;
  font-size: 16px;
  color: #999999;
  margin-bottom: 8px;
  width: fit-content;
  display: inline-flex;
  ${props =>
    props.mandatory ? "::after {content: ' *'; color: #ff0000; font-weight: 400; padding-left: 2px;}" : null};
`;

const ButtonsWrapper = styled("div")`
  margin-top: auto;
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    width: auto;
    position: fixed;
    right: 60px;
    top: 46px;
    z-index: 1;
  }
`;

const ButtonDiv = styled("div")`
  width: 200px;
  margin-right: 20px;
`;

const ImageError = styled("div")`
  width: fit-content;
  display: inline-flex;
  height: 19px;
  color: #ff0000;
  font-family: Vodafone Rg;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  float: right;
  font-weight: 400;
`;

const TextAreaWrapper = styled("div")<{ mandatory: boolean }>`
  > div > textarea {
    color: #333333 !important;
  }

  ${({ mandatory }) =>
    mandatory &&
    css`
      > div > span > span:first-of-type:after {
        content: " *";
        width: 7px;
        color: #ff0000;
        font-family: Vodafone Rg;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
      }
    `};
`;

const LanguageSelectorWrapper = styled("div")`
  width: max-content;
  max-width: 100%;
  margin-left: auto;
`;

const TextInputWrapper = styled("div")`
  margin-bottom: 0;
  margin-top: 6px;
  padding-bottom: 8px;
  padding-top: 8px;
  border-bottom: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;

  > div {
    display: flex;
    flex-direction: row;
    > span {
      width: 210px;
      margin-top: auto;
      margin-bottom: auto;
      padding-right: 3px;
    }
    > div {
      flex: 1;
    }
  }
`;

const Label = styled("div")`
  font-family: Vodafone Rg;
  margin-left: 8px;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 19px;
  letter-spacing: normal;
  color: ${props => props.theme.palette.midGrey};
`;

export const Title = styled.span<{ required?: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Vodafone Rg;
  font-size: 16px;
  line-height: 21px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};

  ${props =>
    props.required
      ? `
::after {
    content: ' *';
    font-family: Vodafone Rg;
    font-size: 16px;
    font-weight: normal;
    color: ${props.theme.palette.errorRed};
}
`
      : ""}
`;

export const Row = styled("div")`
  display: grid !important;
  grid-template-columns: minmax(170.65px, 210px) minmax(168px, 1fr);

  div#selectable-option {
    display: flex;
  }
`;
