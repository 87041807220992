import React from "react";
import styled from "styled-components";
import { ColumnProps } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import { Table } from "@wit/mpesa-ui-components";
import {
  EFormDataDistributionMode,
  EFormDataStatus,
  IInviteList,
  IReceiverList,
  IRewardList,
  ISenderList,
} from "../../referral-campaigns.model";
import EmptyStateIllustration from "../../../../../shared/icons/empty-state-illustration.icon";
import { formatUnixTimestampDate } from "../../../../../shared/shared.utils";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { useTranslation } from "react-i18next";
import StatusDropDownComponent from "../../../../../shared/components/status-dropdown/status-dropdown.component";

interface IAnalyticsProps {
  columnsConfig: ColumnProps[];
  data: (IInviteList[] | ISenderList[]) | (IRewardList[] | IReceiverList[]);
  noResultsText: string;
  onSelectedRowStatusChange: (data: { itemId: number | string; value: string }) => void;
}

const AnalyticsTable = ({ columnsConfig, data, noResultsText, onSelectedRowStatusChange }: IAnalyticsProps) => {
  const [t] = useTranslation();
  const invitesList =
    formatUnixTimestampDate<(IInviteList | ISenderList) | (IRewardList | IReceiverList)>(
      data,
      "firstLogin",
      "DD/MMM/YYYY HH:MM:SS",
    ) || [];

  /**
   * Retrieves the columns for Invites List
   * @returns {ColumnProps[]}
   */
  const getTableColumns = (): ColumnProps[] => {
    const columns = columnsConfig.map(column => {
      return {
        ratio: 3 / 12,
        label: column.label,
        formKey: column.formKey,
        isEditable: column.isEditable,
        sortable: true,
      };
    });

    return [...columns];
  };

  const dropDownLabelStyle = (currentStatus: EFormDataStatus) => {
    return currentStatus === EFormDataStatus.SENT
      ? { color: styleTheme.palette.springGreen }
      : currentStatus === EFormDataStatus.PENDING
      ? { color: styleTheme.palette.freshOrange }
      : currentStatus === EFormDataStatus.FAILED
      ? { color: styleTheme.palette.red.normal }
      : { color: styleTheme.palette.secondary.blueLight };
  };

  const selectedOption = (currentStatus: EFormDataStatus) => {
    return t(`pages.referralCampaigns.analytics.rewardStatus.${currentStatus.toLowerCase()}`);
  };

  const dropDownOptions = (currentStatus: EFormDataStatus) => {
    return currentStatus === EFormDataStatus.SENT
      ? [
          {
            key: EFormDataStatus.FAILED,
            label: t(`pages.referralCampaigns.analytics.rewardStatus.${EFormDataStatus.FAILED.toLowerCase()}`),
          },
          {
            key: EFormDataStatus.PENDING,
            label: t(`pages.referralCampaigns.analytics.rewardStatus.${EFormDataStatus.PENDING.toLowerCase()}`),
          },
          {
            key: EFormDataStatus.REJECTED,
            label: t(`pages.referralCampaigns.analytics.rewardStatus.${EFormDataStatus.REJECTED.toLowerCase()}`),
          },
        ]
      : currentStatus === EFormDataStatus.PENDING
      ? [
          {
            key: EFormDataStatus.FAILED,
            label: t(`pages.referralCampaigns.analytics.rewardStatus.${EFormDataStatus.FAILED.toLowerCase()}`),
          },
          {
            key: EFormDataStatus.SENT,
            label: t(`pages.referralCampaigns.analytics.rewardStatus.${EFormDataStatus.SENT.toLowerCase()}`),
          },
          {
            key: EFormDataStatus.REJECTED,
            label: t(`pages.referralCampaigns.analytics.rewardStatus.${EFormDataStatus.REJECTED.toLowerCase()}`),
          },
        ]
      : currentStatus === EFormDataStatus.FAILED
      ? [
          {
            key: EFormDataStatus.PENDING,
            label: t(`pages.referralCampaigns.analytics.rewardStatus.${EFormDataStatus.PENDING.toLowerCase()}`),
          },
          {
            key: EFormDataStatus.SENT,
            label: t(`pages.referralCampaigns.analytics.rewardStatus.${EFormDataStatus.SENT.toLowerCase()}`),
          },
          {
            key: EFormDataStatus.REJECTED,
            label: t(`pages.referralCampaigns.analytics.rewardStatus.${EFormDataStatus.REJECTED.toLowerCase()}`),
          },
        ]
      : [
          {
            key: EFormDataStatus.FAILED,
            label: t(`pages.referralCampaigns.analytics.rewardStatus.${EFormDataStatus.FAILED.toLowerCase()}`),
          },
          {
            key: EFormDataStatus.PENDING,
            label: t(`pages.referralCampaigns.analytics.rewardStatus.${EFormDataStatus.PENDING.toLowerCase()}`),
          },
          {
            key: EFormDataStatus.SENT,
            label: t(`pages.referralCampaigns.analytics.rewardStatus.${EFormDataStatus.SENT.toLowerCase()}`),
          },
        ];
  };

  return (
    <Table<(IInviteList | ISenderList) | (IRewardList | IReceiverList)>
      columns={getTableColumns()}
      values={invitesList.map(list => {
        if (list.rewardStatus && list.rewardStatus !== "--") {
          const status = list.rewardStatus as EFormDataStatus;
          if (list.distributionMode === EFormDataDistributionMode.MANUAL) {
            return {
              ...list,
              rewardStatus: (
                <StatusDropDownComponent
                  labelStyle={dropDownLabelStyle(status)}
                  itemId={list.id}
                  selectedOption={selectedOption(status)}
                  options={dropDownOptions(status)}
                  onSelectedRowDataStatusChange={data => onSelectedRowStatusChange(data)}
                />
              ),
            };
          }

          return {
            ...list,
            rewardStatus: <span style={dropDownLabelStyle(status)}>{selectedOption(status)}</span>,
          };
        }

        return list;
      })}
      sortable={false}
      isStripedTable={true}
      showActionColumn={false}
      emptyStateComponent={
        <EmptyState>
          <EmptyStateIllustration />
          <NoResultsFoundText>{noResultsText}</NoResultsFoundText>
        </EmptyState>
      }
    />
  );
};

export default AnalyticsTable;

const EmptyState = styled.div`
  margin-top: 192px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const NoResultsFoundText = styled.span`
  margin: 33.67px 0 144.67px 0;
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.midGrey};
`;
