import React from "react";
import * as S from "./safaricom-delete-modal.styled";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import { Modal } from "@wit/mpesa-ui-components";
import DeletePrimaryButton from "../safaricom-delete-button/safaricom-delete-button.component";
import styled from "styled-components";
import LoadingIconWhite from "../../icons/loading-white.icon";

interface ISafaricomDeleteModalProps extends BaseModalProps {
  setInnerHtml?: boolean;
  isLoading?: boolean;
  primaryBtnId?: string;
  secondaryBtnId?: string;
}

/**
 * SafaricomDeleteModal component
 */
const SafaricomDeleteModal = ({
  primaryAction,
  primaryBtnLabel = "Confirm",
  secondaryAction,
  secondaryBtnLabel = "Cancel",
  setInnerHtml = false,
  title,
  description = "",
  isLoading = false,
  primaryBtnId = "",
  secondaryBtnId = "",
}: ISafaricomDeleteModalProps) => {
  return (
    <Modal onBackgroundClick={secondaryAction}>
      <S.ModalContent data-testid="modalContainer">
        <S.ModalTitle>{title}</S.ModalTitle>
        {setInnerHtml ? (
          <S.ModalDescription dangerouslySetInnerHTML={{ __html: description }} />
        ) : (
          <S.ModalDescription>{description}</S.ModalDescription>
        )}

        <S.ModelActionsContainer>
          <S.ButtonContainer>
            <DeletePrimaryButton
              titleLabel={secondaryBtnLabel}
              id={secondaryBtnId}
              onClick={secondaryAction}
              redTheme={false}
            />
          </S.ButtonContainer>
          {!isLoading ? (
            <S.ButtonContainer>
              <DeletePrimaryButton
                titleLabel={primaryBtnLabel}
                id={primaryBtnId}
                onClick={primaryAction}
                redTheme={true}
              />
            </S.ButtonContainer>
          ) : (
            <DivLoading>
              <LoadingIconWhite />
            </DivLoading>
          )}
        </S.ModelActionsContainer>
      </S.ModalContent>
    </Modal>
  );
};

export default SafaricomDeleteModal;

const DivLoading = styled("div")`
  background-color: ${props => props.theme.palette.errorRed};
  width: 106px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
