import { Exclamationicon, FastActionButton, StatusChip, TrashIcon, ThreeDotsIcon } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";

import i18next from "i18next";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { RoutesEnum } from "../../../../routes/routes.constants";

import { fadeInKeyframe, FlexContainer, IconContainer, Row } from "../../../../shared/shared.styled";
import SubBundleStatusChip from "../bundle-details/components/sub-bundle-status.component";
import { IBundleType } from "../bundles.model";
import ReactTooltip from "react-tooltip";
import "../bundles.css";

interface IBundleListItemProps {
  item: IBundleType;
  deleteBundle: (item: IBundleType) => void;
  dragging: boolean;
}

/**
 * Bundle list item
 * @param param0
 */
const BundleListItem = ({ item, deleteBundle, dragging }: IBundleListItemProps) => {
  const [t] = useTranslation();
  const themeContext = useContext(ThemeContext);
  const history = useHistory();
  return (
    <>
      <BundleListItemContainer
        onClick={() => {
          history.push(RoutesEnum.BUNDLE_DETAILS.replace(":id", item.id));
        }}
        id={`bundle-type-${
          item.name.translations.en ? item.name.translations.en : Object.keys(item.name.translations)[0]
        }`}
      >
        <DragContainer className={"handle"} data-tip data-for={`drag-message-${item.id}`}>
          <ThreeDotsIcon />
        </DragContainer>
        <div style={{ display: dragging ? "none" : "block" }}>
          <ReactTooltip
            event="mouseover"
            eventOff="mouseleave mousedown"
            place="bottom"
            type="dark"
            effect="solid"
            id={`drag-message-${item.id}`}
            className={"tooltip-container"}
          >
            <TooltipText>{t("pages.bundles.dragAndDropMessage")}</TooltipText>
          </ReactTooltip>
        </div>

        <BundleItemTitle ratio={3 / 12}>
          {item.name.translations.en ? item.name.translations.en : Object.keys(item.name.translations)[0]}
        </BundleItemTitle>
        <BundleItemNormalText ratio={3 / 12}>{item.description}</BundleItemNormalText>
        <BundleItemNormalText ratio={2 / 12}>
          {item.lastUpdate ? moment(item.lastUpdate).format("DD MMM YYYY") : "n.a."}
        </BundleItemNormalText>
        <BundleItemBoldText ratio={2 / 12} id={`bundles-enabled-${item.id}`}>
          {item.bundlesEnabled}
        </BundleItemBoldText>
        <FlexContainer className="toggable-container" ratio={2 / 12}>
          <Row>
            <FastActionButton
              onClick={e => {
                e.stopPropagation();
                deleteBundle(item);
              }}
              label={t("pages.bundles.delete")}
              iconComponent={
                <div
                  id={`delete-bundle-${
                    item.name.translations.en ? item.name.translations.en : Object.keys(item.name.translations)[0]
                  }`}
                  style={{
                    width: 16,
                    height: 16,
                    stroke: styleTheme.palette.vodafoneRed,
                  }}
                >
                  <TrashIcon />
                </div>
              }
            />
          </Row>
        </FlexContainer>
        <FlexContainer className="status-container" style={{ textAlign: "right", alignItems: "center" }} ratio={2 / 12}>
          {item.hasUnpublishedChanges ? (
            <>
              <ErrorMessage>{t("pages.bundles.unpublishedChanges")}</ErrorMessage>
              <IconContainer
                size={14}
                color={themeContext.palette.vodafoneRed}
                style={{ marginLeft: "4px", marginRight: "14px" }}
              >
                <Exclamationicon style={{ backgroundColor: "rgb(251, 230, 230)", borderRadius: "20px" }} />
              </IconContainer>
            </>
          ) : null}
          <SubBundleStatusChip style={{ width: "fit-content" }} type={item.status as any}>
            {i18next.t(`commons.apps.enums.subBundleStatus.${item.status}`)}
          </SubBundleStatusChip>
        </FlexContainer>
      </BundleListItemContainer>
    </>
  );
};

export default BundleListItem;

const ErrorMessage = styled("span")`
  font-family: Vodafone Lt;
  font-size: 14px;
  font-weight: normal;
  color: ${props => props.theme.palette.vodafoneRed};
  @media (max-width: 1024px) {
    display: none;
  }
`;

const BundleListItemContainer = styled("li")`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 75px;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};

  margin-bottom: 24px;
  padding: 0 35px 0 8px;

  .status-container {
    display: flex;
    justify-content: flex-end;
  }

  .toggable-container {
    display: none;
  }

  :hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;

    .status-container {
      display: none;
    }

    .toggable-container {
      display: flex;
      justify-content: flex-end;
      animation: ${fadeInKeyframe} 0.25s linear;

      button:first-child {
        margin-right: 12px;
      }
    }
  }
  &.sortable-chosen.sortable-ghost {
    img,
    span,
    button,
    div {
      display: none;
    }
  }
`;

const BundleItemTitle = styled(FlexContainer)`
  font-family: Vodafone Rg;
  font-size: 22px;
  font-weight: bold;
  color: ${props => props.theme.palette.darkGrey};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  @media (max-width: 1024px) {
    font-size: 20px;
    padding-right: 8px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
    padding-right: 8px;
  }
`;

const BundleItemNormalText = styled(FlexContainer)`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  @media (max-width: 768px) {
    font-size: 14px;
    padding-right: 8px;
  }
`;

const BundleItemBoldText = styled(FlexContainer)`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.turquoiseBlue};
  @media (max-width: 768px) {
    font-size: 14px;
    padding-right: 8px;
  }
`;

const DragContainer = styled("div")`
  display: flex;
  width: auto;
  padding-left: 16px;
  @media (max-width: 1024px) {
    padding-left: 0;
  }
  padding-right: 16px;
  stroke: ${props => props.theme.palette.greyDarkest};

  svg {
    width: 16px;
    height: 16px;
    stroke: ${props => props.theme.palette.greyDarkest};
  }
`;

const TooltipText = styled("div")`
  font-size: 13px;
  font-family: "Vodafone Rg";
  color: white;
  display: flex;
  width: 85px;
  text-align: center;
`;
