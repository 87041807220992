import { AxiosPromise } from "axios";
import SBApiClient from "../../configs/axios.service-builder.config";
import { IService } from "../../shared/models/service-builder.model";

const ServicesApi = {
  routes: {
    services: "/service/all",
  },
  methods: {
    getServices: (): AxiosPromise<IService[]> => {
      return SBApiClient.get(ServicesApi.routes.services);
    },
  },
};

export default ServicesApi;
