import { AxiosPromise } from "axios";
import ApiClient from "../../configs/axios.config";
import { IAuditingLogsRequest } from "./auditing.model";

/**
 * Auditing API configuration
 * @type {{routes: {getAuditing: string}, methods: {getAuditing: () => AxiosPromise}}}
 */
const AuditingApi = {
  routes: {
    getAuditing: "/audit",
    //getAuditing: "https://demo1715952.mockable.io/v1/auditing",
    getAuditingLogDetails: "https://demo1715952.mockable.io/v1/audit/:id",
  },
  methods: {
    getAuditing: (params: IAuditingLogsRequest): AxiosPromise => {
      return ApiClient.get(AuditingApi.routes.getAuditing, { params: params });
    },
    getAuditingLogDetails: (id: string): AxiosPromise => {
      return ApiClient.get(AuditingApi.routes.getAuditingLogDetails.replace(":id", id));
    },
  },
};

export default AuditingApi;
