import {
  IClientDocSubmissionDetailsRequest,
  IClientDocSubmissionChangeRequest,
  IClientImagesRequest,
} from "./doc-validation-details.interface";
import ApiClient, { updateRequestIdHeader } from "../../../configs/axios.kyc.config";

const DocValidationDetailsApi = {
  routes: {
    registrationDetails: "/registration/info",
    registrationChange: "/registration/status",
    registrationImage: "registration/image",
  },

  methods: {
    getClientSubmissionDetails: ({ remoteId, username }: IClientDocSubmissionDetailsRequest): Promise<any> => {
      const url = DocValidationDetailsApi.routes.registrationDetails;

      const requestConfig = {
        submissionRequestId: remoteId,
        operator: username,
      };

      updateRequestIdHeader();
      return ApiClient.post(url, requestConfig);
    },

    getImageSubmissionDetails: (params: IClientImagesRequest) => {
      const { submissionRequestId, imageType } = params;
      const requestConfig = {
        submissionRequestId,
        imageType,
      };

      updateRequestIdHeader();
      return ApiClient.post(DocValidationDetailsApi.routes.registrationImage, requestConfig);
    },

    changeClientSubmissionDetails: (requestId: string, params: IClientDocSubmissionChangeRequest): Promise<any> => {
      const requestConfig = {
        submissionRequestId: requestId,
        newStatus: params.status,
        comment: params.comment || "",
        customComment: params.customComment || "",
      };

      updateRequestIdHeader();
      return ApiClient.put(DocValidationDetailsApi.routes.registrationChange, requestConfig);
    },
  },
};

export default DocValidationDetailsApi;
