import { IServiceManagerService, ServiceManagerStatusEnum } from "../../shared/models/ant-service-manager.model";
import { IPayloadAction } from "../../shared/shared.interfaces";
import produce from "immer";
import { AuthenticationActions } from "../authentication/authentication.store";
import { IDynamicScreenCategory } from "../configurations/dynamic-screens/dynamic-screens.model";
import { IAllowedScope, IIconAllowedScope } from "./allowed-scope/allowed-scopes.model";

/**
 * Sort by status
 * @param status
 * @returns
 */
const sortByStatus = (status: string) => {
  switch (status) {
    case ServiceManagerStatusEnum.LIVE:
      return 1;
    case ServiceManagerStatusEnum.UNDEPLOYED:
      return 2;
    case ServiceManagerStatusEnum.DISABLED:
      return 3;
    default:
      return -1;
  }
};

export const AntServiceManagerActions = {
  types: {
    FETCHING_ANT_SM_SERVICES: "FETCHING_ANT_SM_SERVICES",
    FETCH_ANT_SM_SERVICES_SUCCESS: "FETCH_ANT_SM_SERVICES_SUCCESS",
    FETCH_ANT_SM_SERVICES_ERROR: "FETCH_ANT_SM_SERVICES_ERROR",
    FETCH_ANT_SM_SERVICES_LEAVE: "FETCH_ANT_SM_SERVICES_LEAVE",
    FETCH_ANT_CATEGORIES: "FETCH_ANT_CATEGORIES",
    SET_ANT_CATEGORY: "SET_ANT_CATEGORY",
    FETCH_ALLOWED_SCOPES: "FETCH_ALLOWED_SCOPES",
    FETCH_ALLOWED_SCOPES_ICONS: "FETCH_ALLOWED_SCOPES_ICONS",
    SET_ALLOWED_SCOPE: "SET_ALLOWED_SCOPE",
  },
  creators: {
    fetchAllowedScopes: (allowedScopes: IAllowedScope[]) => ({
      type: AntServiceManagerActions.types.FETCH_ALLOWED_SCOPES,
      payload: {
        allowedScopes,
      },
    }),
    fetchAllowedScopesIcons: (allowedScopesIcons: IIconAllowedScope[]) => ({
      type: AntServiceManagerActions.types.FETCH_ALLOWED_SCOPES_ICONS,
      payload: {
        allowedScopesIcons,
      },
    }),
    setAllowedScope: (allowedScope: IAllowedScope) => ({
      type: AntServiceManagerActions.types.SET_ALLOWED_SCOPE,
      payload: {
        allowedScope,
      },
    }),
    fetchingServicesAction: () => ({
      type: AntServiceManagerActions.types.FETCHING_ANT_SM_SERVICES,
    }),
    fetchServicesSuccessAction: (services: IServiceManagerService[]) => ({
      type: AntServiceManagerActions.types.FETCH_ANT_SM_SERVICES_SUCCESS,
      payload: {
        services,
      },
    }),
    fetchingServicesLeaveAction: () => ({
      type: AntServiceManagerActions.types.FETCH_ANT_SM_SERVICES_LEAVE,
    }),
    fetchCategories: (categories: IDynamicScreenCategory[]) => ({
      type: AntServiceManagerActions.types.FETCH_ANT_CATEGORIES,
      payload: {
        categories,
      },
    }),
    setCategory: (category: IDynamicScreenCategory) => ({
      type: AntServiceManagerActions.types.SET_ANT_CATEGORY,
      payload: {
        category,
      },
    }),
  },
};

export interface IAntServiceManagerReducerInterface {
  services: IServiceManagerService[];
  isLoadingServices: boolean;
  categories: IDynamicScreenCategory[];
  category: IDynamicScreenCategory;
  allowedScopes: IAllowedScope[];
  allowedScope: IAllowedScope;
  allowedScopesIcons: IIconAllowedScope[];
}

const initialState: IAntServiceManagerReducerInterface = {
  services: [],
  isLoadingServices: false,
  categories: [] as IDynamicScreenCategory[],
  category: {} as IDynamicScreenCategory,
  allowedScopes: [] as IAllowedScope[],
  allowedScope: {} as IAllowedScope,
  allowedScopesIcons: [] as IIconAllowedScope[],
};

export const antServiceManagerReducer = produce(
  (draft: IAntServiceManagerReducerInterface, action: IPayloadAction<any>) => {
    switch (action.type) {
      case AntServiceManagerActions.types.FETCHING_ANT_SM_SERVICES:
        draft.isLoadingServices = true;
        return;
      case AntServiceManagerActions.types.FETCH_ANT_SM_SERVICES_SUCCESS:
        draft.isLoadingServices = false;

        draft.services = action.payload.services.sort(
          (a: IServiceManagerService, b: IServiceManagerService) => sortByStatus(a.status) - sortByStatus(b.status),
        );
        return;
      case AntServiceManagerActions.types.FETCH_ALLOWED_SCOPES:
        draft.allowedScopes = action.payload.allowedScopes;
        return;
      case AntServiceManagerActions.types.SET_ALLOWED_SCOPE:
        draft.allowedScope = action.payload.allowedScope;
        return;
      case AntServiceManagerActions.types.FETCH_ALLOWED_SCOPES_ICONS:
        draft.allowedScopesIcons = action.payload.allowedScopesIcons;
        return;
      case AntServiceManagerActions.types.FETCH_ANT_SM_SERVICES_ERROR:
        draft.isLoadingServices = false;
        return;
      case AntServiceManagerActions.types.FETCH_ANT_CATEGORIES:
        draft.categories = action.payload.categories;
        return;
      case AntServiceManagerActions.types.SET_ANT_CATEGORY:
        draft.category = action.payload.category;
        return;
      case AuthenticationActions.types.LOGOUT:
        return initialState;
      default:
        return draft;
    }
  },
  initialState,
);
