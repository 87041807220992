import { ColumnProps } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import i18next, { t } from "i18next";
import moment from "moment";
import React from "react";
import { Row } from "../../../shared/shared.styled";
import { ISurvey } from "./surveys.api";

export const SurveysUtils = {
  getTableColumns: (): ColumnProps[] => {
    return [
      {
        ratio: 3 / 12,
        label: i18next.t("pages.tools.surveys.table.name"),
        formKey: "name",
        isEditable: false,
        changeFunction: content => {
          return (
            <Row>
              <span>{content}</span>
            </Row>
          );
        },
      },
      {
        ratio: 2 / 12,
        label: i18next.t("pages.tools.surveys.table.entries"),
        formKey: "entries",
        isEditable: false,
        changeFunction: (content, survey: ISurvey) => {
          return (
            <Row>
              {content}
              <span
                style={{
                  color: styleTheme.palette.greyDarker,
                  marginLeft: "5px",
                }}
              >
                {survey.fileName}
              </span>
            </Row>
          );
        },
      },
      {
        ratio: 3 / 12,
        label: i18next.t("pages.tools.surveys.table.sms"),
        formKey: "sms",
        isEditable: false,
        changeFunction: content => {
          return <span className="status-content">{content}</span>;
        },
      },
      {
        ratio: 2 / 12,
        label: i18next.t("pages.tools.surveys.table.createdOn"),
        formKey: "createdOn",
        isEditable: false,
        changeFunction: content => {
          return <span className="status-content">{moment(content).format("DD MMM YYYY")}</span>;
        },
      },
      {
        ratio: 2 / 12,
        label: i18next.t("pages.tools.surveys.table.publishedOn"),
        formKey: "published",
        isEditable: false,
        changeFunction: (content, row: ISurvey) => {
          return (
            <>
              <span className="status-content" style={{ color: content ? "" : styleTheme.palette.greyDarker }}>
                {content
                  ? row && row.publishedOn
                    ? moment(row.publishedOn).format("DD MMM YYYY")
                    : i18next.t("pages.tools.surveys.table.inProgress")
                  : i18next.t("pages.tools.surveys.table.notPublishedYet")}
              </span>
            </>
          );
        },
      },
    ];
  },

  /**
   * function to handle the onChange file event
   * @param e
   * @param setMaxSizeFileError
   * @param setFileError
   * @param setFile
   * @param MAX_FILE_SIZE
   */
  handleFileChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    setMaxSizeFileError: () => void,
    setFileError: (error: string) => void,
    setFile: (file: File) => void,
    MAX_FILE_SIZE: number,
  ) => {
    if (!e.target.files || !e.target.files[0]) {
      return;
    }
    if (!e.target.files[0].name.match(/.(csv)$/i)) {
      setFileError(t("pages.tools.surveys.createNewSurvey.fileFormatError"));
    } else {
      const file: File = e.target.files[0];
      if (file.size > MAX_FILE_SIZE) {
        setMaxSizeFileError();
      } else {
        setFileError("");
      }
      setFile(file);
    }
  },
};
