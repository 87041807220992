import React, { useContext } from "react";
import { ConfigContext } from "../../../app.component";
import RendererUtils from "../../../shared/renderer.utils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface IProps {
  title: string;
  body: string;
}

/**
 * Renders notification preview UI
 * @returns {React.Component}
 */
const NotificationPreviewComponent = ({ title, body }: IProps) => {
  const { config } = useContext(ConfigContext);
  const [t] = useTranslation();

  return (
    <div>
      <div className="preview-section-wrapper">
        <Container>
          <div className="preview-title">{t("pages.pushNotifications.add.preview.previewTitle")}</div>
          <div className="notification-preview-wrapper">
            <div className="notification-preview-header">
              <IconContainer>{config && RendererUtils.getNavigationMenuLogoUsingConfig(config)}</IconContainer>
              <div className="header-title">{t("pages.pushNotifications.add.preview.headerTitle")}</div>
              <div className="header-ts">{t("pages.pushNotifications.add.preview.headerTs")}</div>
            </div>
            <div className="notification-preview-body">
              <div className="notification-preview-title">{title.trim() || "Notification title"}</div>
              <div className="notification-preview-message">
                {body.trim() || title.trim() ? body : "Notification body"}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default NotificationPreviewComponent;

export const Container = styled.div`
  .preview-title {
    font-family: Vodafone Rg;
    font-weight: bold;
    color: ${props => props.theme.palette.midGrey};
    size: 16px;
    line-height: 21px;
    letter-spacing: 0;
  }

  .notification-preview-wrapper {
    margin-top: 16px;

    border-radius: 6px;
    background-color: rgba(227, 227, 227, 0.65);

    .notification-preview-header {
      font-family: Vodafone Rg;
      size: 14px;
      line-height: 20px;
      padding: 16px 8px;

      letter-spacing: 0;
      color: ${props => props.theme.palette.midGrey};

      border-bottom: solid 1px #dcd9d9;

      display: flex;
      flex-direction: row;

      .header-title {
        flex: 1 auto;

        padding-left: 8px;
      }

      .header-ts {
        flex: 0 auto;

        padding-right: 8px;
      }
    }

    .notification-preview-body {
      font-family: Vodafone Rg;
      padding: 16px 8px;

      .notification-preview-title {
        size: 16px;
        line-height: 21px;
        font-weight: bold;
        color: ${props => props.theme.palette.black};
        display: block;
      }

      .notification-preview-message {
        word-break: break-all;
        white-space: normal;
        overflow: hidden;
        display: block;
        size: 14px;
        line-height: 19px;
        color: ${props => props.theme.palette.midGrey};
      }
    }
  }
`;

const IconContainer = styled.div`
  width: 20px;
  height: 20px;
`;
