import { PrimaryButton, SecondaryPageTitle, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { RoutesEnum } from "../../../../routes/routes.constants";
import { ILanguage } from "../../../../shared/models/language.model";
import LanguagesApi from "../../../../shared/services/languages.api";
import { PageContent } from "../../../../shared/responsive-ui.styled";
import { LoadingText, PageContainer } from "../../../../shared/shared.styled";
import { IDynamicScreenAddCategory } from "../../../configurations/dynamic-screens/dynamic-screens.model";
import GeneralCategoriesApi from "../general-categories.api";
import { GeneralCategory, GeneralCategoriesTabNumber } from "../general-categories.model";
import GeneralCategoryForm from "../components/general-category-form.component";

/**
 * AddCategoryPage component
 */
const AddGeneralCategoryPage = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const [showAlert, , setAlertProps] = useAlert();
  const [savingLoading, setSavingLoading] = React.useState(false);
  const category = new GeneralCategory();
  const [errorIds, setErrorIds] = React.useState<string[]>([]);
  const [availableLanguages, setAvailableLanguages] = useState<ILanguage[]>();

  React.useEffect(() => {
    LanguagesApi.methods.getAvailableLanguages().then(
      res => {
        if (res.data.availableLanguages.length === 0) {
          setAvailableLanguages([
            { id: "1", code: "en", name: "English", urlIcon: "ic_flag_uk.png" },
            { id: "2", code: "sw", name: "Swahili (Tanzania)", urlIcon: "ic_flag_tanzania.png" },
          ]);
        } else {
          setAvailableLanguages(res.data.availableLanguages);
        }
      },
      () => {
        setAlertProps({
          type: AlertTypeEnum.ERROR,
          title: t("commons.getLanguagesError"),
        });
        showAlert();
        setAvailableLanguages([
          { id: "1", code: "en", name: "English", urlIcon: "ic_flag_uk.png" },
          { id: "2", code: "sw", name: "Swahili (Tanzania)", urlIcon: "ic_flag_tanzania.png" },
        ]);
      },
    );
  }, []);

  /**
   * function go back
   */
  const goBack = () => {
    history.push(`${RoutesEnum.CATEGORIES}`);
  };

  /**
   * function on submit form
   */
  const onSubmit = (values: IDynamicScreenAddCategory) => {
    setSavingLoading(true);
    GeneralCategoriesApi.methods
      .createCategory(values)
      .then(
        res => {
          setAlertProps({
            title: t("pages.generalCategories.categoryAdded"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          goBack();
        },
        error => {
          if (error?.data?.status?.httpCode === 409) {
            if (error.data?.status?.businessCode === 8) {
              if (error.data?.additionalInfo?.invalidExternalCategoryIds) {
                let ids = error.data.additionalInfo.invalidExternalCategoryIds;
                ids = ids.invalidExternalCategoryIds ? ids.invalidExternalCategoryIds : ids;
                setErrorIds(ids.split(","));
              }
              setAlertProps({
                title: t("pages.generalCategories.duplicatedIdsError"),
                type: AlertTypeEnum.ERROR,
              });
            } else if (error.data.status?.businessCode === 7 || error.data.status?.businessCode === 40919) {
              setAlertProps({
                title: t("pages.generalCategories.categoryAlreadyExistsError"),
                type: AlertTypeEnum.ERROR,
              });
            } else {
              setAlertProps({
                title: t("pages.generalCategories.addCategoriesError"),
                type: AlertTypeEnum.ERROR,
              });
            }
          } else {
            setAlertProps({
              title: t("pages.generalCategories.addCategoriesError"),
              type: AlertTypeEnum.ERROR,
            });
          }
          showAlert();
        },
      )
      .finally(() => setSavingLoading(false));
  };

  return (
    <>
      {!availableLanguages ? (
        <LoadingText style={{ marginLeft: "75px" }}>{t("commons.loadingResults")}</LoadingText>
      ) : (
        availableLanguages && (
          <PageContainer>
            <SecondaryPageTitle
              displayInitialsCircle={false}
              title={t("pages.generalCategories.categoryForm.addNewCategory")}
              goBackFn={() => goBack()}
            />
            <SubmitButtonContainer>
              <PrimaryButton
                titleLabel={t("pages.generalCategories.categoryForm.saveChanges")}
                loading={savingLoading}
                redTheme={true}
                type="submit"
                form={`category-form`}
                id={"save-changes-button"}
              />
            </SubmitButtonContainer>
            <AddCategoryContainer>
              <GeneralCategoryForm
                initialValues={category}
                isEditing={true}
                onSubmitFn={onSubmit}
                errorIds={errorIds}
                availableLanguages={availableLanguages}
                setAvailableLanguages={setAvailableLanguages}
              ></GeneralCategoryForm>
            </AddCategoryContainer>
          </PageContainer>
        )
      )}
    </>
  );
};

export default AddGeneralCategoryPage;

const AddCategoryContainer = styled(PageContent)`
  @media (min-width: 1366px) {
    margin-left: 204px;
    margin-right: 204px;
  }
`;

const SubmitButtonContainer = styled("div")`
  position: fixed;
  right: 60px;
  top: 46px;
  z-index: 1;
`;
