import { PrimaryButton } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import i18next from "i18next";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ConfigContext } from "../../../../../app.component";
import { IStoreInterface } from "../../../../../configs/store.config";
import useVersionsDiff, { VersionItem } from "../../../../../shared/hooks/use-version-diff/use-version-diff.hook";
import VersionItemStatusChip from "../../../../../shared/hooks/use-version-diff/version-item-status-chip.component";
import { Column, Row } from "../../../../../shared/shared.styled";
import { getDefaultTranslation } from "../../../../../shared/shared.utils";
import { IBundleType, ISubBundle, IMiddlewareSubBundle } from "../../bundles.model";
import { getConfigurationFieldTranslation } from "../sub-bundles.utils";
import SubBundleStatusChip from "./sub-bundle-status.component";

interface IPublishBundleProps {
  hideModal: () => void;
  isLoading: boolean;
  publishChanges: () => void;
  bundleDetails: IBundleType;
  lastBundleDetails: IBundleType;
}

/**
 * Publish bundle
 * @param param0
 */
const PublishBundle = ({
  bundleDetails,
  lastBundleDetails,
  isLoading,
  publishChanges,
  hideModal,
}: IPublishBundleProps) => {
  const [t] = useTranslation();
  const configurationFieldsStore = useSelector((state: IStoreInterface) => state.bundlesReducer.configurationFields);
  const [configurationFields] = React.useState(configurationFieldsStore);
  const { config } = useContext(ConfigContext);
  const { prev, current } = useVersionsDiff(lastBundleDetails!.items as [], bundleDetails!.items as []);

  /**
   * Publish request
   * */
  const publish = () => {
    publishChanges();
  };

  return (
    <ModalContainer>
      <ModalTitle>{t("pages.bundleDetails.publishChanges")}</ModalTitle>
      <ModalSubtitle>{t("pages.bundleDetails.publishChangesModalTitle")}</ModalSubtitle>
      <DocumentContainer>
        <Row style={{ alignItems: "normal" }}>
          <Panel background={true}>
            <PanelTitle>{t("pages.bundleDetails.previousVersion")}</PanelTitle>
            {prev ? (
              <>
                {lastBundleDetails.isExternalProvider
                  ? (prev as IMiddlewareSubBundle[]).map((sb: VersionItem<IMiddlewareSubBundle>) => {
                      return (
                        <Row key={sb.id} style={{ marginBottom: "33px", alignItems: "flex-start" }}>
                          <Column>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <Label style={{ paddingTop: "0px" }}>
                                {t("pages.bundleDetails.table.name")}: {getDefaultTranslation(sb.name.translations)}
                              </Label>
                              <VersionItemStatusChip status={sb.versionItemStatus} />
                            </div>
                            <Label>
                              {t("pages.bundleDetails.table.interactiveChannel")}: {sb.providerData.interactiveChannel}
                            </Label>
                            <Label>
                              {t("pages.bundleDetails.table.interactionPoint")}: {sb.providerData.interactionPoint}
                            </Label>
                            <Label>
                              {t("pages.bundleDetails.table.options")}:{" "}
                              {t(`pages.bundleDetails.recipientType.${sb.recipientType}`)}
                            </Label>
                          </Column>
                          <Column style={{ marginLeft: "auto", alignItems: "flex-end" }}>
                            <SubBundleStatusChip type={sb.status as any} id={`sub-bundle-status-${sb.id}`}>
                              {i18next.t(`commons.apps.enums.subBundleStatus.${sb.status}`)}
                            </SubBundleStatusChip>
                          </Column>
                        </Row>
                      );
                    })
                  : (prev as ISubBundle[]).map((sb: VersionItem<ISubBundle>) => {
                      return (
                        <Row key={sb.id} style={{ marginBottom: "33px", alignItems: "flex-start" }}>
                          <Column>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <Label style={{ paddingTop: "0px" }}>
                                {t("pages.bundleDetails.table.identifierCode")}: {sb.identifierCode ?? "n.a."}
                              </Label>
                              <VersionItemStatusChip status={sb.versionItemStatus} />
                            </div>
                            <Label>
                              {t("pages.bundleDetails.table.cost")}: {sb.cost}
                            </Label>
                            <Label>
                              {t("pages.bundleDetails.table.shortCode")}: {sb.shortcode}
                            </Label>
                            {config!.bundles.showFrequency && (
                              <Label>
                                {t("pages.bundleDetails.table.validity")}:{" "}
                                {getConfigurationFieldTranslation(sb.frequency, configurationFields.frequency)}
                              </Label>
                            )}
                            {config!.bundles.showNetworks && (
                              <Label>
                                {t("pages.bundleDetails.table.network")}:{" "}
                                {getConfigurationFieldTranslation(sb.network, configurationFields.plan)}
                              </Label>
                            )}
                            <Label>
                              {t("pages.bundleDetails.table.options")}:{" "}
                              {t(`pages.bundleDetails.recipientType.${sb.recipientType}`)}
                            </Label>
                          </Column>
                          <Column style={{ marginLeft: "auto", alignItems: "flex-end" }}>
                            <SubBundleStatusChip type={sb.status as any} id={`sub-bundle-status-${sb.id}`}>
                              {i18next.t(`commons.apps.enums.subBundleStatus.${sb.status}`)}
                            </SubBundleStatusChip>
                            {sb.content.sms ? (
                              <Label className={"status-content"} style={{ color: styleTheme.palette.turquoiseBlue }}>
                                {getDefaultTranslation(sb.content.sms.translations)}
                              </Label>
                            ) : null}
                            {sb.content.calls ? (
                              <Label className={"status-content"} style={{ color: styleTheme.palette.turquoiseBlue }}>
                                {getDefaultTranslation(sb.content.calls.translations)}
                              </Label>
                            ) : null}
                            {sb.content.data ? (
                              <Label className={"status-content"} style={{ color: styleTheme.palette.turquoiseBlue }}>
                                {getDefaultTranslation(sb.content.data.translations)}
                              </Label>
                            ) : null}
                          </Column>
                        </Row>
                      );
                    })}
              </>
            ) : null}
          </Panel>
          <Panel>
            <PanelTitle>{t("pages.bundleDetails.editedVersion")}</PanelTitle>
            {current ? (
              <>
                {bundleDetails.isExternalProvider
                  ? (current as IMiddlewareSubBundle[]).map((sb: VersionItem<IMiddlewareSubBundle>) => {
                      return (
                        <Row key={sb.id} style={{ marginBottom: "33px", alignItems: "flex-start" }}>
                          <Column>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <Label style={{ paddingTop: "0px" }}>
                                {t("pages.bundleDetails.table.name")}: {getDefaultTranslation(sb.name.translations)}
                              </Label>
                              <VersionItemStatusChip status={sb.versionItemStatus} />
                            </div>
                            <Label>
                              {t("pages.bundleDetails.table.interactiveChannel")}: {sb.providerData.interactiveChannel}
                            </Label>
                            <Label>
                              {t("pages.bundleDetails.table.interactionPoint")}: {sb.providerData.interactionPoint}
                            </Label>
                            <Label>
                              {t("pages.bundleDetails.table.options")}:{" "}
                              {t(`pages.bundleDetails.recipientType.${sb.recipientType}`)}
                            </Label>
                          </Column>
                          <Column style={{ marginLeft: "auto", alignItems: "flex-end" }}>
                            <SubBundleStatusChip type={sb.status as any} id={`sub-bundle-status-${sb.id}`}>
                              {i18next.t(`commons.apps.enums.subBundleStatus.${sb.status}`)}
                            </SubBundleStatusChip>
                          </Column>
                        </Row>
                      );
                    })
                  : (current as ISubBundle[]).map((sb: VersionItem<ISubBundle>) => {
                      return (
                        <Row key={sb.id} style={{ marginBottom: "33px", alignItems: "flex-start" }}>
                          <Column>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <Label style={{ paddingTop: "0px" }}>
                                {t("pages.bundleDetails.table.identifierCode")}: {sb.identifierCode ?? "n.a."}
                              </Label>
                              <VersionItemStatusChip status={sb.versionItemStatus} />
                            </div>
                            <Label>
                              {t("pages.bundleDetails.table.cost")}: {sb.cost}
                            </Label>
                            <Label>
                              {t("pages.bundleDetails.table.shortCode")}: {sb.shortcode}
                            </Label>
                            {config!.bundles.showFrequency && (
                              <Label>
                                {t("pages.bundleDetails.table.validity")}:{" "}
                                {getConfigurationFieldTranslation(sb.frequency, configurationFields.frequency)}
                              </Label>
                            )}
                            {config!.bundles.showNetworks && (
                              <Label>
                                {t("pages.bundleDetails.table.network")}:{" "}
                                {getConfigurationFieldTranslation(sb.network, configurationFields.plan)}
                              </Label>
                            )}
                            <Label>
                              {t("pages.bundleDetails.table.options")}:{" "}
                              {t(`pages.bundleDetails.recipientType.${sb.recipientType}`)}
                            </Label>
                          </Column>
                          <Column style={{ marginLeft: "auto", alignItems: "flex-end" }}>
                            <SubBundleStatusChip type={sb.status as any} id={`sub-bundle-status-${sb.id}`}>
                              {i18next.t(`commons.apps.enums.subBundleStatus.${sb.status}`)}
                            </SubBundleStatusChip>
                            {sb.content.sms ? (
                              <Label className={"status-content"} style={{ color: styleTheme.palette.turquoiseBlue }}>
                                {getDefaultTranslation(sb.content.sms.translations)}
                              </Label>
                            ) : null}
                            {sb.content.calls ? (
                              <Label className={"status-content"} style={{ color: styleTheme.palette.turquoiseBlue }}>
                                {getDefaultTranslation(sb.content.calls.translations)}
                              </Label>
                            ) : null}
                            {sb.content.data ? (
                              <Label className={"status-content"} style={{ color: styleTheme.palette.turquoiseBlue }}>
                                {getDefaultTranslation(sb.content.data.translations)}
                              </Label>
                            ) : null}
                          </Column>
                        </Row>
                      );
                    })}
              </>
            ) : null}
          </Panel>
        </Row>

        <Row
          style={{
            justifyContent: "flex-end",
            width: "100%",
            marginTop: "auto",
            paddingRight: "24px",
            overflow: "visible",
            paddingTop: "24px",
          }}
        >
          <div style={{ marginRight: "12px" }}>
            <PrimaryButton
              disabled={isLoading}
              onClick={hideModal}
              titleLabel={t("pages.bundleDetails.cancel")}
              id="cancel-button"
            />
          </div>
          <div>
            <PrimaryButton
              redTheme={true}
              type="submit"
              onClick={publish}
              disabled={isLoading}
              titleLabel={t("pages.bundleDetails.confirm")}
              id="confirm-button"
            />
          </div>
        </Row>
      </DocumentContainer>
    </ModalContainer>
  );
};

export default PublishBundle;

const ModalContainer = styled("div")`
  border-radius: 6px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  padding-top: 24px;
  padding-bottom: 24px;
`;

const ModalTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 13px;
  padding-left: 24px;
`;

const ModalSubtitle = styled("p")`
  font-family: Vodafone Rg;
  font-size: 18px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 24px;
  padding-left: 24px;
`;

const DocumentContainer = styled("div")`
  font-family: Vodafone Rg;
  max-height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const Panel = styled("div")<{ background?: boolean }>`
  font-family: Vodafone Rg;
  overflow: auto;
  display: flex;
  width: 50%;
  padding: 24px;
  background-color: ${props => (props.background ? props.theme.palette.lightGrey : props.theme.palette.white)};
  flex-direction: column;
  max-height: 300px;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  b,
  strong {
    margin: 0;
  }
`;

const PanelTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  margin-bottom: 12px;
`;

const Label = styled("span")`
  padding-top: 5px;
`;
