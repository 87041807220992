import { produce } from "immer";
import { IPayloadAction } from "../../../shared/shared.interfaces";
import { AuthenticationActions } from "../../authentication/authentication.store";
import { IShortcuts } from "./shortcuts.model";

export const ShortcutsActions = {
  types: {
    GET_SHORTCUTS_SUCCESS: "GET_SHORTCUTS_SUCCESS",
    GET_SHORTCUTS_LAST_VERSION: "GET_SHORTCUTS_LAST_VERSION",
  },
  creators: {
    getShortcutsSuccess: (shortcuts: IShortcuts) => ({
      type: ShortcutsActions.types.GET_SHORTCUTS_SUCCESS,
      payload: {
        shortcuts,
      },
    }),
    getShortcutsLastVersion: (shortcuts: IShortcuts) => ({
      type: ShortcutsActions.types.GET_SHORTCUTS_LAST_VERSION,
      payload: {
        shortcuts,
      },
    }),
  },
};
export interface IShortcutsReducerInterface {
  shortcuts: IShortcuts;
  lastVersion: IShortcuts;
}

const initialState: IShortcutsReducerInterface = {
  shortcuts: {} as IShortcuts,
  lastVersion: {} as IShortcuts,
};

export const shortcutsReducer = produce((draft: IShortcutsReducerInterface, action: IPayloadAction<any>) => {
  switch (action.type) {
    case ShortcutsActions.types.GET_SHORTCUTS_SUCCESS:
      draft.shortcuts = action.payload.shortcuts;
      return;
    case ShortcutsActions.types.GET_SHORTCUTS_LAST_VERSION:
      draft.lastVersion = action.payload.shortcuts;
      return;
    case AuthenticationActions.types.LOGOUT:
      return initialState;
    default:
      return draft;
  }
}, initialState);
