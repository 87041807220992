import { PrimaryButton, TextInput, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikHelpers } from "formik";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import { object, string } from "yup";
import { RoutesEnum } from "../../../routes/routes.constants";
import AnimatedBackgroundImage from "../../../shared/components/background-image.component";
import PasswordPoliciesDialog from "../../../shared/components/password-rules.component";
import RegisterCompletedIcon from "../../../shared/icons/register-completed.icon";
import { IPasswordPolicies, validatePassword } from "../../../shared/passwords-utils";
import UsersApi from "../../admin/users/users.api";
import AuthenticationApi from "../authentication.api";
import { IRegisterUserRequest, IValidateRegisterIdResponse } from "../authentication.interfaces";
import { AuthenticationActions } from "../authentication.store";

export interface IRegisterValues {
  newPassword: string;
  confirmPassword: string;
}

/**
 * Validation schema
 * @param passwordPolicies
 * @returns
 */
const validationSchema = (passwordPolicies: IPasswordPolicies) => {
  let msg = "";
  return object().shape({
    newPassword: string()
      .required(i18next.t("components.changePasswordModal.required"))
      .test("password-rules", "", function(val) {
        if (!val) {
          return false;
        }
        msg = validatePassword(passwordPolicies, val);
        if (msg) {
          return this.createError({ message: msg });
        }
        return false;
      }),
    confirmPassword: string()
      .required(i18next.t("components.changePasswordModal.required"))
      .test("passwords-match", i18next.t("components.changePasswordModal.passwordsShouldMatch"), function(val) {
        return this.parent.newPassword === val;
      }),
  });
};

/**
 * Register user page
 *
 */
const RegisterPage = () => {
  // Hooks initialization
  const [t] = useTranslation(["public"]);
  const [showAlert, hideAlert, setProps] = useAlert();
  const dispatch = useDispatch();
  const { registerId } = useParams<any>();

  const [successfulRequest, setSuccessfulRequest] = React.useState(false);
  const [requestData, setRequestData] = React.useState<IRegisterUserRequest>();

  const [info, setInfo] = React.useState<IValidateRegisterIdResponse>();
  const [showPasswordPolicies, setShowPasswordPolicies] = React.useState(false);
  const [passwordPolicies, setPasswordPolicies] = React.useState<IPasswordPolicies>({
    restrictMinDigits: true,
    minDigits: 1,
    restrictMinUpperCaseLetters: true,
    minUpperCaseLetters: 1,
    restrictMinLowerCaseLetters: true,
    minLowerCaseLetters: 1,
    restrictMinNonAlphanumericCharacters: true,
    minNonAlphanumericCharacters: 1,
    restrictSuccessiveIdenticalChars: false,
    minSuccessiveIdenticalChars: 0,
    minLength: 6,
  });

  const history = useHistory();

  /**
   * GET the password policies
   */
  React.useEffect(() => {
    UsersApi.methods.passwordPolicies("register", registerId).then(
      res => {
        setPasswordPolicies(res.data);
      },
      err => {
        console.error(err);
      },
    );
  }, []);

  // Effects
  React.useEffect(() => {
    AuthenticationApi.methods.validateRegistrationId(registerId).then(
      res => {
        setInfo(res.data);
      },
      () => {
        setProps({
          title: t("pages.register.invalidRegisterIdAlert.title"),
          content: t("pages.register.invalidRegisterIdAlert.content"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        history.push(RoutesEnum.LOGIN);
      },
    );
  }, [setProps, showAlert, t, registerId]);

  /**
   * This handles submit
   * @param values
   * @param actions
   */
  const submit = (values: IRegisterValues, actions: FormikHelpers<IRegisterValues>) => {
    actions.setSubmitting(true);

    const data = {
      id: registerId,
      password: values.newPassword,
    };
    AuthenticationApi.methods.registerUser(data).then(
      () => {
        setSuccessfulRequest(true);
      },
      () => {
        setProps({
          title: t("pages.register.errorRegisteringAlert.title"),
          content: t("pages.register.errorRegisteringAlert.content"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  return (
    <RegisterContainer>
      {successfulRequest ? (
        <RegisterCompletedContainer>
          <RegisterTitle dangerouslySetInnerHTML={{ __html: t("pages.register.title") }} />
          <div style={{ marginLeft: "auto", marginRight: "auto" }}>
            <RegisterCompletedIcon />
          </div>
          <div>
            <SuccessfullyCreatedText>{t("pages.register.accountCreated")}</SuccessfullyCreatedText>
          </div>
          <div>
            <PrimaryButton
              redTheme={true}
              titleLabel={t("pages.register.goToLogin")}
              onClick={() => {
                AuthenticationApi.methods.logout().finally(() => {
                  dispatch(AuthenticationActions.creators.logoutAction());
                  history.push(RoutesEnum.LOGIN);
                });
              }}
            />
          </div>
        </RegisterCompletedContainer>
      ) : (
        <Formik
          onSubmit={submit}
          initialValues={{ newPassword: "", confirmPassword: "" }}
          validationSchema={validationSchema(passwordPolicies)}
          render={({ handleChange, values, errors, handleSubmit, isSubmitting }) => (
            <RegisterFormContainer onSubmit={handleSubmit}>
              <RegisterTitle dangerouslySetInnerHTML={{ __html: t("pages.register.title") }} />
              <RegisterSubTitle>{t("pages.register.subTitle")}</RegisterSubTitle>
              {/*<UsernameTitle>{t("pages.register.username")}</UsernameTitle>
            {info ? <RegisterSubTitle>{info.fullName}</RegisterSubTitle> : null}*/}
              <InputsContainer>
                <TextInputContainer>
                  <TextInput
                    title={t("pages.register.password")}
                    autoFocus
                    placeholder={t("pages.register.passwordPlaceholder")}
                    name="newPassword"
                    onChange={handleChange}
                    onBlur={() => setShowPasswordPolicies(false)}
                    onFocus={() => setShowPasswordPolicies(true)}
                    value={values.newPassword}
                    required
                    error={errors.newPassword}
                    type="password"
                  />
                  {passwordPolicies && showPasswordPolicies ? (
                    <PasswordPoliciesDialog policies={passwordPolicies} topMargin={"120px"} />
                  ) : null}
                </TextInputContainer>
                <TextInputContainer>
                  <TextInput
                    title={t("pages.register.confirmPassword")}
                    placeholder={t("pages.register.confirmPasswordPlaceholder")}
                    name="confirmPassword"
                    onChange={handleChange}
                    value={values.confirmPassword}
                    required
                    error={errors.confirmPassword}
                    type="password"
                  />
                </TextInputContainer>
                <ButtonContainer>
                  <PrimaryButton
                    disabled={isSubmitting}
                    titleLabel={t("pages.register.activateAccountBtn")}
                    redTheme={true}
                    type="submit"
                  />
                </ButtonContainer>
              </InputsContainer>
            </RegisterFormContainer>
          )}
        />
      )}
      <AnimatedBackgroundImage />
    </RegisterContainer>
  );
};

export default RegisterPage;

const RegisterTitle = styled("div")`
  font-family: Vodafone Lt;
  font-size: 33px;
  color: #333;
  margin-bottom: 53px;

  > span {
    color: ${props => props.theme.palette.vodafoneRed};
  }
`;

const RegisterSubTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 15px;
`;

const RegisterContainer = styled("div")`
  height: 100%;
`;

const RegisterFormContainer = styled("form")`
  position: absolute;
  min-width: 397px;
  left: 117px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 1;
  background-color: ${props => props.theme.palette.white};
  padding: 32px 40px 40px 40px;
  border-radius: 6px;

  @media (max-width: 768px) {
    width: calc(100% - 40px);
    left: 20px;
  }
`;

const RegisterCompletedContainer = styled("div")`
  position: absolute;
  width: 397px;
  left: 117px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 1;
  background-color: ${props => props.theme.palette.white};
  padding: 32px 40px 40px 40px;
  border-radius: 6px;

  @media (max-width: 768px) {
    width: calc(100% - 40px);
    left: 20px;
  }
`;

const InputsContainer = styled("div")``;

const TextInputContainer = styled("div")`
  margin-bottom: 15px;
`;

const ButtonContainer = styled("div")`
  margin-top: 48px;
`;
const SuccessfullyCreatedText = styled("div")`
  font-family: Vodafone Rg;
  font-size: 18px;
  text-align: center;
  color: ${props => props.theme.palette.midGrey};
  margin-top: 14px;
  margin-bottom: 45px;
`;

const UsernameTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
  margin-top: 15px;
  margin-bottom: 5px;
`;
