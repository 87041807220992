import { EditIcon, FastActionButton } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { fadeInKeyframe, IconContainer, RatioContainer, Row } from "../../../../shared/shared.styled";
import { IAllowedScope, IIconAllowedScope } from "../allowed-scopes.model";

interface IAllowedScopesListItemProps {
  item: IAllowedScope;
  itemIcon?: IIconAllowedScope;
  getTranslation: (obj: any) => string;
  onEdit: (id: string) => void;
  onClickItemFn: () => void;
}

/**
 * AllowedScopes List item component
 */
const AllowedScopesListItem = ({
  item,
  itemIcon,
  onEdit,
  onClickItemFn,
  getTranslation,
}: IAllowedScopesListItemProps) => {
  const [t] = useTranslation();

  return (
    <>
      <AllowedScopesListItemContainer
        id={`scope-${item.nameTranslations && item.nameTranslations["en"] ? item.nameTranslations["en"] : item.id}`}
        hoverAction={true}
        onClick={onClickItemFn}
      >
        <ItemTitle ratio={6 / 12}>
          <AllowedScopesIconContainer color="transparent">
            {itemIcon && itemIcon.base64 ? <img src={itemIcon.base64} alt={"allowed-scope-icon"} /> : null}
          </AllowedScopesIconContainer>
          {getTranslation(item.nameTranslations)}
        </ItemTitle>
        <ItemNormalText ratio={5 / 12} className="hide-on-hover">
          {item.scope}
        </ItemNormalText>
        <RatioContainer className="toggable-container" ratio={1 / 12}>
          <Row>
            <FastActionButton
              onClick={e => {
                e.stopPropagation();
                onEdit(item.id as string);
              }}
              iconComponent={
                <IconContainer
                  size={16}
                  color={styleTheme.palette.turquoiseBlue}
                  id={`edit-scope-${item.nameTranslations["en"]}`}
                >
                  <EditIcon />
                </IconContainer>
              }
              label={t("pages.antServiceManager.allowedScopes.edit.title")}
            />
          </Row>
        </RatioContainer>
      </AllowedScopesListItemContainer>
    </>
  );
};

export default AllowedScopesListItem;

const AllowedScopesListItemContainer = styled("li")<{ hoverAction: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 75px;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};

  margin-bottom: 24px;
  padding: 0 35px 0 24px;

  .status-container {
    display: flex;
    justify-content: flex-end;
  }

  .toggable-container {
    display: none;
  }

  :hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    .status-container {
      display: ${props => (props.hoverAction ? "none" : "flex")};
    }
    .toggable-container {
      display: ${props => (!props.hoverAction ? "none" : "flex")};
      justify-content: flex-end;
      animation: ${fadeInKeyframe} 0.25s linear;
      button:first-child {
        margin-right: 12px;
      }
    }
  }
`;

const ItemTitle = styled(RatioContainer)`
  font-family: Vodafone Rg;
  font-size: 22px;
  font-weight: bold;
  color: ${props => props.theme.palette.darkGrey};
  display: flex;
  align-items: center;
`;

const ItemNormalText = styled(RatioContainer)`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
  @media (max-width: 1024px) {
    font-size: 14px;
    word-break: break-all;
  }
`;

const AllowedScopesIconContainer = styled("div")<{ color: string }>`
  width: 55px;
  height: 55px;
  background-color: ${props => (props.color ? props.color : props.theme.palette.midGrey)};
  border-radius: 50%;
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 32px;
    height: 32px;
  }
`;
