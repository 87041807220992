import {
  SecondaryPageTitle,
  FormSection,
  Table,
  useAlert,
  EditIcon,
  BlockIcon,
  CheckIcon,
  AutoClosingDropdown,
  useModal,
  ModalTypeEnum,
  TextArea,
} from "@wit/mpesa-ui-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import { Formik, FormikHelpers } from "formik";
import React from "react";
import { PageContainer } from "../../../../../../shared/shared.styled";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { useSelector } from "react-redux";
import { IStoreInterface } from "../../../../../../configs/store.config";
import { IReport } from "../../../request-money.interfaces";
import { RequestMoneyUtils } from "../../../request-money.utils";
import RequestMoneyApi from "../../../request-money.api";
import TablePagination from "../../../../../../shared/components/table-pagination/table-pagination-component";
import { getReportStatusColor } from "../../../../../../shared/shared.utils";
import { RoutesEnum } from "../../../../../../routes/routes.constants";
import { useDispatch } from "react-redux";
import { ReportsActions } from "../../../request-money.store";
import { LoadingText } from "../../../../../../shared/shared.styled";
import { ReportStatusEnum } from "../../../../../../shared/shared.enums";
import { IUpdateReportRequest } from "../../../request-money.interfaces";

const ReportDetailsPage = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<any>();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const report = useSelector((store: IStoreInterface) => store.reportsReducer.reportDetails) as IReport;
  const [relatedReports, setRelatedReports] = React.useState<IReport[]>([]);
  const [page, setActivePage] = React.useState(1);
  const pageSize = 5;
  const [totalRelatedReports, setTotalRelatedReports] = React.useState(0);
  const [isFetchingRelatedReports, setIsFetchingRelatedReports] = React.useState(true);
  const [isEditing, setIsEditing] = React.useState(false);
  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );

  /*
   * Get related reports list method
   * */
  const getRelatedReports = () => {
    setIsFetchingRelatedReports(true);
    RequestMoneyApi.methods
      .getReportedUsers()
      .finally(() => setIsFetchingRelatedReports(false))
      .then(
        res => {
          //TODO: change when BE integration
          //fake pagination with mocked data
          const reportsPage = res.data.reports.slice((page - 1) * pageSize, page * pageSize);

          setRelatedReports(reportsPage);
          setTotalRelatedReports(res.data.total);
        },
        () => {
          setAlertProps({
            title: t("pages.tools.requestMoney.reportedUsers.reportDetail.getRelatedReportsError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
  };

  /*
   * Handles the pagination change
   * */
  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
  };

  /*
   * Open details page method
   * */
  const openDetailsPage = (report: IReport) => {
    dispatch(ReportsActions.creators.setReportDetailAction(report));
    history.push(RoutesEnum.REQUEST_MONEY_REPORT.replace(":id", report.reportID));
    setActivePage(1);
    getRelatedReports();
  };

  const editReportStatus = (values: IUpdateReportRequest, actions: FormikHelpers<any>) => {
    if (report.status === values.status && report.reasonStatus === values.reasonStatus) {
      setIsEditing(false);
    } else {
      setConfirmationProps({
        title: t("pages.tools.requestMoney.reportedUsers.reportDetail.saveChanges"),
        description: t("pages.tools.requestMoney.reportedUsers.reportDetail.modals.confirmEdit", {
          report: report.reportID,
        }),
        primaryBtnId: "confirm-button",
        secondaryBtnId: "cancel-button",
        primaryAction: () => {
          //TODO: Change when BE integration
          //Mocked a successful request to change the report status
          actions.setSubmitting(true);
          report.status = values.status;
          report.reasonStatus = values.reasonStatus;
          setAlertProps({
            title: t("pages.tools.requestMoney.reportedUsers.reportDetail.editSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          setIsEditing(false);
          actions.setSubmitting(false);
          hideConfirmationModal();
        },
        secondaryAction: () => hideConfirmationModal(),
      } as BaseModalProps);
      showConfirmationModal();
    }
  };

  React.useEffect(() => {
    getRelatedReports();
  }, [page]);

  return (
    <PageContainer>
      <SecondaryPageTitle
        displayInitialsCircle={false}
        goBackFn={() => history.push(RoutesEnum.REQUEST_MONEY)}
        title={id}
      />
      <PageContent>
        <Formik
          initialValues={{
            status: report.status,
            reasonStatus: report.reasonStatus,
          }}
          onSubmit={editReportStatus}
          render={({ values, handleChange, isSubmitting, setFieldValue, handleSubmit, resetForm }) => (
            <>
              <MainContent>
                <form onSubmit={handleSubmit}>
                  <FormSection
                    isEditing={isEditing}
                    title={
                      <>
                        {`${t("pages.tools.requestMoney.reportedUsers.reportDetail.reportedNumber")}: `}
                        <GreyText>{report.reportedNumber}</GreyText>
                      </>
                    }
                    rows={[
                      {
                        label: t("pages.tools.requestMoney.reportedUsers.reportDetail.requestID"),
                        displayComponent: <NonEditableText>{report.requestID}</NonEditableText>,
                        editingComponent: <NonEditableText>{report.requestID}</NonEditableText>,
                      },
                      {
                        label: t("pages.tools.requestMoney.reportedUsers.reportDetail.reportedBy"),
                        displayComponent: <NonEditableText>{report.reportedBy}</NonEditableText>,
                        editingComponent: <NonEditableText>{report.reportedBy}</NonEditableText>,
                      },
                      {
                        label: t("pages.tools.requestMoney.reportedUsers.reportDetail.reason"),
                        displayComponent: (
                          <NonEditableText>
                            {t(`pages.tools.requestMoney.reportedUsers.filters.reason.${report.reason}`)}
                          </NonEditableText>
                        ),
                        editingComponent: (
                          <NonEditableText>
                            {t(`pages.tools.requestMoney.reportedUsers.filters.reason.${report.reason}`)}
                          </NonEditableText>
                        ),
                      },
                      {
                        label: t("pages.tools.requestMoney.reportedUsers.reportDetail.reportingDate"),
                        displayComponent: <NonEditableText>{report.reportingDate}</NonEditableText>,
                        editingComponent: <NonEditableText>{report.reportingDate}</NonEditableText>,
                      },
                      {
                        label: t("pages.tools.requestMoney.reportedUsers.reportDetail.updatedOn"),
                        displayComponent: <NonEditableText>{report.updatedOn}</NonEditableText>,
                        editingComponent: <NonEditableText>{report.updatedOn}</NonEditableText>,
                      },
                      {
                        label: t("pages.tools.requestMoney.reportedUsers.reportDetail.status"),
                        displayComponent: (
                          <NonEditableText>
                            <span style={{ color: getReportStatusColor(values.status) }}>
                              {t(`pages.tools.requestMoney.reportedUsers.filters.status.${report.status}`)}
                            </span>
                          </NonEditableText>
                        ),
                        editingComponent: (
                          <>
                            <AutoClosingDropdown
                              //@ts-ignore
                              options={Object.values(ReportStatusEnum)
                                .filter(s => typeof s === "string")
                                .map(status => {
                                  return {
                                    label: (
                                      <span style={{ color: getReportStatusColor(status) }}>
                                        {t(`pages.tools.requestMoney.reportedUsers.filters.status.${status}`)}
                                      </span>
                                    ),
                                    key: status,
                                  };
                                })}
                              dropdownType={DropdownType.RECTANGULAR_NORMAL}
                              selectOption={opt => setFieldValue("status", opt.key)}
                              label={
                                <span style={{ color: getReportStatusColor(values.status) }}>
                                  {t(`pages.tools.requestMoney.reportedUsers.filters.status.${values.status}`)}
                                </span>
                              }
                              hasValue={!!report.status}
                              onChange={handleChange}
                            />
                            {values.status === ReportStatusEnum.ACTION_TAKEN ? (
                              <TextArea
                                maxLength={160}
                                style={{ marginTop: "8px" }}
                                id="reasonStatus"
                                value={values.reasonStatus}
                                onChange={handleChange}
                                autoComplete="off"
                                verticalResize={false}
                                horizontalResize={false}
                              ></TextArea>
                            ) : null}
                          </>
                        ),
                      },
                    ].concat(
                      report.reasonStatus && report.status === ReportStatusEnum.ACTION_TAKEN && !isEditing
                        ? [
                            {
                              label: t("pages.tools.requestMoney.reportedUsers.reportDetail.reasonStatus"),
                              displayComponent: <NonEditableText>{report.reasonStatus}</NonEditableText>,
                              editingComponent: <NonEditableText>{report.reasonStatus}</NonEditableText>,
                            },
                          ]
                        : [],
                    )}
                  />
                </form>
                <form onSubmit={() => null}>
                  <FormSectionRow>
                    <FormSectionTitle>
                      {t("pages.tools.requestMoney.reportedUsers.reportDetail.relatedReports.title")}
                    </FormSectionTitle>
                  </FormSectionRow>
                  {isFetchingRelatedReports ? (
                    <LoadingText>{t("commons.loadingResults")}</LoadingText>
                  ) : (
                    <>
                      <Table
                        values={relatedReports}
                        columns={RequestMoneyUtils.getRelatedReportsColumns()}
                        rowClickAction={(idx, log) => openDetailsPage(log)}
                      />
                      {relatedReports.length > 0 ? (
                        <TablePagination
                          handlePageChange={handlePageChange}
                          totalItems={totalRelatedReports}
                          activePage={page}
                          pageSize={pageSize}
                        />
                      ) : null}
                    </>
                  )}
                </form>
              </MainContent>
              <SideMenu>
                {isEditing ? (
                  <LinkContainer id="save-status" onClick={() => handleSubmit()}>
                    <IconContainer color={styleTheme.palette.digitalGreen}>
                      <CheckIcon />
                    </IconContainer>
                    <span>{t("pages.tools.requestMoney.reportedUsers.reportDetail.saveChanges")}</span>
                  </LinkContainer>
                ) : (
                  <LinkContainer id="edit-status" onClick={() => setIsEditing(true)}>
                    <IconContainer color={styleTheme.palette.turquoiseBlue}>
                      <EditIcon />
                    </IconContainer>
                    <span>{t("pages.tools.requestMoney.reportedUsers.reportDetail.editStatus")}</span>
                  </LinkContainer>
                )}
                <LinkContainer id="move-to-blacklist" onClick={() => null}>
                  <IconContainer color={styleTheme.palette.errorRed}>
                    <BlockIcon />
                  </IconContainer>
                  <span>{t("pages.tools.requestMoney.reportedUsers.reportDetail.moveToBlacklist")}</span>
                </LinkContainer>
              </SideMenu>
            </>
          )}
        />
      </PageContent>
    </PageContainer>
  );
};

export default ReportDetailsPage;

const PageContent = styled("div")`
  display: flex;
  flex-direction: row;
  margin-top: calc(128px + 28px - 50px);
  margin-left: calc(200px + 20px + 24px - 48px);
`;

const MainContent = styled("div")`
  display: flex;
  flex-direction: column;
  width: 60%;
`;

const NonEditableText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${styleTheme.palette.darkGrey};
`;

const GreyText = styled("span")`
  font-family: Vodafone Rg;
  color: ${styleTheme.palette.grey};
`;

const FormSectionRow = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 55px;
  padding-top: 40px;
  padding-bottom: 9px;
`;

const FormSectionTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.black};
`;

const SideMenu = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 55px;
  width: 40%;
  margin-left: 75px;
`;

const LinkContainer = styled("div")<{ disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-family: Vodafone Rg;
  color: ${styleTheme.palette.darkGrey};
  font-size: 16px;
  margin-bottom: 15px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? "none" : "inherit")};

  > div {
    margin-right: 8px;
  }
`;

const IconContainer = styled("div")<{ color: string }>`
  width: 20px;
  height: 20px;
  > svg {
    stroke: ${props => props.color};
  }
`;
