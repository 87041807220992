import React from "react";
import { useTranslation } from "react-i18next";
import { CopyIcon, FormSection, TextInput } from "@wit/mpesa-ui-components";
import styled from "styled-components";
import {
  IGalleryImage,
  IReferralCampaign,
  IScreen,
  ITranslatableObject,
  TypeScreenEnum,
} from "../referral-campaigns.model";
import ReferralCampaignImageGallery from "./referral-campaign-image-gallery.component";
import ImageGalleryComponent from "../../../../shared/components/image-gallery.component";

interface IReceiverScreenFormProps {
  title: React.ReactNode;
  isEditing: boolean;
  language: string;
  values: IReferralCampaign;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  errors: any;
  isLanguageRequired?: boolean;
}

/**
 * CampaignDetailsForm component
 * Can be used to create or edit the campaign details
 */
const ReceiverScreenForm = ({
  title,
  isEditing,
  language: lang,
  values,
  setFieldValue,
  errors,
  isLanguageRequired,
}: IReceiverScreenFormProps) => {
  const [t] = useTranslation();
  const [imgName, setImgName] = React.useState<string | undefined>();
  const [imgUrl, setImgUrl] = React.useState<string>();
  const [showGallery, setShowGallery] = React.useState(false);

  React.useEffect(() => {
    if (findScreen(TypeScreenEnum.RECEIVER)) {
      const image = findScreen(TypeScreenEnum.RECEIVER).urlImage;
      if (image) {
        setImgUrl(image);
        setImgName(image.split("/").pop());
      }
    }
  }, []);

  /**
   * method to find a screen
   */
  const findScreen = (type: TypeScreenEnum): IScreen => {
    return values.screens.find(screen => screen.type === type) as IScreen;
  };

  /**
   * method to set a field at screen array
   */
  const setScreenField = (
    values: IReferralCampaign,
    type: TypeScreenEnum,
    field: string,
    translation: boolean,
    lang: string,
    value: any,
  ) => {
    if (values.screens) {
      const screens = [...values.screens];
      const screen = screens.find(screen => screen.type === type);
      if (!translation) {
        if (screen) {
          screens[screens.indexOf(screen)] = { ...screen, [field]: value };
        } else {
          screens.push({ type, [field]: value } as IScreen);
        }
      } else {
        //@ts-ignore
        if (screen && screen[field] && screen[field].translations) {
          screens[screens.indexOf(screen)] = {
            ...screen,
            //@ts-ignore
            [field]: { translations: { ...screen[field].translations, [lang]: value } },
          };
        } else {
          screens.push({
            type,
            [field]: { translations: { [lang]: value } },
          } as IScreen);
        }
      }
      return screens;
    } else {
      return !translation
        ? [{ type, [field]: value } as IScreen]
        : [{ type, [field]: { translations: { [lang]: value } } } as IScreen];
    }
  };

  /**
   * method to select a gallery image
   */
  const selectGalleryImage = (image: IGalleryImage) => {
    setImgUrl(image.url);
    setImgName(image.filename);
    setFieldValue("screens", setScreenField(values, TypeScreenEnum.RECEIVER, "urlImage", false, lang, image.url));
  };

  /**
   * method to remove a gallery image
   */
  const removeImage = () => {
    setImgUrl(undefined);
    setImgName(undefined);
    setFieldValue("screens", setScreenField(values, TypeScreenEnum.RECEIVER, "urlImage", false, lang, undefined));
  };

  /**
   * method to open gallery
   */
  const displayGallery = () => {
    setShowGallery(true);
  };

  /**
   * method to hide gallery
   */
  const hideGallery = () => {
    setShowGallery(false);
  };

  return (
    <>
      <FormSection
        isEditing={isEditing}
        title={title}
        rows={[
          {
            label: t("pages.referralCampaigns.createCampaign.receiverScreen.headerTitleScreen"),
            displayComponent:
              findScreen(TypeScreenEnum.RECEIVER) &&
              findScreen(TypeScreenEnum.RECEIVER).headerTitle &&
              (findScreen(TypeScreenEnum.RECEIVER).headerTitle as ITranslatableObject).translations[lang] ? (
                <NonEditableText>
                  {(findScreen(TypeScreenEnum.RECEIVER).headerTitle as ITranslatableObject).translations[lang]}
                </NonEditableText>
              ) : null,
            editingComponent: (
              <TextInput
                name="header-title"
                maxLength={25}
                value={
                  values.screens &&
                  findScreen(TypeScreenEnum.RECEIVER) &&
                  findScreen(TypeScreenEnum.RECEIVER).headerTitle
                    ? (findScreen(TypeScreenEnum.RECEIVER).headerTitle as ITranslatableObject).translations[lang] || ""
                    : null
                }
                placeholder={`${t(
                  "pages.referralCampaigns.createCampaign.receiverScreen.headerTitleScreen",
                )} (${lang.toUpperCase()})`}
                required={isLanguageRequired}
                onChange={e =>
                  setFieldValue(
                    `screens`,
                    setScreenField(values, TypeScreenEnum.RECEIVER, "headerTitle", true, lang, e.target.value),
                  )
                }
                error={
                  errors.screens &&
                  errors.screens.find((error: IScreen) => error && error.headerTitle) &&
                  errors.screens.find((error: IScreen) => error && error.headerTitle).headerTitle &&
                  errors.screens.find((error: IScreen) => error && error.headerTitle).headerTitle.translations &&
                  findScreen(TypeScreenEnum.RECEIVER).headerTitle &&
                  (findScreen(TypeScreenEnum.RECEIVER).headerTitle as ITranslatableObject).translations &&
                  !(findScreen(TypeScreenEnum.RECEIVER).headerTitle as ITranslatableObject).translations[lang]
                    ? errors.screens.find((error: IScreen) => error && error.headerTitle).headerTitle.translations[lang]
                    : undefined
                }
              />
            ),
            required: isLanguageRequired,
          },
          {
            label: (
              <FormLabelContainer>
                <FormLabel required={isEditing && isLanguageRequired}>
                  {t("pages.referralCampaigns.createCampaign.receiverScreen.titleScreen")}
                </FormLabel>
                <FormLabelLimit>
                  {(findScreen(TypeScreenEnum.RECEIVER).title as ITranslatableObject).translations[lang] &&
                    (findScreen(TypeScreenEnum.RECEIVER).title as ITranslatableObject).translations[lang].length +
                      t("pages.referralCampaigns.sixtyLimit")}
                </FormLabelLimit>
              </FormLabelContainer>
            ),
            displayComponent:
              findScreen(TypeScreenEnum.RECEIVER) &&
              findScreen(TypeScreenEnum.RECEIVER).title &&
              (findScreen(TypeScreenEnum.RECEIVER).title as ITranslatableObject).translations[lang] ? (
                <NonEditableText>
                  {(findScreen(TypeScreenEnum.RECEIVER).title as ITranslatableObject).translations[lang]}
                </NonEditableText>
              ) : null,
            editingComponent: (
              <TextInput
                name="title"
                value={
                  values.screens && findScreen(TypeScreenEnum.RECEIVER) && findScreen(TypeScreenEnum.RECEIVER).title
                    ? (findScreen(TypeScreenEnum.RECEIVER).title as ITranslatableObject).translations[lang] || ""
                    : null
                }
                placeholder={`${t(
                  "pages.referralCampaigns.createCampaign.receiverScreen.titleScreen",
                )} (${lang.toUpperCase()})`}
                required={isLanguageRequired}
                onChange={e =>
                  setFieldValue(
                    `screens`,
                    setScreenField(values, TypeScreenEnum.RECEIVER, "title", true, lang, e.target.value),
                  )
                }
                error={
                  errors.screens &&
                  errors.screens.find((error: IScreen) => error && error.title) &&
                  errors.screens.find((error: IScreen) => error && error.title).title &&
                  errors.screens.find((error: IScreen) => error && error.title).title.translations &&
                  findScreen(TypeScreenEnum.RECEIVER).title &&
                  (findScreen(TypeScreenEnum.RECEIVER).title as ITranslatableObject).translations &&
                  !(findScreen(TypeScreenEnum.RECEIVER).title as ITranslatableObject).translations[lang]
                    ? errors.screens.find((error: IScreen) => error && error.title).title.translations[lang]
                    : undefined
                }
                maxLength={60}
              />
            ),
            required: isLanguageRequired,
          },
          {
            label: (
              <FormLabelContainer>
                <FormLabel required={isEditing && isLanguageRequired}>
                  {t("pages.referralCampaigns.createCampaign.receiverScreen.descriptionScreen")}
                </FormLabel>
                <FormLabelLimit>
                  {(findScreen(TypeScreenEnum.RECEIVER).description as ITranslatableObject).translations[lang] &&
                    (findScreen(TypeScreenEnum.RECEIVER).description as ITranslatableObject).translations[lang].length +
                      t("pages.referralCampaigns.oneFiftyLimit")}
                </FormLabelLimit>
              </FormLabelContainer>
            ),
            displayComponent:
              findScreen(TypeScreenEnum.RECEIVER) &&
              findScreen(TypeScreenEnum.RECEIVER).description &&
              (findScreen(TypeScreenEnum.RECEIVER).description as ITranslatableObject).translations[lang] ? (
                <NonEditableText>
                  {(findScreen(TypeScreenEnum.RECEIVER).description as ITranslatableObject).translations[lang]}
                </NonEditableText>
              ) : null,
            editingComponent: (
              <TextInput
                name="description"
                value={
                  values.screens &&
                  findScreen(TypeScreenEnum.RECEIVER) &&
                  findScreen(TypeScreenEnum.RECEIVER).description
                    ? (findScreen(TypeScreenEnum.RECEIVER).description as ITranslatableObject).translations[lang] || ""
                    : null
                }
                placeholder={`${t(
                  "pages.referralCampaigns.createCampaign.receiverScreen.descriptionScreen",
                )} (${lang.toUpperCase()})`}
                required={isLanguageRequired}
                onChange={e =>
                  setFieldValue(
                    `screens`,
                    setScreenField(values, TypeScreenEnum.RECEIVER, "description", true, lang, e.target.value),
                  )
                }
                error={
                  errors.screens &&
                  errors.screens.find((error: IScreen) => error && error.description) &&
                  errors.screens.find((error: IScreen) => error && error.description).description &&
                  errors.screens.find((error: IScreen) => error && error.description).description.translations &&
                  findScreen(TypeScreenEnum.RECEIVER).description &&
                  (findScreen(TypeScreenEnum.RECEIVER).description as ITranslatableObject).translations &&
                  !(findScreen(TypeScreenEnum.RECEIVER).description as ITranslatableObject).translations[lang]
                    ? errors.screens.find((error: IScreen) => error && error.description).description.translations[lang]
                    : undefined
                }
                maxLength={150}
              />
            ),
            required: isLanguageRequired,
          },
          {
            label: t("pages.referralCampaigns.createCampaign.receiverScreen.imageScreen"),
            displayComponent: <ImageGalleryComponent imageUrl={imgUrl} />,
            editingComponent: (
              <ImageGalleryComponent
                imageUrl={imgUrl}
                imageName={imgName}
                title={t("pages.referralCampaigns.createCampaign.receiverScreen.openGallery")}
                mainIcon={<CopyIcon />}
                actionFunctionOpenGallery={() => displayGallery()}
                actionFunctionRemoveSelectedImage={() => removeImage()}
                imageGalleryContainerId={"open-receiver-gallery"}
                errors={errors}
              />
            ),
            required: true,
          },
        ]}
      />
      {showGallery ? <ReferralCampaignImageGallery selectImage={selectGalleryImage} hideDrawer={hideGallery} /> : null}
    </>
  );
};

export default ReceiverScreenForm;

const FormLabelContainer = styled("span")`
  display: flex;
  flex-direction: column;
`;

const FormLabel = styled("span")<{ required?: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.greyDarker};

  ${props =>
    props.required
      ? `
  ::after {
    content: ' *';
    font-family: Vodafone Rg;
    font-size: 16px;
    font-weight: normal;
    color: ${props.theme.palette.red.normal};
  }`
      : ""}
`;

const FormLabelLimit = styled("span")`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: ${props => props.theme.palette.greyDarker};
`;

const NonEditableText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.darkGrey};
`;
