import React from "react";
import { useDropzone, DropzoneOptions } from "react-dropzone";
import styled from "styled-components";

interface IDropzoneOptionsProps extends DropzoneOptions {
  imageSrc: string;
  dropText?: string;
  releaseText?: string;
  roundedImage?: boolean;
  inputName?: string;
  error?: boolean;
}

const Dropzone = ({
  onDrop,
  accept,
  multiple,
  imageSrc,
  dropText = "",
  releaseText = "",
  roundedImage = true,
  inputName = "dropzoneInput",
  error = false,
}: IDropzoneOptionsProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple,
  });
  return (
    <DropzoneDiv active={isDragActive} hasError={error} {...getRootProps()}>
      <DropzoneInput name={inputName} {...getInputProps()} />
      <DropzoneInsideDiv>
        <TopDiv>{isDragActive ? <span>{releaseText}</span> : <span>{dropText}</span>}</TopDiv>
        <BottomDiv roundedImage={roundedImage}>
          <ImageWrapper backgroundImage={imageSrc} roundedImage={roundedImage} active={isDragActive}></ImageWrapper>
        </BottomDiv>
      </DropzoneInsideDiv>
    </DropzoneDiv>
  );
};

export default Dropzone;

const DropzoneDiv = styled("div")<{ active: boolean; hasError: boolean }>`
  height: 195px;
  ${props => (!props.hasError ? "border: 1px solid #ebebeb;" : `border: 1px solid ${props.theme.palette.vodafoneRed};`)}
  border-radius: 7px;
  outline: none;
  cursor: pointer;
  display: flex;
  background-color: aliceblue;
  margin-bottom: 10px;
  ${props =>
    props.active ? `background-color: ${props.theme.palette.vodafoneRed}66;border: 1px solid #999999;` : null};
`;

const ImageWrapper = styled("div")<{
  backgroundImage: string;
  roundedImage: boolean;
  active: boolean;
}>`
  ${props =>
    props.roundedImage
      ? `border-radius: 50%; border: 1px solid #ebebeb; ${
          props.active ? `background-color:${props.theme.palette.vodafoneRed}bb;` : "background-color: lightskyblue;"
        }`
      : null};
  height: ${props => (props.roundedImage ? "130px" : "100%")};
  width: ${props => (props.roundedImage ? "130px" : "100%")};
  overflow: hidden;
  margin: auto;
  background-image: ${props => `url(${props.backgroundImage})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  ${props => (!props.roundedImage ? "border-radius: 0px 0px 7px 7px;background-position: center;" : null)};
`;

const DropzoneInput = styled("input")``;

const DropzoneInsideDiv = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: Vodafone Rg;
`;

const TopDiv = styled("div")`
  padding: 7px;
  background-color: white;
  border-radius: 7px 7px 0px 0px;
`;
const BottomDiv = styled("div")<{
  roundedImage: boolean;
}>`
  margin: auto;
  ${props => (!props.roundedImage ? "height: 100%; width:100%;" : null)};
`;
