import {
  DownloadIcon,
  EditIcon,
  FastActionButton,
  ModalTypeEnum,
  PageTitle,
  PublishIcon,
  SearchBar,
  Table,
  TrashIcon,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import { Breadcrumb } from "@wit/mpesa-ui-components/lib/types";
import i18next, { TFunction } from "i18next";
import React, { ReactNode, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { IStoreInterface } from "../../../../configs/store.config";
import { RoutesEnum } from "../../../../routes/routes.constants";
import SafaricomDeleteModal from "../../../../shared/components/safaricom-delete-modal/safaricom-delete-modal.component";
import { SearchBarFilterContainer } from "../../../../shared/responsive-ui.styled";
import { IconContainer, LoadingText, PageContainer } from "../../../../shared/shared.styled";
import { isSFCMarket, TableButtonsContainer } from "../../../../shared/shared.utils";
import CreateNewSurvey from "../components/create-survey.component";
import EditSurveyModal from "../components/modals/edit-survey.modal";
import PublishSurveyModal from "../components/modals/publish-survey.modal";
import SurveysApi, { ISurvey } from "../surveys.api";
import "../surveys.css";
import { SurveysActions } from "../surveys.store";
import { SurveysUtils } from "../surveys.utils";

/**
 * function to getdata for breadcrumbs
 * @param t
 */
const getBreadcrumbs = (t: TFunction): Breadcrumb[] => {
  return [
    {
      label: t("pages.tools.title"),
      url: RoutesEnum.TOOLS,
    },
    {
      label: t("pages.tools.surveys.title"),
      url: "",
    },
  ];
};

/**
 * Survey page component
 */
const SurveysPage = () => {
  const themeContext = useContext(ThemeContext);
  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showAlert, , setAlertProps] = useAlert();

  /*Surveys store*/
  const storeSurveys = useSelector((state: IStoreInterface) => state.surveysReducer.surveys);

  /*Surveys list*/
  const [surveys, setSurveys] = React.useState(storeSurveys);

  /*Search query*/
  const [searchQuery, setSearchQuery] = React.useState("");

  /*Loading state*/
  const [isLoading, setIsLoading] = React.useState(false);

  /*Selected survey*/
  const [selectedSurvey, setSelectedSurvey] = React.useState<ISurvey | undefined>(undefined);

  /*
   * Confirmation modal
   * */
  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );

  /**
   * Edit survey modal
   * @param survey
   */
  const _hideEditSurveyModal = (survey?: ISurvey) => {
    hideEditSurveyModal();
    if (survey) {
      publishSurveyModal(survey);
    }
  };

  const [showEditSurveyModal, hideEditSurveyModal, setModalProps] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <EditSurveyModal survey={selectedSurvey as ISurvey} hideModal={_hideEditSurveyModal} />,
  );

  const [deleteModal, setDeleteModal] = React.useState<ReactNode>();
  const [showDeleteSurveyModalSFC, hideDeleteSurveyModalSFC] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    deleteModal,
  );

  React.useEffect(() => {
    setModalProps({ modalStyles: { width: 600 } });
  }, [setModalProps]);

  /**
   * Publish survey modal
   */
  const _hidePublishSurveyModal = () => {
    hidePublishSurveyModal();
  };

  const [showPublishSurveyModal, hidePublishSurveyModal] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <PublishSurveyModal survey={selectedSurvey as ISurvey} hideModal={_hidePublishSurveyModal} />,
  );

  /*
   * Get surveys
   * */
  React.useEffect(() => {
    setIsLoading(true);
    SurveysApi.methods
      .getSurveys()
      .finally(() => setIsLoading(false))
      .then(res => {
        dispatch(SurveysActions.creators.fetchSurveysSuccessAction(res.data));
      });
  }, [dispatch]);

  /*
   * Search query
   * */
  React.useEffect(() => {
    let surveysClone = [...storeSurveys];

    if (!!searchQuery) {
      surveysClone = surveysClone.filter(s => s.name.toLowerCase().includes(searchQuery.toLowerCase()));
    }
    setSurveys(surveysClone);
  }, [storeSurveys, searchQuery]);

  /**
   * function to delete survey actions
   * @param survey
   */
  const deleteSurvey = (survey: ISurvey) => {
    SurveysApi.methods.deleteSurvey(survey.id).then(() => {
      dispatch(SurveysActions.creators.deleteSurveyAction(survey.id));
      hideConfirmationModal();
      setAlertProps({
        title: t("pages.tools.surveys.modal.delete.success"),
        type: AlertTypeEnum.SUCCESS,
      });
      showAlert();
    });
  };

  /**
   * function to show the delete Survey modal
   * @param survey
   */
  const showDeleteSurveyModal = (survey: ISurvey) => {
    if (isSFCMarket()) {
      setDeleteModal(
        <SafaricomDeleteModal
          secondaryAction={() => hideDeleteSurveyModalSFC()}
          primaryAction={() => deleteSurvey(survey)}
          title={t("pages.tools.surveys.modal.delete.title")}
          description={t("pages.tools.surveys.modal.delete.description")}
          primaryBtnLabel={t("pages.tools.surveys.modal.delete.buttonLabel")}
        />,
      );
      showDeleteSurveyModalSFC();
    } else {
      setConfirmationProps({
        title: t("pages.tools.surveys.modal.delete.title"),
        description: t("pages.tools.surveys.modal.delete.description"),
        primaryBtnLabel: t("pages.tools.surveys.table.fastActions.delete"),
        primaryAction: () => deleteSurvey(survey),
        secondaryAction: () => hideConfirmationModal(),
      } as BaseModalProps);
      showConfirmationModal();
    }
  };

  /**
   * function to edit survey actions
   * @param survey
   */
  const editSurveyModal = (survey: ISurvey) => {
    setSelectedSurvey(survey);
    showEditSurveyModal();
  };

  /**
   * function to publish survey actions
   * @param survey
   */
  const publishSurveyModal = (survey: ISurvey) => {
    setSelectedSurvey(survey);
    showPublishSurveyModal();
  };

  /**
   * function to handler the csv download
   * @param survey
   */
  const downloadCsvHandler = (survey: ISurvey) => {
    const fileDownload = require("js-file-download");
    SurveysApi.methods.downloadSurvey(survey.id).then(
      res => {
        fileDownload(res.data, "export.csv");
      },
      err => console.error(err),
    );
  };

  /*
   * Component render
   * */
  return (
    <PageContainer>
      <PageTitle
        breadcrumbs={getBreadcrumbs(t)}
        title={t("pages.tools.surveys.title")}
        navigateFunction={history.push}
        goBackFn={() => window.history.back()}
        separatorStyle={{ display: "none" }}
        titleContainerStyle={{ marginTop: "13px", marginBottom: "34px" }}
        iconContainerStyle={{ stroke: themeContext.palette.vodafoneRed }}
      />
      {isLoading ? (
        <LoadingText>{t("commons.loadingResults")}</LoadingText>
      ) : (
        <>
          <CreateNewSurvey />
          <FiltersContainer>
            <SearchBarFilterContainer>
              <SearchBar
                placeholderLabel={t("pages.tools.surveys.searchBarPlaceholder")}
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
                clearValue={() => setSearchQuery("")}
              />
            </SearchBarFilterContainer>
          </FiltersContainer>
          <Table<ISurvey>
            columns={SurveysUtils.getTableColumns()}
            values={surveys}
            displayActions={survey =>
              renderFastActions<ISurvey>(
                survey,
                {
                  edit: { onClick: editSurveyModal },
                  delete: { onClick: showDeleteSurveyModal },
                  publish: { onClick: publishSurveyModal },
                  downloadCsv: { onClick: downloadCsvHandler },
                },
                themeContext,
              )
            }
          />
        </>
      )}
    </PageContainer>
  );
};

/*
 * Row fast actions
 * */
const renderFastActions = <T extends unknown>(
  value: T,
  buttonActions: {
    edit: { onClick: (row: T) => any };
    delete: { onClick: (row: T) => any };
    publish: { onClick: (row: T) => any };
    downloadCsv: { onClick: (row: T) => any };
  },
  themeContext: any,
) => {
  return (
    <TableButtonsContainer>
      <FastActionButton
        containerClassName={"download-btn"}
        iconComponent={
          <IconContainer size={16} color={themeContext.palette.turquoise}>
            <DownloadIcon />
          </IconContainer>
        }
        onClick={() => buttonActions.downloadCsv.onClick(value)}
        label={i18next.t("pages.tools.surveys.table.fastActions.downloadCsv")}
      />

      {!(value as ISurvey).publishedOn ? (
        <FastActionButton
          iconComponent={
            <IconContainer size={16} color={themeContext.palette.vodafoneRed}>
              <PublishIcon />
            </IconContainer>
          }
          onClick={() => buttonActions.publish.onClick(value)}
          label={i18next.t("pages.tools.surveys.table.fastActions.publish")}
        />
      ) : null}

      {!(value as ISurvey).publishedOn ? (
        <FastActionButton
          iconComponent={
            <IconContainer size={16} color={themeContext.palette.turquoiseBlue}>
              <EditIcon />
            </IconContainer>
          }
          onClick={() => buttonActions.edit.onClick(value)}
          label={i18next.t("pages.tools.surveys.table.fastActions.edit")}
        />
      ) : null}
      <FastActionButton
        iconComponent={
          <IconContainer size={16} color={themeContext.palette.errorRed}>
            <TrashIcon />
          </IconContainer>
        }
        onClick={() => buttonActions.delete.onClick(value)}
        label={i18next.t("pages.tools.surveys.table.fastActions.delete")}
      />
    </TableButtonsContainer>
  );
};

const FiltersContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 23px;
  margin-bottom: 23px;
`;

export default SurveysPage;
