import { EditIcon, FastActionButton, TrashIcon, UndoIcon } from "@wit/mpesa-ui-components";
import { ColumnProps } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import i18next, { TFunction } from "i18next";
import React, { useState } from "react";
import styled from "styled-components";
import { IBusiness } from "../../../shared/models/business.model";
import { ICategory } from "../../../shared/models/category.model";
import { IconContainer } from "../../../shared/shared.styled";
import { isSFCMarket, TableButtonsContainer } from "../../../shared/shared.utils";
import { BusinessesAPI } from "./business.api";
import { BusinessesActions } from "./business.store";

export enum BusinessStatusEnum {
  LIVE = "LIVE",
  ENABLE = "ENABLE",
  DISABLE = "DISABLE",
  DISABLED = "DISABLED",
  EDIT = "EDIT",
  DELETE = "DELETE",
}

/**
 * Get status color
 * @param status
 * @returns
 */
export const getStatusColor = (status: BusinessStatusEnum) => {
  switch (status) {
    case BusinessStatusEnum.LIVE:
    case BusinessStatusEnum.ENABLE:
      return styleTheme.palette.successGreen;
    case BusinessStatusEnum.DISABLE:
    case BusinessStatusEnum.DISABLED:
      return styleTheme.palette.greyDarker;
    case BusinessStatusEnum.DELETE:
      return styleTheme.palette.digitalRed;
  }

  return styleTheme.palette.black;
};

export const icons = [
  "Add Ons",
  "Add Plus",
  "Admin",
  "All Rewards",
  "Arrow Left",
  "Bank",
  "Bill Report",
  "Calendar",
  "Chat Social",
  "Chevron Right",
  "Clock",
  "Close",
  "Contact Us",
  "Country",
  "Diapal",
  "Document",
  "Edit",
  "Help",
  "Hide Password",
  "Home",
  "Insights",
  "Mail",
  "Mobile",
  "Money",
  "Organization",
  "Phone",
  "Profile",
  "Refound",
  "Search",
  "Settings",
  "Store",
  "Sync",
  "Tablet",
  "Top Up",
];

/**
 * Get icon name
 * @param name
 * @returns
 */
export const getIconName = (name: string) => {
  return `${name.toLowerCase().replace(/ /g, "_")}.png`;
};

/**
 * Render Business Table Buttons
 * @param value
 * @param buttonActions
 * @returns
 */
export const renderTableBusinessButtons = <T extends unknown>(
  value: T,
  buttonActions: {
    edit: { onClick: (row: T) => any };
    delete?: { onClick: (row: T) => any };
    revert?: { onClick: (row: T) => any };
  },
) => {
  return (
    <TableButtonsContainer>
      {(value as IBusiness).status === BusinessStatusEnum.DELETE ? (
        buttonActions && buttonActions.revert ? (
          <FastActionContainer id={`${((value as IBusiness).name as any).en}-restore-button`}>
            <FastActionButton
              iconComponent={
                <IconContainer size={16} color={styleTheme.palette.successGreen}>
                  <UndoIcon />
                </IconContainer>
              }
              onClick={() => buttonActions.revert && buttonActions.revert.onClick(value)}
              label={i18next.t("pages.support.envProperties.table.buttons.restore")}
            />
          </FastActionContainer>
        ) : (
          <></>
        )
      ) : (
        <>
          <FastActionContainer
            id={
              (value as IBusiness).name && ((value as IBusiness).name as any).en
                ? `${((value as IBusiness).name as any).en}-edit-button`
                : `${(value as IBusiness).name}-edit-button`
            }
          >
            <FastActionButton
              iconComponent={
                <IconContainer size={16} color={styleTheme.palette.turquoiseBlue}>
                  <EditIcon />
                </IconContainer>
              }
              onClick={() => buttonActions.edit.onClick(value)}
              label={i18next.t("pages.support.envProperties.table.buttons.edit")}
            />
          </FastActionContainer>

          {buttonActions && buttonActions.delete ? (
            <FastActionContainer
              id={
                (value as IBusiness).name && ((value as IBusiness).name as any).en
                  ? `${((value as IBusiness).name as any).en}-delete-button`
                  : `${(value as IBusiness).name}-delete-button`
              }
            >
              <FastActionButton
                iconComponent={
                  <IconContainer size={16} color={styleTheme.palette.vodafoneRed}>
                    <TrashIcon />
                  </IconContainer>
                }
                onClick={() => buttonActions && buttonActions.delete && buttonActions.delete.onClick(value)}
                label={i18next.t("pages.support.envProperties.table.buttons.delete")}
              />
            </FastActionContainer>
          ) : (
            <></>
          )}
        </>
      )}
    </TableButtonsContainer>
  );
};

/**
 * Render row content
 * @param content
 * @param deleted
 * @param edited
 * @param hideOnHover
 * @returns
 */
const renderContent = (content: any, deleted: boolean, edited: boolean | undefined, hideOnHover = true) => (
  <ContentWrapper deleted={deleted} className={hideOnHover ? "status-content" : ""}>
    <div style={{ paddingRight: "4px", overflow: "hidden", textOverflow: "ellipsis" }}>
      {edited && (
        <IconCont size={16} color={styleTheme.palette.darkGrey}>
          <EditIcon />
        </IconCont>
      )}
      <span>{content}</span>
    </div>
  </ContentWrapper>
);

/**
 * Delete Business
 * @param row
 * @returns
 */
const getDeleted = (row: IBusiness) => {
  return (
    row.status === BusinessStatusEnum.DELETE ||
    row.status === BusinessStatusEnum.DISABLE ||
    row.status === BusinessStatusEnum.DISABLED
  );
};

/**
 * Render Business Status
 * @param newStatus
 * @param t
 * @returns
 */
export const renderStatus = (newStatus: BusinessStatusEnum, t: TFunction) => {
  switch (newStatus) {
    case BusinessStatusEnum.DISABLED:
      return (
        <Status
          color={styleTheme.palette.white}
          background={styleTheme.palette.black}
          border={styleTheme.palette.black}
          type={newStatus}
          id="status-disabled"
        >
          <span>{t("pages.configurations.businessTab.status.deactivated")}</span>
        </Status>
      );
    case BusinessStatusEnum.DISABLE:
      return (
        <Status
          color={styleTheme.palette.black}
          background={styleTheme.palette.white}
          border={styleTheme.palette.black}
          type={newStatus}
          id="status-disabled"
        >
          <span>{t("pages.configurations.businessTab.status.deactivated")}</span>
        </Status>
      );
    case BusinessStatusEnum.LIVE:
      return (
        <Status
          color={styleTheme.palette.white}
          background={styleTheme.palette.successGreen}
          border={styleTheme.palette.successGreen}
          type={newStatus}
          id="status-live"
        >
          <span>{t("pages.configurations.businessTab.status.live")}</span>
        </Status>
      );
    case BusinessStatusEnum.ENABLE:
    case BusinessStatusEnum.EDIT:
      if (isSFCMarket()) {
        return (
          <Status
            color={styleTheme.palette.white}
            background={styleTheme.palette.freshOrange}
            border={styleTheme.palette.freshOrange}
            type={newStatus}
            id="status-live"
          >
            <span>{t("pages.configurations.businessTab.status.draft")}</span>
          </Status>
        );
      }

      return (
        <Status
          color={styleTheme.palette.successGreen}
          background={styleTheme.palette.white}
          border={styleTheme.palette.successGreen}
          type={newStatus}
          id="status-live"
        >
          <span>{t("pages.configurations.businessTab.status.live")}</span>
        </Status>
      );
  }
};

/**
 * Render General Business Status
 * @param newStatus
 * @param t
 * @returns
 */
export const renderGeneralStatus = (newStatus: BusinessStatusEnum, t: TFunction) => {
  switch (newStatus) {
    case BusinessStatusEnum.LIVE:
      return (
        <Status
          color={styleTheme.palette.white}
          background={styleTheme.palette.successGreen}
          border={styleTheme.palette.successGreen}
          type={newStatus}
        >
          <span>{t("pages.configurations.businessTab.status.live")}</span>
        </Status>
      );
    case BusinessStatusEnum.EDIT:
      if (isSFCMarket()) {
        return (
          <Status
            color={styleTheme.palette.white}
            background={styleTheme.palette.freshOrange}
            border={styleTheme.palette.freshOrange}
            type={newStatus}
          >
            <span>{t("pages.configurations.businessTab.status.draft")}</span>
          </Status>
        );
      }
      return (
        <Status
          color={styleTheme.palette.white}
          background={styleTheme.palette.freshOrange}
          border={styleTheme.palette.freshOrange}
          type={newStatus}
        >
          <span>{t("pages.configurations.businessTab.status.edit")}</span>
        </Status>
      );
  }
};

/**
 * Get New Business Data
 * @param dispatch
 * @param showAlert
 * @param setAlertProps
 * @param t
 * @returns
 */
export const getNewData = (dispatch: any, showAlert: any, setAlertProps: any, t: any) => {
  return BusinessesAPI.methods.getBusinesses().then(
    (res: any) => {
      const categories = (res.data.categories as ICategory[]).map(category => {
        return {
          ...category,
          parentCategory: category.parentCategory ? category.parentCategory : null,
        };
      });

      dispatch(BusinessesActions.creators.getBusinessesSuccessAction(res.data.businesses));
      dispatch(BusinessesActions.creators.getBusinessesStatusSuccessAction(res.data.status));
      dispatch(BusinessesActions.creators.getCategoriesSuccessAction(categories));

      if (!isSFCMarket()) {
        return BusinessesAPI.methods.getLastVersion().then(
          resLast => {
            dispatch(BusinessesActions.creators.getLastVersionSuccessAction(resLast.data));
          },
          _err => {
            setAlertProps({
              title: t("pages.configurations.businessTab.errors.getBusinessesError"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          },
        );
      }
    },
    _err => {
      setAlertProps({
        title: t("pages.configurations.businessTab.errors.getBusinessesError"),
        type: AlertTypeEnum.ERROR,
      });
      showAlert();
    },
  );
};

/**
 * Renderer the business icon
 * @param row
 * @returns {JSX.Element}
 */
const LazyLoadingImage = ({ row }: any): JSX.Element => {
  const [url, setUrl] = useState<boolean>(false);
  const deleted = getDeleted(row);
  const icon = row.urlIcon;
  const Img = new Image();
  Img.src = `${icon}?time=${new Date().valueOf()}`;
  Img.onload = function() {
    setUrl(true);
  };

  if (url) {
    if (icon.startsWith("http")) {
      return <Logo deleted={deleted} url={`${icon}?time=${new Date().valueOf()}`} id={`business-${row.name}`} />;
    } else {
      return (
        <Logo
          deleted={deleted}
          url={`./imagesuploaded/${icon}?time=${new Date().valueOf()}`}
          id={`business-${row.name}`}
        />
      );
    }
  } else if (icon && icon.startsWith("data") && isSFCMarket()) {
    return <Logo url={icon} deleted={deleted} id={`business-${row.name}`} />;
  }

  return <Logo deleted={deleted} url={"./images/organization.png"} id={`business-${row.name}`} />;
};

/**
 * Get Business list columns
 * @param t
 * @param categories
 * @param lastVersion
 * @param showCategories
 * @returns
 */
export const getBusinessColumns = <T extends unknown>(
  t: TFunction,
  categories: ICategory[],
  lastVersion: IBusiness[],
  showCategories: Boolean,
): ColumnProps[] => {
  /**
   * Find last version
   * @param id
   * @returns
   */
  const findLastVersion = (id: number | undefined) => {
    if (!id) {
      return undefined;
    }
    return lastVersion.find(b => b.id === id) || null;
  };

  /**
   * Get edited business status
   * @param row
   * @returns
   */
  const getEditedStatus = (row: IBusiness) => {
    const lastVersion = findLastVersion(row.id);
    return (
      (lastVersion && lastVersion.status === BusinessStatusEnum.LIVE && row.status === BusinessStatusEnum.DISABLE) ||
      (lastVersion && lastVersion.status === BusinessStatusEnum.DISABLED && row.status === BusinessStatusEnum.ENABLE)
    );
  };

  const columns: ColumnProps[] = [
    {
      formKey: "urlIcon",
      label: "",
      isEditable: false,
      ratio: 1 / 10,
      changeFunction: (content: string, row: IBusiness) => {
        const deleted = getDeleted(row);
        return (
          <ContentWrapper
            id={`business-${row.name}`}
            popular={row.popular}
            deleted={deleted}
            style={{ justifyContent: "start" }}
          >
            <LazyLoadingImage row={row} />
          </ContentWrapper>
        );
      },
    },
    {
      formKey: "shortCode",
      label: t("pages.configurations.businessTab.headers.shortCode"),
      isEditable: false,
      ratio: isSFCMarket() ? 2 / 10 : 1 / 10,
      alignText: "left",
      changeFunction: (content, row: IBusiness) => {
        const lastVersion = findLastVersion(row.id);
        const edited = (lastVersion && lastVersion.shortCode !== row.shortCode) || false;
        const deleted = getDeleted(row);
        return renderContent(content, deleted, edited, false);
      },
    },
    {
      formKey: "name",
      label: t("pages.configurations.businessTab.headers.name"),
      isEditable: false,
      ratio: isSFCMarket() ? 3 / 10 : 2 / 10,
      alignText: "left",

      changeFunction: (content, row: IBusiness) => {
        const lastVersion = findLastVersion(row.id);
        const edited = (lastVersion && lastVersion.name !== row.name) || false;
        const deleted = getDeleted(row);
        return renderContent(content, deleted, edited, false);
      },
    },
  ];

  if (!isSFCMarket()) {
    columns.push({
      formKey: "amount",
      label: t("pages.configurations.businessTab.headers.amount"),
      isEditable: false,
      ratio: 1 / 10,
      alignText: "left",

      changeFunction: (content, row: IBusiness) => {
        const lastVersion = findLastVersion(row.id);
        const edited = (lastVersion && lastVersion.amount !== row.amount) || false;
        const deleted = getDeleted(row);
        return renderContent(content, deleted, edited, false);
      },
    });
  }

  if (!isSFCMarket()) {
    columns.push({
      formKey: "billReference",
      label: t("pages.configurations.businessTab.headers.billReference"),
      isEditable: false,
      ratio: 1 / 10,
      alignText: "left",
      changeFunction: (content, row: IBusiness) => {
        const lastVersion = findLastVersion(row.id);
        const edited = (lastVersion && lastVersion.billReference !== row.billReference) || false;
        const deleted = getDeleted(row);
        return renderContent(content, deleted, edited, false);
      },
    });
  }
  if (showCategories) {
    columns.push({
      formKey: "categoryId",
      label: t("pages.configurations.businessTab.headers.category"),
      isEditable: false,
      ratio: 2 / 10,
      alignText: "left",

      changeFunction: (content, row: IBusiness) => {
        const category = categories.find(c => c.id === content);
        const lastVersion = findLastVersion(row.id);

        const edited = (lastVersion && lastVersion.categoryId !== row.categoryId) || false;
        const name = category && category.name ? category.name.en : "";
        const deleted = getDeleted(row);
        return renderContent(name, deleted, edited, false);
      },
    });
  }
  columns.push({
    formKey: "status",
    label: t("pages.configurations.businessTab.headers.status"),
    isEditable: false,
    ratio: 1 / 10,
    alignText: "left",
    changeFunction: (_content, row: IBusiness) => {
      const edited = getEditedStatus(row);
      const deleted = getDeleted(row);
      const liveContent = () => (deleted ? renderStatus(row.status, t) : renderStatus(row.status, t));
      return (
        <StatusWrapper deleted={deleted} className={!isSFCMarket() ? "status-content" : ""}>
          <div style={{ display: "flex" }}>
            {edited && (
              <IconCont size={16} color={styleTheme.palette.darkGrey}>
                <EditIcon />
              </IconCont>
            )}
            <div>{liveContent()}</div>
          </div>
        </StatusWrapper>
      );
    },
  });
  columns.push({
    formKey: "popular",
    label: t(" "),
    isEditable: false,
    ratio: 1 / 10,
    changeFunction: (_content, row: IBusiness) => {
      const lastVersion = findLastVersion(row.id);
      const edited = (lastVersion && lastVersion.popular !== row.popular) || false;
      const isNewPopular = edited && row.popular;
      const toBeDeleted = row.status === BusinessStatusEnum.DELETE;
      return (
        <div>
          {toBeDeleted && (
            <Deleted className="status-content deleted-business">
              {t("pages.configurations.businessTab.deleted")}
            </Deleted>
          )}
          {!toBeDeleted && edited && (
            <Popular isNew={isNewPopular} className="status-content deleted-business">
              <span>{t("pages.configurations.businessTab.popular")}</span>
              <IconContainer size={16} color={styleTheme.palette.darkGrey}>
                <EditIcon />
              </IconContainer>
            </Popular>
          )}
        </div>
      );
    },
    alignText: "left",
  });

  return columns;
};

const ContentWrapper = styled.span<{ deleted?: boolean; popular?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  opacity: ${({ deleted }) => (deleted ? 0.5 : 1)};
  height: 45px;
  border-left: 3px solid transparent;
  ${({ popular, theme }) => (popular ? `border-color: ${theme.palette.turquoiseBlue}` : "")};
`;

const StatusWrapper = styled.span<{ deleted?: boolean }>`
  display: flex;
  justify-content: flex-start;
  margin-right: 24px;
  opacity: ${({ deleted }) => (deleted ? 0.5 : 1)};
`;

const IconCont = styled(IconContainer)`
  position: absolute !important;
  margin-left: -20px !important;
  justify-content: flex-start;
`;

const Deleted = styled.span`
  justify-content: flex-end;
  opacity: 0.5;
  color: red;
`;

const Popular = styled.div<{ isNew: boolean }>`
  justify-content: flex-end;
  color: ${({ isNew, theme }) => (isNew ? theme.palette.turquoiseBlue : theme.palette.digitalRed)};
  display: flex;
  > div {
    margin-left: 8px;
  }
`;

const Logo = styled.div<{ deleted: boolean; url: string }>`
  height: 24px;
  width: 24px;
  opacity: ${({ deleted }) => (deleted ? 0.5 : 1)};
  border-radius: 100px;
  background-image: url(${props => props.url});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const Status = styled("div")<{ color: string; background: string; border: string; type: BusinessStatusEnum }>`
  border-radius: 10px;
  padding-left: 12px;
  padding-right: 12px;
  height: 21px;
  line-height: 21px;
  font-weight: bold;
  display: inline-block;
  font-size: 14px;
  text-align: center;
  background-color: ${props => props.background};
  font-family: Vodafone Rg;
  justify-content: flex-end;
  border: 1px solid;
  color: ${props => props.color};
  border-color: ${props => props.border};

  > span {
    position: relative;
    top: -1px;
  }
`;

const FastActionContainer = styled("div")`
  :last-of-type {
    margin-right: 0;
  }
  margin-right: 12px;
`;
