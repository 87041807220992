import React from "react";
import BackendIcon from "../../../../shared/icons/backend.icon";
import FrontendIcon from "../../../../shared/icons/frontend.icon";
import GatewayIcon from "../../../../shared/icons/gateway.icon";
import MiniAppsIcon from "../../../../shared/icons/mini-apps.icon";
import SdkIcon from "../../../../shared/icons/sdk.icon";
import ServiceAdapterIcon from "../../../../shared/icons/service-adapter.icon";
import StorageServiceIcon from "../../../../shared/icons/storage-service.icon";

export enum EComponentTypes {
  SDK = "sdk",
  BACKOFFICE = "backoffice",
  FRONTEND = "frontend",
  GATEWAY = "gateway",
  MINIAPPS = "miniapps",
  SERVICE_ADAPTER = "serviceadapter",
  STORAGE_SERVICE = "storageservice",
}

export interface IComponentVersion {
  component: EComponentTypes;
  version: string;
}

/**
 * Returns the correspondent component icon
 * @param {string} componentName
 * @returns {JSX.Element}
 */
export const getComponentIcon = (componentName: string) => {
  switch (componentName) {
    case EComponentTypes.MINIAPPS:
      return <MiniAppsIcon />;
    case EComponentTypes.FRONTEND:
      return <FrontendIcon />;
    case EComponentTypes.BACKOFFICE:
      return <BackendIcon />;
    case EComponentTypes.GATEWAY:
      return <GatewayIcon />;
    case EComponentTypes.SDK:
      return <SdkIcon />;
    case EComponentTypes.SERVICE_ADAPTER:
      return <ServiceAdapterIcon />;
    case EComponentTypes.STORAGE_SERVICE:
      return <StorageServiceIcon />;
  }
};

/**
 * Removes unnecessary words from the version
 * @param {string} version
 */
export const parseComponentVersion = (version: string) => {
  const wordsToRemove = [":", "version", "backoffice"];
  const regex = new RegExp(wordsToRemove.join("|"), "gi");
  return version.replace(regex, () => {
    return "";
  });
};
