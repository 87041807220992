import React from "react";

/**
 * Icon for Target file
 */
const TargetFileIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20">
      <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g stroke="#2FC56D">
          <g>
            <g>
              <g>
                <path
                  d="M11.786 2H5.744c-.69 0-1.458.768-1.458 1.458v13.334c0 .805.653 1.458 1.458 1.458h8.333c.806 0 1.459-.653 1.459-1.458V5.75L11.786 2zm0 0v3.333c0 .23.186.417.416.417h3.334"
                  transform="translate(-512 -567) translate(215 464) translate(297 95) translate(.5 8)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TargetFileIcon;
