import { produce } from "immer";
import { IPayloadAction } from "./../../shared/shared.interfaces";
import { ILoggedUser } from "./authentication.interfaces";

export const AuthenticationActions = {
  types: {
    UPDATE_LOGGED_USER: "UPDATE_LOGGED_USER",
    UPDATE_SECURITY_COOKIE: "UPDATE_SECURITY_COOKIE",
    UPDATE_XSRF_TOKEN: "UPDATE_XSRF_TOKEN",
    SHOW_MODAL: "SHOW_MODAL",
    LOGOUT: "LOGOUT",
    RESET_FAILED_LOGIN_ATTEMPTS: "RESET_FAILED_LOGIN_ATTEMPTS",
    INCREMENT_FAILED_LOGIN_ATTEMPTS: "INCREMENT_FAILED_LOGIN_ATTEMPTS",
    DECREMENT_FAILED_LOGIN_ATTEMPTS: "DECREMENT_FAILED_LOGIN_ATTEMPTS",
  },
  creators: {
    updateSecurityCookieAction: (securityCookie: string) => ({
      type: AuthenticationActions.types.UPDATE_SECURITY_COOKIE,
      payload: {
        securityCookie,
      },
    }),
    updateXsrfTokenAction: (xsrfToken: string) => ({
      type: AuthenticationActions.types.UPDATE_XSRF_TOKEN,
      payload: {
        xsrfToken,
      },
    }),
    updateLoggedUserAction: (loggedUser: ILoggedUser) => ({
      type: AuthenticationActions.types.UPDATE_LOGGED_USER,
      payload: {
        loggedUser,
      },
    }),
    logoutAction: () => ({
      type: AuthenticationActions.types.LOGOUT,
    }),
    toggleModalAction: (visibility: boolean) => ({
      type: AuthenticationActions.types.SHOW_MODAL,
      payload: {
        visibility,
      },
    }),
    resetFailedLoginAttempts: () => ({
      type: AuthenticationActions.types.RESET_FAILED_LOGIN_ATTEMPTS,
    }),
    incrementFailedLoginAttempts: () => ({
      type: AuthenticationActions.types.INCREMENT_FAILED_LOGIN_ATTEMPTS,
    }),
    decrementFailedLoginAttempts: () => ({
      type: AuthenticationActions.types.DECREMENT_FAILED_LOGIN_ATTEMPTS,
    }),
  },
};

export interface IAuthenticationReducerInterface {
  securityCookie: string;
  xsrfToken: string;
  loggedUser?: ILoggedUser;
  showModal: boolean;
  failedLoginAttempts: number;
}

const initialState: IAuthenticationReducerInterface = {
  securityCookie: "",
  xsrfToken: "",
  showModal: false,
  failedLoginAttempts: 0,
};

export const authenticationReducer = produce((draft: IAuthenticationReducerInterface, action: IPayloadAction<any>) => {
  switch (action.type) {
    case AuthenticationActions.types.UPDATE_LOGGED_USER:
      draft.loggedUser = action.payload.loggedUser;
      return;
    case AuthenticationActions.types.UPDATE_SECURITY_COOKIE:
      draft.securityCookie = action.payload.securityCookie;
      return;
    case AuthenticationActions.types.UPDATE_XSRF_TOKEN:
      draft.xsrfToken = action.payload.xsrfToken;
      return;
    case AuthenticationActions.types.SHOW_MODAL:
      draft.showModal = action.payload.visibility;
      return;
    case AuthenticationActions.types.RESET_FAILED_LOGIN_ATTEMPTS:
      draft.failedLoginAttempts = 0;
      return;
    case AuthenticationActions.types.INCREMENT_FAILED_LOGIN_ATTEMPTS:
      draft.failedLoginAttempts += 1;
      return;
    case AuthenticationActions.types.DECREMENT_FAILED_LOGIN_ATTEMPTS:
      if (draft.failedLoginAttempts > 0) {
        draft.failedLoginAttempts -= 1;
      }
      return;
    case AuthenticationActions.types.LOGOUT:
      localStorage.removeItem("rememberMe");
      localStorage.removeItem("twoFactorAuthenticated");
      return { ...initialState, failedLoginAttempts: draft.failedLoginAttempts };
    default:
      return draft;
  }
}, initialState);
