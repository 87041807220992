import React from "react";
import styled from "styled-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { CheckIcon, EditIcon, UndoIcon } from "@wit/mpesa-ui-components";
import { useTranslation } from "react-i18next";

interface IQuickActionsMenuProps {
  canEdit: boolean;
  isEditing: boolean;
  doneFn: () => void;
  cancelFn: () => void;
  editCampaignFn: () => void;
  cancelCampaignFn: () => void;
  canCancel: boolean;
}

/**
 * QuickActionsMenu component
 */
const QuickActionsMenu = ({
  canEdit,
  isEditing,
  doneFn,
  cancelFn,
  editCampaignFn,
  cancelCampaignFn,
  canCancel = false,
}: IQuickActionsMenuProps) => {
  const [t] = useTranslation();

  return (
    <SideMenu>
      <div>
        {canEdit ? (
          isEditing ? (
            <>
              <LinkContainer id="done" onClick={doneFn}>
                <IconContainer color={styleTheme.palette.digitalGreen}>
                  <CheckIcon />
                </IconContainer>
                <span>{t("pages.referralCampaigns.editCampaign.doneButton")}</span>
              </LinkContainer>
              <LinkContainer id="cancel" onClick={cancelFn}>
                <IconContainer color={styleTheme.palette.errorRed}>
                  <UndoIcon />
                </IconContainer>
                <span>{t("pages.referralCampaigns.editCampaign.cancelButton")}</span>
              </LinkContainer>
            </>
          ) : (
            <LinkContainer id="edit-campaign" onClick={editCampaignFn}>
              <IconContainer color={styleTheme.palette.turquoiseBlue}>
                <EditIcon />
              </IconContainer>
              <span>{t("pages.referralCampaigns.editCampaign.editCampaignButton")}</span>
            </LinkContainer>
          )
        ) : null}
        {/*{canCancel && (
          <LinkContainer id="cancel-campaign" disabled={isEditing} onClick={cancelCampaignFn}>
            <IconContainer color={styleTheme.palette.midGrey}>
              <CancelIcon />
            </IconContainer>
            <span>{t("pages.referralCampaigns.editCampaign.cancelCampaignButton")}</span>
          </LinkContainer>
        )}*/}
      </div>
    </SideMenu>
  );
};

export default QuickActionsMenu;

const SideMenu = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  align-items: flex-end;
  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const LinkContainer = styled("div")<{ disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-family: Vodafone Rg;
  color: ${styleTheme.palette.darkGrey};
  font-size: 16px;
  margin-bottom: 15px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? "none" : "inherit")};

  > div {
    margin-right: 8px;
  }
`;

const IconContainer = styled("div")<{ color: string }>`
  width: 20px;
  height: 20px;
  > svg {
    stroke: ${props => props.color};
  }
`;
