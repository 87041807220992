import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { RoutesEnum } from "../../../routes/routes.constants";
import { IApp } from "../../../shared/models/app.model";
import { fadeInKeyframe, fadeOutKeyframe, Row } from "../../../shared/shared.styled";
import { AppFastActionsContainer } from "./app-fast-actions.component";

interface IAppGridItemProps {
  item: IApp;
  showFastActions: boolean;
  isEnable: boolean | undefined;
}

/**
 * AppGridItem component
 */
const AppGridItem = ({ item, showFastActions, isEnable }: IAppGridItemProps) => {
  // Hooks initialization
  const [t] = useTranslation();
  const history = useHistory();

  /**
   * Navigates to the correspondent app details
   */
  const appDetails = () => {
    history.push(RoutesEnum.APP_DETAILS.replace(":id", item.id));
  };

  return (
    <AppGridItemContainer showFastActions={showFastActions} onClick={appDetails}>
      <AppImageContainer>
        <img src={`images/${item.icon}`} alt="" />
      </AppImageContainer>
      <AppName>{item.name}</AppName>
      <Row style={{ justifyContent: "center" }}>
        <AppInfoLabel>{t("pages.apps.item.lastUpdated")}</AppInfoLabel>
        <AppInfoValue>{item.lastUpdate ? moment(item.lastUpdate).format("DD-MM-YYYY") : "n.a."}</AppInfoValue>
      </Row>
      {isEnable !== undefined ? (
        <Row style={{ justifyContent: "center" }}>
          <StatusChipContainer isEnable={isEnable} id={"app-status"} style={{ width: "fit-content", marginTop: "8px" }}>
            {isEnable ? t("pages.apps.item.live") : t("pages.apps.item.disabled")}
          </StatusChipContainer>
        </Row>
      ) : null}
      <Separator appStatusEnable={isEnable !== undefined} />
      <AppFooterInfo>{t(`commons.apps.enums.platform.${item.system}`)}</AppFooterInfo>
    </AppGridItemContainer>
  );
};

export default AppGridItem;
const AppImageContainer = styled("div")`
  display: flex;
  justify-content: center;
  img {
    width: 90px;
    height: 90px;
  }
`;

const AppInfoLabel = styled("span")`
  font-family: Vodafone Rg;
  font-size: 14px;
  color: ${props => props.theme.palette.black};
`;

const AppInfoValue = styled("span")`
  font-family: Vodafone Rg;
  font-size: 14px;
  color: ${props => props.theme.palette.midGrey};
  padding-left: 5px;
`;

const AppFooterInfo = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
  font-weight: bold;
  text-align: center;
`;

const AppName = styled("span")`
  padding-top: 24px;
  padding-bottom: 9px;
  font-family: Vodafone Rg;
  font-size: 22px;
  font-weight: bold;
  color: ${props => props.theme.palette.black};
  text-align: center;
`;

const Separator = styled("div")<{ appStatusEnable: boolean }>`
  width: 100%;
  height: 1px;
  border-style: solid;
  border-width: 1px;
  border-image-source: radial-gradient(
    circle at 50% 0,
    ${props => props.theme.palette.aluminium},
    rgba(204, 204, 204, 0) 100%
  );
  border-image-slice: 1;
  margin: ${props => (props.appStatusEnable ? "12px" : "24px")} 0px;
`;

const AppGridItemContainer = styled("div")<{ showFastActions: boolean }>`
  max-height: 291px;
  height: 291px;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 272px;
  padding: 24px 14px 24px 14px;

  ${Row} {
    display: flex;
  }

  @media(max-width: 768px){
    width: 100%;
  }

  /*:hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);

    ${Row} {
      animation: ${props => (!props.showFastActions ? fadeInKeyframe : fadeOutKeyframe)} 0.25s linear;
      display: ${props => (props.showFastActions ? "none" : "flex")};
    }

    ${AppFastActionsContainer} {
      display: ${props => (props.showFastActions ? "flex" : "none")};
      margin-top: ${props => (props.showFastActions ? "-6px" : 0)};
      animation: ${props => (props.showFastActions ? fadeInKeyframe : fadeOutKeyframe)} 0.25s linear;
    }
  }*/
`;

const StatusChipContainer = styled("div")<{ isEnable: boolean }>`
  border-radius: 10px;
  padding-left: 12px;
  padding-right: 12px;
  height: 22px;
  line-height: 21px;
  font-weight: bold;
  display: inline-block;
  font-size: 14px;
  text-align: center;
  font-family: Vodafone Rg;

  ${props => {
    let backgroundColor = "";
    const fontColor = props.theme.palette.white;
    let borderColor = "";
    if (props.isEnable) {
      backgroundColor = props.theme.palette.digitalGreen;
      borderColor = props.theme.palette.digitalGreen;
    } else {
      backgroundColor = props.theme.palette.freshOrange;
      borderColor = props.theme.palette.freshOrange;
    }
    return `background-color: ${backgroundColor}; color: ${fontColor}; border: 1px solid ${borderColor}`;
  }}
`;
