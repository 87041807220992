import { FormSection, TextInput } from "@wit/mpesa-ui-components";
import { FormikErrors } from "formik";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { IStoreInterface } from "../../../../configs/store.config";
import HelpIcon from "../../../../shared/icons/help.icon";
import { ConversionEventEnum, IReferralCampaign, ReferralCampaignStatus } from "../referral-campaigns.model";
import { FormLabelText, TooltipContainer, TooltipText, MutedText } from "../referral-campaigns.utils";
import TextAreaWithTags from "./text-area-with-tags.component";
import ReferralCampaignsApi from "../referral-campaigns.api";
import TwoWayBindingToggle from "../../../../shared/components/two-way-binding-toggle.component";

interface ICampaignDetailsFormProps {
  title: React.ReactNode;
  isEditing: boolean;
  language: string;
  values: IReferralCampaign;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  errors: any;
  setErrors: (errors: FormikErrors<IReferralCampaign>) => void;
  campaignStatus?: ReferralCampaignStatus;
  isLanguageRequired?: boolean;
}

/**
 * CampaignDetailsForm component
 * Can be used to create or edit the campaign details
 */
const CampaignDetailsForm = ({
  title,
  isEditing,
  language: lang,
  values,
  setFieldValue,
  errors,
  setErrors,
  campaignStatus,
  isLanguageRequired = false,
}: ICampaignDetailsFormProps) => {
  const [t] = useTranslation();
  const defaultCampaign = (useSelector(
    (store: IStoreInterface) => store.referralCampaignsReducer.referralCampaign,
  ) as IReferralCampaign).defaultCampaign;
  const [hasDefaultCampaign, setHasDefaultCampaign] = React.useState(true);

  /**
   * Checks if theres already a default campaign or not
   */
  React.useEffect(() => {
    if (values.defaultCampaign === undefined) {
      values.defaultCampaign = false;
    }
    ReferralCampaignsApi.methods.hasDefaultCampaign().then(res => {
      setHasDefaultCampaign(res.data);
    });
  }, [isEditing]);

  /** In the first approach this will be the only conversion event
   *  Remove this method when this scenario changes
   */
  React.useEffect(() => {
    setFieldValue("conversionEvent", ConversionEventEnum.FIRST_LOGIN);
  }, []);

  /**
   * Renders the default campaign tooltip jsx
   * @returns {JSX.Element}
   */
  const renderDefaultCampaignTooltip = () => {
    return (
      <>
        <MutedText style={{ marginLeft: "12px" }}>
          {t("pages.referralCampaigns.createCampaign.campaignDetails.defaultCampaign")}
        </MutedText>
        <TooltipContainer data-tip data-for="tooltip-default-campaign" id={"default-campaign-tooltip"}>
          <HelpIcon />
          <ReactTooltip
            event="mouseover"
            eventOff="mouseleave mousedown"
            place="top"
            type="dark"
            effect="solid"
            id={"tooltip-default-campaign"}
            className={"tooltip-container"}
          >
            <TooltipText>
              {t("pages.referralCampaigns.createCampaign.campaignDetails.defaultCampaignTooltip")}
            </TooltipText>
          </ReactTooltip>
        </TooltipContainer>
      </>
    );
  };

  return (
    <>
      <FormSection
        isEditing={isEditing}
        title={title}
        rows={[
          {
            label: t("pages.referralCampaigns.createCampaign.campaignDetails.campaignName"),
            displayComponent: (
              <div style={{ display: "flex", alignItems: "center" }}>
                <NonEditableText style={{ marginRight: !hasDefaultCampaign ? "5px" : "0px" }}>
                  {values.name.translations[lang]}
                </NonEditableText>
                {values.defaultCampaign && (
                  <div style={{ display: "flex", marginLeft: "auto", alignItems: "center", flexShrink: 0 }}>
                    <TwoWayBindingToggle
                      isDisabled={true}
                      cb={() => {}}
                      initialValue={values.defaultCampaign}
                      id={"default-campaign-toggle-display"}
                    />
                    {renderDefaultCampaignTooltip()}
                  </div>
                )}
              </div>
            ),
            editingComponent: (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: !hasDefaultCampaign || defaultCampaign ? "10px" : "0px", flexGrow: 1 }}>
                  <TextInput
                    name="name"
                    maxLength={255}
                    value={values.name.translations[lang] || ""}
                    placeholder={`${t(
                      "pages.referralCampaigns.createCampaign.campaignDetails.campaignNamePlaceholder",
                    )} (${lang.toUpperCase()})`}
                    required={isLanguageRequired}
                    onChange={e => setFieldValue(`name.translations[${lang}]`, e.target.value)}
                    error={
                      errors.name && errors.name.translations && errors.name.translations[lang]
                        ? errors.name.translations[lang]
                        : undefined
                    }
                  />
                </div>
                {((!hasDefaultCampaign && !campaignStatus) ||
                  (!hasDefaultCampaign && campaignStatus === ReferralCampaignStatus.SCHEDULED) ||
                  defaultCampaign ||
                  values.defaultCampaign) && (
                  <div style={{ display: "flex", marginLeft: "auto", alignItems: "center" }}>
                    <TwoWayBindingToggle
                      id={"default-campaign-toggle-edit"}
                      isDisabled={
                        campaignStatus &&
                        campaignStatus !== ReferralCampaignStatus.SCHEDULED &&
                        campaignStatus !== ReferralCampaignStatus.LIVE &&
                        !defaultCampaign
                      }
                      cb={val => {
                        setFieldValue("defaultCampaign", val);
                        if (val) {
                          setFieldValue("startDate", "");
                          setFieldValue("endDate", "");
                        } else {
                          setFieldValue("startDate", moment().format("DD/MM/YYYY"));
                        }
                      }}
                      initialValue={values.defaultCampaign}
                    />

                    {renderDefaultCampaignTooltip()}
                  </div>
                )}
              </div>
            ),
            required: isLanguageRequired,
          },
          {
            label: t("pages.referralCampaigns.createCampaign.campaignDetails.startDate"),
            displayComponent: <NonEditableText>{values.startDate}</NonEditableText>,
            hiddenRow: values.defaultCampaign,
            editingComponent: (
              <div>
                {!campaignStatus || (campaignStatus && campaignStatus === ReferralCampaignStatus.SCHEDULED) ? (
                  <TextInput
                    value={values.startDate}
                    name="startDate"
                    onChange={e => {
                      setFieldValue("startDate", e.target.value);
                    }}
                    error={errors.startDate as string}
                    placeholder={"DD / MM / YYYY"}
                  />
                ) : (
                  <NonEditableText>{values.startDate}</NonEditableText>
                )}
              </div>
            ),
            required: true,
          },
          {
            label: t("pages.referralCampaigns.createCampaign.campaignDetails.endDate"),
            displayComponent: <NonEditableText>{values.endDate}</NonEditableText>,
            hiddenRow: values.defaultCampaign,
            editingComponent: (
              <TextInput
                value={values.endDate}
                name="endDate"
                onChange={e => {
                  setFieldValue("endDate", e.target.value);
                }}
                error={errors.endDate as string}
                placeholder={"DD / MM / YYYY"}
              />
            ),
            required: true,
          },
        ]}
      />
      <FormSection
        isEditing={isEditing}
        title={null}
        rows={[
          {
            label: (
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FormLabelText>
                    {t("pages.referralCampaigns.createCampaign.campaignDetails.conversionEvent")}
                  </FormLabelText>
                  <TooltipContainer data-tip data-for="tooltip-conversion" id={"conversion-tooltip"}>
                    <HelpIcon />
                    <ReactTooltip
                      event="mouseover"
                      eventOff="mouseleave mousedown"
                      place="top"
                      type="dark"
                      effect="solid"
                      id={"tooltip-conversion"}
                      className={"tooltip-container"}
                    >
                      <TooltipText>
                        {t("pages.referralCampaigns.createCampaign.campaignDetails.conversionTooltip")}
                      </TooltipText>
                    </ReactTooltip>
                  </TooltipContainer>
                </div>
              </>
            ),
            displayComponent: values.conversionEvent ? (
              <NonEditableText>
                {t(`pages.referralCampaigns.createCampaign.campaignDetails.${values.conversionEvent.toLowerCase()}`)}
              </NonEditableText>
            ) : null,
            editingComponent: (
              <NonEditableText>
                {values.conversionEvent
                  ? t(`pages.referralCampaigns.createCampaign.campaignDetails.${values.conversionEvent.toLowerCase()}`)
                  : null}
              </NonEditableText>

              /** In the first approach "First app login" will be the only conversion event
               *  Remove this comment block when this scenario changes
              <SectionColumn id="type-dropdown">
                <AutoClosingDropdown
                  hasValue={!!values.conversionEvent}
                  dropdownType={DropdownType.RECTANGULAR_NORMAL}
                  label={
                    values.conversionEvent
                      ? t(
                          `pages.referralCampaigns.createCampaign.campaignDetails.${values.conversionEvent.toLowerCase()}`,
                        )
                      : t(`pages.referralCampaigns.createCampaign.campaignDetails.conversionEventPlaceholder`)
                  }
                  options={getConversionEventOptions()}
                  error={errors.conversionEvent}
                  selectOption={opt => setFieldValue("conversionEvent", opt.key)}
                />
              </SectionColumn>
              */
            ),
            required: true,
          },
          {
            label: (
              <FormLabelContainer>
                <FormLabel required={isEditing && isLanguageRequired}>
                  {t("pages.referralCampaigns.createCampaign.campaignDetails.invitationMessage")}
                </FormLabel>
                <FormLabelLimit>
                  {values.inviteMessage.translations[lang] &&
                    values.inviteMessage.translations[lang].length + t("pages.referralCampaigns.twoTwentyFiveLimit")}
                </FormLabelLimit>
              </FormLabelContainer>
            ),
            displayComponent: <NonEditableText>{values.inviteMessage.translations[lang]}</NonEditableText>,
            editingComponent: (
              <TextAreaWithTags
                tags={[{ color: "#e9f5ff", fontColor: "#007c92", label: "URL Link" }]}
                maxLength={225}
                error={
                  errors.inviteMessage && errors.inviteMessage.translations && errors.inviteMessage.translations[lang]
                    ? errors.inviteMessage.translations[lang]
                    : undefined
                }
                placeholder={`${t(
                  "pages.referralCampaigns.createCampaign.campaignDetails.invitationMessage",
                )} (${lang.toUpperCase()})`}
                maxHeight={"98px"}
                onChangeValue={value => setFieldValue(`inviteMessage.translations[${lang}]`, value)}
                value={values.inviteMessage.translations[lang] || ""}
                setErrors={error =>
                  error ? setErrors({ inviteMessage: { translations: { [lang]: error } } }) : setErrors({})
                }
              />
            ),

            required: isLanguageRequired,
          },
        ]}
      />
    </>
  );
};

export default CampaignDetailsForm;

const NonEditableText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.darkGrey};
`;

const FormLabelContainer = styled("span")`
  display: flex;
  flex-direction: column;
  height: 82px;
`;

const FormLabel = styled("span")<{ required?: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.greyDarker};

  ${props =>
    props.required
      ? `
  ::after {
    content: ' *';
    font-family: Vodafone Rg;
    font-size: 16px;
    font-weight: normal;
    color: ${props.theme.palette.red.normal};
  }`
      : ""}
`;

const FormLabelLimit = styled("span")`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: ${props => props.theme.palette.greyDarker};
`;
