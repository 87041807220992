import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  isCustomerSurveyTabActive,
  isFeedbackTabActive,
  isProblemReportsTabActive,
} from "../feedback-and-problems.utils";
import { IStoreInterface } from "../../../../configs/store.config";

/**
 * Custom Hook - useResultCounter
 * [!important] This hook can be used within
 * feedback tab components, problem reports tab components, customer satisfaction survey tab
 * @param sectionId number
 * @returns total-number-of-results
 */
export function useFeedbackAndProblemsResultCounter(sectionId: number) {
  const { feedbacks, problemReports, customerSurvey } = useSelector(
    (state: IStoreInterface) => state.feedbackAndProblemsReportReducer,
  );

  const counter = useMemo(() => {
    if (isFeedbackTabActive(sectionId)) {
      if (feedbacks && feedbacks.count) {
        return feedbacks.count;
      }
    }
    if (isProblemReportsTabActive(sectionId)) {
      if (problemReports && problemReports.count) {
        return problemReports.count;
      }
    }

    if (isCustomerSurveyTabActive(sectionId)) {
      if (customerSurvey && customerSurvey.count) {
        return customerSurvey.count;
      }
    }
    return 0;
  }, [feedbacks, problemReports, customerSurvey]);

  return counter;
}
