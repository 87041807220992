import {
  CancelIcon,
  FastActionButton,
  ModalTypeEnum,
  PageTitle,
  PrimaryButton,
  TabChild,
  Tabs,
  TrashIcon,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Breadcrumb } from "@wit/mpesa-ui-components/lib/types";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { ConfigContext } from "../../../app.component";
import { IStoreInterface } from "../../../configs/store.config";
import { RoutesEnum } from "../../../routes/routes.constants";
import LoadingComponent from "../../../shared/components/loading-component/LoadingComponent";
import SafaricomDeleteModal from "../../../shared/components/safaricom-delete-modal/safaricom-delete-modal.component";
import { ButtonContainer } from "../../../shared/components/safaricom-delete-modal/safaricom-delete-modal.styled";
import useCheckFeatureAvailable from "../../../shared/hooks/use-check-available-feature";
import { FEATURES } from "../../../shared/renderer.utils";
import { StatusType } from "../../../shared/shared.enums";
import { Column, PageContainer, RatioContainer } from "../../../shared/shared.styled";
import { generateUUIDv4, getColorStatus, isSFCMarket } from "../../../shared/shared.utils";
import AnnouncementsApi from "../announcements.api";
import {
  AnnouncementFormSteps,
  AudienceType,
  ConsumerAudience,
  IAnnouncement,
  IStoryInterface,
} from "../announcements.model";
import { AnnoucementsActions } from "../announcements.store";
import { AnnouncementsUtils } from "../announcements.utils";
import AnnouncementsStepAnouncementDetails from "../components/announcements-step-announcement-details.component";
import AnnouncementsStepAppVersion from "../components/announcements-step-app-version.component";
import AnnouncementsStepCampaignDetails from "../components/announcements-step-campaign-details.component";
import AnnouncementsStepHomescreenCardBusiness from "../components/announcements-step-homescreen-card-business.component";
import AnnouncementsStepHomescreenCardConsumer from "../components/announcements-step-homescreen-card-consumer.component";
import ConfirmWithLoadingModal from "../components/confirm-with-loading-modal.component";
import OnEditingModal from "../components/on-editing-modal.component";
import SideMenuDetails from "../components/sidemenu-details.component";
import UnchangesModal from "../components/unchanges-modal.component";

enum TabState {
  CAMPAIGN_DETAILS = 0,
  APP_VERSION = 1,
  HOMESCREEN_CARD = 2,
  ANNOUNCEMENTS_DETAILS = 3,
}

/**
 * Component create to see the details of announcement
 */
const AnnouncementDetails = () => {
  const [t] = useTranslation();
  const { config } = useContext(ConfigContext);
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const history = useHistory();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const { announcementID } = useParams<any>();
  const isLoadingAnnouncements = useSelector(
    (state: IStoreInterface) => state.announcementsReducer.isLoadingAnnouncements,
  );

  const [isLoadingAnnouncement, setIsLoadingAnnouncement] = useState(true);
  const announcement = useSelector((store: IStoreInterface) =>
    store.announcementsReducer.announcements.find(a => a.id === announcementID),
  );
  const consumerChildEnabled = useCheckFeatureAvailable(FEATURES.CONSUMER_AUDIENCE_CHILD);

  const announcementDefault = {
    id: "",
    campaignName: "",
    createDate: undefined,
    startDate: undefined,
    endDate: undefined,
    color: "",
    icon: "",
    sendPushNotifications: false,
    dismissible: false,
    audience: consumerChildEnabled ? ConsumerAudience.CONSUMERAPP_ADULT : ConsumerAudience.CONSUMERAPP,
    targetBusiness: undefined,
    targetConsumer: undefined,
    stories: [{ callToAction: { action: {} } } as IStoryInterface],
    status: undefined,
  };
  const [announcementSelected, setAnnouncementSelected] = React.useState<IAnnouncement>(
    announcement !== undefined
      ? {
          ...announcement,
          time: moment(announcement.startDate).format("hh:mm a"),
          endTime: moment(announcement.endDate).format("hh:mm a"),
        }
      : {
          ...announcementDefault,
          time: moment(announcementDefault.startDate).format("hh:mm a"),
          endTime: moment(announcementDefault.endDate).format("hh:mm a"),
        },
  );
  const [selectedAnnouncementSavedData, setSelectedAnnouncementSavedData] = React.useState<IAnnouncement>(
    announcementSelected,
  );

  const [editingCampaignDetails, setEditingCampaignDetails] = useState(false);
  const [editingHomeScreenDetails, setEditingHomeScreenDetails] = useState(false);
  const [editingAppVersionDetails, setEditingAppVersionDetails] = useState(false);
  const [editingStoryDetails, setEditingStoryDetails] = useState(false);
  const [numChanges, setNumChanges] = useState<number>(0);
  const [isRequesting, setIsRequesting] = React.useState<boolean>(false);
  const [showTumbnail, setShowTumbnail] = React.useState<boolean>(false);
  const [showTumbnailForEndDate, setShowTumbnailForEndDate] = React.useState<boolean>(false);
  const [formKey, setFormKey] = React.useState(generateUUIDv4());
  const automaticSuggestedEnabled = useCheckFeatureAvailable(FEATURES.AUTOMATIC_SUGGESTED_ANNOUNCEMENTS);

  useEffect(() => {
    dispatch(AnnoucementsActions.creators.fetchingAnnouncementsAction());
    AnnouncementsApi.methods.getAnnouncement(announcementID).then(
      res => {
        if (!res.data.time && res.data.startDate) {
          res.data.time = moment(res.data.startDate).format("hh:mm a");
        }

        if (!res.data.endTime && res.data.endDate) {
          res.data.endTime = moment(res.data.endDate).format("hh:mm a");
        }

        setAnnouncementSelected(res.data);
        setSelectedAnnouncementSavedData(res.data);
        dispatch(AnnoucementsActions.creators.updateAnnouncement(announcementID, res.data));
        setIsLoadingAnnouncement(false);
      },
      () => {
        dispatch(AnnoucementsActions.creators.fetchAnnouncementsErrorAction());
        setAlertProps({
          title: t("pages.announcements.errorGetAnnouncementDetails"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  }, []);

  const [showSaveModal, hideSaveModal] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <ConfirmWithLoadingModal
      isLoading={isRequesting}
      title={t("pages.announcements.detailPage.modals.confirmTitle")}
      description={t("pages.announcements.detailPage.modals.confirmDescription", {
        campaignName: announcement ? announcement.campaignName : "",
      })}
      primaryBtnLabel={t("pages.announcements.detailPage.buttons.save")}
      secondaryBtnLabel={t("pages.announcements.detailPage.buttons.modalCancel")}
      primaryAction={() => {
        updateAnnouncement(1);
      }}
      secondaryAction={() => {
        hideSaveModal();
      }}
    />,
  );
  const [showPublishModal, hidePublishModal] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <ConfirmWithLoadingModal
      isLoading={isRequesting}
      title={t("pages.announcements.detailPage.modals.confirmPublish")}
      description={t("pages.announcements.detailPage.modals.confirmDescriptionPublish", {
        campaignName: announcement ? announcement.campaignName : "",
      })}
      primaryBtnLabel={t("pages.announcements.detailPage.buttons.publish")}
      secondaryBtnLabel={t("pages.announcements.detailPage.buttons.modalCancel")}
      primaryAction={() => {
        updateAnnouncement(0);
      }}
      secondaryAction={() => {
        hidePublishModal();
      }}
    />,
  );

  const [showCancelModal, hideCancelModal] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <SafaricomDeleteModal
      isLoading={isRequesting}
      title={t("pages.announcements.detailPage.modals.cancelTitle")}
      description={t("pages.announcements.detailPage.modals.cancelDescription")}
      primaryBtnLabel={t("pages.announcements.detailPage.buttons.modalCancel")}
      secondaryBtnLabel={t("pages.announcements.detailPage.buttons.cancelBtn")}
      primaryAction={() => {
        cancelRequest();
      }}
      secondaryAction={() => {
        hideCancelModal();
      }}
    />,
  );

  const [showDeleteModal, hideDeleteModal] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <SafaricomDeleteModal
      isLoading={isRequesting}
      title={t("pages.announcements.detailPage.modals.deleteTitle")}
      description={t("pages.announcements.detailPage.modals.deleteDescription")}
      primaryBtnLabel={t("pages.announcements.detailPage.buttons.modalDelete")}
      secondaryBtnLabel={t("pages.announcements.detailPage.buttons.cancelBtn")}
      primaryAction={() => {
        deleteRequest();
      }}
      secondaryAction={() => {
        hideDeleteModal();
      }}
    />,
  );

  const [tab, setTab] = React.useState<number>(0);

  /**
   * Return the breadcrums
   */
  const getBreadcrumbs = (): Breadcrumb[] => {
    return [
      {
        label: t("pages.announcements.title"),
        url: RoutesEnum.ANNOUNCEMENTS,
      },
      {
        label: announcement ? announcement.campaignName : "",
        url: "",
      },
    ];
  };

  /** function that will be call to made the request for the update the announcment */
  const updateAnnouncement = (typeUpdate: number) => {
    const announcementTargetVersions =
      announcementSelected && announcementSelected.targetVersions ? announcementSelected.targetVersions : [];
    const {
      time,
      endTime,
      fileInfoBusinessMerchant,
      fileInfoBusinessAgent,
      fileInfoConsumer,
      ...changesToSend
    } = AnnouncementsUtils.diference(
      announcement !== undefined ? announcement : announcementDefault,
      announcementSelected,
    ) as IAnnouncement;

    const deleteStorys: string[] = AnnouncementsUtils.getStorysToBeDeleted(
      announcement !== undefined ? announcement : announcementDefault,
      announcementSelected,
    );

    const addStorys: IStoryInterface[] = AnnouncementsUtils.getStorysToBeAdded(
      announcement !== undefined ? announcement : announcementDefault,
      announcementSelected,
    );

    let removePromise = () => Promise.resolve();
    if (deleteStorys.length > 0 && !showTumbnail && !showTumbnailForEndDate) {
      removePromise = () => deleteStorysRequest(deleteStorys);
    }

    let addPromise = () => Promise.resolve();
    if (addStorys.length > 0 && !showTumbnail && !showTumbnailForEndDate) {
      addPromise = () => addStorysRequest(addStorys);
    }

    setIsRequesting(true);
    removePromise()
      .then(addPromise)
      .then(() => {
        if (changesToSend !== undefined && !showTumbnail && !showTumbnailForEndDate) {
          changesToSend.status = typeUpdate === 0 ? StatusType.SCHEDULED : StatusType.DRAFT;

          if (changesToSend.targetConsumer) {
            if (isSFCMarket()) {
              changesToSend.targetConsumer = AnnouncementsUtils.getClearMsisdn(changesToSend.targetConsumer);
            } else {
              changesToSend.targetConsumer = AnnouncementsUtils.cleanMsisdn(changesToSend.targetConsumer);
            }
          }

          if (changesToSend.targetBusinessMerchant) {
            changesToSend.targetBusinessMerchant = Array.from(new Set(changesToSend.targetBusinessMerchant));
          }

          if (changesToSend.targetBusinessAgent) {
            changesToSend.targetBusinessAgent = Array.from(new Set(changesToSend.targetBusinessAgent));
          }

          // changed date format to unix ts
          changesToSend.startDate = moment(changesToSend.startDate, "YYYY-MM-DD[T]HH:mm:ssZ").valueOf();
          changesToSend.endDate = moment(changesToSend.endDate, "YYYY-MM-DD[T]HH:mm:ssZ").valueOf();
          changesToSend.targetVersions = announcementTargetVersions;
          changesToSend.audience =
            announcementSelected && announcementSelected.audience
              ? announcementSelected.audience
              : consumerChildEnabled
              ? ConsumerAudience.CONSUMERAPP_ADULT
              : ConsumerAudience.CONSUMERAPP;

          AnnouncementsApi.methods
            .updateAnnouncement(changesToSend.id, changesToSend)
            .then(
              res => {
                const newData = JSON.parse(JSON.stringify(res.data));
                // add matching backgroundImage data
                newData.stories.forEach((story: IStoryInterface) => {
                  const match = announcementSelected.stories.find(s => {
                    if (s.id) {
                      return s.id === story.id;
                    } else {
                      // this is a new story, compare title and description
                      // (hack because BE still doesnt have the image available)
                      return s.title === story.title && s.description === story.description;
                    }
                  });
                  if (match) {
                    story.backgroundImage = match.backgroundImage;
                  }
                });
                setAnnouncementSelected(newData);
                setSelectedAnnouncementSavedData(newData);

                dispatch(AnnoucementsActions.creators.updateAnnouncement(changesToSend.id, res.data)); // to avoid persist images
                setNumChanges(0);
                setAlertProps({
                  title:
                    typeUpdate === 0
                      ? t("pages.announcements.detailPage.alerts.publishChangesSuccess")
                      : t("pages.announcements.detailPage.alerts.publishSaveSuccess"),
                  type: AlertTypeEnum.SUCCESS,
                });
                showAlert();
              },
              () => {
                setAlertProps({
                  title: t("pages.announcements.errorUpdatingAnnouncement"),
                  type: AlertTypeEnum.ERROR,
                });
                showAlert();
              },
            )
            .finally(() => {
              setIsRequesting(false);
              hidePublishModal();
              hideSaveModal();
              hideUnchangesModal();
              setFormKey(generateUUIDv4());
            });
        } else {
          setIsRequesting(false);
        }
      });
  };

  /**
   * Function to execute cancel request
   */
  const cancelRequest = () => {
    setIsRequesting(true);
    AnnouncementsApi.methods
      .cancelAnnouncement(announcementSelected.id)
      .then(
        res => {
          const stories = announcementSelected.stories.map(story => ({ ...story, backgroundImage: "" }));
          setAnnouncementSelected({ ...announcementSelected, status: StatusType.CANCELED, stories: stories });
          setSelectedAnnouncementSavedData({ ...announcementSelected, status: StatusType.CANCELED, stories: stories });
          dispatch(
            AnnoucementsActions.creators.updateAnnouncement(announcementSelected.id, {
              ...announcementSelected,
              status: StatusType.CANCELED,
              stories: stories,
            }),
          );
          setAlertProps({
            title: t("pages.announcements.detailPage.alerts.cancelRequestSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
        },
        () => {
          setAlertProps({
            title: t("pages.announcements.detailPage.alerts.cancelRequestError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        setIsRequesting(false);
        hideCancelModal();
      });
  };

  /**
   * Function to execute delete stories request
   */
  const deleteStorysRequest = (deleteStorysIds: string[]) => {
    return AnnouncementsApi.methods.deleteStoryAnnouncement(announcementSelected.id, deleteStorysIds).then(
      res => {
        setAlertProps({
          title: t("pages.announcements.detailPage.alerts.deleteStoryRequestSuccess"),
          type: AlertTypeEnum.SUCCESS,
        });
        showAlert();
      },
      () => {
        setAlertProps({
          title: t("pages.announcements.detailPage.alerts.deleteStoryRequestError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * Function to execute add stories request
   */
  const addStorysRequest = (addStorys: IStoryInterface[]) => {
    return AnnouncementsApi.methods.addStoryAnnouncement(announcementSelected.id, addStorys).then(
      res => {
        setAlertProps({
          title: t("pages.announcements.detailPage.alerts.addStoryRequestSuccess"),
          type: AlertTypeEnum.SUCCESS,
        });
        showAlert();
      },
      () => {
        setAlertProps({
          title: t("pages.announcements.detailPage.alerts.addStoryRequestError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * Function to execute delete request announcement
   */
  const deleteRequest = () => {
    setIsRequesting(true);
    AnnouncementsApi.methods
      .deleteAnnouncement(announcementSelected.id)
      .then(
        res => {
          setAlertProps({
            title: t("pages.announcements.detailPage.alerts.deletingAnnouncementRequestSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          dispatch(AnnoucementsActions.creators.deleteAnnouncement(announcementSelected.id));
          history.push(RoutesEnum.ANNOUNCEMENTS);
        },
        () => {
          setAlertProps({
            title: t("pages.announcements.detailPage.alerts.errorDeletingAnnouncement"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        setIsRequesting(false);
        hideDeleteModal();
      });
  };

  /**
   * useModal for unchanges added
   */
  const [showUnchangesModal, hideUnchangesModal] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <UnchangesModal
      title={t("pages.announcements.detailPage.modals.unsaveChange")}
      description={t("pages.announcements.detailPage.modals.descriptionUnsaveChange")}
      primaryAction={() => {
        updateAnnouncement(1);
      }}
      secondaryAction={() => hideUnchangesModal()}
      thridAction={() => window.history.back()}
      isLoading={isRequesting}
      thumbnailActive={showTumbnail || showTumbnailForEndDate}
    />,
  );

  /**
   * useModal for editing modal
   */
  const [showOnEditingModal, hideOnEditingModal] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <OnEditingModal
      primaryBtnLabel={t("pages.announcements.detailPage.buttons.modalDiscard")}
      title={t("pages.announcements.detailPage.modals.unsaveChange")}
      description={t("pages.announcements.detailPage.modals.descriptionOnEditingChange")}
      primaryAction={() => {
        window.history.back();
      }}
      secondaryAction={() => hideOnEditingModal()}
    />,
  );

  /**
   * function to call in goBack action
   */
  const goBackFn = () => {
    announcementSelected.status === StatusType.LIVE ||
    announcementSelected.status === StatusType.EXPIRED ||
    numChanges === 0
      ? window.history.back()
      : editingCampaignDetails || editingHomeScreenDetails || editingStoryDetails || editingAppVersionDetails
      ? showOnEditingModal()
      : showUnchangesModal();
  };

  /**
   * function to be call in the component
   */
  const submitForm = (setEditing: React.Dispatch<React.SetStateAction<boolean>>) => {
    setEditing(false);
    setNumChanges(cur => cur + 1);
    setAlertProps({
      type: AlertTypeEnum.SUCCESS,
      title:
        tab === 0
          ? t("pages.announcements.detailPage.alerts.campaignDetailsChanged")
          : tab === 1
          ? t("pages.announcements.detailPage.alerts.appVersionChanged")
          : tab === 2
          ? t("pages.announcements.detailPage.alerts.homeScreenDetailsChanged")
          : tab === 3
          ? t("pages.announcements.detailPage.alerts.announcementDetailsChanged")
          : "",
    });
    showAlert();
  };

  useEffect(() => {
    if (
      (announcementSelected.status === StatusType.SCHEDULED ||
        announcementSelected.status === StatusType.DRAFT ||
        announcementSelected.status === StatusType.CANCELED) &&
      moment(moment().format("YYYY-MM-DD[T]HH:mm:ssZ")).isAfter(
        moment(announcementSelected.startDate).format("YYYY-MM-DD[T]HH:mm:ssZ"),
      )
    ) {
      setShowTumbnail(true);
    } else {
      setShowTumbnail(false);
    }

    if (
      (announcementSelected.status === StatusType.SCHEDULED ||
        announcementSelected.status === StatusType.DRAFT ||
        announcementSelected.status === StatusType.CANCELED) &&
      moment(moment(announcementSelected.startDate).format("YYYY-MM-DD[T]HH:mm:ssZ")).isAfter(
        moment(announcementSelected.endDate).format("YYYY-MM-DD[T]HH:mm:ssZ"),
      )
    ) {
      setShowTumbnailForEndDate(true);
    } else {
      setShowTumbnailForEndDate(false);
    }
  }, [setShowTumbnail, setShowTumbnailForEndDate, announcementSelected]);

  return (
    <PageContainer>
      <PageTitle
        breadcrumbs={getBreadcrumbs()}
        title={announcement ? announcement.campaignName : ""}
        navigateFunction={history.push}
        separatorStyle={{ display: "none" }}
        goBackFn={goBackFn}
        titleContainerStyle={{ marginTop: "13px", marginBottom: "34px" }}
        iconContainerStyle={{ stroke: themeContext.palette.vodafoneRed }}
        rightComponentContainerStyle={{ display: "flex", alignContent: "center", height: "100%", marginRight: "0px" }}
        rightComponent={
          announcementSelected.status === StatusType.LIVE || announcementSelected.status === StatusType.EXPIRED ? (
            <></>
          ) : (
            <>
              <ButtonContainer>
                <PrimaryButton
                  id={"save"}
                  disabled={
                    isLoadingAnnouncements ||
                    isLoadingAnnouncement ||
                    numChanges === 0 ||
                    showTumbnail ||
                    showTumbnailForEndDate ||
                    editingCampaignDetails ||
                    editingHomeScreenDetails ||
                    editingStoryDetails ||
                    editingAppVersionDetails
                  }
                  titleLabel={t("pages.announcements.detailPage.buttons.saveCreate")}
                  redTheme={false}
                  style={{ minWidth: "fit-content" }}
                  onClick={showSaveModal}
                />
              </ButtonContainer>
              <ButtonContainer>
                <PrimaryButton
                  id={"publish"}
                  disabled={
                    isLoadingAnnouncements ||
                    isLoadingAnnouncement ||
                    numChanges === 0 ||
                    showTumbnail ||
                    showTumbnailForEndDate ||
                    editingCampaignDetails ||
                    editingHomeScreenDetails ||
                    editingStoryDetails ||
                    editingAppVersionDetails
                  }
                  titleLabel={t("pages.announcements.detailPage.buttons.publish")}
                  redTheme={true}
                  style={{ minWidth: "fit-content" }}
                  onClick={showPublishModal}
                />
              </ButtonContainer>
            </>
          )
        }
      />
      {isLoadingAnnouncements || isLoadingAnnouncement ? (
        <LoadingContainer>
          <div>
            <LoadingComponent />
          </div>
        </LoadingContainer>
      ) : (
        <>
          <DivContainerStatusActions>
            <RatioContainer ratio={1 / 12} style={{ marginRight: "24px" }}>
              <Column>
                <Label>{t("pages.announcements.rows.status")}</Label>
                <StatusStyle>
                  <SpanStatusStyle
                    style={{
                      backgroundColor: getColorStatus(announcementSelected.status as string),
                    }}
                  >
                    {t(`pages.announcements.statusEnum.${announcementSelected.status}`)}
                  </SpanStatusStyle>
                </StatusStyle>
              </Column>
            </RatioContainer>
            <RatioContainer ratio={automaticSuggestedEnabled ? 1 / 12 : 8 / 12} style={{ marginRight: "24px" }}>
              <Column>
                <Label>{t("pages.announcements.rows.create")}</Label>
                <SpanHeaderContainerStyle>
                  <SpanHeaderStyle>
                    {announcement
                      ? moment(announcement.createDate as string).format("DD MMM YYYY")
                      : moment().format("DD MMM YYYY")}
                  </SpanHeaderStyle>
                </SpanHeaderContainerStyle>
              </Column>
            </RatioContainer>
            {automaticSuggestedEnabled && (
              <RatioContainer ratio={7.4 / 12} style={{ marginRight: "24px" }}>
                <Column>
                  <Label>{t("pages.announcements.rows.campaignId")}</Label>
                  <SpanHeaderContainerStyle>
                    <SpanHeaderStyle>{announcement?.id}</SpanHeaderStyle>
                  </SpanHeaderContainerStyle>
                </Column>
              </RatioContainer>
            )}

            <RatioContainer
              ratio={2.6 / 12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent:
                  announcementSelected.status === StatusType.SCHEDULED ||
                  announcementSelected.status === StatusType.DRAFT ||
                  announcementSelected.status === StatusType.CANCELED
                    ? "flex-end"
                    : "space-between",
              }}
            >
              <Column>
                {announcementSelected.status === StatusType.LIVE ||
                announcementSelected.status === StatusType.EXPIRED ? (
                  <></>
                ) : (
                  <FastActionWrapper isDisabled={false}>
                    <FastActionButton
                      label={t("pages.announcements.detailPage.buttons.delete")}
                      onClick={showDeleteModal}
                      iconComponent={
                        <div
                          style={{
                            stroke: "#e60000",
                            width: 16,
                            height: 16,
                          }}
                        >
                          <TrashIcon />
                        </div>
                      }
                    />
                  </FastActionWrapper>
                )}
              </Column>
              {announcementSelected.status !== StatusType.SCHEDULED &&
              announcementSelected.status !== StatusType.DRAFT &&
              announcementSelected.status !== StatusType.EXPIRED &&
              announcementSelected.status !== StatusType.CANCELED ? (
                <Column>
                  <FastActionWrapper isDisabled={false}>
                    <FastActionButton
                      label={t("pages.announcements.detailPage.buttons.cancel")}
                      onClick={showCancelModal}
                      iconComponent={
                        <div
                          style={{
                            stroke: "#999",
                            width: 16,
                            height: 16,
                          }}
                        >
                          <CancelIcon />
                        </div>
                      }
                    />
                  </FastActionWrapper>
                </Column>
              ) : null}
            </RatioContainer>
          </DivContainerStatusActions>
          <DivTabsDetails key={formKey}>
            <Tabs isSecondaryTabs={true} isSfcTabs={true} controlledSelectedTab={tab} controlledSetSelectedTab={setTab}>
              <TabChild
                label={
                  <TabTitle editing={tab !== TabState.CAMPAIGN_DETAILS && editingCampaignDetails}>
                    {t("pages.announcements.detailPage.tabs.first")}
                  </TabTitle>
                }
              >
                <hr />
                <DivMainContent>
                  <DivContent>
                    <AnnouncementsStepCampaignDetails
                      key={announcementSelected.status}
                      announcement={announcementSelected}
                      setAnnouncement={setAnnouncementSelected}
                      isEditing={editingCampaignDetails}
                      onSubmitForm={(values?: IAnnouncement) => {
                        submitForm(setEditingCampaignDetails);
                        if (values) {
                          setSelectedAnnouncementSavedData(values);
                        }
                      }}
                      detailPage={true}
                      onReady={() => null}
                      showUpdateDate={showTumbnail}
                      showUpdateDateForEndDate={showTumbnailForEndDate}
                      setEditingAppVersion={setEditingAppVersionDetails}
                      setEditingHomescreenCard={setEditingHomeScreenDetails}
                      selectedAnnouncementSavedData={selectedAnnouncementSavedData}
                    />
                  </DivContent>
                  <DivSidemenuContent>
                    {announcementSelected.status === StatusType.LIVE ||
                    announcementSelected.status === StatusType.EXPIRED ? (
                      <></>
                    ) : (
                      <SideMenuDetails
                        isEditing={editingCampaignDetails}
                        setEditing={setEditingCampaignDetails}
                        textEditing={t("pages.announcements.detailPage.sidemenu.campaignDetails")}
                        form={AnnouncementFormSteps.STEP_ONE}
                        onCancelSideMenu={() => {
                          setAnnouncementSelected(selectedAnnouncementSavedData);
                        }}
                      />
                    )}
                  </DivSidemenuContent>
                </DivMainContent>
              </TabChild>
              <TabChild
                label={
                  <TabTitle editing={tab !== TabState.APP_VERSION && editingAppVersionDetails}>
                    {t("pages.announcements.detailPage.tabs.appVersion")}
                  </TabTitle>
                }
              >
                <hr />
                <DivMainContent>
                  <DivContent>
                    <AnnouncementsStepAppVersion
                      key={announcementSelected.status}
                      announcement={announcementSelected}
                      setAnnoucement={setAnnouncementSelected}
                      isEditing={editingAppVersionDetails}
                      onSubmitForm={() => {
                        submitForm(setEditingAppVersionDetails);
                        setSelectedAnnouncementSavedData(announcementSelected);
                      }}
                      onReady={() => null}
                      setEditingAppVersion={setEditingAppVersionDetails}
                    />
                  </DivContent>
                  <DivSidemenuContent>
                    {announcementSelected.status === StatusType.LIVE ||
                    announcementSelected.status === StatusType.EXPIRED ? (
                      <></>
                    ) : (
                      <SideMenuDetails
                        isEditing={editingAppVersionDetails}
                        setEditing={setEditingAppVersionDetails}
                        textEditing={t("pages.announcements.detailPage.sidemenu.appVersion")}
                        form={AnnouncementFormSteps.STEP_TWO}
                        onCancelSideMenu={() => {
                          setAnnouncementSelected(selectedAnnouncementSavedData);
                        }}
                      />
                    )}
                  </DivSidemenuContent>
                </DivMainContent>
              </TabChild>
              <TabChild
                label={
                  <TabTitle editing={tab !== TabState.HOMESCREEN_CARD && editingHomeScreenDetails}>
                    {t("pages.announcements.detailPage.tabs.second")}
                  </TabTitle>
                }
              >
                <hr />
                <DivMainContent>
                  <DivContent>
                    {AnnouncementsUtils.isConsumerAudience(announcementSelected.audience as AudienceType) &&
                    !AnnouncementsUtils.areDeprecatedVersionsSelected(
                      announcementSelected,
                      config!.appVersionDependencies.announcementsThumbnail.min,
                    ) ? (
                      <AnnouncementsStepHomescreenCardConsumer
                        key={announcementSelected.status}
                        announcement={announcementSelected}
                        setAnnoucement={setAnnouncementSelected}
                        isEditing={editingHomeScreenDetails}
                        onSubmitForm={() => {
                          submitForm(setEditingHomeScreenDetails);
                          setSelectedAnnouncementSavedData(announcementSelected);
                        }}
                        hideTitle={true}
                        onReady={() => null}
                        setEditingHomescreenCard={setEditingHomeScreenDetails}
                        selectedAnnouncementSavedData={selectedAnnouncementSavedData}
                        setSelectedAnnouncementSavedData={setSelectedAnnouncementSavedData}
                      />
                    ) : (
                      <AnnouncementsStepHomescreenCardBusiness
                        key={announcementSelected.status}
                        announcement={announcementSelected}
                        setAnnoucement={setAnnouncementSelected}
                        isEditing={editingHomeScreenDetails}
                        onSubmitForm={(values?: IAnnouncement) => {
                          submitForm(setEditingHomeScreenDetails);
                          if (values) {
                            setSelectedAnnouncementSavedData(values);
                          }
                        }}
                        hideTitle={true}
                        onReady={() => null}
                        setEditingHomescreenCard={setEditingHomeScreenDetails}
                        selectedAnnouncementSavedData={selectedAnnouncementSavedData}
                      />
                    )}
                  </DivContent>
                  <DivSidemenuContent>
                    {announcementSelected.status === StatusType.LIVE ||
                    announcementSelected.status === StatusType.EXPIRED ? (
                      <></>
                    ) : (
                      <SideMenuDetails
                        isEditing={editingHomeScreenDetails}
                        setEditing={setEditingHomeScreenDetails}
                        textEditing={t("pages.announcements.detailPage.sidemenu.homeDetails")}
                        form={AnnouncementFormSteps.STEP_THREE}
                        onCancelSideMenu={() => {
                          setAnnouncementSelected(selectedAnnouncementSavedData);
                        }}
                      />
                    )}
                  </DivSidemenuContent>
                </DivMainContent>
              </TabChild>
              <TabChild
                label={
                  <TabTitle editing={tab !== TabState.ANNOUNCEMENTS_DETAILS && editingStoryDetails}>
                    {t("pages.announcements.detailPage.tabs.third")}
                  </TabTitle>
                }
              >
                <hr />
                <DivMainContent>
                  <DivAnnouncementContent>
                    <AnnouncementsStepAnouncementDetails
                      key={announcementSelected.status}
                      announcement={announcementSelected}
                      setAnnoucement={setAnnouncementSelected}
                      isEditing={editingStoryDetails}
                      onSubmitForm={(values?: IAnnouncement) => {
                        submitForm(setEditingStoryDetails);
                        if (values) {
                          setSelectedAnnouncementSavedData(values);
                        }
                      }}
                      hideTitle={true}
                      onReady={() => null}
                      detailpage={true}
                      setEditing={setEditingStoryDetails}
                      selectedAnnouncementSavedData={selectedAnnouncementSavedData}
                    />
                  </DivAnnouncementContent>
                </DivMainContent>
              </TabChild>
            </Tabs>
          </DivTabsDetails>
        </>
      )}
    </PageContainer>
  );
};

const DivContainerStatusActions = styled("div")`
  margin-bottom: 34px;
  display: flex;
  flex-direction: row;
`;

const Label = styled("span")`
  font-family: Vodafone Rg;
  font-weight: bold;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
`;

const StatusStyle = styled.div`
  margin: 1.65vh 0 1.65vh 0;
`;

const SpanHeaderContainerStyle = styled.div`
  margin: 1.65vh 0 1.65vh 0;
`;
const SpanStatusStyle = styled.span`
  font-family: Vodafone RgBd;
  border-radius: 10.5px;
  background-color: red;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  padding: 0.2vh 0.625vw 0.3vh 0.625vw;
`;

const SpanHeaderStyle = styled.span`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #333;
`;

const FastActionWrapper = styled("div")<{ isDisabled: boolean }>`
  button {
    width: inherit;
    min-height: 27px;
    height: inherit;
    ${props =>
      props.isDisabled
        ? "pointer-events: none; opacity:0.5;background-color: #ffffff; :hover{cursor:not-allowed;}"
        : null}
  }
`;

const DivTabsDetails = styled("div")`
  > div > div > div > div {
    z-index: 1;
  }
  > div > div:last-child {
    margin-top: -10px;
    hr {
      border: 1px solid ${props => props.theme.palette.aluminium};
    }
  }
`;

const DivMainContent = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const DivContent = styled("div")`
  flex: 0.85;
`;

const DivAnnouncementContent = styled("div")`
  flex: 1;
`;

const DivSidemenuContent = styled("div")`
  flex: 0.125;
  z-index: 0 !important;
`;

const LoadingContainer = styled("div")`
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TabTitle = styled("span")<{ editing: boolean }>`
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;

  letter-spacing: 0.4px;
  ${props =>
    props.editing
      ? `::after {
    content: " *";
    color: #ff0000;
    padding-left: 2px;
    font-family: Vodafone Rg;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
      }`
      : null};
`;

export default AnnouncementDetails;
