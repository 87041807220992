import i18next from "i18next";
import { array, object, string } from "yup";
import { IDynamicScreenCategory } from "../../configurations/dynamic-screens/dynamic-screens.model";

/**
 * filters categories by name
 * @param {IDynamicScreenCategory[]} categories list of categories to be filtered
 * @param {string} searchString the filter string
 * @returns {IDynamicScreenCategory[]} filtered categories
 */
const generalFilterCategoriesBySearch = (
  categories: IDynamicScreenCategory[],
  searchString: string,
): IDynamicScreenCategory[] => {
  if (searchString) {
    return categories.filter(category =>
      category.name.translations["en"].toLowerCase().includes(searchString.toLowerCase()),
    );
  }
  return categories;
};

/**
 * filters categories by muiltiple filters
 * @param {IDynamicScreenCategory[]} categories list of categories to be filtered
 * @param {Map<string, string[]>} filters filters to be applied to the categories
 * @returns {IDynamicScreenCategory[]} filtered categories
 */
export const generalFilterCategories = (
  categories: IDynamicScreenCategory[],
  filters: Map<string, string[]>,
): IDynamicScreenCategory[] => {
  let newCategories = categories;
  const search = filters.get("search");

  if (search) {
    newCategories = generalFilterCategoriesBySearch(categories, search[0]);
  }

  return newCategories;
};

/**
 * general categories validate schema
 * @returns {object} yup schema for general categories
 */
export const validateSchemaGeneralCategories = () =>
  object().shape({
    categoryName: string().required(i18next.t("commons.mandatoryField")),
    iconId: string().required(i18next.t("commons.mandatoryField")),
    externalCategoryIds: array().when("isDefault", {
      is: false,
      then: array()
        .required(i18next.t("commons.atLeastOneField"))
        .min(1, i18next.t("commons.atLeastOneField"))
        .max(20)
        .of(string()),
    }),
  });
