/*
 * Rate cards API methods
 * */
import {
  PushNotificationAppVersionsEnum,
  PushNotificationLinkTypeEnum,
  PushNotificationUsersEnum,
  IPushNotificationLinkContent,
} from "./push-notifications.model";
import ApiClient from "../../../configs/axios.config";
import { AxiosPromise } from "axios";

export interface ICreatePushNotificationPayload {
  title: { [key: string]: string };
  message: { [key: string]: string };
  users: PushNotificationUsersEnum;
  appVersion: PushNotificationAppVersionsEnum;
  versions?: string[];
  linkType?: PushNotificationLinkTypeEnum;
  linkContent?: IPushNotificationLinkContent;
  scheduledDate?: number;
  sendImmediately?: boolean;
  deepLinkType?: PushNotificationLinkTypeEnum;
}

const PushNotificationsApi = {
  routes: {
    getPushNotifications: "/v1/pushnotifs",
    getPushNotification: "/v1/pushnotifs/:id",
    createPushNotifications: "/v1/pushnotifs",
    editPushNotifications: "/v1/pushnotifs/:id",
    publishPushNotification: "/v1/pushnotifs/:id/publish",
    deletePushNotification: "/v1/pushnotifs/:id",
  },
  methods: {
    getPushNotifications: (): Promise<any> => {
      return ApiClient.get(PushNotificationsApi.routes.getPushNotifications, {});
    },
    getPushNotification: (id: any): Promise<any> => {
      return ApiClient.get(PushNotificationsApi.routes.getPushNotification.replace(":id", id), {});
    },
    createPushNotification: (payload: any): Promise<any> => {
      return ApiClient.post(PushNotificationsApi.routes.createPushNotifications, payload);
    },
    editPushNotification: (id: any, payload: any): Promise<any> => {
      return ApiClient.put(PushNotificationsApi.routes.editPushNotifications.replace(":id", id), payload);
    },
    publishPushNotification: (id: any): AxiosPromise => {
      return ApiClient.put(PushNotificationsApi.routes.publishPushNotification.replace(":id", id));
    },
    deletePushNotification: (id: any): Promise<any> => {
      return ApiClient.delete(PushNotificationsApi.routes.deletePushNotification.replace(":id", id));
    },
  },
};

export default PushNotificationsApi;
