import {
  antExternalServicesReducer,
  IAntExternalServicesReducerInterface,
} from "./../features/ant-service-manager/external-services/ant-external-services.store";
import { applyMiddleware, combineReducers, createStore, Store } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import { tasksReducer, ITasksReducerInterface } from "../features/admin/tasks/tasks.store";
import { IUserReducerInterface, usersReducer } from "../features/admin/users/users.store";
import {
  appPropertiesReducer,
  IAppPropertiesReducerInterface,
} from "../features/apps/app-details/app-properties/app-properties.store";
import { appsReducer, IAppsReducerInterface } from "../features/apps/apps.store";
import { auditingLogsReducer, IAuditingLogsReducerInterface } from "../features/auditing/auditing.store";
import {
  authenticationReducer,
  IAuthenticationReducerInterface,
} from "../features/authentication/authentication.store";
import { banksReducer, IBanksReducerInterface } from "../features/configurations/banks/banks.store";
import { bundlesReducer, IBundlesReducerInterface } from "../features/configurations/bundles/bundles.store";
import { businessesReducer, IBusinessesReducerInterface } from "../features/configurations/business/business.store";
import {
  discoverCardsReducer,
  IDiscoverCardsReducerInterface,
} from "../features/configurations/discover-cards/discover-cards.store";
import {
  ILanguageTranslationsReducerInterface,
  languageTranslationsReducer,
} from "../features/configurations/language-translations/language-translations.store";
import {
  referralCampaignsReducer,
  IReferralCampaignsReducerInterface,
} from "../features/configurations/referral-campaigns/referral-campaigns.store";
import { documentsReducer, IDocumentsReducerInterface } from "../features/configurations/documents/documents.store";
import {
  dynamicScreensReducer,
  IDynamicScreensReducerInterface,
} from "../features/configurations/dynamic-screens/dynamic-screens.store";
import { networksReducer, INetworksReducerInterface } from "../features/configurations/networks/networks.store";
import {
  pushNotificationsReducer,
  IPushNotificationsReducerInterface,
} from "../features/tools/push-notifications/push-notifications.store";
import { rateCardsReducer, IRateCardsReducerInterface } from "../features/configurations/rate-cards/rate-cards.store";
import {
  externalServicesReducer,
  IExternalServicesReducerInterface,
} from "../features/safaricom-service-manager/external-services/external-services.store";
import {
  serviceManagerServiceDetailsReducer,
  IServiceManagerServiceDetailsReducerInterface,
} from "../features/safaricom-service-manager/service-details/service-manager-service-details.store";
import {
  serviceManagerReducer,
  IServiceManagerReducerInterface,
} from "../features/safaricom-service-manager/service-manager.store";
import { servicesReducer, IServicesReducerInterface } from "../features/service-builder/services.store";
import {
  serviceDetailsReducer,
  IServiceDetailsReducerInterface,
} from "../features/service-details/service-details.store";
import { shortcutsReducer, IShortcutsReducerInterface } from "../features/configurations/shortcuts/shortcuts.store";
import { supportReducer, ISupportReducerInterface } from "../features/support/support.store";
import { reportsReducer, IReportsReducerInterface } from "../features/tools/request-money/request-money.store";
import { ISurveyReducerInterface, surveysReducer } from "../features/tools/surveys/surveys.store";
import { announcementsReducer, IAnnouncementsReducerInterface } from "../features/announcements/announcements.store";
import {
  docValidationReducer,
  IDocValidationReducerInterface,
} from "../features/ekyc/doc-validation/doc-validation.store";
import {
  docValidationDetailsReducer,
  docValidationDetailsImagesReducer,
} from "../features/ekyc/doc-validation-details/doc-validation-details.store";
import { IDocBulksListReducerInterface, docBulksListReducer } from "../features/ekyc/doc-bulk/doc-bulk.store";
import {
  IDocBulkDetailsReducerInterface,
  docBulkDetailsReducer,
} from "../features/ekyc/doc-bulk-details/doc-bulk-details.store";
import {
  IDocDashboardCounterReducerInterface,
  docDashboardReducer,
  docDashboardOverallRatingDetailsReducer,
  IDocDashboardOverallRatingDetailsReducerInterface,
} from "../features/ekyc/doc-dashboard/doc-dashboard.store";
import {
  antServiceManagerReducer,
  IAntServiceManagerReducerInterface,
} from "../features/ant-service-manager/ant-service-manager.store";
import {
  antServiceManagerServiceDetailsReducer,
  IAntServiceManagerServiceDetailsReducerInterface,
} from "../features/ant-service-manager/service-details/ant-service-manager-service-details.store";
import {
  generalCategoriesReducer,
  IGeneralCategoriesReducerInterface,
} from "../features/configurations/categories/general-categories.store";
import {
  referralAnalyticsReducer,
  IReferralAnalyticsReducerInterface,
} from "../features/configurations/referral-campaigns/referral-campaigns-analytics/referral-campaigns-analytics.store";
import {
  IDocValidationDetailsReducerInterface,
  IDocValidationDetailsImagesReducerInterface,
} from "../features/ekyc/doc-validation-details/doc-validation-details.interface";
import {
  feedbackAndProblemsReportReducer,
  IFeedbackAndProblemsReducerInterface,
} from "../features/configurations/feedback-and-problems/feedback-and-problems.store";
import {
  ISFCPushNotificationsReducerInterface,
  sfcPushNotificationsReducer,
} from "../features/safaricom-push-notifications/push-notifications.store";

export interface IStoreInterface {
  appPropertiesReducer: IAppPropertiesReducerInterface;
  appsReducer: IAppsReducerInterface;
  authenticationReducer: IAuthenticationReducerInterface;
  banksReducer: IBanksReducerInterface;
  bundlesReducer: IBundlesReducerInterface;
  businessesReducer: IBusinessesReducerInterface;
  discoverCardsReducer: IDiscoverCardsReducerInterface;
  documentsReducer: IDocumentsReducerInterface;
  dynamicScreensReducer: IDynamicScreensReducerInterface;
  referralCampaignsReducer: IReferralCampaignsReducerInterface;
  externalServicesReducer: IExternalServicesReducerInterface;
  networksReducer: INetworksReducerInterface;
  pushNotificationsReducer: IPushNotificationsReducerInterface;
  rateCardsReducer: IRateCardsReducerInterface;
  reportsReducer: IReportsReducerInterface;
  serviceDetailsReducer: IServiceDetailsReducerInterface;
  serviceManagerReducer: IServiceManagerReducerInterface;
  serviceManagerServiceDetailsReducer: IServiceManagerServiceDetailsReducerInterface;
  announcementsReducer: IAnnouncementsReducerInterface;
  servicesReducer: IServicesReducerInterface;
  shortcutsReducer: IShortcutsReducerInterface;
  supportReducer: ISupportReducerInterface;
  surveysReducer: ISurveyReducerInterface;
  tasksReducer: ITasksReducerInterface;
  usersReducer: IUserReducerInterface;
  auditingLogsReducer: IAuditingLogsReducerInterface;
  docValidationReducer: IDocValidationReducerInterface;
  docValidationDetailsReducer: IDocValidationDetailsReducerInterface;
  docValidationDetailsImagesReducer: IDocValidationDetailsImagesReducerInterface[];
  docBulksListReducer: IDocBulksListReducerInterface;
  docBulkDetailsReducer: IDocBulkDetailsReducerInterface;
  docDashboardReducer: IDocDashboardCounterReducerInterface;
  docDashboardOverallRatingDetailsReducer: IDocDashboardOverallRatingDetailsReducerInterface;
  antServiceManagerReducer: IAntServiceManagerReducerInterface;
  antServiceManagerServiceDetailsReducer: IAntServiceManagerServiceDetailsReducerInterface;
  languageTranslationsReducer: ILanguageTranslationsReducerInterface;
  antExternalServicesReducer: IAntExternalServicesReducerInterface;
  generalCategoriesReducer: IGeneralCategoriesReducerInterface;
  referralAnalyticsReducer: IReferralAnalyticsReducerInterface;
  feedbackAndProblemsReportReducer: IFeedbackAndProblemsReducerInterface;
  sfcPushNotificationsReducer: ISFCPushNotificationsReducerInterface;
}

// Turn on/off the session storage persistence
const shouldReadPreviousStoreState = true;

/* process.env.REACT_APP_ENVIRONMENT === "development" ? false : true; */

/**
 * This function is used to save the store in localstorage
 */
const saveStore = ({ usersReducer, ...rest }: IStoreInterface) => {
  localStorage.setItem("store", JSON.stringify(rest));
};

/**
 * This  function is to get the store from local storage
 */
const retrieveStore = (): IStoreInterface | undefined => {
  try {
    const previousStoreState = localStorage.getItem("store");

    if (previousStoreState) {
      const parsedState = JSON.parse(previousStoreState);
      return parsedState;
    }
  } catch {
    // TODO: Handle errors
  }

  return undefined;
};

function getStore() {
  const logger = createLogger({ collapsed: true });

  const reducers = combineReducers({
    appPropertiesReducer,
    appsReducer,
    authenticationReducer,
    banksReducer,
    bundlesReducer,
    businessesReducer,
    discoverCardsReducer,
    documentsReducer,
    dynamicScreensReducer,
    referralCampaignsReducer,
    externalServicesReducer,
    networksReducer,
    pushNotificationsReducer,
    rateCardsReducer,
    reportsReducer,
    serviceDetailsReducer,
    serviceManagerReducer,
    serviceManagerServiceDetailsReducer,
    announcementsReducer,
    servicesReducer,
    supportReducer,
    surveysReducer,
    tasksReducer,
    usersReducer,
    auditingLogsReducer,
    docValidationReducer,
    docValidationDetailsReducer,
    docValidationDetailsImagesReducer,
    docBulksListReducer,
    docBulkDetailsReducer,
    docDashboardReducer,
    docDashboardOverallRatingDetailsReducer,
    antServiceManagerReducer,
    antServiceManagerServiceDetailsReducer,
    languageTranslationsReducer,
    antExternalServicesReducer,
    generalCategoriesReducer,
    referralAnalyticsReducer,
    feedbackAndProblemsReportReducer,
    shortcutsReducer,
    sfcPushNotificationsReducer,
  });

  const store: Store = createStore(
    reducers,
    shouldReadPreviousStoreState ? retrieveStore() : {},
    composeWithDevTools(applyMiddleware(logger)),
  );

  store.subscribe(() => {
    saveStore(store.getState());
  });

  return store;
}

export default getStore();
