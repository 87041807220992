import { MakerCheckerSectionsEnum } from "../../../shared/components/notifications-area/notification.model";

export interface ITask {
  id: string | number;
  author: {
    name: string;
    id: string;
  };
  section: MakerCheckerSectionsEnum;
  date: number;
  status: TaskStatusEnum;
  approver?: {
    name: string;
    id: string;
  };
  reason?: string;
  updateDate?: number;
}

export enum TaskStatusEnum {
  PENDING_APPROVAL = "PENDING_APPROVAL",
  DECLINED = "DECLINED",
  APPROVED = "APPROVED",
}
