import React from "react";

const NoNotificationBellIcon = () => {
  return (
    <svg
      width="140px"
      height="140px"
      viewBox="0 0 140 140"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Icon / Hi / No Notification </title>
      <defs>
        <linearGradient id="r3qz1bx5ka" x1="19.41%" x2="105.332%" y1="-3.669%" y2="70.507%">
          <stop offset="0%" stopColor="#FFF" />
          <stop offset="100%" stopColor="#FFF" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="3mf3sov43b" x1="42.652%" x2="75.683%" y1="5.957%" y2="80.471%">
          <stop offset="0%" />
          <stop offset="100%" stopOpacity="0" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g fillRule="nonzero">
              <path
                fill="#D8D8D8"
                fillOpacity="0"
                d="M0 0H140V140H0z"
                transform="translate(-233 -291) translate(233 291)"
              />
              <path
                fill="#EBEBEB"
                d="M93.902 57.604l-.262-9.056-.132-4.448c-.772-6.214-3.983-11.867-8.925-15.714v-.845c0-1.583-2.61-4.193-5.833-4.193h-.073v-2.931c.033-2.318-.868-4.552-2.5-6.198-1.632-1.647-3.859-2.566-6.177-2.552-4.832 0-8.75 3.917-8.75 8.75v2.916h-.073c-3.15 0-5.76 2.625-5.76 4.208v.845c-4.938 3.847-8.145 9.496-8.918 15.707l-.131 4.513-.263 8.998c-.379 11.07-2.887 21.963-7.386 32.084h62.555c-4.495-10.122-6.998-21.015-7.372-32.084zM64.101 20.417c.048-3.228 2.671-5.822 5.899-5.834 3.222 0 5.833 2.612 5.833 5.834v2.916H64.094l.007-2.916z"
                transform="translate(-233 -291) translate(233 291)"
              />
              <path
                fill="#E60000"
                d="M70 115.208c-3.624 0-6.563 2.938-6.563 6.563 0 3.624 2.939 6.562 6.563 6.562 3.624 0 6.563-2.938 6.563-6.562 0-3.625-2.939-6.563-6.563-6.563z"
                transform="translate(-233 -291) translate(233 291)"
              />
              <path
                fill="#000"
                d="M70 126.875c-3.341-.002-6.148-2.513-6.519-5.833-.291 2.509.884 4.963 3.021 6.309 2.138 1.346 4.858 1.346 6.996 0 2.137-1.346 3.312-3.8 3.02-6.31-.37 3.321-3.177 5.832-6.518 5.834z"
                opacity=".12"
                transform="translate(-233 -291) translate(233 291)"
              />
              <path
                fill="url(#r3qz1bx5ka)"
                d="M74.112 116.667h-8.268l9.26 9.275c1.107-1.353 1.628-3.09 1.449-4.83-.18-1.738-1.045-3.333-2.404-4.43l-.037-.015z"
                opacity=".2"
                transform="translate(-233 -291) translate(233 291)"
              />
              <path
                fill="#EBEBEB"
                d="M112.168 117.396c.892-.016 1.729-.44 2.269-1.152.54-.71.725-1.63.502-2.494l-.591-2.078c-1.458-5.833-3.39-8.16-7.481-12.542-1.31-1.406-2.442-2.968-3.37-4.652-.32-.583-.633-1.254-.94-1.852H37.443c-.307.598-.62 1.269-.94 1.852-.928 1.684-2.06 3.246-3.37 4.652-4.098 4.375-6.008 6.73-7.48 12.542l-.592 2.078c-.223.864-.038 1.784.502 2.494.54.711 1.377 1.136 2.27 1.152h84.335z"
                transform="translate(-233 -291) translate(233 291)"
              />
              <path
                fill="#4A4D4E"
                d="M101.274 89.688H38.719c-.445.947-.802 1.997-1.284 2.916h65.122c-.474-.911-.838-1.969-1.283-2.916z"
                transform="translate(-233 -291) translate(233 291)"
              />
              <path
                fill="#000"
                d="M61.25 21.875L64.094 21.875 64.094 23.333 61.25 23.333zM75.833 21.875L78.677 21.875 78.677 23.333 75.833 23.333z"
                opacity=".12"
                transform="translate(-233 -291) translate(233 291)"
              />
              <path
                fill="url(#3mf3sov43b)"
                d="M93.902 57.604l-.262-9.056-.132-4.448c-.663-5.385-3.16-10.378-7.073-14.139-.59-.566-1.207-1.101-1.852-1.604v-.816c0-1.575-2.603-4.178-5.891-4.193v-2.931c.025-2.319-.88-4.55-2.514-6.195-1.634-1.645-3.86-2.565-6.178-2.555-4.832 0-8.75 3.917-8.75 8.75v2.916c-3.223 0-5.833 2.61-5.833 4.193v.846l-.066.058 39.535 39.536c-.538-3.43-.867-6.891-.984-10.362zm-18.069-34.27H64.108v-2.917c.04-3.228 2.664-5.826 5.892-5.834 3.222 0 5.833 2.612 5.833 5.834v2.916z"
                opacity=".2"
                transform="translate(-233 -291) translate(233 291)"
              />
              <path
                fill="#000"
                d="M55.854 26.98h28.292c.241 0 .437.195.437.437v1.02H55.417v-1.02c0-.242.196-.438.437-.438z"
                opacity=".12"
                transform="translate(-233 -291) translate(233 291)"
              />
              <path
                fill="#000"
                d="M112.168 115.938H27.832c-1.315-.018-2.456-.914-2.785-2.188-.224.867-.037 1.789.506 2.5.544.712 1.384 1.134 2.28 1.146h84.335c.895-.012 1.735-.434 2.279-1.146.543-.711.73-1.633.506-2.5-.329 1.274-1.47 2.17-2.785 2.188z"
                opacity=".06"
                transform="translate(-233 -291) translate(233 291)"
              />
            </g>
            <path
              fill="#E81818"
              d="M-5.104 74.375L139.271 74.375 139.271 76.563 -5.104 76.563z"
              transform="translate(-233 -291) translate(233 291) rotate(-45 67.083 75.469)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
export default NoNotificationBellIcon;
