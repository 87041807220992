import { ColumnProps } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import { TFunction } from "i18next";
import React from "react";
import { AntExternalServicePermitionsEnum, IExternalServices } from "../../../shared/models/ant-service-manager.model";

/** Configurations Utils */
export const getAntExternalServicesColumns = (t: TFunction): ColumnProps[] => {
  return [
    {
      formKey: "serviceId",
      label: t("pages.antExternalServices.configurations.columns.serviceId"),
      isEditable: false,
      ratio: 2 / 12,
      changeFunction: (content: string, row: IExternalServices) => {
        let pin = false;
        let ident = false;

        if (row.permissions !== undefined) {
          ident = row.permissions.includes(AntExternalServicePermitionsEnum.IDENTITY);
          pin = row.permissions.includes(AntExternalServicePermitionsEnum.PIN);
        }

        if (ident) {
          return (
            <div style={{ color: "#6338eb", overflow: "hidden", textOverflow: "ellipsis" }} id={row.externalServiceId}>
              {row.externalServiceId}
            </div>
          );
        } else {
          if (pin) {
            return (
              <div
                style={{ color: "#00c3ff", overflow: "hidden", textOverflow: "ellipsis" }}
                id={row.externalServiceId}
              >
                {row.externalServiceId}
              </div>
            );
          } else {
            return (
              <div style={{ overflow: "hidden", textOverflow: "ellipsis" }} id={row.externalServiceId}>
                {row.externalServiceId}
              </div>
            );
          }
        }
      },
    },
    {
      formKey: "url",
      label: t("pages.antExternalServices.configurations.columns.url"),
      isEditable: true,
      ratio: 3.5 / 12,
    },
    {
      formKey: "contextUrl",
      label: t("pages.antExternalServices.configurations.columns.contextURL"),
      isEditable: false,
      ratio: 4 / 12,
      changeFunction: (content: string, row: IExternalServices) => {
        return process.env.REACT_APP_ANT_EXTERNAL_SERVICES_BASE_URL + row.externalServiceId;
      },
    },
    {
      formKey: "permissions",
      label: t("pages.antExternalServices.configurations.columns.permissions"),
      isEditable: true,
      ratio: 1 / 12,
      changeFunction: (content: string) => {
        let pin = false;
        let ident = false;

        if (content) {
          ident = content.includes(AntExternalServicePermitionsEnum.IDENTITY);
          pin = content.includes(AntExternalServicePermitionsEnum.PIN);
        }

        if (ident) {
          return (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ color: "#6338eb" }}>
                {t("pages.antExternalServices.configurations.columns.permissionIDENTITY")}
              </div>
              <div style={{ color: "#6338eb" }} hidden={!pin}>
                ,
              </div>
              <div style={{ color: "#00c3ff", marginLeft: "5px" }} hidden={!pin}>
                {t("pages.antExternalServices.configurations.columns.permissionPIN")}
              </div>
            </div>
          );
        } else {
          if (pin) {
            return (
              <div style={{ color: "#00c3ff" }}>
                {t("pages.antExternalServices.configurations.columns.permissionPIN")}
              </div>
            );
          } else {
            return <div>-</div>;
          }
        }
      },
    },
  ];
};
