import styled from "styled-components";
import React, { LabelHTMLAttributes } from "react";

interface ITwoWayBindingToggleProps extends LabelHTMLAttributes<any> {
  cb: (value: boolean) => void;
  isDisabled?: boolean;
  initialValue: boolean;
}

const TwoWayBindingToggle = ({ cb, isDisabled = false, initialValue, ...rest }: ITwoWayBindingToggleProps) => {
  function onChange() {
    cb(!initialValue);
  }

  return (
    <ToggleSwitchContainer {...rest}>
      <ToggleInput
        data-testid="toggle"
        value={initialValue as any}
        disabled={isDisabled}
        type="checkbox"
        onChange={isDisabled ? () => null : onChange}
      />
      <Slider isChecked={initialValue} />
    </ToggleSwitchContainer>
  );
};

export default TwoWayBindingToggle;

const constants = {
  toggleWidth: 34,
  toggleHeight: 20,
  toggleButtonWidth: 18,
};

const ToggleSwitchContainer = styled("label")`
  width: ${constants.toggleWidth}px;
  position: relative;
  display: inline-block;
  height: ${constants.toggleHeight}px;
`;

const ToggleInput = styled("input")`
  opacity: 0;
  width: 0;
  height: 0;

  :checked {
    transform: translateX(50%);
  }

  :focus {
    box-shadow: 0 0 1px #2196f3;
  }
`;

const Slider = styled("span")<{ isChecked: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 83.3px;
  background-color: ${props => (props.isChecked ? props.theme.palette.red.normal : props.theme.palette.greyDarker)};

  :before {
    position: absolute;
    content: "";
    height: ${constants.toggleButtonWidth}px;
    width: ${constants.toggleButtonWidth}px;
    top: 1px;
    left: 1px;
    bottom: 0px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);

    ${props => (props.isChecked ? `transform: translateX(${constants.toggleWidth / 2 - 3}px)` : "")};
  }
`;
