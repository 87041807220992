import { ClientDocSubmissionStatusEnum, G2StatusEnum } from "../ekyc.shared.enums";

export const DOC_DASHBOARD = {
  ERROR_STATES: [
    ClientDocSubmissionStatusEnum.ERROR_ON_CRITICAL_FIELDS,
    ClientDocSubmissionStatusEnum.ERROR_ON_FACE_DUPLICATIONS,
    ClientDocSubmissionStatusEnum.ERROR_ON_G2,
    ClientDocSubmissionStatusEnum.ERROR_ON_ID_DUPLICATIONS,
    ClientDocSubmissionStatusEnum.ERROR_ON_ID_VS_SELFIE,
  ],

  PENDING_STATES: [
    ClientDocSubmissionStatusEnum.PENDING_MANUAL_REVIEW,
    ClientDocSubmissionStatusEnum.PENDING_G2_VALIDATION,
    ClientDocSubmissionStatusEnum.PENDING,
  ],
  SUCCESS_STATES: [G2StatusEnum.LITE_ACCOUNT, G2StatusEnum.PREMIUM_ACCOUNT],
};
