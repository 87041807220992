import React from "react";

function StoryPreviewIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 64 64">
      <defs>
        <circle id="cn00j7gxva" cx="32" cy="32" r="32" />
        <circle id="ypstrg79tb" cx="32" cy="32" r="32" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <use fill="#FFF" href="#cn00j7gxva" />
          <mask id="lljhsp6jec" fill="#fff">
            <use href="#ypstrg79tb" />
          </mask>
          <use fill="#1074FF" fillOpacity=".15" href="#ypstrg79tb" />
          <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.062" mask="url(#lljhsp6jec)">
            <g>
              <path
                fill="#219953"
                stroke="#219953"
                d="M16.97 7.12c-3.053 1.631-6.45 2.7-10.055 3.068-.925.095-1.864.143-2.815.143-.123 0-.246 0-.37-.002-.737-.01-1.467-.05-2.19-.117C1.015 9.883.502 9.536 0 9.172c1.286-.386 2.532-.865 3.73-1.43 1.105-.52 2.169-1.11 3.185-1.767.645-.415 1.272-.858 1.877-1.325 2.532 1.227 5.283 2.076 8.178 2.47z"
                transform="translate(14 25)"
              />
              <path
                fill="#2FC56D"
                stroke="#2FC56D"
                d="M36.543 5.126c-1.54 1.741-3.297 3.285-5.23 4.587-1.015.685-2.08 1.303-3.185 1.849-3.645 1.797-7.747 2.807-12.085 2.807-3.2 0-6.273-.55-9.128-1.56-1.095-.388-2.158-.842-3.184-1.361-.751-.38-1.481-.791-2.19-1.236.722.068 1.452.107 2.19.117.123.002.246.002.37.002.95 0 1.889-.048 2.814-.143C10.52 9.82 13.917 8.751 16.97 7.12c3.314-1.769 6.22-4.202 8.543-7.12.834.647 1.708 1.247 2.615 1.796 1.02.617 2.084 1.168 3.185 1.65.681.297 1.377.568 2.086.81 1.022.351 2.072.642 3.144.87z"
                transform="translate(14 25)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default StoryPreviewIcon;
