import { PrimaryButton } from "@wit/mpesa-ui-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import MultipleOptionDropdown from "@wit/mpesa-ui-components/lib/components/dropdown/multiple-option-dropdown/multiple-option-dropdown.component";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Column, RatioContainer, Row } from "../../../shared/shared.styled";
import { ICategory } from "../pages/categories/categories.model";
import ServiceManagerApi from "../service-manager.api";
import { getCategoriesOptions, getSelectedCategoriesOptions } from "../service-manager.utils";
import XCloseIcon from "../../../shared/icons/x-close.icon";

interface IEditMiniAppCategoryProps {
  primaryAction: () => void;
  secondaryAction: () => void;
  miniAppID: string;
  categoryID: string;
  categories: ICategory[];
}

/**
 * Edit Mini App Category modal
 * This modal adding min-app to category/categories before deleting it
 * @param param0
 */
const EditMiniAppCategoryModal = ({
  primaryAction,
  secondaryAction,
  categories,
  categoryID,
  miniAppID,
}: IEditMiniAppCategoryProps) => {
  const [categoriesOptionList, setCategoriesOptionList] = useState<SharedDropdownOption[]>([]);
  const [categoriesNotSelected, setCategoriesNotSelected] = useState<boolean>(true);
  const [t] = useTranslation();
  React.useEffect(() => {
    if (categories && categories.length > 0) {
      const unSelectedCategories = categories.filter(category => category.id !== categoryID);
      if (unSelectedCategories && unSelectedCategories.length > 0) {
        setCategoriesOptionList(
          unSelectedCategories.map(item => {
            return {
              key: item.id,
              label: item.categoryName,
            };
          }),
        );
      }
    }
  }, []);

  /**
   * Method to handle adding mini-apps to categories
   * @param values
   * @param actions
   */
  const _handleSubmit = (values: any, actions: FormikHelpers<any>) => {
    const categories: string[] = values.categories;
    const promiseList: Promise<any>[] = [];
    categories.forEach(item => {
      promiseList.push(ServiceManagerApi.methods.addMiniApsToCategory(item, [miniAppID]));
    });
    if (promiseList.length > 0) {
      Promise.all(promiseList).then(
        res => {
          primaryAction();
          secondaryAction();
        },
        error => {
          actions.setSubmitting(false);
        },
      );
    }
  };

  /**
   * Get initial values
   */
  const getInitialValues = (): any => {
    return {
      categories: [],
    };
  };

  /**
   * Method to return displayed categories in dropdown textbox
   * @param selectedCategories
   * @returns string
   */
  const getCategoriesLabel = (selectedCategories: string[] | undefined): string => {
    if (selectedCategories && selectedCategories.length > 0) {
      return getSelectedCategoriesOptions(selectedCategories, categoriesOptionList);
    }
    return t("pages.serviceManager.serviceDetails.formRows.category.placeholder");
  };

  /**
   * returns array of actual selectedCategoriesValues keys
   * @param selectedCategories
   */
  const getSelectedCategoriesKeys = (selectedCategories: string[]) => {
    return getCategoriesOptions(categoriesOptionList)
      .map(item => {
        if (item && selectedCategories && selectedCategories.includes(item.key) && item.label !== "") {
          return item.key;
        }
      })
      .filter(item => item !== undefined);
  };
  return (
    <ModalContainer>
      <ModalHeader>
        <TitleContainer>
          <ModalTitle>{t("pages.serviceManager.categories.miniApps.editMiniAppCategoryTitle")}</ModalTitle>
          <CloseButtonContainer onClick={secondaryAction}>
            <XCloseIcon />
          </CloseButtonContainer>
        </TitleContainer>
      </ModalHeader>
      <Formik
        onSubmit={_handleSubmit}
        validateOnChange={false}
        initialValues={getInitialValues()}
        render={({ isSubmitting, handleSubmit, values, setFieldValue, errors, handleChange }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Column>
                <FormInfoContainer>
                  <ModalSubTitle>
                    {t("pages.serviceManager.categories.miniApps.editMiniAppCategorySubTitle")}
                  </ModalSubTitle>
                </FormInfoContainer>
                <FormInfoContainer>
                  <RatioContainer ratio={1}>
                    <Column>
                      <LabelRequired>
                        {t("pages.configurations.businessTab.modal.edit.category.chooseToReplaceWith")}
                      </LabelRequired>
                      <MultipleOptionDropdown
                        dropdownType={DropdownType.RECTANGULAR_NORMAL}
                        label={getCategoriesLabel(values.categories)}
                        hasValue={!!values.categories && values.categories.length > 0}
                        options={categoriesOptionList}
                        toggleOption={(opt: SharedDropdownOption) => {
                          setCategoriesNotSelected(false);
                          if (values.categories && values.categories.includes(opt.key)) {
                            const index = values.categories.indexOf(opt.key);
                            const valuesHelper = [...values.categories];
                            valuesHelper.splice(index, 1);
                            setFieldValue("categories", getSelectedCategoriesKeys(valuesHelper));
                            if (valuesHelper.length === 0) {
                              setCategoriesNotSelected(true);
                            }
                          } else {
                            const categoryList = values.categories || [];
                            setFieldValue("categories", getSelectedCategoriesKeys([...categoryList, opt.key]));
                          }
                        }}
                        isOptionSelected={(opt: SharedDropdownOption) => {
                          return values.categories && values.categories.length > 0
                            ? values.categories.includes(opt.key)
                            : false;
                        }}
                        clearAllFilters={() => {
                          setFieldValue("categories", []);
                        }}
                        error={errors.categories ? t("commons.mandatoryField") : undefined}
                      />
                    </Column>
                  </RatioContainer>
                </FormInfoContainer>
              </Column>
              <ModalFooter>
                <ButtonsContainer>
                  <div>
                    <PrimaryButton
                      id="cancel-button"
                      type="button"
                      onClick={secondaryAction}
                      titleLabel={t("pages.configurations.businessTab.modal.edit.cancelBtnLabel")}
                    />
                  </div>
                  <div>
                    <PrimaryButton
                      redTheme={true}
                      type="submit"
                      titleLabel={t("pages.serviceManager.categories.miniApps.replaceBtnLabel")}
                      disabled={categoriesNotSelected}
                    />
                  </div>
                </ButtonsContainer>
              </ModalFooter>
            </form>
          );
        }}
      />
    </ModalContainer>
  );
};

export default EditMiniAppCategoryModal;

const ModalContainer = styled("div")`
  display: flex;
  flex-direction: column;
  font-family: Vodafone Rg;
  position: relative;
`;

const ModalHeader = styled("div")`
  background-color: #f7fafc;
  height: 5.2rem;
  display: flex;
  align-items: center;
  svg {
    position: absolute;
    right: 35px;
    stroke: #7b7b7b;
    width: 28px;
    height: 28px;
  }
`;
const CloseButtonContainer = styled("div")`
  display: contents;
  cursor: pointer;
`;

const TitleContainer = styled("div")`
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
`;
const ModalTitle = styled("span")`
  color: ${props => props.theme.palette.darkGrey};
  font-size: 20px;
  line-height: 24px;
  font-family: Vodafone Rg;
  background-color: #f2f2f2;
  padding: 5px 10px;
  border-radius: 12px;
`;
const ModalSubTitle = styled("span")`
  color: ${props => props.theme.palette.darkGrey};
  margin: 1rem 0.3rem;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  font-family: Vodafone Rg;
`;

const ModalFooter = styled("div")`
  background-color: #f7fafc;
  margin-top: 1.5rem;
  height: 5.2rem;
  display: flex;
  align-items: center;
  > * {
    width: 100%;
  }
`;
const ButtonsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 2rem;

  > div {
    width: "fit-content";

    :first-of-type {
      margin-right: 12px;
    }
  }
`;
const FormInfoContainer = styled(Row)`
  margin: 1rem 3rem;
`;
const Label = styled("div")`
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
  margin-bottom: 8px;
  min-height: 21px;
`;
const LabelRequired = styled(Label)`
  ::after {
    content: " *";
    color: ${props => props.theme.palette.errorRed};
  }
`;
