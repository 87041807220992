import React, { HTMLProps, ReactNode } from "react";
import styled from "styled-components";
import { PushNotificationStatusEnum } from "../push-notifications.model";

export interface IPushNotificationsStatusChipProps extends HTMLProps<HTMLDivElement> {
  type: PushNotificationStatusEnum;
  children: ReactNode;
}

const PushNotificationsStatusChip = ({ type, children, ref, as, ...rest }: IPushNotificationsStatusChipProps) => {
  return (
    <StatusChipContainer type={type} {...rest}>
      {children}
    </StatusChipContainer>
  );
};

export default PushNotificationsStatusChip;

export const StatusChipContainer = styled("div")<{ type: PushNotificationStatusEnum }>`
  border-radius: 10px;
  padding-left: 12px;
  padding-right: 12px;
  height: 22px;
  line-height: 21px;
  font-weight: bold;
  display: inline-block;
  font-size: 14px;
  text-align: center;
  font-family: Vodafone Rg;

  ${props => {
    let backgroundColor = "";
    let fontColor = props.theme.palette.white;
    let borderColor = "";
    switch (props.type) {
      case PushNotificationStatusEnum.SENT:
      case PushNotificationStatusEnum.NO_DATA:
      case PushNotificationStatusEnum.EXPIRED:
        backgroundColor = props.theme.palette.digitalGreen;
        borderColor = props.theme.palette.digitalGreen;
        break;
      case PushNotificationStatusEnum.SCHEDULED:
        backgroundColor = props.theme.palette.turquoiseBlue;
        borderColor = props.theme.palette.turquoiseBlue;
        fontColor = props.theme.palette.white;
        break;
      case PushNotificationStatusEnum.PROCESSING:
      case PushNotificationStatusEnum.DRAFT:
        backgroundColor = props.theme.palette.white;
        fontColor = props.theme.palette.digitalGreen;
        borderColor = props.theme.palette.digitalGreen;
        break;
    }
    return `background-color: ${backgroundColor}; color: ${fontColor}; border: 1px solid ${borderColor}`;
  }}
`;
