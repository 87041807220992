import { AxiosPromise } from "axios";
import SBApiClient from "../../configs/axios.service-builder.config";
import { IServiceManagerService } from "../../shared/models/ant-service-manager.model";

const AntServiceManagerApi = {
  routes: {
    services: "/service/all?isAppAnt=true",
  },
  methods: {
    getServices: (): AxiosPromise<IServiceManagerService[]> => {
      return SBApiClient.get(AntServiceManagerApi.routes.services);
    },
  },
};
export default AntServiceManagerApi;
