import { produce } from "immer";
import { IPayloadAction } from "../../../shared/shared.interfaces";
import { IChargeProfile, IRateCards } from "./rate-cards.model";

export const RateCardsActions = {
  types: {
    GET_RATE_CARDS_SUCCESS: "GET_RATE_CARDS_SUCCESS",
    GET_RATE_CARDS_LAST_VERSION_SUCCESS: " GET_RATE_CARDS_LAST_VERSION_SUCCESS",
  },
  creators: {
    getRateCardsSuccess: (rateCards: IRateCards) => ({
      type: RateCardsActions.types.GET_RATE_CARDS_SUCCESS,
      payload: {
        rateCards,
      },
    }),
    getRateCardsLastVersionSuccess: (rateCards: IRateCards) => ({
      type: RateCardsActions.types.GET_RATE_CARDS_LAST_VERSION_SUCCESS,
      payload: {
        rateCards,
      },
    }),
  },
};
export interface IRateCardsReducerInterface {
  rateCards: IRateCards;
  lastVersion: IRateCards;
}

const initialState: IRateCardsReducerInterface = {
  rateCards: {} as IRateCards,
  lastVersion: {} as IRateCards,
};

export const rateCardsReducer = produce((draft: IRateCardsReducerInterface, action: IPayloadAction<any>) => {
  switch (action.type) {
    case RateCardsActions.types.GET_RATE_CARDS_SUCCESS:
      draft.rateCards = action.payload.rateCards;
      draft.rateCards.chargeProfiles = action.payload.rateCards.chargeProfiles.sort(
        (a: IChargeProfile, b: IChargeProfile) => (a.chargeType ? a.chargeType.localeCompare(b.chargeType) : -1),
      );
      return;
    case RateCardsActions.types.GET_RATE_CARDS_LAST_VERSION_SUCCESS:
      draft.lastVersion = action.payload.rateCards;
      draft.lastVersion.chargeProfiles = action.payload.rateCards.chargeProfiles.sort(
        (a: IChargeProfile, b: IChargeProfile) => (a.chargeType ? a.chargeType.localeCompare(b.chargeType) : -1),
      );
      return;
    default:
      return draft;
  }
}, initialState);
