import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { RoutesEnum } from "../../../routes/routes.constants";
import { useHistory } from "react-router-dom";

/**
 * Add service item
 * @param param0
 * @returns
 */
const AddServiceManagerItem = () => {
  const [t] = useTranslation();
  const history = useHistory();
  return (
    <AddServiceManagerItemContainer
      id="add-new-service"
      // TODO: add new service
      onClick={() => {
        history.push(RoutesEnum.ANT_SERVICE_MANAGER_ADD_SERVICE);
      }}
    >
      <AddServiceManagerItemIconContainer>
        <img src="./images/rounded-plus.svg" alt="add-service button" />
      </AddServiceManagerItemIconContainer>
      <AddServiceManagerItemTitle>{t("pages.antServiceManager.addServiceButton.title")}</AddServiceManagerItemTitle>
    </AddServiceManagerItemContainer>
  );
};

export default AddServiceManagerItem;

const AddServiceManagerItemContainer = styled("div")`
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 0px;
  :hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  }
  padding-top: 20px;
  padding-bottom: 20px;
  max-height: 300px;
`;

const AddServiceManagerItemIconContainer = styled("div")`
  width: 74px;
  height: 74px;
  margin-bottom: 19px;
  margin-right: 0px;
  min-width: 60px;
  img {
    width: 74px;
    height: 74px;
  }
`;

const AddServiceManagerItemTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  text-align: center;
  color: ${props => props.theme.palette.midGrey};
`;
