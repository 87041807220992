import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ConfigContext } from "../../../../app.component";
import { isSFCMarket } from "../../../../shared/shared.utils";

interface IGeneralAddNewCategoryProps {
  addNewCategoryFn: () => void;
}

/**
 * AddNewCategoryListItem component
 */
const GeneralAddNewCategoryListItem = ({ addNewCategoryFn }: IGeneralAddNewCategoryProps) => {
  const [t] = useTranslation();

  return (
    <AddNewCategoryContainer onClick={addNewCategoryFn} id={"add-new-category"}>
      <AddNewCategoryIconContainer>
        <img
          src={isSFCMarket() ? "./images/safaricom-rounded-plus.svg" : "./images/rounded-plus.svg"}
          alt={t("pages.generalCategories.addCategoryButton")}
        />
      </AddNewCategoryIconContainer>
      <AddNewCategoryTitle>{t("pages.generalCategories.addNewCategory")}</AddNewCategoryTitle>
    </AddNewCategoryContainer>
  );
};

export default GeneralAddNewCategoryListItem;

const AddNewCategoryContainer = styled("div")`
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  padding-left: 24px;
  margin-bottom: 24px;
  :hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  }
`;

const AddNewCategoryIconContainer = styled("div")`
  width: 45px;
  height: 45px;
  margin-bottom: 0;
  margin-right: 24px;

  img {
    width: 45px;
    height: 45px;
  }
`;

const AddNewCategoryTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  text-align: center;
  color: ${props => props.theme.palette.midGrey};
`;
