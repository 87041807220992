import React from "react";

const EmptyStateCustomerTableIcon = (props: React.HTMLProps<SVGSVGElement>) => {
  return (
    <svg width="140" height="140" viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd">
        <rect fill="#EBEBEB" fill-rule="nonzero" x="11.667" y="29.167" width="116.667" height="81.667" rx="8.497" />
        <path d="M0 0h140v140H0z" />
        <path
          d="M113.626 75.833H72.793a2.917 2.917 0 0 0 0 5.834h40.833a2.917 2.917 0 0 0 0-5.834z"
          opacity=".05"
          fill="#000"
          fill-rule="nonzero"
        />
        <path
          d="M113.626 64.167H72.793a2.917 2.917 0 0 0 0 5.833h40.833a2.917 2.917 0 0 0 0-5.833z"
          opacity=".06"
          fill="#000"
          fill-rule="nonzero"
        />
        <path
          d="M113.626 52.5H72.793a2.917 2.917 0 0 0 0 5.833h40.833a2.917 2.917 0 0 0 0-5.833z"
          fill="#2FC56D"
          fill-rule="nonzero"
        />
        <path
          d="M72.793 56.875h40.833a2.917 2.917 0 0 0 2.815-2.188c.064.238.098.483.102.73a2.917 2.917 0 0 1-2.917 2.916H72.793a2.917 2.917 0 0 1-2.917-2.916c.01-.248.053-.493.124-.73a2.917 2.917 0 0 0 2.793 2.188zM72.793 68.542h40.833a2.917 2.917 0 0 0 2.815-2.188c.064.238.098.483.102.73A2.917 2.917 0 0 1 113.626 70H72.793a2.917 2.917 0 0 1-2.917-2.917c.01-.247.053-.492.124-.729a2.917 2.917 0 0 0 2.793 2.188zM72.793 80.208h40.833a2.917 2.917 0 0 0 2.815-2.187c.064.238.098.483.102.729a2.917 2.917 0 0 1-2.917 2.917H72.793a2.917 2.917 0 0 1-2.917-2.917c.004-.246.038-.491.102-.73a2.917 2.917 0 0 0 2.815 2.188zM22.801 110.833a11.557 11.557 0 0 1-5.607-1.735l.036-.182.409-2.02c2.69-13.665 9.15-23.822 17.5-28.38a19.14 19.14 0 0 1-8.16-15.808c0-10.463 8.247-18.958 18.382-18.958 10.136 0 18.346 8.51 18.346 18.958a19.163 19.163 0 0 1-8.152 15.758c8.356 4.55 14.831 14.722 17.5 28.437l.73 3.755v.19h-5.863l-45.121-.015z"
          fill="#000"
          fill-rule="nonzero"
          opacity=".06"
        />
        <path
          d="M45.325 45.208c9.873 0 17.945 8.072 18.36 18.164v-.65c0-10.463-8.246-18.957-18.382-18.957-10.135 0-18.324 8.494-18.324 18.943v.65c.394-10.078 8.473-18.15 18.346-18.15zM56.634 79.122c-.35.284-.729.554-1.079.817 8.356 4.55 14.831 14.721 17.5 28.437l.496 2.486h.292v-.19l-.73-3.754c-2.61-13.125-8.647-22.976-16.479-27.796zM17.639 108.34c2.69-13.665 9.15-23.822 17.5-28.38-.372-.255-.73-.532-1.08-.816-7.809 4.827-13.854 14.67-16.406 27.708l-.408 2.02-.037.182.263.153.168-.867z"
          fill="#000"
          fill-rule="nonzero"
          opacity=".06"
        />
        <path
          d="M116.667 109.375H23.333c-6.443 0-11.666-5.223-11.666-11.667v1.459c0 6.443 5.223 11.666 11.666 11.666h93.334c6.443 0 11.666-5.223 11.666-11.666v-1.459c0 6.444-5.223 11.667-11.666 11.667z"
          fill="#000"
          fill-rule="nonzero"
          opacity=".06"
        />
      </g>
    </svg>
  );
};

export default EmptyStateCustomerTableIcon;
