import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { IStoreInterface } from "../../../configs/store.config";
import LoadingComponent from "../../../shared/components/loading-component/LoadingComponent";
import styled from "styled-components";
import { AudienceType, BusinessAudience, ConsumerAudience, IAnnouncement } from "../announcements.model";
import {
  Table,
  SearchBar,
  DateRangeDropdown,
  useAlert,
  useFilters,
  MegaphonePlusIcon,
  MultipleOptionsDropdown,
} from "@wit/mpesa-ui-components";
import { ColumnProps, SortDirectionEnum } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import { AnnouncementsUtils } from "../announcements.utils";
import moment from "moment";
import { SafaricomTheme } from "../../../safaricom.theme";
import { useHistory } from "react-router-dom";
import { RoutesEnum } from "../../../routes/routes.constants";
import { AnnoucementsActions } from "../announcements.store";
import AnnouncementsApi from "../announcements.api";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { OtherFiltersContainer, SearchBarFilterContainer } from "../../../shared/responsive-ui.styled";
import useCheckFeatureAvailable from "../../../shared/hooks/use-check-available-feature";
import { FEATURES } from "../../../shared/renderer.utils";
import MultipleOptionNestedDropdown from "@wit/mpesa-ui-components/lib/components/dropdown/multiple-option-nested-dropdown/multiple-option-nested-dropdown.component";
import { SharedNestedOptionDropdown } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-nested-option-container/shared-dropdown-nested-option-container.component";
import { getColorStatus, removeItemsFromList } from "../../../shared/shared.utils";
import { StatusType } from "../../../shared/shared.enums";

interface IFilterList {
  filterTab: Number;
}

/**
 * Component to list all announcements
 */
const AnnouncementsListPage = ({ filterTab }: IFilterList) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchString, setSearchString] = useState("");
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const [statusSelected, setStatusSelected] = useState<String[]>([]);
  const [selectedAudiences, setSelectedAudiences] = useState<AudienceType[]>([]);

  const isLoadingAnnouncements = useSelector(
    (state: IStoreInterface) => state.announcementsReducer.isLoadingAnnouncements,
  );
  const persistedAnnouncements = useSelector((store: IStoreInterface) => store.announcementsReducer.announcements);
  const [announcements, setannouncements] = useState(persistedAnnouncements);
  const { filters, updateFilter, updateMultipleFilters, isFilterActive, clearFilter } = useFilters();
  const addAnnouncementRow = {
    id: "FirstLineAddAnouncement",
    campaignName: t("pages.announcements.labelCreateAnnouncement"),
    homeScreen: null,
    stories: [],
  };
  const consumerChildEnabled = useCheckFeatureAvailable(FEATURES.CONSUMER_AUDIENCE_CHILD);
  const businessAgentEnabled = useCheckFeatureAvailable(FEATURES.BUSINESS_AUDIENCE_AGENT);

  const translations = {
    dropdownPlaceholder: t("pages.announcements.filters.timeRange"),
    clearFilters: t("components.dateFilter.clearFilters"),
    CUSTOM: t("components.dateFilter.customRange"),
    LAST_3_MONTH: t("components.dateFilter.last3Months"),
    LAST_6_MONTH: t("components.dateFilter.last6Months"),
    LAST_MONTH: t("components.dateFilter.lastMonth"),
    LAST_WEEK: t("components.dateFilter.lastWeek"),
  };

  useEffect(() => {
    AnnouncementsApi.methods.getIcons().then(
      res => {
        sessionStorage.setItem("icons", JSON.stringify(res.data));
        localStorage.removeItem("icons");
      },
      () => {
        setAlertProps({
          title: t("pages.announcements.errorGetIcons"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  }, []);

  useEffect(() => {
    dispatch(AnnoucementsActions.creators.fetchingAnnouncementsAction());
    AnnouncementsApi.methods.getAnnouncements().then(
      res => {
        dispatch(AnnoucementsActions.creators.fetchAnnouncementsSuccessAction(res.data));
      },
      () => {
        dispatch(AnnoucementsActions.creators.fetchAnnouncementsErrorAction());
        setAlertProps({
          title: t("pages.announcements.errorGetAnnouncements"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  }, []);

  /**
   * Check if the Apps general audiences (ALL_BUSINESSAPPS and ALL_CONSUMERAPPS) should be included - meaning all the app audiences options were selected
   * @param audience
   * @returns
   */
  const isAppAudienceSelected = (audience: AudienceType): boolean => {
    if (
      audience === BusinessAudience.ALL_BUSINESSAPPS &&
      Object.keys(BusinessAudience)
        .filter(el => el !== BusinessAudience.ALL_BUSINESSAPPS)
        .every(item => selectedAudiences.includes(item as AudienceType))
    ) {
      return true;
    } else if (
      audience === ConsumerAudience.ALL_CONSUMERAPPS &&
      Object.keys(ConsumerAudience)
        .filter(el => el !== ConsumerAudience.ALL_CONSUMERAPPS && el !== ConsumerAudience.CONSUMERAPP)
        .every(item => selectedAudiences.includes(item as AudienceType))
    ) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * This return the visible announcements with the active filters
   * @returns
   */
  const getVisibleAnnouncementsByCurrentFilters = (): IAnnouncement[] => {
    let visibleAnnouncements = getVisibleAnnouncementsByTab([...persistedAnnouncements]);

    if (searchString) {
      visibleAnnouncements = visibleAnnouncements.filter(
        annou =>
          annou.campaignName.toLowerCase().includes(searchString.toLowerCase()) ||
          annou.id.toLowerCase().includes(searchString.toLowerCase()),
      );
    }
    if (filters.size > 0) {
      visibleAnnouncements = filterTimeRange(filters, visibleAnnouncements);
    }
    if (statusSelected.length > 0) {
      visibleAnnouncements = visibleAnnouncements.filter(announ =>
        statusSelected.includes(announ.status ? announ.status.toString() : ""),
      );
    }

    if (selectedAudiences.length > 0) {
      visibleAnnouncements = visibleAnnouncements.filter(
        announ =>
          announ.audience && (selectedAudiences.includes(announ.audience) || isAppAudienceSelected(announ.audience)),
      );
    }

    return visibleAnnouncements;
  };

  useEffect(() => {
    const visibleAnnouncements = getVisibleAnnouncementsByCurrentFilters();
    visibleAnnouncements.unshift(addAnnouncementRow);
    setannouncements(visibleAnnouncements);
  }, [persistedAnnouncements, filterTab, searchString, statusSelected, filters, selectedAudiences]);

  /**
   * Function to filter announcements by dropdown daterange
   * @param filters information about the daterange filters active
   * @param visible list of announcements
   */
  const filterTimeRange = (filters: Map<string, string[]>, visible: IAnnouncement[]): IAnnouncement[] => {
    let visibleAnnouncements = visible;
    const dateRange = filters.get("dateRange");

    if (dateRange && dateRange.includes("LAST_6_MONTH")) {
      visibleAnnouncements = visibleAnnouncements.filter(
        announcement => moment(announcement.startDate as string) >= moment().subtract(6, "months"),
      );
    }
    if (dateRange && dateRange.includes("LAST_3_MONTH")) {
      visibleAnnouncements = visibleAnnouncements.filter(
        announcement => moment(announcement.startDate as string) >= moment().subtract(3, "months"),
      );
    }
    if (dateRange && dateRange.includes("LAST_MONTH")) {
      visibleAnnouncements = visibleAnnouncements.filter(
        announcement => moment(announcement.startDate as string) >= moment().subtract(1, "months"),
      );
    }
    if (dateRange && dateRange.includes("LAST_WEEK")) {
      visibleAnnouncements = visibleAnnouncements.filter(
        announcement => moment(announcement.startDate as string) >= moment().subtract(1, "week"),
      );
    }
    if (dateRange && dateRange.includes("CUSTOM")) {
      const startDate = filters.get("startDate");
      const endDate = filters.get("endDate");
      if (startDate && endDate) {
        visibleAnnouncements = visibleAnnouncements.filter(
          announcement =>
            (moment(moment(announcement.startDate as string).format("DD MMM YYYY")).isSameOrAfter(
              moment(startDate ? startDate[0] : "").format("DD MMM YYYY"),
            ) &&
              moment(moment(endDate ? endDate[0] : "").format("DD MMM YYYY")).isSameOrAfter(
                moment(announcement.startDate as string).format("DD MMM YYYY"),
              )) ||
            (moment(moment(announcement.endDate as string).format("DD MMM YYYY")).isSameOrBefore(
              moment(endDate ? endDate[0] : "").format("DD MMM YYYY"),
            ) &&
              moment(moment(startDate ? startDate[0] : "").format("DD MMM YYYY")).isSameOrBefore(
                moment(announcement.endDate as string).format("DD MMM YYYY"),
              )),
        );
      }
    }
    return visibleAnnouncements;
  };

  /**
   *function to the options of dropdown status
   */
  const getOptionsStatus = () => {
    return Object.values(StatusType).map(status => ({
      label: t(`pages.announcements.statusEnum.${status}`),
      key: status,
    }));
  };

  /**
   * this function sets the visible announcements by the selected tab
   */
  const getVisibleAnnouncementsByTab = (visibleAnnouncements: IAnnouncement[]): IAnnouncement[] => {
    if (filterTab === 0) {
      visibleAnnouncements = sortAnnouncementsByStatus(visibleAnnouncements, SortDirectionEnum.ASC);
    } else if (filterTab === 1) {
      visibleAnnouncements = visibleAnnouncements.filter(announ => announ.status === StatusType.LIVE);
    } else if (filterTab === 2) {
      visibleAnnouncements = visibleAnnouncements.filter(announ => announ.status === StatusType.EXPIRED);
    }

    return visibleAnnouncements;
  };

  /**
   * function to sort announcement by status
   * @param column column selected
   * @param direction sort direction
   */
  const sortStatus = (column: ColumnProps, direction: SortDirectionEnum) => {
    let visibleAnnouncements = getVisibleAnnouncementsByCurrentFilters();

    if (column.formKey === "status") {
      visibleAnnouncements = sortAnnouncementsByStatus(visibleAnnouncements, direction);
    }
    visibleAnnouncements.unshift(addAnnouncementRow);
    setannouncements(visibleAnnouncements);
  };

  /**
   * function to sort announcement by status
   * @param announcementsList
   * @param direction
   */
  const sortAnnouncementsByStatus = (announcementsList: IAnnouncement[], direction: number) => {
    const liveAnnouncements = announcementsList.filter(announ => announ.status === StatusType.LIVE);
    const scheduledAnnouncements = announcementsList.filter(announ => announ.status === StatusType.SCHEDULED);
    const draftAnnouncements = announcementsList.filter(announ => announ.status === StatusType.DRAFT);
    const canceledAnnouncements = announcementsList.filter(announ => announ.status === StatusType.CANCELED);
    const pastedAnnouncements = announcementsList.filter(announ => announ.status === StatusType.EXPIRED);
    let newVisibleAnnouncements: IAnnouncement[] = [];
    if (direction === 1) {
      newVisibleAnnouncements = newVisibleAnnouncements.concat(
        liveAnnouncements,
        scheduledAnnouncements,
        draftAnnouncements,
        canceledAnnouncements,
        pastedAnnouncements,
      );
    }
    if (direction === 2) {
      newVisibleAnnouncements = newVisibleAnnouncements.concat(
        pastedAnnouncements,
        canceledAnnouncements,
        draftAnnouncements,
        scheduledAnnouncements,
        liveAnnouncements,
      );
    }
    return newVisibleAnnouncements;
  };

  /**
   * function to call when toggle one option in dropdown
   * @param opt chosse option
   */
  const toggleOption = (opt: SharedDropdownOption) => {
    if (isOptionSelected(opt)) {
      setStatusSelected(statusSelected.filter(c => c !== opt.key));
    } else {
      setStatusSelected([...statusSelected, opt.key]);
    }
  };

  /**
   * function to clear filter in dropdown status
   */
  const resetDropdown = () => {
    setStatusSelected([]);
  };

  /**
   * return if that options is chossen
   * @param opt option selected
   */
  const isOptionSelected = (opt: SharedDropdownOption) => {
    return statusSelected.includes(opt.key) || selectedAudiences.includes(opt.key);
  };

  /**
   * Handle general audiences changes (ALL_BUSINESSAPPS and ALL_CONSUMERAPPS)
   * @param opt
   */
  const handleAppAudienceChanges = (opt: SharedNestedOptionDropdown) => {
    if (BusinessAudience.ALL_BUSINESSAPPS === opt.key) {
      if (
        Object.keys(BusinessAudience)
          .filter(el => el !== BusinessAudience.ALL_BUSINESSAPPS)
          .every(item => selectedAudiences.includes(item as AudienceType))
      ) {
        setSelectedAudiences(removeItemsFromList(selectedAudiences, Object.keys(BusinessAudience)));
      } else {
        const businessAudienceOptions = Object.keys(BusinessAudience).filter(
          i => i !== BusinessAudience.ALL_BUSINESSAPPS,
        );

        setSelectedAudiences([...selectedAudiences, ...businessAudienceOptions.map(i => i as AudienceType)]);
      }
    } else if (ConsumerAudience.ALL_CONSUMERAPPS === opt.key) {
      if (
        Object.keys(ConsumerAudience)
          .filter(el => el !== ConsumerAudience.ALL_CONSUMERAPPS && el !== ConsumerAudience.CONSUMERAPP)
          .every(item => selectedAudiences.includes(item as AudienceType))
      ) {
        setSelectedAudiences(removeItemsFromList(selectedAudiences, Object.keys(ConsumerAudience)));
      } else {
        const consumerAudienceOptions = Object.keys(ConsumerAudience).filter(
          i => i !== ConsumerAudience.ALL_CONSUMERAPPS && i !== ConsumerAudience.CONSUMERAPP,
        );

        setSelectedAudiences([...selectedAudiences, ...consumerAudienceOptions.map(i => i as AudienceType)]);
      }
    }
  };

  /**
   * toggle option for audience method
   */
  const audienceToggleOption = (opt: SharedNestedOptionDropdown) => {
    if (
      BusinessAudience.ALL_BUSINESSAPPS === opt.key ||
      (ConsumerAudience.ALL_CONSUMERAPPS === opt.key && consumerChildEnabled)
    ) {
      handleAppAudienceChanges(opt);
    } else {
      if (isOptionSelected(opt)) {
        setSelectedAudiences(selectedAudiences.filter(c => c !== opt.key));
      } else {
        setSelectedAudiences([...selectedAudiences, opt.key]);
      }
    }
  };

  /**
   * function than will return one array with the information about the columns
   */
  const getAnnouncementsColumns = (): ColumnProps[] => {
    return [
      {
        formKey: "campaignName",
        label: t("pages.announcements.rows.announcement"),
        ratio: 6 / 12,
        isEditable: false,
        style: { height: "100%", display: "flex", alignItems: "center", overflow: "visible" },
        changeFunction: (content, row) => {
          return row.id !== "FirstLineAddAnouncement" ? (
            <WhitelistTitle>{content}</WhitelistTitle>
          ) : (
            <AddAnnouncementRow>
              <AddAnnouncementLinkIcon>
                <MegaphonePlusIcon />
              </AddAnnouncementLinkIcon>
              <AddAnnouncementLink>{content}</AddAnnouncementLink>
            </AddAnnouncementRow>
          );
        },
      },
      {
        formKey: "createDate",
        label: t("pages.announcements.rows.create"),
        ratio: 2 / 12,
        isEditable: false,
        style: { height: "100%", display: "flex", marginRight: "0px", alignItems: "center" },
        changeFunction: (content, row) => {
          return row.id !== "FirstLineAddAnouncement" ? (
            <DateStyle>{moment(content).format("DD MMM YYYY")}</DateStyle>
          ) : (
            <></>
          );
        },
      },
      {
        formKey: "startDate",
        label: t("pages.announcements.rows.start"),
        ratio: 2 / 12,
        isEditable: false,
        style: { height: "100%", display: "flex", marginRight: "0px", alignItems: "center" },
        changeFunction: (content, row) => {
          return row.id !== "FirstLineAddAnouncement" ? (
            <DateStyle>{moment(content).format("DD MMM YYYY")}</DateStyle>
          ) : (
            <></>
          );
        },
      },
      {
        formKey: "closeDate",
        label: t("pages.announcements.rows.close"),
        ratio: 2 / 12,
        isEditable: false,
        style: { height: "100%", display: "flex", marginRight: "0px", alignItems: "center" },
        changeFunction: (content, row) => {
          return row.id !== "FirstLineAddAnouncement" ? (
            <DateStyle>{moment(row.endDate).format("DD MMM YYYY")}</DateStyle>
          ) : (
            <></>
          );
        },
      },
      {
        formKey: "status",
        label: t("pages.announcements.rows.status"),
        ratio: 2 / 12,
        sortable: true,
        isEditable: false,
        style: { height: "100%", display: "flex", marginRight: "0px", alignItems: "center", overflow: "visible" },
        changeFunction: (content, row) => {
          return row.id !== "FirstLineAddAnouncement" ? (
            <StatusStyle>
              <SpanStatusStyle style={{ backgroundColor: getColorStatus(content) }}>
                {t(`pages.announcements.statusEnum.${content}`)}
              </SpanStatusStyle>
            </StatusStyle>
          ) : (
            <></>
          );
        },
      },
    ];
  };

  /**
   * function to event on click to the rows of the list
   * @param idx number of row
   * @param rowData announcement selected
   */
  const onClickRowAction = (idx: number, rowData: IAnnouncement) => {
    rowData.id === "FirstLineAddAnouncement"
      ? history.push(RoutesEnum.ADD_ANNOUNCEMENT)
      : history.push(RoutesEnum.ANNOUNCEMENTS_DETAILS.replace(":announcementID", rowData.id));
  };

  return (
    <>
      {isLoadingAnnouncements ? (
        <LoadingContainer>
          <div>
            <LoadingComponent />
          </div>
        </LoadingContainer>
      ) : (
        <>
          <FiltersContainer>
            <SearchBarFilterContainer>
              <SearchBar
                placeholderLabel={t("pages.announcements.filters.search")}
                value={searchString}
                onChange={e => setSearchString(e.target.value)}
                clearValue={() => setSearchString("")}
              />
            </SearchBarFilterContainer>
            <OtherFiltersContainer>
              <DateRangeDropdown
                shouldAllowFutureDates={true}
                showDefaultRanges={true}
                filters={filters}
                updateFilter={updateFilter}
                updateMultipleFilters={updateMultipleFilters}
                isFilterActive={isFilterActive}
                clearFilter={clearFilter}
                translations={translations}
              />
            </OtherFiltersContainer>
            <OtherFiltersContainer>
              <MultipleOptionsDropdown
                label={t("pages.announcements.filters.status")}
                toggleOption={opt => toggleOption(opt)}
                clearAllFilters={() => resetDropdown()}
                hasValue={false}
                isOptionSelected={opt => isOptionSelected(opt)}
                options={getOptionsStatus()}
              />
            </OtherFiltersContainer>

            <OtherFiltersContainer>
              <MultipleOptionNestedDropdown
                label={t("pages.announcements.filters.audience")}
                hasValue={false}
                options={AnnouncementsUtils.getAudienceOptions(
                  selectedAudiences,
                  businessAgentEnabled,
                  consumerChildEnabled,
                )}
                toggleOption={(opt: SharedNestedOptionDropdown) => audienceToggleOption(opt)}
                clearAllFilters={() => null}
                showClearAllFilters={false}
                isOptionSelected={(opt: SharedDropdownOption) =>
                  AnnouncementsUtils.isOptionSelected(
                    selectedAudiences,
                    opt.key,
                    businessAgentEnabled,
                    consumerChildEnabled,
                  )
                }
              />
            </OtherFiltersContainer>
          </FiltersContainer>
          <TableWrapper className="tableWrapper">
            {
              <Table<IAnnouncement>
                columns={getAnnouncementsColumns()}
                values={announcements}
                rowClickAction={onClickRowAction}
                sortable={true}
                sortClickAction={sortStatus}
                initialSortColumnFormKey={"status"}
                initialSortDirection={SortDirectionEnum.ASC}
              />
            }
          </TableWrapper>
        </>
      )}
    </>
  );
};

export default AnnouncementsListPage;

const TableWrapper = styled.div`
  > div:first-child {
    > div:first-child {
      border-bottom: none;
      > div:first-child > span {
        padding-left: 24px;
      }
    }
    > div:last-child {
      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 8px;
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
        background-color: ${props => props.theme.palette.white};
        border: 1px solid ${props => props.theme.palette.lightGrey};
        height: 75px;
        margin-top: 1%;
        > div {
          height: 100%;
        }
      }
      > div:last-child {
        margin-bottom: 1%;
      }
    }
  }
`;

const AddAnnouncementRow = styled.div`
  display: flex;
  align-items: center;
  padding-left: 24px;
  overflow: visible;
`;

const AddAnnouncementLinkIcon = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  stroke: ${SafaricomTheme.palette.white};
  min-width: 1.9vw;
  width: 40px;
  height: 40px;
  background-color: ${SafaricomTheme.palette.vodafoneRed};
  border-radius: 50%;
  box-shadow: 0 3px 5px 0 rgba(142, 142, 142, 0.23);
  svg {
    width: 24px;
  }
`;
const AddAnnouncementLink = styled.div`
  margin-left: 24px;
  font-family: Vodafone Rg;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  align-items: center;
  display: flex;
  color: #999;
  @media (max-width: 1024px) {
    font-size: 20px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const WhitelistTitle = styled.div`
  font-family: Vodafone RgBd;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 24px;
  @media (max-width: 1024px) {
    font-size: 20px;
    padding-right: 16px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
    padding-right: 8px;
  }
`;
const DateStyle = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #999;
  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (max-width: 1024px) {
    white-space: pre-wrap;
  }
`;
const StatusStyle = styled.div``;

const SpanStatusStyle = styled.span`
  font-family: Vodafone RgBd;
  border-radius: 10.5px;
  background-color: red;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  padding: 0.2vh 0.625vw 0.3vh 0.625vw;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const LoadingContainer = styled("div")`
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FiltersContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 32px;
  flex-wrap: wrap;
`;
