import { FormSection, PlusIcon, TextInput, TrashIcon } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { Formik, FormikErrors, yupToFormErrors } from "formik";
import i18next from "i18next";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { array, object, string } from "yup";
import { IStoreInterface } from "../../../../../configs/store.config";
import ColorPicker from "../../../../../shared/components/color-picker.component";
import IconPicker from "../../../../../shared/components/icon-picker.component";
import { ILanguage } from "../../../../../shared/models/language.model";
import LanguagesApi from "../../../../../shared/services/languages.api";
import { ServiceManagerActions } from "../../../service-manager.store";
import CategoriesApi from "../categories.api";
import { ICategory, IIconCategory } from "../categories.model";

interface ICategoryForm {
  initialValues: ICategory;
  isEditing: boolean;
  onSubmitFn: (values: ICategory) => void;
  errorIds: string[];
}

/**
 * CategoryForm component
 */
const CategoryForm = ({ initialValues, isEditing, onSubmitFn, errorIds }: ICategoryForm) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [numberIdsRows, setNumberIdsRows] = React.useState(
    initialValues.externalCategoryIds ? initialValues.externalCategoryIds.length : 1,
  );
  const { icons } = useSelector((state: IStoreInterface) => state.serviceManagerReducer);
  const defaultColor = "#2fc56d";
  const [isLoadingIcons, setIsLoadingIcons] = React.useState(true);
  const [availableLanguages, setAvailableLanguages] = useState<{ label: string; key: string }[]>([
    { label: t("commons.languages.en"), key: "en" },
  ]);
  const [defaultLanguage, setDefaultLanguage] = useState<{ label: string; key: string }>({
    label: t("commons.languages.en"),
    key: "en",
  });

  const [isFormReady, setIsFormReady] = React.useState(false);
  const antIDsRef = useRef(null);

  /**
   * Fetches the available languages
   */
  React.useEffect(() => {
    setIsFormReady(false);
    LanguagesApi.methods
      .getAvailableLanguages()
      .then(
        res => {
          // inits the object when we can have more than the base language
          if (!initialValues.categoryNameTranslations && res.data.availableLanguages.length > 1) {
            initialValues.categoryNameTranslations = {};
          }

          if (res.data.availableLanguages.length > 0) {
            setDefaultLanguage({
              label: t(`commons.languages.${res.data.availableLanguages[0].code}`),
              key: res.data.availableLanguages[0].code,
            });
          }
          setAvailableLanguages(
            res.data.availableLanguages.slice(1).map((lang: ILanguage) => {
              initialValues.categoryNameTranslations![lang.code] =
                initialValues.categoryNameTranslations![lang.code] ?? "";
              return {
                label: t(`commons.languages.${lang.code}`),
                key: lang.code,
              };
            }),
          );
        },
        () => {},
      )
      .finally(() => {
        setIsFormReady(true);
      });
  }, []);

  /**
   * method to get the icons list
   */
  const getIcons = () => {
    CategoriesApi.methods.getIconsList().then(res => {
      dispatch(ServiceManagerActions.creators.fectionsIconsCategories(res.data));
      setIsLoadingIcons(false);
    });
  };

  /**
   * method to return the externals ids rows
   */
  const getExternalIdsRows = (
    values: ICategory,
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    setFieldValue: (field: string, value: any) => void,
    errors: FormikErrors<ICategory>,
  ) => {
    return [...Array(numberIdsRows)].map((elem, index) => ({
      label: <span style={{ display: "none" }} />,
      displayComponent: (
        <NonEditableText id="color-value">
          {values.externalCategoryIds ? values.externalCategoryIds[index] : undefined}
        </NonEditableText>
      ),
      editingComponent: (
        <ExternalIdInputContainer>
          <TextInput
            id="external-category-id-input"
            style={{
              maxWidth: "100%",
              borderColor:
                values.externalCategoryIds &&
                values.externalCategoryIds[index] &&
                errorIds.includes(values.externalCategoryIds[index])
                  ? styleTheme.palette.errorRed
                  : styleTheme.palette.aluminium,
            }}
            maxLength={16}
            name={`externalCategoryIds[${index}]`}
            value={
              values.externalCategoryIds && values.externalCategoryIds[index] ? values.externalCategoryIds[index] : ""
            }
            onChange={e => setANTID(values, setFieldValue, index, e.target.value)}
            placeholder={t("pages.serviceManager.categories.categoryForm.categoryANTIdPlaceholder")}
            error={index === 0 ? (errors.externalCategoryIds as string) : undefined}
          />
          {numberIdsRows > 1 ? (
            <TrashIcon
              onClick={() => {
                setNumberIdsRows(numberIdsRows - 1);
                if (values.externalCategoryIds) {
                  setFieldValue(
                    "externalCategoryIds",
                    values.externalCategoryIds.filter(value => values.externalCategoryIds.indexOf(value) !== index),
                  );
                }
              }}
            ></TrashIcon>
          ) : null}
        </ExternalIdInputContainer>
      ),
      required: true,
    }));
  };

  /**
   * method to set the ANT ID
   * array must have at least one id, but the string value must not be empty
   */
  const setANTID = (
    values: ICategory,
    setFieldValue: (field: string, value: any) => void,
    index: number,
    value: string,
  ) => {
    if (value.length > 0) {
      setFieldValue(`externalCategoryIds[${index}]`, value);
    } else {
      const newExternalIds = [...values.externalCategoryIds];
      newExternalIds.splice(index, 1);
      setFieldValue(`externalCategoryIds`, newExternalIds);
    }
  };

  React.useEffect(() => {
    if (!icons || (icons && icons.length === 0)) {
      getIcons();
    } else {
      setIsLoadingIcons(false);
    }
  }, []);

  React.useEffect(() => {
    setNumberIdsRows(initialValues && initialValues.externalCategoryIds ? initialValues.externalCategoryIds.length : 1);
  }, [initialValues]);

  /**
   * Category name translations inputs
   * @param {ICategory} values
   * @param {React.ChangeEventHandler<HTMLInputElement> | undefined} handleChange
   * @param {FormikErrors<ICategory>} errors
   * @returns {{displayComponent: JSX.Element, label: JSX.Element, editingComponent: JSX.Element, required: boolean}[]}
   */
  const getTranslationsInputs = (
    values: ICategory,
    handleChange: React.ChangeEventHandler<HTMLInputElement> | undefined,
    errors: FormikErrors<ICategory>,
  ) => {
    return availableLanguages.map((lang, idx) => {
      return {
        label: (
          <FormLabel id={`category-name-${lang.key}`} required={isEditing}>
            {t("pages.serviceManager.categories.categoryForm.categoryName")}{" "}
            <LightFormLabel>({lang.label})</LightFormLabel>
          </FormLabel>
        ),
        displayComponent: (
          <NonEditableText id={`category-name-value-${lang.key}`}>
            {values.categoryNameTranslations![lang.key]}
          </NonEditableText>
        ),
        editingComponent: (
          <TextInput
            id={`category-name-input-${lang.key}`}
            name={`categoryNameTranslations.${lang.key}`}
            value={values.categoryNameTranslations![lang.key]}
            onChange={handleChange}
            placeholder={t("pages.serviceManager.categories.categoryForm.categoryNameTranslationPlaceholder", {
              lang: lang.label,
            })}
            error={
              errors.categoryNameTranslations &&
              (((errors.categoryNameTranslations as unknown) as { [x: string]: string })[lang.key] as string)
            }
            maxLength={20}
          />
        ),
        required: true,
      };
    });
  };

  /**
   * Translations validator helper
   * @param {string[]} langs
   * @returns {{[p: string]: any}}
   */
  const getTranslationValidators = (langs: string[]) => {
    let validators: { [x: string]: any } = {};
    (langs as string[]).map((lang: string) => {
      validators = {
        ...validators,
        [lang]: string()
          .required(i18next.t("commons.mandatoryField"))
          .matches(/^\s*\S+.*/, i18next.t("commons.emptyField")),
      };
    });
    return validators;
  };

  /**
   * Validation fn for the category form
   * @param {ICategory} values
   * @returns {Promise<FormikErrors<ICategory>>}
   */
  const validateSchema = (values: ICategory): Promise<FormikErrors<ICategory>> => {
    const langs = availableLanguages.map(l => l.key);
    const schema = object().shape({
      categoryName: string()
        .required(i18next.t("commons.mandatoryField"))
        .matches(/^\s*\S+.*/, i18next.t("commons.emptyField")),
      color: string()
        .matches(/^#([0-9a-f]{3}|[0-9a-f]{6})$/i, i18next.t("commons.invalidColor"))
        .required(i18next.t("commons.mandatoryField")),
      iconId: string().required(i18next.t("commons.mandatoryField")),
      externalCategoryIds: array().when("isDefault", {
        is: false,
        then: array()
          .required(i18next.t("commons.atLeastOneField"))
          .min(1, i18next.t("commons.atLeastOneField"))
          .max(20)
          .of(string()),
      }),
      categoryNameTranslations: object().when("$areTranslationsRequired", {
        is: true,
        then: object().shape(getTranslationValidators(langs)),
      }),
    });
    return new Promise<FormikErrors<ICategory>>(resolve => {
      const areTranslationsRequired = langs.length > 1;
      schema
        .validate(values, {
          context: {
            areTranslationsRequired: areTranslationsRequired,
          },
          abortEarly: false,
        })
        .then(() => {
          resolve({});
        })
        .catch(y => {
          const errors: FormikErrors<ICategory> = yupToFormErrors(y);
          if (errors.externalCategoryIds && antIDsRef != null) {
            (antIDsRef.current! as HTMLDivElement).scrollIntoView();
          }
          resolve(yupToFormErrors(y));
        });
    }).then(r => {
      return r;
    });
  };

  return (
    <>
      {isFormReady && (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitFn}
          validateOnChange={false}
          validateOnBlur={true}
          validate={validateSchema}
          render={({ values, handleChange, isSubmitting, setFieldValue, handleSubmit, errors, resetForm }) => (
            <form
              id="category-form"
              onSubmit={handleSubmit}
              onReset={() => {
                resetForm();
                setNumberIdsRows(initialValues.externalCategoryIds.length);
              }}
              style={{ width: "60%" }}
            >
              <FormContainer>
                <FormSection
                  isEditing={isEditing}
                  title={t("pages.serviceManager.categories.categoryForm.categoryDetails")}
                  rows={[
                    {
                      label: (
                        <FormLabel id="category-name" required={isEditing}>
                          {t("pages.serviceManager.categories.categoryForm.categoryName")}{" "}
                          {availableLanguages.length > 1 && <LightFormLabel>({defaultLanguage.label})</LightFormLabel>}
                        </FormLabel>
                      ),
                      displayComponent: (
                        <NonEditableText id="category-name-value">{values.categoryName}</NonEditableText>
                      ),
                      editingComponent: (
                        <TextInput
                          id="category-name-input"
                          name="categoryName"
                          value={values.categoryName}
                          onChange={handleChange}
                          placeholder={t("pages.serviceManager.categories.categoryForm.categoryNamePlaceholder")}
                          error={errors.categoryName}
                          maxLength={20}
                        />
                      ),
                      required: true,
                    },
                    {
                      label: (
                        <FormLabel id="category-color" required={isEditing}>
                          {t("pages.serviceManager.categories.categoryForm.categoryColor")}
                        </FormLabel>
                      ),
                      displayComponent: (
                        <NonEditableContainer>
                          <NonEditableColor color={values.color} />
                          <NonEditableText id="color-value">{values.color}</NonEditableText>
                        </NonEditableContainer>
                      ),
                      editingComponent: (
                        <ColorPickerContainer>
                          <ColorPicker
                            color={values.color ? values.color : defaultColor}
                            setColor={setFieldValue}
                            name={"color"}
                            error={errors.color}
                          />
                        </ColorPickerContainer>
                      ),
                      required: true,
                    },
                    {
                      label: (
                        <FormLabel id="category-color" required={isEditing}>
                          {t("pages.serviceManager.categories.categoryForm.categoryIcon")}
                        </FormLabel>
                      ),
                      displayComponent: (
                        <NonEditableContainer>
                          <NonEditableIcon color={values.color}>
                            {icons && icons.find(icon => icon.id === values.iconId) ? (
                              <img src={(icons.find(icon => icon.id === values.iconId) as IIconCategory).base64} />
                            ) : null}
                          </NonEditableIcon>
                          <NonEditableText id="icon-value">
                            {icons.find(icon => icon.id === values.iconId)
                              ? (icons.find(icon => icon.id === values.iconId) as IIconCategory).label
                              : t("pages.serviceManager.categories.categoryForm.defaultIconName")}
                          </NonEditableText>
                        </NonEditableContainer>
                      ),
                      editingComponent: (
                        <IconPickerContainer>
                          <IconPicker
                            icons={icons}
                            icon={icons.find(icon => icon.id === values.iconId)}
                            name="iconId"
                            setIconID={setFieldValue}
                            backgroundColor={values.color ? values.color : defaultColor}
                            error={errors.iconId}
                            isLoading={isLoadingIcons}
                          ></IconPicker>
                        </IconPickerContainer>
                      ),
                      required: true,
                    },
                  ]}
                />
                {values.categoryNameTranslations !== undefined && availableLanguages.length > 1 ? (
                  <div style={{ marginTop: "33px" }}>
                    <FormSection
                      isEditing={isEditing}
                      title={t("pages.serviceManager.categories.categoryForm.translations")}
                      rows={getTranslationsInputs(values, handleChange, errors)}
                    />
                  </div>
                ) : null}
                {(!isEditing && values.externalCategoryIds && values.externalCategoryIds.length === 0) || (
                  <CustomFormSection ref={antIDsRef}>
                    <FormSection
                      isEditing={isEditing}
                      title={t("pages.serviceManager.categories.categoryForm.categoryANTId")}
                      rows={getExternalIdsRows(values, handleChange, setFieldValue, errors)}
                    />
                    {isEditing && numberIdsRows <= 20 ? (
                      <AddIDRowContainer>
                        <AddIDContainer onClick={() => setNumberIdsRows(numberIdsRows + 1)}>
                          <PlusIcon></PlusIcon>
                          <AddIDText>{t("pages.serviceManager.categories.categoryForm.addId")}</AddIDText>
                        </AddIDContainer>
                      </AddIDRowContainer>
                    ) : (
                      <div style={{ marginBottom: "50px" }} />
                    )}
                  </CustomFormSection>
                )}
              </FormContainer>
            </form>
          )}
        />
      )}
    </>
  );
};

export default CategoryForm;

const FormContainer = styled("div")`
  width: 100%;
`;

const FormLabel = styled("span")<{ required?: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.greyDarker};

  ${props =>
    props.required
      ? `
  ::after {
    content: ' *';
    font-family: Vodafone Rg;
    font-size: 16px;
    font-weight: normal;
    color: ${props.theme.palette.errorRed};
  }`
      : ""}
`;

const NonEditableText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.darkGrey};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ColorPickerContainer = styled("div")`
  width: 160px;
`;

const CustomFormSection = styled("div")`
  margin-top: 40px;

  > div > div > div {
    &:nth-child(odd) {
      display: none;
    }

    &:nth-child(even) {
      max-width: 100%;
    }
  }
`;

const AddIDText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.darkGrey};
  line-height: 1.31;
`;

const AddIDRowContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  margin-bottom: 50px;
`;

const AddIDContainer = styled("div")`
  display: flex;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    stroke: ${props => props.theme.palette.vodafoneRed};
    margin-right: 8px;
  }
`;

const ExternalIdInputContainer = styled("div")`
  display: flex;
  align-items: center;

  > div {
    width: 100%;
  }

  svg {
    width: 24px;
    height: 24px;
    stroke: ${props => props.theme.palette.errorRed};
    margin-left: 8px;
  }
`;

const NonEditableContainer = styled("div")`
  display: flex;
  align-items: center;
`;

const NonEditableColor = styled("div")<{ color: string }>`
  width: 36px;
  height: 36px;
  margin: 0 11px 0 0;
  padding: 1px;
  border-radius: 6px;
  border: solid 1px ${props => props.theme.palette.aluminium};
  background-color: ${props => (props.color ? props.color : "white")};
`;

const NonEditableIcon = styled("div")<{ color: string }>`
  width: 36px;
  height: 36px;
  margin: 0 11px 0 0;
  padding: 1px;
  border-radius: 6px;
  border: solid 1px ${props => props.theme.palette.aluminium};
  background-color: ${props => (props.color ? props.color : "white")};
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 24px;
    height: 24px;
  }
`;

const IconPickerContainer = styled("div")`
  > button {
    > div {
      width: -webkit-fill-available;
    }
  }
`;

const LightFormLabel = styled("span")`
  font-weight: normal;
`;
