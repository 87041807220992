import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  SecurityIcon,
  CopyIcon,
  FastActionButton,
  CancelIcon,
  CheckIcon,
  PublishIcon,
  ModalTypeEnum,
  useModal,
  useAlert,
  TrashIcon,
  EditIcon,
  ChevronDownIcon,
  EyeIcon,
} from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { StatusChip } from "@wit/mpesa-ui-components";
import { useTranslation } from "react-i18next";
import { IServiceVersion, ServiceStatusEnum } from "../../../../shared/models/service-builder.model";
import { formatUTCTimestamp } from "../../../../shared/shared.utils";
import ServiceDetailsApi from "../../service-details.api";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import WhitelistModal from "../../modals/whitelist.modal";
import { RoutesEnum } from "../../../../routes/routes.constants";
export interface IServiceVersionContainerProps {
  serviceVersion: IServiceVersion;
  isServiceLive: boolean;
  lastVersion?: string;
  refreshPage: () => void;
  open: boolean;
}

/**
 * Service version container
 */
const ServiceVersionContainer = ({
  serviceVersion,
  refreshPage,
  lastVersion,
  open,
  isServiceLive,
}: IServiceVersionContainerProps) => {
  // Hooks initialization
  const [t] = useTranslation();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const [showPublishModal, hidePublishModal, setPublishProps] = useModal(ModalTypeEnum.ConfirmationModal);
  const [editWhitelist, setEditWhitelist] = useState(false);
  const history = useHistory();

  /**
   * check can be edited
   */
  const canEditThisVersion = () => {
    return !!serviceVersion.canBeEdited;
  };

  /**
   * Check edit mode
   */
  const isInEditMode = () => {
    return [
      ServiceStatusEnum.BETA_LIVE,
      ServiceStatusEnum.BETA_UNDEPLOYED,
      ServiceStatusEnum.LIVE_UNDEPLOYED,
      ServiceStatusEnum.UNDEPLOYED_BETA,
      ServiceStatusEnum.UNDEPLOYED_LIVE,
      ServiceStatusEnum.LIVE_DELETED,
      ServiceStatusEnum.BETA_DELETED,
    ].includes(serviceVersion.status as ServiceStatusEnum);
  };

  /**
   * Can delete version when version isn't deployed (BETA or LIVE ) or the service is editable
   */
  const canDeleteVersion = () => {
    return canEditThisVersion() && !isInEditMode();
  };

  /**
   * check need aprove for delete
   */
  const needApproveForDelete = () => {
    return ![ServiceStatusEnum.UNDEPLOYED].includes(serviceVersion.status as ServiceStatusEnum);
  };

  /**
   * can go beta when version is Undeployed and the service is editable
   */
  const canGoBeta = () => {
    return canEditThisVersion() && serviceVersion.status === ServiceStatusEnum.UNDEPLOYED;
  };

  /**
   * can be published whene version is BETA or Undeployed, and sercice is editable
   */
  const canPublish = () => {
    return (
      canEditThisVersion() &&
      [ServiceStatusEnum.BETA, ServiceStatusEnum.UNDEPLOYED].includes(serviceVersion.status as ServiceStatusEnum)
    );
  };

  /**
   * Check can disable
   */
  const canDisable = () => {
    return (
      canEditThisVersion() &&
      [ServiceStatusEnum.BETA, ServiceStatusEnum.LIVE].includes(serviceVersion.status as ServiceStatusEnum)
    );
  };

  /**
   * can be published whene version is BETA, and sercice is editable
   */
  const canEditWhiteList = () => {
    return canEditThisVersion() && serviceVersion.status === ServiceStatusEnum.BETA;
  };

  /**
   * change status
   */
  const changeVersionStatus = useCallback(
    (serviceVersionDetails: IServiceVersion, hideModal: () => void) => {
      //methods to check if status can be changed

      /**
       * show success
       */
      const showSuccess = () => {
        setAlertProps({
          title: editWhitelist
            ? t("pages.serviceBuilder.success.whitelistUpdate.title")
            : t("pages.serviceBuilder.success.versionStatusUpdate.title"),
          type: AlertTypeEnum.SUCCESS,
        });
        showAlert();
        refreshPage();
      };

      /**
       * show error
       */
      const showError = () => {
        setAlertProps({
          title: editWhitelist
            ? t("pages.serviceBuilder.errors.whitelistUpdate.title")
            : t("pages.serviceBuilder.errors.versionStatusUpdate.title"),
          content: editWhitelist
            ? t("pages.serviceBuilder.errors.whitelistUpdate.content")
            : t("pages.serviceBuilder.errors.versionStatusUpdate.content"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      };

      // If version is LIVE or BETA --> go to approve
      // If update is LIVE or BETA --> go to approve
      // Delete/undeploy shuld go to approve when service is LIVE or BETA what is guarented by first clausule
      // const goForApproval = () => {
      //   return (
      //     [ServiceStatusEnum.LIVE, ServiceStatusEnum.BETA].includes(serviceVersion.status as ServiceStatusEnum) ||
      //     [ServiceStatusEnum.LIVE, ServiceStatusEnum.BETA].includes(serviceVersionDetails.status as ServiceStatusEnum)
      //   );
      // };

      /**
       * build data
       */
      const buildData = () => {
        if (serviceVersionDetails.status === ServiceStatusEnum.BETA) {
          return { status: serviceVersionDetails.status, whiteList: serviceVersionDetails.whiteList };
        }
        return { status: serviceVersionDetails.status };
      };

      if (!editWhitelist) {
        ServiceDetailsApi.methods
          .setVersionUpdateForApproval(serviceVersion.id || "", { ...buildData() })
          .finally(() => hideModal())
          .then(
            () => {
              showSuccess();
            },
            () => {
              showError();
            },
          );
      } else {
        ServiceDetailsApi.methods
          .updateServiceVersion(serviceVersion.id ? serviceVersion.id : "", serviceVersionDetails)
          .finally(() => hideModal())
          .then(
            () => {
              showSuccess();
            },
            () => {
              showError();
            },
          );
      }
    },
    [editWhitelist, refreshPage, serviceVersion.id, setAlertProps, showAlert, t],
  );

  function _showPublishModal() {
    const serviceVersionDetails = {
      status: ServiceStatusEnum.LIVE,
      serviceId: serviceVersion.serviceId ? serviceVersion.serviceId : "",
    };
    setPublishProps({
      title: t("pages.serviceBuilder.modals.versionLifecycle.publish.title"),
      description: t("pages.serviceBuilder.modals.versionLifecycle.publish.description"),
      primaryAction: () => {
        changeVersionStatus(serviceVersionDetails, hidePublishModal);
      },
      primaryBtnLabel: t("pages.serviceBuilder.modals.versionLifecycle.publish.primaryButtonLabel"),
      secondaryAction: hidePublishModal,
      secondaryBtnLabel: t("pages.serviceBuilder.modals.versionLifecycle.publish.secondaryButtonLabel"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
    });
    showPublishModal();
  }

  const [showDisableModal, hideDisableModal, setDisableProps] = useModal(ModalTypeEnum.ConfirmationModal);

  function _showDisableModal() {
    const serviceVersionDetails = {
      status: ServiceStatusEnum.UNDEPLOYED,
      serviceId: serviceVersion.serviceId ? serviceVersion.serviceId : "",
    };
    setDisableProps({
      title: t("pages.serviceBuilder.modals.versionLifecycle.disable.title"),
      description: t("pages.serviceBuilder.modals.versionLifecycle.disable.description"),
      primaryAction: () => {
        changeVersionStatus(serviceVersionDetails, hideDisableModal);
      },
      primaryBtnLabel: t("pages.serviceBuilder.modals.versionLifecycle.disable.primaryButtonLabel"),
      secondaryAction: hideDisableModal,
      secondaryBtnLabel: t("pages.serviceBuilder.modals.versionLifecycle.disable.secondaryButtonLabel"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
    });
    showDisableModal();
  }

  const [showBetaModal, hideBetaModal, setBetaProps] = useModal(ModalTypeEnum.ConfirmationModal);

  function _showBetaModal(whitelist: string[], invalidMsisdn: string[]) {
    let serviceVersionDetails: IServiceVersion = {
      serviceId: serviceVersion.serviceId ? serviceVersion.serviceId : "",
      whiteList: whitelist,
      status: ServiceStatusEnum.BETA,
    };
    if (!editWhitelist) {
      serviceVersionDetails = { ...serviceVersionDetails, status: ServiceStatusEnum.BETA };
    }
    setBetaProps({
      setInnerHtml: true,
      title: editWhitelist
        ? t("pages.serviceBuilder.modals.versionLifecycle.whitelistChange.title")
        : t("pages.serviceBuilder.modals.versionLifecycle.goBeta.title"),
      description: editWhitelist
        ? invalidMsisdn.length > 0
          ? `${t(
              "pages.serviceBuilder.modals.versionLifecycle.whitelistChange.description",
            )}<div style='font-size: 14px; color: red; display:-webkit-box; -webkit-line-clamp: 5; -webkit-box-orient: vertical; overflow: hidden; word-break: break-word; hyphens: auto'>Invalid msisdn's: ${invalidMsisdn.join()}</div>`
          : t("pages.serviceBuilder.modals.versionLifecycle.whitelistChange.description")
        : invalidMsisdn.length > 0
        ? `${t(
            "pages.serviceBuilder.modals.versionLifecycle.goBeta.description",
          )}<div style='font-size: 14px; color: red; display:-webkit-box; -webkit-line-clamp: 5; -webkit-box-orient: vertical; overflow: hidden; word-break: break-word; hyphens: auto'>Invalid msisdn's: ${invalidMsisdn.join()}</div>`
        : t("pages.serviceBuilder.modals.versionLifecycle.goBeta.description"),
      primaryAction: () => {
        changeVersionStatus(serviceVersionDetails, hideBetaModal);
      },
      primaryBtnLabel: t("pages.serviceBuilder.modals.versionLifecycle.goBeta.primaryButtonLabel"),
      secondaryAction: hideBetaModal,
      secondaryBtnLabel: t("pages.serviceBuilder.modals.versionLifecycle.goBeta.secondaryButtonLabel"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
    });
    showBetaModal();
  }
  const [showDeleteModal, hideDeleteModal, setDeleteProps] = useModal(ModalTypeEnum.ConfirmationModal);
  const deleteServiceVersion = useCallback(() => {
    //delete service methods

    /**
     * show success
     */
    const showSuccess = () => {
      setAlertProps({
        title: needApproveForDelete()
          ? t("pages.serviceBuilder.success.deleteServiceVersion.title")
          : t("pages.serviceBuilder.success.deleteServiceVersion.titleNow"),
        type: AlertTypeEnum.SUCCESS,
      });
      showAlert();
      refreshPage();
    };

    /**
     * show error
     */
    const showError = () => {
      setAlertProps({
        title: t("pages.serviceBuilder.errors.deletingServiceVersion.title"),
        content: t("pages.serviceBuilder.errors.deletingServiceVersion.content"),
        type: AlertTypeEnum.ERROR,
      });
      showAlert();
    };

    ServiceDetailsApi.methods
      .deleteServiceVersion(serviceVersion.id ? serviceVersion.id : "", needApproveForDelete())
      .finally(() => hideDeleteModal())
      .then(
        () => {
          showSuccess();
        },
        () => {
          showError();
        },
      );
  }, [hideDeleteModal, refreshPage, serviceVersion.id, setAlertProps, showAlert, t]);

  function _showDeleteModal() {
    setDeleteProps({
      title: t("pages.serviceBuilder.modals.deleteVersion.title"),
      description: t("pages.serviceBuilder.modals.deleteVersion.description", {
        serviceVersion: serviceVersion.version ? serviceVersion.version : "",
      }),
      primaryAction: deleteServiceVersion,
      primaryBtnLabel: t("pages.serviceBuilder.modals.deleteVersion.primaryButtonLabel"),
      secondaryAction: hideDeleteModal,
      secondaryBtnLabel: t("pages.serviceBuilder.modals.deleteVersion.secondaryButtonLabel"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
    });
    showDeleteModal();
  }

  /**
   * hide whitelist
   */
  const _hideWhitelistModal = () => {
    hideWhitelistModal();
  };

  const [showEmptyModal, hideEmptyModal, setEmptyProps] = useModal(ModalTypeEnum.ConfirmationModal);

  function _showEmptyModal(suggestion: string) {
    setEmptyProps({
      setInnerHtml: true,
      title: t("pages.serviceBuilder.modals.noMsisdn.title"),
      description: suggestion
        ? `${t(
            "pages.serviceBuilder.modals.noMsisdn.description",
          )}<div style='font-size:14px; color: red'>Suggestion: ${suggestion}</div>`
        : t("pages.serviceBuilder.modals.noMsisdn.description"),
      primaryAction: hideEmptyModal,
      primaryBtnLabel: t("pages.serviceBuilder.modals.noMsisdn.primaryButtonLabel"),
      secondaryAction: () => {
        hideEmptyModal();
        _showWhitelistModal();
      },
      secondaryBtnLabel: t("pages.serviceBuilder.modals.noMsisdn.secondaryButtonLabel"),
      primaryBtnId: "close-button",
      secondaryBtnId: "back-button",
    });
    showEmptyModal();
  }

  /**
   * set whitelist
   * @param validMsisdn
   * @param invalidMsisdn
   * @param suggestion
   */
  const whitelistSetFunction = (validMsisdn: string[], invalidMsisdn: string[], suggestion: string) => {
    // const whitelistAux = whitelistString.split(",").map((str: string) => {
    //   return str.trim();
    // });
    if (validMsisdn.length > 0) {
      _showBetaModal(validMsisdn, invalidMsisdn);
    } else {
      _showEmptyModal(suggestion);
      setEditWhitelist(false);
    }
  };

  /**
   * show whitelist
   */
  const _showWhitelistModal = () => {
    setWhitelistProps({
      modalStyles: { width: "600px" },
    });
    showWhitelistModal();
  };

  /**
   * validation error
   */
  const validationError = () => {
    setEditWhitelist(false);
    setAlertProps({
      title: t("pages.serviceBuilder.errors.validateMsisdnError.title"),
      content: t("pages.serviceBuilder.errors.validateMsisdnError.content"),
      type: AlertTypeEnum.ERROR,
    });
    showAlert();
  };
  const [showWhitelistModal, hideWhitelistModal, setWhitelistProps] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <WhitelistModal
      whitelist={serviceVersion.whiteList ? serviceVersion.whiteList.join() : ""}
      hideModal={_hideWhitelistModal}
      onConfirm={(validMsisdn: string[], invalidMsisdn: string[], suggestion: string) => {
        whitelistSetFunction(validMsisdn, invalidMsisdn, suggestion);
      }}
      onError={validationError}
    ></WhitelistModal>,
  );

  const [isDisplayingComments, setIsDisplayingComments] = useState(false);

  /**
   * toggle comments
   */
  const toggleComments = () => {
    setIsDisplayingComments(!isDisplayingComments);
  };

  return (
    <ServiceVersionDivContainer>
      <IconWrapper>
        <CopyIcon />
      </IconWrapper>
      <ServiceVersionInformation>
        <ServiceVersionInformationHeader>
          <div>
            <VersionNumber>
              {t(`pages.serviceBuilder.versionContainer.versionNumber`)} {serviceVersion.version}
            </VersionNumber>
            {serviceVersion.statusDate ? (
              <OtherInformation>
                {t(`pages.serviceBuilder.versionContainer.lastUpdate`)} {formatUTCTimestamp(serviceVersion.statusDate)}
              </OtherInformation>
            ) : null}
          </div>
          <div>
            <SecurityIcon />

            <StatusChip type={serviceVersion.status as any}>
              {t(`commons.serviceBuilder.enums.status.${serviceVersion.status}`)}
            </StatusChip>
          </div>
        </ServiceVersionInformationHeader>
        <ButtonRowWrapper open={open}>
          {serviceVersion.releaseNotes &&
          serviceVersion.releaseNotes !== undefined &&
          serviceVersion.releaseNotes !== "" ? (
            <ReleaseNotesButton show={isDisplayingComments} onClick={toggleComments}>
              {isDisplayingComments
                ? t(`pages.serviceBuilder.versionContainer.hideReleaseNotes`)
                : t(`pages.serviceBuilder.versionContainer.showReleaseNotes`)}
              <ChevronDownIcon />
            </ReleaseNotesButton>
          ) : null}

          {canPublish() ? (
            <div id={"publish-button"}>
              <FastActionButton
                label={t(`pages.serviceBuilder.versionContainer.publish`)}
                iconComponent={
                  <FastIconWrapper color={styleTheme.palette.digitalGreen}>
                    <CheckIcon />
                  </FastIconWrapper>
                }
                onClick={() => _showPublishModal()}
              />
            </div>
          ) : null}
          {canDisable() ? (
            <div id={"disable-button"}>
              <FastActionButton
                label={t(`pages.serviceBuilder.versionContainer.disable`)}
                iconComponent={
                  <FastIconWrapper color={styleTheme.palette.vodafoneRed}>
                    <CancelIcon />
                  </FastIconWrapper>
                }
                onClick={() => _showDisableModal()}
              />
            </div>
          ) : null}
          {canGoBeta() ? (
            <div id={"go-beta-button"}>
              <FastActionButton
                label={t(`pages.serviceBuilder.versionContainer.goBeta`)}
                iconComponent={
                  <FastIconWrapper color={styleTheme.palette.turquoiseBlue}>
                    <PublishIcon />
                  </FastIconWrapper>
                }
                onClick={() => _showWhitelistModal()}
              />
            </div>
          ) : null}
          {canEditWhiteList() ? (
            <div id={"edit-whitelist-button"}>
              <FastActionButton
                label={t(`pages.serviceBuilder.versionContainer.editWhitelist`)}
                iconComponent={
                  <FastIconWrapper color={styleTheme.palette.turquoiseBlue}>
                    <EditIcon />
                  </FastIconWrapper>
                }
                onClick={() => {
                  setEditWhitelist(true);
                  _showWhitelistModal();
                }}
              />
            </div>
          ) : null}
          {serviceVersion.useRenderer ? (
            <div id={"preview-button"}>
              <FastActionButton
                label={t(`pages.serviceBuilder.versionContainer.preview`)}
                iconComponent={
                  <FastIconWrapper color={styleTheme.palette.turquoiseBlue}>
                    <EyeIcon />
                  </FastIconWrapper>
                }
                onClick={() => {
                  history.push(
                    RoutesEnum.SERVICE_EDITOR.replace(
                      ":versionId",
                      serviceVersion.id !== undefined
                        ? serviceVersion.id
                        : t(`pages.serviceBuilder.versionContainer.unavailable`),
                    )
                      .replace(
                        ":versionNumber",
                        serviceVersion.version !== undefined
                          ? serviceVersion.version
                          : t(`pages.serviceBuilder.versionContainer.unavailable`),
                      )
                      .replace(
                        ":serviceId",
                        serviceVersion.serviceId || t(`pages.serviceBuilder.versionContainer.unavailable`),
                      ),
                    { serviceVersion },
                  );
                }}
              />
            </div>
          ) : null}
          {canDeleteVersion() ? (
            <div id={"delete-button"}>
              <FastActionButton
                label={t(`pages.serviceBuilder.versionContainer.delete`)}
                iconComponent={
                  <FastIconWrapper color={styleTheme.palette.vodafoneRed}>
                    <TrashIcon />
                  </FastIconWrapper>
                }
                onClick={() => _showDeleteModal()}
              />
            </div>
          ) : null}
        </ButtonRowWrapper>

        {isDisplayingComments &&
        serviceVersion.releaseNotes &&
        serviceVersion.releaseNotes !== undefined &&
        serviceVersion.releaseNotes !== "" ? (
          <ReleaseNotesDiv>{serviceVersion.releaseNotes}</ReleaseNotesDiv>
        ) : null}
      </ServiceVersionInformation>
    </ServiceVersionDivContainer>
  );
};

export default ServiceVersionContainer;

const ServiceVersionDivContainer = styled("div")`
  min-height: 126px;
  border-radius: 11px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  cursor: pointer;
  padding: 22px 20px 22px 22px;
  display: flex;
  transition: height 0.25s ease-in-out;
  min-width: 570px;

  &:hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    > div > div {
      opacity: 1;
      :nth-of-type(3) {
        display: -webkit-box;
      }
    }
  }
`;

const ServiceVersionInformation = styled("div")`
  flex: 1;
`;

const ServiceVersionInformationHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  svg {
    stroke: ${props => props.theme.palette.turquoiseBlue};
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
  }
`;

const VersionNumber = styled("div")`
  height: 23px;
  font-family: Vodafone Rg;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.28;
  color: ${props => props.theme.palette.darkGrey};
`;

const OtherInformation = styled("div")`
  height: 21px;
  font-family: Vodafone Rg;
  font-size: 16px;
  line-height: 1.31;
  color: ${props => props.theme.palette.midGrey};
  margin-top: 2px;
`;

const ButtonRowWrapper = styled("div")<{ open: boolean }>`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 5px;
  opacity: 0;
  @media only screen and (max-width: ${props => (props.open ? 1290 : 1000)}px) {
    height: 70px;
    align-content: space-evenly;
    button:nth-child(6) {
      margin-right: 12px;
    }
  }
  > div {
    width: auto;

    &:not(:last-of-type) {
      margin-right: 12px;
    }
  }
`;

const FastIconWrapper = styled("span")<{ color: string }>`
  width: 16px;
  height: 16px;
  stroke: ${props => props.color};
  svg {
    width: inherit;
    height: inherit;
  }
`;

const IconWrapper = styled("div")`
  svg {
    height: 24px;
    width: 24px;

    stroke: ${props => props.theme.palette.turquoiseBlue};
  }
  padding-right: 16px;
`;

const ReleaseNotesButton = styled("button")<{ show: boolean }>`
  height: 21px;
  font-family: Vodafone Rg;
  font-size: 16px;
  line-height: 1.31;
  color: ${props => props.theme.palette.darkGrey};
  display: flex;
  margin: auto;
  margin-right: auto !important;
  margin-left: 0;
  border: none;
  background-color: transparent;
  padding-left: 0;
  cursor: pointer;

  svg {
    width: 18px;
    height: 18px;
    stroke: ${props => props.theme.palette.vodafoneRed};
    margin-left: 4px;
    transition: transform 0.25s ease-in-out;
  }

  ${props => (props.show ? "svg { transform: rotate(180deg); }" : null)}
`;
const ReleaseNotesDiv = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  line-height: 1.31;
  color: ${props => props.theme.palette.midGrey};
  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  display: -webkit-box;
  display: none;
  margin-top: 10px;
  opacity: 0;
`;
