import { IServiceDetails, ServiceStatusEnum, IServiceVersion } from "../../../shared/models/service-builder.model";

// Check if a given service is live
export const isLive = (service: IServiceDetails) => {
  return !![ServiceStatusEnum.BETA, ServiceStatusEnum.LIVE].includes(service.status);
};

// Check if a given service is live
export const isLiveVersion = (version: IServiceVersion) => {
  return !![ServiceStatusEnum.BETA, ServiceStatusEnum.LIVE].includes(version.status as ServiceStatusEnum);
};

export default isLive;
