import { Magnifier, ModalTypeEnum, PageTitle, useAlert, useFilters, useModal } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import { Breadcrumb } from "@wit/mpesa-ui-components/lib/types";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { IStoreInterface } from "../../../configs/store.config";
import { RoutesEnum } from "../../../routes/routes.constants";
import { LoadingText, PageContainer, Row } from "../../../shared/shared.styled";
import AddLanguageTranslationVersion from "./components/add-new-language-translation-version.component";
import CreateLanguageTranslationsVersionModal from "./components/create-language-translations-version.modal";
import EmptyStateIllustration from "./components/empty-state.component";
import LanguageTranslationsVersionListItem from "./components/language-translations-version-list-item.component";
import LanguageTranslationsVersionsFilters from "./components/language-translations-versions-filter.component";
import LanguageTranslationsApi from "./language-translations.api";
import { ILanguageTranslationsVersion } from "./language-translations.model";
import { LanguageTranslationsActions } from "./language-translations.store";
import { filterLanguageTranslationsVersions } from "./language-translations.utils";

/**
 * List of language translations versions
 * @returns {JSX.Element}
 * @constructor
 */
const LanguageTranslationsVersionsList = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [showAlert, , setAlertProps] = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [versionToDuplicate, setVersionToDuplicate] = React.useState("");
  const themeContext = useContext(ThemeContext);

  const [filtersResult, setFiltersResult] = useState<ILanguageTranslationsVersion[]>();
  const {
    filters,
    updateFilter,
    isFilterActive,
    clearFilter,
    resetFilters,
    getFilterValue,
    getFiltersQueryString,
    updateMultipleFilters,
  } = useFilters();
  const history = useHistory();
  const { languageTranslationsVersions } = useSelector(
    (state: IStoreInterface) => state.languageTranslationsReducer,
    shallowEqual,
  );

  /**
   * Delete version confirmation modal
   */
  const [
    showDeleteVersionConfirmationModal,
    hideDeleteVersionConfirmationModal,
    setDeleteVersionConfirmationProps,
  ] = useModal(ModalTypeEnum.ConfirmationModal);

  const deleteVersionProps = {
    title: t("pages.languageTranslationsVersions.deleteVersion"),
    primaryBtnLabel: t("pages.languageTranslationsVersions.delete"),
    secondaryAction: () => hideDeleteVersionConfirmationModal(),
    isKeyboardConfirmationEnabled: true,
    secondaryBtnId: "cancel-button",
    primaryBtnId: "delete-button",
  } as BaseModalProps;

  /**
   * Show delete version modal
   * @param version
   */
  const showDeleteVersionModal = (version: string) => {
    setDeleteVersionConfirmationProps({
      ...deleteVersionProps,
      description: t("pages.languageTranslationsVersions.deleteVersionMessage", { version: version }),
      primaryAction: () => deleteVersion(version),
    });
    showDeleteVersionConfirmationModal();
  };

  /**
   * Close create version modal
   */
  const dismissCreateVersionModal = () => {
    setVersionToDuplicate("");
    hideCreateLanguageTranslationsVersionModal();
  };

  /**
   * Outside fn to display the create version modal
   */
  const displayCreateVersionModal = (version?: string) => {
    setVersionToDuplicate("");
    version && setVersionToDuplicate(version);
    showCreateLanguageTranslationsVersionModal();
  };

  /**
   * Create language translations version modal
   */
  const [showCreateLanguageTranslationsVersionModal, hideCreateLanguageTranslationsVersionModal] = useModal(
    ModalTypeEnum.CustomModal,
    { modalStyles: { width: 400 } } as any,
    <CreateLanguageTranslationsVersionModal
      dismissModal={dismissCreateVersionModal}
      versionToDuplicate={versionToDuplicate}
      history={history}
    />,
  );

  /**
   * Init use effect
   */
  React.useEffect(() => {
    getLanguageTranslationsVersions();
  }, []);

  /**
   * Filters use effect
   */
  React.useEffect(() => {
    if (filters.size > 0) {
      const results = filterLanguageTranslationsVersions(
        languageTranslationsVersions.versions ? languageTranslationsVersions.versions : [],
        filters,
      );
      setFiltersResult(results);
    } else {
      setFiltersResult(undefined);
    }
  }, [filters, languageTranslationsVersions]);

  /**
   * Delete version handler
   * @param {string} version
   */
  const deleteVersion = (version: string) => {
    setDeleteVersionConfirmationProps({
      ...deleteVersionProps,
      description: t("pages.languageTranslationsVersions.deleteVersionMessage", { version: version }),
      primaryAction: () => deleteVersion(version),
      isLoading: true,
    });
    LanguageTranslationsApi.methods
      .deleteLanguageTranslationsVersion(version)
      .then(
        res => {
          setAlertProps({
            title: t("pages.languageTranslationsVersions.deleteVersionSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          dispatch(LanguageTranslationsActions.creators.getLanguageTranslationsVersionsSuccess(res.data));
          hideDeleteVersionConfirmationModal();
        },
        res => {
          setAlertProps({
            title: t("pages.languageTranslationsVersions.deleteVersionError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        setDeleteVersionConfirmationProps({
          ...deleteVersionProps,
          description: t("pages.languageTranslationsVersions.deleteVersionMessage", { version: version }),
          primaryAction: () => deleteVersion(version),
          isLoading: false,
        });
      });
  };

  /**
   * Fetches the list of language translations versions
   */
  const getLanguageTranslationsVersions = () => {
    setIsLoading(true);
    LanguageTranslationsApi.methods
      .getLanguageTranslationsVersions()
      .then(
        res => {
          dispatch(LanguageTranslationsActions.creators.getLanguageTranslationsVersionsSuccess(res.data));
        },
        () => {
          setAlertProps({
            title: t("pages.languageTranslationsVersions.getLanguageTranslationsVersionsError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Sort language translation from higher to lower version number and returns it
   * to be rendered
   * @param languageTranslationsVersion: ILanguageTranslationsVersion[]
   * @returns ILanguageTranslationsVersion[]
   */
  const sortLanguageTranslationVersions = (languageTranslationsVersion: ILanguageTranslationsVersion[]) => {
    const translations = [...languageTranslationsVersion].sort((y, z) => {
      const a =
        y.version.split(".").length === 3
          ? `${y.version}.0`.split(".")
          : y.version.split(".").length === 2
          ? `${y.version}.0.0`.split(".")
          : `${y.version}.0.0.0`.split(".");
      const b =
        z.version.split(".").length === 3
          ? `${z.version}.0`.split(".")
          : z.version.split(".").length === 2
          ? `${z.version}.0.0`.split(".")
          : `${z.version}.0.0.0`.split(".");

      if (Number(`${a[0] + a[1]}.${a[2]}${a[3]}`) > Number(`${b[0] + b[1]}.${b[2]}${b[3]}`)) {
        return -1;
      }

      if (Number(`${a[0] + a[1]}.${a[2]}${a[3]}`) < Number(`${b[0] + b[1]}.${b[2]}${b[3]}`)) {
        return 1;
      }

      return 0;
    });

    return translations;
  };

  /**
   * Get breadcrumbs
   * @returns {Breadcrumb[]}
   */
  const getBreadcrumbs = (): Breadcrumb[] => {
    return [
      {
        label: t("pages.configuration.title"),
        url: RoutesEnum.CONFIGS,
      },
      {
        label: t("pages.languageTranslationsVersions.title"),
        url: "",
      },
    ];
  };

  return (
    <PageContainer>
      <PageTitle
        breadcrumbs={getBreadcrumbs()}
        title={t("pages.languageTranslations.title")}
        navigateFunction={history.push}
        goBackFn={() => history.push(RoutesEnum.CONFIGS)}
        separatorStyle={{ display: "none" }}
        titleContainerStyle={{ marginTop: "13px", marginBottom: "20px", height: "60px" }}
        iconContainerStyle={{ stroke: themeContext.palette.vodafoneRed }}
      />

      {isLoading ? (
        <LoadingText>{t("commons.loadingResults")}</LoadingText>
      ) : (
        <LanguageTranslationsVersionsContainer>
          <Row>
            <LanguageTranslationsVersionsFilters
              filters={filters}
              isFilterActive={isFilterActive}
              updateFilter={updateFilter}
              clearFilter={clearFilter}
              resetFilters={resetFilters}
              getFilterValue={getFilterValue}
              getFiltersQueryString={getFiltersQueryString}
              updateMultipleFilters={updateMultipleFilters}
            />
          </Row>

          <LanguageTranslationsVersionsListContainer>
            <>
              {!filtersResult ? (
                <AddLanguageTranslationVersion
                  clickFn={() => {
                    displayCreateVersionModal();
                  }}
                />
              ) : null}
              {languageTranslationsVersions.versions?.length ? (
                <>
                  {filtersResult ? (
                    <>
                      {filtersResult.map((version: ILanguageTranslationsVersion) => {
                        return (
                          <LanguageTranslationsVersionListItem
                            key={version.version}
                            item={version}
                            duplicateFn={displayCreateVersionModal}
                            deleteFn={showDeleteVersionModal}
                          />
                        );
                      })}
                      {filtersResult.length === 0 ? (
                        <MagnifierContainer>
                          <Magnifier />
                          <NoResultsFoundText>
                            {t("pages.languageTranslationsVersions.noSearchResults")}
                          </NoResultsFoundText>
                        </MagnifierContainer>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {sortLanguageTranslationVersions(languageTranslationsVersions.versions).map(version => {
                        return (
                          <LanguageTranslationsVersionListItem
                            key={version.version}
                            item={version}
                            deleteFn={showDeleteVersionModal}
                            duplicateFn={displayCreateVersionModal}
                          />
                        );
                      })}
                    </>
                  )}
                </>
              ) : (
                <EmptyState>
                  <EmptyStateIllustration />
                  <NoResultsFoundText>{t("pages.languageTranslationsVersions.emptyState")}</NoResultsFoundText>
                </EmptyState>
              )}
            </>
          </LanguageTranslationsVersionsListContainer>
        </LanguageTranslationsVersionsContainer>
      )}
    </PageContainer>
  );
};

const LanguageTranslationsVersionsContainer = styled("div")`
  margin-bottom: 34px;
  display: flex;
  flex-direction: column;
`;

export const MagnifierContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 50px;
  svg {
    width: 140px;
    height: 140px;
    margin-bottom: 24px;
    stroke: red;
  }
`;

export const NoResultsFoundText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.midGrey};
`;

const LanguageTranslationsVersionsListContainer = styled("div")`
  margin-bottom: 50px;
`;

export const EmptyState = styled("div")`
  margin-top: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export default LanguageTranslationsVersionsList;
