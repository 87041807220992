import store from "../../../configs/store.config";
import i18next from "i18next";

export interface IDropdownOptionsInterface {
  key: string;
  label: string;
}

/**
 * Get available languages
 */
export const getAvailableLanguagesOptions = () => {
  const shortcutsStore = store.getState().shortcutsReducer.shortcuts;

  const languages = Object.keys(
    shortcutsStore.actionItemResponses.reduce((keys: any, item: any) => {
      const itemKeys = Object.keys(item.name);
      itemKeys.forEach(key => {
        keys[key] = true;
      });
      return keys;
    }, {}),
  ).map(key => {
    return { label: i18next.t(`commons.languages.${key}`), key: key };
  });

  if (languages) {
    return languages.sort((a, b) => {
      const labelA = a.label.toLowerCase();
      const labelB = b.label.toLowerCase();

      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    });
  } else {
    return [
      {
        label: i18next.t(`commons.languages.en`),
        key: "en",
      },
    ];
  }
};
