import { AxiosPromise } from "axios";
import ApiClient from "../../../configs/axios.config";

/*
 * Notifications API methods
 * */
const NotificationsApi = {
  routes: {
    getNotifications: "/v1/notifications",
    markNotificationAsRead: "/v1/notifications/:id",
  },
  methods: {
    getNotifications: (): AxiosPromise => {
      return ApiClient.get(NotificationsApi.routes.getNotifications, {});
    },
    markNotificationAsRead: (id: string | number): AxiosPromise => {
      return ApiClient.put(NotificationsApi.routes.markNotificationAsRead.replace(":id", `${id}`));
    },
  },
};

export default NotificationsApi;
