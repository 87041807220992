import { IDynamicScreenCategory } from "../../features/configurations/dynamic-screens/dynamic-screens.model";

export interface IServiceManagerService {
  id: string;
  creationDate: string;
  name: string;
  description: string;
  iconUrl: string;
  imageUrl: string;
  status: ServiceManagerStatusEnum;
  antId: string;
  categories: IDynamicScreenCategory[];
  highlighted: boolean;
  uniqueCode: string;
  urlWhitelist: string[];
}

export interface IServiceManagerServiceDetails {
  id: string;
  creationDate: string;
  name: any;
  description: any;
  iconUrl: string;
  imageUrl: string;
  status: ServiceManagerStatusEnum;
  antId: string;
  allowedScopes: string[];
  canBeEdited: boolean;
  categories: IDynamicScreenCategory[];
  highlighted: boolean;
  uniqueCode: string;
  urlWhitelist: string[];
  submittedForApproval: boolean;
  tags?: string[];
  tagsSuggestions?: [];
}

export interface IServiceManagerAddService {
  name: { [x: string]: string };
  description: { [x: string]: string };
  highlighted?: boolean;
  categories?: string[];
  antId: string;
  serviceIcon: string;
  serviceImage: string;
  tags: string[];
  allowedScopes: string[];
}

export interface IExternalServices {
  externalServiceId: string;
  url: string;
  permissions: AntExternalServicePermitionsEnum[];
  rsaPublicKey?: string;
}

export interface IPermissions {
  identity: boolean;
  pin: boolean;
}

export enum ServiceManagerStatusEnum {
  LIVE = "LIVE",
  DISABLED = "DISABLED",
  UNDEPLOYED = "UNDEPLOYED",
}

export enum ServiceManagerServiceStatusSearchEnum {
  LIVE = "LIVE",
  DISABLED = "DISABLED",
}

export enum ServiceManagerServiceTypeSearchEnum {
  ALL_SERVICES = "ALL_SERVICES",
  SERVICES_AREA = "SERVICES_AREA",
}

export enum AntExternalServicePermitionsEnum {
  PIN = "PIN",
  IDENTITY = "IDENTITY",
}
