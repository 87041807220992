import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { VersionItemStatusEnum } from "./use-version-diff.hook";

interface IVersionItemStatusChip {
  status?: VersionItemStatusEnum;
}

/**
 * Version item status chip component
 * @param {VersionItemStatusEnum | undefined} status
 * @returns {JSX.Element}
 * @constructor
 */
const VersionItemStatusChip = ({ status }: IVersionItemStatusChip) => {
  const [t] = useTranslation();
  return <StatusChip status={status}>{t(`commons.versionDiffStatus.${status}`)}</StatusChip>;
};

export default VersionItemStatusChip;

/**
 * Status chip styled component
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {diff: VersionItemStatusEnum}, never>}
 */
const StatusChip = styled("div")<{ status?: VersionItemStatusEnum }>`
  margin-left: 8px;
  border-radius: 10px;
  padding-left: 12px;
  padding-right: 12px;
  height: 22px;
  line-height: 21px;
  font-weight: bold;
  display: ${props => (props.status ? "inline-block" : "none")};
  font-size: 14px;
  text-align: center;
  font-family: Vodafone Rg;
  color: white;
  ${props => {
    let backgroundColor = "";
    const fontColor = props.theme.palette.white;
    let borderColor = "";
    switch (props.status) {
      case VersionItemStatusEnum.ADDED:
        backgroundColor = props.theme.palette.digitalGreen;
        borderColor = props.theme.palette.digitalGreen;
        break;
      case VersionItemStatusEnum.UPDATED:
        backgroundColor = props.theme.palette.turquoiseBlue;
        borderColor = props.theme.palette.turquoiseBlue;
        break;
      case VersionItemStatusEnum.REMOVED:
        backgroundColor = props.theme.palette.freshOrange;
        borderColor = props.theme.palette.freshOrange;
        break;
    }
    return `background-color: ${backgroundColor}; color: ${fontColor}; border: 1px solid ${borderColor}`;
  }}
`;
