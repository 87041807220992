import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { BackgroundImageContext } from "../../routes/unauthenticated-routes.component";

const AnimatedBackgroundImage = () => {
  return (
    <BackgroundImageContext.Consumer>
      {value => (
        <TransitionGroup>
          <CSSTransition key={value} classNames="login-animated-img" timeout={2000}>
            <StyledImage src={value} className="login-animated-img" alt="background-img" />
          </CSSTransition>
        </TransitionGroup>
      )}
    </BackgroundImageContext.Consumer>
  );
};

export default AnimatedBackgroundImage;

const StyledImage = styled("img")`
  position: absolute;
  width: 100%;
  height: 100%;
`;
