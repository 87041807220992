import { AxiosPromise } from "axios";
import ApiClient from "../../configs/axios.config";

/*
 * Languages API
 * */
const LanguagesApi = {
  routes: {
    getAvailableLanguages: "/available_languages",
  },
  methods: {
    getAvailableLanguages: (): AxiosPromise => {
      return ApiClient.get(LanguagesApi.routes.getAvailableLanguages, {});
    },
  },
};

export default LanguagesApi;
