import { AxiosPromise } from "axios";
import ApiClient from "../../configs/axios.config";
import { INotification } from "./push-notifications.modal";

const PushNotificationsApi = {
  routes: {
    addPushNotification: "/on-demand-notifications",
    getPushNotification: "/on-demand-notifications/:notificationId",
    deletePushNotification: "/on-demand-notifications/:notificationId",
    getNotificationsAll: "/on-demand-notifications/all",
    getBackgroundImageByUrl: "/on-demand-notifications/image/:url",
    validateMsisdn: "/announcements/validate/msisdn",
    validateShortcodes: "/announcements/validate/shortcodes",
  },
  methods: {
    addPushNotification: (pushNotification: INotification): AxiosPromise => {
      return ApiClient.post(PushNotificationsApi.routes.addPushNotification, pushNotification, { timeout: 90000 });
    },
    getPushNotification: (notifId: string): AxiosPromise => {
      return ApiClient.get(PushNotificationsApi.routes.deletePushNotification.replace(":notificationId", notifId));
    },
    deletePushNotification: (notifId: string): AxiosPromise => {
      return ApiClient.delete(PushNotificationsApi.routes.deletePushNotification.replace(":notificationId", notifId));
    },
    getNotificationsAll: (): AxiosPromise => {
      return ApiClient.get(PushNotificationsApi.routes.getNotificationsAll);
    },
    getImage: (url: string): AxiosPromise => {
      return ApiClient.get(PushNotificationsApi.routes.getBackgroundImageByUrl.replace(":url", url));
    },
    validateMsisdn: (contentFile: string): AxiosPromise => {
      return ApiClient.request({
        timeout: 0,
        method: "POST",
        url: PushNotificationsApi.routes.validateMsisdn,
        data: contentFile,
      });
    },
    validateShortcode: (contentFile: string): AxiosPromise => {
      return ApiClient.request({
        timeout: 0,
        method: "POST",
        url: PushNotificationsApi.routes.validateShortcodes,
        data: contentFile,
      });
    },
  },
};

export default PushNotificationsApi;
