import React from "react";
import { PageContainer, PageTitle } from "../../../shared/shared.styled";
import { Tabs, TabChild } from "@wit/mpesa-ui-components";
import styled from "styled-components";
import ServiceManagerList from "../service-list/ant-service-list.page";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AntExternalServicesPage from "../external-services/ant-external-services.page";
import GeneralCategoriesPage from "../../configurations/categories/general-categories.page";
import AllowedScopePage from "../allowed-scope/allowed-scopes.page";

export enum ServiceManagerTabNumber {
  SERVICE_LIST = 0,
  EXTERNAL_SERVICES = 1,
  CATEGORIES = 2,
}

/**
 * ServiceManager page
 */
const AntServiceManagerPage = () => {
  const [t] = useTranslation();
  const query = new URLSearchParams(useLocation().search);
  const [tabIndex, setTabIdx] = React.useState(query.get("tabIdx") || ServiceManagerTabNumber.SERVICE_LIST);

  return (
    <PageContainer>
      <PageTitle>{t("pages.antServiceManager.title")}</PageTitle>
      <TabsContainer>
        <Tabs
          controlledSelectedTab={Number(tabIndex) || ServiceManagerTabNumber.SERVICE_LIST}
          controlledSetSelectedTab={setTabIdx}
        >
          <TabChild label={t("pages.antServiceManager.tabs.serviceList")}>
            <ServiceManagerList />
          </TabChild>
          <TabChild label={t("pages.antServiceManager.tabs.externalServices")}>
            <AntExternalServicesPage />
          </TabChild>
          <TabChild label={t("pages.antServiceManager.tabs.categories")}>
            <GeneralCategoriesPage isAntCategories={true} />
          </TabChild>
          <TabChild label={t("pages.antServiceManager.tabs.singleSignOn")}>
            <AllowedScopePage />
          </TabChild>
        </Tabs>
      </TabsContainer>
    </PageContainer>
  );
};

export default AntServiceManagerPage;

const TabsContainer = styled("div")`
  margin-top: 43px;
`;
