import {
  PageTitle,
  TabChild,
  Tabs,
  PrimaryButton,
  useModal,
  ModalTypeEnum,
  useAlert,
  FastActionButton,
  CheckIcon,
  UndoIcon,
} from "@wit/mpesa-ui-components";
import { Breadcrumb } from "@wit/mpesa-ui-components/lib/types";
import { TFunction } from "i18next";
import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { RoutesEnum } from "../../../routes/routes.constants";
import { PageContainer, Row, IconContainer, Column, LoadingText } from "../../../shared/shared.styled";
import BusinessList from "./business-list.page";
import styled, { ThemeContext } from "styled-components";
import PublishBusinessModal from "./publish.modal";
import { useDispatch, useSelector } from "react-redux";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BusinessesAPI } from "./business.api";
import { BusinessStatusEnum } from "./business.store";
import BusinessCategoryList from "./business-categories/business-categories-list.page";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import LastVersionModal from "./last-version.modal";
import { IStoreInterface } from "../../../configs/store.config";
import { getNewData, renderGeneralStatus } from "./business-utils";
import useCheckFeatureAvailable from "../../../shared/hooks/use-check-available-feature";
import { FEATURES } from "../../../shared/renderer.utils";
import { canUserFromSFCMarketApproveBusinessChanges } from "./utils/check-user-roles.util";

/**
 * Method to handle the confirm revert event
 * @param setLoading
 * @param setAlertProps
 * @param showAlert
 * @param hideConfirmationModal
 * @param dispatch
 * @param t
 */
const confirmRevert = (
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setAlertProps: any,
  showAlert: any,
  hideConfirmationModal: any,
  dispatch: any,
  t: TFunction,
) => {
  hideConfirmationModal();
  setLoading(true);
  BusinessesAPI.methods.revertLastVersion().then(
    res => {
      getNewData(dispatch, showAlert, setAlertProps, t).then(() => setLoading(false));
      setAlertProps({
        title: t("pages.appDetails.releaseVersions.revertSuccess"),
        type: AlertTypeEnum.SUCCESS,
      });
      showAlert();
    },
    err => {
      setAlertProps({
        title: t("pages.appDetails.releaseVersions.revertError"),
        type: AlertTypeEnum.ERROR,
      });
      showAlert();
    },
  );
};

/**
 * BusinessPage component
 */
const BusinessPage = () => {
  const userRole = useSelector((state: IStoreInterface) => state.authenticationReducer.loggedUser?.role);
  const { businessesStatus } = useSelector((state: IStoreInterface) => state.businessesReducer);
  const [t] = useTranslation();

  const { idx } = useParams<any>();
  const [tabIdx, setTabIdx] = React.useState(idx);
  const dispatch = useDispatch();
  const [showAlert, , setAlertProps] = useAlert();

  const [loading, setLoading] = useState(true);

  const showCategories = useCheckFeatureAvailable(FEATURES.BUSINESS_CATEGORIES);
  const history = useHistory();
  const themeContext = useContext(ThemeContext);

  /**
   * Method to handle the publish submission event
   */
  const submit = () => {
    hidePublishModal();
    setLoading(true);
    BusinessesAPI.methods
      .publishVersion()
      .then(
        () => {
          setAlertProps({
            title: t("pages.configurations.businessTab.publish.publishSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
        },
        _err => {
          setAlertProps({
            title: t("pages.configurations.businessTab.publish.publishError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .then(() => getNewData(dispatch, showAlert, setAlertProps, t).then(() => setLoading(false)));
  };

  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );

  const [showPublishModal, hidePublishModal, setPublishProps] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <PublishBusinessModal onCancelClick={() => hidePublishModal()} submit={submit} />,
  );

  const getBreadcrumbs = (t: TFunction): Breadcrumb[] => {
    return [
      {
        label: t("pages.documents.configuration"),
        url: RoutesEnum.CONFIGS,
      },
      {
        label: t("pages.business.title"),
        url: "",
      },
    ];
  };

  React.useEffect(() => {
    setPublishProps({ modalStyles: { width: 1000, overflow: "initial" } });
  }, [setPublishProps]);

  React.useEffect(() => {
    setLoading(true);
    getNewData(dispatch, showAlert, setAlertProps, t)
      .catch(_err => {
        setAlertProps({
          title: t("pages.configurations.businessTab.errors.getBusinessesError"),
          type: AlertTypeEnum.ERROR,
        });
        history.push(RoutesEnum.CONFIGS);
      })
      .finally(() => setLoading(false));
  }, []);

  const [showLastVersionModal, hideLastVersionModal, setLastVersionProps] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <LastVersionModal onCancelClick={() => hideLastVersionModal()} />,
  );

  React.useEffect(() => {
    setLastVersionProps({ modalStyles: { width: 553, height: 362, overflow: "initial" } });
  }, [setLastVersionProps]);

  const revertLastVersion = () => {
    if ((businessesStatus.data as string) === BusinessStatusEnum.LIVE.toString()) {
      return;
    }
    setConfirmationProps({
      title: t("pages.appDetails.releaseVersions.confirmRevert"),
      description: t("pages.appDetails.releaseVersions.confirmRevertMessage"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      primaryAction: () => {
        confirmRevert(setLoading, setAlertProps, showAlert, hideConfirmationModal, dispatch, t);
      },
      secondaryAction: () => hideConfirmationModal(),
    } as any);
    showConfirmationModal();
  };

  /**
   * Status bar component
   * @returns  {JSX.Element}
   */
  const renderStatusBar = () => (
    <Row style={{ justifyContent: "space-between", flexWrap: "wrap", gap: "24px" }}>
      <Column>
        <Label>{t("pages.configurations.businessTab.businessListPage.status")}</Label>
        {renderGeneralStatus(businessesStatus.data as BusinessStatusEnum, t)}
      </Column>
      <div style={{ maxWidth: 500, display: "flex", flexDirection: "row" }}>
        {!getDisabledButtons() && (
          <>
            <div style={{ marginRight: 16 }} id={"check-last-version"}>
              <FastActionButton
                iconComponent={
                  <IconContainer size={16} color={styleTheme.palette.successGreen}>
                    <CheckIcon />
                  </IconContainer>
                }
                onClick={() => showLastVersionModal()}
                label={t("pages.configurations.businessTab.businessListPage.checkLastVersion")}
              />
            </div>
            <div id={"revert-to-last-version"}>
              <FastActionButton
                iconComponent={
                  <IconContainer size={16} color={styleTheme.palette.successGreen}>
                    <UndoIcon />
                  </IconContainer>
                }
                onClick={revertLastVersion}
                label={t("pages.configurations.businessTab.businessListPage.revertChanges")}
              />
            </div>
          </>
        )}
      </div>
    </Row>
  );

  const getDisabledButtons = () => {
    return (businessesStatus.data as string) === BusinessStatusEnum.LIVE.toString();
  };

  return (
    <PageContainer>
      <PageTitle
        breadcrumbs={getBreadcrumbs(t)}
        title={t("pages.business.title")}
        goBackFn={() => window.history.back()}
        separatorStyle={{ display: "none" }}
        navigateFunction={history.push}
        titleContainerStyle={{ marginTop: "13px", marginBottom: "34px" }}
        iconContainerStyle={{ stroke: themeContext.palette.vodafoneRed }}
        rightComponentContainerStyle={{ display: "flex", alignContent: "center", height: "100%", marginRight: "0px" }}
        rightComponent={
          <>
            {canUserFromSFCMarketApproveBusinessChanges(userRole) && (
              <ButtonContainer
                style={{
                  position: "relative",
                  top: "10px",
                  display: getDisabledButtons() ? "none" : "",
                }}
              >
                <PrimaryButton
                  id="publish-changes"
                  titleLabel={t("pages.business.publishChanges")}
                  loading={loading}
                  type="submit"
                  redTheme={true}
                  onClick={() => {
                    if (getDisabledButtons()) {
                      return;
                    }
                    showPublishModal();
                  }}
                  style={{ width: "fit-content" }}
                />
              </ButtonContainer>
            )}
          </>
        }
      />
      {loading ? (
        <LoadingText>{t("commons.loadingResults")}</LoadingText>
      ) : (
        <>
          {showCategories ? (
            <Tabs controlledSelectedTab={Number(tabIdx) || 0} controlledSetSelectedTab={setTabIdx}>
              <TabChild label={t("pages.business.title")}>
                {renderStatusBar()}
                <BusinessList getNewData={() => getNewData(dispatch, showAlert, setAlertProps, t)} />
              </TabChild>
              <TabChild label={t("pages.business.categories")}>
                {renderStatusBar()}
                <BusinessCategoryList />
              </TabChild>
            </Tabs>
          ) : (
            <>
              {renderStatusBar()}
              <BusinessList getNewData={() => getNewData(dispatch, showAlert, setAlertProps, t)} />
            </>
          )}
        </>
      )}
    </PageContainer>
  );
};

export default BusinessPage;

const Label = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
  margin-bottom: 14px;
`;
const ButtonContainer = styled.div`
  width: fit-content;
`;
