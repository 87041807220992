import { ModalTypeEnum, PlusIcon, SearchBar, SmallButton, Table, useAlert, useModal } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemeContext } from "styled-components";
import { SearchBarFilterContainer } from "../../../../../shared/responsive-ui.styled";
import { RatioContainer, Row } from "../../../../../shared/shared.styled";

import AppPropertiesApi from "../app-properties.api";
import { IAppProperties, IAppProperty } from "../app-properties.model";
import { getColumns, renderAppPropertyQuickActions } from "../app-properties.utils";
import AddAppPropertyModal from "./add-app-property.modal";

interface IAppPropertiesListInterface {
  appProperties: IAppProperties;
  reloadAppProperties: () => void;
  appId: string;
}

/**
 * App properties list
 * @param param0
 */
const AppPropertiesList = ({ appProperties, reloadAppProperties, appId }: IAppPropertiesListInterface) => {
  const [t] = useTranslation();
  const themeContext = useContext(ThemeContext);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [showableAppProperties, setShowableAppProperties] = React.useState<IAppProperty[]>(
    appProperties.properties ? appProperties.properties : [],
  );
  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );
  const [showAlert, , setAlertProps] = useAlert();
  const [selectedAppProperty, setSelectedAppProperty] = React.useState<IAppProperty>();

  /*
   * Search
   * */
  React.useEffect(() => {
    let appPropertiesTemp = [...(appProperties.properties ? appProperties.properties : [])];

    if (!!searchQuery) {
      appPropertiesTemp = appPropertiesTemp.filter(
        appProp =>
          appProp.fieldName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          appProp.value.toLowerCase().includes(searchQuery.toLowerCase()),
      );
    }
    setShowableAppProperties(appPropertiesTemp);
  }, [appProperties, searchQuery]);

  /**
   * Delete app property
   * */
  const deleteAppProperty = (appProperty: IAppProperty) => {
    AppPropertiesApi.methods
      .deleteAppProperty(appProperty.id, appId)
      .then(
        () => {
          setAlertProps({
            title: t("pages.appProperties.deleteAppPropertySuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          reloadAppProperties();
        },
        () => {
          setAlertProps({
            title: t("pages.appProperties.deleteAppPropertyError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        hideConfirmationModal();
      });
  };

  /**
   * Delete app property handler
   * */
  const showDeleteConfirmation = (appProperty: IAppProperty) => {
    setConfirmationProps({
      title: t("pages.appProperties.deleteAppPropertyTitle"),
      description: t("pages.appProperties.deleteAppPropertyConfirmation", { property: appProperty.fieldName }),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      primaryAction: () => {
        deleteAppProperty(appProperty);
      },
      secondaryAction: () => hideConfirmationModal(),
    } as BaseModalProps);
    showConfirmationModal();
  };

  /**
   * Edit app property modal
   * */
  const _hideEditAppPropertyModal = () => {
    hideEditAppPropertyModal();
  };
  const [editAppPropertyModal, hideEditAppPropertyModal] = useModal(
    ModalTypeEnum.CustomModal,
    { modalStyles: { width: 500, overflow: "initial" } } as any,
    <AddAppPropertyModal
      selectedAppProperty={selectedAppProperty as IAppProperty}
      appProperties={appProperties.properties}
      dismissModal={_hideEditAppPropertyModal}
      reloadData={reloadAppProperties}
      appId={appId}
    />,
  );

  /**
   * Opens the edit app property modal
   * @param appProperty
   */
  const showEditAppPropertiesModal = (appProperty: IAppProperty) => {
    setSelectedAppProperty(appProperty);
    editAppPropertyModal();
  };

  /**
   * Add app property modal
   * */
  const _hideAddAppPropertyModal = () => {
    hideAddAppPropertyModal();
  };
  const [showAddAppPropertyModal, hideAddAppPropertyModal] = useModal(
    ModalTypeEnum.CustomModal,
    { modalStyles: { width: 615, padding: 0 } } as any,
    <AddAppPropertyModal
      selectedAppProperty={undefined}
      appProperties={appProperties.properties}
      dismissModal={_hideAddAppPropertyModal}
      reloadData={reloadAppProperties}
      appId={appId}
    />,
  );
  return (
    <>
      <AppPropertiesFilterContainer>
        <SearchBarFilterContainer>
          <SearchBar
            id={"search-bar"}
            placeholderLabel={t("pages.appProperties.searchPlaceholder")}
            maxLength={50}
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            clearValue={() => setSearchQuery("")}
          />
        </SearchBarFilterContainer>
        <div style={{ marginLeft: "auto" }} id={"add-new-property"}>
          <SmallButton
            titleLabel={t("pages.appProperties.addNewProperty")}
            onClick={showAddAppPropertyModal}
            iconComponent={
              <SmallButtonIconContainer color={styleTheme.palette.vodafoneRed}>
                <PlusIcon />
              </SmallButtonIconContainer>
            }
          />
        </div>
      </AppPropertiesFilterContainer>
      <Table<IAppProperty>
        values={showableAppProperties}
        displayActions={appProperty =>
          renderAppPropertyQuickActions<IAppProperty>(
            appProperty,
            {
              edit: { onClick: showEditAppPropertiesModal },
              delete: { onClick: showDeleteConfirmation },
            },
            themeContext,
          )
        }
        columns={getColumns()}
      />
    </>
  );
};
const AppPropertiesFilterContainer = styled(Row)`
  margin-bottom: 49px;
  margin-top: 41px;
  flex-wrap: wrap;
  gap: 24px;
  > ${RatioContainer} {
    margin-right: 24px;

    :last-of-type {
      margin-right: 0;
    }
  }
`;
const SmallButtonIconContainer = styled("div")<{ color: string }>`
  width: 16px;
  height: 16px;
  stroke: ${props => props.color};

  svg {
    width: 16px;
    height: 16px;
    stroke: ${props => props.color};
  }
`;

export default AppPropertiesList;
