import { MultipleOptionsDropdown, SearchBar } from "@wit/mpesa-ui-components";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { STATUSES } from "../pages/notifications-list.page";
import { useTranslation } from "react-i18next";

interface IProps {
  captureFilterData: (searchString: string, statusSelected: string[]) => void;
}

/**
 * Render filter inputs of the notifications list table
 * @returns {React.Component}
 */
const TableFilterInputsComponent = ({ captureFilterData }: IProps) => {
  const [t] = useTranslation();

  const [searchString, setSearchString] = useState("");
  const [statusSelected, setAppSelected] = useState<string[]>([]);
  function isOptionSelected(opt: SharedDropdownOption) {
    return statusSelected.includes(opt.key);
  }

  function toggleOption(opt: SharedDropdownOption) {
    if (isOptionSelected(opt)) {
      setAppSelected(statusSelected.filter(c => c !== opt.key));
    } else {
      setAppSelected([...statusSelected, opt.key]);
    }
  }

  function resetDropdown() {
    setAppSelected([]);
  }

  useEffect(() => {
    captureFilterData(searchString, statusSelected);
  }, [searchString, statusSelected]);

  return (
    <>
      <SearchBarWrapper>
        <SearchBar
          placeholderLabel={t("pages.externalServices.filters.placeholderSearch")}
          value={searchString}
          onChange={e => setSearchString(e.target.value)}
          clearValue={() => setSearchString("")}
        />
      </SearchBarWrapper>
      <DropdownWrapper>
        <MultipleOptionsDropdown
          label={t("pages.notifications.listPage.status")}
          toggleOption={opt => toggleOption(opt)}
          clearAllFilters={() => resetDropdown()}
          hasValue={false}
          isOptionSelected={opt => isOptionSelected(opt)}
          options={STATUSES}
        />
      </DropdownWrapper>
    </>
  );
};

export default TableFilterInputsComponent;

const SearchBarWrapper = styled.div`
  width: 35%;
  min-width: 220px;
  margin-right: 30px;
`;

const DropdownWrapper = styled.div`
  width: 20%;
  min-width: 100px;
`;
