import { SearchBar } from "@wit/mpesa-ui-components";
import { FilterFuncts } from "@wit/mpesa-ui-components/lib/hooks/use-filters.hook";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { SearchBarFilterContainer } from "../../../../shared/responsive-ui.styled";
import { Row } from "../../../../shared/shared.styled";

export enum GeneralCategoriesFilterEnum {
  SEARCH = "search",
}

/**
 * CategoriesFilters component
 */
const GeneralCategoriesFilters = ({ filters, clearFilter, updateFilter }: FilterFuncts) => {
  const [t] = useTranslation();

  return (
    <GeneralCategoriesFilterContainer>
      <SearchBarFilterContainer id={"search-categories"}>
        <SearchBar
          placeholderLabel={t("pages.generalCategories.searchPlaceholder")}
          value={
            filters.has(GeneralCategoriesFilterEnum.SEARCH)
              ? (filters.get(GeneralCategoriesFilterEnum.SEARCH) as string[])[0]
              : ""
          }
          onChange={e => {
            if (e.target.value) {
              updateFilter(GeneralCategoriesFilterEnum.SEARCH, e.target.value, true);
            } else {
              clearFilter(GeneralCategoriesFilterEnum.SEARCH);
            }
          }}
          clearValue={() => clearFilter(GeneralCategoriesFilterEnum.SEARCH)}
        />
      </SearchBarFilterContainer>
    </GeneralCategoriesFilterContainer>
  );
};

export default GeneralCategoriesFilters;

const GeneralCategoriesFilterContainer = styled(Row)`
  width: 100%;
`;
