import React, { useEffect } from "react";
import { ConfigContext } from "../../app.component";
import { getCorrectBackgroundImage } from "../../features/authentication/authentication.utils";

const maxIdx = 2;
const placeholderImageUrl = "./images/login/background-login-{{idx}}{{size}}.jpg";
const CHANGE_IMAGE_TIMEOUT = 15000;

function changeAttrsInImageUrl(imageUrl: string, idx: number): string {
  let newImageUrl = imageUrl.replace("{{idx}}", idx.toString());
  newImageUrl = getCorrectBackgroundImage(newImageUrl);
  return newImageUrl;
}

function useRandomBackground() {
  const { config } = React.useContext(ConfigContext);
  const [currentIdx, setCurrentIdx] = React.useState(1);
  const [imageUrl, setImageUrl] = React.useState(changeAttrsInImageUrl(placeholderImageUrl, 1));

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (config?.randomBackgroundDisabled) {
      setCurrentIdx(1);
    } else {
      intervalId = setInterval(() => {
        setCurrentIdx(c => {
          return c === maxIdx ? 1 : c + 1;
        });
      }, CHANGE_IMAGE_TIMEOUT);
    }

    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    setImageUrl(changeAttrsInImageUrl(placeholderImageUrl, currentIdx));
  }, [currentIdx]);

  return {
    imageUrl,
  };
}

export default useRandomBackground;
