import { FormSection, TextInput } from "@wit/mpesa-ui-components";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { IStoreInterface } from "../../../../configs/store.config";
import ColorPicker from "../../../../shared/components/color-picker.component";
import { ServiceManagerActions } from "../../service-manager.store";
import AllowedScopesApi from "../allowed-scopes.api";
import { AppTypeEnum, IAllowedScope, IIconAllowedScope } from "../allowed-scopes.model";
import { createBase64, getPastelColor, validateSchemaAllowedScopes } from "../allowed-scopes.utils";
import IconPicker from "./icon-picker.component";

interface IAllowedScopeForm {
  initialValues: IAllowedScope;
  isEditing: boolean;
  onSubmitFn: (values: IAllowedScope) => void;
  errorIds: string[];
}

/**
 * AllowedScopeForm component
 */
const AllowedScopeForm = ({ initialValues, isEditing, onSubmitFn, errorIds }: IAllowedScopeForm) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const defaultColor = "#2fc56d";
  const [isLoadingIcons, setIsLoadingIcons] = React.useState(false);
  const { allowedScopesIcons } = useSelector((state: IStoreInterface) => state.serviceManagerReducer);

  /**
   * method to return the app type string (CONSUMER, BUSINESS, ALL APPS)
   */
  const getAppTypeLabel = (serviceType: AppTypeEnum[]) => {
    if (serviceType.length > 1) {
      return t("pages.serviceManager.allowedScopes.form.allApps");
    } else if (serviceType.length === 1 && serviceType[0] === AppTypeEnum.CONSUMER) {
      return t("pages.serviceManager.allowedScopes.form.consumer");
    } else if (serviceType.length === 1 && serviceType[0] === AppTypeEnum.ORG) {
      return t("pages.serviceManager.allowedScopes.form.business");
    } else {
      return t("pages.serviceManager.allowedScopes.form.appPlaceholder");
    }
  };

  /**
   * method to get the icons list
   */
  const getIcons = () => {
    AllowedScopesApi.methods.getAllowedScopesIcons().then(res => {
      dispatch(ServiceManagerActions.creators.fetchAllowedScopesIcons(res.data));
      setIsLoadingIcons(false);
    });
  };

  React.useEffect(() => {
    if (!allowedScopesIcons || (allowedScopesIcons && allowedScopesIcons.length === 0)) {
      getIcons();
    } else {
      setIsLoadingIcons(false);
    }
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitFn}
      validateOnChange={false}
      validateOnBlur={true}
      validationSchema={validateSchemaAllowedScopes}
      render={({ values, handleChange, setFieldValue, handleSubmit, errors, resetForm }) => (
        <form id="allowed-scope-form" onSubmit={handleSubmit} onReset={() => resetForm()} style={{ width: "60%" }}>
          <FormContainer>
            <FormSection
              isEditing={isEditing}
              title={t("pages.serviceManager.allowedScopes.form.ssoDetails")}
              rows={[
                {
                  label: (
                    <FormLabel id="allowed-scope-name" required={isEditing}>
                      {t("pages.serviceManager.allowedScopes.form.friendlyName")}
                    </FormLabel>
                  ),
                  displayComponent: <NonEditableText id="allowed-scope-name-value">{values.name}</NonEditableText>,
                  editingComponent: (
                    <TextInput
                      id="allowed-scope-name-input"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      placeholder={t("pages.serviceManager.allowedScopes.form.friendlyNamePlaceholder")}
                      error={errors.name}
                      maxLength={100}
                    />
                  ),
                  required: true,
                },
                {
                  label: (
                    <FormLabel id="allowed-scope-scope" required={false}>
                      {t("pages.serviceManager.allowedScopes.form.ssoScope")}
                    </FormLabel>
                  ),
                  displayComponent: <NonEditableText id="allowed-scope-scope-value">{values.scope}</NonEditableText>,
                  editingComponent: <NonEditableText id="allowed-scope-scope-value">{values.scope}</NonEditableText>,
                },
                {
                  label: (
                    <FormLabel id="allowed-scope-scope" required={false}>
                      {t("pages.serviceManager.allowedScopes.form.app")}
                    </FormLabel>
                  ),
                  displayComponent: (
                    <>
                      {values.serviceType.length > 0 ? (
                        <ItemAppText app={values.serviceType[0]}>{getAppTypeLabel(values.serviceType)}</ItemAppText>
                      ) : null}
                    </>
                  ),
                  editingComponent: (
                    <>
                      {values.serviceType.length > 0 ? (
                        <ItemAppText app={values.serviceType[0]}>{getAppTypeLabel(values.serviceType)}</ItemAppText>
                      ) : null}
                    </>
                  ),
                },
                {
                  label: (
                    <FormLabel id="allowed-scope-color" required={isEditing}>
                      {t("pages.serviceManager.allowedScopes.form.iconColor")}
                    </FormLabel>
                  ),
                  displayComponent: (
                    <NonEditableContainer>
                      <NonEditableColor color={values.iconColor} />
                      <NonEditableText id="color-value">{values.iconColor}</NonEditableText>
                    </NonEditableContainer>
                  ),
                  editingComponent: (
                    <ColorPickerContainer>
                      <ColorPicker
                        color={values.iconColor ? values.iconColor : defaultColor}
                        setColor={(name, color) => {
                          setFieldValue(name, color);
                          setFieldValue("iconBackgroundColor", getPastelColor(color));
                        }}
                        name={"iconColor"}
                        error={errors.iconColor}
                        isCustomizable={false}
                      />
                    </ColorPickerContainer>
                  ),
                  required: true,
                },
                {
                  label: (
                    <FormLabel id="category-color" required={isEditing}>
                      {t("pages.serviceManager.allowedScopes.form.icon")}
                    </FormLabel>
                  ),
                  displayComponent: (
                    <NonEditableContainer>
                      <IconNotEditableContainer backgroundColor={values.iconBackgroundColor}>
                        <IconNotEditableMask
                          icon={
                            allowedScopesIcons &&
                            allowedScopesIcons.find((icon: any) => icon.id === values.iconId) &&
                            (allowedScopesIcons.find((icon: any) => icon.id === values.iconId) as IIconAllowedScope)
                              .content
                              ? createBase64(
                                  allowedScopesIcons.find(
                                    (icon: any) => icon.id === values.iconId,
                                  ) as IIconAllowedScope,
                                )
                              : null
                          }
                          color={values.iconColor}
                        />
                      </IconNotEditableContainer>
                      <NonEditableText>
                        {allowedScopesIcons &&
                        allowedScopesIcons.find((icon: any) => icon.id === values.iconId) &&
                        (allowedScopesIcons.find((icon: any) => icon.id === values.iconId) as IIconAllowedScope).name
                          ? (allowedScopesIcons.find((icon: any) => icon.id === values.iconId) as IIconAllowedScope)
                              .name
                          : t("pages.serviceManager.allowedScopes.form.defaultIconName")}
                      </NonEditableText>
                    </NonEditableContainer>
                  ),
                  editingComponent: (
                    <IconPickerContainer>
                      <IconPicker
                        icons={allowedScopesIcons}
                        icon={
                          allowedScopesIcons && allowedScopesIcons.find((icon: any) => icon.id === values.iconId)
                            ? allowedScopesIcons.find((icon: any) => icon.id === values.iconId)
                            : undefined
                        }
                        name="iconId"
                        setIconID={setFieldValue}
                        color={values.iconColor ? values.iconColor : defaultColor}
                        backgroundColor={values.iconColor ? getPastelColor(values.iconColor) : defaultColor}
                        error={errors.iconId}
                        isLoading={isLoadingIcons}
                      ></IconPicker>
                    </IconPickerContainer>
                  ),
                  required: true,
                },
              ]}
            ></FormSection>
          </FormContainer>
        </form>
      )}
    />
  );
};

export default AllowedScopeForm;

const FormContainer = styled("div")`
  width: 100%;
`;

const FormLabel = styled("span")<{ required?: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.greyDarker};

  ${props =>
    props.required
      ? `
  ::after {
    content: ' *';
    font-family: Vodafone Rg;
    font-size: 16px;
    font-weight: normal;
    color: ${props.theme.palette.errorRed};
  }`
      : ""}
`;

const NonEditableText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.darkGrey};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ColorPickerContainer = styled("div")`
  width: 160px;
`;

const NonEditableContainer = styled("div")`
  display: flex;
  align-items: center;
`;

const NonEditableColor = styled("div")<{ color: string }>`
  width: 36px;
  height: 36px;
  margin: 0 11px 0 0;
  padding: 1px;
  border-radius: 6px;
  border: solid 1px ${props => props.theme.palette.aluminium};
  background-color: ${props => (props.color ? props.color : "white")};
`;

const IconPickerContainer = styled("div")`
  > button {
    > div {
      width: -webkit-fill-available;
    }
  }
`;

const ItemAppText = styled("span")<{
  app: AppTypeEnum;
}>`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props =>
    props.app === AppTypeEnum.CONSUMER ? props.theme.palette.turquoiseBlue : props.theme.palette.redViolet};
  text-transform: capitalize;
`;

const IconNotEditableContainer = styled("div")<{
  backgroundColor: string;
}>`
  background-color: ${props => (props.backgroundColor ? `${props.backgroundColor}` : "#ffffff")};
  width: 36px;
  height: 36px;
  margin: 0 11px 0 0;
  border-radius: 6px;
  border: solid 1px ${props => props.theme.palette.aluminium};
`;

const IconNotEditableMask = styled("div")<{
  color: string;
  icon: string | null;
}>`
  mask-image: ${props => (props.icon ? `url("${props.icon}")` : "none")};
  mask-repeat: no-repeat;
  mask-size: 24px 24px;
  mask-position: center;
  background-color: ${props => (props.color ? `${props.color}` : "#ffffff")};
  width: 100%;
  height: 100%;
  border-radius: 6px;
  border: solid 1px ${props => props.theme.palette.aluminium};
`;
