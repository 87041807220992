import { CancelIcon, CheckIcon } from "@wit/mpesa-ui-components";
import React from "react";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { IDocStatusItemProps } from "../doc-validation-details.interface";

const DocStatusItem = ({ status }: IDocStatusItemProps) => {
  return (
    <>
      {status === "MOVE_ON" ? (
        <CheckIcon />
      ) : status !== undefined ? (
        <CancelIcon style={{ stroke: styleTheme.palette.vodafoneRed }} />
      ) : (
        ""
      )}
    </>
  );
};

export default DocStatusItem;
