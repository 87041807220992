import React from "react";

const MiniAppsIcon = (props: React.HTMLProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.803 13.846c.661 0 1.197.536 1.197 1.197v3.76c0 .661-.536 1.197-1.197 1.197h-3.76a1.197 1.197 0 0 1-1.197-1.197v-3.76c0-.66.536-1.197 1.197-1.197h3.76zm-9.846 0c.66 0 1.197.536 1.197 1.197v3.76c0 .661-.536 1.197-1.197 1.197h-3.76A1.197 1.197 0 0 1 4 18.803v-3.76c0-.66.536-1.197 1.197-1.197h3.76zM18.803 4C19.464 4 20 4.536 20 5.197v3.76c0 .66-.536 1.197-1.197 1.197h-3.76a1.197 1.197 0 0 1-1.197-1.197v-3.76c0-.661.536-1.197 1.197-1.197h3.76zM8.957 4c.66 0 1.197.536 1.197 1.197v3.76c0 .66-.536 1.197-1.197 1.197h-3.76A1.197 1.197 0 0 1 4 8.957v-3.76C4 4.536 4.536 4 5.197 4h3.76z"
        stroke="#090"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MiniAppsIcon;
