import { AutoClosingDropdown, SearchBar } from "@wit/mpesa-ui-components";
import { FilterFuncts } from "@wit/mpesa-ui-components/lib/hooks/use-filters.hook";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReferralCampaignStatus } from "../referral-campaigns.model";
import { Row } from "../../../../shared/shared.styled";
import { OtherFiltersContainer, SearchBarFilterContainer } from "../../../../shared/responsive-ui.styled";

export enum ReferralCampaignFilterEnum {
  SEARCH = "search",
  STATUS = "status",
}

/**
 * ReferralCampaignsFilter component
 */
const ReferralCampaignsFilters = ({
  filters,
  clearFilter,
  isFilterActive,
  resetFilters,
  updateFilter,
}: FilterFuncts) => {
  const [t] = useTranslation();

  /**
   * Returns the referral campaigns status options to be used on the status filter
   */
  const getStatusOptions = () => {
    const status: {
      label: string;
      key: ReferralCampaignStatus | undefined;
    }[] = Object.values(ReferralCampaignStatus).map(status => ({
      label: t(`pages.referralCampaigns.table.statusEnum.${status.toLowerCase()}`),
      key: status,
    }));
    status.push({
      label: t(`commons.apps.enums.platform.seeAll`),
      key: undefined,
    });
    return status;
  };

  return (
    <ReferralCampaignsFilterContainer>
      <SearchBarFilterContainer id={"search-referral-campaigns"}>
        <SearchBar
          placeholderLabel={t("pages.referralCampaigns.searchPlaceholder")}
          value={
            filters.has(ReferralCampaignFilterEnum.SEARCH)
              ? (filters.get(ReferralCampaignFilterEnum.SEARCH) as string[])[0]
              : ""
          }
          onChange={e => {
            if (e.target.value) {
              updateFilter(ReferralCampaignFilterEnum.SEARCH, e.target.value, true);
            } else {
              clearFilter(ReferralCampaignFilterEnum.SEARCH);
            }
          }}
          clearValue={() => clearFilter(ReferralCampaignFilterEnum.SEARCH)}
        />
      </SearchBarFilterContainer>

      <OtherFiltersContainer id={"referral-campaigns-status-dropdown"}>
        <AutoClosingDropdown
          label={
            filters.has(ReferralCampaignFilterEnum.STATUS)
              ? t(
                  `pages.referralCampaigns.table.statusEnum.${(filters.get(
                    ReferralCampaignFilterEnum.STATUS,
                  ) as string[])[0].toLocaleLowerCase()}`,
                )
              : t("pages.referralCampaigns.table.headers.status")
          }
          options={getStatusOptions()}
          selectOption={val => {
            if (val.key) {
              updateFilter(ReferralCampaignFilterEnum.STATUS, val.key, true);
            } else {
              clearFilter(ReferralCampaignFilterEnum.STATUS);
            }
          }}
          hasValue={filters.has(ReferralCampaignFilterEnum.STATUS)}
        />
      </OtherFiltersContainer>
    </ReferralCampaignsFilterContainer>
  );
};

export default ReferralCampaignsFilters;

const ReferralCampaignsFilterContainer = styled(Row)`
  flex-wrap: wrap;
  width: 100%;
  gap: 24px;
`;
