import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import React from "react";
import styled from "styled-components";
import { isEqual } from "lodash";
import { TFunction } from "react-i18next";
import { VersionItem, VersionItemStatusEnum } from "../../../shared/hooks/use-version-diff/use-version-diff.hook";
import VersionItemStatusChip from "../../../shared/hooks/use-version-diff/version-item-status-chip.component";
import { Column, Row } from "../../../shared/shared.styled";
import { StatusText, VersionLabel } from "./components/check-language-translations-last-version.modal";
import { ILanguage } from "../../../shared/models/language.model";
import {
  LanguageTranslationsVersionsFiltersEnum,
  LanguageTranslationsVersionStatusOptionsEnum,
} from "./components/language-translations-versions-filter.component";
import {
  ILanguageTranslation,
  ILanguageTranslationsVersion,
  LanguageTranslationStatusEnum,
} from "./language-translations.model";
import { IOrderedTranslations } from "./components/publish-language-translations.modal";

/**
 * Filter an array of language translations versions by status
 * @param {ILanguageTranslationsVersion[]} languageTranslationsVersion
 * @param {string} status
 * @returns {ILanguageTranslationsVersion[]}
 */
export const filterLanguageTranslationsVersionByStatus = (
  languageTranslationsVersion: ILanguageTranslationsVersion[],
  status?: string,
): ILanguageTranslationsVersion[] => {
  if (status) {
    return languageTranslationsVersion.filter(c => {
      if (c.submittedForApproval && status === LanguageTranslationsVersionStatusOptionsEnum.SUBMITTED_FOR_APPROVAL) {
        return c;
      } else if (
        !c.submittedForApproval &&
        (c.status === LanguageTranslationStatusEnum.LIVE || c.status === LanguageTranslationStatusEnum.EDIT) &&
        status === LanguageTranslationsVersionStatusOptionsEnum.LIVE
      ) {
        return c;
      }
    });
  }
  return languageTranslationsVersion;
};

/**
 * Filter an array of language translations versions by using a search string
 * @param {ILanguageTranslationsVersion[]} languageTranslationsVersions
 * @param {string} searchString
 * @returns {ILanguageTranslationsVersion[]}
 */
export const filterLanguageTranslationsVersionsBySearchString = (
  languageTranslationsVersions: ILanguageTranslationsVersion[],
  searchString: string,
) => {
  if (!!searchString) {
    return languageTranslationsVersions.filter(dc => dc.version.toLowerCase().includes(searchString.toLowerCase()));
  }
  return languageTranslationsVersions;
};

/**
 * Filter an array of language translations versions
 */
export const filterLanguageTranslationsVersions = (
  languageTranslationsVersion: ILanguageTranslationsVersion[],
  filters: Map<string, string[]>,
): ILanguageTranslationsVersion[] => {
  let newLanguageTranslationsVersion: ILanguageTranslationsVersion[];
  const status = filters.get(LanguageTranslationsVersionsFiltersEnum.STATUS) as any;
  const search = filters.get(LanguageTranslationsVersionsFiltersEnum.SEARCH);

  newLanguageTranslationsVersion = filterLanguageTranslationsVersionByStatus(
    languageTranslationsVersion,
    status ? status[0] : undefined,
  );

  if (search) {
    newLanguageTranslationsVersion = filterLanguageTranslationsVersionsBySearchString(
      newLanguageTranslationsVersion,
      search[0],
    );
  }

  return newLanguageTranslationsVersion;
};

/**
 * This is a function to be used on getOrderedTranslations()
 * in order to sort the strings on publish and approve
 * @parentFunction getOrderedTranslations()
 * @param a: IOrderedTranslations
 * @param b: IOrderedTranslations
 * @returns number
 */
const compareStrings = (a: IOrderedTranslations, b: IOrderedTranslations) => {
  if (a.key.toLocaleLowerCase() < b.key.toLocaleLowerCase()) {
    return -1;
  }

  if (a.key.toLocaleLowerCase() > b.key.toLocaleLowerCase()) {
    return 1;
  }

  return 0;
};

/**
 * Orders prev and current translations in the following sequence:
 * [Added], [Updated], [Removed] and in last [Not changed]
 * @param prev: IOrderedTranslations[]
 * @param current: IOrderedTranslations[]
 * @returns { previousTranslations, currentTranslations }
 */
export const getOrderedTranslations = (prev: IOrderedTranslations[], current: IOrderedTranslations[]) => {
  let toAdd: { [key: string]: IOrderedTranslations[] } = { prev: [], curr: [] };
  let toRemove: { [key: string]: IOrderedTranslations[] } = { prev: [], curr: [] };
  let toUpdate: { [key: string]: IOrderedTranslations[] } = { prev: [], curr: [] };
  let noChange: IOrderedTranslations[] = [];

  current.forEach(current => {
    const filtered = prev.filter(previous => current.key === previous.key)[0];

    if (
      isEqual(current, filtered) &&
      current.status === LanguageTranslationStatusEnum.LIVE &&
      current.status === filtered.status
    ) {
      noChange.push(filtered);
      return;
    }

    if (current.status === LanguageTranslationStatusEnum.DELETE) {
      if (!filtered) {
        if (current.key) {
          toRemove.curr.push({ ...current });
          toRemove.prev.push({
            ...current,
            key: "",
            translations: {},
          });
        }
      } else {
        if (current.key !== filtered.key) {
          toRemove.curr.push({ ...current });
        } else {
          toRemove.prev.push({ ...filtered });
          toRemove.curr.push({
            ...filtered,
            versionItemStatus: VersionItemStatusEnum.UPDATED,
            status: LanguageTranslationStatusEnum.DELETE,
          });
        }
      }
      return;
    }

    if (current.status === LanguageTranslationStatusEnum.EDIT) {
      if (!filtered) {
        if (current.key) {
          toAdd.curr.push({
            ...current,
            status: LanguageTranslationStatusEnum.LIVE,
          });
          toAdd.prev.push({
            ...current,
            key: "",
            translations: {},
          });
        }
      } else if (current.key === filtered.key) {
        toUpdate.curr.push({ ...current });
        toUpdate.prev.push({ ...filtered });
      }
    }
  });

  return {
    previousTranslations: [
      ...toAdd.prev.sort(compareStrings),
      ...toUpdate.prev.sort(compareStrings),
      ...toRemove.prev.sort(compareStrings),
      ...noChange.sort(compareStrings),
    ],
    currentTranslations: [
      ...toAdd.curr.sort(compareStrings),
      ...toUpdate.curr.sort(compareStrings),
      ...toRemove.curr.sort(compareStrings),
      ...noChange.sort(compareStrings),
    ],
  };
};

/**
 * selectedLanguageEvaluator - Checks if on the changes were made on only one language or not,
 * if it verifies that the changes were made on one language only, it will set and show
 * as initial language not english(non default language) on modal right-top corner
 * @param pastTranslations: ILanguageTranslation[]
 * @param futureTranslations: ILanguageTranslation[]
 * @param availableLanguages: ILanguage[]
 */
export const selectedLanguageEvaluator = (
  pastTranslations: ILanguageTranslation[],
  futureTranslations: ILanguageTranslation[],
  availableLanguages: ILanguage[],
) => {
  let interfaceLanguages: string[] = [];
  let affectedColumnLanguage: string[] = [];
  const newTranslations = futureTranslations.filter(
    (ft: IOrderedTranslations) => ft.versionItemStatus?.toLocaleLowerCase() === "added",
  );
  const updatedTranslations = futureTranslations.filter(
    (ft: IOrderedTranslations) => ft.versionItemStatus?.toLocaleLowerCase() === "updated",
  );

  if (newTranslations.length <= 0 && updatedTranslations.length >= 1) {
    updatedTranslations.forEach((ut: IOrderedTranslations) => {
      if (interfaceLanguages.length <= 0) {
        interfaceLanguages.push(...Object.keys(ut.translations));
      }

      const prevTranslation = pastTranslations.filter((pt: IOrderedTranslations) => pt.key === ut.key);
      interfaceLanguages.forEach(il => {
        if (ut.translations[il] !== prevTranslation[0].translations[il]) {
          if (!affectedColumnLanguage.find(acl => acl === il)) {
            affectedColumnLanguage.push(il);
          }
        }
      });
    });
  }

  if (affectedColumnLanguage.length === 1) {
    const lang: any = availableLanguages.filter(al => al.code === affectedColumnLanguage[0])[0];
    return {
      key: affectedColumnLanguage[0],
      label: lang.name ?? lang.label,
    };
  }

  return {
    key: availableLanguages[0].code,
    label: availableLanguages[0].name,
  };
};

/**
 * Render check last/current version row
 * @param languageTranslation
 * @param t
 * @param selectedLanguage
 * @param hasBackgroundColor: boolean
 * @param isCurrentLanguageTranslation: boolean
 */
export const renderVersionRow = (
  languageTranslation: VersionItem<ILanguageTranslation>,
  t: TFunction<"translation", undefined>,
  selectedLanguage: string,
  hasBackgroundColor?: boolean,
  isCurrentLanguageTranslation?: boolean,
) => {
  return (
    <Translation background={hasBackgroundColor}>
      <Row key={languageTranslation.id} style={{ marginBottom: "0", alignItems: "flex-start" }}>
        <Column style={{ paddingRight: "8px" }}>
          <VersionLabel style={{ wordBreak: "break-word" }}>
            <b>{languageTranslation.key}</b>
            {!!!languageTranslation.key ? null : (
              <VersionItemStatusChip status={languageTranslation.versionItemStatus} />
            )}
          </VersionLabel>
          <VersionLabel style={{ wordBreak: "break-word" }}>
            {languageTranslation.translations[selectedLanguage]}
          </VersionLabel>
        </Column>

        <Column style={{ flexGrow: 1, alignItems: "flex-end", paddingTop: "5px", textAlign: "right" }}>
          {!!!languageTranslation.key && !isCurrentLanguageTranslation ? null : (
            <StatusText status={languageTranslation.status as LanguageTranslationStatusEnum}>
              {t(`pages.languageTranslations.languageTranslationsStatusEnum.${languageTranslation.status}`)}
            </StatusText>
          )}
        </Column>
      </Row>
    </Translation>
  );
};

const Translation = styled("div")<{ background?: boolean }>`
  padding: 5px 24px 0 24px;
  margin: 0;
  background: ${props => (props.background ? props.theme.palette.lightGrey : props.theme.palette.white)};
  min-height: 68.5px;

  &:last-child {
    padding-bottom: 24px;
  }
`;
