import { useEffect, useState } from "react";
import PushNotificationsApi from "../push-notifications.api";
import { useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { NotificationsActions } from "../push-notifications.store";
import { useDispatch } from "react-redux";

/**
 * Custom hook for getting the notifications list
 */
const useGetNotificationsList = () => {
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showAlert, hideAlert, setAlertProps] = useAlert();

  const [notificationsList, setNotificationsList] = useState([]);
  const [isLoading, toggleIsLoading] = useState(false);

  /**
   * Get the list of notifications from the BE
   */
  const getNotifications = () => {
    toggleIsLoading(true);

    PushNotificationsApi.methods.getNotificationsAll().then(
      res => {
        setNotificationsList(res.data);
        dispatch(NotificationsActions.creators.fetchNotificationsSuccess(res.data));

        toggleIsLoading(false);
      },
      () => {
        setAlertProps({
          title: "Error loading notifications!",
          type: AlertTypeEnum.ERROR,
        });
        showAlert();

        toggleIsLoading(false);
      },
    );
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return { notificationsList, isLoading };
};

export default useGetNotificationsList;
