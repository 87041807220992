import { produce } from "immer";
import { IPayloadAction } from "../../../shared/shared.interfaces";
import { IFormCategoriesResponse, IFormDataResponse, IFormDetailsResponse } from "./feedback-and-problems.model";

export interface IFeedbackAndProblemsReducerInterface {
  feedbacks: IFormDataResponse;
  feedbackDetails: IFormDetailsResponse[];
  feedbackCategories: IFormCategoriesResponse[];
  customerSurvey: IFormDataResponse;
  problemReports: IFormDataResponse;
  problemReportsDetails: IFormDetailsResponse[];
  problemReportsCategories: IFormCategoriesResponse[];
}

const initialState: IFeedbackAndProblemsReducerInterface = {
  feedbacks: {} as IFormDataResponse,
  feedbackDetails: [] as IFormDetailsResponse[],
  feedbackCategories: [] as IFormCategoriesResponse[],
  customerSurvey: {} as IFormDataResponse,
  problemReports: {} as IFormDataResponse,
  problemReportsDetails: [] as IFormDetailsResponse[],
  problemReportsCategories: [] as IFormCategoriesResponse[],
};

export const FeedbackAndProblemsStoreActions = {
  types: {
    CREATING_FEEDBACK_CATEGORIES_DATA: "CREATING_FEEDBACK_CATEGORIES_DATA",
    CREATING_PROBLEM_CATEGORIES_DATA: "CREATING_PROBLEM_CATEGORIES_DATA",
    FETCHING_FEEDBACK_CATEGORIES_DATA: "FETCHING_FEEDBACK_CATEGORIES_DATA",
    FETCHING_PROBLEM_CATEGORIES_DATA: "FETCHING_PROBLEM_CATEGORIES_DATA",
    FETCHING_FEEDBACK_DETAILS_DATA: "FETCHING_FEEDBACK_DETAILS_DATA",
    FETCHING_PROBLEM_DETAILS_DATA: "FETCHING_PROBLEM_DETAILS_DATA",
    FETCHING_FEEDBACK_CATEGORIES_OK: "FETCHING_FEEDBACK_CATEGORIES_OK",
    FETCHING_PROBLEM_CATEGORIES_OK: "FETCHING_PROBLEM_CATEGORIES_OK",
    FETCHING_FEEDBACK_DETAILS_OK: "FETCHING_FEEDBACK_DETAILS_OK",
    FETCHING_PROBLEM_DETAILS_OK: "FETCHING_PROBLEM_DETAILS_OK",
    FETCHING_FEEDBACKS_DATA: "FETCHING_FEEDBACKS_DATA",
    FETCHING_PROBLEMS_DATA: "FETCHING_PROBLEMS_DATA",
    FETCHING_FEEDBACKS_OK: "FETCHING_FEEDBACKS_OK",
    FETCHING_PROBLEMS_OK: "FETCHING_PROBLEMS_OK",
    FETCHING_CUSTOMER_DATA: "FETCHING_CUSTOMER_DATA",
    FETCHING_CUSTOMER_OK: "FETCHING_CUSTOMER_OK",
    UPDATING_FEEDBACKS_DATA: "UPDATING_FEEDBACKS_DATA",
    UPDATING_PROBLEMS_DATA: "UPDATING_PROBLEMS_DATA",
    UPDATING_FEEDBACKS_OK: "UPDATING_FEEDBACKS_OK",
    UPDATING_PROBLEMS_OK: "UPDATING_PROBLEMS_OK",
    DOWNLOAD_FEEDBACKS_DATA_FILE: "DOWNLOAD_FEEDBACKS_DATA_FILE",
    DOWNLOAD_PROBLEMS_DATA_FILE: "DOWNLOAD_PROBLEMS_DATA_FILE",
    DOWNLOAD_CUSTOMER_DATA_FILE: "DOWNLOAD_CUSTOMER_DATA_FILE",
  },
  creators: {
    updateProblemsData: () => ({
      type: FeedbackAndProblemsStoreActions.types.UPDATING_PROBLEMS_DATA,
    }),
    updateFeedbacksData: () => ({
      type: FeedbackAndProblemsStoreActions.types.UPDATING_FEEDBACKS_DATA,
    }),
    setFeedbacksData: (data: IFormDataResponse) => ({
      type: FeedbackAndProblemsStoreActions.types.FETCHING_FEEDBACKS_OK,
      payload: {
        ...data,
      },
    }),
    setProblemsData: (data: IFormDataResponse) => ({
      type: FeedbackAndProblemsStoreActions.types.FETCHING_PROBLEMS_OK,
      payload: {
        ...data,
      },
    }),
    setCustomerSurveyData: (data: IFormDataResponse) => ({
      type: FeedbackAndProblemsStoreActions.types.FETCHING_CUSTOMER_OK,
      payload: {
        ...data,
      },
    }),
    setFeedbackCategoriesData: (data: IFormCategoriesResponse[]) => ({
      type: FeedbackAndProblemsStoreActions.types.FETCHING_FEEDBACK_CATEGORIES_OK,
      payload: [...data],
    }),
    setProblemCategoriesData: (data: IFormCategoriesResponse[]) => ({
      type: FeedbackAndProblemsStoreActions.types.FETCHING_PROBLEM_CATEGORIES_OK,
      payload: [...data],
    }),
    setFeedbackDetailsData: (data: IFormDetailsResponse[]) => ({
      type: FeedbackAndProblemsStoreActions.types.FETCHING_FEEDBACK_DETAILS_OK,
      payload: data,
    }),
    setProblemDetailsData: (data: IFormDetailsResponse[]) => ({
      type: FeedbackAndProblemsStoreActions.types.FETCHING_PROBLEM_DETAILS_OK,
      payload: data,
    }),
    downloadFeedbacksReportFile: () => ({
      type: FeedbackAndProblemsStoreActions.types.DOWNLOAD_FEEDBACKS_DATA_FILE,
    }),
    downloadProblemsReportFile: () => ({
      type: FeedbackAndProblemsStoreActions.types.DOWNLOAD_PROBLEMS_DATA_FILE,
    }),
    downloadCustomerSurveyFile: () => ({
      type: FeedbackAndProblemsStoreActions.types.DOWNLOAD_CUSTOMER_DATA_FILE,
    }),
    creatingFeedbacksCategory: () => ({
      type: FeedbackAndProblemsStoreActions.types.CREATING_FEEDBACK_CATEGORIES_DATA,
    }),
    creatingProblemsReportCategory: () => ({
      type: FeedbackAndProblemsStoreActions.types.CREATING_PROBLEM_CATEGORIES_DATA,
    }),
    getFeedbacksData: () => ({
      type: FeedbackAndProblemsStoreActions.types.FETCHING_FEEDBACKS_DATA,
    }),
    getProblemsData: () => ({
      type: FeedbackAndProblemsStoreActions.types.FETCHING_PROBLEMS_DATA,
    }),
    getCustomerSurveyData: () => ({
      type: FeedbackAndProblemsStoreActions.types.FETCHING_CUSTOMER_DATA,
    }),
    getFeedbackDetailsData: () => ({
      type: FeedbackAndProblemsStoreActions.types.FETCHING_FEEDBACK_DETAILS_DATA,
    }),
    getProblemsDetailsData: () => ({
      type: FeedbackAndProblemsStoreActions.types.FETCHING_PROBLEM_DETAILS_DATA,
    }),
    getFeedbackCategoriesData: () => ({
      type: FeedbackAndProblemsStoreActions.types.FETCHING_FEEDBACK_CATEGORIES_DATA,
    }),
    getProblemsCategoriesData: () => ({
      type: FeedbackAndProblemsStoreActions.types.FETCHING_PROBLEM_CATEGORIES_DATA,
    }),
  },
};

export const feedbackAndProblemsReportReducer = produce(
  (draft: IFeedbackAndProblemsReducerInterface, action: IPayloadAction<any>) => {
    switch (action.type) {
      case FeedbackAndProblemsStoreActions.types.DOWNLOAD_FEEDBACKS_DATA_FILE:
      case FeedbackAndProblemsStoreActions.types.DOWNLOAD_PROBLEMS_DATA_FILE:
      case FeedbackAndProblemsStoreActions.types.DOWNLOAD_CUSTOMER_DATA_FILE:
      case FeedbackAndProblemsStoreActions.types.FETCHING_FEEDBACKS_DATA:
      case FeedbackAndProblemsStoreActions.types.FETCHING_CUSTOMER_DATA:
      case FeedbackAndProblemsStoreActions.types.FETCHING_PROBLEMS_DATA:
      case FeedbackAndProblemsStoreActions.types.FETCHING_FEEDBACK_CATEGORIES_DATA:
      case FeedbackAndProblemsStoreActions.types.FETCHING_PROBLEM_CATEGORIES_DATA:
      case FeedbackAndProblemsStoreActions.types.FETCHING_FEEDBACK_DETAILS_DATA:
      case FeedbackAndProblemsStoreActions.types.FETCHING_PROBLEM_DETAILS_DATA:
      case FeedbackAndProblemsStoreActions.types.UPDATING_FEEDBACKS_DATA:
      case FeedbackAndProblemsStoreActions.types.CREATING_FEEDBACK_CATEGORIES_DATA:
      case FeedbackAndProblemsStoreActions.types.CREATING_PROBLEM_CATEGORIES_DATA:
      case FeedbackAndProblemsStoreActions.types.UPDATING_PROBLEMS_DATA:
      case FeedbackAndProblemsStoreActions.types.UPDATING_FEEDBACKS_OK:
      case FeedbackAndProblemsStoreActions.types.UPDATING_PROBLEMS_OK:
        return;

      case FeedbackAndProblemsStoreActions.types.FETCHING_FEEDBACKS_OK:
        draft.feedbacks = action.payload as IFormDataResponse;
        return;
      case FeedbackAndProblemsStoreActions.types.FETCHING_CUSTOMER_OK:
        draft.customerSurvey = action.payload as IFormDataResponse;
        return;
      case FeedbackAndProblemsStoreActions.types.FETCHING_FEEDBACK_DETAILS_OK:
        draft.feedbackDetails = action.payload as IFormDetailsResponse[];
        return;
      case FeedbackAndProblemsStoreActions.types.FETCHING_FEEDBACK_CATEGORIES_OK:
        draft.feedbackCategories = action.payload as IFormCategoriesResponse[];
        return;
      case FeedbackAndProblemsStoreActions.types.FETCHING_PROBLEMS_OK:
        draft.problemReports = action.payload as IFormDataResponse;
        return;
      case FeedbackAndProblemsStoreActions.types.FETCHING_PROBLEM_DETAILS_OK:
        draft.problemReportsDetails = action.payload as IFormDetailsResponse[];
        return;
      case FeedbackAndProblemsStoreActions.types.FETCHING_PROBLEM_CATEGORIES_OK:
        draft.problemReportsCategories = action.payload as IFormCategoriesResponse[];
        return;
      default:
        return draft;
    }
  },
  initialState,
);
