import React, { useState } from "react";
import styled from "styled-components";
import { PageContainer } from "../../../shared/shared.styled";
import { PrimaryButton } from "@wit/mpesa-ui-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { NOTIFICATION_STATUSES } from "../push-notifications.modal";
import { RoutesEnum } from "../../../routes/routes.constants";
import useFilterTable from "../hooks/use-filter-table";
import NotificationsListTable from "../components/notifications-list-table.component";
import TableFilterInputsComponent from "../components/table-filter-inputs.component";
import useGetNotificationsList from "../hooks/use-get-notifications-list.hook";
import LoadingSpinner from "../components/spinner.component";
import { canUserFromSFCMarketAddOrEditOrDeleteNotification } from "../utils/check-user-oles.util";
import { useSelector } from "react-redux";
import { IStoreInterface } from "../../../configs/store.config";

export const STATUSES: SharedDropdownOption[] = [
  {
    key: NOTIFICATION_STATUSES.DRAFT,
    label: "Draft",
  },
  {
    key: NOTIFICATION_STATUSES.SCHEDULED,
    label: "Scheduled",
  },
  {
    key: NOTIFICATION_STATUSES.SENT,
    label: "Sent",
  },
];

/**
 * Renders notifications list table
 * @returns {React.Component}
 */
const SFCPushNotificationsListPage = () => {
  const userRole = useSelector((state: IStoreInterface) => state.authenticationReducer.loggedUser?.role);
  const [t] = useTranslation();
  const history = useHistory();

  const [searchString, setSearchString] = useState("");
  const [statusSelected, setAppSelected] = useState<string[]>([]);

  const { notificationsList, isLoading: isLoadingNotifications } = useGetNotificationsList();
  const { filteredNotifications } = useFilterTable(searchString, statusSelected, notificationsList);

  /**
   * capture filter data from the filter components
   * @param searchString search string
   * @param statusSelected selected statuses list
   */
  const captureFilterData = (searchString: string, statusSelected: string[]) => {
    setSearchString(searchString);
    setAppSelected(statusSelected);
  };

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>{t("pages.notifications.listPage.title")}</PageTitle>
        {canUserFromSFCMarketAddOrEditOrDeleteNotification(userRole) && (
          <ButtonWrapper>
            <PrimaryButton
              titleLabel={t("pages.notifications.createPage.title")}
              redTheme
              onClick={() => history.push(RoutesEnum.SFC_ADD_NOTIFICATION)}
            />
          </ButtonWrapper>
        )}
      </PageHeader>
      {isLoadingNotifications ? (
        <LoadingSpinner />
      ) : (
        <>
          <FiltersContainer>
            <TableFilterInputsComponent captureFilterData={captureFilterData} />
          </FiltersContainer>

          <TableWrapper className="tableWrapper">
            <NotificationsListTable filteredNotifications={filteredNotifications} />
          </TableWrapper>
        </>
      )}
    </PageContainer>
  );
};

export default SFCPushNotificationsListPage;

export const PageHeader = styled("h1")`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-right: 7%;
`;

export const PageTitle = styled("h1")`
  font-family: Vodafone Lt;
  font-size: 42px;
  color: #333;
  margin: 0;
  font-weight: normal;
`;

const ButtonWrapper = styled("div")``;

const TableWrapper = styled.div`
  > div:first-child {
    > div:last-child {
      > div {
        padding: 0;
        border-bottom: 1px solid;
        border-bottom-color: ${props => props.theme.palette.lightGrey};
      }
    }
  }

  margin-bottom: 5rem;
`;

export const FiltersContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 32px 0;
  flex-wrap: wrap;
  > div {
    margin-top: 4px;
  }
`;
