import React, { useState } from "react";
import i18next, { TFunction } from "i18next";
import styled from "styled-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { ColumnProps } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import { EditIcon, FastActionButton, UndoIcon, TrashIcon } from "@wit/mpesa-ui-components";
import { IconContainer } from "../../../shared/shared.styled";
import { TableButtonsContainer } from "../../../shared/shared.utils";
import Store from "../../../configs/store.config";
import { IBank, BanksStatusEnum } from "./bank.model";

export const getStatusColor = (status: BanksStatusEnum) => {
  switch (status) {
    case BanksStatusEnum.LIVE:
    case BanksStatusEnum.ENABLE:
      return styleTheme.palette.successGreen;
    case BanksStatusEnum.DISABLE:
    case BanksStatusEnum.DISABLED:
      return styleTheme.palette.greyDarker;
    case BanksStatusEnum.DELETE:
      return styleTheme.palette.digitalRed;
  }

  return styleTheme.palette.black;
};

export const renderBanksGeneralStatus = (newStatus: string, t: TFunction) => {
  switch (newStatus) {
    case BanksStatusEnum.LIVE:
      return (
        <Status
          color={styleTheme.palette.white}
          background={styleTheme.palette.successGreen}
          border={styleTheme.palette.successGreen}
          type={newStatus}
        >
          <span>{t("pages.banks.status.live")}</span>
        </Status>
      );
    case BanksStatusEnum.EDIT:
      return (
        <Status
          color={styleTheme.palette.white}
          background={styleTheme.palette.freshOrange}
          border={styleTheme.palette.freshOrange}
          type={newStatus}
        >
          <span>{t("pages.banks.status.edit")}</span>
        </Status>
      );
  }
};

export const renderTableBanksButtons = <T extends unknown>(
  value: T,
  buttonActions: {
    edit: { onClick: (row: T) => any };
    delete?: { onClick: (row: T) => any };
    revert?: { onClick: (row: T) => any };
  },
) => {
  return (
    <TableButtonsContainer>
      {(value as IBank).status === BanksStatusEnum.DELETE ? (
        buttonActions && buttonActions.revert && findLastVersion((value as IBank).id) ? (
          <FastActionContainer id={`restore-button-${(value as IBank).name}`}>
            <FastActionButton
              iconComponent={
                <IconContainer size={16} color={styleTheme.palette.successGreen}>
                  <UndoIcon />
                </IconContainer>
              }
              onClick={() => buttonActions.revert && buttonActions.revert.onClick(value)}
              label={i18next.t("pages.banks.table.restore")}
            />
          </FastActionContainer>
        ) : (
          <></>
        )
      ) : (
        <>
          <FastActionContainer id={`edit-button-${(value as IBank).name}`}>
            <FastActionButton
              iconComponent={
                <IconContainer size={16} color={styleTheme.palette.turquoiseBlue}>
                  <EditIcon />
                </IconContainer>
              }
              onClick={() => buttonActions.edit.onClick(value)}
              label={i18next.t("pages.banks.table.edit")}
            />
          </FastActionContainer>
          {buttonActions && buttonActions.delete ? (
            <FastActionContainer id={`delete-button-${(value as IBank).name}`}>
              <FastActionButton
                iconComponent={
                  <IconContainer size={16} color={styleTheme.palette.vodafoneRed}>
                    <TrashIcon />
                  </IconContainer>
                }
                onClick={() => buttonActions && buttonActions.delete && buttonActions.delete.onClick(value)}
                label={i18next.t("pages.banks.table.delete")}
              />
            </FastActionContainer>
          ) : (
            <></>
          )}
        </>
      )}
    </TableButtonsContainer>
  );
};

export const renderStatus = (newStatus: BanksStatusEnum, t: TFunction) => {
  switch (newStatus) {
    case BanksStatusEnum.DISABLED.toString():
      return (
        <Status
          color={styleTheme.palette.white}
          background={styleTheme.palette.black}
          border={styleTheme.palette.black}
          type={newStatus}
        >
          <span>{t("pages.banks.status.deactivated")}</span>
        </Status>
      );
    case BanksStatusEnum.DISABLE.toString():
      return (
        <Status
          color={styleTheme.palette.black}
          background={styleTheme.palette.white}
          border={styleTheme.palette.black}
          type={newStatus}
        >
          <span>{t("pages.banks.status.deactivated")}</span>
        </Status>
      );
    case BanksStatusEnum.LIVE.toString():
      return (
        <Status
          color={styleTheme.palette.white}
          background={styleTheme.palette.successGreen}
          border={styleTheme.palette.successGreen}
          type={newStatus}
        >
          <span>{t("pages.banks.status.live")}</span>
        </Status>
      );
    case BanksStatusEnum.ENABLE.toString():
    case BanksStatusEnum.EDIT.toString():
      return (
        <Status
          color={styleTheme.palette.successGreen}
          background={styleTheme.palette.white}
          border={styleTheme.palette.successGreen}
          type={newStatus}
        >
          <span>{t("pages.banks.status.live")}</span>
        </Status>
      );
  }
};

const getDisabled = (row: IBank) => {
  return (
    row.status === BanksStatusEnum.DELETE ||
    row.status === BanksStatusEnum.DISABLE ||
    row.status === BanksStatusEnum.DISABLED
  );
};

const findLastVersion = (id: string | undefined) => {
  if (!id) {
    return undefined;
  }

  const lastVersion: IBank[] = Store.getState().banksReducer.banks.lastVersion;
  return lastVersion.find(b => b.id === id) || null;
};

const renderContent = (content: any, row: IBank, edited: boolean) => {
  return (
    <Content disabled={getDisabled(row)}>
      {content && edited && (
        <IconCont size={16} color={styleTheme.palette.darkGrey}>
          <EditIcon />
        </IconCont>
      )}
      {content}
    </Content>
  );
};

const getEditedStatus = (row: IBank) => {
  const lastVersion = findLastVersion(row.id);
  return (
    (lastVersion && lastVersion.status === BanksStatusEnum.LIVE && row.status === BanksStatusEnum.DISABLE) ||
    (lastVersion && lastVersion.status === BanksStatusEnum.DISABLED && row.status === BanksStatusEnum.ENABLE)
  );
};

const LazyLoadingImage = ({ row }: any): JSX.Element => {
  const [url, setUrl] = useState<boolean>(false);
  const icon = row.urlIcon;
  const Img = new Image();
  Img.src = `${icon}?time=${new Date().valueOf()}`;
  Img.onload = function() {
    setUrl(true);
  };

  if (url) {
    if (icon.startsWith("http")) {
      return (
        <LogoContainer id={`bank-${row.name}`}>
          <ImageContent
            disabled={getDisabled(row)}
            url={`${icon}?time=${new Date().valueOf()}`}
            id={`bank-${row.name}`}
          />
        </LogoContainer>
      );
    } else {
      return (
        <LogoContainer id={`bank-${row.name}`}>
          <ImageContent
            disabled={getDisabled(row)}
            url={`./imagesuploaded/${icon}?time=${new Date().valueOf()}`}
            id={`bank-${row.name}`}
          />
        </LogoContainer>
      );
    }
  }

  return (
    <LogoContainer id={`bank-${row.name}`}>
      <ImageContent disabled={getDisabled(row)} url={"./images/bank.png"} />
    </LogoContainer>
  );
};

export const getBankColumns = (t: TFunction): ColumnProps[] => {
  return [
    {
      formKey: "iconUrl",
      label: "",
      isEditable: false,
      ratio: 1 / 12,
      changeFunction: (content: string, row: IBank) => {
        return <LazyLoadingImage row={row} />;
      },
    },
    {
      formKey: "shortCode",
      label: t("pages.banks.table.shortCode"),
      isEditable: false,
      ratio: 2 / 12,
      changeFunction: (content, row) => {
        const lastVersion = findLastVersion(row.id);
        const edited = (lastVersion && lastVersion.shortCode !== row.shortCode) || false;
        return renderContent(content, row, edited);
      },
    },
    {
      formKey: "name",
      label: t("pages.banks.table.name"),
      isEditable: false,
      ratio: 3 / 12,
      changeFunction: (content, row) => {
        const lastVersion = findLastVersion(row.id);
        const edited = (lastVersion && lastVersion.name !== row.name) || false;
        return renderContent(content, row, edited);
      },
    },
    {
      formKey: "accountNumberPrefix",
      label: t("pages.banks.table.prefix"),
      isEditable: false,
      ratio: 3 / 12,
      changeFunction: (content, row) => {
        const lastVersion = findLastVersion(row.id);
        const edited = (lastVersion && lastVersion.accountNumberPrefix !== row.accountNumberPrefix) || false;
        return renderContent(content, row, edited);
      },
    },
    {
      formKey: "status",
      label: t("pages.banks.table.status"),
      isEditable: false,
      ratio: 2 / 12,
      changeFunction: (content: BanksStatusEnum, row: IBank) => {
        const edited = getEditedStatus(row) || false;
        return renderContent(renderStatus(content, t), row, edited);
      },
    },
    {
      formKey: "",
      label: "  ",
      isEditable: false,
      ratio: 1 / 12,
      changeFunction: (content: BanksStatusEnum, row: IBank) => {
        if (row.status === BanksStatusEnum.DELETE) {
          return <Delete>{t("pages.banks.status.delete")}</Delete>;
        } else {
          return <></>;
        }
      },
    },
  ];
};

const Status = styled("div")<{ color: string; background: string; border: string; type: BanksStatusEnum }>`
  border-radius: 10px;
  padding-left: 12px;
  padding-right: 12px;
  height: 21px;
  line-height: 21px;
  font-weight: bold;
  display: inline-block;
  font-size: 14px;
  text-align: center;
  background-color: ${props => props.background};
  font-family: Vodafone Rg;
  justify-content: flex-end;
  margin-bottom: 10px;
  border: 1px solid;
  color: ${props => props.color};
  border-color: ${props => props.border};

  > span {
    position: relative;
    top: -1px;
  }
`;

const Delete = styled.span`
  color: ${props => props.theme.palette.digitalRed};
`;

const Content = styled.span<{ disabled?: boolean; edited?: boolean }>`
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`;

const LogoContainer = styled.div`
  display: flex;
  height: 45px;
  align-items: center;
`;

const ImageContent = styled.div<{ disabled?: boolean; url: string }>`
  height: 24px;
  width: 24px;
  border: 100px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  background-image: url(${props => props.url});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0;
`;

const IconCont = styled(IconContainer)`
  position: absolute !important;
  margin-left: -20px !important;
  justify-content: flex-start;
`;

const FastActionContainer = styled("div")`
  :last-of-type {
    margin-right: 0;
  }
  margin-right: 12px;
`;
