import { SearchBar } from "@wit/mpesa-ui-components";
import { FilterFuncts } from "@wit/mpesa-ui-components/lib/hooks/use-filters.hook";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { SearchBarFilterContainer } from "../../../../../shared/responsive-ui.styled";
import { Row } from "../../../../../shared/shared.styled";

export enum CategoriesFilterEnum {
  SEARCH = "search",
}

/**
 * CategoriesFilters component
 */
const CategoriesFilters = ({ filters, clearFilter, isFilterActive, resetFilters, updateFilter }: FilterFuncts) => {
  const [t] = useTranslation();

  return (
    <CategoriesFilterContainer>
      <SearchBarFilterContainer id={"search-categories"}>
        <SearchBar
          placeholderLabel={t("pages.serviceManager.categories.searchPlaceholder")}
          value={
            filters.has(CategoriesFilterEnum.SEARCH) ? (filters.get(CategoriesFilterEnum.SEARCH) as string[])[0] : ""
          }
          onChange={e => {
            if (e.target.value) {
              updateFilter(CategoriesFilterEnum.SEARCH, e.target.value, true);
            } else {
              clearFilter(CategoriesFilterEnum.SEARCH);
            }
          }}
          clearValue={() => clearFilter(CategoriesFilterEnum.SEARCH)}
        />
      </SearchBarFilterContainer>
    </CategoriesFilterContainer>
  );
};

export default CategoriesFilters;

const CategoriesFilterContainer = styled(Row)`
  margin-bottom: 40px;
  margin-top: 40px;
  width: 100%;
`;
