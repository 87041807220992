import React from "react";

const RegisterCompletedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="192"
      height="192"
      viewBox="0 0 192 192"
    >
      <defs>
        <circle id="b" cx="61.424" cy="38.16" r="38.16" />
        <filter id="a" width="127.5%" height="127.5%" x="-13.8%" y="-9.8%" filterUnits="objectBoundingBox">
          <feOffset dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3" />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.556640625 0 0 0 0 0.556640625 0 0 0 0 0.556640625 0 0 0 0.23 0"
          />
        </filter>
        <path
          id="d"
          d="M121.017 141.471a12 12 0 0 0 1.407-9.351c-9.61-38-35.23-55.8-60.66-55.8S10.154 94.06.424 132.08a12 12 0 0 0 9.18 14.82 255.482 255.482 0 0 0 52 5.32 255.483 255.483 0 0 0 51.67-5.32 12 12 0 0 0 7.743-5.429z"
        />
        <filter id="c" width="117.1%" height="127.7%" x="-8.6%" y="-9.9%" filterUnits="objectBoundingBox">
          <feOffset dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3" />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.556640625 0 0 0 0 0.556640625 0 0 0 0 0.556640625 0 0 0 0.23 0"
          />
        </filter>
        <circle id="e" cx="26" cy="26" r="26" />
        <filter id="f" width="140.4%" height="140.4%" x="-20.2%" y="-14.4%" filterUnits="objectBoundingBox">
          <feOffset dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3" />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.556862745 0 0 0 0 0.556862745 0 0 0 0 0.556862745 0 0 0 0.23 0"
          />
        </filter>
        <linearGradient id="g" x1="7.316%" x2="63.055%" y1="32.68%" y2="76.303%">
          <stop offset="0%" stop-opacity=".571" />
          <stop offset="100%" stop-opacity="0" />
        </linearGradient>
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g transform="translate(30.576 19.78)">
          <use fill="#000" filter="url(#a)" xlinkHref="#b" />
          <use fill="#F4F4F4" xlinkHref="#b" />
        </g>
        <g transform="translate(30.576 19.78)">
          <use fill="#000" filter="url(#c)" xlinkHref="#d" />
          <use fill="#F4F4F4" xlinkHref="#d" />
        </g>
        <path
          fill="#000"
          fill-rule="nonzero"
          d="M82.73 95a38.15 38.15 0 0 0 47.43-37v-1c-.475 20.718-17.406 37.267-38.13 37.267-20.724 0-37.655-16.549-38.13-37.267v1c.004 17.48 11.882 32.723 28.83 37zM153.36 153.92a12 12 0 0 1-9.51 10.76 255.47 255.47 0 0 1-103.72 0 12 12 0 0 1-9.51-10.77 12 12 0 0 0 9.51 12.77 255.482 255.482 0 0 0 103.72 0 12 12 0 0 0 9.51-12.76z"
          opacity=".06"
        />
        <g>
          <g transform="translate(120 96)">
            <mask id="h" fill="#fff">
              <use xlinkHref="#e" />
            </mask>
            <g fill-rule="nonzero">
              <use fill="#000" filter="url(#f)" xlinkHref="#e" />
              <use fill="#E90000" xlinkHref="#e" />
            </g>
            <path
              fill="url(#g)"
              d="M21.596 31.416L39.284 12 64.21 36.482l-8.043 20.494-18.05 1.323c-8.178-8.705-13.985-14.917-17.42-18.639-3.435-3.72-6.392-7.15-8.871-10.288l9.77 2.044z"
              mask="url(#h)"
              opacity=".25"
            />
          </g>
          <path d="M125 101h42v42h-42z" />
          <path
            fill="#FFF"
            fill-rule="nonzero"
            d="M142.268 136c-1.256 0-2.045-.92-2.472-1.424l-8.732-9.671-.027-.03-.177-.22a2.912 2.912 0 0 1-.61-1.855 2.813 2.813 0 0 1 2.809-2.808c.75 0 1.459.324 2.106.965l6.438 5.597 13.939-17.679c.455-.667 1.124-1.422 2.277-1.422a2.57 2.57 0 0 1 2.568 2.568 2.857 2.857 0 0 1-.606 1.64l-11.83 18.091v.011l-3.045 4.487c-.404.577-1.22 1.75-2.638 1.75z"
          />
          <path
            fill="#000"
            fill-rule="nonzero"
            d="M159.781 111.183l-11.83 18.09v.011l-3.045 4.494c-.4.57-1.216 1.734-2.625 1.734-1.255 0-2.045-.92-2.472-1.424l-8.745-9.662-.026-.02-.178-.219a2.892 2.892 0 0 1-.602-1.601v.182a2.912 2.912 0 0 0 .611 1.86l.177.218.027.03 8.723 9.658c.438.503 1.217 1.424 2.472 1.424 1.418 0 2.234-1.163 2.625-1.734l3.043-4.491v-.011l11.845-18.09c.369-.47.58-1.045.606-1.641 0-.081 0-.16-.013-.239a3.023 3.023 0 0 1-.593 1.43z"
            opacity=".2"
          />
          <path fill="#E60000" fill-rule="nonzero" d="M140.332 135.145l-.219-.22c.07.075.143.147.22.22z" />
          <path
            fill="#FFF"
            fill-rule="nonzero"
            d="M159.843 108.438a2.562 2.562 0 0 0-2.03-.985c-1.153 0-1.823.755-2.278 1.422l-2.246 2.86 6.324-3.516.23.219z"
          />
        </g>
      </g>
    </svg>
  );
};
export default RegisterCompletedIcon;
