import React from "react";
import styled from "styled-components";
import { CheckIcon } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { IconContainer } from "../../shared.styled";

export interface IProgressIndicatorProps {
  activeIndex?: number;
  numberOfDots?: number;
  checkPrevious?: boolean;
  onClick?: (index: number) => any;
}

const ProgressIndicator = (params: IProgressIndicatorProps) => {
  const indicators = [];
  if (params.numberOfDots !== undefined && params.numberOfDots > 0) {
    for (let i = 0; i < params.numberOfDots; i++) {
      const active = params.activeIndex !== undefined && params.activeIndex === i + 1;
      const showMarker = params.checkPrevious && params.activeIndex && i < params.activeIndex;
      if (i > 0) {
        indicators.push(<Separator key={`s${i}`} />);
      }
      indicators.push(
        showMarker && !active ? (
          <IconContainer
            key={i}
            onClick={() => params.onClick && params.onClick(i)}
            style={{
              flex: "0 auto",
              backgroundColor: styleTheme.palette.vodafoneRed,
              borderRadius: "50%",
              cursor: "pointer",
            }}
            size={20}
            color={styleTheme.palette.white}
          >
            <CheckIcon />
          </IconContainer>
        ) : (
          <ProgressIndicatorInner key={i} active={active}>
            {active && <IndicatorDot />}
          </ProgressIndicatorInner>
        ),
      );
    }
  }

  return <ProgressIndicatorWrapper>{indicators}</ProgressIndicatorWrapper>;
};

const ProgressIndicatorWrapper = styled("div")`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Separator = styled("div")`
  flex: 1 auto;

  background-color: ${props => props.theme.palette.aluminium};
  height: 1px;
`;

const ProgressIndicatorInner = styled("div")<{ active: boolean }>`
  flex: 0 auto;

  width: 20px;
  height: 20px;
  padding: 4px;

  background-color: ${props => props.theme.palette.white};
  border: 1px solid ${props => props.theme.palette.aluminium};

  border-radius: 50%;

  box-shadow: ${props =>
    props.active ? "0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);" : "none"};
`;

const IndicatorDot = styled("div")`
  width: 10px;
  height: 10px;

  background-color: ${props => props.theme.palette.vodafoneRed};
  border-radius: 50%;
`;

export default ProgressIndicator;
