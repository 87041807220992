import React from "react";
import { useTranslation } from "react-i18next";
import { IReferralCampaign, ReferralCampaignStatus } from "../../referral-campaigns.model";
import { Formik, FormikHelpers } from "formik";
import RewardDetailsForm from "../../components/reward-details-form.component";
import styled from "styled-components";
import QuickActionsMenu from "./quick-actions-menu.component";
import { validateRewardDetails, validateSchemaRewardDetails } from "../../referral-campaigns.utils";
import { ILanguage } from "../../../../../shared/models/language.model";

interface IEditRewardDetailsProps {
  languages: ILanguage[];
  selectedLanguage: string;
  setSelectedLanguage: React.Dispatch<React.SetStateAction<string>>;
  referralCampaign: IReferralCampaign;
  onSubmitFn: (values: IReferralCampaign) => void;
  onCancelCampaignFn: () => void;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * EditRewardDetails component
 */
const EditRewardDetails = ({
  languages,
  selectedLanguage,
  setSelectedLanguage,
  referralCampaign,
  onSubmitFn,
  onCancelCampaignFn,
  isEditing,
  setIsEditing,
}: IEditRewardDetailsProps) => {
  const [t] = useTranslation();

  /**
   * Performs the create request
   * @param {ICampaignDetails} values
   * @param {FormikHelpers<ICampaignDetails>} actions
   */
  const handleSubmitRewardDetail = (values: IReferralCampaign, actions: FormikHelpers<IReferralCampaign>) => {
    onSubmitFn(values);
    setIsEditing(false);
  };

  return (
    <Formik
      initialValues={referralCampaign}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmitRewardDetail}
      validate={validateSchemaRewardDetails}
      render={({ values, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue, errors, resetForm }) => (
        <form onSubmit={handleSubmit}>
          <RewardDetailsContainer>
            <FormContainer>
              <RewardDetailsForm
                firstTitle={
                  <FormSubTitle>{t("pages.referralCampaigns.createCampaign.rewardDetails.senderRewards")}</FormSubTitle>
                }
                secondTitle={
                  <FormSubTitle>
                    {t("pages.referralCampaigns.createCampaign.rewardDetails.receiverRewards")}
                  </FormSubTitle>
                }
                isEditing={isEditing}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                language={selectedLanguage}
              ></RewardDetailsForm>
            </FormContainer>
            {referralCampaign.status && referralCampaign.status !== ReferralCampaignStatus.DISABLED ? (
              <QuickActionsMenu
                canEdit={referralCampaign.status !== ReferralCampaignStatus.LIVE}
                isEditing={isEditing}
                doneFn={() => {
                  handleSubmit();
                  validateRewardDetails(values);
                }}
                cancelFn={() => {
                  resetForm();
                  setIsEditing(false);
                }}
                canCancel={referralCampaign.status !== ReferralCampaignStatus.SCHEDULED}
                editCampaignFn={() => setIsEditing(true)}
                cancelCampaignFn={onCancelCampaignFn}
              ></QuickActionsMenu>
            ) : (
              <EmptySpace />
            )}
          </RewardDetailsContainer>
        </form>
      )}
    />
  );
};

export default EditRewardDetails;

const FormSubTitle = styled("span")`
  font-family: "Vodafone Rg";
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.darkGrey};
`;

const RewardDetailsContainer = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const FormContainer = styled("div")`
  flex: 1;
`;

const EmptySpace = styled("div")`
  flex: 1;
`;
