import React, { useState } from "react";

import ServiceDetailsDrawer from "./service-details-drawer/service-details-drawer.component";
import ServiceDetailsContainer from "./service-details-container/service-details-container.component";
import { IServiceDetails, ServiceStatusEnum, IServiceVersion } from "../../shared/models/service-builder.model";
import isLive from "./helpers/service.helpers";

export interface IServiceDetailsProps {}

interface IServiceDetailsPageProps {
  showAdminActions: boolean;
  serviceDetails: IServiceDetails;
  serviceVersions: IServiceVersion[];
  refreshPage: () => void;
}

const ServiceDetailsComponent = ({
  showAdminActions,
  serviceDetails,
  serviceVersions,
  refreshPage,
}: IServiceDetailsPageProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const { canBeEdited } = serviceDetails;

  return (
    <>
      <ServiceDetailsDrawer
        showAdminActions={showAdminActions}
        open={isDrawerOpen}
        pending={!canBeEdited}
        serviceDetails={serviceDetails}
        toggleDrawer={toggleDrawer}
        refreshPage={refreshPage}
      />
      <ServiceDetailsContainer
        open={isDrawerOpen}
        serviceName={
          serviceDetails.name && serviceDetails.name["en"]
            ? serviceDetails.name["en"]
            : serviceDetails.name && serviceDetails.name[Object.keys(serviceDetails.name)[0]]
            ? serviceDetails.name[Object.keys(serviceDetails.name)[0]]
            : ""
        }
        serviceVersions={serviceVersions}
        serviceId={serviceDetails.id}
        refreshPage={refreshPage}
        pending={!canBeEdited}
        isServiceLive={isLive(serviceDetails)}
      />
    </>
  );
};

export default ServiceDetailsComponent;
