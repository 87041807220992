import React from "react";
import styled from "styled-components";
import { ELLIPSIS, ITablePagination } from "./table-pagination.model";
import usePagination from "./use-table-pagination.hook";

const TablePagination = ({ handlePageChange, totalItems, activePage, pageSize }: ITablePagination) => {
  const paginationItems = usePagination({ totalItems, pageSize, activePage });
  const lastPage = paginationItems![paginationItems!.length - 1];

  // Don't render pagination if only 1 page
  if (activePage === 0 || paginationItems!.length < 2) {
    return null;
  }

  return (
    <PaginationContainer>
      <PaginationItem onClick={() => handlePageChange(activePage - 1)} disabledClick={activePage === 1}>
        <ArrowLeft />
      </PaginationItem>
      {paginationItems!.map(pageNumber => {
        if (pageNumber === ELLIPSIS) {
          return (
            <PaginationItem disabledClick={true}>
              <Ellipsis />
            </PaginationItem>
          );
        }
        return (
          <PaginationItem
            className={`${pageNumber === activePage ? "active" : ""}`}
            onClick={() => handlePageChange(Number(pageNumber))}
          >
            {pageNumber}
          </PaginationItem>
        );
      })}
      <PaginationItem onClick={() => handlePageChange(activePage + 1)} disabledClick={activePage === lastPage}>
        <ArrowRight />
      </PaginationItem>
    </PaginationContainer>
  );
};

const PaginationContainer = styled("ul")`
  display: flex;
  padding-left: 0;
  padding-bottom: 16px;
`;

const PaginationItem = styled("li")<{ disabledClick?: boolean }>`
  font-family: Vodafone Rg;
  font-size: 16px;
  padding: 7px 0;
  width: 45px;
  border: 1px solid ${props => props.theme.palette.aluminium};
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  border-radius: 10px;
  ${props => (props.disabledClick ? "pointer-events: none; color: #999;" : null)}
  &:hover {
    cursor: pointer;
    border-color: ${props => props.theme.palette.midGrey};
    font-weight: bold;
    color: ${props => props.theme.palette.darkGrey};
  }
  &.active {
    font-weight: bold;
    border-color: ${props => props.theme.palette.darkGrey};
  }
`;

const ArrowLeft = styled("div")`
  &::before {
    content: "⟨";
  }
`;

const ArrowRight = styled("div")`
  &::before {
    content: "⟩";
  }
`;

const Ellipsis = styled("div")`
  &::before {
    content: "...";
  }
`;

export default TablePagination;
