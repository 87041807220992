import i18next from "i18next";
import { IAuditingLogDetails, ITranslatableChanges } from "../../auditing.model";

/**
 * Returns a list of dropdown options containing the languages in a given auditing log details object
 * @param {string[]} languages
 * @returns {{label: string, key: string}[]}
 */
export const getLanguagesDropdownOptions = (languages: string[]) => {
  return languages.map(l => {
    return { label: i18next.t(`commons.languages.${l}`), key: l };
  });
};

/**
 * Verifies the list of available languages in a given auditing log
 * @param {IAuditingLogDetails} details
 * @returns {[string, string]}
 */
export const getAvailableLanguages = (details: IAuditingLogDetails) => {
  const languages: string[] = [];
  if (details.changes) {
    details.changes.forEach(change => {
      change.minorChanges.forEach(minorChange => {
        const keys: string[] = [];
        if (typeof minorChange.oldValue === "object") {
          keys.concat(getLanguageKeysFromTranslations(minorChange.oldValue));
        }
        if (typeof minorChange.newValue === "object") {
          keys.concat(getLanguageKeysFromTranslations(minorChange.newValue));
        }
        keys.forEach(key => {
          if (languages.indexOf(key) === -1) {
            languages.push(key);
          }
        });
      });
    });
  }
  return languages;
};

/**
 * Returns an array of language keys from a translatable object
 * @param obj
 * @returns {string[]}
 */
const getLanguageKeysFromTranslations = (obj: ITranslatableChanges<string | null> | null) => {
  return Object.keys(obj as ITranslatableChanges<string | null>);
};
