import { Modal, PrimaryButton } from "@wit/mpesa-ui-components";
import { safaricomPalette } from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import i18next from "i18next";
import React from "react";
import styled from "styled-components";
import {
  ModalContent,
  ModalDescription,
  ModalTitle,
} from "../../../shared/components/safaricom-delete-modal/safaricom-delete-modal.styled";
import LoadingIconWhite from "../../../shared/icons/loading-white.icon";

interface IUnchangesModal extends BaseModalProps {
  thridBtnLabel?: string;
  thridAction: (args?: any) => void;
  isLoading: boolean;
  thumbnailActive: boolean;
}

/**
 * compoenent that will retrieve the modal for the unchanges made
 * @param param0 Interface of the modal
 */
const UnchangesModal = ({
  thridBtnLabel = i18next.t("pages.announcements.detailPage.buttons.modalDiscard") as string,
  thridAction,
  primaryAction,
  primaryBtnLabel = i18next.t("pages.announcements.detailPage.buttons.save") as string,
  secondaryAction,
  secondaryBtnLabel = i18next.t("pages.announcements.detailPage.buttons.cancelBtn") as string,
  title,
  description,
  isLoading,
  thumbnailActive,
}: IUnchangesModal) => {
  return (
    <Modal onBackgroundClick={secondaryAction} modalStyles={{ width: "462px" }}>
      <ModalContent>
        <ModalTitle>{title}</ModalTitle>
        <ModalDescription>{description}</ModalDescription>
      </ModalContent>
      <DivActions>
        <DivCancelAction>
          <PrimaryButton
            id={"cancel"}
            titleLabel={secondaryBtnLabel}
            disabled={isLoading}
            redTheme={false}
            style={{ width: "fit-content" }}
            onClick={secondaryAction}
          />
        </DivCancelAction>
        <DivDiscardAndSave>
          <div style={{ flex: 0.3, marginRight: "12px" }}>
            <PrimaryButton
              id={"discard"}
              titleLabel={thridBtnLabel}
              disabled={isLoading}
              redTheme={true}
              style={{ backgroundColor: safaricomPalette.errorRed }}
              onClick={thridAction}
            />
          </div>
          <div style={{ flex: 0.3 }}>
            {!isLoading ? (
              <PrimaryButton
                id={"save-draft"}
                titleLabel={primaryBtnLabel}
                redTheme={true}
                disabled={thumbnailActive}
                style={{ width: "fit-content" }}
                onClick={() => {
                  primaryAction();
                }}
              />
            ) : (
              <DivLoading>
                <LoadingIconWhite />
              </DivLoading>
            )}
          </div>
        </DivDiscardAndSave>
      </DivActions>
    </Modal>
  );
};

export default UnchangesModal;

const DivActions = styled("div")`
  display: flex;
  flex-direction: row;
  padding: 0 24px 24px 24px;
  justify-content: space-between;
`;

const DivCancelAction = styled("div")`
  flex: 0.2;
`;

const DivDiscardAndSave = styled("div")`
  flex: 0.7;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const DivLoading = styled("div")`
  background-color: rgb(47, 197, 109);
  width: 106px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
