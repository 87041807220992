import { useMemo } from "react";
import { useSelector } from "react-redux";
import { isFeedbackTabActive, isProblemReportsTabActive } from "../feedback-and-problems.utils";
import { IStoreInterface } from "../../../../configs/store.config";

/**
 * Custom Hook - UseDetailsActiveCategories
 * [!important] This hook can be used within
 * feedback tab components, problem reports tab components, customer satisfaction survey tab
 * @param sectionId number
 * @returns current-active-categories
 */
export function useFeedbackAndProblemsDetailsActiveCategories(sectionId: number) {
  const { feedbackDetails, problemReportsDetails } = useSelector(
    (state: IStoreInterface) => state.feedbackAndProblemsReportReducer,
  );

  const activeCategories = useMemo(() => {
    const activeCategories = ["..."];
    if (isFeedbackTabActive(sectionId)) {
      if (feedbackDetails && feedbackDetails.length >= 1) {
        activeCategories.length = 0;
        feedbackDetails.forEach(item => {
          activeCategories.push(item.name.translations["en"]);
        });
      }
    }
    if (isProblemReportsTabActive(sectionId)) {
      if (problemReportsDetails && problemReportsDetails.length >= 1) {
        activeCategories.length = 0;
        problemReportsDetails.forEach(item => {
          activeCategories.push(item.name.translations["en"]);
        });
      }
    }
    return activeCategories;
  }, [feedbackDetails, problemReportsDetails]);

  return activeCategories;
}
