import { AutoClosingDropdown, PrimaryButton, useAlert } from "@wit/mpesa-ui-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { RoutesEnum } from "../../../../routes/routes.constants";
import { Column } from "../../../../shared/shared.styled";
import LanguageTranslationsApi from "../language-translations.api";
import { ILanguageTranslations } from "../language-translations.model";
import { LanguageTranslationsActions } from "../language-translations.store";
import compareVersions from "compare-versions";

interface ICreateLanguageTranslationsVersionModalInterface {
  dismissModal: () => void;
  versionToDuplicate?: string;
  history: any;
}

/**
 * Language translation version modal
 * @returns
 */
const CreateLanguageTranslationsVersionModal = ({
  dismissModal,
  versionToDuplicate,
  history,
}: ICreateLanguageTranslationsVersionModalInterface) => {
  const [t] = useTranslation();
  const [showAlert, , setAlertProps] = useAlert();
  const [whiteListedVersions, setWhitelistedVersions] = React.useState<string[]>([]);
  const [version, setVersion] = React.useState<string>("");
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    getWhitelistedVersions();
  }, []);

  /**
   * Gets the list of whitelisted versions
   */
  const getWhitelistedVersions = () => {
    LanguageTranslationsApi.methods.getWhitelistedVersions().then(
      res => {
        setWhitelistedVersions(res.data.versions?.sort(compareVersions));
      },
      () => {
        setAlertProps({
          title: t("pages.languageTranslationsVersions.getWhitelistedVersionsError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        dismissModal();
      },
    );
  };

  /**
   * Performs a create/duplicate request
   */
  const submitRequest = () => {
    setIsSubmitting(true);
    LanguageTranslationsApi.methods
      .createLanguageTranslationVersion({
        version: version,
        copyFromVersion: versionToDuplicate ? versionToDuplicate : null,
      })
      .then(
        () => {
          dispatch(LanguageTranslationsActions.creators.getLanguageTranslationsSuccess({} as ILanguageTranslations));
          dispatch(
            LanguageTranslationsActions.creators.getLanguageTranslationsLastVersion({} as ILanguageTranslations),
          );
          history.push(RoutesEnum.LANGUAGE_TRANSLATIONS_VERSION.replace(":version", version));
          setAlertProps({
            title: versionToDuplicate
              ? t("pages.languageTranslationsVersions.duplicateSuccess", { version: versionToDuplicate })
              : t("pages.languageTranslationsVersions.addVersionSuccess", { version: version }),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          dismissModal();
        },
        () => {
          setAlertProps({
            title: versionToDuplicate
              ? t("pages.languageTranslationsVersions.duplicateError", { version: versionToDuplicate })
              : t("pages.languageTranslationsVersions.addVersionError", { version: version }),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  return (
    <ModalContainer>
      <ModalTitle>
        {versionToDuplicate
          ? t("pages.languageTranslationsVersions.duplicateVersion")
          : t("pages.languageTranslationsVersions.addNew")}
      </ModalTitle>
      {versionToDuplicate && (
        <ModalMessage>{t("pages.languageTranslationsVersions.duplicateVersionMessage")}</ModalMessage>
      )}
      <DropdownColumn>
        <LabelRequired>{t("pages.languageTranslationsVersions.versionNumber")}</LabelRequired>
        <AutoClosingDropdown
          options={whiteListedVersions.reverse().map(v => {
            return { key: v, label: `${t("pages.languageTranslationsVersions.version")} ${v}` };
          })}
          dropdownType={DropdownType.RECTANGULAR_NORMAL}
          selectOption={opt => setVersion(opt.key)}
          label={
            version
              ? `${t("pages.languageTranslationsVersions.version")} ${version}`
              : t("pages.languageTranslationsVersions.versionNumberPlaceholder")
          }
          hasValue={!!version}
        />
      </DropdownColumn>
      <ButtonsContainer>
        <div>
          <PrimaryButton
            redTheme={false}
            id={"cancel-version-button"}
            titleLabel={t("pages.languageTranslationsVersions.cancel")}
            onClick={dismissModal}
            type="button"
            disabled={isSubmitting}
          />
        </div>
        <div>
          <PrimaryButton
            redTheme={true}
            id={"create-new-version-button"}
            titleLabel={
              versionToDuplicate
                ? t("pages.languageTranslationsVersions.duplicate")
                : t("pages.languageTranslationsVersions.createNewVersion")
            }
            type="submit"
            onClick={submitRequest}
            disabled={!version || isSubmitting}
          />
        </div>
      </ButtonsContainer>
    </ModalContainer>
  );
};

const Label = styled("div")`
  font-family: "Vodafone Rg";
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
  margin-bottom: 8px;
`;

const DropdownColumn = styled(Column)`
  margin-top: 24px;
  margin-bottom: 60px;
  > button {
    > div {
      height: 100px;
      > div {
        height: 100%;
      }
    }
  }
`;

const LabelRequired = styled(Label)`
  ::after {
    content: " *";
    color: ${props => props.theme.palette.errorRed};
  }
`;

const ModalContainer = styled("div")`
  border-radius: 6px;
  box-shadow: 0px 4px 4px rgba(142, 142, 142, 0.23), 0px 3px 10px rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.palette.white};
  padding: 24px;
`;

const ModalTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 13px;
`;

const ModalMessage = styled("p")`
  font-family: Vodafone Rg;
  font-size: 18px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 24px;
  margin-top: 13px;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  > div {
    :first-of-type {
      margin-right: 12px;
    }
  }
`;

export default CreateLanguageTranslationsVersionModal;
