import { UserRolesDefault, UserRolesSFC, UserRolesVDF } from "../../features/admin/users/users.interfaces";
import { ILoggedUser } from "../../features/authentication/authentication.interfaces";

/**
 * hook method to check if logged user has permission to access component
 */
const useCheckRolePermissionDenied = (
  component: React.ReactNode,
  loggedUser: ILoggedUser,
  deniedUsers: Array<UserRolesDefault | UserRolesSFC | UserRolesVDF>,
): React.ReactNode => {
  if (deniedUsers.includes(loggedUser.role)) {
    return null;
  }

  return component;
};

export default useCheckRolePermissionDenied;
