import { AxiosResponse } from "axios";
import i18n from "i18next";
import { IConfig, marketsWithPrivateConfigs } from "../../app.component";
import ApiClient from "../../configs/axios.config";
import { constructPath } from "../../configs/i18n.config";
import { ILoggedUser, ILoginResponse } from "../../features/authentication/authentication.interfaces";
import { AuthenticationActions } from "../../features/authentication/authentication.store";
import { BackOfficeMarketsEnums } from "../shared.enums";

/**
 * A Login Successful Handler
 * @param {AxiosResponse<ILoginResponse>} res
 * @param {boolean} loginFromModal
 * @param {(payload: any) => void} dispatcher
 * @param {boolean | undefined} rememberMe
 */
export const loginSuccessHandler = (
  res: AxiosResponse<ILoginResponse>,
  dispatcher: (payload: {
    type: string;
    payload: { visibility?: boolean; xsrfToken?: string; loggedUser?: ILoggedUser };
  }) => void,
  loginFromModal: boolean,
  rememberMe?: boolean | undefined,
) => {
  const token = getResToken(res);
  if (token) {
    dispatcher(AuthenticationActions.creators.updateXsrfTokenAction(token));
    localStorage.setItem("xsrfToken", token);
  }
  if (!loginFromModal) {
    localStorage.setItem("rememberMe", String(rememberMe));
  }
  dispatcher(AuthenticationActions.creators.toggleModalAction(false));
  dispatcher(AuthenticationActions.creators.updateLoggedUserAction({ ...res.data }));
  return fetchPrivateConfig(res.data);
};

/**
 * Fetches the private config if the Market is ET
 */
export const fetchPrivateConfig = (user: ILoginResponse) => {
  return new Promise<null | IConfig>((resolve, reject) => {
    if (
      !marketsWithPrivateConfigs.includes(process.env.REACT_APP_MARKET as BackOfficeMarketsEnums) ||
      (user.is2FAEnabled &&
        localStorage.getItem("twoFactorAuthenticated") &&
        localStorage.getItem("twoFactorAuthenticated") === "false")
    ) {
      resolve(JSON.parse(localStorage.getItem("config") as string));
      return;
    }
    ApiClient.get(
      `${
        process.env.REACT_APP_LOCAL_BASE_URL_EKYC_V1 ? "v1/config" : (process.env.REACT_APP_CONFIG_URL as string)
      }/private`,
    )
      .then(res => {
        const config: IConfig = {
          ...res.data,
          ...(JSON.parse(localStorage.getItem("config") as string) as IConfig),
          market: process.env.REACT_APP_MARKET as string,
        };
        localStorage.setItem("config", JSON.stringify(config));
        resolve(config);
      })
      .catch(e => {
        console.log(e);
        reject();
      });
  });
};

/**
 * Returns the response token
 * @param {AxiosResponse<any>} res
 * @returns {string}
 */
export const getResToken = (res: AxiosResponse<any>) => {
  const tokenOnCookie =
    document.cookie &&
    document.cookie.split(";").find((e: string) => e.includes("XSRF-TOKEN=")) &&
    (document.cookie.split(";").find((e: string) => e.includes("XSRF-TOKEN=")) as string).replace("XSRF-TOKEN=", "");
  return !!tokenOnCookie ? tokenOnCookie : res.headers["xsrf-token"];
};

/**
 *
 * @param {AxiosResponse<any>} res
 * @param {string} token
 */
export const initPrivateTranslation = (res: AxiosResponse<any>, token: string) => {
  i18n.init({
    ns: ["private", "market_values"],
    defaultNS: "private",
    lng: process.env.REACT_APP_MARKET!.includes(BackOfficeMarketsEnums.DRC_EKYC) ? "fr" : "en",
    fallbackLng: "en",
    backend: {
      loadPath: constructPath,
      customHeaders: {
        "Security-Cookie": res.headers["security-cookie"],
        "XSRF-TOKEN": token,
      },
    },
  });
};

/**
 * A Logout Successful Handler
 * Clear localStorage xsrf token and securityCookie and
 * Clears the authentication reducer
 * @param {(payload: any) => void} dispatcher
 */
export const logoutSuccessHandler = (dispatcher: (payload: { type: string }) => void): void => {
  localStorage.removeItem("xsrfToken");
  localStorage.removeItem("securityCookie");
  dispatcher(AuthenticationActions.creators.logoutAction());
};
