import { AxiosPromise } from "axios";
import ApiClient from "../../../configs/axios.config";
import { IEditedTask } from "./components/task-details.component";

/*
 * Tasks API methods
 * */
const TasksApi = {
  routes: {
    getMyTasks: "/v1/tasks",
    editTask: "/v1/tasks/:taskId",
    getTaskDetails: "/v1/tasks/details/:taskId",
  },
  methods: {
    getMyTasks: (): AxiosPromise => {
      return ApiClient.get(TasksApi.routes.getMyTasks, {});

      /*return new Promise((resolve, reject) => {
        ApiClient.get(TasksApi.routes.getMyTasks, {})
          .then(res => {
            // TODO: remove dummy logic
            console.log("adding dummy task to list");
            const result = { ...res };
            result.data = [...dummyServiceTasks, ...result.data];
            resolve(result);
          })
          .catch(reject);
      });*/
    },
    editTask: (taskId: string, task: IEditedTask): AxiosPromise => {
      return ApiClient.put(TasksApi.routes.editTask.replace(":taskId", taskId), task);
    },
    getTaskDetails: (taskId: string): AxiosPromise => {
      return ApiClient.get(TasksApi.routes.getTaskDetails.replace(":taskId", taskId), {});

      /*// TODO: remove dummy logic
      if (taskId === servicesTaskDetails.task.id) {
        console.log("returning dummy task details");
        return new Promise((resolve, reject) => {
          setTimeout(() => resolve({ data: servicesTaskDetails }), 500);
        });
      } else {
        return ApiClient.get(TasksApi.routes.getTaskDetails.replace(":taskId", taskId), {});
      }*/
    },
  },
};

export default TasksApi;
