import { IServiceManagerGroups, ServiceManagerStatusEnum } from "../../../../shared/models/service-manager.model";

export enum TrustedRolesEnum {
  TRUSTED = "Trusted",
  UNTRUSTED = "Untrusted",
}

export enum ServiceType {
  CONSUMER = "CONSUMER",
  ORG = "ORG",
}
export interface IServiceFormDetails {
  name: string;
  description: string;
  color: string;
  status: ServiceManagerStatusEnum;
  groups: IServiceManagerGroups[];
  serviceIcon: string;
  serviceImage: string;
  trustedLevel: TrustedRolesEnum;
  allowedScopes: string[];
  categories?: string[];
  nameTranslations?: {
    [lang: string]: string;
  };
  descriptionTranslations?: {
    [lang: string]: string;
  };
  id?: string;
}

export interface IServiceForm {
  id: string;
  name: string;
  description: string;
  serviceIcon: string;
  serviceImage: string;
  nameTranslations?: {
    [lang: string]: string;
  };
  descriptionTranslations?: {
    [lang: string]: string;
  };
}
