import { PrimaryButton, TextInput, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikHelpers, yupToFormErrors } from "formik";
import { FormikErrors } from "formik/dist/types";
import i18next from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { object, string } from "yup";
import { Column, RatioContainer } from "../../../../../shared/shared.styled";
import { BanksStatusEnum } from "../../../../configurations/banks/bank.model";
import AppPropertiesApi from "../app-properties.api";
import { IAddAppPropertyRequest, IAppProperty, AppPropertyStatusEnum } from "../app-properties.model";
import AppPropertyStatusChip from "./app-property-status.component";

interface IAddAppPropertyModalInterface {
  dismissModal: () => void;
  appProperties: IAppProperty[];
  reloadData: () => void;
  selectedAppProperty: IAppProperty | undefined;
  appId: string;
}
const AddAppPropertyModal = ({
  dismissModal,
  appProperties,
  reloadData,
  selectedAppProperty,
  appId,
}: IAddAppPropertyModalInterface) => {
  const [t] = useTranslation();
  const [formValues] = useState<IAddAppPropertyRequest>({
    fieldName: selectedAppProperty ? selectedAppProperty.fieldName : "",
    value: selectedAppProperty ? selectedAppProperty.value : "",
    status: selectedAppProperty ? selectedAppProperty.status : AppPropertyStatusEnum.ENABLE,
  });
  const [showAlert, , setAlertProps] = useAlert();

  /**
   * Form validation schema
   */
  const schema = object().shape({
    fieldName: string()
      .required(i18next.t("pages.appProperties.addAppPropertyModal.requiredField"))
      .nullable()
      .max(255, i18next.t("pages.appProperties.addAppPropertyModal.maxLenDescription"))
      .test("fieldNameEqual", i18next.t("pages.appProperties.addAppPropertyModal.propertyExists"), function(val) {
        return !appProperties.some(
          prop => prop.fieldName === val && (selectedAppProperty ? prop.id !== selectedAppProperty.id : true),
        );
      }),
    value: string()
      .required(i18next.t("pages.appProperties.addAppPropertyModal.requiredField"))
      .max(255, i18next.t("pages.appProperties.addAppPropertyModal.maxLenDescription")),
  });

  /*
   * Request to add the app property
   * */
  const addNewProperty = (values: IAddAppPropertyRequest, actions: FormikHelpers<IAddAppPropertyRequest>) => {
    actions.setSubmitting(true);
    if (selectedAppProperty) {
      AppPropertiesApi.methods
        .editProperty(values, selectedAppProperty.id, appId)
        .then(
          () => {
            setAlertProps({
              type: AlertTypeEnum.SUCCESS,
              title: t("pages.appProperties.addAppPropertyModal.editSuccess"),
            });
            showAlert();
            reloadData();
            dismissModal();
          },
          err => {
            setAlertProps({
              type: AlertTypeEnum.ERROR,
              title:
                err && err.data && err.data.status && err.data.status.message
                  ? err.data.status.message
                  : t("pages.appProperties.addAppPropertyModal.editError"),
            });
            showAlert();
          },
        )
        .finally(() => {
          actions.setSubmitting(false);
        });
    } else {
      AppPropertiesApi.methods
        .addProperty(values, appId)
        .then(
          () => {
            setAlertProps({
              type: AlertTypeEnum.SUCCESS,
              title: t("pages.appProperties.addAppPropertyModal.createSuccess"),
            });
            showAlert();
            reloadData();
            dismissModal();
          },
          err => {
            setAlertProps({
              type: AlertTypeEnum.ERROR,
              title:
                err && err.data && err.data.status && err.data.status.message
                  ? err.data.status.message
                  : t("pages.appProperties.addAppPropertyModal.createError"),
            });
            showAlert();
          },
        )
        .finally(() => {
          actions.setSubmitting(false);
        });
    }
  };

  /**
   * Change status of the app property
   * @param {BanksStatusEnum | undefined} currentStatus
   * @param {(field: string, value: any, shouldValidate?: (boolean | undefined)) => void} setFieldValue
   */
  const changeStatus = (currentStatus: AppPropertyStatusEnum, setFieldValue: (field: string, value: any) => void) => {
    if (
      currentStatus === AppPropertyStatusEnum.LIVE ||
      currentStatus === AppPropertyStatusEnum.EDIT ||
      currentStatus === AppPropertyStatusEnum.ENABLE
    ) {
      setFieldValue("status", AppPropertyStatusEnum.DISABLE);
    } else {
      setFieldValue("status", AppPropertyStatusEnum.ENABLE);
    }
  };

  /*
   * Retrieves the jsx for the main form
   * */
  const getMainFormInputs = (
    values: IAddAppPropertyRequest,
    handleChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined,
    setFieldValue: any,
    errors: any,
  ) => {
    return (
      <>
        <FormContainer>
          <RatioContainer ratio={1}>
            <TextInput
              name="fieldName"
              title={t("pages.appProperties.addAppPropertyModal.name")}
              placeholder={t("pages.appProperties.addAppPropertyModal.namePlaceholder")}
              onChange={handleChange}
              value={values.fieldName || ""}
              error={errors.fieldName}
              required
            />
          </RatioContainer>
        </FormContainer>
        <FormContainer>
          <RatioContainer ratio={1}>
            <TextInput
              name="value"
              title={t("pages.appProperties.addAppPropertyModal.value")}
              placeholder={t("pages.appProperties.addAppPropertyModal.valuePlaceholder")}
              onChange={handleChange}
              value={values.value || ""}
              error={errors.value}
              required
            />
          </RatioContainer>
        </FormContainer>
        <FormContainer>
          <RatioContainer ratio={1}>
            <Column>
              <Label>{t("pages.appProperties.addAppPropertyModal.status")}</Label>
              <AppPropertyStatusChip
                id={"status-chip"}
                style={{ cursor: "pointer", width: "max-content" }}
                type={values.status}
                onClick={() => changeStatus(values.status, setFieldValue)}
              >
                {t(`pages.appProperties.appPropertiesStatusEnum.${values.status}`)}
              </AppPropertyStatusChip>
            </Column>
          </RatioContainer>
        </FormContainer>
      </>
    );
  };

  /**
   * Fn to validate the form every stroke
   * @param values
   */
  const validateSchema = (values: IAddAppPropertyRequest): Promise<FormikErrors<IAddAppPropertyRequest>> => {
    return new Promise<FormikErrors<IAddAppPropertyRequest>>(resolve => {
      schema
        .validate(values, {
          abortEarly: false,
        })
        .then(() => {
          resolve({});
        })
        .catch(y => {
          resolve(yupToFormErrors(y));
        });
    }).then(r => {
      return r;
    });
  };

  return (
    <ModalContainer>
      {selectedAppProperty ? (
        <ModalTitle>{t("pages.appProperties.addAppPropertyModal.titleEdit")}</ModalTitle>
      ) : (
        <ModalTitle>{t("pages.appProperties.addAppPropertyModal.title")}</ModalTitle>
      )}
      {formValues ? (
        <Formik
          initialValues={formValues}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={addNewProperty}
          validate={validateSchema}
          render={({ values, handleChange, isSubmitting, handleSubmit, setFieldValue, errors }) => (
            <form onSubmit={handleSubmit}>
              {getMainFormInputs(values, handleChange, setFieldValue, errors)}
              <ButtonsContainer>
                <div>
                  <PrimaryButton
                    id="cancel-button"
                    type="button"
                    onClick={dismissModal}
                    titleLabel={t("pages.appProperties.addAppPropertyModal.cancel")}
                  />
                </div>
                <div>
                  <PrimaryButton
                    id="save-changes-button"
                    redTheme={true}
                    type="submit"
                    disabled={!!errors.value || !!errors.fieldName || !values.fieldName || !values.value}
                    titleLabel={
                      selectedAppProperty
                        ? t("pages.appProperties.addAppPropertyModal.saveChanges")
                        : t("pages.appProperties.addAppPropertyModal.save")
                    }
                  />
                </div>
              </ButtonsContainer>
            </form>
          )}
        />
      ) : null}
    </ModalContainer>
  );
};

export default AddAppPropertyModal;

const ModalContainer = styled("div")`
  border-radius: 6px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  padding: 24px;
`;

const ModalTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 13px;
`;

const FormContainer = styled("div")`
  padding-top: 24px;
  display: flex;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 50px;
  > div {
    width: fit-content;
    :first-of-type {
      margin-right: 12px;
    }
  }
`;

const Label = styled("span")`
  min-height: 21px;
  font-family: Vodafone Rg;
  font-weight: bold;
  line-height: 21px;
  font-size: 16px;
  color: ${props => props.theme.palette.greyDarker};
  margin-bottom: 8px;
`;
