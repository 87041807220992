import { EditIcon, Exclamationicon, FastActionButton, TrashIcon } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";

import i18next from "i18next";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { RoutesEnum } from "../../../../routes/routes.constants";
import {
  fadeInKeyframe,
  IconContainer,
  RatioContainer,
  Row,
  Column,
  FlexContainer,
} from "../../../../shared/shared.styled";
import SubBundleStatusChip from "../../bundles/bundle-details/components/sub-bundle-status.component";
import { IChargeProfile, ChargeProfileStatusEnum } from "../rate-cards.model";

interface IChargeProfileListItemProps {
  item: IChargeProfile;
  deleteChargeProfile: (item: IChargeProfile) => void;
  editChargeProfile: (item: IChargeProfile) => void;
  hasFastActions: boolean;
}

const ChargeProfileListItem = ({
  item,
  deleteChargeProfile,
  editChargeProfile,
  hasFastActions,
}: IChargeProfileListItemProps) => {
  const [t] = useTranslation();
  const themeContext = useContext(ThemeContext);
  const history = useHistory();
  return (
    <>
      <ChargeProfileListItemContainer
        onClick={() => {
          if (hasFastActions) {
            history.push(RoutesEnum.CHARGE_PROFILE.replace(":id", item.id));
          }
        }}
        id={`charge-profile-${item.name}`}
        hoverAction={hasFastActions}
      >
        <ItemTitle ratio={4 / 12}>{item.name}</ItemTitle>
        <ItemNormalText ratio={3 / 12} style={{ color: styleTheme.palette.turquoiseBlue, fontWeight: "bold" }}>
          {item.chargeType}
        </ItemNormalText>
        <ItemNormalText ratio={1 / 12}>{item.ranges.length}</ItemNormalText>
        <FlexContainer className="toggable-container" ratio={4 / 12}>
          <Row>
            {hasFastActions && (
              <>
                <FastActionButton
                  iconComponent={
                    <IconContainer
                      size={16}
                      color={styleTheme.palette.turquoiseBlue}
                      id={`edit-charge-profile${item.name}`}
                    >
                      <EditIcon />
                    </IconContainer>
                  }
                  onClick={e => {
                    e.stopPropagation();
                    editChargeProfile(item);
                  }}
                  label={t("pages.rateCards.edit")}
                />
                {item.status !== "DELETE" ? (
                  <FastActionButton
                    onClick={e => {
                      e.stopPropagation();
                      deleteChargeProfile(item);
                    }}
                    label={t("pages.rateCards.delete")}
                    iconComponent={
                      <div
                        id={`delete-charge-profile${item.name}`}
                        style={{
                          width: 16,
                          height: 16,
                          stroke: styleTheme.palette.vodafoneRed,
                        }}
                      >
                        <TrashIcon />
                      </div>
                    }
                  />
                ) : null}
              </>
            )}
          </Row>
        </FlexContainer>
        <FlexContainer className="status-container" style={{ textAlign: "right", alignItems: "center" }} ratio={4 / 12}>
          <Column>
            {!item.invalid && item.hasUnpublishedChanges && hasFastActions ? (
              <Row>
                <ErrorMessage>{t("pages.bundles.unpublishedChanges")}</ErrorMessage>
                <IconContainer
                  size={14}
                  color={themeContext.palette.vodafoneRed}
                  style={{ marginLeft: "4px", marginRight: "14px" }}
                >
                  <Exclamationicon style={{ backgroundColor: "rgb(251, 230, 230)", borderRadius: "20px" }} />
                </IconContainer>
              </Row>
            ) : null}
            {item.invalid ? (
              <Row>
                <ErrorMessage>{t("pages.rateCards.errors.invalidProfile")}</ErrorMessage>
                <IconContainer
                  size={14}
                  color={themeContext.palette.vodafoneRed}
                  style={{ marginLeft: "4px", marginRight: "14px" }}
                >
                  <Exclamationicon style={{ backgroundColor: "rgb(251, 230, 230)", borderRadius: "20px" }} />
                </IconContainer>
              </Row>
            ) : null}
          </Column>
          <SubBundleStatusChip style={{ width: "fit-content" }} type={item.status as any}>
            {!hasFastActions && item.status === ChargeProfileStatusEnum.EDIT
              ? i18next.t(`commons.apps.enums.subBundleStatus.PENDING`)
              : i18next.t(`commons.apps.enums.subBundleStatus.${item.status}`)}
          </SubBundleStatusChip>
        </FlexContainer>
      </ChargeProfileListItemContainer>
    </>
  );
};

export default ChargeProfileListItem;

const ErrorMessage = styled("span")`
  font-family: Vodafone Lt;
  font-size: 14px;
  font-weight: normal;
  color: ${props => props.theme.palette.vodafoneRed};
`;

const ChargeProfileListItemContainer = styled("li")<{ hoverAction: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 75px;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};

  margin-bottom: 24px;
  padding: 0 35px 0 24px;

  .status-container {
    display: flex;
    justify-content: flex-end;
  }

  .toggable-container {
    display: none;
  }

  :hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    .status-container {
      display: ${props => (props.hoverAction ? "none" : "flex")};
    }
    .toggable-container {
      display: ${props => (!props.hoverAction ? "none" : "flex")};
      justify-content: flex-end;
      animation: ${fadeInKeyframe} 0.25s linear;
      button:first-child {
        margin-right: 12px;
      }
    }
  }
`;

const ItemTitle = styled(FlexContainer)`
  font-family: Vodafone Rg;
  font-size: 22px;
  font-weight: bold;
  color: ${props => props.theme.palette.darkGrey};
  @media (max-width: 1024px) {
    font-size: 20px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const ItemNormalText = styled(FlexContainer)`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
  @media (max-width: 768px) {
    font-size: 14px;
    padding-right: 8px;
  }
`;
