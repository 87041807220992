import { ArrowDownIcon, PrimaryButton, TextArea, TextInput, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikHelpers, FormikTouched, FormikValues, yupToFormErrors } from "formik";
import { FormikErrors } from "formik/dist/types";
import i18next from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { object, string } from "yup";
import { ILanguage } from "../../../../shared/models/language.model";
import LanguageTranslationsApi from "../language-translations.api";
import { IAddLanguageTranslationRequest, ILanguageTranslation } from "../language-translations.model";
import { LanguageTranslationsActions } from "../language-translations.store";
import useEmptyLanguageWarningModal from "../../../../shared/hooks/use-empty-language-warning-modal.hook";

interface ILanguageTranslationsDrawerProps {
  hideDrawer: () => void;
  selectedTranslation?: ILanguageTranslation;
  availableLanguages: ILanguage[];
  translations: ILanguageTranslation[];
}

/**
 * Language translation editor
 * @param {ILanguageTranslation | undefined} selectedTranslation
 * @param {() => void} hideDrawer
 * @param {ILanguage[]} availableLanguages
 * @param {ILanguageTranslation[]} translations
 * @returns {JSX.Element}
 * @constructor
 */
const LanguageTranslationsDrawer = ({
  selectedTranslation,
  hideDrawer,
  availableLanguages,
  translations,
}: ILanguageTranslationsDrawerProps) => {
  const [t] = useTranslation();
  const { version } = useParams<any>();
  const [showAlert, , setAlertProps] = useAlert();
  const dispatch = useDispatch();
  const [formValues] = useState<IAddLanguageTranslationRequest>({
    key: selectedTranslation?.key ? selectedTranslation.key : "",
    version: version,
    translations: selectedTranslation?.translations
      ? selectedTranslation.translations
      : availableLanguages.reduce((acc: { [key: string]: string }, cur) => {
          acc[cur.code] = "";
          return acc;
        }, {}),
    id: selectedTranslation?.id ? selectedTranslation.id : null,
  });

  const { warningModalOpen, showWarning, setShowWarning } = useEmptyLanguageWarningModal(availableLanguages || []);
  /**
   * Submit method wrapper to show warning if needed
   * @param values
   */
  const submitWrapper = (values: any, actions: FormikHelpers<any>) => {
    if (showWarning) {
      setShowWarning(false);
      actions.setSubmitting(false);
      warningModalOpen(handleFormSubmission, values, actions);
    } else {
      handleFormSubmission(values, actions);
    }
  };

  /**
   * Checks if the translations already include a given key
   * @param {string} key
   * @returns {boolean}
   */
  const checkKeyExistence = (key: string) => {
    return !translations.some(t => t.key === key);
  };

  /**
   * Validator helper for name and description
   * @returns {{[p: string]: any}}
   */
  const getTranslationValidators = () => {
    let validators: { [x: string]: any } = {};
    availableLanguages.map((lang, idx) => {
      validators = {
        ...validators,
        [lang.code]: lang.mandatory
          ? string()
              .trim()
              .required(i18next.t("pages.languageTranslations.translationRequired"))
              .max(4000, i18next.t("pages.languageTranslations.maxTransLength"))
          : string()
              .max(4000, i18next.t("pages.languageTranslations.maxTransLength"))
              .nullable(true),
      };
    });
    return validators;
  };

  /**
   * Form validation schema
   * @type {ObjectSchema<Shape<object, {eventId: string, category: string, name: object, section: string, description: object}>>}
   */
  const schema = object().shape({
    translations: object().shape(getTranslationValidators()),
    key: string()
      .trim()
      .required(i18next.t("pages.languageTranslations.requiredField"))
      .max(255, i18next.t("pages.languageTranslations.maxTransKeyLength"))
      .test("key-already-exists", i18next.t("pages.languageTranslations.keyAlreadyExists"), function(val: string) {
        if (!selectedTranslation?.id) {
          return checkKeyExistence(val);
        }
        return true;
      }),
  });

  /**
   * Fn to validate the form every stroke
   * @param values
   */
  const validateSchema = (
    values: IAddLanguageTranslationRequest,
  ): Promise<FormikErrors<IAddLanguageTranslationRequest>> => {
    return new Promise<FormikErrors<IAddLanguageTranslationRequest>>(resolve => {
      schema
        .validate(values, {
          abortEarly: false,
        })
        .then(() => {
          setShowWarning && setShowWarning(false);
          //Validate if any optional language is empty
          for (let l = 0; l < availableLanguages.length; l++) {
            if (!values.translations[availableLanguages[l].code]) {
              setShowWarning && setShowWarning(true);
            }
          }
          resolve({});
        })
        .catch(y => {
          resolve(yupToFormErrors(y));
        });
    }).then(r => {
      return r;
    });
  };

  /**
   * Render language inputs
   * @param {(field: string, value: any, shouldValidate?: boolean) => void} setFieldValue
   * @param {FormikErrors<IAddLanguageTranslationRequest>} errors
   * @param {FormikValues} values
   * @param touched
   * @param handleBlur
   * @returns {JSX.Element[]}
   */
  const renderAvailableLanguagesInputs = (
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    errors: FormikErrors<IAddLanguageTranslationRequest>,
    values: FormikValues,
    touched: FormikTouched<IAddLanguageTranslationRequest>,
    handleBlur: any,
  ) => {
    return (
      <>
        <TextInput
          id="key"
          aligment="left"
          title={t("pages.languageTranslations.key")}
          placeholder={t("pages.languageTranslations.addKeyPlaceholder")}
          name="key"
          onChange={e => setFieldValue("key", e.target.value)}
          onBlur={handleBlur}
          value={values.key || ""}
          error={errors.key && touched.key ? errors.key : undefined}
          required={!values.id}
          style={{ marginBottom: "25px" }}
          disabled={values.id}
        />
        {availableLanguages.map((lang: ILanguage, idx) => {
          return (
            <TextArea
              id={`translation-${lang.code}`}
              title={lang.name}
              placeholder={t("pages.languageTranslations.addTranslationPlaceholder", { lang: lang.name.toLowerCase() })}
              name={`translations[${lang.code}]`}
              onChange={e => setFieldValue(`translations[${lang.code}]`, e.target.value)}
              onBlur={handleBlur}
              value={values.translations[lang.code] || ""}
              error={
                errors.translations &&
                errors.translations[lang.code] &&
                touched.translations &&
                touched.translations[lang.code]
                  ? errors.translations[lang.code]
                  : undefined
              }
              required={lang.mandatory}
              style={{ marginBottom: "25px" }}
            />
          );
        })}
      </>
    );
  };

  /**
   * Renders the form buttons
   * @param {boolean | undefined} isSubmitting
   * @param {IAddLanguageTranslationRequest} values
   * @param isValid
   * @param dirty
   * @returns {JSX.Element}
   */
  const renderFormButtons = (
    isSubmitting: boolean | undefined,
    values: FormikValues,
    isValid: boolean,
    dirty: boolean,
  ) => {
    return (
      <ButtonsContainer>
        <div>
          <PrimaryButton
            redTheme={false}
            id={"cancel-button"}
            titleLabel={t("pages.languageTranslations.cancel")}
            onClick={hideDrawer}
            disabled={isSubmitting}
            type="button"
          />
        </div>
        <div>
          <PrimaryButton
            redTheme={true}
            id={values.id ? "save-changes-button" : "add-translation-button"}
            titleLabel={
              values.id ? t("pages.languageTranslations.saveChanges") : t("pages.languageTranslations.addTranslation")
            }
            type="submit"
            disabled={isSubmitting || !isValid || (isValid && !dirty)}
          />
        </div>
      </ButtonsContainer>
    );
  };

  /**
   * Submits the data to the remote server to create or edit a given translation
   * @param {IAddLanguageTranslationRequest} values
   * @param {FormikHelpers<IAddLanguageTranslationRequest>} actions
   */
  const handleFormSubmission = (
    values: IAddLanguageTranslationRequest,
    actions: FormikHelpers<IAddLanguageTranslationRequest>,
  ) => {
    actions.setSubmitting(true);
    if (selectedTranslation?.id) {
      LanguageTranslationsApi.methods
        .editLanguageTranslation(values)
        .finally(() => actions.setSubmitting(false))
        .then(
          res => {
            setAlertProps({
              title: t("pages.languageTranslations.editLanguageTranslationSuccess"),
              type: AlertTypeEnum.SUCCESS,
            });
            showAlert();
            dispatch(LanguageTranslationsActions.creators.getLanguageTranslationsSuccess(res.data));
            hideDrawer();
          },
          () => {
            setAlertProps({
              title: t("pages.languageTranslations.editLanguageTranslationError"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          },
        );
    } else {
      LanguageTranslationsApi.methods
        .createLanguageTranslation(values)
        .finally(() => actions.setSubmitting(false))
        .then(
          res => {
            setAlertProps({
              title: t("pages.languageTranslations.addLanguageTranslationSuccess"),
              type: AlertTypeEnum.SUCCESS,
            });
            showAlert();
            dispatch(LanguageTranslationsActions.creators.getLanguageTranslationsSuccess(res.data));
            hideDrawer();
          },
          () => {
            setAlertProps({
              title: t("pages.languageTranslations.addLanguageTranslationError"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          },
        );
    }
  };

  return (
    <>
      <DrawerContainer>
        <TitleContainer>
          <CloseIconContainer onClick={() => hideDrawer()}>
            <ArrowDownIcon />
          </CloseIconContainer>
          <Title>{t("pages.languageTranslations.addTranslation")}</Title>
        </TitleContainer>
        <FormContainer>
          <Formik
            initialValues={formValues}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={submitWrapper}
            validate={validateSchema}
            render={({
              values,
              touched,
              handleBlur,
              isSubmitting,
              handleSubmit,
              setFieldValue,
              errors,
              isValid,
              dirty,
            }) => (
              <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                {renderAvailableLanguagesInputs(setFieldValue, errors, values, touched, handleBlur)}
                {renderFormButtons(isSubmitting, values, isValid, dirty)}
              </form>
            )}
          />
        </FormContainer>
      </DrawerContainer>
      <DrawerOverlay onClick={() => hideDrawer()} />
    </>
  );
};

const FormContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 0px 76px 34px 76px;
  height: calc(100% - 159.5px);
`;

const TitleContainer = styled("div")`
  display: flex;
  padding: 66px 76px 66px 48px;
  align-items: center;
`;

const DrawerOverlay = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(51, 51, 51, 0.4);
`;

const DrawerContainer = styled("div")`
  z-index: 11;
  position: fixed;
  top: 0;
  right: 0;
  width: 552px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  height: 100%;
  overflow: auto;
`;

const CloseIconContainer = styled("div")`
  stroke: ${props => props.theme.palette.vodafoneRed};
  transform: rotate(90deg);
  cursor: pointer;
  svg {
    width: 16px;
  }
`;

const Title = styled("span")`
  font-family: Vodafone Rg;
  font-size: 24px;
  color: ${props => props.theme.palette.darkGrey};
  margin-left: 11.5px;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  > div {
    :first-of-type {
      margin-right: 12px;
    }
  }
`;

export default LanguageTranslationsDrawer;
