import { Modal, PrimaryButton } from "@wit/mpesa-ui-components";
import { safaricomPalette } from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import i18next from "i18next";
import React from "react";
import styled from "styled-components";
import {
  ModalContent,
  ModalDescription,
  ModalTitle,
} from "../../../shared/components/safaricom-delete-modal/safaricom-delete-modal.styled";

/**
 * compoenent that will retrieve the modal for the OnEditing made
 * @param param0 Interface of the modal
 */
const OnEditingModal = ({
  primaryAction,
  primaryBtnLabel = i18next.t("pages.announcements.detailPage.buttons.save"),
  secondaryAction,
  secondaryBtnLabel = i18next.t("pages.announcements.detailPage.buttons.cancelBtn"),
  title,
  description,
}: BaseModalProps) => {
  return (
    <Modal onBackgroundClick={secondaryAction} modalStyles={{ width: "462px" }}>
      <ModalContent>
        <ModalTitle>{title}</ModalTitle>
        <ModalDescription>{description}</ModalDescription>
      </ModalContent>
      <DivActions>
        <DivCancelAction>
          <PrimaryButton
            id={"cancel"}
            titleLabel={secondaryBtnLabel as string}
            redTheme={false}
            style={{ width: "fit-content" }}
            onClick={secondaryAction}
          />
        </DivCancelAction>
        <DivDiscardAndSave>
          <PrimaryButton
            id={"discard"}
            titleLabel={primaryBtnLabel as string}
            redTheme={true}
            style={{ backgroundColor: safaricomPalette.errorRed }}
            onClick={() => {
              primaryAction();
            }}
          />
        </DivDiscardAndSave>
      </DivActions>
    </Modal>
  );
};

export default OnEditingModal;

const DivActions = styled("div")`
  display: flex;
  flex-direction: row;
  padding: 0 24px 24px 24px;
  justify-content: space-between;
`;

const DivCancelAction = styled("div")`
  flex: 0.3;
`;

const DivDiscardAndSave = styled("div")`
  flex: 0.7;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
