import React from "react";
import { Table } from "@wit/mpesa-ui-components";
import { ITableResultRow, ISectionResultsTableComponentProps, EFormDataStatus } from "../feedback-and-problems.model";
import ResultsEmptyComponent from "./results-empty.component";
import { formatUnixTimestampDate } from "../../../../shared/shared.utils";
import {
  categoryFixer,
  isFeedbackTabActive,
  feedbacksStringPath,
  problemsStringPath,
  isProblemReportsTabActive,
} from "../feedback-and-problems.utils";
import { useFeedbackAndProblemsResults } from "../hooks/use-feedback-and-problems-results.hook";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import StatusDropDownComponent from "../../../../shared/components/status-dropdown/status-dropdown.component";

const SectionResultsTableComponent = ({
  columnsConfig,
  isResultOfSearch,
  sectionId,
  toggleSideDrawer,
  selectedRowData,
  onSelectedRowDataStatusChange,
  translations,
}: ISectionResultsTableComponentProps) => {
  const resultSet = useFeedbackAndProblemsResults(sectionId);
  const resultList: ITableResultRow[] = formatUnixTimestampDate(categoryFixer(resultSet), "date", "DD MMM YYYY") || [];

  const baseFeedbackString = `${feedbacksStringPath}.resultArea.statusEnums`;
  const baseProblemReportString = `${problemsStringPath}.resultArea.statusEnums`;

  const dropDownLabelStyle = (currentStatus: EFormDataStatus) => {
    return currentStatus === EFormDataStatus.RESOLVED
      ? { color: styleTheme.palette.springGreen }
      : currentStatus === EFormDataStatus.IN_PROGRESS
      ? { color: styleTheme.palette.freshOrange }
      : { color: styleTheme.palette.red.normal };
  };

  const selectedOption = (currentStatus: EFormDataStatus) => {
    return sectionId === 0
      ? translations(`${baseFeedbackString}.${currentStatus}`)
      : translations(`${baseProblemReportString}.${currentStatus}`);
  };

  const dropDownOptions = (currentStatus: EFormDataStatus) => {
    return currentStatus === EFormDataStatus.RESOLVED
      ? [
          {
            key: EFormDataStatus.NOT_REVIEWED,
            label: isFeedbackTabActive(sectionId)
              ? translations(`${baseFeedbackString}.${EFormDataStatus.NOT_REVIEWED}`)
              : translations(`${baseProblemReportString}.${EFormDataStatus.NOT_REVIEWED}`),
          },
          {
            key: EFormDataStatus.IN_PROGRESS,
            label: isFeedbackTabActive(sectionId)
              ? translations(`${baseFeedbackString}.${EFormDataStatus.IN_PROGRESS}`)
              : translations(`${baseProblemReportString}.${EFormDataStatus.IN_PROGRESS}`),
          },
        ]
      : currentStatus === EFormDataStatus.IN_PROGRESS
      ? [
          {
            key: EFormDataStatus.NOT_REVIEWED,
            label: isFeedbackTabActive(sectionId)
              ? translations(`${baseFeedbackString}.${EFormDataStatus.NOT_REVIEWED}`)
              : translations(`${baseProblemReportString}.${EFormDataStatus.NOT_REVIEWED}`),
          },
          {
            key: EFormDataStatus.RESOLVED,
            label: isFeedbackTabActive(sectionId)
              ? translations(`${baseFeedbackString}.${EFormDataStatus.RESOLVED}`)
              : translations(`${baseProblemReportString}.${EFormDataStatus.RESOLVED}`),
          },
        ]
      : [
          {
            key: EFormDataStatus.IN_PROGRESS,
            label: isFeedbackTabActive(sectionId)
              ? translations(`${baseFeedbackString}.${EFormDataStatus.IN_PROGRESS}`)
              : translations(`${baseProblemReportString}.${EFormDataStatus.IN_PROGRESS}`),
          },
          {
            key: EFormDataStatus.RESOLVED,
            label: isFeedbackTabActive(sectionId)
              ? translations(`${baseFeedbackString}.${EFormDataStatus.RESOLVED}`)
              : translations(`${baseProblemReportString}.${EFormDataStatus.RESOLVED}`),
          },
        ];
  };

  return (
    <div style={{ margin: "26px 0" }}>
      <Table<any>
        columns={
          isFeedbackTabActive(sectionId)
            ? columnsConfig.feedbacks
            : isProblemReportsTabActive(sectionId)
            ? columnsConfig.problems
            : columnsConfig.customer
        }
        values={resultList
          .sort((a, b) => Number(b.feedbackId) - Number(a.feedbackId))
          .map(item => {
            return {
              ...item,
              status: (
                <StatusDropDownComponent
                  labelStyle={dropDownLabelStyle(item.status as EFormDataStatus)}
                  itemId={(item.feedbackId && Number(item.feedbackId)) || Number(item.feedbackId)}
                  selectedOption={selectedOption(item.status as EFormDataStatus)}
                  options={dropDownOptions(item.status as EFormDataStatus)}
                  onSelectedRowDataStatusChange={data => onSelectedRowDataStatusChange(data)}
                />
              ),
            };
          })}
        sortable={false}
        isStripedTable={true}
        showActionColumn={false}
        emptyStateComponent={
          <ResultsEmptyComponent sectionId={sectionId} translations={translations} isSearchResult={isResultOfSearch} />
        }
        rowClickAction={(e, item) => {
          selectedRowData(item as ITableResultRow);
          toggleSideDrawer();
        }}
      />
    </div>
  );
};

export default React.memo(SectionResultsTableComponent);
