import { EditIcon, FastActionButton, TrashIcon } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import i18next from "i18next";
import React from "react";
import { IconContainer } from "../../../../shared/shared.styled";
import { TableButtonsContainer } from "../../../../shared/shared.utils";
import DiscoverCardStatusChip from "../../../configurations/discover-cards/components/discover-card-status.component";
import { IAppProperty, AppPropertyStatusEnum } from "./app-properties.model";

export const getColumns = () => {
  return [
    {
      formKey: "fieldName",
      ratio: 1 / 3,
      isEditable: false,
      label: i18next.t("pages.appProperties.table.name"),
      changeFunction: (content: string) => {
        return <span id={content}>{content}</span>;
      },
    },
    {
      formKey: "value",
      ratio: 1 / 3,
      isEditable: false,
      label: i18next.t("pages.appProperties.table.value"),
    },
    {
      formKey: "status",
      ratio: 1 / 3,
      isEditable: false,
      label: i18next.t("pages.appProperties.table.status"),
      changeFunction: (content: any, appProp: IAppProperty) => {
        return (
          <div className={"status-content"} style={{ alignItems: "center", height: "44px" }}>
            {appProp.status === AppPropertyStatusEnum.DELETE ? (
              <span style={{ color: styleTheme.palette.vodafoneRed }}>
                {i18next.t(`commons.apps.enums.subBundleStatus.${content}`)}
              </span>
            ) : (
              <DiscoverCardStatusChip type={content} id={`app-property-status-${appProp.status}`}>
                {i18next.t(`pages.appProperties.appPropertiesStatusEnum.${content}`)}
              </DiscoverCardStatusChip>
            )}
          </div>
        );
      },
    },
  ];
};

/*
 * App property quick actions
 * */
export const renderAppPropertyQuickActions = <T extends unknown>(
  value: T,
  buttonActions: {
    edit: { onClick: (row: T) => any };
    delete: { onClick: (row: T) => any };
  },
  themeContext: any,
) => {
  return (
    <TableButtonsContainer>
      {(value as IAppProperty).status !== AppPropertyStatusEnum.DELETE ? (
        <>
          <FastActionButton
            iconComponent={
              <IconContainer size={16} color={themeContext.palette.turquoiseBlue} id={"edit-button"}>
                <EditIcon />
              </IconContainer>
            }
            onClick={() => buttonActions.edit.onClick(value)}
            label={i18next.t("pages.appProperties.table.edit")}
          />
          <FastActionButton
            iconComponent={
              <IconContainer size={16} color={themeContext.palette.errorRed} id={"delete-button"}>
                <TrashIcon />
              </IconContainer>
            }
            onClick={() => buttonActions.delete.onClick(value)}
            label={i18next.t("pages.appProperties.table.delete")}
          />
        </>
      ) : null}
    </TableButtonsContainer>
  );
};
