import { IGatewayLog } from "../../shared/models/gateway-log.model";
import { IMiniAppsLog } from "../../shared/models/mini-apps-log.model";
import { IAppReport } from "../../shared/models/app-reports.model";

export interface IGetGatewayLogsResponse {
  count: number;
  gwReportLogList: IGatewayLog[];
}

export interface IGetAppsLogsResponse {
  count: number;
  reportLogList: IAppLog[];
}

export interface IAppLog {
  id: string;
  msisdn: string;
  environment: string;
  appConfigVersion: number;
  appConfigId: string;
  apkVersion: string;
  deviceId: string;
  deviceModel: string;
  sdkOperation: string;
  request: string;
  response: string;
  appRequestTime: string;
  appResponseTime: string;
  requestTime: string;
  status: GatewayLogStatusEnum;
  sid: string;
  connectionStatus: string;
  connectionType: string;
  cardCarrier: string;
  networkCarrier: string;
  requestId: string;
}

export interface IGetAppReportRelatedToGatewayLogResponse {
  count: number;
  reportLogList: IAppReport[];
}
export enum GatewayLogStatusEnum {
  SUCCESS,
  FAILED,
  TIMEOUT,
  PENDING,
}

export interface IGwReportLogsRequest {
  page: number;
  pageSize: number;
  startDate?: number;
  endDate?: number;
  gwOperation?: string;
  requestId?: string;
  requestContent?: string;
  asyncResponseContent?: string;
  syncResponseContent?: string;
  requestStatus?: string;
  fullDetails?: boolean;
}

export interface IAppReportLogsRequest {
  page: number;
  pageSize: number;
  startDate?: number;
  endDate?: number;
  msisdn?: string | number;
  sdkOperation?: string;
  requestStatus?: string;
  requestContent?: string;
  responseContent?: string;
  requestId?: string;
  connectionStatus?: string;
  connectionType?: string;
  cardCarrier?: string;
  networkCarrier?: string;
  appConfigId?: string;
  fullDetails?: boolean;
}

export interface IGetMiniAppsLogsResponse {
  metadata: {
    resultsCount: number;
    resultsLimit: number;
    resultsOffset: number;
  };
  results: IMiniAppsLog[];
}
export interface IMiniAppsReportLogsRequest {
  page: number;
  pageSize: number;
  startDate?: number;
  endDate?: number;
  serviceOperation?: string;
  requestId?: string;
  requestContent?: string;
  syncResponseContent?: string;
  requestStatus?: string;
  fullDetails?: boolean;
}

export enum MiniAppsLogStatusEnum {
  SUCCESS,
  FAILED,
  TIMEOUT,
  PENDING,
}
