import { EditIcon, PlusIcon, SmallButton, StatusChip, TrashIcon, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";

import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { IStoreInterface } from "../../../configs/store.config";
import { RoutesEnum } from "../../../routes/routes.constants";
import { IApp } from "../../../shared/models/app.model";
import { LoadingText } from "../../../shared/shared.styled";

import DocumentsApi from "./documents.api";
import { DocumentsActions } from "./documents.store";
import { useHistory } from "react-router-dom";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";

interface IDocumentsAppsProps {
  title: string;
  type: string;
  isDeletable: boolean;
  tabId: string;
  onDelete: (id: string, documentName: string) => void;
}

const DocumentsApps = ({ title, type, isDeletable, onDelete, tabId }: IDocumentsAppsProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [showAlert, , setAlertProps] = useAlert();
  const history = useHistory();

  /*Apps store*/
  const appsDocumentsStore = useSelector((state: IStoreInterface) => state.documentsReducer.apps);

  /*Apps and services with documents */
  const [apps, setDocuments] = React.useState(appsDocumentsStore);
  const [isLoading, setIsLoading] = React.useState(false);

  /*
   * Get apps
   * */
  React.useEffect(() => {
    setIsLoading(true);
    DocumentsApi.methods.getDocumentApps(type).then(
      res => {
        dispatch(DocumentsActions.creators.fetchDocumentsApps(res.data, title, type));
        setDocuments(res.data);
        setIsLoading(false);
      },
      () => {
        setAlertProps({
          title: t(`pages.documents.document.getAppsAndServicesError`),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        setIsLoading(false);
      },
    );
  }, [dispatch]);

  /*
   * Navigate to apps documents
   * */
  const navigateToDocument = (app: IApp) => {
    if (app.documents) {
      history.push(
        RoutesEnum.DOCUMENT.replace(":id", app.id)
          .replace(":tabId", tabId)
          .replace(":document", type),
      );
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingText>{t("commons.loadingResults")}</LoadingText>
      ) : (
        <>
          <SmallButtonsContainer>
            <SmallButton
              onClick={() => {
                history.push(RoutesEnum.ADD_DOCUMENT.replace(":tabId", tabId));
              }}
              titleLabel={t("pages.documents.manage.addDocument")}
              iconComponent={
                <IconContainer>
                  <PlusIcon />
                </IconContainer>
              }
            />
            {isDeletable && (
              <>
                <SmallButton
                  onClick={() => history.push(RoutesEnum.EDIT_DOCUMENT.replace(":tabId", tabId).replace(":type", type))}
                  titleLabel={t("pages.documents.manage.editDocument")}
                  iconComponent={
                    <IconContainer iconColor={styleTheme.palette.turquoiseBlue}>
                      <EditIcon />
                    </IconContainer>
                  }
                />
                <SmallButton
                  onClick={() => onDelete(type, title)}
                  titleLabel={t("pages.documents.manage.removeDocument")}
                  iconComponent={
                    <IconContainer>
                      <TrashIcon />
                    </IconContainer>
                  }
                />
              </>
            )}
          </SmallButtonsContainer>
          <TabTitle>{title}</TabTitle>
          {apps ? (
            <AppGridItemsContainer>
              {apps.map((app: IApp, i) => {
                return (
                  <Fragment key={app.id}>
                    {app.documents ? (
                      <AppCard
                        onClick={() => {
                          navigateToDocument(app);
                        }}
                      >
                        <AppCardTitle>{app.name}</AppCardTitle>
                        <AppPlatform>
                          {t(`commons.apps.enums.platform.${app.system}`)}
                          <ChipContainer>
                            <StatusChip type={app.documents.status as any}>
                              {t(`commons.apps.enums.status.${app.documents.status}`)}
                            </StatusChip>
                          </ChipContainer>
                        </AppPlatform>
                      </AppCard>
                    ) : null}
                  </Fragment>
                );
              })}
            </AppGridItemsContainer>
          ) : null}
        </>
      )}
    </>
  );
};

export default DocumentsApps;

const AppGridItemsContainer = styled("div")`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 50px;
`;

const SmallButtonsContainer = styled("div")`
  gap: 30px;
  display: flex;
  flex-direction: row-reverse;
`;

const IconContainer = styled("div")<{ iconColor?: string }>`
  stroke: ${({ iconColor }) => (iconColor ? iconColor : props => props.theme.palette.vodafoneRed)};
  width: 20px;
  height: 20px;

  svg {
    height: 20px;
  }
`;

const AppCard = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 375px;
  height: 215px;
  border-radius: 8px;
  margin-bottom: 24px;
  margin-right: 24px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  &:hover {
    cursor: pointer;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  }
`;

const AppCardTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  font-weight: bold;
  padding: 24px 24px 90px 24px;
  color: ${props => props.theme.palette.black};
  border-bottom: 1px solid ${props => props.theme.palette.aluminium};
`;

const AppPlatform = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  padding: 24px;
  display: flex;
  color: ${props => props.theme.palette.midGrey};
`;

const ChipContainer = styled("div")`
  margin-left: auto;
`;

const TabTitle = styled("div")`
  margin-top: 36px;
  font-family: Vodafone Rg;
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.palette.black};
`;
