import { TFunction } from "react-i18next";
import { ITableResultList, IStatusDropDownData, EFormDataStatus } from "./feedback-and-problems.model";
import { ILanguage } from "../../../shared/models/language.model";
import { Breadcrumb } from "@wit/mpesa-ui-components/lib/types";
import { RoutesEnum } from "../../../routes/routes.constants";

export const feedbacksStringPath = "pages.feedbackAndProblemsReport.feedbacksSection";
export const problemsStringPath = "pages.feedbackAndProblemsReport.problemsSection";
export const customerStringPath = "pages.feedbackAndProblemsReport.customerSection";

/**
 * Feedbacks and Problems Report Page
 * Display configuration data
 * @returns {}
 */
export const feedbackAndProblemsPageConfigs = (t: TFunction<"translation">) => {
  const PAGE_SECTIONS: string[] = [
    t(`${feedbacksStringPath}.title`),
    t(`${problemsStringPath}.title`),
    t(`${customerStringPath}.title`),
  ];
  const INITIAL_SECTION = 0;
  const INITIAL_PAGE = 1;
  const NUMBER_OF_ROWS_PER_PAGE = 20;
  const SEARCH_INPUT_MAX_LENGTH = 30;
  const CATEGORIES_INPUT_MAX_LENGTH = 30;
  const CATEGORIES_INPUT_MIN_LENGTH = 3;
  const SECTION_COLUMNS: {
    [key: string]: {
      formKey: string;
      label: string;
      isEditable: boolean;
      sortable: boolean;
      ratio: number;
    }[];
  } = {
    feedbacks: [
      {
        formKey: "feedbackId",
        label: t(`${feedbacksStringPath}.resultArea.feedbackId`),
        isEditable: false,
        sortable: false,
        ratio: 1.4 / 12,
      },
      {
        formKey: "msisdn",
        label: t(`${feedbacksStringPath}.resultArea.userMsisdn`),
        isEditable: false,
        sortable: false,
        ratio: 1.5 / 12,
      },
      {
        formKey: "category",
        label: t(`${feedbacksStringPath}.resultArea.category`),
        isEditable: false,
        sortable: false,
        ratio: 1.5 / 12,
      },
      {
        formKey: "date",
        label: t(`${feedbacksStringPath}.resultArea.date`),
        isEditable: false,
        sortable: false,
        ratio: 1.5 / 12,
      },
      {
        formKey: "feedback",
        label: t(`${feedbacksStringPath}.resultArea.feedback`),
        isEditable: false,
        sortable: false,
        ratio: 5.6 / 12,
      },
      {
        formKey: "status",
        label: t(`${feedbacksStringPath}.resultArea.status`),
        isEditable: false,
        sortable: false,
        ratio: 1.7 / 12,
      },
    ],
    problems: [
      {
        formKey: "feedbackId",
        label: t(`${problemsStringPath}.resultArea.reportId`),
        isEditable: false,
        sortable: false,
        ratio: 1.4 / 12,
      },
      {
        formKey: "msisdn",
        label: t(`${problemsStringPath}.resultArea.userMsisdn`),
        isEditable: false,
        sortable: false,
        ratio: 1.5 / 12,
      },
      {
        formKey: "category",
        label: t(`${problemsStringPath}.resultArea.category`),
        isEditable: false,
        sortable: false,
        ratio: 1.5 / 12,
      },
      {
        formKey: "date",
        label: t(`${problemsStringPath}.resultArea.date`),
        isEditable: false,
        sortable: false,
        ratio: 1.5 / 12,
      },
      {
        formKey: "feedback",
        label: t(`${problemsStringPath}.resultArea.problemReport`),
        isEditable: false,
        sortable: false,
        ratio: 5.6 / 12,
      },
      {
        formKey: "status",
        label: t(`${problemsStringPath}.resultArea.status`),
        isEditable: false,
        sortable: false,
        ratio: 1.7 / 12,
      },
    ],
    customer: [
      {
        formKey: "feedbackId",
        label: t(`${customerStringPath}.resultArea.feedbackId`),
        isEditable: false,
        sortable: false,
        ratio: 2 / 12,
      },
      {
        formKey: "msisdn",
        label: t(`${customerStringPath}.resultArea.userMsisdn`),
        isEditable: false,
        sortable: false,
        ratio: 3 / 12,
      },
      {
        formKey: "date",
        label: t(`${customerStringPath}.resultArea.date`),
        isEditable: false,
        sortable: false,
        ratio: 3 / 12,
      },
      {
        formKey: "completedQuestions",
        label: t(`${customerStringPath}.resultArea.completedQuestions`),
        isEditable: false,
        sortable: false,
        ratio: 4 / 12,
      },
    ],
  };

  return {
    PAGE_SECTIONS,
    INITIAL_PAGE,
    INITIAL_SECTION,
    NUMBER_OF_ROWS_PER_PAGE,
    SEARCH_INPUT_MAX_LENGTH,
    CATEGORIES_INPUT_MAX_LENGTH,
    CATEGORIES_INPUT_MIN_LENGTH,
    SECTION_COLUMNS,
  };
};

/**
 * Checks if the user is on Tab: Feedback
 * @returns boolean
 */
export const isFeedbackTabActive = (sectionId: number) => sectionId === 0;

/**
 * Checks if the user is on Tab: Problem Reports
 * @returns boolean
 */
export const isProblemReportsTabActive = (sectionId: number) => sectionId === 1;

/**
 * Checks if the user is on Tab: Customer Survey
 * @returns boolean
 */
export const isCustomerSurveyTabActive = (sectionId: number) => sectionId === 2;

/**
 * Returns categories in English (BO's default language)
 * @param data: ITableResultList[]
 * @returns Array of Objects
 */
export const categoryFixer = (data: ITableResultList[]) => {
  return data.map(item => {
    return {
      ...item,
      category: item.category.name.translations["en"],
    };
  });
};

/**
 * Filters letters and only keep numbers and + signal
 * @param value: string
 * @returns number
 */
export const searchInputTextHandler = (value: string): string => {
  let val = value;
  if (value.indexOf("+") >= 1) {
    val = value.replace("+", "");
    val = `+${val}`;
  }
  return val.replace(/([^0-9+])/gi, "");
};

/**
 * Gets market languages, analyses and return non duplicated languages (with same code)
 * @param lang ILanguage[]
 */
export const getFormattedMarketLanguages = (lang: ILanguage[]) => {
  const uniqueValues = lang
    .filter((value, index, self) => self.map(x => x.code).indexOf(value.code) === index)
    .map(item => item)
    .filter((value, index, self) => self.indexOf(value) === index);

  const langCodes = uniqueValues.map(item => item.code);

  return {
    languages: langCodes,
    activeLanguage: langCodes.includes("en") ? "en" : langCodes[0],
  };
};

/**
 * Return breadcrumbs for the current page
 * @param t TFunction
 * @returns Breadcrumb[]
 */
export const getBreadcrumbs = (t: TFunction): Breadcrumb[] => {
  return [
    {
      label: t("pages.configuration.title"),
      url: RoutesEnum.CONFIGS,
    },
    {
      label: t("pages.feedbackAndProblemsReport.title"),
      url: RoutesEnum.FEEDBACK_AND_PROBLEMS_REPORT,
    },
  ];
};

/**
 * Return the status dropDown data
 * @param sectionId number
 * @param t TFunction
 * @returns IStatusDropDownData[]
 */
export const getSelectableStatus = (sectionId: number, t: TFunction): IStatusDropDownData[] => {
  const baseFeedbackString = `${feedbacksStringPath}.resultArea.statusEnums`;
  const baseProblemReportString = `${problemsStringPath}.resultArea.statusEnums`;

  return [
    {
      key: EFormDataStatus.NOT_REVIEWED,
      label: isFeedbackTabActive(sectionId)
        ? t(`${baseFeedbackString}.${EFormDataStatus.NOT_REVIEWED}`)
        : t(`${baseProblemReportString}.${EFormDataStatus.NOT_REVIEWED}`),
    },
    {
      key: EFormDataStatus.IN_PROGRESS,
      label: isFeedbackTabActive(sectionId)
        ? t(`${baseFeedbackString}.${EFormDataStatus.IN_PROGRESS}`)
        : t(`${baseProblemReportString}.${EFormDataStatus.IN_PROGRESS}`),
    },
    {
      key: EFormDataStatus.RESOLVED,
      label: isFeedbackTabActive(sectionId)
        ? t(`${baseFeedbackString}.${EFormDataStatus.RESOLVED}`)
        : t(`${baseProblemReportString}.${EFormDataStatus.RESOLVED}`),
    },
  ];
};

/**
 * Just some dumb data, for demo purposes
 * TODO: Remove this after integration
 */
export const customerFakeData = {
  count: 18,
  list: [
    {
      feedbackId: 22,
      msisdn: "258857695444",
      date: 1680605799793,
      completedQuestions: 3,
      ratedExperience: " 8/10",
      finishedTransactions: "Yes",
      customerSatisfaction: "6/10",
      opinion:
        "Cras rutrum tincidunt ligula, eu vestibu sapien in terdum ac. Quisque et neque vitae mi lobortis cursus et vitae dolor. Vivamus vitae tellus arcu. Sed ultrici es turpis sed blandit bibendum.",
    },
    {
      feedbackId: 25,
      msisdn: "258857695444",
      date: 1680613636056,
      completedQuestions: 3,
      ratedExperience: " 8/10",
      finishedTransactions: "Yes",
      customerSatisfaction: "6/10",
      opinion:
        "Cras rutrum tincidunt ligula, eu vestibu sapien in terdum ac. Quisque et neque vitae mi lobortis cursus et vitae dolor. Vivamus vitae tellus arcu. Sed ultrici es turpis sed blandit bibendum.",
    },
    {
      feedbackId: 26,
      msisdn: "258857695444",
      completedQuestions: 3,
      date: 1680613674478,
      ratedExperience: " 8/10",
      finishedTransactions: "Yes",
      customerSatisfaction: "6/10",
      opinion:
        "Cras rutrum tincidunt ligula, eu vestibu sapien in terdum ac. Quisque et neque vitae mi lobortis cursus et vitae dolor. Vivamus vitae tellus arcu. Sed ultrici es turpis sed blandit bibendum.",
    },
    {
      feedbackId: 57,
      msisdn: "258857695444",
      completedQuestions: 3,
      date: 1682003622314,
      ratedExperience: " 8/10",
      finishedTransactions: "Yes",
      customerSatisfaction: "6/10",
      opinion:
        "Cras rutrum tincidunt ligula, eu vestibu sapien in terdum ac. Quisque et neque vitae mi lobortis cursus et vitae dolor. Vivamus vitae tellus arcu. Sed ultrici es turpis sed blandit bibendum.",
    },
    {
      feedbackId: 24,
      msisdn: "258857695444",
      completedQuestions: 3,
      date: 1680613288438,
      ratedExperience: " 8/10",
      finishedTransactions: "Yes",
      customerSatisfaction: "6/10",
      opinion:
        "Cras rutrum tincidunt ligula, eu vestibu sapien in terdum ac. Quisque et neque vitae mi lobortis cursus et vitae dolor. Vivamus vitae tellus arcu. Sed ultrici es turpis sed blandit bibendum.",
    },
    {
      feedbackId: 41,
      msisdn: "258857695444",
      completedQuestions: 3,
      date: 1681214779417,
      ratedExperience: " 8/10",
      finishedTransactions: "Yes",
      customerSatisfaction: "6/10",
      opinion:
        "Cras rutrum tincidunt ligula, eu vestibu sapien in terdum ac. Quisque et neque vitae mi lobortis cursus et vitae dolor. Vivamus vitae tellus arcu. Sed ultrici es turpis sed blandit bibendum.",
    },
    {
      feedbackId: 42,
      msisdn: "258857695444",
      completedQuestions: 3,
      date: 1681214798632,
      ratedExperience: " 8/10",
      finishedTransactions: "Yes",
      customerSatisfaction: "6/10",
      opinion:
        "Cras rutrum tincidunt ligula, eu vestibu sapien in terdum ac. Quisque et neque vitae mi lobortis cursus et vitae dolor. Vivamus vitae tellus arcu. Sed ultrici es turpis sed blandit bibendum.",
    },
    {
      feedbackId: 43,
      msisdn: "258857695444",
      completedQuestions: 3,
      date: 1681214829535,
      ratedExperience: " 2/10",
      finishedTransactions: "Yes",
      customerSatisfaction: "4/10",
      opinion:
        "Cras rutrum tincidunt ligula, eu vestibu sapien in terdum ac. Quisque et neque vitae mi lobortis cursus et vitae dolor. Vivamus vitae tellus arcu. Sed ultrici es turpis sed blandit bibendum.",
    },
    {
      feedbackId: 47,
      msisdn: "258849990995",
      completedQuestions: 3,
      date: 1681313564559,
      ratedExperience: " 9/10",
      finishedTransactions: "Yes",
      customerSatisfaction: "5/10",
      opinion:
        "Cras rutrum tincidunt ligula, eu vestibu sapien in terdum ac. Quisque et neque vitae mi lobortis cursus et vitae dolor. Vivamus vitae tellus arcu. Sed ultrici es turpis sed blandit bibendum.",
    },
    {
      feedbackId: 50,
      msisdn: "258857695444",
      completedQuestions: 3,
      date: 1681485153212,
      ratedExperience: " 8/10",
      finishedTransactions: "Yes",
      customerSatisfaction: "2/10",
      opinion:
        "Cras rutrum tincidunt ligula, eu vestibu sapien in terdum ac. Quisque et neque vitae mi lobortis cursus et vitae dolor. Vivamus vitae tellus arcu. Sed ultrici es turpis sed blandit bibendum.",
    },
    {
      feedbackId: 54,
      msisdn: "258857695444",
      completedQuestions: 3,
      date: 1681745851180,
      ratedExperience: " 8/10",
      finishedTransactions: "Yes",
      customerSatisfaction: "9/10",
      opinion:
        "Cras rutrum tincidunt ligula, eu vestibu sapien in terdum ac. Quisque et neque vitae mi lobortis cursus et vitae dolor. Vivamus vitae tellus arcu. Sed ultrici es turpis sed blandit bibendum.",
    },
    {
      feedbackId: 56,
      msisdn: "258843845597",
      completedQuestions: 3,
      date: 1681813644429,
      ratedExperience: " 8/10",
      finishedTransactions: "Yes",
      customerSatisfaction: "7/10",
      opinion:
        "Cras rutrum tincidunt ligula, eu vestibu sapien in terdum ac. Quisque et neque vitae mi lobortis cursus et vitae dolor. Vivamus vitae tellus arcu. Sed ultrici es turpis sed blandit bibendum.",
    },
    {
      feedbackId: 59,
      msisdn: "258857695444",
      completedQuestions: 3,
      date: 1682005115841,
      ratedExperience: " 8/10",
      finishedTransactions: "Yes",
      customerSatisfaction: "4/10",
      opinion:
        "Cras rutrum tincidunt ligula, eu vestibu sapien in terdum ac. Quisque et neque vitae mi lobortis cursus et vitae dolor. Vivamus vitae tellus arcu. Sed ultrici es turpis sed blandit bibendum.",
    },
  ],
};
