export enum StatusEnum {
  DISABLED,
  ACTIVE,
  PENDING_ACTIVE,
}

export enum BundleStatusEnum {
  LIVE = "LIVE",
  INACTIVE = "INACTIVE",
}

export enum BundleUseEnum {
  MY_PHONE = "MY_PHONE",
  BUY_FOR_A_FRIEND = "BUY_FOR_A_FRIEND",
  BOTH = "BOTH",
}

export enum ValidityDropdownEnum {
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  NIGHT_TIME = "nightTime",
}

export enum NetworkDropdownEnum {
  VODACOM = "vodacom",
  ALL_NETWORKS = "allNetworks",
}

export enum ReportStatusEnum {
  PENDING_ANALYSIS = "pendingAnalysis",
  ACTION_TAKEN = "actionTaken",
  NO_ACTION_TAKEN = "noActionTaken",
}

export enum ReportReasonEnum {
  SPAM = "spam",
  FRAUD = "fraud",
  UNKNOWN_REQUESTER = "unknownRequester",
  I_SHOULD_NOT_BE_THE_RECEIVER = "iShouldNotBeTheReceiver",
  OTHER = "other",
}

export enum SelectedView {
  LIST = "LIST",
  GRID = "GRID",
}

export enum DateFormatEnum {
  DO = "Do MMM YYYY",
  DD = "DD MMM YYYY",
}

export enum BackOfficeMarketsEnums {
  MZ = "mz",
  LS = "ls",
  TZ = "tz",
  GH = "gh",
  DRC = "drc",
  DRC_EKYC = "drc-ekyc",
  KE = "ke",
  ET = "et",
}

export enum FieldTypeEnum {
  STRING = "STRING",
  NUMBER = "NUMBER",
}

export enum StatusType {
  LIVE = "LIVE",
  DRAFT = "DRAFT",
  SCHEDULED = "SCHEDULED",
  CANCELED = "CANCELED",
  EXPIRED = "EXPIRED",
}
