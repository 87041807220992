import {
  AutoClosingDropdown,
  ModalTypeEnum,
  PlusIcon,
  SearchBar,
  SmallButton,
  useModal,
} from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { FilterFuncts } from "@wit/mpesa-ui-components/lib/hooks/use-filters.hook";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IConfig } from "../../../../../app.component";
import { SearchBarFilterContainer } from "../../../../../shared/responsive-ui.styled";
import { RatioContainer, Row } from "../../../../../shared/shared.styled";
import { IBundleType, IConfigurationFields } from "../../bundles.model";
import { getConfigurationFieldTranslation, getNetworkOptions, getValidityOptions } from "../sub-bundles.utils";
import AddSubBundleModal from "./add-sub-bundle.modal";

interface ISubBundlesListFiltersInterface extends FilterFuncts {
  configurationFields: IConfigurationFields;
  bundle: IBundleType;
  reloadSubBundles: () => void;
  config?: IConfig;
}
export enum SubBundlesFiltersEnum {
  SEARCH = "search",
  VALIDITY = "validity",
  NETWORK = "network",
}

/**
 * Sub bundle list filters
 * @param param0
 */
const SubBundlesListFilters = ({
  filters,
  clearFilter,
  isFilterActive,
  resetFilters,
  updateFilter,
  configurationFields,
  bundle,
  reloadSubBundles,
  config,
}: ISubBundlesListFiltersInterface) => {
  const [t] = useTranslation();

  /**
   * Add sub-bundle modal
   * */
  const _hideAddSubBundleModal = () => {
    hideAddSubBundleModal();
  };
  const [showAddSubBundleModal, hideAddSubBundleModal, addSubBundleModalProps] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <AddSubBundleModal
      bundle={bundle}
      configurationFields={configurationFields}
      dismissModal={_hideAddSubBundleModal}
      reloadSubBundles={reloadSubBundles}
    />,
  );
  React.useEffect(() => {
    addSubBundleModalProps({ modalStyles: { width: 825, padding: 0, maxHeight: "98%", overflow: "auto" } });
  }, [addSubBundleModalProps]);

  /**
   * Opens the new sub bundle modal
   * */
  const openAddNewSubBundleModal = () => {
    showAddSubBundleModal();
  };

  return (
    <SubBundlesFiltersContainer>
      <SearchBarFilterContainer>
        <SearchBar
          placeholderLabel={t("pages.bundleDetails.searchPlaceholder")}
          value={
            filters.has(SubBundlesFiltersEnum.SEARCH) ? (filters.get(SubBundlesFiltersEnum.SEARCH) as string[])[0] : ""
          }
          onChange={e => updateFilter(SubBundlesFiltersEnum.SEARCH, e.target.value, true)}
          clearValue={() => clearFilter(SubBundlesFiltersEnum.SEARCH)}
        />
      </SearchBarFilterContainer>
      {config!.bundles?.showFrequency && (
        <RatioContainer ratio={2 / 12}>
          <AutoClosingDropdown
            label={
              filters.has(SubBundlesFiltersEnum.VALIDITY)
                ? getConfigurationFieldTranslation(
                    (filters.get(SubBundlesFiltersEnum.VALIDITY) as string[])[0],
                    configurationFields.frequency,
                  )
                : t("pages.bundleDetails.validityPlaceholder")
            }
            options={getValidityOptions(configurationFields, true)}
            selectOption={val => {
              if (val.key) {
                updateFilter(SubBundlesFiltersEnum.VALIDITY, val.key, true);
              } else {
                clearFilter(SubBundlesFiltersEnum.VALIDITY);
              }
            }}
            hasValue={filters.has(SubBundlesFiltersEnum.VALIDITY)}
          />
        </RatioContainer>
      )}
      {config!.bundles?.showNetworks && (
        <RatioContainer ratio={2 / 12}>
          <AutoClosingDropdown
            label={
              filters.has(SubBundlesFiltersEnum.NETWORK)
                ? getConfigurationFieldTranslation(
                    (filters.get(SubBundlesFiltersEnum.NETWORK) as string[])[0],
                    configurationFields.plan,
                  )
                : t("pages.bundleDetails.networkPlaceholder")
            }
            options={getNetworkOptions(configurationFields, true)}
            selectOption={val => {
              if (val.key) {
                updateFilter(SubBundlesFiltersEnum.NETWORK, val.key, true);
              } else {
                clearFilter(SubBundlesFiltersEnum.NETWORK);
              }
            }}
            hasValue={filters.has(SubBundlesFiltersEnum.NETWORK)}
          />
        </RatioContainer>
      )}

      <div id="add-new-sub-bundle" style={{ marginLeft: "auto" }}>
        <SmallButton
          titleLabel={t("pages.bundleDetails.addNewSubBundle")}
          onClick={openAddNewSubBundleModal}
          iconComponent={
            <SmallButtonIconContainer color={styleTheme.palette.vodafoneRed}>
              <PlusIcon />
            </SmallButtonIconContainer>
          }
        />
      </div>
    </SubBundlesFiltersContainer>
  );
};

export default SubBundlesListFilters;

const SubBundlesFiltersContainer = styled(Row)`
  margin-bottom: 49px;
  margin-top: 41px;
  flex-wrap: wrap;
  gap: 24px;
  > ${RatioContainer} {
    margin-right: 24px;

    :last-of-type {
      margin-right: 0;
    }
  }
`;
const SmallButtonIconContainer = styled("div")<{ color: string }>`
  width: 16px;
  height: 16px;
  stroke: ${props => props.color};

  svg {
    width: 16px;
    height: 16px;
    stroke: ${props => props.color};
  }
`;
