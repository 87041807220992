import { produce } from "immer";
import { IApp, IDocumentType } from "../../../shared/models/app.model";
import { IPayloadAction } from "../../../shared/shared.interfaces";
import { AuthenticationActions } from "../../authentication/authentication.store";

export const DocumentsActions = {
  types: {
    FETCH_DOCUMENTS_SUCCESS: "FETCH_DOCUMENTS_SUCCESS",
    FETCH_DOCUMENTS_LIST_SUCCESS: "FETCH_DOCUMENTS_LIST_SUCCESS",
  },
  creators: {
    fetchDocumentsApps: (apps: IApp[], document: string, type: string) => ({
      type: DocumentsActions.types.FETCH_DOCUMENTS_SUCCESS,
      payload: {
        apps,
        document,
        type,
      },
    }),
    fetchDocumentsList: (documents: IDocumentType[]) => ({
      type: DocumentsActions.types.FETCH_DOCUMENTS_LIST_SUCCESS,
      payload: {
        documents,
      },
    }),
  },
};
export interface IDocumentsReducerInterface {
  apps: IApp[];
  document: string;
  type: string;
  documents: IDocumentType[];
}

const initialState: IDocumentsReducerInterface = {
  apps: [],
  document: "",
  type: "",
  documents: [],
};

export const documentsReducer = produce((draft: IDocumentsReducerInterface, action: IPayloadAction<any>) => {
  switch (action.type) {
    case DocumentsActions.types.FETCH_DOCUMENTS_SUCCESS:
      draft.apps = action.payload.apps;
      draft.type = action.payload.type;
      draft.document = action.payload.document;
      return;
    case DocumentsActions.types.FETCH_DOCUMENTS_LIST_SUCCESS:
      draft.documents = action.payload.documents;
      return;
    case AuthenticationActions.types.LOGOUT:
      return initialState;
    default:
      return draft;
  }
}, initialState);
