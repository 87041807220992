import {
  AutoComplete,
  Calendar,
  CalendarIcon,
  Dropdown,
  PrimaryButton,
  TextInput,
  useAlert,
} from "@wit/mpesa-ui-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik } from "formik";
import i18next from "i18next";
import moment from "moment";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { date, object, string } from "yup";
import { ConfigContext, IConfig } from "../../../../app.component";
import EmptyStateCustomerTableIcon from "../../../../shared/icons/empty-state-customer-table.icon";
import { CalendarHeader, Row } from "../../../../shared/shared.styled";
import { getPhoneNumberRegex } from "../../../../shared/shared.utils";
import {
  DropdownLabelContainer,
  IconLabelContainer,
  CalendarSection,
} from "../../../announcements/announcements.styled";
import IdentiyConverterApi from "../identity-converter.api";
import { ICustomerSearchForm, IIdentityComponentsProps } from "../identity-converter.model";
import { IdentityConverterUtils } from "../identity-converter.utils";
import * as S from "../identity-converter.styled";

/**
 * This returns a validation schema for ICustomerSearch
 * @returns
 */
export const getValidationSchema = (config: IConfig | undefined) => {
  return object().shape({
    msisdn: string()
      .required(i18next.t("commons.mandatoryField"))
      .matches(
        getPhoneNumberRegex(config),
        i18next.t("pages.tools.identityConverter.customer.form.errors.validNumberError"),
      ),
    createdOn: date().required(i18next.t("commons.mandatoryField")),
    startTime: string().matches(
      /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])\s([ap][m])$/,
      i18next.t("commons.invalidTime"),
    ),
    expiredOn: date().required(i18next.t("commons.mandatoryField")),
    endTime: string()
      .required(i18next.t("commons.mandatoryField"))
      .matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])\s([ap][m])$/, i18next.t("commons.invalidTime")),
  });
};

/**
 * This return CustomerIdentityConverter component
 * @returns
 */
const CustomerIdentityConverter: FunctionComponent<IIdentityComponentsProps> = ({
  setIsLoading,
  setTableData,
  setEmptyTableComponent,
}) => {
  const [t] = useTranslation();

  const [isValidEndDate, setIsValidEndDate] = React.useState<boolean>(true);
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const { config } = React.useContext(ConfigContext);

  /**
   * This validates dates
   * @param values
   * @param createdOn
   * @param expiredOn
   */
  const validateDates = (values: ICustomerSearchForm, createdOn: string, expiredOn: string | undefined) => {
    const isDatesValid = IdentityConverterUtils.validateDatesAndTimes(values, createdOn, expiredOn);
    setIsValidEndDate(isDatesValid);
  };

  React.useEffect(() => {
    setTableData([]);
    setEmptyTableComponent(<EmptyStateCustomerTableIcon />);
  }, []);

  /**
   * This handles the form submission
   * @param values
   */
  const handleOnSubmit = (values: ICustomerSearchForm) => {
    setIsLoading(true);
    IdentiyConverterApi.methods
      .getCustomerIdentities({
        ...values,
        createdOn: moment(values.createdOn, "YYYY-MM-DD[T]HH:mm:ssZ").valueOf(),
        expiredOn: moment(values.expiredOn, "YYYY-MM-DD[T]HH:mm:ssZ").valueOf(),
      })
      .then(res => {
        setTableData(res.data.identifiers);
      })
      .catch(error => {
        setAlertProps({
          title: t("pages.tools.identityConverter.table.errors.searchIdentities"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /*
   * Component render
   * */
  return (
    <S.Container>
      <S.IdentityConverterContainer>
        <S.IdentityConverterTitle>{t("pages.tools.identityConverter.customer.title")}</S.IdentityConverterTitle>
        <Formik
          onSubmit={handleOnSubmit}
          initialValues={{ msisdn: "", ...IdentityConverterUtils.getInitialDatesAndTimes() }}
          isInitialValid={false}
          validationSchema={getValidationSchema(config)}
          validateOnChange={true}
          validateOnBlur={true}
          render={({ handleChange, values, handleSubmit, errors, setFieldValue, handleBlur, touched, isValid }) => (
            <form onSubmit={handleSubmit}>
              <Row
                style={{
                  gap: "32px",
                  alignItems: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                <S.FieldSection style={{ flex: "1 1 20%" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <S.InputTitle>
                      {t("pages.tools.identityConverter.customer.form.phoneNumberLabel")} <S.InputTitleRequired />
                    </S.InputTitle>

                    <TextInput
                      placeholder={t("pages.tools.identityConverter.customer.form.phoneNumberPlaceholder")}
                      required
                      name="msisdn"
                      value={values.msisdn}
                      error={touched.msisdn ? errors.msisdn : undefined}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </S.FieldSection>
                <S.FieldSection style={{ flex: "1 1 20%" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <S.InputTitle>
                      {t("pages.tools.identityConverter.startDateTimeLabel")} <S.InputTitleRequired />
                    </S.InputTitle>

                    <S.DivDateComponents>
                      <S.DivCalendar>
                        <Dropdown
                          hasValue={false}
                          label={
                            <DropdownLabelContainer>
                              <IconLabelContainer>
                                <CalendarIcon />
                              </IconLabelContainer>{" "}
                              {values.createdOn ? (
                                <CalendarHeader>{moment(values.createdOn).format("DD/MM/YYYY")}</CalendarHeader>
                              ) : (
                                <span>{"DD/MM/YYYY"}</span>
                              )}
                            </DropdownLabelContainer>
                          }
                          dropdownType={DropdownType.RECTANGULAR_NORMAL}
                          error={touched.createdOn ? errors.createdOn : undefined}
                        >
                          {
                            <CalendarSection>
                              <Calendar
                                selectDate={(date: moment.Moment) => {
                                  let dateString: string = date.format("YYYY-MM-DD[T]HH:mm:ssZ").toString();
                                  const regex = new RegExp(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])\s([ap][m])$/);
                                  if (values.startTime && regex.test(values.startTime)) {
                                    [dateString] = IdentityConverterUtils.addTimeToDate(dateString, values.startTime);
                                  }
                                  IdentityConverterUtils.setStartDate(setFieldValue, dateString, values);
                                  validateDates(values, dateString, undefined);
                                  setFieldValue("createdOn", dateString);
                                }}
                                shouldAllowPastDates={true}
                                shouldAllowFutureDates={false}
                              />
                            </CalendarSection>
                          }
                        </Dropdown>
                      </S.DivCalendar>
                      {
                        <S.DivTime>
                          <AutoComplete
                            value={values.startTime ? values.startTime : ""}
                            placeholder={t("pages.tools.identityConverter.placeholderTime")}
                            options={IdentityConverterUtils.timeOptions()}
                            maxLength={8}
                            selectOption={(time: string) => {
                              setFieldValue("startTime", time);
                              if (time.slice(-2) === "am" || time.slice(-2) === "pm") {
                                const regex = new RegExp(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])\s([ap][m])$/);
                                if (regex.test(time)) {
                                  const [date] = IdentityConverterUtils.addTimeToDate(
                                    values.createdOn ? values.createdOn : moment().format("YYYY-MM-DD[T]HH:mm:ssZ"),
                                    time,
                                  );
                                  validateDates(values, date, undefined);
                                  setFieldValue("startTime", time);
                                }
                              }
                            }}
                            error={touched.startTime ? errors.startTime : undefined}
                            name="startTime"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </S.DivTime>
                      }
                    </S.DivDateComponents>
                  </div>
                </S.FieldSection>
                <S.FieldSection style={{ flex: "1 1 20%" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <S.InputTitle>
                      {t("pages.tools.identityConverter.endDateTimeLabel")} <S.InputTitleRequired />
                    </S.InputTitle>

                    <S.DivDateComponents>
                      <S.DivCalendar>
                        <Dropdown
                          hasValue={false}
                          label={
                            <DropdownLabelContainer>
                              <IconLabelContainer>
                                <CalendarIcon />
                              </IconLabelContainer>{" "}
                              {values.expiredOn ? (
                                <CalendarHeader>{moment(values.expiredOn).format("DD/MM/YYYY")}</CalendarHeader>
                              ) : (
                                <span>{"DD/MM/YYYY"}</span>
                              )}
                            </DropdownLabelContainer>
                          }
                          dropdownType={DropdownType.RECTANGULAR_NORMAL}
                          error={errors.expiredOn && touched.expiredOn ? t("commons.mandatoryField") : undefined}
                        >
                          {
                            <CalendarSection>
                              <Calendar
                                selectDate={(date: moment.Moment) => {
                                  let dateString: string = date.format("YYYY-MM-DD[T]HH:mm:ssZ").toString();
                                  const regex = new RegExp(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])\s([ap][m])$/);
                                  if (values.endTime && regex.test(values.endTime)) {
                                    [dateString] = IdentityConverterUtils.addTimeToDate(dateString, values.endTime);
                                  }

                                  setFieldValue("expiredOn", dateString);
                                  validateDates(values, values.createdOn, dateString);
                                }}
                                shouldAllowPastDates={true}
                                shouldAllowFutureDates={false}
                              />
                            </CalendarSection>
                          }
                        </Dropdown>
                      </S.DivCalendar>
                      {
                        <S.DivTime>
                          <AutoComplete
                            value={values.endTime ? values.endTime : ""}
                            placeholder={t("pages.tools.identityConverter.placeholderTime")}
                            options={IdentityConverterUtils.timeOptions()}
                            maxLength={8}
                            selectOption={(time: string) => {
                              setFieldValue("endTime", time);
                              if (time.slice(-2) === "am" || time.slice(-2) === "pm") {
                                const regex = new RegExp(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])\s([ap][m])$/);
                                if (regex.test(time)) {
                                  const [date] = IdentityConverterUtils.addTimeToDate(
                                    values.expiredOn ? values.expiredOn : moment().format("YYYY-MM-DD[T]HH:mm:ssZ"),
                                    time,
                                  );
                                  setFieldValue("endTime", time);
                                  validateDates(values, values.createdOn, date);
                                  setFieldValue("expiredOn", date);
                                }
                              }
                            }}
                            error={
                              touched.endTime && errors.endTime
                                ? errors.endTime
                                : !isValidEndDate
                                ? t("pages.tools.identityConverter.customer.form.errors.errorSelectEndTime")
                                : undefined
                            }
                            name="endTime"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </S.DivTime>
                      }
                    </S.DivDateComponents>
                  </div>
                </S.FieldSection>

                <S.ButtonContainer style={{ flex: "1 1 20%" }}>
                  <PrimaryButton
                    disabled={!isValidEndDate || !isValid}
                    titleLabel={t("pages.tools.identityConverter.customer.form.buttonConvertLabel")}
                    type="submit"
                    redTheme={true}
                    style={{ width: "fit-content" }}
                  />
                </S.ButtonContainer>
              </Row>
            </form>
          )}
        />
      </S.IdentityConverterContainer>
    </S.Container>
  );
};

export default CustomerIdentityConverter;
