import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { IStoreInterface } from "../../../configs/store.config";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  SearchBar,
  MultipleOptionsDropdown,
  SmallButton,
  PlusIcon,
  useModal,
  ModalTypeEnum,
  useAlert,
} from "@wit/mpesa-ui-components";
import { renderTableButtons, getExternalServicePermissions } from "../../../shared/shared.utils";
import { RoutesEnum } from "../../../routes/routes.constants";
import { useTranslation } from "react-i18next";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { IExternalServices, ExternalServicePermitionsEnum } from "../../../shared/models/service-manager.model";
import { getExternalServicesColumns } from "./configurations.utils";
import ExternalServicesApi from "./external-services.api";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { ExternalServicesActions } from "./external-services.store";
import LoadingComponent from "../../../shared/components/loading-component/LoadingComponent";
import SafaricomDeleteModal from "../../../shared/components/safaricom-delete-modal/safaricom-delete-modal.component";
import { useHistory } from "react-router-dom";

/** ExternalServices page */
const ExternalServicesPage = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const [selectedExternalService, setSelectedExternalService] = React.useState<IExternalServices | undefined>(
    undefined,
  );

  const [showDeleteModal, hideDeleteModal] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <SafaricomDeleteModal
      secondaryAction={() => hideDeleteModal()}
      primaryAction={() => deleteRequest()}
      title={t("pages.externalServices.deleteModal.title")}
      description={t("pages.externalServices.deleteModal.description")}
      primaryBtnLabel={t("pages.externalServices.deleteModal.btnLabel")}
    />,
  );

  const [searchString, setSearchString] = useState("");
  const [permissionsSelected, setPermissionsSelected] = useState<string[]>([]);

  const isLoadingExternalServices = useSelector(
    (state: IStoreInterface) => state.externalServicesReducer.isLoadingExternalServices,
  );
  const persistedExternalServices = useSelector(
    (store: IStoreInterface) => store.externalServicesReducer.externalServices,
  );
  const [externalServices, setExternalServices] = useState(persistedExternalServices);

  const refreshPage = useCallback(() => {
    dispatch(ExternalServicesActions.creators.fetchingExternalServicesAction());
    ExternalServicesApi.methods.getExternalServices().then(
      res => {
        dispatch(ExternalServicesActions.creators.fetchExternalServicesSuccessAction(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.serviceBuilder.errors.searchServices"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  }, [dispatch, t, showAlert, setAlertProps]);

  React.useEffect(() => {
    refreshPage();
    return () => {
      dispatch(ExternalServicesActions.creators.fetchExternalServicesLeaveAction());
    };
  }, [dispatch, t, showAlert, setAlertProps]);

  React.useEffect(() => {
    if (persistedExternalServices) {
      let visibleExternalServices = [...persistedExternalServices];

      if (searchString) {
        visibleExternalServices = visibleExternalServices.filter(
          es =>
            es.serviceId.toLowerCase().includes(searchString.toLowerCase()) ||
            es.url.includes(searchString.toLowerCase()),
        );
      }
      if (permissionsSelected.length > 0) {
        if (permissionsSelected.includes("PIN")) {
          visibleExternalServices = visibleExternalServices.filter(
            es => es.permissions !== undefined && es.permissions.includes(ExternalServicePermitionsEnum.PIN),
          );
        }

        if (permissionsSelected.includes("IDENTITY")) {
          visibleExternalServices = visibleExternalServices.filter(
            es => es.permissions !== undefined && es.permissions.includes(ExternalServicePermitionsEnum.IDENTITY),
          );
        }
      }
      setExternalServices(visibleExternalServices);
    } else {
      setExternalServices([]);
    }
  }, [persistedExternalServices, searchString, permissionsSelected]);

  /** function to redirect to add new external */
  const addNewExternalService = () => {
    history.push(RoutesEnum.ADD_NEW_EXTERNAL_SERVICE);
  };

  /** function to redirect to edit external service */
  const editExternalService = (externalService: IExternalServices) => {
    history.push(RoutesEnum.EXTERNAL_SERVICES_DETAILS.replace(":externalServiceID", externalService.serviceId));
  };

  /** function deleteRequest */
  const deleteRequest = () => {
    if (selectedExternalService) {
      ExternalServicesApi.methods
        .deleteExternalService(selectedExternalService.serviceId)
        .then(
          () => {
            setAlertProps({
              title: t("pages.externalServices.deleteRequest.titleDone"),
              type: AlertTypeEnum.SUCCESS,
            });
            showAlert();
            ExternalServicesActions.creators.fetchingExternalServicesAction();
            ExternalServicesApi.methods.getExternalServices().then(res => {
              dispatch(ExternalServicesActions.creators.fetchExternalServicesSuccessAction(res.data));
            });
          },
          () => {
            setAlertProps({
              title: t("pages.externalServices.deleteRequest.titleError"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          },
        )
        .finally(hideDeleteModal);
    }
  };

  /** function to call function to delete external service */
  const deleteExternalService = (externalService: IExternalServices) => {
    setSelectedExternalService(externalService);
    showDeleteModal();
  };

  function isOptionSelected(opt: SharedDropdownOption) {
    return permissionsSelected.includes(opt.key);
  }

  function toggleOption(opt: SharedDropdownOption) {
    if (isOptionSelected(opt)) {
      setPermissionsSelected(permissionsSelected.filter(c => c !== opt.key));
    } else {
      setPermissionsSelected([...permissionsSelected, opt.key]);
    }
  }

  function resetDropdown() {
    setPermissionsSelected([]);
  }
  return (
    <>
      {isLoadingExternalServices ? (
        <LoadingContainer>
          <div>
            <LoadingComponent />
          </div>
        </LoadingContainer>
      ) : (
        <>
          <FiltersContainer>
            <div style={{ flex: "1 0 40%" }}>
              <SearchBar
                placeholderLabel={t("pages.externalServices.filters.placeholderSearch")}
                value={searchString}
                onChange={e => setSearchString(e.target.value)}
                clearValue={() => setSearchString("")}
              />
            </div>

            <div style={{ flex: "1 0 20%" }}>
              <MultipleOptionsDropdown
                label={
                  permissionsSelected.length > 0
                    ? t("pages.serviceBuilder.filters.labelOfStatusSelected", {
                        labelOfSelectedStatus: permissionsSelected,
                      })
                    : t("pages.externalServices.filters.allPermissions")
                }
                toggleOption={opt => toggleOption(opt)}
                clearAllFilters={() => resetDropdown()}
                hasValue={false}
                isOptionSelected={opt => isOptionSelected(opt)}
                options={getExternalServicePermissions()}
              />
            </div>
            <SmallButtonsContainer style={{ flex: "1 1 40%", justifyContent: "flex-end" }}>
              <SmallButton
                onClick={addNewExternalService}
                titleLabel={t("pages.externalServices.filters.description")}
                iconComponent={
                  <IconContainer>
                    <PlusIcon />
                  </IconContainer>
                }
              />
            </SmallButtonsContainer>
          </FiltersContainer>
          <TableWrapper className="tableWrapper">
            <Table<IExternalServices>
              columns={getExternalServicesColumns(t)}
              values={externalServices}
              displayActions={service =>
                renderTableButtons<IExternalServices>(service, {
                  edit: { onClick: editExternalService },
                  delete: { onClick: deleteExternalService },
                })
              }
            />
          </TableWrapper>
        </>
      )}
    </>
  );
};

export default ExternalServicesPage;

const TableWrapper = styled.div`
  > div:first-child {
    > div:last-child {
      > div {
        padding: 0;
        border-bottom: 1px solid;
        border-bottom-color: ${props => props.theme.palette.lightGrey};
      }
    }
  }
`;

const FiltersContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 32px;
  gap: 24px;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
`;

const SmallButtonsContainer = styled("div")`
  margin-left: auto;
  display: flex;
`;

const IconContainer = styled("div")`
  stroke: ${props => props.theme.palette.vodafoneRed};
  width: 20px;
  height: 20px;

  svg {
    height: 20px;
  }
`;

const LoadingContainer = styled("div")`
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
