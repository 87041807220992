import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { DocValidationUtils } from "../../doc-validation/doc-validation.utils";
import { IClientDocDashCounter } from "../doc-dashboard.interface";

export const DocDashSplittedCard: FC<{ entry: IClientDocDashCounter }> = ({ entry }) => {
  const { t } = useTranslation();

  if (!entry) {
    entry = ({
      status: entry,
      total: 0,
      percentage: 0,
      lastUpdate: Date.now(),
    } as unknown) as IClientDocDashCounter;
  }

  const color = DocValidationUtils.getClientDocStatusColor(`${entry.status}`);

  return (
    <DocErrorItem>
      <DocErrorLabel color={color}>{t(`commons.clientDocSubmissionStatus.${entry.status}`)}</DocErrorLabel>
      <DocErrorCounterContainer>
        <DocErrorCounter color={color}>{entry.total}</DocErrorCounter>
        <DocErrorPercentage color={styleTheme.palette.midGrey}>({entry.percentage})%</DocErrorPercentage>
      </DocErrorCounterContainer>
    </DocErrorItem>
  );
};

const DocErrorItem = styled("li")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.palette.aluminium};
  margin-bottom: 9px;
  padding-bottom: 8px;
`;

const DocErrorLabel = styled("div")<{ color: string }>`
  width: fill-available;
  font-family: "Vodafone Rg";
  font-size: 14px;
  color: ${({ color }) => color};
`;

const DocErrorCounterContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
  width: 35%;
  font-family: "Vodafone Rg";
  font-size: 14px;
`;

const DocErrorCounter = styled("div")<{ color: string }>`
  color: ${({ color }) => color};
`;

const DocErrorPercentage = styled("div")<{ color: string }>`
  width: 38px;
  display: flex;
  font-size: 11px;
  margin-left: 7px;
  justify-content: flex-end;
  align-items: center;
  color: ${({ color }) => color};
`;
