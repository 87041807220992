import { AxiosPromise } from "axios";
import ApiClient from "../../../../configs/axios.config";
import { IAddAppPropertyRequest } from "./app-properties.model";

/*
 * App properties API methods
 * */
const AppPropertiesApi = {
  routes: {
    getAppProperties: "/v1/apps/:appId/properties",
    getAppPropertiesLastVersion: "/v1/apps/:appId/properties?previousVersion=true",
    revertChanges: "/v1/apps/:appId/properties/revert",
    publish: "/v1/apps/:appId/properties/publish",
    deleteAppProperty: "/v1/apps/:appId/properties/:id",
    addProperty: "/v1/apps/:appId/properties/",
    editProperty: "/v1/apps/:appId/properties/:id",
  },
  methods: {
    getAppProperties: (appId: string): AxiosPromise => {
      return ApiClient.get(AppPropertiesApi.routes.getAppProperties.replace(":appId", appId), {});
    },
    getLastVersion: (appId: string): AxiosPromise => {
      return ApiClient.get(AppPropertiesApi.routes.getAppPropertiesLastVersion.replace(":appId", appId), {});
    },
    publish: (appId: string): AxiosPromise => {
      return ApiClient.put(AppPropertiesApi.routes.publish.replace(":appId", appId));
    },
    revertChanges: (appId: string): AxiosPromise => {
      return ApiClient.put(AppPropertiesApi.routes.revertChanges.replace(":appId", appId));
    },
    deleteAppProperty: (id: string, appId: string): AxiosPromise => {
      return ApiClient.delete(`${AppPropertiesApi.routes.getAppProperties.replace(":appId", appId)}/${id}`, {});
    },
    addProperty: (appProperty: IAddAppPropertyRequest, appId: string): AxiosPromise => {
      return ApiClient.post(AppPropertiesApi.routes.addProperty.replace(":appId", appId), appProperty);
    },
    editProperty: (appProperty: IAddAppPropertyRequest, id: string, appId: string): AxiosPromise => {
      return ApiClient.put(
        AppPropertiesApi.routes.editProperty.replace(":appId", appId).replace(":id", id),
        appProperty,
      );
    },
  },
};

export default AppPropertiesApi;
