import React from "react";
import { PageContainer, PageTitle } from "../../shared/shared.styled";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { RoutesEnum } from "../../routes/routes.constants";
import checkComponentDisplayStatus from "../../shared/hooks/use-check-available-feature-component.hook";
import { toTranslationStringKey, getNumberOfDaysFromNow } from "../../shared/shared.utils";
import { FEATURES } from "../../shared/renderer.utils";
import { StatusChip } from "@wit/mpesa-ui-components";
import checkUserRolePermission from "../../shared/hooks/use-check-role-permission-denied.hook";
import { UserRolesSFC } from "../admin/users/users.interfaces";
import { useSelector } from "react-redux";
import { IStoreInterface } from "../../configs/store.config";
import { ILoggedUser } from "../authentication/authentication.interfaces";
import { StatusEnum } from "@wit/mpesa-ui-components/lib/enums/status.enum";
import { NEW_FEATURE_DAYS } from "../../shared/shared.constants";

/**
 * Contains the Tools cards configurations
 */
const cardsConfiguration = [
  {
    name: FEATURES.IDENTITY_CONVERTER,
    id: "identity-converter",
  },
  {
    name: FEATURES.PUSH_NOTIFICATIONS,
    id: "push-notifications",
    allowedSFCUsers: UserRolesSFC.REQUEST_MONEY_MANAGER,
    addedOn: "01-07-2022",
  },
  {
    name: FEATURES.REQUEST_MONEY,
    id: "request-money",
  },
  {
    name: FEATURES.REQUEST_PAYMENT,
    id: "request-payment",
  },
  {
    name: FEATURES.SURVEYS,
    id: "Surveys",
    allowedSFCUsers: UserRolesSFC.REQUEST_MONEY_MANAGER,
  },
];

/**
 * ToolsPage component
 */
const ToolsPage = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const loggedUser = useSelector((state: IStoreInterface) => state.authenticationReducer.loggedUser) as ILoggedUser;

  return (
    <PageContainer>
      <PageTitle>{t("pages.tools.title")}</PageTitle>
      <ToolsCardsContainer>
        {cardsConfiguration.map((item, index) => {
          return checkComponentDisplayStatus(
            checkUserRolePermission(
              <ToolsCard
                key={index}
                id={item.id.toLowerCase()}
                onClick={() => {
                  history.push(RoutesEnum[item.name as keyof typeof RoutesEnum]);
                }}
              >
                <ToolsCardsTitle>{t(`pages.tools.${toTranslationStringKey(item.id)}.title`)}</ToolsCardsTitle>
                {item.addedOn && getNumberOfDaysFromNow(item.addedOn as string) < NEW_FEATURE_DAYS ? (
                  <div style={{ width: "20px" }}>
                    <StatusChip type={StatusEnum.LIVE}>{t("pages.configuration.new")}</StatusChip>
                  </div>
                ) : null}
                <ToolsCardsSubTitle>{t("pages.tools.seeMore")}</ToolsCardsSubTitle>
              </ToolsCard>,
              loggedUser,
              [item.allowedSFCUsers as UserRolesSFC],
            ),
            item.name,
          );
        })}
      </ToolsCardsContainer>
    </PageContainer>
  );
};

export default ToolsPage;

const ToolsCardsContainer = styled("div")`
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 50px;
`;

const ToolsCard = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 158px;
  height: 167px;
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 24px;
  margin-right: 24px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  &:hover {
    cursor: pointer;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  }
`;

const ToolsCardsTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.palette.black};
`;

const ToolsCardsSubTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 13px;
  color: ${props => props.theme.palette.greyDarker};
`;
