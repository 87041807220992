export interface IAppProperties {
  properties: IAppProperty[];
  hasPreviousVersion: boolean;
  hasUnpublishedChanges: boolean;
  status: AppPropertyStatusEnum;
}

export interface IAppProperty {
  fieldName: string;
  value: string;
  status: AppPropertyStatusEnum;
  id: string;
}

export enum AppPropertyStatusEnum {
  EDIT = "EDIT",
  LIVE = "LIVE",
  DELETE = "DELETE",
  DISABLE = "DISABLE",
  DISABLED = "DISABLED",
  ENABLE = "ENABLE",
}

export interface IAddAppPropertyRequest {
  fieldName: string;
  value: string;
  status: AppPropertyStatusEnum;
}
