import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { PrimaryButton, TextArea } from "@wit/mpesa-ui-components";

interface ITaskDeclineModalProps {
  cancelAction: () => void;
  confirmAction: (reason: string) => void;
}

const TaskDeclineModal = ({ cancelAction, confirmAction }: ITaskDeclineModalProps) => {
  // Hooks initialization
  const [t] = useTranslation();
  const [reason, setReason] = useState("");

  return (
    <TaskDeclineForm id="taskDeclineForm">
      <Title>{t("pages.myTasks.declineModal.title")}</Title>
      <Description>{t("pages.myTasks.declineModal.description")}</Description>
      <div>
        <TextArea
          title={t("pages.myTasks.declineModal.textAreaTitle")}
          placeholder={t("pages.myTasks.declineModal.textAreaPlaceholder")}
          name="reason"
          value={reason}
          verticalResize={false}
          style={{ minHeight: "79px", fontSize: "16px" }}
          onChange={evt => {
            setReason(evt.target.value);
          }}
        />
      </div>
      <TaskDeclineButtonsWrapper>
        <TaskDeclineButtonDiv>
          <PrimaryButton
            id={"cancel-reject-button"}
            titleLabel={t("pages.myTasks.declineModal.cancelButton")}
            onClick={() => {
              cancelAction();
            }}
            redTheme={false}
            loading={false}
            style={{ width: "111px" }}
          />
        </TaskDeclineButtonDiv>
        <TaskDeclineButtonDiv>
          <PrimaryButton
            id={"confirm-reject-button"}
            titleLabel={t("pages.myTasks.declineModal.rejectButton")}
            onClick={() => {
              confirmAction(reason);
            }}
            redTheme={true}
            loading={false}
            style={{ width: "100px" }}
          />
        </TaskDeclineButtonDiv>
      </TaskDeclineButtonsWrapper>
    </TaskDeclineForm>
  );
};

export default TaskDeclineModal;

const TaskDeclineForm = styled("div")`
  display: flex;
  padding: 24px;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const TaskDeclineButtonDiv = styled("div")`
  margin-right: 12px;
  &:last-of-type {
    margin-right: 0;
  }
`;
const TaskDeclineButtonsWrapper = styled("div")`
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
`;

const Title = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 13px;
`;
const Description = styled("div")`
  font-family: Vodafone Rg;
  font-size: 18px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 24px;
`;
