import { Table, useAlert, useFilters } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { IStoreInterface } from "../../../../configs/store.config";
import TablePagination from "../../../../shared/components/table-pagination/table-pagination-component";
import { IMiniAppsLog } from "../../../../shared/models/mini-apps-log.model";
import { LoadingText } from "../../../../shared/shared.styled";
import SupportApi from "../../support.api";
import { MiniAppsLogStatusEnum, IMiniAppsReportLogsRequest } from "../../support.interfaces";
import { SupportActions } from "../../support.store";
import { SupportUtils } from "../../support.utils";
import MiniAppsLogsFilters, { MiniAppsFilterKeys } from "./mini-apps-logs-filters.component";
import RequestDrawer from "./mini-apps-request-drawer.component";

/**
 * Mini Apps logs tab
 * @returns
 */
const MiniAppsLogsTab = () => {
  // Hooks init
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const {
    filters,
    updateFilter,
    isFilterActive,
    clearFilter,
    resetFilters,
    getFilterValue,
    getFiltersQueryString,
    updateMultipleFilters,
  } = useFilters();

  // Local state
  const firstUpdate = React.useRef(true);
  const [isFetchingLogs, setIsFetchingLogs] = React.useState(false);
  const [drawerIsOpen, setDrawerIsOpen] = React.useState(false);
  const [selectedLog, setSelectedLog] = React.useState<IMiniAppsLog | undefined>();
  const [page, setActivePage] = React.useState(1);
  const pageSize = 20;
  const [requestParams, setRequestParams] = React.useState<IMiniAppsReportLogsRequest>({
    page: 1,
    pageSize: 20,
  });
  const [requestParamsTemp, setRequestParamsTemp] = React.useState<IMiniAppsReportLogsRequest>({
    page: 1,
    pageSize: 20,
  });

  // Redux state
  const { miniAppsLogs, miniAppsLogsCount } = useSelector((state: IStoreInterface) => state.supportReducer);

  /**
   * Show log drawer
   * @param miniAppsLog
   */
  const showLogDrawer = (miniAppsLog: IMiniAppsLog) => {
    setSelectedLog(miniAppsLog);
    setDrawerIsOpen(true);
  };

  /*
   * Get miniApps logs on page change
   * */
  React.useEffect(() => {
    setRequestParams({ ...requestParams, page: page });
  }, [dispatch, page]);

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    getMiniAppsLogs();
  }, [requestParams]);

  /*
   * Updates the request state each time the filters are changed
   * */
  React.useEffect(() => {
    let request: IMiniAppsReportLogsRequest = { page, pageSize };
    const dateFilter = getDateFilter();
    if (!dateFilter) {
      const timeFilter = getTimeFilter();
      request = { ...request, ...formatTimeFilter(timeFilter) };
    } else {
      request = { ...request, ...dateFilter };
    }
    request = {
      ...request,
      ...getMiniAppsFilterKey(MiniAppsFilterKeys.REQUEST_ID),
      ...getMiniAppsFilterKey(MiniAppsFilterKeys.REQUEST_CONTENT),
      ...getMiniAppsFilterKey(MiniAppsFilterKeys.SYNC_RESPONSE_CONTENT),
      ...getMiniAppsFilterKey(MiniAppsFilterKeys.OPERATION),
      ...getStatusFilter(),
    };
    setRequestParamsTemp(request);
  }, [filters]);

  /**
   * Refresh results handler
   * */
  const refreshResults = () => {
    setActivePage(1);
    setRequestParams({ ...requestParamsTemp, page: 1 });
  };

  /**
   * Returns the status filter
   * */
  const getStatusFilter = () => {
    if (filters.get(MiniAppsFilterKeys.STATUS)) {
      const status = (filters.get(MiniAppsFilterKeys.STATUS) as string[])[0];
      // @ts-ignore
      return { requestStatus: MiniAppsLogStatusEnum[status] };
    }
    return null;
  };

  /**
   * Get mini apps filter key
   * @param filterKey
   * @returns
   */
  const getMiniAppsFilterKey = (filterKey: MiniAppsFilterKeys) => {
    if (filters.get(filterKey)) {
      return { [filterKey]: (filters.get(filterKey) as string[])[0] };
    }
    return null;
  };

  /**
   * Format time filter
   * */
  const formatTimeFilter = (timeFilter: { startTime: string[]; endTime: string[] } | null) => {
    if (timeFilter) {
      const startDate = moment()
          .utcOffset(0)
          .hours(Number(timeFilter.startTime[0]))
          .minutes(Number(timeFilter.startTime[1]))
          .seconds(0),
        endDate = moment()
          .utcOffset(0)
          .hours(Number(timeFilter.endTime[0]))
          .minutes(Number(timeFilter.endTime[1]))
          .seconds(0);
      if (endDate.isBefore(startDate)) {
        endDate.add(1, "d");
      }
      return { startDate: startDate.unix(), endDate: endDate.unix() };
    }
    return null;
  };

  /**
   * Returns the time filter object
   * */
  const getTimeFilter = () => {
    if (filters.get(MiniAppsFilterKeys.TIME_RANGE)) {
      const startTime = (filters.get(MiniAppsFilterKeys.TIME_RANGE) as string[])[0].split(":");
      const endTime = (filters.get(MiniAppsFilterKeys.TIME_RANGE) as string[])[1].split(":");
      return { startTime: startTime, endTime: endTime };
    }
    return null;
  };

  /**
   * Returns the date filter object
   * */
  const getDateFilter = () => {
    if (filters.get(MiniAppsFilterKeys.START_DATE)) {
      let startDate,
        endDate = null;
      const timeFilter = getTimeFilter();
      if (timeFilter) {
        startDate = moment((filters.get(MiniAppsFilterKeys.START_DATE) as string[])[0])
          .utcOffset(0)
          .hours(Number(timeFilter.startTime[0]))
          .minutes(Number(timeFilter.startTime[1]))
          .seconds(0);
        endDate = moment((filters.get(MiniAppsFilterKeys.END_DATE) as string[])[0])
          .utcOffset(0)
          .hours(Number(timeFilter.endTime[0]))
          .minutes(Number(timeFilter.endTime[1]))
          .seconds(59);
      } else {
        startDate = moment((filters.get(MiniAppsFilterKeys.START_DATE) as string[])[0])
          .utcOffset(0)
          .hours(0)
          .minutes(0)
          .seconds(0);
        endDate = moment((filters.get(MiniAppsFilterKeys.END_DATE) as string[])[0])
          .utcOffset(0)
          .hours(0)
          .minutes(0)
          .seconds(0)
          .add(1, "d");
      }
      return { startDate: startDate.unix(), endDate: endDate.unix() };
    }
    return null;
  };

  /**
   * Get miniApps logs method
   * */
  const getMiniAppsLogs = () => {
    setIsFetchingLogs(true);
    SupportApi.methods
      .getMiniAppsLogs(requestParams)
      .finally(() => setIsFetchingLogs(false))
      .then(
        res => {
          const { metadata, results } = res.data;
          dispatch(SupportActions.Creators.fetchMiniAppsLogsSuccessAction(results, metadata?.resultsCount));
        },
        () => {
          dispatch(SupportActions.Creators.fetchMiniAppsLogsErrorAction());
          setAlertProps({
            title: t("pages.support.miniAppsLogs.getLogsError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
  };

  /**
   * Handles the pagination change
   * */
  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
  };
  return (
    <>
      <MiniAppsLogsTabContainer>
        <MiniAppsLogsFilters
          filters={filters}
          isFilterActive={isFilterActive}
          updateFilter={updateFilter}
          clearFilter={clearFilter}
          resetFilters={resetFilters}
          getFilterValue={getFilterValue}
          getFiltersQueryString={getFiltersQueryString}
          updateMultipleFilters={updateMultipleFilters}
          miniAppsLogsCount={miniAppsLogsCount}
          refreshResults={refreshResults}
        />
        {isFetchingLogs ? (
          <LoadingText>{t("commons.loadingResults")}</LoadingText>
        ) : (
          <>
            <Table
              rowClickAction={(idx, log) => showLogDrawer(log)}
              values={miniAppsLogs || []}
              columns={SupportUtils.getMiniAppsLogsColumns()}
            />
          </>
        )}
        {!isFetchingLogs && miniAppsLogsCount ? (
          <TablePagination
            handlePageChange={handlePageChange}
            totalItems={miniAppsLogsCount}
            activePage={page}
            pageSize={pageSize}
          />
        ) : null}
      </MiniAppsLogsTabContainer>
      {drawerIsOpen ? (
        <RequestDrawer
          miniAppsReportLogId={(selectedLog as IMiniAppsLog).id}
          miniAppsReportLogRequestId={(selectedLog as IMiniAppsLog).requestId}
          hideDrawer={() => setDrawerIsOpen(false)}
        />
      ) : null}
    </>
  );
};

export default MiniAppsLogsTab;

const MiniAppsLogsTabContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;
