import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { PageContainer, Row } from "../../../shared/shared.styled";
import {
  SecondaryPageTitle,
  TextInput,
  AutoClosingDropdown,
  PrimaryButton,
  FormSection,
  useAlert,
  Checkbox,
} from "@wit/mpesa-ui-components";
import styled from "styled-components";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import i18next from "i18next";
import BundlesApi from "../bundles/bundles.api";
import { ICurrency } from "../../../shared/shared.interfaces";
import RateCardsApi from "./rate-cards.api";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { IChargeProfile } from "./rate-cards.model";
import { getCurrenciesDropdown } from "./rate-cards.utils";

import { RoutesEnum } from "../../../routes/routes.constants";
import { PageContent } from "../../../shared/responsive-ui.styled";

/**
 * Form validation schema
 */
const validationSchema = () => {
  return object().shape({
    externalId: string()
      .required(i18next.t("pages.rateCards.chargeProfileModal.requiredField"))
      .nullable()
      .max(255, i18next.t("pages.rateCards.chargeProfileModal.maxLenDescription")),
    name: string()
      .required(i18next.t("pages.rateCards.chargeProfileModal.requiredField"))
      .max(255, i18next.t("pages.rateCards.chargeProfileModal.maxLenDescription")),
    reasonType: string().required(i18next.t("pages.rateCards.chargeProfileModal.requiredField")),
    chargeType: string().required(i18next.t("pages.rateCards.chargeProfileModal.requiredField")),
    currencyCode: string().required(i18next.t("pages.rateCards.chargeProfileModal.requiredField")),
  });
};

const EditChargeProfilePage = () => {
  const [t] = useTranslation();
  const { id } = useParams<any>();
  const [currencies, setCurrencies] = useState<any>();
  const [reasons, setReasons] = useState([]);
  const [types, setTypes] = useState([]);
  const [showAlert, , setAlertProps] = useAlert();
  const [profileData, setProfileData] = useState<any>();
  const history = useHistory();
  const isEdit = !!id;

  const getFormInputs = (
    values: any,
    handleChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined,
    setFieldValue: any,
    errors: any,
  ) => {
    const rows = [];
    rows.push(
      {
        label: t("pages.rateCards.chargeProfileModal.reasonType"),
        displayComponent: null,
        editingComponent: (
          <DropdownContainer id={"reason-type-dropdown"}>
            <AutoClosingDropdown
              hasValue={values.reasonType !== ""}
              label={values.reasonType ? values.reasonType : t("pages.rateCards.selectReasonType")}
              options={reasons}
              error={errors.reasonType}
              selectOption={opt => {
                setFieldValue("reasonType", opt.label);
                setFieldValue("chargeType", "");
                getChargeTypes(opt.key);
              }}
            />
          </DropdownContainer>
        ),
      },
      {
        label: t("pages.rateCards.chargeProfileModal.chargeType"),
        displayComponent: null,
        editingComponent: (
          <DropdownContainer id={"charge-type-dropdown"}>
            <AutoClosingDropdown
              hasValue={values.chargeType !== ""}
              label={values.chargeType ? values.chargeType : t("pages.rateCards.selectChargeType")}
              options={types}
              error={errors.chargeType}
              selectOption={opt => {
                setFieldValue("chargeType", opt.label);
              }}
            />
          </DropdownContainer>
        ),
      },
      {
        label: t("pages.rateCards.chargeProfileModal.chargeProfilePlaceholder"),
        displayComponent: null,
        editingComponent: (
          <TextInput
            name="name"
            value={values.name}
            onChange={handleChange}
            required={true}
            error={errors.name}
            placeholder={t("pages.rateCards.chargeProfileModal.enterChargeProfileName")}
          />
        ),
      },
      {
        label: t("pages.rateCards.chargeProfileModal.currency"),
        displayComponent: null,
        editingComponent: (
          <DropdownContainer id={"currencies-dropdown"}>
            <AutoClosingDropdown
              hasValue={values.currencyCode !== ""}
              label={
                values.currencyCode
                  ? t(`commons.currencies.${values.currencyCode}`)
                  : t("pages.rateCards.selectCurrency")
              }
              options={getCurrenciesDropdown(currencies, t)}
              error={errors.currencyCode}
              selectOption={opt => setFieldValue("currencyCode", opt.key)}
            />
          </DropdownContainer>
        ),
      },
      {
        label: t("pages.rateCards.chargeProfileModal.chargeId"),
        displayComponent: null,
        editingComponent: (
          <TextInput
            name="externalId"
            value={values.externalId}
            onChange={handleChange}
            required={true}
            error={errors.externalId}
            placeholder={t("pages.rateCards.chargeProfileModal.chargeIdPlaceholder")}
          />
        ),
      },
    );
    return (
      <FormSection title={t("pages.rateCards.chargeProfileModal.chargeTypeDetails")} rows={rows} isEditing={true} />
    );
  };

  /*
   * Get currencies effect
   * */
  React.useEffect(() => {
    BundlesApi.methods.getCurrencies().then(
      res => {
        const c = res.data.currencies.map((curr: ICurrency) => {
          return curr.currencyCode;
        });
        setCurrencies(c);
      },
      () => {
        setAlertProps({
          title: t("pages.rateCards.errors.title"),
          content: t("pages.rateCards.errors.currency"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  }, []);

  React.useEffect(() => {
    if (isEdit && profileData !== undefined && reasons.length) {
      const reasonType = reasons.find((e: any) => {
        return e.label === profileData.reasonType;
      });
      if (reasonType) {
        getChargeTypes((reasonType as any).key);
      }
    }
  }, [reasons, profileData, isEdit]);

  /*
   * Get ChargeProfile
   * */
  React.useEffect(() => {
    if (id) {
      RateCardsApi.methods.getChargeProfile(id).then(
        res => {
          setProfileData(res.data);
        },
        () => {
          setAlertProps({
            title: t("pages.rateCards.chargeProfileModal.getProfileError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
    } else {
      setProfileData({
        chargeType: "",
        currencyCode: "",
        externalId: null,
        hasPreviousVersion: true,
        hasUnpublishedChanges: true,
        id: "",
        name: "",
        rangeValuesRequired: true,
        ranges: [],
        reasonType: "",
      });
    }
  }, []);

  /*
   * Get ReasonTypes
   * */
  React.useEffect(() => {
    RateCardsApi.methods.getReasonTypes().then(
      res => {
        const list = res.data.reasonTypeList.map((opt: any) => {
          return {
            key: opt.reasonValue,
            label: opt.reasonLabel,
          };
        });
        setReasons(list);
      },
      () => {
        setAlertProps({
          title: t("pages.rateCards.chargeProfileModal.getReasonTypesError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        setTimeout(() => {
          history.push(RoutesEnum.RATE_CARDS);
        }, 100);
      },
    );
  }, []);

  /*
   * Get ChargeTypes
   * */
  const getChargeTypes = (reason: any) => {
    RateCardsApi.methods.getChargeTypes(reason).then(
      res => {
        const list = res.data.map((opt: any) => {
          return {
            key: opt.chargeTypeValue,
            label: opt.chargeTypeLabel,
          };
        });
        setTypes(list);
      },
      () => {
        setAlertProps({
          title: t("pages.rateCards.chargeProfileModal.getChargeTypesError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        setTimeout(() => {
          history.push(RoutesEnum.RATE_CARDS);
        }, 100);
      },
    );
  };

  const submitProfile = (values: IChargeProfile) => {
    const request = { ...values };
    if (!isEdit) {
      (request as any).id = undefined;
    }
    RateCardsApi.methods.updateChargeProfileTiers(values.id, request).then(
      res => {
        setProfileData(res.data);
        setAlertProps({
          title: isEdit
            ? t("pages.rateCards.chargeProfileModal.editSuccess")
            : t("pages.rateCards.chargeProfileModal.createSuccess"),
          type: AlertTypeEnum.SUCCESS,
        });
        showAlert();
        setTimeout(() => {
          history.push(RoutesEnum.RATE_CARDS);
        }, 100);
      },
      () => {
        setAlertProps({
          title: isEdit
            ? t("pages.rateCards.chargeProfileModal.editError")
            : t("pages.rateCards.chargeProfileModal.createError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  if (profileData === undefined) {
    return null;
  }

  return (
    <PageContainer>
      <SecondaryPageTitle
        displayInitialsCircle={false}
        title={isEdit ? t("pages.rateCards.chargeProfileModal.edit") : t("pages.rateCards.chargeProfileModal.title")}
        goBackFn={() => window.history.back()}
      />
      <AddProfileContainer>
        <Formik
          initialValues={profileData}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={submitProfile}
          validationSchema={validationSchema()}
          render={({ values, handleChange, isSubmitting, handleSubmit, setFieldValue, errors }: any) => {
            return (
              <form onSubmit={handleSubmit}>
                {getFormInputs(values, handleChange, setFieldValue, errors)}
                <Row style={{ height: "55px" }} id={"tiered-checkbox"}>
                  <Checkbox
                    name={"rangeValuesRequired"}
                    setFieldValue={setFieldValue}
                    value={values.rangeValuesRequired}
                    data-testid={"tiered-checkbox"}
                  ></Checkbox>
                  <Label style={{ marginTop: "0" }}>
                    {t("pages.rateCards.chargeProfileModal.tieredByPrincipalAmount")}
                  </Label>
                </Row>
                <Row>
                  <InfoLabel style={{ marginTop: "0" }}>
                    {t("pages.rateCards.chargeProfileModal.uncheckToRemoveTiers")}
                  </InfoLabel>
                </Row>
                <div
                  style={{
                    width: "max-content",
                    marginLeft: "auto",
                    marginTop: 36,
                  }}
                >
                  <PrimaryButton
                    id={isEdit ? "save-changes-button" : "create-charge-profile-button"}
                    redTheme={true}
                    disabled={isSubmitting}
                    titleLabel={
                      isEdit
                        ? t("pages.rateCards.chargeProfileModal.save")
                        : t("pages.rateCards.chargeProfileModal.title")
                    }
                    type="submit"
                  />
                </div>
              </form>
            );
          }}
        />
      </AddProfileContainer>
    </PageContainer>
  );
};

export default EditChargeProfilePage;

const AddProfileContainer = styled(PageContent)`
  @media (min-width: 1366px) {
    margin-left: 204px;
    margin-right: 204px;
  }

  div[data-testid="checkbox"] {
    width: 16px;
    height: 16px;
    margin-top: 10px;
    border-bottom: 1px solid #cccccc !important;
  }
`;

const DropdownContainer = styled("div")`
  button {
    border-radius: 6px;
    border: 1px solid #ebebeb;
  }
`;

const Label = styled("div")`
  font-family: Vodafone Rg;
  margin-left: 8px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: ${props => props.theme.palette.midGrey};
  margin-top: 12px;
`;
const InfoLabel = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  color: ${props => props.theme.palette.anthracite};
  margin-top: 12px;
`;
