import { EditIcon, FastActionButton } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { IStoreInterface } from "../../../../configs/store.config";
import { ItemName } from "../../../../shared/responsive-ui.styled";
import { fadeInKeyframe, IconContainer, RatioContainer, Row } from "../../../../shared/shared.styled";
import AllowedScopesApi from "../allowed-scopes.api";
import { AppTypeEnum, IAllowedScope, IIconAllowedScope } from "../allowed-scopes.model";
import { createBase64 } from "../allowed-scopes.utils";

interface IAllowedScopeListItemProps {
  item: IAllowedScope;
  onDelete: (category: IAllowedScope) => void;
  onEdit: (id: string) => void;
  onClickItemFn: () => void;
}

/**
 * AllowedScopeListItem component
 */
const AllowedScopeListItem = ({ item, onDelete, onEdit, onClickItemFn }: IAllowedScopeListItemProps) => {
  const [t] = useTranslation();
  const [isIconLoading, setIsIconLoading] = React.useState(false);
  const [icon, setIcon] = React.useState<IIconAllowedScope>();
  const { allowedScopesIcons } = useSelector((state: IStoreInterface) => state.serviceManagerReducer);

  /**
   * Get app type string Consumer or Business
   */
  const getAppTypeEnum = (serviceType: AppTypeEnum[]) => {
    return serviceType.length > 1
      ? t("pages.serviceManager.allowedScopes.table.allApps")
      : serviceType.includes(AppTypeEnum.CONSUMER)
      ? t("pages.serviceManager.allowedScopes.table.consumer")
      : t("pages.serviceManager.allowedScopes.table.business");
  };

  /**
   * method to get the icon
   */
  const getIcon = () => {
    AllowedScopesApi.methods
      .getIcon(item.iconUrl)
      .then(res => {
        setIcon(res.data);
      })
      .finally(() => setIsIconLoading(false));
  };

  React.useEffect(() => {
    const itemIcon = allowedScopesIcons ? allowedScopesIcons.find(icon => icon.id === item.iconId) : undefined;
    if (itemIcon) {
      setIcon(itemIcon);
      setIsIconLoading(false);
    } else {
      getIcon();
    }
  }, []);

  return (
    <>
      <AllowedScopeListItemContainer onClick={onClickItemFn} id={`allowed-scope-${item.id}`} hoverAction={true}>
        <ItemTitle ratio={4 / 12} className="item-title">
          <AllowedScopeIconContainer color={item.iconBackgroundColor}>
            {!isIconLoading && item.iconUrl ? (
              <IconDropDownContainer
                backgroundColor={item.iconColor}
                logo={icon && icon.content ? createBase64(icon) : null}
              />
            ) : null}
          </AllowedScopeIconContainer>
          <ItemName className="item-name">{item.name}</ItemName>
        </ItemTitle>

        <ItemNormalText ratio={2 / 12} className="hide-on-hover">
          {item.scope}
        </ItemNormalText>
        <ItemNormalText ratio={2 / 12} className="hide-on-hover">
          {item.type}
        </ItemNormalText>
        <RatioContainer ratio={2 / 12} className="hide-on-hover">
          {item.serviceType && item.serviceType.length > 0 ? (
            <ItemBoldText app={item.serviceType}>{`${getAppTypeEnum(item.serviceType)} `}</ItemBoldText>
          ) : null}
        </RatioContainer>

        <RatioContainer className="toggable-container" ratio={2 / 12}>
          <Row>
            <>
              <FastActionButton
                onClick={e => {
                  e.stopPropagation();
                  onEdit(item.id as string);
                }}
                iconComponent={
                  <IconContainer
                    size={16}
                    color={styleTheme.palette.turquoiseBlue}
                    id={`edit-allowed-scope-${item.name}`}
                  >
                    <EditIcon />
                  </IconContainer>
                }
                label={t("pages.serviceManager.allowedScopes.table.edit")}
              />
            </>
          </Row>
        </RatioContainer>
      </AllowedScopeListItemContainer>
    </>
  );
};

export default AllowedScopeListItem;

const AllowedScopeListItemContainer = styled("li")<{ hoverAction: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 75px;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};

  margin-bottom: 24px;
  padding: 0 35px 0 25px;

  .status-container {
    display: flex;
    justify-content: flex-end;
  }

  .toggable-container {
    display: none;
  }

  :hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    .status-container {
      display: ${props => (props.hoverAction ? "none" : "flex")};
    }
    .toggable-container {
      display: ${props => (!props.hoverAction ? "none" : "flex")};
      justify-content: flex-end;
      animation: ${fadeInKeyframe} 0.25s linear;
      margin-left: auto;
      button:first-child {
        margin-right: 12px;
      }
    }
    .item-name {
      @media (max-width: 768px) {
        max-width: unset;
        white-space: pre-wrap;
      }
    }
    .item-title {
      @media (max-width: 768px) {
        flex-grow: 1;
      }
    }
    .hide-on-hover {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
`;

const ItemTitle = styled(RatioContainer)`
  font-family: Vodafone Rg;
  font-size: 22px;
  font-weight: bold;
  color: ${props => props.theme.palette.darkGrey};
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    font-size: 20px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const ItemNormalText = styled(RatioContainer)`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
  @media (max-width: 1024px) {
    font-size: 14px;
    word-break: break-all;
  }
`;

const AllowedScopeIconContainer = styled("div")<{ color: string }>`
  width: 55px;
  height: 55px;
  background-color: ${props => (props.color ? props.color : "#d2deea")};
  border-radius: 50%;
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  img {
    width: 32px;
    height: 32px;
  }

  @media (max-width: 768px) {
    margin-right: 16px;
  }
  @media (max-width: 600px) {
    margin-right: 8px;
    width: 32px;
    height: 32px;
  }
`;

const IconDropDownContainer = styled("div")<{
  logo: string | null;
  backgroundColor: string;
}>`
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : "#ecf2f8")};
  mask-image: ${props =>
    props.logo
      ? `url("${props.logo}")`
      : "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgBAMAAAAQtmoLAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAeUExURUdwTNPf69Xf7NPf69Th7Nfk8NPf69Pf69Pf69Le6qm1MAoAAAAJdFJOUwDgYK9DFozP7hqWnd4AAAC5SURBVFjDY2AYBaNgFAxLwJKcQpJ6VsuZM01J0cA8c+bMyWCWsyA2UByApsETqGGmA8hpM7GDVjQNmSDBBCCDHYeGaWgaLEGCBkAGBw4Nkym1gWQ/kBxKJMcDyTE9CkYBThASQJp695nTHUhRzwTMD5MgyVYJG1BF1xAB1DAjAJoxsIEENA2aIEEFIIMNRxadRGmpAdfASKQGkp1EsqdJDlaSI470pDEKRsFo42S0cTKa3EfBKBiBAABkTD+NwN52ZAAAAABJRU5ErkJggg==')"};
  mask-repeat: no-repeat;
  mask-size: 32px 32px;
  mask-position: center;
  width: 100%;
  height: 100%;
  @media (max-width: 600px) {
    mask-size: 24px 24px;
  }
`;

const ItemBoldText = styled("span")<{
  app: AppTypeEnum[] | null;
}>`
  @media (max-width: 1024px) {
    font-size: 14px;
  }
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props =>
    props.app && props.app.length && props.app.length > 1
      ? props.theme.palette.vodafoneRed
      : props.app && props.app.length && props.app.includes(AppTypeEnum.CONSUMER)
      ? props.theme.palette.turquoiseBlue
      : props.theme.palette.redViolet};
`;
