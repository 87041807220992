import styled from "styled-components";

export const FiltersContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
  flex-wrap: wrap;
  > div {
    margin-top: 4px;
  }
`;
