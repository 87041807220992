import { AxiosPromise } from "axios";
import ApiClient from "../../../configs/axios.config";
import { INetworkUpdateItem } from "./networks.model";

/*
 * Networks API methods
 * */
const NetworksApi = {
  routes: {
    getNetworks: "/v1/networks/:type",
    getLastVersion: "/v1/networks/:type?previousVersion=true",
    editNetworks: "/v1/networks",
    publishNetworks: "/v1/networks/:type",
    revertNetworks: "/v1/networks/revert/:type",
  },
  methods: {
    getNetworks: (type: string): AxiosPromise => {
      return ApiClient.get(NetworksApi.routes.getNetworks.replace(":type", type), {});
    },
    getLastVersion: (type: string): AxiosPromise => {
      return ApiClient.get(NetworksApi.routes.getLastVersion.replace(":type", type), {});
    },
    revertNetworks: (type: string): AxiosPromise => {
      return ApiClient.put(NetworksApi.routes.revertNetworks.replace(":type", type));
    },
    publishNetworks: (type: string): AxiosPromise => {
      return ApiClient.put(NetworksApi.routes.publishNetworks.replace(":type", type));
    },
    editNetworks: (networks: INetworkUpdateItem): AxiosPromise => {
      return ApiClient.post(NetworksApi.routes.editNetworks, networks);
    },
  },
};

export default NetworksApi;
