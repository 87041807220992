import { AxiosPromise } from "axios";
import ApiClient from "../../../../configs/axios.config";
import { ICategory, ICategoryEditPayload, IIconCategory, IOrderObject } from "./categories.model";

const CategoriesApi = {
  routes: {
    categories: "/service-categories",
    iconsList: "/service-categories/icons",
    categoryId: "/service-categories/{CATEGORY_ID}",
    reorderCategory: "/service-categories/order",
  },
  methods: {
    getCategories: (): AxiosPromise<ICategory[]> => {
      return ApiClient.get(CategoriesApi.routes.categories);
    },
    getIconsList: (): AxiosPromise<IIconCategory[]> => {
      return ApiClient.get(CategoriesApi.routes.iconsList);
    },
    getIcon: (iconUrl: string): AxiosPromise<IIconCategory> => {
      return ApiClient.get(CategoriesApi.routes.categories + iconUrl);
    },
    createCategory: (payload: ICategory): AxiosPromise<ICategory> => {
      return ApiClient.post(CategoriesApi.routes.categories, payload);
    },
    editCategory: (id: string, payload: ICategoryEditPayload): AxiosPromise<ICategory> => {
      return ApiClient.post(CategoriesApi.routes.categoryId.replace("{CATEGORY_ID}", id), payload);
    },
    reOrderCategory: (id: string, payload: IOrderObject) => {
      return ApiClient.post(CategoriesApi.routes.reorderCategory, payload);
    },
    deleteCategory: (id: string) => {
      return ApiClient.delete(CategoriesApi.routes.categoryId.replace("{CATEGORY_ID}", id));
    },
  },
};

export default CategoriesApi;
