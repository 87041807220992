import React from "react";
import { deepCopyObject } from "../../shared.utils";

const diff = require("diff-arrays-of-objects");

/**
 * Version item status enum to indicate the status of a changed item
 */
export enum VersionItemStatusEnum {
  "ADDED" = "added",
  "UPDATED" = "updated",
  "REMOVED" = "removed",
}

/**
 * Version item for each item of a version
 */
export type VersionItem<T> = T & {
  versionItemStatus?: VersionItemStatusEnum;
};

/**
 * Receives two version arrays and returns a copy of them with the added, removed and updated items identified
 * @param previousVersionArray - array of items of the previous version
 * @param currentVersionArray - array of items of the current version
 * @param {string} identifier
 * @returns {{prev: any, current: any}}
 */
const useVersionsDiff = <T,>(previousVersionArray: T[], currentVersionArray: T[], identifier = "id") => {
  const a1 = previousVersionArray ? deepCopyObject(previousVersionArray) : [];
  const a2 = currentVersionArray ? deepCopyObject(currentVersionArray) : [];
  const diffs = diff(a1, a2, identifier);

  // Added items handler
  diffs.added.forEach((a: { [x: string]: any }) => {
    const obj = a2.find((o: { [x: string]: any }) => o[identifier] === a[identifier]) as VersionItem<T>;
    obj.versionItemStatus = VersionItemStatusEnum.ADDED;
  });
  // Updated items handler
  diffs.updated.forEach((a: { [x: string]: any }) => {
    const obj = a2.find((o: { [x: string]: any }) => o[identifier] === a[identifier]) as VersionItem<T>;
    obj.versionItemStatus = VersionItemStatusEnum.UPDATED;
  });
  // Removed items handler - applied only to the a1 since it's the previous version
  diffs.removed.forEach((a: { [x: string]: any }) => {
    const obj = a1.find((o: { [x: string]: any }) => o[identifier] === a[identifier]) as VersionItem<T>;
    obj.versionItemStatus = VersionItemStatusEnum.REMOVED;
  });

  return { prev: a1, current: a2 };
};

export default useVersionsDiff;
