import { AxiosPromise } from "axios";
import ApiClient from "../../../configs/axios.config";
import {
  EFormType,
  IFormDataRequest,
  IFormDataResponse,
  IFormDetailsRequest,
  IFormDetailsResponse,
  IFormCategoriesResponse,
  ICreateFormCategoryRequest,
  ICreateFormCategoryResponse,
  IUpdateFormDataRowResponse,
  IExportFormDataRequest,
} from "./feedback-and-problems.model";
import { getParametrizedRoute } from "../../../shared/shared.utils";

const staticPath = "/v1/report-forms";
const FeedbackAndProblemsAPI = {
  routes: {
    formItemStatus: `${staticPath}/{formType}/responses/{itemId}`,
    formDetails: `${staticPath}/{formType}/details`,
    formCategories: `${staticPath}/{formType}/category?includeAll={includeAll}`,
    formDataWithUrlParams: `${staticPath}/{formType}/responses?page={pageNumber}&pageSize={pageSize}&search={searchQuery}&categoryId={categoryId}&status={status}&startDate={startDate}&endDate={endDate}`,
    formData: `${staticPath}/{formType}/responses`,
    exportFormData: `${staticPath}/{formType}/responses/export?excel={excel}&csv={csv}`,
    exportFormDataWithUrlParams: `${staticPath}/{formType}/responses/export?search={searchQuery}&startDate={startDate}&endDate={endDate}&categoryId={categoryId}&status={status}&excel={excel}&csv={csv}`,
  },
  methods: {
    getFormDetails: (formType: EFormType): AxiosPromise<IFormDetailsResponse[]> => {
      return ApiClient.get(getParametrizedRoute({ formType }, FeedbackAndProblemsAPI.routes.formDetails));
    },
    updateFormDetails: (formType: EFormType, payload: IFormDetailsRequest): AxiosPromise<IFormDetailsResponse> => {
      return ApiClient.put(getParametrizedRoute({ formType }, FeedbackAndProblemsAPI.routes.formDetails), payload);
    },
    getFormCategories: (formType: EFormType, includeAll: boolean): AxiosPromise<IFormCategoriesResponse[]> => {
      return ApiClient.get(
        getParametrizedRoute({ formType, includeAll }, FeedbackAndProblemsAPI.routes.formCategories),
      );
    },
    createFormCategory: (
      formType: EFormType,
      payload: ICreateFormCategoryRequest,
    ): AxiosPromise<number | ICreateFormCategoryResponse> => {
      return ApiClient.post(
        getParametrizedRoute(
          { formType },
          FeedbackAndProblemsAPI.routes.formCategories.replace("?includeAll={includeAll}", ""),
        ),
        payload,
      );
    },
    getFormData: (formType: EFormType, urlRequestParams?: IFormDataRequest): AxiosPromise<IFormDataResponse> => {
      const route = urlRequestParams
        ? FeedbackAndProblemsAPI.routes.formDataWithUrlParams
        : FeedbackAndProblemsAPI.routes.formData;

      return ApiClient.get(getParametrizedRoute({ formType, ...urlRequestParams }, route));
    },
    updateFormDataRow: (
      formType: EFormType,
      itemId: string | number,
      newStatus: string,
    ): AxiosPromise<IUpdateFormDataRowResponse> => {
      return ApiClient.put(getParametrizedRoute({ formType, itemId }, FeedbackAndProblemsAPI.routes.formItemStatus), {
        status: newStatus,
      });
    },
    exportFormData: (
      formType: EFormType,
      payload: IExportFormDataRequest,
      urlRequestParams?: IFormDataRequest,
    ): AxiosPromise => {
      const route = urlRequestParams
        ? FeedbackAndProblemsAPI.routes.exportFormDataWithUrlParams
        : FeedbackAndProblemsAPI.routes.exportFormData;

      return ApiClient.get(getParametrizedRoute({ formType, ...urlRequestParams, ...payload }, route), {
        responseType: "blob",
      });
    },
  },
};

export default FeedbackAndProblemsAPI;
