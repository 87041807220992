import React from "react";
import { IIconProps } from "./icon-interfaces";

const DesktopIcon = ({ id }: IIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" id={id}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                fill="#D8D8D8"
                fillOpacity="0"
                d="M0 0L24 0 24 24 0 24z"
                transform="translate(-118 -308) translate(118 298) translate(0 10)"
              />
              <path
                stroke="#333"
                strokeLinejoin="round"
                d="M4.199 14.273L1.875 19.5 22.125 19.5 19.8 14.269"
                transform="translate(-118 -308) translate(118 298) translate(0 10)"
              />
              <path
                stroke="#333"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.5 17L15.5 17M5 14.5c-.828 0-1.5-.672-1.5-1.5V5c0-.828.672-1.5 1.5-1.5h14c.828 0 1.5.672 1.5 1.5v8c0 .828-.672 1.5-1.5 1.5H5z"
                transform="translate(-118 -308) translate(118 298) translate(0 10)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DesktopIcon;
