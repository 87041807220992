import {
  DownloadIcon,
  EditIcon,
  FastActionButton,
  Magnifier,
  ModalTypeEnum,
  PlusIcon,
  SearchBar,
  SmallButton,
  Table,
  TrashIcon,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import { ColumnProps, SortDirectionEnum } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import UploadIcon from "../../../../shared/icons/upload-file.icon";
import { ILanguage } from "../../../../shared/models/language.model";
import { IconContainer, LoadingText, RatioContainer, Row } from "../../../../shared/shared.styled";
import { deepCopyObject, sortArrayBy, TableButtonsContainer } from "../../../../shared/shared.utils";
import { EmptyState, MagnifierContainer, NoResultsFoundText } from "../language-translations-versions-list.page";
import LanguageTranslationsApi from "../language-translations.api";
import {
  LanguageTranslationModalsEnum,
  ILanguageTranslation,
  ILanguageTranslations,
  LanguageTranslationStatusEnum,
} from "../language-translations.model";
import { LanguageTranslationsActions } from "../language-translations.store";
import EmptyStateIllustration from "../../../../shared/icons/empty-state-illustration.icon";
import ExportLanguageTranslationsModal from "./export-language-translations.modal";
import ImportLanguageTranslationsModal from "./import-language-translations.modal";
import LanguageTranslationsStatusChip from "./language-translations-status-chip.component";
import LanguageTranslationsDrawer from "./language-translations.drawer";
import { SearchBarFilterContainer } from "../../../../shared/responsive-ui.styled";
import LanguageSelectorDropdown from "../../../../shared/components/language-selector-dropdown.component";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";

interface ILanguageTranslationsTableInterface {
  languageTranslations: ILanguageTranslations;
  availableLanguages: ILanguage[];
  loadingTranslations: boolean;
}

/**
 * Language translations table and search/export
 * @param {ILanguageTranslations} languageTranslations
 * @param {ILanguage[]} availableLanguages
 * @returns {JSX.Element}
 * @constructor
 */
const LanguageTranslationsTable = ({
  languageTranslations,
  availableLanguages,
  loadingTranslations,
}: ILanguageTranslationsTableInterface) => {
  const [t] = useTranslation();
  const { version } = useParams<any>();
  const dispatch = useDispatch();
  const [showAlert, , setAlertProps] = useAlert();
  const [searchString, setSearchString] = React.useState("");
  const [translations, setTranslations] = React.useState<ILanguageTranslation[]>([]);
  const [sort, setSort] = React.useState<{
    direction: SortDirectionEnum;
    key: string;
  }>({
    direction: SortDirectionEnum.ASC,
    key: "key",
  });
  const [isDrawerVisible, setIsDrawerVisible] = React.useState(false);
  const [selectedTranslation, setSelectedTranslation] = React.useState<ILanguageTranslation>();

  /**
   * Get selected language
   * @returns
   */
  const getSelectedLanguage = () => {
    let language = { label: t("commons.languages.en"), key: "en" };
    if (availableLanguages && availableLanguages.length > 0) {
      for (const l of availableLanguages) {
        if (!l.mandatory) {
          language = {
            label: t(`commons.languages.${l.code}`),
            key: l.code,
          };
          return language;
        }
      }
    }
    return language;
  };

  const [selectedLanguage, setSelectedLanguage] = React.useState<SharedDropdownOption>(getSelectedLanguage());
  const theme = React.useContext(ThemeContext);

  /**
   * Close export/import modal
   */
  const dismissLanguageTranslationsModal = (modal: string) => {
    if (modal === LanguageTranslationModalsEnum.EXPORT) {
      hideExportLanguageTranslationsModal();
    } else {
      hideImportLanguageTranslationsModal();
    }
  };

  /**
   * Export language translations modal
   */
  const [showExportLanguageTranslationsModal, hideExportLanguageTranslationsModal] = useModal(
    ModalTypeEnum.CustomModal,
    { modalStyles: { width: 400 } } as any,
    <ExportLanguageTranslationsModal version={version} dismissModal={dismissLanguageTranslationsModal} />,
  );

  /**
   * Import language translations modal
   */
  const [showImportLanguageTranslationsModal, hideImportLanguageTranslationsModal] = useModal(
    ModalTypeEnum.CustomModal,
    { modalStyles: { width: 400 } } as any,
    <ImportLanguageTranslationsModal version={version} dismissModal={dismissLanguageTranslationsModal} />,
  );

  /**
   * Delete modal
   */
  const [showConfirmationDeleteModal, hideConfirmationDeleteModal, setConfirmationDeleteProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );

  /**
   * Delete app property handler
   * */
  const showDeleteConfirmation = (key: string) => {
    setConfirmationDeleteProps({
      title: t("pages.languageTranslations.deleteModalTitle"),
      description: t("pages.languageTranslations.deleteModalMessage", {
        key: key,
      }),
      isKeyboardConfirmationEnabled: true,
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      setInnerHtml: true,
      primaryAction: () => {
        LanguageTranslationsApi.methods
          .deleteLanguageTranslation({ key: key }, version)
          .then(
            res => {
              dispatch(LanguageTranslationsActions.creators.getLanguageTranslationsSuccess(res.data));
              hideConfirmationDeleteModal();
              setAlertProps({
                title: t("pages.languageTranslations.deleteLanguageTranslationSuccess"),
                type: AlertTypeEnum.SUCCESS,
              });
              showAlert();
            },
            () => {
              setAlertProps({
                title: t("pages.languageTranslations.deleteLanguageTranslationError"),
                type: AlertTypeEnum.ERROR,
              });
              showAlert();
            },
          )
          .finally(() => {});
      },
      secondaryAction: () => hideConfirmationDeleteModal(),
    } as BaseModalProps);
    showConfirmationDeleteModal();
  };

  /**
   * Filter translations according to parameter on searchString
   * @param (no params)
   * @return void
   */
  const filterTranslations = (): void => {
    let translationsClone = deepCopyObject(languageTranslations.translations ?? []);
    if (!!searchString) {
      translationsClone = translationsClone.filter(
        tr =>
          tr.key.toLowerCase().includes(searchString.toLowerCase()) ||
          availableLanguages
            .map(lang => tr.translations[lang.code]?.toLowerCase().includes(searchString.toLowerCase()))
            .includes(true),
      );
    }
    sortList(sort.key, sort.direction, translationsClone);
  };

  /**
   * Language translations store changed
   */
  React.useEffect(() => {
    if (!searchString) {
      setTranslations(
        languageTranslations.translations?.length > 0
          ? sortArrayBy(languageTranslations.translations, "key", SortDirectionEnum.ASC)
          : [],
      );
      return;
    }

    filterTranslations();
  }, [languageTranslations]);

  /**
   * Search has changed
   */
  React.useEffect(() => {
    filterTranslations();
  }, [searchString]);

  React.useEffect(() => {
    if (document.getElementById("language-selector-wrapper")) {
      document.getElementById("language-selector-wrapper")!.parentElement!.classList!.value = "";
    }
  }, [translations]);

  /**
   * Sort the translations per language
   * @param {ILanguageTranslation[]} data
   * @param {string} lang
   * @param {SortDirectionEnum} direction
   * @returns {ILanguageTranslation[]}
   */
  const sortByTranslations = (data: ILanguageTranslation[], lang: string, direction: SortDirectionEnum) => {
    const columnLanguages = availableLanguages.map(lang => lang.code);
    const dataList = data.slice().map(translation => {
      let diffKeys = {};
      columnLanguages.forEach(language => {
        if (
          !translation.translations.hasOwnProperty(language) ||
          (translation.translations.hasOwnProperty(language) &&
            (translation.translations[language] === null || translation.translations[language] === undefined))
        ) {
          diffKeys = { ...diffKeys, [language]: "" };
        }
      });

      return {
        ...translation,
        translations: {
          ...translation.translations,
          ...diffKeys,
        },
      };
    });
    return (dataList as ILanguageTranslation[]).sort((a, b) => {
      if (direction === SortDirectionEnum.ASC) {
        return a.translations[lang].localeCompare(b.translations[lang]);
      } else {
        return b.translations[lang].localeCompare(a.translations[lang]);
      }
    });
  };

  /**
   * Sorts the list of translations
   * @param {string} key
   * @param {string} direction
   * @param translationsList
   */
  const sortList = (key: string, direction: number, translationsList?: ILanguageTranslation[]) => {
    if (key === "key") {
      setTranslations(sortArrayBy(translationsList ? translationsList : translations, "key", direction));
    } else {
      setTranslations(sortByTranslations(translationsList ? translationsList : translations, key, direction));
    }
    setSort({
      direction: direction,
      key: key,
    });
  };

  /**
   * Retrieves the columns for the language translation list
   * @returns {ColumnProps[]}
   */
  const getTableColumns = (): ColumnProps[] => {
    let mandatoryLanguages: ColumnProps[] = availableLanguages
      .filter(lang => lang.mandatory)
      .map(lang => {
        return {
          ratio: 3 / 12,
          label: lang.name,
          formKey: lang.code,
          isEditable: false,
          sortable: false,
          changeFunction: (content: any, item: ILanguageTranslation) => {
            return <span id={`${lang.code}-${item.translations[lang.code]}`}>{item.translations[lang.code]}</span>;
          },
        };
      });
    if (!mandatoryLanguages || mandatoryLanguages.length === 0) {
      mandatoryLanguages = [
        {
          ratio: 3 / 12,
          label: availableLanguages[0].name,
          formKey: availableLanguages[0].code,
          isEditable: false,
          sortable: false,
          changeFunction: (content: any, item: ILanguageTranslation) => {
            return (
              <span id={`${availableLanguages[0].code}-${item.translations[availableLanguages[0].code]}`}>
                {item.translations[availableLanguages[0].code]}
              </span>
            );
          },
        },
      ] as ColumnProps[];
    }

    const optionalLanguages: ILanguage[] = availableLanguages.filter(lang => !lang.mandatory);
    const optionalShowLanguages: ColumnProps | {} =
      optionalLanguages && optionalLanguages.length > 0
        ? {
            ratio: 3 / 12,
            label: (
              <LanguageSelectorWrapper id={"language-selector-wrapper"}>
                <LanguageSelectorDropdown
                  availableLanguages={optionalLanguages.map(l => {
                    return {
                      label: t(`commons.languages.${l.code}`),
                      key: l.code,
                    };
                  })}
                  selectedLanguage={selectedLanguage}
                  changeSelectedLanguage={setSelectedLanguage}
                  color={theme.palette.turquoiseBlue}
                  id={"language-selector"}
                ></LanguageSelectorDropdown>
              </LanguageSelectorWrapper>
            ),
            isEditable: false,
            sortable: false,
            changeFunction: (content: any, item: ILanguageTranslation) => {
              return (
                <span id={`${selectedLanguage.key}-${item.translations[selectedLanguage.key]}`}>
                  {item.translations[selectedLanguage.key]}
                </span>
              );
            },
          }
        : {};

    return [
      {
        ratio: 3 / 12,
        label: (
          <span style={{ color: "#999999", fontWeight: "bold" }}>{i18next.t("pages.languageTranslations.key")}</span>
        ),
        formKey: "key",
        isEditable: false,
        sortable: false,
        changeFunction: content => {
          return <span id={content}>{content}</span>;
        },
      },
      ...mandatoryLanguages,
      optionalShowLanguages && (optionalShowLanguages as ColumnProps),
      {
        ratio: 3 / 12,
        label: "",
        formKey: "status",
        isEditable: false,
        sortable: false,
        alignText: "right",
        style: { marginRight: "0px" },
        changeFunction: content => {
          return (
            <div
              className={content === LanguageTranslationStatusEnum.DELETE ? "" : "status-content"}
              style={{ justifyContent: "flex-end" }}
            >
              <LanguageTranslationsStatusChip type={content} style={{ marginTop: "11px" }}>
                {t(`pages.languageTranslations.languageTranslationsListItemStatusEnum.${content}`)}
              </LanguageTranslationsStatusChip>
            </div>
          );
        },
      },
    ];
  };

  /**
   * Hides the language translation editor
   */
  const hideDrawer = () => {
    setSelectedTranslation(undefined);
    setIsDrawerVisible(false);
  };

  /**
   * Render action icons for each translation
   * @param {T} value
   * @param {{delete: {onClick: (row: T) => any}}} buttonActions
   * @returns {JSX.Element}
   */
  const renderFastActions = <T extends ILanguageTranslation>(
    value: T,
    buttonActions: {
      delete: { onClick: (row: T) => any };
      edit: { onClick: (row: T) => any };
    },
  ) => {
    return (
      <>
        {value.status !== LanguageTranslationStatusEnum.DELETE && !languageTranslations.submittedForApproval && (
          <TableButtonsContainer>
            <FastActionButton
              containerIdName={`edit-${value.key}-button`}
              iconComponent={
                <IconContainer size={16} color={styleTheme.palette.turquoiseBlue}>
                  <EditIcon />
                </IconContainer>
              }
              onClick={() => buttonActions.edit.onClick(value)}
              label={i18next.t("pages.languageTranslations.edit")}
            />
            <FastActionButton
              containerIdName={`delete-${value.key}-button`}
              iconComponent={
                <IconContainer size={16} color={styleTheme.palette.errorRed}>
                  <TrashIcon />
                </IconContainer>
              }
              onClick={() => buttonActions.delete.onClick(value)}
              label={i18next.t("pages.languageTranslations.delete")}
            />
          </TableButtonsContainer>
        )}
      </>
    );
  };

  return (
    <>
      <Row style={{ marginTop: "48px", flexWrap: "wrap", gap: "24px" }}>
        <SearchBarFilterContainer>
          <SearchBar
            value={searchString}
            onChange={e => setSearchString(e.target.value)}
            placeholderLabel={t("pages.languageTranslations.search")}
            clearValue={() => setSearchString("")}
          />
        </SearchBarFilterContainer>
        <RatioContainerCustom ratio={8 / 12}>
          <SmallButton
            disabled={!languageTranslations.translations?.length}
            titleLabel={t("pages.languageTranslations.exportModal.title")}
            onClick={() => {
              showExportLanguageTranslationsModal();
            }}
            iconComponent={
              <SmallButtonIconContainer color={styleTheme.palette.vodafoneRed}>
                <DownloadIcon />
              </SmallButtonIconContainer>
            }
          />

          <SmallButton
            disabled={languageTranslations.submittedForApproval}
            titleLabel={t("pages.languageTranslations.importModal.title")}
            onClick={() => {
              showImportLanguageTranslationsModal();
            }}
            iconComponent={
              <SmallButtonIconContainer color={styleTheme.palette.turquoiseBlue}>
                <UploadIcon />
              </SmallButtonIconContainer>
            }
          />

          <SmallButton
            disabled={languageTranslations.submittedForApproval}
            titleLabel={t("pages.languageTranslations.add")}
            onClick={() => {
              setSelectedTranslation(undefined);
              setIsDrawerVisible(true);
            }}
            iconComponent={
              <SmallButtonIconContainer color={styleTheme.palette.vodafoneRed}>
                <PlusIcon />
              </SmallButtonIconContainer>
            }
          />
        </RatioContainerCustom>
      </Row>
      <Row style={{ marginTop: "36px" }}>
        {loadingTranslations ? (
          <LoadingText>{t("commons.loadingResults")}</LoadingText>
        ) : (
          <Table<ILanguageTranslation>
            columns={getTableColumns()}
            values={translations}
            sortable={true}
            isStripedTable={true}
            showActionColumn={false}
            emptyStateComponent={
              <>
                {!!searchString ? (
                  <MagnifierContainer>
                    <Magnifier />
                    <NoResultsFoundText>{t("pages.languageTranslations.noSearchResults")}</NoResultsFoundText>
                  </MagnifierContainer>
                ) : (
                  <EmptyState>
                    <EmptyStateIllustration />
                    <NoResultsFoundText>{t("pages.languageTranslations.emptyState")}</NoResultsFoundText>
                  </EmptyState>
                )}
              </>
            }
            initialSortColumnFormKey={sort.key}
            initialSortDirection={sort.direction}
            sortClickAction={(column, direction) => {
              sortList(column.formKey as string, direction);
            }}
            displayActions={(languageTranslation: ILanguageTranslation) =>
              renderFastActions<ILanguageTranslation>(languageTranslation, {
                delete: {
                  onClick: () => {
                    showDeleteConfirmation(languageTranslation.key);
                  },
                },
                edit: {
                  onClick: () => {
                    setSelectedTranslation(languageTranslation);
                    setIsDrawerVisible(true);
                  },
                },
              })
            }
          />
        )}
      </Row>
      {isDrawerVisible && (
        <LanguageTranslationsDrawer
          hideDrawer={hideDrawer}
          selectedTranslation={selectedTranslation}
          availableLanguages={availableLanguages}
          translations={translations}
        />
      )}
    </>
  );
};

const SmallButtonIconContainer = styled("div")<{ color: string }>`
  width: 16px;
  height: 16px;
  stroke: ${props => props.color};

  svg {
    width: 16px;
    height: 16px;
    stroke: ${props => props.color};
  }
`;

const RatioContainerCustom = styled(RatioContainer)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: 1024px) {
    justify-content: flex-start;
    flex: 100%;
    flex-wrap: wrap;
    gap: 24px;
  }
  column-gap: 20px;
`;

const LanguageSelectorWrapper = styled("div")`
  div > button {
    padding: 0px;
    svg {
      height: 24px;
      width: 12px;
      display: block;
    }
  }
`;
export default LanguageTranslationsTable;
