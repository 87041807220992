import { Drawer, SecondaryPageTitle } from "@wit/mpesa-ui-components";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import NotificationBellIcon from "../../icons/notification-bell.icon";
import NoNotificationBellIcon from "../../icons/no-notification-bell.icon";
import NotificationItem from "./notification.component";
import { INotification } from "./notification.model";

export interface INotificationsAreaProps {
  isVisible: boolean;
  hideDrawer: () => void;
  notifications: INotification[];
  getNotifications: () => void;
}

export interface INotificationGroup {
  dateDescription: string;
  notifications: INotification[];
}

const NotificationsDrawer = ({ isVisible, hideDrawer, notifications, getNotifications }: INotificationsAreaProps) => {
  const [t] = useTranslation();
  const reduceNotifications = (): INotificationGroup[] => {
    if (!notifications || !notifications.length) {
      return [];
    }
    notifications.sort((a, b) => (a.date > b.date ? -1 : 1));

    const groups = notifications.reduce((acc: any, notif) => {
      let date = "";
      if (moment(notif.date).isSame(moment(), "day")) {
        date = t("notifications.today");
      } else if (moment(notif.date).isSame(moment().subtract(1, "day"), "day")) {
        date = t("notifications.yesterday");
      } else {
        date = moment(notif.date).format("DD MMM YYYY");
      }
      if (acc.findIndex((obj: INotificationGroup) => obj.dateDescription === date) === -1) {
        acc.push({ dateDescription: date, notifications: [notif] });
      } else {
        acc[acc.findIndex((obj: INotificationGroup) => obj.dateDescription === date)].notifications.push(notif);
      }
      return acc;
    }, []);
    return groups;
  };

  /**
   * Gets the list of notifications every time the drawer is open
   */
  React.useEffect(() => {
    if (isVisible) {
      getNotifications();
    }
  }, [isVisible]);

  return (
    <Drawer isVisible={isVisible} hideDrawer={hideDrawer}>
      <NotificationsContainer>
        <SecondaryPageTitle
          backgroundColor="#fff"
          titleFontSize={24}
          goBackFn={hideDrawer}
          height={104}
          displayInitialsCircle={false}
          paddingLeft={82}
          title={t("notifications.title")}
          icon={
            <NotificationBellContainer>
              <NotificationBellIcon />
            </NotificationBellContainer>
          }
        />
        {notifications && notifications.length ? (
          <NotificationsList id={"notifications-list"}>
            {reduceNotifications().map((group: INotificationGroup) => (
              <NotificationSection
                key={`section-${group.dateDescription}`}
                id={`notifications-${group.dateDescription}`}
              >
                <NotificationSectionTitle>{group.dateDescription}</NotificationSectionTitle>
                {group.notifications.map((n: INotification) => (
                  <NotificationItem
                    notification={n}
                    key={`notification-${n.id}`}
                    hideDrawer={hideDrawer}
                    getNotifications={getNotifications}
                  />
                ))}
              </NotificationSection>
            ))}
          </NotificationsList>
        ) : (
          <NoNotificationBellContainer>
            <NoNotificationBellIcon />
            <NoNotificationText>{t("notifications.noNotificationTitle")}</NoNotificationText>
          </NoNotificationBellContainer>
        )}
      </NotificationsContainer>
    </Drawer>
  );
};
const NotificationBellContainer = styled("div")`
  svg {
    height: 48px;
    width: 48px;
    transform: rotate(0deg) !important;
    padding-left: 16px;
  }
`;

const NoNotificationBellContainer = styled("div")`
  margin: auto;
  svg {
    height: 140px;
    width: 140px;
  }
`;

const NoNotificationText = styled("div")`
  margin-top: 24px;
  text-align: center;
  font-family: Vodafone Rg;
  font-size: 22px;
  color: #999999;
`;

const NotificationSection = styled("div")`
  display: flex;
  flex-direction: column;
`;

const NotificationSectionTitle = styled("span")`
  font-size: 15px;
  font-weight: bold;
  font-family: "Vodafone Rg";
  margin-bottom: 16px;
  margin-top: 16px;
`;

const NotificationsContainer = styled("div")`
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: column;
  & > div {
    position: relative;
  }
  * {
    direction: ltr !important;
  }
`;

const NotificationsList = styled("div")`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 11px 50px 118px;
  align-items: flex-start;
  width: 100%;
  flex: 1;
  > div {
    width: 100%;
  }
  direction: ltr;
`;

export default NotificationsDrawer;
