import { Magnifier, ModalTypeEnum, useAlert, useFilters, useModal, PageTitle } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import React, { useContext } from "react";
import Document from "../../configurations/rate-cards/components/document.component";
import { TFunction, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled, { ThemeContext } from "styled-components";
import { IStoreInterface } from "../../../configs/store.config";
import { ListHeader, ListHeaders, LoadingText, PageContainer } from "../../../shared/shared.styled";
import { GeneralCategoriesActions } from "./general-categories.store";
import GeneralCategoriesApi from "./general-categories.api";
import { generalFilterCategories } from "./general-categories.utils";
import { RoutesEnum } from "../../../routes/routes.constants";
import { useHistory } from "react-router-dom";
import GeneralCategoriesFilters from "./components/general-categories-filter.component";
import GeneralAddNewCategoryListItem from "./components/general-add-new-category-list-item.component";
import GeneralCategoryListItem from "./components/general-category-list-item.component";
import { IDynamicScreenCategory } from "../../configurations/dynamic-screens/dynamic-screens.model";
import { Breadcrumb } from "@wit/mpesa-ui-components/lib/types";
import EmptySearchResult from "../../../shared/components/empty-search-result.component";
import { IIconCategory } from "./general-categories.model";

interface IGeneralCategoryProps {
  isAntCategories?: boolean;
}

/**
 * Get breadcrumbs
 * @param t
 */
const getBreadcrumbs = (t: TFunction): Breadcrumb[] => {
  return [
    {
      label: t("pages.configuration.title"),
      url: RoutesEnum.CONFIGS,
    },
    {
      label: t("pages.generalCategories.title"),
      url: "",
    },
  ];
};

/**
 * GeneralCategoriesPage component
 */
const GeneralCategoriesPage = ({ isAntCategories = false }: IGeneralCategoryProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showAlert, , setAlertProps] = useAlert();
  const [isLoading, setIsLoading] = React.useState(true);
  const [itemIcons, setItemIcons] = React.useState<IIconCategory[]>([]);
  const { categories } = useSelector((state: IStoreInterface) => state.generalCategoriesReducer);
  const { icons } = useSelector((state: IStoreInterface) => state.serviceManagerReducer);
  const [showableCategories, setShowableCategories] = React.useState<IDynamicScreenCategory[]>([]);
  const [showDeleteModal, hideDeleteModal, setDeleteProps] = useModal(ModalTypeEnum.ConfirmationModal);
  const {
    filters,
    updateFilter,
    isFilterActive,
    clearFilter,
    resetFilters,
    getFilterValue,
    getFiltersQueryString,
    updateMultipleFilters,
  } = useFilters();
  const themeContext = useContext(ThemeContext);

  /**
   * method to get the general categories list
   */
  const getCategories = (): void => {
    GeneralCategoriesApi.methods
      .getCategories()
      .then(
        res => {
          const categoriesSorted = res.data.items.sort((a: IDynamicScreenCategory, b: IDynamicScreenCategory) =>
            a.name.translations["en"].localeCompare(b.name.translations["en"]),
          );
          dispatch(GeneralCategoriesActions.creators.fetchCategories(categoriesSorted));

          if (filters.size > 0) {
            setShowableCategories(generalFilterCategories(res.data.items ? [...categoriesSorted] : [], filters));
          } else {
            setShowableCategories(categoriesSorted);
          }
        },
        () => {
          setAlertProps({
            title: t("pages.generalCategories.getCategoriesError"),
            type: AlertTypeEnum.ERROR,
          });

          showAlert();
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * method to get the icons list
   */
  const getIcons = (): void => {
    GeneralCategoriesApi.methods.getIconsList().then(res => {
      setItemIcons(res.data);
      dispatch(GeneralCategoriesActions.creators.fectionsIconsCategories(res.data));
    });
  };

  /**
   * general category delete function
   * @param {IDynamicScreenCategory} category
   */
  const onCategoryDelete = (category: IDynamicScreenCategory): void => {
    setDeleteProps({
      secondaryAction: () => {
        hideDeleteModal();
      },
      primaryAction: () => {
        GeneralCategoriesApi.methods
          .deleteCategory(category.id)
          .then(
            res => {
              getCategories();
              setAlertProps({
                title: t("pages.generalCategories.categoryDeleted"),
                type: AlertTypeEnum.SUCCESS,
              });
            },
            () => {
              setAlertProps({
                title: t("pages.generalCategories.deleteCategoriesError"),
                type: AlertTypeEnum.ERROR,
              });
            },
          )
          .finally(() => {
            hideDeleteModal();
            showAlert();
          });
      },
      title: t("pages.generalCategories.categoryForm.deleteTitleModal"),
      description: t("pages.generalCategories.categoryForm.deleteDescriptionModal").replace(
        "{category}",
        category.name.translations["en"],
      ),
      primaryBtnLabel: t("pages.generalCategories.categoryForm.deleteLabelBtn"),
      primaryBtnId: "delete-category-confirm",
      secondaryBtnId: "cancel-button",
      primaryBtnMaxWidth: 160,
    });

    showDeleteModal();
  };

  React.useEffect(() => {
    if (!itemIcons.length && !icons.length) {
      getIcons();
    }
    if (!itemIcons.length && icons.length) {
      setItemIcons(icons);
    }
    getCategories();
  }, []);

  React.useEffect(() => {
    if (filters.size > 0) {
      setShowableCategories(generalFilterCategories(categories ? [...categories] : [], filters));
    } else {
      setShowableCategories(categories);
    }
  }, [filters]);

  /**
   * Render the list of categories
   * @param element
   * @returns
   */
  const renderListPage = (element: JSX.Element): JSX.Element => {
    if (!isAntCategories) {
      return (
        <PageContainer>
          <PageTitle
            separatorStyle={{ display: "none" }}
            breadcrumbs={getBreadcrumbs(t)}
            title={t("pages.generalCategories.title")}
            navigateFunction={history.push}
            goBackFn={() => history.push(RoutesEnum.CONFIGS)}
            titleContainerStyle={{ marginTop: "13px", marginBottom: "34px" }}
            iconContainerStyle={{ stroke: themeContext.palette.vodafoneRed }}
          />
          {element}
        </PageContainer>
      );
    } else {
      return element;
    }
  };

  return renderListPage(
    <>
      {!isLoading ? (
        <>
          <GeneralCategoriesFilters
            filters={filters}
            updateFilter={updateFilter}
            isFilterActive={isFilterActive}
            clearFilter={clearFilter}
            resetFilters={resetFilters}
            getFilterValue={getFilterValue}
            getFiltersQueryString={getFiltersQueryString}
            updateMultipleFilters={updateMultipleFilters}
          />
          <CategoriesListContainer>
            <ListHeaders>
              <ListHeader ratio={12 / 12}>{t("pages.generalCategories.table.category")}</ListHeader>
            </ListHeaders>

            {filters.size === 0 && !isAntCategories ? (
              <GeneralAddNewCategoryListItem addNewCategoryFn={() => history.push(RoutesEnum.ADD_CATEGORY)} />
            ) : null}

            {categories && categories.length && showableCategories ? (
              <>
                {showableCategories.length > 0 ? (
                  showableCategories.map((category: IDynamicScreenCategory) => {
                    const itemIcon = itemIcons.find(icon => icon.id.toString() === category.iconId?.toString());
                    return (
                      <GeneralCategoryListItem
                        isAntCategories={isAntCategories}
                        onDelete={onCategoryDelete}
                        onEdit={() => {
                          dispatch(GeneralCategoriesActions.creators.setCategory(category));
                          !isAntCategories
                            ? history.push(
                                `${RoutesEnum.CATEGORY_DETAILS.replace(":categoryId", category.id)}?editing=true`,
                              )
                            : history.push(
                                `${RoutesEnum.ANT_SERVICE_MANAGER_CATEGORY_DETAILS.replace(
                                  ":categoryId",
                                  category.id,
                                )}?editing=true`,
                              );
                        }}
                        onClickItemFn={() => {
                          dispatch(GeneralCategoriesActions.creators.setCategory(category));
                          !isAntCategories
                            ? history.push(
                                `${RoutesEnum.CATEGORY_DETAILS.replace(":categoryId", category.id)}?editing=true`,
                              )
                            : history.push(
                                `${RoutesEnum.ANT_SERVICE_MANAGER_CATEGORY_DETAILS.replace(
                                  ":categoryId",
                                  category.id,
                                )}?editing=true`,
                              );
                        }}
                        item={category}
                        itemIcon={itemIcon}
                        key={category.id}
                      />
                    );
                  })
                ) : (
                  <EmptySearchResult title={t("pages.generalCategories.table.noSearchResults")} />
                )}
              </>
            ) : (
              <EmptyState>
                <Document />
                <SelectText>{t("pages.generalCategories.table.emptyState")}</SelectText>
              </EmptyState>
            )}
          </CategoriesListContainer>
        </>
      ) : (
        <LoadingText>{t("commons.loadingResults")}</LoadingText>
      )}
    </>,
  );
};

export default GeneralCategoriesPage;

const CategoriesListContainer = styled("div")`
  margin-bottom: 50px;
  margin-top: 31px;
`;

const MagnifierContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 50px;

  svg {
    width: 140px;
    height: 140px;
    margin-bottom: 24px;
    stroke: red;
  }
`;

const NoResultsFoundText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.midGrey};
`;

const EmptyState = styled("div")`
  margin-top: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const SelectText = styled("div")`
  font-family: "Vodafone Rg";
  width: 400px;
  height: 51px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  margin-top: 35px;
`;
