export interface IUpdateGroupOrderRequest {
  serviceIdentifier: string;
  groupName: ServiceGroupbyName;
  toPosition?: number;
  promotedPosition?: number;
  serviceType: ServiceType;
}

export enum ServiceGroupEnum {
  CONSUMER_SERVICES = "Consumer - Services",
  CONSUMER_MINI_APPS = "Consumer - Mini Apps Tab",
  CONSUMER_GROW = "Consumer - Grow",
  AGENT_HUB = "Agent - Hub",
  MERCHANT_HUB = "Merchant - Hub",
  SERVICES_AREA = "Services Area",
  MY_SAFARICOM_PRE_PAID = "My Safaricom Pre-Paid",
  MY_SAFARICOM_POST_PAID = "My Safaricom Post-Paid",
}

export enum ServiceGroupbyName {
  DISCOVER = "Discover",
  GROW = "Grow",
  SERVICES_AREA = "Services Area",
  MY_SAFARICOM_PRE_PAID = "My Safaricom Pre-Paid",
  MY_SAFARICOM_POST_PAID = "My Safaricom Post-Paid",
}

export enum ServiceType {
  ORG = "ORG",
  CONSUMER = "CONSUMER",
}

export enum UserType {
  AGENT = "AGENT",
  MERCHANT = "MERCHANT",
  CUSTOMER = "CUSTOMER",
}
