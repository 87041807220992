import {
  CheckIcon,
  EditIcon,
  ModalTypeEnum,
  SecondaryPageTitle,
  UndoIcon,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { IStoreInterface } from "../../../../configs/store.config";
import { RoutesEnum } from "../../../../routes/routes.constants";
import SafaricomDeleteModal from "../../../../shared/components/safaricom-delete-modal/safaricom-delete-modal.component";
import { PageContent } from "../../../../shared/responsive-ui.styled";
import { PageContainer } from "../../../../shared/shared.styled";
import { ServiceManagerTabNumber } from "../../pages/safaricom-service-manager.page";
import { ServiceManagerActions } from "../../service-manager.store";
import AllowedScopesApi from "../allowed-scopes.api";
import { IAllowedScope } from "../allowed-scopes.model";
import AllowedScopeForm from "../components/allowed-scope-form.component";

/**
 * EditAllowedScopePage component
 */
const EditAllowedScopePage = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showAlert, , setAlertProps] = useAlert();
  const { allowedScope } = useSelector((state: IStoreInterface) => state.serviceManagerReducer);
  const query = new URLSearchParams(useLocation().search);
  const [isEditing, setIsEditing] = React.useState(query.get("editing") === "true" || false);
  const [errorIds] = React.useState<string[]>([]);
  const [showDeleteModal, hideDeleteModal] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <SafaricomDeleteModal
      title={t("pages.serviceManager.allowedScopes.form.deleteTitleModal")}
      description={t("pages.serviceManager.allowedScopes.form.deleteDescriptionModal").replace(
        "{allowedScope}",
        allowedScope.name,
      )}
      primaryBtnId={"delete-button"}
      secondaryBtnId={"cancel-button"}
      primaryBtnLabel={"Delete SSO"}
      primaryAction={() => null} //TODO: on delete call - BE integration
      secondaryAction={() => hideDeleteModal()}
    />,
  );

  /**
   * function go back
   */
  const goBack = () => {
    history.push(`${RoutesEnum.SERVICE_MANAGER}?tabIdx=${ServiceManagerTabNumber.SSO}`);
  };

  /**
   * function on submit form
   */
  const onSubmit = (values: IAllowedScope) => {
    const { iconUrl, ...payload } = values;

    AllowedScopesApi.methods
      .editAllowedScope(payload)
      .then(
        res => {
          console.log("res.data", res.data);
          dispatch(ServiceManagerActions.creators.setAllowedScope(res.data));
          setIsEditing(false);
          setAlertProps({
            title: t("pages.serviceManager.allowedScopes.allowedScopeChanged"),
            type: AlertTypeEnum.SUCCESS,
          });
        },
        error => {
          setAlertProps({
            title: t("pages.serviceManager.allowedScopes.editAllowedScopeError"),
            type: AlertTypeEnum.ERROR,
          });
        },
      )
      .finally(() => {
        showAlert();
      });
  };

  /**
   * function to reset form
   */
  const resetForm = () => {
    const form = document.getElementById("allowed-scope-form") as HTMLFormElement;
    form.reset();
  };

  return (
    <PageContainer>
      <SecondaryPageTitle displayInitialsCircle={false} title={`${allowedScope.name}`} goBackFn={() => goBack()} />
      <PageContent>
        <AllowedScopeForm
          initialValues={allowedScope}
          isEditing={isEditing}
          onSubmitFn={onSubmit}
          errorIds={errorIds}
        />
        <SideMenu className="side-menu">
          {!isEditing ? (
            <LinkContainer
              id="edit-allowed-scope"
              onClick={() => {
                setIsEditing(!isEditing);
              }}
            >
              <IconContainer color={styleTheme.palette.turquoiseBlue}>
                <EditIcon />
              </IconContainer>
              <span>{t("pages.serviceManager.allowedScopes.form.editDetails")}</span>
            </LinkContainer>
          ) : (
            <>
              <LinkContainerSave id="save-changes" type="submit" form={`allowed-scope-form`}>
                <IconContainer color={styleTheme.palette.successGreen}>
                  <CheckIcon />
                </IconContainer>
                <span>{t("pages.serviceManager.allowedScopes.form.saveChanges")}</span>
              </LinkContainerSave>
              <LinkContainer
                id="cancel-changes"
                onClick={() => {
                  setIsEditing(false);
                  resetForm();
                }}
              >
                <IconContainer color={styleTheme.palette.errorRed}>
                  <UndoIcon />
                </IconContainer>
                <span>{t("pages.serviceManager.allowedScopes.form.cancelChanges")}</span>
              </LinkContainer>
            </>
          )}
        </SideMenu>
      </PageContent>
    </PageContainer>
  );
};

export default EditAllowedScopePage;

const SideMenu = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 75px;
  width: 40%;
  margin-left: 50px;
`;

const LinkContainer = styled("div")<{ disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-family: Vodafone Rg;
  color: ${styleTheme.palette.darkGrey};
  font-size: 16px;
  margin-bottom: 15px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? "none" : "inherit")};

  > div {
    margin-right: 8px;
  }
`;
const LinkContainerSave = styled("button")<{ disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-family: Vodafone Rg;
  color: ${styleTheme.palette.darkGrey};
  font-size: 16px;
  margin-bottom: 15px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? "none" : "inherit")};
  border: none;
  background: none;
  padding: 0;

  > div {
    margin-right: 8px;
  }
`;

const IconContainer = styled("div")<{ color: string }>`
  width: 20px;
  height: 20px;
  > svg {
    stroke: ${props => props.color};
  }
`;
