import React from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import styled from "styled-components";
import { CloudUploadIcon, TrashIcon } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";

interface IDropzoneOptionsProps extends DropzoneOptions {
  dropTitle?: string;
  dropDescription?: string;
  releaseText?: string;
  inputName?: string;
  fileName?: string;
  onClear?: () => any;
}

const Dropzone = ({
  onDrop,
  accept,
  multiple,
  dropTitle = "",
  dropDescription = "",
  releaseText = "",
  inputName = "dropzoneInput",
  fileName = "",
  onClear,
}: IDropzoneOptionsProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple,
  });

  return (
    <DropzoneDiv
      active={isDragActive}
      {...getRootProps()}
      style={{ backgroundColor: fileName !== "" ? "#fff" : "#e9f5ff" }}
    >
      <DropzoneInput name={inputName} {...getInputProps()} />
      <DropzoneInsideDiv>
        <TextDiv>
          {isDragActive ? (
            <ContentTextWrapper>
              <IconWrapper active={isDragActive}>
                <CloudUploadIcon />
              </IconWrapper>
              <TitleContent active={isDragActive}>{releaseText}</TitleContent>
            </ContentTextWrapper>
          ) : (
            <ContentTextWrapper>
              {fileName === "" && (
                <>
                  <IconWrapper style={{ paddingRight: 5 }}>
                    <CloudUploadIcon />
                  </IconWrapper>
                  <TitleContent>
                    {dropTitle} <u style={{ color: "#00b0ca", fontWeight: "bold" }}>{dropDescription}</u>
                  </TitleContent>
                </>
              )}
              {fileName !== "" && (
                <>
                  <DescriptionContent style={{ color: styleTheme.palette.darkGrey, paddingRight: 10 }}>
                    {fileName}
                  </DescriptionContent>
                  <IconWrapper style={{ paddingRight: 0 }}>
                    <CloudUploadIcon />
                  </IconWrapper>
                  {onClear && (
                    <IconWrapper
                      style={{ paddingLeft: 10, paddingRight: 0 }}
                      onClick={e => {
                        e.stopPropagation();
                        onClear();
                      }}
                    >
                      <TrashIcon style={{ stroke: styleTheme.palette.vodafoneRed }} />
                    </IconWrapper>
                  )}
                </>
              )}
            </ContentTextWrapper>
          )}
        </TextDiv>
      </DropzoneInsideDiv>
    </DropzoneDiv>
  );
};

export default Dropzone;

const DropzoneDiv = styled("div")<{ active: boolean }>`
  border-radius: 6px;
  cursor: pointer;
  padding: 5px 12px;
  display: flex;
  border: 1px solid ${props => props.theme.palette.aluminium};
  ${props =>
    props.active
      ? `background-color: ${props.theme.palette.vodafoneRed}66;border: 1px solid ${props.theme.palette.vodafoneRed};`
      : null};
`;

const DropzoneInput = styled("input")``;

const DropzoneInsideDiv = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: Vodafone Rg;
  margin: auto;
  align-items: center;
`;

const TextDiv = styled("div")`
  background-color: transparent;
  display: flex;
  height: 100%;
  width: 100%;
`;

const IconWrapper = styled("div")<{ active?: boolean }>`
  flex: 0 auto;
  svg {
    height: 20px;
    width: auto

    stroke: ${props => (props.active ? props.theme.palette.vodafoneRed : "#00b0ca")};
  }
  padding-right: 10px;
`;

const ContentTextWrapper = styled("div")`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const TitleContent = styled("div")<{ active?: boolean }>`
  font-family: Vodafone Rg;
  color: ${props => props.theme.palette.darkGrey};
  font-size: 14px;
  line-height: 20px;
`;
const DescriptionContent = styled("div")<{ hasFile?: boolean }>`
  flex: 1 auto;
  font-family: Vodafone Rg;
  color: ${props => (props.hasFile ? props.theme.palette.vodafoneRed : props.theme.palette.midGrey)};
  font-size: 16px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
