import React from "react";
import { useTranslation } from "react-i18next";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import styled from "styled-components";
import { RatioContainer } from "../../../../shared/shared.styled";
import { DateRangeDropdown, useFilters } from "@wit/mpesa-ui-components";
import { customerStringPath } from "../feedback-and-problems.utils";
import CardsComponent from "./cards.component";
import ProgressBar from "../../../../shared/components/progress-bar/progress-bar.component";

const SectionCustomerDetailsComponent = () => {
  const [t] = useTranslation();
  const { filters, resetFilters, updateFilter, isFilterActive, clearFilter, updateMultipleFilters } = useFilters();

  return (
    <SectionDetailsContainer>
      <SectionDetails>
        <RatioContainer ratio={2.5 / 12}>
          <DateAndSearchFilterWrapper id="date-filter">
            <DateRangeDropdown
              filters={filters}
              updateFilter={updateFilter}
              isFilterActive={isFilterActive}
              clearFilter={clearFilter}
              updateMultipleFilters={updateMultipleFilters}
              translations={{
                dropdownPlaceholder: t(`${customerStringPath}.filtersArea.date`),
                clearFilters: t("components.dateFilter.clearFilters"),
                CUSTOM: t(`${customerStringPath}.filtersArea.dateRange`),
                LAST_3_MONTH: t("components.dateFilter.last3Months"),
                LAST_6_MONTH: t("components.dateFilter.last6Months"),
                LAST_MONTH: t("components.dateFilter.lastMonth"),
                LAST_WEEK: t("components.dateFilter.lastWeek"),
                LAST_YEAR: t("components.dateFilter.lastYear"),
              }}
            />
          </DateAndSearchFilterWrapper>
        </RatioContainer>
      </SectionDetails>
      <CardsContainer>
        <CardsComponent
          title={t(`${customerStringPath}.filtersArea.surveyRespondents`)}
          titleColor={styleTheme.palette.springGreen}
          dataColor={styleTheme.palette.springGreen}
          data={<p>10,234</p>}
          footer={t(`${customerStringPath}.filtersArea.completedSurvey`, { surveyNumbers: "79, 099" })}
        />
        <CardsComponent
          title={t(`${customerStringPath}.filtersArea.probabilityRecomendingApp`)}
          titleColor={styleTheme.palette.turquoiseBlue}
          dataColor={styleTheme.palette.turquoiseBlue}
          data={<p>9/10</p>}
          footer={t(`${customerStringPath}.filtersArea.basedOnAnswers`, { answerNumbers: "10,323" })}
        />
        <CardsComponent
          title={t(`${customerStringPath}.filtersArea.satisfactionWithApp`)}
          titleColor={styleTheme.palette.redViolet}
          dataColor={styleTheme.palette.redViolet}
          data={<p>7/10</p>}
          footer={t(`${customerStringPath}.filtersArea.basedOnAnswers`, { answerNumbers: "10,323" })}
        />
        <CardsComponent
          title={t(`${customerStringPath}.filtersArea.transactionCompletionRate`)}
          titleColor={styleTheme.palette.turquoise}
          data={
            <>
              <ProgressBar
                label="Yes"
                labelColor={styleTheme.palette.turquoise}
                barColor={styleTheme.palette.turquoise}
                description="84%"
                percentage={84}
              />
              <ProgressBar
                label="No"
                labelColor={styleTheme.palette.turquoise}
                barColor={styleTheme.palette.turquoise}
                description="16%"
                percentage={16}
              />
            </>
          }
          footer={t(`${customerStringPath}.filtersArea.basedOnAnswers`, { answerNumbers: "10,200" })}
        />
      </CardsContainer>
    </SectionDetailsContainer>
  );
};

export default SectionCustomerDetailsComponent;

const SectionDetails = styled("div")`
  width: calc(100% - 48px - 48px);
  position: relative;
  display: flex;
  flex-direction: column;
`;

const SectionDetailsContainer = styled.div`
  font-family: Vodafone Rg;
  position: relative;
  background-color: ${styleTheme.palette.white};
  width: calc(100% + 48px + 48px);
  margin: 0;
  margin-left: -48px;
  padding: 0 0 0 48px;
`;

const DateAndSearchFilterWrapper = styled("div")<{ disabled?: Boolean }>`
  width: 100%;
  max-width: 270px;
  height: auto;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const CardsContainer = styled("div")`
  padding: 40px 0;
  display: flex;
  gap: 28px;
`;
