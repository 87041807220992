import { StatusChip } from "@wit/mpesa-ui-components";
import React, { useContext } from "react";
import { ConfigContext } from "../../app.component";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IConfig } from "../../app.component";
import { RoutesEnum } from "../../routes/routes.constants";
import { PageContainer, PageTitle } from "../../shared/shared.styled";
import checkComponentDisplayStatus from "../../shared/hooks/use-check-available-feature-component.hook";
import { FEATURES } from "../../shared/renderer.utils";
import { toTranslationStringKey, getNumberOfDaysFromNow } from "../../shared/shared.utils";
import { useHistory } from "react-router-dom";
import { StatusEnum } from "@wit/mpesa-ui-components/lib/enums/status.enum";
import { BackOfficeMarketsEnums } from "../../shared/shared.enums";
import { NEW_FEATURE_DAYS } from "../../shared/shared.constants";
import { canUserFromSFCMarketViewBusiness } from "./business/utils/check-user-roles.util";
import { useSelector } from "react-redux";
import { IStoreInterface } from "../../configs/store.config";

/**
 * Contains the Configurations cards configurations
 */
const cardsConfiguration = [
  {
    name: FEATURES.BANKS,
    id: "banks",
  },
  {
    name: FEATURES.BUNDLES,
    id: "bundles",
  },
  {
    name: FEATURES.BUSINESS,
    id: "business",
  },
  {
    name: FEATURES.CATEGORIES,
    id: "general-categories",
    addedOn: "01-07-2022",
  },
  {
    name: FEATURES.DISCOVER_CARDS,
    id: "discover-cards",
  },
  {
    name: FEATURES.DOCUMENTS,
    id: "documents",
  },
  {
    name: FEATURES.DYNAMIC_SCREENS,
    id: "dynamic-screens",
  },
  {
    name: FEATURES.FEEDBACK_AND_PROBLEMS_REPORT,
    id: "feedback-and-problem-report",
    addedOn: "06-03-2023",
    hiddenOnMarket: [
      BackOfficeMarketsEnums.GH,
      BackOfficeMarketsEnums.DRC_EKYC,
      BackOfficeMarketsEnums.KE,
      BackOfficeMarketsEnums.ET,
      BackOfficeMarketsEnums.LS,
    ],
  },
  {
    name: FEATURES.LANGUAGE_TRANSLATIONS,
    id: "language-translations",
  },
  {
    name: FEATURES.NETWORKS,
    id: "networks",
    hiddenOnMarket: [BackOfficeMarketsEnums.MZ],
  },
  {
    name: FEATURES.RATE_CARDS,
    id: "rate-cards",
  },
  {
    name: FEATURES.REFERRAL_CAMPAIGNS,
    id: "referral-campaigns",
  },
  {
    name: FEATURES.SHORTCUTS,
    id: "shortcuts",
    addedOn: "08-05-2023",
  },
  {
    name: FEATURES.WALLETS,
    realFeatureName: FEATURES.NETWORKS,
    id: "wallets",
    addedOn: "01-07-2022",
    hiddenOnMarket: [
      BackOfficeMarketsEnums.LS,
      BackOfficeMarketsEnums.TZ,
      BackOfficeMarketsEnums.GH,
      BackOfficeMarketsEnums.DRC,
      BackOfficeMarketsEnums.DRC_EKYC,
      BackOfficeMarketsEnums.KE,
      BackOfficeMarketsEnums.ET,
    ],
  },
];

/**
 * ConfigurationsPage component
 */
const ConfigurationsPage = () => {
  const userRole = useSelector((state: IStoreInterface) => state.authenticationReducer.loggedUser?.role);

  const [t] = useTranslation();
  const history = useHistory();
  const { config } = useContext(ConfigContext);
  const cards = (config as IConfig).market
    ? cardsConfiguration.filter(
        card =>
          !card.hiddenOnMarket?.includes(
            ((config as IConfig).market as string).toLowerCase() as BackOfficeMarketsEnums,
          ),
      )
    : cardsConfiguration;

  return (
    <PageContainer>
      <PageTitle style={{ marginBottom: 43 }}>{t("pages.configuration.title")}</PageTitle>
      <ConfigurationCardsContainer>
        {cards.map((item, index) => {
          if (item.name === FEATURES.BUSINESS && !canUserFromSFCMarketViewBusiness(userRole)) {
            console.log("item", item);

            return null;
          }
          return checkComponentDisplayStatus(
            <ConfigurationCard
              key={index}
              id={item.id.toLowerCase()}
              onClick={() => {
                history.push(RoutesEnum[item.name as keyof typeof RoutesEnum]);
              }}
            >
              <ConfigurationCardTitle>
                {t(`pages.configuration.${toTranslationStringKey(item.id)}`)}
              </ConfigurationCardTitle>
              {item.addedOn && getNumberOfDaysFromNow(item.addedOn as string) < NEW_FEATURE_DAYS ? (
                <div style={{ width: "20px" }}>
                  <StatusChip type={StatusEnum.LIVE}>{t("pages.configuration.new")}</StatusChip>
                </div>
              ) : null}
              <ConfigurationCardSubTitle>{t("pages.configuration.seeMore")}</ConfigurationCardSubTitle>
            </ConfigurationCard>,
            item.realFeatureName ? item.realFeatureName : item.name,
          );
        })}
      </ConfigurationCardsContainer>
    </PageContainer>
  );
};

export default ConfigurationsPage;

const ConfigurationCardsContainer = styled("div")`
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 50px;
`;

const ConfigurationCard = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 158px;
  height: 167px;
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 24px;
  margin-right: 24px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  &:hover {
    cursor: pointer;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  }
`;

const ConfigurationCardTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 20px;
  font-weight: bold;
  height: 60px;
  color: ${props => props.theme.palette.black};
`;

const ConfigurationCardSubTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 13px;
  color: ${props => props.theme.palette.greyDarker};
`;
