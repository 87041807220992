import { ModalTypeEnum, PageTitle, PrimaryButton, useAlert, useModal } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { Breadcrumb } from "@wit/mpesa-ui-components/lib/types";
import { TFunction } from "i18next";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { RoutesEnum } from "../../../../routes/routes.constants";
import LanguageSelectorDropdown from "../../../../shared/components/language-selector-dropdown.component";
import { MakerCheckerSectionsEnum } from "../../../../shared/components/notifications-area/notification.model";
import useVersionsDiff, { VersionItem } from "../../../../shared/hooks/use-version-diff/use-version-diff.hook";
import { ILanguage } from "../../../../shared/models/language.model";
import LanguagesApi from "../../../../shared/services/languages.api";
import { Column, LoadingText, PageContainer, RatioContainer, Row } from "../../../../shared/shared.styled";
import { TableButtonsContainer } from "../../../../shared/shared.utils";
import { DiscoverCardsStatusEnum, IDiscoverCard } from "../../../configurations/discover-cards/discover-cards.model";
import { ILanguageTranslation } from "../../../configurations/language-translations/language-translations.model";
import {
  renderVersionRow,
  getOrderedTranslations,
  selectedLanguageEvaluator,
} from "../../../configurations/language-translations/language-translations.utils";
import RateCardsVersionItem from "../../../configurations/rate-cards/components/version-item.component";
import ServiceVersionItem from "../../../service-builder/components/service-version-item.component";
import { ITask, TaskStatusEnum } from "../task.model";
import TasksApi from "../tasks.api";
import { getTaskStatusColor } from "../tasks.utils";
import TaskDeclineModal from "./task-decline.modal";
import { IOrderedTranslations } from "../../../configurations/language-translations/components/publish-language-translations.modal";
import { useDispatch, useSelector } from "react-redux";
import { IStoreInterface } from "../../../../configs/store.config";
import GeneralCategoriesApi from "../../../configurations/categories/general-categories.api";
import { GeneralCategoriesActions } from "../../../configurations/categories/general-categories.store";

/**
 * get breadcrumbs
 */
const getBreadcrumbs = (t: TFunction, name: string): Breadcrumb[] => {
  return [
    {
      label: t("pages.admin.title"),
      url: `${RoutesEnum.ADMIN}?tabIdx=1`,
    },
    {
      label: name,
      url: "",
    },
  ];
};

export interface ITaskDetails extends ITask {
  previous: any;
  current: any;
}

export interface IEditedTask {
  status: TaskStatusEnum;
  reason?: string;
}

/**
 * Task details page
 */
const TaskDetailsPage = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { taskId } = useParams<any>();
  const [detailsLoading, setDetailsLoading] = useState(true);
  const [requestApproveLoading, setRequestApproveLoading] = useState(false);
  const [taskDetails, setTaskDetails] = useState({} as ITaskDetails);
  const [availableLanguageOptions, setAvailableLanguageOptions] = useState<SharedDropdownOption[]>([]);
  const [selectedLanguage, setSelectedLanguage] = React.useState<SharedDropdownOption>();
  const [showLanguageSelector, setShowLanguageSelector] = React.useState<boolean>(true);
  const { prev, current } = useVersionsDiff(taskDetails?.previous?.translations, taskDetails?.current?.translations);
  const { previousTranslations, currentTranslations } = getOrderedTranslations(
    prev as IOrderedTranslations[],
    current as IOrderedTranslations[],
  );
  const { icons } = useSelector((state: IStoreInterface) => state.serviceManagerReducer);
  const [isLoadingIcons, setIsLoadingIcons] = React.useState(true);

  /**
   * method to get the icons list
   */
  const getIcons = () => {
    GeneralCategoriesApi.methods.getIconsList().then(res => {
      dispatch(GeneralCategoriesActions.creators.fectionsIconsCategories(res.data));
      setIsLoadingIcons(false);
    });
  };

  /**
   * Confirm decline
   */
  const declineAction = (reason: string) => {
    hideDeclineModal();
    const editedTask = {
      status: TaskStatusEnum.DECLINED,
      reason: reason,
    } as IEditedTask;

    updateTaskDetails(taskId, editedTask);
  };

  /**
   * approve action
   */
  const approveAction = () => {
    hideDeclineModal();
    const editedTask = {
      status: TaskStatusEnum.APPROVED,
    } as IEditedTask;

    updateTaskDetails(taskId, editedTask);
  };

  /**
   * hide decline modal
   */
  const _hideDeclineModal = () => {
    hideDeclineModal();
  };

  /**
   * Get available languages and sets on state
   */
  const getAvailableLanguages = () => {
    LanguagesApi.methods.getAvailableLanguages().then(
      res => {
        const languages = res.data.availableLanguages.map((lang: ILanguage) => {
          return {
            label: t(`commons.languages.${lang.code}`),
            key: lang.code,
            code: lang.code,
          };
        });
        const languageSelected = selectedLanguageEvaluator(
          prev as ILanguageTranslation[],
          current as ILanguageTranslation[],
          languages,
        );
        setAvailableLanguageOptions(languages);
        setSelectedLanguage(languageSelected);
      },
      () => {
        setAlertProps({
          type: AlertTypeEnum.ERROR,
          title: t("commons.getLanguagesError"),
        });
        showAlert();
        setAvailableLanguageOptions([
          { label: t(`commons.languages.en`), key: "en", code: "en" },
          { label: t(`commons.languages.sw`), key: "sw", code: "sw" },
        ] as any);
      },
    );
  };

  /**
   * Get task details
   */
  const getTaskDetails = () => {
    TasksApi.methods.getTaskDetails(taskId).then(
      res => {
        setTaskDetails({ ...res.data.task, previous: res.data.previous, current: res.data.current } as ITaskDetails);
        if (res.data.task.section === MakerCheckerSectionsEnum.ACTION_ITEMS) {
          setShowLanguageSelector(false);
        }
        setDetailsLoading(false);
      },
      () => {
        setAlertProps({
          title: t("pages.myTasks.errorLoadingTaskDetails"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * Update task details
   */
  const updateTaskDetails = (taskId: string, editedTask: IEditedTask) => {
    setRequestApproveLoading(true);
    TasksApi.methods
      .editTask(taskId, editedTask)
      .then(
        res => {
          if (res && res.data) {
            setTaskDetails({ ...taskDetails, ...res.data } as ITaskDetails);
          } else {
            getTaskDetails();
          }
        },
        err => {
          setAlertProps({
            type: AlertTypeEnum.ERROR,
            title: t("pages.myTasks.errorEditTaskDetails"),
          });
          showAlert();
        },
      )
      .finally(() => {
        setRequestApproveLoading(false);
      });
  };

  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const [showDeclineModal, hideDeclineModal, setDeclineProps] = useModal(
    ModalTypeEnum.CustomModal,
    { modalStyles: { width: "400px", height: "325px", overflow: "visible" } } as any,
    <TaskDeclineModal cancelAction={_hideDeclineModal} confirmAction={declineAction} />,
  );

  /**
   * Get used icon by iconId
   * @param item
   * @returns
   */
  const getIconById = (iconId: string | number) => {
    return iconId && icons && icons.find(icon => icon.id.toString() === iconId?.toString()) ? (
      <img src={icons.find(icon => icon.id.toString() === iconId?.toString())?.base64} alt={"category-icon"} />
    ) : null;
  };

  /**
   * render item
   * @param item
   * @param type
   */
  const renderItem = (item: IDiscoverCard | any, type: MakerCheckerSectionsEnum) => {
    switch (type) {
      case MakerCheckerSectionsEnum.DISCOVER_CARDS:
        return (
          <>
            <ItemColumn disabled={false}>
              <Row>
                <ImageContainer>
                  <Image background={item.urlImage}></Image>
                </ImageContainer>
                <ItemInfo>
                  {item.name && selectedLanguage && (
                    <Row>
                      <span style={{ fontWeight: 1000, wordBreak: "break-word" }}>
                        {item.name[selectedLanguage.key]}
                      </span>
                    </Row>
                  )}
                  {item.description && selectedLanguage && (
                    <Row style={{ marginTop: 7, marginBottom: 7, wordBreak: "break-word" }}>
                      {item.description[selectedLanguage.key]}
                    </Row>
                  )}
                  {item.endDate && item.endDate !== 0 ? (
                    <DateInfo style={{}}>{`${t("pages.discoverCards.publishModal.expires")} ${moment(
                      item.endDate,
                      "x",
                    ).format("DD/MM/YYYY")}`}</DateInfo>
                  ) : null}
                </ItemInfo>
                <StatusContainer>
                  {item.status && (
                    <StatusRow type={item.status as DiscoverCardsStatusEnum}>
                      {t(`commons.discoverCards.discoverCardsStatusEnum.${item.status}`)}
                    </StatusRow>
                  )}
                </StatusContainer>
              </Row>
            </ItemColumn>
          </>
        );

      case MakerCheckerSectionsEnum.RATE_CARDS:
        return <RateCardsVersionItem item={item} />;

      case MakerCheckerSectionsEnum.SERVICES: {
        return selectedLanguage ? (
          <ServiceVersionItem
            service={item.service}
            serviceVersion={item.serviceVersion}
            language={selectedLanguage.key}
          />
        ) : null;
      }

      case MakerCheckerSectionsEnum.ACTION_ITEMS:
        return (
          <>
            <ItemColumn disabled={false}>
              <Row>
                <ImageContainer style={{ width: "40px", height: "40px" }}>{getIconById(item?.iconId)}</ImageContainer>
                <ItemInfo>
                  {item.name && selectedLanguage && (
                    <Row>
                      <span style={{ fontWeight: 700, wordBreak: "break-word" }}>
                        {item.name[selectedLanguage.key]}
                      </span>
                    </Row>
                  )}
                  {(item.deepLinkType || item.data.type) && (
                    <Row style={{ marginTop: 4 }}>
                      {t(`commons.typeOfLinkDropDown.${item.deepLinkType || item.data.type}`)}
                    </Row>
                  )}
                </ItemInfo>
                <StatusContainer>
                  {item.status && (
                    <StatusRow type={item.status}>
                      {t(`commons.shortcuts.shortcutsStatusEnum.${item.status}`)}
                    </StatusRow>
                  )}
                </StatusContainer>
              </Row>
            </ItemColumn>
          </>
        );

      default:
        return <></>;
    }
  };

  /**
   * render changes
   * @param lastVersion
   * @param currentVersion
   * @param type
   */
  const renderChanges = (lastVersion: any, currentVersion: any, type: MakerCheckerSectionsEnum) => {
    if (type === MakerCheckerSectionsEnum.DISCOVER_CARDS) {
      return (
        currentVersion &&
        currentVersion.discoverCards &&
        currentVersion.discoverCards.map((edited: any, index: number) => {
          return (
            <Item key={edited.id}>
              {lastVersion && lastVersion.discoverCards && lastVersion.discoverCards[index] ? (
                renderItem(lastVersion.discoverCards[index], type)
              ) : (
                <ItemColumn></ItemColumn>
              )}
              {edited ? renderItem(edited, type) : <ItemColumn></ItemColumn>}
            </Item>
          );
        })
      );
    } else if (type === MakerCheckerSectionsEnum.RATE_CARDS) {
      return (
        currentVersion &&
        currentVersion.chargeProfiles &&
        currentVersion.chargeProfiles.map((edited: any, index: number) => {
          return (
            <Item key={edited.id}>
              {lastVersion && lastVersion.chargeProfiles && lastVersion.chargeProfiles[index] ? (
                renderItem(lastVersion.chargeProfiles[index], type)
              ) : (
                <ItemColumn></ItemColumn>
              )}
              {renderItem(edited, type)}
            </Item>
          );
        })
      );
    } else if (type === MakerCheckerSectionsEnum.SERVICES) {
      if (currentVersion && currentVersion.service && currentVersion.serviceVersion) {
        return (
          <Item>
            {renderItem(lastVersion, type)}
            {renderItem(currentVersion, type)}
          </Item>
        );
      }
    } else if (type === MakerCheckerSectionsEnum.LANGUAGE_TRANSLATIONS) {
      return (
        <Item>
          <LanguageTranslationColumn>
            {prev ? (
              <>
                <VersionLabel>
                  {t("pages.languageTranslations.versionNumber")} {lastVersion.version}
                </VersionLabel>
                <DateLabel>
                  {`${t("pages.languageTranslationsVersions.lastUpdate")}: ${moment(lastVersion.lastUpdate).format(
                    "DD MMM YYYY",
                  )}`}
                </DateLabel>
                {previousTranslations.map(languageTranslation => {
                  return selectedLanguage
                    ? renderVersionRow(
                        languageTranslation as VersionItem<ILanguageTranslation>,
                        t,
                        selectedLanguage.key,
                        true,
                        false,
                      )
                    : null;
                })}
              </>
            ) : null}
          </LanguageTranslationColumn>
          <LanguageTranslationColumn>
            {current ? (
              <>
                <VersionLabel>
                  {t("pages.languageTranslations.versionNumber")} {currentVersion.version}
                </VersionLabel>
                <DateLabel>
                  {`${t("pages.languageTranslationsVersions.lastUpdate")}: ${moment(currentVersion.lastUpdate).format(
                    "DD MMM YYYY",
                  )}`}
                </DateLabel>
                {currentTranslations.map(languageTranslation => {
                  return selectedLanguage
                    ? renderVersionRow(
                        languageTranslation as VersionItem<ILanguageTranslation>,
                        t,
                        selectedLanguage.key,
                        false,
                        true,
                      )
                    : null;
                })}
              </>
            ) : null}
          </LanguageTranslationColumn>
        </Item>
      );
    } else if (type === MakerCheckerSectionsEnum.ACTION_ITEMS) {
      return (
        currentVersion &&
        currentVersion.actionItemResponses &&
        currentVersion.actionItemResponses.map((edited: any, index: number) => {
          return (
            <Item key={edited.id}>
              {lastVersion && lastVersion.actionItemResponses && lastVersion.actionItemResponses[index] ? (
                renderItem(lastVersion.actionItemResponses[index], type)
              ) : (
                <ItemColumn></ItemColumn>
              )}
              {edited ? renderItem(edited, type) : <ItemColumn></ItemColumn>}
            </Item>
          );
        })
      );
    }
  };

  /** UseEffects */

  useEffect(() => {
    getTaskDetails();
  }, [taskId]);

  useEffect(() => {
    getAvailableLanguages();
  }, [taskDetails.current]);

  useEffect(() => {
    if ((!icons || (icons && icons.length === 0)) && taskDetails.section === MakerCheckerSectionsEnum.ACTION_ITEMS) {
      getIcons();
    } else {
      setIsLoadingIcons(false);
    }
  }, []);

  return (
    <PageContainer>
      {detailsLoading || isLoadingIcons ? (
        <LoadingText>{t("commons.loadingDetails")}</LoadingText>
      ) : (
        <>
          <PageTitle
            breadcrumbs={getBreadcrumbs(
              t,
              taskDetails.author.name
                ? taskDetails.author.name + t("pages.myTasks.titleSufix")
                : taskDetails.author.id + t("pages.myTasks.titleSufix"),
            )}
            title={
              taskDetails.author.name
                ? taskDetails.author.name + t("pages.myTasks.titleSufix")
                : taskDetails.author.id + t("pages.myTasks.titleSufix")
            }
            navigateFunction={history.push}
            goBackFn={() => history.push(`${RoutesEnum.ADMIN}?tabIdx=1`)}
            separatorStyle={{ display: "none" }}
            titleContainerStyle={{ marginTop: "13px", marginBottom: "20px", height: "60px", fontSize: "24px" }}
            iconContainerStyle={{ color: "#999999" }}
          />
          <TaskDetailsCardContainer id="task-details-card">
            <Row style={{ width: "100%" }}>
              <RatioContainer ratio={3 / 12} style={{ marginRight: 9 }}>
                <Column>
                  <TaskDetailsTitle>{t("pages.myTasks.declineModal.status")}</TaskDetailsTitle>
                  <TaskDetailsDescription>
                    {taskDetails.status === TaskStatusEnum.PENDING_APPROVAL ? (
                      <span style={{ color: getTaskStatusColor(taskDetails.status) }}>
                        {t(`pages.myTasks.statusEnum.${taskDetails.status.toLowerCase()}`)}
                      </span>
                    ) : (
                      <span
                        id={`${t(`pages.myTasks.statusEnum.${taskDetails.status.toLowerCase()}`)}-${
                          taskDetails && taskDetails.approver && taskDetails.approver.name
                            ? taskDetails.approver.name
                            : "null"
                        }`}
                        style={{ color: getTaskStatusColor(taskDetails.status) }}
                      >
                        {t(`pages.myTasks.statusEnum.${taskDetails.status.toLowerCase()}`)}
                        {taskDetails.approver
                          ? ` ${t(`pages.myTasks.by`)} ${
                              taskDetails.approver.name ? taskDetails.approver.name : taskDetails.approver.id
                            }`
                          : ""}
                      </span>
                    )}
                  </TaskDetailsDescription>
                </Column>
              </RatioContainer>
              <RatioContainer ratio={3 / 12} style={{ marginRight: 9 }}>
                <Column>
                  <TaskDetailsTitle>{t("pages.myTasks.declineModal.submission_date")}</TaskDetailsTitle>
                  <TaskDetailsDescription>{moment(taskDetails.date).format("DD MMM YYYY")}</TaskDetailsDescription>
                </Column>
              </RatioContainer>
              <RatioContainer ratio={3 / 12} style={{ marginRight: 9 }}>
                <Column>
                  <TaskDetailsTitle>{t("pages.myTasks.declineModal.section")}</TaskDetailsTitle>
                  <TaskDetailsDescription>
                    {t(`pages.myTasks.sectionEnum.${taskDetails.section}`)}
                  </TaskDetailsDescription>
                </Column>
              </RatioContainer>
              <RatioContainer ratio={3 / 12} style={{ display: "flex", justifyContent: "flex-end" }}>
                {taskDetails.status === TaskStatusEnum.PENDING_APPROVAL && (
                  <Column style={{ alignItems: "flex-end" }}>
                    <Row>
                      <TableButtonsContainer style={{ position: "relative" }}>
                        <ButtonContainer style={{ position: "relative", marginRight: "16px" }}>
                          <PrimaryButton
                            id={"reject-changes"}
                            titleLabel={t("pages.myTasks.declineModal.rejectButton")}
                            disabled={requestApproveLoading}
                            type="submit"
                            redTheme={false}
                            style={{ width: "fit-content" }}
                            onClick={() => {
                              showDeclineModal();
                            }}
                          />
                        </ButtonContainer>
                        <ButtonContainer style={{ position: "relative" }}>
                          <PrimaryButton
                            id={"approve-changes"}
                            titleLabel={t("pages.myTasks.declineModal.approveButton")}
                            disabled={requestApproveLoading}
                            type="submit"
                            redTheme={true}
                            style={{ width: "fit-content" }}
                            onClick={() => {
                              approveAction();
                            }}
                          />
                        </ButtonContainer>
                      </TableButtonsContainer>
                    </Row>
                  </Column>
                )}
                {taskDetails.status === TaskStatusEnum.APPROVED && taskDetails.updateDate && (
                  <Column style={{ width: "100%" }}>
                    <Row>
                      <StatusWrapper>
                        {`${t("pages.myTasks.declineModal.approved_on")} `}
                        {moment(taskDetails.updateDate).format("DD MMM YYYY")}
                      </StatusWrapper>
                    </Row>
                  </Column>
                )}
                {taskDetails.status === TaskStatusEnum.DECLINED && (
                  <Column style={{ width: "100%" }}>
                    <Row>
                      {taskDetails.updateDate && (
                        <StatusWrapper>
                          {`${t("pages.myTasks.declineModal.declined_on")} `}
                          {moment(taskDetails.updateDate).format("DD MMM YYYY")}
                        </StatusWrapper>
                      )}
                    </Row>
                    <Row>
                      {taskDetails.reason && (
                        <ReasonWrapper>
                          {`${t("pages.myTasks.declineModal.reason")} `} {taskDetails.reason}
                        </ReasonWrapper>
                      )}
                    </Row>
                  </Column>
                )}
              </RatioContainer>
            </Row>
          </TaskDetailsCardContainer>

          <Content>
            <Row>
              <ItemColumn>
                {selectedLanguage ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span>{t("pages.discoverCards.publishModal.previousVersion")}</span>
                    {showLanguageSelector && (
                      <LanguageSelectorContainer>
                        <LanguageSelectorDropdown
                          availableLanguages={availableLanguageOptions}
                          selectedLanguage={selectedLanguage}
                          changeSelectedLanguage={setSelectedLanguage}
                          color={theme.palette.turquoiseBlue}
                          id={"language-selector-1"}
                        />
                      </LanguageSelectorContainer>
                    )}
                  </div>
                ) : null}
              </ItemColumn>
              <ItemColumn>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span>{t("pages.discoverCards.publishModal.editedVersion")}</span>
                </div>
              </ItemColumn>
            </Row>
            <ItemsContainer>
              {renderChanges(taskDetails.previous, taskDetails.current, taskDetails.section)}
            </ItemsContainer>
          </Content>
        </>
      )}
    </PageContainer>
  );
};

export default TaskDetailsPage;

const TaskDetailsCardContainer = styled("div")`
  display: flex;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  margin-top: 16px;
  margin-bottom: 40px;
  border-radius: 8px;
  padding: 28px 28px 28px 32px;
  height: 120px;
  width: 100%;
  transition: all 0.5s;
  cursor: pointer;
`;

const ItemsContainer = styled.div`
  /*max-height: 283px;
  overflow: scroll;*/
  width: 100%;
  overflow-x: hidden;
`;

const Item = styled(Row)`
  display: flex;
  align-items: flex-start;
`;

const ImageContainer = styled(Column)`
  margin-right: 24px;
  display: flex;
  align-self: baseline;
  flex: 1;
  padding: 4px;
`;

const Image = styled("div")<{ background: any }>`
  width: 64px;
  height: 64px;
  background: url(${props => (props.background ? `${props.background}?time=${new Date().valueOf()}` : "none")});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
`;

const ItemColumn = styled(Column)<{ disabled?: Boolean }>`
  flex: 1;
  padding: 16px 24px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const ItemInfo = styled(Column)`
  flex: 20;
`;

const StatusContainer = styled(Column)`
  padding-left: 24px;
  display: flex;
  align-self: start;
  flex: 1;
`;

const StatusRow = styled("div")<{ type: DiscoverCardsStatusEnum }>`
  ${props => {
    let color = props.theme.palette.digitalGreen;
    switch (props.type) {
      case DiscoverCardsStatusEnum.LIVE:
        color = props.theme.palette.digitalGreen;
        break;
      case DiscoverCardsStatusEnum.DISABLE:
        color = props.theme.palette.freshOrange;
        break;
      case DiscoverCardsStatusEnum.DISABLED:
        color = props.theme.palette.freshOrange;
        break;
      case DiscoverCardsStatusEnum.ENABLE:
      case DiscoverCardsStatusEnum.EDIT:
        color = props.theme.palette.digitalGreen;
        break;
      case DiscoverCardsStatusEnum.DELETE:
        color = props.theme.palette.vodafoneRed;
        break;
    }
    return `color: ${color};display: flex;justify-content: flex-end;`;
  }}
`;

const Content = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  font-family: Vodafone Rg;
  color: ${props => props.theme.palette.darkGrey};
  border: solid 1px #f4f4f4;
  background: ${props => `linear-gradient(90deg, ${props.theme.palette.lightGrey} 50%, transparent 50%)`};
`;

const DateInfo = styled(Row)`
  font-size: 14px;
  font-weight: normal;
  line-height: 1.29;
  opacity: 0.5;
`;

const ButtonContainer = styled("div")`
  width: fit-content;
`;

const TaskDetailsTitle = styled(Row)`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
  font-weight: bold;
  margin-bottom: 12.5px;
`;

const TaskDetailsDescription = styled(Row)`
  font-family: Vodafone Rg;
  font-size: 18px;
  color: ${props => props.theme.palette.darkGrey};
`;

const StatusWrapper = styled("span")`
  font-family: Vodafone Rg;
  font-size: 18px;
  color: ${props => props.theme.palette.darkGrey};
`;

const ReasonWrapper = styled("span")`
  font-family: Vodafone Rg;
  font-size: 14px;
  margin-top: 8px;
  color: ${props => props.theme.palette.darkGrey};
`;

const LanguageTranslationColumn = styled(Column)`
  width: 50%;
  padding: 16px 24px 24px 24px;
`;

const VersionLabel = styled("span")`
  font-family: Vodafone Rg;
  font-size: 18px;
  color: ${props => props.theme.palette.darkGrey};
  line-height: 21px;
  padding-bottom: 4px;
`;

const DateLabel = styled("span")`
  font-family: Vodafone Rg;
  font-size: 14px;
  color: ${props => props.theme.palette.midGrey};
  line-height: 16px;
  padding-bottom: 24px;
`;

const LanguageSelectorContainer = styled("div")`
  margin-left: 10px;
  button {
    padding-top: 0 !important ;
  }
`;
