import { Magnifier } from "@wit/mpesa-ui-components";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ReactSortable } from "react-sortablejs";
import styled, { ThemeContext } from "styled-components";
import { IStoreInterface } from "../../../../configs/store.config";
import { ListHeader, Row } from "../../../../shared/shared.styled";
import "../discover-cards.css";
import { IDiscoverCard } from "../discover-cards.model";
import DiscoverCardItem from "./discover-card-item.component";
import { isEqual } from "lodash";
import { getAvailableLanguagesOptions } from "../discover-cards.utils";
import ReactTooltip from "react-tooltip";
import LanguageSelectorDropdown from "../../../../shared/components/language-selector-dropdown.component";

export interface IDiscoverCardsListInterface {
  discoverCards: IDiscoverCard[];
  setDiscoverCards: (newState: IDiscoverCard[]) => void;
  onLanguageChange: (language: { label: string; key: string }) => void;
  onSortHandler: (event: any) => void;
  selectedLanguage: { label: string; key: string };
  onRestore: (id: string) => void;
  onDelete: (id: string) => void;
  onStatusChange: (id: IDiscoverCard) => void;
  hasSubmittedVersion?: boolean;
}

/**
 * Discover cards list
 * @param param0
 */
const DiscoverCardsList = ({
  discoverCards,
  setDiscoverCards,
  onLanguageChange,
  onSortHandler,
  selectedLanguage,
  onRestore,
  onDelete,
  onStatusChange,
  hasSubmittedVersion = false,
}: IDiscoverCardsListInterface) => {
  const [t] = useTranslation();
  const discoverCardsStore = useSelector((state: IStoreInterface) => state.discoverCardsReducer.discoverCards);
  const lastVersion = useSelector((state: IStoreInterface) => state.discoverCardsReducer.lastVersion);
  const [dragging, setDragging] = useState(false);
  const theme = useContext(ThemeContext);

  /**
   * Gets the default translation for a given field
   * @param obj
   */
  const getObjectTranslation = (obj: { [x: string]: any }) => {
    return obj !== null ? (obj[selectedLanguage.key] ? obj[selectedLanguage.key] : "n.a.") : "n.a";
  };

  /**
   * Move handler
   * @param event
   */
  const onMoveHandler = (event: any) => {
    if (
      discoverCardsStore &&
      discoverCardsStore.discoverCards &&
      discoverCards &&
      discoverCardsStore.discoverCards.length === discoverCards.length
    ) {
      return event;
    } else {
    }
  };

  /**
   * list equal
   * @param oldValues
   * @param newValues
   */
  const isListEqual = (oldValues: any[], newValues: any[]) => {
    if (!oldValues.length) {
      return true;
    }

    if (!oldValues[0].chosen) {
      oldValues = oldValues.map(v => {
        return { ...v, chosen: false, selected: false };
      });
    }
    return isEqual(oldValues, newValues);
  };

  return (
    <div>
      <ListHeaders>
        <ListHeader ratio={2 / 12} style={{ marginRight: 16 }}>
          {discoverCards && discoverCards ? (
            <LanguageSelectorDropdown
              availableLanguages={getAvailableLanguagesOptions()}
              selectedLanguage={selectedLanguage}
              changeSelectedLanguage={onLanguageChange}
              color={theme.palette.turquoiseBlue}
              id={"language-selector"}
            ></LanguageSelectorDropdown>
          ) : null}
        </ListHeader>
        <ListHeader ratio={3 / 12} style={{ marginRight: 16 }}>
          {t("pages.discoverCards.title")}
        </ListHeader>
        <ListHeader ratio={4 / 12} style={{ marginRight: 16 }}>
          {t("pages.discoverCards.description")}
        </ListHeader>
        <ListHeader ratio={3 / 12} style={{ marginRight: 16, textAlign: "right" }}>
          {t("pages.discoverCards.status")}
        </ListHeader>
      </ListHeaders>
      {discoverCards ? (
        <>
          <ReactSortable
            ghostClass={"sortable-ghost"}
            chosenClass={"sortable-chosen"}
            dragClass={"sortable-drag"}
            list={discoverCards}
            handle={".handle"}
            setList={(newState, sortable, store) => {
              if (!isListEqual(discoverCards, newState)) {
                setDiscoverCards(newState);
              }
            }}
            onMove={onMoveHandler}
            onSort={onSortHandler}
            onChoose={() => {
              setDragging(true);
            }}
            onUnchoose={() => {
              setDragging(false);
              ReactTooltip.hide();
            }}
          >
            {discoverCards.map(card => {
              return (
                <DiscoverCardItem
                  key={card.id}
                  discoverCard={card}
                  getTranslation={getObjectTranslation}
                  onDelete={onDelete}
                  onRestore={onRestore}
                  onStatusChange={onStatusChange}
                  isNew={
                    lastVersion &&
                    lastVersion.discoverCards &&
                    lastVersion.discoverCards.findIndex(c => c.id === card.id) < 0
                  }
                  hasFastActions={!hasSubmittedVersion}
                  dragging={dragging}
                />
              );
            })}
          </ReactSortable>
        </>
      ) : null}
      {discoverCards.length === 0 ? (
        <MagnifierContainer>
          <Magnifier />
          <NoResultsFoundText>{t("pages.discoverCards.noSearchResults")}</NoResultsFoundText>
        </MagnifierContainer>
      ) : null}
    </div>
  );
};

const ListHeaders = styled(Row)`
  padding: 0px;
  margin-bottom: 15px;
`;

const MagnifierContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 50px;
  svg {
    width: 140px;
    height: 140px;
    margin-bottom: 24px;
    stroke: red;
  }
`;

const NoResultsFoundText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.midGrey};
`;

export default DiscoverCardsList;
