import { isKEMarket } from "../../../../shared/shared.utils";
import { ISingleUserRequest, UserRolesDefault } from "../../../admin/users/users.interfaces";

/**
 * Check if the user can add, edit, or delete a business (Popular Pills), apply only for SFCMarket
 * @param userRole user role
 * @returns boolean
 */
export const canUserFromSFCMarketAddOrEditOrDeleteBusiness = (userRole: ISingleUserRequest["role"]) => {
  if (isKEMarket()) {
    return userRole === UserRolesDefault.ADMIN || userRole === UserRolesDefault.DEVELOPER;
  }

  return true; // Not checking for other market
};

/**
 * Check if the user approve (publish) changes of a business (Popular Pills), apply only for SFCMarket
 * @param userRole user role
 * @returns boolean
 */
export const canUserFromSFCMarketApproveBusinessChanges = (userRole: ISingleUserRequest["role"]) => {
  if (isKEMarket()) {
    return (
      userRole === UserRolesDefault.ADMIN ||
      userRole === UserRolesDefault.APPROVER ||
      userRole === UserRolesDefault.DEVELOPER
    );
  }

  return true; // Not checking for other market
};

/**
 * Check if the user approve (publish) changes of a business (Popular Pills), apply only for SFCMarket
 * @param userRole user role
 * @returns boolean
 */
export const canUserFromSFCMarketViewBusiness = (userRole: ISingleUserRequest["role"]) => {
  if (isKEMarket()) {
    return (
      userRole === UserRolesDefault.ADMIN ||
      userRole === UserRolesDefault.APPROVER ||
      userRole === UserRolesDefault.DEVELOPER ||
      userRole === UserRolesDefault.SUPPORT
    );
  }

  return true; // Not checking for other market
};
