import React from "react";
import { Formik, FormikHelpers } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import CampaignProgressAndSubmit from "./campaign-progress-and-submit.component";
import { IReferralCampaign } from "../../referral-campaigns.model";
import RewardDetailsForm from "../../components/reward-details-form.component";
import { validateRewardDetails, validateSchemaRewardDetails } from "../../referral-campaigns.utils";
import { PageContent } from "../../../../../shared/responsive-ui.styled";
import { ILanguage } from "../../../../../shared/models/language.model";

interface ICreateRewardDetailsProps {
  languages: ILanguage[];
  selectedLanguage: string;
  setSelectedLanguage: React.Dispatch<React.SetStateAction<string>>;
  formValues: IReferralCampaign;
  advanceToNextStep: (values: IReferralCampaign) => void;
  setStep: (step: number, values: IReferralCampaign) => void;
}

/**
 * CreateRewardDetails component
 */
const CreateRewardDetails = ({
  languages,
  selectedLanguage,
  setSelectedLanguage,
  formValues,
  advanceToNextStep,
  setStep,
}: ICreateRewardDetailsProps) => {
  const [t] = useTranslation();

  /**
   * Performs the create request
   * @param {ICampaignDetails} values
   * @param {FormikHelpers<ICampaignDetails>} actions
   */
  const handleSubmitRewardDetail = (values: IReferralCampaign, actions: FormikHelpers<IReferralCampaign>) => {
    advanceToNextStep(values);
  };

  return (
    <Formik
      initialValues={formValues}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmitRewardDetail}
      validate={validateSchemaRewardDetails}
      render={({ values, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue, errors }) => (
        <PageContent className={"display-column"}>
          <form onSubmit={handleSubmit}>
            <CampaignProgressAndSubmit
              stepTitle={t("pages.referralCampaigns.createCampaign.rewardDetails.insertRewardDetails")}
              stepDescription={t("pages.referralCampaigns.createCampaign.step").replace("{0}", "2")}
              buttonLabel={t("pages.referralCampaigns.createCampaign.continue")}
              step={2}
              totalOfSteps={4}
              setStep={step => setStep(step, values)}
              onSubmitFn={() => validateRewardDetails(values)}
            />
            <CampaignFormDetailsContainer>
              <RewardDetailsForm
                firstTitle={
                  <TitlesContainer>
                    <FormTitle>{t("pages.referralCampaigns.createCampaign.rewardDetails.title")}</FormTitle>
                    <FormSubTitle>
                      {t("pages.referralCampaigns.createCampaign.rewardDetails.senderRewards")}
                    </FormSubTitle>
                  </TitlesContainer>
                }
                secondTitle={
                  <FormSubTitle>
                    {t("pages.referralCampaigns.createCampaign.rewardDetails.receiverRewards")}
                  </FormSubTitle>
                }
                isEditing={true}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                language={selectedLanguage}
              ></RewardDetailsForm>
            </CampaignFormDetailsContainer>
          </form>
        </PageContent>
      )}
    />
  );
};

export default CreateRewardDetails;

const CampaignFormDetailsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 56px;
  > div > div > span {
    width: 100%;
  }
`;

const FormTitle = styled("span")`
  font-family: "Vodafone Rg";
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 16px;
`;

const FormSubTitle = styled("span")`
  font-family: "Vodafone Rg";
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.darkGrey};
`;

const TitlesContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;
