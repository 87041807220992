import {
  CheckIcon,
  FastActionButton,
  Magnifier,
  ModalTypeEnum,
  PageTitle,
  PrimaryButton,
  UndoIcon,
  useAlert,
  useFilters,
  useModal,
} from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";

import i18next from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { IStoreInterface } from "../../../configs/store.config";
import { RoutesEnum } from "../../../routes/routes.constants";
import LoadingIconWhite from "../../../shared/icons/loading-white.icon";
import { VersionControlButtonsContainer, VersionControlButtonsRow } from "../../../shared/responsive-ui.styled";
import {
  Column,
  ListHeader,
  ListHeaders,
  LoadingText,
  PageContainer,
  RatioContainer,
  Row,
} from "../../../shared/shared.styled";
import SubBundleStatusChip from "../bundles/bundle-details/components/sub-bundle-status.component";
import AddChargeProfileItem from "./components/add-charge-profile-item.component";
import ChargeProfileListItem from "./components/charge-profile-list-item.component";
import LastVersionModal from "./components/check-last-version.component";
import Document from "./components/document.component";
import PublishRateCardsModal from "./components/publish.component";
import RateCardsFilters from "./components/rate-cards-filters.component";
import RateCardsApi from "./rate-cards.api";
import { IChargeProfile, RateCardsStatusEnum } from "./rate-cards.model";
import { RateCardsActions } from "./rate-cards.store";
import { filterChargeProfiles, getBreadcrumbs } from "./rate-cards.utils";

const RateCardsListPage = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { rateCards } = useSelector((state: IStoreInterface) => state.rateCardsReducer);
  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );

  const [lastVersionLoading, setLastVersionLoading] = React.useState(false);
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [revertLoading, setRevertLoading] = useState(false);
  const [showAlert, , setAlertProps] = useAlert();
  const [filtersResult, setFiltersResult] = useState<IChargeProfile[]>();
  const {
    filters,
    updateFilter,
    isFilterActive,
    clearFilter,
    resetFilters,
    getFilterValue,
    getFiltersQueryString,
    updateMultipleFilters,
  } = useFilters();
  const history = useHistory();

  React.useEffect(() => {
    if (filters.size > 0) {
      const newChargeProfiles = filterChargeProfiles(rateCards.chargeProfiles ? rateCards.chargeProfiles : [], filters);
      setFiltersResult(newChargeProfiles);
    } else {
      setFiltersResult(undefined);
    }
  }, [filters, rateCards]);

  React.useEffect(() => {
    getRateCards();
    getLastVersion();
  }, []);

  /*
   * Get rate cards fn
   * */
  const getRateCards = () => {
    RateCardsApi.methods.getRateCards().then(
      res => {
        dispatch(RateCardsActions.creators.getRateCardsSuccess(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.rateCards.getRateCardsError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /*
   * Get last version fn
   * */
  const getLastVersion = () => {
    RateCardsApi.methods.getLastVersion().then(res => {
      dispatch(RateCardsActions.creators.getRateCardsLastVersionSuccess(res.data));
    });
  };

  /*
   * TODO: Revert to last version handler
   * */
  const revertToLastVersion = () => {
    const confirmationProps = {
      title: t("pages.rateCards.confirmRevert"),
      description: t("pages.rateCards.confirmRevertMessage"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      secondaryAction: () => hideConfirmationModal(),
      primaryAction: () => {
        setRevertLoading(true);
        setConfirmationProps({ ...confirmationProps, isLoading: true });
        RateCardsApi.methods
          .revertChanges()
          .then(
            () => {
              setAlertProps({
                title: t("pages.rateCards.revertSuccess"),
                type: AlertTypeEnum.SUCCESS,
              });
              getRateCards();
              getLastVersion();
              showAlert();
              hideConfirmationModal();
            },
            () => {
              setAlertProps({
                title: t("pages.rateCards.revertError"),
                type: AlertTypeEnum.ERROR,
              });
              showAlert();
            },
          )
          .finally(() => {
            setRevertLoading(false);
            setConfirmationProps({ ...confirmationProps, isLoading: false });
          });
      },
    };

    setConfirmationProps(confirmationProps as BaseModalProps);
    showConfirmationModal();
  };

  /*
   * TODO: Publish Changes
   * */
  const publish = () => {
    setApprovalLoading(true);
    RateCardsApi.methods
      .submitForApproval()
      .then(
        () => {
          setAlertProps({
            title: t("pages.rateCards.submitSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          getRateCards();
          getLastVersion();
          showAlert();
          hidePublishModal();
        },
        () => {
          setAlertProps({
            title: t("pages.rateCards.submitError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        /**
         * Reset all publishing loading states
         */
        setLastVersionLoading(false);
        setApprovalLoading(false);
      });
  };

  /**
   * Publish Modal
   */
  const [showPublishModal, hidePublishModal] = useModal(
    ModalTypeEnum.CustomModal,
    { modalStyles: { width: 1024, padding: 0 } } as any,
    <PublishRateCardsModal isLoading={approvalLoading} onCancelClick={() => hidePublishModal()} submit={publish} />,
  );

  /**
   * Invalid Profiles Alert
   */
  const showInvalidProfilesAlert = () => {
    setAlertProps({
      title: t("pages.rateCards.invalidChargeProfiles.title"),
      content: t("pages.rateCards.invalidChargeProfiles.message"),
      type: AlertTypeEnum.ERROR,
    });
    showAlert();
    /**
     * Reset all publishing loading states
     */
    setLastVersionLoading(false);
    setApprovalLoading(false);
  };

  /**
   * Check Last Version Modal
   */
  const [showLastVersionModal, hideLastVersionModal] = useModal(
    ModalTypeEnum.CustomModal,
    { modalStyles: { width: 824, padding: 0 } } as any,
    <LastVersionModal onCancelClick={() => hideLastVersionModal()} />,
  );

  /*
   * Delete charge profile confirmation
   * */
  const showDeleteChargeProfileConfirmation = (chargeProfile: IChargeProfile) => {
    setConfirmationProps({
      title: t("pages.rateCards.deleteChargeProfile"),
      description: t("pages.rateCards.deleteChargeProfileMessage", { chargeProfile: chargeProfile.name }),
      primaryBtnLabel: t("pages.rateCards.delete"),
      primaryAction: () => deleteChargeProfile(chargeProfile),
      secondaryAction: () => hideConfirmationModal(),
      primaryBtnId: "delete-button",
      secondaryBtnId: "cancel-button",
    } as BaseModalProps);
    showConfirmationModal();
  };

  /*
   * Delete charge profile handler
   * */
  const deleteChargeProfile = (chargeProfile: IChargeProfile) => {
    RateCardsApi.methods
      .deleteChargeProfile(chargeProfile.id)
      .then(
        () => {
          setAlertProps({
            title: t("pages.rateCards.deleteChargeProfileSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          getRateCards();
        },
        () => {
          setAlertProps({
            title: t("pages.rateCards.deleteChargeProfileError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        hideConfirmationModal();
      });
  };

  /*
   * Returns a list of unique charge types
   * */
  const getUniqueChargeTypes = () => {
    return rateCards.chargeProfiles
      .map(c => c.chargeType)
      .filter((value, index, self) => self.indexOf(value) === index);
  };

  return (
    <PageContainer>
      <PageTitle
        breadcrumbs={getBreadcrumbs(t)}
        title={t("pages.rateCards.rateCards")}
        navigateFunction={history.push}
        goBackFn={() => history.push(RoutesEnum.CONFIGS)}
        separatorStyle={{ display: "none" }}
        titleContainerStyle={{ marginTop: "13px", marginBottom: "20px", height: "60px" }}
        rightComponentContainerStyle={{ display: "flex", alignContent: "center", height: "100%", marginRight: "0px" }}
        rightComponent={
          <>
            {rateCards ? (
              <ButtonContainer style={{ position: "relative", top: "10px" }}>
                <PrimaryButton
                  id={"publish-changes"}
                  disabled={!rateCards.hasUnpublishedChanges || rateCards.submittedForApproval || lastVersionLoading}
                  titleLabel={t("pages.rateCards.submitChanges")}
                  type="submit"
                  redTheme={true}
                  style={{ width: "fit-content" }}
                  onClick={() => {
                    setLastVersionLoading(true);
                    return rateCards.hasInvalids ? showInvalidProfilesAlert() : showPublishModal();
                  }}
                />
              </ButtonContainer>
            ) : null}
          </>
        }
      />

      {!rateCards.chargeProfiles ? (
        <LoadingText>{t("commons.loadingResults")}</LoadingText>
      ) : (
        <RateCardsContainer>
          <VersionControlButtonsContainer>
            <RatioContainer ratio={2 / 12} style={{ marginRight: "24px" }}>
              <Column>
                <Label>{t("pages.bundleDetails.status")}</Label>
                <SubBundleStatusChip style={{ marginTop: "14px", width: "max-content" }} type={rateCards.status as any}>
                  {rateCards.submittedForApproval && rateCards.status === RateCardsStatusEnum.EDIT
                    ? i18next.t(`commons.rateCards.rateCardsStatusEnum.PENDING`)
                    : i18next.t(`commons.rateCards.rateCardsStatusEnum.${rateCards.status}`)}
                </SubBundleStatusChip>
              </Column>
            </RatioContainer>
            {rateCards.hasUnpublishedChanges && rateCards.hasPreviousVersion && !rateCards.submittedForApproval ? (
              <VersionControlButtonsRow>
                <VersionControlButtonsContainer>
                  <div id={"check-last-version"}>
                    <FastActionButton
                      label={t("pages.rateCards.checkLastVersion")}
                      onClick={() => showLastVersionModal()}
                      iconComponent={
                        <FastIconWrapper color={styleTheme.palette.successGreen}>
                          <CheckIcon />
                        </FastIconWrapper>
                      }
                    />
                  </div>
                  <div id={"revert-to-last-version"}>
                    <FastActionButton
                      label={!revertLoading ? t("pages.languageTranslations.revertChanges") : t("commons.loading")}
                      onClick={revertToLastVersion}
                      iconComponent={
                        <FastIconWrapper color={styleTheme.palette.midGrey}>
                          {revertLoading && <LoadingIconWhite strokeColor={styleTheme.palette.midGrey} />}
                          {!revertLoading && <UndoIcon />}
                        </FastIconWrapper>
                      }
                    />
                  </div>
                </VersionControlButtonsContainer>
              </VersionControlButtonsRow>
            ) : null}
          </VersionControlButtonsContainer>
          <Row>
            <RateCardsFilters
              filters={filters}
              isFilterActive={isFilterActive}
              updateFilter={updateFilter}
              clearFilter={clearFilter}
              resetFilters={resetFilters}
              getFilterValue={getFilterValue}
              getFiltersQueryString={getFiltersQueryString}
              updateMultipleFilters={updateMultipleFilters}
              chargeTypes={getUniqueChargeTypes()}
            />
          </Row>

          <RateCardsListContainer>
            <>
              <ListHeaders>
                <ListHeader ratio={4 / 12}>{t("pages.rateCards.chargeProfile")}</ListHeader>
                <ListHeader ratio={3 / 12}>{t("pages.rateCards.chargeType")}</ListHeader>
                <ListHeader ratio={1 / 12}>{t("pages.rateCards.tiers")}</ListHeader>
                <ListHeader ratio={4 / 12} style={{ textAlign: "right" }}>
                  {t("pages.bundles.status")}
                </ListHeader>
              </ListHeaders>
              {!filtersResult && !rateCards.submittedForApproval ? (
                <AddChargeProfileItem addNewChargeProfileFn={() => history.push(RoutesEnum.CHARGE_PROFILE_CREATE)} />
              ) : null}
              {rateCards.chargeProfiles ? (
                <>
                  {filtersResult ? (
                    <>
                      {filtersResult.map((c: IChargeProfile) => {
                        return (
                          <ChargeProfileListItem
                            key={c.id}
                            item={c}
                            deleteChargeProfile={showDeleteChargeProfileConfirmation}
                            editChargeProfile={chargeProfile =>
                              history.push(RoutesEnum.CHARGE_PROFILE_EDIT.replace(":id", chargeProfile.id))
                            }
                            hasFastActions={
                              rateCards.submittedForApproval !== undefined ? !rateCards.submittedForApproval : true
                            }
                          />
                        );
                      })}
                      {filtersResult.length === 0 ? (
                        <MagnifierContainer>
                          <Magnifier />
                          <NoResultsFoundText>{t("pages.rateCards.noSearchResults")}</NoResultsFoundText>
                        </MagnifierContainer>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {rateCards.chargeProfiles.map(c => {
                        return (
                          <ChargeProfileListItem
                            key={c.id}
                            item={c}
                            deleteChargeProfile={showDeleteChargeProfileConfirmation}
                            editChargeProfile={chargeProfile =>
                              history.push(RoutesEnum.CHARGE_PROFILE_EDIT.replace(":id", chargeProfile.id))
                            }
                            hasFastActions={
                              rateCards.submittedForApproval !== undefined ? !rateCards.submittedForApproval : true
                            }
                          />
                        );
                      })}
                    </>
                  )}
                </>
              ) : (
                <EmptyState>
                  <Document />
                  <SelectText>{t("pages.rateCards.emptyState")}</SelectText>
                </EmptyState>
              )}
            </>
          </RateCardsListContainer>
        </RateCardsContainer>
      )}
    </PageContainer>
  );
};

export default RateCardsListPage;

const RateCardsContainer = styled("div")`
  margin-bottom: 34px;
  display: flex;
  flex-direction: column;
`;

const MagnifierContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 50px;
  svg {
    width: 140px;
    height: 140px;
    margin-bottom: 24px;
    stroke: red;
  }
`;

const NoResultsFoundText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.midGrey};
`;

const ButtonContainer = styled("div")`
  width: fit-content;
`;

const RateCardsListContainer = styled("div")`
  margin-bottom: 50px;
`;

const Label = styled("div")`
  font-family: "Vodafone Rg";
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
  margin-bottom: 8px;
  min-height: 21px;
`;

const EmptyState = styled("div")`
  margin-top: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const SelectText = styled("div")`
  width: 273px;
  height: 51px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  margin-top: 35px;
`;

const FastIconWrapper = styled("span")<{ color: string }>`
  width: 16px;
  height: 16px;
  stroke: ${props => props.color};
  svg {
    width: inherit;
    height: inherit;
  }
`;
