export interface ITablePagination {
  handlePageChange: (pageNumber: number) => void;
  totalItems: number;
  activePage: number;
  pageSize: number;
}

export interface ITablePaginationHook extends Omit<ITablePagination, "handlePageChange"> {}

export const ELLIPSIS = "...";
