import React from "react";
import i18next from "i18next";
import { ColumnProps } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import moment from "moment";

export const RequestPaymentUtils = {
  getBlacklistColumns: (): ColumnProps[] => {
    return [
      {
        formKey: "tillNumber",
        ratio: 5 / 12,
        isEditable: false,
        label: i18next.t("pages.tools.requestPayment.blacklist.table.headers.tillNumber"),
      },
      {
        formKey: "createdAt",
        ratio: 5 / 12,
        isEditable: false,
        label: i18next.t("pages.tools.requestPayment.blacklist.table.headers.blockedOn"),
        changeFunction: (content, rowData: any) => <span>{moment(content).format("DD-MM-YYYY")}</span>,
      },
    ];
  },
};

export function onEnterPressed(event: React.KeyboardEvent<HTMLInputElement>, fn: { (): void; (): void }) {
  if (event.keyCode === 13) {
    fn();
  }
}
