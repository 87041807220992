import { AxiosPromise } from "axios";
import { IExternalServices } from "../../../shared/models/ant-service-manager.model";
import ApiClient from "../../../configs/axios.config";
import {
  ISingleAntExternalServiceRequest,
  IUpdateAntExternalServiceRequest,
  IUpdateAntExternalServiceResponse,
} from "./external-services-add/ant-external-service-add.interface";
import SBApiClient from "../../../configs/axios.service-builder.config";

const AntExternalServicesApi = {
  routes: {
    services: "/external-service/",
  },
  methods: {
    getAntExternalServices: (): AxiosPromise<IExternalServices[]> => {
      return SBApiClient.get(AntExternalServicesApi.routes.services);
    },
    createAntExternalService: (
      antExternalService: ISingleAntExternalServiceRequest[],
    ): AxiosPromise<IExternalServices> => {
      return SBApiClient.post(
        AntExternalServicesApi.routes.services,
        antExternalService.length === 1 ? antExternalService[0] : antExternalService,
      );
    },
    updateAntExternalService: (
      values: IUpdateAntExternalServiceRequest,
    ): AxiosPromise<IUpdateAntExternalServiceResponse> => {
      return SBApiClient.put(AntExternalServicesApi.routes.services, values);
    },
    deleteAntExternalService: (serviceIdentifier: string): AxiosPromise<void> => {
      return SBApiClient.delete(`${AntExternalServicesApi.routes.services}${serviceIdentifier}`);
    },
  },
};

export default AntExternalServicesApi;
