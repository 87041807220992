import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import i18next from "i18next";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { PrimaryButton, TextInput, useAlert } from "@wit/mpesa-ui-components";
import { Formik } from "formik";
import { object, string } from "yup";
import moment from "moment";
import ReferralCampaignsApi from "../referral-campaigns.api";
import { IDuplicateReferralRequest } from "../referral-campaigns.model";

interface IDuplicateReferralModalProps {
  cancelAction: () => void;
  confirmAction: (startDate: string, endDate: string) => void;
}

/**
 * DuplicateReferralModal component
 */
const DuplicateReferralModal = ({ cancelAction, confirmAction }: IDuplicateReferralModalProps) => {
  // Hooks initialization
  const [t] = useTranslation();
  const [showAlert, , setAlertProps] = useAlert();

  /**
   * Handler before duplicating a campaign
   * @param {IReferralCampaign} values
   */
  const beforeSubmit = (values: IDuplicateReferralRequest) => {
    ReferralCampaignsApi.methods
      .areDatesAvailable({
        startDate: moment(values.startDate, "DD/MM/YYYY").valueOf(),
        endDate: moment(values.endDate, "DD/MM/YYYY").valueOf(),
      })
      .then(
        res => {
          if (res.data) {
            confirmAction(values.startDate, values.endDate);
          } else {
            setAlertProps({
              title: t("pages.referralCampaigns.createCampaign.invalidDates"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          }
        },
        () => {
          setAlertProps({
            title: t("pages.referralCampaigns.createCampaign.invalidDates"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
  };

  /**
   * validation schema for formik
   */
  const getValidationSchema = () => {
    return object().shape({
      startDate: string()
        .required(t("pages.referralCampaigns.requiredField"))
        .test("format", t("pages.referralCampaigns.wrongFormat"), function(startDate) {
          if (!startDate) {
            return true;
          }
          return moment(startDate, "DD/MM/YYYY").format("DD/MM/YYYY") === startDate;
        })
        .test("is-greater", t("pages.referralCampaigns.timeGreaterStart"), function(startDate) {
          if (!startDate) {
            return true;
          }
          return moment(startDate, "DD/MM/YYYY").isSameOrAfter(new Date(), "day");
        }),
      endDate: string()
        .required(i18next.t("pages.referralCampaigns.requiredField"))
        .test("format", i18next.t("pages.referralCampaigns.wrongFormat"), function(endDate) {
          if (!endDate) {
            return true;
          }
          return moment(endDate, "DD/MM/YYYY").format("DD/MM/YYYY") === endDate;
        })
        .test("is-greater", i18next.t("pages.referralCampaigns.timeGreaterEnd"), function(endDate) {
          if (!endDate) {
            return true;
          }
          return moment(endDate, "DD/MM/YYYY").isAfter(moment(this.parent.startDate, "DD/MM/YYYY"), "day");
        }),
    });
  };

  return (
    <DuplicateReferralForm id="duplicateReferralForm">
      <Title>{t("pages.referralCampaigns.duplicateCampaignModal.title")}</Title>
      <Description>{t("pages.referralCampaigns.duplicateCampaignModal.description")}</Description>
      <Formik
        initialValues={{ startDate: "", endDate: "" }}
        onSubmit={beforeSubmit}
        validationSchema={getValidationSchema()}
        render={({ values, handleSubmit, setFieldValue, errors }) => (
          <form onSubmit={handleSubmit}>
            <TextInput
              title={t("pages.referralCampaigns.duplicateCampaignModal.startDate")}
              required={true}
              value={values.startDate}
              name="startDate"
              onChange={e => {
                setFieldValue("startDate", e.target.value);
              }}
              error={errors.startDate}
              placeholder={t("pages.referralCampaigns.duplicateCampaignModal.placeholder")}
            />

            <div style={{ marginTop: "12px" }}>
              <TextInput
                title={t("pages.referralCampaigns.duplicateCampaignModal.endDate")}
                required={true}
                value={values.endDate}
                name="endDate"
                onChange={e => {
                  setFieldValue("endDate", e.target.value);
                }}
                error={errors.endDate}
                placeholder={t("pages.referralCampaigns.duplicateCampaignModal.placeholder")}
              />
            </div>

            <DuplicateReferralButtonsWrapper>
              <DuplicateReferralButtonDiv>
                <PrimaryButton
                  titleLabel={t("pages.referralCampaigns.duplicateCampaignModal.cancel")}
                  onClick={() => {
                    cancelAction();
                  }}
                  redTheme={false}
                  loading={false}
                  style={{ width: "111px" }}
                  id="cancel-button"
                />
              </DuplicateReferralButtonDiv>
              <DuplicateReferralButtonDiv>
                <PrimaryButton
                  titleLabel={t("pages.referralCampaigns.duplicateCampaignModal.duplicate")}
                  type="submit"
                  disabled={!values.startDate || !values.endDate}
                  redTheme={true}
                  loading={false}
                  style={{ width: "100px" }}
                  id="duplicate-button"
                />
              </DuplicateReferralButtonDiv>
            </DuplicateReferralButtonsWrapper>
          </form>
        )}
      />
    </DuplicateReferralForm>
  );
};

export default DuplicateReferralModal;

const DuplicateReferralForm = styled("div")`
  display: flex;
  padding: 24px;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const DuplicateReferralButtonDiv = styled("div")`
  margin-right: 12px;
  &:last-of-type {
    margin-right: 0;
  }
`;
const DuplicateReferralButtonsWrapper = styled("div")`
  width: 100%;
  display: flex;
  margin-top: 50px;
  justify-content: flex-end;
`;

const Title = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 13px;
`;
const Description = styled("div")`
  font-family: Vodafone Rg;
  font-size: 18px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 24px;
`;
