import {
  CheckIcon,
  FastActionButton,
  ModalTypeEnum,
  PageTitle,
  PrimaryButton,
  UndoIcon,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import { Breadcrumb } from "@wit/mpesa-ui-components/lib/types";
import i18next, { TFunction } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { IStoreInterface } from "../../../configs/store.config";
import { RoutesEnum } from "../../../routes/routes.constants";
import { Column, LoadingText, PageContainer, RatioContainer, Row } from "../../../shared/shared.styled";
import SubBundleStatusChip from "../bundles/bundle-details/components/sub-bundle-status.component";
import ShortcutsList from "./components/shortcuts-list.component";
import ShortcutsApi from "./shortcuts.api";
import { ShortcutsActions } from "./shortcuts.store";
import LastVersionModal from "./components/shortcuts-check-last-version";
import PublishShortcut from "./components/shortcuts-publish";
import { ShortcutsStatusEnum, ShortcutsErrorCodes } from "./shortcuts.model";
import { VersionControlButtonsContainer, VersionControlButtonsRow } from "../../../shared/responsive-ui.styled";
import LoadingIconWhite from "../../../shared/icons/loading-white.icon";
import LanguagesApi from "../../../shared/services/languages.api";
import { ILanguage } from "../../../shared/models/language.model";
import GeneralCategoriesApi from "../categories/general-categories.api";
import { GeneralCategoriesActions } from "../categories/general-categories.store";

/**
 * Gets the Breadcrumbs
 * @param t
 */
const getBreadcrumbs = (t: TFunction): Breadcrumb[] => {
  return [
    {
      label: t("pages.configuration.title"),
      url: RoutesEnum.CONFIGS,
    },
    {
      label: t("pages.shortcuts.shortcuts"),
      url: "",
    },
  ];
};

/**
 * ShortcutsPage component
 */
const ShortcutsPage = () => {
  const [t] = useTranslation();
  const [showAlert, , setAlertProps] = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = React.useState(true);
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [revertLoading, setRevertLoading] = useState(false);
  const [availableLanguages, setAvailableLanguages] = useState<ILanguage[]>();
  const { icons } = useSelector((state: IStoreInterface) => state.serviceManagerReducer);
  const [isLoadingIcons, setIsLoadingIcons] = React.useState(true);

  /**
   * method to get the icons list
   */
  const getIcons = () => {
    GeneralCategoriesApi.methods.getIconsList().then(res => {
      dispatch(GeneralCategoriesActions.creators.fectionsIconsCategories(res.data));
      setIsLoadingIcons(false);
    });
  };

  /**
   * Gets the default translation for a given field
   * @param obj
   */
  const getObjectTranslation = (obj: { [x: string]: any }) => {
    return obj !== null ? (obj[selectedLanguage.key] ? obj[selectedLanguage.key] : "n.a.") : "n.a";
  };

  const [showLastVersionModal, hideLastVersionModal] = useModal(
    ModalTypeEnum.CustomModal,
    { modalStyles: { width: 482, overflow: "initial" } } as any,
    <LastVersionModal onCancelClick={() => hideLastVersionModal()} icons={icons} />,
  );

  const [showPublish, hidePublish] = useModal(
    ModalTypeEnum.CustomModal,
    { modalStyles: { width: 1000, overflow: "initial" } } as any,
    <PublishShortcut
      isLoading={approvalLoading}
      onCancelClick={() => {
        hidePublish();
      }}
      submit={() => publish()}
      icons={icons}
    />,
  );

  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );

  // Redux state
  const shortcutsStore = useSelector((state: IStoreInterface) => state.shortcutsReducer.shortcuts, shallowEqual);

  const [selectedLanguage, setSelectedLanguage] = React.useState({
    label: t("commons.languages.en"),
    key: "en",
  });

  /**
   * Gets the list of shortcuts
   */
  const getShortcuts = () => {
    let allLoaded = false;
    setIsLoading(true);
    ShortcutsApi.methods
      .getShortcuts()
      .then(
        res => {
          dispatch(ShortcutsActions.creators.getShortcutsSuccess(res.data));
        },
        () => {
          setAlertProps({
            title: t("pages.shortcuts.getShortcutsError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        if (allLoaded) {
          setIsLoading(false);
        } else {
          allLoaded = true;
        }
      });
    ShortcutsApi.methods
      .getLastVersion()
      .then(
        res => {
          dispatch(ShortcutsActions.creators.getShortcutsLastVersion(res.data));
        },
        () => {
          setAlertProps({
            title: t("pages.shortcuts.getShortcutsError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        if (allLoaded) {
          setIsLoading(false);
        } else {
          allLoaded = true;
        }
      });
  };

  React.useEffect(() => {
    getShortcuts();

    if (!icons || (icons && icons.length === 0)) {
      getIcons();
    } else {
      setIsLoadingIcons(false);
    }

    LanguagesApi.methods.getAvailableLanguages().then(
      res => {
        setAvailableLanguages(res.data.availableLanguages);
      },
      () => {
        setAlertProps({
          type: AlertTypeEnum.ERROR,
          title: t("commons.getLanguagesError"),
        });
        showAlert();
        setAvailableLanguages([
          {
            name: t("commons.languages.en"),
            code: "en",
            id: "en",
            urlIcon: "",
          },
          {
            name: t("commons.languages.sw"),
            code: "sw",
            id: "sw",
            urlIcon: "",
          },
        ]);
      },
    );
  }, []);

  /**
   * Revert to last version handler
   */
  const revertToLastVersion = () => {
    const confirmationProps = {
      title: t("pages.shortcuts.confirmRevert"),
      description: t("pages.shortcuts.confirmRevertMessage"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      secondaryAction: () => {
        hideConfirmationModal();
      },
      primaryAction: () => {
        setRevertLoading(true);
        setConfirmationProps({ ...confirmationProps, isLoading: true });
        ShortcutsApi.methods
          .revertChanges()
          .then(
            () => {
              setAlertProps({
                title: t("pages.shortcuts.revertSuccess"),
                type: AlertTypeEnum.SUCCESS,
              });
              showAlert();
              hideConfirmationModal();
              getShortcuts();
            },
            () => {
              setAlertProps({
                title: t("pages.shortcuts.revertError"),
                type: AlertTypeEnum.ERROR,
              });
              showAlert();
            },
          )
          .finally(() => {
            setRevertLoading(false);
            setConfirmationProps({ ...confirmationProps, isLoading: false });
          });
      },
    };

    setConfirmationProps(confirmationProps as BaseModalProps);
    showConfirmationModal();
  };

  /**
   * Publish Changes
   */
  const publish = () => {
    setApprovalLoading(true);
    ShortcutsApi.methods
      .submitShortcuts()
      .then(
        () => {
          setAlertProps({
            title: t("pages.shortcuts.submitSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          hideConfirmationModal();
          getShortcuts();
          hidePublish();
        },
        err => {
          if (err.data && err.data.status && err.data.status.businessCode === ShortcutsErrorCodes.MANY_LIVE_CARDS) {
            setAlertProps({
              title: t("pages.shortcuts.submitLiveError", {
                max_live:
                  err?.data?.status?.message.split(":").length > 0 ? err?.data?.status?.message.split(":")[1] : "3",
              }),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          } else {
            setAlertProps({
              title: t("pages.shortcuts.submitError"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          }
        },
      )
      .finally(() => {
        /**
         * Reset all publishing loading states
         */
        setApprovalLoading(false);
      });
  };

  /**
   * Sets showable shortcuts
   * @param e
   */
  const setShowableShortcuts = (e: any) => {
    const newShortcut = {
      actionItemResponses: e,
    };
    dispatch(ShortcutsActions.creators.getShortcutsSuccess({ ...shortcutsStore, ...newShortcut }));
  };

  /**
   * On list change handler
   * @param event
   */
  const onSortHandler = (event: any) => {
    const ids = shortcutsStore.actionItemResponses.map(ai => {
      return ai.id;
    });
    ShortcutsApi.methods.sortShortcuts(ids).then(
      res => {
        dispatch(ShortcutsActions.creators.getShortcutsSuccess(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.shortcuts.sortError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  return (
    <PageContainer>
      <PageTitle
        breadcrumbs={getBreadcrumbs(t)}
        title={t("pages.shortcuts.shortcuts")}
        navigateFunction={history.push}
        goBackFn={() => history.push(RoutesEnum.CONFIGS)}
        separatorStyle={{ display: "none" }}
        titleContainerStyle={{ marginTop: "13px", marginBottom: "20px", height: "60px" }}
        rightComponentContainerStyle={{
          display: "flex",
          alignContent: "center",
          height: "100%",
          marginRight: "0px",
        }}
        rightComponent={
          <>
            {shortcutsStore ? (
              <ButtonContainer style={{ position: "relative", top: "10px" }}>
                <PrimaryButton
                  id={"publish-changes"}
                  disabled={!shortcutsStore.hasUnpublishedChanges || shortcutsStore.submittedForApproval}
                  titleLabel={t("pages.shortcuts.submitChanges")}
                  type="submit"
                  redTheme={true}
                  style={{ width: "fit-content" }}
                  onClick={() => {
                    showPublish();
                  }}
                />
              </ButtonContainer>
            ) : null}
          </>
        }
      />
      {!shortcutsStore.actionItemResponses || isLoading || isLoadingIcons ? (
        <LoadingText>{t("commons.loadingResults")}</LoadingText>
      ) : null}
      {shortcutsStore.actionItemResponses && !isLoading && !isLoadingIcons ? (
        <ShortcutsContainer>
          <VersionControlButtonsContainer>
            <RatioContainer ratio={2 / 12} style={{ marginRight: "24px" }}>
              <Column>
                <Label>{t("pages.bundleDetails.status")}</Label>
                <SubBundleStatusChip
                  style={{ marginTop: "14px", width: "max-content" }}
                  type={shortcutsStore.status as any}
                >
                  {shortcutsStore.submittedForApproval &&
                  (shortcutsStore.status === ShortcutsStatusEnum.DISABLE ||
                    shortcutsStore.status === ShortcutsStatusEnum.ENABLE ||
                    shortcutsStore.status === ShortcutsStatusEnum.EDIT)
                    ? i18next.t(`commons.shortcuts.shortcutsStatusEnum.PENDING`)
                    : i18next.t(`commons.shortcuts.shortcutsStatusEnum.${shortcutsStore.status}`)}
                </SubBundleStatusChip>
              </Column>
            </RatioContainer>
            {!shortcutsStore.submittedForApproval &&
            shortcutsStore.hasUnpublishedChanges &&
            shortcutsStore.hasPreviousVersion ? (
              <VersionControlButtonsRow>
                <VersionControlButtonsContainer>
                  <div id={"check-last-version"}>
                    <FastActionButton
                      label={t("pages.shortcuts.checkLastVersion")}
                      onClick={showLastVersionModal}
                      iconComponent={
                        <FastIconWrapper color={styleTheme.palette.successGreen}>
                          <CheckIcon />
                        </FastIconWrapper>
                      }
                    ></FastActionButton>
                  </div>
                  <div id={"revert-to-last-version"}>
                    <FastActionButton
                      label={!revertLoading ? t("pages.languageTranslations.revertChanges") : t("commons.loading")}
                      onClick={revertToLastVersion}
                      iconComponent={
                        <FastIconWrapper color={styleTheme.palette.midGrey}>
                          {revertLoading && <LoadingIconWhite strokeColor={styleTheme.palette.midGrey} />}
                          {!revertLoading && <UndoIcon />}
                        </FastIconWrapper>
                      }
                    />
                  </div>
                </VersionControlButtonsContainer>
              </VersionControlButtonsRow>
            ) : null}
          </VersionControlButtonsContainer>
          <ShortcutsList
            shortcuts={shortcutsStore.actionItemResponses}
            setShortcuts={setShowableShortcuts}
            onLanguageChange={setSelectedLanguage}
            selectedLanguage={selectedLanguage}
            onSortHandler={onSortHandler}
            hasSubmittedVersion={shortcutsStore.submittedForApproval}
            getShortcuts={getShortcuts}
            getTranslation={getObjectTranslation}
            availableLanguages={availableLanguages}
            icons={icons}
          ></ShortcutsList>
        </ShortcutsContainer>
      ) : null}
    </PageContainer>
  );
};

const ButtonContainer = styled("div")`
  width: fit-content;
`;

const ShortcutsContainer = styled("div")`
  margin-bottom: 34px;
  display: flex;
  flex-direction: column;
`;

const FastIconWrapper = styled("span")<{ color: string }>`
  width: 16px;
  height: 16px;
  stroke: ${props => props.color};
  svg {
    width: inherit;
    height: inherit;
  }
`;

const Label = styled("span")`
  font-family: Vodafone Rg;
  font-weight: bold;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
`;

export default ShortcutsPage;
