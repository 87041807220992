import {
  FastActionButton,
  ModalTypeEnum,
  SearchBar,
  Table,
  TrashIcon,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import { SortDirectionEnum } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import i18next from "i18next";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemeContext } from "styled-components";
import { IApp, IReleasedVersion } from "../../../../shared/models/app.model";
import { SearchBarFilterContainer } from "../../../../shared/responsive-ui.styled";
import { FieldTypeEnum } from "../../../../shared/shared.enums";
import { IconContainer } from "../../../../shared/shared.styled";
import { TableButtonsContainer, sortArrayBy } from "../../../../shared/shared.utils";
import AppsApi from "../../apps.api";
import { AppReleaseVersionsUtils } from "./app-release-versions.utils";

interface IAppReleaseVersionsTable {
  app: IApp;
  refetchApp: () => void;
}
const AppReleaseVersionsTable = ({ app, refetchApp }: IAppReleaseVersionsTable) => {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [releasedVersions, setReleasedVersions] = React.useState<IReleasedVersion[]>([]);
  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );
  const [showAlert, , setAlertProps] = useAlert();
  const [t] = useTranslation();
  const themeContext = useContext(ThemeContext);

  /*
   * Search for versions
   * */
  React.useEffect(() => {
    //TODO: use ts v3.7 to remove these ts-ignores by using the nullish operator
    // @ts-ignore
    let releasedVersionsClone = [...app.releases.releasedVersions];

    if (!!searchQuery) {
      releasedVersionsClone = releasedVersionsClone.filter(
        rv =>
          rv.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          rv.version.toLowerCase().includes(searchQuery.toLowerCase()),
      );
    }
    setReleasedVersions(releasedVersionsClone);
  }, [searchQuery]);

  React.useEffect(() => {
    setReleasedVersions(
      sortArrayBy(app.releases!.releasedVersions, "version", SortDirectionEnum.DESC, FieldTypeEnum.NUMBER),
    );
    setSearchQuery("");
  }, [app]);

  /*
   * Delete release version
   * */
  const deleteReleaseVersion = (rv: IReleasedVersion) => {
    AppsApi.methods.deleteReleaseVersion(app.id, rv.id).then(
      () => {
        hideConfirmationModal();
        setAlertProps({
          title: t("pages.appDetails.releaseVersions.deleteSuccess"),
          type: AlertTypeEnum.SUCCESS,
        });
        showAlert();
        refetchApp();
      },
      () => {
        setAlertProps({
          title: t("pages.appDetails.releaseVersions.deleteError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };
  const showDeleteReleaseVersionModal = (rv: IReleasedVersion) => {
    setConfirmationProps({
      title: t("pages.appDetails.releaseVersions.deleteConfirmationTitle"),
      description: t("pages.appDetails.releaseVersions.deleteConfirmationMessage"),
      primaryBtnId: "delete-button",
      secondaryBtnId: "cancel-button",
      primaryBtnLabel: t("pages.appDetails.releaseVersions.delete"),
      primaryAction: () => deleteReleaseVersion(rv),
      secondaryAction: () => hideConfirmationModal(),
    } as BaseModalProps);
    showConfirmationModal();
  };

  return (
    <>
      <FiltersContainer>
        <SearchBarFilterContainer>
          <SearchBar
            placeholderLabel={t("pages.appDetails.releaseVersions.searchPlaceholder")}
            maxLength={50}
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            clearValue={() => setSearchQuery("")}
          />
        </SearchBarFilterContainer>
      </FiltersContainer>
      <Table<IReleasedVersion>
        columns={AppReleaseVersionsUtils.getReleaseVersionsTableColumns()}
        values={releasedVersions}
        sortable={true}
        initialSortColumnFormKey={"name"}
        sortClickAction={(column, direction) => {
          setReleasedVersions(
            sortArrayBy(
              releasedVersions,
              column.formKey as keyof IReleasedVersion,
              direction,
              column.formKey === "version" ? FieldTypeEnum.NUMBER : FieldTypeEnum.STRING,
            ),
          );
        }}
        initialSortDirection={SortDirectionEnum.DESC}
        displayActions={releasedVersion =>
          renderFastActions<IReleasedVersion>(
            releasedVersion,
            {
              delete: { onClick: showDeleteReleaseVersionModal },
            },
            themeContext,
          )
        }
      />
    </>
  );
};

/*
 * Row fast actions
 * */
const renderFastActions = <T extends IReleasedVersion>(
  value: T,
  buttonActions: {
    delete: { onClick: (row: T) => any };
  },
  themeContext: any,
) => {
  return (
    <TableButtonsContainer id={`delete-${value.version}`}>
      <FastActionButton
        iconComponent={
          <IconContainer size={16} color={themeContext.palette.errorRed}>
            <TrashIcon />
          </IconContainer>
        }
        onClick={() => buttonActions.delete.onClick(value)}
        label={i18next.t("pages.appDetails.releaseVersions.delete")}
      />
    </TableButtonsContainer>
  );
};

const FiltersContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 23px;
  margin-bottom: 23px;
`;
export default AppReleaseVersionsTable;
