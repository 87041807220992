import { produce } from "immer";
import { IPayloadAction } from "../../../shared/shared.interfaces";
import { IClientDocBulk } from "./doc-bulk.model";
import { IDocBulkEntry } from "./doc-bulk.interface";
import { getDocBulkStatus } from "../ekyc.shared.utils";
import { ClientDocBulkStatusEnum } from "../ekyc.shared.enums";

export const DocBulkActions = {
  types: {
    FETCH_BULKS_SUCCESS: "FETCH_BULKS_SUCCESS",
  },
  creators: {
    fetchBulksSuccessAction: (bulks: IDocBulkEntry[], count: number) => ({
      type: DocBulkActions.types.FETCH_BULKS_SUCCESS,
      payload: {
        bulks,
        count,
      },
    }),
  },
};

export interface IDocBulksListReducerInterface {
  bulkEntries: IClientDocBulk[];
  totalBulks: number;
}

const bulksListInitialState: IDocBulksListReducerInterface = {
  bulkEntries: [],
  totalBulks: 0,
};

export const convertDocBulkEntryToModel = (entry?: IDocBulkEntry): IClientDocBulk | null => {
  if (!entry) {
    return null;
  }

  return {
    remoteId: entry.id,
    bulkName: entry.bulkName,
    creationDate: entry.createOn,
    numRequests: entry.numberOfRequests,
    conflictedRequestIds: entry.invalidIds,
    status: getDocBulkStatus(entry.status),
    statusDate: entry.statusDate,
    createdBy: entry.operator,
  };
};

export const docBulksListReducer = produce((draft: IDocBulksListReducerInterface, action: IPayloadAction<any>) => {
  switch (action.type) {
    case DocBulkActions.types.FETCH_BULKS_SUCCESS:
      draft.bulkEntries = action.payload.bulks.map(function(entry: IDocBulkEntry) {
        return convertDocBulkEntryToModel(entry);
      });
      draft.totalBulks = action.payload.count;
      return draft;
    default:
      return draft;
  }
}, bulksListInitialState);
