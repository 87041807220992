import { produce } from "immer";
import { AuthenticationActions } from "../../authentication/authentication.store";
import { IPayloadAction } from "../../../shared/shared.interfaces";
import { ISurvey } from "./surveys.api";

export const SurveysActions = {
  types: {
    FETCH_SURVEYS_SUCCESS: "FETCH_SURVEYS_SUCCESS",
    ADD_SURVEY: "ADD_SURVEY",
    DELETE_SURVEY: "DELETE_SURVEY",
    PUBLISH_SURVEY: "PUBLISH_SURVEY",
  },
  creators: {
    fetchSurveysSuccessAction: (surveys: ISurvey[]) => ({
      type: SurveysActions.types.FETCH_SURVEYS_SUCCESS,
      payload: {
        surveys,
      },
    }),
    deleteSurveyAction: (id: string) => ({
      type: SurveysActions.types.DELETE_SURVEY,
      payload: {
        id,
      },
    }),
  },
};

export interface ISurveyReducerInterface {
  surveys: ISurvey[];
}

const initialState: ISurveyReducerInterface = {
  surveys: [],
};

export const surveysReducer = produce((draft: ISurveyReducerInterface, action: IPayloadAction<any>) => {
  switch (action.type) {
    case SurveysActions.types.FETCH_SURVEYS_SUCCESS:
      draft.surveys = action.payload.surveys;
      return;
    case SurveysActions.types.DELETE_SURVEY:
      const survey = action.payload;
      const surveyIdx = draft.surveys.findIndex(s => s.id === survey.id);
      if (surveyIdx > -1) {
        draft.surveys.splice(surveyIdx, 1);
      }
      return;
    case AuthenticationActions.types.LOGOUT:
      return initialState;
    default:
      return draft;
  }
}, initialState);
