import { Drawer } from "@wit/mpesa-ui-components";
import React from "react";
import ChangePassword from "./change-password.component";
import UserArea from "./user-area.component";

export interface IUserAreaProps {
  isVisible: boolean;
  hideDrawer: () => void;
  onLogoutClick: () => void;
}
export enum UserAreasEnum {
  USER_AREA = "USER_AREA",
  CHANGE_PASSWORD_AREA = "CHANGE_PASSWORD",
}

const UserDrawer = ({ isVisible, hideDrawer, onLogoutClick }: IUserAreaProps) => {
  const [area, setArea] = React.useState(UserAreasEnum.USER_AREA);

  const renderArea = () => {
    switch (area) {
      case UserAreasEnum.USER_AREA:
        return (
          <UserArea
            hideDrawer={hideDrawer}
            onLogoutClick={onLogoutClick}
            onChangePasswordClick={() => {
              setArea(UserAreasEnum.CHANGE_PASSWORD_AREA);
            }}
          />
        );
      case UserAreasEnum.CHANGE_PASSWORD_AREA:
        return (
          <ChangePassword
            showUserArea={() => {
              setArea(UserAreasEnum.USER_AREA);
            }}
          />
        );
      default:
        return <p>Something went wrong</p>;
    }
  };

  return (
    <Drawer isVisible={isVisible} hideDrawer={hideDrawer}>
      {renderArea()}
    </Drawer>
  );
};

export default UserDrawer;
