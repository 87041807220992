export interface IDocBulkCreateRequest {
  status: string[];
  searchString?: string;
  dateRangeIni?: number;
  dateRangeEnd?: number;
  sort?: string[];
}

export interface IDocBulksRequest {
  page: number;
  pageSize: number;
  bulkName?: string;
  sort?: string;
}

export interface IDocBulksResponse {
  totalElements: number;
  bulkRegistrations: IDocBulkEntry[];
}

export interface IDocBulkEntry {
  id: string;
  bulkName: string;
  createOn: number;
  numberOfRequests: number;
  invalidIds?: string[];
  status: string;
  statusDate: number;
  operator: string;
}

export const BulkColumnKeysToSortParam: any = {
  bulkName: "bulkName",
  creationDate: "createOn",
  numRequests: "numberOfRequests",
  status: "status",
};

export const BulkSortDirectionToSortParam: any = {
  1: "ASC",
  2: "DESC",
};
