import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { EnforceTextLinesBrake } from "../../../../shared/shared.styled";
import { ICreatePushNotificationPayload } from "../push-notifications.api";
import { Row } from "../../../../shared/shared.styled";
import { PrimaryButton } from "@wit/mpesa-ui-components";
import MPESAAppIcon from "./mpesa-app.icon";
import { PreviewSection } from "./add-push-notification";
import { PushNotificationAppVersionsEnum, PushNotificationLinkTypeEnum } from "../push-notifications.model";
import moment from "moment";
import GenericLanguageSelector from "../../../../shared/components/generic-language-selector.component";
import { ILanguage } from "../../../../shared/models/language.model";

interface IPushNotificationDetailsModalProps {
  hideModal: () => void;
  confirmModal: () => Promise<any>;
  pushNotification: ICreatePushNotificationPayload;
  availableLanguages: ILanguage[];
  linkUriName?: string;
}

/**
 * Push notification details modal
 * @param param0
 * @returns
 */
const PushNotificationDetailsModal = ({
  pushNotification,
  hideModal,
  confirmModal,
  availableLanguages,
  linkUriName = "",
}: IPushNotificationDetailsModalProps) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState(availableLanguages[0].code);

  /**
   * Submits the whole push notifications payload
   */
  const onConfirm = () => {
    setLoading(true);
    confirmModal().catch(() => {
      setLoading(false);
    });
  };

  return (
    <ModalContainer>
      <ModalTitle>{t("pages.pushNotifications.add.modal.title")}</ModalTitle>
      <DocumentContainer>
        <SubTitleContainer>
          <ModalSubTitle>{t("pages.pushNotifications.add.modal.subtitle")}</ModalSubTitle>
          <div style={{ marginTop: "auto", marginLeft: "auto" }}>
            <GenericLanguageSelector
              selectedLanguage={language}
              availableLanguages={availableLanguages.map(lang => lang.code)}
              changeSelectedLanguage={(lang: any) => setLanguage(lang.key)}
            ></GenericLanguageSelector>
          </div>
        </SubTitleContainer>
        <div />
        <Row style={{ alignItems: "initial" }}>
          <Fields style={{ flex: "0.6" }}>
            <div className="field-wrapper">
              <div className="field-title">{t("pages.pushNotifications.add.modal.fields.title")}</div>
              <div className="field-value">{pushNotification.title[language]}</div>
            </div>
            <div className="field-wrapper">
              <div className="field-title">{t("pages.pushNotifications.add.modal.fields.message")}</div>
              <div className="field-value">{pushNotification.message[language]}</div>
            </div>
            <div className="field-wrapper">
              <div className="field-title">{t("pages.pushNotifications.add.modal.fields.users")}</div>
              <div className="field-value">
                {t(`pages.pushNotifications.add.targetUsersDropDown.${pushNotification.users}`)}
              </div>
            </div>
            <div className="field-wrapper">
              <div className="field-title">{t("pages.pushNotifications.add.modal.fields.versions")}</div>
              <div className="field-value" style={{ maxHeight: "300px", overflowY: "auto" }}>
                {pushNotification.appVersion === PushNotificationAppVersionsEnum.ALL_VERSIONS
                  ? t("pages.pushNotifications.add.targetReleasesDropdownPlaceholder")
                  : pushNotification.versions && pushNotification.versions.join(", ")}
              </div>
            </div>
            <div className="field-wrapper">
              <div className="field-title">{t("pages.pushNotifications.add.modal.fields.linkType")}</div>
              <div className="field-value">
                {pushNotification.linkType === PushNotificationLinkTypeEnum.DEEP_LINK && pushNotification.deepLinkType
                  ? t(`pages.pushNotifications.add.linkTypeDropDown.${pushNotification.deepLinkType}`)
                  : t(`pages.pushNotifications.add.linkTypeDropDown.${pushNotification.linkType}`)}
              </div>
            </div>
            {pushNotification.linkType === PushNotificationLinkTypeEnum.DYNAMIC_SCREEN && (
              <>
                <div className="field-wrapper">
                  <div className="field-title">{t("pages.pushNotifications.add.modal.fields.eventId")}</div>
                  <div className="field-value">
                    {pushNotification.linkContent && pushNotification.linkContent.eventId}
                  </div>
                </div>
                <div className="field-wrapper">
                  <div className="field-title">{t("pages.pushNotifications.add.modal.fields.serviceName")}</div>
                  <div className="field-value">
                    {pushNotification.linkContent && (pushNotification.linkContent.name as any)[language]}
                  </div>
                </div>
              </>
            )}
            {pushNotification.linkType === PushNotificationLinkTypeEnum.EXTERNAL_LINK && (
              <div className="field-wrapper">
                <div className="field-title">{t("pages.pushNotifications.add.modal.fields.uri")}</div>
                <div className="field-value">{pushNotification.linkContent && pushNotification.linkContent.uri}</div>
              </div>
            )}
            {pushNotification.linkType === PushNotificationLinkTypeEnum.DEEP_LINK &&
              pushNotification.deepLinkType === PushNotificationLinkTypeEnum.BUNDLE && (
                <div className="field-wrapper">
                  <div className="field-title">{t("pages.pushNotifications.add.modal.fields.bundle")}</div>
                  <div className="field-value">{linkUriName || "N/A"}</div>
                </div>
              )}
            {pushNotification.linkType === PushNotificationLinkTypeEnum.DEEP_LINK &&
              pushNotification.deepLinkType === PushNotificationLinkTypeEnum.MINI_APP && (
                <div className="field-wrapper">
                  <div className="field-title">{t("pages.pushNotifications.add.modal.fields.miniApp")}</div>
                  <div className="field-value">{linkUriName || "N/A"}</div>
                </div>
              )}
            {pushNotification.linkType === PushNotificationLinkTypeEnum.NATIVE && (
              <div className="field-wrapper">
                <div className="field-title">{t("pages.pushNotifications.add.modal.fields.flow")}</div>
                <div className="field-value">
                  {pushNotification.linkContent &&
                    t(`commons.internalFlowDropDown.${pushNotification.linkContent.flow}`)}
                </div>
              </div>
            )}
            <div className="field-wrapper">
              <div className="field-title">{t("pages.pushNotifications.add.modal.fields.dateAndTime")}</div>
              <div className="field-value">
                {pushNotification.sendImmediately
                  ? t("pages.pushNotifications.add.modal.fields.immediately")
                  : moment(pushNotification.scheduledDate).format("DD/MM/YYYY HH:mm")}
              </div>
            </div>
          </Fields>
          <PreviewSection style={{ flex: "0.4" }}>
            <div className="preview-title">{t("pages.pushNotifications.add.preview.previewTitle")}</div>
            <div className="notification-preview-wrapper">
              <div className="notification-preview-header">
                <MPESAAppIcon />
                <div className="header-title">{t("pages.pushNotifications.add.preview.headerTitle")}</div>
                <div className="header-ts">{t("pages.pushNotifications.add.preview.headerTs")}</div>
              </div>
              <div className="notification-preview-body">
                <div className="notification-preview-title">{pushNotification.title[language]}</div>
                <div className="notification-preview-message">{pushNotification.message[language]}</div>
              </div>
            </div>
          </PreviewSection>
        </Row>
        <Row style={{ justifyContent: "flex-end", width: "100%", paddingTop: "24px" }}>
          <div style={{ width: 110, marginRight: "12px" }}>
            <PrimaryButton
              id={"cancel-button"}
              disabled={loading}
              onClick={hideModal}
              titleLabel={t("pages.pushNotifications.add.modal.cancel")}
            />
          </div>
          <div style={{ width: 110 }}>
            <PrimaryButton
              id={"confirm-button"}
              redTheme={true}
              type="submit"
              disabled={loading}
              onClick={onConfirm}
              titleLabel={t("pages.pushNotifications.add.modal.confirm")}
            />
          </div>
        </Row>
      </DocumentContainer>
    </ModalContainer>
  );
};

export default PushNotificationDetailsModal;

const SubTitleContainer = styled("div")`
  display: flex;
  margin-bottom: 24px;
  width: 60%;
`;

const ModalContainer = styled("div")`
  border-radius: 6px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  padding: 24px;
`;

const ModalTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 24px;
`;

const ModalSubTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 18px;
  line-height: 23px;
  color: ${props => props.theme.palette.darkGrey};
`;

const DocumentContainer = styled("div")`
  font-family: Vodafone Rg;
  overflow-y: auto;
`;

const Fields = styled("div")`
  padding-right: 40px;

  .field-wrapper {
    display: flex;
    flex-direction: row;

    padding: 10px 0;
    border-bottom: 1px solid ${props => props.theme.palette.aluminium};

    .field-title {
      width: 130px;
      font-family: Vodafone Rg;
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      letter-spacing: 0px;
      color: ${props => props.theme.palette.midGrey};
      ${EnforceTextLinesBrake}
    }

    .field-value {
      flex: 1;

      font-family: Vodafone Rg;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0px;
      color: ${props => props.theme.palette.darkGrey};
      ${EnforceTextLinesBrake}
      -webkit-line-clamp: 5;
    }
  }
`;
