import { produce } from "immer";
import { IPayloadAction } from "../../../shared/shared.interfaces";
import { AuthenticationActions } from "../../authentication/authentication.store";
import { IDiscoverCards } from "./discover-cards.model";

export const DiscoverCardsActions = {
  types: {
    GET_DISCOVER_CARDS_SUCCESS: "GET_DISCOVER_CARDS_SUCCESS",
    GET_DISCOVER_CARDS_LAST_VERSION: "GET_DISCOVER_CARDS_LAST_VERSION",
  },
  creators: {
    getDiscoverCardsSuccess: (discoverCards: IDiscoverCards) => ({
      type: DiscoverCardsActions.types.GET_DISCOVER_CARDS_SUCCESS,
      payload: {
        discoverCards,
      },
    }),
    getDiscoverCardsLastVersion: (discoverCards: IDiscoverCards) => ({
      type: DiscoverCardsActions.types.GET_DISCOVER_CARDS_LAST_VERSION,
      payload: {
        discoverCards,
      },
    }),
  },
};
export interface IDiscoverCardsReducerInterface {
  discoverCards: IDiscoverCards;
  lastVersion: IDiscoverCards;
}

const initialState: IDiscoverCardsReducerInterface = {
  discoverCards: {} as IDiscoverCards,
  lastVersion: {} as IDiscoverCards,
};

export const discoverCardsReducer = produce((draft: IDiscoverCardsReducerInterface, action: IPayloadAction<any>) => {
  switch (action.type) {
    case DiscoverCardsActions.types.GET_DISCOVER_CARDS_SUCCESS:
      draft.discoverCards = action.payload.discoverCards;
      return;
    case DiscoverCardsActions.types.GET_DISCOVER_CARDS_LAST_VERSION:
      draft.lastVersion = action.payload.discoverCards;
      return;
    case AuthenticationActions.types.LOGOUT:
      return initialState;
    default:
      return draft;
  }
}, initialState);
