import { ModalTypeEnum, Table, useAlert, useFilters, useModal } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ThemeContext } from "styled-components";
import { ConfigContext } from "../../../../../app.component";
import { IStoreInterface } from "../../../../../configs/store.config";
import BundlesApi from "../../bundles.api";
import { IBundleType, ISubBundle } from "../../bundles.model";
import { filterSubBundles, renderSubBundlesListQuickActions, SubBundlesUtils } from "../sub-bundles.utils";
import EditSubBundleModal from "./edit-sub-bundle.modal";
import SubBundlesListFilters from "./sub-bundles-list-filters.component";

interface ISubBundlesListInterface {
  bundle: IBundleType;
  reloadBundleDetails: () => void;
}

/**
 * Sub bundles list
 * @param param0
 */
const SubBundlesList = ({ bundle, reloadBundleDetails }: ISubBundlesListInterface) => {
  const [t] = useTranslation();
  const configurationFieldsStore = useSelector((state: IStoreInterface) => state.bundlesReducer.configurationFields);
  const [configurationFields, setConfigurationFields] = React.useState(configurationFieldsStore);
  const [selectedSubBundle, setSelectedSubBundle] = React.useState<ISubBundle>();
  const themeContext = useContext(ThemeContext);
  const { config } = useContext(ConfigContext);
  const {
    filters,
    updateFilter,
    isFilterActive,
    clearFilter,
    resetFilters,
    getFilterValue,
    getFiltersQueryString,
    updateMultipleFilters,
  } = useFilters();

  const [showableSubBundles, setShowableSubBundles] = React.useState<ISubBundle[]>(
    bundle.items ? (bundle.items as ISubBundle[]) : [],
  );
  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );
  const [showAlert, , setAlertProps] = useAlert();

  /*
   * Filters effect
   * */
  React.useEffect(() => {
    if (filters.size > 0 && bundle.items) {
      setShowableSubBundles(filterSubBundles(bundle.items as ISubBundle[], filters));
    } else {
      setShowableSubBundles(bundle.items ? (bundle.items as ISubBundle[]) : []);
    }
  }, [filters, bundle]);

  /*
   * Configuration fields effect
   * */
  React.useEffect(() => {
    setConfigurationFields(configurationFieldsStore);
  }, [configurationFieldsStore]);

  /**
   * Delete sub-bundle
   * @param subBundle
   */
  const deleteSubBundle = (subBundle: ISubBundle) => {
    BundlesApi.methods
      .deleteSubBundle(bundle.id, subBundle.id)
      .then(
        () => {
          setAlertProps({
            title: t("pages.bundleDetails.deleteSubBundleSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          reloadBundleDetails();
        },
        () => {
          setAlertProps({
            title: t("pages.bundleDetails.deleteSubBundleError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        hideConfirmationModal();
      });
  };

  /**
   * Delete sub-bundle
   * @param subBundle
   */
  const restoreSubBundle = (subBundle: ISubBundle) => {
    BundlesApi.methods
      .restoreSubBundle(bundle.id, subBundle.id)
      .then(
        () => {
          setAlertProps({
            title: t("pages.bundleDetails.restoreSubBundleSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          reloadBundleDetails();
        },
        () => {
          setAlertProps({
            title: t("pages.bundleDetails.restoreSubBundleError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        hideConfirmationModal();
      });
  };

  /**
   * Delete sub-bundle handler
   * @param subBundle
   */
  const showDeleteConfirmation = (subBundle: ISubBundle) => {
    setConfirmationProps({
      title: t("pages.bundleDetails.deleteSubBundleTitle"),
      description: t("pages.bundleDetails.deleteSubBundleConfirmation"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      primaryAction: () => deleteSubBundle(subBundle),
      secondaryAction: () => hideConfirmationModal(),
    } as BaseModalProps);
    showConfirmationModal();
  };

  /**
   * Restore sub-bundle handler
   */
  const showRestoreConfirmation = (subBundle: ISubBundle) => {
    setConfirmationProps({
      title: t("pages.bundleDetails.restoreSubBundleTitle"),
      description: t("pages.bundleDetails.restoreSubBundleConfirmation"),
      primaryAction: () => restoreSubBundle(subBundle),
      secondaryAction: () => hideConfirmationModal(),
    } as BaseModalProps);
    showConfirmationModal();
  };

  /**
   * Edit sub-bundle modal
   */
  const _hideEditSubBundleModal = () => {
    hideEditSubBundleModal();
  };
  const [editSubBundleModal, hideEditSubBundleModal, editSubBundleModalProps] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <EditSubBundleModal
      subBundle={selectedSubBundle as ISubBundle}
      bundle={bundle}
      configurationFields={configurationFields}
      dismissModal={_hideEditSubBundleModal}
      reloadSubBundles={reloadBundleDetails}
    />,
  );
  React.useEffect(() => {
    editSubBundleModalProps({ modalStyles: { width: 825, padding: 0, maxHeight: "98%", overflow: "auto" } });
  }, [editSubBundleModalProps]);

  /**
   * Opens the edit sub-bundle modal
   * @param s
   */
  const showEditSubBundleModal = (s: ISubBundle) => {
    setSelectedSubBundle(s);
    editSubBundleModal();
  };
  return (
    <>
      <SubBundlesListFilters
        filters={filters}
        isFilterActive={isFilterActive}
        updateFilter={updateFilter}
        clearFilter={clearFilter}
        resetFilters={resetFilters}
        getFilterValue={getFilterValue}
        getFiltersQueryString={getFiltersQueryString}
        updateMultipleFilters={updateMultipleFilters}
        configurationFields={configurationFields}
        bundle={bundle}
        reloadSubBundles={reloadBundleDetails}
        config={config}
      />

      <Table<ISubBundle>
        values={showableSubBundles}
        displayActions={subBundle =>
          renderSubBundlesListQuickActions<ISubBundle>(
            subBundle,
            {
              edit: { onClick: showEditSubBundleModal },
              delete: { onClick: showDeleteConfirmation },
              restore: { onClick: showRestoreConfirmation },
            },
            themeContext,
          )
        }
        columns={SubBundlesUtils.getSubBundlesListColumns(configurationFields, config)}
      />
    </>
  );
};

export default SubBundlesList;
