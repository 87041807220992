import { IIcon } from "../../../shared/components/icon-picker.component";

export interface IAllowedScope {
  id: string;
  name: string;
  scope: string;
  type: string;
  iconColor: string;
  iconId: string;
  iconUrl: string;
  serviceType: AppTypeEnum[];
  iconBackgroundColor: string;
  format: string;
}

export class AllowedScope implements IAllowedScope {
  id!: string;
  name!: string;
  scope!: string;
  type!: string;
  iconColor!: string;
  iconId!: string;
  iconUrl!: string;
  serviceType!: AppTypeEnum[];
  iconBackgroundColor!: string;
  format!: string;
}

export interface IIconAllowedScope extends IIcon {
  format?: string;
  hash?: string;
  hashMatches?: boolean;
  imageType?: string;
  content?: string;
  name?: string;
}

export enum AppTypeEnum {
  CONSUMER = "CONSUMER",
  ORG = "ORG",
}

export enum AllowedScopeTypeEnum {
  STRING = "String",
  NUMBER = "Number",
  DECIMAL = "Decimal",
  DATE = "Date",
}

export interface IAllowedScopeToEditRequest {
  id: string;
  name: string;
  scope: string;
  type: string;
  iconColor: string;
  iconId: string;
  serviceType: AppTypeEnum[];
  iconBackgroundColor: string;
  format: string;
}
