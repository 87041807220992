import { TabChild, Tabs } from "@wit/mpesa-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { PageContainer, PageTitle } from "../../shared/shared.styled";
import TasksPage from "./tasks/tasks.page";
import UsersPage from "./users/pages/users.page";
import { useSelector } from "react-redux";
import { IStoreInterface } from "../../configs/store.config";
import { UserRolesDefault, UserRolesVDF } from "./users/users.interfaces";
import { ILoggedUser } from "../authentication/authentication.interfaces";

const AdminPage = () => {
  const [t] = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [tab, setTab] = React.useState(Number(query.get("tabIdx")) || 0);

  const { role } = useSelector((state: IStoreInterface) => state.authenticationReducer.loggedUser) as ILoggedUser;
  const isApprover = role === UserRolesDefault.APPROVER || role === UserRolesVDF.APPROVER_SERVICE_MANAGEMENT;
  return (
    <PageContainer>
      <PageTitle style={{ marginBottom: isApprover ? "43px" : "0px" }}>{t("pages.admin.title")}</PageTitle>
      {isApprover && tab !== undefined ? (
        <Tabs controlledSelectedTab={tab} controlledSetSelectedTab={setTab}>
          <TabChild label={t("pages.users.title")}>
            <UsersPage />
          </TabChild>
          <TabChild label={t("pages.myTasks.title")}>
            <TasksPage />
          </TabChild>
        </Tabs>
      ) : (
        <UsersPage />
      )}
    </PageContainer>
  );
};

export default AdminPage;
