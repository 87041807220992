import { PrimaryButton, TextInput, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikHelpers } from "formik";
import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { object, string } from "yup";
import { RoutesEnum } from "../../../routes/routes.constants";
import AnimatedBackgroundImage from "../../../shared/components/background-image.component";
import SentForApprovalIcon from "../../../shared/icons/sent-for-approval.icon";
import AuthenticationApi from "../authentication.api";
import { IForgotPasswordRequest } from "../authentication.interfaces";

function getValidationSchema(t: TFunction) {
  return object().shape({
    email: string()
      .required(t("components.changePasswordModal.required"))
      .email(t("commons.emailRequired")),
  });
}

/**
 * Expired password
 */
const ExpiredPasswordPage = () => {
  const [t] = useTranslation(["public"]);
  const [successfulRequest, setSuccessfulRequest] = React.useState(false);
  const history = useHistory();
  const [showAlert, hideAlert, setAlertProps] = useAlert();

  function handleResetPassword(values: IForgotPasswordRequest, actions: FormikHelpers<IForgotPasswordRequest>) {
    actions.setSubmitting(true);
    const data = {
      email: values.email,
      language: "en-GB",
    };
    AuthenticationApi.methods
      .forgotPassword(data)
      .then(
        () => {
          setSuccessfulRequest(true);
        },
        () => {
          setAlertProps({
            title: t("pages.expiredPassword.sendEmailError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        actions.setSubmitting(false);
      });
  }

  return (
    <ForgotPasswordContainer>
      {successfulRequest ? (
        <ForgotPasswordFormContainer>
          <ForgotPasswordTitle>{t("pages.expiredPassword.title")}</ForgotPasswordTitle>
          <ForgotPasswordDescription>{t("pages.expiredPassword.successfulDescription")}</ForgotPasswordDescription>
          <div style={{ marginLeft: "auto", marginRight: "auto" }}>
            <SentForApprovalIcon />
          </div>
          <ResendLink onClick={() => setSuccessfulRequest(false)}>
            {t("pages.expiredPassword.resendEmailLink")}
          </ResendLink>
          <div>
            <PrimaryButton
              redTheme={true}
              titleLabel={t("pages.expiredPassword.backToLoginBtn")}
              onClick={() => history.push(RoutesEnum.LOGIN)}
            />
          </div>
        </ForgotPasswordFormContainer>
      ) : (
        <Formik
          initialValues={{ email: "", language: "" }}
          onSubmit={handleResetPassword}
          validationSchema={getValidationSchema(t)}
          render={({ setFieldValue, values, errors, handleSubmit, isSubmitting }) => (
            <ForgotPasswordFormContainer onSubmit={handleSubmit}>
              <ForgotPasswordTitle>{t("pages.expiredPassword.title")}</ForgotPasswordTitle>
              <ForgotPasswordDescription>{t("pages.expiredPassword.description")}</ForgotPasswordDescription>
              <TextInput
                name={"email"}
                required={true}
                style={{ marginBottom: 97 }}
                type="email"
                title={t("pages.expiredPassword.inputTitle")}
                placeholder={t("pages.expiredPassword.inputPlaceholder")}
                value={values.email}
                error={errors.email}
                onChange={evt => setFieldValue("email", evt.target.value)}
              />
              <ButtonContainer>
                <PrimaryButton
                  disabled={isSubmitting}
                  redTheme={true}
                  titleLabel={t("pages.expiredPassword.btnLabel")}
                  type="submit"
                />
              </ButtonContainer>
            </ForgotPasswordFormContainer>
          )}
        />
      )}

      <AnimatedBackgroundImage />
    </ForgotPasswordContainer>
  );
};

export default ExpiredPasswordPage;

const ButtonContainer = styled("div")``;

const ForgotPasswordContainer = styled("div")`
  height: 100%;
`;

const ForgotPasswordFormContainer = styled("form")`
  position: absolute;
  width: 397px;
  left: 117px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 1;
  background-color: ${props => props.theme.palette.white};
  padding: 32px 40px 40px 40px;
  border-radius: 6px;

  @media (max-width: 768px) {
    width: calc(100% - 40px);
    left: 20px;
  }
`;

const ForgotPasswordTitle = styled("span")`
  font-family: Vodafone Lt;
  font-size: 33px;
  color: #333;
  margin-bottom: 53px;
`;

const ForgotPasswordDescription = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: #333;
  margin-bottom: 18px;
`;

const ResendLink = styled("span")`
  margin-left: auto;
  margin-right: auto;
  font-family: Vodafone Rg;
  font-size: 14px;
  color: #999;
  margin-bottom: 12px;
  cursor: pointer;
`;
