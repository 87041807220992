import produce from "immer";
import { IEnvProperty } from "../../shared/models/env-property.model";
import { IGatewayLog } from "../../shared/models/gateway-log.model";
import { IPayloadAction } from "../../shared/shared.interfaces";
import { AuthenticationActions } from "../authentication/authentication.store";
import { IAppLog, IGetAppsLogsResponse, IGetGatewayLogsResponse, IGetMiniAppsLogsResponse } from "./support.interfaces";
import { ICommandId } from "../../shared/models/command-id.model";
import { IMiniAppsLog } from "../../shared/models/mini-apps-log.model";

export const SupportActions = {
  Actions: {
    FETCHING_COMMAND_IDS_SUCCESS: "FETCHING_COMMAND_IDS_SUCCESS",
    UPDATE_COMMAND_ID: "UPDATE_COMMAND_ID",
    FETCHING_PROPERTIES_SUCCESS: "FETCHING_PROPERTIES_SUCCESS",
    ADD_PROPERTY: "ADD_PROPERTY",
    UPDATE_PROPERTY: "UPDATE_PROPERTY",
    DELETE_PROPERTY: "DELETE_PROPERTY",
    FETCHING_GATEWAY_LOGS_SUCCESS: "FETCHING_GATEWAY_LOGS_SUCCESS",
    FETCHING_APP_LOGS_SUCCESS: "FETCHING_APP_LOGS_SUCCESS",
    FETCHING_MINI_APPS_LOGS_SUCCESS: "FETCHING_MINI_APPS_LOGS_SUCCESS",
    FETCHING_MINI_APPS_LOGS_ERROR: "FETCHING_MINI_APPS_LOGS_ERROR",
    FETCHING_GATEWAY_LOGS_ERROR: "FETCHING_GATEWAY_LOGS_ERROR",
    FETCHING_APP_LOGS_ERROR: "FETCHING_APP_LOGS_ERROR",
  },
  Creators: {
    fetchCommandIdsSuccessAction: (commandIds: ICommandId[]) => ({
      type: SupportActions.Actions.FETCHING_COMMAND_IDS_SUCCESS,
      payload: {
        commandIds,
      },
    }),
    updateCommandId: (commandId: ICommandId) => ({
      type: SupportActions.Actions.UPDATE_COMMAND_ID,
      payload: {
        commandId,
      },
    }),
    fetchPropertiesSuccessAction: (properties: IEnvProperty[]) => ({
      type: SupportActions.Actions.FETCHING_PROPERTIES_SUCCESS,
      payload: {
        properties,
      },
    }),
    addPropertyAction: (property: IEnvProperty) => ({
      type: SupportActions.Actions.ADD_PROPERTY,
      payload: {
        property,
      },
    }),
    updateProperty: (property: IEnvProperty) => ({
      type: SupportActions.Actions.UPDATE_PROPERTY,
      payload: {
        property,
      },
    }),
    deletePropertyAction: (property: IEnvProperty) => ({
      type: SupportActions.Actions.DELETE_PROPERTY,
      payload: {
        property,
      },
    }),
    fetchGatewayLogsSuccessAction: (gwReportLogList: IGatewayLog[], count: number) => ({
      type: SupportActions.Actions.FETCHING_GATEWAY_LOGS_SUCCESS,
      payload: {
        gwReportLogList,
        count,
      },
    }),
    fetchAppLogsSuccessAction: (reportLogList: IAppLog[], count: number) => ({
      type: SupportActions.Actions.FETCHING_APP_LOGS_SUCCESS,
      payload: {
        reportLogList,
        count,
      },
    }),
    fetchMiniAppsLogsSuccessAction: (miniAppsReportLogList: IMiniAppsLog[], count: number) => ({
      type: SupportActions.Actions.FETCHING_MINI_APPS_LOGS_SUCCESS,
      payload: {
        miniAppsReportLogList,
        count,
      },
    }),
    fetchMiniAppsLogsErrorAction: () => ({
      type: SupportActions.Actions.FETCHING_MINI_APPS_LOGS_ERROR,
      payload: {},
    }),
    fetchGatewayLogsErrorAction: () => ({
      type: SupportActions.Actions.FETCHING_GATEWAY_LOGS_ERROR,
      payload: {},
    }),
    fetchAppLogsErrorAction: () => ({
      type: SupportActions.Actions.FETCHING_APP_LOGS_ERROR,
      payload: {},
    }),
  },
};

export interface ISupportReducerInterface {
  properties: IEnvProperty[];
  commandIds: ICommandId[];
  gatewayLogs: IGatewayLog[];
  appLogs: IAppLog[];
  miniAppsLogs: IMiniAppsLog[];
  gatewayLogsCount: number;
  appLogsCount: number;
  miniAppsLogsCount: number;
}

const initialState: ISupportReducerInterface = {
  properties: [],
  commandIds: [],
  gatewayLogs: [],
  appLogs: [],
  miniAppsLogs: [],
  gatewayLogsCount: 0,
  appLogsCount: 0,
  miniAppsLogsCount: 0,
};

export const supportReducer = produce((draft: ISupportReducerInterface, action: IPayloadAction<any>) => {
  switch (action.type) {
    case SupportActions.Actions.FETCHING_COMMAND_IDS_SUCCESS:
      draft.commandIds = action.payload.commandIds;
      return;
    case SupportActions.Actions.UPDATE_COMMAND_ID:
      const { commandId } = action.payload;
      const pos = draft.commandIds.findIndex(c => c.commandName === commandId.commandName);
      if (pos > -1) {
        draft.commandIds[pos] = commandId;
      }
      return;
    case SupportActions.Actions.FETCHING_PROPERTIES_SUCCESS:
      draft.properties = action.payload.properties;
      return;
    case SupportActions.Actions.ADD_PROPERTY:
      draft.properties = [...draft.properties, action.payload.property];
      return;
    case SupportActions.Actions.DELETE_PROPERTY:
      const {
        property: { propertyName },
      } = action.payload;
      draft.properties = draft.properties.filter(p => p.propertyName !== propertyName);
      return;
    case SupportActions.Actions.UPDATE_PROPERTY:
      const { property } = action.payload;
      const idx = draft.properties.findIndex(p => p.propertyName === property.propertyName);
      if (idx > -1) {
        draft.properties[idx] = property;
      }
      return;
    case SupportActions.Actions.FETCHING_GATEWAY_LOGS_SUCCESS:
      const { gwReportLogList, count } = action.payload as IGetGatewayLogsResponse;
      draft.gatewayLogs = gwReportLogList;
      draft.gatewayLogsCount = count;
      return;
    case SupportActions.Actions.FETCHING_APP_LOGS_SUCCESS:
      draft.appLogs = (action.payload as IGetAppsLogsResponse).reportLogList;
      draft.appLogsCount = (action.payload as IGetAppsLogsResponse).count;
      return;
    case SupportActions.Actions.FETCHING_MINI_APPS_LOGS_SUCCESS:
      draft.miniAppsLogs = action.payload.miniAppsReportLogList;
      draft.miniAppsLogsCount = action.payload.count;
      return;
    case SupportActions.Actions.FETCHING_MINI_APPS_LOGS_ERROR:
      draft.miniAppsLogs = [];
      draft.miniAppsLogsCount = 0;
      return;
    case SupportActions.Actions.FETCHING_GATEWAY_LOGS_ERROR:
      draft.gatewayLogs = [];
      draft.gatewayLogsCount = 0;
      return;
    case SupportActions.Actions.FETCHING_APP_LOGS_ERROR:
      draft.appLogs = [];
      draft.appLogsCount = 0;
      return;
    case AuthenticationActions.types.LOGOUT:
      return initialState;
    default:
      return draft;
  }
}, initialState);
