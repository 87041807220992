import moment from "moment";
import React from "react";
import i18next from "i18next";
import { ColumnProps } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import { IGatewayLog } from "../../shared/models/gateway-log.model";
import { IAppLog, GatewayLogStatusEnum, MiniAppsLogStatusEnum } from "./support.interfaces";
import styled from "styled-components";
import { CheckIcon, RecurringIcon, CancelIcon, ClockIcon } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { DateTime } from "luxon";
import { IMiniAppsLog } from "../../shared/models/mini-apps-log.model";

export const SupportUtils = {
  getDeleteConfirmationModalProps: (primaryActionCb: () => any, secondaryActionCb: () => any) => {
    return {
      title: i18next.t("pages.support.envProperties.modals.confirmationModal.title"),
      description: i18next.t("pages.support.envProperties.modals.confirmationModal.description"),
      primaryBtnId: "delete-button",
      secondaryBtnId: "cancel-button",
      primaryBtnLabel: i18next.t("pages.support.envProperties.modals.confirmationModal.primaryBtnLabel"),
      primaryAction: primaryActionCb,
      secondaryBtnLabel: i18next.t("pages.support.envProperties.modals.confirmationModal.secondaryBtnLabel"),
      secondaryAction: secondaryActionCb,
    };
  },
  getAppLogsColumns: (): ColumnProps[] => {
    return [
      {
        formKey: "status",
        ratio: 1 / 12,
        isEditable: false,
        label: i18next.t("pages.support.appLogs.table.headers.status"),
        changeFunction: (content, row) => getGatewayLogStatus(row),
      },
      {
        formKey: "msisdn",
        ratio: 2 / 12,
        isEditable: false,
        label: i18next.t("pages.support.appLogs.table.headers.msisdn"),
      },
      {
        formKey: "sid",
        ratio: 3 / 12,
        isEditable: false,
        label: i18next.t("pages.support.appLogs.table.headers.sid"),
      },
      {
        formKey: "appRequestTime",
        ratio: 2 / 12,
        isEditable: false,
        label: i18next.t("pages.support.appLogs.table.headers.requestedTime"),
      },
      {
        formKey: "sdkOperation",
        ratio: 3 / 12,
        isEditable: false,
        label: i18next.t("pages.support.appLogs.table.headers.sdkOperation"),
      },
      {
        formKey: "elapsedTime",
        ratio: 1 / 12,
        isEditable: false,
        label: i18next.t("pages.support.appLogs.table.headers.elapsedTime"),
        changeFunction: (content, appLog: IAppLog) =>
          `${appLog.appRequestTime ? elapsedTime(appLog.appRequestTime, appLog.appResponseTime) : "-"}`,
      },
    ];
  },
  getGatewayLogsColumns: (): ColumnProps[] => {
    return [
      {
        formKey: "status",
        ratio: 1 / 12,
        isEditable: false,
        label: i18next.t("pages.support.gatewayLogs.table.headers.status"),
        changeFunction: (content, row) => getGatewayLogStatus(row),
      },
      {
        formKey: "requestId",
        ratio: 4 / 12,
        isEditable: false,
        label: i18next.t("pages.support.gatewayLogs.table.headers.requestId"),
      },
      {
        formKey: "requestTime",
        ratio: 3 / 12,
        isEditable: false,
        label: i18next.t("pages.support.gatewayLogs.table.headers.requestedTime"),
      },
      {
        formKey: "gwOperation",
        ratio: 2 / 12,
        isEditable: false,
        label: i18next.t("pages.support.gatewayLogs.table.headers.gatewayOperation"),
      },
      {
        formKey: "syncResponseTime",
        ratio: 1 / 12,
        isEditable: false,
        label: i18next.t("pages.support.gatewayLogs.table.headers.syncEt"),
        changeFunction: (content, gwLog: IGatewayLog) =>
          `${gwLog.syncResponseTime ? `${getElapsedTime(gwLog, "SYNC")} ms` : "-"}`,
      },
      {
        formKey: "asyncResponseTime",
        ratio: 1 / 12,
        isEditable: false,
        label: i18next.t("pages.support.gatewayLogs.table.headers.asyncEt"),
        changeFunction: (content, gwLog: IGatewayLog) =>
          `${gwLog.asyncResponseTime ? `${getElapsedTime(gwLog, "ASYNC")} ms` : "-"}`,
      },
    ];
  },
  getMiniAppsLogsColumns: (): ColumnProps[] => {
    return [
      {
        formKey: "status",
        ratio: 1 / 12,
        isEditable: false,
        label: i18next.t("pages.support.miniAppsLogs.table.headers.status"),
        changeFunction: (content, row) => getMiniAppsLogStatus(row),
      },
      {
        formKey: "requestId",
        ratio: 4 / 12,
        isEditable: false,
        label: i18next.t("pages.support.miniAppsLogs.table.headers.requestId"),
      },
      {
        formKey: "requestTime",
        ratio: 3 / 12,
        isEditable: false,
        label: i18next.t("pages.support.miniAppsLogs.table.headers.requestedTime"),
      },
      {
        formKey: "serviceOperation",
        ratio: 2 / 12,
        isEditable: false,
        label: i18next.t("pages.support.miniAppsLogs.table.headers.miniAppsOperation"),
      },
      {
        formKey: "syncResponseTime",
        ratio: 1 / 12,
        isEditable: false,
        label: i18next.t("pages.support.miniAppsLogs.table.headers.syncEt"),
        changeFunction: (content, miniAppsLog: IMiniAppsLog) =>
          `${miniAppsLog.syncResponseTime ? `${getMiniAppsElapsedTime(miniAppsLog, "SYNC")} ms` : "-"}`,
      },
    ];
  },
};

/**
 * Get Gateway logs status
 * @param gatewayLog
 * @returns
 */
export const getGatewayLogStatus = (gatewayLog: IGatewayLog) => {
  switch (gatewayLog.status) {
    case GatewayLogStatusEnum.PENDING:
      return (
        <StatusIconContainer color={styleTheme.palette.warningYellow}>
          <ClockIcon />
        </StatusIconContainer>
      );
    case GatewayLogStatusEnum.FAILED:
      return (
        <StatusIconContainer color={styleTheme.palette.vodafoneRed}>
          <CancelIcon />
        </StatusIconContainer>
      );
    case GatewayLogStatusEnum.SUCCESS:
      return (
        <StatusIconContainer color={styleTheme.palette.successGreen}>
          <CheckIcon />
        </StatusIconContainer>
      );
    case GatewayLogStatusEnum.TIMEOUT:
      return (
        <StatusIconContainer color={styleTheme.palette.turquoiseBlue}>
          <RecurringIcon />
        </StatusIconContainer>
      );
  }
};

/**
 * Get Mini Apps logs status
 * @param miniAppsLog
 * @returns
 */
export const getMiniAppsLogStatus = (miniAppsLog: IMiniAppsLog) => {
  switch (miniAppsLog.status) {
    case MiniAppsLogStatusEnum.PENDING:
      return (
        <StatusIconContainer color={styleTheme.palette.warningYellow}>
          <ClockIcon />
        </StatusIconContainer>
      );
    case MiniAppsLogStatusEnum.FAILED:
      return (
        <StatusIconContainer color={styleTheme.palette.vodafoneRed}>
          <CancelIcon />
        </StatusIconContainer>
      );
    case MiniAppsLogStatusEnum.SUCCESS:
      return (
        <StatusIconContainer color={styleTheme.palette.successGreen}>
          <CheckIcon />
        </StatusIconContainer>
      );
    case MiniAppsLogStatusEnum.TIMEOUT:
      return (
        <StatusIconContainer color={styleTheme.palette.turquoiseBlue}>
          <RecurringIcon />
        </StatusIconContainer>
      );
  }
};

const StatusIconContainer = styled("span")<{ color: string }>`
  height: 44px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  stroke: ${props => props.color};
  svg {
    width: 24px;
    height: 24px;
  }
`;

export function getElapsedTime(gatewayLog: IGatewayLog, type: "SYNC" | "ASYNC") {
  if (type === "SYNC" && gatewayLog.syncResponseTime) {
    return (
      DateTime.fromFormat(gatewayLog.syncResponseTime, "dd/MM/yyyy HH:mm:ss.SSS").toMillis() -
      DateTime.fromFormat(gatewayLog.requestTime, "dd/MM/yyyy HH:mm:ss.SSS").toMillis()
    );
  } else if (type === "ASYNC" && gatewayLog.asyncResponseTime) {
    return (
      DateTime.fromFormat(gatewayLog.asyncResponseTime, "dd/MM/yyyy HH:mm:ss.SSS").toMillis() -
      DateTime.fromFormat(gatewayLog.requestTime, "dd/MM/yyyy HH:mm:ss.SSS").toMillis()
    );
  } else {
    return undefined;
  }
}

export function getMiniAppsElapsedTime(miniAppLog: IMiniAppsLog, type: "SYNC" | "ASYNC") {
  if (type === "SYNC" && miniAppLog.syncResponseTime) {
    return (
      DateTime.fromFormat(miniAppLog.syncResponseTime, "dd/MM/yyyy HH:mm:ss.SSS").toMillis() -
      DateTime.fromFormat(miniAppLog.requestTime, "dd/MM/yyyy HH:mm:ss.SSS").toMillis()
    );
  } else {
    return undefined;
  }
}

export function elapsedTime(initDate: string | undefined, endDate: string | undefined) {
  const momentInit = moment(endDate, "dd/MM/yyyy HH:mm:ss.SSS");
  const diff = momentInit.diff(moment(initDate, "dd/MM/yyyy HH:mm:ss.SSS"));
  if (isNaN(diff)) {
    return "-";
  }
  return `${diff}ms`;
}

export function downloadCsvFile(csvData: string, fileName: string) {
  const url = URL.createObjectURL(new Blob([csvData], { type: "text/csv" }));
  const downloadLink = document.createElement("a");
  downloadLink.setAttribute("href", url);
  downloadLink.setAttribute("download", fileName);

  downloadLink.click();
}
