import { produce } from "immer";
import { IPayloadAction } from "../../shared/shared.interfaces";
import { AudienceType, ConsumerAudience, IAnnouncement, IResponseToGetAnnouncements } from "./announcements.model";

export const AnnoucementsActions = {
  types: {
    FETCHING_ANNOUNCEMENTS: "FETCHING_ANNOUNCEMENTS",
    FETCHING_ANNOUNCEMENTS_SUCCESS: "FETCHING_ANNOUNCEMENTS_SUCCESS",
    FETCHING_ANNOUNCEMENTS_ERROR: "FETCHING_ANNOUNCEMENTS_ERROR",
    ADD_ANNOUNCEMENT: "ADD_ANNOUNCEMENT",
    UPDATE_ANNOUNCEMENT: "UPDATE_ANNOUNCEMENT",
    DELETE_ANNOUNCEMENT: "DELETE_ANNOUNCEMENT",
    FETCHING_ICONS_ANNOUNCEMENTS: "FETCHING_ICONS_ANNOUNCEMENTS",
  },
  creators: {
    fetchingAnnouncementsAction: () => ({
      type: AnnoucementsActions.types.FETCHING_ANNOUNCEMENTS,
    }),
    fetchAnnouncementsSuccessAction: (announcements: IResponseToGetAnnouncements) => ({
      type: AnnoucementsActions.types.FETCHING_ANNOUNCEMENTS_SUCCESS,
      payload: { announcements },
    }),
    fetchAnnouncementsErrorAction: () => ({
      type: AnnoucementsActions.types.FETCHING_ANNOUNCEMENTS_ERROR,
    }),
    addAnnouncement: (announcement: IAnnouncement) => ({
      type: AnnoucementsActions.types.ADD_ANNOUNCEMENT,
      payload: { announcement },
    }),
    updateAnnouncement: (id: string, announcement: IAnnouncement) => ({
      type: AnnoucementsActions.types.UPDATE_ANNOUNCEMENT,
      payload: { id, announcement },
    }),
    deleteAnnouncement: (id: string) => ({
      type: AnnoucementsActions.types.DELETE_ANNOUNCEMENT,
      payload: { id },
    }),
  },
};

export interface IAnnouncementsReducerInterface {
  announcements: IAnnouncement[];
  isLoadingAnnouncements: boolean;
  audienceAvailable: AudienceType;
}

const initialState: IAnnouncementsReducerInterface = {
  announcements: [],
  isLoadingAnnouncements: false,
  audienceAvailable: ConsumerAudience.ALL_CONSUMERAPPS,
};
export const announcementsReducer = produce((draft: IAnnouncementsReducerInterface, action: IPayloadAction<any>) => {
  switch (action.type) {
    case AnnoucementsActions.types.FETCHING_ANNOUNCEMENTS:
      draft.isLoadingAnnouncements = true;
      return;
    case AnnoucementsActions.types.FETCHING_ANNOUNCEMENTS_SUCCESS:
      draft.isLoadingAnnouncements = false;
      draft.announcements = initialState.announcements.concat(action.payload.announcements.announcements);
      draft.audienceAvailable = action.payload.announcements.audienceAvailable;
      return;
    case AnnoucementsActions.types.FETCHING_ANNOUNCEMENTS_ERROR:
      draft.isLoadingAnnouncements = false;
      return;
    case AnnoucementsActions.types.ADD_ANNOUNCEMENT:
      draft.announcements = [...draft.announcements, action.payload.announcement];
      return;
    case AnnoucementsActions.types.UPDATE_ANNOUNCEMENT:
      draft.isLoadingAnnouncements = false;
      const idx = draft.announcements.findIndex(a => a.id === action.payload.id);
      if (idx > -1) {
        draft.announcements[idx] = action.payload.announcement;
      }
      return;
    case AnnoucementsActions.types.DELETE_ANNOUNCEMENT:
      draft.announcements = draft.announcements.filter(a => a.id !== action.payload.id);
      return;
    default:
      return draft;
  }
}, initialState);
