import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { PageContainer, Row, Column, IconContainer, LoadingText } from "../../../shared/shared.styled";
import {
  PageTitle,
  PrimaryButton,
  FastActionButton,
  CheckIcon,
  UndoIcon,
  useModal,
  ModalTypeEnum,
} from "@wit/mpesa-ui-components";
import { RoutesEnum } from "../../../routes/routes.constants";
import i18next, { TFunction } from "i18next";
import { Breadcrumb } from "@wit/mpesa-ui-components/lib/types";
import styled from "styled-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { IStoreInterface } from "../../../configs/store.config";
import { renderBanksGeneralStatus } from "./banks.utils";
import BanksList from "./components/banks-list.component";
import LastVersionModal from "./components/check-last-version-banks.component";
import PublishBanksModal from "./components/publish.component";
import { AxiosPromise } from "axios";
import useBankAPI from "./banks.api";
import { IBank, BanksStatusEnum } from "./bank.model";

const confirmRevert = (
  revertBanks: () => AxiosPromise<{
    banks: IBank[];
    status: BanksStatusEnum;
  }>,
  hideConfirmationModal: any,
) => {
  hideConfirmationModal();
  revertBanks().then(
    res => {},
    err => {},
  );
};

const BanksPage = () => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(true);
  const { banksStatus } = useSelector((state: IStoreInterface) => state.banksReducer);
  const history = useHistory();
  const { publishBanks, revertBanks, getData } = useBankAPI();

  const getBreadcrumbs = (t: TFunction): Breadcrumb[] => {
    return [
      {
        label: t("pages.configuration.title"),
        url: RoutesEnum.CONFIGS,
      },
      {
        label: t("pages.banks.title"),
        url: "",
      },
    ];
  };

  const submit = () => {
    hidePublishModal();
    publishBanks();
  };

  const [showPublishModal, hidePublishModal, setPublishProps] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <PublishBanksModal onCancelClick={() => hidePublishModal()} submit={submit} />,
  );

  React.useEffect(() => {
    setPublishProps({ modalStyles: { width: 1000, overflow: "initial" } });
  }, [setPublishProps]);

  useEffect(() => {
    setLoading(true);
    getData().then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showLastVersionModal, hideLastVersionModal, setLastVersionProps] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <LastVersionModal onCancelClick={() => hideLastVersionModal()} />,
  );

  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );

  React.useEffect(() => {
    setLastVersionProps({ modalStyles: { width: 553, height: 362, overflow: "initial" } });
  }, [setLastVersionProps]);

  const revertLastVersion = () => {
    setConfirmationProps({
      title: t("pages.appDetails.releaseVersions.confirmRevert"),
      description: t("pages.appDetails.releaseVersions.confirmRevertMessage"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      primaryAction: () => {
        confirmRevert(revertBanks, hideConfirmationModal);
      },
      secondaryAction: () => hideConfirmationModal(),
    } as any);
    showConfirmationModal();
  };

  const renderStatusBar = () => {
    return (
      <Row style={{ justifyContent: "space-between", flexWrap: "wrap", gap: "24px" }}>
        <Column>
          <Label>{t("pages.banks.status.title")}</Label>
          {renderBanksGeneralStatus(banksStatus, t)}
        </Column>
        {banksStatus === BanksStatusEnum.EDIT ? (
          <div style={{ maxWidth: 500, display: "flex", flexDirection: "row" }}>
            <div style={{ marginRight: 16 }} id={"check-last-version"}>
              <FastActionButton
                iconComponent={
                  <IconContainer size={16} color={styleTheme.palette.successGreen}>
                    <CheckIcon />
                  </IconContainer>
                }
                onClick={showLastVersionModal}
                label={t("pages.banks.checkLastVersion")}
              />
            </div>
            <div id={"revert-to-last-version"}>
              <FastActionButton
                iconComponent={
                  <IconContainer size={16} color={styleTheme.palette.successGreen}>
                    <UndoIcon />
                  </IconContainer>
                }
                onClick={revertLastVersion}
                label={t("pages.banks.revertChanges")}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </Row>
    );
  };

  return (
    <PageContainer>
      <PageTitle
        breadcrumbs={getBreadcrumbs(t)}
        title={t("pages.banks.title")}
        navigateFunction={history.push}
        goBackFn={() => window.history.back()}
        separatorStyle={{ display: "none" }}
        titleContainerStyle={{ marginTop: "13px", marginBottom: "34px" }}
        rightComponentContainerStyle={{ display: "flex", alignContent: "center", height: "100%", marginRight: "0px" }}
        rightComponent={
          banksStatus === BanksStatusEnum.EDIT ? (
            <ButtonContainer style={{}}>
              <PrimaryButton
                id={"publish-changes"}
                titleLabel={t("pages.banks.publishChanges")}
                redTheme={true}
                style={{ width: "fit-content" }}
                onClick={() => showPublishModal()}
              />
            </ButtonContainer>
          ) : null
        }
      />
      {renderStatusBar()}
      {loading ? <LoadingText>{t("commons.loadingResults")}</LoadingText> : <BanksList />}
    </PageContainer>
  );
};
export default BanksPage;

const Label = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
  margin-bottom: 14px;
`;
const ButtonContainer = styled.div`
  width: fit-content;
  position: relative;
  top: 10px;
`;
