import ApiClient, { updateRequestIdHeader } from "../../../configs/axios.kyc.config";

const DocDashboardApi = {
  routes: {
    counters: "/registration/all/details",
    ratingOverallDetails: "/registration/rating/details",
    csvExport: "/csv/registrations/details",
  },

  methods: {
    getCounters: (): Promise<any> => {
      updateRequestIdHeader();
      return ApiClient.get(DocDashboardApi.routes.counters);
    },
    getOverallRatingDetails: (): Promise<any> => {
      updateRequestIdHeader();
      return ApiClient.get(DocDashboardApi.routes.ratingOverallDetails);
    },

    getRegistrationsCsv: () => {
      updateRequestIdHeader();
      return ApiClient.get(DocDashboardApi.routes.csvExport);
    },
  },
};

export default DocDashboardApi;
