import { DiscoverCardsFiltersEnum } from "./components/discover-cards-filters.component";
import { IDiscoverCard, DiscoverCardsStatusEnum } from "./discover-cards.model";
import store from "../../../configs/store.config";
import i18next from "i18next";

export interface IDropdownOptionsInterface {
  key: string;
  label: string;
}

/**
 *
 * @param discoverCards
 * @param status
 * DiscoverCards filter by status
 */
export const filterDiscoverCardsByStatus = (
  discoverCards: IDiscoverCard[],
  status?: DiscoverCardsStatusEnum,
): IDiscoverCard[] => {
  if (status) {
    return discoverCards.filter(dc => dc.status === status);
  }
  return discoverCards;
};

/**
 *
 * @param discoverCards
 * @param searchString
 * @param selectedLang
 * Discover cards filter by search
 */
export const filterDiscoverCardsBySearch = (
  discoverCards: IDiscoverCard[],
  searchString: string,
  selectedLang: string,
) => {
  if (!!searchString) {
    return discoverCards.filter(dc =>
      dc.name === null || dc.description === null
        ? ""
        : dc.name[selectedLang].toLowerCase().includes(searchString.toLowerCase()) ||
          dc.description[selectedLang].toLowerCase().includes(searchString.toLowerCase()),
    );
  }
  return discoverCards;
};

/**
 * Filter an array of Discover cards using a search string
 */
export const filterDiscoverCards = (
  discoverCards: IDiscoverCard[],
  filters: Map<string, string[]>,
  selectedLang: string,
): IDiscoverCard[] => {
  let newDiscoverCards: IDiscoverCard[];
  const status = filters.get(DiscoverCardsFiltersEnum.STATUS) as any;
  const search = filters.get(DiscoverCardsFiltersEnum.SEARCH);

  newDiscoverCards = filterDiscoverCardsByStatus(discoverCards, status ? status[0] : undefined);

  if (search) {
    newDiscoverCards = filterDiscoverCardsBySearch(newDiscoverCards, search[0], selectedLang);
  }

  return newDiscoverCards;
};

/**
 * Get available languages
 */
export const getAvailableLanguagesOptions = () => {
  const discoverCardsStore = store.getState().discoverCardsReducer.discoverCards;

  const languages = Object.keys(
    discoverCardsStore.discoverCards.reduce((keys: any, item: any) => {
      const itemKeys = Object.keys(item.name);
      itemKeys.forEach(key => {
        keys[key] = true;
      });
      return keys;
    }, {}),
  ).map(key => {
    return { label: i18next.t(`commons.languages.${key}`), key: key };
  });

  if (languages) {
    return languages.sort((a, b) => {
      const labelA = a.label.toLowerCase();
      const labelB = b.label.toLowerCase();

      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    });
  } else {
    return [
      {
        label: i18next.t(`commons.languages.en`),
        key: "en",
      },
    ];
  }
};

/**
 * filterDropdownOptions - Filters the dropdown options by the sent acceptable states
 * @param serviceList {any[]}
 * @param acceptableStates {string[]}
 * @param language {string | undefined}
 *
 * @returns filteredList {dropdownOptionsInterface}
 */
export const filterDropdownOptions = (serviceList: any[], acceptableStates: string[], language?: string) => {
  const filteredList: IDropdownOptionsInterface[] = [];
  if (serviceList && serviceList.length > 0) {
    serviceList.map((service: any) => {
      if (acceptableStates.includes(service.status)) {
        if (service.name instanceof Object && !service.eventId) {
          filteredList.push({
            key: service.name.key,
            label: language && service.name.translations[language],
          });
        } else {
          filteredList.push({
            key: service.eventId ? service.eventId : service.id,
            label: language && service.eventId ? service.name.translations[language] : service.name,
          });
        }
      }
    });
  }
  return filteredList.sort((a, b) => (a.label?.toLowerCase() < b.label?.toLowerCase() ? -1 : 1));
};

/**
 * Get converted languages to SharedDropdownOption
 * @returns
 */
export const getConvertedLanguages = (availableLanguages: String[]) => {
  return (
    availableLanguages &&
    availableLanguages.map(lang => ({
      label: i18next.t(`commons.languages.${lang}`),
      key: lang,
    }))
  );
};
