import { AutoClosingDropdown } from "@wit/mpesa-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IAuditingLogDetails } from "../../auditing.model";
import { getAvailableLanguages, getLanguagesDropdownOptions } from "./auditing-details.utils";

interface IAuditingDetailsBodyInterface {
  details: IAuditingLogDetails;
}
const AuditingDetailsBody = ({ details }: IAuditingDetailsBodyInterface) => {
  const [t] = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>();
  const [availableLanguages, setAvailableLanguages] = React.useState<string[]>();

  /**
   * On language selection
   * @param {string} lang
   */
  const onLanguageChange = (lang: string) => {
    setSelectedLanguage(lang);
  };

  /**
   * Sets the available languages if any
   */
  React.useEffect(() => {
    setAvailableLanguages(getAvailableLanguages(details));
  }, [details]);

  return (
    <BodyContainer>
      {availableLanguages ? (
        <Container>
          <TraceResults>{t("pages.auditing.details.traceResults")}</TraceResults>
          <DropdownWrapper id={"language-selector"}>
            <AutoClosingDropdown
              hasValue={false}
              label={t(`commons.languages.${selectedLanguage}`)}
              options={getLanguagesDropdownOptions(availableLanguages)}
              selectOption={opt => {
                onLanguageChange(opt.key);
              }}
            />
          </DropdownWrapper>
        </Container>
      ) : null}
    </BodyContainer>
  );
};

const BodyContainer = styled("div")`
  margin-top: 32px;
`;

const Container = styled("div")`
  padding: 0px 37px 0px 68px;
  display: flex;
`;

const TraceResults = styled("span")`
  font-size: 14px;
  font-family: "Vodafone Rg";
  color: ${props => props.theme.palette.darkGrey};
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 8px;
  align-self: center;
`;

export const DropdownWrapper = styled.div`
  padding-top: 2px;
  > button {
    border: none;
    color: ${props => props.theme.palette.turquoiseBlue};
    padding-left: 0px;
    justify-content: normal;
    > span {
      max-width: 100% !important;
    }
    :hover {
      border: none;
    }

    > svg {
      stroke: ${props => props.theme.palette.turquoiseBlue};
    }
  }
`;
export default AuditingDetailsBody;
