import React from "react";

const NotificationBellIcon = () => {
  return (
    <svg
      width="32px"
      height="40px"
      viewBox="0 0 32 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Icon / Hi / Notification Copy</title>
      <defs>
        <linearGradient x1="19.4104327%" y1="-3.66868485%" x2="105.332138%" y2="70.5065936%" id="linearGradient-1">
          <stop stopColor="#FFFFFF" offset="0%"></stop>
          <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="42.6523907%" y1="5.95709616%" x2="75.6828271%" y2="80.4707977%" id="linearGradient-2">
          <stop stopColor="#000000" offset="0%"></stop>
          <stop stopColor="#000000" stopOpacity="0" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g id="06-Environment-configuration" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group" transform="translate(-8.000000, -4.000000)" fillRule="nonzero">
          <g id="notification">
            <rect id="bg" fillOpacity="0" fill="#D8D8D8" x="0" y="0" width="48" height="48"></rect>
            <path
              d="M32.195,19.75 L32.105,16.645 L32.06,15.12 C31.795081,12.9893413 30.694306,11.0512935 29,9.7325 L29,9.4425 C29,8.9 28.105,8.005 27,8.005 L26.975,8.005 L26.975,7 C26.9864365,6.20524093 26.6774872,5.4393308 26.1178063,4.8749464 C25.558125,4.310562 24.794827,3.99521453 24,3.99994632 C22.3431458,3.99994632 21,5.34314575 21,7 L21,8 L20.975,8 C19.895,8 19,8.9 19,9.4425 L19,9.7325 C17.3072034,11.051151 16.2074237,12.9881301 15.9425,15.1175 L15.8975,16.665 L15.8075,19.75 C15.677496,23.5452672 14.8176664,27.2799663 13.275,30.75 L34.7225,30.75 C33.1814952,27.2796418 32.3233637,23.5449447 32.195,19.75 L32.195,19.75 Z M21.9775,7 C21.9938467,5.89350868 22.893395,5.00396773 24,5 C25.1045695,5 26,5.8954305 26,7 L26,8 L23.975,8 L21.975,8 L21.9775,7 Z"
              id="Shape"
              fill="#EBEBEB"
            ></path>
            <path
              d="M24,39.5 C22.7573593,39.5 21.75,40.5073593 21.75,41.75 C21.75,42.9926407 22.7573593,44 24,44 C25.2426407,44 26.25,42.9926407 26.25,41.75 C26.25,40.5073593 25.2426407,39.5 24,39.5 L24,39.5 Z"
              id="Shape"
              fill="#E60000"
            ></path>
            <path
              d="M24,43.5 C22.8545099,43.4994563 21.8922765,42.6383972 21.765,41.5 C21.6651592,42.360317 22.0679833,43.201569 22.800811,43.663172 C23.5336386,44.124775 24.4663614,44.124775 25.199189,43.663172 C25.9320167,43.201569 26.3348407,42.360317 26.235,41.5 C26.1077235,42.6383972 25.14549,43.4994563 24,43.5 Z"
              id="Shape"
              fill="#000000"
              opacity="0.12"
            ></path>
            <path
              d="M25.41,40 L22.59,40 L22.575,40 L25.75,43.18 C26.1294043,42.7162723 26.3081805,42.1203707 26.2467035,41.5243745 C26.1852268,40.9283783 25.888575,40.381517 25.4225,40.005 L25.41,40 Z"
              id="Shape"
              fill="url(#linearGradient-1)"
              opacity="0.2"
            ></path>
            <path
              d="M38.4575,40.25 C38.7635715,40.2444277 39.0502297,40.0989717 39.2354593,39.855249 C39.4206885,39.611526 39.4840813,39.2963882 39.4075,39 L39.205,38.2875 C38.705,36.2875 38.0425,35.49 36.64,33.9875 C36.190782,33.5054288 35.8028438,32.9697042 35.485,32.3925 C35.375,32.1925 35.2675,31.9625 35.1625,31.7575 L12.8375,31.7575 C12.7325,31.9625 12.625,32.1925 12.515,32.3925 C12.1971564,32.9697042 11.8092179,33.5054288 11.36,33.9875 L11.36,33.9875 C9.955,35.4875 9.3,36.295 8.795,38.2875 L8.5925,39 C8.51591875,39.2963882 8.57931147,39.611526 8.76454085,39.855249 C8.94977022,40.0989717 9.2364286,40.2444277 9.5425,40.25 L38.4575,40.25 Z"
              id="Shape"
              fill="#EBEBEB"
            ></path>
            <path
              d="M34.7225,30.75 L13.275,30.75 C13.1225,31.075 13,31.435 12.835,31.75 L35.1625,31.75 C35,31.4375 34.875,31.075 34.7225,30.75 Z"
              id="Shape"
              fill="#4A4D4E"
            ></path>
            <polygon
              id="Rectangle_path"
              fill="#000000"
              opacity="0.12"
              points="21 7.5 21.975 7.5 21.975 8 21 8"
            ></polygon>
            <polygon
              id="Rectangle_path"
              fill="#000000"
              opacity="0.12"
              points="26 7.5 26.975 7.5 26.975 8 26 8"
            ></polygon>
            <path
              d="M32.195,19.75 L32.105,16.645 L32.06,15.12 C31.8325195,13.2735728 30.9761855,11.5617875 29.635,10.2725 C29.4328637,10.078444 29.2209193,9.89487013 29,9.7225 L29,9.4425 C29,8.9025 28.1075,8.01 26.98,8.005 L26.98,7 C26.9887125,6.2051824 26.6781955,5.4400944 26.1180237,4.8761631 C25.557852,4.31223177 24.7948581,3.99660402 24,3.99997288 C22.3431458,3.99997288 21,5.34314575 21,7 L21,8 L21,8 C19.895,8 19,8.895 19,9.4375 L19,9.7275 L18.9775,9.7475 L32.5325,23.3025 C32.3478035,22.1263059 32.2350937,20.9399319 32.195,19.75 L32.195,19.75 Z M26,8 L24.4175,8 C24.28,8 24.14,8 24,8 C23.86,8 23.7225,8 23.5825,8 L21.98,8 L21.98,7 C21.9936603,5.89334345 22.8932623,5.00264837 24,5 C25.1045695,5 26,5.8954305 26,7 L26,8 Z"
              id="Shape"
              fill="url(#linearGradient-2)"
              opacity="0.2"
            ></path>
            <path
              d="M19.15,9.25 L28.85,9.25 C28.9328427,9.25 29,9.3171573 29,9.4 L29,9.75 L19,9.75 L19,9.4 C19,9.3171573 19.0671573,9.25 19.15,9.25 Z"
              id="Shape"
              fill="#000000"
              opacity="0.12"
            ></path>
            <path
              d="M38.4575,39.75 L9.5425,39.75 C9.09144237,39.7440145 8.70023462,39.4367832 8.5875,39 C8.5107277,39.297227 8.57472735,39.613247 8.76109485,39.8571835 C8.94746235,40.10112 9.23554497,40.2459408 9.5425,40.25 L38.4575,40.25 C38.764455,40.2459408 39.0525378,40.10112 39.2389053,39.8571835 C39.4252728,39.613247 39.4892723,39.297227 39.4125,39 C39.2997655,39.4367832 38.9085575,39.7440145 38.4575,39.75 Z"
              id="Shape"
              fill="#000000"
              opacity="0.06"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
export default NotificationBellIcon;
