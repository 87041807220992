import { Modal, PrimaryButton } from "@wit/mpesa-ui-components";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import React from "react";
import styled from "styled-components";
import {
  ButtonContainer,
  ModalContent,
  ModalDescription,
  ModalTitle,
  ModelActionsContainer,
} from "../../../shared/components/safaricom-delete-modal/safaricom-delete-modal.styled";
import LoadingIconWhite from "../../../shared/icons/loading-white.icon";

interface IConfirmWithLoading extends BaseModalProps {
  isLoading: boolean;
}

/**
 * compoenent that will retrieve the modal for the unchanges made
 * @param param0 Interface of the modal
 */
const ConfirmWithLoadingModal = ({ isLoading, ...rest }: IConfirmWithLoading) => {
  return (
    <Modal onBackgroundClick={rest.secondaryAction}>
      <ModalContent>
        <ModalTitle>{rest.title}</ModalTitle>
        <ModalDescription>{rest.description}</ModalDescription>
        <ModelActionsContainer>
          <ButtonContainer>
            <PrimaryButton
              id={"cancel"}
              titleLabel={rest.secondaryBtnLabel ? rest.secondaryBtnLabel : ""}
              disabled={isLoading}
              redTheme={false}
              style={{ width: "fit-content" }}
              onClick={rest.secondaryAction}
            />
          </ButtonContainer>
          {!isLoading ? (
            <ButtonContainer>
              <PrimaryButton
                id={"save"}
                titleLabel={rest.primaryBtnLabel ? rest.primaryBtnLabel : ""}
                redTheme={true}
                style={{ width: "fit-content" }}
                onClick={rest.primaryAction}
              />
            </ButtonContainer>
          ) : (
            <DivLoading>
              <LoadingIconWhite />
            </DivLoading>
          )}
        </ModelActionsContainer>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmWithLoadingModal;

const DivLoading = styled("div")`
  background-color: rgb(47, 197, 109);
  width: 106px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
