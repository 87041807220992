import {
  DownloadIcon,
  ModalTypeEnum,
  SearchBar,
  SmallButton,
  Table,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IStoreInterface } from "../../../../configs/store.config";
import { IEnvProperty } from "../../../../shared/models/env-property.model";
import { SearchBarFilterContainer } from "../../../../shared/responsive-ui.styled";
import { IconContainer, LoadingText } from "../../../../shared/shared.styled";
import { renderTableButtons } from "../../../../shared/shared.utils";
import SupportApi from "../../support.api";
import { SupportActions } from "../../support.store";
import { FiltersContainer } from "../../support.styled";
import { downloadCsvFile, SupportUtils } from "../../support.utils";
import EditPropertyModal from "./edit-property.modal";
import NewPropertySection from "./new-property.component";

/**
 * Environment Properties Tab Component
 * @returns {JSX.Element} component
 */
const EnvironmentPropertiesTab = () => {
  // Hooks Initialization
  const [t] = useTranslation();
  const [showModal, hideModal, setProps] = useModal(ModalTypeEnum.ConfirmationModal);
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const dispatch = useDispatch();

  // Redux state
  const properties = useSelector((state: IStoreInterface) => state.supportReducer.properties);
  const [displayedProperties, setDisplayedProperties] = React.useState<IEnvProperty[]>(properties ? properties : []);

  // Local state
  const [isFetchingProperties, setIsFetchingProperties] = React.useState(true);
  const [envPropertyInEdition, setEditEnvProperty] = React.useState<IEnvProperty | undefined>(undefined);
  const [searchString, setSearchString] = React.useState("");

  const _hideEditModal = () => {
    hideEditModal();
  };

  const [showEditModal, hideEditModal] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <EditPropertyModal property={envPropertyInEdition as IEnvProperty} hideModal={_hideEditModal} />,
  );

  const fetchProperties = React.useCallback(() => {
    setIsFetchingProperties(true);
    SupportApi.methods
      .getProperties()
      .finally(() => {
        setIsFetchingProperties(false);
      })
      .then(
        res => {
          dispatch(SupportActions.Creators.fetchPropertiesSuccessAction(res.data.properties));
        },
        () => {
          showAlert();
        },
      );
  }, [dispatch, showAlert]);

  // Effects
  React.useEffect(() => {
    fetchProperties();
  }, [fetchProperties]);

  /*
   * Search
   * */
  React.useEffect(() => {
    let propertiesTemp = [...(properties ? properties : [])];

    if (!!searchString) {
      propertiesTemp = propertiesTemp.filter(
        prop =>
          prop.propertyName.toLowerCase().includes(searchString.toLowerCase()) ||
          prop.propertyValue.toLowerCase().includes(searchString.toLowerCase()),
      );
    }
    setDisplayedProperties(propertiesTemp);
  }, [properties, searchString]);

  /**
   * Function that allows the user to delete a property.
   * This function should only be called in the ConfirmationModal primaryAction callback
   * @param property EnvProperty
   */
  function deleteProperty(property: IEnvProperty) {
    SupportApi.methods.deleteProperty(property).then(
      () => {
        dispatch(SupportActions.Creators.deletePropertyAction(property));
        setAlertProps({
          title: t("pages.support.envProperties.alerts.deleteProperty.success"),
        });
        showAlert();
        hideModal();
      },
      () => {
        setAlertProps({
          title: t("pages.support.envProperties.alerts.deleteProperty.error"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  }

  /**
   * Shows the ConfirmationDialog before deleting a property
   * @param property EnvProperty
   */
  function showConfirmationDialog(property: IEnvProperty) {
    const primaryCb = () => deleteProperty(property);
    const modalProps: BaseModalProps = SupportUtils.getDeleteConfirmationModalProps(primaryCb, hideModal);
    setProps(modalProps);
    showModal();
  }

  function showEditPropertyDialog(property: IEnvProperty) {
    setEditEnvProperty(property);
    showEditModal();
  }

  /**
   * Calls the API to get the data and then calls our downloadJsonFile function
   */
  function exportData() {
    /**Temporary Solution until it's implemented on the backend side */
    const propertiesJson = JSON.parse(JSON.stringify(displayedProperties));
    const csvHeaders = `${t("pages.support.envProperties.table.headers.propertyName")},${t(
      "pages.support.envProperties.table.headers.propertyValue",
    )}`;
    let csvData = csvHeaders.concat("\n");
    for (let i = 0; i < Object.keys(propertiesJson).length; i++) {
      //Remove propertyType data from each row
      delete propertiesJson[i].propertyType;
      //Check if any of the values needs to be modified
      propertiesJson[i].propertyName = formatCSVValue(propertiesJson[i].propertyName);
      propertiesJson[i].propertyValue = formatCSVValue(propertiesJson[i].propertyValue);
      //Add each row value to the csvData variable, without the propertyType value
      csvData += Object.values(propertiesJson[i])
        .toString()
        .concat("\n");
    }

    downloadCsvFile(csvData, `Environment_Properties_${new Date().toISOString()}.csv`);
  }

  const formatCSVValue = (csvData: string) => {
    if (csvData.includes(",")) {
      return csvData.replace(/,/g, ";");
    } else if (csvData.trim().length === 0) {
      return "";
    } else {
      return csvData;
    }
  };

  return (
    <>
      <NewPropertySection />
      {isFetchingProperties ? (
        <LoadingText>{t("commons.loadingResults")}</LoadingText>
      ) : (
        <>
          <FiltersContainer style={{ marginBottom: "24px" }}>
            <SearchBarFilterContainer>
              <SearchBar
                id="searchProperties"
                value={searchString}
                onChange={e => setSearchString(e.target.value)}
                placeholderLabel={t("pages.support.envProperties.searchPlaceholder")}
                clearValue={() => setSearchString("")}
              />
            </SearchBarFilterContainer>
            <div style={{ marginLeft: "auto" }}>
              <SmallButton
                iconComponent={
                  <IconContainer size={20} color={styleTheme.palette.vodafoneRed}>
                    <DownloadIcon />
                  </IconContainer>
                }
                id="exportCsvButton"
                titleLabel={t("pages.support.envProperties.exportBtnLabel")}
                onClick={exportData}
              />
            </div>
          </FiltersContainer>
          <Table
            columns={[
              {
                formKey: "propertyName",
                label: t("pages.support.envProperties.table.headers.propertyName"),
                isEditable: false,
                ratio: 5 / 12,
              },
              {
                formKey: "propertyValue",
                label: t("pages.support.envProperties.table.headers.propertyValue"),
                isEditable: false,
                ratio: 5 / 12,
                changeFunction: (content, row) => {
                  if (row.propertyType !== "PLAIN_TEXT") {
                    return <>**********</>;
                  }
                  return <>{content}</>;
                },
              },
            ]}
            values={displayedProperties}
            displayActions={property =>
              renderTableButtons<IEnvProperty>(property, {
                edit: { onClick: showEditPropertyDialog },
                delete: { onClick: showConfirmationDialog },
              })
            }
          />
        </>
      )}
    </>
  );
};

export default EnvironmentPropertiesTab;
