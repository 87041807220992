import Axios, { AxiosError, AxiosRequestConfig } from "axios";
import store from "./store.config";
import { AuthenticationActions } from "../features/authentication/authentication.store";
// Enums
export enum AxiosHeadersEnum {
  SECURITY_COOKIE = "Security-Cookie",
  XSRF_TOKEN = "XSRF-TOKEN",
}

// Constants
export const AxiosConstants = {
  TIMEOUT: 30000,
};

// Configs
const requestConfig: AxiosRequestConfig = {
  // baseURL: "/api/",
  baseURL: process.env.REACT_APP_API_HOST,
  timeout: AxiosConstants.TIMEOUT,
  headers: {
    "Security-Cookie": `${localStorage.getItem("securityCookie")}`,
    "XSRF-TOKEN": `${localStorage.getItem("xsrfToken")}`,
  },
};

const ApiClient = Axios.create(requestConfig);

// Helpers
export function updateAxiosHeaders(key: string, value: string) {
  // @ts-ignore
  ApiClient.defaults.headers[key] = value;
}

// Interceptors
ApiClient.interceptors.response.use(
  response => {
    const securityCookie = response.headers["security-cookie"];
    const cookie = response.headers["set-Cookie"];
    const xsrfToken = response.headers["xsrf-token"];
    if (!!securityCookie) {
      updateAxiosHeaders(
        AxiosHeadersEnum.SECURITY_COOKIE,
        securityCookie, // FIXME: For some reason, this comes lowercase
      );
      // TODO: Since we're still not sure where we want to save this, we will just save this in the session storage for now
      localStorage.setItem("securityCookie", securityCookie);

      /* store.dispatch(
        AuthenticationActions.creators.updateSecurityCookieAction(
          securityCookie
        )
      ); */
    }
    if (!!xsrfToken) {
      updateAxiosHeaders(AxiosHeadersEnum.XSRF_TOKEN, xsrfToken);
      // TODO: Since we're still not sure where we want to save this, we will just save this in the session storage for now
      localStorage.setItem("xsrfToken", xsrfToken);

      /* store.dispatch(
        AuthenticationActions.creators.updateXsrfTokenAction(xsrfToken)
      ); */
    }
    return response;
  },
  (error: AxiosError) => {
    if (
      error.request.responseURL &&
      !error.request.responseURL.includes("logout") &&
      error.response &&
      error.response.status === 401 &&
      error.response.data &&
      error.response.data.status &&
      error.response.data.status.businessCode &&
      (error.response.data.status.businessCode === 4013 ||
        error.response.data.status.businessCode === 4014 ||
        error.response.data.status.businessCode === 4015)
    ) {
      store.dispatch(AuthenticationActions.creators.toggleModalAction(true));
    }
    return Promise.reject(error.response);
  },
);

export const configureAxiosUrl = (apiUrl: string) => {
  localStorage.setItem("baseUrl", apiUrl);
  ApiClient.defaults.baseURL = apiUrl;
};

export default ApiClient;
