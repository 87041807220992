import React from "react";
import styled from "styled-components";
import { NOTIFICATION_STATUSES } from "../push-notifications.modal";
import { t } from "i18next";
import EditIcon from "../../../shared/icons/edit.icon";
import ClockIcon from "../../../shared/icons/clock.icon";
import CheckIcon from "../../../shared/icons/check.icon";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { STATUSES } from "../pages/notifications-list.page";

/** Map status value (key) to status label */
const mapStatusValueToLabel = (value: string, options: SharedDropdownOption[]): string => {
  return options.find(opt => opt.key === value)?.label || "";
};

/**
 * Style the status cell, change the color
 * @param status notification status
 */
const styleStatusCell = (status: string) => {
  switch (status) {
    case NOTIFICATION_STATUSES.DRAFT:
      return (
        <StatusCell>
          <EditIcon />
          <StatusText color="#EB9700">
            {t(`pages.notifications.listPage.${mapStatusValueToLabel(status, STATUSES).toLowerCase()}`)}
          </StatusText>
        </StatusCell>
      );
    case NOTIFICATION_STATUSES.SCHEDULED:
      return (
        <StatusCell>
          <ClockIcon />
          <StatusText color="#00C3FF">
            {t(`pages.notifications.listPage.${mapStatusValueToLabel(status, STATUSES).toLowerCase()}`)}
          </StatusText>
        </StatusCell>
      );
    case NOTIFICATION_STATUSES.SENT:
      return (
        <StatusCell>
          <CheckIcon />
          <StatusText color="#009900">
            {t(`pages.notifications.listPage.${mapStatusValueToLabel(status, STATUSES).toLowerCase()}`)}
          </StatusText>
        </StatusCell>
      );
    default:
      return mapStatusValueToLabel(status, STATUSES);
  }
};

export default styleStatusCell;

const StatusCell = styled.div`
  display: flex;
  align-items: center;
`;

const StatusText = styled("span")<{ color: string }>`
  margin-left: 0.3rem;
  color: ${props => props.color};
`;
