import { AutoClosingDropdown } from "@wit/mpesa-ui-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface IGenericLanguageSelectorInterface {
  availableLanguages: SharedDropdownOption[] | string[];
  selectedLanguage: string;
  changeSelectedLanguage: (lang: SharedDropdownOption) => void;
  dropdownType?: DropdownType;
  id?: string;
  color?: string;
  backgroundColor?: string;
  border?: string;
  activeBorder?: string;
  fontSize?: string;
  lineHeight?: string;
  iconWidth?: string;
  iconHeight?: string;
}

/**
 * Generic dropdown language selector component
 */
const GenericLanguageSelector = ({
  availableLanguages,
  selectedLanguage,
  changeSelectedLanguage,
  id,
  color,
  backgroundColor,
  border,
  activeBorder,
  fontSize,
  lineHeight,
  iconWidth,
  iconHeight,
  dropdownType = DropdownType.CURVED_NORMAL,
}: IGenericLanguageSelectorInterface) => {
  const [t] = useTranslation();

  /**
   * Check type of available languages
   * @returns
   */
  const checkArrayStringType = () => {
    let isStringArray = true;
    if (availableLanguages && Array.isArray(availableLanguages)) {
      availableLanguages.map(lang => {
        if (typeof lang !== "string") {
          isStringArray = false;
        }
      });
    } else {
      isStringArray = false;
    }
    return isStringArray;
  };

  /**
   * Get available languages
   */
  const getLanguagesDropdownOptions = () => {
    if (checkArrayStringType()) {
      return availableLanguages.map((lang: any) => {
        return {
          key: lang,
          label: t(`commons.languages.${lang}`),
        };
      });
    } else if (availableLanguages) {
      return availableLanguages as SharedDropdownOption[];
    } else {
      return [{ key: "", label: "" }];
    }
  };
  return (
    <LanguageDropdownWrapper
      id={id || "language-selector"}
      color={color}
      backgroundColor={backgroundColor}
      border={border}
      activeBorder={activeBorder}
      fontSize={fontSize}
      lineHeight={lineHeight}
      iconWidth={iconWidth}
      iconHeight={iconHeight}
    >
      <AutoClosingDropdown
        hasValue={!!selectedLanguage}
        dropdownType={dropdownType}
        label={t(`commons.languages.${selectedLanguage}`)}
        options={getLanguagesDropdownOptions()}
        selectOption={changeSelectedLanguage}
      />
    </LanguageDropdownWrapper>
  );
};

export default GenericLanguageSelector;

const LanguageDropdownWrapper = styled.div<{
  color?: string;
  backgroundColor?: string;
  border?: string;
  activeBorder?: string;
  fontSize?: string;
  lineHeight?: string;
  iconWidth?: string;
  iconHeight?: string;
}>`
  width: fit-content;
  min-width: 100px;
  > button {
    background-color: ${props => (props.backgroundColor ? props.backgroundColor : "#333333")};
    color: ${props => (props.color ? props.color : "white")};
    border: ${props => (props.border ? props.border : "none")};
    padding: 7px 12px;
    margin-top: auto;
    margin-bottom: auto;
    height: auto;
    :hover {
      border: ${props => (props.activeBorder ? props.activeBorder : "none")};
    }
    > div {
      min-width: -webkit-fill-available !important;
      z-index: 10;
    }
    > span {
      font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
      line-height: ${props => (props.lineHeight ? props.lineHeight : "16px")};
    }
    > svg {
      stroke: ${props => (props.color ? props.color : "white")};
      height: ${props => (props.iconHeight ? props.iconHeight : "18px")};
      width: ${props => (props.iconWidth ? props.iconWidth : "18px")};
    }
  }
`;
