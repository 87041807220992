import React, { useContext, useState } from "react";
import { PrimaryButton, TextInput, AutoClosingDropdown, ArrowDownIcon, useAlert } from "@wit/mpesa-ui-components";
import i18next from "i18next";
import styled, { css, keyframes, ThemeContext } from "styled-components";
import { Formik } from "formik";
import { IconContainer } from "../../../../shared/shared.styled";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { IStoreInterface } from "../../../../configs/store.config";
import { IShortcut, ShortcutActionTypeEnum, ShortcutFlowEnum } from "../shortcuts.model";
import { ConfigContext } from "../../../../app.component";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { filterDropdownOptions, IDropdownOptionsInterface } from "../../discover-cards/discover-cards.utils";
import { ServiceStatusEnum } from "../../../../shared/models/service-builder.model";
import { BundleTypeStatusEnum } from "../../bundles/bundles.model";
import BundlesApi from "../../bundles/bundles.api";
import { ServicesActions } from "../../../service-builder/services.store";
import ServicesApi from "../../../service-builder/services.api";
import { AntServiceManagerActions } from "../../../ant-service-manager/ant-service-manager.store";
import AntServiceManagerApi from "../../../ant-service-manager/ant-service-manager.api";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BundlesActions } from "../../bundles/bundles.store";
import ShortcutsApi from "../shortcuts.api";
import { ILanguage } from "../../../../shared/models/language.model";
import IconPicker from "../../../../shared/components/icon-picker.component";
import { object, string } from "yup";
import { getTranslationValidators } from "../../../../shared/shared.utils";
import { IIconCategory } from "../../categories/general-categories.model";
import { DynamicScreenStatusEnum } from "../../dynamic-screens/dynamic-screens.model";
import DynamicScreensApi from "../../dynamic-screens/dynamic-screens.api";
import { DynamicScreensActions } from "../../dynamic-screens/dynamic-screens.store";
import useEmptyLanguageWarningModal from "../../../../shared/hooks/use-empty-language-warning-modal.hook";

interface IShortcutDrawerProps {
  setIsVisible: (visible: boolean) => void;
  isVisible: boolean;
  selectedShortcut: IShortcut;
  getShortcuts: () => void;
  availableLanguages: ILanguage[] | undefined;
  icons: IIconCategory[];
}

/**
 *
 * @param value
 * @param setLanguage
 * @param availableLanguages
 */
const validateLanguages = (
  value: any,
  availableLanguages: ILanguage[],
  setShowWarning: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  if (!value) {
    return false;
  }
  for (let l = 0; l < availableLanguages.length; l++) {
    if (!value[availableLanguages[l].code] && availableLanguages[l].mandatory) {
      return false;
    } else if (!value[availableLanguages[l].code]) {
      setShowWarning(true);
    }
  }
  return true;
};

/**
 *
 * @param actualType
 * @param setLanguage
 * @param availableLanguages
 * @returns
 */
export const getValidationSchema = (
  actualType: string,
  availableLanguages: ILanguage[],
  setShowWarning: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  if (actualType === ShortcutActionTypeEnum.NATIVE) {
    return object().shape({
      name: object()
        .shape(getTranslationValidators(availableLanguages))
        .test("name-lang", i18next.t("pages.shortcuts.edit.errors.mandatory"), value =>
          validateLanguages(value, availableLanguages, setShowWarning),
        ),
      iconId: string().required(i18next.t("pages.shortcuts.edit.errors.mandatory")),
      data: object().shape({
        type: string().required(i18next.t("pages.shortcuts.edit.errors.mandatory")),
        flow: string().required(i18next.t("pages.shortcuts.edit.errors.mandatory")),
      }),
    });
  } else if (actualType === ShortcutActionTypeEnum.DYNAMIC_SCREEN) {
    return object().shape({
      name: object()
        .shape(getTranslationValidators(availableLanguages))
        .test("name-lang", i18next.t("pages.shortcuts.edit.errors.mandatory"), value =>
          validateLanguages(value, availableLanguages, setShowWarning),
        ),
      iconId: string().required(i18next.t("pages.shortcuts.edit.errors.mandatory")),
      data: object().shape({
        type: string().required(i18next.t("pages.shortcuts.edit.errors.mandatory")),
        eventId: string().required(i18next.t("pages.shortcuts.edit.errors.mandatory")),
      }),
    });
  } else {
    return object().shape({
      name: object()
        .shape(getTranslationValidators(availableLanguages))
        .test("name-lang", i18next.t("pages.shortcuts.edit.errors.mandatory"), value =>
          validateLanguages(value, availableLanguages, setShowWarning),
        ),
      iconId: string().required(i18next.t("pages.shortcuts.edit.errors.mandatory")),
      data: object().shape({
        type: string().required(i18next.t("pages.shortcuts.edit.errors.mandatory")),
        uri: string().required(i18next.t("pages.shortcuts.edit.errors.mandatory")),
      }),
    });
  }
};

/**
 * Shortcut Drawer component
 * @returns  {JSX.Element}
 */
const ShortcutDrawer = ({
  isVisible,
  setIsVisible,
  selectedShortcut,
  getShortcuts,
  availableLanguages,
  icons,
}: IShortcutDrawerProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const { config } = useContext(ConfigContext);
  const dynamicScreens = useSelector((store: IStoreInterface) => store.dynamicScreensReducer.dynamicScreens.items);
  const miniApps = useSelector((store: IStoreInterface) => store.servicesReducer.services);
  const antMiniApps = useSelector((store: IStoreInterface) => store.antServiceManagerReducer.services);
  const bundles = useSelector((store: IStoreInterface) => store.bundlesReducer.bundles);

  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, , setAlertProps] = useAlert();
  const [actualType, setActualType] = useState(selectedShortcut.type);
  const [availableDynamicScreen, setAvailableDynamicScreen] = useState(true);
  const [availableMiniApp, setAvailableMiniApp] = useState(true);
  const [availableBundle, setAvailableBundle] = useState(true);
  const defaultColor = themeContext.palette.greyLight;

  const { warningModalOpen, showWarning, setShowWarning } = useEmptyLanguageWarningModal(availableLanguages || []);

  const MINI_APP_URI = "mpesa://mini-app";
  const BUNDLES_URI = "mpesa://bundle";

  /**
   * Get initial values
   * @returns
   */
  const getInitialValues = (): IShortcut => {
    const editedShortcut = selectedShortcut
      ? ({
          ...selectedShortcut,
          data: {
            ...selectedShortcut.data,
            type: selectedShortcut.deepLinkType ? selectedShortcut.deepLinkType : selectedShortcut.data.type,
          },
        } as IShortcut)
      : {
          data: {
            type: "",
            uri: "",
            flow: "",
            eventId: "",
          },
        };
    return editedShortcut as IShortcut;
  };

  /**
   * Fetch type of links send in the configuration file from the backend
   * In case the typeOfLinks object is undefined or empty return a default list
   */
  const typeOptions =
    config && config.actionItems && config.actionItems.typeOfLinks
      ? config.actionItems.typeOfLinks.sort().map(options => ({
          key: options,
          label: t(`commons.typeOfLinkDropDown.${options}`),
        }))
      : [
          {
            key: ShortcutActionTypeEnum.NONE,
            label: t("commons.typeOfLinkDropDown.NONE"),
          },
          {
            key: ShortcutActionTypeEnum.EXTERNAL_LINK,
            label: t("commons.typeOfLinkDropDown.EXTERNAL_LINK"),
          },
          {
            key: ShortcutActionTypeEnum.NATIVE,
            label: t("commons.typeOfLinkDropDown.NATIVE"),
          },
        ];

  const availableIconsList =
    config && config.actionItems && config.actionItems.availableIcons ? config.actionItems.availableIcons : undefined;

  /**
   * Load store values if they are empty
   */
  React.useEffect(() => {
    if (bundles.length === 0 && typeOptions.find(option => option.key === ShortcutActionTypeEnum.BUNDLE)) {
      getBundles();
    }

    if (
      dynamicScreens === undefined &&
      typeOptions.find(option => option.key === ShortcutActionTypeEnum.DYNAMIC_SCREEN)
    ) {
      getDynamicScreens();
    }

    if (miniApps.length === 0 && typeOptions.find(option => option.key === ShortcutActionTypeEnum.MINI_APP)) {
      getMiniApps();
    }

    if (antMiniApps.length === 0 && typeOptions.find(option => option.key === ShortcutActionTypeEnum.MINI_APP)) {
      getAntMiniApps();
    }
  }, []);

  /**
   * Gets the list of bundle types
   */
  const getBundles = () => {
    BundlesApi.methods.getBundles().then(
      res => {
        dispatch(BundlesActions.creators.getBundlesSuccessAction(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.bundles.getBundlesError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * Get dynamic screens list
   */
  const getDynamicScreens = () => {
    DynamicScreensApi.methods.getDynamicScreens().then(
      res => {
        dispatch(DynamicScreensActions.creators.getDynamicScreensSuccess(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.dynamicScreens.getDynamicScreensError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * Get mini apps list
   */
  const getMiniApps = () => {
    dispatch(ServicesActions.creators.fetchingServicesAction());
    ServicesApi.methods.getServices().then(
      res => {
        dispatch(ServicesActions.creators.fetchServicesSuccessAction(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.serviceBuilder.errors.searchServices"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * Get ant mini apps list
   */
  const getAntMiniApps = () => {
    dispatch(AntServiceManagerActions.creators.fetchingServicesAction());
    AntServiceManagerApi.methods.getServices().then(
      res => {
        dispatch(AntServiceManagerActions.creators.fetchServicesSuccessAction(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.serviceBuilder.errors.searchServices"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * Get labels for each dropdown link
   * @param type
   */
  const getOptionLabel = (type: string) => {
    if (getInitialValues().data.type) {
      return t(`commons.typeOfLinkDropDown.${type}`);
    } else {
      return type && typeOptions.find(opt => opt.key === type)
        ? (typeOptions.find(opt => opt.key === type) as any).label
        : t("pages.shortcuts.edit.chooseLink");
    }
  };

  const flowOptions = (config && config.actionItems && config.actionItems.availableAppFlows
    ? config.actionItems.availableAppFlows
    : Object.values(ShortcutFlowEnum)
  )
    .sort()
    .map(flow => ({
      label: i18next.t(`commons.internalFlowDropDown.${flow}`),
      key: flow,
    }));

  /**
   * get flow label
   * @param type
   */
  const getFlowLabel = (type: string) => {
    return type && flowOptions.find(opt => opt.key === type)
      ? (flowOptions.find(opt => opt.key === type) as any).label
      : t("pages.shortcuts.edit.chooseFlow");
  };

  /**
   * Filter mini apps accepted options
   */
  const miniAppOptions = filterDropdownOptions([...miniApps, ...antMiniApps], [ServiceStatusEnum.LIVE]);

  /**
   * Get mini app label
   * @param type
   */
  const getMiniAppLabel = (type: string) => {
    let miniAppType: string;
    //Fetch mini app key from url on EDIT
    if (type && type.includes(MINI_APP_URI)) {
      const formatedType = type.split("?");
      const bundleKey = formatedType[1].split("id=");
      miniAppType = bundleKey[1];

      if (miniAppOptions.length === 0) {
        setAvailableMiniApp(false);
      } else if (!miniAppOptions.find(app => app.key === miniAppType)) {
        setAvailableMiniApp(true);
        return t("pages.shortcuts.edit.unableToFindItem");
      } else {
        setAvailableMiniApp(true);
      }
    } else {
      miniAppType = type;
    }
    return type && miniAppOptions && miniAppOptions.find(opt => opt.key === miniAppType)
      ? (miniAppOptions && (miniAppOptions.find(opt => opt.key === miniAppType) as any)).label
      : t("pages.shortcuts.edit.chooseMiniApp");
  };

  /**
   * Filter bundle accepted options
   */
  const bundleOptions =
    availableLanguages && filterDropdownOptions(bundles, [BundleTypeStatusEnum.LIVE], availableLanguages[0]?.code);

  /**
   * Filter Dynamic screen accepted options
   */
  const dynamicScreenOptions =
    availableLanguages &&
    filterDropdownOptions(
      dynamicScreens,
      [
        DynamicScreenStatusEnum.LIVE,
        DynamicScreenStatusEnum.DISABLED,
        DynamicScreenStatusEnum.DISABLE,
        DynamicScreenStatusEnum.EDIT,
        DynamicScreenStatusEnum.ENABLE,
      ],
      availableLanguages && availableLanguages[0].code,
    );

  /**
   * Get dynamic screen label
   * @param type
   */
  const getDynamicScreenLabel = (type: string) => {
    if (selectedShortcut.data.type === ShortcutActionTypeEnum.DYNAMIC_SCREEN) {
      if (dynamicScreenOptions && dynamicScreenOptions.length === 0) {
        setAvailableDynamicScreen(false);
      } else if (dynamicScreenOptions && !dynamicScreenOptions.find(dynamic => dynamic.key === type)) {
        setAvailableDynamicScreen(true);
        return t("pages.discoverCards.add.unableToFindItem");
      } else {
        setAvailableDynamicScreen(true);
      }
    }

    return type && dynamicScreenOptions && dynamicScreenOptions.find(opt => opt.key === type)
      ? (dynamicScreenOptions && (dynamicScreenOptions.find(opt => opt.key === type) as any)).label
      : t("pages.discoverCards.add.chooseDynamicScreen");
  };

  /**
   * Get bundle label
   * @param type
   */
  const getBundleLabel = (type: string) => {
    let bundleType: string;
    //Fetch bundle key from url on EDIT
    if (type && type.includes("mpesa://bundle")) {
      const formatedType = type.split("&");
      const bundleKey = formatedType[1].split("bundleTypeName=");
      bundleType = bundleKey[1];

      if (bundleOptions && bundleOptions.length === 0) {
        setAvailableBundle(false);
      } else if (bundleOptions && !bundleOptions.find(bundle => bundle.key === bundleType)) {
        setAvailableBundle(true);
        return t("pages.shortcuts.edit.unableToFindItem");
      } else {
        setAvailableBundle(true);
      }
    } else {
      bundleType = type;
    }
    return type && bundleOptions && bundleOptions.find(opt => opt.key === (bundleType as string))
      ? (bundleOptions && (bundleOptions.find(opt => opt.key === bundleType) as any)).label
      : t("pages.shortcuts.edit.chooseBundle");
  };

  /**
   * Submit method wrapper to show warning if needed
   * @param values
   */
  const submitWrapper = (values: any) => {
    if (showWarning) {
      setShowWarning(false);
      warningModalOpen(submit, values);
    } else {
      setShowWarning(false);
      submit(values);
    }
  };

  /**
   * Submit method
   * @param values
   */
  const submit = (values: any) => {
    setIsLoading(true);
    const deepLinkList = [ShortcutActionTypeEnum.MINI_APP, ShortcutActionTypeEnum.BUNDLE];
    const requestData: any = {
      ...values,
      type: deepLinkList.includes(values.data.type) ? ShortcutActionTypeEnum.DEEP_LINK : values.data.type,
    };

    if (
      requestData.deepLinkType === ShortcutActionTypeEnum.MINI_APP &&
      requestData.type !== ShortcutActionTypeEnum.DEEP_LINK
    ) {
      delete requestData.deepLinkType;
    }

    switch (values.data.type) {
      case ShortcutActionTypeEnum.NATIVE:
        requestData.data = { type: values.data.type, flow: values.data.flow };
        break;

      case ShortcutActionTypeEnum.DYNAMIC_SCREEN:
        requestData.data = { type: values.data.type, eventId: values.data.eventId };
        break;

      case ShortcutActionTypeEnum.BUNDLE:
        requestData.data = { type: ShortcutActionTypeEnum.DEEP_LINK, uri: values.data.uri };
        requestData.deepLinkType = ShortcutActionTypeEnum.BUNDLE;
        break;

      case ShortcutActionTypeEnum.MINI_APP:
        requestData.data = { type: ShortcutActionTypeEnum.DEEP_LINK, uri: values.data.uri };
        requestData.deepLinkType = ShortcutActionTypeEnum.MINI_APP;
        break;

      case ShortcutActionTypeEnum.NONE:
        requestData.data = { type: ShortcutActionTypeEnum.NONE };
        break;

      default:
        requestData.data = { type: values.data.type, uri: values.data.uri };
        break;
    }
    ShortcutsApi.methods
      .editShortcut(requestData, selectedShortcut.id)
      .then(
        _res => {
          setAlertProps({
            title: t("pages.shortcuts.edit.success"),
            type: AlertTypeEnum.SUCCESS,
          });
          getShortcuts();
          setIsVisible(false);
        },
        _err => {
          setAlertProps({
            title: t("pages.shortcuts.edit.error"),
            type: AlertTypeEnum.ERROR,
          });
        },
      )
      .finally(() => {
        showAlert();
        setIsLoading(false);
      });
  };

  /**
   * Filter for available icons
   * @returns
   */
  const getFilteredIcons = () => {
    return icons ? (availableIconsList ? icons.filter(icon => availableIconsList.includes(icon.label)) : icons) : [];
  };

  /**
   * Check form
   * @param values
   */
  const checkFormSubmitButton = (values: any) => {
    if (JSON.stringify(selectedShortcut) === JSON.stringify(values)) {
      return true;
    }
    return false;
  };

  /**
   * Check name clear
   * @param selectedValue
   * @param setFieldValue
   * @param uri
   * @param flow
   * @param eventId
   */
  const checkNameClear = (
    selectedValue: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    uri?: string,
    flow?: string,
    eventId?: string,
  ) => {
    /**
     * Clear field method
     */
    const clearField = () => {
      availableLanguages?.forEach((lang: ILanguage) => {
        setFieldValue(`name[${lang.code}]`, "");
      });
    };

    /**
     * Set field method
     */
    const setFieldValues = () => {
      availableLanguages?.forEach((lang: ILanguage) => {
        setFieldValue(`name[${lang.code}]`, selectedShortcut.name[lang.code]);
      });
    };
    if (
      selectedValue === selectedShortcut.data.type ||
      (selectedShortcut.data.type === ShortcutActionTypeEnum.DEEP_LINK &&
        selectedValue === selectedShortcut?.deepLinkType)
    ) {
      switch (selectedValue) {
        case ShortcutActionTypeEnum.EXTERNAL_LINK:
          if (uri && uri === selectedShortcut.data.uri) {
            setFieldValues();
          } else {
            clearField();
          }
          break;

        case ShortcutActionTypeEnum.MINI_APP:
        case ShortcutActionTypeEnum.BUNDLE:
          if (uri && selectedShortcut.data.uri && getDeepLinkId(uri) === getDeepLinkId(selectedShortcut.data.uri)) {
            setFieldValues();
          } else {
            clearField();
          }
          break;

        case ShortcutActionTypeEnum.NATIVE:
          if (flow && flow === selectedShortcut.data.flow) {
            setFieldValues();
          } else {
            clearField();
          }
          break;

        case ShortcutActionTypeEnum.DYNAMIC_SCREEN:
          if (eventId && eventId === selectedShortcut.data.eventId) {
            setFieldValues();
          } else {
            clearField();
          }
          break;

        default:
          break;
      }
    } else {
      clearField();
    }
  };

  /**
   * Get mini app label
   * @param type
   */
  const getDeepLinkId = (value: string) => {
    let deepLinkId: string = value;
    if (value && value.includes(MINI_APP_URI)) {
      const formatedType = value.split("?");
      const bundleKey = formatedType[1].split("id=");
      deepLinkId = bundleKey[1];
    } else if (value && value.includes(BUNDLES_URI)) {
      const formatedType = value.split("&");
      const bundleKey = formatedType[1].split("bundleTypeName=");
      deepLinkId = bundleKey[1];
    }
    return deepLinkId;
  };

  return (
    <div>
      {isVisible && (
        <>
          <DrawerContainer isVisible={isVisible}>
            <Header>
              <div onClick={() => setIsVisible(false)}>
                <IconBack size={20} color={themeContext.palette.vodafoneRed}>
                  <ArrowDownIcon />
                </IconBack>
              </div>
              <ModalTitle>{t("pages.shortcuts.edit.edit")}</ModalTitle>
            </Header>

            <Formik<any>
              onSubmit={submitWrapper}
              validateOnChange={false}
              initialValues={getInitialValues()}
              validationSchema={getValidationSchema(actualType, availableLanguages as ILanguage[], setShowWarning)}
              render={({ isSubmitting, handleSubmit, values, setFieldValue, errors, handleChange }) => {
                return (
                  <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <FormSectionRow>
                      <SectionColumn>
                        <Label required={true}>{t("pages.shortcuts.edit.typeOfLink")}</Label>
                      </SectionColumn>
                      <SectionColumn>
                        <AutoClosingDropdown
                          error={errors.data && ((errors.data as any).type as string)}
                          label={getOptionLabel(values.data.type)}
                          hasValue={values.data.type ? true : false}
                          selectOption={opt => {
                            setFieldValue("data.type", opt.key);
                            setActualType(opt.key);
                            if (
                              (opt.key === ShortcutActionTypeEnum.MINI_APP &&
                                getInitialValues().data.type === ShortcutActionTypeEnum.BUNDLE) ||
                              (opt.key === ShortcutActionTypeEnum.BUNDLE &&
                                getInitialValues().data.type === ShortcutActionTypeEnum.MINI_APP)
                            ) {
                              setFieldValue("data.uri", "");
                            }
                            if (
                              (opt.key === ShortcutActionTypeEnum.MINI_APP &&
                                selectedShortcut?.deepLinkType === ShortcutActionTypeEnum.MINI_APP) ||
                              (opt.key === ShortcutActionTypeEnum.BUNDLE &&
                                selectedShortcut?.deepLinkType === ShortcutActionTypeEnum.BUNDLE)
                            ) {
                              setFieldValue("data.uri", selectedShortcut.data.uri);
                            }
                            checkNameClear(
                              opt.key,
                              setFieldValue,
                              getInitialValues().data.uri,
                              getInitialValues().data.flow,
                              getInitialValues().data.eventId,
                            );
                          }}
                          dropdownType={DropdownType.RECTANGULAR_NORMAL}
                          options={typeOptions}
                        />
                      </SectionColumn>
                    </FormSectionRow>
                    <FormSectionRow
                      hide={!(values.data.type && values.data.type === ShortcutActionTypeEnum.EXTERNAL_LINK)}
                    >
                      <SectionColumn>
                        <Label required={true}>{t("commons.typeOfLinkDropDown.EXTERNAL_LINK")}</Label>
                      </SectionColumn>
                      <SectionColumn>
                        <TextInput
                          value={values.data.uri}
                          error={errors.data && ((errors.data as any).uri as string)}
                          name="data.uri"
                          placeholder={t("pages.shortcuts.edit.addURI")}
                          onChange={(e: React.ChangeEvent) => {
                            handleChange(e);
                            checkNameClear(
                              values.data.type,
                              setFieldValue,
                              (e.target as HTMLInputElement).value,
                              undefined,
                              undefined,
                            );
                          }}
                        />
                      </SectionColumn>
                    </FormSectionRow>

                    <FormSectionRow hide={!(values.data.type && values.data.type === ShortcutActionTypeEnum.NATIVE)}>
                      <SectionColumn>
                        <Label required={true}>{t("commons.typeOfLinkDropDown.NATIVE")}</Label>
                      </SectionColumn>
                      <SectionColumn>
                        <AutoClosingDropdown
                          error={errors.data && ((errors.data as any).flow as string)}
                          label={getFlowLabel(values.data.flow)}
                          hasValue={values.data.flow ? true : false}
                          selectOption={opt => {
                            setFieldValue("data.flow", opt.key);
                            checkNameClear(values.data.type, setFieldValue, undefined, opt.key, undefined);
                          }}
                          dropdownType={DropdownType.RECTANGULAR_NORMAL}
                          options={flowOptions}
                        />
                      </SectionColumn>
                    </FormSectionRow>

                    <FormSectionRow
                      hide={!(values.data.type && values.data.type === ShortcutActionTypeEnum.DYNAMIC_SCREEN)}
                    >
                      <SectionColumn>
                        <Label required={true}> {t("commons.typeOfLinkDropDown.DYNAMIC_SCREEN")}</Label>
                      </SectionColumn>
                      <SectionColumn>
                        {availableDynamicScreen ? (
                          <AutoClosingDropdown
                            error={errors.data && ((errors.data as any).eventId as string)}
                            label={getDynamicScreenLabel(values.data.eventId)}
                            hasValue={!!values.data.eventId}
                            selectOption={opt => {
                              setFieldValue("data.eventId", opt.key);
                              checkNameClear(values.data.type, setFieldValue, undefined, undefined, opt.key);
                            }}
                            dropdownType={DropdownType.RECTANGULAR_NORMAL}
                            options={dynamicScreenOptions as IDropdownOptionsInterface[]}
                          ></AutoClosingDropdown>
                        ) : (
                          <Label>{t("pages.shortcuts.edit.unableToFindItem")}</Label>
                        )}
                      </SectionColumn>
                    </FormSectionRow>

                    {/*Bundles & Mini Apps*/}
                    <FormSectionRow hide={!(values.data.type && values.data.type === ShortcutActionTypeEnum.MINI_APP)}>
                      <SectionColumn>
                        <Label required={true}>{t("commons.typeOfLinkDropDown.MINI_APP")}</Label>
                      </SectionColumn>
                      <SectionColumn>
                        {availableMiniApp ? (
                          <AutoClosingDropdown
                            error={errors.data && ((errors.data as any).uri as string)}
                            label={getMiniAppLabel(values.data.uri)}
                            hasValue={values.data.uri ? true : false}
                            selectOption={opt => {
                              setFieldValue("data.uri", opt.key);
                              checkNameClear(values.data.type, setFieldValue, opt.key, undefined, undefined);
                            }}
                            dropdownType={DropdownType.RECTANGULAR_NORMAL}
                            options={miniAppOptions as IDropdownOptionsInterface[]}
                          />
                        ) : (
                          <Label>{t("pages.shortcuts.edit.unableToFindItem")}</Label>
                        )}
                      </SectionColumn>
                    </FormSectionRow>

                    <FormSectionRow hide={!(values.data.type && values.data.type === ShortcutActionTypeEnum.BUNDLE)}>
                      <SectionColumn>
                        <Label required={true}>{t("commons.typeOfLinkDropDown.BUNDLE")}</Label>
                      </SectionColumn>
                      <SectionColumn>
                        {availableBundle ? (
                          <AutoClosingDropdown
                            error={errors.data && ((errors.data as any).uri as string)}
                            label={getBundleLabel(values.data.uri)}
                            hasValue={values.data.uri ? true : false}
                            selectOption={opt => {
                              setFieldValue("data.uri", opt.key);
                              checkNameClear(values.data.type, setFieldValue, opt.key, undefined, undefined);
                            }}
                            dropdownType={DropdownType.RECTANGULAR_NORMAL}
                            options={bundleOptions as IDropdownOptionsInterface[]}
                          />
                        ) : (
                          <Label>{t("pages.shortcuts.edit.unableToFindItem")}</Label>
                        )}
                      </SectionColumn>
                    </FormSectionRow>
                    <FormSectionRow>
                      <SectionColumn>
                        <Label required={true}>Icon</Label>
                      </SectionColumn>
                      <SectionColumn>
                        <IconPickerContainer>
                          <IconPicker
                            icons={getFilteredIcons()}
                            icon={icons.find(icon => icon.id.toString() === values.iconId?.toString())}
                            name="iconId"
                            setIconID={setFieldValue}
                            backgroundColor={defaultColor}
                            displayBgColor={defaultColor}
                            borderColor={"#ebebeb"}
                            activeBorderColor={"#e60000"}
                            error={errors.iconId as string}
                            isLoading={false}
                          ></IconPicker>
                        </IconPickerContainer>
                      </SectionColumn>
                    </FormSectionRow>
                    {availableLanguages &&
                      availableLanguages.map((lang: ILanguage, idx) => {
                        return (
                          <FormSectionRow>
                            <SectionColumn>
                              <Label required={lang.mandatory}>{lang.name}</Label>
                            </SectionColumn>
                            <SectionColumn>
                              <TextInput
                                id={`name-${lang.code}`}
                                placeholder={t("pages.shortcuts.edit.addTranslationPlaceholder", {
                                  lang: lang.name.toLowerCase(),
                                })}
                                name={`name[${lang.code}]`}
                                onChange={e => setFieldValue(`name[${lang.code}]`, e.target.value)}
                                value={values.name[lang.code] || ""}
                              />
                              {errors.name && (errors.name as any)[lang.code] && (
                                <ErrorMessageLabel id={`inputErrorMessage-${lang.code}`}>
                                  {(errors.name as any)[lang.code]}
                                </ErrorMessageLabel>
                              )}
                            </SectionColumn>
                          </FormSectionRow>
                        );
                      })}

                    <ButtonsContainer>
                      <div>
                        <PrimaryButton
                          redTheme={true}
                          type="button"
                          onClick={() => handleSubmit()}
                          disabled={isLoading || checkFormSubmitButton(values)}
                          titleLabel={t("pages.shortcuts.edit.saveChangesBtnLabel")}
                          id="save-changes-button"
                        />
                      </div>
                      <div>
                        <PrimaryButton
                          loading={false}
                          type="button"
                          onClick={() => setIsVisible(false)}
                          titleLabel={t("pages.shortcuts.edit.cancelBtnLabel")}
                          id="cancel-button"
                        />
                      </div>
                    </ButtonsContainer>
                  </form>
                );
              }}
            />
          </DrawerContainer>
          <DrawerOverlay onClick={() => setIsVisible(false)} className="overlay" isVisible={isVisible} />
        </>
      )}
    </div>
  );
};

export default ShortcutDrawer;

const slideIn = keyframes`
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }

  `;

const slideOut = keyframes`
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(100%);
    }
  `;

const slideInAnimation = css`
  animation: ${slideIn} 0.25s ease-out forwards;
`;

const slideOutAnimation = css`
  animation: ${slideOut} 0.25s ease-out forwards;
`;

const Label = styled("div")<{ required?: boolean }>`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
  margin-bottom: 8px;
  min-height: 21px;
  text-align: left;

  ${props =>
    props.required
      ? `
::after {
    content: ' *';
    font-family: Vodafone Rg;
    font-size: 16px;
    font-weight: normal;
    color: ${props.theme.palette.errorRed};
}
`
      : ""}
`;

const DrawerContainer = styled.div<{ isVisible: boolean }>`
  overflow: auto;
  z-index: 11;
  position: fixed;
  top: 0;
  right: 0;
  width: 718px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  height: 100%;
  padding: 67px 75px;
  left: calc(100% - 711px);
  display: flex;
  flex-direction: column;
  ${props => (props.isVisible ? slideInAnimation : slideOutAnimation)};
`;

const DrawerOverlay = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: black;
  opacity: 0.4;
  display: ${props => (props.isVisible ? "flex" : "none")};
`;

export const FormSectionRow = styled("div")<{ hide?: boolean }>`
  display: ${props => (props.hide ? "none" : "flex")};
  flex-direction: column;
  padding-top: 0px;
  padding-bottom: 26px;
`;

export const SectionColumn = styled("div")`
  flex: 1;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  flex-direction: row-reverse;
  margin-top: auto;
  padding-bottom: 12px;

  > div {
    width: "fit-content";
    margin-right: 16px;

    :first-of-type {
      margin-right: 12px;
    }
  }
`;

const IconBack = styled(IconContainer)`
  display: flex;
  width: 16px;
  justify-content: flex-end;
  width: 100%;
  svg {
    transform: rotate(90deg);
  }
`;

const ModalTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 56px;
  margin-left: 16px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const IconPickerContainer = styled("div")`
  > button {
    > div {
      width: -webkit-fill-available;
    }
  }
`;

const ErrorMessageLabel = styled("span")`
  font-family: Vodafone Rg;
  font-size: 14px;
  color: #ff0000;
  margin-top: 4px;
`;
