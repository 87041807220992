import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import styled from "styled-components";

export interface IDoughnutChartDataProps {
  label: string;
  data: number;
  backgroundColor: string;
  borderColor?: string;
}

export interface IDoughnutChartLegendProps {
  label: string;
  description: string;
  descriptionColor?: string;
  extraItemStyle?: React.CSSProperties;
}

export interface IDoughnutChartProps {
  title?: string;
  showTitle?: boolean;
  dataLabel?: string;
  chartData: IDoughnutChartDataProps[];
  borderWidth?: number;
  borderRadius?: number;
  centerText?: string | JSX.Element;
  chartWidth?: string;
  chartHeight?: string;
  chartCutOut?: string;
  chartExtraStyle?: React.CSSProperties;
  chartLegendData?: IDoughnutChartLegendProps[];
  chartLegendExtraStyle?: React.CSSProperties;
  enableTooltip?: boolean;
  chartWrapperExtraStyle?: React.CSSProperties;
}

/**
 * Doughnut chart component
 * @returns Doughnut chart
 */
const DoughnutChart = ({
  title,
  dataLabel,
  chartData,
  centerText,
  chartExtraStyle,
  chartLegendData,
  chartLegendExtraStyle,
  chartWrapperExtraStyle,
  showTitle = false,
  borderWidth = 10,
  borderRadius = 10,
  chartHeight = "200px",
  chartWidth = "200px",
  chartCutOut = "50%",
  enableTooltip = false,
}: IDoughnutChartProps) => {
  ChartJS.register(ArcElement, Title, Tooltip, Legend);

  //Define chart labels
  const labels = chartData.map(e => e.label);
  //Define chart values
  const values = chartData.map(e => e.data);
  //Define chart background colors
  const backgroundColor = chartData.map(e => e.backgroundColor);
  //Define chart border colors
  const borderColor = chartData.map(e => (e.borderColor ? e.borderColor : "white"));

  const data = {
    labels: labels,
    datasets: [
      {
        label: dataLabel,
        data: values,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        hoverBorderColor: borderColor,
        borderWidth: borderWidth,
        borderRadius: borderRadius,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    cutout: chartCutOut,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: showTitle,
        text: title,
      },
      tooltip: {
        caretSize: 0,
        backgroundColor: "#222",
        cornerRadius: 6,
        titleFont: {
          family: "Vodafone RgBd",
        },
        bodyFont: {
          family: "Vodafone Rg",
        },
        footerFont: {
          family: "Vodafone Rg",
        },
        displayColors: false,
        enabled: enableTooltip,
      },
    },
  };

  return (
    <>
      <ChartWrapper style={chartWrapperExtraStyle}>
        <div
          style={{
            ...chartExtraStyle,
            width: chartWidth,
            height: chartHeight,
          }}
        >
          <Doughnut data={data} options={options} />
          {centerText && <CenterText>{centerText}</CenterText>}
        </div>
        {chartLegendData && (
          <ChartLegend style={chartLegendExtraStyle}>
            {chartLegendData.map(e => (
              <ChartLegendItem style={e.extraItemStyle}>
                <ChartLegendItemTitle>{e.label}</ChartLegendItemTitle>
                <ChartLegendItemValue color={e.descriptionColor || ""}>{e.description}</ChartLegendItemValue>
              </ChartLegendItem>
            ))}
          </ChartLegend>
        )}
      </ChartWrapper>
    </>
  );
};

export default DoughnutChart;

const CenterText = styled("div")`
  position: relative;
  width: 50%;
  max-height: 30%;
  top: -50%;
  left: 50%;
  text-align: center;
  font-family: Vodafone Rg;
  transform: translate(-50%, -50%);
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: -1;
`;

const ChartWrapper = styled("div")`
  display: flex;
`;
const ChartLegend = styled("div")`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: center;
`;
const ChartLegendItem = styled("div")`
  border: 1px solid ${props => props.theme.palette.lightGrey};
  width: 100%;
  height: fit-content;
  padding: 8px;
  border-radius: 6px;
`;
const ChartLegendItemTitle = styled("p")`
  margin: 0;
  margin-bottom: 4px;
  font-family: Vodafone Rg;
  font-size: 16px;
  line-height: 18px;
  color: ${props => props.theme.palette.anthracite};
`;
const ChartLegendItemValue = styled("p")<{ color?: string }>`
  margin: 0;
  font-family: Vodafone Rg;
  font-size: 16px;
  line-height: 18px;
  color: ${props => (props.color ? props.color : props.theme.palette.anthracite)};
`;
