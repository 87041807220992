import React from "react";
import styled from "styled-components";
import { ProgressBar, PrimaryButton } from "@wit/mpesa-ui-components";

interface ICampaignProgressAndSubmitProps {
  step: number;
  totalOfSteps: number;
  onSubmitFn: () => void;
  buttonLabel: string;
  stepTitle: string;
  stepDescription: string;
  setStep: (step: number) => void;
}

/**
 * CampaignProgressAndSubmit component
 */
const CampaignProgressAndSubmit = ({
  step,
  totalOfSteps,
  onSubmitFn,
  stepTitle,
  stepDescription,
  buttonLabel,
  setStep,
}: ICampaignProgressAndSubmitProps) => {
  return (
    <StepSection>
      <DividerInfo>
        <DetailTitle>{stepTitle}</DetailTitle>
        <DetailSteps>{stepDescription}</DetailSteps>
      </DividerInfo>
      <ProgressBarInfo>
        <div>
          <ProgressBar step={step} totalOfSteps={totalOfSteps} canClickPreviousSteps={true} onClickStep={setStep} />
        </div>
      </ProgressBarInfo>
      <ButtonSection>
        <ButtonInfo size={25}>
          <PrimaryButton
            titleLabel={buttonLabel}
            redTheme={true}
            type="submit"
            onClick={onSubmitFn}
            id={"submit-button"}
          />
        </ButtonInfo>
      </ButtonSection>
    </StepSection>
  );
};

export default CampaignProgressAndSubmit;

const StepSection = styled("div")`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 28px;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
`;

const DividerInfo = styled("div")``;
const ProgressBarInfo = styled("div")`
  > div {
    width: 350px;
    > div:nth-child(2) {
      background: ${props => props.theme.palette.vodafoneRed};
      opacity: 0.5;
    }
    > div:last-child {
      .erXWRt {
        background-color: ${props => props.theme.palette.vodafoneRed};
      }
      > div > div > div {
        background-color: ${props => props.theme.palette.vodafoneRed};
      }
    }
  }
`;

const DetailTitle = styled("div")`
  font-family: Vodafone RgBd;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 0.5vh;
`;
const DetailSteps = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: ${props => props.theme.palette.platinum};
`;

export const ButtonSection = styled("div")`
  width: 118px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ButtonInfo = styled("div")<{ size: number }>`
  min-width: max-content;
`;
