import {
  AutoClosingDropdown,
  DateRangeDropdown,
  RecurringIcon,
  SearchBar,
  SmallButton,
  UndoIcon,
} from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { FilterFuncts } from "@wit/mpesa-ui-components/lib/hooks/use-filters.hook";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { RatioContainer, Row } from "../../../shared/shared.styled";
import { IChargeProfile } from "../../configurations/rate-cards/rate-cards.model";
import { onEnterPressed } from "../../../shared/shared.utils";
import { OperationEnum, SectionsEnum } from "../auditing.model";
import { getRolesWithClearFilter } from "../../../shared/shared.utils";
import moment from "moment";

export enum AuditingLogsFilterKeys {
  SEARCH = "search",
  UPDATE_TYPE = "updateType",
  ENTITY = "entity",
  START_DATE = "startDate",
  END_DATE = "endDate",
  USER_ROLE = "role",
}

interface IAuditingLogsFiltersInterface extends FilterFuncts {
  count: number;
  refreshResults: () => void;
}

const AuditingLogsFilters = ({
  filters,
  clearFilter,
  isFilterActive,
  resetFilters,
  updateFilter,
  updateMultipleFilters,
  count,
  refreshResults,
}: IAuditingLogsFiltersInterface) => {
  const [t] = useTranslation();

  const dateRangeTranslations = {
    dropdownPlaceholder: t("pages.auditing.filters.updateDate"),
    clearFilters: t("pages.auditing.filters.clearFilter"),
    CUSTOM: t("pages.auditing.filters.dateRange"),
  };

  /**
   * List of sections dropdown options
   * @returns {{label: string, key: SectionsEnum | null}[]}
   */
  const getSectionsOptions = () => {
    const options: { label: string; key: SectionsEnum | null }[] = Object.values(SectionsEnum)
      .map(section => {
        return {
          label: t(`pages.auditing.sections.${section}`),
          key: section,
        };
      })
      .sort((a, b) => (a.label ? a.label.localeCompare(b.label) : -1));
    options.push({
      label: i18next.t(`pages.auditing.filters.clearFilter`),
      key: null,
    });
    return options;
  };

  /**
   * List of operations dropdown options
   * @returns {{label: string, key: OperationEnum | null}[]}
   */
  const getOperationsOptions = () => {
    const options: { label: string; key: OperationEnum | null }[] = Object.values(OperationEnum)
      .map(status => {
        return {
          label: t(`pages.auditing.operations.${status}`),
          key: status,
        };
      })
      .sort((a, b) => (a.label ? a.label.localeCompare(b.label) : -1));
    options.push({
      label: i18next.t(`pages.auditing.filters.clearFilter`),
      key: null,
    });
    return options;
  };

  return (
    <>
      <CustomRow>
        <RatioContainer ratio={3 / 12}>
          <SearchBar
            onKeyDown={e => {
              onEnterPressed(e, refreshResults);
            }}
            value={
              filters.has(AuditingLogsFilterKeys.SEARCH)
                ? (filters.get(AuditingLogsFilterKeys.SEARCH) as string[])[0]
                : ""
            }
            onChange={e => updateFilter(AuditingLogsFilterKeys.SEARCH, e.target.value, true)}
            clearValue={() => clearFilter(AuditingLogsFilterKeys.SEARCH)}
            placeholderLabel={t("pages.auditing.filters.search")}
          />
        </RatioContainer>
        <RatioContainer ratio={2 / 12}>
          <DateRangeDropdown
            translations={dateRangeTranslations}
            filters={filters}
            isFilterActive={isFilterActive}
            clearFilter={clearFilter}
            firstEnabledDate={moment().subtract(6, "month")}
            updateFilter={updateFilter}
            updateMultipleFilters={updateMultipleFilters}
          />
        </RatioContainer>
        <RatioContainer ratio={2 / 12}>
          <DropdownWrapper>
            <AutoClosingDropdown
              options={getRolesWithClearFilter(t)}
              selectOption={opt => {
                if (opt.key) {
                  updateFilter(AuditingLogsFilterKeys.USER_ROLE, opt.key, true);
                } else {
                  clearFilter(AuditingLogsFilterKeys.USER_ROLE);
                }
              }}
              hasValue={!!filters.get(AuditingLogsFilterKeys.USER_ROLE)}
              label={
                filters.get(AuditingLogsFilterKeys.USER_ROLE)
                  ? t(`userRoles.${(filters.get(AuditingLogsFilterKeys.USER_ROLE) as string[])[0]}`)
                  : t("pages.auditing.filters.userRole")
              }
            />
          </DropdownWrapper>
        </RatioContainer>
        <RatioContainer ratio={2 / 12}>
          <DropdownWrapper>
            <AutoClosingDropdown
              options={getOperationsOptions()}
              selectOption={opt => {
                if (opt.key) {
                  updateFilter(AuditingLogsFilterKeys.UPDATE_TYPE, opt.key, true);
                } else {
                  clearFilter(AuditingLogsFilterKeys.UPDATE_TYPE);
                }
              }}
              hasValue={!!filters.get(AuditingLogsFilterKeys.UPDATE_TYPE)}
              label={
                filters.get(AuditingLogsFilterKeys.UPDATE_TYPE)
                  ? t(`pages.auditing.operations.${(filters.get(AuditingLogsFilterKeys.UPDATE_TYPE) as string[])[0]}`)
                  : t("pages.auditing.filters.updateType")
              }
            />
          </DropdownWrapper>
        </RatioContainer>
        <RatioContainer ratio={2 / 12}>
          <DropdownWrapper>
            <AutoClosingDropdown
              options={getSectionsOptions()}
              selectOption={opt => {
                if (opt.key) {
                  updateFilter(AuditingLogsFilterKeys.ENTITY, opt.key, true);
                } else {
                  clearFilter(AuditingLogsFilterKeys.ENTITY);
                }
              }}
              hasValue={!!filters.get(AuditingLogsFilterKeys.ENTITY)}
              label={
                filters.get(AuditingLogsFilterKeys.ENTITY)
                  ? t(`pages.auditing.sections.${(filters.get(AuditingLogsFilterKeys.ENTITY) as string[])[0]}`)
                  : t("pages.auditing.filters.section")
              }
            />
          </DropdownWrapper>
        </RatioContainer>
      </CustomRow>
      <Row style={{ marginBottom: 12 }}>
        <AuditingLogsCount>
          <span>{count}</span>
          &nbsp;
          {`${t("pages.support.gatewayLogs.recordsFound")}`}
        </AuditingLogsCount>
        <div style={{ marginRight: 24 }}>
          <SmallButton
            titleLabel={t("pages.support.gatewayLogs.refreshResults")}
            onClick={() => refreshResults()}
            iconComponent={
              <SmallButtonIconContainer color={styleTheme.palette.turquoiseBlue}>
                <RecurringIcon />
              </SmallButtonIconContainer>
            }
          />
        </div>
        <div>
          <SmallButton
            titleLabel={t("pages.support.gatewayLogs.clearFilters")}
            onClick={resetFilters}
            iconComponent={
              <SmallButtonIconContainer color={styleTheme.palette.vodafoneRed}>
                <UndoIcon />
              </SmallButtonIconContainer>
            }
          />
        </div>
      </Row>
    </>
  );
};

export default AuditingLogsFilters;

const DropdownWrapper = styled("div")`
  > button {
    > div {
      min-width: -webkit-fill-available !important;
    }
  }
`;

const CustomRow = styled(Row)`
  margin-bottom: 18px;

  > ${RatioContainer} {
    margin-right: 24px;

    :last-of-type {
      margin-right: 0;
    }
  }
`;

const AuditingLogsCount = styled("div")`
  display: flex;
  flex-direction: row;
  margin-right: 24px;
  font-family: Vodafone Rg;
  font-size: 14px;
  color: ${props => props.theme.palette.midGrey};

  > span {
    font-weight: bold;
  }
`;

const SmallButtonIconContainer = styled("div")<{ color: string }>`
  width: 16px;
  height: 16px;
  stroke: ${props => props.color};

  svg {
    width: 16px;
    height: 16px;
    stroke: ${props => props.color};
  }
`;
