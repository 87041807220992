import { IDocBulkCreateRequest, IDocBulksRequest } from "./doc-bulk.interface";
import ApiClient, { updateRequestIdHeader } from "../../../configs/axios.kyc.config";
import qs from "qs";

const DocBulkApi = {
  routes: {
    createBulk: "/registration/bulk/export",
    bulksList: "/registration/bulk",
  },

  methods: {
    createBulk: (params: IDocBulkCreateRequest, username: string): Promise<any> => {
      const config = {
        headers: {
          operator: username,
        },
      };

      updateRequestIdHeader();
      return ApiClient.post(DocBulkApi.routes.createBulk, params, config);
    },

    getBulks: (params: IDocBulksRequest): Promise<any> => {
      // Pages index are 0 (zero) based in the services
      const paramsClone = { ...params };
      paramsClone.page = paramsClone.page - 1;

      const requestConfig = {
        params: paramsClone,
        paramsSerializer: function(innerParams: IDocBulksRequest) {
          return qs.stringify(innerParams, { arrayFormat: "comma" });
        },
      };

      updateRequestIdHeader();
      return ApiClient.get(DocBulkApi.routes.bulksList, requestConfig);
    },
  },
};

export default DocBulkApi;
