import { AntExternalServicePermitionsEnum } from "../../../../shared/models/ant-service-manager.model";

export enum AntPermissionsRoleEnum {
  PIN = "PIN",
  IDENTITY = "IDENTITY",
}

export interface ISingleAntExternalServiceRequest {
  externalServiceId: string;
  url: string;
  permissions: AntPermissionsRoleEnum[];
  rsaPublicKey?: string;
}

export interface ICreateAntExternalServiceRequest {
  externalService: Array<ISingleAntExternalServiceRequest>;
}

export interface IUpdateAntExternalServiceRequest {
  externalServiceId: string;
  url: string;
  permissions: AntExternalServicePermitionsEnum[];
  rsaPublicKey?: string;
}

export interface IUpdateAntExternalServiceResponse {
  externalServiceId: string;
  url: string;
  permissions: AntExternalServicePermitionsEnum[];
}
