import { DownloadIcon, PrimaryButton, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled, { ThemeContext } from "styled-components";
import { IconContainer, Row } from "../../../../../shared/shared.styled";
import SurveysApi, { ISurvey } from "../../surveys.api";
import { SurveysActions } from "../../surveys.store";

interface IPublishSurveyModalProps {
  survey: ISurvey;
  hideModal: () => void;
}

const PublishSurveyModal = ({ survey, hideModal }: IPublishSurveyModalProps) => {
  const [t] = useTranslation();
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = React.useState(false);
  const [showAlert, , setAlertProps] = useAlert();

  const publishSurvey = () => {
    setIsLoading(true);
    SurveysApi.methods.publishSurvey(survey.id).then(
      () => {
        SurveysApi.methods
          .getSurveys()
          .finally(() => setIsLoading(false))
          .then(res => {
            dispatch(SurveysActions.creators.fetchSurveysSuccessAction(res.data));
            setIsLoading(false);
            setAlertProps({
              title: t("pages.tools.surveys.modal.publish.success"),
              type: AlertTypeEnum.SUCCESS,
            });
            showAlert();
            hideModal();
          });
      },
      () => {
        setIsLoading(false);
        setAlertProps({
          title: t("pages.tools.surveys.modal.publish.error"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  return (
    <ModalContainer>
      <ModalTitle>{t("pages.tools.surveys.modal.publish.title")}</ModalTitle>
      <ModalDescription>
        {t("pages.tools.surveys.modal.publish.description", {
          audience: survey.entries,
        })}
      </ModalDescription>

      <FileNameContainer>
        <IconContainer size={16} color={themeContext.palette.vodafoneRed}>
          <DownloadIcon />
        </IconContainer>
        <FileNameText>{survey.fileName}</FileNameText>
        <FileSizeText>{survey.fileSize}</FileSizeText>
      </FileNameContainer>

      <Row style={{ justifyContent: "flex-end", marginTop: "50px" }}>
        <div style={{ marginRight: "12px" }}>
          <PrimaryButton
            disabled={isLoading}
            onClick={hideModal}
            titleLabel={t("pages.tools.surveys.modal.publish.cancel")}
          />
        </div>
        <div>
          <PrimaryButton
            redTheme={true}
            type="submit"
            onClick={publishSurvey}
            disabled={isLoading}
            titleLabel={t("pages.tools.surveys.modal.publish.publish")}
          />
        </div>
      </Row>
    </ModalContainer>
  );
};

export default PublishSurveyModal;

const ModalContainer = styled("div")`
  border-radius: 6px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  padding: 24px;
`;

const ModalTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 24px;
`;

const ModalDescription = styled("div")`
  font-family: Vodafone Rg;
  font-size: 18px;
  color: ${props => props.theme.palette.black};
`;

const FileNameContainer = styled("div")`
  margin-top: 20px;
  display: flex;
`;

const FileSizeText = styled("span")`
  color: ${props => props.theme.palette.grey};
  font-family: Vodafone Rg;
  font-size: 16px;
  margin-left: 3px;
`;

const FileNameText = styled("span")`
  color: ${props => props.theme.palette.grey};
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.greyDarkest};
  margin-left: 8px;
`;
