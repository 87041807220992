import React from "react";
import { PageContainer, PageTitle } from "../../../shared/shared.styled";
import { Tabs, TabChild } from "@wit/mpesa-ui-components";
import styled from "styled-components";
import ServiceManagerList from "../service-list/service-list.page";
import SectionManager from "../section-manager/section-manager.page";
import ExternalServices from "../external-services/external-services.page";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CategoriesPage from "./categories/categories.page";
import AllowedScopesPage from "../allowed-scopes/allowed-scopes.page";

export enum ServiceManagerTabNumber {
  SERVICE_LIST = 0,
  SERVICE_MANAGER = 1,
  EXTERNAL_SERVICES = 2,
  CATEGORIES = 3,
  SSO = 4,
}

/**
 * ServiceManager page
 */
const ServiceManagerPage = () => {
  const [t] = useTranslation();
  const query = new URLSearchParams(useLocation().search);
  const [tabIndex, setTabIdx] = React.useState(query.get("tabIdx") || ServiceManagerTabNumber.SERVICE_LIST);

  return (
    <PageContainer>
      <PageTitle>{t("pages.serviceManager.title")}</PageTitle>
      <TabsContainer>
        <Tabs
          controlledSelectedTab={Number(tabIndex) || ServiceManagerTabNumber.SERVICE_LIST}
          controlledSetSelectedTab={setTabIdx}
          isResponsive={true}
        >
          <TabChild label={t("pages.serviceManager.tabs.serviceList")}>
            <ServiceManagerList />
          </TabChild>
          <TabChild label={t("pages.serviceManager.tabs.sectionManager")}>
            <SectionManager />
          </TabChild>
          <TabChild label={t("pages.serviceManager.tabs.externalServices")}>
            <ExternalServices />
          </TabChild>
          <TabChild label={t("pages.serviceManager.tabs.categories")}>
            <CategoriesPage />
          </TabChild>
          <TabChild label={t("pages.serviceManager.tabs.allowedScopes")}>
            <AllowedScopesPage />
          </TabChild>
        </Tabs>
      </TabsContainer>
    </PageContainer>
  );
};

export default ServiceManagerPage;

const TabsContainer = styled("div")`
  margin-top: 43px;
`;
