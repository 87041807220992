import { produce } from "immer";
import { IPayloadAction } from "../../../shared/shared.interfaces";
import { IClientDocBulk } from "../doc-bulk/doc-bulk.model";
import { IDocBulkEntry } from "../doc-bulk/doc-bulk.interface";
import { convertDocBulkEntryToModel } from "../doc-bulk/doc-bulk.store";

export const DocBulkDetailsActions = {
  types: {
    FETCH_BULK_DETAILS_SUCCESS: "FETCH_BULK_DETAILS_SUCCESS",
  },
  creators: {
    fetchBulksSuccessAction: (bulk?: IDocBulkEntry) => ({
      type: DocBulkDetailsActions.types.FETCH_BULK_DETAILS_SUCCESS,
      payload: {
        bulk,
      },
    }),
  },
};

export interface IDocBulkDetailsReducerInterface {
  bulkDetails: IClientDocBulk | null;
}

const bulkDetailsInitialState: IDocBulkDetailsReducerInterface = {
  bulkDetails: null,
};

export const docBulkDetailsReducer = produce((draft: IDocBulkDetailsReducerInterface, action: IPayloadAction<any>) => {
  switch (action.type) {
    case DocBulkDetailsActions.types.FETCH_BULK_DETAILS_SUCCESS:
      draft.bulkDetails = convertDocBulkEntryToModel(action.payload.bulk);
      return draft;
    default:
      return draft;
  }
}, bulkDetailsInitialState);
