import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import styled, { css } from "styled-components";

export const MainContent = styled("div")`
  display: flex;
  flex-direction: column;
  width: 60%;
`;

export const LeftContent = styled("div")`
  display: flex;
  flex-direction: column;
  width: 40%;
`;

export const ImageContainer = styled("div")<{ backgroundImage: string }>`
position: relative;
width: 250px;
height: 160px;
border: 1px solid #ebebeb;
border-radius: 10px;
background-image: url("data:image/jpeg;base64,${props => props.backgroundImage}");
background-size: cover;
background-position: center;
background-repeat: no-repeat;
`;

export const LeftFormDiv = styled("div")`
  width: 90%;
  @media (max-width: 1024px) {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }
  > div {
    :first-child {
      > div > div {
        :first-child {
          max-width: 30%;
        }
        :last-child {
          max-width: 70%;
        }
      }
    }
  }
`;

export const LinkContainer = styled("div")<{ disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-family: Vodafone Rg;
  color: ${styleTheme.palette.darkGrey};
  font-size: 16px;
  margin-bottom: 15px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? "none" : "inherit")};
  width: fit-content;

  > div {
    margin-right: 8px;
  }
`;

export const IconContainer = styled("div")<{ color: string }>`
  width: 20px;
  height: 20px;
  > svg {
    stroke: ${props => props.color};
  }
`;

export const RightFormDiv = styled("div")`
  margin-top: 55px;
`;

export const EditServiceFormRight = styled("div")`
  width: 250px;
  overflow: hidden;
  min-width: 200px;
`;

export const UserStatus = styled("div")<{ color: any }>`
  font-family: Vodafone Rg;
  color: ${props => props.color};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
`;

export const DescriptionContainer = styled("span")`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  hyphens: auto;
`;

export const ColorContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ColorValue = styled("span")`
  font-family: Vodafone Rg;
`;

export const Value = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  margin-top: -12px;
`;
export const ValueContainer = styled("div")`
  position: relative;
`;

export const AvatarContainer = styled("div")`
  position: relative;
  width: 250px;
  height: 160px;
  border: 1px solid #ebebeb;
  background-color: #eaf7ff;
  border-radius: 10px;
`;

export const AvatarDiv = styled("div")<{ backgroundImage: string }>`
border-radius: 50%;
width: 128px;
height: 128px;
border: 1px solid ${props => props.theme.palette.aluminium};
overflow: hidden;
background-image: url("data:image/jpeg;base64,${props => props.backgroundImage}");
background-size: cover;
background-position: center;
background-repeat: no-repeat;
margin: auto;
margin-top: 16px;
`;

export const Separator = styled("div")`
  margin-top: 24px;
`;

export const ServiceColor = styled("div")<{ color: any }>`
  width: 40px;
  height: 36px;
  border-radius: 6px;
  background-color: ${props => props.color};
  margin-left: 16px;
  border: 1px solid ${props => props.theme.palette.aluminium};
`;

export const DropzoneTitle = styled("div")<{ mandatory: boolean }>`
  min-height: 21px;
  font-family: Vodafone Rg;
  font-weight: bold;
  line-height: 21px;
  font-size: 16px;
  color: #999999;
  margin-bottom: 8px;
  width: fit-content;
  display: inline-flex;
  ${props =>
    props.mandatory ? "::after {content: ' *'; color: #ff0000; font-weight: 400; padding-left: 2px;}" : null};
`;

export const Actions = styled("div")`
  margin-top: 40px;
  @media (max-width: 1024px) {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  button {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  button:not(:last-of-type) {
    margin-bottom: 12px;
  }

  button:first-of-type {
    svg {
      stroke: ${props => props.theme.palette.secondary.blueLight};
    }
  }

  button:nth-of-type(2) {
    svg {
      stroke: ${props => props.theme.palette.midGrey};
    }
  }

  button:nth-of-type(3) {
    svg {
      stroke: ${props => props.theme.palette.vodafoneRed};
    }
  }
`;

export const ImageError = styled("div")`
  width: fit-content;
  display: inline-flex;
  height: 19px;
  color: #ff0000;
  font-family: Vodafone Rg;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  float: right;
  font-weight: 400;
`;

export const HelpIconContainer = styled("div")`
  margin-top: -3px;
`;

export const ToogleDiv = styled("div")<{ isEditing: boolean }>`
  ${props => (!props.isEditing ? "label{background-color: white;opacity: 0.5;}" : null)};
`;
export const TabsContainer = styled("div")`
  display: flex;
  margin-top: 33px;
  flex-direction: column;
  row-gap: 16px;
`;

export const TabContentContainer = styled("div")`
  :first-child > :first-child > :first-child {
    display: none;
  }
`;

export const NonEditableText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${styleTheme.palette.darkGrey};
`;
export const ButtonsWrapper = styled("div")`
  margin-top: auto;
  width: 100%;
  display: flex;
  margin-bottom: 10px;
`;
export const ButtonDiv = styled("div")`
  width: 200px;
  margin-right: 20px;
`;
export const TextAreaWrapper = styled("div")<{ mandatory: boolean }>`
  > div > textarea {
    color: #333333 !important;
  }
  ${({ mandatory }) =>
    mandatory &&
    css`
      > div > span > span:first-of-type:after {
        content: " *";
        width: 7px;
        color: #ff0000;
        font-family: Vodafone Rg;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
      }
    `};
`;
export const TextAreaErrorHandling = styled("div")`
  font-family: Vodafone Rg;
  font-size: 14px;
  color: #ff0000;
  margin-top: 4px;
`;
