import produce from "immer";
import { IPayloadAction } from "../../../shared/shared.interfaces";
import { AuthenticationActions } from "../../authentication/authentication.store";
import { IExternalServices } from "../../../shared/models/ant-service-manager.model";
import { IUpdateAntExternalServiceRequest } from "./external-services-add/ant-external-service-add.interface";

export interface IAntExternalServicesReducerInterface {
  antExternalServices: IExternalServices[];
  isLoadingAntExternalServices: boolean;
}

const antExternalInitialState: IAntExternalServicesReducerInterface = {
  antExternalServices: [],
  isLoadingAntExternalServices: false,
};

export const AntExternalServicesActions = {
  types: {
    FETCHING_ANT_EXTERNAL_SERVICES: "FETCHING_ANT_EXTERNAL_SERVICES",
    FETCH_ANT_EXTERNAL_SERVICES_SUCCESS: "FETCH_ANT_EXTERNAL_SERVICES_SUCCESS",
    FETCH_ANT_EXTERNAL_SERVICES_ERROR: "FETCH_ANT_EXTERNAL_SERVICES_ERROR",
    FETCH_ANT_EXTERNAL_SERVICES_LEAVE: "FETCH_ANT_EXTERNAL_SERVICES_LEAVE",
    ADD_ANT_EXTERNAL_SERVICE: "ADD_ANT_EXTERNAL_SERVICE",
    REMOVE_ANT_EXTERNAL_SERVICE: "REMOVE_ANT_EXTERNAL_SERVICE",
    EDIT_ANT_EXTERNAL_SERVICE: "EDIT_ANT_EXTERNAL_SERVICE",
  },
  creators: {
    fetchingAntExternalServicesAction: () => ({
      type: AntExternalServicesActions.types.FETCHING_ANT_EXTERNAL_SERVICES,
    }),
    fetchAntExternalServicesSuccessAction: (antExternalServices: IExternalServices[]) => ({
      type: AntExternalServicesActions.types.FETCH_ANT_EXTERNAL_SERVICES_SUCCESS,
      payload: {
        antExternalServices,
      },
    }),
    fetchAntExternalServicesLeaveAction: () => ({
      type: AntExternalServicesActions.types.FETCH_ANT_EXTERNAL_SERVICES_ERROR,
    }),
    editAntExternalServiceAction: (values: IUpdateAntExternalServiceRequest) => ({
      type: AntExternalServicesActions.types.EDIT_ANT_EXTERNAL_SERVICE,
      payload: {
        values,
      },
    }),
  },
};

export const antExternalServicesReducer = produce(
  (draft: IAntExternalServicesReducerInterface, action: IPayloadAction<any>) => {
    switch (action.type) {
      case AntExternalServicesActions.types.FETCHING_ANT_EXTERNAL_SERVICES:
        draft.isLoadingAntExternalServices = true;
        return;
      case AntExternalServicesActions.types.FETCH_ANT_EXTERNAL_SERVICES_SUCCESS:
        draft.isLoadingAntExternalServices = false;
        draft.antExternalServices = action.payload.antExternalServices;
        return;
      case AntExternalServicesActions.types.FETCH_ANT_EXTERNAL_SERVICES_ERROR:
        draft.isLoadingAntExternalServices = false;
        return;
      case AuthenticationActions.types.LOGOUT:
        return antExternalInitialState;
      default:
        return draft;
    }
  },
  antExternalInitialState,
);
