import { produce } from "immer";
import { IPayloadAction } from "../../../shared/shared.interfaces";
import { AuthenticationActions } from "../../authentication/authentication.store";
import { IBundleType, IConfigurationFields, ITranslatableObject } from "./bundles.model";

export const BundlesActions = {
  types: {
    GET_BUNDLES_SUCCESS: "GET_BUNDLES_SUCCESS",
    ADD_BUNDLE_SUCCESS: "ADD_BUNDLE_SUCCESS",
    GET_CONFIGURATION_FIELDS: "GET_CONFIGURATION_FIELDS",
    SORT_BUNDLES_SUCCESS: "SORT_BUNDLES_SUCCESS",
  },
  creators: {
    getBundlesSuccessAction: (bundles: IBundleType[]) => ({
      type: BundlesActions.types.GET_BUNDLES_SUCCESS,
      payload: {
        bundles,
      },
    }),
    addBundleSuccessAction: (bundle: IBundleType) => ({
      type: BundlesActions.types.ADD_BUNDLE_SUCCESS,
      payload: {
        bundle,
      },
    }),
    getConfigurationFields: (configurationFields: IConfigurationFields) => ({
      type: BundlesActions.types.GET_CONFIGURATION_FIELDS,
      payload: {
        configurationFields,
      },
    }),
    sortBundlesSuccessAction: (bundles: IBundleType[]) => ({
      type: BundlesActions.types.SORT_BUNDLES_SUCCESS,
      payload: {
        bundles,
      },
    }),
  },
};
export interface IBundlesReducerInterface {
  bundles: IBundleType[];
  configurationFields: IConfigurationFields;
}

const initialState: IBundlesReducerInterface = {
  bundles: [],
  configurationFields: {} as IConfigurationFields,
};

export const bundlesReducer = produce((draft: IBundlesReducerInterface, action: IPayloadAction<any>) => {
  switch (action.type) {
    case BundlesActions.types.GET_BUNDLES_SUCCESS:
      draft.bundles = action.payload.bundles;
      return;
    case BundlesActions.types.ADD_BUNDLE_SUCCESS:
      draft.bundles = [...draft.bundles, action.payload.bundle];
      return;
    case BundlesActions.types.GET_CONFIGURATION_FIELDS:
      draft.configurationFields = action.payload.configurationFields;
      return;
    case AuthenticationActions.types.LOGOUT:
      return initialState;
    case BundlesActions.types.SORT_BUNDLES_SUCCESS:
      draft.bundles = action.payload.bundles;
      return;
    default:
      return draft;
  }
}, initialState);
