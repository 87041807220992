import {
  CancelIcon,
  CheckIcon,
  FastActionButton,
  ModalTypeEnum,
  PageTitle,
  PrimaryButton,
  TabChild,
  Tabs,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import { Breadcrumb } from "@wit/mpesa-ui-components/lib/types";
import { AxiosResponse } from "axios";
import { TFunction } from "i18next";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { IStoreInterface } from "../../../../configs/store.config";
import { RoutesEnum } from "../../../../routes/routes.constants";
import { ILanguage } from "../../../../shared/models/language.model";
import LanguagesApi from "../../../../shared/services/languages.api";
import { Column, LoadingText, PageContainer, Row } from "../../../../shared/shared.styled";
import { FastIconWrapper } from "../../networks/networks.page";
import ReferralCampaignStatusChip from "../components/referral-campaign-status-chip.component";
import ReferralCampaignsApi from "../referral-campaigns.api";
import {
  IReferralCampaign,
  IReward,
  ReceiverTypeRewardEnum,
  ReferralCampaignStatus,
  SenderTypeRewardEnum,
  TargetEnum,
  TypeScreenEnum,
} from "../referral-campaigns.model";
import { ReferralCampaignsActions } from "../referral-campaigns.store";
import { validateSchemaReceiverScreen, validateSchemaSenderScreen } from "../referral-campaigns.utils";
import { ReferralAnalyticsPageConfigs } from "../referral-campaigns-analytics/referral-campaigns-analytics.utils";
import ReferralAnalytics from "../referral-campaigns-analytics/referral-campaigns-analytics.page";
import EditCampaignDetails from "./components/edit-campaign-details.component";
import EditReceiverScreen from "./components/edit-receiver-screen.component";
import EditRewardDetails from "./components/edit-reward-details.component";
import EditSenderScreen from "./components/edit-sender-screen.component";

/**
 * method to get the breadcrumbs
 * @param t translations
 */
const getBreadcrumbs = (t: TFunction, campaignName: string): Breadcrumb[] => {
  return [
    {
      label: t("pages.referralCampaigns.title"),
      url: RoutesEnum.REFERRAL_CAMPAIGNS,
    },
    {
      label: campaignName,
      url: "",
    },
  ];
};

/**
 * EditReferralCampaign page
 */
const EditReferralCampaign = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const themeContext = React.useContext(ThemeContext);
  const [showAlert, , setAlertProps] = useAlert();
  const [tab, setTab] = React.useState(0);
  const { PAGE_SECTIONS } = ReferralAnalyticsPageConfigs();
  const [availableLanguages, setAvailableLanguages] = React.useState<ILanguage[]>();
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>("en");
  const [isChangesMade, setIsChangesMade] = React.useState(false);
  const [isEditingCampaignDetails, setIsEditingCampaignDetails] = React.useState(false);
  const [isEditingRewards, setIsEditingRewards] = React.useState(false);
  const [isEditingSender, setIsEditingSender] = React.useState(false);
  const [isEditingReceiver, setIsEditingReceiver] = React.useState(false);
  const [referralCampaign, setReferralCampaign] = React.useState(
    useSelector((store: IStoreInterface) => store.referralCampaignsReducer.referralCampaign) as IReferralCampaign,
  );
  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );

  React.useEffect(() => {
    getReferralCampaign(referralCampaign.id as string);
  }, []);

  /**
   * Sets up language toggle and the default form values
   */
  const setupLanguages = () => {
    let languages: ILanguage[];
    LanguagesApi.methods
      .getAvailableLanguages()
      .then(
        res => {
          languages = res.data.availableLanguages;
        },
        () => {
          languages = [
            { id: "1", code: "en", name: "English", urlIcon: "ic_flag_uk.png" },
            { id: "2", code: "sw", name: "Swahili (Tanzania)", urlIcon: "ic_flag_tanzania.png" },
          ];
          setAlertProps({
            type: AlertTypeEnum.ERROR,
            title: t("commons.getLanguagesError"),
          });
          showAlert();
        },
      )
      .finally(() => {
        const langCodes = languages.map((lang: ILanguage) => {
          return lang.code;
        });
        setAvailableLanguages(languages);
        setSelectedLanguage(langCodes.includes("en") ? "en" : langCodes[0]);
      });
  };

  /**
   * method to get the referral campaign details
   */
  const getReferralCampaign = (id: string) => {
    ReferralCampaignsApi.methods.getReferralCampaign(id).then(
      (res: AxiosResponse<IReferralCampaign>) => {
        res.data.startDate = moment(res.data.startDate).format("DD/MM/YYYY");
        res.data.endDate = moment(res.data.endDate).format("DD/MM/YYYY");
        setReferralCampaign(res.data);
        setupLanguages();
      },
      () => {
        setAlertProps({
          title: t("pages.referralCampaigns.getReferralCampaignsError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * method to cancel a campaign
   */
  const cancelCampaign = () => {
    setConfirmationProps({
      title: t("pages.referralCampaigns.editCampaign.cancelCampaignModal.title"),
      description: t("pages.referralCampaigns.editCampaign.cancelCampaignModal.description"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      primaryAction: () => {
        const referralForSubmit = prepareReferralForCancel({ ...referralCampaign });
        ReferralCampaignsApi.methods
          .editReferralCampaign(referralCampaign.id as string, referralForSubmit)
          .then(
            res => {
              res.data.startDate = moment(res.data.startDate).format("DD/MM/YYYY");
              res.data.endDate = moment(res.data.endDate).format("DD/MM/YYYY");
              setReferralCampaign(res.data);
              dispatch(ReferralCampaignsActions.creators.setReferralCampaign(res.data));
              setAlertProps({
                type: AlertTypeEnum.SUCCESS,
                title: t("pages.referralCampaigns.editCampaign.cancelCampaignModal.success"),
              });
              showAlert();
              turnOffEditing();
            },
            () => {
              setAlertProps({
                title: t("pages.referralCampaigns.editCampaign.cancelCampaignModal.error"),
                type: AlertTypeEnum.ERROR,
              });
              showAlert();
            },
          )
          .finally(() => {
            hideConfirmationModal();
            setTab(0);
          });
      },
      secondaryAction: () => hideConfirmationModal(),
    } as BaseModalProps);
    showConfirmationModal();
  };

  /**
   * Start campaign modal
   */
  const startCampaign = () => {
    setConfirmationProps({
      title: t("pages.referralCampaigns.editCampaign.startCampaignModal.title"),
      description: t("pages.referralCampaigns.editCampaign.startCampaignModal.description"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      primaryAction: () => {
        const referralForSubmit = prepareReferralForStart({ ...referralCampaign });
        ReferralCampaignsApi.methods
          .editReferralCampaign(referralCampaign.id as string, referralForSubmit)
          .then(
            res => {
              res.data.startDate = moment(res.data.startDate).format("DD/MM/YYYY");
              res.data.endDate = moment(res.data.endDate).format("DD/MM/YYYY");
              setReferralCampaign(res.data);
              dispatch(ReferralCampaignsActions.creators.setReferralCampaign(res.data));
              setAlertProps({
                type: AlertTypeEnum.SUCCESS,
                title: t("pages.referralCampaigns.editCampaign.startCampaignModal.success"),
              });
              showAlert();
              turnOffEditing();
            },
            err => {
              let errorMessage: string;
              switch (err.data.status.businessCode) {
                case 40043:
                  errorMessage = t("pages.referralCampaigns.editCampaign.startCampaignModal.errorDatePeriodInvalid");
                  break;
                default:
                  errorMessage = t("pages.referralCampaigns.editCampaign.startCampaignModal.error");
                  break;
              }
              setAlertProps({
                title: errorMessage,
                type: AlertTypeEnum.ERROR,
              });
              showAlert();
            },
          )
          .finally(() => {
            hideConfirmationModal();
            setTab(0);
          });
      },
      secondaryAction: () => hideConfirmationModal(),
    } as BaseModalProps);
    showConfirmationModal();
  };

  /**
   * method to verify if the saves changes is available
   */
  const onDoneFn = (values: IReferralCampaign) => {
    if (!_.isEqual(values, referralCampaign)) {
      setIsChangesMade(true);
      setReferralCampaign(values);
    }
  };

  /**
   * Deletes invalid fields on rewards before saving/editing
   * @param {IReferralCampaign} values
   * @returns {IReferralCampaign}
   */
  const removeInvalidRewardFields = (values: IReferralCampaign) => {
    values.rewards.forEach(r => {
      if (r.type === ReceiverTypeRewardEnum.NONE || r.type === SenderTypeRewardEnum.NONE) {
        delete r.maxInvites;
        delete r.amount;
        delete r.prizeCategory;
      }
    });
    return values;
  };

  /**
   * After a successful request turn off all the left over quick actions menus that are still in editing mode
   */
  const turnOffEditing = () => {
    setIsEditingCampaignDetails(false);
    setIsEditingRewards(false);
    setIsEditingReceiver(false);
    setIsEditingSender(false);
  };

  /**
   * method to save changes of campaign
   */
  const saveChanges = () => {
    setConfirmationProps({
      title: t("pages.referralCampaigns.editCampaign.saveChangesModal.title"),
      description: t("pages.referralCampaigns.editCampaign.saveChangesModal.description"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      primaryAction: () => {
        removeInvalidRewardFields(referralCampaign);
        const referralForSubmit = prepareReferralForSubmit({ ...referralCampaign });
        ReferralCampaignsApi.methods
          .editReferralCampaign(referralCampaign.id as string, referralForSubmit)
          .then(
            res => {
              res.data.startDate = moment(res.data.startDate).format("DD/MM/YYYY");
              res.data.endDate = moment(res.data.endDate).format("DD/MM/YYYY");
              setReferralCampaign(res.data);
              dispatch(ReferralCampaignsActions.creators.setReferralCampaign(res.data));
              hideConfirmationModal();
              setAlertProps({
                type: AlertTypeEnum.SUCCESS,
                title: t("pages.referralCampaigns.editCampaign.saveChangesModal.success"),
              });
              showAlert();
              setIsChangesMade(false);
              turnOffEditing();
            },
            () => {
              setAlertProps({
                title: t("pages.referralCampaigns.editCampaign.saveChangesModal.error"),
                type: AlertTypeEnum.ERROR,
              });
              showAlert();
            },
          )
          .finally(() => {
            hideConfirmationModal();
            setTab(0);
          });
      },
      secondaryAction: () => hideConfirmationModal(),
    } as BaseModalProps);
    showConfirmationModal();
  };

  /**
   * method to prepare the referral campaign for cancel a campaign
   */
  const prepareReferralForCancel = (campaign: IReferralCampaign) => {
    campaign.startDate = campaign.defaultCampaign ? undefined : moment(campaign.startDate, "DD/MM/YYYY").valueOf();
    campaign.endDate = moment().valueOf();
    campaign.creationDate = undefined;
    return campaign;
  };

  /**
   * method to prepare the referral campaign to start a campaign
   */
  const prepareReferralForStart = (campaign: IReferralCampaign) => {
    campaign.startDate = moment().valueOf();
    campaign.endDate = moment(campaign.endDate, "DD/MM/YYYY").valueOf();
    campaign.creationDate = undefined;

    return campaign;
  };

  /**
   * method to prepare the referral campaign to submit/edit a campaign
   */
  const prepareReferralForSubmit = (campaign: IReferralCampaign) => {
    if (campaign.defaultCampaign) {
      campaign.startDate = undefined;
      campaign.endDate = undefined;
    } else {
      campaign.startDate = moment(campaign.startDate, "DD/MM/YYYY").valueOf();
      campaign.endDate = moment(campaign.endDate, "DD/MM/YYYY").valueOf();
    }
    campaign.creationDate = undefined;

    return campaign;
  };

  /**
   * method to verify if campaign is ready to be saved
   * is needed to validate the fields that previously could be empty
   * is user change the receiver reward != NONE
   * is user change the sender reward != NONE
   */
  const isRewardOptionsValid = () => {
    if (
      referralCampaign.rewards &&
      referralCampaign.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) &&
      (referralCampaign.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) as IReward).type !==
        SenderTypeRewardEnum.NONE
    ) {
      if (
        (referralCampaign.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) as IReward)
          .maxInvites !== -1 &&
        !referralCampaign.screens.find(screen => screen.type === TypeScreenEnum.SENDER_FINAL)
      ) {
        setTab(2);
        setIsEditingSender(true);
        setAlertProps({
          title: t("pages.referralCampaigns.editCampaign.saveChangesModal.errorMissingFields"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      } else {
        validateSchemaSenderScreen(referralCampaign, availableLanguages as ILanguage[]).then(errors => {
          if (!_.isEmpty(errors)) {
            setTab(2);
            setIsEditingSender(true);
            setAlertProps({
              title: t("pages.referralCampaigns.editCampaign.saveChangesModal.errorMissingFields"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          } else {
            saveChanges();
          }
        });
      }
    } else if (
      referralCampaign.rewards &&
      referralCampaign.rewards.find((reward: IReward) => reward.target === TargetEnum.RECEIVER) &&
      (referralCampaign.rewards.find((reward: IReward) => reward.target === TargetEnum.RECEIVER) as IReward).type !==
        ReceiverTypeRewardEnum.NONE
    ) {
      validateSchemaReceiverScreen(referralCampaign, availableLanguages as ILanguage[]).then(errors => {
        if (!_.isEmpty(errors)) {
          setTab(3);
          setIsEditingReceiver(true);
          setAlertProps({
            title: t("pages.referralCampaigns.editCampaign.saveChangesModal.errorMissingFields"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        } else {
          saveChanges();
        }
      });
    } else {
      saveChanges();
    }
  };

  return (
    <PageContainer>
      <CustomRow>
        <PageTitle
          breadcrumbs={getBreadcrumbs(
            t,
            referralCampaign.name.translations.en
              ? referralCampaign.name.translations.en
              : Object.keys(referralCampaign.name.translations)[0],
          )}
          title={
            referralCampaign.name.translations.en
              ? referralCampaign.name.translations.en
              : Object.keys(referralCampaign.name.translations)[0]
          }
          navigateFunction={history.push}
          goBackFn={() => history.push(RoutesEnum.REFERRAL_CAMPAIGNS)}
          separatorStyle={{ display: "none" }}
          titleContainerStyle={{ marginTop: "13px", marginBottom: "34px" }}
          iconContainerStyle={{ stroke: themeContext.palette.vodafoneRed }}
        />
        <ButtonContainer>
          <PrimaryButton
            titleLabel={t("pages.referralCampaigns.editCampaign.saveChanges")}
            redTheme={true}
            style={{ width: "100%" }}
            onClick={isRewardOptionsValid}
            disabled={!isChangesMade}
            id={"save-changes-button"}
          />
        </ButtonContainer>
      </CustomRow>
      {availableLanguages && referralCampaign ? (
        <>
          <Row>
            <ColumnCustom>
              <Label>{t("pages.referralCampaigns.status")}</Label>
              <ReferralCampaignStatusChip
                style={{ marginTop: "14px", width: "max-content" }}
                status={referralCampaign.status}
                key={referralCampaign.status}
              >
                {t(`pages.referralCampaigns.table.statusEnum.${referralCampaign.status.toLocaleLowerCase()}`)}
              </ReferralCampaignStatusChip>
            </ColumnCustom>
            <ColumnCustom>
              <Label>{t("pages.referralCampaigns.createdOn")}</Label>
              <DateLabel>{moment(referralCampaign.creationDate).format("DD MMM YYYY")}</DateLabel>
            </ColumnCustom>
            <Column style={{ marginLeft: "auto", alignSelf: "start" }}>
              {referralCampaign.status === ReferralCampaignStatus.LIVE && (
                <div id={"finish-campaign"}>
                  <FastActionButton
                    label={t("pages.referralCampaigns.finishCampaign")}
                    onClick={() => {
                      cancelCampaign();
                    }}
                    iconComponent={
                      <FastIconWrapper color={styleTheme.palette.midGrey}>
                        <CancelIcon />
                      </FastIconWrapper>
                    }
                  />
                </div>
              )}
              {referralCampaign.status === ReferralCampaignStatus.SCHEDULED && (
                <div id={"start-campaign"}>
                  <FastActionButton
                    label={t("pages.referralCampaigns.startCampaign")}
                    onClick={() => {
                      startCampaign();
                    }}
                    iconComponent={
                      <FastIconWrapper color={styleTheme.palette.successGreen}>
                        <CheckIcon />
                      </FastIconWrapper>
                    }
                  />
                </div>
              )}
            </Column>
          </Row>
          <DivTabsDetails tab={tab}>
            <Tabs isSecondaryTabs={true} controlledSelectedTab={tab} controlledSetSelectedTab={setTab} key={tab}>
              <TabChild label={t("pages.referralCampaigns.createCampaign.campaignDetails.title")}>
                <hr />
                <EditCampaignDetails
                  languages={availableLanguages}
                  selectedLanguage={selectedLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  referralCampaign={referralCampaign}
                  onSubmitFn={onDoneFn}
                  onCancelCampaignFn={cancelCampaign}
                  isEditing={isEditingCampaignDetails}
                  setIsEditing={setIsEditingCampaignDetails}
                />
              </TabChild>
              <TabChild label={t("pages.referralCampaigns.createCampaign.rewardDetails.title")}>
                <hr />
                <EditRewardDetails
                  languages={availableLanguages}
                  selectedLanguage={selectedLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  referralCampaign={referralCampaign}
                  onSubmitFn={onDoneFn}
                  onCancelCampaignFn={cancelCampaign}
                  isEditing={isEditingRewards}
                  setIsEditing={setIsEditingRewards}
                />
              </TabChild>
              <TabChild label={t("pages.referralCampaigns.createCampaign.senderScreen.title")}>
                <hr />
                <EditSenderScreen
                  languages={availableLanguages}
                  selectedLanguage={selectedLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  referralCampaign={referralCampaign}
                  onSubmitFn={onDoneFn}
                  onCancelCampaignFn={cancelCampaign}
                  isEditing={isEditingSender}
                  setIsEditing={setIsEditingSender}
                />
              </TabChild>
              <TabChild label={t("pages.referralCampaigns.createCampaign.receiverScreen.title")}>
                <hr />
                <EditReceiverScreen
                  languages={availableLanguages}
                  selectedLanguage={selectedLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  referralCampaign={referralCampaign}
                  onSubmitFn={onDoneFn}
                  onCancelCampaignFn={cancelCampaign}
                  isEditing={isEditingReceiver}
                  setIsEditing={setIsEditingReceiver}
                />
              </TabChild>
              <TabChild label={t("pages.referralCampaigns.analytics.title")}>
                <hr />
                <ReferralAnalytics
                  sectionTabs={PAGE_SECTIONS}
                  referralCampaignID={Number(referralCampaign.id)}
                  referralCampaignStartDate={referralCampaign.startDate as string}
                />
              </TabChild>
            </Tabs>
          </DivTabsDetails>
        </>
      ) : (
        <LoadingText>{t("commons.loadingResults")}</LoadingText>
      )}
    </PageContainer>
  );
};

export default EditReferralCampaign;

const DivTabsDetails = styled("div")<{ tab: number }>`
  > div > div > div {
    div:nth-child(${props => props.tab + 1}) {
      border-bottom: 4px solid ${props => props.theme.palette.vodafoneRed} !important;
    }
  }

  > div > div > div > div {
    font-family: VodafoneRg;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    margin-right: 34px;
    color: ${props => props.theme.palette.darkGrey};
    z-index: 1;
    border-bottom: none;

    :hover {
      border-bottom: 4px solid ${props => props.theme.palette.vodafoneRed};
    }
  }
  > div > div:last-child {
    margin-top: -11px;
    hr {
      border: 1px solid ${props => props.theme.palette.aluminium};
    }
  }
`;

const Label = styled("span")`
  font-family: Vodafone Rg;
  font-weight: bold;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
`;

const CustomRow = styled(Row)`
  justify-content: space-between;
`;

const ColumnCustom = styled(Column)`
  width: 194px;
  height: 54px;
  margin-bottom: 40px;
  justify-content: space-between;
`;

const DateLabel = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: ${props => props.theme.palette.darkGrey};
`;

const ButtonContainer = styled("div")`
  width: 146px;
`;
