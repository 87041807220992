import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import { BackOfficeMarketsEnums } from "../shared/shared.enums";

/**
 * This function to create translations file link
 * @param lng
 * @param ns
 * @returns
 */
export const constructPath = (lng: string[], ns: string[]) => {
  const apiUrl = localStorage.getItem("baseUrl");
  if (process.env.REACT_APP_ENVIRONMENT !== "development") {
    if (ns[0] === "private") {
      return `${apiUrl}translations/static?language=${lng}`;
    }
  }
  return `${process.env.REACT_APP_ROUTER_BASE ?? ""}/locales/${lng}/${ns}.json`;
};

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    ns: "public",
    defaultNS: "public",
    lng: process.env.REACT_APP_MARKET!.includes(BackOfficeMarketsEnums.DRC_EKYC) ? "fr" : "en",
    fallbackLng: "en",
    backend: {
      loadPath: constructPath,
      customHeaders: {
        "Security-Cookie": localStorage.getItem("securityCookie"),
        "XSRF-TOKEN": localStorage.getItem("xsrfToken"),
      },
    },
    interpolation: {
      skipOnVariables: false,
    },
  });
