import { CheckIcon, TabChild, Tabs, TextInput } from "@wit/mpesa-ui-components";
import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import CustomizeTabColor from "./customize-tab-color.component";

interface IColorOptionsProps {
  tab: number;
  setTab: React.Dispatch<React.SetStateAction<number>>;
  color: string;
  setColor(field: string, value: string, shouldValidate?: boolean): void;
  textToSave: string;
  show?: boolean;
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Component to dropdown of color ou small badges
 */
const ColorOptions = ({
  tab,
  setTab,
  setColor,
  color,
  show = false,
  textToSave,
  setShow = undefined,
}: IColorOptionsProps) => {
  const swatchesColors = [
    "#47b933",
    "#2fc56d",
    "#12c59f",
    "#00c3ff",
    "#1074ff",
    "#142c52",
    "#6338eb",
    "#b138eb",
    "#ff7bac",
    "#ff2a58",
    "#ff2a2a",
    "#ff6319",
    "#ffaa0f",
    "#55461b",
    "#666666",
  ];
  const [t] = useTranslation();
  const badgesColors = swatchesColors.map((color, index) => (
    <ColorBadges key={index} backgroundColor={color} onClick={() => setColor(textToSave, color)} />
  ));
  const ref = useRef(null);

  const clickListener = useCallback(
    (e: MouseEvent) => {
      if (!(ref.current! as any).contains(e.target) && setShow !== undefined) {
        setShow(!show);
      }
    },
    [ref.current],
  );

  useEffect(() => {
    document.addEventListener("click", clickListener);
    return () => {
      document.removeEventListener("click", clickListener);
    };
  }, []);

  return (
    <ColorDropdown ref={ref}>
      <Tabs controlledSelectedTab={tab} controlledSetSelectedTab={setTab} isSecondaryTabs={true}>
        <TabChild label={t("pages.announcements.detailPage.rows.colorDropdown.swatches")}>
          <BadgesSection>{badgesColors}</BadgesSection>
        </TabChild>
        <TabChild label={t("pages.announcements.detailPage.rows.colorDropdown.customize")}>
          <CustomizeTabColor
            hexCode={color}
            onChange={(color: any) => {
              setColor(textToSave, color.hex);
            }}
          />
        </TabChild>
      </Tabs>
      <hr />
      <ColorInput>
        <div>
          <ColorBadges backgroundColor={color} />
        </div>
        <TextInput onChange={e => setColor(textToSave, e.target.value)} value={color} style={{ width: "50%" }} />
        <ColorCheck
          onClick={() => {
            setShow !== undefined && setShow(!show);
          }}
        >
          <CheckIcon />
        </ColorCheck>
      </ColorInput>
    </ColorDropdown>
  );
};

export default ColorOptions;

const ColorDropdown = styled("div")`
  min-width: 256px;
  > div > div:first-child {
    padding-top: 1vh;
    padding-left: 0.5vw;
  }
  > div > div:last-child {
    margin-top: 0;
  }
  hr {
    border-bottom: none;
    border-top: 1px solid ${props => props.theme.palette.aluminium};
  }
`;
const ColorInput = styled("div")`
  display: flex;
  flex-direction: row;
  margin: 0.5vw;
  align-items: center;

  > div {
    padding-left: 0 !important;
    padding-top: 0 !important;
    padding-right: 0.25vw;
    > div {
      margin-left: 0 !important;
    }
  }

  input {
    font-family: Vodafone Rg;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    color: ${props => props.theme.palette.midGrey};
  }
`;
const ColorBadges = styled("div")<{ backgroundColor: string }>`
  width: 32px;
  height: 32px;
  border: 1px solid ${props => props.theme.palette.aluminium};
  background-color: ${props => props.backgroundColor};
  border-radius: 50%;
  cursor: pointer;
`;

const ColorCheck = styled("a")`
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin-left: auto;
  svg {
    stroke: ${props => props.theme.palette.vodafoneRed};
    width: 25px;
    height: 25px;
  }
`;

const BadgesSection = styled("div")`
  border-top: 1px solid ${props => props.theme.palette.aluminium};
  padding: 0.5vw 0.5vw 0.5vw 0.5vw;
  display: flex;
  flex-wrap: wrap;
  > div {
    margin: 2px;
    flex-grow: 0;
  }
`;
