export interface ITranslatableObject {
  key: string;
  translations: any;
}

export interface IConfigurationFields {
  frequency: ITranslatableObject[];
  plan: ITranslatableObject[];
}

export enum BundleTypeProvider {
  MPESA = "MPESA",
  J4U = "J4U",
  MIDDLEWARE = "MIDDLEWARE",
}

export enum BundleSubTypeProvider {
  J4U = "J4U_INTERACTION_DATA",
  MIDDLEWARE = "MW_INTERACTION_DATA",
}

export enum BundleTypeStatusEnum {
  LIVE = "LIVE",
  DISABLED = "DISABLED",
  EDIT = "EDIT",
}

export interface IBundleType {
  id: string;
  description?: string;
  lastUpdate: number;
  name: ITranslatableObject;
  priority: number;
  provider: BundleTypeProvider;
  upsellPromo: boolean;
  buyForFriend: boolean;
  status: BundleTypeStatusEnum;
  bundlesEnabled: number;
  hasUnpublishedChanges: boolean;
  hasPreviousVersion: boolean;
  items?: ISubBundle[] | IMiddlewareSubBundle[];
  isExternalProvider?: boolean;
}

export interface ISubBundle {
  id: number;
  cost: string;
  content: {
    type: "BUNDLE_PLAN" | "BUNDLE_DESCRIPTION";
    sms: ITranslatableObject;
    data: ITranslatableObject;
    calls: ITranslatableObject;
  };
  currency: string;
  frequency: string;
  network: string;
  priority: number;
  recipientType: "SELF" | "OTHERS" | "ANY";
  shortcode: string;
  status: SubBundleStatus;
  livePublished: boolean;
  dataAllNet: number;
  smsAllNet: number;
  voiceAllNet: number;
  voiceOnNet: number;
  identifierCode?: string;
}

export interface IMiddlewareSubBundle {
  id: number;
  status: SubBundleStatus;
  recipientType: "SELF" | "OTHERS" | "ANY";
  name: ITranslatableObject;
  priority: number;
  livePublished: boolean;
  providerData: IMiddlewareData;
}

export interface IMiddlewareData {
  type?: BundleSubTypeProvider;
  interactiveChannel: string;
  interactionPoint: string;
}

export enum SubBundleStatus {
  EDIT = "EDIT",
  DELETE = "DELETE",
  DISABLE = "DISABLE",
  ENABLE = "ENABLE",
  LIVE = "LIVE",
  DISABLED = "DISABLED",
}
export interface IAddBundleRequest {
  name: {
    translations: any;
  };
  provider: BundleTypeProvider;
  description: string;
  upsellPromo: boolean;
  buyForFriend: boolean;
}

export interface IAddSubBundleRequest {
  cost: string;
  content: {
    type: "BUNDLE_PLAN" | "BUNDLE_DESCRIPTION";
    sms?: {
      translations: any;
    };
    data?: {
      translations: any;
    };
    calls?: {
      translations: any;
    };
  };
  currency: string;
  frequency?: string;
  network?: string;
  recipientType: "SELF" | "OTHERS" | "ANY";
  shortcode: string;
  voiceOnNet?: number;
  voiceAllNet?: number;
  smsAllNet?: number;
  dataAllNet?: number;
  identifierCode?: string;
}

export interface IEditSubBundleRequest {
  id: string | number;
  cost: string;
  content: {
    type: "BUNDLE_PLAN" | "BUNDLE_DESCRIPTION";
    sms?: {
      translations: any;
    };
    data?: {
      translations: any;
    };
    calls?: {
      translations: any;
    };
  };
  currency: string;
  frequency?: string;
  network?: string;
  recipientType: "SELF" | "OTHERS" | "ANY";
  shortcode: string;
  status: SubBundleStatus;
  voiceOnNet?: number;
  voiceAllNet?: number;
  smsAllNet?: number;
  dataAllNet?: number;
  identifierCode?: string;
}

export interface IAddMiddlewareSubBundleRequest {
  name: {
    translations: any;
  };
  providerData: IMiddlewareData;
  recipientType: "SELF" | "OTHERS" | "ANY";
}

export interface IEditMiddlewareSubBundleRequest {
  id: string | number;
  name: {
    translations: any;
  };
  providerData: IMiddlewareData;
  recipientType: "SELF" | "OTHERS" | "ANY";
  status: SubBundleStatus;
}

export interface IEditBundleRequest {
  id: string;
  name: ITranslatableObject;
  provider: BundleTypeProvider;
  description: string;
  upsellPromo: boolean;
  buyForFriend: boolean;
}
