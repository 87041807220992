export interface INetworks {
  status: NetworksStatusEnum;
  unpublishedChanges: boolean;
  previousVersion: boolean;
  networks: INetworkItem[];
}

export interface INetworkItem {
  networkType: NetworksTypeEnum;
  sectionType: NetworksSectionEnum;
  status: NetworksStatusEnum;
  editable: boolean;
  id: string;
  validation?: string;
  hasUnpublishedChanges: boolean;
  hasPreviousVersion: boolean;
}

export interface INetworkUpdateItem {
  networkType: NetworksTypeEnum;
  sectionType: NetworksSectionEnum;
  status: NetworksStatusEnum;
  id: string;
}

export enum NetworksStatusEnum {
  DISABLE = "DISABLE",
  DISABLED = "DISABLED",
  EDIT = "EDIT",
  ENABLE = "ENABLE",
  LIVE = "LIVE",
  DELETE = "DELETE",
}

export enum NetworksSectionEnum {
  PAY_PERSON = "PAY_PERSON",
  PAY_AIRTIME = "PAY_AIRTIME",
}

export enum NetworksTypeEnum {
  AIRTEL = "AIRTEL",
  TIGO = "TIGO",
  MIC = "MIC",
  SMILE = "SMILE",
  TTCL = "TTCL",
  HALOTEL = "HALOTEL",
  VIETTEL = "VIETTEL",
  ZANTEL = "ZANTEL",
  VODACOM = "VODACOM",
}
