import styled from "styled-components";

const AnalyticsContainer = styled.section`
  display: flex;
  flex-direction: column;
  font-family: "Vodafone Rg";
  border: 0;
  margin: 0;
`;

const ContainerHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 20px;
  border: 0;
  margin: 0;
`;

const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;

  & .container_body_cards {
    position: relative;
    width: 100%;
  }

  & .container_body_tabs {
    position: relative;
    width: 100%;
  }
`;

const ContainerFooter = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 55px;
  margin: 0 0 10px 0;
  padding: 0 0 0 0 !important;
  & ul {
    height: 35.2px;
  }
`;

const RelativeRow = styled.div`
  position: relative;
  width: auto;
  margin: 30px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  @media (min-width: 756px) {
    flex-direction: row;
    width: 650px;
  }
`;

const RelativeChartRow = styled(RelativeRow)`
  width: auto;
  gap: 40px;
`;

const AbsoluteRow = styled.div`
  position: absolute;
  right: 0;
  top: 5px;
`;

const RelativeTableRow = styled.div`
  position: relative;
  width: 100%;
  min-height: 20px;
  margin: 27px 0;
`;

export {
  RelativeTableRow,
  RelativeChartRow,
  RelativeRow,
  AbsoluteRow,
  ContainerHeader,
  ContainerBody,
  ContainerFooter,
  AnalyticsContainer,
};
