import { PageTitle, TabChild, Tabs, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Breadcrumb } from "@wit/mpesa-ui-components/lib/types";
import { TFunction } from "i18next";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { ConfigContext } from "../../../app.component";
import { RoutesEnum } from "../../../routes/routes.constants";
import { IApp } from "../../../shared/models/app.model";
import { FEATURES } from "../../../shared/renderer.utils";
import { PageContainer } from "../../../shared/shared.styled";
import AppsApi from "../apps.api";
import AppPropertiesPage from "./app-properties/app-properties.component";
import AppReleaseVersionsPage from "./app-release-versions/app-release-versions.page";

const getBreadcrumbs = (t: TFunction, app: IApp): Breadcrumb[] => {
  return [
    {
      label: t("pages.appDetails.title"),
      url: RoutesEnum.APPS,
    },
    {
      label: app.name,
      url: "",
    },
  ];
};

/**
 * App details page
 */
const AppDetailsPage = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const [tabId, setTabId] = React.useState(0);
  const { config } = useContext(ConfigContext);
  const [showAlert, , setAlertProps] = useAlert();
  const [app, setApp] = React.useState<IApp>();
  const { id } = useParams<any>();
  const themeContext = useContext(ThemeContext);

  /*
   * GET the app details
   */
  const getApp = () => {
    AppsApi.methods.getAppReleases(id).then(
      res => {
        setApp(res.data);
      },
      () => {
        setAlertProps({
          title: t("pages.appDetails.releaseVersions.getErrorTitle"),
          content: t("pages.appDetails.releaseVersions.getErrorContent"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  React.useEffect(() => {
    getApp();
  }, []);

  return (
    <PageContainer>
      {app ? (
        <>
          <PageTitle
            breadcrumbs={getBreadcrumbs(t, app)}
            title={`${app.name} ${t(`commons.apps.enums.platform.${app.system}`)}`}
            navigateFunction={history.push}
            separatorStyle={{ display: "none" }}
            goBackFn={() => window.history.back()}
            titleContainerStyle={{ marginTop: "13px", marginBottom: "34px" }}
            iconContainerStyle={{ stroke: themeContext.palette.vodafoneRed }}
          />

          {!config?.disabledFeatures ||
          (config.disabledFeatures !== undefined && !config.disabledFeatures.includes(FEATURES.APP_PROPERTIES)) ? (
            <Tabs controlledSelectedTab={0} controlledSetSelectedTab={setTabId} isResponsive={true}>
              <TabChild label={t("pages.appDetails.releaseVersions.title")}>
                <AppReleaseVersionsPage id={id} app={app} refetchApp={getApp} />
              </TabChild>
              <TabChild label={t("pages.appProperties.appProperties")}>
                <AppPropertiesPage appId={id} />
              </TabChild>
            </Tabs>
          ) : (
            <>
              <TabLabel>{t("pages.appDetails.releaseVersions.title")}</TabLabel>
              <AppReleaseVersionsPage id={id} app={app} refetchApp={getApp} />
            </>
          )}
        </>
      ) : null}
    </PageContainer>
  );
};

export default AppDetailsPage;

const TabLabel = styled("span")`
  border-radius: 20px;
  padding: 4px 30px;
  background-color: rgb(51, 51, 51);
  display: flex;
  color: rgb(255, 255, 255);
  font-family: "Vodafone Rg";
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  align-self: flex-start;
  margin-bottom: 24px;
`;
