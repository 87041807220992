import React from "react";
import { FilterFuncts } from "@wit/mpesa-ui-components/lib/hooks/use-filters.hook";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import {
  DateRangeDropdown,
  RecurringIcon,
  SmallButton,
  UndoIcon,
  MultipleOptionsDropdown,
  SearchBarWithDropdown,
  DownloadIcon,
} from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { Row } from "../../../../../shared/shared.styled";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ReportStatusEnum, ReportReasonEnum } from "../../../../../shared/shared.enums";
import { onEnterPressed } from "../../request-money.utils";
import UsersApi from "../../../../admin/users/users.api";

export enum ReportedUsersFilterKeys {
  REPORTED_NUMBER = "reportedNumber",
  REPORTED_BY = "reportedBy",
  REPORTING_DATE = "reportingDate",
  REPORTING_DATE_DATE_RANGE = "reportingDateDateRange",
  REPORTING_DATE_START_DATE = "reportingDateStartDate",
  REPORTING_DATE_END_DATE = "reportingDateEndDate",
  UPDATED_ON = "updatedOn",
  UPDATED_ON_DATE_RANGE = "updatedOnDateRange",
  UPDATED_ON_START_DATE = "updatedOnStartDate",
  UPDATED_ON_END_DATE = "updatedOnEndDate",
  REASON = "reason",
  STATUS = "status",
}

interface IReportedUsersFiltersProps extends FilterFuncts {
  reportedUsersCount: number;
  refreshResults: () => void;
}

const ReportedUsersFilters = ({
  filters,
  clearFilter,
  isFilterActive,
  resetFilters,
  updateFilter,
  updateMultipleFilters,
  reportedUsersCount,
  refreshResults,
}: IReportedUsersFiltersProps) => {
  const [t] = useTranslation();
  const [searchSelectedOption, setSearchSelectedOption] = React.useState<SharedDropdownOption>({
    key: "all",
    label: "All",
  });

  const selectOption = (option: SharedDropdownOption) => {
    const value =
      searchSelectedOption.key !== "all"
        ? filters.get(searchSelectedOption.key)
        : filters.get(ReportedUsersFilterKeys.REPORTED_NUMBER);
    setSearchSelectedOption(option);
    if (value && value.length > 0) {
      onChangeSearchOption(option.key, value as string[]);
    }
  };

  const onChangeSearchOption = (option: string, value: string[]) => {
    if (option === "all") {
      updateMultipleFilters([
        { key: ReportedUsersFilterKeys.REPORTED_NUMBER, values: value },
        { key: ReportedUsersFilterKeys.REPORTED_BY, values: value },
      ]);
    } else {
      if (option === ReportedUsersFilterKeys.REPORTED_NUMBER) {
        updateMultipleFilters([
          { key: ReportedUsersFilterKeys.REPORTED_NUMBER, values: value },
          { key: ReportedUsersFilterKeys.REPORTED_BY, values: [] },
        ]);
      } else if (option === ReportedUsersFilterKeys.REPORTED_BY) {
        updateMultipleFilters([
          { key: ReportedUsersFilterKeys.REPORTED_BY, values: value },
          { key: ReportedUsersFilterKeys.REPORTED_NUMBER, values: [] },
        ]);
      }
    }
  };

  const clearSearch = () => {
    if (searchSelectedOption.key === "all") {
      updateMultipleFilters([
        { key: ReportedUsersFilterKeys.REPORTED_BY, values: [] },
        { key: ReportedUsersFilterKeys.REPORTED_NUMBER, values: [] },
      ]);
    } else {
      clearFilter(searchSelectedOption.key);
    }
  };

  //TODO: Change when BE integration
  //Export a local CSV from users to not depend from an external service to this being work
  const exportCSVFile = () => {
    const fileDownload = require("js-file-download");
    UsersApi.methods.exportUsersCsv().then(
      res => {
        fileDownload(res.data, "export.csv");
      },
      err => console.error(err),
    );
  };

  const clearFilters = () => {
    const defaultValue = {
      key: "all",
      label: t("pages.tools.requestMoney.reportedUsers.filters.searchAll"),
    } as SharedDropdownOption;
    setSearchSelectedOption(defaultValue.key);
    selectOption(defaultValue);
    resetFilters();
  };

  return (
    <>
      <CustomRow>
        <FilterContainer>
          <SearchBarWithDropdown
            onKeyDown={e => {
              onEnterPressed(e, refreshResults);
            }}
            value={
              searchSelectedOption.key === ReportedUsersFilterKeys.REPORTED_NUMBER
                ? filters.has(ReportedUsersFilterKeys.REPORTED_NUMBER)
                  ? (filters.get(ReportedUsersFilterKeys.REPORTED_NUMBER) as string[])[0]
                  : ""
                : filters.has(ReportedUsersFilterKeys.REPORTED_BY)
                ? (filters.get(ReportedUsersFilterKeys.REPORTED_BY) as string[])[0]
                : ""
            }
            onChange={event => onChangeSearchOption(searchSelectedOption.key, [event.target.value])}
            clearValue={() => clearSearch()}
            placeholderLabel={t("pages.tools.requestMoney.reportedUsers.filters.searchPlaceholder")}
            options={[
              { key: "all", label: t("pages.tools.requestMoney.reportedUsers.filters.searchAll") },
              {
                key: ReportedUsersFilterKeys.REPORTED_NUMBER,
                label: t("pages.tools.requestMoney.reportedUsers.filters.searchReportedNumber"),
              },
              {
                key: ReportedUsersFilterKeys.REPORTED_BY,
                label: t("pages.tools.requestMoney.reportedUsers.filters.searchReportedBy"),
              },
            ]}
            selectOption={opt => selectOption(opt)}
            label={searchSelectedOption.label}
            hasValue={true}
          />
        </FilterContainer>
        <FilterContainer>
          <DateRangeDropdown
            value={ReportedUsersFilterKeys.REPORTING_DATE}
            filters={filters}
            isFilterActive={isFilterActive}
            clearFilter={clearFilter}
            updateFilter={updateFilter}
            updateMultipleFilters={updateMultipleFilters}
            translations={{
              dropdownPlaceholder: t("pages.tools.requestMoney.reportedUsers.filters.reportingDatePlaceholder"),
              clearFilters: t("pages.tools.requestMoney.reportedUsers.filters.clearFilters"),
              CUSTOM: t("pages.tools.requestMoney.reportedUsers.filters.customRange"),
              LAST_WEEK: t("pages.tools.requestMoney.reportedUsers.filters.lastWeek"),
              LAST_MONTH: t("pages.tools.requestMoney.reportedUsers.filters.lastMonth"),
            }}
            showDefaultRanges={true}
          />
        </FilterContainer>
        <FilterContainer>
          <DateRangeDropdown
            value={ReportedUsersFilterKeys.UPDATED_ON}
            filters={filters}
            isFilterActive={isFilterActive}
            clearFilter={clearFilter}
            updateFilter={updateFilter}
            updateMultipleFilters={updateMultipleFilters}
            translations={{
              dropdownPlaceholder: t("pages.tools.requestMoney.reportedUsers.filters.updatedOnPlaceholder"),
              clearFilters: t("pages.tools.requestMoney.reportedUsers.filters.clearFilters"),
              CUSTOM: t("pages.tools.requestMoney.reportedUsers.filters.customRange"),
              LAST_WEEK: t("pages.tools.requestMoney.reportedUsers.filters.lastWeek"),
              LAST_MONTH: t("pages.tools.requestMoney.reportedUsers.filters.lastMonth"),
            }}
            showDefaultRanges={true}
          />
        </FilterContainer>
        <FilterContainer>
          <MultipleOptionsDropdown
            options={Object.values(ReportReasonEnum)
              .filter(s => typeof s === "string")
              .map(reason => {
                return {
                  label: t(`pages.tools.requestMoney.reportedUsers.filters.reason.${reason}`),
                  key: reason,
                };
              })}
            toggleOption={opt => updateFilter(ReportedUsersFilterKeys.REASON, opt.key)}
            isOptionSelected={opt => isFilterActive(ReportedUsersFilterKeys.REASON, opt.key)}
            clearAllFilters={() => clearFilter(ReportedUsersFilterKeys.REASON)}
            hasValue={!!filters.get(ReportedUsersFilterKeys.REASON)}
            label={t("pages.tools.requestMoney.reportedUsers.filters.reasonPlaceholder")}
          ></MultipleOptionsDropdown>
        </FilterContainer>
        <FilterContainer>
          <MultipleOptionsDropdown
            options={Object.values(ReportStatusEnum)
              .filter(s => typeof s === "string")
              .map(status => {
                return {
                  label: t(`pages.tools.requestMoney.reportedUsers.filters.status.${status}`),
                  key: status,
                };
              })}
            toggleOption={opt => updateFilter(ReportedUsersFilterKeys.STATUS, opt.key)}
            isOptionSelected={opt => isFilterActive(ReportedUsersFilterKeys.STATUS, opt.key)}
            clearAllFilters={() => clearFilter(ReportedUsersFilterKeys.STATUS)}
            hasValue={!!filters.get(ReportedUsersFilterKeys.STATUS)}
            label={t("pages.tools.requestMoney.reportedUsers.filters.statusPlaceholder")}
          ></MultipleOptionsDropdown>
        </FilterContainer>
      </CustomRow>
      <div style={{ display: "flex", gap: "24px", flexWrap: "wrap", marginBottom: "16px" }}>
        <ReportedUsersCount>
          <span>{reportedUsersCount}</span>
          &nbsp;
          {`${t("pages.tools.requestMoney.reportedUsers.filters.recordsFound")}`}
        </ReportedUsersCount>
        <div>
          <SmallButton
            titleLabel={t("pages.tools.requestMoney.reportedUsers.filters.refreshResults")}
            onClick={() => refreshResults()}
            iconComponent={
              <SmallButtonIconContainer color={styleTheme.palette.turquoiseBlue}>
                <RecurringIcon />
              </SmallButtonIconContainer>
            }
          />
        </div>
        <div>
          <SmallButton
            titleLabel={t("pages.tools.requestMoney.reportedUsers.filters.clearFilters")}
            onClick={() => clearFilters()}
            iconComponent={
              <SmallButtonIconContainer color={styleTheme.palette.vodafoneRed}>
                <UndoIcon />
              </SmallButtonIconContainer>
            }
          />
        </div>
        <ExportButton>
          <SmallButton
            titleLabel={t("pages.tools.requestMoney.reportedUsers.filters.exportCSVFile")}
            onClick={() => exportCSVFile()}
            iconComponent={
              <SmallButtonIconContainer color={styleTheme.palette.vodafoneRed}>
                <DownloadIcon />
              </SmallButtonIconContainer>
            }
          />
        </ExportButton>
      </div>
    </>
  );
};

export default ReportedUsersFilters;
const ExportButton = styled("div")`
  margin-left: auto;
  @media (max-width: 768px) {
    margin-left: unset;
  }
`;
const FilterContainer = styled("div")`
  flex: 1 1 15%;
`;
const CustomRow = styled(Row)`
  margin-bottom: 18px;
  flex-wrap: wrap;
  gap: 16px;
  > ${FilterContainer} {
    :nth-child(1) {
      > div {
        > button {
          border: solid 1px #ebebeb;
          height: 24px;
        }
      }
    }
  }
`;

const ReportedUsersCount = styled("div")`
  display: flex;
  flex-direction: row;
  font-family: Vodafone Rg;
  font-size: 14px;
  color: ${props => props.theme.palette.midGrey};

  > span {
    font-weight: bold;
  }
`;

const SmallButtonIconContainer = styled("div")<{ color: string }>`
  width: 16px;
  height: 16px;
  stroke: ${props => props.color};

  svg {
    width: 16px;
    height: 16px;
    stroke: ${props => props.color};
  }
`;
