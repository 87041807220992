/* eslint-disable require-jsdoc */
import {
  AutoClosingDropdown,
  FormSection,
  ModalTypeEnum,
  SecondaryPageTitle,
  PrimaryButton,
  TextArea,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import WarningIcon from "../../../../shared/icons/warning.icon";
import { FormSectionRow } from "@wit/mpesa-ui-components/lib/components/form-section/form-section.component";
import * as Yup from "yup";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { LoadingText } from "../../../../shared/shared.styled";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikProps, FormikValues } from "formik";
import i18next, { TFunction } from "i18next";
import moment from "moment";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { IStoreInterface } from "../../../../configs/store.config";
import { RoutesEnum } from "../../../../routes/routes.constants";
import { PageContainer } from "../../../../shared/shared.styled";
import {
  IClientDocSubmissionDetailsRequest,
  IClientDocSubmissionChangeRequest,
  IClientDocSubmissionDetailsResponse,
  ImageType,
  ICustomReasonCharactersLimit,
  IDocValidationDetailsPageProps,
  IDocValidationDetailsImagesReducerInterface,
  IDocValidationDetailsReducerInterface,
} from "../doc-validation-details.interface";
import { ClientDocSubmissionNextStatusEnumKeys, ClientDocSubmissionNextStatusEnum } from "../../ekyc.shared.enums";
import DocValidationDetailsApi from "../doc-validation-details.api";
import { DocValidationDetailsActions } from "../doc-validation-details.store";
import { DocValidationDetailsUtils } from "../doc-validation-details.utils";
import { DocValidationUtils } from "../../doc-validation/doc-validation.utils";
import ClientDocPhotos from "../components/doc-validation-photos.component";
import DocStatusHistoryList from "../components/doc-status-history.component";
import { useHistory, useLocation } from "react-router-dom";
import { ILoggedUser } from "../../../authentication/authentication.interfaces";
import DocStatusItem from "../components/doc-status-item.component";
import ExclamationIcon from "@wit/mpesa-ui-components/lib/assets/icons/exclamation.icon";
import { renderDate } from "../../../../shared/shared.utils";
import FullScreenImageModal from "../components/doc-validation-fullscreen-image.component";

const DocValidationDetailsPage = () => {
  const ANOTHER_USER_SEEING_MINUTES = 10;
  const CUSTOM_REASON_CHARACTERS_LIMIT: ICustomReasonCharactersLimit = {
    max: 60,
    min: 4,
  };
  const history = useHistory();
  const { state } = useLocation<IDocValidationDetailsPageProps>();
  const remoteId = state?.remoteId;

  if (!remoteId) {
    history.push("/404");
  }
  // Hooks init
  const { t } = useTranslation("private");
  const [tMarket] = useTranslation("market_values");
  const dispatch = useDispatch();
  const [showAlert, hideAlert, setAlertProps] = useAlert();

  const formRowRef = useRef<HTMLDivElement>(null); // Helper reference used to style the registration status section
  const registrationApprovalRef = useRef<HTMLDivElement>(null); // Helper reference used to style the registration approval / rejection section
  const formRef = useRef<FormikProps<FormikValues>>(null); // Reference used to submit the form
  const agentInfoRef = useRef<HTMLDivElement>(null); // Helper reference used to style the agent information section

  // Redux state
  const detailsData: IDocValidationDetailsReducerInterface = useSelector(
    (store: IStoreInterface) => store.docValidationDetailsReducer,
  );

  const submissionImages: IDocValidationDetailsImagesReducerInterface[] = useSelector(
    (store: IStoreInterface) => store.docValidationDetailsImagesReducer,
  );

  const user = useSelector((s: IStoreInterface) => s.authenticationReducer.loggedUser as ILoggedUser);

  const navigationParams: any = {};

  // Local state definitions
  const [isLoadingDetails, setIsLoadingDetails] = React.useState(true);
  const [isLoadingImages, setIsLoadingImages] = React.useState(true);
  const [isEditable, setIsEditable] = React.useState(false);
  const [hasLoadFailed, setHasLoadFailed] = React.useState(false);
  const [isSubmittingReq, setIsSubmittingReq] = React.useState(false);
  const [requestDetailsParams, setRequestDetailsParams] = React.useState<IClientDocSubmissionDetailsRequest>({
    remoteId: remoteId,
    username: user.username,
    previousDuplicates: [],
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  function getStatusReason(values: any): string {
    return `statusReasons.${values.nextStatus}.${values.nextStatusReason}`;
  }

  /*
   * Queries the server for the client submission details and updates the table,
   * every time the "requestDetailsParams" change.
   */
  React.useEffect(() => {
    getClientSubmissionDetails();
  }, [requestDetailsParams]);

  const [, , pictureModalProps] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <FullScreenImageModal photoUrl={detailsData.signatureUrl || ""} />,
  );

  React.useEffect(() => {
    pictureModalProps({ modalStyles: { width: "auto", overflow: "visible" } });
  }, [pictureModalProps]);

  React.useEffect(() => {
    let duplicateText = "";

    if (!isLoadingDetails) {
      const docNextStates = DocValidationDetailsUtils.getNextDocStatus(detailsData.status);
      const editable = docNextStates.length > 0;

      if (editable) {
        if (!i18next.exists("market_values:genders.0")) {
          setIsEditable(false);

          setAlertProps({
            title: t("pages.docSubmissionDetails.loadMarketValuesError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();

          return;
        }

        setIsEditable(true);
      }

      if (detailsData.numberOfIdNumberDuplications > 0) {
        duplicateText = `${detailsData.numberOfIdNumberDuplications} ${t(
          "pages.docSubmissionDetails.filters.byIdNumber",
        )}`;
      }
      if (detailsData.numberOfPhoneNumberDuplications > 0) {
        if (duplicateText) {
          duplicateText += ", ";
        }
        duplicateText += `${detailsData.numberOfPhoneNumberDuplications} ${t(
          "pages.docSubmissionDetails.filters.byPhoneNumber",
        )}`;
      }

      if (duplicateText) {
        setAlertProps({
          title: t("pages.docSubmissionDetails.filters.duplicatedRecords"),
          content: duplicateText,
          type: AlertTypeEnum.WARNING,
        });
        showAlert();
      }
    }
  }, [detailsData]);

  React.useLayoutEffect(() => {
    const registrationStatusSection = formRowRef?.current?.parentElement?.parentElement;
    registrationStatusSection?.classList?.add("status-container");
    const registrationApprovalSection = registrationApprovalRef?.current?.parentElement?.parentElement?.parentElement;
    registrationApprovalSection?.classList?.add("status-container");

    const agentInfoSection = agentInfoRef?.current?.parentElement?.parentElement;
    agentInfoSection?.classList?.add("status-container");
  }, [formRowRef.current, registrationApprovalRef.current, agentInfoRef.current]);

  /*
   * Get client doc submission images
   */

  const getImages = async (requestId: string, documentType: string) => {
    const imagesType: ImageType[] = [ImageType.FRONT, ImageType.SELFIE];
    const promises: Promise<any>[] = [];

    if (documentType !== "08") {
      // TODO: Write an interface tht specifies if the documentType has a back side or not
      imagesType.push(ImageType.BACK);
    }

    imagesType.forEach((imageType: ImageType) => {
      promises.push(
        new Promise((resolve, _) => {
          DocValidationDetailsApi.methods
            .getImageSubmissionDetails({
              submissionRequestId: requestId,
              imageType,
            })
            .then(
              res => {
                const imageResponse: string = res.data.content;
                resolve({
                  url: imageResponse,
                  extension: "jpeg",
                  context: imageType,
                });
              },
              () => {
                setHasLoadFailed(true);
                setAlertProps({
                  title: t("pages.docSubmissionDetails.getDetailsError"),
                  type: AlertTypeEnum.ERROR,
                });
                showAlert();
              },
            );
        }),
      );
    });

    const images = await Promise.all(promises);
    setIsLoadingImages(false);
    dispatch(DocValidationDetailsActions.creators.fetchDocDetailsImagesSuccessAction(images));
  };

  /*
   * Get client doc submission details
   */
  const getClientSubmissionDetails = () => {
    setIsLoadingDetails(true);

    DocValidationDetailsApi.methods
      .getClientSubmissionDetails(requestDetailsParams)
      .finally(() => setIsLoadingDetails(false))
      .then(
        res => {
          const detailsResponse: IClientDocSubmissionDetailsResponse = res.data;
          getImages(requestDetailsParams.remoteId, detailsResponse.documentType);
          dispatch(DocValidationDetailsActions.creators.fetchDocDetailsSuccessAction(detailsResponse));
        },
        () => {
          setHasLoadFailed(true);
          setAlertProps({
            title: t("pages.docSubmissionDetails.getDetailsError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
  };

  const saveChanges = (values: any) => {
    setIsSubmittingReq(true);
    const request: IClientDocSubmissionChangeRequest = {
      status: ClientDocSubmissionNextStatusEnum[values.nextStatus as ClientDocSubmissionNextStatusEnumKeys],
    };

    const stateReason = getReasonParamsFromValues(values.reasonText, values.nextStatusReason, values.nextStatus);
    if (stateReason) {
      request.comment = stateReason.comment;
      request.customComment = stateReason.customComment;
    }

    DocValidationDetailsApi.methods
      .changeClientSubmissionDetails(remoteId, request)
      .finally(() => setIsSubmittingReq(false))
      .then(
        () => {
          history.push({
            pathname: RoutesEnum.DOC_VALIDATION,
            state: {
              successfulUpdate: true,
            },
          });
        },
        () => {
          setAlertProps({
            title: t("pages.docSubmissionDetails.changeDetailsError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
  };

  const getReasonParamsFromValues = (reasonText?: string, reasonType?: string, status?: string): any => {
    if (reasonType === "CUSTOM" && reasonText) {
      return {
        comment: tMarket(`statusReasons.${status}.${reasonType}`),
        customComment: reasonText,
      };
    }

    if (reasonType) {
      return {
        comment: tMarket(`statusReasons.${status}.${reasonType}`),
      };
    }

    return null;
  };

  const getFormValidationSchema = (t: TFunction) => {
    return Yup.object().shape({
      nextStatus: Yup.string().required(t("pages.docSubmissionDetails.formErrors.required")),
      nextStatusReason: Yup.string().when("nextStatus", {
        is: (value: ClientDocSubmissionNextStatusEnumKeys) => {
          return DocValidationDetailsUtils.nextDocStatusHasReason(value);
        },
        then: Yup.string().required(t("pages.docSubmissionDetails.formErrors.required")),
      }),
      reasonText: Yup.string().when("nextStatusReason", {
        is: "CUSTOM",
        then: Yup.string().required("error"),
      }),
    });
  };

  return (
    <PageContainer style={{ paddingBottom: 140 }}>
      <SecondaryPageTitle
        goBackFn={() => {
          hideAlert();
          if (requestDetailsParams.previousDuplicates.length > 0) {
            setRequestDetailsParams((prev: IClientDocSubmissionDetailsRequest) => ({
              ...prev,
              previousDuplicates: prev.previousDuplicates.slice(0, -1),
              remoteId: prev.previousDuplicates[prev.previousDuplicates.length - 1],
            }));
          } else {
            setRequestDetailsParams({
              ...requestDetailsParams,
              previousDuplicates: [],
            });
            history.push({
              pathname: RoutesEnum.DOC_VALIDATION,
              state: {
                successfulUpdate: false,
              },
            });
          }
        }}
        title={navigationParams.fullName || detailsData.fullName}
        displayInitialsCircle={true}
        titleFontSize={26}
      />
      {isEditable && (
        <ButtonsContainer>
          <ButtonWrapper>
            <PrimaryButton
              titleLabel={t("pages.docSubmissionDetails.buttonCancelLabel")}
              type="submit"
              redTheme={false}
              onClick={() => {
                hideAlert();
                window.history.back();
              }}
              disabled={isSubmittingReq}
            />
          </ButtonWrapper>
          <ButtonWrapper style={{ width: "auto" }}>
            <PrimaryButton
              titleLabel={t("pages.docSubmissionDetails.buttonSaveLabel")}
              type="submit"
              disabled={isButtonDisabled}
              onClick={() => {
                if (formRef.current) {
                  saveChanges(formRef.current.values);
                } else {
                  setAlertProps({
                    title: t("pages.docSubmissionDetails.failedToSaveChanges"),
                    type: AlertTypeEnum.ERROR,
                  });
                  showAlert();
                }
              }}
              redTheme={true}
              loading={isSubmittingReq}
            />
          </ButtonWrapper>
        </ButtonsContainer>
      )}

      {!isLoadingDetails &&
      !hasLoadFailed &&
      isEditable &&
      detailsData &&
      ((detailsData.lastSeenBy &&
        detailsData.lastSeenBy !== user.username &&
        detailsData.lastSeenTime !== undefined &&
        detailsData.lastSeenTime > Date.now() - ANOTHER_USER_SEEING_MINUTES * 60 * 1000) ||
        (detailsData.penultSeenBy &&
          detailsData.penultSeenBy !== user.username &&
          detailsData.penultSeenTime !== undefined &&
          detailsData.penultSeenTime > Date.now() - ANOTHER_USER_SEEING_MINUTES * 60 * 1000)) ? (
        <WarningHeader>
          {t("pages.docSubmissionDetails.beingSeenByWarn", { minutes: ANOTHER_USER_SEEING_MINUTES })}
        </WarningHeader>
      ) : (
        <></>
      )}

      <PageContent>
        <MainContent>
          <Formik
            innerRef={formRef}
            initialValues={{
              nextStatus: undefined,
              nextStatusReason: undefined,
              reasonText: undefined,
            }}
            validationSchema={getFormValidationSchema(t)}
            validateOnChange={true}
            validateOnBlur={false}
            onSubmit={() => {}}
            render={props => {
              const { values, handleChange, setFieldValue, handleSubmit } = props;
              var rows: FormSectionRow[];

              if (isLoadingDetails || hasLoadFailed) {
                rows = [];
              } else {
                rows = [
                  {
                    label: t("pages.docSubmissionDetails.firstName"),
                    displayComponent: detailsData.firstName?.value,
                    editingComponent: (
                      <ManualEntryWrapper>
                        <div>
                          <NonEditableText>{detailsData.firstName?.value}</NonEditableText>
                        </div>

                        {detailsData.firstName?.status === "REVIEW" && (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <ManualEntryLabel>{t("pages.docSubmissionDetails.manualReview")}</ManualEntryLabel>
                            <WarningIcon id="warning-icon" stroke={styleTheme.palette.turquoiseBlue} />
                          </div>
                        )}
                      </ManualEntryWrapper>
                    ),
                  },
                  {
                    label: t("pages.docSubmissionDetails.lastName"),
                    displayComponent: detailsData.lastName?.value,
                    editingComponent: (
                      <ManualEntryWrapper>
                        <div>
                          <NonEditableText>{detailsData.lastName?.value}</NonEditableText>
                        </div>

                        {detailsData.lastName?.status === "REVIEW" && (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <ManualEntryLabel>{t("pages.docSubmissionDetails.manualReview")}</ManualEntryLabel>

                            <WarningIcon id="warning-icon" stroke={styleTheme.palette.turquoiseBlue} />
                          </div>
                        )}
                      </ManualEntryWrapper>
                    ),
                  },
                  {
                    label: t("pages.docSubmissionDetails.dateOfBirth"),
                    displayComponent: renderDate({ date: moment(detailsData.dateOfBirth.value), printHour: false }),
                    editingComponent: (
                      <ManualEntryWrapper>
                        <div>
                          <NonEditableText>
                            {renderDate({ date: moment(detailsData.dateOfBirth.value), printHour: false })}
                          </NonEditableText>
                        </div>

                        {detailsData.dateOfBirth?.status === "REVIEW" && (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <ManualEntryLabel>{t("pages.docSubmissionDetails.manualReview")}</ManualEntryLabel>

                            <WarningIcon id="warning-icon" stroke={styleTheme.palette.turquoiseBlue} />
                          </div>
                        )}
                      </ManualEntryWrapper>
                    ),
                  },
                  {
                    label: t("pages.docSubmissionDetails.placeOfBirth"),
                    displayComponent: detailsData.placeOfBirth?.value,
                    editingComponent: (
                      <ManualEntryWrapper>
                        <div>
                          <NonEditableText>{detailsData.placeOfBirth?.value}</NonEditableText>
                        </div>

                        {detailsData.placeOfBirth?.status === "REVIEW" && (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <ManualEntryLabel>{t("pages.docSubmissionDetails.manualReview")}</ManualEntryLabel>

                            <WarningIcon id="warning-icon" stroke={styleTheme.palette.turquoiseBlue} />
                          </div>
                        )}
                      </ManualEntryWrapper>
                    ),
                  },
                  {
                    label: t("pages.docSubmissionDetails.gender"),
                    displayComponent: tMarket(`genders.${detailsData.gender?.value}`),
                    editingComponent: (
                      <ManualEntryWrapper>
                        <div>
                          <NonEditableText>{tMarket(`genders.${detailsData.gender?.value}`)}</NonEditableText>
                        </div>

                        {detailsData.gender?.status === "REVIEW" && (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <ManualEntryLabel>{t("pages.docSubmissionDetails.manualReview")}</ManualEntryLabel>

                            <WarningIcon id="warning-icon" stroke={styleTheme.palette.turquoiseBlue} />
                          </div>
                        )}
                      </ManualEntryWrapper>
                    ),
                  },
                  {
                    label: t("pages.docSubmissionDetails.idType"),
                    displayComponent: tMarket(`idCards.${detailsData.documentType}`),
                    editingComponent: (
                      <NonEditableText>{tMarket(`idCards.${detailsData.documentType}`)}</NonEditableText>
                    ),
                  },
                  {
                    label: t("pages.docSubmissionDetails.idNumber"),
                    displayComponent: detailsData.idNumber.value,
                    editingComponent: (
                      <ManualEntryWrapper>
                        <div>
                          <NonEditableText>{detailsData.idNumber?.value}</NonEditableText>
                        </div>

                        {detailsData.idNumber?.status === "REVIEW" && (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <ManualEntryLabel>{t("pages.docSubmissionDetails.manualReview")}</ManualEntryLabel>

                            <WarningIcon id="warning-icon" stroke={styleTheme.palette.turquoiseBlue} />
                          </div>
                        )}
                      </ManualEntryWrapper>
                    ),
                  },
                ];

                rows.push.apply(rows, [
                  {
                    label: t("pages.docSubmissionDetails.nationality"),
                    displayComponent: detailsData.nationality.value,
                    editingComponent: (
                      <ManualEntryWrapper>
                        <div>
                          <NonEditableText>{detailsData.nationality?.value}</NonEditableText>
                        </div>

                        {detailsData.nationality?.status === "REVIEW" && (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <ManualEntryLabel>{t("pages.docSubmissionDetails.manualReview")}</ManualEntryLabel>

                            <WarningIcon id="warning-icon" stroke={styleTheme.palette.turquoiseBlue} />
                          </div>
                        )}
                      </ManualEntryWrapper>
                    ),
                  },
                  {
                    label: t("pages.docSubmissionDetails.addressStreet"),
                    displayComponent: detailsData.addressStreet?.value,
                    editingComponent: (
                      <ManualEntryWrapper>
                        <div>
                          <NonEditableText>{detailsData.addressStreet?.value}</NonEditableText>
                        </div>

                        {detailsData.addressStreet?.status === "REVIEW" && (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <ManualEntryLabel>{t("pages.docSubmissionDetails.manualReview")}</ManualEntryLabel>

                            <WarningIcon id="warning-icon" stroke={styleTheme.palette.turquoiseBlue} />
                          </div>
                        )}
                      </ManualEntryWrapper>
                    ),
                  },
                  {
                    label: t("pages.docSubmissionDetails.addressProvince"),
                    displayComponent: detailsData.addressProvince?.value,
                    editingComponent: (
                      <ManualEntryWrapper>
                        <div>
                          <NonEditableText>{detailsData.addressProvince?.value}</NonEditableText>
                        </div>

                        {detailsData.addressProvince?.status === "REVIEW" && (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <ManualEntryLabel>{t("pages.docSubmissionDetails.manualReview")}</ManualEntryLabel>

                            <WarningIcon id="warning-icon" stroke={styleTheme.palette.turquoiseBlue} />
                          </div>
                        )}
                      </ManualEntryWrapper>
                    ),
                  },
                  {
                    label: t("pages.docSubmissionDetails.addressTown"),
                    displayComponent: detailsData.addressTown?.value,
                    editingComponent: (
                      <ManualEntryWrapper>
                        <div>
                          <NonEditableText>{detailsData.addressTown?.value}</NonEditableText>
                        </div>

                        {detailsData.addressTown?.status === "REVIEW" && (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <ManualEntryLabel>{t("pages.docSubmissionDetails.manualReview")}</ManualEntryLabel>

                            <WarningIcon id="warning-icon" stroke={styleTheme.palette.turquoiseBlue} />
                          </div>
                        )}
                      </ManualEntryWrapper>
                    ),
                  },
                  {
                    label: t("pages.docSubmissionDetails.addressCommune"),
                    displayComponent: detailsData.addressCommune?.value,
                    editingComponent: (
                      <ManualEntryWrapper>
                        <div>
                          <NonEditableText>{detailsData.addressCommune?.value}</NonEditableText>
                        </div>

                        {detailsData.addressCommune?.status === "REVIEW" && (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <ManualEntryLabel>{t("pages.docSubmissionDetails.manualReview")}</ManualEntryLabel>

                            <WarningIcon id="warning-icon" stroke={styleTheme.palette.turquoiseBlue} />
                          </div>
                        )}
                      </ManualEntryWrapper>
                    ),
                  },
                  {
                    label: t("pages.docSubmissionDetails.phoneNumber"),
                    displayComponent: detailsData.phoneNumber,
                    editingComponent: <NonEditableText>{detailsData.phoneNumber}</NonEditableText>,
                  },
                  {
                    label: t("pages.docSubmissionDetails.g2Status"),
                    displayComponent: detailsData?.g2Status
                      ? t(`commons.clientDocSubmissionStatus.${detailsData.g2Status}`)
                      : "-",
                    editingComponent: (
                      <NonEditableText>
                        {detailsData?.g2Status ? t(`commons.clientDocSubmissionStatus.${detailsData.g2Status}`) : "-"}
                      </NonEditableText>
                    ),
                  },
                  {
                    label: t("pages.docSubmissionDetails.requestTime"),
                    displayComponent: renderDate({ date: moment(detailsData.requestTime) }),
                    editingComponent: (
                      <NonEditableText>{renderDate({ date: moment(detailsData.requestTime) })}</NonEditableText>
                    ),
                  },
                  {
                    label: t("pages.docSubmissionDetails.lastStatus"),
                    displayComponent: !detailsData.lastStatusTime
                      ? "-"
                      : renderDate({ date: moment(detailsData.lastStatusTime) }),
                    editingComponent: (
                      <NonEditableText>
                        {!detailsData.lastStatusTime ? "-" : renderDate({ date: moment(detailsData.lastStatusTime) })}
                      </NonEditableText>
                    ),
                  },
                ]);
                rows.push.apply(rows, [
                  {
                    label: <FormTitle margin={32}>{t("pages.docSubmissionDetails.agentInfo.agentInfo")}</FormTitle>,
                    displayComponent: (
                      <FormRowWrapper ref={agentInfoRef}>
                        <NonEditableText
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: styleTheme.palette.greyDarker,
                          }}
                        >
                          {t("pages.docSubmissionDetails.agentInfo.operatorId")}
                        </NonEditableText>

                        <NonEditableText>{detailsData.agent.operatorId}</NonEditableText>
                      </FormRowWrapper>
                    ),
                    editingComponent: (
                      <FormRowWrapper ref={agentInfoRef}>
                        <NonEditableText
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: styleTheme.palette.greyDarker,
                          }}
                        >
                          {t("pages.docSubmissionDetails.agentInfo.operatorId")}
                        </NonEditableText>

                        <NonEditableText>{detailsData.agent.operatorId}</NonEditableText>
                      </FormRowWrapper>
                    ),
                  },
                  {
                    label: t("pages.docSubmissionDetails.agentInfo.shortcode"),
                    displayComponent: <NonEditableText>{detailsData.agent.shortcode}</NonEditableText>,
                    editingComponent: <NonEditableText>{detailsData.agent.shortcode}</NonEditableText>,
                  },
                  {
                    label: t("pages.docSubmissionDetails.firstName"),
                    displayComponent: <NonEditableText>{detailsData.agent.shortcode}</NonEditableText>,
                    editingComponent: <NonEditableText>{detailsData.agent.firstName}</NonEditableText>,
                  },
                  {
                    label: t("pages.docSubmissionDetails.agentInfo.middleName"),
                    displayComponent: <NonEditableText>{detailsData.agent.shortcode}</NonEditableText>,
                    editingComponent: <NonEditableText>{detailsData.agent.middleName}</NonEditableText>,
                  },
                  {
                    label: t("pages.docSubmissionDetails.lastName"),
                    displayComponent: <NonEditableText>{detailsData.agent.shortcode}</NonEditableText>,
                    editingComponent: <NonEditableText>{detailsData.agent.lastName}</NonEditableText>,
                  },
                ]);
                rows.push.apply(rows, [
                  {
                    label: <FormTitle margin={40}>{t("pages.docSubmissionDetails.status")}</FormTitle>,
                    displayComponent: (
                      <FormRowWrapper ref={formRowRef}>
                        <NonEditableText
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: styleTheme.palette.greyDarker,
                          }}
                        >
                          {t("pages.docSubmissionDetails.validationStatus")}
                        </NonEditableText>

                        <NonEditableText
                          style={{
                            color: DocValidationUtils.getClientDocStatusColor(detailsData.status),
                          }}
                        >
                          {String(t(`commons.clientDocSubmissionStatus.${detailsData.status}`))}
                          {detailsData.g2Error && <G2ErrorMessage> - {detailsData.g2Error}</G2ErrorMessage>}
                        </NonEditableText>
                      </FormRowWrapper>
                    ),
                    editingComponent: (
                      <FormRowWrapper ref={formRowRef}>
                        <NonEditableText
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: styleTheme.palette.greyDarker,
                          }}
                        >
                          {t("pages.docSubmissionDetails.validationStatus")}
                        </NonEditableText>

                        <NonEditableText
                          style={{
                            color: DocValidationUtils.getClientDocStatusColor(detailsData.status),
                          }}
                        >
                          {String(t(`commons.clientDocSubmissionStatus.${detailsData.status}`))}
                          {detailsData.g2Error && <G2ErrorMessage> - {detailsData.g2Error}</G2ErrorMessage>}
                        </NonEditableText>
                      </FormRowWrapper>
                    ),
                  },
                ]);

                DocValidationDetailsUtils.getNextDocStatus(detailsData.status).length > 0 &&
                  (!DocValidationDetailsUtils.nextDocStatusHasReason(values.nextStatus) ||
                    DocValidationDetailsUtils.getNextDocStatus(detailsData.status).length !== 0) &&
                  rows.push.apply(rows, [
                    {
                      label: "",
                      displayComponent: detailsData.status === "REJECTED" && (
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <FormRowWrapper ref={registrationApprovalRef}>
                            <div>
                              <NonEditableText
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  color: styleTheme.palette.greyDarker,
                                  marginRight: "130px",
                                }}
                              >
                                {t("pages.docSubmissionDetails.rejectReason")}
                              </NonEditableText>
                            </div>
                            <div>
                              <NonEditableText>{detailsData.reason}</NonEditableText>
                            </div>
                          </FormRowWrapper>
                        </div>
                      ),
                      editingComponent: (
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <FormRowWrapper ref={registrationApprovalRef}>
                            {DocValidationDetailsUtils.getNextDocStatus(detailsData.status).length > 0 && (
                              <div style={{ flex: 1 }}>
                                <AutoClosingDropdown
                                  options={DocValidationDetailsUtils.getNextDocStatus(detailsData.status).map(
                                    status => {
                                      return {
                                        labelElement: (
                                          <NextStatus>
                                            <DocStatusItem status={status} />
                                            <span>{`${t(`commons.clientDocSubmissionStatus.${status}`)}`}</span>
                                          </NextStatus>
                                        ),
                                        key: status,
                                        label: "",
                                        disabled: DocValidationDetailsUtils.disableOption({
                                          optionToBeDisabled: ClientDocSubmissionNextStatusEnum.MOVE_ON,
                                          currentOption: status,
                                          currentStatus: detailsData.status,
                                          forbiddenStatuses: ["ERROR_ON_G2", "ERROR_ON_ID_DUPLICATIONS"],
                                        }),
                                      };
                                    },
                                  )}
                                  dropdownType={DropdownType.RECTANGULAR_NORMAL}
                                  selectOption={opt => {
                                    setFieldValue("nextStatusReason", undefined);
                                    setFieldValue("reasonText", undefined);
                                    setFieldValue("nextStatus", opt.key);
                                    setIsButtonDisabled(opt.key !== ClientDocSubmissionNextStatusEnum.MOVE_ON);
                                  }}
                                  label={
                                    <NextStatus>
                                      <DocStatusItem status={values.nextStatus} />
                                      <span>
                                        {t(
                                          `commons.clientDocSubmissionStatus.${values.nextStatus || "approvalStatus"}`,
                                        )}
                                      </span>
                                    </NextStatus>
                                  }
                                  hasValue={!!values.nextStatus}
                                />
                              </div>
                            )}
                            {!DocValidationDetailsUtils.nextDocStatusHasReason(values.nextStatus) ||
                              (DocValidationDetailsUtils.getNextDocStatus(detailsData.status).length !== 0 && (
                                <ErrorReason>
                                  <AutoClosingDropdown
                                    options={DocValidationDetailsUtils.getDocStatusReasons(values.nextStatus).map(
                                      reason => {
                                        return {
                                          label: tMarket(`statusReasons.${values.nextStatus}.${reason}`),
                                          key: reason,
                                        };
                                      },
                                    )}
                                    dropdownType={DropdownType.RECTANGULAR_NORMAL}
                                    selectOption={opt => {
                                      setFieldValue("nextStatusReason", opt.key);
                                      setIsButtonDisabled(opt.key === "CUSTOM");
                                    }}
                                    label={
                                      values.nextStatusReason
                                        ? tMarket(getStatusReason(values))
                                        : t(`pages.docSubmissionDetails.selectReason`)
                                    }
                                    hasValue={!!values.nextStatusReason}
                                  />
                                </ErrorReason>
                              ))}
                          </FormRowWrapper>
                          {values.nextStatusReason === "CUSTOM" && (
                            <>
                              <br />
                              <CustomErrorMessage>
                                <TextArea
                                  name="reasonText"
                                  placeholder={t("pages.docSubmissionDetails.typeReason")}
                                  autoComplete="off"
                                  verticalResize={false}
                                  horizontalResize={false}
                                  onChange={e => {
                                    const { length: customReasonLength } = e.target.value.trim();
                                    setIsButtonDisabled(
                                      customReasonLength <= CUSTOM_REASON_CHARACTERS_LIMIT.min ||
                                        customReasonLength >= CUSTOM_REASON_CHARACTERS_LIMIT.max,
                                    );
                                    handleChange(e);
                                  }}
                                  value={values.reasonText}
                                  color={styleTheme.palette.midGrey}
                                  maxLength={CUSTOM_REASON_CHARACTERS_LIMIT.max}
                                  minLength={CUSTOM_REASON_CHARACTERS_LIMIT.min}
                                />
                              </CustomErrorMessage>
                            </>
                          )}
                        </div>
                      ),
                    },
                  ]);
              }

              return (
                <FormContainer>
                  <form onSubmit={handleSubmit}>
                    <FormSection
                      isEditing={isEditable && !isSubmittingReq}
                      title={
                        <div>
                          <div>
                            {t("pages.docSubmissionDetails.title")}{" "}
                            <span style={{ color: styleTheme.palette.greyDarker }}>
                              {requestDetailsParams.remoteId}
                            </span>
                          </div>
                          <FormTitle margin={16}>
                            <strong>{t("pages.docSubmissionDetails.submittedInfo")}</strong>
                          </FormTitle>
                        </div>
                      }
                      rows={rows}
                    />
                  </form>
                </FormContainer>
              );
            }}
          />
          {isLoadingDetails ? <LoadingText>{t("commons.loading")}</LoadingText> : <></>}
        </MainContent>
        {isLoadingImages || hasLoadFailed ? (
          <>
            <LoadingText>{t("commons.loading")}</LoadingText>
          </>
        ) : (
          <SideMenu>
            <FormLabel>{t("pages.docSubmissionDetails.idPhotos")}</FormLabel>
            <ClientDocPhotos photoUrls={submissionImages} msisdn={detailsData.phoneNumber} />
          </SideMenu>
        )}
        {isLoadingDetails || hasLoadFailed ? (
          <></>
        ) : (
          <>
            {(detailsData.duplicateDocuments || detailsData.duplicateFaces) && (
              <DuplicatedFaceContainer>
                <DuplicatedFaceIcon color={styleTheme.palette.digitalRed} style={{ marginRight: 5 }}>
                  <ExclamationIcon />
                </DuplicatedFaceIcon>
                {detailsData.duplicateFaces && (
                  <>
                    <DuplicatedFaceText>{t("pages.docSubmissionDetails.duplicatedFace")}</DuplicatedFaceText>
                    <DuplicatedRegistrationId
                      onClick={() => {
                        setRequestDetailsParams((prev: IClientDocSubmissionDetailsRequest) => ({
                          ...prev,
                          remoteId: detailsData.duplicateFaces ? detailsData.duplicateFaces[0] : "",
                          previousDuplicates: [...prev.previousDuplicates, prev.remoteId],
                        }));
                      }}
                    >
                      {t("pages.docSubmissionDetails.registration")}
                    </DuplicatedRegistrationId>
                  </>
                )}

                {detailsData.duplicateDocuments && (
                  <>
                    <DuplicatedFaceText>{t("pages.docSubmissionDetails.duplicatedIdNumber")}</DuplicatedFaceText>
                    <DuplicatedRegistrationId
                      onClick={() => {
                        setRequestDetailsParams((prev: IClientDocSubmissionDetailsRequest) => ({
                          ...prev,
                          remoteId: detailsData.duplicateDocuments ? detailsData.duplicateDocuments[0] : "",
                          previousDuplicates: [...prev.previousDuplicates, prev.remoteId],
                        }));
                      }}
                    >
                      {t("pages.docSubmissionDetails.registration")}
                    </DuplicatedRegistrationId>
                  </>
                )}
              </DuplicatedFaceContainer>
            )}
            <HistoryListContainer>
              <DocStatusHistoryList historyEntries={detailsData.statusHistory} />
            </HistoryListContainer>
          </>
        )}
      </PageContent>
    </PageContainer>
  );
};

export default DocValidationDetailsPage;

const DuplicatedFaceContainer = styled("div")<{ color?: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top 40px;
  flex: 1 0 100%;

  svg {
    width: 16px;
    height: 16px;
    stroke: ${props => (props.color ? props.color : props.theme.palette.midGrey)};
  }
`;

const DuplicatedFaceIcon = styled("div")<{ color?: string }>`
  width: 20px;
  height: 20px;
  stroke: ${props => (props.color ? props.color : props.theme.palette.midGrey)};
  background-color: #e6000014;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 16px;
    height: 16px;
    stroke: ${props => (props.color ? props.color : props.theme.palette.midGrey)};
  }
`;

const DuplicatedFaceText = styled("span")`
  font-family: Vodafone Rg;
`;

const DuplicatedRegistrationId = styled("span")`
  font-family: Vodafone Rg;
  text-decoration: underline;
  color: ${styleTheme.palette.turquoiseBlue};
  margin-left: 5px;
  cursor: pointer;
`;

const PageContent = styled("div")`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-top: calc(128px + 28px - 50px);
  margin-left: calc(200px + 20px + 24px - 48px);
  column-gap: 30px;

  @media (max-width: 1024px) {
    margin-left: auto;
  }
`;

const HistoryListContainer = styled("div")`
  flex: 1 0 100%;
  margin-top: 23px;

  @media (max-width: 805px) {
    border-top: 1px solid #ebebeb;
    margin-top: 40px;
  }
`;

const MainContent = styled("div")`
  display: flex;
  flex-direction: column;
  width: 60%;

  @media (max-width: 1024px) {
    flex-grow: 1;
  }
`;

const SideMenu = styled("div")`
  display: flex;
  flex-direction: column;

  @media (max-width: 805px) {
    margin-top: 40px;
  }
`;

const FormContainer = styled("div")`
  .status-container {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    justify-content: space-between;
    padding-bottom: 15px !important;

    div {
      max-width: 100% !important;
      width: 100% !important;
    }

    > div > div {
      margin-top: 26px;
    }
  }
`;

const FormRowWrapper = styled("div")`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  font-family: "Vodafone Rg";
  font-size: 16px;

  > span {
    flex: 1;
  }

  > div > button {
    width: 268px;
    height: 36px;
  }

  > div > div > input {
    width: 230px;
    border-color: ${styleTheme.palette.aluminium};
    cursor: pointer;

    &:hover {
      border-color: ${styleTheme.palette.greyDarker};
    }
  }
`;

const NextStatus = styled("div")`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: unset;

  svg {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    stroke: ${styleTheme.palette.digitalGreen};
  }
`;

const ErrorReason = styled("div")`
  display: flex;
  align-items: center;
  flex: 1;

  button {
    height: 36px;
  }

  span {
    margin-left: 10px;
  }

  @media (max-width: 660px) {
    flex: 1 0 100%;
    margin-top: 14px;
  }
`;

const CustomErrorMessage = styled("div")`
  textarea {
    width: 100&;
    height: 50px;
    color: ${styleTheme.palette.black};
    border: 1px solid ${styleTheme.palette.midGrey};
  }

  textarea::placeholder {
    color: ${styleTheme.palette.midGrey};
  }
`;

const FormLabel = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${styleTheme.palette.midGrey};
  margin-bottom: 20px;
`;

const FormTitle = styled("div")<{ margin: number }>`
  font-size: 16px;
  font-weight: bold;
  font-family: "Vodafone Rg";
  margin-top: ${({ margin }) => margin}px;
`;

const ButtonsContainer = styled("div")`
  position: fixed;
  z-index: 1;
  right: 82px;
  top: 48px;
`;

const ButtonWrapper = styled("div")`
  width: fit-content;
  display: inline-block;
  margin-left: 12px;
  vertical-align: middle;
`;

const NonEditableText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${styleTheme.palette.darkGrey};
  align-self: center;
`;

const WarningHeader = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 5px;
  margin-bottom: -80px;
  margin-top: calc(128px - 50px);
  margin-left: -50px;
  margin-right: -50px;
  border: 1px solid #eb8b00;
  background-color: #fff9c4;
  color: #eb8b00;
`;

const ManualEntryWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const ManualEntryLabel = styled("div")`
  margin-right: 7px;
  font-family: Vodafone Rg;
  color: ${styleTheme.palette.turquoiseBlue};
`;

const G2ErrorMessage = styled("span")`
  color: black !important;
  font-family: Vodafone Rg;
`;
