import React from "react";
import { useDropzone, DropzoneOptions } from "react-dropzone";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { FileUploadIcon, CloudUploadIcon, SentForApprovalIcon } from "@wit/mpesa-ui-components";

interface IDropzoneOptionsProps extends DropzoneOptions {
  dropTitle?: string;
  dropDescription?: string;
  releaseText?: string;
  inputName?: string;
  fileName?: string;
  error?: string;
  height?: string;
  multipleDropzones?: boolean;
}

const Dropzone = ({
  onDrop,
  accept,
  multiple,
  dropTitle = "",
  dropDescription = "",
  releaseText = "",
  inputName = "dropzoneInput",
  fileName = "",
  error = "",
  height = "126px",
  multipleDropzones = false,
}: IDropzoneOptionsProps) => {
  const [t] = useTranslation();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple,
  });

  return (
    <DropzoneDiv
      hasFile={!isDragActive && fileName !== ""}
      active={isDragActive}
      height={height}
      hasMinWidth={multipleDropzones}
      {...getRootProps()}
    >
      <DropzoneInput name={inputName} {...getInputProps()} />

      {isDragActive ? (
        <DropzoneInsideDiv>
          <TextDiv>
            <ContentTextWrapper>
              <IconWrapper active={isDragActive}>
                <CloudUploadIcon />
              </IconWrapper>
              <TitleContent active={isDragActive}>{releaseText}</TitleContent>
            </ContentTextWrapper>
          </TextDiv>
        </DropzoneInsideDiv>
      ) : fileName === "" ? (
        <DropzoneInsideDiv>
          <TextDiv>
            <ContentTextWrapper>
              {!multipleDropzones ? (
                <IconWrapper>
                  <FileUploadIcon />
                </IconWrapper>
              ) : (
                <AddIconWrapper>
                  <AddAppItemIconContainer gridView={true}>
                    <img src="./images/rounded-plus.svg" alt="add-app button" />
                  </AddAppItemIconContainer>
                </AddIconWrapper>
              )}

              <div style={{ margin: "auto" }}>
                <TitleContent>{dropTitle}</TitleContent>
                <DescriptionContent>{dropDescription}</DescriptionContent>
                {!multipleDropzones ? <DescriptionContent>File: No file selected</DescriptionContent> : null}
                {error ? <ErrorContent>{error}</ErrorContent> : null}
              </div>
            </ContentTextWrapper>
          </TextDiv>
        </DropzoneInsideDiv>
      ) : (
        <>
          <DropzoneInsideDivWF>
            <TextDivWF>
              <ContentTextWrapper>
                <IconWrapperWF>
                  <SentForApprovalIcon />
                </IconWrapperWF>
                <div style={{ margin: "auto" }}>
                  <TitleContent hasFile={fileName !== ""}>{fileName}</TitleContent>
                  <DescriptionContent>{dropDescription}</DescriptionContent>
                  {error ? <ErrorContent>{error}</ErrorContent> : null}
                </div>
              </ContentTextWrapper>
              {!multipleDropzones ? (
                <ReplaceFileWrapper id="replaceFileAbsolute" absolutePosition={false}>
                  <ReplaceFileButton>{t("pages.serviceBuilder.modals.fileDropzone.replaceFile")}</ReplaceFileButton>
                </ReplaceFileWrapper>
              ) : null}
            </TextDivWF>
            {multipleDropzones ? (
              <ReplaceFileWrapper id="replaceFileAbsolute" absolutePosition={true}>
                <ReplaceFileButton>{t("pages.serviceBuilder.modals.fileDropzone.replaceFile")}</ReplaceFileButton>
              </ReplaceFileWrapper>
            ) : null}
          </DropzoneInsideDivWF>
        </>
      )}
    </DropzoneDiv>
  );
};

export default Dropzone;

const DropzoneDiv = styled("div")<{ active: boolean; height: string; hasFile: boolean; hasMinWidth?: boolean }>`
  height: ${props => props.height};
  border-radius: 11px;
  background-color: #ffffff;
  cursor: pointer;
  padding: ${props => (props.hasFile ? "25px 9px" : "22px 20px 22px 22px")};
  display: flex;
  transition: height 0.25s ease-in-out;
  border: 1px dashed #999999;
  ${props => (props.active ? `background-color: rgba(204,204,204,0.5);border: 1px dashed #6d7278;` : null)};
  ${props =>
    props.hasFile
      ? `
  border-radius: 8px;box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);background-color: var(--white); border:none;`
      : null}
  ${props => (props.hasMinWidth ? "min-width: 310px;" : null)}
  :hover {
    > div > div:nth-of-type(2) {
      opacity: 1;
    }
  }
`;

const DropzoneInput = styled("input")``;

const DropzoneInsideDiv = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: Vodafone Rg;
  margin: auto;
  align-items: center;
`;

const DropzoneInsideDivWF = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: Vodafone Rg;
  margin: auto;
  overflow: visible;
`;

const TextDiv = styled("div")`
  background-color: transparent;
  display: flex;
  height: 100%;
`;

const TextDivWF = styled("div")`
  background-color: transparent;
  display: flex;
  height: 100%;
`;

const IconWrapper = styled("div")<{ active?: boolean }>`
  svg {
    height: 100%;
    width: ${props => (props.active ? "40px" : "70px")};

    stroke: ${props => (props.active ? props.theme.palette.vodafoneRed : props.theme.palette.midGrey)};
  }
  padding-right: 10px;
`;

const AddIconWrapper = styled("div")<{ active?: boolean }>`
  margin: auto;
`;

const IconWrapperWF = styled("div")<{ active?: boolean }>`
  svg {
    height: 76px;
    width: 76px;
  }
  padding-right: 10px;
`;

const ContentTextWrapper = styled("div")`
  display: flex;
`;

const TitleContent = styled("div")<{ active?: boolean; hasFile?: boolean }>`
  font-family: Vodafone Rg;
  color: ${props =>
    props.active ? "#000000" : props.hasFile ? props.theme.palette.darkGrey : props.theme.palette.midGrey};
  font-size: ${props => (props.hasFile ? "22px" : "20px")};
  ${props => (props.hasFile ? "line-height: 25px;" : null)};
  margin: auto;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
`;
const DescriptionContent = styled("div")<{ hasFile?: boolean }>`
  font-family: Vodafone Rg;
  color: ${props => (props.hasFile ? props.theme.palette.vodafoneRed : props.theme.palette.midGrey)};
  font-size: 16px;
  margin: auto;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
`;

const ErrorContent = styled("div")`
  font-family: Vodafone Rg;
  color: ${props => props.theme.palette.vodafoneRed};
  font-size: 12px;
  margin: auto;
`;

const ReplaceFileWrapper = styled("div")<{ absolutePosition?: boolean }>`
  min-width: 99px;
  width: 99px;
  height: 27px;
  border-radius: 20px;
  border: solid 1px #ebebeb;
  margin: auto;
  margin-right: 10px;
  ${props => (props.absolutePosition ? "position: relative; margin-right: 0px; margin-top: -93px; opacity: 0;" : null)}
`;

const ReplaceFileButton = styled("div")`
  font-family: Vodafone Rg;
  font-size: 14px;
  line-height: 1.36;
  letter-spacing: normal;
  color: #333333;
  padding: 3px 16px 5px 16px;
  margin: 0;
`;

const AddAppItemIconContainer = styled("div")<{ gridView: boolean }>`
  width: 45px;
  height: 45px;
  margin-bottom: 0px;
  margin-right: 24px;

  img {
    width: 45px;
    height: 45px;
  }
`;
