import { AxiosPromise } from "axios";
import ApiClient from "../../configs/axios.service-builder.config";
import SBApiClientBO from "../../configs/axios.config";

import { IServiceAddDetails } from "../../shared/models/service-builder.model";

const ServiceAddApi = {
  routes: {
    addService: "/service",
    getCanBeHighlighted: "/v1/ipg_services/canbehighlighted",
  },
  methods: {
    addService: (
      imageService: File | string,
      iconService: File | string,
      serviceToAdd: IServiceAddDetails,
    ): AxiosPromise<void> => {
      const data = new FormData();
      const image = new Blob([imageService], { type: "text/plain;charset=utf-8" });
      const icon = new Blob([iconService], { type: "text/plain;charset=utf-8" });
      data.append("imageService", image);
      data.append("iconService", icon);
      data.append("metadata", JSON.stringify(serviceToAdd));
      return ApiClient.post(ServiceAddApi.routes.addService, data);
    },
    getCanBeHighlighted: (): AxiosPromise<{ canBeHighlighted: boolean }> => {
      return SBApiClientBO.get(ServiceAddApi.routes.getCanBeHighlighted);
    },
  },
};

export default ServiceAddApi;
