import { EyeIcon, FastActionButton, MemberIcon } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import i18next from "i18next";
import moment from "moment";
import React from "react";
import { IconContainer, Row } from "../../../shared/shared.styled";
import { TableButtonsContainer } from "../../../shared/shared.utils";
import { TasksFiltersEnum } from "./components/tasks-filters.component";
import { ITask, TaskStatusEnum } from "./task.model";

export const getTasksColumns = (t: any) => {
  return [
    {
      formKey: "author.name",
      label: t("pages.myTasks.creator"),
      ratio: 1,
      isEditable: false,
      changeFunction: (content: string, row: ITask) => {
        return (
          <Row id={`${row.author.name}_${row.status}_${row.id}`}>
            <MemberIcon fullName={row.author.name ? row.author.name : row.author.id} />
            <span>{row.author.name ? row.author.name : row.author.id}</span>
          </Row>
        );
      },
    },
    {
      formKey: "section",
      label: t("pages.myTasks.section"),
      ratio: 1,
      isEditable: false,
      changeFunction: (content: string, row: ITask) => {
        return <span>{t(`pages.myTasks.sectionEnum.${row.section}`)}</span>;
      },
    },
    {
      formKey: "date",
      label: t("pages.myTasks.submissionDate"),
      ratio: 1,
      isEditable: false,
      changeFunction: (content: string, row: ITask) => {
        return <span>{moment(row.date).format("DD MMM YYYY")}</span>;
      },
    },
    {
      formKey: "status",
      label: t("pages.myTasks.status"),
      ratio: 1,
      isEditable: false,
      changeFunction: (content: string, row: ITask) => {
        if (content === TaskStatusEnum.PENDING_APPROVAL) {
          return (
            <span id={"pending-approval"} style={{ color: getTaskStatusColor(content) }}>
              {t(`pages.myTasks.statusEnum.${content.toLowerCase()}`)}
            </span>
          );
        }
        return (
          <span id={content.toLowerCase()} style={{ color: getTaskStatusColor(content) }}>
            {t(`pages.myTasks.statusEnum.${content.toLowerCase()}`)} {t(`pages.myTasks.by`)}{" "}
            {row.approver ? (row.approver.name ? row.approver.name : row.approver.id) : ""}
          </span>
        );
      },
    },
  ];
};
export const getTaskStatusColor = (status: string) => {
  switch (status) {
    case TaskStatusEnum.PENDING_APPROVAL:
      return styleTheme.palette.turquoiseBlue;
    case TaskStatusEnum.APPROVED:
      return styleTheme.palette.successGreen;
    case TaskStatusEnum.DECLINED:
      return styleTheme.palette.digitalRed;
  }
  return styleTheme.palette.black;
};
export const renderTableTasksButtons = <T extends unknown>(
  value: T,
  buttonActions: {
    seeDetails: { onClick: (row: T) => any };
  },
) => {
  return (
    <TableButtonsContainer>
      {(value as ITask).status === TaskStatusEnum.PENDING_APPROVAL ? (
        <div id={"see_details"}>
          <FastActionButton
            iconComponent={
              <IconContainer size={16} color={styleTheme.palette.turquoiseBlue}>
                <EyeIcon />
              </IconContainer>
            }
            onClick={() => buttonActions.seeDetails && buttonActions.seeDetails.onClick(value)}
            label={i18next.t("pages.myTasks.seeDetails")}
          />
        </div>
      ) : null}
    </TableButtonsContainer>
  );
};
const filterTasksByStatus = (tasks: ITask[], status?: string): ITask[] => {
  if (status) {
    return tasks.filter(t => t.status === status);
  }
  return tasks;
};

const filterTasksBySearch = (tasks: ITask[], searchString: string) => {
  if (!!searchString) {
    return tasks.filter(dc => dc.author.name.toLowerCase().includes(searchString.toLowerCase()));
  }
  return tasks;
};

/**
 * Filter an array of tasks using a search string and the status dropdown filter
 */
export const filterTasks = (tasks: ITask[], filters: Map<string, string[]>): ITask[] => {
  let newTasks: ITask[];
  const status = filters.get(TasksFiltersEnum.STATUS) as any;
  const search = filters.get(TasksFiltersEnum.SEARCH);

  newTasks = filterTasksByStatus(tasks, status ? status[0] : undefined);

  if (search) {
    newTasks = filterTasksBySearch(newTasks, search[0]);
  }

  return newTasks;
};
