import React, { ReactNode, useContext } from "react";
import {
  Table,
  useAlert,
  FastActionButton,
  CancelIcon,
  useModal,
  ModalTypeEnum,
  useFilters,
} from "@wit/mpesa-ui-components";
import TablePagination from "../../../../../shared/components/table-pagination/table-pagination-component";
import { IconContainer } from "../../../../../shared/shared.styled";
import { TableButtonsContainer } from "../../../../../shared/shared.utils";
import { RequestPaymentUtils } from "../../request-payment.utils";
import { useTranslation } from "react-i18next";
import { IBlockedTillNumber, IBlacklistParameters } from "../../request-payment.interfaces";
import RequestPaymentApi from "../../request-payment.api";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import i18next from "i18next";
import { ThemeContext } from "styled-components";
import { LoadingText } from "../../../../../shared/shared.styled";
import BlacklistFilters from "./blacklist-filters.component";

import { BlacklistFilterKeys } from "./blacklist-filters.component";
import BlacklistNewNumber from "./blacklist-new-number.component";
import SafaricomDeleteModal from "../../../../../shared/components/safaricom-delete-modal/safaricom-delete-modal.component";

/**
 * BlacklistTab component
 */
const BlacklistTab = () => {
  const [t] = useTranslation();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const [blacklist, setBlacklist] = React.useState<IBlockedTillNumber[]>([]);
  const [isFetchingBlacklist, setIsFetchingBlacklist] = React.useState(true);
  const themeContext = useContext(ThemeContext);
  const [requestParams, setRequestParams] = React.useState<IBlacklistParameters>({ page: 1, pageSize: 20 });
  const [page, setActivePage] = React.useState(1);
  const pageSize = 20;
  const [totalBlockedNumbers, setTotalBlockedNumbers] = React.useState(0);
  const [removeBlacklistModal, setRemoveBlacklistModal] = React.useState<ReactNode>();
  const [showRemoveBlacklistModal, hideRemoveBlacklistModal] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    removeBlacklistModal,
  );
  const {
    filters,
    updateFilter,
    isFilterActive,
    clearFilter,
    resetFilters,
    getFilterValue,
    getFiltersQueryString,
    updateMultipleFilters,
  } = useFilters();

  /**
   *  Get blacklist method
   */
  const getBlacklist = () => {
    setIsFetchingBlacklist(true);
    RequestPaymentApi.methods
      .getBlacklist(requestParams)
      .finally(() => setIsFetchingBlacklist(false))
      .then(
        res => {
          const blacklist = res.data.blacklistedTills as IBlockedTillNumber[];
          setTotalBlockedNumbers(res.data.totalCount);
          setBlacklist(blacklist);
        },
        () => {
          setAlertProps({
            title: t("pages.tools.requestPayment.blacklist.getBlacklistError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
  };

  /**
   * Remove number from blacklist method
   * @param numberToRemove
   */
  const removeFromBlacklist = (numberToRemove: IBlockedTillNumber) => {
    hideRemoveBlacklistModal();
    setIsFetchingBlacklist(true);
    RequestPaymentApi.methods.removeFromBlacklist(numberToRemove, requestParams).then(
      res => {
        setAlertProps({
          title: t("pages.tools.requestPayment.blacklist.removeFromListSuccess"),
          type: AlertTypeEnum.SUCCESS,
        });
        showAlert();
        getBlacklist();
      },
      () => {
        setIsFetchingBlacklist(false);
        setAlertProps({
          title: t("pages.tools.requestPayment.blacklist.removeFromListError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * Handles the pagination change
   * @param pageNumber
   */
  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
  };

  /**
   * Modal to remove a number from blacklist
   * @param blockedTillNumber
   */
  const showRemoveFromBlacklistModal = (blockedTillNumber: IBlockedTillNumber) => {
    setRemoveBlacklistModal(
      <SafaricomDeleteModal
        secondaryAction={() => hideRemoveBlacklistModal()}
        primaryAction={() => removeFromBlacklist(blockedTillNumber)}
        title={t("pages.tools.requestPayment.blacklist.modal.removeFromListConfirmationTitle")}
        description={t("pages.tools.requestPayment.blacklist.modal.removeFromListConfirmationMessage", {
          number: blockedTillNumber.tillNumber,
        })}
        primaryBtnLabel={t("pages.tools.requestPayment.blacklist.modal.remove")}
        primaryBtnId="remove-button"
        secondaryBtnId="cancel-button"
      />,
    );
    showRemoveBlacklistModal();
  };

  /**
   * refresh results
   */
  const refreshResults = () => {
    getBlacklist();
  };

  /**
   * function to get reported users filters
   * @param filterKey
   */
  const getReportedUsersFilterKey = (filterKey: BlacklistFilterKeys) => {
    if (filters.get(filterKey)) {
      return { [filterKey]: (filters.get(filterKey) as string[])[0] };
    }
    return null;
  };

  /**
   * function to handle on added new number
   */
  const onAddedNewTillNumber = () => {
    setActivePage(1);
    setAlertProps({
      title: t("pages.tools.requestPayment.blacklist.newBlacklistContact.newNumberSuccess"),
      type: AlertTypeEnum.SUCCESS,
    });
    showAlert();
    if (page === 1) {
      getBlacklist();
    }
  };

  React.useEffect(() => {
    setRequestParams({ ...requestParams, page: page });
  }, [page]);

  React.useEffect(() => {
    const request: IBlacklistParameters = {
      page: 1,
      pageSize,
      ...getReportedUsersFilterKey(BlacklistFilterKeys.BLOCKED_TILL_NUMBER),
    };
    setActivePage(1);
    setRequestParams(request);
  }, [filters]);

  React.useEffect(() => {
    if (requestParams) {
      getBlacklist();
    }
  }, [requestParams]);

  return (
    <>
      <BlacklistNewNumber requestParams={requestParams} onAddedNewTillNumber={onAddedNewTillNumber} />
      <BlacklistFilters
        filters={filters}
        isFilterActive={isFilterActive}
        updateFilter={updateFilter}
        clearFilter={clearFilter}
        resetFilters={resetFilters}
        getFilterValue={getFilterValue}
        getFiltersQueryString={getFiltersQueryString}
        updateMultipleFilters={updateMultipleFilters}
        refreshResults={refreshResults}
      ></BlacklistFilters>
      {isFetchingBlacklist ? (
        <LoadingText>{t("commons.loadingResults")}</LoadingText>
      ) : (
        <>
          <Table
            values={blacklist}
            columns={RequestPaymentUtils.getBlacklistColumns()}
            displayActions={blockedTillNumber =>
              renderFastActions<IBlockedTillNumber>(
                blockedTillNumber,
                {
                  removeFromBlacklist: { onClick: showRemoveFromBlacklistModal },
                },
                themeContext,
              )
            }
          />
          {totalBlockedNumbers > pageSize ? (
            <TablePagination
              handlePageChange={handlePageChange}
              totalItems={totalBlockedNumbers}
              activePage={page}
              pageSize={pageSize}
            />
          ) : null}
        </>
      )}
    </>
  );
};

/**
 * Row fast actions
 * @param value
 * @param buttonActions
 * @param themeContext
 * @returns
 */
const renderFastActions = <T extends IBlockedTillNumber>(
  value: T,
  buttonActions: {
    removeFromBlacklist: { onClick: (row: T) => any };
  },
  themeContext: any,
) => {
  return (
    <TableButtonsContainer id={`remove-blacklist-${value.tillNumber}`}>
      <FastActionButton
        iconComponent={
          <IconContainer size={16} color={themeContext.palette.errorRed}>
            <CancelIcon />
          </IconContainer>
        }
        onClick={() => buttonActions.removeFromBlacklist.onClick(value)}
        label={i18next.t("pages.tools.requestPayment.blacklist.removeFromBlacklist")}
      />
    </TableButtonsContainer>
  );
};

export default BlacklistTab;
