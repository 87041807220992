import React, { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import ServiceDetailsComponent from "../service-details.component";
import ServiceDetailsApi from "../service-details.api";
import { useDispatch, useSelector } from "react-redux";
import { IStoreInterface } from "../../../configs/store.config";
import { ServiceDetailsActions } from "../service-details.store";
import { useTranslation } from "react-i18next";
import { useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { UserRolesDefault, UserRolesVDF } from "../../admin/users/users.interfaces";
import { ILoggedUser } from "../../authentication/authentication.interfaces";

/**
 * Service details page
 */
const ServiceDetailsPage = () => {
  const [t] = useTranslation();
  const { serviceId } = useParams<any>();
  const dispatch = useDispatch();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const history = useHistory();
  // Redux states
  const { serviceDetails, serviceVersions, isLoadingServiceVersions, isLoadingServiceDetails } = useSelector(
    (state: IStoreInterface) => state.serviceDetailsReducer,
  );

  const { role } = useSelector((state: IStoreInterface) => state.authenticationReducer.loggedUser) as ILoggedUser;

  const [showAdminActions, setShowAdminActions] = React.useState(false);

  const refreshPage = useCallback(() => {
    dispatch(ServiceDetailsActions.creators.fetchingServiceDetailsAction());
    dispatch(ServiceDetailsActions.creators.fetchingServiceVersionsAction());
    ServiceDetailsApi.methods.getServiceDetails(serviceId).then(
      res => {
        dispatch(ServiceDetailsActions.creators.fetchServiceDetailsSuccessAction(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.serviceBuilder.errors.noServiceDetails"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        history.push("/service-builder");
      },
    );
    ServiceDetailsApi.methods.getServiceVersions(serviceId).then(
      res => {
        dispatch(ServiceDetailsActions.creators.fetchServiceVersionsSuccessAction(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.serviceBuilder.errors.noServiceVersions"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        history.push("/service-builder");
      },
    );
  }, [dispatch, serviceId, setAlertProps, showAlert, t]);

  React.useEffect(() => {
    refreshPage();
    return () => {
      dispatch(ServiceDetailsActions.creators.fetchingServiceDetailsLeaveAction());
    };
  }, [dispatch, refreshPage, serviceId, setAlertProps, showAlert, t]);

  React.useEffect(() => {
    if (
      role === UserRolesDefault.ADMIN ||
      role === UserRolesVDF.ADMIN_SERVICE_MANAGEMENT ||
      role === UserRolesVDF.EDITOR_SERVICE_MANAGEMENT ||
      role === UserRolesDefault.APPROVER ||
      role === UserRolesVDF.APPROVER_SERVICE_MANAGEMENT
    ) {
      setShowAdminActions(true);
    } else {
      setShowAdminActions(false);
    }
  }, [role]);
  return (
    <>
      {isLoadingServiceDetails ||
      isLoadingServiceVersions ||
      isLoadingServiceVersions === undefined ||
      isLoadingServiceVersions === undefined ? (
        "Loading..."
      ) : (
        <ServiceDetailsComponent
          showAdminActions={showAdminActions}
          serviceDetails={serviceDetails}
          serviceVersions={serviceVersions}
          refreshPage={refreshPage}
        ></ServiceDetailsComponent>
      )}
    </>
  );
};

export default ServiceDetailsPage;
