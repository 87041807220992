import React from "react";
import { PageContainer, PageTitle, ListHeader, ListHeaders, LoadingText } from "../../../shared/shared.styled";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IStoreInterface } from "../../../configs/store.config";
import { ServicesActions } from "../services.store";
import ServicesApi from "../services.api";
import ServiceGridItem from "../components/service-grid-item.component";
import styled from "styled-components";
import AddServiceItem from "../components/add-service-item.component";
import ServicesFilters from "../components/services-filters.component";
import ServiceListItem from "../components/service-list-item.component";
import { useAlert, useFilters } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { filterServices } from "../services.utils";
import RendererUtils from "../../../shared/renderer.utils";
import { UserRolesVDF } from "../../admin/users/users.interfaces";
import { ILoggedUser } from "../../authentication/authentication.interfaces";
import { SelectedView } from "../../../shared/shared.enums";
import EmptySearchResult from "../../../shared/components/empty-search-result.component";

/**
 * Services page
 * @returns
 */
const ServicesPage = () => {
  // Hooks initializations
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const {
    filters,
    updateFilter,
    isFilterActive,
    clearFilter,
    resetFilters,
    getFilterValue,
    getFiltersQueryString,
    updateMultipleFilters,
  } = useFilters();

  // Redux states
  const { services, isLoadingServices } = useSelector((state: IStoreInterface) => state.servicesReducer);
  const loggedUser = useSelector((state: IStoreInterface) => state.authenticationReducer.loggedUser) as ILoggedUser;

  // Local states
  const [selectedView, setSelectedView] = React.useState<SelectedView>(SelectedView.GRID);
  const [showableServices, setShowableServices] = React.useState(services);

  // Effects
  React.useEffect(() => {
    setShowableServices(services);
  }, [services]);

  React.useEffect(() => {
    dispatch(ServicesActions.creators.fetchingServicesAction());
    ServicesApi.methods.getServices().then(
      res => {
        dispatch(ServicesActions.creators.fetchServicesSuccessAction(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.serviceBuilder.errors.searchServices"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  }, [dispatch, t, showAlert, setAlertProps]);

  React.useEffect(() => {
    if (filters.size > 0) {
      setShowableServices(filterServices(services, filters));
    } else {
      setShowableServices(services);
    }
  }, [filters, services]);

  function showNoResults(): boolean {
    if (showableServices.length === 0) {
      if (
        filters.size === 0 ||
        (filters.size === 1 && filters.get("search") !== undefined && (filters.get("search") as string[])[0] === "")
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  /* // Functions
  function changeFilterCb(filters: ServiceFilters) {
    if (isServiceFiltersActive(filters)) {
      setShowableServices(filterServices(services, filters));
    } else {
      setShowableServices(services);
    }
  } */

  function getServiceUser() {
    return RendererUtils.checkRolePermission(
      [
        UserRolesVDF.ADMIN_SERVICE_MANAGEMENT,
        UserRolesVDF.EDITOR_SERVICE_MANAGEMENT,
        UserRolesVDF.APPROVER_SERVICE_MANAGEMENT,
      ],
      loggedUser,
    );
  }

  function renderGridView() {
    const serviceUser = getServiceUser();
    return (
      <ServiceGridItemsContainer>
        {showableServices !== services || !serviceUser ? null : <AddServiceItem selectedView={selectedView} />}
        {showableServices.map(service => (
          <ServiceGridItem key={service.id} item={service} disabled={!serviceUser} />
        ))}
      </ServiceGridItemsContainer>
    );
  }

  function renderListView() {
    const serviceUser = getServiceUser();
    return (
      <ServiceListItemsContainer>
        <ListHeaders>
          <ListHeader ratio={0.5 / 12} style={{ minWidth: 80 }}></ListHeader>
          <ListHeader ratio={5 / 12} style={{ marginRight: 24 }}>
            {t("pages.serviceBuilder.listHeaders.serviceName")}
          </ListHeader>
          <ListHeader ratio={5 / 12} style={{ marginRight: 24 }}>
            {t("pages.serviceBuilder.listHeaders.serviceDescription")}
          </ListHeader>

          <ListHeader ratio={1.5 / 12}>{t("pages.serviceBuilder.listHeaders.status")}</ListHeader>
        </ListHeaders>
        {showableServices !== services || !serviceUser ? null : <AddServiceItem selectedView={selectedView} />}
        {showableServices.map(service => (
          <ServiceListItem key={service.id} item={service} disabled={!serviceUser} />
        ))}
      </ServiceListItemsContainer>
    );
  }

  return (
    <PageContainer>
      <PageTitle>{t("pages.serviceBuilder.title")}</PageTitle>
      {isLoadingServices ? (
        <LoadingText>{t("commons.loadingResults")}</LoadingText>
      ) : (
        <>
          <ServicesFilters
            filters={filters}
            isFilterActive={isFilterActive}
            updateFilter={updateFilter}
            clearFilter={clearFilter}
            resetFilters={resetFilters}
            getFilterValue={getFilterValue}
            getFiltersQueryString={getFiltersQueryString}
            updateMultipleFilters={updateMultipleFilters}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
          />
          {showNoResults() ? (
            <EmptySearchResult />
          ) : selectedView === SelectedView.GRID ? (
            renderGridView()
          ) : (
            renderListView()
          )}
        </>
      )}
    </PageContainer>
  );
};

export default ServicesPage;

const ServiceGridItemsContainer = styled("div")`
  margin-top: 49px;
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  column-gap: 24px;
  row-gap: 24px;
  flex: 1;
  margin-bottom: 45px;
`;

const ServiceListItemsContainer = styled("ul")`
  margin-left: 0;
  padding-left: 0;
`;
