import { SearchBar } from "@wit/mpesa-ui-components";
import { FilterFuncts } from "@wit/mpesa-ui-components/lib/hooks/use-filters.hook";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { SearchBarFilterContainer } from "../../../../shared/responsive-ui.styled";
import { Row } from "../../../../shared/shared.styled";

export enum AllowedScopesFilterEnum {
  SEARCH = "search",
}

/**
 * AllowedScopesFilters component
 */
const AllowedScopesFilters = ({ filters, clearFilter, isFilterActive, resetFilters, updateFilter }: FilterFuncts) => {
  const [t] = useTranslation();

  return (
    <AllowedScopesFilterContainer>
      <SearchBarFilterContainer id={"search-allowed-scopes"}>
        <SearchBar
          placeholderLabel={t("pages.serviceManager.allowedScopes.searchPlaceholder")}
          value={
            filters.has(AllowedScopesFilterEnum.SEARCH)
              ? (filters.get(AllowedScopesFilterEnum.SEARCH) as string[])[0]
              : ""
          }
          onChange={e => {
            if (e.target.value) {
              updateFilter(AllowedScopesFilterEnum.SEARCH, e.target.value, true);
            } else {
              clearFilter(AllowedScopesFilterEnum.SEARCH);
            }
          }}
          clearValue={() => clearFilter(AllowedScopesFilterEnum.SEARCH)}
        />
      </SearchBarFilterContainer>
    </AllowedScopesFilterContainer>
  );
};

export default AllowedScopesFilters;

const AllowedScopesFilterContainer = styled(Row)`
  margin-bottom: 40px;
  margin-top: 40px;
  width: 100%;
`;
