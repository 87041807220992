export interface IUpdateServiceRequest {
  name?: string;
  description: string;
  color: string;
  allowedAgentRoles?: string[];
  allowedMerchantRoles?: string[];
  nameTranslations?: {
    [lang: string]: string;
  };
  descriptionTranslations?: {
    [lang: string]: string;
  };
}

export interface IUpdateServiceDetails {
  serviceDetailsId: string;
  imageFile: File;
  iconFile: File;
  updateDetails: IUpdateServiceRequest;
  values: any;
}
export enum ServiceInputTabNumber {
  NAME = 0,
  DESCRIPTION = 1,
}
