import { SharedNestedOptionDropdown } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-nested-option-container/shared-dropdown-nested-option-container.component";
import { FilterFuncts } from "@wit/mpesa-ui-components/lib/hooks/use-filters.hook";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../../app.component";
import { ServiceManagerFiltersEnum } from "./service-manager-filters.component";

/**
 * Service groups utils methods
 * @function useServiceGroupsFilter
 */
const useServiceGroupsFilter = () => {
  const { config } = useContext(ConfigContext);
  const { t } = useTranslation();

  /**
   * Returns a list of unique service types
   */
  const _getUniqueServiceTypes = () => {
    return config!.serviceGroups!.map(s => s.serviceType).filter((val, idx, self) => self.indexOf(val) === idx);
  };

  /**
   * Returns the list of nested service groups (user type)
   * @param serviceType
   */
  const _getNestedServiceGroups = (serviceType: string): SharedNestedOptionDropdown[] => {
    const serviceGroups = config!.serviceGroups!.filter(s => s.serviceType === serviceType);
    return serviceGroups.map(s => {
      return {
        key: s.id,
        label: s.label,
      };
    });
  };

  /**
   * Removes parent options that are selected and don't have any selected children
   * @param filters
   */
  const _removeParentsWithoutChildren = (filters: string[]) => {
    const parents = _getUniqueServiceTypes();
    parents.forEach(p => {
      let hasChildren = false;
      config!.serviceGroups!.forEach(s => {
        if (s.serviceType === p && filters.includes(s.value)) {
          hasChildren = true;
        }
      });
      if (!hasChildren) {
        const valueIndex = filters.findIndex(v => v === p);
        if (valueIndex > -1) {
          filters.splice(valueIndex, 1);
        }
      }
    });
  };

  /**
   * Removes all the children of a parent if they are all selected
   * @param opt
   * @param values
   */
  const _removeAllChildrenSelected = (opt: SharedNestedOptionDropdown, values: string[]) => {
    // It's a parent
    if (_getUniqueServiceTypes().includes(opt.key)) {
      const childrenOptions = _getNestedServiceGroups(opt.key).map(o => o.key);
      if (childrenOptions.every(child => values.includes(child)) && !values.includes(opt.key)) {
        childrenOptions.forEach(child => {
          const valueIndex = values.findIndex(v => v === child);
          if (valueIndex > -1) {
            values.splice(valueIndex, 1);
          }
        });
      }
    }
  };

  /**
   * Checks at a given time the parent has all its children selected
   * If it does, it pushes its key to the filters array if it's not there already
   * @param opt
   * @param values
   */
  const _areAllChildrenSelected = (opt: SharedNestedOptionDropdown, values: string[]) => {
    const serviceTypes = _getUniqueServiceTypes();
    // it's a child
    if (!serviceTypes.includes(opt.key)) {
      serviceTypes.forEach(s => {
        const childrenOptions = _getNestedServiceGroups(s).map(o => o.key);
        if (childrenOptions.every(child => values.includes(child)) && !values.includes(s)) {
          values.push(s);
        }
      });
    }
  };

  /**
   * Returns the label value of a given child key
   * @param child
   */
  const _getChildLabel = (child: string) => {
    return config!.serviceGroups!.find(s => s.id === child)?.label;
  };

  /**
   * Returns the list of services group options
   */
  const getServicesGroupOptions = (): SharedNestedOptionDropdown[] => {
    return _getUniqueServiceTypes().map(s => {
      return {
        key: s,
        label: t(`commons.serviceBuilder.enums.servicesType.${s}`),
        children: _getNestedServiceGroups(s),
        openOptions: true,
      };
    });
  };

  /**
   * Manually toggles the value of a nested option inside the filters Map
   * This is done here and not in the lib, so it doesn't interfere with the default behaviour that was specified for multiple nested dropdowns - which the default use case was not for filtering
   * @param filters
   * @param updateMultipleFilters
   * @param opt
   */
  const toggleServiceGroupOption = (
    filters: FilterFuncts["filters"],
    updateMultipleFilters: FilterFuncts["updateMultipleFilters"],
    opt: SharedNestedOptionDropdown,
  ) => {
    if (filters.has(ServiceManagerFiltersEnum.SERVICES_TYPES)) {
      const values = filters.get(ServiceManagerFiltersEnum.SERVICES_TYPES) as string[];
      const valueIndex = values.findIndex(v => v === opt.key);
      if (valueIndex > -1) {
        if (!_getUniqueServiceTypes().includes(opt.key)) {
          values.splice(valueIndex, 1);
        } else {
          // if it's a parent, we don't want to remove it - we want to remove all the children instead;
          // the parent itself will be removed at last using _removeParentsWithoutChildren
          _removeAllChildrenSelected(opt, values);
        }
      } else {
        values.push(opt.key);
      }
      if (values.length === 0) {
        filters.delete(ServiceManagerFiltersEnum.SERVICES_TYPES);
      } else {
        filters.set(ServiceManagerFiltersEnum.SERVICES_TYPES, values);
      }
      _areAllChildrenSelected(opt, values);
      _removeParentsWithoutChildren(values);
    } else {
      filters.set(ServiceManagerFiltersEnum.SERVICES_TYPES, [opt.key]);
    }
    updateMultipleFilters([
      {
        key: ServiceManagerFiltersEnum.SERVICES_TYPES,
        values: (filters.get(ServiceManagerFiltersEnum.SERVICES_TYPES) as string[]) ?? [],
      },
    ]);
  };

  /**
   * Verifies if an option is selected
   * @param isFilterActive
   * @param opt
   */
  const isOptionSelected = (isFilterActive: FilterFuncts["isFilterActive"], opt: SharedNestedOptionDropdown) => {
    return isFilterActive(ServiceManagerFiltersEnum.SERVICES_TYPES, opt.key);
  };

  /**
   * Returns an accumulative string of the selected service groups
   * @param filters
   * @param defaultLabel
   */
  const getSelectedLabels = (filters: FilterFuncts["filters"], defaultLabel: string) => {
    if (filters.get(ServiceManagerFiltersEnum.SERVICES_TYPES)) {
      const values = filters.get(ServiceManagerFiltersEnum.SERVICES_TYPES) as string[];
      return values
        .map(v => {
          if (!_getUniqueServiceTypes().includes(v)) {
            return _getChildLabel(v);
          }
        })
        .join(", ");
    } else {
      return defaultLabel;
    }
  };

  return { getServicesGroupOptions, toggleServiceGroupOption, isOptionSelected, getSelectedLabels };
};

export default useServiceGroupsFilter;
