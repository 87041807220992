import {
  MultipleOptionsDropdown,
  DateRangeDropdown,
  RecurringIcon,
  SearchBarWithDropdown,
  SmallButton,
  UndoIcon,
  DownloadIcon,
  ListViewIcon,
  CheckIcon,
} from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { FilterFuncts } from "@wit/mpesa-ui-components/lib/hooks/use-filters.hook";
import { useTranslation } from "react-i18next";
import React from "react";
import styled from "styled-components";
import { RatioContainer, Row } from "../../../../shared/shared.styled";
import { onEnterPressed } from "../doc-validation.utils";
import { ClientDocSubmissionStatusEnum } from "../../ekyc.shared.enums";

export enum DocValidationFilterKeys {
  SEARCH = "search",
  STATUS = "requestStatus",
  START_DATE = "startDate",
  END_DATE = "endDate",
  SEARCH_PARAM = "searchParam",
}

interface IDocValidationFiltersProps extends FilterFuncts {
  entriesCount: number;
  refreshResults: () => void;
  downloadCsv?: () => void;
  createBulk?: () => void;
  exportRegistrations: () => void;
}

const DocValidationFilters = ({
  filters,
  clearFilter,
  isFilterActive,
  resetFilters,
  updateFilter,
  updateMultipleFilters,
  entriesCount,
  refreshResults,
  downloadCsv,
  createBulk,
  exportRegistrations,
}: IDocValidationFiltersProps) => {
  const [t] = useTranslation();

  const getSearchBarOptions = () => {
    return [
      { key: "all", label: t("pages.docValidation.filters.freeTextTargets.all") },
      { key: "duplicates", label: t("pages.docValidation.filters.freeTextTargets.duplicates") },
    ];
  };

  return (
    <>
      <CustomRow>
        {/* Free text search */}
        <RatioContainer ratio={4 / 12}>
          <SearchBarWithDropdown
            onKeyDown={e => {
              onEnterPressed(e, refreshResults);
            }}
            options={getSearchBarOptions()}
            selectOption={opt => {
              updateFilter(DocValidationFilterKeys.SEARCH_PARAM, opt.key, true);
            }}
            label={
              filters.get(DocValidationFilterKeys.SEARCH_PARAM)
                ? t(
                    `pages.docValidation.filters.freeTextTargets.${
                      (filters.get(DocValidationFilterKeys.SEARCH_PARAM) as string[])[0]
                    }`,
                  )
                : t("pages.docValidation.filters.freeTextTargets.all")
            }
            hasValue={false}
            value={
              filters.has(DocValidationFilterKeys.SEARCH)
                ? (filters.get(DocValidationFilterKeys.SEARCH) as string[])[0]
                : ""
            }
            onChange={e => updateFilter(DocValidationFilterKeys.SEARCH, e.target.value, true)}
            clearValue={() => {
              clearFilter(DocValidationFilterKeys.SEARCH);
            }}
            placeholderLabel={t("pages.docValidation.filters.search")}
          />
        </RatioContainer>
        {/* Date search */}
        <RatioContainer ratio={2 / 12}>
          <DateRangeDropdown
            filters={filters}
            isFilterActive={isFilterActive}
            clearFilter={clearFilter}
            updateFilter={updateFilter}
            updateMultipleFilters={updateMultipleFilters}
            translations={{
              dropdownPlaceholder: t("pages.docValidation.filters.dropdownPlaceholder"),
              clearFilters: t("pages.docValidation.filters.dropdownClear"),
              CUSTOM: t("pages.docValidation.filters.custom"),
            }}
          />
        </RatioContainer>
        {/* Status search */}
        <RatioContainer ratio={2 / 12}>
          <MultipleOptionsDropdown
            options={Object.keys(ClientDocSubmissionStatusEnum)
              .filter(s => typeof s === "string" && s !== "UNKNOWN" && s !== "NOT_VALIDATED")
              .map(status => {
                return {
                  label: t(`commons.clientDocSubmissionStatus.${status}`),
                  key: status,
                };
              })}
            toggleOption={opt => updateFilter(DocValidationFilterKeys.STATUS, opt.key)}
            isOptionSelected={opt => isFilterActive(DocValidationFilterKeys.STATUS, opt.key)}
            clearAllFilters={() => clearFilter(DocValidationFilterKeys.STATUS)}
            hasValue={!!filters.get(DocValidationFilterKeys.STATUS)}
            label={t("pages.docValidation.filters.status")}
            clearAllFiltersLabel={t("pages.docValidation.filters.clearAllFiltersLabel")}
          ></MultipleOptionsDropdown>
        </RatioContainer>

        {/* Search submit button */}
        <div>
          <SmallButton
            titleLabel={t("pages.docValidation.filters.applyFilters")}
            onClick={() => refreshResults()}
            iconComponent={
              <SmallButtonIconContainer color={styleTheme.palette.vodafoneRed}>
                <CheckIcon />
              </SmallButtonIconContainer>
            }
          />
        </div>
      </CustomRow>

      <Row style={{ marginBottom: 12 }}>
        {/* Records counter */}
        <DocValidationSubmissionsCount>
          <span>{entriesCount}</span>
          &nbsp;
          {`${t("pages.docValidation.filters.recordsFound")}`}
        </DocValidationSubmissionsCount>

        {/* Clear filters and query for all registers*/}
        <div style={{ marginRight: 24 }}>
          <SmallButton
            titleLabel={t("pages.docValidation.filters.refreshResults")}
            onClick={() => refreshResults()}
            iconComponent={
              <SmallButtonIconContainer color={styleTheme.palette.turquoiseBlue}>
                <RecurringIcon />
              </SmallButtonIconContainer>
            }
          />
        </div>
        <div style={{ marginRight: 24 }}>
          <SmallButton
            titleLabel={t("pages.docValidation.filters.clearFilters")}
            onClick={() => setTimeout(() => resetFilters(), 0)}
            iconComponent={
              <SmallButtonIconContainer color={styleTheme.palette.vodafoneRed}>
                <UndoIcon />
              </SmallButtonIconContainer>
            }
          />
        </div>
        <div>
          <SmallButton
            titleLabel={t("pages.docValidation.filters.export")}
            onClick={exportRegistrations}
            iconComponent={
              <SmallButtonIconContainer color={styleTheme.palette.vodafoneRed}>
                <DownloadIcon />
              </SmallButtonIconContainer>
            }
          />
        </div>
        {createBulk ? (
          <div style={{ marginLeft: "auto" }}>
            <SmallButton
              titleLabel={t("pages.docValidation.bulk.createBulkBtnLabel")}
              onClick={() => createBulk()}
              iconComponent={
                <SmallButtonIconContainer color={styleTheme.palette.vodafoneRed}>
                  <ListViewIcon />
                </SmallButtonIconContainer>
              }
            />
          </div>
        ) : (
          <></>
        )}
        {downloadCsv ? (
          <div style={{ marginLeft: createBulk ? 24 : "auto" }}>
            <SmallButton
              titleLabel={t("pages.docValidation.exportBtnLabel")}
              onClick={() => downloadCsv()}
              iconComponent={
                <SmallButtonIconContainer color={styleTheme.palette.vodafoneRed}>
                  <DownloadIcon />
                </SmallButtonIconContainer>
              }
            />
          </div>
        ) : (
          <></>
        )}
      </Row>
    </>
  );
};

export default DocValidationFilters;

const CustomRow = styled(Row)`
  margin-bottom: 18px;

  > ${RatioContainer} {
    margin-right: 24px;

    :last-of-type {
      margin-right: 0;
    }
  }
`;

const DocValidationSubmissionsCount = styled("div")`
  display: flex;
  flex-direction: row;
  margin-right: 24px;
  font-family: Vodafone Rg;
  font-size: 14px;
  color: ${props => props.theme.palette.midGrey};

  > span {
    font-weight: bold;
  }
`;

const SmallButtonIconContainer = styled("div")<{ color: string }>`
  width: 18px;
  height: 18px;
  stroke: ${props => props.color};

  svg {
    width: 18px;
    height: 18px;
    stroke: ${props => props.color};
  }
`;
