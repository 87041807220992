import React, { useContext } from "react";
import { Row } from "../../../../../shared/shared.styled";
import { Formik, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import { TextInput, PrimaryButton, useAlert } from "@wit/mpesa-ui-components";
import styled from "styled-components";
import { IBlacklistParameters, IBlockedTillNumber } from "../../request-payment.interfaces";
import { object, string } from "yup";
import i18next from "i18next";
import RequestPaymentApi from "../../request-payment.api";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { THEMES } from "../../../../../shared/renderer.utils";
import { ConfigContext } from "../../../../../app.component";

interface IBlacklistNewNumberProps {
  onAddedNewTillNumber: () => void;
  requestParams: IBlacklistParameters;
}

/**
 * validation schema for the till number
 */
const getValidationSchema = () => {
  return object().shape({
    tillNumber: string().required(
      i18next.t("pages.tools.requestPayment.blacklist.newBlacklistContact.emptyInputError"),
    ),
  });
};

/**
 * Blacklist new number component
 */
const BlacklistNewNumber = ({ onAddedNewTillNumber, requestParams }: IBlacklistNewNumberProps) => {
  const [t] = useTranslation();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const [numberAlreadyExist, setNumberAlreadyExist] = React.useState(false);

  const { config } = useContext(ConfigContext);

  /**
   * function to validate phone number
   * @param tillNumber
   */
  const validateTillFormat = (tillNumber: string) => {
    const regex = /^\d+$/;
    return tillNumber.match(regex);
  };

  /**
   * function to block new number
   * @param values
   * @param actions
   */
  const addNewBlockedNumber = (values: IBlockedTillNumber, actions: FormikHelpers<IBlockedTillNumber>) => {
    if (validateTillFormat(values.tillNumber)) {
      actions.setSubmitting(true);
      setNumberAlreadyExist(false);
      RequestPaymentApi.methods
        .addToBlacklist(values, requestParams)
        .finally(() => actions.setSubmitting(false))
        .then(
          res => {
            onAddedNewTillNumber();
            actions.resetForm();
          },
          e => {
            if (
              e &&
              e.data &&
              e.data.status &&
              e.data.status.message &&
              e.data.status.message ===
                t("pages.tools.requestPayment.blacklist.newBlacklistContact.alreadyExistsNumberError")
            ) {
              setNumberAlreadyExist(true);
            } else {
              setAlertProps({
                title: t("pages.tools.requestPayment.blacklist.newBlacklistContact.newNumberError"),
                type: AlertTypeEnum.ERROR,
              });
              showAlert();
            }
          },
        );
    } else {
      actions.setSubmitting(false);
      actions.setFieldError(
        "tillNumber",
        t("pages.tools.requestPayment.blacklist.newBlacklistContact.validNumberError"),
      );
    }
  };

  return (
    <FormContainer>
      <FormContainerTitle>{t("pages.tools.requestPayment.blacklist.newBlacklistContact.title")}</FormContainerTitle>
      <Formik
        onSubmit={addNewBlockedNumber}
        initialValues={{ tillNumber: "" }}
        validationSchema={getValidationSchema()}
        render={({ handleChange, values, handleSubmit, errors, isSubmitting, resetForm }) => (
          <form onSubmit={handleSubmit}>
            <Row style={{ alignItems: "flex-end", justifyContent: "space-between", flexWrap: "wrap", gap: "16px" }}>
              <div style={{ flex: "1 0 25%", minWidth: "264px" }}>
                <FieldSection>
                  <TextInput
                    title={t("pages.tools.requestPayment.blacklist.newBlacklistContact.tillNumber")}
                    placeholder={t("pages.tools.requestPayment.blacklist.newBlacklistContact.insertPhonePlaceholder")}
                    name="tillNumber"
                    onChange={handleChange}
                    value={values.tillNumber}
                    error={errors.tillNumber}
                    autoComplete="off"
                    maxLength={14}
                    required
                  />
                </FieldSection>
              </div>
              <div style={{ flex: "1 1 50%", display: "flex", justifyContent: "flex-end" }}>
                <ButtonContainer>
                  <PrimaryButton
                    titleLabel={t("pages.tools.requestPayment.blacklist.newBlacklistContact.addToBlacklist")}
                    type="submit"
                    redTheme={true}
                    loading={isSubmitting}
                    safaricom={config?.theme === THEMES.SAFARICOM}
                    id="add-blacklist-button"
                  />
                </ButtonContainer>
              </div>
            </Row>
            {numberAlreadyExist ? (
              <ErrorRow>
                <span style={{ fontSize: "16px;", fontWeight: "bold" }}>
                  {t("pages.tools.requestPayment.blacklist.newBlacklistContact.alreadyExistsNumber.title")}
                </span>
                <span style={{ fontSize: "14px;" }}>
                  {t("pages.tools.requestPayment.blacklist.newBlacklistContact.alreadyExistsNumber.description")}
                </span>
              </ErrorRow>
            ) : null}
          </form>
        )}
      />
    </FormContainer>
  );
};

const FormContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 31px 47px;
  margin: 0px -47px 0px -47px;
  background-color: ${props => props.theme.palette.greyLight};
`;

const FormContainerTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.4px;
  color: ${props => props.theme.palette.midGrey};
  text-transform: uppercase;
  margin-bottom: 21px;
`;

const FieldSection = styled("div")``;

const ButtonContainer = styled("div")`
  width: fit-content;
`;

const ErrorRow = styled(Row)`
  font-family: Vodafone Rg;
  color: red;
  margin-top: 12px;
  margin-bottom: 18px;
  flex-direction: column;
  align-items: flex-start;
`;

export default BlacklistNewNumber;
