import { EditIcon, FastActionButton, ThreeDotsIcon, TrashIcon } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { IStoreInterface } from "../../../../../configs/store.config";
import DragRulesDialog from "../../../../../shared/components/drag-rules.component";
import { ItemName } from "../../../../../shared/responsive-ui.styled";
import { fadeInKeyframe, IconContainer, RatioContainer, Row } from "../../../../../shared/shared.styled";
import CategoriesApi from "../categories.api";
import { ICategory, IIconCategory } from "../categories.model";

interface ICategoryListItemProps {
  item: ICategory;
  onDelete: (category: ICategory) => void;
  onEdit: (id: string) => void;
  onClickItemFn: () => void;
  selectedLanguage: { key: string; label: string };
}

/**
 * CategoryListItem component
 */
const CategoryListItem = ({ item, onDelete, onEdit, onClickItemFn, selectedLanguage }: ICategoryListItemProps) => {
  const [t] = useTranslation();
  const [isIconLoading, setIsIconLoading] = React.useState(true);
  const [icon, setIcon] = React.useState<IIconCategory>();
  const { icons } = useSelector((state: IStoreInterface) => state.serviceManagerReducer);
  const [showRules, setShowRules] = React.useState(false);

  /**
   * method to get the icon
   */
  const getIcon = () => {
    CategoriesApi.methods
      .getIcon(item.iconUrl)
      .then(res => {
        setIcon(res.data);
      })
      .finally(() => setIsIconLoading(false));
  };

  React.useEffect(() => {
    const itemIcon = icons ? icons.find(icon => icon.id === item.iconId) : undefined;
    if (itemIcon) {
      setIcon(itemIcon);
      setIsIconLoading(false);
    } else {
      getIcon();
    }
  }, []);

  return (
    <>
      <CategoryListItemContainer onClick={onClickItemFn} id={`category-${item.id}`} hoverAction={true}>
        <Dots onMouseEnter={() => setShowRules(true)} onMouseLeave={() => setShowRules(false)}>
          <ThreeDotsIcon />
          {showRules ? <DragRulesDialog text={t("pages.serviceManager.categories.reorder")} /> : null}
        </Dots>
        <ItemTitle ratio={6 / 12} className="item-title">
          <CategoryIconContainer color={item.color}>
            {!isIconLoading && icon && icon.base64 ? <img src={icon.base64} alt={"category-icon"} /> : null}
          </CategoryIconContainer>
          <ItemName className="item-name">
            {selectedLanguage.key === "en"
              ? item.categoryName
              : item.categoryNameTranslations && item.categoryNameTranslations[selectedLanguage.key]
              ? item.categoryNameTranslations[selectedLanguage.key]
              : item.categoryName}
          </ItemName>
          {item.isDefault && (
            <DefaultCampaignText data-tip data-for="tooltip-default-category">
              {t("pages.serviceManager.categories.table.default")}
              <ReactTooltip
                event="mouseover"
                eventOff="mouseleave mousedown"
                place="top"
                type="dark"
                effect="solid"
                id={"tooltip-default-category"}
                className={"tooltip-container"}
              >
                <TooltipText>{t("pages.serviceManager.categories.table.defaultTooltip")}</TooltipText>
              </ReactTooltip>
            </DefaultCampaignText>
          )}
        </ItemTitle>

        <ItemNormalText ratio={3 / 12} className="hide-on-hover">
          {item.externalCategoryIds
            ? item.externalCategoryIds.slice(0, 3).join("; ") +
              (item.externalCategoryIds.length > 3 ? `; +${item.externalCategoryIds.length - 3}` : "")
            : null}
        </ItemNormalText>
        <RatioContainer className="toggable-container" ratio={3 / 12}>
          <Row>
            <>
              <FastActionButton
                onClick={e => {
                  e.stopPropagation();
                  onEdit(item.id as string);
                }}
                iconComponent={
                  <IconContainer
                    size={16}
                    color={styleTheme.palette.turquoiseBlue}
                    id={`edit-category${item.categoryName}`}
                  >
                    <EditIcon />
                  </IconContainer>
                }
                label={t("pages.serviceManager.categories.table.edit")}
              />
              {!item.isDefault && (
                <FastActionButton
                  onClick={e => {
                    e.stopPropagation();
                    onDelete(item);
                  }}
                  label={t("pages.serviceManager.categories.table.delete")}
                  iconComponent={
                    <IconContainer
                      id={`delete-category${item.categoryName}`}
                      size={16}
                      color={styleTheme.palette.vodafoneRed}
                    >
                      <TrashIcon />
                    </IconContainer>
                  }
                />
              )}
            </>
          </Row>
        </RatioContainer>
      </CategoryListItemContainer>
    </>
  );
};

export default CategoryListItem;

const CategoryListItemContainer = styled("li")<{ hoverAction: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 75px;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};

  margin-bottom: 24px;
  padding: 0 35px 0 25px;

  .status-container {
    display: flex;
    justify-content: flex-end;
  }

  .toggable-container {
    display: none;
  }

  :hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    .status-container {
      display: ${props => (props.hoverAction ? "none" : "flex")};
    }
    .toggable-container {
      display: ${props => (!props.hoverAction ? "none" : "flex")};
      justify-content: flex-end;
      animation: ${fadeInKeyframe} 0.25s linear;
      margin-left: auto;
      button:first-child {
        margin-right: 12px;
      }
    }

    .item-name {
      @media (max-width: 768px) {
        max-width: unset;
        white-space: pre-wrap;
      }
    }
    .item-title {
      @media (max-width: 768px) {
        flex-grow: 1;
      }
    }
    .hide-on-hover {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
`;

const ItemTitle = styled(RatioContainer)`
  font-family: Vodafone Rg;
  font-size: 22px;
  font-weight: bold;
  color: ${props => props.theme.palette.darkGrey};
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    font-size: 20px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const ItemNormalText = styled(RatioContainer)`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (max-width: 1024px) {
    white-space: pre-wrap;
  }
`;

const CategoryIconContainer = styled("div")<{ color: string }>`
  width: 55px;
  height: 55px;
  background-color: ${props => (props.color ? props.color : props.theme.palette.midGrey)};
  border-radius: 50%;
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  img {
    width: 32px;
    height: 32px;
  }
  @media (max-width: 768px) {
    margin-right: 16px;
  }
  @media (max-width: 600px) {
    margin-right: 8px;
    width: 32px;
    height: 32px;
  }
`;

const DefaultCampaignText = styled("span")`
  font-family: Vodafone Rg;
  font-weight: normal;
  font-size: 16px;
  margin-left: 16px;
  color: ${props => props.theme.palette.vodafoneRed};
`;

const TooltipText = styled("div")`
  font-size: 12px;
  font-family: "Vodafone Rg";
  color: white;
  display: flex;
  width: 140px;
  text-align: center;
`;
const Dots = styled("div")`
  display: flex;
  width: 30px;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 5%;
`;
