import { CheckIcon, EditIcon, UndoIcon } from "@wit/mpesa-ui-components";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemeContext } from "styled-components";

interface ISideMenuDetailsProps {
  textEditing: string;
  form: string;
  isEditing: boolean;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  onCancelSideMenu?(): void;
}

/**
 * component to sidemenu for editing announcement
 * @param textEditing
 * @param form
 * @param isEditing
 * @param setEditing
 */
const SideMenuDetails = ({
  textEditing,
  form,
  isEditing,
  setEditing,
  onCancelSideMenu = () => null,
}: ISideMenuDetailsProps) => {
  const [t] = useTranslation();
  const themeContext = useContext(ThemeContext);

  /**
   * Preforme the cancel action
   */
  const onCancel = () => {
    setEditing(false);
    onCancelSideMenu();
    (document.getElementById(form) as HTMLFormElement).reset();
  };

  return (
    <SideMenu>
      {!isEditing ? (
        <LinkContainer
          id="edit-campaign-details"
          onClick={() => {
            setEditing(!isEditing);
          }}
        >
          <IconContainer color={themeContext.palette.turquoiseBlue}>
            <EditIcon />
          </IconContainer>
          <span>{textEditing}</span>
        </LinkContainer>
      ) : (
        <>
          <LinkButtonContainer id={`save-changes-form`} type="submit" form={form} onSubmit={() => setEditing(false)}>
            <IconContainer color={themeContext.palette.successGreen}>
              <CheckIcon />
            </IconContainer>
            <span>{t("pages.announcements.detailPage.sidemenu.done")}</span>
          </LinkButtonContainer>
          <LinkContainer id="cancel-changes" onClick={onCancel}>
            <IconContainer color="#e60000">
              <UndoIcon />
            </IconContainer>
            <span>{t("pages.announcements.detailPage.sidemenu.cancel")}</span>
          </LinkContainer>
        </>
      )}
    </SideMenu>
  );
};

export default SideMenuDetails;

const SideMenu = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 4vh;
`;

const LinkContainer = styled("div")<{ disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-family: Vodafone Rg;
  color: ${props => props.theme.palette.darkGrey};
  font-size: 16px;
  margin-bottom: 15px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? "none" : "inherit")};
  > div {
    margin-right: 8px;
  }
`;

const LinkButtonContainer = styled("button")<{ disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-family: Vodafone Rg;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  overflow: hidden;
  outline: none;
  color: ${props => props.theme.palette.darkGrey};
  font-size: 16px;
  margin-bottom: 15px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? "none" : "inherit")};
  > div {
    margin-right: 8px;
  }
`;

const IconContainer = styled("div")<{ color: string }>`
  > svg {
    stroke: ${props => props.color};
    width: 20px;
    height: 20px;
  }
`;
