import React, { useState, useCallback, useContext } from "react";
import { IStoreInterface } from "../../../../configs/store.config";
import { useSelector, useDispatch } from "react-redux";
import {
  useAlert,
  useModal,
  ModalTypeEnum,
  SecondaryPageTitle,
  FormSection,
  TextInput,
  MultipleOptionsDropdown,
  EditIcon,
  CheckIcon,
  TrashIcon,
  UndoIcon,
  TextArea,
  PrimaryButton,
} from "@wit/mpesa-ui-components";
import { IExternalService } from "../external-service.modal";
import { FormikHelpers, Formik, FormikErrors, FormikState } from "formik";
import { useTranslation } from "react-i18next";
import { IExternalServices, ExternalServicePermitionsEnum } from "../../../../shared/models/service-manager.model";
import ExternalServicesApi from "../external-services.api";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { ExternalServicesActions } from "../external-services.store";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import { PageContainer } from "../../../../shared/shared.styled";
import styled from "styled-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { getExternalServicePermissions } from "../../../../shared/shared.utils";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { RoutesEnum } from "../../../../routes/routes.constants";
import SafaricomDeleteModal from "../../../../shared/components/safaricom-delete-modal/safaricom-delete-modal.component";
import { useHistory, useParams } from "react-router-dom";
import { ServiceManagerTabNumber } from "../../pages/safaricom-service-manager.page";
import { PageContent } from "../../../../shared/responsive-ui.styled";
import { BackOfficeMarketsEnums } from "../../../../shared/shared.enums";
import { ConfigContext } from "../../../../app.component";
import { TFunction } from "i18next";
import { object, string } from "yup";
import { arrayUtils } from "../../../../shared/utils/array.utils";

/** External Service Profie page */
const ExternalServicesAddAndEditPage = ({ isAddMode }: { isAddMode?: boolean }) => {
  const params = useParams<any>();
  const externalService = useSelector(
    (store: IStoreInterface) =>
      store.externalServicesReducer.externalServices.find(
        service => service.serviceId === params.externalServiceID || "",
      ) as IExternalServices,
  );

  const [t] = useTranslation();
  const { config } = useContext(ConfigContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const [isEditing, setIsEditing] = React.useState(!!isAddMode);
  const [permissionsSelected, setPermissionsSelected] = useState<ExternalServicePermitionsEnum[]>(
    externalService?.permissions || [],
  );
  const [showAlert, hideAlert, setAlertProps] = useAlert();

  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );
  const [showDeleteModal, hideDeleteModal] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <SafaricomDeleteModal
      secondaryAction={() => hideDeleteModal()}
      primaryAction={() => deleteRequest()}
      title={t("pages.externalServices.deleteModal.title")}
      description={t("pages.externalServices.deleteModal.description")}
      primaryBtnLabel={t("pages.externalServices.deleteModal.btnLabel")}
    />,
  );

  /** function external service schema */
  const addExternalServiceValidationSchema = (t: TFunction) => {
    return object().shape({
      externalServiceId: string().required(t("commons.mandatoryField")),
      url: string().required(t("commons.mandatoryField")),
      rsaPublicKey: permissionsSelected.includes(ExternalServicePermitionsEnum.PIN)
        ? string().required(t("commons.mandatoryField"))
        : string(),
    });
  };

  const refreshPage = useCallback(() => {
    ExternalServicesApi.methods.getExternalServices().then(
      res => {
        dispatch(ExternalServicesActions.creators.fetchExternalServicesSuccessAction(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.serviceBuilder.errors.noServiceDetails"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        history.push(RoutesEnum.SERVICE_MANAGER);
      },
    );
  }, [dispatch, setAlertProps, showAlert, t]);

  /**
   * Check if an option has been selected
   * @param opt Dropdown options
   * @returns if the passed option is selected or not (boolean)
   */
  const isOptionSelected = (opt: SharedDropdownOption) => {
    return permissionsSelected.includes(opt.key);
  };

  /**
   * Renders dropdown label to show on the surface
   * @returns JSX.Element
   */
  const getDropdownLabel = () => {
    if (permissionsSelected.length) {
      return permissionsSelected.join(", ");
    }

    return <div>{t("pages.externalServices.configurations.columns.choosePermission")}</div>;
  };

  /**
   * Toggles the dropdown
   * @param opt Selected option
   * @param errors Formik validation errors
   * @param setErrors Formik set error helper function
   */
  const toggleOption = (
    opt: SharedDropdownOption,
    errors: FormikErrors<IExternalService>,
    setErrors: (errors: FormikErrors<IExternalService>) => void,
  ) => {
    let _permissionsSelect = [...permissionsSelected];

    if (isOptionSelected(opt)) {
      _permissionsSelect = permissionsSelected.filter(c => c !== opt.key);
    } else {
      _permissionsSelect = [..._permissionsSelect, opt.key];
    }
    setPermissionsSelected(_permissionsSelect);

    if (!_permissionsSelect.includes(ExternalServicePermitionsEnum.PIN)) {
      setErrors({ ...errors, rsaPublicKey: undefined });
    }
  };

  /**
   * Reset selected options of the permissions dropdown
   */
  const resetDropdown = () => {
    setPermissionsSelected(externalService.permissions || []);
  };

  /**
   * Check if the current index is NOT the index of the last item
   * @param arr Array of ExternalServicePermissions
   * @param index current index
   * @returns boolean
   */
  // const isNotLastItemInTheList = (arr: ExternalServicePermitionsEnum[], index: number) => arr.length - 1 !== index;

  /**
   * View the list of external service permissions
   * @returns JSX.ELement object
   */
  const displayPermissionsDisplay = () => {
    if (!permissionsSelected.length) {
      return <Value style={{ marginLeft: "1rem" }}>-</Value>;
    }

    return permissionsSelected.map((permission, index, array) => (
      <>
        <PermissionLabel isPin={permission === ExternalServicePermitionsEnum.PIN}>{permission}</PermissionLabel>
        {arrayUtils.isNotLastItemInTheList(array, index) && <Value>, </Value>}
      </>
    ));
  };

  /**
   * Create new external service request
   * @param values Formik of external service values
   * @param actions Formik of external service actions
   */
  const addExternalServices = (values: IExternalService, actions: FormikHelpers<IExternalService>) => {
    actions.setSubmitting(true);
    ExternalServicesApi.methods
      .createExternalService([{ ...values, permissions: permissionsSelected }])
      .finally(() => actions.setSubmitting(false))
      .then(
        __ => {
          setAlertProps({
            type: AlertTypeEnum.SUCCESS,
            title: t("pages.externalServices.addPage.addExternalService.title"),
          });
          showAlert();
          history.push(`${RoutesEnum.SERVICE_MANAGER}?tabIdx=${ServiceManagerTabNumber.EXTERNAL_SERVICES}`);
        },
        err => {
          setAlertProps({
            type: AlertTypeEnum.ERROR,
            title:
              err && err.data && err.data.status && err.data.status.message
                ? err.data.status.message
                : t("pages.externalServices.addPage.addExternalService.error"),
          });
          showAlert();
        },
      );
  };

  /**
   * Update existing external service request
   * @param values Formik of external service values
   * @param actions Formik of external service actions
   */
  const editExternalService = (values: IExternalService, actions: FormikHelpers<IExternalService>) => {
    values.permissions = [];
    values.permissions = [...permissionsSelected];
    if (
      permissionsSelected.includes(ExternalServicePermitionsEnum.PIN) &&
      config?.market === BackOfficeMarketsEnums.KE &&
      (!values.rsaPublicKey || values.rsaPublicKey?.trim() === "")
    ) {
      setAlertProps({
        title: t("pages.serviceManager.externalServices.alerts.rsaRequired"),
        type: AlertTypeEnum.ERROR,
      });
      showAlert();
    } else {
      setConfirmationProps({
        title: t("pages.serviceManager.externalServices.links.saveChanges"),
        description: t("pages.serviceManager.externalServices.modals.confirmEdit", {
          externalService: externalService?.serviceId,
        }),
        primaryBtnId: "confirm-button",
        secondaryBtnId: "cancel-button",
        primaryAction: () => {
          actions.setSubmitting(true);
          ExternalServicesApi.methods
            .updateExternalService(values)
            .finally(() => actions.setSubmitting(false))
            .then(
              () => {
                setAlertProps({
                  title: t("pages.serviceManager.externalServices.alerts.editSuccess"),
                  type: AlertTypeEnum.SUCCESS,
                });

                showAlert();
                setIsEditing(false);
                dispatch(ExternalServicesActions.creators.editExternalServiceAction(values));
                ExternalServicesActions.creators.fetchingExternalServicesAction();
                ExternalServicesApi.methods.getExternalServices().then(res => {
                  dispatch(ExternalServicesActions.creators.fetchExternalServicesSuccessAction(res.data));
                });
              },
              () => {
                setAlertProps({
                  title: t("pages.serviceManager.externalServices.alerts.editError"),
                  type: AlertTypeEnum.ERROR,
                });
                showAlert();
              },
            )
            .finally(() => {
              actions.setSubmitting(false);
              hideConfirmationModal();
              refreshPage();
            });
        },
        secondaryAction: () => hideConfirmationModal(),
      } as BaseModalProps);
      showConfirmationModal();
    }
  };

  /**
   * Remove External Service Request
   */
  const deleteRequest = () => {
    ExternalServicesApi.methods
      .deleteExternalService(externalService?.serviceId)
      .then(
        () => {
          setAlertProps({
            title: t("pages.externalServices.deleteRequest.titleDone"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          history.push(`${RoutesEnum.SERVICE_MANAGER}?tabIdx=${ServiceManagerTabNumber.EXTERNAL_SERVICES}`);
        },
        () => {
          setAlertProps({
            title: t("pages.externalServices.deleteRequest.titleError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(hideDeleteModal);
  };

  /** show deleteModal */
  const deleteExternalService = () => {
    showDeleteModal();
  };

  /**
   * Renders actions buttons for add and edit external services form
   * @returns React Node
   */
  const rendersActionButtons = (
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void,
    resetForm: (nextState?: Partial<FormikState<IExternalService>> | undefined) => void,
  ) => {
    if (isAddMode) {
      return;
      return (
        <LinkContainer
          onClick={() => {
            handleSubmit();
          }}
        >
          <IconContainer color={styleTheme.palette.successGreen}>
            <CheckIcon />
          </IconContainer>
          <span>{t("pages.userProfile.links.saveChanges")}</span>
        </LinkContainer>
      );
    }

    return (
      <>
        {!isEditing ? (
          <LinkContainer
            onClick={() => {
              setIsEditing(!isEditing);
            }}
          >
            <IconContainer color={styleTheme.palette.turquoiseBlue}>
              <EditIcon />
            </IconContainer>
            <span>{t("pages.externalServices.profilePage.editDetails")}</span>
          </LinkContainer>
        ) : (
          <>
            <LinkContainer
              onClick={() => {
                handleSubmit();
              }}
            >
              <IconContainer color={styleTheme.palette.successGreen}>
                <CheckIcon />
              </IconContainer>
              <span>{t("pages.userProfile.links.saveChanges")}</span>
            </LinkContainer>

            <LinkContainer
              onClick={() => {
                resetForm();
                resetDropdown();
                setIsEditing(false);
              }}
            >
              <IconContainer color={styleTheme.palette.vodafoneRed}>
                <UndoIcon />
              </IconContainer>
              <span>{t("pages.externalServices.profilePage.cancelText")}</span>
            </LinkContainer>
          </>
        )}
        {!isAddMode && (
          <LinkContainer onClick={() => deleteExternalService()} disabled={isEditing}>
            <IconContainer color={styleTheme.palette.vodafoneRed}>
              <TrashIcon />
            </IconContainer>
            <span>{t("pages.externalServices.profilePage.removeService")}</span>
          </LinkContainer>
        )}
      </>
    );
  };

  /**
   * Submit handler for add and edit the external services
   * @param values Formik of external service values
   * @param actions Formik of external service actions
   */
  const onSubmit = (values: IExternalService, actions: FormikHelpers<any>) => {
    if (isAddMode) {
      addExternalServices(values, actions);
    } else {
      editExternalService(values, actions);
    }
  };

  return (
    <PageContainer>
      <SecondaryPageTitle
        goBackFn={() =>
          history.push(`${RoutesEnum.SERVICE_MANAGER}?tabIdx=${ServiceManagerTabNumber.EXTERNAL_SERVICES}`)
        }
        title={isAddMode ? t("pages.externalServices.addPage.title") : t("pages.externalServices.profilePage.title")}
        displayInitialsCircle={false}
      />
      <PageContent>
        <Formik
          initialValues={{
            externalServiceId: isAddMode ? "" : externalService?.serviceId,
            url: isAddMode ? "" : externalService?.url,
            permissions: isAddMode ? [] : permissionsSelected,
            rsaPublicKey: isAddMode ? "" : externalService?.rsaPublicKey,
          }}
          onSubmit={onSubmit}
          validationSchema={addExternalServiceValidationSchema(t)}
          validateOnChange={false}
        >
          {({ values, handleChange, handleSubmit, resetForm, errors, setErrors, touched }) => {
            return (
              <>
                <MainContent>
                  <form onSubmit={handleSubmit}>
                    <FormSection
                      isEditing={isEditing}
                      title={t("pages.externalServices.addPage.description")}
                      rows={[
                        {
                          label: (
                            <TitleLabel mandatory={true}>
                              {t("pages.externalServices.addPage.getRows.serviceIdentifier")}
                            </TitleLabel>
                          ),
                          displayComponent: externalService?.serviceId,
                          editingComponent: (
                            <TextInput
                              name="externalServiceId"
                              value={values.externalServiceId}
                              onChange={handleChange}
                              placeholder={t("pages.externalServices.configurations.columns.serviceId")}
                              disabled={!isAddMode}
                              required
                              error={errors.externalServiceId}
                            />
                          ),
                        },
                        {
                          label: (
                            <TitleLabel mandatory={true}>
                              {t("pages.externalServices.configurations.columns.url")}
                            </TitleLabel>
                          ),
                          displayComponent: (
                            <DescriptionContainer>
                              <Value>{externalService?.url}</Value>
                            </DescriptionContainer>
                          ),
                          editingComponent: (
                            <TextInput
                              name="url"
                              value={values.url}
                              onChange={handleChange}
                              placeholder={t("pages.externalServices.configurations.columns.url")}
                              required
                              error={errors.url}
                            />
                          ),
                        },
                        {
                          label: t("pages.externalServices.configurations.columns.permissions"),
                          displayComponent: <DescriptionContainer>{displayPermissionsDisplay()}</DescriptionContainer>,
                          editingComponent: (
                            <MultipleOptionsDropdown
                              options={getExternalServicePermissions()}
                              dropdownType={DropdownType.RECTANGULAR_NORMAL}
                              toggleOption={opt => toggleOption(opt, errors, setErrors)}
                              clearAllFilters={() => resetDropdown()}
                              isOptionSelected={opt => isOptionSelected(opt)}
                              label={getDropdownLabel()}
                              hasValue={!!values.permissions}
                            />
                          ),
                        },
                        {
                          label: (
                            <TitleLabel
                              mandatory={
                                permissionsSelected.includes(ExternalServicePermitionsEnum.PIN) &&
                                config?.market === BackOfficeMarketsEnums.KE
                              }
                            >
                              {t("pages.externalServices.addPage.getRows.RSApublicKey")}
                            </TitleLabel>
                          ),
                          displayComponent: <RsaKeyLabelText>{values.rsaPublicKey}</RsaKeyLabelText>,
                          editingComponent: (
                            <>
                              <TextArea
                                name="rsaPublicKey"
                                value={values.rsaPublicKey}
                                onChange={handleChange}
                                placeholder={t("pages.externalServices.configurations.columns.publicKey")}
                                error={errors.rsaPublicKey}
                              />
                              <ErrorMsg>{errors.rsaPublicKey}</ErrorMsg>
                            </>
                          ),
                        },
                      ]}
                    />
                    {isAddMode && (
                      <AddButtonContainer>
                        <PrimaryButton
                          redTheme
                          safaricom
                          type="submit"
                          onClick={() => handleSubmit()}
                          style={{ width: 200 }}
                          titleLabel={t("pages.userProfile.links.saveChanges")}
                        />
                      </AddButtonContainer>
                    )}
                  </form>
                </MainContent>
                <SideMenu className="side-menu">{rendersActionButtons(handleSubmit, resetForm)}</SideMenu>
              </>
            );
          }}
        </Formik>
      </PageContent>
    </PageContainer>
  );
};

export default ExternalServicesAddAndEditPage;

const DescriptionContainer = styled("span")`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  hyphens: auto;
`;

const PermissionLabel = styled("span")<{ isPin: boolean }>`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => (props.isPin ? "#00c3ff" : "#6338eb")};
`;

const Value = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
`;

const MainContent = styled("div")`
  display: flex;
  flex-direction: column;
  width: 75%;
`;

const SideMenu = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 55px;
  width: 40%;
  margin-left: 10px;
`;

const LinkContainer = styled("div")<{ disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-family: Vodafone Rg;
  color: ${styleTheme.palette.darkGrey};
  font-size: 16px;
  margin-bottom: 15px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? "none" : "inherit")};

  > div {
    margin-right: 8px;
  }
`;

const IconContainer = styled("div")<{ color: string }>`
  width: 20px;
  height: 20px;
  > svg {
    stroke: ${props => props.color};
  }
`;

export const FileIconContainer = styled("div")<{ color: string; size: number }>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  svg {
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    stroke: ${props => props.color};
  }
`;

const TitleLabel = styled("div")<{ mandatory: boolean }>`
  min-height: 21px;
  font-family: Vodafone Rg;
  font-weight: bold;
  line-height: 21px;
  font-size: 16px;
  color: #999999;
  margin-bottom: 8px;
  width: fit-content;
  display: inline-flex;
  ${props =>
    props.mandatory ? "::after {content: ' *'; color: #ff0000; font-weight: 400; padding-left: 2px;}" : null};
`;
const RsaKeyLabelText = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: #333333;
  line-height: 21px;
  padding: 12px 0 11px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ErrorMsg = styled("span")`
  font-family: Vodafone Rg;
  font-size: 14px;
  color: #ff2a58;
  margin-top: 4px;
`;

const AddButtonContainer = styled("div")`
  display: flex;
  margin: 1rem 0;
`;
