import { produce } from "immer";
import { IPayloadAction } from "../../shared/shared.interfaces";
import { AuthenticationActions } from "../authentication/authentication.store";
import { IAuditingLog } from "./auditing.model";

export const AuditingLogsActions = {
  types: {
    GET_AUDITING_LOGS_SUCCESS: "GET_AUDITING_LOGS_SUCCESS",
  },
  creators: {
    getAuditingLogsSuccess: (auditingLogs: IAuditingLog[], auditingLogsCount: number) => ({
      type: AuditingLogsActions.types.GET_AUDITING_LOGS_SUCCESS,
      payload: {
        auditingLogs,
        auditingLogsCount,
      },
    }),
  },
};
export interface IAuditingLogsReducerInterface {
  auditingLogs: IAuditingLog[];
  auditingLogsCount: number;
}

const initialState: IAuditingLogsReducerInterface = {
  auditingLogs: [],
  auditingLogsCount: 0,
};

export const auditingLogsReducer = produce((draft: IAuditingLogsReducerInterface, action: IPayloadAction<any>) => {
  switch (action.type) {
    case AuditingLogsActions.types.GET_AUDITING_LOGS_SUCCESS:
      draft.auditingLogs = action.payload.auditingLogs;
      draft.auditingLogsCount = action.payload.auditingLogsCount;
      return;
    case AuthenticationActions.types.LOGOUT:
      return initialState;
    default:
      return draft;
  }
}, initialState);
