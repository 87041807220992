import { CancelIcon, DownloadIcon, Dropdown, TabChild, Tabs, TextInput, useAlert } from "@wit/mpesa-ui-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikErrors } from "formik";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { object, string } from "yup";
import { SafaricomTheme } from "../../../safaricom.theme";
import LoadingComponent from "../../../shared/components/loading-component/LoadingComponent";
import useCheckFeatureAvailable from "../../../shared/hooks/use-check-available-feature";
import { FEATURES } from "../../../shared/renderer.utils";
import { StatusType } from "../../../shared/shared.enums";
import { Row } from "../../../shared/shared.styled";
import { formatBytes, getColorStatus } from "../../../shared/shared.utils";
import AnnouncementsApi from "../announcements.api";
import {
  AnnouncementFormSteps,
  AppPlacement,
  AudienceType,
  ConsumerAudience,
  IAnnouncement,
  IIcon,
} from "../announcements.model";
import {
  ColorContainer,
  DropdownLabelContainer,
  HomeMainContent,
  IconDropDownContainer,
} from "../announcements.styled";
import { AnnouncementsUtils } from "../announcements.utils";
import ColorOptions from "./color-options.component";
import DropzoneBackground from "./dropzone-background.component";
import HomeScreenAnnouncementConsumer from "./home-screen-announcement-consumer.component";
import IconOptions from "./icon-options.component";
import MobileSchema from "./mobile-schema-component";

export enum ThumbnailImagesFields {
  THUMBNAIL_IMAGE = "thumbnailImage",
  THUMBNAIL_IMAGE_NEW = "thumbnailImageCarousel",
}

export enum MobileSchemaTabNumber {
  ADULT = 0,
  CHILD = 1,
}

interface IAnnouncementsStepHomescreenCardConsumerProps {
  announcement: IAnnouncement;
  setAnnoucement: React.Dispatch<React.SetStateAction<IAnnouncement>>;
  isEditing: boolean;
  onSubmitForm: () => void;
  onReady: React.Dispatch<React.SetStateAction<boolean>>;
  hideTitle?: boolean;
  setEditingHomescreenCard?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedAnnouncementSavedData?: IAnnouncement;
  setSelectedAnnouncementSavedData?: React.Dispatch<React.SetStateAction<IAnnouncement>>;
}

/**
 * function to validate the form
 */
const validateStepTwo = (
  shouldRenderImageCarousel: boolean,
  isAvatarIconOptional: boolean,
  appPlacement?: AppPlacement,
) => {
  return object().shape({
    title: string().max(
      AnnouncementsUtils.getFieldsValidation(appPlacement).TITLE_MAX_LENGTH,
      i18next
        .t("pages.announcements.errors.maxTitleHomeCustom")
        .replace("{size}", AnnouncementsUtils.getFieldsValidation(appPlacement).TITLE_MAX_LENGTH.toString()),
    ),
    smallDescription: string().max(
      AnnouncementsUtils.getFieldsValidation(appPlacement).DESCRITPION_MAX_LENGTH,
      i18next
        .t("pages.announcements.errors.maxDescriptionHomeCustom")
        .replace("{size}", AnnouncementsUtils.getFieldsValidation(appPlacement).DESCRITPION_MAX_LENGTH.toString()),
    ),
    color: string()
      .required(i18next.t("commons.mandatoryField"))
      .matches(/^#([0-9A-F]{3}){1,2}$/i, i18next.t("commons.invalidColor")),
    icon: !isAvatarIconOptional ? string().required(i18next.t("commons.mandatoryField")) : string().nullable(),
    thumbnailImage: string().required(i18next.t("commons.mandatoryField")),
    thumbnailImageCarousel: shouldRenderImageCarousel
      ? string().required(i18next.t("commons.mandatoryField"))
      : string(),
  });
};

/**
 * component for the second step of the announcment
 */
const AnnouncementsStepHomescreenCardConsumer = ({
  announcement,
  setAnnoucement,
  isEditing,
  onSubmitForm,
  onReady,
  hideTitle = false,
  setEditingHomescreenCard,
  selectedAnnouncementSavedData,
  setSelectedAnnouncementSavedData,
}: IAnnouncementsStepHomescreenCardConsumerProps) => {
  const [t] = useTranslation();
  const colorDefault = "#2fc56d";
  const [tab, setTab] = useState(AnnouncementsUtils.isConsumerAudience(announcement.audience as AudienceType) ? 1 : 0);
  const [tabDropColor, setTabDropColor] = React.useState(0);
  const [open, setOpen] = useState(false);
  const iconAnnouncement: IIcon = AnnouncementsUtils.getIconSelected(announcement.icon);
  const [iconSelected, setIconSelected] = React.useState<IIcon>(iconAnnouncement);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadImage, setUploadImage] = useState(false);
  const [errorUpload, setErrorUpload] = useState(false);
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const [thumbnailFileInfo, setThumbnailFileInfo] = React.useState<any>();
  const [thumbnailFileInfoCarousel, setThumbnailFileInfoCarousel] = React.useState<any>();
  const consumerChildEnabled = useCheckFeatureAvailable(FEATURES.CONSUMER_AUDIENCE_CHILD);
  const isUploadCarouselImageFieldEnabled = useCheckFeatureAvailable(FEATURES.NEW_IMAGE_FOR_ANNOUNCEMENT_CAROUSEL);
  const [tabIndex, setTabIndex] = React.useState<number>(MobileSchemaTabNumber.ADULT);
  const profileTypesEnabled = useCheckFeatureAvailable(FEATURES.GSM_PROFILE);

  /**
   * function that will be call for onSubmit form
   * @param values values of the announcemt
   */
  const onSubmitStepTwo = (values: IAnnouncement) => {
    setAnnoucement(values);
    onSubmitForm();
  };

  useEffect(() => {
    if (consumerChildEnabled && announcement.audience === ConsumerAudience.CONSUMERAPP_ADULT) {
      setTabIndex(MobileSchemaTabNumber.ADULT);
    } else if (consumerChildEnabled && announcement.audience === ConsumerAudience.CONSUMERAPP_ADULT) {
      setTabIndex(MobileSchemaTabNumber.CHILD);
    }
  }, [announcement.audience]);

  /**
   * Check if should render image carousel
   * @returns {boolean}
   */
  const shouldRenderImageCarousel = (): boolean => {
    return (
      isUploadCarouselImageFieldEnabled &&
      (!consumerChildEnabled || announcement.audience !== ConsumerAudience.CONSUMERAPP_CHILD)
    );
  };

  /**
   * Check whether the thumbnail images ready for the next step
   * @function isThumbnailImagesValidForOnReady
   * @param {IAnnouncement} values Announcement values
   * @returns {boolean} Return `true` if the thumbnail image valid and `false` if not
   */
  const validateThumbnailImageForOnReady = (values: IAnnouncement): boolean => {
    if (!shouldRenderImageCarousel()) {
      return !!values.thumbnailImage;
    }

    return !!values.thumbnailImage && !!values.thumbnailImageCarousel;
  };

  /**
   * function that will be call for onChange form
   * @param values values of announcement
   */
  const onChangeValues = (values: IAnnouncement) => {
    onReady((isUploadCarouselImageFieldEnabled || !!values.icon) && validateThumbnailImageForOnReady(values));
    setAnnoucement(values);
  };

  useEffect(() => {
    if (announcement.appPlacement === AppPlacement.SAFARICOM_TAB && isEditing) {
      const iconsStorage: IIcon[] = JSON.parse(sessionStorage.getItem("icons") as string);
      announcement.icon = iconsStorage.find(i => i.label === "card-disable-fill")?.id ?? "000";
    }
    onChangeValues(announcement);
    if (!isEditing) {
      (document.getElementById(AnnouncementFormSteps.STEP_THREE) as HTMLFormElement).reset();
    }
  }, [announcement]);

  /**
   * Check if the image dimensions are valid
   * @function validateImageForOnLoad
   * @param {number} width Image width
   * @param {number} height Image height
   * @param {number} imageAspectRatio Image aspect ratio
   * @param {boolean} isNewImage true if the new image field exist
   * @returns {boolean}
   */
  const validateImageForOnLoad = (
    width: number,
    height: number,
    imageAspectRatio: number,
    isNewImage: boolean,
  ): boolean => {
    const constValues = AnnouncementsUtils.getImageConstraintValues(
      profileTypesEnabled,
      isNewImage,
      announcement.appPlacement,
    );

    let isImageValid = false;
    if (width >= constValues.MIN_WIDTH && height >= constValues.MIN_HEIGHT) {
      if (
        imageAspectRatio !== constValues.VALID_RATIO &&
        !AnnouncementsUtils.isSafaricomTab(profileTypesEnabled, announcement.appPlacement)
      ) {
        setAlertProps({
          type: AlertTypeEnum.ERROR,
          title: t("pages.announcements.detailPage.rows.imageError"),
          content: t("pages.announcements.detailPage.rows.aspectRatioError", { ratio: constValues.RATIO_STRING }),
        });
        showAlert();
      } else {
        isImageValid = true;
      }
    } else {
      setAlertProps({
        type: AlertTypeEnum.ERROR,
        title: t("pages.announcements.detailPage.rows.imageError"),
        content: t("pages.announcements.detailPage.rows.minDimensionSizeAnnouncementCard", {
          width: constValues.MIN_WIDTH,
          height: constValues.MIN_HEIGHT,
        }),
      });
      showAlert();
    }

    return isImageValid;
  };

  /**
   *This handles the on drop (uplaod) image event
   * @param acceptedImage
   * @param {IAnnouncement} values Announcement form values
   * @param {Function} setFieldValue
   * @param {ThumbnailImagesFields} thumbnailImageField iMAGE field name
   */
  const onDrop = (
    acceptedImage: File[],
    values: IAnnouncement,
    setFieldValue: any,
    thumbnailImageField: ThumbnailImagesFields,
  ) => {
    const isNewImage = thumbnailImageField === ThumbnailImagesFields.THUMBNAIL_IMAGE_NEW;
    const constValues = AnnouncementsUtils.getImageConstraintValues(
      profileTypesEnabled,
      isNewImage,
      announcement.appPlacement,
    );

    if (!acceptedImage[0]) {
      setAlertProps({
        type: AlertTypeEnum.ERROR,
        title: t("pages.announcements.detailPage.rows.imageError"),
      });
      showAlert();
    } else if (acceptedImage[0] && acceptedImage[0].size > constValues.MAX_FILE_SIZE) {
      setAlertProps({
        type: AlertTypeEnum.ERROR,
        title: t("pages.announcements.detailPage.rows.imageError"),
        content: t("pages.announcements.detailPage.rows.maxSizeErrorAnnouncementCard", {
          size: constValues.MAX_FILE_SIZE / 1000,
        }),
      });
      showAlert();
    } else {
      const file: File = acceptedImage[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadstart = function() {
        setUploadImage(true);
      };
      reader.onerror = function() {
        setErrorUpload(true);
      };
      reader.onloadend = function(e) {
        const img = new Image();
        img.src = e.target !== null && e.target.result !== null ? (e.target.result as string) : "";

        img.onload = function() {
          const width = img.naturalWidth || img.width;
          const height = img.naturalHeight || img.height;
          const imageAspectRatio = width / height;

          if (validateImageForOnLoad(width, height, imageAspectRatio, isNewImage)) {
            setFieldValue(thumbnailImageField, reader.result);
            if (isNewImage) {
              setThumbnailFileInfoCarousel({
                name: file.name,
                size: file.size,
              });
            } else {
              setThumbnailFileInfo({
                name: file.name,
                size: file.size,
              });
            }
            onChangeValues({ ...values, [thumbnailImageField]: reader.result as string });
          }
          setUploadImage(false);
        };
      };
    }
  };

  /**
   * function to clear image selected
   */
  const clearImage = (setFieldValue: any, thumbnailImageField: ThumbnailImagesFields) => {
    setFieldValue(thumbnailImageField, "");
    onChangeValues({ ...announcement, [thumbnailImageField]: "" });
    setThumbnailFileInfo("");
  };

  /**
   * request image to backend
   * @param tab number of tab
   */
  const requestCarouselUrl = (thumbnailImage: string) => {
    if (!announcement.thumbnailImageCarousel && announcement.thumbnailImageCarouselUrl) {
      setIsLoading(true);
      setErrorUpload(false);
      AnnouncementsApi.methods
        .getImage(announcement.thumbnailImageCarouselUrl)
        .then(
          res => {
            setAnnoucement({ ...announcement, thumbnailImage, thumbnailImageCarousel: res.data });
            if (setSelectedAnnouncementSavedData && selectedAnnouncementSavedData) {
              setSelectedAnnouncementSavedData({
                ...selectedAnnouncementSavedData,
                thumbnailImage,
                thumbnailImageCarousel: res.data,
              });
            }
          },
          () => {
            setErrorUpload(true);
          },
        )
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setErrorUpload(false);
    }
  };

  /**
   * request image to backend
   * @param tab number of tab
   */
  const requestThumbnailUrl = () => {
    if (!announcement.thumbnailImage && announcement.thumbnailImageUrl) {
      setIsLoading(true);
      setErrorUpload(false);
      AnnouncementsApi.methods
        .getImage(announcement.thumbnailImageUrl)
        .then(
          res => {
            if (shouldRenderImageCarousel() && announcement.thumbnailImageCarouselUrl) {
              requestCarouselUrl(res.data);
            } else {
              setAnnoucement({ ...announcement, thumbnailImage: res.data, thumbnailImageCarousel: "" });
              if (setSelectedAnnouncementSavedData && selectedAnnouncementSavedData) {
                setSelectedAnnouncementSavedData({ ...selectedAnnouncementSavedData, thumbnailImage: res.data });
              }
            }
          },
          () => {
            setErrorUpload(true);
          },
        )
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setErrorUpload(false);
    }
  };

  React.useEffect(() => {
    if (!isEditing) {
      requestThumbnailUrl();
    }
  }, []);

  /**
   * Get the app name based on audience
   * @param values
   */
  const getAppNameByAudience = (values: IAnnouncement) => {
    if (values.audience === ConsumerAudience.CONSUMERAPP_CHILD) {
      return t("pages.announcements.detailPage.rows.childApp");
    } else if (values.audience === ConsumerAudience.CONSUMERAPP_ADULT) {
      return t("pages.announcements.detailPage.rows.adultApp");
    } else {
      if (profileTypesEnabled && !!values.appPlacement) {
        return t(`pages.announcements.detailPage.rows.${values.appPlacement}`);
      } else {
        return t("pages.announcements.detailPage.rows.consumerApp");
      }
    }
  };

  /**
   * Render image name
   * @param values - Announcement form values
   * @param thumbnailImageField - Thumbnail image field
   * @returns {string} image name and extension
   */
  const renderImageNameAndExtension = (values: IAnnouncement, thumbnailImageField: ThumbnailImagesFields): string => {
    if (thumbnailImageField === ThumbnailImagesFields.THUMBNAIL_IMAGE_NEW) {
      return thumbnailFileInfoCarousel
        ? thumbnailFileInfoCarousel.name
        : `${t(`pages.announcements.detailPage.rows.carousel`)}.${
            values[thumbnailImageField]
              ? values[thumbnailImageField]
                  ?.split(",")[0]
                  .split("/")[1]
                  .split(";")[0]
              : "png"
          }`;
    }

    return thumbnailFileInfo
      ? thumbnailFileInfo.name
      : `${t(`pages.announcements.detailPage.rows.thumbnail`)}.${
          values[thumbnailImageField]
            ? values[thumbnailImageField]
                ?.split(",")[0]
                .split("/")[1]
                .split(";")[0]
            : "png"
        }`;
  };

  /**
   * Render image size
   * @param values - Announcement form values
   * @param thumbnailImageField - Thumbnail image field
   * @returns {string} image size
   */
  const renderImageSize = (values: IAnnouncement, thumbnailImageField: ThumbnailImagesFields): string => {
    let size = 0;
    if (thumbnailImageField === ThumbnailImagesFields.THUMBNAIL_IMAGE_NEW && thumbnailFileInfoCarousel) {
      size = thumbnailFileInfoCarousel?.size || 0;
    } else if (thumbnailImageField === ThumbnailImagesFields.THUMBNAIL_IMAGE && thumbnailFileInfo) {
      size = thumbnailFileInfo?.size || 0;
    } else if (values[thumbnailImageField]) {
      size = values[thumbnailImageField]?.length || 0;
    } else if (announcement[thumbnailImageField]) {
      size = announcement[thumbnailImageField]?.length || 0;
    }

    return formatBytes(size);
  };

  /**
   * Render background image field
   * @returns {JSX.Element}
   */
  const renderUploadImageField = (
    values: IAnnouncement,
    errors: FormikErrors<IAnnouncement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    title: string,
    thumbnailImageField: ThumbnailImagesFields,
  ): JSX.Element => {
    const constValues = AnnouncementsUtils.getImageConstraintValues(
      profileTypesEnabled,
      thumbnailImageField === ThumbnailImagesFields.THUMBNAIL_IMAGE_NEW,
      announcement.appPlacement,
    );

    return (
      <>
        <DropzoneTitle mandatory={isEditing}>{title}</DropzoneTitle>

        {!values[thumbnailImageField] && isEditing && !uploadImage && !isLoading ? (
          <>
            <DropzoneBackground
              inputName={thumbnailImageField}
              onDrop={(file: any) => onDrop(file, values, setFieldValue, thumbnailImageField)}
              accept={".jpg, .png,"}
              multiple={false}
              dropText={t("pages.announcements.detailPage.rows.dropImage")}
              releaseText={t("pages.announcements.detailPage.rows.uploadImage")}
              error={errors[thumbnailImageField] ? !!errors[thumbnailImageField] : false}
            />
            <ImageInfo isEditing={isEditing} hasValue={announcement && !!announcement[thumbnailImageField]}>
              <ImageInfoTitle>{t("pages.announcements.detailPage.rows.recommendations")}</ImageInfoTitle>
              <ImageInfoDescription>
                {AnnouncementsUtils.isSafaricomTab(profileTypesEnabled, values.appPlacement) ? (
                  <>
                    {t("pages.announcements.detailPage.rows.imageRequirementsAnnouncementCardSafaricomTab", {
                      width: constValues.MIN_WIDTH,
                      height: constValues.MIN_HEIGHT,
                    })}
                  </>
                ) : (
                  t("pages.announcements.detailPage.rows.imageRequirementsAnnouncementCard", {
                    width: constValues.MIN_WIDTH,
                    height: constValues.MIN_HEIGHT,
                    ratio: constValues.RATIO_STRING,
                    size: constValues.MAX_FILE_SIZE / 1000,
                  })
                )}
              </ImageInfoDescription>
            </ImageInfo>
          </>
        ) : isLoading || uploadImage ? (
          <LoadingComponent />
        ) : !isEditing && errorUpload ? (
          <ImageDiv>
            <ErrorUploadImages>
              <DownloadIcon />
            </ErrorUploadImages>
            <DescriptionErrorImage>{t("pages.announcements.detailPage.rows.imageLoadingError")}</DescriptionErrorImage>
          </ImageDiv>
        ) : announcement[thumbnailImageField] ? (
          <ImageDiv>
            <ImageWrapper
              backgroundImage={
                isEditing ? (values[thumbnailImageField] as string) : (announcement[thumbnailImageField] as string)
              }
              isEditing={isEditing}
              isCarouselImg={thumbnailImageField === ThumbnailImagesFields.THUMBNAIL_IMAGE_NEW}
            >
              <span onClick={() => clearImage(setFieldValue, thumbnailImageField)}>
                <CancelIcon />
              </span>
            </ImageWrapper>
            <DescriptionImage
              isEditing={isEditing}
              isCarouselImg={thumbnailImageField === ThumbnailImagesFields.THUMBNAIL_IMAGE_NEW}
            >
              <NameImage>{renderImageNameAndExtension(values, thumbnailImageField)}</NameImage>
              <SizeImage>{renderImageSize(values, thumbnailImageField)}</SizeImage>
            </DescriptionImage>
          </ImageDiv>
        ) : (
          <NoImageUploaded>No image selected</NoImageUploaded>
        )}
      </>
    );
  };

  /**
   * Check if we should render the carousel image ratio mobile preview, where the carousel image ratio is 2:1
   * @returns {boolean}
   */
  const shouldRenderCarouselMobilePreview = (): boolean => {
    return !!(
      isUploadCarouselImageFieldEnabled &&
      announcement.audience !== ConsumerAudience.CONSUMERAPP_CHILD &&
      (isEditing || announcement.thumbnailImageCarousel)
    );
  };

  return (
    <Formik
      initialValues={announcement.color ? announcement : { ...announcement, color: colorDefault }}
      onSubmit={onSubmitStepTwo}
      validationSchema={() =>
        validateStepTwo(shouldRenderImageCarousel(), isUploadCarouselImageFieldEnabled, announcement.appPlacement)
      }
      validateOnChange={false}
      validateOnBlur={false}
      key={announcement.thumbnailImage || announcement.thumbnailImageCarousel}
    >
      {({ values, setFieldValue, handleSubmit, resetForm, errors }) => (
        <HomeMainContent>
          <HomeScreenMain>
            {AnnouncementsUtils.isSafaricomTab(profileTypesEnabled, values.appPlacement) ? (
              <HomeScreenTitle hidden={hideTitle}>
                {t("pages.announcements.detailPage.rows.secondTitleSafaricomTab")}
              </HomeScreenTitle>
            ) : (
              <HomeScreenTitle hidden={hideTitle}>{`${t("pages.announcements.detailPage.rows.consumer")} ${t(
                "pages.announcements.detailPage.rows.secondTitle",
              )}`}</HomeScreenTitle>
            )}
            <SectionForm
              id={AnnouncementFormSteps.STEP_THREE}
              onSubmit={handleSubmit}
              onChange={() => onChangeValues(values)}
              onReset={() => {
                if (announcement.icon || isUploadCarouselImageFieldEnabled) {
                  resetForm(announcement);
                } else if (setEditingHomescreenCard) {
                  setEditingHomescreenCard(true);
                }
              }}
            >
              {/* The new carousel image */}
              {shouldRenderImageCarousel() &&
                renderUploadImageField(
                  values,
                  errors,
                  setFieldValue,
                  t("pages.announcements.detailPage.rows.backgroundImageNew"),
                  ThumbnailImagesFields.THUMBNAIL_IMAGE_NEW,
                )}

              {/* The old image */}
              {renderUploadImageField(
                values,
                errors,
                setFieldValue,
                t("pages.announcements.detailPage.rows.backgroundImage"),
                ThumbnailImagesFields.THUMBNAIL_IMAGE,
              )}

              <TextAreaSection removeMargin={false}>
                {!isEditing ? (
                  <>
                    <DefineTitle required={false}>{t("pages.announcements.detailPage.rows.title")}</DefineTitle>
                    <NotEditingText>{announcement.title}</NotEditingText>
                  </>
                ) : (
                  <TextInput
                    value={values.title ? values.title : ""}
                    required={false}
                    title={t("pages.announcements.detailPage.rows.title")}
                    placeholder={t("pages.announcements.detailPage.rows.placeholderHomeTitleConsumer", {
                      maxCharacters: AnnouncementsUtils.getFieldsValidation(announcement.appPlacement).TITLE_MAX_LENGTH,
                    })}
                    onChange={e => setFieldValue("title", e.target.value)}
                    error={errors.title ? errors.title : undefined}
                    style={{ color: "#333333" }}
                    maxLength={AnnouncementsUtils.getFieldsValidation(announcement.appPlacement).TITLE_MAX_LENGTH}
                  />
                )}
              </TextAreaSection>
              <TextInputSection>
                {!isEditing ? (
                  <>
                    <DefineTitle required={false}>{t("pages.announcements.detailPage.rows.description")}</DefineTitle>
                    <NotEditingText>{announcement.smallDescription}</NotEditingText>
                  </>
                ) : (
                  <TextInput
                    value={values.smallDescription ? values.smallDescription : ""}
                    required={false}
                    title={t("pages.announcements.detailPage.rows.description")}
                    placeholder={t("pages.announcements.detailPage.rows.placeholderHomeDescriptionConsumer", {
                      maxCharacters: AnnouncementsUtils.getFieldsValidation(announcement.appPlacement)
                        .DESCRITPION_MAX_LENGTH,
                    })}
                    onChange={e => setFieldValue("smallDescription", e.target.value)}
                    error={errors.smallDescription ? errors.smallDescription : undefined}
                    maxLength={AnnouncementsUtils.getFieldsValidation(announcement.appPlacement).DESCRITPION_MAX_LENGTH}
                  />
                )}
              </TextInputSection>
              {!AnnouncementsUtils.isSafaricomTab(profileTypesEnabled, values.appPlacement) && (
                <SectionFormRow style={{ marginTop: "48px" }}>
                  <IconSection>
                    <DefineTitle required={!isUploadCarouselImageFieldEnabled && isEditing}>
                      {t("pages.announcements.detailPage.rows.avatar")}
                    </DefineTitle>
                    {!isEditing ? (
                      <Row>
                        <IconBadge logo={iconAnnouncement.base64} />
                        <NotEditingText>{iconAnnouncement.label}</NotEditingText>
                      </Row>
                    ) : (
                      <IconOptionsContainer>
                        <Dropdown
                          hasValue={false}
                          label={
                            <DropdownLabelContainer>
                              <IconDropDownContainer logo={iconSelected.base64} />
                              <SpanInput>{iconSelected.label}</SpanInput>
                            </DropdownLabelContainer>
                          }
                          dropdownType={DropdownType.RECTANGULAR_NORMAL}
                          error={errors.icon}
                        >
                          <IconOptions
                            iconID={values.icon ? values.icon : ""}
                            setIconID={(textToSave, icon) => {
                              setFieldValue(textToSave, icon);
                              onChangeValues({ ...values, icon });
                            }}
                            setIcon={icon => {
                              setIconSelected(icon);
                              if (icon.category === null) {
                                setFieldValue("color", "#ffffff");
                              }
                            }}
                            textToSave={"icon"}
                            customIcons={true}
                          />
                        </Dropdown>
                      </IconOptionsContainer>
                    )}
                  </IconSection>
                  {iconSelected && iconSelected.id !== "000" && iconSelected.category !== null ? (
                    <ColorSection>
                      <DefineTitle required={isEditing}>
                        {t("pages.announcements.detailPage.rows.backgroundColor")}
                      </DefineTitle>
                      {!isEditing ? (
                        <Row>
                          <BigBadgeColor color={announcement.color ? announcement.color : colorDefault} />
                          <NotEditingText>{announcement.color ? announcement.color : colorDefault}</NotEditingText>
                        </Row>
                      ) : (
                        <Dropdown
                          hasValue={false}
                          label={
                            <DropdownLabelContainer>
                              <ColorContainer backgroundColor={values.color ? values.color : colorDefault} />{" "}
                              <SpanInput>{values.color ? values.color : colorDefault}</SpanInput>
                            </DropdownLabelContainer>
                          }
                          dropdownType={DropdownType.RECTANGULAR_NORMAL}
                          isOpen={open}
                          controlledToggleOpen={setOpen}
                          error={errors.color}
                        >
                          <ColorOptions
                            tab={tabDropColor}
                            setTab={setTabDropColor}
                            color={values.color ? values.color : colorDefault}
                            setColor={setFieldValue}
                            textToSave={"color"}
                            show={open}
                            setShow={setOpen}
                          />
                        </Dropdown>
                      )}
                    </ColorSection>
                  ) : null}
                </SectionFormRow>
              )}
            </SectionForm>
          </HomeScreenMain>
          <MainSideBarColumn child={tab}>
            {!consumerChildEnabled || values.audience !== ConsumerAudience.ALL_CONSUMERAPPS ? (
              <>
                <AudienceContainer>{getAppNameByAudience(values)}</AudienceContainer>
                <div>
                  <MobileSchema appPlacement={values.appPlacement}>
                    <HomeScreenAnnouncementConsumer
                      valuesAnnouncement={values}
                      icon={iconSelected}
                      tab={tab}
                      backgroundImage={
                        shouldRenderCarouselMobilePreview()
                          ? (announcement.thumbnailImageCarousel as string)
                          : (announcement.thumbnailImage as string)
                      }
                      audience={values.audience as AudienceType}
                      isCarouselPreview={shouldRenderCarouselMobilePreview()}
                    />
                  </MobileSchema>
                </div>
              </>
            ) : (
              <TabContainer>
                <Tabs
                  controlledSelectedTab={Number(tabIndex) || MobileSchemaTabNumber.ADULT}
                  controlledSetSelectedTab={setTabIndex}
                  tabActiveColor={getColorStatus(StatusType.LIVE)}
                >
                  <TabChild label={t("pages.announcements.detailPage.rows.adultApp")}>
                    <MobileSchema>
                      <HomeScreenAnnouncementConsumer
                        valuesAnnouncement={values}
                        icon={iconSelected}
                        tab={tab}
                        backgroundImage={
                          shouldRenderCarouselMobilePreview()
                            ? (announcement.thumbnailImageCarousel as string)
                            : (announcement.thumbnailImage as string)
                        }
                        audience={ConsumerAudience.CONSUMERAPP_ADULT}
                        isCarouselPreview={shouldRenderCarouselMobilePreview()}
                      />
                    </MobileSchema>
                  </TabChild>
                  <TabChild label={t("pages.announcements.detailPage.rows.childApp")}>
                    <MobileSchema>
                      <HomeScreenAnnouncementConsumer
                        valuesAnnouncement={values}
                        icon={iconSelected}
                        tab={tab}
                        backgroundImage={values.thumbnailImage ?? (announcement.thumbnailImage as string)}
                        audience={ConsumerAudience.CONSUMERAPP_CHILD}
                      />
                    </MobileSchema>
                  </TabChild>
                </Tabs>
              </TabContainer>
            )}
          </MainSideBarColumn>
        </HomeMainContent>
      )}
    </Formik>
  );
};

export default AnnouncementsStepHomescreenCardConsumer;

const HomeScreenMain = styled("div")`
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const HomeScreenTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme.palette.darkGrey};
`;

const MainSideBarColumn = styled("div")<{ child: number }>`
  display: flex;
  flex-direction: column;
  width: 40%;
  > div {
    width: 300px;
    margin: auto;
    > div > div {
      margin-left: 4.5%;
      margin-right: 4.5%;
      > div:nth-child(${props => props.child + 1}) {
        background-color: ${SafaricomTheme.palette.vodafoneRed};
      }
    }
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SectionForm = styled("form")`
  margin-right: 20%;
`;

const SectionFormRow = styled("div")`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ColorSection = styled("div")`
  flex: 0.35;
  > button > div:last-child > div > div > div:first-child > div {
    margin-left: -70px;
  }
`;

const IconSection = styled("div")`
  flex: 0.6;
`;

const TextAreaSection = styled("div")<{ removeMargin: boolean }>`
  margin-top: ${props => (props.removeMargin ? "0" : "3vh")};
  font-family: Vodafone RgBd;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: ${props => props.theme.palette.midGrey};
  position: relative;
  > div > span > span:last-child {
    color: #ff2a58;
    margin-left: 0.2vw;
    font-weight: normal;
  }
`;

const TextInputSection = styled("div")`
  position: relative;
  margin-top: 3vh;
  font-family: Vodafone RgBd;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: ${props => props.theme.palette.midGrey};

  input {
    ::placeholder {
      color: #999999;
    }
  }
`;

const DefineTitle = styled("div")<{ required: boolean }>`
  min-height: 21px;
  font-family: Vodafone Rg;
  font-weight: bold;
  line-height: 21px;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
  margin-bottom: 8px;
  ${props => (props.required ? `::after {content: " *";color: #ff0000;font-weight: 400;padding-left: 2px;}` : null)};
`;

const SpanInput = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: ${props => props.theme.palette.midGrey};
`;

const NotEditingText = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: ${props => props.theme.palette.darkGrey};
`;

const BigBadgeColor = styled("div")<{ color: string }>`
  width: 38px;
  height: 34px;
  border-radius: 5px;
  background-color: ${props => props.color};
  margin-right: 0.5vw;
`;

const IconBadge = styled("div")<{
  logo: string;
}>`
  background-color: #fff;
  background-image: ${props => (props.logo ? `url("${props.logo}")` : "none")};
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: center;
  width: 38px;
  height: 34px;
`;

const AudienceContainer = styled("div")`
  height: 27px;
  background-color: #2fc56d;
  border-radius: 20px;
  padding: 5px 30px;
  cursor: pointer;
  width: fit-content !important;
  color: white;
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  text-align: center;
  margin-bottom: 3vh !important;
`;

const IconOptionsContainer = styled("div")`
  width: 100%;
  > button > div {
    width: 100%;
  }
`;
const ImageInfo = styled("div")<{ isEditing: boolean; hasValue: boolean }>`
  display: ${(props: { isEditing: any; hasValue: any }) => (!props.isEditing || props.hasValue ? "none" : "flex")};
  flex-direction: column;
`;

const ImageInfoTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${(props: { theme: { palette: { midGrey: any } } }) => props.theme.palette.midGrey};
`;

const ImageInfoDescription = styled("span")`
  font-family: Vodafone Rg;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${(props: { theme: { palette: { midGrey: any } } }) => props.theme.palette.midGrey};
`;
const ImageDiv = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ImageWrapper = styled("div")<{
  backgroundImage: string;
  isEditing: boolean;
  isCarouselImg: boolean;
}>`
    position: relative;
    width: ${(props: { isEditing: any }) => (props.isEditing ? "56" : "90")}px;
    height: ${(props: { isEditing: any }) => (props.isEditing ? "56" : "90")}px;
    max-width: ${(props: { isEditing: any; isCarouselImg: boolean }) => {
      if (props.isCarouselImg) {
        return props.isEditing ? "112" : "180";
      }
      return "";
    }}px;
    background-image: url("${props => props.backgroundImage}");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 3px;
    flex: ${(props: { isEditing: any; isCarouselImg: boolean }) => {
      if (props.isCarouselImg) {
        return "0.26";
      }
      return props.isEditing ? "0.08" : "0.125";
    }};
    >span{
      position: absolute;
      background: #aaa;
      z-index: 0;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      right: -0.5vw;
      top: -0.5vh;
      display:${(props: { isEditing: any }) => (props.isEditing ? "flex" : "none")};
      justify-content: center;
      align-items: center;
      cursor: pointer;
      svg{
        stroke: #fff;
        width: 15px;
        height: 15px;
      }
    }
`;

const DescriptionImage = styled("div")<{
  isEditing: boolean;
  isCarouselImg: boolean;
}>`
  flex: ${(props: { isEditing: any; isCarouselImg: boolean }) => {
    if (props.isCarouselImg) {
      return props.isEditing ? "0.84" : "0.72";
    }
    return props.isEditing ? "0.88" : "0.835";
  }};
  align-self: center;
  ${props => (props.isCarouselImg ? "padding-left: 0.5rem" : null)}
`;

const NameImage = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 4px;
`;

const NoImageUploaded = styled("div")`
  font-family: Vodafone Rg;
  font-size: 0.85rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  padding: 0 1rem;
  margin-bottom: 1rem;
`;

const SizeImage = styled("div")`
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #afafaf;
`;
const DescriptionErrorImage = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 0.9;
  justify-content: center;
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: ${props => props.theme.palette.errorRed};
`;

const ErrorUploadImages = styled("div")`
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  flex: 0.08;
  align-items: center;
  justify-content: center;
  svg {
    width: 32px;
    height: 32px;
    stroke: ${props => props.theme.palette.errorRed};
  }
`;
const DropzoneTitle = styled("div")<{ mandatory: boolean }>`
  min-height: 21px;
  font-family: Vodafone Rg;
  font-weight: bold;
  line-height: 21px;
  font-size: 16px;
  color: #999999;
  margin-bottom: 8px;
  margin-top: 24px;
  width: fit-content;
  display: inline-flex;
  ${(props: { mandatory: any }) =>
    props.mandatory ? "::after {content: ' *'; color: #ff0000; font-weight: 400; padding-left: 2px;}" : null};
`;

const TabContainer = styled("div")`
  > div:first-child > div:first-child {
    display: flex;
    justify-content: center;
    white-space: nowrap;
  }
`;
