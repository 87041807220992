import { AxiosPromise } from "axios";
import ApiClient from "../../configs/axios.config";
import {
  AppVersionServiceType,
  AudienceType,
  IAnnouncement,
  IAppVersions,
  ICallToActionInterface,
  IResponseToGetAnnouncements,
  IStoryInterface,
} from "./announcements.model";

const AnnouncementsApi = {
  routes: {
    announcements: "/announcements/all",
    addAnnouncement: "/announcements/add",
    getAnnouncement: "/announcements/:announcementID",
    deleteAnnouncement: "/announcements/:announcementID",
    updateAnnouncement: "/announcements/:announcementID",
    cancelAnnouncement: "/announcements/:announcementID/cancel",
    deleteStoryAnnouncement: "/announcements/story/:announcementID",
    addStoryAnnouncement: "/announcements/story/:announcementID",
    icons: "/announcements/icons",
    getStoryImageByUrl: "/announcements:url",
    validateMsisdn: "/announcements/validate/msisdn",
    validateShortcodes: "/announcements/validate/shortcodes",
    getCallToActionOptions: "/announcements/actions/:service",
    appVersions: "/v1/app-version?feature=ANNOUNCEMENTS&serviceType=:serviceType",
  },
  methods: {
    getAnnouncements: (): AxiosPromise<IResponseToGetAnnouncements> => {
      return ApiClient.get(AnnouncementsApi.routes.announcements);
    },
    getAnnouncement: (id: string): AxiosPromise<IAnnouncement> => {
      return ApiClient.get(AnnouncementsApi.routes.getAnnouncement.replace(":announcementID", id));
    },
    getIcons: (): AxiosPromise<[]> => {
      return ApiClient.get(AnnouncementsApi.routes.icons);
    },
    addIcon: (label: string, base64: string | ArrayBuffer | null): AxiosPromise<string> => {
      return ApiClient.post(AnnouncementsApi.routes.icons, { label, imageUrl: base64 });
    },

    /**
     * This request has the ability to upload up to 4 high res images, so the increasing of the timeout time is to assure that the request doesn't fail
     */
    addAnnouncement: (announcement: IAnnouncement): AxiosPromise => {
      return ApiClient.post(AnnouncementsApi.routes.addAnnouncement, announcement, { timeout: 90000 });
    },
    updateAnnouncement: (id: string, announcement: IAnnouncement): AxiosPromise<IAnnouncement> => {
      return ApiClient.post(AnnouncementsApi.routes.updateAnnouncement.replace(":announcementID", id), announcement);
    },
    deleteAnnouncement: (id: string): AxiosPromise => {
      return ApiClient.delete(AnnouncementsApi.routes.deleteAnnouncement.replace(":announcementID", id));
    },
    cancelAnnouncement: (id: string): AxiosPromise => {
      return ApiClient.post(AnnouncementsApi.routes.cancelAnnouncement.replace(":announcementID", id));
    },
    deleteStoryAnnouncement: (id: string, idStorys: string[]): AxiosPromise => {
      return ApiClient.delete(AnnouncementsApi.routes.deleteStoryAnnouncement.replace(":announcementID", id), {
        data: idStorys,
      });
    },
    addStoryAnnouncement: (id: string, stories: IStoryInterface[]): AxiosPromise => {
      return ApiClient.put(AnnouncementsApi.routes.addStoryAnnouncement.replace(":announcementID", id), stories);
    },
    getImage: (url: string): AxiosPromise => {
      return ApiClient.get(AnnouncementsApi.routes.getStoryImageByUrl.replace(":url", url));
    },
    validateMsisdn: (contentFile: string): AxiosPromise => {
      return ApiClient.post(AnnouncementsApi.routes.validateMsisdn, contentFile);
    },
    validateShortcode: (contentFile: string): AxiosPromise => {
      return ApiClient.post(AnnouncementsApi.routes.validateShortcodes, contentFile);
    },
    getCallToActionOptions: (service: AudienceType): AxiosPromise<ICallToActionInterface> => {
      return ApiClient.get(AnnouncementsApi.routes.getCallToActionOptions.replace(":service", service));
    },
    getAppVersions: (serviceType: AppVersionServiceType): AxiosPromise<IAppVersions> => {
      return ApiClient.get(AnnouncementsApi.routes.appVersions.replace(":serviceType", serviceType));
    },
  },
};

export default AnnouncementsApi;
