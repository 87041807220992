import {
  CheckIcon,
  FastActionButton,
  ModalTypeEnum,
  PrimaryButton,
  StatusChip,
  UndoIcon,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ConfigContext } from "../../../../app.component";
import LoadingIconWhite from "../../../../shared/icons/loading-white.icon";
import { IApp, IReleases } from "../../../../shared/models/app.model";
import { VersionControlButtonsContainer } from "../../../../shared/responsive-ui.styled";
import { Column } from "../../../../shared/shared.styled";
import { isSFCMarket } from "../../../../shared/shared.utils";
import AppsApi from "../../apps.api";
import AddNewAppReleaseVersionComponent from "./add-new-app-release-version.component";
import AppLastReleaseVersionModal from "./app-last-release-version.modal";
import AppReleaseVersionsTable from "./app-release-versions-table.component";
import PublishAppReleaseVersions from "./publish-app-release-versions.modal";

interface IAppReleaseVersionsPageInterface {
  id: string;
  app: IApp;
  refetchApp: () => void;
}
const AppReleaseVersionsPage = ({ id, app, refetchApp }: IAppReleaseVersionsPageInterface) => {
  const [t] = useTranslation();
  const [lastVersion, setLastVersion] = React.useState<IReleases>();
  const [isPublishing, setIsPublishing] = React.useState(false);
  const [lastVersionLoading, setLastVersionLoading] = React.useState(false);
  const [revertLoading, setRevertLoading] = React.useState(false);
  const [showAlert, , setAlertProps] = useAlert();
  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );
  const { config } = useContext(ConfigContext);

  /*
   * Checks the last version
   * */
  const checkLastVersion = () => {
    getLastVersion(() => {
      showLastVersionModal();
    });
  };

  /*
   * Get last version
   * */
  const getLastVersion = (cb: Function) => {
    AppsApi.methods.getLastVersion(id).then(
      res => {
        setLastVersion(res.data);
        cb();
      },
      err => {
        setAlertProps({
          title: t("pages.appDetails.releaseVersions.getLastVersionError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /*
   * Check last version modal
   * */
  const _hideLastVersionModal = () => {
    hideLastVersionModal();
  };
  const [showLastVersionModal, hideLastVersionModal, appLastReleaseVersionModalProps] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <AppLastReleaseVersionModal lastVersion={lastVersion} hideModal={_hideLastVersionModal} />,
  );
  React.useEffect(() => {
    appLastReleaseVersionModalProps({ modalStyles: { width: 600 } });
  }, [appLastReleaseVersionModalProps]);

  /*
   * Revert to last version handler
   * */
  const revertToLastVersion = () => {
    const confirmationProps = {
      title: t("pages.appDetails.releaseVersions.confirmRevert"),
      description: t("pages.appDetails.releaseVersions.confirmRevertMessage"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      secondaryAction: () => hideConfirmationModal(),
      primaryAction: () => {
        setRevertLoading(true);
        setConfirmationProps({ ...confirmationProps, isLoading: true });
        AppsApi.methods
          .revertToLastReleaseVersion(id)
          .then(
            res => {
              setAlertProps({
                title: t("pages.appDetails.releaseVersions.revertSuccess"),
                type: AlertTypeEnum.SUCCESS,
              });
              showAlert();
              hideConfirmationModal();
              refetchApp();
            },
            err => {
              setAlertProps({
                title: t("pages.appDetails.releaseVersions.revertError"),
                type: AlertTypeEnum.ERROR,
              });
              showAlert();
            },
          )
          .finally(() => {
            setRevertLoading(false);
            setConfirmationProps({ ...confirmationProps, isLoading: false });
          });
      },
    };

    setConfirmationProps(confirmationProps as BaseModalProps);
    showConfirmationModal();
  };

  /*
   * Publish changes handler
   * */
  const publishChanges = () => {
    setIsPublishing(true);
    AppsApi.methods
      .publishReleaseVersion(id)
      .then(
        res => {
          setAlertProps({
            title: t("pages.appDetails.releaseVersions.publishSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          refetchApp();
        },
        err => {
          setAlertProps({
            title: t("pages.appDetails.releaseVersions.publishError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        /**
         * Reset all publishing loading states
         */
        setLastVersionLoading(false);
        setIsPublishing(false);
      });
  };

  /*
   * Show publish modal
   * */
  const showPublishConfirmationModal = () => {
    setLastVersionLoading(true);
    getLastVersion(() => {
      showPublishModal();
      setLastVersionLoading(false);
    });
  };

  /*
   * Publish modal
   * */
  const _hidePublishModal = () => {
    hidePublishModal();
  };
  const _publishChanges = () => {
    setLastVersionLoading(true);
    hidePublishModal();
    publishChanges();
  };

  const [showPublishModal, hidePublishModal, publishModalProps] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <PublishAppReleaseVersions
      currentVersion={app.releases}
      lastVersion={lastVersion}
      hideModal={_hidePublishModal}
      isLoading={isPublishing}
      publishChanges={_publishChanges}
    />,
  );

  React.useEffect(() => {
    publishModalProps({ modalStyles: { width: 825, padding: 0 } });
  }, [publishModalProps]);

  return (
    <>
      {app.releases ? (
        <>
          {config && !isSFCMarket() && (
            <Row>
              <Column style={{ width: "20%", justifyContent: "center" }}>
                <Label>{t("pages.appDetails.releaseVersions.status")}</Label>
                <ChipContainer>
                  <StatusChip type={app.releases.status as any}>
                    {t(`commons.apps.enums.status.${app.releases.status}`)}
                  </StatusChip>
                </ChipContainer>
              </Column>
              <ButtonContainer>
                <PrimaryButton
                  disabled={!app.releases.hasUnpublishedChanges || lastVersionLoading}
                  titleLabel={t("pages.appDetails.releaseVersions.publishChanges")}
                  type="button"
                  redTheme={true}
                  onClick={showPublishConfirmationModal}
                  id={"publish-changes"}
                />
              </ButtonContainer>
            </Row>
          )}
          {app.releases.hasPreviousVersion && config && !isSFCMarket() ? (
            <Row>
              <VersionControlButtonsContainer>
                <div id={"check-last-version"}>
                  <FastActionButton
                    label={t("pages.appDetails.releaseVersions.checkLastVersion")}
                    onClick={checkLastVersion}
                    iconComponent={
                      <FastIconWrapper color={styleTheme.palette.successGreen}>
                        <CheckIcon />
                      </FastIconWrapper>
                    }
                  ></FastActionButton>
                </div>
                <div id={"revert-to-last-version"}>
                  <FastActionButton
                    label={!revertLoading ? t("pages.languageTranslations.revertChanges") : t("commons.loading")}
                    onClick={revertToLastVersion}
                    iconComponent={
                      <FastIconWrapper color={styleTheme.palette.midGrey}>
                        {revertLoading && <LoadingIconWhite strokeColor={styleTheme.palette.midGrey} />}
                        {!revertLoading && <UndoIcon />}
                      </FastIconWrapper>
                    }
                  />
                </div>
              </VersionControlButtonsContainer>
            </Row>
          ) : null}
        </>
      ) : null}

      <AddNewAppReleaseVersionComponent refetchApp={refetchApp} app={app} />
      <AppReleaseVersionsTable app={app} refetchApp={refetchApp} />
    </>
  );
};
const ButtonContainer = styled("div")`
  margin-left: auto;
  align-self: flex-end;
`;

const Row = styled("div")`
  display: flex;
  padding-bottom: 33px;
`;
export const Label = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
`;
const ChipContainer = styled("div")`
  margin-top: 14px;
`;
const FastIconWrapper = styled("span")<{ color: string }>`
  width: 16px;
  height: 16px;
  stroke: ${props => props.color};
  svg {
    width: inherit;
    height: inherit;
  }
`;
export default AppReleaseVersionsPage;
