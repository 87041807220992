import React from "react";
import styled from "styled-components";
import { CancelIcon } from "@wit/mpesa-ui-components";
import { IScreen } from "../../features/configurations/referral-campaigns/referral-campaigns.model";

interface IImageGalleryProps {
  backgroundColor?: string;
  imageUrl?: string;
  imageName?: string;
  title?: string;
  mainIcon?: React.ReactChild;
  actionFunctionOpenGallery?: () => void;
  actionFunctionRemoveSelectedImage?: () => void;
  imageGalleryContainerId?: string;
  errors?: any;
}

const ImageGallery = ({
  mainIcon,
  actionFunctionOpenGallery,
  actionFunctionRemoveSelectedImage,
  errors,
  backgroundColor,
  imageUrl,
  imageName,
  title,
  imageGalleryContainerId,
}: IImageGalleryProps) => {
  const errorMessage = (imageName?: string, errors?: any) => {
    if (!imageName && errors && errors.screens) {
      const er = errors.screens.find((error: IScreen) => error && error.urlImage);
      return er && er.urlImage ? <ErrorMessage data-testid="imageErrorMessage">{er.urlImage}</ErrorMessage> : null;
    }
  };

  return (
    <>
      <ImageGalleryContainer
        id={imageGalleryContainerId}
        image={imageUrl}
        backgroundColor={backgroundColor}
        onClick={e => {
          e.stopPropagation();
          if (!imageUrl) {
            actionFunctionOpenGallery && actionFunctionOpenGallery();
          }
        }}
      >
        {mainIcon}
        <OpenGallery>{title}</OpenGallery>
      </ImageGalleryContainer>

      {imageName ? (
        <ImageLabelContainer>
          <ImageLabel>{imageName}</ImageLabel>
          <CancelIcon
            onClick={e => {
              e.stopPropagation();
              actionFunctionRemoveSelectedImage && actionFunctionRemoveSelectedImage();
            }}
          ></CancelIcon>
        </ImageLabelContainer>
      ) : null}

      {errorMessage(imageName, errors)}
    </>
  );
};

export default ImageGallery;

const ImageGalleryContainer = styled("div")<{ image: string | undefined; backgroundColor: string | undefined }>`
  width: 75px;
  height: 75px;
  padding: 17px 4px 16px 5px;
  border-radius: 6px;
  border: solid 1px ${props => props.theme.palette.aluminium};
  stroke: ${props => props.theme.palette.turquoiseBlue};
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : props.theme.palette.cyanBlue)};
  background-image: ${props => (props.image ? `url(${props.image})` : "none")};
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > * {
    display: ${props => (props.image ? "none" : "inline")};
  }
  &:hover {
    cursor: ${props => (props.image ? "default" : "pointer")};
  }
`;

const OpenGallery = styled("span")`
  margin-top: 8px;
  font-family: Vodafone Rg;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${props => props.theme.palette.turquoiseBlue};
`;

const ImageLabelContainer = styled("div")`
  width: 77px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  stroke: ${props => props.theme.palette.vodafoneRed};
  > svg {
    width: 24px;
    height: 24px;
    &:hover {
      cursor: pointer;
    }
  }
`;

const ImageLabel = styled("span")`
  width: 54px;
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.palette.darkGrey};
`;

const ErrorMessage = styled("span")`
  font-family: Vodafone Rg;
  font-size: 14px;
  color: ${props => props.theme.palette.errorRed};
  margin-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
