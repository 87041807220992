import React from "react";
import { AutoClosingDropdown } from "@wit/mpesa-ui-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { IFiltersDropDownComponentProps } from "../feedback-and-problems.model";
import { feedbacksStringPath } from "../feedback-and-problems.utils";

const FiltersDropDownComponent = ({
  componentId,
  placeholder,
  options,
  selectedOption,
  onChangeSelectedOption,
}: IFiltersDropDownComponentProps) => {
  const [t] = useTranslation();

  return (
    <FiltersItemsWrapper id={componentId}>
      <AutoClosingDropdown
        hasValue={!!(selectedOption && selectedOption.key)}
        options={options}
        selectOption={o => onChangeSelectedOption(o)}
        label={selectedOption.key ? selectedOption.label : t(`${feedbacksStringPath}.${placeholder}`)}
      />
    </FiltersItemsWrapper>
  );
};

export default FiltersDropDownComponent;

const FiltersItemsWrapper = styled("div")<{ disabled?: Boolean }>`
  width: 100%;
  height: auto;
  opacity: ${props => (props.disabled ? 0.5 : 1)};

  button {
    border: 1px solid ${styleTheme.palette.aluminium};
  }
`;
