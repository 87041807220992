import React from "react";
import { Table } from "@wit/mpesa-ui-components";
import { ISectionResultsCustomerTableComponentProps } from "../feedback-and-problems.model";
import ResultsEmptyComponent from "./results-empty.component";
import { formatUnixTimestampDate } from "../../../../shared/shared.utils";
import { useFeedbackAndProblemsResults } from "../hooks/use-feedback-and-problems-results.hook";

const SectionCustomerResultsTableComponent = ({
  columnsConfig,
  isResultOfSearch,
  sectionId,
  toggleSideDrawer,
  selectedRowData,
  translations,
}: ISectionResultsCustomerTableComponentProps) => {
  const resultSet = useFeedbackAndProblemsResults(sectionId);
  const resultList = formatUnixTimestampDate(resultSet, "date", "DD MMM YYYY") || [];

  return (
    <div style={{ margin: "26px 0" }}>
      <Table<any>
        columns={columnsConfig.customer}
        values={resultList
          .sort((a, b) => Number(b.feedbackId) - Number(a.feedbackId))
          .map(item => {
            return {
              ...item,
            };
          })}
        sortable={false}
        isStripedTable={true}
        showActionColumn={false}
        emptyStateComponent={
          <ResultsEmptyComponent sectionId={sectionId} translations={translations} isSearchResult={isResultOfSearch} />
        }
        rowClickAction={(e, item) => {
          selectedRowData(item);
          toggleSideDrawer();
        }}
      />
    </div>
  );
};

export default React.memo(SectionCustomerResultsTableComponent);
