import React from "react";
import styled from "styled-components";
import LoadingComponent from "../../../shared/components/loading-component/LoadingComponent";

/**
 * Loading Spinner Component
 * @returns JSX.Element
 */
const LoadingSpinner = () => {
  return (
    <LoadingContainer>
      <LoadingComponent />
    </LoadingContainer>
  );
};

export default LoadingSpinner;

const LoadingContainer = styled("div")`
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
