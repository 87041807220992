import { NOTIFICATION_STATUSES } from "../../safaricom-push-notifications/push-notifications.modal";

enum AppsEnum {
  CONSUMER = "CONSUMER",
  BUSINESS = "BUSINESS",
}

export const TOPICS_DATA = [
  {
    id: "SD rayss 1",
    topicName: "SD rayss 1",
    topicDescription: "Topic description 1",
    createdOn: new Date("2022"),
    app: AppsEnum.CONSUMER,
    status: NOTIFICATION_STATUSES.SCHEDULED,
  },
  {
    id: "Ahm Name 22",
    topicName: "Hen he fo",
    topicDescription: "Topic description 22",
    createdOn: new Date("1 3 2023"),
    app: AppsEnum.BUSINESS,
    status: NOTIFICATION_STATUSES.SCHEDULED,
  },
  {
    id: "LL ko kd",
    topicName: "LL ko kd",
    topicDescription: "Topic description 94545",
    createdOn: new Date("10 10 2022"),
    app: AppsEnum.CONSUMER,
    status: NOTIFICATION_STATUSES.SENT,
  },
  {
    id: "Jk JK Ldd",
    topicName: "Jk JK Ldd",
    topicDescription: "Topic description 69121",
    createdOn: new Date("6 30 2022"),
    app: AppsEnum.BUSINESS,
    status: NOTIFICATION_STATUSES.DRAFT,
  },
  {
    id: "Ray de Name 963.",
    topicName: "Ray de Name 963.",
    topicDescription: "Topic description 963.",
    createdOn: new Date("7 19 2022"),
    app: AppsEnum.CONSUMER,
    status: NOTIFICATION_STATUSES.SENT,
  },
  {
    id: "Jk JK Ldd",
    topicName: "Jk JK Ldd",
    topicDescription: "Topic description 1",
    createdOn: new Date("2022"),
    app: AppsEnum.CONSUMER,
    status: NOTIFICATION_STATUSES.SENT,
  },
  {
    id: "Topic Name 22",
    topicName: "Topic Name 22",
    topicDescription: "Topic description 22",
    createdOn: new Date("1 3 2023"),
    app: AppsEnum.BUSINESS,
    status: NOTIFICATION_STATUSES.SCHEDULED,
  },
  {
    id: "LL ko kd",
    topicName: "LL ko kd",
    topicDescription: "Topic description 94545",
    createdOn: new Date("10 10 2022"),
    app: AppsEnum.CONSUMER,
    status: NOTIFICATION_STATUSES.SCHEDULED,
  },
  {
    id: "HS lk sok",
    topicName: "HS lk sok",
    topicDescription: "Topic description 69121",
    createdOn: new Date("6 30 2022"),
    app: AppsEnum.BUSINESS,
    status: NOTIFICATION_STATUSES.SENT,
  },
  {
    id: "Ray de Name 963.",
    topicName: "Ray de Name 963.",
    topicDescription: "Topic description 963.",
    createdOn: new Date("7 19 2022"),
    app: AppsEnum.CONSUMER,
    status: NOTIFICATION_STATUSES.SCHEDULED,
  },
  {
    id: "Jk JK Ldd",
    topicName: "Jk JK Ldd",
    topicDescription: "Topic description 1",
    createdOn: new Date("2022"),
    app: AppsEnum.CONSUMER,
    status: NOTIFICATION_STATUSES.SENT,
  },
  {
    id: "Lo ols lks",
    topicName: "Lo ols lks",
    topicDescription: "Topic description 22",
    createdOn: new Date("1 3 2023"),
    app: AppsEnum.BUSINESS,
    status: NOTIFICATION_STATUSES.SENT,
  },
  {
    id: "LL ko kd",
    topicName: "LL ko kd",
    topicDescription: "Topic description 94545",
    createdOn: new Date("10 10 2022"),
    app: AppsEnum.CONSUMER,
    status: NOTIFICATION_STATUSES.SCHEDULED,
  },
  {
    id: "Topic Name 69121",
    topicName: "Topic Name 69121",
    topicDescription: "Topic description 69121",
    createdOn: new Date("6 30 2022"),
    app: AppsEnum.BUSINESS,
    status: NOTIFICATION_STATUSES.SCHEDULED,
  },
  {
    id: "Ray de Name 963.",
    topicName: "Ray de Name 963.",
    topicDescription: "Topic description 963.",
    createdOn: new Date("7 19 2022"),
    app: AppsEnum.CONSUMER,
    status: NOTIFICATION_STATUSES.DRAFT,
  },
  {
    id: "Jk JK Ldd",
    topicName: "Jk JK Ldd",
    topicDescription: "Topic description 1",
    createdOn: new Date("2022"),
    app: AppsEnum.CONSUMER,
    status: NOTIFICATION_STATUSES.SCHEDULED,
  },
  {
    id: "Topic Name 22",
    topicName: "Topic Name 22",
    topicDescription: "Topic description 22",
    createdOn: new Date("1 3 2023"),
    app: AppsEnum.BUSINESS,
    status: NOTIFICATION_STATUSES.DRAFT,
  },
  {
    id: "Topic Name 94545",
    topicName: "Topic Name 94545",
    topicDescription: "Topic description 94545",
    createdOn: new Date("10 10 2022"),
    app: AppsEnum.CONSUMER,
    status: NOTIFICATION_STATUSES.SENT,
  },
  {
    id: "Topic Name 69121",
    topicName: "Topic Name 69121",
    topicDescription: "Topic description 69121",
    createdOn: new Date("6 30 2022"),
    app: AppsEnum.BUSINESS,
    status: NOTIFICATION_STATUSES.SCHEDULED,
  },
  {
    id: "Topic Name 963.",
    topicName: "Topic Name 963.",
    topicDescription: "Topic description 963.",
    createdOn: new Date("7 19 2022"),
    app: AppsEnum.CONSUMER,
    status: NOTIFICATION_STATUSES.SCHEDULED,
  },
  {
    id: "Jk JK Ldd",
    topicName: "Jk JK Ldd",
    topicDescription: "Topic description 1",
    createdOn: new Date("2022"),
    app: AppsEnum.CONSUMER,
    status: NOTIFICATION_STATUSES.SENT,
  },
  {
    id: "Topic Name 22",
    topicName: "Topic Name 22",
    topicDescription: "Topic description 22",
    createdOn: new Date("1 3 2023"),
    app: AppsEnum.BUSINESS,
    status: NOTIFICATION_STATUSES.SCHEDULED,
  },
  {
    id: "Topic Name 94545",
    topicName: "Topic Name 94545",
    topicDescription: "Topic description 94545",
    createdOn: new Date("10 10 2022"),
    app: AppsEnum.CONSUMER,
    status: NOTIFICATION_STATUSES.SCHEDULED,
  },
  {
    id: "Topic Name 69121",
    topicName: "Topic Name 69121",
    topicDescription: "Topic description 69121",
    createdOn: new Date("6 30 2022"),
    app: AppsEnum.BUSINESS,
    status: NOTIFICATION_STATUSES.SENT,
  },
  {
    id: "Fg jej jh jhjh",
    topicName: "Fg jej jh jhjh",
    topicDescription: "Topic description 963.",
    createdOn: new Date("7 19 2022"),
    app: AppsEnum.CONSUMER,
    status: NOTIFICATION_STATUSES.SCHEDULED,
  },
  {
    id: "Topic Name 1",
    topicName: "Topic Name 1",
    topicDescription: "Topic description 1",
    createdOn: new Date("2022"),
    app: AppsEnum.CONSUMER,
    status: NOTIFICATION_STATUSES.SCHEDULED,
  },
  {
    id: "Ef hej jee",
    topicName: "Ef hej jee",
    topicDescription: "Topic description 22",
    createdOn: new Date("1 3 2023"),
    app: AppsEnum.BUSINESS,
    status: NOTIFICATION_STATUSES.SCHEDULED,
  },
  {
    id: "Topic Name 94545",
    topicName: "Topic Name 94545",
    topicDescription: "Topic description 94545",
    createdOn: new Date("10 10 2022"),
    app: AppsEnum.CONSUMER,
    status: NOTIFICATION_STATUSES.DRAFT,
  },
  {
    id: "zc sfs gsg",
    topicName: "zc sfs gsg",
    topicDescription: "Topic description 69121",
    createdOn: new Date("6 30 2022"),
    app: AppsEnum.BUSINESS,
    status: NOTIFICATION_STATUSES.SENT,
  },
  {
    id: "Df he kke",
    topicName: "Df he kke",
    topicDescription: "Topic description 963.",
    createdOn: new Date("7 19 2022"),
    app: AppsEnum.CONSUMER,
    status: NOTIFICATION_STATUSES.SCHEDULED,
  },
];
