import React from "react";
import styled from "styled-components";

interface ISideDrawer {
  toggleSideDrawer(): void;
  open: boolean;
  children: React.ReactNode;
}

/**
 * Render side drawer component
 * @functions SideDrawer
 * @returns {React.ReactNode}
 */
const SideDrawer = ({ toggleSideDrawer, open, children }: ISideDrawer) => {
  if (!open) {
    return null;
  }

  return (
    <>
      <DrawerContainer>{children}</DrawerContainer>
      <DrawerOverlay onClick={toggleSideDrawer} />
    </>
  );
};

export default SideDrawer;

const DrawerOverlay = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(51, 51, 51, 0.4);
`;

const DrawerContainer = styled("div")`
  z-index: 11;
  position: fixed;
  top: 0;
  right: 0;
  width: 552px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  height: 100%;
  overflow: auto;
`;
