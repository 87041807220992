import { CopyIcon, FastActionButton, TrashIcon } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import moment from "moment";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { RoutesEnum } from "../../../../routes/routes.constants";
import { fadeInKeyframe, IconContainer, RatioContainer, Row } from "../../../../shared/shared.styled";
import { ILanguageTranslations, ILanguageTranslationsVersion } from "../language-translations.model";
import { LanguageTranslationsActions } from "../language-translations.store";
import LanguageTranslationsStatusChip from "./language-translations-status-chip.component";

interface ILanguageTranslationsVersionListItemProps {
  item: ILanguageTranslationsVersion;
  duplicateFn: (version: string) => void;
  deleteFn: (version: string) => void;
}

/**
 * Language translation version item
 * @param {ILanguageTranslationsVersion} item
 * @param duplicateFn
 * @param deleteFn
 * @returns {JSX.Element}
 * @constructor
 */
const LanguageTranslationsVersionListItem = ({
  item,
  duplicateFn,
  deleteFn,
}: ILanguageTranslationsVersionListItemProps) => {
  const [t] = useTranslation();
  const theme = useContext(ThemeContext);
  const history = useHistory();
  const dispatch = useDispatch();

  /**
   * Decides the status text of the current version
   * @returns {TFuncReturn<"translation", string, string, undefined> | TFuncReturn<"translation", string, string, undefined>}
   */
  const getVersionStatusText = () => {
    if (item.submittedForApproval) {
      return t(`pages.languageTranslationsVersions.statusOptionsEnum.submittedForApproval`);
    } else {
      return t(`pages.languageTranslationsVersions.statusOptionsEnum.live`);
    }
  };

  return (
    <>
      <Container
        onClick={() => {
          dispatch(LanguageTranslationsActions.creators.getLanguageTranslationsSuccess({} as ILanguageTranslations));
          dispatch(
            LanguageTranslationsActions.creators.getLanguageTranslationsLastVersion({} as ILanguageTranslations),
          );
          history.push(RoutesEnum.LANGUAGE_TRANSLATIONS_VERSION.replace(":version", item.version));
        }}
        id={`language-translation-version-${item.version}`}
        hoverAction={true}
      >
        <CopyIcon style={{ stroke: theme.palette.turquoiseBlue, marginRight: "14px" }} />
        <RatioContainer ratio={9 / 12} style={{ display: "flex", flexDirection: "column" }}>
          <VersionNameText>{`${t("pages.languageTranslationsVersions.version")} ${item.version}`}</VersionNameText>
          <LastUpdatedText>
            {`${t("pages.languageTranslationsVersions.lastUpdate")}: ${moment(item.lastUpdate).format("DD MMM YYYY")}`}
          </LastUpdatedText>
        </RatioContainer>
        <RatioContainer
          className="status-container"
          style={{ textAlign: "right", alignItems: "center" }}
          ratio={3 / 12}
        >
          <LanguageTranslationsStatusChip id={"status-chip"} style={{ width: "max-content" }} type={item.status}>
            {getVersionStatusText()}
          </LanguageTranslationsStatusChip>
        </RatioContainer>
        <RatioContainer className="toggable-container" ratio={3 / 12}>
          <Row>
            {!item.submittedForApproval && (
              <FastActionButton
                iconComponent={
                  <IconContainer size={16} color={styleTheme.palette.vodafoneRed} id={`delete-version-${item.version}`}>
                    <TrashIcon />
                  </IconContainer>
                }
                label={t("pages.languageTranslationsVersions.delete")}
                onClick={e => {
                  e.stopPropagation();
                  deleteFn(item.version);
                }}
              />
            )}

            <FastActionButton
              iconComponent={
                <IconContainer
                  size={16}
                  color={styleTheme.palette.freshOrange}
                  id={`duplicate-version-${item.version}`}
                >
                  <CopyIcon />
                </IconContainer>
              }
              label={t("pages.languageTranslationsVersions.duplicate")}
              onClick={e => {
                e.stopPropagation();
                duplicateFn(item.version);
              }}
            />
          </Row>
        </RatioContainer>
      </Container>
    </>
  );
};

const Container = styled("li")<{ hoverAction: boolean }>`
  display: flex;
  flex-direction: row;
  height: 75px;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  margin-bottom: 24px;
  padding: 16px 35px 16px 24px;

  .status-container {
    display: flex;
    justify-content: flex-end;
  }

  .toggable-container {
    display: none;
  }

  :hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    .status-container {
      display: ${props => (props.hoverAction ? "none" : "flex")};
    }
    .toggable-container {
      display: ${props => (!props.hoverAction ? "none" : "flex")};
      justify-content: flex-end;
      animation: ${fadeInKeyframe} 0.25s linear;
      button:first-child {
        margin-right: 12px;
      }
    }
  }
`;

const VersionNameText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 18px;
  line-height: 21px;
  color: ${props => props.theme.palette.darkGrey};
`;

const LastUpdatedText = styled("span")`
  padding-top: 5px;
  font-family: Vodafone Rg;
  font-size: 14px;
  color: ${props => props.theme.palette.midGrey};
`;

export default LanguageTranslationsVersionListItem;
