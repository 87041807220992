import React from "react";
import { FilterFuncts } from "@wit/mpesa-ui-components/lib/hooks/use-filters.hook";
import { RatioContainer, Row } from "../../../../../shared/shared.styled";
import { SearchBar } from "@wit/mpesa-ui-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { SearchBarFilterContainer } from "../../../../../shared/responsive-ui.styled";

export enum BlacklistFilterKeys {
  BLOCKED_NUMBER = "blockedNumber",
}

interface IBlacklistFiltersProps extends FilterFuncts {
  refreshResults: () => void;
}

const BlacklistFilters = ({
  filters,
  clearFilter,
  isFilterActive,
  resetFilters,
  updateFilter,
  updateMultipleFilters,
  refreshResults,
}: IBlacklistFiltersProps) => {
  const [t] = useTranslation();

  return (
    <>
      <CustomRow>
        <SearchBarFilterContainer>
          <SearchBar
            value={
              filters.has(BlacklistFilterKeys.BLOCKED_NUMBER)
                ? (filters.get(BlacklistFilterKeys.BLOCKED_NUMBER) as string[])[0]
                : ""
            }
            onChange={e => {
              if (e.target.value) {
                updateFilter(BlacklistFilterKeys.BLOCKED_NUMBER, e.target.value, true);
              } else {
                clearFilter(BlacklistFilterKeys.BLOCKED_NUMBER);
              }
            }}
            clearValue={() => clearFilter(BlacklistFilterKeys.BLOCKED_NUMBER)}
            placeholderLabel={t("pages.tools.requestMoney.blacklist.searchPlaceholder")}
          />
        </SearchBarFilterContainer>
      </CustomRow>
    </>
  );
};

export default BlacklistFilters;

const CustomRow = styled(Row)`
  margin-bottom: 18px;
  margin-top: 18px;

  > ${RatioContainer} {
    margin-right: 24px;

      margin-right: 0;
    }
  }
`;
