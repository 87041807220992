import Axios, { AxiosError, HeadersDefaults } from "axios";
import store from "./store.config";
import { AxiosHeadersEnum } from "./axios.config";
import { AuthenticationActions } from "../features/authentication/authentication.store";

// FIXME: Remove this file and use the existent ApiClient to manipulate ekyc request headers and interceptors

export const AxiosConstants = {
  TIMEOUT: 30000,
};

interface IAdditionalHeaders extends HeadersDefaults {
  [key: string]: any;
}

const ApiClient = Axios.create({
  baseURL: "", // later set from the remote config ("apiKycUrl")
  timeout: AxiosConstants.TIMEOUT,
  headers: {
    "Security-Cookie": localStorage.getItem("securityCookie") || "",
    "XSRF-TOKEN": localStorage.getItem("xsrfToken") || "",
  },
  withCredentials: true,
});

export const configureAxiosKycUrl = (apiUrl: string) => {
  ApiClient.defaults.baseURL = apiUrl;
};

export function updateAxiosHeaders(key: string, value: string) {
  const headers: IAdditionalHeaders = {
    ...ApiClient.defaults.headers,
    key: value,
  };

  ApiClient.defaults.headers = {
    ...headers,
  };
}

export function updateRequestIdHeader() {
  const headers: IAdditionalHeaders = {
    ...ApiClient.defaults.headers,
    request_Id: String(Date.now()),
  };

  ApiClient.defaults.headers = {
    ...headers,
  };
}

// Interceptors

ApiClient.interceptors.request.use(request => {
  const headers: IAdditionalHeaders = {
    ...ApiClient.defaults.headers,
    "Security-Cookie": localStorage.getItem("securityCookie"),
    "XSRF-TOKEN": localStorage.getItem("xsrfToken"),
  };

  request.headers = {
    ...headers,
  };
  return request;
});

ApiClient.interceptors.response.use(
  response => {
    const securityCookie = response.headers["security-cookie"];
    const xsrfToken = response.headers["xsrf-token"];
    if (!!securityCookie) {
      updateAxiosHeaders(AxiosHeadersEnum.SECURITY_COOKIE, securityCookie);
      localStorage.setItem("securityCookie", securityCookie);
    }
    if (!!xsrfToken) {
      updateAxiosHeaders(AxiosHeadersEnum.XSRF_TOKEN, xsrfToken);
      localStorage.setItem("xsrfToken", xsrfToken);
    }
    return response;
  },
  (error: AxiosError) => {
    if (error.response && (error.response.status === 403 || error.response.status === 401)) {
      store.dispatch(AuthenticationActions.creators.toggleModalAction(true));
    }
    return Promise.reject(error.response);
  },
);

export default ApiClient;
