import React, { useState } from "react";
import { PageTitle, PrimaryButton, useAlert, TabChild, Tabs } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IStoreInterface } from "../../../configs/store.config";
import { RoutesEnum } from "../../../routes/routes.constants";
import {
  customerFakeData,
  customerStringPath,
  feedbackAndProblemsPageConfigs,
  isProblemReportsTabActive,
} from "./feedback-and-problems.utils";
import SideDrawer from "../../../shared/components/side-drawer/side-drawer.component";
import SectionFiltersComponent from "./components/section-filters.component";
import FeedbackAndProblemsAPI from "./feedback-and-problems.api";
import SectionDetailsComponent from "./components/section-details.component";
import SectionResultsTableComponent from "./components/section-results-table.component";
import DetailsDrawerComponent from "./components/details-drawer.component";
import ResultsTableDrawerComponent from "./components/results-drawer.component";
import ResultsTablePaginationComponent from "./components/results-pagination.component";
import { ButtonContainer, PageContainer } from "../../../shared/shared.styled";
import {
  EFormDataStatus,
  EFormType,
  ITableResultRow,
  ISearchRequest,
  IFormDataRequest,
  IFormDetailsRequest,
  ITableCustomerResultRow,
} from "./feedback-and-problems.model";
import {
  isFeedbackTabActive,
  problemsStringPath,
  feedbacksStringPath,
  getBreadcrumbs,
  getSelectableStatus,
} from "./feedback-and-problems.utils";
import { FeedbackAndProblemsStoreActions } from "./feedback-and-problems.store";
import LanguagesApi from "../../../shared/services/languages.api";
import { ILanguage } from "../../../shared/models/language.model";
import SectionCustomerDetailsComponent from "./components/section-customer-details.component";
import SectionCustomerResultsTableComponent from "./components/section-customer-results-table.component";
import ResultsTableCustomerDrawerComponent from "./components/results-customer-drawer.component";

let isResultOfSearch: boolean = false;
let tempRequestParams: ISearchRequest = {
  searchQuery: "",
  startDate: "",
  endDate: "",
  categoryId: "",
  status: "" as EFormDataStatus,
};
let requestParams: IFormDataRequest = {
  searchQuery: "",
  startDate: "",
  endDate: "",
  categoryId: "",
  status: "" as EFormDataStatus,
  pageNumber: undefined,
  pageSize: undefined,
};

/**
 * FeedbackAndProblemsReport Page
 * @returns {JSX.Element}
 */
const FeedbackAndProblemsReport = () => {
  const [t] = useTranslation();
  const pConfigs = feedbackAndProblemsPageConfigs(t);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showAlert, , setAlertProps] = useAlert();

  const [sectionId, setSectionId] = useState<number>(pConfigs.INITIAL_SECTION);
  const [activePage, setActivePage] = useState<number>(pConfigs.INITIAL_PAGE);
  const [isSectionDetailsDrawerOpen, setIsSectionDetailsDrawerOpen] = useState<boolean>(false);
  const [isSectionResultDrawerOpen, setIsSectionResultDrawerOpen] = useState<boolean>(false);
  const [isCustomerDetailsDrawerOpen, setIsCustomerDetailsDrawerOpen] = useState<boolean>(false);
  const [customerDetailsDrawerData, setCustomerDetailsDrawerData] = useState<ITableCustomerResultRow | undefined>(
    undefined,
  );
  const [sectionResultDrawerData, setSectionResultDrawerData] = useState<ITableResultRow | undefined>(undefined);
  const [selectedMarketLanguage, setSelectedMarketLanguage] = useState<string>("en");
  const [marketLanguages, setMarketLanguages] = useState<ILanguage[]>([]);

  const {
    feedbacks,
    feedbackCategories,
    feedbackDetails,
    customerSurvey,
    problemReports,
    problemReportsCategories,
    problemReportsDetails,
  } = useSelector((state: IStoreInterface) => state.feedbackAndProblemsReportReducer);

  /**
   * Gets (fetch) from the BE the data to be presented on section result table
   * fetches according to the current section (feedback/problem reports)
   */
  const getFormData = async () => {
    if (
      !!requestParams.searchQuery ||
      !!requestParams.status ||
      !!requestParams.categoryId ||
      !!requestParams.startDate ||
      !!requestParams.endDate
    ) {
      isResultOfSearch = true;
    }

    if (
      !requestParams.searchQuery &&
      !requestParams.status &&
      !requestParams.categoryId &&
      !requestParams.startDate &&
      !requestParams.endDate
    ) {
      isResultOfSearch = false;
    }

    if (isFeedbackTabActive(sectionId)) {
      dispatch(FeedbackAndProblemsStoreActions.creators.getFeedbacksData());
    } else if (isProblemReportsTabActive(sectionId)) {
      dispatch(FeedbackAndProblemsStoreActions.creators.getProblemsData());
    } else {
      dispatch(FeedbackAndProblemsStoreActions.creators.getCustomerSurveyData());
    }

    try {
      const res = await FeedbackAndProblemsAPI.methods.getFormData(
        isFeedbackTabActive(sectionId)
          ? EFormType.FEEDBACK
          : isProblemReportsTabActive(sectionId)
          ? EFormType.REPORT_PROBLEM
          : EFormType.CUSTOMER_SURVEY,
        requestParams,
      );
      if (isFeedbackTabActive(sectionId)) {
        dispatch(FeedbackAndProblemsStoreActions.creators.setFeedbacksData(res.data));
      } else if (isProblemReportsTabActive(sectionId)) {
        dispatch(FeedbackAndProblemsStoreActions.creators.setProblemsData(res.data));
      } else {
        /**
         * TODO: Change this with actual data coming from the server.
         */
        dispatch(FeedbackAndProblemsStoreActions.creators.setCustomerSurveyData(customerFakeData));
      }
    } catch (error) {
      setAlertProps({
        title: isFeedbackTabActive(sectionId)
          ? t(`${feedbacksStringPath}.errors.onFetchFormData`)
          : isProblemReportsTabActive(sectionId)
          ? t(`${problemsStringPath}.errors.onFetchFormData`)
          : t(`${customerStringPath}.errors.onFetchFormData`),
        type: AlertTypeEnum.ERROR,
      });
      showAlert();
    }
  };

  /**
   * Makes an update request to the BE in order to update form data row status
   * fetches according to the current section (feedback/problem reports)
   * @param data { itemId: number | string; statusCode: EFormDataStatus }
   */
  const updateFormDataRow = async (data: { itemId: number | string; value: string }) => {
    if (isFeedbackTabActive(sectionId)) {
      dispatch(FeedbackAndProblemsStoreActions.creators.updateFeedbacksData());
    } else {
      dispatch(FeedbackAndProblemsStoreActions.creators.updateProblemsData());
    }

    try {
      await FeedbackAndProblemsAPI.methods.updateFormDataRow(
        isFeedbackTabActive(sectionId) ? EFormType.FEEDBACK : EFormType.REPORT_PROBLEM,
        data.itemId,
        data.value,
      );
      setAlertProps({
        title: isFeedbackTabActive(sectionId)
          ? t(`${feedbacksStringPath}.success.onUpdateFormRowStatus`)
          : t(`${problemsStringPath}.success.onUpdateFormRowStatus`),
        type: AlertTypeEnum.SUCCESS,
      });
      showAlert();
      await getFormData(); // TODO: This can be improved, with BE support
    } catch (error) {
      setAlertProps({
        title: isFeedbackTabActive(sectionId)
          ? t(`${feedbacksStringPath}.errors.onUpdateFormRowStatus`).replace("{id}", String(data.itemId))
          : t(`${problemsStringPath}.errors.onUpdateFormRowStatus`).replace("{id}", String(data.itemId)),
        type: AlertTypeEnum.ERROR,
      });
      showAlert();
    }
  };

  /**
   * Gets (fetch) from the BE the available categories that can be used within the dropdowns
   * fetches according to the current section (feedback/problem reports)
   */
  const getFormCategories = async () => {
    if (isFeedbackTabActive(sectionId)) {
      dispatch(FeedbackAndProblemsStoreActions.creators.getFeedbackCategoriesData());
    } else {
      dispatch(FeedbackAndProblemsStoreActions.creators.getProblemsCategoriesData());
    }

    try {
      const res = await FeedbackAndProblemsAPI.methods.getFormCategories(
        isFeedbackTabActive(sectionId) ? EFormType.FEEDBACK : EFormType.REPORT_PROBLEM,
        true,
      );
      if (isFeedbackTabActive(sectionId)) {
        dispatch(FeedbackAndProblemsStoreActions.creators.setFeedbackCategoriesData(res.data));
      } else {
        dispatch(FeedbackAndProblemsStoreActions.creators.setProblemCategoriesData(res.data));
      }
    } catch (error) {
      setAlertProps({
        title: isFeedbackTabActive(sectionId)
          ? t(`${feedbacksStringPath}.errors.onFetchCategories`)
          : t(`${problemsStringPath}.errors.onFetchCategories`),
        type: AlertTypeEnum.ERROR,
      });
      showAlert();
    }
  };

  /**
   * Gets (fetch) from the BE the current active categories
   * fetches according to the current section (feedback/problem reports)
   */
  const getFormDetails = async () => {
    if (isFeedbackTabActive(sectionId)) {
      dispatch(FeedbackAndProblemsStoreActions.creators.getFeedbackDetailsData());
    } else {
      dispatch(FeedbackAndProblemsStoreActions.creators.getProblemsDetailsData());
    }

    try {
      const res = await FeedbackAndProblemsAPI.methods.getFormDetails(
        isFeedbackTabActive(sectionId) ? EFormType.FEEDBACK : EFormType.REPORT_PROBLEM,
      );
      if (isFeedbackTabActive(sectionId)) {
        dispatch(FeedbackAndProblemsStoreActions.creators.setFeedbackDetailsData(res.data));
      } else {
        dispatch(FeedbackAndProblemsStoreActions.creators.setProblemDetailsData(res.data));
      }
    } catch (error) {
      setAlertProps({
        title: isFeedbackTabActive(sectionId)
          ? t(`${feedbacksStringPath}.errors.onFetchDetails`)
          : t(`${problemsStringPath}.errors.onFetchDetails`),
        type: AlertTypeEnum.ERROR,
      });
      showAlert();
    }
  };

  /**
   * Makes an update request to the BE in order to update the current active categories
   * fetches according to the current section (feedback/problem reports)
   * @param payload IFormDetailsRequest
   */
  const updateFormDetails = async (payload: IFormDetailsRequest) => {
    if (isFeedbackTabActive(sectionId)) {
      dispatch(FeedbackAndProblemsStoreActions.creators.updateFeedbacksData());
    } else {
      dispatch(FeedbackAndProblemsStoreActions.creators.updateProblemsData());
    }

    try {
      await FeedbackAndProblemsAPI.methods.updateFormDetails(
        isFeedbackTabActive(sectionId) ? EFormType.FEEDBACK : EFormType.REPORT_PROBLEM,
        payload,
      );
      toggleSectionDetailsDrawer();
      setAlertProps({
        title: isFeedbackTabActive(sectionId)
          ? t(`${feedbacksStringPath}.success.onUpdateFormDetails`)
          : t(`${problemsStringPath}.success.onUpdateFormDetails`),
        type: AlertTypeEnum.SUCCESS,
      });
      showAlert();
      await getFormDetails(); // TODO: This can be improved, with BE support
    } catch (error) {
      setAlertProps({
        title: isFeedbackTabActive(sectionId)
          ? t(`${feedbacksStringPath}.errors.onUpdateFormDetails`)
          : t(`${problemsStringPath}.errors.onUpdateFormDetails`),
        type: AlertTypeEnum.ERROR,
      });
      showAlert();
    }
  };

  /**
   * Gets (fetch) from the BE the languages that this market supports
   */
  const getMarketLanguages = async () => {
    try {
      const res = await LanguagesApi.methods.getAvailableLanguages();
      setMarketLanguages(res.data.availableLanguages);
    } catch (error) {
      setAlertProps({
        type: AlertTypeEnum.ERROR,
        title: t("commons.getLanguagesError"),
      });
      showAlert();
    }
  };

  /**
   * Hides/closes as also shows/open the Section Details Drawer
   */
  const toggleSectionDetailsDrawer = () => setIsSectionDetailsDrawerOpen((prevState: boolean) => !prevState);

  /**
   * Close and open the Section Result Drawer
   */
  const toggleSectionResultsDrawer = () => setIsSectionResultDrawerOpen((prevState: boolean) => !prevState);

  /**
   * Open and close the customer survey drawer
   */
  const toggleCustomerDetailsDrawer = () => setIsCustomerDetailsDrawerOpen((prevState: boolean) => !prevState);

  /**
   * Handles pagination buttons clicks
   * When the user clicks on another page number
   * this will update the state (active page), the requestParams variable
   * and will make a request to the BE
   * @param pageNumber number
   */
  const handlePageChange = async (pageNumber: number) => {
    if (pageNumber === activePage) {
      return;
    }

    setActivePage(pageNumber);
    requestParams = {
      ...requestParams,
      ...tempRequestParams,
      pageNumber: pageNumber,
    };

    await getFormData();
  };

  /**
   * Handles refresh button clicks
   * (Almost the same as handlePageChange())
   */
  const refreshFormResults = async () => {
    requestParams = {
      ...requestParams,
      ...tempRequestParams,
    };

    await getFormData();
  };

  React.useEffect(() => {
    requestParams = {
      ...requestParams,
      pageNumber: activePage,
      pageSize: pConfigs.NUMBER_OF_ROWS_PER_PAGE,
    };

    (async () => {
      await getFormData();
      await getFormCategories();
      await getFormDetails();
      await getMarketLanguages();
    })();

    return () => {
      requestParams = {
        searchQuery: "",
        startDate: "",
        endDate: "",
        categoryId: "",
        status: "" as EFormDataStatus,
        pageNumber: pConfigs.INITIAL_PAGE,
        pageSize: pConfigs.NUMBER_OF_ROWS_PER_PAGE,
      };

      tempRequestParams = {
        searchQuery: "",
        startDate: "",
        endDate: "",
        categoryId: "",
        status: "" as EFormDataStatus,
      };
    };
  }, [sectionId]);

  return (
    <>
      <PageContainer>
        <PageTitle
          breadcrumbs={getBreadcrumbs(t)}
          title={t("pages.feedbackAndProblemsReport.title")}
          navigateFunction={history.push}
          goBackFn={() => history.push(RoutesEnum.CONFIGS)}
          separatorStyle={{ display: "none" }}
          titleContainerStyle={{ marginTop: "13px", marginBottom: "20px", height: "60px" }}
          rightComponentContainerStyle={{ display: "flex", alignContent: "center", height: "100%", marginRight: "0px" }}
          rightComponent={
            <ButtonContainer style={{ position: "relative", top: "10px", display: "none" }}>
              <PrimaryButton
                id={"publish-changes"}
                disabled={true}
                titleLabel={t("pages.feedbackAndProblemsReport.saveChanges")}
                type={"submit"}
                redTheme={true}
                style={{ width: "fit-content" }}
                onClick={() => null}
              />
            </ButtonContainer>
          }
        />

        <PageContents>
          <Tabs controlledSelectedTab={sectionId} controlledSetSelectedTab={setSectionId}>
            {pConfigs.PAGE_SECTIONS.map((title, index) => (
              <TabChild key={index} label={title}>
                {(sectionId === 0 || sectionId === 1) && (
                  <SectionDetailsComponent toggleSideDrawer={toggleSectionDetailsDrawer} sectionId={sectionId} />
                )}

                {sectionId === 2 && <SectionCustomerDetailsComponent />}

                <SectionFiltersComponent
                  sectionId={sectionId}
                  searchInputMaxLength={pConfigs.SEARCH_INPUT_MAX_LENGTH}
                  selectableStatuses={getSelectableStatus(sectionId, t)}
                  onClickRefresh={refreshFormResults}
                  staticParams={tempRequestParams}
                />

                {(sectionId === 0 || sectionId === 1) && (
                  <SectionResultsTableComponent
                    sectionId={sectionId}
                    columnsConfig={pConfigs.SECTION_COLUMNS}
                    isResultOfSearch={isResultOfSearch}
                    toggleSideDrawer={toggleSectionResultsDrawer}
                    selectedRowData={setSectionResultDrawerData}
                    onSelectedRowDataStatusChange={updateFormDataRow}
                    translations={t}
                  />
                )}

                {sectionId === 2 && (
                  <SectionCustomerResultsTableComponent
                    sectionId={sectionId}
                    columnsConfig={pConfigs.SECTION_COLUMNS}
                    isResultOfSearch={isResultOfSearch}
                    toggleSideDrawer={toggleCustomerDetailsDrawer}
                    selectedRowData={setCustomerDetailsDrawerData}
                    onSelectedRowDataStatusChange={updateFormDataRow}
                    translations={t}
                  />
                )}

                <ResultsTablePaginationComponent
                  sectionId={sectionId}
                  currentActivePage={activePage}
                  numberOfRowsPerPage={pConfigs.NUMBER_OF_ROWS_PER_PAGE}
                  onPageChange={handlePageChange}
                  feedbacks={feedbacks}
                  problemReports={problemReports}
                  customerSurvey={customerSurvey}
                />
              </TabChild>
            ))}
          </Tabs>
        </PageContents>
      </PageContainer>

      <SideDrawer toggleSideDrawer={toggleSectionDetailsDrawer} open={isSectionDetailsDrawerOpen}>
        <DetailsDrawerComponent
          sectionId={sectionId}
          activeCategories={isFeedbackTabActive(sectionId) ? feedbackDetails : problemReportsDetails}
          selectableMarketLanguages={marketLanguages}
          closeDrawer={toggleSectionDetailsDrawer}
          selectedMarketLanguage={selectedMarketLanguage}
          onClickSubmitChanges={updateFormDetails}
          onClickLanguageSelector={setSelectedMarketLanguage}
          categoryList={isFeedbackTabActive(sectionId) ? feedbackCategories : problemReportsCategories}
        />
      </SideDrawer>

      <SideDrawer toggleSideDrawer={toggleCustomerDetailsDrawer} open={isCustomerDetailsDrawerOpen}>
        <ResultsTableCustomerDrawerComponent
          drawerBodyData={customerDetailsDrawerData}
          closeDrawer={toggleCustomerDetailsDrawer}
        />
      </SideDrawer>

      <SideDrawer toggleSideDrawer={toggleSectionResultsDrawer} open={isSectionResultDrawerOpen}>
        <ResultsTableDrawerComponent
          sectionId={sectionId}
          drawerBodyData={sectionResultDrawerData}
          closeDrawer={toggleSectionResultsDrawer}
        />
      </SideDrawer>
    </>
  );
};

export default React.memo(FeedbackAndProblemsReport);

export const PageContents = styled("div")`
  font-family: Vodafone Rg;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 0 0 0;

  & .container_body_cards {
    position: relative;
    width: 100%;
  }

  & .container_body_tabs {
    position: relative;
    width: 100%;
  }
`;
