import React, { useState, useEffect } from "react";
import { PrimaryButton, TextInput } from "@wit/mpesa-ui-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { RegExEpressions } from "../../../../shared/shared.utils";
import { feedbackAndProblemsPageConfigs } from "../feedback-and-problems.utils";
import { ICategoryCreationModalComponentProps, ICreateFormCategoryRequest } from "../feedback-and-problems.model";
import {
  feedbacksStringPath,
  problemsStringPath,
  isFeedbackTabActive,
  getFormattedMarketLanguages,
} from "../feedback-and-problems.utils";
import useEmptyLanguageWarningModal from "../../../../shared/hooks/use-empty-language-warning-modal.hook";

const CategoryCreationModalComponent = ({
  sectionId,
  marketLanguages,
  categoryList,
  createFormCategory,
  closeCategoryCreationModal,
}: ICategoryCreationModalComponentProps) => {
  const [t] = useTranslation();
  const pConfig = feedbackAndProblemsPageConfigs(t);

  const [isSubmitEnabled, setIsSubmitEnabled] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [formData, setFormData] = useState<ICreateFormCategoryRequest>({
    name: {},
  });

  /**
   * Handle Input-Field Key Up Event.
   * Verifies on each key up event if everything is okay
   * to be submitted oor not, if there is an error, it will keep the submit button disabled.
   */
  const onKeyUp = () => {
    let errors = Object.values(formErrors).length;
    let emptyInputFields = false;
    let showWarningModal = false;

    Object.values(formErrors).forEach(error => {
      if (error === "") {
        errors = errors - 1;
      }
    });

    marketLanguages.map(l => {
      if (l.mandatory && (formData.name[l.code] === "" || !formData.name[l.code])) {
        emptyInputFields = true;
      } else if (formData.name[l.code] === "" || !formData.name[l.code]) {
        showWarningModal = true;
      }
    });

    if (errors >= 1 || emptyInputFields) {
      setIsSubmitEnabled(false);
    } else {
      setIsSubmitEnabled(true);
    }
    setShowWarning(showWarningModal);
  };

  const { warningModalOpen, showWarning, setShowWarning } = useEmptyLanguageWarningModal(marketLanguages || []);

  /**
   * Handle Input-Field Change Event.
   * On input changes, it verifies the current value in the input field
   * filters the value, and verifies if the given string already exists
   * if exists - show error otherwise allow the operation to proceed
   * @param eve React.ChangeEvent<HTMLInputElement>
   */
  const onChange = (eve: React.ChangeEvent<HTMLInputElement>) => {
    if (eve.target.value === "") {
      setFormData({
        name: {
          ...formData.name,
          [eve.currentTarget.id]: "",
        },
      });
      return;
    }
    const filteredStrings = eve.target.value.replace(RegExEpressions.especialCharacters, "");
    const inputValue = filteredStrings[0].toUpperCase() + filteredStrings.slice(1).toLowerCase();
    if (JSON.stringify(categoryList).includes(`"${eve.currentTarget.id}":"${inputValue}"`)) {
      setFormErrors({
        ...formErrors,
        [eve.currentTarget.id.toLocaleLowerCase()]: isFeedbackTabActive(sectionId)
          ? `${t(feedbacksStringPath + ".errors.onTypeNewCategoryName")}`
          : `${t(problemsStringPath + ".errors.onTypeNewCategoryName")}`,
      });
    } else {
      setFormErrors({
        ...formErrors,
        [eve.currentTarget.id.toLocaleLowerCase()]: "",
      });
    }
    setFormData({
      name: {
        ...formData.name,
        [eve.currentTarget.id]: inputValue,
      },
    });
  };

  /**
   * Generates input fields according to market languages
   * Example, if the market has 3 languages, it will generate 3 input fields that are obligatory/required
   * @returns Array of Elements (InputFields)
   */
  const getInputFields = () => {
    return Object.keys(formData.name).map((item, index) => {
      return (
        <InputBoxesContainerRow key={index}>
          <TextInput
            id={item.toLocaleLowerCase()}
            required={marketLanguages.find(l => l.code === item)?.mandatory}
            value={formData.name[item.toLocaleUpperCase()]}
            maxLength={pConfig.CATEGORIES_INPUT_MAX_LENGTH}
            minLength={pConfig.CATEGORIES_INPUT_MIN_LENGTH}
            title={
              isFeedbackTabActive(sectionId)
                ? t(`${feedbacksStringPath}.detailsArea.modal.inputBoxLabel`).replace(
                    "{lang}",
                    `(${item.toLocaleUpperCase()})`,
                  )
                : t(`${problemsStringPath}.detailsArea.modal.inputBoxLabel`).replace(
                    "{lang}",
                    `(${item.toLocaleUpperCase()})`,
                  )
            }
            placeholder={
              isFeedbackTabActive(sectionId)
                ? t(`${feedbacksStringPath}.detailsArea.modal.inputBoxPlaceholder`).replace(
                    "{lang}",
                    `(${item.toLocaleUpperCase()})`,
                  )
                : t(`${problemsStringPath}.detailsArea.modal.inputBoxPlaceholder`).replace(
                    "{lang}",
                    `(${item.toLocaleUpperCase()})`,
                  )
            }
            onChange={onChange}
            onKeyUp={onKeyUp}
            disabled={false}
            error={formErrors[item.toLocaleLowerCase()] && formErrors[item.toLocaleLowerCase()]}
          />
        </InputBoxesContainerRow>
      );
    });
  };

  /**
   * According to marketLanguages, on initial render and on marketLanguages changes
   * this useEffect block we will populate the formErrors and formData state
   */
  useEffect(() => {
    const { languages } = getFormattedMarketLanguages(marketLanguages);
    setFormErrors(languages.reduce((state, value) => ({ ...state, [value.toLocaleLowerCase()]: "" }), {}));
    setFormData(
      languages.reduce(
        (state, value) => ({
          ...state,
          name: { ...state.name, [value.toLocaleLowerCase()]: "" },
        }),
        {
          name: {},
        },
      ),
    );
  }, [marketLanguages]);

  /**
   * Submit method wrapper to show warning if needed
   * @param values
   */
  const submitWrapper = (formData: ICreateFormCategoryRequest) => {
    if (showWarning) {
      warningModalOpen(createFormCategory, formData);
    } else {
      createFormCategory(formData);
    }
  };

  return (
    <ModalContainer>
      <ModalTitle>
        {isFeedbackTabActive(sectionId)
          ? t(`${feedbacksStringPath}.detailsArea.modal.title`)
          : t(`${problemsStringPath}.detailsArea.modal.title`)}
      </ModalTitle>
      <InputBoxesContainer>{getInputFields()}</InputBoxesContainer>
      <ButtonsContainer>
        <div>
          <PrimaryButton
            redTheme={false}
            id={"cancel-btn"}
            titleLabel={
              isFeedbackTabActive(sectionId)
                ? t(`${feedbacksStringPath}.detailsArea.modal.cancel`)
                : t(`${problemsStringPath}.detailsArea.modal.cancel`)
            }
            onClick={closeCategoryCreationModal}
            type={"button"}
          />
        </div>
        <div>
          <PrimaryButton
            redTheme={true}
            id={"create-btn"}
            titleLabel={
              isFeedbackTabActive(sectionId)
                ? t(`${feedbacksStringPath}.detailsArea.modal.create`)
                : t(`${problemsStringPath}.detailsArea.modal.create`)
            }
            type={"submit"}
            onClick={() => submitWrapper(formData)}
            disabled={!isSubmitEnabled}
          />
        </div>
      </ButtonsContainer>
    </ModalContainer>
  );
};

export default CategoryCreationModalComponent;

const ModalContainer = styled("div")`
  border-radius: 6px;
  box-shadow: 0 4px 4px rgba(142, 142, 142, 0.23), 0 3px 10px rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.palette.white};
  padding: 24px;
`;

const ModalTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 24px;
`;

const InputBoxesContainer = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const InputBoxesContainerRow = styled("div")`
  width: 100%;
  text-align: left;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  font-family: inherit;
  margin-bottom: 24px;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  > div {
    :first-of-type {
      margin-right: 12px;
    }
  }
`;
