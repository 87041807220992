import { MultipleOptionsDropdown, SearchBar } from "@wit/mpesa-ui-components";
import MultipleOptionNestedDropdown from "@wit/mpesa-ui-components/lib/components/dropdown/multiple-option-nested-dropdown/multiple-option-nested-dropdown.component";
import { FilterFuncts } from "@wit/mpesa-ui-components/lib/hooks/use-filters.hook";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { OtherFiltersContainer, SearchBarFilterContainer } from "../../../shared/responsive-ui.styled";
import { SelectedView } from "../../../shared/shared.enums";
import { getServiceManagerServiceStatus } from "../../../shared/shared.utils";
import { Row } from "./../../../shared/shared.styled";
import useServiceGroupsFilter from "./use-service-groups-filter.hook";

export enum ServiceManagerFiltersEnum {
  SEARCH = "search",
  STATUS = "status",
  SERVICES_TYPES = "services",
}

export enum ExternalServicesFiltersEnum {
  SEARCH = "search",
  PERMISSIONS = "permissions",
}

interface IServicesFiltersProps extends FilterFuncts {
  selectedView: SelectedView;
  setSelectedView: (selectedView: SelectedView) => any;
}

/**
 * Component to show and manage filters on service manager
 */
const ServiceManagerFilters = ({
  filters,
  clearFilter,
  isFilterActive,
  updateFilter,
  updateMultipleFilters,
}: IServicesFiltersProps) => {
  // Hook initializations
  const [t] = useTranslation();
  const {
    getServicesGroupOptions,
    toggleServiceGroupOption,
    isOptionSelected,
    getSelectedLabels,
  } = useServiceGroupsFilter();

  /**
   * This function retrieve labels for the services items for labeling purposes
   * @param itemsList
   * @param path
   * @returns array of labels
   */
  const getServiceTypeDropdownLabel = (itemsList: string[], path: string) => {
    return itemsList.map((item: string) => t(`${path}.${item}`));
  };

  /**
   * Retrieve the values used to compose the label regarding ServiceManagerServiceStatusSearchEnum
   * @param itemsList
   * @returns array of allowed items to compose the label
   */
  const getServiceManagerServiceStatusSearchEnumForLabel = (itemsList: string[]) => {
    return itemsList.filter(item => getServiceManagerServiceStatus().find(itemList => itemList.key === item));
  };

  return (
    <ServicesFilterContainer>
      <SearchBarFilterContainer>
        <SearchBar
          placeholderLabel={t("pages.serviceBuilder.filters.searchPlaceholder")}
          value={
            filters.has(ServiceManagerFiltersEnum.SEARCH)
              ? (filters.get(ServiceManagerFiltersEnum.SEARCH) as string[])[0]
              : ""
          }
          onChange={e => updateFilter(ServiceManagerFiltersEnum.SEARCH, e.target.value, true)}
          clearValue={() => clearFilter(ServiceManagerFiltersEnum.SEARCH)}
        />
      </SearchBarFilterContainer>
      <OtherFiltersContainer>
        <MultipleOptionsDropdown
          label={
            filters.has(ServiceManagerFiltersEnum.STATUS)
              ? `${getServiceTypeDropdownLabel(
                  getServiceManagerServiceStatusSearchEnumForLabel(
                    filters.get(ServiceManagerFiltersEnum.STATUS) as string[],
                  ),
                  "commons.serviceBuilder.enums.status",
                )}`
              : t("pages.serviceBuilder.filters.statusDropdownPlaceholder")
          }
          options={getServiceManagerServiceStatus()}
          toggleOption={val => updateFilter(ServiceManagerFiltersEnum.STATUS, val.key)}
          hasValue={filters.has(ServiceManagerFiltersEnum.STATUS)}
          isOptionSelected={val => isFilterActive(ServiceManagerFiltersEnum.STATUS, val.key)}
          clearAllFilters={() => clearFilter(ServiceManagerFiltersEnum.STATUS)}
        />
      </OtherFiltersContainer>
      <OtherFiltersContainer>
        <MultipleOptionNestedDropdown
          options={getServicesGroupOptions()}
          isOptionSelected={opt => isOptionSelected(isFilterActive, opt)}
          clearAllFilters={() => {
            clearFilter(ServiceManagerFiltersEnum.SERVICES_TYPES);
          }}
          hasValue={filters.has(ServiceManagerFiltersEnum.SERVICES_TYPES)}
          label={getSelectedLabels(filters, t("pages.serviceBuilder.filters.serviceTypeDropdownPlaceholder"))}
          toggleOption={opt => toggleServiceGroupOption(filters, updateMultipleFilters, opt)}
        />
      </OtherFiltersContainer>
    </ServicesFilterContainer>
  );
};

const ServicesFilterContainer = styled(Row)`
  margin-bottom: 49px;
  margin-top: 41px;
  flex-wrap: wrap;
`;

export default ServiceManagerFilters;
