import { AutoClosingDropdown, Logout, SecondaryPageTitle, SecurityIcon, SmallButton } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled, { ThemeContext } from "styled-components";
import { IStoreInterface } from "../../../configs/store.config";
import { AuthenticationTypeEnum } from "../../../features/admin/users/users.interfaces";
import { ILoggedUser } from "../../../features/authentication/authentication.interfaces";
import { IconContainer } from "../../shared.styled";
import LastLogins from "./last-logins.component";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { useLanguageManager } from "../../hooks/use-language-manager.hook";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { ILanguage } from "../../models/language.model";

export interface IUserAreaProps {
  hideDrawer: () => void;
  onLogoutClick: () => void;
  onChangePasswordClick: () => void;
}

/**
 * UserArea component
 * Side menu with logged user info
 */
const UserArea = ({ hideDrawer, onLogoutClick, onChangePasswordClick }: IUserAreaProps) => {
  const [t] = useTranslation();
  const user = useSelector((s: IStoreInterface) => s.authenticationReducer.loggedUser as ILoggedUser);
  const themeContext = useContext(ThemeContext);
  const { languages, changeLanguage, showLanguageSelector, selectedLanguage } = useLanguageManager();

  const languagesDropDown: SharedDropdownOption[] = useMemo(
    () =>
      languages.map(
        (language: ILanguage): SharedDropdownOption => ({
          label: language.name,
          key: language.code,
        }),
      ),
    [languages],
  );

  return (
    <UserAreaContainer>
      <SecondaryPageTitle
        backgroundColor="#fff"
        titleFontSize={24}
        initialsFontSize={20.2}
        circleMarginLeft={16}
        goBackFn={hideDrawer}
        height={104}
        initialsCircleDimension={48}
        paddingLeft={82}
        title={user.fullName}
      />
      <UserAreaContent>
        <div>
          <UserAreaTitle>{t("components.userArea.title")}</UserAreaTitle>
          <UserAreaRow>
            <UserAreaRowTitle>{t("components.userArea.usernameLabel")}</UserAreaRowTitle>
            <UserAreaRowContent>{user.username}</UserAreaRowContent>
          </UserAreaRow>
          <UserAreaRow>
            <UserAreaRowTitle>{t("components.userArea.role")}</UserAreaRowTitle>
            <UserAreaRowContent>{t(`userRoles.${user.role}`)}</UserAreaRowContent>
          </UserAreaRow>
          <LastLogins />
          {showLanguageSelector && (
            <LanguageContainer>
              <UserAreaTitle>{t("components.userArea.preferedLanguage")}</UserAreaTitle>
              <UserAreaRow>
                <UserAreaRowTitle>{t("components.userArea.language")}</UserAreaRowTitle>
                <UserAreaRowContent>
                  <AutoClosingDropdown
                    options={languagesDropDown}
                    selectOption={(lng: SharedDropdownOption) => changeLanguage(lng.key)}
                    label={selectedLanguage.name || t("components.userArea.language")}
                    hasValue={true}
                    dropdownType={DropdownType.RECTANGULAR_NORMAL}
                  />
                </UserAreaRowContent>
              </UserAreaRow>
            </LanguageContainer>
          )}
        </div>
        <div>
          {user.authenticationType !== AuthenticationTypeEnum.LDAP && (
            <div id="change-password-button">
              <SmallButton
                onClick={onChangePasswordClick}
                titleLabel={t("components.userArea.changePassword")}
                iconComponent={
                  <IconContainer size={20} color={themeContext.palette.vodafoneRed}>
                    <SecurityIcon />
                  </IconContainer>
                }
              />
            </div>
          )}
          <div id="log-out-button">
            <SmallButton
              onClick={onLogoutClick}
              titleLabel={t("components.userArea.logout")}
              iconComponent={
                <IconContainer size={20} color={themeContext.palette.vodafoneRed}>
                  <Logout />
                </IconContainer>
              }
            />
          </div>
        </div>
      </UserAreaContent>
    </UserAreaContainer>
  );
};

export default UserArea;

const UserAreaContainer = styled("div")`
  height: 100%;
  display: flex;
  flex-direction: column;
  & > div {
    position: relative;
  }

  * {
    direction: ltr !important;
  }
`;

const UserAreaContent = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 11px 50px 118px;
  align-items: flex-start;
  width: 100%;
  flex: 1;

  > div {
    width: 100%;
  }

  button {
    &:first-of-type {
      margin-bottom: 12px;
    }
    path {
      stroke: ${styleTheme.palette.red};
    }
  }
`;

const UserAreaTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 18px;
  color: ${styleTheme.palette.black};
  margin-bottom: 9px;
`;

export const UserAreaRow = styled("div")`
  display: flex;
  height: 55px;
  border-bottom: solid 1px ${styleTheme.palette.aluminium};
  align-items: center;
`;

export const UserAreaRowTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: #999;
  flex: 1;
`;

export const UserAreaRowContent = styled("span")`
  flex: 1;
  font-family: Vodafone Rg;
  font-size: 16px;
  color: #333;
  display: flex;
`;

export const LanguageContainer = styled("div")`
  margin-top: 50px;
`;
