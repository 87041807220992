import { IServiceManagerServiceDetails } from "../../../shared/models/service-manager.model";
import produce from "immer";
import { IPayloadAction } from "../../../shared/shared.interfaces";
import { AuthenticationActions } from "../../authentication/authentication.store";

export const ServiceManagerServiceDetailsActions = {
  types: {
    FETCHING_SM_SERVICE_DETAILS: "FETCHING_SM_SERVICE_DETAILS",
    FETCH_SM_SERVICE_DETAILS_SUCCESS: "FETCH_SM_SERVICE_DETAILS_SUCCESS",
    FETCH_SM_SERVICE_DETAILS_ERROR: "FETCH_SM_SERVICE_DETAILS_ERROR",
    FETCH_SM_SERVICE_DETAILS_LEAVE: "FETCH_SM_SERVICE_DETAILS_LEAVE",
    UPDATE_SM_SERVICE_DETAILS: "UPDATE_SM_SERVICE_DETAILS",
    UPDATE_SM_SERVICE_STATUS: "UPDATE_SM_SERVICE_STATUS",
    UPDATE_SM_SERVICE_ADD_GROUP: "UPDATE_SM_SERVICE_ADD_GROUP",
    UPDATE_SM_SERVICE_REMOVE_GROUP: "UPDATE_SM_SERVICE_REMOVE_GROUP",
    DELETE_SM_SERVICE_IN_DETAILS: "DELETE_SM_SERVICE_IN_DETAILS",
    UPDATE_SM_SERVICE_IN_DETAILS: "UPDATE_SM_SERVICE_IN_DETAILS",
  },
  creators: {
    fetchingServiceDetailsAction: () => ({
      type: ServiceManagerServiceDetailsActions.types.FETCHING_SM_SERVICE_DETAILS,
    }),
    fetchServiceDetailsSuccessAction: (serviceDetails: IServiceManagerServiceDetails) => ({
      type: ServiceManagerServiceDetailsActions.types.FETCH_SM_SERVICE_DETAILS_SUCCESS,
      payload: {
        serviceDetails,
      },
    }),
    fetchingServiceDetailsLeaveAction: () => ({
      type: ServiceManagerServiceDetailsActions.types.FETCH_SM_SERVICE_DETAILS_LEAVE,
    }),
    updateServiceManagerServiceDetails: (serviceId: string, values: any) => ({
      type: ServiceManagerServiceDetailsActions.types.UPDATE_SM_SERVICE_DETAILS,
      payload: {
        serviceId,
        values,
      },
    }),
    updateServiceManagerServiceStatus: (serviceId: string) => ({
      type: ServiceManagerServiceDetailsActions.types.UPDATE_SM_SERVICE_STATUS,
      payload: {
        serviceId,
      },
    }),
    updateServiceManagerAddGroup: (serviceId: string, values: any) => ({
      type: ServiceManagerServiceDetailsActions.types.UPDATE_SM_SERVICE_ADD_GROUP,
      payload: {
        serviceId,
        values,
      },
    }),
    updateServiceManagerRemoveGroup: (serviceId: string, values: any) => ({
      type: ServiceManagerServiceDetailsActions.types.UPDATE_SM_SERVICE_REMOVE_GROUP,
      payload: {
        serviceId,
        values,
      },
    }),
  },
};

export interface IServiceManagerServiceDetailsReducerInterface {
  serviceDetails: IServiceManagerServiceDetails;
  isLoadingServiceDetails: boolean;
}

const initialState: IServiceManagerServiceDetailsReducerInterface = {
  serviceDetails: {} as IServiceManagerServiceDetails,
  isLoadingServiceDetails: true,
};

export const serviceManagerServiceDetailsReducer = produce(
  (draft: IServiceManagerServiceDetailsReducerInterface, action: IPayloadAction<any>) => {
    switch (action.type) {
      case ServiceManagerServiceDetailsActions.types.FETCHING_SM_SERVICE_DETAILS:
        draft.isLoadingServiceDetails = true;
        return;
      case ServiceManagerServiceDetailsActions.types.FETCH_SM_SERVICE_DETAILS_SUCCESS:
        draft.isLoadingServiceDetails = false;
        draft.serviceDetails = action.payload.serviceDetails;
        return;
      case ServiceManagerServiceDetailsActions.types.FETCH_SM_SERVICE_DETAILS_ERROR:
        draft.isLoadingServiceDetails = false;
        return;
      case ServiceManagerServiceDetailsActions.types.DELETE_SM_SERVICE_IN_DETAILS: {
        return;
      }
      case ServiceManagerServiceDetailsActions.types.UPDATE_SM_SERVICE_IN_DETAILS:
      case ServiceManagerServiceDetailsActions.types.UPDATE_SM_SERVICE_DETAILS:
      case ServiceManagerServiceDetailsActions.types.UPDATE_SM_SERVICE_STATUS:
      case ServiceManagerServiceDetailsActions.types.UPDATE_SM_SERVICE_ADD_GROUP:
      case ServiceManagerServiceDetailsActions.types.UPDATE_SM_SERVICE_REMOVE_GROUP:
        draft.serviceDetails = {
          ...draft.serviceDetails,
          ...action.payload.serviceDetails,
        };
        return;
      case AuthenticationActions.types.LOGOUT:
      case ServiceManagerServiceDetailsActions.types.FETCH_SM_SERVICE_DETAILS_LEAVE:
        return initialState;
      default:
        return draft;
    }
  },
  initialState,
);
