import produce from "immer";
import { PayloadAction } from "@wit/mpesa-ui-components/lib/hooks/table-state-management";
import { IBusiness, IBusinessesResponse } from "../../../shared/models/business.model";
import { ICategory } from "../../../shared/models/category.model";

export const BusinessesActions = {
  types: {
    GET_BUSINESSES_PENDING: "GET_BUSINESSES_PENDING",
    GET_BUSINESSES_SUCCESS: "GET_BUSINESSES_SUCCESS",
    GET_BUSINESSES_ERROR: "GET_BUSINESSES_ERROR",
    DELETE_BUSINESS_SUCCESS: "DELETE_BUSINESS_SUCCESS",
    REVERT_DELETE_BUSINESS_SUCCESS: "REVERT_DELETE_BUSINESS_SUCCESS",
    CREATE_BUSINESS_SUCCESS: "CREATE_BUSINESS_SUCCESS",
    EDIT_BUSINESS_SUCCESS: "EDIT_BUSINESS_SUCCESS",
    GET_LAST_VERSION_SUCCESS: "GET_LAST_VERSION_SUCCESS",
    GET_BUSINESSES_STATUS: "GET_BUSINESSES_STATUS",

    GET_CATEGORIES_PENDING: "GET_CATEGORIES_PENDING",
    GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
    GET_CATEGORIES_ERROR: "GET_CATEGORIES_ERROR",
    DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",
    REVERT_DELETE_CATEGORY_SUCCESS: "REVERT_DELETE_CATEGORY_SUCCESS",
    CREATE_CATEGORY_SUCCESS: "CREATE_CATEGORY_SUCCESS",
    EDIT_CATEGORY_SUCCESS: "EDIT_CATEGORY_SUCCESS",
    GET_CATEGORY_LAST_VERSION_SUCCESS: "GET_CATEGORY_LAST_VERSION_SUCCESS",
  },
  creators: {
    getCategoriesSuccessAction: (categories: ICategory[]) => ({
      type: BusinessesActions.types.GET_CATEGORIES_SUCCESS,
      payload: {
        categories,
      },
    }),
    getBusinessesStatusSuccessAction: (status: string) => ({
      type: BusinessesActions.types.GET_BUSINESSES_STATUS,
      payload: {
        status,
      },
    }),

    getBusinessesSuccessAction: (businesses: IBusiness[]) => ({
      type: BusinessesActions.types.GET_BUSINESSES_SUCCESS,
      payload: {
        businesses,
      },
    }),
    getLastVersionSuccessAction: (businesses: IBusinessesResponse) => ({
      type: BusinessesActions.types.GET_LAST_VERSION_SUCCESS,
      payload: {
        businesses,
      },
    }),
    createBusinessSuccessAction: (business: IBusiness) => ({
      type: BusinessesActions.types.CREATE_BUSINESS_SUCCESS,
      payload: {
        business,
      },
    }),
    editBusinessSuccessAction: (business: IBusiness) => ({
      type: BusinessesActions.types.EDIT_BUSINESS_SUCCESS,
      payload: {
        business,
      },
    }),
    deleteBusinessSuccessAction: (business: IBusiness) => ({
      type: BusinessesActions.types.DELETE_BUSINESS_SUCCESS,
      payload: {
        business,
      },
    }),
    restoreBusinessSuccessAction: (business: IBusiness) => ({
      type: BusinessesActions.types.REVERT_DELETE_BUSINESS_SUCCESS,
      payload: {
        business,
      },
    }),
    createCategorySuccessAction: (category: ICategory) => ({
      type: BusinessesActions.types.CREATE_CATEGORY_SUCCESS,
      payload: {
        category,
      },
    }),
    editCategorySuccessAction: (category: ICategory) => ({
      type: BusinessesActions.types.EDIT_CATEGORY_SUCCESS,
      payload: {
        category,
      },
    }),
    deleteCategorySuccessAction: (category: ICategory) => ({
      type: BusinessesActions.types.DELETE_CATEGORY_SUCCESS,
      payload: {
        category,
      },
    }),
    revertDeleteCategorySuccessAction: (category: ICategory) => ({
      type: BusinessesActions.types.REVERT_DELETE_CATEGORY_SUCCESS,
      payload: {
        category,
      },
    }),
  },
};

export enum BusinessStatusEnum {
  LIVE = "LIVE",
  ENABLE = "ENABLE",
  DISABLE = "DISABLE",
  DISABLED = "DISABLED ",
  EDIT = "EDIT",
  DELETE = "DELETE",
}

export interface IBusinessesReducerInterface {
  businesses: IReducerData<IBusiness[]>;
  businessesStatus: IReducerData<String>;
  categories: IReducerData<ICategory[]>;
}

export interface IReducerData<T> {
  isLoaded: boolean;
  data: T;
  lastVersion: T;
  isLoading: boolean;
  popularCount?: {
    ALL: number;
    CONSUMER: number;
    ORGANIZATION: number;
  };
}

const initialState: IBusinessesReducerInterface = {
  businesses: {
    isLoading: false,
    isLoaded: false,
    lastVersion: [],
    data: [],
  },
  businessesStatus: {
    isLoading: false,
    isLoaded: false,
    lastVersion: BusinessStatusEnum.LIVE,
    data: BusinessStatusEnum.LIVE,
  },
  categories: {
    isLoading: false,
    isLoaded: false,
    data: [],
    lastVersion: [],
  },
};

export const businessesReducer = produce((draft: IBusinessesReducerInterface, action: PayloadAction<any>) => {
  switch (action.type) {
    case BusinessesActions.types.GET_CATEGORIES_SUCCESS:
      draft.categories.data = action.payload.categories;
      return;
    case BusinessesActions.types.CREATE_CATEGORY_SUCCESS: {
      const { category } = action.payload;
      category.deleted = false;
      draft.categories.data.push(category);
      return;
    }
    case BusinessesActions.types.EDIT_CATEGORY_SUCCESS: {
      const { category } = action.payload;
      const categoryIdx = draft.categories.data.findIndex(c => c.id === category.id);
      if (categoryIdx > -1) {
        draft.categories.data.splice(categoryIdx, 1, category);
      }
      return;
    }
    case BusinessesActions.types.DELETE_CATEGORY_SUCCESS: {
      const { category } = action.payload;
      const categoryIdx = draft.categories.data.findIndex(c => c.id === category.id);
      if (categoryIdx > -1) {
        draft.categories.data[categoryIdx].status = "DELETE";
      }
      return;
    }
    case BusinessesActions.types.REVERT_DELETE_CATEGORY_SUCCESS: {
      const { category } = action.payload;
      const categoryIdx = draft.categories.data.findIndex(c => c.id === category.id);
      if (categoryIdx > -1) {
        draft.categories.data[categoryIdx].status = "LIVE";
      }
      return;
    }

    case BusinessesActions.types.GET_BUSINESSES_PENDING:
      draft.businesses.isLoading = true;
      draft.businesses.isLoaded = false;
      return;
    case BusinessesActions.types.GET_BUSINESSES_SUCCESS:
      draft.businesses.data = action.payload.businesses;
      draft.businesses.popularCount = {
        ALL: draft.businesses.data.filter(b => b.popular && b.popularBillType === "ALL").length,
        CONSUMER: draft.businesses.data.filter(b => b.popular && b.popularBillType === "CONSUMER").length,
        ORGANIZATION: draft.businesses.data.filter(b => b.popular && b.popularBillType === "ORGANIZATION").length,
      };
      draft.businesses.isLoading = false;
      draft.businesses.isLoaded = true;
      return;
    case BusinessesActions.types.GET_LAST_VERSION_SUCCESS:
      draft.businesses.lastVersion = action.payload.businesses.businesses;
      draft.categories.lastVersion = action.payload.businesses.categories;
      draft.businesses.isLoading = false;
      draft.businesses.isLoaded = true;
      return;
    case BusinessesActions.types.GET_BUSINESSES_ERROR:
      draft.businesses.isLoading = false;
      return;
    case BusinessesActions.types.CREATE_BUSINESS_SUCCESS: {
      const { business } = action.payload;
      business.deleted = false;
      draft.businesses.data.push(business);
      return;
    }
    case BusinessesActions.types.GET_BUSINESSES_STATUS:
      draft.businessesStatus.data = action.payload.status;
      return;
    case BusinessesActions.types.EDIT_BUSINESS_SUCCESS: {
      const { business } = action.payload;
      const businessIdx = draft.businesses.data.findIndex(b => b.id === business.id);
      if (businessIdx > -1) {
        draft.businesses.data.splice(businessIdx, 1, business);
      }
      return;
    }
    case BusinessesActions.types.DELETE_BUSINESS_SUCCESS: {
      const { business } = action.payload;
      const businessIdx = draft.businesses.data.findIndex(b => b.id === business.id);
      const lastVersionIdx = draft.businesses.lastVersion.findIndex(b => b.id === business.id);
      if (businessIdx > -1) {
        if (lastVersionIdx > -1) {
          draft.businesses.data[businessIdx].status = BusinessStatusEnum.DELETE;
        } else {
          draft.businesses.data = draft.businesses.data.slice(businessIdx, 0);
        }
      }
      return;
    }
    case BusinessesActions.types.REVERT_DELETE_BUSINESS_SUCCESS: {
      const { business } = action.payload;
      const businessIdx = draft.businesses.data.findIndex(b => b.id === business.id);
      const lastVersionIdx = draft.businesses.lastVersion.findIndex(b => b.id === business.id);
      if (businessIdx > -1) {
        draft.businesses.data[businessIdx] = draft.businesses.lastVersion[lastVersionIdx];
      }
      return;
    }
    default:
      return draft;
  }
}, initialState);
