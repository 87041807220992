import { produce } from "immer";
import { IPayloadAction } from "../../../shared/shared.interfaces";
import { IReferralCampaigns, IReferralCampaign, ReferralCampaignStatus } from "./referral-campaigns.model";

export const ReferralCampaignsActions = {
  types: {
    GET_REFERRAL_CAMPAIGNS_SUCCESS: "GET_REFERRAL_CAMPAIGNS_SUCCESS",
    SET_REFERRAL_CAMPAIGN: "SET_REFERRAL_CAMPAIGN",
    REMOVE_REFERRAL_CAMPAIGN: "REMOVE_REFERRAL_CAMPAIGN",
  },
  creators: {
    getReferralCampaignsSuccess: (referralCampaigns: IReferralCampaigns) => ({
      type: ReferralCampaignsActions.types.GET_REFERRAL_CAMPAIGNS_SUCCESS,
      payload: {
        referralCampaigns,
      },
    }),
    setReferralCampaign: (referralCampaign: IReferralCampaign) => ({
      type: ReferralCampaignsActions.types.SET_REFERRAL_CAMPAIGN,
      payload: {
        referralCampaign,
      },
    }),
    removeReferralCampaign: () => ({
      type: ReferralCampaignsActions.types.REMOVE_REFERRAL_CAMPAIGN,
      payload: {},
    }),
  },
};
export interface IReferralCampaignsReducerInterface {
  referralCampaigns: IReferralCampaigns;
  referralCampaign: IReferralCampaign;
}

const initialState: IReferralCampaignsReducerInterface = {
  referralCampaigns: {} as IReferralCampaigns,
  referralCampaign: {} as IReferralCampaign,
};

export const referralCampaignsReducer = produce(
  (draft: IReferralCampaignsReducerInterface, action: IPayloadAction<any>) => {
    const statusSorted = [
      ReferralCampaignStatus.LIVE,
      ReferralCampaignStatus.SCHEDULED,
      ReferralCampaignStatus.DISABLED,
    ];
    switch (action.type) {
      case ReferralCampaignsActions.types.GET_REFERRAL_CAMPAIGNS_SUCCESS:
        draft.referralCampaigns = action.payload.referralCampaigns;
        draft.referralCampaigns.campaigns = action.payload.referralCampaigns.campaigns.sort(
          (a: IReferralCampaign, b: IReferralCampaign) =>
            a.status ? statusSorted.indexOf(a.status) - statusSorted.indexOf(b.status) : -1,
        );
        return;
      case ReferralCampaignsActions.types.SET_REFERRAL_CAMPAIGN:
        draft.referralCampaign = action.payload.referralCampaign as IReferralCampaign;
        return;
      case ReferralCampaignsActions.types.REMOVE_REFERRAL_CAMPAIGN:
        draft.referralCampaign = {} as IReferralCampaign;
        return;
      default:
        return draft;
    }
  },
  initialState,
);
