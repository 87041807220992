import { AutoClosingDropdown, SearchBar } from "@wit/mpesa-ui-components";
import { FilterFuncts } from "@wit/mpesa-ui-components/lib/hooks/use-filters.hook";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Row } from "../../../../shared/shared.styled";
import { TaskStatusEnum } from "../task.model";

export enum TasksFiltersEnum {
  SEARCH = "search",
  STATUS = "status",
}

interface ITasksFilters extends FilterFuncts {}

/**
 * Task filters
 */
const TasksFilters = ({ filters, clearFilter, isFilterActive, resetFilters, updateFilter }: ITasksFilters) => {
  const [t] = useTranslation();

  /**
   * Returns the tasks status options to be used on the status filter
   */
  const getStatusOptions = () => {
    const status: {
      label: string;
      labelElement: JSX.Element;
      key: TaskStatusEnum | undefined;
    }[] = Object.values(TaskStatusEnum)
      .sort()
      .map((status: any) => ({
        label: i18next.t(`pages.myTasks.statusEnum.${status.toLowerCase()}`),
        labelElement: (
          <span id={`${status.toLowerCase()}-button`}>
            {i18next.t(`pages.myTasks.statusEnum.${status.toLowerCase()}`)}
          </span>
        ),
        key: status,
      }));
    status.push({
      label: i18next.t(`commons.apps.enums.platform.seeAll`),
      labelElement: <span id={`clear_filter-button`}>{i18next.t(`commons.apps.enums.platform.seeAll`)}</span>,
      key: undefined,
    });
    return status;
  };

  return (
    <FilterContainer>
      <div style={{ flex: 2 / 12, marginRight: 24 }} id={"tasks-status-dropdown"}>
        <AutoClosingDropdown
          label={
            filters.has(TasksFiltersEnum.STATUS)
              ? t(`pages.myTasks.statusEnum.${(filters.get(TasksFiltersEnum.STATUS) as string[])[0].toLowerCase()}`)
              : t("pages.myTasks.allStatus")
          }
          options={getStatusOptions()}
          selectOption={val => {
            if (val.key) {
              updateFilter(TasksFiltersEnum.STATUS, val.key, true);
            } else {
              clearFilter(TasksFiltersEnum.STATUS);
            }
          }}
          hasValue={filters.has(TasksFiltersEnum.STATUS)}
        />
      </div>
      <div style={{ flex: 4 / 12, marginRight: 24 }} id={"search-tasks"}>
        <SearchBar
          placeholderLabel={t("pages.myTasks.searchTasks")}
          value={filters.has(TasksFiltersEnum.SEARCH) ? (filters.get(TasksFiltersEnum.SEARCH) as string[])[0] : ""}
          onChange={e => {
            if (e.target.value) {
              updateFilter(TasksFiltersEnum.SEARCH, e.target.value, true);
            } else {
              clearFilter(TasksFiltersEnum.SEARCH);
            }
          }}
          clearValue={() => clearFilter(TasksFiltersEnum.SEARCH)}
        />
      </div>
    </FilterContainer>
  );
};

export default TasksFilters;

const FilterContainer = styled(Row)`
  margin-bottom: 27px;
  width: 100%;
`;
