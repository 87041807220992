import { Formik, FormikErrors, FormikTouched } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { SelectAudienceByTypes, INotification, SetFieldValueType } from "../push-notifications.modal";
import { AutoClosingDropdown, FormSection, StatusChip, Toggle, useAlert } from "@wit/mpesa-ui-components";
import { useTranslation } from "react-i18next";
import { SpanForm } from "../../announcements/announcements.styled";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { AnnouncementsUtils } from "../../announcements/announcements.utils";
import MultipleOptionNestedDropdown from "@wit/mpesa-ui-components/lib/components/dropdown/multiple-option-nested-dropdown/multiple-option-nested-dropdown.component";
import { FormSectionRow } from "@wit/mpesa-ui-components/lib/components/form-section/form-section.component";
import styleTheme, { safaricomPalette, SafaricomTheme } from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import {
  AppVersionServiceType,
  AudienceType,
  BusinessAudience,
  ConsumerAudience,
  DeviceType,
  IVersion,
} from "../../announcements/announcements.model";
import { StatusEnum } from "@wit/mpesa-ui-components/lib/enums/status.enum";
import { SharedNestedOptionDropdown } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-nested-option-container/shared-dropdown-nested-option-container.component";
import { compareVersions, sortArrayBy } from "../../../shared/shared.utils";
import { ConfigContext } from "../../../app.component";
import AnnouncementsApi from "../../announcements/announcements.api";
import { SortDirectionEnum } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import { FieldTypeEnum } from "../../../shared/shared.enums";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { DropzoneTitle } from "../../safaricom-service-manager/service-details/pages/service-manager-service-details.styled";
import LoadingComponent from "../../../shared/components/loading-component/LoadingComponent";
import UploadCSVFileIcon from "../../../shared/icons/upload-csv-file.icon";
import DownloadFileIcon from "../../../shared/icons/download.icon";
import TrashIcon from "../../../shared/icons/trash.icon";
import { object, string } from "yup";
import i18next from "i18next";
import { FEATURES } from "../../../shared/renderer.utils";
import useCheckFeatureAvailable from "../../../shared/hooks/use-check-available-feature";
import DropzoneCSVBackground from "../../topics/components/dropzone-csv-background.component";
import CampaignBasicDetails from "./campaign-basic-details.component";
import { LoadingText } from "../../../shared/shared.styled";
import { AxiosPromise } from "axios";
import TargetAudienceDrawer from "../../announcements/components/target-audience-drawer.component";
import { MAX_CSV_FILE_SIZE } from "../../../shared/shared.constants";
import PushNotificationsApi from "../push-notifications.api";

interface IProps {
  notificationValues: INotification;
  onChangeValues: (value: INotification) => void;
  isEditing?: boolean;
  isDetailsPage?: boolean;
}

/**
 * This returns a validation schema for create topics from
 */
const getValidationSchema = () => {
  return object().shape({
    name: string().required("Topic name is required!"),
    description: string().required("Topic description is required!"),
    csvFile: string().required(i18next.t("commons.mandatoryField")),
  });
};

/** */
const AudienceDetails = ({ notificationValues, isEditing, isDetailsPage, onChangeValues }: IProps) => {
  const [t] = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showAlert, __, setAlertProps] = useAlert();

  const [csvFile, setCSVFile] = useState<File | null>();
  const [uploadingFile, setUploadingFile] = useState(false);

  const [selectedAudience, setSelectedAudience] = useState<string[]>([
    ConsumerAudience.CONSUMERAPP_ADULT,
    ConsumerAudience.CONSUMERAPP_CHILD,
  ]);
  const [appVersionsAndroid, setAppVersionsAndroid] = useState<IVersion[]>([]);
  const [appVersionsiOS, setAppVersionsiOS] = useState<IVersion[]>([]);
  const [selectedVersionsAndroid, setSelectedVersionsAndroid] = useState<string[]>(
    notificationValues.targetVersions
      ? AnnouncementsUtils.getSelectedVersions(notificationValues, DeviceType.ANDROID)
      : [],
  );
  const [selectedVersionsiOS, setSelectedVersionsiOS] = useState<string[]>(
    notificationValues.targetVersions ? AnnouncementsUtils.getSelectedVersions(notificationValues, DeviceType.IOS) : [],
  );
  const [isLoadingVersions, setIsLoadingVersions] = useState(true);

  const { config } = useContext(ConfigContext);
  const businessAgentEnabled = useCheckFeatureAvailable(FEATURES.BUSINESS_AUDIENCE_AGENT);
  const consumerChildEnabled = useCheckFeatureAvailable(FEATURES.CONSUMER_AUDIENCE_CHILD);
  const [showTargetAudienceDrawer, setShowTargetAudienceDrawer] = useState<boolean>(false);

  const refConsumer = useRef(null);
  const refBusinessAgent = useRef(null);
  const MAX_FILE_SIZE = config?.MAX_CSV_FILE_SIZE ? config?.MAX_CSV_FILE_SIZE : MAX_CSV_FILE_SIZE;
  const MAX_FILE_SIZE_TEXT = `Max ${MAX_FILE_SIZE / 1000000}MB`;

  /**
   * Renders display component of the selectAudienceBy field
   * @param values Notification values
   */
  const renderDisplayComponentOfSelectAudienceByField = (values: INotification) => {
    if (!isEditing && values.targetVersions?.length) {
      return t("pages.notifications.createPage.appAndAppVersion");
    } else if (!isEditing && values.hasCSVFile === true) {
      return t("pages.notifications.createPage.csvFileUpload");
    }

    return values.selectAudienceBy;
  };

  const audienceSelectionOptions: SharedDropdownOption[] = [
    {
      key: SelectAudienceByTypes.APP_AND_APP_VERSION,
      label: t("pages.notifications.createPage.appAndAppVersion"),
    },
    {
      key: SelectAudienceByTypes.CSV_FILE_UPLOAD,
      label: t("pages.notifications.createPage.csvFileUpload"),
    },
  ];

  /**
   * Map selected key to label to display
   * @param {string} key selected key
   * @return {string}
   */
  const mapKeyToLabel = (key: string): string => {
    const audienceOption: SharedDropdownOption[] = audienceSelectionOptions.filter(obj => obj.key === key);
    if (audienceOption && audienceOption.length > 0) {
      return audienceOption[0].label;
    }
    return "Select Audience";
  };

  /**
   *
   * @param opt
   * @param setFieldValue
   * @param values
   * @returns
   */
  const onAudienceChange = (opt: SharedDropdownOption, values: INotification, setFieldValue: SetFieldValueType) => {
    clearOtherAudienceSelection(setFieldValue, values);
    setFieldValue("selectAudienceBy", opt.key);
    if (opt.key === SelectAudienceByTypes.CSV_FILE_UPLOAD) {
      setFieldValue("sendImmediately", false);
      onChangeValues({ ...notificationValues, selectAudienceBy: opt.key, sendImmediately: false });
    } else {
      onChangeValues({ ...notificationValues, selectAudienceBy: opt.key });
    }
  };

  /** validate CSV file */
  const validateCSVFile = (reader: FileReader, audienceType: string): AxiosPromise | undefined => {
    if (audienceType.includes("BUSINESS") && reader.result) {
      return PushNotificationsApi.methods.validateShortcode(reader?.result.toString());
    } else if (audienceType.includes("CONSUMER") && reader.result) {
      return PushNotificationsApi.methods.validateMsisdn(reader?.result.toString());
    }
  };

  /** CSV file upload handler */
  const onCSVFileUpload = (values: INotification, file: File, reader: FileReader, audience: AudienceType) => {
    if (reader.result) {
      setUploadingFile(true);
      const updatedNotification: INotification = { ...values };
      const validateCSVResponse = validateCSVFile(reader, audience);
      if (validateCSVResponse) {
        validateCSVResponse.then(
          res => {
            if (!res.data.validInputs.length) {
              setAlertProps({
                type: AlertTypeEnum.ERROR,
                title: t("pages.announcements.detailPage.rows.validateFile"),
                content: audience.includes("BUSINESS")
                  ? t("pages.topics.createPage.shortCodesErrMsg")
                  : t("pages.topics.createPage.msisdnsErrMsg"),
              });
              showAlert();
              setUploadingFile(false);
              return;
            }
            if (audience.includes("BUSINESS")) {
              updatedNotification.businessAudience = res.data.validInputs;
            } else if (audience.includes("CONSUMER")) {
              updatedNotification.consumerAudience = res.data.validInputs;
            }

            updatedNotification.csvStatistics = {
              validTargets: res.data.validInputs,
              invalidTargets: res.data.invalidInputs,
              allTargets: [...res.data.validInputs, ...res.data.invalidInputs],
            };

            updatedNotification.csvFile = file.name;
            updatedNotification.csvFileContent = file;

            setCSVFile(file);
            setUploadingFile(false);
            onChangeValues(updatedNotification);
          },
          () => {
            setAlertProps({
              type: AlertTypeEnum.ERROR,
              title: t("pages.announcements.detailPage.rows.validateFile"),
              content: audience.includes("BUSINESS")
                ? t("pages.announcements.detailPage.rows.failToValidateShortcodes")
                : t("pages.announcements.detailPage.rows.failToValidateShortcodes"),
            });
            showAlert();
            setUploadingFile(false);
          },
        );
      }
    }
  };

  /**
   * to return the fields to the second section of the form
   * @param values are the values of the announcement to change
   * @param errors  are to use when in validation the fields are with errors
   * @param setFieldValue function used to update values
   */
  const renderAudienceSelectionByRow = (
    values: INotification,
    errors: FormikErrors<INotification>,
    setFieldValue: SetFieldValueType,
  ): FormSectionRow[] => {
    return [
      {
        label: (
          <DropzoneTitle mandatory={!!isEditing}>
            {t("pages.notifications.createPage.AudienceSelectionBy")}
          </DropzoneTitle>
        ),
        displayComponent: <DisplayText>{renderDisplayComponentOfSelectAudienceByField(values)}</DisplayText>,
        editingComponent: (
          <AutoClosingDropdown
            dropdownType={DropdownType.RECTANGULAR_NORMAL}
            hasValue={!!values.selectAudienceBy}
            label={mapKeyToLabel(values.selectAudienceBy!) || "Select Audience"}
            options={audienceSelectionOptions}
            error={errors.selectAudienceBy}
            selectOption={opt => {
              onAudienceChange(opt, values, setFieldValue);
            }}
          />
        ),
      },
    ];
  };

  /**
   * onDrop handler method for uploading CSV file
   * @param {File[]} acceptedCSVFile
   * @param {AudienceType} audience
   * @param {SetFieldValueType} setFieldValue
   * @param {FormikTouched<INotification>} setTouched
   * @param {FormikTouched<INotification>} touched
   */
  const onDropCSVFile = (
    acceptedCSVFile: File[],
    values: INotification,
    audience: AudienceType,
    setFieldValue: SetFieldValueType,
    setTouched: (touched: FormikTouched<INotification>) => void,
    touched: FormikTouched<INotification>,
  ) => {
    setTouched({ ...touched, csvFile: true });

    if (!acceptedCSVFile[0]) {
      setAlertProps({
        type: AlertTypeEnum.ERROR,
        title: t("pages.topics.createPage.errorUpload"),
      });
      showAlert();
    } else if (acceptedCSVFile[0] && acceptedCSVFile[0].size > MAX_FILE_SIZE) {
      setAlertProps({
        type: AlertTypeEnum.ERROR,
        title: t("pages.announcements.detailPage.rows.imageError"),
        content: t("pages.topics.createPage.maxSizeError"),
      });
      showAlert();
    } else {
      const file: File = acceptedCSVFile[0];
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onloadend = function() {
        onCSVFileUpload(values, file, reader, audience);
      };
    }
  };

  /**
   * Clear audience fields
   * @param {SetFieldValueType} setFieldValue
   */
  const clearCSVFields = (setFieldValue: SetFieldValueType) => {
    setCSVFile(null);
    setFieldValue("csvFile", "");
    setFieldValue("csvFileContent", undefined);
  };

  /**
   * clear audience fields
   * @param setFieldValue
   */
  const clearOtherAudienceSelection = (setFieldValue: SetFieldValueType, values: INotification) => {
    setFieldValue("targetVersions", undefined);
    setFieldValue("consumerAudience", undefined);
    setFieldValue("businessAudience", undefined);

    const updatedValues = values;
    delete updatedValues["targetVersions"];
    delete updatedValues["consumerAudience"];
    delete updatedValues["csvFile"];
    delete updatedValues["csvFileContent"];

    clearCSVFields(setFieldValue);
    setSelectedVersionsAndroid([]);
    setSelectedVersionsiOS([]);
    onChangeValues({ ...updatedValues });
  };

  /**
   * handle download the uploaded CSV file
   */
  const handleDownload = () => {
    if (notificationValues.csvFileContent) {
      const blob = new Blob([notificationValues.csvFileContent], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = notificationValues.csvFileContent.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  /** render preview of CSV file
   * @param {SetFieldValueType} setFieldValue
   * @returns {JSX.Element}
   */
  const renderCSVFilePreview = (setFieldValue: SetFieldValueType) => (
    <FilePreview>
      <DropzoneRow>
        <IconDiv isDrag={true}>
          <UploadCSVFileIcon />
        </IconDiv>
        <FileNameText>{csvFile?.name || notificationValues.csvFileContent?.name || "audience-file.csv"}</FileNameText>
        {!isDetailsPage && (
          <Row>
            <IconActionContainer onClick={handleDownload}>
              <DownloadFileIcon />
            </IconActionContainer>
            <IconActionContainer
              onClick={() => {
                clearCSVFields(setFieldValue);
                onChangeValues({ ...notificationValues, csvFile: "", csvFileContent: undefined });
              }}
            >
              <TrashIcon />
            </IconActionContainer>
          </Row>
        )}
      </DropzoneRow>
      <StatusChipContainer>
        <StatusChip
          type={StatusEnum.LIVE}
          style={{ marginRight: "8px", backgroundColor: SafaricomTheme.palette.digitalGreen }}
          onClick={() => {
            setShowTargetAudienceDrawer(true);
          }}
        >
          {t("pages.announcements.detailPage.rows.validTargets", {
            numberOfTargets: notificationValues.consumerAudience ? notificationValues.consumerAudience.length : 0,
          })}
        </StatusChip>
        <StatusChip
          type={StatusEnum.DELETE}
          style={{ marginRight: "8px", backgroundColor: SafaricomTheme.palette.errorRed }}
          onClick={() => {
            setShowTargetAudienceDrawer(true);
          }}
        >
          {t("pages.announcements.detailPage.rows.invalidTargets", {
            numberOfTargets: notificationValues.csvStatistics?.invalidTargets
              ? notificationValues.csvStatistics?.invalidTargets.length
              : 0,
          })}
        </StatusChip>
      </StatusChipContainer>
    </FilePreview>
  );

  /**
   * Handle upload and preview of CSV file
   * @param {INotification} values
   * @param {FormikErrors<INotification>} errors
   * @param {FormikTouched<INotification>} touched
   * @param {SetFieldValueType} setFieldValue
   * @param {(FormikTouched<INotification>) => void} setTouched
   * @returns {JSX.Element}
   */
  const renderUploadCSVFileDropZoneField = (
    values: INotification,
    errors: FormikErrors<INotification>,
    touched: FormikTouched<INotification>,
    setFieldValue: SetFieldValueType,
    setTouched: (touched: FormikTouched<INotification>) => void,
  ) => {
    if (uploadingFile) {
      return (
        <div style={{ margin: 50 }}>
          <LoadingComponent />
        </div>
      );
    }
    if (csvFile || notificationValues.csvFileContent) {
      return renderCSVFilePreview(setFieldValue);
    }

    return (
      <>
        <DropzoneCSVBackground
          inputName="upload-csv-file"
          onDrop={(file: File[]) => onDropCSVFile(file, values, values.audience, setFieldValue, setTouched, touched)}
          accept={".csv"}
          multiple={false}
          dropText={t("pages.topics.createPage.dropTxt")}
          releaseText={t("pages.announcements.detailPage.rows.uploadImage")}
          error={!!errors.csvFile && !!touched.csvFile}
          maxSizeText={MAX_FILE_SIZE_TEXT}
        />
        <SmallHint>{t("pages.notifications.createPage.csvFileHint")}</SmallHint>
      </>
    );
  };

  /** Update selected audience value handler */
  const updateSelectedAudienceValue = (
    values: INotification,
    updatedSelectedAudience: string[],
    setFieldValue: SetFieldValueType,
  ) => {
    let audience: string = updatedSelectedAudience[0];
    const updatedValues = { ...values };
    if (
      updatedSelectedAudience.includes(ConsumerAudience.CONSUMERAPP_ADULT) &&
      updatedSelectedAudience.includes(ConsumerAudience.CONSUMERAPP_CHILD)
    ) {
      audience = ConsumerAudience.ALL_CONSUMERAPPS;
    } else if (
      updatedSelectedAudience.includes(BusinessAudience.BUSINESSAPP_AGENT) &&
      updatedSelectedAudience.includes(BusinessAudience.BUSINESSAPP_MERCHANT)
    ) {
      audience = BusinessAudience.ALL_BUSINESSAPPS;
    }
    if (audience.includes("CONSUMERAPP")) {
      updatedValues.audience = ConsumerAudience[audience as keyof typeof ConsumerAudience];
    } else if (audience.includes("BUSINESSAPP")) {
      updatedValues.audience = BusinessAudience[audience as keyof typeof BusinessAudience];
    }
    clearOtherAudienceSelection(setFieldValue, updatedValues);
  };

  /**
   * Toggle option for apps audience method
   * @param opt  selected option
   * @param values are the values of the announcement to change
   * @param setFieldValue function used to update values
   */
  const toggleAudienceOption = (
    opt: SharedNestedOptionDropdown,
    values: INotification,
    setFieldValue: SetFieldValueType,
  ) => {
    let updatedSelectedAudience: string[] = [...selectedAudience];
    if (isAudienceOptionSelected(opt)) {
      if (selectedAudience.length === 1) {
        return;
      }
      updatedSelectedAudience = selectedAudience.filter(c => c !== opt.key);
      if (AnnouncementsUtils.isConsumerAudience(opt.key)) {
        updatedSelectedAudience = updatedSelectedAudience.filter(c => c !== ConsumerAudience.ALL_CONSUMERAPPS);
      } else if (AnnouncementsUtils.isBusinessAudience(opt.key)) {
        updatedSelectedAudience = updatedSelectedAudience.filter(c => c !== BusinessAudience.ALL_BUSINESSAPPS);
      }
    } else {
      if (opt.key === ConsumerAudience.ALL_CONSUMERAPPS) {
        updatedSelectedAudience = [ConsumerAudience.CONSUMERAPP_ADULT, ConsumerAudience.CONSUMERAPP_CHILD];
      } else if (opt.key === BusinessAudience.ALL_BUSINESSAPPS) {
        updatedSelectedAudience = [BusinessAudience.BUSINESSAPP_AGENT, BusinessAudience.BUSINESSAPP_MERCHANT];
      } else {
        updatedSelectedAudience = [...selectedAudience, opt.key];
      }
    }
    setSelectedAudience(updatedSelectedAudience);
    updateSelectedAudienceValue(values, updatedSelectedAudience, setFieldValue);
  };

  /**
   * Renders Topics field or render Apps, OS typ, Apps versions fields. This depends on the user's selections for `audienceType` field
   * @param {INotification} values
   * @param {FormikErrors<INotification>} errors
   * @param {SetFieldValueType} setFieldValue
   * @param {FormikTouched<INotification>} touched
   * @param {FormikTouched<INotification>} setTouched
   */
  const renderConditionalAudienceTypesFields = (
    values: INotification,
    errors: FormikErrors<INotification>,
    setFieldValue: SetFieldValueType,
    touched: FormikTouched<INotification>,
    setTouched: (touched: FormikTouched<INotification>) => void,
  ) => {
    const csvFileFieldToTargetAudience = [
      {
        label: <DropzoneTitle mandatory={!!isEditing}>Apps</DropzoneTitle>,
        displayComponent: t(`pages.announcements.audienceEnum.${notificationValues.audience}`),
        editingComponent: (
          <MultipleOptionNestedDropdown
            dropdownType={DropdownType.RECTANGULAR_NORMAL}
            label={
              values.audience ? (
                <span style={{ color: safaricomPalette.black }}>
                  {t(`pages.announcements.audienceEnum.${values.audience}`)}
                </span>
              ) : values.audience === undefined ? (
                t("pages.announcements.detailPage.rows.selectAudience")
              ) : (
                t(`pages.announcements.audienceEnum.${notificationValues}`)
              )
            }
            hasValue={false}
            options={AnnouncementsUtils.getAudienceOptions(
              [values.audience] as AudienceType[],
              businessAgentEnabled,
              consumerChildEnabled,
            )}
            toggleOption={(opt: SharedNestedOptionDropdown) => toggleAudienceOption(opt, values, setFieldValue)}
            isOptionSelected={(opt: SharedDropdownOption) =>
              AnnouncementsUtils.isOptionSelected(
                [values.audience] as AudienceType[],
                opt.key,
                businessAgentEnabled,
                consumerChildEnabled,
              )
            }
            clearAllFilters={() => null}
            showClearAllFilters={false}
            error={errors.audience ? t("commons.mandatoryField") : undefined}
          />
        ),
      },
      {
        label: <DropzoneTitle mandatory={!!isEditing}>CSV File</DropzoneTitle>,
        displayComponent: renderCSVFilePreview(setFieldValue),
        editingComponent: renderUploadCSVFileDropZoneField(values, errors, touched, setFieldValue, setTouched),
      },
    ];
    const AppsAndOSFieldsToTargetAudience = [
      {
        label: <DropzoneTitle mandatory={!!isEditing}>Apps</DropzoneTitle>,
        displayComponent: t(`pages.announcements.audienceEnum.${notificationValues.audience}`),
        editingComponent: (
          <MultipleOptionNestedDropdown
            dropdownType={DropdownType.RECTANGULAR_NORMAL}
            label={
              values.audience ? (
                <span style={{ color: safaricomPalette.black }}>
                  {t(`pages.announcements.audienceEnum.${values.audience}`)}
                </span>
              ) : values.audience === undefined ? (
                t("pages.announcements.detailPage.rows.selectAudience")
              ) : (
                t(`pages.announcements.audienceEnum.${notificationValues}`)
              )
            }
            hasValue={false}
            options={AnnouncementsUtils.getAudienceOptions(
              [values.audience] as AudienceType[],
              businessAgentEnabled,
              consumerChildEnabled,
            )}
            toggleOption={(opt: SharedNestedOptionDropdown) => toggleAudienceOption(opt, values, setFieldValue)}
            isOptionSelected={(opt: SharedDropdownOption) =>
              AnnouncementsUtils.isOptionSelected(
                [values.audience] as AudienceType[],
                opt.key,
                businessAgentEnabled,
                consumerChildEnabled,
              )
            }
            clearAllFilters={() => null}
            showClearAllFilters={false}
            error={errors.audience ? t("commons.mandatoryField") : undefined}
          />
        ),
      },
    ];

    if (
      values.selectAudienceBy === SelectAudienceByTypes.APP_AND_APP_VERSION ||
      (!isEditing && notificationValues.targetVersions?.length)
    ) {
      return AppsAndOSFieldsToTargetAudience;
    }
    if (
      values.selectAudienceBy === SelectAudienceByTypes.CSV_FILE_UPLOAD ||
      (!isEditing && notificationValues.hasCSVFile === true)
    ) {
      return csvFileFieldToTargetAudience;
    }

    return [];
  };

  /**
   * method to check if there are deprecated versions (<2.1) selected
   */
  const isDeprecated = () => {
    return (
      selectedVersionsAndroid.some(
        version => compareVersions(version, config!.appVersionDependencies.announcementsThumbnail.min) === -1,
      ) ||
      selectedVersionsiOS.some(
        version => compareVersions(version, config!.appVersionDependencies.announcementsThumbnail.min) === -1,
      )
    );
  };

  useEffect(() => {
    // TODO: enhance this;
    const androidVersionsToShow: any[] = [];
    const iOSVersionsToShow: any[] = [];
    if (isDetailsPage && notificationValues.targetVersions?.length) {
      notificationValues.targetVersions?.forEach(version => {
        if (version.deviceType === DeviceType.ANDROID) {
          androidVersionsToShow.push(version.appBuildVersions);
        } else if (version.deviceType === DeviceType.IOS) {
          iOSVersionsToShow.push(version.appBuildVersions);
        }
      });

      setSelectedVersionsAndroid(androidVersionsToShow.flat());
      setSelectedVersionsiOS(iOSVersionsToShow.flat());
    }
  }, [notificationValues]);

  /**
   * toggle option method for dropdown
   */
  const toggleOption = (
    opt: SharedNestedOptionDropdown,
    appVersion: string[],
    setSelectedVersions: (value: string[]) => void,
    setFieldValue: SetFieldValueType,
    allAppVersions: IVersion[],
    deviceType: DeviceType,
    values: INotification,
  ) => {
    if (appVersion && appVersion.includes(opt.key)) {
      const index = appVersion.indexOf(opt.key);
      const newAppVersion = [...appVersion];
      newAppVersion.splice(index, 1);
      const newTargetVersions = getTargetVersionFieldValue(newAppVersion, allAppVersions, deviceType);
      setTargetVersions(setFieldValue, values, newTargetVersions, setSelectedVersions, newAppVersion);
    } else if (appVersion && opt.children) {
      let nthChildSelected = 0;
      let newAppVersion = [] as string[];
      opt.children.forEach(option => {
        if (appVersion.includes(option.key)) {
          nthChildSelected++;
        }
      });
      if (nthChildSelected < opt.children.length) {
        newAppVersion = AnnouncementsUtils.removeDuplicatesFromArray([
          ...appVersion,
          ...AnnouncementsUtils.getOptionsChildren(opt),
        ]);
      } else {
        newAppVersion = [...appVersion];
        opt.children.forEach(option => {
          const index = newAppVersion.indexOf(option.key);
          newAppVersion.splice(index, 1);
        });
      }
      const newTargetVersions = getTargetVersionFieldValue(newAppVersion, allAppVersions, deviceType);
      setTargetVersions(setFieldValue, values, newTargetVersions, setSelectedVersions, newAppVersion);
    } else {
      let newAppVersion = appVersion ? appVersion : [];
      newAppVersion = AnnouncementsUtils.removeDuplicatesFromArray([
        ...newAppVersion,
        ...(opt.children ? AnnouncementsUtils.getOptionsChildren(opt) : [opt.key]),
      ]);
      const newTargetVersions = getTargetVersionFieldValue(newAppVersion, allAppVersions, deviceType);
      setTargetVersions(setFieldValue, values, newTargetVersions, setSelectedVersions, newAppVersion);
    }
  };

  /**
   * method to get the available app versions
   */
  const getAppVersion = () => {
    // TODO: enhance this, not to call the API if the audience is still in the context of BUSINESS or CONSUMER
    setIsLoadingVersions(true);
    const consumerQuery = AppVersionServiceType.CONSUMER;
    const businessQuery = AppVersionServiceType.ORG;

    const query = notificationValues.audience?.includes("BUSINESS") ? businessQuery : consumerQuery;
    AnnouncementsApi.methods.getAppVersions(query).then(
      res => {
        const appVersions = res.data.appVersions;
        const androidAppVersions = appVersions.find(versions => versions.deviceType === DeviceType.ANDROID);
        const iosAppVersions = appVersions.find(versions => versions.deviceType === DeviceType.IOS);
        setAppVersionsAndroid(
          androidAppVersions && androidAppVersions.versions
            ? sortArrayBy(androidAppVersions.versions, "appVersion", SortDirectionEnum.ASC, FieldTypeEnum.NUMBER)
            : [],
        );
        setAppVersionsiOS(
          iosAppVersions && iosAppVersions.versions
            ? sortArrayBy(iosAppVersions.versions, "appVersion", SortDirectionEnum.ASC, FieldTypeEnum.NUMBER)
            : [],
        );
        setIsLoadingVersions(false);
      },
      () => {},
    );
  };

  /**
   * method to set the targetVersions
   */
  const setTargetVersions = (
    setFieldValue: SetFieldValueType,
    values: INotification,
    targetVersions: IVersion[],
    setSelectedVersions: (value: string[]) => void,
    newAppVersion: string[],
  ) => {
    setSelectedVersions(newAppVersion);
    setFieldValue("targetVersions", targetVersions);
    onChangeValues({ ...values, targetVersions });
  };

  /**
   * is dropdown option selected
   */
  const getTargetVersionFieldValue = (newAppVersion: string[], allAppVersions: IVersion[], deviceType: DeviceType) => {
    return [
      ...AnnouncementsUtils.getTargetVersionsObject(newAppVersion, allAppVersions, deviceType),
      ...(deviceType === DeviceType.ANDROID
        ? AnnouncementsUtils.getTargetVersionsObject(selectedVersionsiOS, appVersionsiOS, DeviceType.IOS)
        : AnnouncementsUtils.getTargetVersionsObject(selectedVersionsAndroid, appVersionsAndroid, DeviceType.ANDROID)),
    ];
  };

  /**
   *method to return label to be shown when editing
   */
  const getDropdownLabelEditing = (appVersion?: string[]) => {
    return appVersion && appVersion.length > 0
      ? appVersion.includes("all-versions-key")
        ? t("pages.announcements.detailPage.rows.allVersions")
        : t("pages.announcements.detailPage.rows.selectedNVersions").replace("{number}", appVersion.length.toString())
      : t("pages.announcements.detailPage.rows.chooseVersions");
  };

  /**
   * method to return label to be shown when not editing
   */
  const getDropdownLabel = (appVersion?: string[]) => {
    return appVersion && appVersion.length > 0
      ? appVersion.includes("all-versions-key")
        ? t("pages.announcements.detailPage.rows.allVersions")
        : AnnouncementsUtils.sortArrayOfVersions(appVersion).join("; ")
      : t("pages.announcements.detailPage.rows.none");
  };

  /**
   * is dropdown option selected
   */
  const isOptionSelected = (opt: SharedDropdownOption, appVersion?: string[]) => {
    return (appVersion && appVersion.includes(opt.key)) || (appVersion && appVersion.includes("all-versions-key"));
  };

  function isAudienceOptionSelected(opt: SharedDropdownOption) {
    return selectedAudience.includes(opt.key);
  }

  /**
   * get apps version dropdown options
   */
  const getAppsVersionsOptionsDropdown = (
    values: INotification,
    versions: IVersion[],
  ): SharedNestedOptionDropdown[] => {
    let options = [] as SharedNestedOptionDropdown[];

    if (versions.length > 0) {
      if (AnnouncementsUtils.isBusinessAudience(values.audience as AudienceType)) {
        options.push({
          label: "All versions",
          key: "all-versions-key",
        });
      }

      options = [
        ...options,
        ...Object.values(versions).map(version => ({
          label: version.appVersion,
          key: version.appVersion,
          children: version.appBuildVersions
            ? Object.values(version.appBuildVersions).map(subVersion => ({ label: subVersion, key: subVersion }))
            : [],
          disabled:
            ((values.audience === ConsumerAudience.CONSUMERAPP_ADULT ||
              values.audience === ConsumerAudience.CONSUMERAPP) &&
              !(selectedVersionsAndroid.length === 0 && selectedVersionsiOS.length === 0) &&
              config &&
              (!!(
                compareVersions(version.appVersion, config.appVersionDependencies.announcementsThumbnail.min) >= 0 &&
                isDeprecated()
              ) ||
                !!(
                  compareVersions(version.appVersion, config.appVersionDependencies.announcementsThumbnail.min) < 0 &&
                  !isDeprecated()
                ))) ||
            ((values.audience === ConsumerAudience.CONSUMERAPP_CHILD ||
              values.audience === ConsumerAudience.ALL_CONSUMERAPPS) &&
              config &&
              (!!(
                compareVersions(version.appVersion, config.appVersionDependencies.childAccount.min) >= 0 &&
                isDeprecated()
              ) ||
                !!(
                  compareVersions(version.appVersion, config.appVersionDependencies.childAccount.min) < 0 &&
                  !isDeprecated()
                ))),
        })),
      ];
    }
    return options;
  };

  /**
   * to return the fields to the second section of the form
   * @param values are the values of the announcement to change
   * @param errors  are to use when in validation the fields are with errors
   * @param setFieldValue function used to update values
   */
  const renderAppsVersionsRow = (
    values: INotification,
    errors: FormikErrors<INotification>,
    setFieldValue: SetFieldValueType,
  ): FormSectionRow[] => {
    return [
      {
        label: t("pages.announcements.detailPage.rows.appVersionAndroid"),
        displayComponent: <AppVersionDisplayText>{getDropdownLabel(selectedVersionsAndroid)}</AppVersionDisplayText>,
        editingComponent: !isLoadingVersions ? (
          <MultipleOptionNestedDropdown
            options={getAppsVersionsOptionsDropdown(values, appVersionsAndroid)}
            toggleOption={opt =>
              toggleOption(
                opt,
                selectedVersionsAndroid,
                setSelectedVersionsAndroid,
                setFieldValue,
                appVersionsAndroid,
                DeviceType.ANDROID,
                values,
              )
            }
            isOptionSelected={(opt: SharedDropdownOption) => isOptionSelected(opt, selectedVersionsAndroid)}
            clearAllFilters={() => {
              const clearedFiltersVersions = [
                ...AnnouncementsUtils.getTargetVersionsObject([], appVersionsAndroid, DeviceType.ANDROID),
                ...AnnouncementsUtils.getTargetVersionsObject(selectedVersionsiOS, appVersionsiOS, DeviceType.IOS),
              ];
              setTargetVersions(setFieldValue, values, clearedFiltersVersions, setSelectedVersionsAndroid, []);
            }}
            label={getDropdownLabelEditing(selectedVersionsAndroid)}
            dropdownType={DropdownType.RECTANGULAR_NORMAL}
            hasValue={false}
            error={errors.targetVersions ? t("commons.mandatoryVersion") : undefined}
          />
        ) : (
          <LoadingText>{t("pages.announcements.detailPage.rows.loadingAppVersions")}</LoadingText>
        ),
      },
      {
        label: t("pages.announcements.detailPage.rows.appVersioniOS"),
        displayComponent: <AppVersionDisplayText>{getDropdownLabel(selectedVersionsiOS)}</AppVersionDisplayText>,
        editingComponent: !isLoadingVersions ? (
          <MultipleOptionNestedDropdown
            options={getAppsVersionsOptionsDropdown(values, appVersionsiOS)}
            toggleOption={opt =>
              toggleOption(
                opt,
                selectedVersionsiOS,
                setSelectedVersionsiOS,
                setFieldValue,
                appVersionsiOS,
                DeviceType.IOS,
                values,
              )
            }
            isOptionSelected={(opt: SharedDropdownOption) => isOptionSelected(opt, selectedVersionsiOS)}
            clearAllFilters={() => {
              const clearedFiltersVersions = [
                ...AnnouncementsUtils.getTargetVersionsObject([], appVersionsiOS, DeviceType.IOS),
                ...AnnouncementsUtils.getTargetVersionsObject(
                  selectedVersionsAndroid,
                  appVersionsAndroid,
                  DeviceType.ANDROID,
                ),
              ];
              setTargetVersions(setFieldValue, values, clearedFiltersVersions, setSelectedVersionsiOS, []);
            }}
            label={getDropdownLabelEditing(selectedVersionsiOS)}
            dropdownType={DropdownType.RECTANGULAR_NORMAL}
            hasValue={false}
            error={errors.targetVersions ? t("commons.mandatoryVersion") : undefined}
          />
        ) : (
          <LoadingText>{t("pages.announcements.detailPage.rows.loadingAppVersions")}</LoadingText>
        ),
      },
    ];
  };

  useEffect(() => {
    getAppVersion();
  }, [notificationValues.audience]);

  return (
    <Container>
      <Formik
        initialValues={notificationValues}
        validationSchema={getValidationSchema()}
        validateOnBlur={true}
        validateOnChange={true}
        enableReinitialize
        onSubmit={(data: INotification) => console.log(data)}
        validateOnMount
      >
        {({ values, setFieldValue, errors, touched, setTouched }) => {
          return (
            <Form>
              {showTargetAudienceDrawer ? (
                <TargetAudienceDrawer
                  isVisible={showTargetAudienceDrawer}
                  setIsVisible={setShowTargetAudienceDrawer}
                  validTargets={values.csvStatistics?.validTargets || []}
                  invalidTargets={values.csvStatistics?.invalidTargets || []}
                  allTotalTargets={values.csvStatistics?.allTargets || []}
                  audience={notificationValues.audience}
                  refBusiness={refBusinessAgent}
                  refConsumer={refConsumer}
                  audienceSelected={undefined}
                />
              ) : null}
              <FormSection
                title={<SubTitle>{t("pages.notifications.createPage.audienceSelection")}</SubTitle>}
                isEditing={!!isEditing}
                rows={[
                  ...renderAudienceSelectionByRow(values, errors, setFieldValue),
                  ...renderConditionalAudienceTypesFields(values, errors, setFieldValue, touched, setTouched),
                ]}
              />
              {(values as INotification).selectAudienceBy === SelectAudienceByTypes.APP_AND_APP_VERSION ||
              (!isEditing && !!notificationValues.targetVersions?.length) ? (
                <FormSection
                  title={<SubTitle>{t("pages.notifications.createPage.appsVersions")}</SubTitle>}
                  isEditing={!!isEditing}
                  rows={renderAppsVersionsRow(values, errors, setFieldValue)}
                />
              ) : null}
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default AudienceDetails;

const Container = styled.section`
  width: 100%;
  padding-bottom: 150px;
`;

const Form = styled.form``;

const DisplayText = styled("div")`
  font-family: Vodafone Rg;
  color: #333;
`;

const SubTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 18px;
  color: #999;
`;

const FilePreview = styled("div")`
  height: 116px;
  border: 2px dashed ${styleTheme.palette.aluminium};
  border-radius: 6px;
  outline: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const DropzoneRow = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Vodafone Rg;
`;

const IconDiv = styled("div")<{ isDrag: boolean }>`
  margin: 10px;
  display: flex;
  align-items: center;
  > svg {
    width: 55px;
    height: 55px;
    > path {
      stroke: ${props => (props.isDrag ? props.theme.palette.darkGrey : props.theme.palette.midGrey)};
      opacity: 1;
    }
  }
  @media (max-width: 1024px) {
    margin: 0.1rem;
    > svg {
      width: 40px;
      height: 40px;
    }
  }
`;

const FileNameText = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: #333333;
  text-overflow: ellipsis;
  max-width: 50%;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 0.3rem;
  @media (max-width: 1024px) {
    margin-right: 0.1rem;
    font-size: 18px;
  }
`;

const Row = styled("div")`
  display: flex;
`;

const IconActionContainer = styled("div")`
  cursor: pointer;
`;
const CallToActionToogle = styled("div")<{ isDisabled: boolean }>`
  margin-top: 1.2rem;
  margin-bottom: 0.4rem;
  opacity: ${props => (props.isDisabled ? "0.5" : "1")};
`;

const SmallHint = styled("small")`
  font-family: Vodafone Rg;
  font-size: 0.8rem;
  color: #333333;
  font-style: italic;
`;

const AppVersionDisplayText = styled.div`
  max-width: fit-content;
  font-family: Vodafone Rg;
  color: #333333;
`;

const StatusChipContainer = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Vodafone Rg;
  > div {
    cursor: pointer;
  }
`;
