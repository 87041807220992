import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { useTranslation } from "react-i18next";
import { CancelIcon } from "@wit/mpesa-ui-components";
import { useSelector } from "react-redux";
import { IStoreInterface } from "../../../../configs/store.config";
import { Column, Row } from "../../../../shared/shared.styled";
import { IDiscoverCard, DiscoverCardsStatusEnum } from "../discover-cards.model";
import moment from "moment";
import { getAvailableLanguagesOptions } from "../discover-cards.utils";
import LanguageSelectorDropdown from "../../../../shared/components/language-selector-dropdown.component";

interface ILastVersionModalProps {
  onCancelClick: () => void;
}

/**
 * last version modal
 * @param param0
 * @returns
 */
const LastVersionModal = ({ onCancelClick }: ILastVersionModalProps) => {
  const [t] = useTranslation();

  const { lastVersion } = useSelector((state: IStoreInterface) => state.discoverCardsReducer);

  const [language, setLanguage] = React.useState({ label: t("commons.languages.en"), key: "en" });
  const theme = useContext(ThemeContext);

  /**
   * Renders each item
   * @param item
   * @returns
   */
  const renderItem = (item: IDiscoverCard) => {
    return (
      <ItemColumn disabled={false}>
        <Row>
          <ImageContainer>
            <Image background={item.urlImage}></Image>
          </ImageContainer>
          <ItemInfo>
            {item.name && (
              <Row>
                <span style={{ fontWeight: 1000 }}> {item.name[language.key]}</span>
              </Row>
            )}
            {item.description && <Row style={{ marginTop: 7, marginBottom: 7 }}>{item.description[language.key]}</Row>}
            {item.endDate && item.endDate !== 0 ? (
              <DateInfo style={{}}>{`${t("pages.discoverCards.checkLastVersionModal.expires")} ${moment(
                item.endDate,
                "x",
              ).format("DD/MM/YYYY")}`}</DateInfo>
            ) : null}
          </ItemInfo>
          <StatusContainer>
            {item.status && (
              <StatusRow type={item.status}>
                {t(`commons.discoverCards.discoverCardsStatusEnum.${item.status}`)}
              </StatusRow>
            )}
          </StatusContainer>
        </Row>
      </ItemColumn>
    );
  };

  return (
    <ModalContainer>
      <Header>
        <ModalTitle>{t("pages.discoverCards.checkLastVersionModal.title")}</ModalTitle>
        <RightContainer>
          <CloseIconContainer onClick={onCancelClick} id="close-last-version-modal">
            <CancelIcon color={"grey"} />
          </CloseIconContainer>
          <div style={{ paddingRight: "15px" }}>
            <LanguageSelectorDropdown
              availableLanguages={getAvailableLanguagesOptions()}
              selectedLanguage={language}
              changeSelectedLanguage={setLanguage}
              color={theme.palette.turquoiseBlue}
              id={"last-version-language-selector"}
            ></LanguageSelectorDropdown>
          </div>
        </RightContainer>
      </Header>
      <ItemsContainer>
        {lastVersion &&
          lastVersion.discoverCards &&
          lastVersion.discoverCards.map((lastVersionItem, index) => {
            return (
              <Item key={lastVersionItem.id}>
                {lastVersionItem ? renderItem(lastVersionItem) : <ItemColumn></ItemColumn>}
              </Item>
            );
          })}
      </ItemsContainer>
    </ModalContainer>
  );
};

export default LastVersionModal;

const ModalContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 24px;
  font-family: Vodafone Rg;
`;

const ModalTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 21px;
`;

const ItemsContainer = styled.div`
  max-height: 250px;
  overflow: scroll;
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${props => props.theme.palette.darkGrey};
  overflow-x: hidden;
`;

const Item = styled(Row)`
  display: flex;
  /* justify-content: space-between; */
`;

const ImageContainer = styled(Column)`
  margin-right: 24px;
  display: flex;
  align-self: baseline;
  flex: 1;
  padding: 4px;
`;

const Image = styled("div")<{ background: any }>`
  width: 64px;
  height: 64px;
  background: url(${props => (props.background ? `${props.background}?time=${new Date().valueOf()}` : "none")});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
`;

const ItemColumn = styled(Column)<{ disabled?: Boolean }>`
  flex: 1;
  padding: 16px 0px 16px 0;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  border-bottom: 1px solid #d8d8d8;
  margin-right: 34px;
`;

const ItemInfo = styled(Column)`
  flex: 20;
`;
const StatusContainer = styled(Column)`
  padding-left: 24px;
  display: flex;
  align-self: end;
  flex: 1;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CloseIconContainer = styled("div")`
  left: 23px;
  margin-top: -10px;
  stroke: ${props => props.theme.palette.midGrey};
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  svg {
    width: 24px;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DateInfo = styled(Row)`
  font-size: 14px;
  font-weight: normal;
  line-height: 1.29;
  opacity: 0.5;
`;

const StatusRow = styled("div")<{ type: DiscoverCardsStatusEnum }>`
  ${props => {
    let color = props.theme.palette.digitalGreen;
    switch (props.type) {
      case DiscoverCardsStatusEnum.LIVE:
        color = props.theme.palette.digitalGreen;
        break;
      case DiscoverCardsStatusEnum.DISABLE:
        color = props.theme.palette.freshOrange;
        break;
      case DiscoverCardsStatusEnum.DISABLED:
        color = props.theme.palette.freshOrange;
        break;
      case DiscoverCardsStatusEnum.ENABLE:
      case DiscoverCardsStatusEnum.EDIT:
        color = props.theme.palette.digitalGreen;
        break;
      case DiscoverCardsStatusEnum.DELETE:
        color = props.theme.palette.vodafoneRed;
        break;
    }
    return `color: ${color};display: flex;justify-content: flex-end;`;
  }}
`;
