import React from "react";
import styled from "styled-components";
import { CancelIcon } from "@wit/mpesa-ui-components";

interface IImageProps {
  backgroundImage: string;
  imageName: string;
  imageSize: string;
  isEditing: boolean | undefined;
}
const ImagePreview = ({ backgroundImage, imageName, imageSize, isEditing }: IImageProps) => {
  return (
    <>
      <ImageDiv>
        <ImageWrapper backgroundImage={backgroundImage} isEditing={isEditing}>
          <a>
            <CancelIcon />
          </a>
        </ImageWrapper>
        <DescriptionImage isEditing={isEditing}>
          <NameImage>{imageName}</NameImage>
          <SizeImage>{imageSize}</SizeImage>
        </DescriptionImage>
      </ImageDiv>
    </>
  );
};

export default ImagePreview;

const ImageDiv = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ImageWrapper = styled("div")<{
  backgroundImage: string;
  isEditing: boolean | undefined;
}>`
    position: relative;
    width: ${props => (props.isEditing ? "56" : "90")}px;
    height: ${props => (props.isEditing ? "56" : "90")}px;
    background-image: url("${props => props.backgroundImage}");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 3px;
    flex: ${props => (props.isEditing ? "0.08" : "0.125")};
    >a{
      position: absolute;
      background: #aaa;
      z-index: 1;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      right: -0.5vw;
      top: -0.5vh;
      display:${props => (props.isEditing ? "flex" : "none")};
      justify-content: center;
      align-items: center;
      cursor: pointer;
      svg{
        stroke: #fff;
        width: 15px;
        height: 15px;
      }
    }
`;

const DescriptionImage = styled("div")<{
  isEditing: boolean | undefined;
}>`
  flex: ${props => (props.isEditing ? "0.88" : "0.835")};
  align-self: center;
`;

const NameImage = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 4px;
`;

const SizeImage = styled("div")`
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #afafaf;
`;
