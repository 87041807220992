import { ThreeDotsIcon, FastActionButton, TrashIcon, EditIcon, UndoIcon } from "@wit/mpesa-ui-components";
import i18next from "i18next";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Column, FlexContainer, Row, IconContainer } from "../../../../shared/shared.styled";
import { IDiscoverCard, DiscoverCardsStatusEnum } from "../discover-cards.model";
import ReactTooltip from "react-tooltip";

import { RoutesEnum } from "../../../../routes/routes.constants";
import { TableButtonsContainer } from "../../../../shared/shared.utils";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import DiscoverCardStatusChip from "./discover-card-status.component";
import { useHistory } from "react-router-dom";

interface IDiscoverCardItemInterface {
  discoverCard: IDiscoverCard;
  isNew: boolean;
  getTranslation: (obj: any) => string;
  onDelete: (id: string) => void;
  onRestore: (id: string) => void;
  onStatusChange: (id: IDiscoverCard) => void;
  hasFastActions?: boolean;
  dragging?: boolean;
}

/**
 * Discover card item
 * @param param0
 */
const DiscoverCardItem = ({
  discoverCard,
  isNew,
  getTranslation,
  onDelete,
  onRestore,
  onStatusChange,
  hasFastActions = true,
  dragging = false,
}: IDiscoverCardItemInterface) => {
  const [t] = useTranslation();
  const history = useHistory();
  return (
    <DiscoverCardItemContainer id={`discovery-card-${getTranslation(discoverCard.name)}`} hoverAction={hasFastActions}>
      <Row style={{ width: "100%" }}>
        <FlexContainer ratio={2 / 12} style={{ marginRight: 16, height: "100%", display: "flex" }}>
          <Row style={{ width: "100%" }}>
            {hasFastActions ? (
              <>
                <DragContainer className={"handle"} data-tip data-for={`drag-message-${discoverCard.id}`}>
                  <ThreeDotsIcon />
                </DragContainer>
                <div style={{ display: dragging ? "none" : "block" }}>
                  <ReactTooltip
                    event="mouseover"
                    eventOff="mouseleave mousedown"
                    place="bottom"
                    type="dark"
                    effect="solid"
                    id={`drag-message-${discoverCard.id}`}
                    className={"tooltip-container"}
                  >
                    <TooltipText>{t("pages.discoverCards.dragAndDropMessage")}</TooltipText>
                  </ReactTooltip>
                </div>
              </>
            ) : null}
            <ImageContainer style={{ marginLeft: !hasFastActions ? "48px" : "" }}>
              <Image className="imageList" url={`${discoverCard.urlImage}?time=${new Date().valueOf()}`} />
            </ImageContainer>
          </Row>
        </FlexContainer>
        <FlexContainer ratio={3 / 12} style={{ marginRight: 16 }}>
          <Column>
            <Title id={`discovery-card-title-${discoverCard.id}`}>{getTranslation(discoverCard.name)}</Title>
          </Column>
        </FlexContainer>
        <FlexContainer ratio={4 / 12} style={{ marginRight: 16 }}>
          <Column>
            <Description id={`discovery-card-description-${discoverCard.id}`}>
              {getTranslation(discoverCard.description)}
            </Description>
          </Column>
        </FlexContainer>
        <FlexContainer ratio={3 / 12} style={{ marginRight: 16, display: "flex", justifyContent: "flex-end" }}>
          <Column style={{ alignItems: "flex-end" }}>
            <DiscoverCardStatusChip
              type={discoverCard.status as any}
              id={`${discoverCard.status}-${getTranslation(discoverCard.name)}`}
              style={{ width: "fit-content" }}
              onClick={() => {
                if (hasFastActions) {
                  onStatusChange(discoverCard);
                }
              }}
            >
              {discoverCard.submittedForApproval &&
              (discoverCard.status === DiscoverCardsStatusEnum.DISABLE ||
                discoverCard.status === DiscoverCardsStatusEnum.ENABLE ||
                discoverCard.status === DiscoverCardsStatusEnum.EDIT)
                ? i18next.t(`commons.discoverCards.discoverCardsStatusEnum.PENDING`)
                : i18next.t(`commons.discoverCards.discoverCardsStatusEnum.${discoverCard.status}`)}
            </DiscoverCardStatusChip>
            <Row className="date-wrapper">
              <DateContainer>
                {discoverCard.startDate ? (
                  <>
                    <DateLabel color={"#428600"}>{moment(discoverCard.startDate).format("DD/MM/YYYY")}</DateLabel>
                    {discoverCard.endDate ? <DateLabel>&nbsp;-&nbsp;</DateLabel> : null}
                  </>
                ) : null}
                {discoverCard.endDate ? (
                  <DateLabel>{moment(discoverCard.endDate).format("DD/MM/YYYY")}</DateLabel>
                ) : null}
              </DateContainer>
            </Row>
            {hasFastActions ? (
              <Row className="edit-remove-wrapper">
                {/* Todo Translate */}
                <TableButtonsContainer style={{ position: "relative", marginTop: 10 }}>
                  {discoverCard.status === "DELETE" && !isNew && (
                    <FastActionButton
                      iconComponent={
                        <IconContainer
                          size={16}
                          color={styleTheme.palette.turquoiseBlue}
                          id={`edit-discover-card-${getTranslation(discoverCard.name)}`}
                        >
                          <UndoIcon />
                        </IconContainer>
                      }
                      onClick={() => onRestore(discoverCard.id)}
                      label={t("pages.discoverCards.listButtons.restore")}
                    />
                  )}
                  {discoverCard.status !== "DELETE" && (
                    <>
                      <FastActionButton
                        iconComponent={
                          <IconContainer
                            size={16}
                            color={styleTheme.palette.turquoiseBlue}
                            id={`edit-discover-card-${getTranslation(discoverCard.name)}`}
                          >
                            <EditIcon />
                          </IconContainer>
                        }
                        onClick={() => history.push(RoutesEnum.EDIT_DISCOVER_CARD.replace(":id", discoverCard.id))}
                        label={t("pages.discoverCards.listButtons.edit")}
                      />
                      <FastActionButton
                        iconComponent={
                          <IconContainer
                            size={16}
                            color={styleTheme.palette.vodafoneRed}
                            id={`delete-discover-card-${getTranslation(discoverCard.name)}`}
                          >
                            <TrashIcon />
                          </IconContainer>
                        }
                        onClick={() => onDelete(discoverCard.id)}
                        label={t("pages.discoverCards.listButtons.delete")}
                      />
                    </>
                  )}
                </TableButtonsContainer>
              </Row>
            ) : null}
          </Column>
        </FlexContainer>
      </Row>
    </DiscoverCardItemContainer>
  );
};

const DiscoverCardItemContainer = styled("div")<{ hoverAction: boolean }>`
  display: flex;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  margin-top: 24px;
  border-radius: 8px;
  padding: 18px 0px 18px 0px;
  height: 104px;
  width: 100%;
  transition: all 0.5s;
  cursor: pointer;

  .edit-remove-wrapper {
    display: none;
  }

  &.sortable-chosen.sortable-ghost {
    img,
    span,
    button,
    div {
      display: none;
    }
  }
  ${props =>
    props.hoverAction ? ":hover {.edit-remove-wrapper { display: flex; } .date-wrapper { display: none;}}" : ""};
`;

const SpanTitleDescription = styled("span")`
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: "Vodafone Rg";
`;

const Title = styled(SpanTitleDescription)`
  font-size: 22px;
  font-weight: bold;
  @media (max-width: 1024px) {
    font-size: 20px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const Description = styled(SpanTitleDescription)`
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ImageContainer = styled("div")`
  height: 100%;
  display: flex;
  align-items: center;
  > img {
    max-height: 69px;
    border-radius: 8px;
  }
`;

const Image = styled("div")<{ url: string }>`
  background-image: ${props => (props.url ? `url(${props.url})` : "none")};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 69px;
  width: 69px;
  border-radius: 5px;
  @media (max-width: 900px) {
    height: 36px;
    width: 36px;
  }
`;

const DragContainer = styled("div")`
  display: flex;
  width: auto;
  padding-left: 16px;
  padding-right: 16px;
  stroke: ${props => props.theme.palette.greyDarkest};
  @media (max-width: 900px) {
    padding-right: 4px;
    padding-left: 4px;
  }

  svg {
    width: 16px;
    height: 16px;
    stroke: ${props => props.theme.palette.greyDarkest};
  }
`;

const TooltipText = styled("div")`
  font-size: 13px;
  font-family: "Vodafone Rg";
  color: white;
  display: flex;
  width: 85px;
  text-align: center;
`;

const DateLabel = styled("span")<{ color?: string }>`
  font-family: "Vodafone Rg";
  font-size: 13px;
  color: ${props => (props.color ? props.color : props.theme.palette.midGrey)};
  margin-top: 14px;
`;

const DateContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

export default DiscoverCardItem;
