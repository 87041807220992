import { PrimaryButton, SecondaryPageTitle, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { RoutesEnum } from "../../../../../routes/routes.constants";
import { PageContent } from "../../../../../shared/responsive-ui.styled";
import { PageContainer } from "../../../../../shared/shared.styled";
import { ServiceManagerTabNumber } from "../../safaricom-service-manager.page";
import CategoriesApi from "../categories.api";
import { Category, ICategory } from "../categories.model";
import CategoryForm from "../components/category-form.component";

/**
 * AddCategoryPage component
 */
const AddCategoryPage = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const [showAlert, , setAlertProps] = useAlert();
  const category = new Category();
  const [errorIds, setErrorIds] = React.useState<string[]>([]);

  /**
   * function go back
   */
  const goBack = () => {
    history.push(`${RoutesEnum.SERVICE_MANAGER}?tabIdx=${ServiceManagerTabNumber.CATEGORIES}`);
  };

  /**
   * function on submit form
   */
  const onSubmit = (values: ICategory) => {
    CategoriesApi.methods
      .createCategory(values)
      .then(
        res => {
          setAlertProps({
            title: t("pages.serviceManager.categories.categoryAdded"),
            type: AlertTypeEnum.SUCCESS,
          });
          goBack();
        },
        error => {
          if (error.data.status.httpCode === 409) {
            if (error.data.status.businessCode === 8) {
              if (error.data.additionalInfo && error.data.additionalInfo.invalidExternalCategoryIds) {
                let ids = error.data.additionalInfo.invalidExternalCategoryIds;
                ids = ids.invalidExternalCategoryIds ? ids.invalidExternalCategoryIds : ids;
                setErrorIds(ids.split(","));
              }
              setAlertProps({
                title: t("pages.serviceManager.categories.duplicatedIdsError"),
                type: AlertTypeEnum.ERROR,
              });
            } else if (error.data.status.businessCode === 7) {
              setAlertProps({
                title: t("pages.serviceManager.categories.categoryAlreadyExistsError"),
                type: AlertTypeEnum.ERROR,
              });
            }
          } else {
            setAlertProps({
              title: t("pages.serviceManager.categories.addCategoriesError"),
              type: AlertTypeEnum.ERROR,
            });
          }
        },
      )
      .finally(() => {
        showAlert();
      });
  };

  return (
    <PageContainer>
      <SecondaryPageTitle
        displayInitialsCircle={false}
        title={t("pages.serviceManager.categories.categoryForm.addNewCategory")}
        goBackFn={() => goBack()}
      />
      <SubmitButtonContainer>
        <PrimaryButton
          titleLabel={t("pages.serviceManager.categories.categoryForm.saveChanges")}
          redTheme={true}
          type="submit"
          form={`category-form`}
        />
      </SubmitButtonContainer>
      <PageContent>
        <CategoryForm initialValues={category} isEditing={true} onSubmitFn={onSubmit} errorIds={errorIds} />
      </PageContent>
    </PageContainer>
  );
};

export default AddCategoryPage;

const SubmitButtonContainer = styled("div")`
  position: fixed;
  right: 60px;
  top: 46px;
  z-index: 1;
  @media (max-width: 1024px) {
    right: 24px;
  }
  @media (max-width: 768px) {
    right: unset;
    bottom: 24px;
    top: unset;
    left: 138px;
  }
`;
