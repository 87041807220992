import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { IStoreInterface } from "../../../../../configs/store.config";
import { Column, Row } from "../../../../../shared/shared.styled";
import { getDefaultTranslation } from "../../../../../shared/shared.utils";
import { IBundleType, ISubBundle, IMiddlewareSubBundle } from "../../bundles.model";
import { getConfigurationFieldTranslation } from "../sub-bundles.utils";
import SubBundleStatusChip from "./sub-bundle-status.component";
import { CancelIcon } from "@wit/mpesa-ui-components";

interface IBundleLastVersionModalProps {
  hideModal: () => void;
  bundle: IBundleType;
}

/**
 * Bundle last version
 * @param param0
 */
const BundleLastVersionModal = ({ bundle, hideModal }: IBundleLastVersionModalProps) => {
  const [t] = useTranslation();
  const configurationFieldsStore = useSelector((state: IStoreInterface) => state.bundlesReducer.configurationFields);
  const [configurationFields] = React.useState(configurationFieldsStore);
  return (
    <ModalContainer>
      <Header>
        <ModalTitle>{t("pages.bundleDetails.lastVersionPublished")}</ModalTitle>
        <RightContainer>
          <CloseIconContainer onClick={hideModal} id="close-last-version-modal">
            <CancelIcon color={"grey"} />
          </CloseIconContainer>
        </RightContainer>
      </Header>
      <LastVersionContainer>
        {bundle.items ? (
          <>
            {bundle.isExternalProvider
              ? (bundle.items as IMiddlewareSubBundle[]).map(sb => {
                  return (
                    <Row key={sb.id} style={{ marginBottom: "33px", alignItems: "flex-start" }}>
                      <Column>
                        <Label>
                          {t("pages.bundleDetails.table.name")}: {getDefaultTranslation(sb.name.translations)}
                        </Label>
                        <Label>
                          {t("pages.bundleDetails.table.interactiveChannel")}: {sb.providerData.interactiveChannel}
                        </Label>
                        <Label>
                          {t("pages.bundleDetails.table.interactionPoint")}: {sb.providerData.interactionPoint}
                        </Label>
                        <Label>
                          {t("pages.bundleDetails.table.options")}:{" "}
                          {t(`pages.bundleDetails.recipientType.${sb.recipientType}`)}
                        </Label>
                      </Column>
                      <Column style={{ marginLeft: "auto", alignItems: "flex-end" }}>
                        <SubBundleStatusChip type={sb.status as any} id={`sub-bundle-status-${sb.id}`}>
                          {i18next.t(`commons.apps.enums.subBundleStatus.${sb.status}`)}
                        </SubBundleStatusChip>
                      </Column>
                    </Row>
                  );
                })
              : (bundle.items as ISubBundle[]).map(sb => {
                  return (
                    <Row key={sb.id} style={{ marginBottom: "33px", alignItems: "flex-start" }}>
                      <Column>
                        <Label>
                          {t("pages.bundleDetails.table.cost")}: {sb.cost}
                        </Label>
                        <Label>
                          {t("pages.bundleDetails.table.shortCode")}: {sb.shortcode}
                        </Label>
                        <Label>
                          {t("pages.bundleDetails.table.validity")}:{" "}
                          {getConfigurationFieldTranslation(sb.frequency, configurationFields.frequency)}
                        </Label>
                        <Label>
                          {t("pages.bundleDetails.table.network")}:{" "}
                          {getConfigurationFieldTranslation(sb.network, configurationFields.plan)}
                        </Label>
                        <Label>
                          {t("pages.bundleDetails.table.options")}:{" "}
                          {t(`pages.bundleDetails.recipientType.${sb.recipientType}`)}
                        </Label>
                      </Column>
                      <Column style={{ marginLeft: "auto", alignItems: "flex-end" }}>
                        <SubBundleStatusChip
                          type={sb.status as any}
                          id={`sub-bundle-status-${sb.id}`}
                          style={{ width: "fit-content" }}
                        >
                          {i18next.t(`commons.apps.enums.subBundleStatus.${sb.status}`)}
                        </SubBundleStatusChip>
                        {sb.content.sms ? (
                          <Label className={"status-content"} style={{ color: styleTheme.palette.turquoiseBlue }}>
                            {getDefaultTranslation(sb.content.sms.translations)}
                          </Label>
                        ) : null}
                        {sb.content.calls ? (
                          <Label className={"status-content"} style={{ color: styleTheme.palette.turquoiseBlue }}>
                            {getDefaultTranslation(sb.content.calls.translations)}
                          </Label>
                        ) : null}
                        {sb.content.data ? (
                          <Label className={"status-content"} style={{ color: styleTheme.palette.turquoiseBlue }}>
                            {getDefaultTranslation(sb.content.data.translations)}
                          </Label>
                        ) : null}
                      </Column>
                    </Row>
                  );
                })}
          </>
        ) : null}
      </LastVersionContainer>
    </ModalContainer>
  );
};

export default BundleLastVersionModal;

const ModalContainer = styled("div")`
  border-radius: 6px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  padding: 24px;
`;

const ModalTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 24px;
`;

const LastVersionContainer = styled("div")`
  font-family: Vodafone Rg;
  height: 400px;
  overflow-y: auto;
  font-size: 16px;
`;

const Label = styled("span")`
  padding-top: 5px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const RightContainer = styled.div`
  display: flex;
`;

const CloseIconContainer = styled("div")`
  left: 23px;
  margin-top: -10px;
  stroke: ${props => props.theme.palette.midGrey};
  cursor: pointer;
  svg {
    width: 24px;
  }
`;
