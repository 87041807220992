import { AutoClosingDropdown, SearchBar } from "@wit/mpesa-ui-components";
import { FilterFuncts } from "@wit/mpesa-ui-components/lib/hooks/use-filters.hook";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { OtherFiltersContainer, SearchBarFilterContainer } from "../../../../shared/responsive-ui.styled";
import { Row } from "../../../../shared/shared.styled";

export enum RateCardsFiltersEnum {
  SEARCH = "search",
  CHARGE_TYPE = "chargeType",
}

interface IRateCardsFiltersProps extends FilterFuncts {
  chargeTypes: string[];
}

const RateCardsFilters = ({ filters, clearFilter, updateFilter, chargeTypes }: IRateCardsFiltersProps) => {
  const [t] = useTranslation();

  /**
   * Returns the charge type options to be used on the filters
   */
  const getChargeTypeOptions = () => {
    const options: { label: string; key: string | undefined }[] = chargeTypes.map(c => ({
      label: c,
      key: c,
    }));
    options.push({
      label: i18next.t(`commons.apps.enums.platform.seeAll`),
      key: undefined,
    });
    return options;
  };

  return (
    <RateCardsFilterContainer>
      <SearchBarFilterContainer id={"search-rate-cards"}>
        <SearchBar
          placeholderLabel={t("pages.rateCards.search")}
          value={
            filters.has(RateCardsFiltersEnum.SEARCH) ? (filters.get(RateCardsFiltersEnum.SEARCH) as string[])[0] : ""
          }
          onChange={e => {
            if (e.target.value) {
              updateFilter(RateCardsFiltersEnum.SEARCH, e.target.value, true);
            } else {
              clearFilter(RateCardsFiltersEnum.SEARCH);
            }
          }}
          clearValue={() => clearFilter(RateCardsFiltersEnum.SEARCH)}
        />
      </SearchBarFilterContainer>

      <OtherFiltersContainer id={"rate-cards-status-dropdown"}>
        <AutoClosingDropdown
          label={
            filters.has(RateCardsFiltersEnum.CHARGE_TYPE)
              ? (filters.get(RateCardsFiltersEnum.CHARGE_TYPE) as string[])[0]
              : t("pages.rateCards.chargeType")
          }
          options={getChargeTypeOptions()}
          selectOption={val => {
            if (val.key) {
              updateFilter(RateCardsFiltersEnum.CHARGE_TYPE, val.key, true);
            } else {
              clearFilter(RateCardsFiltersEnum.CHARGE_TYPE);
            }
          }}
          hasValue={filters.has(RateCardsFiltersEnum.CHARGE_TYPE)}
        />
      </OtherFiltersContainer>
    </RateCardsFilterContainer>
  );
};

export default RateCardsFilters;

const RateCardsFilterContainer = styled(Row)`
  margin-bottom: 49px;
  margin-top: 48px;
  width: 100%;
  flex-wrap: wrap;
  gap: 24px;
`;
