import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import ServiceVersionContainer from "./service-version-container/service-version-container.component";
import { IServiceVersion, VersionLanguagesEnum } from "../../../shared/models/service-builder.model";
import Dropzone from "../../../shared/components/file-dropzone.component";
import { Formik } from "formik";

import { useTranslation } from "react-i18next";
import { ModalTypeEnum, useModal, useAlert } from "@wit/mpesa-ui-components";
import AddVersionModal from "../modals/add-version.modal";
import LanguagesApi from "../../../shared/services/languages.api";
import { ILanguage } from "../../../shared/models/language.model";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";

// TODO: change this
export const SERVICE_BUILDER_DEFAULT_LANGUAGE = VersionLanguagesEnum.ENGLISH;
export interface IServiceDetailsVersionManagementProps {
  serviceVersions?: IServiceVersion[];
  serviceId: string;
  serviceName: string;
  refreshPage: () => void;
  open: boolean;
  pending: boolean;
  isServiceLive: boolean;
}

export interface IFileLanguageMapping {
  file?: File;
  language?: VersionLanguagesEnum;
}

const ServiceDetailsVersionManagement = ({
  serviceVersions,
  serviceId,
  serviceName,
  refreshPage,
  open,
  pending,
  isServiceLive,
}: IServiceDetailsVersionManagementProps) => {
  // Hooks initialization
  const [t] = useTranslation();
  const [versionZip, setVersionZip] = useState({} as IFileLanguageMapping);
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const [availableLanguages, setAvailableLanguages] = useState<ILanguage[]>([]);

  const _hideAddVersionModal = () => {
    hideAddVersionModal();
    refreshPage();
  };

  useEffect(() => {
    LanguagesApi.methods.getAvailableLanguages().then(
      res => {
        setAvailableLanguages(res.data.availableLanguages);
      },
      () => {
        setAlertProps({
          type: AlertTypeEnum.ERROR,
          title: t("commons.getLanguagesError"),
        });
        showAlert();
      },
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearModalFields = () => {
    setVersionZip({} as IFileLanguageMapping);
  };

  const onDrop = useCallback(
    acceptedFile => {
      if (acceptedFile.length > 0) {
        const file: File = acceptedFile[0];
        setVersionZip({ file: file, language: SERVICE_BUILDER_DEFAULT_LANGUAGE } as IFileLanguageMapping);
        showAddVersionModal();

        return file;
      }
    },
    // eslint-disable-next-line
    [],
  );

  const [showAddVersionModal, hideAddVersionModal, setAddVersionProps] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <AddVersionModal
      serviceId={serviceId}
      serviceName={serviceName}
      versionFileZip={versionZip}
      hideModal={_hideAddVersionModal}
      showAlert={showAlert}
      setAlertProps={setAlertProps}
      onDrop={onDrop}
      clearFields={clearModalFields}
      lastVersion={serviceVersions !== undefined && serviceVersions.length > 0 ? serviceVersions[0].version : undefined}
      langVersions={availableLanguages}
    ></AddVersionModal>,
  );

  useEffect(() => {
    setAddVersionProps({
      modalStyles: { width: "700px", overflow: "visible" },
    });

    return () => {};
  }, [setAddVersionProps]);

  return (
    <>
      {!pending ? (
        <Formik
          onSubmit={() => {}}
          validateOnChange={false}
          initialValues={{
            zipFile: null,
          }}
          render={({ setFieldValue, errors, setErrors }) => (
            <AddVersionForm marginTop={serviceVersions !== undefined && serviceVersions.length > 0 ? 0 : 20}>
              <Dropzone
                fileName={versionZip && versionZip.file !== undefined ? versionZip.file.name : ""}
                inputName="serviceVersion"
                onDrop={(file: any) => {
                  if (!file[0]) {
                    setErrors({
                      ...errors,
                      zipFile: t("pages.serviceBuilder.modals.addServiceVersion.versionFile.errorFile"),
                    });
                  } else {
                    setErrors({
                      ...errors,
                      zipFile: "",
                    });
                    setFieldValue("serviceVersion", file);
                    onDrop(file);
                  }
                }}
                accept={".zip, .json"}
                multiple={false}
                dropTitle={t("pages.serviceBuilder.addVersion.dropTitle")}
                dropDescription={t("pages.serviceBuilder.addVersion.dropDescription")}
                releaseText={t("pages.serviceBuilder.addVersion.release")}
                error={errors.zipFile}
              />
            </AddVersionForm>
          )}
        />
      ) : null}
      {serviceVersions !== undefined
        ? serviceVersions.map((serviceVersion: IServiceVersion, idx: number) => (
            <ServiceVersionContainerWrapper key={idx}>
              <ServiceVersionContainer
                serviceVersion={serviceVersion}
                refreshPage={refreshPage}
                lastVersion={
                  serviceVersions !== undefined && serviceVersions.length > 0 ? serviceVersions[0].version : undefined
                }
                isServiceLive={isServiceLive}
                open={open}
              />
            </ServiceVersionContainerWrapper>
          ))
        : null}
    </>
  );
};

export default ServiceDetailsVersionManagement;

const ServiceVersionContainerWrapper = styled("div")`
  margin-top: 20px;
  :last-of-type {
    margin-bottom: 20px;
  }
`;
const AddVersionForm = styled("form")<{ marginTop: number }>`
  display: flex;
  padding-top: 20px;
  width: 100%;
  flex-direction: column;
  margin-top: ${props => props.marginTop}px;
  min-width: 570px;
`;
