import { AxiosPromise } from "axios";
import ApiClient from "../../../configs/axios.config";
import { IAllowedScopeToEditRequest, IIconAllowedScope } from "./allowed-scopes.model";

const AllowedScopesApi = {
  routes: {
    allowedScopes: "/allowed-scopes",
    icons: "/allowed-scopes/icons",
  },
  methods: {
    getAllowedScopes: (): AxiosPromise => {
      return ApiClient.get(AllowedScopesApi.routes.allowedScopes);
    },
    editAllowedScope: (allowedScope: IAllowedScopeToEditRequest): AxiosPromise => {
      return ApiClient.put(AllowedScopesApi.routes.allowedScopes, allowedScope);
    },
    getAllowedScopesIcons: (): AxiosPromise => {
      return ApiClient.get(AllowedScopesApi.routes.icons);
    },
    getIcon: (iconUrl: string): AxiosPromise<IIconAllowedScope> => {
      return ApiClient.get(AllowedScopesApi.routes.allowedScopes + iconUrl);
    },
  },
};

export default AllowedScopesApi;
