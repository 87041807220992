import { PrimaryButton, SecondaryPageTitle, TextInput, useAlert } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikHelpers } from "formik";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { object, string } from "yup";
import { IStoreInterface } from "../../../configs/store.config";
import AuthenticationApi from "../../../features/authentication/authentication.api";
import UsersApi from "../../../features/admin/users/users.api";
import { ILoggedUser } from "../../../features/authentication/authentication.interfaces";
import { IPasswordPolicies, validatePassword } from "../../passwords-utils";
import { Row } from "../../shared.styled";
import PasswordPoliciesDialog from "../password-rules.component";

interface IChangePasswordProps {
  showUserArea: () => void;
}

const validationSchema = (passwordPolicies: IPasswordPolicies) => {
  let msg = "";
  return object().shape({
    oldPassword: string().required(i18next.t("components.changePasswordModal.required")),
    newPassword: string()
      .required(i18next.t("components.changePasswordModal.required"))
      .test("password-rules", "", function(val) {
        if (!val) {
          return false;
        }
        msg = validatePassword(passwordPolicies, val);
        if (msg) {
          return this.createError({ message: msg });
        }
        return false;
      })
      .test("old-password-match", i18next.t("components.changePasswordModal.oldPasswordMatch"), function(val) {
        return this.parent.oldPassword !== val;
      }),
    confirmPassword: string()
      .required(i18next.t("components.changePasswordModal.required"))
      .test("passwords-match", i18next.t("components.changePasswordModal.passwordsShouldMatch"), function(val) {
        return this.parent.newPassword === val;
      }),
  });
};

const ChangePassword = ({ showUserArea }: IChangePasswordProps) => {
  const [t] = useTranslation();
  const [showAlert, , setAlertProps] = useAlert();
  const user = useSelector((s: IStoreInterface) => s.authenticationReducer.loggedUser as ILoggedUser);
  const daysToUpdatePassword = user.daysToPasswordExpiration;
  const [showPasswordPolicies, setShowPasswordPolicies] = React.useState(false);
  const [passwordPolicies, setPasswordPolicies] = React.useState<IPasswordPolicies>({
    restrictMinDigits: true,
    minDigits: 1,
    restrictMinUpperCaseLetters: true,
    minUpperCaseLetters: 1,
    restrictMinLowerCaseLetters: true,
    minLowerCaseLetters: 1,
    restrictMinNonAlphanumericCharacters: true,
    minNonAlphanumericCharacters: 1,
    restrictSuccessiveIdenticalChars: false,
    minSuccessiveIdenticalChars: 0,
    minLength: 6,
  });

  /*
   * GET the password policies
   * */
  React.useEffect(() => {
    UsersApi.methods.passwordPolicies("updateOwnPassword", user.username).then(
      res => {
        setPasswordPolicies(res.data);
      },
      err => {
        console.error(err);
      },
    );
  }, []);

  /*
   * Form submit
   * */
  const changePassword = (values: any, actions: FormikHelpers<any>) => {
    actions.setSubmitting(true);
    AuthenticationApi.methods
      .changePassword(values.oldPassword, values.newPassword)
      .finally(() => actions.setSubmitting(false))
      .then(
        () => {
          setAlertProps({
            title: t("components.changePasswordModal.success"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          showUserArea();
        },
        err => {
          const errorMsg = err.data.status.message || null;
          const modalMsg = errorMsg ? errorMsg : t("components.changePasswordModal.error");
          setAlertProps({
            title: modalMsg,
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
  };

  /*
   * Render
   * */
  return (
    <ChangePasswordContainer>
      <SecondaryPageTitle
        displayInitialsCircle={false}
        backgroundColor="#fff"
        titleFontSize={24}
        initialsFontSize={20.2}
        circleMarginLeft={16}
        goBackFn={showUserArea}
        height={104}
        initialsCircleDimension={48}
        paddingLeft={82}
        title={t("components.changePasswordModal.changePassword")}
      />
      <ChangePasswordContent>
        <Subtitle>{t("components.changePasswordModal.subtitle")}</Subtitle>
        <Subtitle
          style={{ marginBottom: "42px" }}
          dangerouslySetInnerHTML={{
            __html: t("components.changePasswordModal.subtitle2", {
              days: daysToUpdatePassword,
              class: daysToUpdatePassword < 7 ? "red" : "green",
            }),
          }}
        ></Subtitle>
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          onSubmit={changePassword}
          validationSchema={validationSchema(passwordPolicies)}
          render={({ handleChange, values, handleSubmit, errors, isSubmitting }) => (
            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <Row style={{ alignItems: "flex-end" }}>
                <FieldSection>
                  <TextInput
                    title={t("components.changePasswordModal.oldPassword")}
                    placeholder={t("components.changePasswordModal.typeOldPassword")}
                    required
                    onChange={handleChange}
                    name="oldPassword"
                    value={values.oldPassword}
                    error={errors.oldPassword}
                    autoComplete="off"
                    type="password"
                  />
                </FieldSection>
              </Row>
              <Row style={{ marginTop: "26px", alignItems: "flex-end" }}>
                <FieldSection>
                  <TextInput
                    title={t("components.changePasswordModal.newPassword")}
                    placeholder={t("components.changePasswordModal.typeNewPassword")}
                    required
                    onChange={handleChange}
                    onBlur={() => setShowPasswordPolicies(false)}
                    onFocus={() => setShowPasswordPolicies(true)}
                    name="newPassword"
                    value={values.newPassword}
                    error={errors.newPassword}
                    autoComplete="off"
                    type="password"
                  />
                  {passwordPolicies && showPasswordPolicies ? (
                    <PasswordPoliciesDialog policies={passwordPolicies} topMargin={"105px"} />
                  ) : null}
                </FieldSection>
              </Row>

              <Row style={{ marginTop: "26px", alignItems: "flex-end" }}>
                <FieldSection>
                  <TextInput
                    title={t("components.changePasswordModal.repeatPassword")}
                    placeholder={t("components.changePasswordModal.retypeNewPassword")}
                    required
                    onChange={handleChange}
                    name="confirmPassword"
                    value={values.confirmPassword}
                    error={errors.confirmPassword}
                    autoComplete="off"
                    type="password"
                  />
                </FieldSection>
              </Row>
              <Row style={{ justifyContent: "flex-end", marginTop: "50px" }}>
                <div style={{ marginRight: "12px" }}>
                  <PrimaryButton
                    onClick={showUserArea}
                    disabled={isSubmitting}
                    titleLabel={t("components.changePasswordModal.cancel")}
                  />
                </div>
                <div>
                  <PrimaryButton
                    redTheme={true}
                    type="submit"
                    disabled={isSubmitting}
                    titleLabel={t("components.changePasswordModal.save")}
                  />
                </div>
              </Row>
            </form>
          )}
        />
      </ChangePasswordContent>
    </ChangePasswordContainer>
  );
};

export default ChangePassword;

const ChangePasswordContainer = styled("div")`
  height: 100%;
  display: flex;
  flex-direction: column;
  & > div {
    position: relative;
  }

  * {
    direction: ltr !important;
  }
`;

const ChangePasswordContent = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 0 11px 50px 118px;
  align-items: flex-start;
  width: 100%;
  flex: 1;

  > div {
    width: 100%;
  }

  button {
    &:first-of-type {
      margin-bottom: 12px;
    }
    path {
      stroke: ${styleTheme.palette.red};
    }
  }
`;

const Subtitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  color: ${props => props.theme.palette.darkGrey};
  span  {
    &.red {
      color: ${props => props.theme.palette.digitalRed};
    }
    &.green {
      color: ${props => props.theme.palette.vodafoneRed};
    }
  }
`;

const FieldSection = styled("div")`
  width: 100%;
`;
