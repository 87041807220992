import React, { HTMLProps, ReactNode } from "react";
import styled from "styled-components";
import { ShortcutsStatusEnum } from "../shortcuts.model";

export interface ISubBundleStatusChipProps extends HTMLProps<HTMLDivElement> {
  type: ShortcutsStatusEnum;
  children: ReactNode;
}

/**
 * Shortcut status chip
 * @param param0
 * @returns
 */
const ShortcutStatusChip = ({ type, children, ref, as, ...rest }: ISubBundleStatusChipProps) => {
  return (
    <StatusChipContainer type={type} {...rest}>
      {children}
    </StatusChipContainer>
  );
};

export default ShortcutStatusChip;

export const StatusChipContainer = styled("div")<{ type: ShortcutsStatusEnum }>`
  border-radius: 10px;
  padding-left: 12px;
  padding-right: 12px;
  height: 22px;
  line-height: 21px;
  font-weight: bold;
  display: inline-block;
  font-size: 14px;
  text-align: center;
  font-family: Vodafone Rg;

  ${props => {
    let backgroundColor = "";
    let fontColor = props.theme.palette.white;
    let borderColor = "";
    switch (props.type) {
      case ShortcutsStatusEnum.LIVE:
        backgroundColor = props.theme.palette.digitalGreen;
        borderColor = props.theme.palette.digitalGreen;
        break;
      case ShortcutsStatusEnum.DISABLE:
        backgroundColor = props.theme.palette.white;
        borderColor = props.theme.palette.freshOrange;
        fontColor = props.theme.palette.freshOrange;
        break;
      case ShortcutsStatusEnum.DISABLED:
        backgroundColor = props.theme.palette.freshOrange;
        borderColor = props.theme.palette.freshOrange;
        break;
      case ShortcutsStatusEnum.ENABLE:
      case ShortcutsStatusEnum.EDIT:
        backgroundColor = props.theme.palette.white;
        fontColor = props.theme.palette.digitalGreen;
        borderColor = props.theme.palette.digitalGreen;
        break;
      case ShortcutsStatusEnum.DELETE:
        backgroundColor = props.theme.palette.vodafoneRed;
        borderColor = props.theme.palette.vodafoneRed;
        break;
    }
    return `background-color: ${backgroundColor}; color: ${fontColor}; border: 1px solid ${borderColor}`;
  }}
`;
