import { produce } from "immer";
import { IPayloadAction } from "../../../shared/shared.interfaces";
import { AuthenticationActions } from "../../authentication/authentication.store";
import { ILanguageTranslations, ILanguageTranslationsVersions } from "./language-translations.model";

export const LanguageTranslationsActions = {
  types: {
    GET_LANGUAGE_TRANSLATIONS_SUCCESS: "GET_LANGUAGE_TRANSLATIONS_SUCCESS",
    GET_LANGUAGE_TRANSLATIONS_VERSIONS_SUCCESS: "GET_LANGUAGE_TRANSLATIONS_VERSIONS_SUCCESS",
    GET_LANGUAGE_TRANSLATIONS_LAST_VERSION: "GET_LANGUAGE_TRANSLATIONS_LAST_VERSION",
  },
  creators: {
    getLanguageTranslationsSuccess: (languageTranslations: ILanguageTranslations) => ({
      type: LanguageTranslationsActions.types.GET_LANGUAGE_TRANSLATIONS_SUCCESS,
      payload: {
        languageTranslations,
      },
    }),
    getLanguageTranslationsVersionsSuccess: (languageTranslationsVersions: ILanguageTranslationsVersions) => ({
      type: LanguageTranslationsActions.types.GET_LANGUAGE_TRANSLATIONS_VERSIONS_SUCCESS,
      payload: {
        languageTranslationsVersions,
      },
    }),
    getLanguageTranslationsLastVersion: (languageTranslations: ILanguageTranslations) => ({
      type: LanguageTranslationsActions.types.GET_LANGUAGE_TRANSLATIONS_LAST_VERSION,
      payload: {
        languageTranslations,
      },
    }),
  },
};
export interface ILanguageTranslationsReducerInterface {
  languageTranslations: ILanguageTranslations;
  languageTranslationsVersions: ILanguageTranslationsVersions;
  lastVersion: ILanguageTranslations;
}

const initialState: ILanguageTranslationsReducerInterface = {
  languageTranslations: {} as ILanguageTranslations,
  languageTranslationsVersions: {} as ILanguageTranslationsVersions,
  lastVersion: {} as ILanguageTranslations,
};

export const languageTranslationsReducer = produce(
  (draft: ILanguageTranslationsReducerInterface, action: IPayloadAction<any>) => {
    switch (action.type) {
      case LanguageTranslationsActions.types.GET_LANGUAGE_TRANSLATIONS_SUCCESS:
        draft.languageTranslations = action.payload.languageTranslations;
        return;
      case LanguageTranslationsActions.types.GET_LANGUAGE_TRANSLATIONS_VERSIONS_SUCCESS:
        draft.languageTranslationsVersions = action.payload.languageTranslationsVersions;
        return;
      case LanguageTranslationsActions.types.GET_LANGUAGE_TRANSLATIONS_LAST_VERSION:
        draft.lastVersion = action.payload.languageTranslations;
        return;
      case AuthenticationActions.types.LOGOUT:
        return initialState;
      default:
        return draft;
    }
  },
  initialState,
);
