import {
  AutoClosingDropdown,
  DateRangeDropdown,
  Dropdown,
  EyeCrossedIcon,
  EyeIcon,
  RecurringIcon,
  SearchBar,
  SmallButton,
  TimePicker,
  UndoIcon,
} from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { FilterFuncts } from "@wit/mpesa-ui-components/lib/hooks/use-filters.hook";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { RatioContainer, Row } from "../../../../shared/shared.styled";
import { GatewayLogStatusEnum } from "../../support.interfaces";
import { onEnterPressed } from "../../../../shared/shared.utils";
import { FiltersContainer } from "../../support.styled";
import { OtherFiltersContainer, SearchBarFilterContainer } from "../../../../shared/responsive-ui.styled";

export enum AppLogsFilterKeys {
  STATUS = "requestStatus",
  OPERATION = "sdkOperation",
  TIME_RANGE = "timeRange",
  START_DATE = "startDate",
  END_DATE = "endDate",

  MSISDN = "msisdn",
  SDK_REQUEST = "requestContent",
  SDK_RESPONSE = "responseContent",
  APP_CONFIG_ID = "appConfigId",
  REQUEST_ID = "requestId",
  CONNECTION_STATUS = "connectionStatus",
  CONNECTION_TYPE = "connectionType",
  CARD_CARRIER = "cardCarrier",
  NETWORK_CARRIER = "networkCarrier",
}

interface IAppLogsFiltersProps extends FilterFuncts {
  appLogsCount: number;
  refreshResults: () => void;
}

/**
 * App logs filters component
 * @param {any} param
 * @returns {JSX.Element} component
 */
const AppLogsFilters = ({
  filters,
  clearFilter,
  isFilterActive,
  resetFilters,
  updateFilter,
  updateMultipleFilters,
  appLogsCount,
  refreshResults,
}: IAppLogsFiltersProps) => {
  const [t] = useTranslation();
  const [timeValues, setTimeValues] = React.useState({
    fromValue: "00:00",
    toValue: "18:00",
  });
  const [isTimePickerOpen, setIsTimePickerOpen] = React.useState(false);
  const firstUpdate = React.useRef(true);
  const [showExtraFields, setShowExtraFields] = React.useState(false);

  /*
   * Clear the hidden search fields upon hiding them
   * */
  React.useEffect(() => {
    if (!showExtraFields) {
      updateMultipleFilters([
        { key: AppLogsFilterKeys.MSISDN, values: [""] },
        { key: AppLogsFilterKeys.APP_CONFIG_ID, values: [""] },
        { key: AppLogsFilterKeys.CARD_CARRIER, values: [""] },
        { key: AppLogsFilterKeys.NETWORK_CARRIER, values: [""] },
        { key: AppLogsFilterKeys.CONNECTION_STATUS, values: [""] },
        { key: AppLogsFilterKeys.CONNECTION_TYPE, values: [""] },
        { key: AppLogsFilterKeys.SDK_REQUEST, values: [""] },
        { key: AppLogsFilterKeys.SDK_RESPONSE, values: [""] },
      ]);
    }
  }, [showExtraFields]);

  /*
   * Update time range filter upon timeValues change
   * */
  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    updateMultipleFilters([{ key: AppLogsFilterKeys.TIME_RANGE, values: [timeValues.fromValue, timeValues.toValue] }]);
  }, [timeValues]);

  return (
    <>
      <FiltersContainer style={{ marginBottom: "18px" }}>
        <SearchBarFilterContainer>
          <SearchBar
            onKeyDown={e => {
              onEnterPressed(e, refreshResults);
            }}
            value={
              filters.has(AppLogsFilterKeys.REQUEST_ID)
                ? (filters.get(AppLogsFilterKeys.REQUEST_ID) as string[])[0]
                : ""
            }
            onChange={e => updateFilter(AppLogsFilterKeys.REQUEST_ID, e.target.value, true)}
            clearValue={() => clearFilter(AppLogsFilterKeys.REQUEST_ID)}
            placeholderLabel={t("pages.support.appLogs.filters.requestId")}
          />
        </SearchBarFilterContainer>
        <SearchBarFilterContainer>
          <SearchBar
            onKeyDown={e => {
              onEnterPressed(e, refreshResults);
            }}
            value={
              filters.has(AppLogsFilterKeys.OPERATION) ? (filters.get(AppLogsFilterKeys.OPERATION) as string[])[0] : ""
            }
            onChange={e => updateFilter(AppLogsFilterKeys.OPERATION, e.target.value, true)}
            clearValue={() => clearFilter(AppLogsFilterKeys.OPERATION)}
            placeholderLabel={t("pages.support.appLogs.filters.sdkOperation")}
          />
        </SearchBarFilterContainer>
        <OtherFiltersContainer>
          <DateRangeDropdown
            filters={filters}
            isFilterActive={isFilterActive}
            clearFilter={clearFilter}
            updateFilter={updateFilter}
            updateMultipleFilters={updateMultipleFilters}
          />
        </OtherFiltersContainer>
        <OtherFiltersContainer>
          <Dropdown
            controlledToggleOpen={setIsTimePickerOpen}
            isOpen={isTimePickerOpen}
            hasValue={filters.has(AppLogsFilterKeys.TIME_RANGE)}
            label={
              filters.has(AppLogsFilterKeys.TIME_RANGE)
                ? `${(filters.get(AppLogsFilterKeys.TIME_RANGE) as string[])[0]} - ${
                    (filters.get(AppLogsFilterKeys.TIME_RANGE) as string[])[1]
                  }`
                : t("pages.support.appLogs.filters.timeRange")
            }
          >
            {isTimePickerOpen ? (
              <TimePickerContainer>
                <TimePicker values={timeValues} setValues={setTimeValues} />
              </TimePickerContainer>
            ) : null}

            <ClearFilterText
              onClick={() => {
                setTimeValues({
                  fromValue: "00:00",
                  toValue: "18:00",
                });
                setTimeout(() => {
                  setIsTimePickerOpen(false);
                  clearFilter(AppLogsFilterKeys.TIME_RANGE);
                }, 0);
              }}
            >
              {t("pages.support.appLogs.filters.clearFilter")}
            </ClearFilterText>
          </Dropdown>
        </OtherFiltersContainer>
        <OtherFiltersContainer>
          <AutoClosingDropdown
            options={Object.values(GatewayLogStatusEnum)
              .filter(s => typeof s === "string")
              .map(status => {
                return {
                  label: t(`commons.gatewayLogStatus.${status}`),
                  key: status,
                };
              })}
            selectOption={opt => {
              updateFilter(AppLogsFilterKeys.STATUS, opt.key, true);
            }}
            hasValue={!!filters.get(AppLogsFilterKeys.STATUS)}
            label={
              filters.get(AppLogsFilterKeys.STATUS)
                ? t(`commons.gatewayLogStatus.${(filters.get(AppLogsFilterKeys.STATUS) as string[])[0]}`)
                : t("pages.support.appLogs.filters.status")
            }
          ></AutoClosingDropdown>
        </OtherFiltersContainer>
      </FiltersContainer>
      <FiltersContainer style={{ display: showExtraFields ? "inherit" : "none", marginTop: "0", marginBottom: "16px" }}>
        <SearchBarFilterContainer>
          <SearchBar
            onKeyDown={e => {
              onEnterPressed(e, refreshResults);
            }}
            value={filters.has(AppLogsFilterKeys.MSISDN) ? (filters.get(AppLogsFilterKeys.MSISDN) as string[])[0] : ""}
            onChange={e => updateFilter(AppLogsFilterKeys.MSISDN, e.target.value, true)}
            clearValue={() => clearFilter(AppLogsFilterKeys.MSISDN)}
            placeholderLabel={t("pages.support.appLogs.filters.msisdn")}
          />
        </SearchBarFilterContainer>
        <SearchBarFilterContainer>
          <SearchBar
            onKeyDown={e => {
              onEnterPressed(e, refreshResults);
            }}
            value={
              filters.has(AppLogsFilterKeys.APP_CONFIG_ID)
                ? (filters.get(AppLogsFilterKeys.APP_CONFIG_ID) as string[])[0]
                : ""
            }
            onChange={e => updateFilter(AppLogsFilterKeys.APP_CONFIG_ID, e.target.value, true)}
            clearValue={() => clearFilter(AppLogsFilterKeys.APP_CONFIG_ID)}
            placeholderLabel={t("pages.support.appLogs.filters.appConfigId")}
          />
        </SearchBarFilterContainer>
        <SearchBarFilterContainer>
          <SearchBar
            onKeyDown={e => {
              onEnterPressed(e, refreshResults);
            }}
            value={
              filters.has(AppLogsFilterKeys.CARD_CARRIER)
                ? (filters.get(AppLogsFilterKeys.CARD_CARRIER) as string[])[0]
                : ""
            }
            onChange={e => updateFilter(AppLogsFilterKeys.CARD_CARRIER, e.target.value, true)}
            clearValue={() => clearFilter(AppLogsFilterKeys.CARD_CARRIER)}
            placeholderLabel={t("pages.support.appLogs.filters.cardCarrier")}
          />
        </SearchBarFilterContainer>
      </FiltersContainer>
      <FiltersContainer style={{ display: showExtraFields ? "inherit" : "none", marginTop: "0", marginBottom: "16px" }}>
        <SearchBarFilterContainer>
          <SearchBar
            onKeyDown={e => {
              onEnterPressed(e, refreshResults);
            }}
            value={
              filters.has(AppLogsFilterKeys.NETWORK_CARRIER)
                ? (filters.get(AppLogsFilterKeys.NETWORK_CARRIER) as string[])[0]
                : ""
            }
            onChange={e => updateFilter(AppLogsFilterKeys.NETWORK_CARRIER, e.target.value, true)}
            clearValue={() => clearFilter(AppLogsFilterKeys.NETWORK_CARRIER)}
            placeholderLabel={t("pages.support.appLogs.filters.networkCarrier")}
          />
        </SearchBarFilterContainer>
        <SearchBarFilterContainer>
          <SearchBar
            onKeyDown={e => {
              onEnterPressed(e, refreshResults);
            }}
            value={
              filters.has(AppLogsFilterKeys.CONNECTION_STATUS)
                ? (filters.get(AppLogsFilterKeys.CONNECTION_STATUS) as string[])[0]
                : ""
            }
            onChange={e => updateFilter(AppLogsFilterKeys.CONNECTION_STATUS, e.target.value, true)}
            clearValue={() => clearFilter(AppLogsFilterKeys.CONNECTION_STATUS)}
            placeholderLabel={t("pages.support.appLogs.filters.connectionStatus")}
          />
        </SearchBarFilterContainer>
        <SearchBarFilterContainer>
          <SearchBar
            onKeyDown={e => {
              onEnterPressed(e, refreshResults);
            }}
            value={
              filters.has(AppLogsFilterKeys.CONNECTION_TYPE)
                ? (filters.get(AppLogsFilterKeys.CONNECTION_TYPE) as string[])[0]
                : ""
            }
            onChange={e => updateFilter(AppLogsFilterKeys.CONNECTION_TYPE, e.target.value, true)}
            clearValue={() => clearFilter(AppLogsFilterKeys.CONNECTION_TYPE)}
            placeholderLabel={t("pages.support.appLogs.filters.connectionType")}
          />
        </SearchBarFilterContainer>
      </FiltersContainer>
      <FiltersContainer style={{ display: showExtraFields ? "inherit" : "none", marginTop: "0", marginBottom: "16px" }}>
        <SearchBarFilterContainer>
          <SearchBar
            onKeyDown={e => {
              onEnterPressed(e, refreshResults);
            }}
            value={
              filters.has(AppLogsFilterKeys.SDK_REQUEST)
                ? (filters.get(AppLogsFilterKeys.SDK_REQUEST) as string[])[0]
                : ""
            }
            onChange={e => updateFilter(AppLogsFilterKeys.SDK_REQUEST, e.target.value, true)}
            clearValue={() => clearFilter(AppLogsFilterKeys.SDK_REQUEST)}
            placeholderLabel={t("pages.support.appLogs.filters.sdkRequest")}
          />
        </SearchBarFilterContainer>
        <SearchBarFilterContainer>
          <SearchBar
            onKeyDown={e => {
              onEnterPressed(e, refreshResults);
            }}
            value={
              filters.has(AppLogsFilterKeys.SDK_RESPONSE)
                ? (filters.get(AppLogsFilterKeys.SDK_RESPONSE) as string[])[0]
                : ""
            }
            onChange={e => updateFilter(AppLogsFilterKeys.SDK_RESPONSE, e.target.value, true)}
            clearValue={() => clearFilter(AppLogsFilterKeys.SDK_RESPONSE)}
            placeholderLabel={t("pages.support.appLogs.filters.sdkResponse")}
          />
        </SearchBarFilterContainer>
      </FiltersContainer>
      <CustomRow>
        <SmallButton
          titleLabel={t(`pages.support.appLogs.${showExtraFields ? "hide" : "show"}ExtraFields`)}
          onClick={() => setShowExtraFields(!showExtraFields)}
          iconComponent={
            <SmallButtonIconContainer color={styleTheme.palette.turquoiseBlue}>
              {showExtraFields ? <EyeCrossedIcon /> : <EyeIcon />}
            </SmallButtonIconContainer>
          }
        />
      </CustomRow>
      <Row style={{ marginBottom: 12 }}>
        <GatewayLogsCount>
          <span>{appLogsCount}</span>
          &nbsp;
          {`${t("pages.support.appLogs.recordsFound")}`}
        </GatewayLogsCount>
        <div style={{ marginRight: 24 }}>
          <SmallButton
            titleLabel={t("pages.support.appLogs.refreshResults")}
            onClick={() => refreshResults()}
            iconComponent={
              <SmallButtonIconContainer color={styleTheme.palette.turquoiseBlue}>
                <RecurringIcon />
              </SmallButtonIconContainer>
            }
          />
        </div>
        <div>
          <SmallButton
            titleLabel={t("pages.support.appLogs.clearFilters")}
            onClick={() => {
              setTimeValues({
                fromValue: "00:00",
                toValue: "18:00",
              });
              setTimeout(() => {
                setIsTimePickerOpen(false);
                clearFilter(AppLogsFilterKeys.TIME_RANGE);
                resetFilters();
              }, 0);
            }}
            iconComponent={
              <SmallButtonIconContainer color={styleTheme.palette.vodafoneRed}>
                <UndoIcon />
              </SmallButtonIconContainer>
            }
          />
        </div>
      </Row>
    </>
  );
};

export default AppLogsFilters;

const TimePickerContainer = styled("div")`
  padding: 8px 8px 8px 12px;
`;

const ClearFilterText = styled("div")`
  font-family: Vodafone Rg;
  font-size: 14px;
  color: ${props => props.theme.palette.midGrey};
  text-align: left;
  display: flex;
  align-items: center;
  max-height: 30px;
  height: 30px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 12px;
  &:hover {
    background: ${props => props.theme.palette.lightGrey};
  }
`;

const CustomRow = styled(Row)`
  margin-bottom: 18px;

  > ${RatioContainer} {
    margin-right: 24px;

    :last-of-type {
      margin-right: 0;
    }
  }
`;

const GatewayLogsCount = styled("div")`
  display: flex;
  flex-direction: row;
  margin-right: 24px;
  font-family: Vodafone Rg;
  font-size: 14px;
  color: ${props => props.theme.palette.midGrey};

  > span {
    font-weight: bold;
  }
`;

const SmallButtonIconContainer = styled("div")<{ color: string }>`
  width: 16px;
  height: 16px;
  stroke: ${props => props.color};

  svg {
    width: 16px;
    height: 16px;
    stroke: ${props => props.color};
  }
`;
