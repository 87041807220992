import { produce } from "immer";
import { IPayloadAction } from "../../../shared/shared.interfaces";
import { AuthenticationActions } from "../../authentication/authentication.store";
import { ITask } from "./task.model";

export const TasksActions = {
  types: {
    GET_TASKS_SUCCESS: "GET_TASKS_SUCCESS",
  },
  creators: {
    getTasksSuccess: (tasks: ITask[]) => ({
      type: TasksActions.types.GET_TASKS_SUCCESS,
      payload: {
        tasks,
      },
    }),
  },
};
export interface ITasksReducerInterface {
  tasks: ITask[];
}

const initialState: ITasksReducerInterface = {
  tasks: [] as ITask[],
};

export const tasksReducer = produce((draft: ITasksReducerInterface, action: IPayloadAction<any>) => {
  switch (action.type) {
    case TasksActions.types.GET_TASKS_SUCCESS:
      draft.tasks = action.payload.tasks;
      return;
    case AuthenticationActions.types.LOGOUT:
      return initialState;
    default:
      return draft;
  }
}, initialState);
