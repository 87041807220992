import { AlertProvider, ModalProvider, MPesaTheme } from "@wit/mpesa-ui-components";
import Axios from "axios";
import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { configureAxiosUrl } from "./configs/axios.config";
import { configureAxiosKycUrl } from "./configs/axios.kyc.config";
import "./configs/i18n.config";
import store from "./configs/store.config";
import { EComponentTypes } from "./features/support/components/component-versions/component-versions.utils";
import { SafaricomTheme } from "./safaricom.theme";
import SessionWrapperComponent from "./session-wrapper.component";
import LoadingComponent from "./shared/components/loading-component/LoadingComponent";
import { ILanguage } from "./shared/models/language.model";
import { CLIENTS, THEMES } from "./shared/renderer.utils";
import { BackOfficeMarketsEnums } from "./shared/shared.enums";
import { IServiceGroup } from "./shared/models/service-manager.model";

export interface IConfig {
  client: CLIENTS;
  market?: BackOfficeMarketsEnums;
  serviceGroups?: IServiceGroup[];
  theme: string;
  version: number;
  apiUrl: string;
  showCategories: boolean;
  availableAreas: string[];
  defaultRoute: string;
  disabledFeatures: string[];
  discoverCards: {
    typeOfLinks: string[];
    availableAppFlows: string[];
  };
  pushNotifications: {
    typeOfLinks?: string[];
    availableAppFlows: string[];
  };
  formValidations: IFormValidationsInterface;
  componentBaseUrls: [{ component: EComponentTypes; url: string }];
  bundles: {
    showCalls: boolean;
    showData: boolean;
    showFrequency: boolean;
    showNetworks: boolean;
    showSMS: boolean;
    useUpsell: boolean;
    showIdentifierCode: boolean;
  };
  supportedLanguages?: ILanguage[];
  actionItems?: {
    typeOfLinks?: string[];
    availableAppFlows: string[];
    availableIcons: string[];
  };
  randomBackgroundDisabled?: boolean;
  appVersionDependencies: {
    childAccount: {
      min: string;
      max: string;
    };
    announcementsThumbnail: {
      min: string;
      max: string;
    };
  };
  MAX_CSV_FILE_SIZE: number;
}

interface IFormValidationsInterface {
  phoneNumberRegex: string;
}

interface IConfigContextInterface {
  config: IConfig | undefined;
  setConfig: (config: IConfig | undefined) => void;
}

export const ConfigContext = React.createContext<IConfigContextInterface>({
  config: undefined,
  setConfig: () => {},
});

/**
 * Defines a list of markets that will use the private config fetching mechanism
 */
export const marketsWithPrivateConfigs = [BackOfficeMarketsEnums.ET, BackOfficeMarketsEnums.DRC_EKYC];

/**
 * Retrieves the config url
 */
const getConfigUrl = () => {
  const baseUrl = process.env.REACT_APP_CONFIG_URL as string;
  if (marketsWithPrivateConfigs.includes(process.env.REACT_APP_MARKET as BackOfficeMarketsEnums)) {
    return `${baseUrl}/public`;
  }
  return baseUrl;
};

/**
 * AppWrapper component
 */
const AppWrapper = () => {
  const [config, setConfig] = React.useState<IConfig | undefined>(undefined);
  const [isLoadingConfig, setIsLoadingConfig] = React.useState(true);

  /**
   * Effect to fetch the config before mounting our app
   */
  React.useEffect(() => {
    setIsLoadingConfig(true);
    const configUrl = getConfigUrl();

    Axios.get(configUrl)
      .finally(() => setIsLoadingConfig(false))
      .then(res => {
        configureAxiosUrl(
          process.env.REACT_APP_LOCAL_BASE_URL ? process.env.REACT_APP_LOCAL_BASE_URL : res.data.apiUrl,
        );
        configureAxiosKycUrl(
          process.env.REACT_APP_LOCAL_BASE_URL_EKYC_V1
            ? process.env.REACT_APP_LOCAL_BASE_URL_EKYC_V1
            : res.data.ekycApiURL,
        );
        const conf: IConfig = {
          ...res.data,
          market: process.env.REACT_APP_MARKET as string,
        };
        setConfig(conf);
        localStorage.setItem("config", JSON.stringify(conf));
        handleFavicon(conf);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  return isLoadingConfig ? (
    <LoadingComponent
      loadingText={
        !process.env.REACT_APP_MARKET!.includes(BackOfficeMarketsEnums.DRC)
          ? "Loading configuration..."
          : "Chargement des configurations..."
      }
    />
  ) : config ? (
    <ConfigContext.Provider value={{ config, setConfig }}>
      <App />
    </ConfigContext.Provider>
  ) : (
    <LoadingComponent
      loadingText={
        !process.env.REACT_APP_MARKET!.includes(BackOfficeMarketsEnums.DRC)
          ? "There was a problem loading the configuration..."
          : "Un problème est survenu lors du chargement de la configuration..."
      }
    />
  );
};

/**
 * get favicon icon
 */
const getFaviconEl = () => {
  return document.getElementById("favicon");
};

/**
 * get favicon icon
 */
const handleFavicon = (config: IConfig | undefined) => {
  const favicon = getFaviconEl() as HTMLAnchorElement; // Accessing favicon element
  if (config) {
    switch (config.theme) {
      case THEMES.SAFARICOM:
        favicon.href = `${process.env.PUBLIC_URL}/safaricom-favicon.ico`;
        break;
      case THEMES.CLASSIC:
        favicon.href = `${process.env.PUBLIC_URL}/favicon.ico`;
        break;
    }
  }
};

/**
 * get app theme
 */
const getAppTheme = (config: IConfig | undefined) => {
  if (config) {
    switch (config.theme) {
      case THEMES.SAFARICOM:
        return SafaricomTheme;
      case THEMES.CLASSIC:
        return MPesaTheme;
      default:
        return MPesaTheme;
    }
  }
  return MPesaTheme;
};

const App = () => {
  const { config } = React.useContext(ConfigContext);
  return (
    <Provider store={store}>
      <ThemeProvider theme={getAppTheme(config)}>
        <Suspense fallback={<span />}>
          <AlertProvider>
            <ModalProvider>
              <ConfigContext.Consumer>
                {({ config }) => (config ? <SessionWrapperComponent /> : "Error loading config")}
              </ConfigContext.Consumer>
            </ModalProvider>
          </AlertProvider>
        </Suspense>
      </ThemeProvider>
    </Provider>
  );
};

export default AppWrapper;
