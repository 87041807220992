import React, { useCallback } from "react";
import styled from "styled-components";
import {
  FastActionButton,
  DownloadIcon,
  CopyIcon,
  TrashIcon,
  useAlert,
  useModal,
  ModalTypeEnum,
} from "@wit/mpesa-ui-components";
import { useTranslation } from "react-i18next";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { IApp } from "../../../shared/models/app.model";
import AppsApi from "../apps.api";
import { downloadJsonFile } from "../../../shared/shared.utils";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { useDispatch } from "react-redux";
import { AppsActions } from "../apps.store";

interface IAppFastActionsProps {
  app: IApp;
}

const AppFastActions = ({ app }: IAppFastActionsProps) => {
  // Hook Initialization
  const [t] = useTranslation();
  const [showAlert, , setAlertProps] = useAlert();
  const [showModal, hideModal, setProps] = useModal(ModalTypeEnum.ConfirmationModal);
  const dispatch = useDispatch();
  // Functions
  const exportApp = useCallback(() => {
    AppsApi.methods.exportApp(app).then(
      res => {
        downloadJsonFile(res.data, app.name);
      },
      () => {
        setAlertProps({
          title: t("pages.apps.errors.exportingApp.title"),
          content: t("pages.apps.errors.exportingApp.content"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  }, [app, showAlert, setAlertProps, t]);

  const deleteApp = useCallback(() => {
    AppsApi.methods
      .deleteApp(app)
      .finally(() => hideModal())
      .then(
        () => {
          dispatch(AppsActions.creators.deleteAppAction(app));
          setAlertProps({
            title: t("pages.apps.success.deletingApp.title"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
        },
        () => {
          setAlertProps({
            title: t("pages.apps.errors.deletingApp.title"),
            content: t("pages.apps.errors.deletingApp.content"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
  }, [app, showAlert, setAlertProps, dispatch, hideModal, t]);

  function _showModal() {
    setProps({
      title: t("pages.apps.modals.deleteApp.title"),
      description: t("pages.apps.modals.deleteApp.description", {
        appName: app.name,
        appId: app.id,
      }),
      primaryAction: deleteApp,
      primaryBtnLabel: t("pages.apps.modals.deleteApp.primaryButtonLabel"),
      secondaryAction: hideModal,
      secondaryBtnLabel: t("pages.apps.modals.deleteApp.secondaryButtonLabel"),
    });
    showModal();
  }

  return (
    <AppFastActionsContainer>
      <FastActionButton
        label={t("pages.apps.fastActionButtons.export")}
        iconComponent={
          <IconContainer color={styleTheme.palette.turquoiseBlue}>
            <DownloadIcon />
          </IconContainer>
        }
        onClick={exportApp}
      />
      <FastActionButton
        label={t("pages.apps.fastActionButtons.deprecate")}
        iconComponent={
          <IconContainer color={styleTheme.palette.successGreen}>
            <CopyIcon />
          </IconContainer>
        }
        onClick={() => null}
      />
      <FastActionButton
        label={t("pages.apps.fastActionButtons.delete")}
        iconComponent={
          <IconContainer color={styleTheme.palette.errorRed}>
            <TrashIcon />
          </IconContainer>
        }
        onClick={_showModal}
      />
    </AppFastActionsContainer>
  );
};

export default AppFastActions;

export const AppFastActionsContainer = styled("div")`
  display: none;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  > button {
    width: fit-content;
  }
`;

const IconContainer = styled("div")<{ color: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  stroke: ${props => props.color};
  svg {
    width: 16px;
    height: 16px;
  }
`;
