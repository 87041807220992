import { ConfigContext } from "../../app.component";
import { useContext } from "react";

/**
 *Checks if a feature is enabled by checking if it is not disabled
 * @param feature
 * @returns
 */
const useCheckFeatureAvailable = (feature: string): boolean => {
  const { config } = useContext(ConfigContext);
  if (config) {
    if (config.disabledFeatures !== undefined && !config.disabledFeatures.includes(feature)) {
      return true;
    }
  }
  return false;
};

export default useCheckFeatureAvailable;
