import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { ArrowDownIcon } from "@wit/mpesa-ui-components";
import ServicePreviewDrawerApi from "./service-preview-drawer.api";

interface IServicePreviewDrawerProps {
  closeDrawer: () => void;
  appVersion: string;
  versionId: string;
  language: string;
}

const ServicePreviewDrawer = ({ closeDrawer, appVersion, versionId, language }: IServicePreviewDrawerProps) => {
  //const [loadedError, setLoadedError] = useState(false);
  const [configLoaded, setConfigLoaded] = useState(false);
  //let { versionId } = useParams()
  const [rendererConfig, setRendererConfig] = useState({});
  //const [isNotEmpty, setIsNotEmpty] = useState(false);

  const rendererLink =
    process.env.REACT_APP_SERVICE_EDITOR_RENDERER_URL !== undefined
      ? process.env.REACT_APP_SERVICE_EDITOR_RENDERER_URL
      : undefined;

  const getServiceConfiguration = useCallback(lang => {
    ServicePreviewDrawerApi.methods
      .getServiceConfiguration(versionId, lang)
      .then(res => {
        try {
          if (res.data && rendererLink !== undefined) {
            setRendererConfig(res.data);
            //console.log(rendererConfig);
            /*if (JSON.stringify(res.data) === JSON.stringify({})) {
              setIsNotEmpty(false);
            } else {
              setIsNotEmpty(true);
            }*/
            if (rendererConfig !== {}) {
              ((document.getElementById("iframeEl") as HTMLIFrameElement).contentWindow as Window).postMessage(
                {
                  type: "MPESA_RENDERER_SET_CONFIG",
                  payload: { ...res.data },
                },
                rendererLink,
              );
              process.nextTick(() => {
                //setLoadedError(false);
                setConfigLoaded(true);
              });
            }
          }
        } catch (e) {
          //setLoadedError(true);
          setConfigLoaded(true);
          setRendererConfig({});
          //setIsNotEmpty(false);
          console.error(e);
        }
      })
      .catch(err => {
        //console.log("failed to load");
        //setLoadedError(true);
        setConfigLoaded(true);
        setRendererConfig({});
        //setIsNotEmpty(false);
        console.error(err);
      });
  }, []);

  const onIframeLoad = () => {
    !configLoaded && getServiceConfiguration(language);
  };

  /*useEffect(() => {
    getServiceConfiguration(language);
  }, []);*/

  return (
    <ServicePreviewDrawerMask>
      <ServicePreviewDrawerContainer>
        <ServicePreviewDrawerChildrenContainer>
          <ServicePreviewVersionContainer>
            <BackButton onClick={closeDrawer}>
              <ArrowDownIcon />
            </BackButton>
            <VersionNumber>Version nº {appVersion}</VersionNumber>
          </ServicePreviewVersionContainer>

          <PreviewDiv>
            <IFrameWrapper>
              <IFrame
                id="iframeEl"
                title="renderer-preview"
                src={rendererLink}
                onLoad={onIframeLoad}
                style={{ opacity: configLoaded ? 1 : 0 }}
              />
            </IFrameWrapper>
          </PreviewDiv>
        </ServicePreviewDrawerChildrenContainer>
      </ServicePreviewDrawerContainer>
    </ServicePreviewDrawerMask>
  );
};

export default ServicePreviewDrawer;

const ServicePreviewDrawerMask = styled("div")`
  position: fixed;
  z-index: 10;
  background-color: rgba(51, 51, 51, 0.4);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ServicePreviewDrawerContainer = styled("div")`
  position: fixed;
  right: 0;
  width: 551px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  height: 100%;
  overflow: auto;
`;

const ServicePreviewDrawerChildrenContainer = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 67px 0px 67px 0px;
`;

const ServicePreviewVersionContainer = styled("div")`
  display: flex;
  padding: 0 86px;
  height: 27px;
  width: 100%;
  margin-bottom: 50px;
`;

const BackButton = styled("div")`
  align-items: center;
  padding-top: 5px;
  flex-basis: 10%;
  svg {
    transform: rotate(90deg);
    width: 20px;
    height: 20px;
    stroke: ${props => props.theme.palette.vodafoneRed};
  }
  :hover {
    cursor: pointer;
  }
`;

const VersionNumber = styled("div")`
  flex-basis: 90%;
  font-family: Vodafone Rg;
  font-size: 24px;
  color: ${props => props.theme.palette.darkGrey};
  letter-spacing: 0;
`;
//Renderer

const IFrameWrapper = styled("div")`
  height: 544px;
  width: 306px;
  flex: 1;
  border-radius: 3.5px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1), 0 3px 7px 0 rgba(142, 142, 142, 0.23);
  background: url(/images/serviceEditor/service_editor_illustration.png);
  background-position: center;
  background-repeat: no-repeat;
`;

const IFrame = styled("iframe")`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: none;
  border-radius: 3.5px;
`;

const PreviewDiv = styled("div")`
  display: -ms-flexbox;
  display: flex;
  flex: 1;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  margin-bottom: 10px;
`;
