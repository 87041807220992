import { PrimaryButton, TextInput, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { object, string } from "yup";
import AnimatedBackgroundImage from "../../../shared/components/background-image.component";
import AuthenticationApi from "../authentication.api";
import { ITwoFactorAuthenticationRequest, ITwoFactorAuthenticationResponse } from "../authentication.interfaces";
import { ButtonContainer, LoginContainer, LoginFormContainer, LoginTitle } from "./login.page";

interface ITwoFactorAuthenticationComponentInterface {
  needsToSetup2FA: boolean;
  validate2FA: (
    values: ITwoFactorAuthenticationRequest,
    actions: FormikHelpers<ITwoFactorAuthenticationRequest>,
  ) => void;
}

const TwoFactorAuthenticationComponent = ({
  needsToSetup2FA,
  validate2FA,
}: ITwoFactorAuthenticationComponentInterface) => {
  const [t] = useTranslation(["public"]);
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const [showQRCodeHelper, setShowQRCodeHelper] = useState(false);
  const [twoFactorAuthenticationSecrets, setTwoFactorAuthenticationSecrets] = useState<
    ITwoFactorAuthenticationResponse
  >();

  React.useEffect(() => {
    if (needsToSetup2FA) {
      AuthenticationApi.methods.get2FASecret().then(
        res => {
          setTwoFactorAuthenticationSecrets(res.data);
        },
        () => {
          setAlertProps({
            title: t("pages.twoFactorAuthentication.get2FASecretError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
    }
  }, [needsToSetup2FA]);

  const get2FASchema = () => {
    return object().shape({
      otpCode: string().required(t("commons.mandatoryField")),
    });
  };

  return (
    <LoginContainer>
      <>
        {showQRCodeHelper && twoFactorAuthenticationSecrets ? (
          <LoginFormContainer>
            <LoginTitle>{t("pages.twoFactorAuthentication.cantScanQRCode")}</LoginTitle>
            <LoginMessageDescription
              marginBottom={24}
              dangerouslySetInnerHTML={{
                __html: t("pages.twoFactorAuthentication.cantScanCodeMessage"),
              }}
            />
            <TwoFactorSecret>{twoFactorAuthenticationSecrets.secret}</TwoFactorSecret>
            <LoginMessageDescription
              marginBottom={0}
              dangerouslySetInnerHTML={{
                __html: t("pages.twoFactorAuthentication.cantScanCodeMessageBottom"),
              }}
            />
            <ButtonContainer>
              <PrimaryButton
                onClick={() => setShowQRCodeHelper(false)}
                redTheme={true}
                titleLabel={t("pages.twoFactorAuthentication.ok")}
                type="button"
              />
            </ButtonContainer>
          </LoginFormContainer>
        ) : (
          <Formik
            initialValues={{ otpCode: "" }}
            onSubmit={validate2FA}
            validationSchema={get2FASchema()}
            render={({ setFieldValue, values, errors, handleSubmit, isSubmitting }) => (
              <LoginFormContainer onSubmit={handleSubmit}>
                <LoginTitle>{t("pages.twoFactorAuthentication.title")}</LoginTitle>
                <LoginMessageDescription
                  marginBottom={!needsToSetup2FA ? 18 : 0}
                  dangerouslySetInnerHTML={{
                    __html: needsToSetup2FA
                      ? t("pages.twoFactorAuthentication.setupMessage")
                      : t("pages.twoFactorAuthentication.verificationMessage"),
                  }}
                ></LoginMessageDescription>
                {needsToSetup2FA ? (
                  <>
                    {twoFactorAuthenticationSecrets ? (
                      <QRCodeContainer>
                        <img src={`data:image/png;base64, ${twoFactorAuthenticationSecrets.image}`} alt="Scan me" />
                      </QRCodeContainer>
                    ) : (
                      <QRCodeContainer>
                        <LoadingQRCode></LoadingQRCode>
                      </QRCodeContainer>
                    )}
                    <QRCodeHelper onClick={() => setShowQRCodeHelper(true)}>
                      {t("pages.twoFactorAuthentication.cantScanQRCode")}
                    </QRCodeHelper>
                  </>
                ) : null}
                <TextInput
                  name={"otpCode"}
                  autoFocus
                  required={true}
                  type="text"
                  title={t("pages.twoFactorAuthentication.verificationCode")}
                  placeholder={t("pages.twoFactorAuthentication.verificationCodePlaceholder")}
                  value={values.otpCode}
                  error={errors.otpCode}
                  onChange={evt => setFieldValue("otpCode", evt.target.value)}
                />
                <ButtonContainer>
                  <PrimaryButton
                    disabled={isSubmitting}
                    redTheme={true}
                    titleLabel={t("pages.twoFactorAuthentication.verifyButton")}
                    type="submit"
                  />
                </ButtonContainer>
              </LoginFormContainer>
            )}
          />
        )}
        <AnimatedBackgroundImage />
      </>
    </LoginContainer>
  );
};

const TwoFactorSecret = styled("div")`
  font-size: 18px;
  font-family: "Vodafone RgBd";
  color: ${props => props.theme.palette.darkGrey};
  border-radius: 6px;
  border: solid 1px ${props => props.theme.palette.aluminium};
  padding: 8px 10px 8px 10px;
  margin-bottom: 24px;
  text-align: center;
`;

const QRCodeContainer = styled("div")`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 15px;
  > img {
    height: 200px;
    width: 200px;
  }
`;

const LoadingQRCode = styled("div")`
  background-color: ${props => props.theme.palette.midGrey};
  height: 100%;
  width: 100%;
`;

const QRCodeHelper = styled("a")`
  font-size: 14px;
  font-family: "Vodafone Rg";
  color: ${props => props.theme.palette.midGrey}
  margin-bottom: 20px;
  text-align: center;
  cursor: pointer;
`;

const LoginMessageDescription = styled("span")<{ marginBottom: number }>`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: #333;
  margin-bottom: ${props => props.marginBottom}px;
`;

export default TwoFactorAuthenticationComponent;
