import { AxiosPromise } from "axios";
import ApiClient from "../../../configs/axios.config";
import { IDocument, IDocumentType, IDocumentsList } from "../../../shared/models/app.model";

/*
 * Documents api list
 * */
const DocumentsApi = {
  routes: {
    documents: "/v1/documents/typeList",
    apps: "/v1/documents/:type",
    document: "/v1/documents",
    publish: "/v1/documents/publish",
    revert: "/v1/documents/revert",
  },
  methods: {
    getDocumentList: (document?: string): AxiosPromise<IDocumentsList> => {
      return ApiClient.get(DocumentsApi.routes.documents, {
        params: {
          type: document,
        },
      });
    },
    addDocument: (data: IDocumentType): AxiosPromise<IDocumentsList> => {
      return ApiClient.post(DocumentsApi.routes.document, {
        ...data,
      });
    },
    editDocument: (data: IDocumentType): AxiosPromise<IDocumentsList> => {
      return ApiClient.put(DocumentsApi.routes.document, {
        ...data,
      });
    },
    deleteDocument: (document: string): AxiosPromise<IDocumentsList> => {
      return ApiClient.delete(DocumentsApi.routes.document, {
        params: { type: document },
      });
    },
    getDocumentApps: (document: string): AxiosPromise<[]> => {
      return ApiClient.get(DocumentsApi.routes.apps.replace(":type", document));
    },
    getDocument: (document: string, id: string, previousVersion: boolean = false): AxiosPromise<IDocument> => {
      return ApiClient.get(DocumentsApi.routes.document, {
        params: {
          type: document,
          appId: id,
          previousVersion,
        },
      });
    },
    publish: (document: string, id: string): AxiosPromise<IDocument> => {
      return ApiClient.put(`${DocumentsApi.routes.publish}?type=${document}&appId=${id}`);
    },
    save: (document: string, id: string, data: any): AxiosPromise<IDocument> => {
      return ApiClient.put(DocumentsApi.routes.apps.replace(":type", document), {
        ...{ type: document, appId: id, content: { ...data } },
      });
    },
    revert: (document: string, id: string): AxiosPromise<IDocument> => {
      return ApiClient.put(`${DocumentsApi.routes.revert}?type=${document}&appId=${id}`);
    },
  },
};

export default DocumentsApi;
