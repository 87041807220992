import { ArrowDownIcon, PrimaryButton, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { LoadingText } from "../../../../shared/shared.styled";
import ReferralCampaignsApi from "../referral-campaigns.api";
import { IGalleryImage } from "../referral-campaigns.model";

interface IReferralCampaignImageGalleryProps {
  selectImage: (image: IGalleryImage) => void;
  hideDrawer: () => void;
}

interface IThumbnailProps {
  image: IGalleryImage;
  clickCb: (image: IGalleryImage) => void;
  isSelected: boolean;
}

const Thumbnail = React.memo(function Thumbnail({ image, clickCb, isSelected = false }: IThumbnailProps) {
  return <ThumbnailContainer selected={isSelected} url={image.url} onClick={() => clickCb(image)} />;
});

/**
 * ReferralCampaignImageGallery component
 * @param param0
 */
const ReferralCampaignImageGallery = ({ selectImage, hideDrawer }: IReferralCampaignImageGalleryProps) => {
  const [t] = useTranslation();
  const [selectedImage, setSelectedImage] = React.useState<IGalleryImage>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [images, setImages] = React.useState<IGalleryImage[]>([]);
  const [showAlert, , setAlertProps] = useAlert();

  React.useEffect(() => {
    setIsLoading(true);
    ReferralCampaignsApi.methods
      .getImageGallery()
      .then(
        res => {
          setImages(res.data);
        },
        () => {
          setAlertProps({
            type: AlertTypeEnum.ERROR,
            title: t("pages.referralCampaigns.gallery.fetchGalleryError"),
          });
          showAlert();
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  React.useEffect(() => {}, [selectedImage]);

  return (
    <>
      <GalleryContainer>
        <TitleContainer>
          <CloseIconContainer onClick={hideDrawer}>
            <ArrowDownIcon />
          </CloseIconContainer>
          <Title>{t("pages.referralCampaigns.gallery.title")}</Title>
          <ChooseButtonContainer>
            <PrimaryButton
              redTheme={true}
              onClick={() => {
                selectImage(selectedImage as IGalleryImage);
                hideDrawer();
              }}
              titleLabel={t("pages.referralCampaigns.gallery.choose")}
              type="button"
              disabled={!selectedImage}
              id={"gallery-choose"}
            />
          </ChooseButtonContainer>
        </TitleContainer>

        <Gallery>
          {isLoading ? <LoadingText>{t("commons.loadingImages")}</LoadingText> : null}
          {(!images || images.length === 0) && !isLoading ? (
            <NoImages>{t("pages.referralCampaigns.gallery.noImages")}</NoImages>
          ) : (
            <>
              {images.map((img, index) => {
                return (
                  <span id={`image-${index}`}>
                    <Thumbnail
                      key={img.filename}
                      image={img}
                      clickCb={setSelectedImage}
                      isSelected={selectedImage && selectedImage.url === img.url ? true : false}
                    />
                  </span>
                );
              })}
            </>
          )}
        </Gallery>
      </GalleryContainer>
      <GalleryOverlay onClick={hideDrawer} />
    </>
  );
};

export default ReferralCampaignImageGallery;

const NoImages = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.vodafoneRed};
  font-weight: bold;
`;

const ThumbnailContainer = styled.div<{ url: string; selected: boolean }>`
  animation: fadeIn ease 1s;
  width: 174px;
  height: 174px;
  border-radius: 2px;
  background-image: ${props => (props.url ? `url(${props.url})` : "none")};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #e9f5ff;
  cursor: pointer;
  border: 3px solid ${props => (props.selected ? props.theme.palette.greyDarker : "transparent")};
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Gallery = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 20px;
  grid-area: gallery;
  padding: 0px 81px 40px 109px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const ChooseButtonContainer = styled("div")`
  margin-left: auto;
`;

const TitleContainer = styled("div")`
  display: flex;
  padding: 74px 81px 33px 65px;
  align-items: center;
  grid-area: title;
`;

const GalleryOverlay = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
`;

const GalleryContainer = styled("div")`
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  width: 750px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  height: 100%;
  display: grid;
  grid-template-rows: 143px 1fr;
  grid-template-areas: "title" "gallery";
`;

const CloseIconContainer = styled("div")`
  stroke: ${props => props.theme.palette.vodafoneRed};
  transform: rotate(90deg);
  cursor: pointer;
  svg {
    width: 24px;
  }
`;

const Title = styled("span")`
  font-family: Vodafone Rg;
  font-size: 24px;
  color: ${props => props.theme.palette.darkGrey};
  margin-left: 18px;
`;
