import React from "react";
import { IIconProps } from "./icon-interfaces";

const WarningIcon = (props: IIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" id={props.id}>
      <g fill={props?.fill || "none"} fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g stroke={props?.stroke || "#E60000"}>
          <g>
            <g>
              <g>
                <path
                  d="M19.054 15.035c1.045 1.849.196 3.465-1.9 3.465H2.846c-2.09 0-2.946-1.616-1.9-3.465L8.1 1.894c1.041-1.793 2.75-1.888 3.801 0l7.153 13.141zM10 5.5v7.063m.177 2.26c.097.098.097.256 0 .354-.098.097-.256.097-.354 0-.097-.098-.097-.256 0-.354.098-.097.256-.097.354 0"
                  transform="translate(-118 -440) translate(118 430) translate(0 10) translate(2 2)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default WarningIcon;
