import React from "react";

const FrontendIcon = (props: React.HTMLProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#00B0CA" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <path d="M9.5 5.5a1 1 0 0 1 1 1v1a1 1 0 0 1-2 0v-1a1 1 0 0 1 1-1zm4 0a1 1 0 0 1 1 1v1a1 1 0 0 1-2 0v-1a1 1 0 0 1 1-1zm.625 10H3.921c-.785 0-1.421-.671-1.421-1.5V4c0-.829.636-1.5 1.421-1.5H19.08c.785 0 1.421.671 1.421 1.5v6.226m-5.045.274h5.09c.527 0 .955.389.955.875v9.262c0 .48-.427.87-.955.87h-5.09c-.527 0-.955-.39-.955-.87v-9.262c0-.486.428-.875.955-.875z" />
        <path d="M6 5.5 4.5 7 6 8.5m11-3L18.5 7 17 8.5m-4.079 5H10.08m7.145 6h1.619" />
      </g>
    </svg>
  );
};

export default FrontendIcon;
