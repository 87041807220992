import {
  MultipleOptionsDropdown,
  PlusIcon,
  PrimaryButton,
  SecondaryPageTitle,
  TextInput,
  TrashIcon,
  useAlert,
} from "@wit/mpesa-ui-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import FormSection, {
  FormSectionRow,
} from "@wit/mpesa-ui-components/lib/components/form-section/form-section.component";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikErrors, FormikHelpers } from "formik";
import { TFunction } from "i18next";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { array, object, string } from "yup";
import { RoutesEnum } from "../../../../routes/routes.constants";
import { formatBytes, getExternalServicePermissions } from "../../../../shared/shared.utils";
import { ServiceManagerTabNumber } from "../../pages/ant-service-manager.page";
import AntExternalServicesApi from "../ant-external-services.api";
import {
  ICreateAntExternalServiceRequest,
  ISingleAntExternalServiceRequest,
  AntPermissionsRoleEnum,
} from "./ant-external-service-add.interface";

/** function externalservice schema */
const addExternalServiceValidationSchema = (t: TFunction) => {
  return object().shape({
    externalService: array().of(
      object().shape({
        externalServiceId: string()
          .matches(/^\S*$/, t("commons.emptySpaces"))
          .required(t("commons.mandatoryField")),
        url: string().required(t("commons.mandatoryField")),
      }),
    ),
  });
};

/** page add external service page */
const AntExternalServiceAddPage = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const [permissionsSelected, setpermissionsSelected] = useState<string[]>([]);
  const [keyFile, setFile] = React.useState<any>();
  const [keyContent, setKeyContent] = useState<string | undefined>();
  const [fileError, setFileError] = React.useState("");
  const themeContext = useContext(ThemeContext);
  const MAX_FILE_SIZE = 1000000;

  const toggleOption = (opt: SharedDropdownOption) => {
    if (isOptionSelected(opt)) {
      setpermissionsSelected(permissionsSelected.filter(c => c !== opt.key));
    } else {
      setpermissionsSelected([...permissionsSelected, opt.key]);
    }
  };

  const isOptionSelected = (opt: SharedDropdownOption) => {
    return permissionsSelected.includes(opt.key);
  };

  const resetDropdown = () => {
    setpermissionsSelected([]);
  };

  const getDropdownLabel = () => {
    if (permissionsSelected.length === 2) {
      return (
        <div style={{ color: "#333333" }}>
          {t("pages.antExternalServices.configurations.columns.permissionPIN")},{" "}
          {t("pages.antExternalServices.configurations.columns.permissionIDENTITY")}
        </div>
      );
    } else if (permissionsSelected.length === 1) {
      if (permissionsSelected.includes(AntPermissionsRoleEnum.PIN)) {
        return (
          <div style={{ color: "#333333" }}>{t("pages.antExternalServices.configurations.columns.permissionPIN")}</div>
        );
      } else {
        return (
          <div style={{ color: "#333333" }}>
            {t("pages.antExternalServices.configurations.columns.permissionIDENTITY")}
          </div>
        );
      }
    } else {
      return <div>{t("pages.antExternalServices.configurations.columns.choosePermission")}</div>;
    }
  };

  /** function to handle file */
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file: File = e.target.files[0];
    if (file.size > MAX_FILE_SIZE) {
      setMaxSizeFileError();
    } else {
      if (file.name.match(/.(pem)$/i)) {
        setFileError("");
        setFile(file);
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onloadend = function() {
          if (reader.result) {
            setKeyContent(reader.result.toString());
          }
        };
      } else {
        setFileError(t("pages.antExternalServices.addPage.FileErrorType"));
      }
    }
  };

  /** function to removefile */
  const removeFile = () => {
    setFile(null);
    setKeyContent(undefined);
  };

  /** function setMaSizeError */
  const setMaxSizeFileError = () => {
    setFileError(t("pages.antExternalServices.addPage.MaxSizeFileError"));
  };

  const getRows = (
    externalService: ISingleAntExternalServiceRequest,
    errors: FormikErrors<ICreateAntExternalServiceRequest>,
    setFieldValue: any,
    idx: number,
  ): FormSectionRow[] => {
    return [
      {
        label: (
          <TitleLabel mandatory={true}>{t("pages.antExternalServices.addPage.getRows.serviceIdentifier")}</TitleLabel>
        ),
        displayComponent: null,
        editingComponent: (
          <TextInput
            id={"externalServiceId"}
            onChange={e => setFieldValue(`externalService[${idx}].externalServiceId`, e.target.value)}
            error={
              errors.externalService && errors.externalService[idx]
                ? (errors.externalService[idx] as ISingleAntExternalServiceRequest).externalServiceId
                : undefined
            }
            placeholder={t("pages.antExternalServices.addPage.getRows.placeholderService")}
            value={externalService.externalServiceId}
            required={true}
          />
        ),
      },
      {
        label: <TitleLabel mandatory={true}>{t("pages.antExternalServices.addPage.getRows.url")}</TitleLabel>,
        displayComponent: null,
        editingComponent: (
          <TextInput
            id={"url"}
            onChange={e => setFieldValue(`externalService[${idx}].url`, e.target.value)}
            error={
              errors.externalService && errors.externalService[idx]
                ? (errors.externalService[idx] as ISingleAntExternalServiceRequest).url
                : undefined
            }
            placeholder={t("pages.antExternalServices.addPage.getRows.placeholderUrl")}
            value={externalService.url}
            required={true}
          />
        ),
      },
      {
        label: t("pages.antExternalServices.addPage.getRows.permissions"),
        displayComponent: null,
        editingComponent: (
          <>
            <div id={"permissions"}>
              <MultipleOptionsDropdown
                options={getExternalServicePermissions()}
                dropdownType={DropdownType.RECTANGULAR_NORMAL}
                toggleOption={opt => toggleOption(opt)}
                clearAllFilters={() => resetDropdown()}
                isOptionSelected={opt => isOptionSelected(opt)}
                label={getDropdownLabel()}
                hasValue={!!externalService.permissions}
              />
            </div>
          </>
        ),
      },
      {
        label: t("pages.antExternalServices.addPage.getRows.RSApublicKey"),
        displayComponent: null,
        editingComponent: (
          <div>
            {fileError ? <FormError>{fileError}</FormError> : null}
            <div>
              {!keyFile ? (
                <TextButton style={{ marginRight: "auto" }} id="upload-pem-button">
                  <FileIconContainer style={{ marginRight: "5px" }} size={16} color={themeContext.palette.vodafoneRed}>
                    <PlusIcon />
                  </FileIconContainer>
                  {t("pages.antExternalServices.addPage.getRows.placeholderRSApublicKey")}
                  <input type="file" onChange={handleFileChange} accept=".pem" />
                </TextButton>
              ) : null}
              {keyFile ? (
                <FileNameContainer>
                  <FileNameText>{keyFile.name}</FileNameText>
                  <FileSizeText>{formatBytes(keyFile.size)}</FileSizeText>
                  <FileIconContainer size={16} color={themeContext.palette.vodafoneRed}>
                    <TrashIcon onClick={removeFile} style={{ marginLeft: "5px", cursor: "pointer" }} />
                  </FileIconContainer>
                </FileNameContainer>
              ) : null}
            </div>
          </div>
        ),
      },
    ];
  };

  /** function to add external service request */
  const addExternalServices = (
    values: ICreateAntExternalServiceRequest,
    actions: FormikHelpers<ICreateAntExternalServiceRequest>,
  ) => {
    values.externalService.some(element => {
      if (element.permissions === undefined && permissionsSelected.length > 0) {
        element.permissions = [];
        if (permissionsSelected.includes(AntPermissionsRoleEnum.PIN)) {
          element.permissions.push(AntPermissionsRoleEnum.PIN);
        }
        if (permissionsSelected.includes(AntPermissionsRoleEnum.IDENTITY)) {
          element.permissions.push(AntPermissionsRoleEnum.IDENTITY);
        }
      }
    });

    if (keyContent !== undefined) {
      values.externalService[0].rsaPublicKey = keyContent;
    }

    actions.setSubmitting(true);
    AntExternalServicesApi.methods
      .createAntExternalService(values.externalService)
      .finally(() => actions.setSubmitting(false))
      .then(
        res => {
          setAlertProps({
            type: AlertTypeEnum.SUCCESS,
            title: t("pages.antExternalServices.addPage.addExternalService.title"),
          });
          showAlert();
          history.push(`${RoutesEnum.ANT_SERVICE_MANAGER}?tabIdx=${ServiceManagerTabNumber.EXTERNAL_SERVICES}`);
        },
        err => {
          setAlertProps({
            type: AlertTypeEnum.ERROR,
            title:
              err && err.data && err.data.status && err.data.status.message
                ? err.data.status.message
                : t("pages.antExternalServices.addPage.addExternalService.error"),
          });
          showAlert();
        },
      );
  };

  return (
    <>
      <SecondaryPageTitle
        title={t("pages.antExternalServices.addPage.title")}
        goBackFn={() =>
          history.push(`${RoutesEnum.ANT_SERVICE_MANAGER}?tabIdx=${ServiceManagerTabNumber.EXTERNAL_SERVICES}`)
        }
        displayInitialsCircle={false}
      />
      <Formik
        onSubmit={addExternalServices}
        initialValues={
          {
            externalService: [
              {
                externalServiceId: "",
                url: "",
              },
            ],
          } as ICreateAntExternalServiceRequest
        }
        validateOnBlur={true}
        validateOnChange={false}
        validationSchema={addExternalServiceValidationSchema(t)}
        render={({ handleSubmit, isSubmitting, values, setFieldValue, errors }) => (
          <>
            <FormContainer onSubmit={handleSubmit}>
              {values.externalService.map((es, idx) => (
                <FormSectionCard key={idx}>
                  <FormSection
                    title={
                      <FormSectionCardTitle>
                        <div>{t("pages.antExternalServices.addPage.description")}</div>
                      </FormSectionCardTitle>
                    }
                    rows={getRows(es, errors, setFieldValue, idx)}
                    isEditing={true}
                  />
                </FormSectionCard>
              ))}
              <ButtonRow>
                <ButtonContainer id="save-changes-button">
                  <PrimaryButton
                    redTheme={true}
                    type="submit"
                    disabled={isSubmitting}
                    titleLabel={t("pages.antExternalServices.addPage.saveChanges")}
                    loading={isSubmitting}
                  />
                </ButtonContainer>
              </ButtonRow>
            </FormContainer>
          </>
        )}
      />
    </>
  );
};

export default AntExternalServiceAddPage;

const FormContainer = styled("form")`
  padding-top: 175px;
  margin-left: 245px;
  margin-right: 245px;

  #permissions {
    & > button {
      font-family: Vodafone Rg;
      font-size: 16px;
      color: #999;
    }
  }
`;

const FormSectionCardTitle = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > span {
    font-family: Vodafone Rg;
    font-size: 22px;
    color: ${props => props.theme.palette.darkGrey};
  }
`;

const FormSectionCard = styled("div")`
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  padding: 18px 24px;

  > div > div > span > div > div > button {
    display: none;
  }

  :hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);

    > div > div > span > div > div > button {
      display: flex;
    }
  }

  :not(:last-child) {
    margin-bottom: 24px;
  }

  > div > div {
    border-bottom: none !important;
  }

  > div:first-child > div:first-child > span:first-child {
    width: 100%;
  }
`;
const ButtonRow = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;

  svg {
    stroke: ${props => props.theme.palette.vodafoneRed};
  }
`;

const ButtonContainer = styled("div")`
  width: fit-content;
  margin-left: auto;
`;

const TextButton = styled("label")`
  display: flex;
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.darkGrey};
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
  > input {
    display: none;
  }
`;

export const FileIconContainer = styled("div")<{ color: string; size: number }>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  svg {
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    stroke: ${props => props.color};
  }
`;

const FileNameContainer = styled("div")`
  display: flex;
`;

const FileSizeText = styled("span")`
  color: ${props => props.theme.palette.grey};
  font-family: Vodafone Rg;
  font-size: 16px;
  margin-left: 3px;
`;

const FileNameText = styled("span")`
  color: ${props => props.theme.palette.grey};
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.greyDarkest};
  margin-left: 8px;
`;

const TitleLabel = styled("div")<{ mandatory: boolean }>`
  min-height: 21px;
  font-family: Vodafone Rg;
  font-weight: bold;
  line-height: 21px;
  font-size: 16px;
  color: #999999;
  margin-bottom: 8px;
  width: fit-content;
  display: inline-flex;
  ${props =>
    props.mandatory ? "::after {content: ' *'; color: #ff0000; font-weight: 400; padding-left: 2px;}" : null};
`;

const FormError = styled("span")`
  height: 19px;
  color: ${props => props.theme.palette.errorRed};
  font-family: Vodafone Rg;
  font-size: 14px;
  text-align: right;
  float: right;
  font-weight: 400;
`;
