import React from "react";
import { IResultsEmptyComponentProps } from "../feedback-and-problems.model";
import EmptyResult from "../../../../shared/components/empty-result.component";
import EmptySearchResult from "../../../../shared/components/empty-search-result.component";
import {
  feedbacksStringPath,
  problemsStringPath,
  isFeedbackTabActive,
  isProblemReportsTabActive,
  customerStringPath,
  isCustomerSurveyTabActive,
} from "../feedback-and-problems.utils";

const ResultsEmptyComponent = ({ sectionId, translations, isSearchResult }: IResultsEmptyComponentProps) => {
  if (!isSearchResult) {
    if (isFeedbackTabActive(sectionId)) {
      return <EmptyResult title={translations(`${feedbacksStringPath}.resultArea.noResult`)} />;
    }
    if (isProblemReportsTabActive(sectionId)) {
      return <EmptyResult title={translations(`${problemsStringPath}.resultArea.noResult`)} />;
    }
    if (isCustomerSurveyTabActive(sectionId)) {
      return <EmptyResult title={translations(`${customerStringPath}.resultArea.noResult`)} />;
    }
  }

  return (
    <EmptySearchResult
      title={
        isFeedbackTabActive(sectionId)
          ? translations(`${feedbacksStringPath}.resultArea.noSearchResult`)
          : isProblemReportsTabActive(sectionId)
          ? translations(`${problemsStringPath}.resultArea.noSearchResult`)
          : translations(`${customerStringPath}.resultArea.noSearchResult`)
      }
    />
  );
};

export default ResultsEmptyComponent;
