import React, { useState } from "react";
import DropzoneBackground from "../../announcements/components/dropzone-background.component";
import { INotification, SetFieldValueType } from "../push-notifications.modal";
import { DropzoneTitle } from "../../safaricom-service-manager/service-details/pages/service-manager-service-details.styled";
import { useTranslation } from "react-i18next";
import { CancelIcon, useAlert } from "@wit/mpesa-ui-components";
import styled from "styled-components";
import { formatBytes } from "../../../shared/shared.utils";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { FormikErrors } from "formik";

const constValues = {
  MIN_WIDTH: 160,
  MIN_HEIGHT: 120,
  MAX_FILE_SIZE: 250000,
  VALID_RATIO: 4 / 3, // width : height
  RATIO_STRING: "4:3",
};

interface IProps {
  values: INotification;
  notificationValues: INotification;
  errors: FormikErrors<INotification>;
  isEditing: boolean;
  setFieldValue: SetFieldValueType;
  onChangeValues: (value: INotification) => void;
}

/**
 * React component to render notification image field and its corresponding fields.
 * @returns {React.Component}
 */
const UploadImageComponent = ({
  values,
  notificationValues,
  errors,
  isEditing,
  setFieldValue,
  onChangeValues,
}: IProps) => {
  const [t] = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showAlert, __, setAlertProps] = useAlert();

  const [imageInfo, setImageInfo] = useState<{ name: string; size: number } | undefined>();

  /**
   * Check if the image dimensions are valid
   * @function validateImageForOnLoad
   * @param {number} width Image width
   * @param {number} height Image height
   * @param {number} imageAspectRatio Image aspect ratio
   * @param {boolean} isNewImage true if the new image field exist
   * @returns {boolean}
   */
  const validateImageForOnLoad = (width: number, height: number, imageAspectRatio: number): boolean => {
    let isImageValid = false;
    if (width >= constValues.MIN_WIDTH && height >= constValues.MIN_HEIGHT) {
      if (imageAspectRatio !== constValues.VALID_RATIO) {
        setAlertProps({
          type: AlertTypeEnum.ERROR,
          title: t("pages.announcements.detailPage.rows.imageError"),
          content: t("pages.announcements.detailPage.rows.aspectRatioError", { ratio: constValues.RATIO_STRING }),
        });
        showAlert();
      } else {
        isImageValid = true;
      }
    } else {
      setAlertProps({
        type: AlertTypeEnum.ERROR,
        title: t("pages.announcements.detailPage.rows.imageError"),
        content: t("pages.announcements.detailPage.rows.minDimensionSizeAnnouncementCard", {
          width: constValues.MIN_WIDTH,
          height: constValues.MIN_HEIGHT,
        }),
      });
      showAlert();
    }

    return isImageValid;
  };

  /**
   *This handles the on drop (uplaod) image event
   * @param acceptedImage
   * @param {IAnnouncement} values Announcement form values
   * @param {Function} setFieldValue
   */
  const onDropImage = (acceptedImage: File[], values: INotification, setFieldValue: SetFieldValueType) => {
    if (!acceptedImage[0]) {
      setAlertProps({
        type: AlertTypeEnum.ERROR,
        title: t("pages.announcements.detailPage.rows.imageError"),
      });
      return showAlert();
    }

    if (acceptedImage[0] && acceptedImage[0].size > 250000) {
      setAlertProps({
        type: AlertTypeEnum.ERROR,
        title: t("pages.announcements.detailPage.rows.imageError"),
        content: t("pages.announcements.detailPage.rows.maxSizeErrorAnnouncementCard", {
          size: constValues.MAX_FILE_SIZE / 1000,
        }),
      });
      return showAlert();
    }

    const file = acceptedImage[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = () => {
      setAlertProps({
        type: AlertTypeEnum.ERROR,
        title: t("pages.announcements.detailPage.rows.imageError"),
      });
      return showAlert();
    };

    reader.onloadend = e => {
      const img = new Image();
      img.src = (e.target?.result as string) ?? "";

      img.onload = () => {
        const { naturalWidth, width, naturalHeight, height } = img;
        const isImageValid = validateImageForOnLoad(naturalWidth || width, naturalHeight || height, width / height);
        if (isImageValid) {
          setFieldValue("backgroundImage", reader.result);
          const imageInfo = { name: file.name, size: file.size };
          setImageInfo(imageInfo);

          onChangeValues({ ...notificationValues, backgroundImage: reader.result as string });
        }
      };
    };
  };

  /**
   * function to clear image selected
   */
  const clearImage = (setFieldValue: SetFieldValueType) => {
    setFieldValue("backgroundImage", "");
    onChangeValues({ ...notificationValues, backgroundImage: "" });
    setImageInfo(undefined);
  };

  /**
   * Render image name
   * @param values - Announcement form values
   * @param thumbnailImageField - Thumbnail image field
   * @returns {string} image name and extension
   */
  const renderImageNameAndExtension = (values: INotification): string => {
    return imageInfo
      ? imageInfo.name
      : `${t(`pages.announcements.detailPage.rows.thumbnail`)}.${
          values.backgroundImage
            ? values.backgroundImage
                ?.split(",")[0]
                .split("/")[1]
                .split(";")[0]
            : "png"
        }`;
  };

  /**
   * Render image size
   * @param values - Announcement form values
   * @param thumbnailImageField - Thumbnail image field
   * @returns {string} image size
   */
  const renderImageSize = (values: INotification): string => {
    let size = 0;
    if (imageInfo) {
      size = imageInfo?.size || 0;
    } else if (values.backgroundImage) {
      size = values.backgroundImage?.length || 0;
    } else if (notificationValues.backgroundImage) {
      size = notificationValues.backgroundImage?.length || 0;
    }

    return formatBytes(size);
  };
  return (
    <section>
      {!values.backgroundImage ? (
        <>
          <DropzoneTitle mandatory={false}>Image</DropzoneTitle>
          <DropzoneBackground
            inputName={"thumbnailImageField"}
            onDrop={(file: File[]) => onDropImage(file, values, setFieldValue)}
            accept={".jpg, .jpeg, .png"}
            multiple={false}
            dropText={t("pages.announcements.detailPage.rows.dropImage")}
            releaseText={t("pages.announcements.detailPage.rows.uploadImage")}
            error={!!errors.backgroundImage}
          />
          <ImageInfo isEditing={isEditing} hasValue={false}>
            <ImageInfoTitle>{t("pages.announcements.detailPage.rows.recommendations")}</ImageInfoTitle>
            <ImageInfoDescription>
              {t("pages.announcements.detailPage.rows.imageRequirementsAnnouncementCard", {
                width: constValues.MIN_WIDTH,
                height: constValues.MIN_HEIGHT,
                ratio: constValues.RATIO_STRING,
                size: constValues.MAX_FILE_SIZE / 1000,
              })}
            </ImageInfoDescription>
          </ImageInfo>
        </>
      ) : (
        <ImageDiv>
          <ImageWrapper backgroundImage={values.backgroundImage} isEditing={isEditing}>
            <span onClick={() => clearImage(setFieldValue)}>
              <CancelIcon />
            </span>
          </ImageWrapper>
          <DescriptionImage isEditing={isEditing}>
            <NameImage>{renderImageNameAndExtension(values)}</NameImage>
            <SizeImage>{renderImageSize(values)}</SizeImage>
          </DescriptionImage>
        </ImageDiv>
      )}
    </section>
  );
};

export default UploadImageComponent;

const ImageInfo = styled("div")<{ isEditing: boolean; hasValue: boolean }>`
  display: ${(props: { isEditing: boolean; hasValue: boolean }) =>
    !props.isEditing || props.hasValue ? "none" : "flex"};
  flex-direction: column;
`;

const ImageInfoTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${(props: { theme: { palette: { midGrey: string } } }) => props.theme.palette.midGrey};
`;

const ImageInfoDescription = styled("span")`
  font-family: Vodafone Rg;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${(props: { theme: { palette: { midGrey: string } } }) => props.theme.palette.midGrey};
`;

const ImageDiv = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ImageWrapper = styled("div")<{
  backgroundImage: string;
  isEditing: boolean;
}>`
    position: relative;
    width: ${(props: { isEditing: boolean }) => (props.isEditing ? "60" : "90")}px;
    height: ${(props: { isEditing: boolean }) => (props.isEditing ? "40" : "60")}px;
    background-image: url("${props => props.backgroundImage}");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 3px;
    flex: ${(props: { isEditing: boolean }) => {
      return props.isEditing ? "0.08" : "0.125";
    }};
    >span{
      position: absolute;
      background: #aaa;
      z-index: 0;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      right: -0.5vw;
      top: -0.5vh;
      display:${(props: { isEditing: boolean }) => (props.isEditing ? "flex" : "none")};
      justify-content: center;
      align-items: center;
      cursor: pointer;
      svg{
        stroke: #fff;
        width: 15px;
        height: 15px;
      }
    }
`;

const DescriptionImage = styled("div")<{
  isEditing: boolean;
}>`
  flex: ${(props: { isEditing: boolean }) => {
    return props.isEditing ? "0.88" : "0.835";
  }};
  align-self: center;
`;
const NameImage = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 4px;
`;

const SizeImage = styled("div")`
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #afafaf;
`;
