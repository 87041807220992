import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import DocumentIcon from "../../shared/icons/document.icon";

const EmptyResult = ({ title }: { title?: string }) => {
  const [t] = useTranslation();

  return (
    <NoResult>
      <ImageIcon>
        <DocumentIcon />
      </ImageIcon>
      <TextLabel>{title}</TextLabel>
    </NoResult>
  );
};

export default EmptyResult;

const NoResult = styled.div`
  margin-top: 192px;
  margin-bottom: 192px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const ImageIcon = styled("span")`
  margin-bottom: 24px;
  svg {
    width: 140px;
    height: 140px;
  }
`;

const TextLabel = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.midGrey};
`;
