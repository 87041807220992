import {
  CheckIcon,
  FastActionButton,
  ModalTypeEnum,
  PageTitle,
  PrimaryButton,
  UndoIcon,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import { Breadcrumb } from "@wit/mpesa-ui-components/lib/types";

import { TFunction } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { RoutesEnum } from "../../../../routes/routes.constants";
import LoadingIconWhite from "../../../../shared/icons/loading-white.icon";
import { VersionControlButtonsContainer, VersionControlButtonsRow } from "../../../../shared/responsive-ui.styled";
import { Column, LoadingText, PageContainer, Row } from "../../../../shared/shared.styled";
import { getDefaultTranslation } from "../../../../shared/shared.utils";
import BundlesApi from "../bundles.api";
import { IBundleType } from "../bundles.model";
import { BundlesActions } from "../bundles.store";
import BundleDetailsComponent from "./components/bundle-details.component";
import BundleLastVersionModal from "./components/bundle-last-version.modal";
import MiddlewareSubBundlesList from "./components/middleware-sub-bundles-list.component";
import PublishBundle from "./components/publish-bundle.modal";
import SubBundlesList from "./components/sub-bundles-list.component";

/**
 * Bundle details page
 */
const BundleDetails = () => {
  const [t] = useTranslation();
  const { id } = useParams<any>();
  const history = useHistory();
  const [bundleDetails, setBundleDetails] = React.useState<IBundleType>();
  const [showAlert, , setAlertProps] = useAlert();
  const [isLoading, setIsLoading] = React.useState<any>();
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [lastVersionLoading, setLastVersionLoading] = React.useState(false);
  const [revertLoading, setRevertLoading] = useState(false);
  const [previousVersion, setPreviousVersion] = useState<any>();
  const dispatch = useDispatch();
  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );

  /**
   * Gets the bundle details data
   */
  const getBundleDetails = () => {
    setIsLoading(true);
    BundlesApi.methods
      .getBundleDetails(id)
      .then(
        res => {
          setBundleDetails(res.data);
        },
        () => {
          setAlertProps({
            title: t("pages.bundleDetails.getBundleDetailsError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Breadcrumbs
   * */
  const getBreadcrumbs = (t: TFunction, bundleName: string): Breadcrumb[] => {
    return [
      {
        label: t("pages.bundleDetails.configuration"),
        url: RoutesEnum.CONFIGS,
      },
      {
        label: t("pages.bundleDetails.bundles"),
        url: RoutesEnum.BUNDLES,
      },
      {
        label: bundleName,
        url: "",
      },
    ];
  };

  /**
   * Get configuration fields translations
   * */
  const getConfigurationFieldsTranslations = () => {
    BundlesApi.methods.getConfigurationFields().then(res => {
      getBundleDetails();
      dispatch(BundlesActions.creators.getConfigurationFields(res.data));
    });
  };

  /**
   * Revert to last version handler
   * */
  const revertToLastVersion = () => {
    const confirmationProps = {
      title: t("pages.bundleDetails.confirmRevert"),
      description: t("pages.bundleDetails.confirmRevertMessage"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      secondaryAction: () => hideConfirmationModal(),
      primaryAction: () => {
        setRevertLoading(true);
        setConfirmationProps({ ...confirmationProps, isLoading: true });

        BundlesApi.methods
          .revertBundle(id)
          .then(
            res => {
              setAlertProps({
                title: t("pages.bundleDetails.revertSuccess"),
                type: AlertTypeEnum.SUCCESS,
              });
              showAlert();
              hideConfirmationModal();
              getBundleDetails();
            },
            () => {
              setAlertProps({
                title: t("pages.bundleDetails.revertError"),
                type: AlertTypeEnum.ERROR,
              });
              showAlert();
            },
          )
          .finally(() => {
            setRevertLoading(false);
            setConfirmationProps({ ...confirmationProps, isLoading: false });
          });
      },
    };

    setConfirmationProps(confirmationProps as BaseModalProps);
    showConfirmationModal();
  };

  /**
   * On load effect
   * */
  React.useEffect(() => {
    getConfigurationFieldsTranslations();
  }, [dispatch]);

  /**
   * Get previous version
   * */
  const getPreviousVersion = (cb: Function) => {
    BundlesApi.methods.getPreviousVersion(id).then(
      res => {
        setPreviousVersion(res.data);
        cb();
      },
      () => {
        setAlertProps({
          title: t("pages.bundleDetails.getPreviousVersionError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * Check last version modal
   * */
  const _hideCheckLastVersionModal = () => {
    hideBundleLastVersionModal();
  };
  const [showBundleLastVersion, hideBundleLastVersionModal, bundleLastVersionModalProps] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <BundleLastVersionModal bundle={previousVersion as IBundleType} hideModal={_hideCheckLastVersionModal} />,
  );
  React.useEffect(() => {
    bundleLastVersionModalProps({ modalStyles: { width: 600 } });
  }, [bundleLastVersionModalProps]);

  /**
   * Publish modal
   * */
  const _hidePublishModal = () => {
    hidePublishModal();
  };

  /**
   * Hide publish modal
   */
  const _publishChanges = () => {
    setLastVersionLoading(true);
    hidePublishModal();
    publishChanges();
  };
  const [showPublishModal, hidePublishModal, publishModalProps] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <PublishBundle
      bundleDetails={bundleDetails as IBundleType}
      lastBundleDetails={previousVersion}
      hideModal={_hidePublishModal}
      isLoading={approvalLoading}
      publishChanges={_publishChanges}
    />,
  );
  React.useEffect(() => {
    publishModalProps({ modalStyles: { width: 825, padding: 0 } });
  }, [publishModalProps]);

  /**
   * Publish changes handler
   * */
  const publishChanges = () => {
    setApprovalLoading(true);
    BundlesApi.methods
      .publish(id)
      .then(
        res => {
          setAlertProps({
            title: t("pages.bundleDetails.publishSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          getBundleDetails();
        },
        err => {
          setAlertProps({
            title: t("pages.bundleDetails.publishError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        /**
         * Reset all publishing loading states
         */
        setLastVersionLoading(false);
        setApprovalLoading(false);
      });
  };

  /**
   * Check last version handler
   * */
  const checkLastVersion = () => {
    getPreviousVersion(() => {
      showBundleLastVersion();
    });
  };

  /**
   * Show publish modal
   * */
  const showPublishConfirmationModal = () => {
    setLastVersionLoading(true);
    getPreviousVersion(() => {
      showPublishModal();
      setLastVersionLoading(false);
    });
  };
  return (
    <PageContainer>
      {bundleDetails ? (
        <PageTitle
          breadcrumbs={getBreadcrumbs(t, getDefaultTranslation(bundleDetails.name.translations))}
          title={getDefaultTranslation(bundleDetails.name.translations)}
          navigateFunction={history.push}
          goBackFn={() => history.push(RoutesEnum.BUNDLES)}
          separatorStyle={{ display: "none" }}
          titleContainerStyle={{ marginTop: "13px", marginBottom: "20px", height: "60px" }}
          rightComponentContainerStyle={{ display: "flex", alignContent: "center", height: "100%", marginRight: "0px" }}
          rightComponent={
            <>
              <ButtonContainer style={{ position: "relative", top: "10px" }}>
                <PrimaryButton
                  id="publish-changes"
                  disabled={!bundleDetails.hasUnpublishedChanges || lastVersionLoading}
                  titleLabel={t("pages.bundleDetails.publishChanges")}
                  type="submit"
                  redTheme={true}
                  style={{ width: "fit-content" }}
                  onClick={showPublishConfirmationModal}
                />
              </ButtonContainer>
            </>
          }
        />
      ) : null}
      {isLoading ? <LoadingText>{t("commons.loadingResults")}</LoadingText> : null}
      {bundleDetails ? (
        <BundleTypeDetailsContainer>
          <VersionControlButtonsContainer>
            <BundleTypeDetailsText>{t("pages.bundleDetails.bundleTypeDetails")}</BundleTypeDetailsText>
            {bundleDetails.hasUnpublishedChanges && bundleDetails.hasPreviousVersion ? (
              <VersionControlButtonsRow>
                <Column style={{ flexDirection: "row" }}>
                  <div>
                    <FastActionButton
                      label={t("pages.bundleDetails.checkLastVersion")}
                      onClick={checkLastVersion}
                      iconComponent={
                        <FastIconWrapper color={styleTheme.palette.successGreen}>
                          <CheckIcon />
                        </FastIconWrapper>
                      }
                    ></FastActionButton>
                  </div>
                  <div style={{ marginLeft: "18px" }}>
                    <FastActionButton
                      label={
                        !revertLoading ? t("pages.bundleDetails.revertChangesToLastVersion") : t("commons.loading")
                      }
                      onClick={revertToLastVersion}
                      iconComponent={
                        <FastIconWrapper color={styleTheme.palette.midGrey}>
                          {revertLoading && <LoadingIconWhite strokeColor={styleTheme.palette.midGrey} />}
                          {!revertLoading && <UndoIcon />}
                        </FastIconWrapper>
                      }
                    />
                  </div>
                </Column>
              </VersionControlButtonsRow>
            ) : null}
          </VersionControlButtonsContainer>

          <Row style={{ marginTop: "24px" }}>
            <BundleDetailsComponent bundle={bundleDetails} />
          </Row>
          {bundleDetails.isExternalProvider ? (
            <MiddlewareSubBundlesList bundle={bundleDetails} reloadBundleDetails={getBundleDetails} />
          ) : (
            <SubBundlesList bundle={bundleDetails} reloadBundleDetails={getBundleDetails} />
          )}
        </BundleTypeDetailsContainer>
      ) : null}
    </PageContainer>
  );
};

export default BundleDetails;

const ButtonContainer = styled("div")`
  width: fit-content;
`;
const BundleTypeDetailsContainer = styled("div")`
  margin-bottom: 34px;
  display: flex;
  flex-direction: column;
`;
const BundleTypeDetailsText = styled("span")`
  font-weight: bold;
  font-family: Vodafone Rg;
  font-size: 18px;
  color: ${props => props.theme.palette.darkGrey};
`;
const FastIconWrapper = styled("span")<{ color: string }>`
  width: 16px;
  height: 16px;
  stroke: ${props => props.color};
  svg {
    width: inherit;
    height: inherit;
  }
`;
