import { IServiceManagerService } from "../../shared/models/service-manager.model";
import { IPayloadAction } from "../../shared/shared.interfaces";
import produce from "immer";
import { AuthenticationActions } from "../authentication/authentication.store";
import { ServiceDetailsActions } from "../service-details/service-details.store";
import { ICategory, IIconCategory } from "./pages/categories/categories.model";
import { IAllowedScope, IIconAllowedScope } from "./allowed-scopes/allowed-scopes.model";

export const ServiceManagerActions = {
  types: {
    FETCHING_SM_SERVICES: "FETCHING_SM_SERVICES",
    FETCH_SM_SERVICES_SUCCESS: "FETCH_SM_SERVICES_SUCCESS",
    FETCH_SM_SERVICES_ERROR: "FETCH_SM_SERVICES_ERROR",
    FETCH_SM_SERVICES_LEAVE: "FETCH_SM_SERVICES_LEAVE",
    UPDATE_SECTION_MANAGER_ORDER_LIST: "UPDATE_SECTION_MANAGER_ORDER_LIST",
    UPDATE_SECTION_MANAGER_PROMOTED_ORDER_LIST: "UPDATE_SECTION_MANAGER_PROMOTED_ORDER_LIST",
    FETCH_CATEGORIES: "FETCH_CATEGORIES",
    SET_CATEGORY: "SET_CATEGORY",
    FETCHING_ICONS_CATEGORIES: "FETCHING_ICONS_CATEGORIES",
    FETCH_ALLOWED_SCOPES: "FETCH_ALLOWED_SCOPES",
    FETCH_ALLOWED_SCOPES_ICONS: "FETCH_ALLOWED_SCOPES_ICONS",
    SET_ALLOWED_SCOPE: "SET_ALLOWED_SCOPE",
  },
  creators: {
    fetchAllowedScopes: (allowedScopes: IAllowedScope[]) => ({
      type: ServiceManagerActions.types.FETCH_ALLOWED_SCOPES,
      payload: {
        allowedScopes,
      },
    }),
    fetchAllowedScopesIcons: (allowedScopesIcons: IIconAllowedScope[]) => ({
      type: ServiceManagerActions.types.FETCH_ALLOWED_SCOPES_ICONS,
      payload: {
        allowedScopesIcons,
      },
    }),
    setAllowedScope: (allowedScope: IAllowedScope) => ({
      type: ServiceManagerActions.types.SET_ALLOWED_SCOPE,
      payload: {
        allowedScope,
      },
    }),
    fetchCategories: (categories: ICategory[]) => ({
      type: ServiceManagerActions.types.FETCH_CATEGORIES,
      payload: {
        categories,
      },
    }),
    setCategory: (category: ICategory) => ({
      type: ServiceManagerActions.types.SET_CATEGORY,
      payload: {
        category,
      },
    }),
    fectionsIconsCategories: (icons: IIconCategory[]) => ({
      type: ServiceManagerActions.types.FETCHING_ICONS_CATEGORIES,
      payload: {
        icons,
      },
    }),
    fetchingServicesAction: () => ({
      type: ServiceManagerActions.types.FETCHING_SM_SERVICES,
    }),
    fetchServicesSuccessAction: (services: IServiceManagerService[]) => ({
      type: ServiceManagerActions.types.FETCH_SM_SERVICES_SUCCESS,
      payload: {
        services,
      },
    }),
    fetchingServicesLeaveAction: () => ({
      type: ServiceManagerActions.types.FETCH_SM_SERVICES_LEAVE,
    }),
    updateSectionManagerOrderList: () => ({
      type: ServiceManagerActions.types.UPDATE_SECTION_MANAGER_ORDER_LIST,
    }),
    updateSectionManagerPromotedOrderList: () => ({
      type: ServiceManagerActions.types.UPDATE_SECTION_MANAGER_PROMOTED_ORDER_LIST,
    }),
  },
};

export interface IServiceManagerReducerInterface {
  services: IServiceManagerService[];
  isLoadingServices: boolean;
  categories: ICategory[];
  category: ICategory;
  icons: IIconCategory[];
  allowedScopes: IAllowedScope[];
  allowedScope: IAllowedScope;
  allowedScopesIcons: IIconAllowedScope[];
}

const initialState: IServiceManagerReducerInterface = {
  services: [],
  isLoadingServices: false,
  categories: [] as ICategory[],
  category: {} as ICategory,
  icons: [] as IIconCategory[],
  allowedScopes: [] as IAllowedScope[],
  allowedScope: {} as IAllowedScope,
  allowedScopesIcons: [] as IIconAllowedScope[],
};

export const serviceManagerReducer = produce((draft: IServiceManagerReducerInterface, action: IPayloadAction<any>) => {
  switch (action.type) {
    case ServiceManagerActions.types.FETCHING_SM_SERVICES:
      draft.isLoadingServices = true;
      return;
    case ServiceManagerActions.types.FETCH_SM_SERVICES_SUCCESS:
      draft.isLoadingServices = false;
      draft.services = action.payload.services.services.sort((a: { name: string }, b: { name: any }) =>
        a.name ? a.name.localeCompare(b.name) : -1,
      );
      return;
    case ServiceManagerActions.types.FETCH_SM_SERVICES_ERROR:
      draft.isLoadingServices = false;
      return;
    case ServiceManagerActions.types.FETCH_CATEGORIES:
      draft.categories = action.payload.categories;
      return;
    case ServiceManagerActions.types.FETCH_ALLOWED_SCOPES:
      draft.allowedScopes = action.payload.allowedScopes;
      return;
    case ServiceManagerActions.types.SET_ALLOWED_SCOPE:
      draft.allowedScope = action.payload.allowedScope;
      return;
    case ServiceManagerActions.types.SET_CATEGORY:
      draft.category = action.payload.category;
      return;
    case ServiceManagerActions.types.FETCH_ALLOWED_SCOPES_ICONS:
      draft.allowedScopesIcons = action.payload.allowedScopesIcons;
      return;
    case ServiceManagerActions.types.FETCHING_ICONS_CATEGORIES:
      draft.icons = action.payload.icons;
      return;
    case AuthenticationActions.types.LOGOUT:
      return initialState;
    case ServiceDetailsActions.types.FETCH_SERVICE_DETAILS_LEAVE:
      return initialState;
    default:
      return draft;
  }
}, initialState);
