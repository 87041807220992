import {
  DownloadIcon,
  ModalTypeEnum,
  SearchBar,
  SmallButton,
  Table,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IStoreInterface } from "../../../../configs/store.config";
import { IconContainer, LoadingText } from "../../../../shared/shared.styled";
import { renderTableButtons } from "../../../../shared/shared.utils";
import { ICommandId } from "../../../../shared/models/command-id.model";
import EditCommandIdModal from "./edit-command-id.modal";
import { downloadCsvFile } from "../../support.utils";
import { SupportActions } from "../../support.store";
import SupportApi from "../../support.api";
import { SearchBarFilterContainer } from "../../../../shared/responsive-ui.styled";
import { FiltersContainer } from "../../support.styled";

/**
 * Command Ids Tab Component
 * @returns {JSX.Element}
 */
const CommandIdsTab = () => {
  // Hooks Initialization
  const [t] = useTranslation();
  const [showAlert] = useAlert();
  const dispatch = useDispatch();

  // Redux state
  const commandIds = useSelector((state: IStoreInterface) => state.supportReducer.commandIds);
  const [displayedCommandIds, setDisplayedCommandIds] = React.useState<ICommandId[]>(commandIds ? commandIds : []);

  // Local state
  const [isFetchingCommandIds, setIsFetchingCommandIds] = React.useState(true);
  const [commandIdInEdition, setEditCommandIdInEdition] = React.useState<ICommandId | undefined>(undefined);
  const [searchString, setSearchString] = React.useState("");

  const _hideEditModal = () => {
    hideEditModal();
  };

  const [showEditModal, hideEditModal] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <EditCommandIdModal commandId={commandIdInEdition as ICommandId} hideModal={_hideEditModal} />,
  );

  const fetchCommandIds = React.useCallback(() => {
    setIsFetchingCommandIds(true);
    SupportApi.methods
      .getCommandIds()
      .finally(() => {
        setIsFetchingCommandIds(false);
      })
      .then(
        res => {
          const mappedCommandIds = res.data.commandIds.map(e => {
            return { commandName: e.key, commandValue: e.value };
          });
          dispatch(SupportActions.Creators.fetchCommandIdsSuccessAction(mappedCommandIds as ICommandId[]));
        },
        () => {
          showAlert();
        },
      );
  }, [dispatch, showAlert]);

  // Effects
  React.useEffect(() => {
    fetchCommandIds();
  }, [fetchCommandIds]);

  /*
   * Search
   * */
  React.useEffect(() => {
    let tempValues = [...(commandIds ? commandIds : [])];

    if (!!searchString) {
      tempValues = tempValues.filter(
        entry =>
          entry.commandName.toLowerCase().includes(searchString.toLowerCase()) ||
          entry.commandValue.toLowerCase().includes(searchString.toLowerCase()),
      );
    }
    setDisplayedCommandIds(tempValues);
  }, [commandIds, searchString]);

  function showEditPropertyDialog(commandId: ICommandId) {
    setEditCommandIdInEdition(commandId);
    showEditModal();
  }

  /**
   * Calls the API to get the data and then calls our downloadJsonFile function
   */
  function exportData() {
    /**Temporary Solution until it's implemented on the backend side */
    const propertiesJson = JSON.parse(JSON.stringify(displayedCommandIds));
    const csvHeaders = `${t("pages.support.commandIds.table.headers.commandName")},${t(
      "pages.support.commandIds.table.headers.commandValue",
    )}`;
    let csvData = csvHeaders.concat("\n");
    for (let i = 0; i < Object.keys(propertiesJson).length; i++) {
      //Check if any of the values needs to be modified
      propertiesJson[i].commandName = formatCSVValue(propertiesJson[i].commandName);
      propertiesJson[i].commandValue = formatCSVValue(propertiesJson[i].commandValue);
      //Add each row value to the csvData variable
      csvData += Object.values(propertiesJson[i])
        .toString()
        .concat("\n");
    }

    downloadCsvFile(csvData, `Command_Ids_${new Date().toISOString()}.csv`);
  }

  const formatCSVValue = (csvData: string) => {
    if (csvData.includes(",")) {
      return csvData.replace(/,/g, ";");
    } else if (csvData.trim().length === 0) {
      return "";
    } else {
      return csvData;
    }
  };

  return (
    <>
      {isFetchingCommandIds ? (
        <LoadingText>{t("commons.loadingResults")}</LoadingText>
      ) : (
        <>
          <FiltersContainer style={{ marginBottom: "24px" }}>
            <SearchBarFilterContainer>
              <SearchBar
                value={searchString}
                onChange={e => setSearchString(e.target.value)}
                placeholderLabel={t("pages.support.commandIds.searchPlaceholder")}
                clearValue={() => setSearchString("")}
              />
            </SearchBarFilterContainer>
            <div style={{ marginLeft: "auto" }}>
              <SmallButton
                iconComponent={
                  <IconContainer size={20} color={styleTheme.palette.vodafoneRed}>
                    <DownloadIcon />
                  </IconContainer>
                }
                titleLabel={t("pages.support.commandIds.exportBtnLabel")}
                onClick={exportData}
              />
            </div>
          </FiltersContainer>
          <Table
            columns={[
              {
                formKey: "commandName",
                label: t("pages.support.commandIds.table.headers.commandName"),
                isEditable: false,
                ratio: 5 / 12,
              },
              {
                formKey: "commandValue",
                label: t("pages.support.commandIds.table.headers.commandValue"),
                isEditable: false,
                ratio: 5 / 12,
              },
            ]}
            values={displayedCommandIds}
            displayActions={commandId =>
              renderTableButtons<ICommandId>(commandId, {
                edit: { onClick: showEditPropertyDialog },
              })
            }
          />
        </>
      )}
    </>
  );
};

export default CommandIdsTab;
