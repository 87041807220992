import React from "react";

/** Render trash SVG icon */
const TrashIcon = () => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Remove File</title>
      <g id="11-Topics" stroke="none" fill="none" fillRule="evenodd">
        <g id="11.00.06-Create-Topic:-Done" transform="translate(-865.000000, -416.000000)">
          <rect fill="#FFFFFF" x="0" y="0" width="1360" height="768"></rect>
          <g id="Cards-/-Service-/-Version-management-/-Resting-Copy" transform="translate(493.000000, 377.000000)">
            <path
              d="M6,0 L483,0 C486.313708,-6.08718376e-16 489,2.6862915 489,6 L489,120 C489,123.313708 486.313708,126 483,126 L6,126 C2.6862915,126 -7.58779353e-15,123.313708 0,120 L0,6 C-4.05812251e-16,2.6862915 2.6862915,6.08718376e-16 6,0 Z"
              id="Rectangle"
              stroke="#CCCCCC"
              strokeDasharray="4"
            ></path>
            <g
              id="Group"
              transform="translate(94.000000, 25.000000)"
              stroke="#E60000"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <g id="Group-2" transform="translate(80.000000, 14.000000)">
                <g id="Icon-/-cancel-Copy" transform="translate(198.000000, 0.000000)">
                  <path
                    d="M17.9583333,6.04166667 L17.9583333,19.3333333 C17.9583333,20.0927249 17.3427249,20.7083333 16.5833333,20.7083333 L7.41666667,20.7083333 C6.65727514,20.7083333 6.04166667,20.0927249 6.04166667,19.3333333 L6.04166667,6.04166667 M12,17.5 L12,9.25 M9.25,17.5 L9.25,9.25 M5.125,6.04166667 L18.875,6.04166667 M14.75,6.04166667 L14.75,5.125 C14.75,4.11247796 13.9291887,3.29166667 12.9166667,3.29166667 L11.0833333,3.29166667 C10.0708113,3.29166667 9.25,4.11247796 9.25,5.125 L9.25,6.04166667 M14.75,17.5 L14.75,9.25"
                    id="Path-2"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TrashIcon;
