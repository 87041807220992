import { EyeIcon, FastActionButton, StatusChip } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IServiceDetails, ServiceStatusEnum, IServiceVersion } from "../../../shared/models/service-builder.model";
import { Column, Row } from "../../../shared/shared.styled";
import { formatUTCTimestamp } from "../../../shared/shared.utils";
import ServicePreviewDrawer from "../../configurations/service-approval/components/service-preview-drawer.component";

interface IServiceVersionItemProps {
  service: IServiceDetails;
  serviceVersion: IServiceVersion;
  language: string;
}

const mapTransitoryStatus = (status: string) => {
  const idx = status.indexOf("_");

  if (idx !== -1) {
    return status.substring(idx + 1);
  }

  return status;
};

const getStatus = (serviceStatus: string, versionStatus: string) => {
  return versionStatus ? mapTransitoryStatus(versionStatus) : mapTransitoryStatus(serviceStatus);
};

const showVersionData = (service: IServiceDetails) => {
  return (
    service.id && service.status !== ServiceStatusEnum.LIVE_DELETED && service.status !== ServiceStatusEnum.BETA_DELETED
  );
};

const ServiceVersionItem = ({ service, serviceVersion, language }: IServiceVersionItemProps) => {
  const [t] = useTranslation();
  const [showDetails, setShowDetails] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  /**
   * Returns a string with all the categories in this service
   * @returns {string}
   */
  const getCategories = () => {
    if (service.categories.length > 0) {
      let label = "";
      service.categories.forEach(c => (label += `${c.name.translations[language]}, `));
      label = label.substring(0, label.length - 2);
      return label;
    }
  };

  // TODO: add translations, when added to service builder?
  // TODO: use service or serviceVersion status??
  return (
    <>
      {showVersionData(service) && (
        <ItemColumn disabled={false}>
          <Row>
            <ImageContainer>
              <ServiceIcon background={service.iconUrl} />
            </ImageContainer>
            <ItemInfo>
              <Row>
                <span
                  style={{
                    flex: 1,
                    fontWeight: 1000,
                    fontSize: 18,
                    lineHeight: "23px",
                    color: styleTheme.palette.darkGrey,
                  }}
                >
                  {service.name[language]}
                </span>
                {serviceVersion.useRenderer && (
                  <div style={{ flex: 0 }}>
                    <FastActionButton
                      label={"Preview"}
                      iconComponent={
                        <FastIconWrapper color={styleTheme.palette.turquoiseBlue}>
                          <EyeIcon />
                        </FastIconWrapper>
                      }
                      onClick={() => setShowPreview(true)}
                    />
                  </div>
                )}
              </Row>
              <Row style={{ marginTop: 10 }}>
                <span style={{ fontSize: 16, lineHeight: "21px", color: styleTheme.palette.darkGrey }}>
                  {service.description[language]}
                </span>
              </Row>
              <Row style={{ marginTop: 10, height: 20 }}>
                {service.highlighted ? (
                  <Label>{t("pages.serviceBuilder.modals.addService.highlighted.title")}</Label>
                ) : null}
              </Row>

              <Row style={{ margin: "10px 0 40px 0", height: 20, flexDirection: "column", alignItems: "start" }}>
                {service.categories && service.categories.length ? (
                  <CategoriesText>
                    {t(`pages.serviceBuilder.categories.title`)} <span className="purple">{getCategories()}</span>
                  </CategoriesText>
                ) : null}
                {service.tags && service.tags.length > 0 && (
                  <TagsText>
                    {t(`pages.serviceBuilder.tags.title`)}:
                    <span className="turquoise">{service.tags.toString().replace(/[,]/g, ", ")}</span>
                  </TagsText>
                )}
              </Row>
              <Row style={{ marginTop: 20 }}>
                <div style={{ flex: 1 }}>
                  <ItemSpan style={{ color: styleTheme.palette.darkGrey, paddingTop: 0 }}>
                    {t("pages.serviceEditor.versionNumber")}
                    {unescape(serviceVersion.version as string)}
                  </ItemSpan>
                  {serviceVersion.statusDate && (
                    <ItemSpan style={{ color: styleTheme.palette.midGrey, paddingTop: 0 }}>
                      {t("pages.serviceBuilder.item.lastUpdated")} {formatUTCTimestamp(serviceVersion.statusDate)}
                    </ItemSpan>
                  )}
                </div>
                <div style={{ flex: 0 }}>
                  <StatusChip type={getStatus(service.status as string, serviceVersion.status || "") as any}>
                    {t(
                      `commons.serviceBuilder.enums.status.${getStatus(
                        service.status as string,
                        serviceVersion.status || "",
                      )}`,
                    )}
                  </StatusChip>
                </div>
              </Row>
              <Row style={{ marginTop: 15 }}>
                <span
                  style={{
                    fontSize: 14,
                    lineHeight: "18px",
                    color: "#00b0ca",
                    cursor: "pointer",
                    padding: "8px 0",
                    textDecoration: "underline",
                  }}
                  onClick={() => setShowDetails(!showDetails)}
                >
                  {showDetails ? "Hide details" : "See all details"}
                </span>
              </Row>

              {showDetails && (
                <>
                  <div style={{ marginTop: 15, display: "flex" }}>
                    <div style={{ flex: 0 }}>
                      <ItemSpan style={{ color: styleTheme.palette.midGrey }}>Service Image</ItemSpan>
                      <ServiceImage background={service.imageUrl} />
                    </div>
                    <div style={{ flex: 1, wordBreak: "break-all", paddingLeft: 40 }}>
                      <ItemSpan style={{ color: styleTheme.palette.midGrey }}>External API calls</ItemSpan>
                      {service.urlWhiteList && service.urlWhiteList.length > 0 ? (
                        service.urlWhiteList.map((entry, idx) => (
                          <Row key={idx}>
                            <ItemSpan>{entry}</ItemSpan>
                          </Row>
                        ))
                      ) : (
                        <Row>
                          <ItemSpan>Empty</ItemSpan>
                        </Row>
                      )}
                    </div>
                  </div>
                  <div style={{ marginTop: 15 }}>
                    <ItemSpan style={{ color: styleTheme.palette.midGrey }}>Whitelist</ItemSpan>
                    {serviceVersion.whiteList && serviceVersion.whiteList.length > 0 ? (
                      serviceVersion.whiteList.map((entry, idx) => (
                        <Row key={idx}>
                          <ItemSpan>{entry}</ItemSpan>
                        </Row>
                      ))
                    ) : (
                      <Row>
                        <ItemSpan>Empty</ItemSpan>
                      </Row>
                    )}
                  </div>
                </>
              )}
            </ItemInfo>
          </Row>
          {serviceVersion.releaseNotes && (
            <div
              style={{ marginTop: 20, padding: "20px 0 6px", borderTop: `1px solid ${styleTheme.palette.aluminium}` }}
            >
              <Row>
                <ItemSpan style={{ color: styleTheme.palette.anthracite }}>
                  {t("pages.serviceBuilder.modals.addServiceVersion.releaseNotes.title")}
                </ItemSpan>
              </Row>
              <Row>
                <ItemSpan>{serviceVersion.releaseNotes}</ItemSpan>
              </Row>
            </div>
          )}

          {showPreview ? (
            <ServicePreviewDrawer
              appVersion={serviceVersion.version || ""}
              versionId={serviceVersion.id || ""}
              language={language}
              closeDrawer={() => setShowPreview(false)}
            />
          ) : null}
        </ItemColumn>
      )}
      {!showVersionData(service) && (
        <ItemColumn>
          <Row>
            <NotExists>This service does not currently exist</NotExists>
          </Row>
        </ItemColumn>
      )}
    </>
  );
};

export default ServiceVersionItem;

const NotExists = styled.span`
  font-size: 14px;
  color: #999999;
`;

const ItemColumn = styled(Column)<{ disabled?: Boolean }>`
  flex: 1;
  padding: 0 24px 16px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const ItemInfo = styled(Column)`
  flex: 1 auto;
`;

const ImageContainer = styled(Column)`
  margin-right: 24px;
  display: flex;
  align-self: baseline;
  flex: 0 auto;
  padding: 4px;
`;

const ServiceIcon = styled("div")<{ background: any }>`
  width: 80px;
  height: 80px;
  background: url("${props => props.background}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 40px;
  border: solid 0.8px ${props => props.theme.palette.aluminium};
  background-color: #9dd6ff;
`;

const ServiceImage = styled("div")<{ background: any }>`
  width: 130px;
  height: 90px;
  background: url("${props => props.background}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 3px;
  border: solid 0.5px ${props => props.theme.palette.aluminium};
`;

const TagsText = styled("div")`
  width: 100%;
  height: auto;
  margin-top: 5px 0 10px 0;
  font-family: Vodafone Rg;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  text-align: left;
  > .turquoise {
    font-weight: bold;
    color: color: ${props => props.theme.palette.turquoise};
    text-transform: capitalize;
    margin-left: 3px;
  }
`;

const FastIconWrapper = styled("span")<{ color: string }>`
  width: 16px;
  height: 16px;
  stroke: ${props => props.color};
  svg {
    width: inherit;
    height: inherit;
  }
`;

const ItemSpan = styled("span")`
  display: block;
  padding-top: 4px;
  font-size: 14px;
  line-height: 19px;
  color: ${props => props.theme.palette.darkGrey};
`;

const Label = styled("div")`
  font-family: Vodafone Rg;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${props => props.theme.palette.freshOrange};
`;

const CategoriesText = styled("div")`
  margin-top: 13px;
  font-family: Vodafone Rg;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  text-align: center;
  > .purple {
    font-weight: bold;
    color: #9c2aa0;
  }
`;
