import React, { useState } from "react";
import styled from "styled-components";
import { Formik, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import { PrimaryButton, TextInput, useAlert, Checkbox } from "@wit/mpesa-ui-components";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import { Column, RatioContainer, Row } from "../../../../shared/shared.styled";
import useBanksAPI from "../banks.api";
import { IBank, BanksStatusEnum } from "../bank.model";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { srcToFile, verifyImageUrl } from "../../../../shared/shared.utils";
import ReactTooltip from "react-tooltip";
import HelpIcon from "../../../../shared/icons/help.icon";
import { fileToBase64, isHttp } from "../../../../shared/shared.utils";

interface IEditBankModalProps {
  onCancelClick: () => void;
  selectedBank: IBank;
  setSelectedBank: React.Dispatch<React.SetStateAction<IBank | undefined>>;
}

const FILE_SIZE = 74 * 74;
const SUPPORTED_FORMATS = ["image/svg+xml"];

const _URL = window.URL || window.webkitURL;

/**
 * EditBankModal component
 */
const EditBankModal = ({ onCancelClick, selectedBank, setSelectedBank }: IEditBankModalProps) => {
  const [t] = useTranslation();
  const { editBank, createBank } = useBanksAPI();

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
  });
  const [imageUrl, setImageUrl] = useState<string>();
  const [image, setImage] = useState<string>();
  const [imageName, setImageName] = useState<string>();
  const [cantUpload, setCantUpload] = useState<boolean>(false);
  const [showAlert, , setAlertProps] = useAlert();

  /**
   * verify image size method
   */
  const verifyImageSize = async (file: any, setFieldValue: any) => {
    if (SUPPORTED_FORMATS.indexOf(file.type) < 0) {
      setImageUrl(undefined);
      setFieldValue("urlIcon", undefined);
      setCantUpload(true);
      setImage(undefined);
      setImageName(undefined);
      setImageUrl(undefined);
      return;
    }
    const img = new Image();
    const objectUrl = _URL.createObjectURL(file);
    const base64Image = await fileToBase64(file);

    img.onload = function(e) {
      if (img.width > 74 || img.height > 74) {
        setCantUpload(true);
        setImage(undefined);
        setImageName(undefined);
        setImageUrl(undefined);
        return;
      } else {
        setCantUpload(false);
      }
      const url = _URL.createObjectURL(file);
      setImage(base64Image);
      setImageName(file.name);
      setImageUrl(url);
    };
    img.src = objectUrl;
  };

  /**
   * change status method
   */
  const changeStatus = (
    currentStatus: BanksStatusEnum | undefined,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  ) => {
    if (currentStatus === BanksStatusEnum.LIVE || currentStatus === BanksStatusEnum.ENABLE) {
      setFieldValue("status", BanksStatusEnum.DISABLE);
    } else {
      setFieldValue("status", BanksStatusEnum.ENABLE);
    }
  };

  /**
   * getBanksValidationSchema method
   */
  const getBanksValidationSchema = () => {
    return Yup.object().shape({
      shortCode: Yup.string().required(t("pages.banks.createEditModal.shortCode.error")),
      name: Yup.string().required(t("pages.banks.createEditModal.name.error")),
      accountNumberPrefix: Yup.string(),
      urlIcon: Yup.mixed()
        .test("fileSize", "", value => value && value.size <= FILE_SIZE)
        .test("fileFormat", "", value => value && SUPPORTED_FORMATS.includes(value.type)),
    });
  };

  /**
   * getBanksValidationSchema method
   */
  const getInitialValues = () => {
    return (
      selectedBank || {
        accountNumberPrefix: "",
        name: "",
        shortCode: "",
        accountNameCheck: false,
      }
    );
  };

  /**
   * onSubmit method
   */
  const onSubmit = async (bank: IBank, _actions: FormikHelpers<IBank>) => {
    const requestData: any = {
      ...bank,
    };

    if (!image && !selectedBank) {
      try {
        const a = await srcToFile("./images/bank-generic.svg", "default.svg", "image/svg+xml");
        requestData.base64Image = await fileToBase64(a);
        requestData.imageName = a.name;
      } catch (err) {
        setAlertProps({
          title: err as string,
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        return;
      }
    } else {
      requestData.base64Image = image;
      requestData.imageName = imageName;
    }
    if (selectedBank) {
      editBank(bank.id, requestData).then(res => {
        if (res.status === 200) {
          onCancelClick();
        }
      });
    } else {
      createBank(requestData).then(res => {
        if (res.status === 200) {
          onCancelClick();
        }
      });
    }
  };

  /**
   * Renders the account name check tooltip jsx
   * @returns {JSX.Element}
   */
  const renderAccountNameCheckTooltip = () => {
    return (
      <>
        <MutedText style={{ marginLeft: "12px" }}>{t("pages.banks.createEditModal.bankNameCheckLabel")}</MutedText>
        <TooltipContainer data-tip data-for="tooltip-account-name-check" id={"account-name-check-tooltip"}>
          <HelpIcon />
          <ReactTooltip
            event="mouseover"
            eventOff="mouseleave mousedown"
            place="top"
            type="dark"
            effect="solid"
            id={"tooltip-account-name-check"}
            className={"tooltip-container"}
          >
            <TooltipText>{t("pages.banks.createEditModal.bankNameCheckTooltip")}</TooltipText>
          </ReactTooltip>
        </TooltipContainer>
      </>
    );
  };

  return (
    <ModalContainer>
      <ModalTitle>
        {selectedBank ? t("pages.banks.createEditModal.editTitle") : t("pages.banks.createEditModal.addTitle")}
      </ModalTitle>
      <Formik
        onSubmit={onSubmit}
        validationSchema={getBanksValidationSchema()}
        validateOnChange={false}
        initialValues={getInitialValues()}
        render={({ isSubmitting, handleSubmit, values, setFieldValue, errors, handleChange }) => {
          if (values && values.urlIcon && typeof values.urlIcon === "string") {
            if (isHttp(values.urlIcon)) {
              verifyImageUrl(values.urlIcon, (url: string) => setImageUrl(url));
            } else {
              setImageUrl(`./imagesuploaded/${values.urlIcon}`);
            }
          }

          const isNewImage = imageUrl && (imageUrl as string).includes("blob");
          return (
            <form onSubmit={handleSubmit}>
              <Column>
                <FormInfoContainer>
                  <ImageUpload className="container">
                    {imageUrl && (
                      <Logo src={`${imageUrl}${!isNewImage ? `?time=${new Date().valueOf()}` : ""}`} alt="logo"></Logo>
                    )}
                    {!imageUrl && <Logo src={"./images/bank.png"} alt="logo"></Logo>}
                    <DropImage {...getRootProps({ className: "dropzone" })}>
                      <input
                        {...getInputProps()}
                        onChange={event => {
                          setFieldValue("urlIcon", event.currentTarget.files ? event.currentTarget.files[0] : null);
                          if (event.currentTarget.files && event.currentTarget.files[0]) {
                            verifyImageSize(event.currentTarget.files[0], setFieldValue);
                          }
                        }}
                      />
                      <EditPicture missing={false}>
                        {imageUrl || selectedBank
                          ? t("pages.banks.createEditModal.editPicture")
                          : t("pages.banks.createEditModal.addPicture")}
                      </EditPicture>
                      <PictureDescription missing={cantUpload}>
                        <p> {t("pages.banks.createEditModal.dimension")}</p>
                        <p>{t("pages.banks.createEditModal.format")}</p>
                      </PictureDescription>
                    </DropImage>
                    {cantUpload && <UploadError>{t("pages.banks.createEditModal.uploadError")}</UploadError>}
                  </ImageUpload>
                </FormInfoContainer>
                <FormInfoContainer>
                  <RatioContainer style={{ marginRight: 24 }} ratio={1 / 2}>
                    <TextInput
                      title={t("pages.banks.createEditModal.name.label")}
                      placeholder={t("pages.banks.createEditModal.name.placeholder")}
                      name="name"
                      onChange={handleChange}
                      value={values.name || ""}
                      error={errors.name}
                      maxLength={100}
                      required
                    />
                  </RatioContainer>
                  <RatioContainer ratio={1 / 2}>
                    <TextInput
                      name="shortCode"
                      title={t("pages.banks.createEditModal.shortCode.label")}
                      placeholder={t("pages.banks.createEditModal.shortCode.placeholder")}
                      onChange={handleChange}
                      value={values.shortCode || ""}
                      error={errors.shortCode}
                      maxLength={100}
                      required
                    />
                  </RatioContainer>
                </FormInfoContainer>

                <FormInfoContainer>
                  <RatioContainer style={{ marginRight: 24 }} ratio={1 / 2}>
                    <TextInput
                      name="accountNumberPrefix"
                      title={t("pages.banks.createEditModal.prefix.label")}
                      placeholder={t("pages.banks.createEditModal.prefix.placeholder")}
                      onChange={handleChange}
                      value={values.accountNumberPrefix || ""}
                      error={errors.accountNumberPrefix || ""}
                      maxLength={20}
                    />
                  </RatioContainer>
                  <RatioContainer ratio={1 / 2}>
                    <CheckboxTitle>{t("pages.banks.createEditModal.accountName")}</CheckboxTitle>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        name={"accountNameCheck"}
                        data-testid={"account-name-checkbox"}
                        setFieldValue={setFieldValue}
                        value={values.accountNameCheck}
                      ></Checkbox>
                      <div style={{ display: "flex", alignItems: "center", flexShrink: 0, height: "36px" }}>
                        {renderAccountNameCheckTooltip()}
                      </div>
                    </div>
                  </RatioContainer>
                </FormInfoContainer>
                {selectedBank && (
                  <FormInfoContainer>
                    <Label>{t("pages.banks.createEditModal.status.label")}</Label>

                    <div onClick={() => changeStatus(values.status, setFieldValue)}>
                      {values.status === BanksStatusEnum.LIVE ||
                      values.status === BanksStatusEnum.ENABLE ||
                      values.status === BanksStatusEnum.EDIT ? (
                        <LiveStatus>
                          <span>{t("pages.banks.createEditModal.status.live")}</span>
                        </LiveStatus>
                      ) : (
                        <DisabledStatus>
                          <span>{t("pages.banks.createEditModal.status.disabled")}</span>
                        </DisabledStatus>
                      )}
                    </div>
                  </FormInfoContainer>
                )}
              </Column>
              <ButtonsContainer>
                <div>
                  <PrimaryButton
                    id="cancel-button"
                    type="button"
                    onClick={onCancelClick}
                    titleLabel={t("pages.banks.createEditModal.cancelBtnLabel")}
                  />
                </div>
                <div>
                  <PrimaryButton
                    id={selectedBank ? "save-changes-button" : "add-bank-button"}
                    redTheme={true}
                    type="submit"
                    titleLabel={
                      selectedBank
                        ? t("pages.banks.createEditModal.saveChangesBtnLabel")
                        : t("pages.banks.createEditModal.addBankBtnLabel")
                    }
                  />
                </div>
              </ButtonsContainer>
            </form>
          );
        }}
      />
    </ModalContainer>
  );
};

export default EditBankModal;

const ModalContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 24px 18px 24px 24px;
  font-family: Vodafone Rg;
`;

const ModalTitle = styled("span")`
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 21px;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 50px;

  > div {
    width: "fit-content";

    :first-of-type {
      margin-right: 12px;
    }
  }
`;

const Label = styled("div")`
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
  margin-bottom: 8px;
  min-height: 21px;
`;

const FormInfoContainer = styled(Row)`
  margin-bottom: 22px;
`;

const Status = styled.div`
  border-radius: 10px;
  padding-left: 12px;
  padding-right: 12px;
  height: 21px;
  line-height: 21px;
  font-weight: bold;
  display: inline-block;
  font-size: 14px;
  text-align: center;
  color: ${props => props.theme.palette.white};
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-left: 10px;
  > span {
    position: relative;
    bottom: 1px;
  }
`;

const LiveStatus = styled(Status)`
  color: ${props => props.theme.palette.successGreen};
  border: 1px solid;
  border-color: ${props => props.theme.palette.successGreen};
`;
const DisabledStatus = styled(Status)`
  color: black;
  border: 1px solid black;
  opacity: 0.5;
`;

const ImageUpload = styled.div`
  display: flex;
`;

const DropImage = styled.div`
  margin-left: 25px;
`;

const Logo = styled.img`
  width: 63px;
  height: 63px;
  border-radius: 100px;
  padding: 10px;
  border: solid 1px;
  border-color: ${props => props.theme.palette.aluminium};
`;

const EditPicture = styled.div<{ missing: Boolean | undefined }>`
  height: 27px;
  border-radius: 20px;
  border: solid 1px;
  border-color: ${props => props.theme.palette.aluminium};
  font-size: 14px;
  line-height: 1.36;
  color: ${props => (props.missing ? "red" : props.theme.palette.darkGrey)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const PictureDescription = styled.div<{ missing: Boolean | undefined }>`
  margin-top: 15px;
  color: ${props => (props.missing ? "red" : props.theme.palette.midGrey)};

  p {
    margin: 0;
    font-size: 13px;
  }
`;

const UploadError = styled.span`
  color: ${props => props.theme.palette.digitalRed};
  font-family: Vodafone Rg;
  font-size: 13px;
  margin-left: 8px;
`;

export const MutedText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
`;

export const TooltipText = styled("div")`
  font-size: 12px;
  font-family: "Vodafone Rg";
  color: white;
  display: flex;
  width: 140px;
  text-align: center;
`;

export const TooltipContainer = styled("div")`
  display: flex;
  margin-left: 5px;
  cursor: pointer;
`;

export const CheckboxTitle = styled("div")`
  min-height: 21px;
  font-family: Vodafone Rg;
  font-weight: bold;
  line-height: 21px;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
  margin-bottom: 8px;
`;
