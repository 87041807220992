import {
  AutoClosingDropdown,
  FormSection,
  PrimaryButton,
  SecondaryPageTitle,
  TextInput,
  useAlert,
} from "@wit/mpesa-ui-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { FormSectionRow } from "@wit/mpesa-ui-components/lib/components/form-section/form-section.component";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikHelpers, FormikErrors } from "formik";
import { TFunction } from "i18next";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { array, object, string } from "yup";
import { RoutesEnum } from "../../../../routes/routes.constants";
import { getRoles, isSFCMarket } from "../../../../shared/shared.utils";
import UsersApi from "../users.api";
import {
  ICreateUserRequest,
  ISingleUserRequest,
  UserRolesVDF,
  UserRolesDefault,
  AuthenticationTypeEnum,
} from "../users.interfaces";
import { UsersActions } from "../users.store";
import { useHistory } from "react-router-dom";
import { ConfigContext } from "../../../../app.component";
import { THEMES } from "../../../../shared/renderer.utils";
import { useUsersValidationRules } from "../hooks/use-users-validation-rules.hook";
import { IFullNameValidation } from "../../../../shared/shared.interfaces";

/**
 * method to validate user form
 */
const addUserValidationSchema = (t: TFunction, validationRules: IFullNameValidation) => {
  const userSchema = {
    username: string()
      .required(t("commons.mandatoryField"))
      .email(t("commons.emailRequired")),
    fullName: string()
      .matches(
        validationRules.REGEX,
        t("validationRules.fullName", {
          ns: "market_values",
          minChars: validationRules.MIN,
          maxChars: validationRules.MAX,
        }),
      )
      .required(t("commons.mandatoryField")),
    role: string().required(t("commons.mandatoryField")),
    authenticationType: string(),
  };

  if (isSFCMarket()) {
    userSchema.authenticationType = string().required(t("commons.mandatoryField"));
  }
  return object().shape({
    users: array().of(object().shape(userSchema)),
  });
};

/**
 * AddUserPage component
 */
const AddUserPage = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const history = useHistory();
  const { config } = useContext(ConfigContext);
  const { fullName: FULL_NAME_VALIDATION_RULES } = useUsersValidationRules();

  function getRows(
    user: ISingleUserRequest,
    errors: FormikErrors<ICreateUserRequest>,
    setFieldValue: any,
    idx: number,
  ): FormSectionRow[] {
    const rows = [
      {
        label: t("pages.addUser.formSection.nameLabel"),
        displayComponent: null,
        editingComponent: (
          <TextInput
            id={"full-name"}
            maxLength={50}
            onChange={e => setFieldValue(`users[${idx}].fullName`, e.target.value)}
            error={errors.users && errors.users[idx] ? (errors.users[idx] as ISingleUserRequest).fullName : undefined}
            placeholder={t("pages.addUser.formSection.namePlaceholder")}
            value={user.fullName}
            required={true}
          />
        ),
      },
      {
        label: t("pages.addUser.formSection.usernameLabel"),
        displayComponent: null,
        editingComponent: (
          <TextInput
            id={"username"}
            onChange={e => setFieldValue(`users[${idx}].username`, e.target.value)}
            placeholder={t("pages.addUser.formSection.usernamePlaceholder")}
            error={errors.users && errors.users[idx] ? (errors.users[idx] as ISingleUserRequest).username : undefined}
            value={user.username}
            required={true}
          />
        ),
      },
      {
        label: t("pages.addUser.formSection.roleLabel"),
        displayComponent: null,
        editingComponent: (
          <>
            <div id={"roles"}>
              <AutoClosingDropdown
                options={(getRoles(t) as { key: UserRolesDefault | UserRolesVDF; label: string }[]).map(role => {
                  return {
                    label: <span id={role.key}>{role.label}</span>,
                    key: role.key,
                  } as { key: UserRolesDefault | UserRolesVDF; label: any };
                })}
                dropdownType={DropdownType.RECTANGULAR_NORMAL}
                selectOption={opt => setFieldValue(`users[${idx}].role`, opt.key)}
                label={user.role ? t(`userRoles.${user.role}`) : t("pages.addUser.formSection.dropdownPlaceholder")}
                hasValue={!!user.role}
              />
            </div>
          </>
        ),
      },
    ];
    if (isSFCMarket()) {
      rows.push({
        label: t("pages.addUser.formSection.authenticationLabel"),
        displayComponent: null,
        editingComponent: (
          <>
            <div id={"authentication-types"}>
              <AutoClosingDropdown
                options={Object.values(AuthenticationTypeEnum).map(authentication => ({
                  label: t(`pages.addUser.formSection.authenticationType.${authentication.toLowerCase()}`),
                  key: authentication,
                }))}
                dropdownType={DropdownType.RECTANGULAR_NORMAL}
                selectOption={opt => setFieldValue(`users[${idx}].authenticationType`, opt.key)}
                label={
                  user.authenticationType
                    ? t(`pages.addUser.formSection.authenticationType.${user.authenticationType.toLowerCase()}`)
                    : t("pages.addUser.formSection.authenticationPlaceholder")
                }
                hasValue={!!user.authenticationType}
                error={
                  errors.users && errors.users[idx] && (errors.users[idx] as ISingleUserRequest).authenticationType
                    ? (errors.users[idx] as ISingleUserRequest).authenticationType
                    : undefined
                }
              />
            </div>
          </>
        ),
      });
    }

    return rows;
  }

  /**
   * Add users request
   */
  const addUsers = (values: ICreateUserRequest, actions: FormikHelpers<ICreateUserRequest>) => {
    actions.setSubmitting(true);
    UsersApi.methods
      .createUser(values.users)
      .finally(() => actions.setSubmitting(false))
      .then(
        res => {
          dispatch(UsersActions.creators.addUserAction(res.data));
          setAlertProps({
            type: AlertTypeEnum.SUCCESS,
            title: t("pages.addUser.formSection.alerts.addUserSuccess"),
          });
          showAlert();
          history.push(RoutesEnum.ADMIN);
        },
        err => {
          let message;
          if (err.data.status && err.data.status.httpCode === 404 && err.data.status.businessCode === 4046) {
            message = t("pages.addUser.formSection.alerts.addLdapUserError");
          } else {
            message =
              err && err.data && err.data.status && err.data.status.message
                ? err.data.status.message
                : t("pages.addUser.formSection.alerts.addUserError");
          }
          setAlertProps({
            type: AlertTypeEnum.ERROR,
            title: message,
          });
          showAlert();
        },
      );
  };

  return (
    <>
      <SecondaryPageTitle
        displayInitialsCircle={false}
        title={t("pages.addUser.title")}
        paddingLeft={200}
        goBackFn={() => window.history.back()}
      />
      <Formik
        onSubmit={addUsers}
        initialValues={
          {
            users: [
              {
                fullName: "",
                username: "",
                role: UserRolesDefault.SUPPORT,
                language: "en-GB",
              },
            ],
          } as ICreateUserRequest
        }
        validateOnBlur={true}
        validateOnChange={false}
        validationSchema={addUserValidationSchema(t, FULL_NAME_VALIDATION_RULES)}
        render={({ handleSubmit, isSubmitting, values, setFieldValue, errors, setValues }) => (
          <>
            <FormContainer onSubmit={handleSubmit}>
              <FormTitle>{t("pages.addUser.formTitle")}</FormTitle>
              {values.users.map((user, idx) => (
                <FormSectionCard>
                  <FormSection
                    title={
                      <FormSectionCardTitle>
                        <div>{t("pages.addUser.formSection.title")}</div>
                      </FormSectionCardTitle>
                    }
                    rows={getRows(user, errors, setFieldValue, idx)}
                    isEditing={true}
                  />
                </FormSectionCard>
              ))}
              <ButtonRow>
                <ButtonContainer>
                  <PrimaryButton
                    redTheme={true}
                    type="submit"
                    disabled={isSubmitting}
                    titleLabel={t("pages.addUser.formSection.buttonLabel")}
                    loading={isSubmitting}
                    safaricom={config?.theme === THEMES.SAFARICOM}
                  />
                </ButtonContainer>
              </ButtonRow>
            </FormContainer>
          </>
        )}
      />
    </>
  );
};

export default AddUserPage;

const FormTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
  margin-bottom: 32px;
  width: 50%;
  @media (max-width: 768px) {
    width: 75%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const IconContainer = styled("div")<{ color?: string; size?: string }>`
  width: ${props => (props.size ? props.size : "24px")};
  height: ${props => (props.size ? props.size : "24px")};
  stroke: ${props => (props.color ? props.color : props.theme.palette.vodafoneRed)};
  svg {
    width: ${props => (props.size ? props.size : "24px")};
    height: ${props => (props.size ? props.size : "24px")};
  }
`;

const FormContainer = styled("form")`
  margin-top: 156px;
  margin-left: 245px;
  margin-right: 288px;
  margin-bottom: 156px;
  @media (max-width: 1024px) {
    margin-left: 137px;
    margin-right: 24px;
  }
  @media (max-width: 768px) {
    margin-left: 94px;
    margin-right: 24px;
  }
`;

const FormSectionCardTitle = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > span {
    font-family: Vodafone Rg;
    font-size: 22px;
    color: ${props => props.theme.palette.darkGrey};
  }
`;

const FormSectionCard = styled("div")`
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  padding: 18px 24px;

  > div > div > span > div > div > button {
    display: none;
  }

  :hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);

    > div > div > span > div > div > button {
      display: flex;
    }
  }

  :not(:last-child) {
    margin-bottom: 24px;
  }

  div {
    border-bottom: none !important;
  }

  > div:first-child > div:first-child > span:first-child {
    width: 100%;
  }
`;
const ButtonRow = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;

  svg {
    stroke: ${props => props.theme.palette.vodafoneRed};
  }
`;

const ButtonContainer = styled("div")`
  width: fit-content;
  margin-left: auto;
`;
