import React from "react";

const AuditingIcon = (props: React.HTMLProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M21.5 5.5h-12m12 6.5h-12m-6-8.5h2c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1h-2c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1zm0 6.5h2c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1h-2c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1zm0 6.5h2c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1h-2c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1zm18 2h-12"
                  transform="translate(-23 -425) translate(18 125) translate(0 295) translate(5 5)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AuditingIcon;
