import React, { HTMLProps, ReactNode } from "react";
import styled from "styled-components";
import { SubBundleStatus } from "../../bundles.model";

export interface ISubBundleStatusChipProps extends HTMLProps<HTMLDivElement> {
  type: SubBundleStatus;
  children: ReactNode;
}

/**
 * Sub bundle status chip
 * @param param0
 */
const SubBundleStatusChip = ({ type, children, ref, as, ...rest }: ISubBundleStatusChipProps) => {
  return (
    <StatusChipContainer type={type} {...rest}>
      {children}
    </StatusChipContainer>
  );
};

export default SubBundleStatusChip;

const StatusChipContainer = styled("div")<{ type: SubBundleStatus }>`
  border-radius: 10px;
  padding-left: 12px;
  padding-right: 12px;
  height: 22px;
  line-height: 21px;
  font-weight: bold;
  display: inline-block;
  font-size: 14px;
  text-align: center;
  font-family: Vodafone Rg;

  ${props => {
    let backgroundColor = "";
    let fontColor = props.theme.palette.white;
    let borderColor = "";
    switch (props.type) {
      case SubBundleStatus.LIVE:
        backgroundColor = props.theme.palette.digitalGreen;
        borderColor = props.theme.palette.digitalGreen;
        break;
      case SubBundleStatus.DISABLE:
        backgroundColor = props.theme.palette.white;
        borderColor = props.theme.palette.midGrey;
        fontColor = props.theme.palette.midGrey;
        break;
      case SubBundleStatus.DISABLED:
        backgroundColor = props.theme.palette.midGrey;
        borderColor = props.theme.palette.midGrey;
        break;
      case SubBundleStatus.ENABLE:
      case SubBundleStatus.EDIT:
        backgroundColor = props.theme.palette.white;
        fontColor = props.theme.palette.digitalGreen;
        borderColor = props.theme.palette.digitalGreen;
        break;
      case SubBundleStatus.DELETE:
        backgroundColor = props.theme.palette.vodafoneRed;
        borderColor = props.theme.palette.vodafoneRed;
        break;
    }
    return `background-color: ${backgroundColor}; color: ${fontColor}; border: 1px solid ${borderColor}`;
  }}
`;
