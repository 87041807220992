import React from "react";

const ServiceAreaIcon = (props: React.HTMLProps<SVGSVGElement>) => {
  return (
    <svg width="21" height="21" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.52 2.767c.19.004.38.064.553.182l.08.06 5.598 4.513a.4.4 0 0 1-.442.663l-.06-.04L12.9 7.058v6.458c0 .355-.25.662-.59.71l-.096.007h-2.38v-3.666a.333.333 0 0 0-.334-.334h-2a.333.333 0 0 0-.333.334v3.666H4.786a.696.696 0 0 1-.68-.622l-.006-.095v-6.46L2.751 8.146a.4.4 0 0 1-.554-.573l.052-.05L7.845 3.01c.196-.16.419-.24.641-.243h.034z"
        fill="#2FC56D"
        fill-rule="evenodd"
        fill-opacity=".994"
      />
    </svg>
  );
};

export default ServiceAreaIcon;
