/**
 * This file used to to add, edit and view mini-app details.
 */

import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import {
  CancelIcon,
  CheckIcon,
  EditIcon,
  FormSection,
  ModalTypeEnum,
  MultipleOptionsDropdown,
  PrimaryButton,
  SecondaryPageTitle,
  TabChild,
  Tabs,
  TextInput,
  Toggle,
  TrashIcon,
  UndoIcon,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import MultipleOptionDropdown from "@wit/mpesa-ui-components/lib/components/dropdown/multiple-option-dropdown/multiple-option-dropdown.component";
import MultipleOptionNestedDropdown from "@wit/mpesa-ui-components/lib/components/dropdown/multiple-option-nested-dropdown/multiple-option-nested-dropdown.component";
import { SharedNestedOptionDropdown } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-nested-option-container/shared-dropdown-nested-option-container.component";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { FormSectionRow } from "@wit/mpesa-ui-components/lib/components/form-section/form-section.component";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikErrors, FormikHelpers } from "formik";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { object, string } from "yup";
import { ConfigContext } from "../../../../app.component";

import { RoutesEnum } from "../../../../routes/routes.constants";
import DisplayIconWithTooltip from "../../../../shared/components/display-icon-with-tooltip-component";
import LoadingComponent from "../../../../shared/components/loading-component/LoadingComponent";
import SafaricomActivateModal from "../../../../shared/components/safaricom-activate-modal/safaricom-activate-modal.component";
import SafaricomDeleteModal from "../../../../shared/components/safaricom-delete-modal/safaricom-delete-modal.component";
import useCheckFeatureAvailable from "../../../../shared/hooks/use-check-available-feature";
import HelpIcon from "../../../../shared/icons/help.icon";
import { ILanguage } from "../../../../shared/models/language.model";
import {
  IMiniAppRole,
  IServiceGroup,
  IServiceManagerServiceDetails,
  ServiceDetailsGroupsEnum,
  ServiceManagerStatusEnum,
} from "../../../../shared/models/service-manager.model";
import { FEATURES } from "../../../../shared/renderer.utils";
import { PageContent } from "../../../../shared/responsive-ui.styled";
import LanguagesApi from "../../../../shared/services/languages.api";
import { StatusType } from "../../../../shared/shared.enums";
import { FormSectionTitle, PageContainer } from "../../../../shared/shared.styled";
import {
  getColorStatus,
  getMiniAppRolesOptions,
  getServiceManagerStatusColor,
  removeDuplicateItemsFromList,
} from "../../../../shared/shared.utils";
import { FormLabel } from "../../../configurations/discover-cards/add-discover-card/add-discover-card.page";
import AllowedScopesApi from "../../allowed-scopes/allowed-scopes.api";
import { IAllowedScope } from "../../allowed-scopes/allowed-scopes.model";
import useServiceGroupsFilter from "../../components/use-service-groups-filter.hook";
import CategoriesApi from "../../pages/categories/categories.api";
import { ServiceManagerTabNumber } from "../../pages/safaricom-service-manager.page";
import ConfirmWithLoadingModal from "../../section-manager/confirm-with-loading-modal.component";
import { ServiceGroupbyName, UserType } from "../../section-manager/section-manager.interface";
import { LightFormLabel, LoadingContainer, NonEditableText } from "../../service-manager.styled";
import {
  getAllowedScopesOptions,
  getCategoriesOptions,
  getIDByServiceGroup,
  getSelectedCategoriesOptions,
} from "../../service-manager.utils";
import Dropzone from "../../utils/dropzone.component";
import ServiceManagerServiceDetailsApi from "../service-manager-service-details.api";
import {
  IUpdateServiceDetails,
  IUpdateServiceRequest,
  ServiceInputTabNumber,
} from "../service-manager-service-details.model";
import { ServiceManagerServiceDetailsActions } from "../service-manager-service-details.store";
import { IServiceFormDetails, ServiceType, TrustedRolesEnum } from "./details.interfaces";

import {
  Actions,
  AvatarContainer,
  AvatarDiv,
  ButtonDiv,
  ButtonsWrapper,
  ColorContainer,
  ColorValue,
  DescriptionContainer,
  DropzoneTitle,
  EditServiceFormRight,
  HelpIconContainer,
  IconContainer,
  ImageContainer,
  ImageError,
  LeftContent,
  LeftFormDiv,
  LinkContainer,
  MainContent,
  RightFormDiv,
  Separator,
  ServiceColor,
  TabContentContainer,
  TabsContainer,
  ToogleDiv,
  UserStatus,
  Value,
  ValueContainer,
} from "./service-manager-service-details.styled";
import ServiceManagerApi from "../../service-manager.api";
import { AxiosResponse } from "axios";

type TServiceData = IServiceManagerServiceDetails | IUpdateServiceRequest;

/**
 * Validation Schema
 */
const getServiceDetailsValidationSchema = () => {
  return object().shape({
    name: string().required(i18next.t("pages.serviceManager.serviceDetails.validateSchema.name")),
    id: string().required(i18next.t("commons.mandatoryField")),
    description: string(),
    color: string().required(i18next.t("commons.mandatoryField")),
    descriptionTranslations: object().nullable(),
    nameTranslations: object().nullable(),
    serviceIcon: string()
      .required(i18next.t("pages.serviceBuilder.modals.addService.icon.errorRequired"))
      .nullable(),
    serviceImage: string().nullable(),
  });
};

// TODO: Check the data types for the entire file

const MAX_FILE_SIZE = 2000000;
const SERVICE_INITIAL_VALUES = {
  id: "",
  creationDate: "",
  name: "",
  description: "",
  iconUrl: "",
  imageUrl: "",
  icon: "",
  image: "",
  color: "#000000",
  status: ServiceManagerStatusEnum.UNDEPLOYED,
  groups: [],
  trustedLevel: TrustedRolesEnum.UNTRUSTED,
  allowedScopes: [],
  allowedAgentRoles: [],
  allowedMerchantRoles: [],
  categories: [],
  nameTranslations: {},
  descriptionTranslations: {},
};

/** ServiceManagerDetail page */
const ServiceManagerServiceDetailsPage = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const { config } = useContext(ConfigContext);
  const { serviceId } = useParams<{ serviceId: string }>();
  const handleGroupsSelectedChanges: any = useRef(false);
  const isMounted = useRef(false);

  //* Redux hooks
  const dispatch = useDispatch();
  const [serviceDetails, setServiceDetails] = useState<IServiceManagerServiceDetails>(SERVICE_INITIAL_VALUES);

  //* useState hooks
  const [isEditing, setIsEditing] = useState(false);
  const [allowedScopes, setAllowedScopes] = useState<IAllowedScope[]>([]);
  const [isStarting, setIsStarting] = useState(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [categoriesNotSelected, setCategoriesNotSelected] = useState<boolean>(false);
  const [placementAreasNotSelected, setPlacementAreasNotSelected] = useState<boolean>(false);
  const [agentRolesSelected, setAgentRolesSelected] = useState<string[]>([]);
  const [merchantRolesSelected, setMerchantRolesSelected] = useState<string[]>([]);
  const [groupsSelected, setGroupsSelected] = useState<IServiceGroup[]>([]);
  const [iconFile, setIconFile] = useState<any>();
  const [icon, setIcon] = useState(serviceDetails?.icon);
  const [imageFile, setImageFile] = useState<any>();
  const [image, setImage] = useState(serviceDetails?.image);
  const [confirmModal, setConfirmModal] = useState<ReactNode>();
  const [merchantRoleOptions, setMerchantRoleOptions] = useState<IMiniAppRole[]>([]);
  const [agentRoleOptions, setAgentRoleOptions] = useState<IMiniAppRole[]>([]);
  const [categories, setCategories] = useState<SharedDropdownOption[]>([]);
  const [isFormReady, setIsFormReady] = useState(false);
  const [tabIndex, setTabIdx] = useState(ServiceInputTabNumber.NAME);
  const [availableLanguages, setAvailableLanguages] = useState<{ label: string; key: string }[]>([
    { label: t("commons.languages.en"), key: "en" },
  ]);
  const [defaultLanguage, setDefaultLanguage] = useState<{ label: string; key: string }>({
    label: t("commons.languages.en"),
    key: "en",
  });
  const [serviceDetailsInitialValues, setServiceDetailsInitialValues] = useState<IServiceManagerServiceDetails>(
    serviceDetails,
  );

  //* Custom hooks
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const serviceUserTypeEnabled = useCheckFeatureAvailable(FEATURES.SERVICE_USER_TYPE);
  const agentHubEnabled = useCheckFeatureAvailable(FEATURES.AGENT_HUB);
  const miniAppsConfigByRoleEnabled = useCheckFeatureAvailable(FEATURES.MINI_APPS_CONFIGURATION_ROLE);
  const addAndRemoveServicesEnabled = useCheckFeatureAvailable(FEATURES.ADD_AND_REMOVE_SERVICES);
  const serviceGroups: IServiceGroup[] = config?.serviceGroups || []; // static service groups
  const miniAppsCategoryEdit = useCheckFeatureAvailable(FEATURES.MINI_APPS_CATEGORY_EDIT);
  const { getServicesGroupOptions } = useServiceGroupsFilter();

  //* Modal hooks
  const [ShowActivateServiceModal, hideActivateServiceModal] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <SafaricomActivateModal
      isLoading={isLoading}
      secondaryAction={() => hideActivateServiceModal()}
      primaryAction={() => activateService()}
      title={t("pages.serviceManager.serviceDetails.activateModal.title")}
      description={t("pages.serviceManager.serviceDetails.activateModal.description")}
      primaryBtnLabel={t("pages.serviceManager.serviceDetails.activateModal.btnLabel")}
    />,
  );
  const [showDisableServiceModal, hideDisableServiceModal] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <SafaricomDeleteModal
      isLoading={isLoading}
      secondaryAction={() => hideDisableServiceModal()}
      primaryAction={() => disableService()}
      title={t("pages.serviceManager.serviceDetails.disableModal.title")}
      description={t("pages.serviceManager.serviceDetails.disableModal.description")}
      primaryBtnLabel={t("pages.serviceManager.serviceDetails.disableModal.btnLabel")}
    />,
  );
  const [showConfirmModal, hideConfirmModal] = useModal(ModalTypeEnum.CustomModal, undefined, confirmModal);
  const [showDeleteModal, hideDeleteModal, setDeleteProps] = useModal(ModalTypeEnum.ConfirmationModal);

  /**
   * Show delete modal function
   */
  const _showDeleteModal = () => {
    setDeleteProps({
      secondaryAction: () => {
        hideDeleteModal();
      },
      primaryAction: () => {
        deleteServiceRequest();
      },
      title: t("pages.serviceManager.serviceDetails.deleteModal.title"),
      description: t("pages.serviceManager.serviceDetails.deleteModal.description"),
      primaryBtnLabel: t("pages.serviceManager.serviceDetails.deleteModal.btnLabel"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      primaryBtnMaxWidth: 160,
    });
    showDeleteModal();
  };

  /**
   * Service translations inputs
   * @param {IServiceFormDetails} values
   * @param {ChangeEventHandler<HTMLInputElement> | undefined} handleChange
   * @param {FormikErrors<ICategory>} errors
   * @returns {{displayComponent: JSX.Element, label: JSX.Element, editingComponent: JSX.Element, required: boolean}[]}
   */
  const getTranslationsInputs = (
    handleChange: React.ChangeEventHandler<HTMLInputElement> | undefined,
    errors: FormikErrors<IServiceFormDetails>,
    inputName: string,
    langs: any,
    maxLength: number,
  ) => {
    return availableLanguages.map((lang, idx) => {
      return {
        label: (
          <FormLabel id={`${inputName}-${lang.key}`}>
            {inputName === "nameTranslations"
              ? t("pages.serviceManager.serviceDetails.formRows.name.label")
              : t("pages.serviceManager.serviceDetails.formRows.description.label")}{" "}
            <LightFormLabel>({lang.label})</LightFormLabel>
          </FormLabel>
        ),
        displayComponent: <NonEditableText id={`${inputName}-${lang.key}`}>{langs![lang.key]}</NonEditableText>,
        editingComponent: (
          <TextInput
            id={`${inputName}-${lang.key}`}
            name={`${inputName}.${lang.key}`}
            value={langs![lang.key]}
            onChange={handleChange}
            placeholder={t("pages.serviceManager.categories.categoryForm.categoryNameTranslationPlaceholder", {
              lang: lang.label,
            })}
            error={
              errors[`${inputName}` as keyof IServiceFormDetails] &&
              (((errors[`${inputName}` as keyof IServiceFormDetails] as unknown) as { [x: string]: string })[
                lang.key
              ] as string)
            }
            maxLength={maxLength}
          />
        ),
      };
    });
  };

  /**
   * function go back
   */
  const goBack = () => {
    history.push(`${RoutesEnum.SERVICE_MANAGER}?tabIdx=${ServiceManagerTabNumber.SERVICE_LIST}`);
  };

  /**
   * Delete service modal function
   */
  const deleteService = () => {
    _showDeleteModal();
  };

  /**
   * Delete service function
   */
  const deleteServiceRequest = () => {
    ServiceManagerServiceDetailsApi.methods
      .deleteServiceManagerServiceDetails(serviceDetails!.id)
      .then(
        () => {
          setAlertProps({
            title: t("pages.serviceManager.serviceDetails.success.deletingService"),
            type: AlertTypeEnum.SUCCESS,
          });
          hideDeleteModal();
          showAlert();
          goBack();
        },
        () => {
          setAlertProps({
            title: t("pages.serviceManager.serviceDetails.errors.deletingService"),
            type: AlertTypeEnum.ERROR,
          });
          hideDeleteModal();
          showAlert();
        },
      )
      .finally(() => {
        hideDeleteModal();
      });
  };

  /**
   * Set initial value for selected mini app roles from BE data
   */
  const setInitialSelectedRolesFromBE = () => {
    setMerchantRolesSelected(serviceDetails?.allowedMerchantRoles || []);
    setAgentRolesSelected(serviceDetails?.allowedAgentRoles || []);
  };

  /**
   * Validates if the roles selection are valid
   * @returns
   */
  const validateRolesFields = useCallback((): boolean => {
    let isRolesValid = true;
    if (groupsSelected.find(c => c.label === ServiceDetailsGroupsEnum.AGENT_HUB) && agentRolesSelected.length === 0) {
      isRolesValid = false;
      setAlertProps({
        title: t("pages.serviceManager.serviceDetails.errors.agentRolesErrorMin"),
        type: AlertTypeEnum.ERROR,
      });
      showAlert();
    }

    if (
      groupsSelected.find(c => c.label === ServiceDetailsGroupsEnum.MERCHANT_HUB) &&
      merchantRolesSelected.length === 0
    ) {
      isRolesValid = false;
      setAlertProps({
        title: t("pages.serviceManager.serviceDetails.errors.merchantRolesErrorMin"),
        type: AlertTypeEnum.ERROR,
      });
      showAlert();
    }

    return isRolesValid;
  }, [groupsSelected, merchantRolesSelected, agentRolesSelected]);

  /**
   * method to get the allowed scopes list
   */
  const getAllowedScopes = () => {
    AllowedScopesApi.methods.getAllowedScopes().then(
      res => {
        setAllowedScopes(res.data);
      },
      () => {
        setAlertProps({
          title: t("pages.serviceManager.allowedScopes.getAllowedScopesError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  //* Runs on mount for service details of serviceId
  const refreshPage = useCallback(() => {
    ServiceManagerServiceDetailsApi.methods.getServiceManagerServiceDetails(serviceId).then(
      res => {
        setServiceDetails(res.data);
        dispatch(ServiceManagerServiceDetailsActions.creators.fetchServiceDetailsSuccessAction(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.serviceBuilder.errors.noServiceDetails"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        history.push(RoutesEnum.SERVICE_MANAGER);
      },
    );
  }, [dispatch, serviceId, setAlertProps, showAlert, t]);

  /**
   * method to check if option is selected
   */
  const isOptionSelected = (opt?: SharedDropdownOption) => {
    const arrayOps: string[] = [];

    if (isStarting) {
      if (serviceDetails.groups) {
        serviceDetails.groups.forEach(element => {
          if (element.name && element.order && element.serviceType) {
            if (
              element.name === ServiceGroupbyName.DISCOVER &&
              element.serviceType === ServiceType.ORG &&
              agentHubEnabled &&
              element.userType === UserType.AGENT
            ) {
              const serviceGroupID = getIDByServiceGroup(serviceGroups, element);
              if (serviceGroupID) {
                arrayOps.push(serviceGroupID);
              }
            }
          }
        });
        handleGroupsSelectedChanges.current = !!opt;
        setGroupsSelected([...groupsSelected, ...serviceGroups.filter(item => arrayOps.includes(item.id))]);
        setIsStarting(false);
      }
    }

    if (opt) {
      return [...groupsSelected, ...serviceGroups.filter(item => arrayOps.includes(item.id))].some(
        item => item.id === opt.key,
      );
    }
  };

  /**
   * method to get the dropdown label
   */
  const getDropdownLabelSelectedOptions = (selectedOptions: any[], options?: IMiniAppRole[]) => {
    if (options?.length === 0) {
      return "-";
    } else if (selectedOptions.length === options?.length) {
      return t("pages.serviceManager.serviceDetails.allRoles");
    } else if (selectedOptions.length > 0) {
      const selectedOptionLabel = options?.filter(item => selectedOptions.includes(item.id)).map(item => item.name);
      return selectedOptionLabel ? selectedOptionLabel.join(", ") : "-";
    } else {
      return "-";
    }
  };

  /**
   * method to check if option is selected
   */
  const isAlreadyOptionSelected = (selectedOptions: any[], opt: SharedDropdownOption) => {
    return selectedOptions.includes(opt.key);
  };

  /**
   * method to toggle an option
   */
  const toggleOptionHandle = (
    selectedOptions: any[],
    opt: SharedDropdownOption,
    setGroup: (value: React.SetStateAction<any[]>) => void,
  ) => {
    if (isAlreadyOptionSelected(selectedOptions, opt)) {
      setGroup(selectedOptions.filter(c => c !== opt.key));
    } else {
      setGroup([...selectedOptions, opt.key]);
    }
  };

  /**
   * method to get the dropdown label
   */
  const getDropdownLabel = () => {
    isOptionSelected();
    if (groupsSelected.length > 0) {
      return groupsSelected
        .map(item =>
          serviceGroups.find(el => el.id === item.id) ? serviceGroups.find(el => el.id === item.id)?.label : "",
        )
        .join(", ");
    } else {
      return t("pages.serviceManager.serviceDetails.formRows.group.placeholder");
    }
  };

  /**
   * method to toggle an option
   */
  const toggleOption = (opt: SharedNestedOptionDropdown) => {
    setPlacementAreasNotSelected(false);
    let groupSelected: IServiceGroup[] = [];
    if (opt.children) {
      if (opt.children.every(item => groupsSelected.find(element => element.id === item.key))) {
        groupSelected = groupsSelected.filter(c => c.serviceType !== opt.key);
      } else {
        groupSelected = removeDuplicateItemsFromList([
          ...groupsSelected,
          ...serviceGroups.filter(item => item.serviceType === opt.key),
        ]);
      }
    } else {
      if (isOptionSelected(opt)) {
        groupSelected = groupsSelected.filter(c => c.id !== opt.key);
      } else {
        groupSelected = [...groupsSelected, ...serviceGroups.filter(item => item.id === opt.key)];
      }
    }
    if (miniAppsCategoryEdit) {
      if (!groupSelected || groupSelected.length === 0) {
        setPlacementAreasNotSelected(true);
      }
    }
    setGroupsSelected(groupSelected);
  };

  /**
   * method to return group options
   */
  const groupsDisplay = () => {
    let optionsSelected = "";
    serviceDetails.groups.forEach(element => {
      if (element.order !== null) {
        if (
          element.name === ServiceGroupbyName.DISCOVER &&
          element.serviceType === ServiceType.ORG &&
          agentHubEnabled &&
          element.userType === UserType.AGENT
        ) {
          optionsSelected += `${ServiceDetailsGroupsEnum.AGENT_HUB}, `;
        } else {
          optionsSelected += `${
            serviceGroups.find(el => el.id === getIDByServiceGroup(serviceGroups, element))?.label
          }, `;
        }
      }
    });
    if (serviceDetails?.groups?.length) {
      if (optionsSelected.endsWith(", ")) {
        optionsSelected = optionsSelected.substr(0, optionsSelected.length - 2);
      }
      if (optionsSelected.length === 0) {
        optionsSelected = "-";
      }
    } else {
      optionsSelected = "-";
    }
    return (
      <ValueContainer>
        <Value>{optionsSelected}</Value>
      </ValueContainer>
    );
  };

  /**
   * Method to return displayed categories in dropdown options
   * @param selectedCategories
   * @returns Styled Element
   */
  const categoriesDisplay = (selectedCategories: string[] | undefined) => {
    const optionsSelected = getSelectedCategoriesOptions(selectedCategories, categories);
    return (
      <ValueContainer>
        <Value>{optionsSelected}</Value>
      </ValueContainer>
    );
  };

  /**
   * Method to return displayed categories in dropdown textbox
   * @param selectedCategories
   * @returns string
   */
  const getCategoriesLabel = (selectedCategories: string[] | undefined): string => {
    if (selectedCategories && selectedCategories.length > 0) {
      return getSelectedCategoriesOptions(selectedCategories, categories);
    }
    return t("pages.serviceManager.serviceDetails.formRows.category.placeholder");
  };

  /** function to displayStatus */
  const displayStatus = () => {
    if (serviceDetails.status === ServiceManagerStatusEnum.LIVE) {
      return (
        <UserStatus color={getServiceManagerStatusColor("LIVE")}>
          {t("pages.serviceManager.serviceDetails.statusColor.live")}
        </UserStatus>
      );
    } else {
      return (
        <UserStatus color={getServiceManagerStatusColor("DISABLED")}>
          {t("pages.serviceManager.serviceDetails.statusColor.disabled")}
        </UserStatus>
      );
    }
  };

  /** function to display TrustLeveç */
  const displayTrustedLevel = (values: TrustedRolesEnum) => {
    return (
      <ToogleDiv isEditing={isEditing}>
        <Toggle
          key={`tooglePush${isEditing}`}
          cb={() => {}}
          isDisabled={!isEditing}
          initialValue={getTrustedLevelBooleanValue(values)}
        />
      </ToogleDiv>
    );
  };

  /** function to get TrustedRolesEnum value of TrustLevel */
  const getTrustedLevelEnumValue = (value: boolean) => {
    if (value) {
      return TrustedRolesEnum.TRUSTED;
    } else {
      return TrustedRolesEnum.UNTRUSTED;
    }
  };

  /** function to get boolean value of TrustLevel */
  const getTrustedLevelBooleanValue = (value: TrustedRolesEnum) => {
    if (value?.toUpperCase() === TrustedRolesEnum.TRUSTED.toUpperCase()) {
      return true;
    } else {
      return false;
    }
  };

  /** function to activateService */
  const activateService = () => {
    setIsLoading(true);
    ServiceManagerServiceDetailsApi.methods
      .updateServiceManagerServiceStatus(serviceDetails.id)
      .then(
        () => {
          setAlertProps({
            title: t("pages.serviceManager.serviceDetails.success.updatingService"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          dispatch(ServiceManagerServiceDetailsActions.creators.updateServiceManagerServiceStatus(serviceDetails.id));
          ServiceManagerServiceDetailsActions.creators.fetchingServiceDetailsAction();
          ServiceManagerServiceDetailsApi.methods.getServiceManagerServiceDetails(serviceId).then(res => {
            setServiceDetails(res.data);
          });
        },
        () => {
          setAlertProps({
            title: t("pages.serviceManager.serviceDetails.errors.updatingService"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        setIsLoading(false);
        hideActivateServiceModal();
      });
  };

  /** function to disable Servicerequest */
  const disableService = () => {
    setIsLoading(true);
    ServiceManagerServiceDetailsApi.methods
      .updateServiceManagerServiceStatus(serviceDetails.id)
      .then(
        () => {
          setAlertProps({
            title: t("pages.serviceManager.serviceDetails.success.updatingServiceDisabled"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          dispatch(ServiceManagerServiceDetailsActions.creators.updateServiceManagerServiceStatus(serviceDetails.id));
          ServiceManagerServiceDetailsActions.creators.fetchingServiceDetailsAction();
          ServiceManagerServiceDetailsApi.methods.getServiceManagerServiceDetails(serviceId).then(res => {
            setServiceDetails(res.data);
            dispatch(ServiceManagerServiceDetailsActions.creators.fetchServiceDetailsSuccessAction(res.data));
          });
        },
        () => {
          setAlertProps({
            title: t("pages.serviceManager.serviceDetails.errors.updatingServiceDisabled"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        setIsLoading(false);
        hideDisableServiceModal();
      });
  };

  const onDrop = useCallback(
    acceptedIcon => {
      if (acceptedIcon.length > 0) {
        const file: File = acceptedIcon[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = function() {
          if (reader.result) {
            setIconFile(file);
            setIcon(reader.result.toString().split(",")[1]);
          }
        };
      }
    },
    [iconFile, icon],
  );

  const onDropImage = useCallback(
    acceptedImage => {
      if (acceptedImage.length > 0) {
        const file: File = acceptedImage[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = function() {
          if (reader.result) {
            setImageFile(file);
            setImage(reader.result.toString().split(",")[1]);
          }
        };
      }
    },
    [imageFile, image],
  );

  /**
   * Updates the service data
   * @param serviceDetails
   * @returns
   */
  const updateServiceDetails = (serviceInfo: IUpdateServiceDetails, actions: FormikHelpers<any>) =>
    ServiceManagerServiceDetailsApi.methods
      .updateServiceManagerServiceDetails(
        serviceInfo.serviceDetailsId,
        serviceInfo.imageFile,
        serviceInfo.iconFile,
        serviceInfo.updateDetails,
      )
      .then(
        () => {
          setAlertProps({
            title: t("pages.serviceManager.serviceDetails.success.updatingServiceDetails"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          setIsFormReady(false);
          ServiceManagerServiceDetailsApi.methods.getServiceManagerServiceDetails(serviceId).then(res => {
            setServiceDetails(res.data);
            setIsEditing(false);
          });
        },
        () => {
          setAlertProps({
            title: t("pages.serviceManager.serviceDetails.errors.updatingServiceDetails"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        actions.setSubmitting(false);
        hideActivateServiceModal();
        hideConfirmModal();
      });

  /**
   * returns array of actual selectedAllowedValues keys
   * @param selectedAllowedScopes
   */
  const getSelectedAllowedScopeKeys = (selectedAllowedScopes: string | any[]) => {
    return getAllowedScopesOptions(allowedScopes, groupsSelected)
      .map(item => {
        if (item && selectedAllowedScopes && selectedAllowedScopes.includes(item.key) && item.label !== "") {
          return item.key;
        }
      })
      .filter(item => item !== undefined);
  };

  /**
   * getAllowedScopeName method
   */
  const getAllowedScopeName = (allowedScopesIds: string[]) => {
    const scopesLabel = [] as string[];
    allowedScopes.forEach(scope => {
      allowedScopesIds.forEach(id => {
        if (scope.id === id) {
          scopesLabel.push(scope.name);
        }
      });
    });
    return scopesLabel.join(", ");
  };

  /**
   * returns array of actual selectedCategoriesValues keys
   * @param selectedCategories
   */
  const getSelectedCategoriesKeys = (selectedCategories: string[]) => {
    return getCategoriesOptions(categories)
      .map(item => {
        if (item && selectedCategories && selectedCategories.includes(item.key) && item.label !== "") {
          return item.key;
        }
      })
      .filter(item => item !== undefined);
  };

  /**
   * This method load all categories
   */
  const loadCategories = () => {
    CategoriesApi.methods.getCategories().then(
      res => {
        if (res.data && res.data.length > 0) {
          const categoriesSorted = res.data.sort((a, b) => a.categoryName.localeCompare(b.categoryName));
          setCategories(
            categoriesSorted.map(item => {
              return {
                key: item.id,
                label: item.categoryName,
              };
            }),
          );
        }
      },
      () => {
        setAlertProps({
          title: t("pages.generalCategories.getCategoriesError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * This compose the form rows
   * @param values
   * @param errors
   * @param setFieldValue
   */
  const getFormRows = (
    values: IServiceFormDetails,
    errors: FormikErrors<IServiceFormDetails>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    handleChange: (e: React.ChangeEvent<any>) => void,
  ): FormSectionRow[] => {
    const form: FormSectionRow[] = [];
    form.push({
      label: (
        <DropzoneTitle mandatory={true}>
          {t("pages.serviceManager.serviceDetails.formRows.name.label")}&nbsp;
          {availableLanguages.length > 1 && <LightFormLabel>({defaultLanguage.label})</LightFormLabel>}
        </DropzoneTitle>
      ),
      displayComponent: serviceDetails.name,
      editingComponent: (
        <TextInput
          name="name"
          value={values.name}
          error={errors.name}
          onChange={handleChange}
          placeholder={t("pages.serviceManager.serviceDetails.formRows.name.placeholder")}
          required
          maxLength={46}
        />
      ),
    });
    form.push({
      label: (
        <DropzoneTitle mandatory={false}>
          {t("pages.serviceManager.serviceDetails.formRows.description.label")}&nbsp;
          {availableLanguages.length > 1 && <LightFormLabel>({defaultLanguage.label})</LightFormLabel>}
        </DropzoneTitle>
      ),

      displayComponent: (
        <ValueContainer>
          <DescriptionContainer>
            <Value>{serviceDetails.description}</Value>
          </DescriptionContainer>
        </ValueContainer>
      ),
      editingComponent: (
        <TextInput
          name="description"
          value={values.description}
          onChange={handleChange}
          placeholder={t("pages.serviceManager.serviceDetails.formRows.description.placeholder")}
          maxLength={100}
        />
      ),
    });
    form.push({
      label: (
        <DropzoneTitle mandatory={true}>{t("pages.serviceManager.serviceDetails.formRows.antID.label")}</DropzoneTitle>
      ),
      displayComponent: serviceDetails.id,
      editingComponent: isAddMode ? (
        <TextInput
          name="id"
          maxLength={16}
          value={values.id}
          onChange={handleChange}
          placeholder={t("pages.serviceManager.serviceDetails.formRows.antID.placeholder")}
          error={errors.id || ""}
          required
        />
      ) : (
        <NonEditableText>{serviceDetails.id}</NonEditableText>
      ),
    });
    form.push({
      label: (
        <DropzoneTitle mandatory={true}>{t("pages.serviceManager.serviceDetails.formRows.color.label")}</DropzoneTitle>
      ),
      displayComponent: (
        <ColorContainer>
          <div style={{ width: "87px" }}>
            <ColorValue>{serviceDetails.color.toUpperCase()}</ColorValue>
          </div>
          <ServiceColor color={serviceDetails.color} />
        </ColorContainer>
      ),
      editingComponent: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ width: "87px" }}>
            <TextInput
              name="color"
              value={values?.color?.toUpperCase()}
              onChange={handleChange}
              placeholder={t("pages.serviceManager.serviceDetails.formRows.color.placeholder")}
              error={errors.color}
              required
            />
          </div>
          <ServiceColor color={values.color} />
        </div>
      ),
      required: true,
    });
    form.push({
      label: (
        <DropzoneTitle mandatory={miniAppsCategoryEdit}>
          {t("pages.serviceManager.serviceDetails.formRows.group.label")}
        </DropzoneTitle>
      ),
      displayComponent: groupsDisplay(),
      editingComponent: (
        <div>
          <div
            style={{
              border: placementAreasNotSelected ? "solid red 1px" : "",
              borderRadius: "7px",
            }}
          >
            <MultipleOptionNestedDropdown
              label={getDropdownLabel()}
              dropdownType={DropdownType.RECTANGULAR_NORMAL}
              hasValue={!!groupsSelected && groupsSelected.length > 0}
              toggleOption={opt => {
                toggleOption(opt);
                setFieldValue("allowedScopes", getSelectedAllowedScopeKeys(values.allowedScopes));
              }}
              isOptionSelected={opt => isOptionSelected(opt)}
              options={getServicesGroupOptions()}
              clearAllFilters={() => setGroupsSelected([])}
            />
          </div>
          {placementAreasNotSelected ? (
            <span
              style={{
                fontFamily: "Vodafone Rg",
                fontSize: "14px",
                color: "#ff2a58",
                marginTop: "4px",
              }}
            >
              {t("pages.serviceManager.serviceDetails.validateSchema.servicePlacement")}
            </span>
          ) : (
            ""
          )}
        </div>
      ),
    });
    if (miniAppsCategoryEdit) {
      form.push({
        label: (
          <DropzoneTitle mandatory={true}>
            {t("pages.serviceManager.serviceDetails.formRows.category.label")}
          </DropzoneTitle>
        ),
        displayComponent: categoriesDisplay(values.categories),
        editingComponent: (
          <div>
            <div
              style={{
                border: categoriesNotSelected ? "solid red 1px" : "",
                borderRadius: "7px",
              }}
            >
              <MultipleOptionDropdown
                dropdownType={DropdownType.RECTANGULAR_NORMAL}
                label={getCategoriesLabel(values.categories)}
                hasValue={!!values.categories && values.categories.length > 0}
                options={categories}
                toggleOption={(opt: SharedDropdownOption) => {
                  setCategoriesNotSelected(false);
                  if (values.categories && values.categories.includes(opt.key)) {
                    const index = values.categories.indexOf(opt.key);
                    const valuesHelper = [...values.categories];
                    valuesHelper.splice(index, 1);
                    setFieldValue("categories", getSelectedCategoriesKeys(valuesHelper));
                    if (valuesHelper.length === 0) {
                      setCategoriesNotSelected(true);
                    }
                  } else {
                    const categoryList = values.categories || [];
                    setFieldValue("categories", getSelectedCategoriesKeys([...categoryList, opt.key]));
                  }
                }}
                isOptionSelected={(opt: SharedDropdownOption) => {
                  return values.categories && values.categories.length > 0
                    ? values.categories.includes(opt.key)
                    : false;
                }}
                clearAllFilters={() => {
                  setFieldValue("categories", []);
                }}
                error={errors.categories ? t("commons.mandatoryField") : undefined}
              />
            </div>
            {categoriesNotSelected ? (
              <span
                style={{
                  fontFamily: "Vodafone Rg",
                  fontSize: "14px",
                  color: "#ff2a58",
                  marginTop: "4px",
                }}
              >
                {t("pages.serviceManager.serviceDetails.validateSchema.categories")}
              </span>
            ) : (
              ""
            )}
          </div>
        ),
      });
    }

    if (miniAppsConfigByRoleEnabled && groupsSelected.find(item => item.label === ServiceDetailsGroupsEnum.AGENT_HUB)) {
      form.push({
        label: (
          <>
            <DropzoneTitle mandatory={true}>
              {t("pages.serviceManager.serviceDetails.formRows.agentRole.label")}
              {agentRoleOptions.length === 0 ? (
                <HelpIconContainer>
                  <DisplayIconWithTooltip
                    toolTipText={t("pages.serviceBuilder.errors.noMiniAppRolesTryRefresh")}
                    tooltipId={"agent-roles"}
                    containerColor={"transparent"}
                  >
                    <HelpIcon></HelpIcon>
                  </DisplayIconWithTooltip>
                </HelpIconContainer>
              ) : (
                ""
              )}
            </DropzoneTitle>
          </>
        ),
        displayComponent: (
          <ValueContainer>
            <Value>{getDropdownLabelSelectedOptions(agentRolesSelected, agentRoleOptions)}</Value>
          </ValueContainer>
        ),
        editingComponent:
          agentRoleOptions.length > 0 ? (
            <MultipleOptionsDropdown
              label={getDropdownLabelSelectedOptions(agentRolesSelected, agentRoleOptions)}
              dropdownType={DropdownType.RECTANGULAR_NORMAL}
              hasValue={false}
              toggleOption={opt => {
                toggleOptionHandle(agentRolesSelected, opt, setAgentRolesSelected);
              }}
              isOptionSelected={opt => isAlreadyOptionSelected(agentRolesSelected, opt)}
              options={getMiniAppRolesOptions(agentRoleOptions)}
              clearAllFilters={() => setAgentRolesSelected([])}
            />
          ) : (
            <>
              <ValueContainer>
                <Value>{getDropdownLabelSelectedOptions(agentRolesSelected, agentRoleOptions)}</Value>
              </ValueContainer>
            </>
          ),
      });
    }

    if (
      miniAppsConfigByRoleEnabled &&
      groupsSelected.find(item => item.label === ServiceDetailsGroupsEnum.MERCHANT_HUB)
    ) {
      form.push({
        label: (
          <DropzoneTitle mandatory={true}>
            {t("pages.serviceManager.serviceDetails.formRows.merchantRole.label")}
            {merchantRoleOptions.length === 0 ? (
              <HelpIconContainer>
                <DisplayIconWithTooltip
                  toolTipText={t("pages.serviceBuilder.errors.noMiniAppRolesTryRefresh")}
                  tooltipId={"agent-roles"}
                  containerColor={"transparent"}
                >
                  <HelpIcon></HelpIcon>
                </DisplayIconWithTooltip>
              </HelpIconContainer>
            ) : (
              ""
            )}
          </DropzoneTitle>
        ),
        displayComponent: (
          <ValueContainer>
            <Value>{getDropdownLabelSelectedOptions(merchantRolesSelected, merchantRoleOptions)}</Value>
          </ValueContainer>
        ),
        editingComponent:
          merchantRoleOptions.length > 0 ? (
            <MultipleOptionsDropdown
              label={getDropdownLabelSelectedOptions(merchantRolesSelected, merchantRoleOptions)}
              dropdownType={DropdownType.RECTANGULAR_NORMAL}
              hasValue={!!merchantRolesSelected && merchantRolesSelected.length > 0}
              toggleOption={opt => {
                toggleOptionHandle(merchantRolesSelected, opt, setMerchantRolesSelected);
              }}
              isOptionSelected={opt => isAlreadyOptionSelected(merchantRolesSelected, opt)}
              options={getMiniAppRolesOptions(merchantRoleOptions)}
              clearAllFilters={() => setMerchantRolesSelected([])}
            />
          ) : (
            <ValueContainer>
              <Value>{getDropdownLabelSelectedOptions(merchantRolesSelected, merchantRoleOptions)}</Value>
            </ValueContainer>
          ),
      });
    }

    form.push({
      label: (
        <DropzoneTitle mandatory={false}>
          {t("pages.serviceManager.serviceDetails.formRows.allowedScopes.label")}
        </DropzoneTitle>
      ),
      displayComponent: (
        <ValueContainer>
          <Value>{values.allowedScopes ? getAllowedScopeName(values.allowedScopes) : null}</Value>
        </ValueContainer>
      ),
      editingComponent: (
        <MultipleOptionDropdown
          dropdownType={DropdownType.RECTANGULAR_NORMAL}
          label={
            values.allowedScopes &&
            getSelectedAllowedScopeKeys(values.allowedScopes).length > 0 &&
            getAllowedScopeName(getSelectedAllowedScopeKeys(values.allowedScopes))
              ? getAllowedScopeName(getSelectedAllowedScopeKeys(values.allowedScopes))
              : t("pages.serviceManager.serviceDetails.formRows.allowedScopes.placeholder")
          }
          hasValue={values.allowedScopes && values.allowedScopes.length > 0}
          options={getAllowedScopesOptions(allowedScopes, groupsSelected)}
          toggleOption={(opt: SharedDropdownOption) => {
            if (values.allowedScopes && values.allowedScopes.includes(opt.key)) {
              const index = values.allowedScopes.indexOf(opt.key);
              const valuesHelper = [...values.allowedScopes];

              valuesHelper.splice(index, 1);

              setFieldValue("allowedScopes", getSelectedAllowedScopeKeys(valuesHelper));
            } else {
              const allowedScopes = values.allowedScopes ? values.allowedScopes : [];
              setFieldValue("allowedScopes", getSelectedAllowedScopeKeys([...allowedScopes, opt.key]));
            }
          }}
          isOptionSelected={(opt: SharedDropdownOption) => {
            return values.allowedScopes && values.allowedScopes.length > 0
              ? values.allowedScopes.includes(opt.key)
              : false;
          }}
          clearAllFilters={() => {
            setFieldValue("allowedScopes", []);
          }}
          error={errors.allowedScopes ? t("commons.mandatoryField") : undefined}
        />
      ),
    });
    form.push({
      label: (
        <DropzoneTitle mandatory={false}>{t("pages.serviceManager.serviceDetails.formRows.trust.label")}</DropzoneTitle>
      ),
      displayComponent: displayTrustedLevel(values.trustedLevel),
      editingComponent: (
        <ToogleDiv isEditing={isEditing || !isAddMode}>
          <Toggle
            key={`tooglePush${isEditing}`}
            cb={(newValue: boolean) => {
              setFieldValue("trustedLevel", getTrustedLevelEnumValue(newValue));
            }}
            isDisabled={!isEditing || isAddMode}
            initialValue={getTrustedLevelBooleanValue(values.trustedLevel)}
          />
        </ToogleDiv>
      ),
    });
    if (!isAddMode) {
      form.push({
        label: t("pages.serviceManager.serviceDetails.formRows.status.label"),
        displayComponent: displayStatus(),
        editingComponent: <NonEditableText>{displayStatus()}</NonEditableText>,
      });
    }

    return form;
  };

  /**
   * Get Agent and Merchant mini-apps roles
   */
  const getAgentAndMerchantMiniAppsRoles = () => {
    if (miniAppsConfigByRoleEnabled) {
      ServiceManagerServiceDetailsApi.methods.getMiniAppsRole(UserType.AGENT).then(
        res => {
          setAgentRoleOptions(res.data);
        },
        () => {
          setAlertProps({
            title: t("pages.serviceBuilder.errors.noMiniAppRoles"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
      ServiceManagerServiceDetailsApi.methods.getMiniAppsRole(UserType.MERCHANT).then(
        res => {
          setMerchantRoleOptions(res.data);
        },
        () => {
          setAlertProps({
            title: t("pages.serviceBuilder.errors.noMiniAppRoles"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
    }
  };

  /**
   * Get available languages
   */
  const getAvailableLanguages = () => {
    LanguagesApi.methods
      .getAvailableLanguages()
      .then(
        res => {
          let initialValuesHelper = {
            ...serviceDetails,
            nameTranslations: { ...serviceDetails.nameTranslations },
            descriptionTranslations: { ...serviceDetails.descriptionTranslations },
          };

          // inits the object when we can have more than the base language
          if (initialValuesHelper.nameTranslations === undefined && res.data.availableLanguages.length > 1) {
            initialValuesHelper = { ...initialValuesHelper, nameTranslations: {} };
          }

          if (initialValuesHelper.descriptionTranslations === undefined && res.data.availableLanguages.length > 1) {
            initialValuesHelper = { ...initialValuesHelper, descriptionTranslations: {} };
          }

          if (res.data.availableLanguages.length > 0) {
            setDefaultLanguage({
              label: t(`commons.languages.${res.data.availableLanguages[0].code}`),
              key: res.data.availableLanguages[0].code,
            });
          }
          setAvailableLanguages(
            res.data.availableLanguages.slice(1).map((lang: ILanguage) => {
              initialValuesHelper.nameTranslations![lang.code] = initialValuesHelper.nameTranslations![lang.code] ?? "";
              initialValuesHelper.descriptionTranslations![lang.code] =
                initialValuesHelper.descriptionTranslations![lang.code] ?? "";

              return {
                label: t(`commons.languages.${lang.code}`),
                key: lang.code,
              };
            }),
          );
          // setIsFormReady(true);
          setServiceDetailsInitialValues(initialValuesHelper);
        },
        () => {},
      )
      .finally(() => {
        setIsFormReady(true);
      });
  };

  const isAddMode = useMemo(() => {
    return history.location.pathname.includes("add");
  }, [history.location.pathname]);

  /**
   * Get form ready
   */
  const getFormReadyForAddMode = () => {
    if (!isAddMode) {
      return;
    }

    setIsEditing(true);
    setIsFormReady(true);
  };

  /**
   *
   */
  const addServicePlacementForAddMode = async (serviceId: string, actions: FormikHelpers<any>) => {
    actions.setSubmitting(true);

    /**
     * Check if the current group is selected
     */
    const isCurrentGroupSelected = (staticGroup: IServiceGroup) => {
      return groupsSelected.some(selectedGroup => selectedGroup.id === staticGroup.id);
    };

    const promisesList = [];
    for (const staticGroup of serviceGroups) {
      if (isCurrentGroupSelected(staticGroup)) {
        const addRequest = ServiceManagerServiceDetailsApi.methods.updateServiceManagerServiceAddGroup(
          serviceId,
          staticGroup.value,
          staticGroup.serviceType as ServiceType,
          serviceUserTypeEnabled ? (staticGroup.userType as UserType) : undefined,
        );
        promisesList.push(addRequest);
      }

      //* Add groups to service
      Promise.all(promisesList)
        .then(res => {
          setAlertProps({
            title: t("pages.antServiceManager.serviceAdd.success.addingService"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
        })
        .catch(error => {
          let errorMessage = "pages.serviceManager.serviceDetails.updateError";
          let errorContent = "";
          if (error?.data?.status?.httpCode === 400 && error?.data?.status?.businessCode === 112) {
            errorMessage = "pages.serviceManager.serviceDetails.updateErrorMaximumItems";
            errorContent = "pages.serviceManager.serviceDetails.cannotAddMoreServices";
          }

          setAlertProps({
            title: t(errorMessage),
            type: AlertTypeEnum.ERROR,
            content: t(errorContent),
          });
          showAlert();
        })
        .finally(() => {
          actions.setSubmitting(false);
          hideConfirmModal();
          setIsFormReady(true);
          history.push(RoutesEnum.SERVICE_MANAGER);
        });
    }
  };

  /**
   * Persists the service
   * @param serviceData
   * @param actions
   */
  const AddNewService = (serviceData: IServiceManagerServiceDetails, actions: FormikHelpers<any>) => {
    setIsFormReady(false);
    ServiceManagerApi.methods.addService(imageFile as File, iconFile as File, serviceData).then(
      (res: AxiosResponse) => {
        addServicePlacementForAddMode(res.data.id, actions);
      },
      () => {
        setAlertProps({
          title: t("pages.antServiceManager.serviceAdd.errors.addingService"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        actions.setSubmitting(false);
        setIsFormReady(true);
      },
    );
  };

  /**
   * Update service data to have business app placement roles
   * @param {T extends TServiceData} serviceData
   */
  const updateServiceDataToHaveBusinessRoles = <T extends TServiceData>(serviceData: T) => {
    if (miniAppsConfigByRoleEnabled) {
      if (
        agentRolesSelected.length > 0 &&
        groupsSelected.some(item => item.label === ServiceDetailsGroupsEnum.AGENT_HUB)
      ) {
        serviceData.allowedAgentRoles = agentRolesSelected;
      }
      if (
        merchantRolesSelected.length > 0 &&
        groupsSelected.some(item => item.label === ServiceDetailsGroupsEnum.MERCHANT_HUB)
      ) {
        serviceData.allowedMerchantRoles = merchantRolesSelected;
      }
    }

    return serviceData;
  };

  /**
   * Get the new service data ready to be added
   * @param values
   * @param actions
   */
  const getServiceReadyToBeAdded = (values: any, actions: FormikHelpers<any>) => {
    let serviceData = {
      name: values.name,
      description: values.description,
      allowedScopes: values.allowedScopes,
      color: values.color,
      trustedLevel: values.trustedLevel.toUpperCase(),
      descriptionTranslations: values.descriptionTranslations,
      nameTranslations: values.nameTranslations,
      id: values.id,
    } as IServiceManagerServiceDetails;

    let isGroupSelected = true,
      isCategorySelected = true;
    if (miniAppsCategoryEdit) {
      serviceData = { ...serviceData, categories: values.categories } as IServiceManagerServiceDetails;
      if (!groupsSelected.length) {
        setPlacementAreasNotSelected(true);
        isGroupSelected = false;
      }
      if (!values.categories?.length) {
        setCategoriesNotSelected(true);
        isCategorySelected = false;
      }

      if (!isGroupSelected || !isCategorySelected) {
        actions.setSubmitting(false);
        return;
      }
    }
    serviceData = updateServiceDataToHaveBusinessRoles(serviceData);
    AddNewService(serviceData, actions);
  };

  /** function to add/update Service*/
  const onSubmit = (values: any, actions: FormikHelpers<any>) => {
    //* For add new service mode
    if (isAddMode) {
      getServiceReadyToBeAdded(values, actions);
      return;
    }

    let updateDetails = {
      color: values.color,
      description: values.description,
      trustLevel: values.trustedLevel.toUpperCase(),
      allowedScopes: values.allowedScopes || [],
      nameTranslations: values.nameTranslations,
      descriptionTranslations: values.descriptionTranslations,
    } as IUpdateServiceRequest;
    if (values.name !== serviceDetails.name) {
      updateDetails = { ...updateDetails, name: values.name };
    }

    if (miniAppsCategoryEdit) {
      updateDetails = { ...updateDetails, categories: values.categories } as IUpdateServiceRequest;
      if (!groupsSelected || groupsSelected.length === 0) {
        setPlacementAreasNotSelected(true);
        actions.setSubmitting(false);
        return;
      }
      if (!values.categories || values.categories.length === 0) {
        setCategoriesNotSelected(true);
        actions.setSubmitting(false);
        return;
      }
    }

    const updateServiceDetailsInfo = {
      serviceDetailsId: serviceDetails.id,
      imageFile: imageFile,
      iconFile: iconFile,
      updateDetails: updateDetails,
      values: values,
    } as IUpdateServiceDetails;

    updateDetails = updateServiceDataToHaveBusinessRoles(updateDetails);

    setConfirmModal(
      <ConfirmWithLoadingModal
        title={t("pages.serviceManager.serviceDetails.confirmationProps.title")}
        description={t("pages.serviceManager.serviceDetails.confirmationProps.description")}
        primaryBtnLabel={t("pages.announcements.detailPage.buttons.save")}
        secondaryBtnLabel={t("pages.announcements.detailPage.buttons.cancelBtn")}
        primaryAction={async () => {
          actions.setSubmitting(true);

          /**
           * Check if the current group is selected
           */
          const isCurrentGroupSelected = (staticGroup: IServiceGroup) => {
            return groupsSelected.some(selectedGroup => selectedGroup.id === staticGroup.id);
          };

          /**
           * Check if the current group is NOT assigned to the current service
           */
          const isGroupAssignedToCurrentService = (staticGroup: IServiceGroup) => {
            return serviceDetails.groups.some(
              assignedGroupToService => getIDByServiceGroup(serviceGroups, assignedGroupToService) === staticGroup.id,
            );
          };

          /**
           * Find the current group
           */
          const findCurrentGroup = (staticGroup: IServiceGroup) =>
            serviceDetails.groups.find(item => getIDByServiceGroup(serviceGroups, item) === staticGroup.id);

          const promisesList: any[] = [];
          //* forOf of serviceGroups to make the API calls processed sequentially:
          for (const staticGroup of serviceGroups) {
            //* Add groups
            if (isCurrentGroupSelected(staticGroup) && !isGroupAssignedToCurrentService(staticGroup)) {
              const currentGroup = findCurrentGroup(staticGroup);
              const addRequest = ServiceManagerServiceDetailsApi.methods.updateServiceManagerServiceAddGroup(
                serviceDetails.id,
                staticGroup.value,
                staticGroup.serviceType as ServiceType,
                serviceUserTypeEnabled ? (staticGroup.userType as UserType) : undefined,
                currentGroup?.promotedOrder,
              );
              promisesList.push(addRequest);
            }

            //* Remove groups from service
            if (isGroupAssignedToCurrentService(staticGroup) && !isCurrentGroupSelected(staticGroup)) {
              const removeReq = ServiceManagerServiceDetailsApi.methods.updateServiceManagerServiceRemoveGroup(
                serviceDetails.id,
                staticGroup.value,
                staticGroup.serviceType as ServiceType,
                serviceUserTypeEnabled ? (staticGroup.userType as UserType) : undefined,
              );
              promisesList.push(removeReq);
            }
          }

          //* Apply groups changes
          Promise.all(promisesList)
            .then(res => {
              updateServiceDetails(updateServiceDetailsInfo, actions);
            })
            .catch(error => {
              actions.setSubmitting(false);
              hideConfirmModal();

              let errorMessage = "pages.serviceManager.serviceDetails.updateError";
              let errorContent = "";
              if (error?.data?.status?.httpCode === 400 && error?.data?.status?.businessCode === 112) {
                errorMessage = "pages.serviceManager.serviceDetails.updateErrorMaximumItems";
                errorContent = "pages.serviceManager.serviceDetails.cannotAddMoreServices";
              }

              setAlertProps({
                title: t(errorMessage),
                type: AlertTypeEnum.ERROR,
                content: t(errorContent),
              });
              showAlert();
            });
        }}
        secondaryAction={() => hideConfirmModal()}
      />,
    );
    showConfirmModal();
  };

  //* API calls on mount
  useEffect(() => {
    loadCategories();
    getAllowedScopes();
    getAgentAndMerchantMiniAppsRoles();
    getFormReadyForAddMode();

    if (isAddMode) {
      getAvailableLanguages();
    }
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      getAvailableLanguages();
    } else {
      isMounted.current = true;
    }
  }, [serviceDetails]);

  useEffect(() => {
    setIcon(serviceDetails.icon);
    return () => {};
  }, [serviceDetails.icon]);

  useEffect(() => {
    setImage(serviceDetails.image);
    return () => {};
  }, [serviceDetails.image]);

  useEffect(() => {
    if (isAddMode) {
      return;
    }

    refreshPage();
    return () => {
      dispatch(ServiceManagerServiceDetailsActions.creators.fetchingServiceDetailsLeaveAction());
    };
  }, [dispatch, refreshPage, serviceId, setAlertProps, showAlert, t]);

  useEffect(() => {
    setInitialSelectedRolesFromBE();
    if (serviceDetails.groups !== undefined) {
      setGroupsSelected(
        serviceDetails.groups.map(
          item =>
            ({
              serviceType: item.serviceType,
              userType: item.userType,
              value: item.name,
              id: getIDByServiceGroup(serviceGroups, item),
              label: serviceGroups.find(element => element.id === getIDByServiceGroup(serviceGroups, item))?.label,
            } as IServiceGroup),
        ),
      );
    }
  }, [serviceDetails]);

  /**
   * This function handle the groups selected change event regarding the roles
   */
  useEffect(() => {
    if (miniAppsConfigByRoleEnabled && handleGroupsSelectedChanges.current) {
      if (groupsSelected.find(c => c.label === ServiceDetailsGroupsEnum.AGENT_HUB) && agentRolesSelected.length === 0) {
        setAgentRolesSelected(agentRoleOptions.map(item => item.id));
      } else if (!groupsSelected.find(c => c.label === ServiceDetailsGroupsEnum.AGENT_HUB)) {
        setAgentRolesSelected([]);
      }
      if (
        groupsSelected.find(c => c.label === ServiceDetailsGroupsEnum.MERCHANT_HUB) &&
        merchantRolesSelected.length === 0
      ) {
        setMerchantRolesSelected(merchantRoleOptions.map(item => item.id));
      } else if (!groupsSelected.find(c => c.label === ServiceDetailsGroupsEnum.MERCHANT_HUB)) {
        setMerchantRolesSelected([]);
      }
    }
  }, [groupsSelected]);

  return (
    <>
      {!isFormReady ? (
        <LoadingContainer>
          <div>
            <LoadingComponent />
          </div>
        </LoadingContainer>
      ) : (
        <PageContainer>
          {isAddMode ? (
            <SecondaryPageTitle
              title={t("pages.antServiceManager.serviceAdd.title")}
              goBackFn={() =>
                history.push(`${RoutesEnum.SERVICE_MANAGER}?tabIdx=${ServiceManagerTabNumber.SERVICE_LIST}`)
              }
              displayInitialsCircle={false}
            />
          ) : (
            <SecondaryPageTitle
              title={t("pages.serviceManager.serviceDetails.title")}
              goBackFn={() => window.history.back()}
              displayInitialsCircle={false}
            />
          )}
          <PageContent>
            <Formik
              initialValues={{
                name: serviceDetails.name || serviceDetailsInitialValues.name,
                description: serviceDetails.description || serviceDetailsInitialValues.description,
                color: serviceDetails.color || serviceDetailsInitialValues.color,
                groups: serviceDetails.groups || serviceDetailsInitialValues.groups,
                status: serviceDetails.status || serviceDetailsInitialValues.status,
                serviceIcon: serviceDetails.icon || serviceDetailsInitialValues.icon,
                serviceImage: serviceDetails.image || serviceDetailsInitialValues.image,
                trustedLevel: serviceDetails.trustedLevel || serviceDetailsInitialValues.trustedLevel,
                allowedScopes: serviceDetailsInitialValues.allowedScopes.length
                  ? serviceDetailsInitialValues.allowedScopes
                  : serviceDetails.allowedScopes,
                nameTranslations: serviceDetails.nameTranslations || serviceDetailsInitialValues.nameTranslations,
                descriptionTranslations:
                  serviceDetails.descriptionTranslations || serviceDetailsInitialValues.descriptionTranslations,
                categories: serviceDetails.categories,
                id: serviceDetails.id,
              }}
              validationSchema={getServiceDetailsValidationSchema()}
              validateOnChange={false}
              validateOnBlur={true}
              onSubmit={(values: any, actions: FormikHelpers<any>) => {
                if ((miniAppsConfigByRoleEnabled && validateRolesFields()) || !miniAppsConfigByRoleEnabled) {
                  onSubmit(values, actions);
                }
              }}
            >
              {({ values, handleChange, setFieldValue, handleSubmit, resetForm, errors, setErrors, isSubmitting }) => (
                <>
                  <MainContent className={"main-content"}>
                    <LeftFormDiv>
                      <FormSection
                        title={t("pages.serviceManager.serviceDetails.description")}
                        isEditing={isEditing}
                        rows={getFormRows(values, errors, setFieldValue, handleChange)}
                      />

                      {serviceDetailsInitialValues.nameTranslations &&
                      serviceDetailsInitialValues.descriptionTranslations &&
                      availableLanguages.length > 1 ? (
                        <TabsContainer>
                          <FormSectionTitle>
                            {t("pages.serviceManager.serviceDetails.formRows.translations")}
                          </FormSectionTitle>
                          <Tabs
                            controlledSelectedTab={Number(tabIndex) || ServiceInputTabNumber.NAME}
                            controlledSetSelectedTab={setTabIdx}
                            isSfcTabs={true}
                            tabActiveColor={getColorStatus(StatusType.LIVE)}
                          >
                            <TabChild label={t("pages.serviceManager.serviceDetails.formRows.name.label")}>
                              <TabContentContainer>
                                <FormSection
                                  isEditing={isEditing}
                                  title={""}
                                  rows={getTranslationsInputs(
                                    handleChange,
                                    errors,
                                    "nameTranslations",
                                    values.nameTranslations,
                                    46,
                                  )}
                                />
                              </TabContentContainer>
                            </TabChild>
                            <TabChild label={t("pages.serviceManager.serviceDetails.formRows.description.label")}>
                              <TabContentContainer>
                                <FormSection
                                  isEditing={isEditing}
                                  title={""}
                                  rows={getTranslationsInputs(
                                    handleChange,
                                    errors,
                                    "descriptionTranslations",
                                    values.descriptionTranslations,
                                    100,
                                  )}
                                />
                              </TabContentContainer>
                            </TabChild>
                          </Tabs>
                        </TabsContainer>
                      ) : null}
                      <Actions>
                        {isAddMode ? (
                          <ButtonsWrapper>
                            <ButtonDiv>
                              <PrimaryButton
                                id="create-new-service-button"
                                loading={isSubmitting}
                                titleLabel={t("pages.serviceBuilder.modals.addService.save.title")}
                                onClick={() => {
                                  handleSubmit();
                                }}
                                redTheme={true}
                                safaricom={true}
                              />
                            </ButtonDiv>
                          </ButtonsWrapper>
                        ) : (
                          <>
                            {!isEditing ? (
                              <LinkContainer
                                onClick={() => {
                                  setIsEditing(!isEditing);
                                }}
                              >
                                <IconContainer color={styleTheme.palette.turquoiseBlue}>
                                  <EditIcon />
                                </IconContainer>
                                <span>{t("pages.serviceManager.serviceDetails.editDetails.title")}</span>
                              </LinkContainer>
                            ) : (
                              <>
                                <LinkContainer
                                  onClick={() => {
                                    handleSubmit();
                                  }}
                                >
                                  <IconContainer color={styleTheme.palette.successGreen}>
                                    <CheckIcon />
                                  </IconContainer>
                                  <span>{t("pages.userProfile.links.saveChanges")}</span>
                                </LinkContainer>

                                <LinkContainer
                                  onClick={() => {
                                    resetForm();
                                    setIsStarting(true);
                                    setGroupsSelected([]);
                                    setIsEditing(false);
                                    setIcon(serviceDetails.icon);
                                    setImage(serviceDetails.image);
                                    setIconFile(undefined);
                                    setImageFile(undefined);
                                    setInitialSelectedRolesFromBE();
                                  }}
                                >
                                  <IconContainer color={styleTheme.palette.vodafoneRed}>
                                    <UndoIcon />
                                  </IconContainer>
                                  <span>{t("pages.userProfile.links.cancelChanges")}</span>
                                </LinkContainer>
                              </>
                            )}
                            {serviceDetails.status === "LIVE" ? (
                              <LinkContainer disabled={isEditing} onClick={showDisableServiceModal}>
                                <IconContainer color={styleTheme.palette.midGrey}>
                                  <CancelIcon />
                                </IconContainer>
                                <span>{t("pages.serviceManager.serviceDetails.editDetails.disableService")}</span>
                              </LinkContainer>
                            ) : (
                              <LinkContainer onClick={ShowActivateServiceModal} disabled={isEditing}>
                                <IconContainer color={styleTheme.palette.successGreen}>
                                  <CheckIcon />
                                </IconContainer>
                                <span>{t("pages.serviceManager.serviceDetails.editDetails.activateService")}</span>
                              </LinkContainer>
                            )}
                            {addAndRemoveServicesEnabled && (
                              <LinkContainer id="delete-service" disabled={isEditing} onClick={deleteService}>
                                <IconContainer color={styleTheme.palette.errorRed}>
                                  <TrashIcon />
                                </IconContainer>
                                <span>{t("pages.antServiceManager.serviceDetails.editDetails.deleteService")}</span>
                              </LinkContainer>
                            )}
                          </>
                        )}
                      </Actions>
                    </LeftFormDiv>
                  </MainContent>
                  <LeftContent>
                    {isEditing ? (
                      <>
                        <RightFormDiv>
                          <EditServiceFormRight>
                            <DropzoneTitle mandatory={true}>
                              {t("pages.serviceBuilder.modals.addService.icon.title")}
                            </DropzoneTitle>
                            {errors.serviceIcon ? <ImageError>{errors.serviceIcon}</ImageError> : null}
                            <Dropzone
                              inputName="serviceIcon"
                              onDrop={(file: any) => {
                                if (!file[0]) {
                                  setErrors({
                                    ...errors,
                                    serviceIcon: t("pages.serviceBuilder.modals.addService.icon.imageError"),
                                  });
                                  if (values.serviceIcon === null) {
                                    setFieldValue("serviceIcon", null);
                                  }
                                } else if (file[0] && file[0].size > MAX_FILE_SIZE) {
                                  setErrors({
                                    ...errors,
                                    serviceIcon: t(
                                      "pages.serviceBuilder.modals.updateService.icon.maxSizeError",
                                    ).replace("{0}", (MAX_FILE_SIZE / 1000000).toString()),
                                  });
                                  if (values.serviceIcon === null) {
                                    setFieldValue("serviceIcon", null);
                                  }
                                } else {
                                  setFieldValue("serviceIcon", file);
                                  onDrop(file);
                                }
                              }}
                              accept={"image/*"}
                              imageSrc={icon}
                              multiple={false}
                              dropText={t("pages.serviceBuilder.modals.updateService.icon.drop")}
                              releaseText={t("pages.serviceBuilder.modals.updateService.icon.release")}
                              error={!!errors.serviceIcon}
                            />

                            <DropzoneTitle mandatory={false}>
                              {t("pages.serviceBuilder.modals.addService.image.title")}
                            </DropzoneTitle>
                            {errors.serviceImage ? <ImageError>{errors.serviceImage}</ImageError> : null}
                            <Dropzone
                              inputName="serviceImage"
                              onDrop={(file: any) => {
                                if (!file[0]) {
                                  setErrors({
                                    ...errors,
                                    serviceImage: t("pages.serviceBuilder.modals.addService.icon.imageError"),
                                  });
                                  if (values.serviceImage === null) {
                                    setFieldValue("serviceImage", null);
                                  }
                                } else if (file[0] && file[0].size > MAX_FILE_SIZE) {
                                  setErrors({
                                    ...errors,
                                    serviceImage: t(
                                      "pages.serviceBuilder.modals.updateService.icon.maxSizeError",
                                    ).replace("{0}", (MAX_FILE_SIZE / 1000000).toString()),
                                  });
                                  if (values.serviceImage === null) {
                                    setFieldValue("serviceImage", null);
                                  }
                                } else {
                                  setFieldValue("serviceImage", file);
                                  onDropImage(file);
                                }
                              }}
                              accept={"image/*"}
                              imageSrc={image}
                              multiple={false}
                              roundedImage={false}
                              dropText={t("pages.serviceBuilder.modals.updateService.icon.drop")}
                              releaseText={t("pages.serviceBuilder.modals.updateService.icon.release")}
                              error={!!errors.serviceImage}
                            />
                          </EditServiceFormRight>
                        </RightFormDiv>
                      </>
                    ) : (
                      <>
                        <RightFormDiv>
                          <DropzoneTitle mandatory={true}>
                            {t("pages.serviceBuilder.modals.addService.icon.title")}
                          </DropzoneTitle>
                          <AvatarContainer>
                            <AvatarDiv backgroundImage={icon}></AvatarDiv>
                          </AvatarContainer>{" "}
                          <Separator />
                          <DropzoneTitle mandatory={true}>
                            {t("pages.serviceBuilder.modals.addService.image.title")}
                          </DropzoneTitle>
                          <ImageContainer backgroundImage={image}></ImageContainer>
                        </RightFormDiv>
                      </>
                    )}
                  </LeftContent>
                </>
              )}
            </Formik>
          </PageContent>
        </PageContainer>
      )}
    </>
  );
};

export default ServiceManagerServiceDetailsPage;
