import { PrimaryButton } from "@wit/mpesa-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Column, Row } from "../../../../shared/shared.styled";
import { INetworkItem } from "../networks.model";
import { getStatus } from "../networks.page";

interface IPublishChangesModalProps {
  onCancelClick: () => void;
  onPublishClick: () => void;
  isLoading: boolean;
  networkList: INetworkItem[];
  lastNetworkList: INetworkItem[];
}

const PublishChangesModalPage = ({
  onCancelClick,
  onPublishClick,
  networkList,
  isLoading,
  lastNetworkList,
}: IPublishChangesModalProps) => {
  const [t] = useTranslation();

  return (
    <ModalContainer>
      <Row style={{ padding: 24 }}>
        <Column>
          <ModalTitle>{t("pages.networks.publishModal.publish")}</ModalTitle>
          <ModalSubTitle>{t("pages.networks.publishModal.wantPublish")}</ModalSubTitle>
        </Column>
      </Row>
      <Content>
        <Row>
          <ItemColumn>{t("pages.networks.publishModal.previousVersion")}</ItemColumn>
          <ItemColumn>{t("pages.networks.publishModal.editedVersion")}</ItemColumn>
        </Row>
        <ItemsContainer>
          <Row>
            <ItemColumn>
              {lastNetworkList &&
                lastNetworkList.map((edited, index) => {
                  const status = getStatus(edited.status);
                  return (
                    <NetworkItemDiv key={index} id={edited.networkType}>
                      <NetworkText>{t(`commons.networks.${edited.networkType}`)}</NetworkText>
                      <ValueItem color={status ? "#009900" : "#eb8b00"}>
                        <div>{status ? t("pages.networks.activeLabel") : t("pages.networks.disabledLabel")}</div>
                      </ValueItem>
                    </NetworkItemDiv>
                  );
                })}
            </ItemColumn>
            <ItemColumn>
              {networkList &&
                networkList.map((edited, index) => {
                  const status = getStatus(edited.status);
                  return (
                    <NetworkItemDiv key={index} id={edited.networkType}>
                      <NetworkText>{t(`commons.networks.${edited.networkType}`)}</NetworkText>
                      <ValueItem color={status ? "#009900" : "#eb8b00"}>
                        <div>{status ? t("pages.networks.activeLabel") : t("pages.networks.disabledLabel")}</div>
                      </ValueItem>
                    </NetworkItemDiv>
                  );
                })}
            </ItemColumn>
          </Row>
        </ItemsContainer>
      </Content>
      <ButtonsContainer style={{ padding: 24 }}>
        <div>
          <PrimaryButton
            type="button"
            id="cancel-button"
            onClick={onCancelClick}
            disabled={isLoading}
            titleLabel={t("pages.networks.publishModal.cancelBtnLabel")}
          />
        </div>
        <div>
          <PrimaryButton
            id="confirm-button"
            redTheme={true}
            type="submit"
            onClick={onPublishClick}
            disabled={isLoading}
            titleLabel={t("pages.networks.publishModal.saveChangesBtnLabel")}
          />
        </div>
      </ButtonsContainer>
    </ModalContainer>
  );
};

export default PublishChangesModalPage;

const ModalContainer = styled("div")`
  display: flex;
  flex-direction: column;
  font-family: Vodafone Rg;
`;

const ModalTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 21px;
`;

const ModalSubTitle = styled(ModalTitle)`
  font-size: 18px;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 50px;

  > div {
    width: "fit-content";

    :first-of-type {
      margin-right: 12px;
    }
  }
`;

const ItemsContainer = styled.div`
  max-height: 220px;
  overflow: auto;
  width: 100%;
  overflow-x: hidden;
`;

const ItemColumn = styled(Column)<{ disabled?: Boolean }>`
  flex: 1;
  padding: 16px 0px 16px 0;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  padding-left: 24px;
  &:first-child {
    padding-right: 74px;
  }

  &:last-child {
    padding-left: 30px;
    padding-right: 44px;
  }
`;

const Content = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${props => props.theme.palette.darkGrey};

  background: ${props => `linear-gradient(90deg, ${props.theme.palette.lightGrey} 50%, transparent 50%)`};
`;

const NetworkItemDiv = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;
const NetworkText = styled.span`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
`;

const ValueItem = styled.div<{ color: string }>`
  float: right;
  color: ${props => props.color};
`;
