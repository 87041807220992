import { AxiosPromise } from "axios";
import ApiClient from "../../../../configs/axios.service-builder.config";

const ServicePreviewDrawerApi = {
  routes: {
    serviceConfiguration: "/serviceversion/:serviceId/configFile",
  },
  methods: {
    getServiceConfiguration: (serviceId: string, language: string): AxiosPromise<any> => {
      return ApiClient.get(
        ServicePreviewDrawerApi.routes.serviceConfiguration.replace(":serviceId", serviceId) + "?language=" + language,
      );
    },
  },
};

export default ServicePreviewDrawerApi;
