import {
  CancelIcon,
  CheckIcon,
  EditIcon,
  FormSection,
  ModalTypeEnum,
  MultipleOptionsDropdown,
  SecondaryPageTitle,
  TextArea,
  TextInput,
  UndoIcon,
  useAlert,
  useModal,
  TrashIcon,
  Checkbox,
  TextInputWithTag,
} from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import { Formik, FormikErrors, FormikHelpers, yupToFormErrors } from "formik";
import React, { useCallback, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import HelpIcon from "../../../../shared/icons/help.icon";
import { IStoreInterface } from "../../../../configs/store.config";
import { RoutesEnum } from "../../../../routes/routes.constants";
import { ServiceManagerStatusEnum } from "../../../../shared/models/ant-service-manager.model";
import { getServiceManagerStatusColor, RegExEpressions } from "../../../../shared/shared.utils";
import { LoadingText, PageContainer, DropDownWrapper } from "../../../../shared/shared.styled";
import AntServiceManagerServiceDetailsApi from "../ant-service-manager-service-details.api";
import { IUpdateServiceDetails, IUpdateServiceRequest } from "../ant-service-manager-service-details.model";
import { AntServiceManagerServiceDetailsActions } from "../ant-service-manager-service-details.store";
import Dropzone from "../utils/dropzone.component";
import LanguagesApi from "../../../../shared/services/languages.api";
import { ILanguage } from "../../../../shared/models/language.model";
import { object, Ref, string, StringSchema } from "yup";
import i18next from "i18next";
import { IDynamicScreenCategory } from "../../../configurations/dynamic-screens/dynamic-screens.model";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import DynamicScreensApi from "../../../configurations/dynamic-screens/dynamic-screens.api";
import { ServiceManagerTabNumber } from "../../pages/ant-service-manager.page";
import ReactTooltip from "react-tooltip";
import GenericLanguageSelector from "../../../../shared/components/generic-language-selector.component";
import useEmptyLanguageWarningModal from "../../../../shared/hooks/use-empty-language-warning-modal.hook";
import AllowedScopesApi from "../../allowed-scope/allowed-scopes.api";
import { IAllowedScope } from "../../allowed-scope/allowed-scopes.model";

/**
 * Ant service manager details page
 * @returns
 */
const AntServiceManagerDetailsPage = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { serviceId } = useParams<any>();
  const [isEditing, setIsEditing] = React.useState(false);
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const MAX_FILE_SIZE = 2000000;
  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );
  const [showDisableModal, hideDisableModal, setDisableProps] = useModal(ModalTypeEnum.ConfirmationModal);
  const [showDeleteModal, hideDeleteModal, setDeleteProps] = useModal(ModalTypeEnum.ConfirmationModal);

  const { serviceDetails, isLoadingServiceDetails } = useSelector(
    (state: IStoreInterface) => state.antServiceManagerServiceDetailsReducer,
  );
  const [iconFile, setIconFile] = useState<string | undefined>();
  const [imageFile, setImageFile] = useState<string | undefined>();
  const [icon, setIcon] = useState(serviceDetails.iconUrl);
  const [image, setImage] = useState(serviceDetails.imageUrl);

  const [availableLanguages, setAvailableLanguages] = useState<ILanguage[]>();
  const [language, setLanguage] = useState("en");

  const [canBeHighlighted, setCanBeHighlighted] = useState<boolean>(false);

  const [categories, setCategories] = React.useState<IDynamicScreenCategory[]>(serviceDetails.categories || []);
  const [selectedCategories, setSelectedCategories] = React.useState<string[]>([]);

  const [allowedScopes, setAllowedScopes] = React.useState<IAllowedScope[]>([]);

  const tagsSuggestions = useRef(new Array<string>());
  const [tags, setTags] = useState<string[]>([]);
  const [tagInputText, setTagInputText] = useState<string>("");
  const [tagErrorText, setTagErrorText] = useState<string>("");

  const { warningModalOpen, showWarning, setShowWarning } = useEmptyLanguageWarningModal(availableLanguages || []);
  /**
   * Submit method wrapper to show warning if needed
   * @param values
   */
  const submitWrapper = (values: any, actions: FormikHelpers<any>) => {
    if (showWarning) {
      setShowWarning(false);
      actions.setSubmitting(false);
      warningModalOpen(updateService, values, actions);
    } else {
      updateService(values, actions);
    }
  };

  /**
   * Show disable modal function
   */
  const _showDisableModal = () => {
    setDisableProps({
      secondaryAction: () => {
        hideDisableModal();
      },
      primaryAction: () => {
        disableServiceRequest();
      },
      title: t("pages.antServiceManager.serviceDetails.disableModal.title"),
      description: t("pages.antServiceManager.serviceDetails.disableModal.description"),
      primaryBtnLabel: t("pages.antServiceManager.serviceDetails.disableModal.btnLabel"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
    });
    showDisableModal();
  };

  /**
   * Show delete modal function
   */
  const _showDeleteModal = () => {
    setDeleteProps({
      secondaryAction: () => {
        hideDeleteModal();
      },
      primaryAction: () => {
        deleteServiceRequest();
      },
      title: t("pages.antServiceManager.serviceDetails.deleteModal.title"),
      description: t("pages.antServiceManager.serviceDetails.deleteModal.description"),
      primaryBtnLabel: t("pages.antServiceManager.serviceDetails.deleteModal.btnLabel"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      primaryBtnMaxWidth: 160,
    });
    showDeleteModal();
  };

  /**
   * function go back
   */
  const goBack = () => {
    history.push(`${RoutesEnum.ANT_SERVICE_MANAGER}?tabIdx=${ServiceManagerTabNumber.SERVICE_LIST}`);
  };

  const refreshPage = useCallback(() => {
    AntServiceManagerServiceDetailsApi.methods.getServiceManagerServiceDetails(serviceId).then(
      res => {
        dispatch(AntServiceManagerServiceDetailsActions.creators.fetchServiceDetailsSuccessAction(res.data));

        if (res.data.tags) {
          setTags(res.data.tags);
        }

        if (res.data.tagsSuggestions) {
          tagsSuggestions.current.push(...res.data.tagsSuggestions);
        }
      },
      () => {
        setAlertProps({
          title: t("pages.serviceBuilder.errors.noServiceDetails"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        history.push(RoutesEnum.SERVICE_MANAGER);
      },
    );
  }, [dispatch, serviceId, setAlertProps, showAlert, t]);

  /**
   * Display status function
   * @returns
   */
  const displayStatus = () => {
    if (serviceDetails.status === ServiceManagerStatusEnum.LIVE) {
      return (
        <UserStatus color={getServiceManagerStatusColor("LIVE")}>
          {t("pages.antServiceManager.serviceDetails.statusColor.live")}
        </UserStatus>
      );
    } else {
      return (
        <UserStatus color={getServiceManagerStatusColor("DISABLED")}>
          {t("pages.antServiceManager.serviceDetails.statusColor.disabled")}
        </UserStatus>
      );
    }
  };

  /**
   * Activate service function
   */
  const activateService = () => {
    setConfirmationProps({
      title: t("pages.antServiceManager.serviceDetails.activateModal.title"),
      primaryBtnLabel: t("pages.antServiceManager.serviceDetails.activateModal.btnLabel"),
      description: t("pages.antServiceManager.serviceDetails.activateModal.description", {
        serviceDetails: serviceDetails.name[language],
      }),
      primaryAction: () => {
        AntServiceManagerServiceDetailsApi.methods
          .updateServiceManagerServiceStatus(serviceDetails.id, ServiceManagerStatusEnum.LIVE)
          .then(
            () => {
              setAlertProps({
                title: t("pages.antServiceManager.serviceDetails.success.updatingService"),
                type: AlertTypeEnum.SUCCESS,
              });
              showAlert();
              dispatch(
                AntServiceManagerServiceDetailsActions.creators.updateServiceManagerServiceStatus(serviceDetails.id),
              );
              AntServiceManagerServiceDetailsActions.creators.fetchingServiceDetailsAction();
              AntServiceManagerServiceDetailsApi.methods.getServiceManagerServiceDetails(serviceId).then(res => {
                dispatch(AntServiceManagerServiceDetailsActions.creators.fetchServiceDetailsSuccessAction(res.data));
              });
            },
            () => {
              setAlertProps({
                title: t("pages.antServiceManager.serviceDetails.errors.updatingService"),
                type: AlertTypeEnum.ERROR,
              });
              showAlert();
            },
          )
          .finally(() => {
            hideConfirmationModal();
          });
      },
      secondaryAction: () => hideConfirmationModal(),
      primaryBtnId: "activate-button",
      secondaryBtnId: "cancel-button",
    } as BaseModalProps);
    showConfirmationModal();
  };

  /**
   * Disable service modal function
   */
  const disableService = () => {
    _showDisableModal();
  };

  /**
   * Disable service function
   */
  const disableServiceRequest = () => {
    AntServiceManagerServiceDetailsApi.methods
      .updateServiceManagerServiceStatus(serviceDetails.id, ServiceManagerStatusEnum.UNDEPLOYED)
      .then(
        () => {
          setAlertProps({
            title: t("pages.antServiceManager.serviceDetails.success.updatingServiceDisabled"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          dispatch(
            AntServiceManagerServiceDetailsActions.creators.updateServiceManagerServiceStatus(serviceDetails.id),
          );
          AntServiceManagerServiceDetailsActions.creators.fetchingServiceDetailsAction();
          AntServiceManagerServiceDetailsApi.methods.getServiceManagerServiceDetails(serviceId).then(res => {
            dispatch(AntServiceManagerServiceDetailsActions.creators.fetchServiceDetailsSuccessAction(res.data));
          });
        },
        () => {
          setAlertProps({
            title: t("pages.antServiceManager.serviceDetails.errors.updatingServiceDisabled"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        hideDisableModal();
      });
  };

  /**
   * Delete service modal function
   */
  const deleteService = () => {
    _showDeleteModal();
  };

  /**
   * Delete service function
   */
  const deleteServiceRequest = () => {
    AntServiceManagerServiceDetailsApi.methods
      .deleteServiceManagerServiceDetails(serviceDetails.id)
      .then(
        () => {
          setAlertProps({
            title: t("pages.antServiceManager.serviceDetails.success.deletingService"),
            type: AlertTypeEnum.SUCCESS,
          });
          hideDisableModal();
          showAlert();
          goBack();
        },
        () => {
          setAlertProps({
            title: t("pages.antServiceManager.serviceDetails.errors.deletingService"),
            type: AlertTypeEnum.ERROR,
          });
          hideDisableModal();
          showAlert();
        },
      )
      .finally(() => {
        hideDisableModal();
      });
  };

  const onDrop = useCallback(
    acceptedIcon => {
      if (acceptedIcon.length > 0) {
        const file: File = acceptedIcon[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = function() {
          if (reader.result) {
            setIconFile(reader.result.toString());
            setIcon(window.URL.createObjectURL(file));
          }
        };
      }
    },
    [iconFile, icon],
  );

  const onDropImage = useCallback(
    acceptedImage => {
      if (acceptedImage.length > 0) {
        const file: File = acceptedImage[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = function() {
          if (reader.result) {
            setImageFile(reader.result.toString());
            setImage(window.URL.createObjectURL(file));
          }
        };
      }
    },
    [imageFile, image],
  );

  React.useEffect(() => {
    return () => {
      window.URL.revokeObjectURL(icon);
      window.URL.revokeObjectURL(image);
    };
  }, []);

  React.useEffect(() => {
    setSelectedCategories(
      serviceDetails && serviceDetails.categories
        ? serviceDetails.categories.map((c: IDynamicScreenCategory) => c.id)
        : [],
    );
  }, [serviceDetails]);

  /**
   * Updates the service data
   * @param serviceDetails
   * @returns
   */
  const updateServiceDetails = (serviceInfo: IUpdateServiceDetails, actions: FormikHelpers<any>) => {
    AntServiceManagerServiceDetailsApi.methods
      .updateServiceManagerServiceDetails(
        serviceInfo.serviceDetailsId,
        serviceInfo.imageFile,
        serviceInfo.iconFile,
        serviceInfo.updateDetails,
      )
      .finally(() => actions.setSubmitting(false))
      .then(
        () => {
          setAlertProps({
            title: t("pages.antServiceManager.serviceDetails.success.updatingServiceDetails"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();

          dispatch(
            AntServiceManagerServiceDetailsActions.creators.updateServiceManagerServiceDetails(
              serviceDetails.id,
              serviceInfo.values,
            ),
          );
          AntServiceManagerServiceDetailsActions.creators.fetchingServiceDetailsAction();
          AntServiceManagerServiceDetailsApi.methods.getServiceManagerServiceDetails(serviceId).then(res => {
            dispatch(AntServiceManagerServiceDetailsActions.creators.fetchServiceDetailsSuccessAction(res.data));
            res.data &&
              res.data.categories &&
              setSelectedCategories(res.data.categories.map((c: IDynamicScreenCategory) => c.id));
            setIsEditing(false);
          });
        },
        err => {
          if (
            err?.response?.data?.code === 20001 ||
            err?.response?.data?.code === 40003 //20001 = already exist | 40003 = max highlighted reached
          ) {
            setAlertProps({
              title: err.response.data.message,
              type: AlertTypeEnum.ERROR,
            });
          } else {
            setAlertProps({
              title: t("pages.antServiceManager.serviceDetails.errors.updatingServiceDetails"),
              type: AlertTypeEnum.ERROR,
            });
          }
          showAlert();
        },
      )
      .finally(() => {
        actions.setSubmitting(false);
        hideConfirmationModal();
      });
  };

  /**
   * Update service function
   * @param values
   * @param actions
   */
  const updateService = (values: any, actions: FormikHelpers<any>) => {
    let updateDetails = {
      description: values.description,
      categories: values.categories,
      highlighted: values.highlighted,
      allowedScopes: values.allowedScopes,
      tags: tags,
    } as IUpdateServiceRequest;

    if (values.name !== serviceDetails.name) {
      updateDetails = { ...updateDetails, name: values.name };
    }

    const updateServiceDetailsInfo = {
      serviceDetailsId: serviceDetails.id,
      imageFile: imageFile,
      iconFile: iconFile,
      updateDetails: updateDetails,
      values: values,
    } as IUpdateServiceDetails;

    setConfirmationProps({
      title: t("pages.antServiceManager.serviceDetails.confirmationProps.title"),
      description: t("pages.antServiceManager.serviceDetails.confirmationProps.description"),
      primaryAction: () => {
        actions.setSubmitting(true);
        updateServiceDetails(updateServiceDetailsInfo, actions);
      },
      secondaryAction: () => hideConfirmationModal(),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
    } as BaseModalProps);
    showConfirmationModal();
  };

  React.useEffect(() => {
    LanguagesApi.methods.getAvailableLanguages().then(
      res => {
        if (res.data.availableLanguages.length === 0) {
          setAvailableLanguages([
            { id: "1", code: "en", name: "English", urlIcon: "ic_flag_uk.png" },
            { id: "2", code: "sw", name: "Swahili (Tanzania)", urlIcon: "ic_flag_tanzania.png" },
          ]);
        } else {
          setAvailableLanguages(res.data.availableLanguages);
        }
      },
      () => {
        setAlertProps({
          type: AlertTypeEnum.ERROR,
          title: t("commons.getLanguagesError"),
        });
        showAlert();
        setAvailableLanguages([
          { id: "1", code: "en", name: "English", urlIcon: "ic_flag_uk.png" },
          { id: "2", code: "sw", name: "Swahili (Tanzania)", urlIcon: "ic_flag_tanzania.png" },
        ]);
      },
    );

    DynamicScreensApi.methods.getDynamicScreensCategories().then(
      res => {
        setCategories(res.data.items);
      },
      () => {
        setAlertProps({
          title: t("pages.serviceBuilder.errors.categoriesError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );

    AntServiceManagerServiceDetailsApi.methods.getCanBeHighlighted().then(res => {
      setCanBeHighlighted(res.data.canBeHighlighted);
    });

    AllowedScopesApi.methods.getAllowedScopes().then(
      res => {
        setAllowedScopes(res.data);
      },
      () => {
        setAlertProps({
          title: t("pages.antServiceManager.allowedScopes.getAllowedScopesError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  }, []);

  React.useEffect(() => {
    setIcon(serviceDetails.iconUrl);
  }, [serviceDetails.iconUrl]);

  React.useEffect(() => {
    setImage(serviceDetails.imageUrl);
  }, [serviceDetails.imageUrl]);

  React.useEffect(() => {
    refreshPage();
    return () => {
      dispatch(AntServiceManagerServiceDetailsActions.creators.fetchingServiceDetailsLeaveAction());
    };
  }, [dispatch, refreshPage, serviceId, setAlertProps, showAlert, t]);

  /**
   * Creates the language validators for the translatable fields
   * @param {string[]} languages
   * @returns {{[p: string]: Ref | StringSchema<string>}}
   */
  const getTranslationValidators = (languages: ILanguage[]) => {
    let validators: { [x: string]: Ref | StringSchema<string> } = {};
    languages.map((lang: ILanguage) => {
      if (lang.mandatory) {
        validators = {
          ...validators,
          [lang.code]: string()
            .trim()
            .required(i18next.t("pages.serviceBuilder.formErrors.required"))
            .max(255, i18next.t("pages.serviceBuilder.formErrors.maxLen255")),
        };
      } else {
        validators = {
          ...validators,
          [lang.code]: string().max(255, i18next.t("pages.serviceBuilder.formErrors.maxLen255")),
        };
      }
    });
    return validators;
  };

  /**
   * Retrieves the form validation schema
   * @param {string[]} availableLanguages
   * @returns {ObjectSchema<Shape<object, {name: Shape<object, {}>, serviceImage: string | null, serviceIcon: string | null, description: Shape<object, {}>}>>}
   */
  const getValidationSchema = (availableLanguages: ILanguage[]) => {
    return object().shape({
      serviceImage: string()
        .required(i18next.t("pages.serviceBuilder.modals.addService.image.errorRequired"))
        .nullable(),
      serviceIcon: string()
        .required(i18next.t("pages.serviceBuilder.modals.addService.icon.errorRequired"))
        .nullable(),
      name: object().shape(getTranslationValidators(availableLanguages)),
      description: object().shape(getTranslationValidators(availableLanguages)),
    });
  };

  /**
   * Validate form promise
   * @param values
   * @param {string[]} languages
   * @returns {Promise<FormikErrors<IUpdateServiceDetails>>}
   */
  const validateSchema = (
    values: any,
    languages: ILanguage[],
    setShowWarning?: React.Dispatch<React.SetStateAction<boolean>>,
  ): Promise<FormikErrors<IUpdateServiceDetails>> => {
    return new Promise<FormikErrors<IUpdateServiceDetails>>(resolve => {
      getValidationSchema(languages)
        .validate(values, {
          abortEarly: false,
        })
        .then(() => {
          setShowWarning && setShowWarning(false);
          //Validate if any optional language is empty
          for (let l = 0; l < languages.length; l++) {
            if (!values.name[languages[l].code] || !values.description[languages[l].code]) {
              setShowWarning && setShowWarning(true);
            }
          }
          resolve({});
        })
        .catch(errors => {
          const langErrors: string[] = [];
          errors.inner.forEach((err: { path: string }) => {
            if ((err.path as string).includes("name") || (err.path as string).includes("description")) {
              langErrors.push(err.path.replace("name.", "").replace("description.", ""));
            }
          });
          if (langErrors.length > 0) {
            langErrors.sort();
            setLanguage(langErrors[0]);
          }
          resolve(yupToFormErrors(errors));
        });
    }).then(r => {
      return r;
    });
  };

  /**
   * Toggles a category in the dropdown
   * @param {SharedDropdownOption} opt
   * @param {(field: string, value: any, shouldValidate?: (boolean | undefined)) => void} setFieldValue
   */
  const toggleCategory = (
    opt: SharedDropdownOption,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  ) => {
    if (selectedCategories.includes(opt.key)) {
      setFieldValue(
        "categories",
        selectedCategories.filter(c => c !== opt.key),
      );
      setSelectedCategories(selectedCategories.filter(c => c !== opt.key));
    } else {
      setFieldValue("categories", [...selectedCategories, opt.key]);
      setSelectedCategories([...selectedCategories, opt.key]);
    }
  };

  /**
   * Checks if a category is already selected on the dropdown
   * @param {SharedDropdownOption} opt
   * @returns {boolean}
   */
  const isCategorySelected = (opt: SharedDropdownOption) => {
    return selectedCategories.includes(opt.key);
  };

  /**
   * Returns a label for the categories dropdown
   * @returns {string}
   */
  const getCategoriesLabel = () => {
    if (selectedCategories.length > 0) {
      let label = "";
      selectedCategories.forEach(c => {
        const nameTranslation = (categories.find(cat => {
          return cat.id === c;
        }) as IDynamicScreenCategory).name.translations;
        label += `${nameTranslation[language] ? nameTranslation[language] : nameTranslation["en"]}, `;
      });
      label = label.substring(0, label.length - 2);
      return label;
    } else {
      return t("pages.serviceBuilder.modals.addService.category.placeholder");
    }
  };

  /**
   * Returns a label for the allowed single sign on scopes dropdown
   * @returns {string}
   */
  const getAllowedScopeName = (allowedScopesIds: string[]) => {
    const scopesLabel = [] as string[];
    allowedScopes.forEach(scope => {
      allowedScopesIds.forEach(id => {
        if (scope.id === id) {
          scopesLabel.push(
            scope.nameTranslations[language] ? scope.nameTranslations[language] : scope.nameTranslations["en"],
          );
        }
      });
    });

    return scopesLabel.join(", ");
  };

  /**
   * Return allowed scope options
   */
  const getAllowedScopesOptions = (allowedScopes: IAllowedScope[]): SharedDropdownOption[] => {
    const options: SharedDropdownOption[] = allowedScopes.map(scope => {
      return {
        label: scope.nameTranslations[language] ? scope.nameTranslations[language] : scope.nameTranslations["en"],
        key: scope.id,
      };
    });

    return options;
  };

  /**
   * returns array of actual selectedAllowedValues keys
   * @param selectedAllowedScopes
   */
  const getSelectedAllowedScopeKeys = (selectedAllowedScopes: string | any[]) => {
    return getAllowedScopesOptions(allowedScopes)
      .map(item => {
        if (item && selectedAllowedScopes && selectedAllowedScopes.includes(item.key) && item.label !== "") {
          return item.key;
        }
      })
      .filter(item => item !== undefined);
  };

  return (
    <>
      {availableLanguages && (
        <PageContainer>
          <SecondaryPageTitle
            title={t("pages.antServiceManager.serviceDetails.title")}
            goBackFn={() => goBack()}
            displayInitialsCircle={false}
          />
          {isLoadingServiceDetails || !availableLanguages ? (
            <LoadingText style={{ marginLeft: "75px" }}>{t("commons.loadingResults")}</LoadingText>
          ) : (
            <Formik
              onSubmit={submitWrapper}
              initialValues={{
                name: serviceDetails.name,
                description: serviceDetails.description,
                status: serviceDetails.status,
                allowedScopes: serviceDetails.allowedScopes,
                highlighted: serviceDetails.highlighted,
                serviceIcon: serviceDetails.iconUrl,
                serviceImage: serviceDetails.imageUrl,
                tags: tags,
              }}
              validateOnBlur={false}
              validateOnChange={false}
              validate={values => validateSchema(values, availableLanguages, setShowWarning)}
              enableReinitialize
              render={({ values, handleChange, setFieldValue, handleSubmit, resetForm, errors, setErrors }) => (
                <AntEditServiceForm>
                  <TopFormDiv>
                    <EditServiceFormLeft>
                      <div style={{ marginTop: "auto", marginLeft: "auto" }}>
                        <GenericLanguageSelector
                          selectedLanguage={language}
                          availableLanguages={availableLanguages.map(lang => lang.code)}
                          changeSelectedLanguage={(lang: any) => setLanguage(lang.key)}
                        />
                      </div>
                      <FormSection
                        title={t("pages.antServiceManager.serviceDetails.description")}
                        isEditing={isEditing}
                        rows={[
                          {
                            label: (
                              <DropzoneTitle
                                mandatory={availableLanguages.find(l => l.code === language)?.mandatory || false}
                              >
                                {t("pages.antServiceManager.serviceDetails.formRows.name.label")}
                              </DropzoneTitle>
                            ),
                            displayComponent: <ServiceName>{serviceDetails.name[language]}</ServiceName>,
                            editingComponent: (
                              <TextInput
                                name={`name.${language}`}
                                value={(values.name && values.name[language]) || ""}
                                error={errors.name && ((errors.name as { [x: string]: string })[language] as string)}
                                onChange={handleChange}
                                placeholder={t("pages.antServiceManager.serviceDetails.formRows.name.placeholder")}
                                required={availableLanguages.find(l => l.code === language)?.mandatory}
                              />
                            ),
                          },
                          {
                            label: (
                              <DropzoneTitle
                                mandatory={availableLanguages.find(l => l.code === language)?.mandatory || false}
                              >
                                {t("pages.antServiceManager.serviceDetails.formRows.description.label")}
                              </DropzoneTitle>
                            ),
                            displayComponent: (
                              <ValueContainer>
                                <DescriptionContainer>
                                  <Value>{serviceDetails.description[language]}</Value>
                                </DescriptionContainer>
                              </ValueContainer>
                            ),
                            editingComponent: (
                              <TextAreaWrapper
                                mandatory={availableLanguages.find(l => l.code === language)?.mandatory || false}
                              >
                                <TextArea
                                  style={{ fontSize: "16px", fontFamily: "Vodafone Rg" }}
                                  placeholder={t("pages.antServiceManager.serviceAdd.formRows.description.placeholder")}
                                  name={`description.${language}`}
                                  onChange={handleChange}
                                  value={(values.description && values.description[language]) || ""}
                                  error={
                                    errors.description &&
                                    ((errors.description as { [x: string]: string })[language] as string)
                                  }
                                  autoComplete="off"
                                  maxHeight="330px"
                                />
                                <TextAreaErrorHandling>
                                  {errors.description &&
                                    ((errors.description as { [x: string]: string })[language] as string)}
                                </TextAreaErrorHandling>
                              </TextAreaWrapper>
                            ),
                          },
                          {
                            label: (
                              <DropzoneTitle mandatory={false}>
                                {t("pages.antServiceManager.serviceAdd.formRows.tags.label")}
                              </DropzoneTitle>
                            ),
                            displayComponent: (
                              <TagsDiv>
                                {serviceDetails.tags ? serviceDetails.tags.toString().replace(/[,]/g, ", ") : ""}
                              </TagsDiv>
                            ),
                            editingComponent: (
                              <TextInputWithTag
                                error={tagErrorText}
                                addTagButtonText={t("pages.antServiceManager.serviceAdd.formRows.tags.addTag")}
                                tags={tags}
                                setTags={setTags}
                                inputValue={tagInputText}
                                setInputValue={setTagInputText}
                                tagsSuggestions={tagsSuggestions.current}
                                minTagTextLength={3}
                                maxTagTextLength={25}
                                placeholder={t("pages.antServiceManager.serviceAdd.formRows.tags.tagsPlaceholder")}
                                regexExp={RegExEpressions.especialCharacters}
                              />
                            ),
                          },
                          {
                            label: t("pages.antServiceManager.serviceDetails.formRows.antID.label"),
                            displayComponent: <NonEditableText>{serviceDetails.antId}</NonEditableText>,
                            editingComponent: <NonEditableText>{serviceDetails.antId}</NonEditableText>,
                          },
                          {
                            label: (
                              <DropzoneTitle mandatory={false}>
                                {t("pages.antServiceManager.serviceAdd.formRows.sso.ssoAllowedScopes")}
                              </DropzoneTitle>
                            ),
                            displayComponent: (
                              <ValueContainer>
                                <DescriptionContainer>
                                  <Value>
                                    {values.allowedScopes && values.allowedScopes.length > 0
                                      ? getAllowedScopeName(values.allowedScopes)
                                      : t("pages.antServiceManager.serviceAdd.formRows.sso.noAllowedScopesSelected")}
                                  </Value>
                                </DescriptionContainer>
                              </ValueContainer>
                            ),
                            editingComponent: (
                              <DropDownWrapper id="selectable-option">
                                <MultipleOptionsDropdown
                                  showClearAllFilters={false}
                                  options={getAllowedScopesOptions(allowedScopes)}
                                  clearAllFilters={() => {
                                    setFieldValue("allowedScopes", []);
                                  }}
                                  dropdownType={DropdownType.RECTANGULAR_NORMAL}
                                  hasValue={values.allowedScopes && values.allowedScopes.length > 0}
                                  isOptionSelected={(opt: SharedDropdownOption) => {
                                    return values.allowedScopes && values.allowedScopes.length > 0
                                      ? values.allowedScopes.includes(opt.key)
                                      : false;
                                  }}
                                  label={
                                    values.allowedScopes &&
                                    getSelectedAllowedScopeKeys(values.allowedScopes).length > 0 &&
                                    getAllowedScopeName(getSelectedAllowedScopeKeys(values.allowedScopes))
                                      ? getAllowedScopeName(getSelectedAllowedScopeKeys(values.allowedScopes))
                                      : t("pages.antServiceManager.serviceAdd.formRows.sso.placeholder")
                                  }
                                  toggleOption={(opt: SharedDropdownOption) => {
                                    if (values.allowedScopes && values.allowedScopes.includes(opt.key)) {
                                      const index = values.allowedScopes.indexOf(opt.key);
                                      const valuesHelper = [...values.allowedScopes];

                                      valuesHelper.splice(index, 1);

                                      setFieldValue("allowedScopes", getSelectedAllowedScopeKeys(valuesHelper));
                                    } else {
                                      const allowedScopes = values.allowedScopes ? values.allowedScopes : [];
                                      setFieldValue(
                                        "allowedScopes",
                                        getSelectedAllowedScopeKeys([...allowedScopes, opt.key]),
                                      );
                                    }
                                  }}
                                />
                              </DropDownWrapper>
                            ),
                          },
                          {
                            label: t("pages.antServiceManager.serviceDetails.formRows.status.label"),
                            displayComponent: displayStatus(),
                            editingComponent: <NonEditableText>{displayStatus()}</NonEditableText>,
                          },
                          {
                            label: (
                              <DropzoneTitle mandatory={false}>
                                {t("pages.antServiceManager.serviceAdd.formRows.highlighted.title")}
                              </DropzoneTitle>
                            ),
                            displayComponent: (
                              <SelectableOption>
                                {values.highlighted ? (
                                  <HighlightedLabel>
                                    {t("pages.antServiceManager.serviceAdd.formRows.highlighted.highlightedService")}
                                  </HighlightedLabel>
                                ) : (
                                  <NonHighlightedLabel>
                                    {t("pages.antServiceManager.serviceAdd.formRows.highlighted.nonHighlightedService")}
                                  </NonHighlightedLabel>
                                )}
                              </SelectableOption>
                            ),
                            editingComponent: (
                              <SelectableOption>
                                <Checkbox
                                  data-testid={"highlighted-checkbox"}
                                  name={"highlighted"}
                                  onValueChange={() => {
                                    if (values.highlighted) {
                                      setCanBeHighlighted(true);
                                    }
                                    setFieldValue("highlighted", !values.highlighted);
                                  }}
                                  value={values.highlighted}
                                  disabled={canBeHighlighted ? false : !values!.highlighted}
                                />
                                <Label>{t("pages.antServiceManager.serviceAdd.formRows.highlighted.label")}</Label>
                                {!canBeHighlighted && !values!.highlighted && (
                                  <TooltipContainer data-tip data-for="highlighted-tooltip" id={"highlighted-tooltip"}>
                                    <HelpIcon />
                                    <ReactTooltip
                                      event="mouseover"
                                      eventOff="mouseleave mousedown"
                                      place="top"
                                      type="dark"
                                      effect="solid"
                                      id={"highlighted-tooltip"}
                                      className={"tooltip-container"}
                                    >
                                      <TooltipText>
                                        {t("pages.antServiceManager.serviceAdd.formRows.highlighted.tooltip")}
                                      </TooltipText>
                                    </ReactTooltip>
                                  </TooltipContainer>
                                )}
                              </SelectableOption>
                            ),
                          },
                        ].concat(
                          categories.length > 0
                            ? [
                                {
                                  label: (
                                    <DropzoneTitle mandatory={false}>
                                      {t("pages.antServiceManager.serviceAdd.formRows.categories.label")}
                                    </DropzoneTitle>
                                  ),
                                  displayComponent:
                                    selectedCategories.length === 0 ? (
                                      <ValueContainer>
                                        <DescriptionContainer>
                                          <Value>
                                            {t(
                                              "pages.antServiceManager.serviceAdd.formRows.categories.noCategoriesText",
                                            )}
                                          </Value>
                                        </DescriptionContainer>
                                      </ValueContainer>
                                    ) : (
                                      <ValueContainer>
                                        <DescriptionContainer>
                                          <Value>{getCategoriesLabel()}</Value>
                                        </DescriptionContainer>
                                      </ValueContainer>
                                    ),
                                  editingComponent: (
                                    <DropDownWrapper id="selectable-option">
                                      <MultipleOptionsDropdown
                                        showClearAllFilters={false}
                                        options={categories.map(c => {
                                          return {
                                            label: c.name.translations[language]
                                              ? c.name.translations[language]
                                              : c.name.translations["en"],
                                            key: c.id,
                                          };
                                        })}
                                        clearAllFilters={() => {
                                          setSelectedCategories([]);
                                          setFieldValue("categories", []);
                                        }}
                                        dropdownType={DropdownType.RECTANGULAR_NORMAL}
                                        hasValue={selectedCategories.length > 0}
                                        isOptionSelected={opt => isCategorySelected(opt)}
                                        label={getCategoriesLabel()}
                                        toggleOption={opt => toggleCategory(opt, setFieldValue)}
                                      />
                                    </DropDownWrapper>
                                  ),
                                },
                              ]
                            : [],
                        )}
                      />
                    </EditServiceFormLeft>
                    <EditServiceFormRight>
                      {isEditing ? (
                        <>
                          <div>
                            <DropzoneTitle mandatory={true}>
                              {t("pages.serviceBuilder.modals.addService.icon.title")}
                            </DropzoneTitle>
                            {errors.serviceIcon ? <ImageError>{errors.serviceIcon}</ImageError> : null}
                            <Dropzone
                              inputName="serviceIcon"
                              onDrop={(file: any) => {
                                if (!file[0]) {
                                  setErrors({
                                    ...errors,
                                    serviceIcon: t("pages.serviceBuilder.modals.addService.icon.imageError"),
                                  });
                                  if (values.serviceIcon === null) {
                                    setFieldValue("serviceIcon", null);
                                  }
                                } else if (file[0] && file[0].size > MAX_FILE_SIZE) {
                                  setErrors({
                                    ...errors,
                                    serviceIcon: t(
                                      "pages.serviceBuilder.modals.updateService.icon.maxSizeError",
                                    ).replace("{0}", (MAX_FILE_SIZE / 1000000).toString()),
                                  });
                                  if (values.serviceIcon === null) {
                                    setFieldValue("serviceIcon", null);
                                  }
                                } else {
                                  setFieldValue("serviceIcon", file);
                                  onDrop(file);
                                }
                              }}
                              accept={"image/*"}
                              imageSrc={icon}
                              multiple={false}
                              dropText={t("pages.serviceBuilder.modals.updateService.icon.drop")}
                              releaseText={t("pages.serviceBuilder.modals.updateService.icon.release")}
                              error={!!errors.serviceIcon}
                            />
                          </div>
                          <div>
                            <DropzoneTitle mandatory={true}>
                              {t("pages.serviceBuilder.modals.addService.image.title")}
                            </DropzoneTitle>
                            {errors.serviceImage ? <ImageError>{errors.serviceImage}</ImageError> : null}
                            <Dropzone
                              inputName="serviceImage"
                              onDrop={(file: any) => {
                                if (!file[0]) {
                                  setErrors({
                                    ...errors,
                                    serviceImage: t("pages.serviceBuilder.modals.addService.icon.imageError"),
                                  });
                                  if (values.serviceImage === null) {
                                    setFieldValue("serviceImage", null);
                                  }
                                } else if (file[0] && file[0].size > MAX_FILE_SIZE) {
                                  setErrors({
                                    ...errors,
                                    serviceImage: t(
                                      "pages.serviceBuilder.modals.updateService.icon.maxSizeError",
                                    ).replace("{0}", (MAX_FILE_SIZE / 1000000).toString()),
                                  });
                                  if (values.serviceImage === null) {
                                    setFieldValue("serviceImage", null);
                                  }
                                } else {
                                  setFieldValue("serviceImage", file);
                                  onDropImage(file);
                                }
                              }}
                              accept={"image/*"}
                              imageSrc={image}
                              multiple={false}
                              roundedImage={false}
                              dropText={t("pages.serviceBuilder.modals.updateService.icon.drop")}
                              releaseText={t("pages.serviceBuilder.modals.updateService.icon.release")}
                              error={!!errors.serviceImage}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <DropzoneTitle mandatory={true}>
                              {t("pages.serviceBuilder.modals.addService.icon.title")}
                            </DropzoneTitle>
                            <AvatarContainer>
                              <AvatarDiv backgroundImage={icon} />
                            </AvatarContainer>{" "}
                          </div>

                          <div>
                            <Separator />
                            <DropzoneTitle mandatory={true}>
                              {t("pages.serviceBuilder.modals.addService.image.title")}
                            </DropzoneTitle>
                            <ImageContainer backgroundImage={image} />
                          </div>
                        </>
                      )}
                    </EditServiceFormRight>
                  </TopFormDiv>

                  <Actions>
                    {!isEditing ? (
                      <LinkContainer
                        onClick={() => {
                          setIsEditing(!isEditing);
                        }}
                        id="edit-button"
                      >
                        <IconContainer color={styleTheme.palette.turquoiseBlue}>
                          <EditIcon />
                        </IconContainer>
                        <span>{t("pages.antServiceManager.serviceDetails.editDetails.title")}</span>
                      </LinkContainer>
                    ) : (
                      <>
                        <LinkContainer
                          onClick={() => {
                            handleSubmit();
                          }}
                          id="save-changes-button"
                        >
                          <IconContainer color={styleTheme.palette.successGreen}>
                            <CheckIcon />
                          </IconContainer>
                          <span>{t("pages.userProfile.links.saveChanges")}</span>
                        </LinkContainer>

                        <LinkContainer
                          onClick={() => {
                            resetForm();
                            setIsEditing(false);
                            setIcon(serviceDetails.iconUrl);
                            setImage(serviceDetails.imageUrl);
                            setIconFile(undefined);
                            setImageFile(undefined);
                            setSelectedCategories(serviceDetails.categories.map((c: IDynamicScreenCategory) => c.id));
                          }}
                          id="cancel-changes"
                        >
                          <IconContainer color={styleTheme.palette.vodafoneRed}>
                            <UndoIcon />
                          </IconContainer>
                          <span>{t("pages.userProfile.links.cancelChanges")}</span>
                        </LinkContainer>
                      </>
                    )}

                    {serviceDetails.status === "LIVE" ? (
                      <LinkContainer disabled={isEditing} onClick={disableService} id="disable-service-button">
                        <IconContainer color={styleTheme.palette.midGrey}>
                          <CancelIcon />
                        </IconContainer>
                        <span>{t("pages.antServiceManager.serviceDetails.editDetails.disableService")}</span>
                      </LinkContainer>
                    ) : (
                      <LinkContainer
                        onClick={() => activateService()}
                        disabled={isEditing}
                        id="activate-service-button"
                      >
                        <IconContainer color={styleTheme.palette.successGreen}>
                          <CheckIcon />
                        </IconContainer>
                        <span>{t("pages.antServiceManager.serviceDetails.editDetails.activateService")}</span>
                      </LinkContainer>
                    )}
                    <LinkContainer id="delete-service" disabled={isEditing} onClick={deleteService}>
                      <IconContainer color={styleTheme.palette.errorRed}>
                        <TrashIcon />
                      </IconContainer>
                      <span>{t("pages.antServiceManager.serviceDetails.editDetails.deleteService")}</span>
                    </LinkContainer>
                  </Actions>
                </AntEditServiceForm>
              )}
            />
          )}
        </PageContainer>
      )}
    </>
  );
};

export default AntServiceManagerDetailsPage;

const AntEditServiceForm = styled("form")`
  display: flex;
  margin: auto;
  flex-direction: column;
  max-width: 62%;
  margin-left: 204px;
  margin-right: 204px;
  margin-top: 110px;
  @media (max-width: 1024px) {
    min-width: 88%;
    margin-left: 44px;
    margin-right: 44px;
  }
`;

const TopFormDiv = styled("div")`
  display: flex;
  flex-direction: row;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
`;

const EditServiceFormLeft = styled("div")`
  flex: 1;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  > div {
    margin-bottom: 10px;
  }
  @media (max-width: 1024px) {
    padding-right: 0;
    min-width: 100%;
    > div {
      margin-bottom: 40px;
    }
  }
`;

export const TooltipContainer = styled("div")`
  display: flex;
  margin-left: 8px;
  cursor: pointer;
`;

export const TooltipText = styled("div")`
  font-size: 12px;
  line-height: 14px;
  font-family: "Vodafone Rg";
  color: white;
  display: flex;
  width: 210px;
  text-align: center;
`;

const EditServiceFormRight = styled("div")`
  overflow: hidden;
  min-width: 250px;
  @media (max-width: 1024px) {
    padding-bottom: 32px;
    > div:first-child {
      margin-bottom: 48px;
    }
  }
`;

const DropzoneTitle = styled("div")<{ mandatory: boolean }>`
  min-height: 21px;
  font-family: Vodafone Rg;
  font-weight: bold;
  line-height: 21px;
  font-size: 16px;
  color: #999999;
  margin-bottom: 8px;
  width: fit-content;
  display: inline-flex;
  ${props =>
    props.mandatory ? "::after {content: ' *'; color: #ff0000; font-weight: 400; padding-left: 2px;}" : null};
`;

const ImageContainer = styled("div")<{ backgroundImage: string }>`
  position: relative;
  width: 250px;
  height: 160px;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  background-image: url("${props => props.backgroundImage}");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const LinkContainer = styled("div")<{ disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-family: Vodafone Rg;
  color: ${styleTheme.palette.darkGrey};
  font-size: 16px;
  margin-bottom: 15px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? "none" : "inherit")};
  width: -moz-fit-content;
  width: fit-content;

  > div {
    margin-right: 8px;
  }
`;

const NonEditableText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${styleTheme.palette.darkGrey};
`;

const IconContainer = styled("div")<{ color: string }>`
  width: 20px;
  height: 20px;
  > svg {
    stroke: ${props => props.color};
  }
`;

const UserStatus = styled("div")<{ color: any }>`
  font-family: Vodafone Rg;
  color: ${props => props.color};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
`;

const TagsDiv = styled("div")`
  font-family: Vodafone Rg;
  color: ${props => props.theme.palette.turquoise};
  text-transform: capitalize;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
`;

const DescriptionContainer = styled("div")`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  hyphens: auto;
`;

const Value = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  margin-top: -12px;
`;

const ValueContainer = styled("div")`
  position: relative;
`;

const AvatarContainer = styled("div")`
  position: relative;
  width: 250px;
  height: 160px;
  border: 1px solid #ebebeb;
  background-color: #eaf7ff;
  border-radius: 10px;
`;

const AvatarDiv = styled("div")<{ backgroundImage: string }>`
  border-radius: 50%;
  width: 128px;
  height: 128px;
  border: 1px solid ${props => props.theme.palette.aluminium};
  overflow: hidden;
  background-image: url("${props => props.backgroundImage}");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: auto;
  margin-top: 16px;
`;

const Separator = styled("div")`
  margin-top: 24px;
`;

const Actions = styled("div")`
  margin-top: 40px;
  @media (max-width: 1024px) {
    margin-top: 20px;
    margin-bottom: 48px;
  }

  button {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  button:not(:last-of-type) {
    margin-bottom: 12px;
  }

  button:first-of-type {
    svg {
      stroke: ${props => props.theme.palette.secondary.blueLight};
    }
  }

  button:nth-of-type(2) {
    svg {
      stroke: ${props => props.theme.palette.midGrey};
    }
  }

  button:nth-of-type(3) {
    svg {
      stroke: ${props => props.theme.palette.vodafoneRed};
    }
  }
`;

const Label = styled("div")`
  font-family: Vodafone Rg;
  margin-left: 8px;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 19px;
  letter-spacing: normal;
  color: ${props => props.theme.palette.midGrey};
`;

const SelectableOption = styled("div")`
  display: flex;
`;

const HighlightedLabel = styled(Label)`
  margin-left: 0;
  color: ${props => props.theme.palette.warningYellow};
`;

const NonHighlightedLabel = styled(Label)`
  margin-left: 0;
  color: ${props => props.theme.palette.black};
`;

const ImageError = styled("div")`
  width: fit-content;
  display: inline-flex;
  height: 19px;
  color: #ff0000;
  font-family: Vodafone Rg;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  float: right;
  font-weight: 400;
`;

export const ToogleDiv = styled("div")<{ isEditing: boolean }>`
  ${props => (!props.isEditing ? "label{background-color: white;opacity: 0.5;}" : null)};
`;

const TextAreaWrapper = styled("div")<{ mandatory: boolean }>`
  > div > textarea {
    color: #333333 !important;
  }

  ${({ mandatory }) =>
    mandatory &&
    css`
      > div > span > span:first-of-type:after {
        content: " *";
        width: 7px;
        color: #ff0000;
        font-family: Vodafone Rg;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
      }
    `};
`;

const TextAreaErrorHandling = styled("div")`
  font-family: Vodafone Rg;
  font-size: 14px;
  color: #ff0000;
  margin-top: 4px;
`;

const ServiceName = styled("span")`
  white-space: normal;
  word-wrap: break-word;
  font-family: Vodafone Rg;
  font-size: 16px;
`;
