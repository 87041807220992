import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "@wit/mpesa-ui-components";
import { useSelector } from "react-redux";
import { IStoreInterface } from "../../../../configs/store.config";
import useVersionsDiff, { VersionItem } from "../../../../shared/hooks/use-version-diff/use-version-diff.hook";
import VersionItemStatusChip from "../../../../shared/hooks/use-version-diff/version-item-status-chip.component";
import { getStatusColor } from "../banks.utils";
import { Row, Column } from "../../../../shared/shared.styled";
import { IBank, BanksStatusEnum } from "../bank.model";

interface IPublishBanksModalProps {
  onCancelClick: () => void;
  submit: () => void;
}

const PublishBanksModal = ({ onCancelClick, submit }: IPublishBanksModalProps) => {
  const [t] = useTranslation();

  const { banks } = useSelector((state: IStoreInterface) => state.banksReducer);
  const { prev, current } = useVersionsDiff(banks!.lastVersion, banks!.data);

  const renderIBankItem = (item: VersionItem<IBank>) => {
    return (
      <ItemColumn disabled={item.status === BanksStatusEnum.DELETE}>
        <Row>
          <ImageContainer>
            <img
              style={{ height: 24 }}
              src={`./imagesuploaded/${item.urlIcon}`}
              alt={item.name}
              onError={e => ((e.target as HTMLImageElement).src = "./images/bank.png")}
            />
          </ImageContainer>
          <ItemInfo>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {item.shortCode && (
                <Row>
                  {t("pages.banks.publishModal.shortCode")}: {item.shortCode}
                </Row>
              )}
              <VersionItemStatusChip status={item.versionItemStatus} />
            </div>
            {item.name && (
              <Row>
                {t("pages.banks.publishModal.name")}: {item.name}
              </Row>
            )}
            {item.accountNumberPrefix && (
              <Row>
                {t("pages.banks.publishModal.prefix")}: {item.accountNumberPrefix}
              </Row>
            )}
          </ItemInfo>
          <StatusContainer>
            {item.status === BanksStatusEnum.LIVE && (
              <StatusRow color={getStatusColor(BanksStatusEnum.LIVE)}>{t("pages.banks.status.live")}</StatusRow>
            )}
            {item.status === BanksStatusEnum.DELETE && (
              <StatusRow color={getStatusColor(BanksStatusEnum.DELETE)}>{t("pages.banks.status.delete")}</StatusRow>
            )}
            {item.status === BanksStatusEnum.DISABLE && (
              <StatusRow color={getStatusColor(BanksStatusEnum.DISABLE)}>
                {t("pages.banks.status.deactivate")}
              </StatusRow>
            )}
            {item.status === BanksStatusEnum.DISABLED && (
              <StatusRow color={getStatusColor(BanksStatusEnum.DISABLED)}>
                {t("pages.banks.status.deactivated")}
              </StatusRow>
            )}
          </StatusContainer>
        </Row>
      </ItemColumn>
    );
  };

  return (
    <ModalContainer>
      <Row style={{ padding: 24 }}>
        <Column>
          <ModalTitle>{t("pages.banks.publishModal.publish")}</ModalTitle>
          <ModalSubTitle>{t("pages.banks.publishModal.wantPublish")}</ModalSubTitle>
        </Column>
      </Row>
      <Content>
        <Row>
          <ItemColumn>{t("pages.banks.publishModal.previousVersion")}</ItemColumn>
          <ItemColumn>{t("pages.banks.publishModal.editedVersion")}</ItemColumn>
        </Row>
        <ItemsContainer>
          {prev &&
            current &&
            current.map((edited, index) => {
              return (
                <Item key={edited.id}>
                  {prev[index] ? renderIBankItem(prev[index]) : <ItemColumn />}
                  {edited ? renderIBankItem(edited) : <ItemColumn />}
                </Item>
              );
            })}
        </ItemsContainer>
      </Content>
      <ButtonsContainer style={{ padding: 24 }}>
        <div>
          <PrimaryButton
            type="button"
            id="cancel-button"
            onClick={onCancelClick}
            titleLabel={t("pages.banks.publishModal.cancelBtnLabel")}
          />
        </div>
        <div>
          <PrimaryButton
            id="confirm-button"
            redTheme={true}
            type="submit"
            onClick={() => submit()}
            titleLabel={t("pages.banks.publishModal.saveChangesBtnLabel")}
          />
        </div>
      </ButtonsContainer>
    </ModalContainer>
  );
};

export default PublishBanksModal;

const ModalContainer = styled("div")`
  display: flex;
  flex-direction: column;
  font-family: Vodafone Rg;
`;

const ModalTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 21px;
`;

const ModalSubTitle = styled(ModalTitle)`
  font-size: 18px;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 50px;

  > div {
    width: "fit-content";

    :first-of-type {
      margin-right: 12px;
    }
  }
`;

const ItemsContainer = styled.div`
  max-height: 220px;
  overflow: scroll;
  width: 100%;
  overflow-x: hidden;
`;

const Item = styled(Row)`
  display: flex;
`;

const ImageContainer = styled(Column)`
  margin-right: 24px;
  display: flex;
  align-self: baseline;
  flex: 1;
  padding: 4px;
`;

const ItemColumn = styled(Column)<{ disabled?: Boolean }>`
  flex: 1;
  padding: 16px 0px 16px 0;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  padding-left: 24px;
  &:first-child {
    padding-right: 74px;
  }

  &:last-child {
    padding-left: 30px;
    padding-right: 44px;
  }
`;

const ItemInfo = styled(Column)`
  flex: 20;
`;
const StatusContainer = styled(Column)`
  padding-left: 24px;
  display: flex;
  align-self: baseline;
  flex: 1;
`;

const StatusRow = styled(Row)<{ color: string }>`
  color: ${props => props.color};
  display: flex;
  justify-content: flex-end;
`;

const Content = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${props => props.theme.palette.darkGrey};

  background: ${props => `linear-gradient(90deg, ${props.theme.palette.lightGrey} 50%, transparent 50%)`};
`;
