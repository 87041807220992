import { ColumnProps } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import i18next from "i18next";
import React from "react";

export const AppReleaseVersionsUtils = {
  getLastVersionTableColumns: (): ColumnProps[] => {
    return [
      {
        ratio: 7 / 12,
        label: i18next.t("pages.appDetails.releaseVersions.versionName"),
        formKey: "name",
        isEditable: false,
        changeFunction: content => {
          return (
            <span style={{ color: styleTheme.palette.midGrey }} id={content}>
              {content}
            </span>
          );
        },
      },
      {
        ratio: 4 / 12,
        label: i18next.t("pages.appDetails.releaseVersions.versionNumber"),
        formKey: "version",
        isEditable: false,
      },
    ];
  },
  getReleaseVersionsTableColumns: (): ColumnProps[] => {
    return [
      {
        ratio: 7 / 12,
        label: i18next.t("pages.appDetails.releaseVersions.versionName"),
        formKey: "name",
        isEditable: false,
        sortable: true,
        changeFunction: content => {
          return <span id={content}>{content}</span>;
        },
      },
      {
        ratio: 2 / 12,
        label: i18next.t("pages.appDetails.releaseVersions.versionNumber"),
        formKey: "version",
        isEditable: false,
        sortable: true,
      },
    ];
  },
};
