import { Magnifier, ModalTypeEnum, useAlert, useFilters, useModal } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import React, { ReactNode, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { IStoreInterface } from "../../../../configs/store.config";
import { RoutesEnum } from "../../../../routes/routes.constants";
import LanguageSelectorDropdown from "../../../../shared/components/language-selector-dropdown.component";
import SafaricomDeleteModal from "../../../../shared/components/safaricom-delete-modal/safaricom-delete-modal.component";
import { ILanguage } from "../../../../shared/models/language.model";
import { ListHeader, ListHeaders } from "../../../../shared/responsive-ui.styled";
import LanguagesApi from "../../../../shared/services/languages.api";
import { LoadingText } from "../../../../shared/shared.styled";
import { isSFCMarket } from "../../../../shared/shared.utils";
import { arrayUtils } from "../../../../shared/utils/array.utils";
import Document from "../../../configurations/rate-cards/components/document.component";
import { Container } from "../../../tools/identity-converter/identity-converter.styled";
import { ServiceManagerActions } from "../../service-manager.store";
import CategoriesApi from "./categories.api";
import { ICategory } from "./categories.model";
import { filterCategories } from "./categories.utils";
import AddNewCategoryListItem from "./components/add-new-category-list-item.component";
import CategoriesFilters from "./components/categories-filter.component";
import CategoryListItem from "./components/category-list-item.component";

/**
 * Category Page
 * @function CategoryPage
 * @returns {JSX.Element} JSX Element
 */
const CategoriesPage = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showAlert, , setAlertProps] = useAlert();
  const { categories } = useSelector((state: IStoreInterface) => state.serviceManagerReducer);
  const [showableCategories, setShowableCategories] = React.useState<ICategory[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [deleteModal, setDeleteModal] = React.useState<ReactNode>();
  const [availableLanguages, setAvailableLanguages] = useState<{ label: string; key: string }[]>([
    { label: t("commons.languages.en"), key: "en" },
  ]);
  const [selectedLanguage, setSelectedLanguage] = React.useState(availableLanguages[0]);

  const [showDeleteModal, hideDeleteModal] = useModal(ModalTypeEnum.CustomModal, undefined, deleteModal);
  const {
    filters,
    updateFilter,
    isFilterActive,
    clearFilter,
    resetFilters,
    getFilterValue,
    getFiltersQueryString,
    updateMultipleFilters,
  } = useFilters();

  /**
   * Get icons API call
   * @function getIcons
   */
  const getIcons = () => {
    CategoriesApi.methods.getIconsList().then(res => {
      dispatch(ServiceManagerActions.creators.fectionsIconsCategories(res.data));
    });
  };

  /**
   * Get available languages API call
   * @function getAvailableLanguages
   */
  const getAvailableLanguages = () => {
    LanguagesApi.methods.getAvailableLanguages().then(
      res => {
        setAvailableLanguages(
          res.data.availableLanguages.map((lang: ILanguage) => {
            return {
              label: t(`commons.languages.${lang.code}`),
              key: lang.code,
            };
          }),
        );
      },
      () => {},
    );
  };

  /**
   * Get categories API call
   * @function getCategories
   */
  const getCategories = () => {
    CategoriesApi.methods
      .getCategories()
      .then(
        res => {
          let categoriesSorted = res.data;
          if (!isSFCMarket()) {
            categoriesSorted = categoriesSorted.sort((a, b) => a.categoryName.localeCompare(b.categoryName));
          }
          dispatch(ServiceManagerActions.creators.fetchCategories(categoriesSorted));

          if (filters.size > 0) {
            setShowableCategories(
              filterCategories(res.data ? [...categoriesSorted] : [], filters, selectedLanguage.key),
            );
          } else {
            setShowableCategories(categoriesSorted);
          }
        },
        () => {
          setAlertProps({
            title: t("pages.serviceManager.categories.getCategoriesError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Delete a category API call
   * @function deleteCategory
   * @param {ICategory} category - The category data
   */
  const onDelete = (category: ICategory) => {
    if (category.services.length > 0) {
      setAlertProps({
        title: t("pages.serviceManager.categories.deleteCategoriesWithMiniAppError"),
        type: AlertTypeEnum.ERROR,
      });
      showAlert();
    } else {
      setDeleteModal(
        <SafaricomDeleteModal
          secondaryAction={() => hideDeleteModal()}
          primaryAction={() => {
            CategoriesApi.methods
              .deleteCategory(category.id)
              .then(
                res => {
                  getCategories();
                  setAlertProps({
                    title: t("pages.serviceManager.categories.categoryDeleted"),
                    type: AlertTypeEnum.SUCCESS,
                  });
                },
                () => {
                  setAlertProps({
                    title: t("pages.serviceManager.categories.deleteCategoriesError"),
                    type: AlertTypeEnum.ERROR,
                  });
                },
              )
              .finally(() => {
                hideDeleteModal();
                showAlert();
              });
          }}
          title={t("pages.serviceManager.categories.categoryForm.deleteTitleModal")}
          description={t("pages.serviceManager.categories.categoryForm.deleteDescriptionModal").replace(
            "{category}",
            category.categoryName,
          )}
          primaryBtnLabel={t("pages.serviceManager.categories.categoryForm.deleteLabelBtn")}
        />,
      );
      showDeleteModal();
    }
  };

  /**
   * Handle the dragend event
   * @function onDragEnd
   * @fires OnDragEnd
   * @param {Object} result - The result object of the dragend event
   */
  const onDragEnd = (result: any) => {
    const { source, destination } = result;

    if (destination) {
      editCategoryOrder(result.draggableId, destination.index, source.index);
    }
  };

  /**
   * Edit category order API call
   * @param {string} identifier - The identifier of the category
   * @param {number} destinationIndex - The new order of the current category
   * @param {number} sourceIndex - The old order of the current category
   */
  const editCategoryOrder = (identifier: string, destinationIndex: number, sourceIndex: number) => {
    const itemsList = arrayUtils.reorderArray<ICategory>(showableCategories, sourceIndex, destinationIndex);
    setShowableCategories(itemsList);
    CategoriesApi.methods
      .reOrderCategory(identifier, {
        fromPosition: sourceIndex + 1,
        toPosition: destinationIndex + 1,
      })
      .then(
        () => {},
        () => {
          //reverse ordering in case of api failure
          getCategories();
          setAlertProps({
            title: t("pages.categories.edit.error.reorder"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
  };

  React.useEffect(() => {
    getCategories();
    getIcons();
    getAvailableLanguages();
  }, []);

  React.useEffect(() => {
    if (filters.size > 0) {
      setShowableCategories(filterCategories(categories ? [...categories] : [], filters, selectedLanguage.key));
    } else {
      setShowableCategories(categories);
    }
  }, [filters]);

  return (
    <>
      <CategoriesFilters
        filters={filters}
        isFilterActive={isFilterActive}
        updateFilter={updateFilter}
        clearFilter={clearFilter}
        resetFilters={resetFilters}
        getFilterValue={getFilterValue}
        getFiltersQueryString={getFiltersQueryString}
        updateMultipleFilters={updateMultipleFilters}
      />
      <CategoriesListContainer>
        {!isLoading ? (
          <>
            <ListHeaders>
              <ListHeader ratio={6 / 12}>
                <CategoryHeaderContainer>
                  {t("pages.serviceManager.categories.table.category")}
                  {availableLanguages.length > 1 && (
                    <div style={{ paddingLeft: "8px", paddingTop: "3px" }}>
                      <LanguageSelectorDropdown
                        availableLanguages={availableLanguages}
                        selectedLanguage={selectedLanguage}
                        changeSelectedLanguage={setSelectedLanguage}
                        id={"language-selector"}
                      />
                    </div>
                  )}
                </CategoryHeaderContainer>
              </ListHeader>
              <ListHeader ratio={3 / 12}>{t("pages.serviceManager.categories.table.antId")}</ListHeader>
            </ListHeaders>
            {filters.size === 0 ? (
              <AddNewCategoryListItem addNewCategoryFn={() => history.push(RoutesEnum.SERVICE_MANAGER_ADD_CATEGORY)} />
            ) : null}
            {categories && categories.length && showableCategories ? (
              <>
                {showableCategories.length > 0 ? (
                  <>
                    <Container>
                      <DragDropContext onDragEnd={result => onDragEnd(result)}>
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <ItemsContainer {...provided.droppableProps} ref={provided.innerRef}>
                              {showableCategories.map((category: ICategory, index: number) => (
                                <Draggable key={category.id} draggableId={category.id} index={index}>
                                  {(providedDrag, snapshotDrag) => (
                                    <div
                                      ref={providedDrag.innerRef}
                                      {...providedDrag.draggableProps}
                                      {...providedDrag.dragHandleProps}
                                    >
                                      <CategoryListItem
                                        selectedLanguage={selectedLanguage}
                                        onDelete={onDelete}
                                        onEdit={() => {
                                          dispatch(ServiceManagerActions.creators.setCategory(category));
                                          history.push(
                                            `${RoutesEnum.SERVICE_MANAGER_CATEGORY_DETAILS.replace(
                                              ":categoryId",
                                              category.id,
                                            )}?editing=true`,
                                          );
                                        }}
                                        onClickItemFn={() => {
                                          dispatch(ServiceManagerActions.creators.setCategory(category));
                                          history.push(
                                            RoutesEnum.SERVICE_MANAGER_CATEGORY_DETAILS.replace(
                                              ":categoryId",
                                              category.id,
                                            ),
                                          );
                                        }}
                                        item={category}
                                        key={category.id}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </ItemsContainer>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Container>
                  </>
                ) : (
                  <MagnifierContainer>
                    <Magnifier />
                    <NoResultsFoundText>
                      {t("pages.serviceManager.categories.table.noSearchResults")}
                    </NoResultsFoundText>
                  </MagnifierContainer>
                )}
              </>
            ) : (
              <EmptyState>
                <Document />
                <SelectText>{t("pages.serviceManager.categories.table.emptyState")}</SelectText>
              </EmptyState>
            )}
          </>
        ) : (
          <LoadingText>{t("commons.loadingResults")}</LoadingText>
        )}
      </CategoriesListContainer>
    </>
  );
};

export default CategoriesPage;

const CategoriesListContainer = styled("div")`
  margin-bottom: 50px;
  margin-top: 31px;
`;

const MagnifierContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 50px;
  svg {
    width: 140px;
    height: 140px;
    margin-bottom: 24px;
    stroke: red;
  }
`;

const NoResultsFoundText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.midGrey};
`;

const EmptyState = styled("div")`
  margin-top: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const SelectText = styled("div")`
  font-family: "Vodafone Rg";
  width: 400px;
  height: 51px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  margin-top: 35px;
`;

const CategoryHeaderContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ItemsContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;
