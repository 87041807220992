import React from "react";
import styled from "styled-components";
import { IServiceManagerService } from "../../../shared/models/service-manager.model";
import { useTranslation } from "react-i18next";
import { Separator } from "../../../shared/shared.styled";
import { ThreeDotsIcon, useAlert } from "@wit/mpesa-ui-components";
import { SafaricomServiceItemTitle } from "../service-manager.styled";
import StarRating from "react-svg-star-rating";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import ServiceManagerApi from "../service-manager.api";
import { ServiceManagerActions } from "../service-manager.store";
import { useDispatch } from "react-redux";
import DragRulesDialog from "../../../shared/components/drag-rules.component";
import { ServiceType } from "../service-details/pages/details.interfaces";
import { UserType } from "./section-manager.interface";
import LoadingComponent from "../../../shared/components/loading-component/LoadingComponent";
import { FEATURES } from "../../../shared/renderer.utils";
import useCheckFeatureAvailable from "../../../shared/hooks/use-check-available-feature";

interface IServicePromotedItemProps {
  item: IServiceManagerService;
  groupName: string;
  dragprops?: any;
  serviceType: ServiceType;
  userType?: UserType;
}

/** ServicePromotedItem Component */
const ServicePromotedItem = ({ item, groupName, dragprops, serviceType, userType }: IServicePromotedItemProps) => {
  // Hooks initialization
  const [t] = useTranslation();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const [showRules, setShowRules] = React.useState(false);
  const [showUnpromote, setShowUnpromote] = React.useState(false);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const serviceUserTypeEnabled = useCheckFeatureAvailable(FEATURES.SERVICE_USER_TYPE);

  /** function to unpromoteService */
  const unpromoteService = () => {
    let toPosition: number;

    item.groups.forEach(group => {
      if (
        group.name === groupName &&
        group.serviceType === serviceType &&
        (!serviceUserTypeEnabled || group.userType === userType) &&
        group.order
      ) {
        toPosition = group.order;
        if (group.promotedOrder) {
          if (!toPosition) {
            setIsLoading(true);
            ServiceManagerApi.methods
              .unpromoteDeleteService(
                item.id,
                groupName,
                group.serviceType,
                serviceUserTypeEnabled ? userType : undefined,
              )
              .then(() => {
                setAlertProps({
                  title: t("pages.serviceManager.servicePromoted.success.unpromoteDelete"),
                  type: AlertTypeEnum.SUCCESS,
                });
                showAlert();
                ServiceManagerActions.creators.fetchingServicesAction();
                ServiceManagerApi.methods.getServices().then(res => {
                  dispatch(ServiceManagerActions.creators.fetchServicesSuccessAction(res.data));
                  setIsLoading(false);
                });
              })
              .catch(() => {
                setIsLoading(false);
              });
          } else {
            unpromoteServiceCall(item.id, group.serviceType, toPosition);
          }
        } else {
          setAlertProps({
            title: t("pages.serviceManager.servicePromoted.errors.unpromoteDelete"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        }
      }
    });
  };

  /** function to umpromote service request */
  const unpromoteServiceCall = (identifier: string, serviceType: ServiceType, toPosition: number) => {
    setIsLoading(true);
    ServiceManagerApi.methods
      .unpromoteService(identifier, groupName, serviceType, toPosition, serviceUserTypeEnabled ? userType : undefined)
      .then(
        () => {
          setAlertProps({
            title: t("pages.serviceManager.servicePromoted.success.unpromoteService"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          ServiceManagerActions.creators.fetchingServicesAction();
          ServiceManagerApi.methods.getServices().then(res => {
            dispatch(ServiceManagerActions.creators.fetchServicesSuccessAction(res.data));
            setIsLoading(false);
          });
        },
        () => {
          setIsLoading(false);
          setAlertProps({
            title: t("pages.serviceManager.servicePromoted.errors.unpromoteService"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
  };

  return (
    <ServiceGridItemContainer style={{ border: dragprops.isDragging ? "1px solid #2fc56d" : "0px" }}>
      <HorizontalPaddedContainer>
        <ServiceItemImage backgroundImage={item.image}></ServiceItemImage>
      </HorizontalPaddedContainer>
      <Separator />
      <VerticalPaddedContainer style={{ height: "56px" }}>
        <Dots onMouseEnter={() => setShowRules(true)} onMouseLeave={() => setShowRules(false)}>
          <ThreeDotsIcon />
          {showRules ? <DragRulesDialog text={t("pages.serviceManager.servicePromoted.reorder")} /> : null}
        </Dots>
        <ServiceItemLogoContainer>
          <ServiceItemLogo backgroundImage={item.icon}></ServiceItemLogo>
        </ServiceItemLogoContainer>
        <InfoContainer>
          <SafaricomServiceItemTitle>{item.name}</SafaricomServiceItemTitle>
          <Description>{item.description}</Description>
        </InfoContainer>
        {isLoading ? (
          <LoadingContainer>
            <LoadingComponent></LoadingComponent>
          </LoadingContainer>
        ) : (
          <StarContainer onMouseEnter={() => setShowUnpromote(true)} onMouseLeave={() => setShowUnpromote(false)}>
            <StarRating
              hoverColor={"#2fc56d"}
              activeColor={"#2fc56d"}
              count={1}
              innerRadius={20}
              size={20}
              initialRating={1}
              handleOnClick={() => unpromoteService()}
            />
            {showUnpromote ? (
              <DragRulesDialog
                text={t("pages.serviceManager.servicePromoted.unpromote")}
                height={"40px"}
                width={"80px"}
              />
            ) : null}
          </StarContainer>
        )}
      </VerticalPaddedContainer>
    </ServiceGridItemContainer>
  );
};

export default ServicePromotedItem;

const ServiceItemImage = styled("div")<{ backgroundImage: string }>`
  width: 100%;
  height: 200px;
  background-size: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/jpeg;base64,${props => props.backgroundImage}");
`;

const ServiceItemLogo = styled("div")<{ backgroundImage: string }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: solid 1px #ebebeb;
  background-image: url("data:image/jpeg;base64,${props => props.backgroundImage}");
`;

const Dots = styled("div")`
  display: flex;
  width: 30px;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const StarContainer = styled("div")`
  display: flex;
  width: 40px;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
`;

const ServiceItemLogoContainer = styled("div")`
  display: flex;
  width: 50px;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const HorizontalPaddedContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;

const VerticalPaddedContainer = styled("div")`
  display: flex;
  flex-direction: row;
  position: relative;
`;

const InfoContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  width: 60%;
  height: 100%;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  hyphens: auto;
  min-height: 36px;
  :last-of-type {
    margin-bottom: 24px;
  }
`;

const Description = styled("span")`
  min-width: 50px;
  font-family: Vodafone Rg;
  font-size: 11px;
  color: ${props => props.theme.palette.greyDarker};
  display: flex;
  flex-direction: row;
`;

const ServiceGridItemContainer = styled("div")`
  height: fit-content;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  display: flex;
  flex-direction: column;
  cursor: pointer;
  flex: 1;
`;
const LoadingContainer = styled("div")`
  display: flex;
  width: 32px;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 5px;
`;
