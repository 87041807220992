import React from "react";
import { useDropzone, DropzoneOptions } from "react-dropzone";
import styled from "styled-components";
import { FileUploadIcon, CloudUploadIcon } from "@wit/mpesa-ui-components";

interface IDropzoneOptionsProps extends DropzoneOptions {
  dropTitle?: string;
  dropDescription?: string;
  releaseText?: string;
  inputName?: string;
  fileName?: string;
}

const Dropzone = ({
  onDrop,
  accept,
  multiple,
  dropTitle = "",
  dropDescription = "",
  releaseText = "",
  inputName = "dropzoneInput",
  fileName = "",
}: IDropzoneOptionsProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple,
  });

  return (
    <DropzoneDiv active={isDragActive} {...getRootProps()}>
      <DropzoneInput name={inputName} {...getInputProps()} />
      <DropzoneInsideDiv id="dropzone-div">
        <TextDiv>
          {isDragActive ? (
            <ContentTextWrapper>
              <IconWrapper active={isDragActive}>
                <CloudUploadIcon />
              </IconWrapper>
              <TitleContent active={isDragActive}>{releaseText}</TitleContent>
            </ContentTextWrapper>
          ) : (
            <ContentTextWrapper>
              <IconWrapper>
                <FileUploadIcon />
              </IconWrapper>
              <div style={{ margin: "auto" }}>
                <TitleContent>{dropTitle}</TitleContent>
                <DescriptionContent>{dropDescription}</DescriptionContent>
                <DescriptionContent hasFile={fileName !== ""}>
                  File: {fileName !== "" ? fileName : "No file selected"}
                </DescriptionContent>
              </div>
            </ContentTextWrapper>
          )}
        </TextDiv>
      </DropzoneInsideDiv>
    </DropzoneDiv>
  );
};

export default Dropzone;

const DropzoneDiv = styled("div")<{ active: boolean }>`
  height: 126px;
  border-radius: 11px;
  background-color: #ffffff;
  cursor: pointer;
  padding: 22px 20px 22px 22px;
  display: flex;
  transition: height 0.25s ease-in-out;
  border: 1px dashed #999999;
  ${props =>
    props.active
      ? `background-color: ${props.theme.palette.vodafoneRed}66;border: 1px dashed ${props.theme.palette.vodafoneRed};`
      : null};
`;

const DropzoneInput = styled("input")``;

const DropzoneInsideDiv = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: Vodafone Rg;
  margin: auto;
  align-items: center;
`;

const TextDiv = styled("div")`
  background-color: transparent;
  display: flex;
  height: 100%;
`;

const IconWrapper = styled("div")<{ active?: boolean }>`
  svg {
    height: 100%;
    width: ${props => (props.active ? "40px" : "70px")};

    stroke: ${props => (props.active ? props.theme.palette.vodafoneRed : props.theme.palette.midGrey)};
  }
  padding-right: 10px;
`;

const ContentTextWrapper = styled("div")`
  display: flex;
`;

const TitleContent = styled("div")<{ active?: boolean }>`
  font-family: Vodafone Rg;
  color: ${props => (props.active ? "#000000" : props.theme.palette.midGrey)};
  font-size: 20px;
  margin: auto;
`;
const DescriptionContent = styled("div")<{ hasFile?: boolean }>`
  font-family: Vodafone Rg;
  color: ${props => (props.hasFile ? props.theme.palette.vodafoneRed : props.theme.palette.midGrey)};
  font-size: 16px;
  margin: auto;
`;
