import React from "react";
import styled from "styled-components";
import { IReferralCampaign, IScreen, ITranslatableObject, TypeScreenEnum } from "../referral-campaigns.model";
import MobileSchema from "./mobile-schema.component";

interface IReceiverScreenPreviewProps {
  values: IReferralCampaign;
  language: string;
}

/**
 * ReceiverScreenPreview component
 */
const ReceiverScreenPreview = ({ values, language: lang }: IReceiverScreenPreviewProps) => {
  /**
   * method to find a screen
   */
  const findScreen = (type: TypeScreenEnum): IScreen => {
    return values.screens.find(reward => reward.type === type) as IScreen;
  };

  return (
    <PreviewContainer>
      <div style={{ marginTop: "20px" }}>
        <MobileSchema>
          <Background>
            <Image
              image={
                findScreen(TypeScreenEnum.RECEIVER) && findScreen(TypeScreenEnum.RECEIVER).urlImage
                  ? (findScreen(TypeScreenEnum.RECEIVER).urlImage as string)
                  : null
              }
            />
            <HeaderTitle>
              {values.screens &&
              findScreen(TypeScreenEnum.RECEIVER) &&
              findScreen(TypeScreenEnum.RECEIVER).headerTitle &&
              (findScreen(TypeScreenEnum.RECEIVER).headerTitle as ITranslatableObject).translations[lang]
                ? (findScreen(TypeScreenEnum.RECEIVER).headerTitle as ITranslatableObject).translations[lang]
                : `Header title (${lang.toUpperCase()})`}
            </HeaderTitle>
            <Title>
              {values.screens &&
              findScreen(TypeScreenEnum.RECEIVER) &&
              findScreen(TypeScreenEnum.RECEIVER).title &&
              (findScreen(TypeScreenEnum.RECEIVER).title as ITranslatableObject).translations[lang]
                ? (findScreen(TypeScreenEnum.RECEIVER).title as ITranslatableObject).translations[lang]
                : `Title (${lang.toUpperCase()})`}
            </Title>
            <Description>
              {values.screens &&
              findScreen(TypeScreenEnum.RECEIVER) &&
              findScreen(TypeScreenEnum.RECEIVER).description &&
              (findScreen(TypeScreenEnum.RECEIVER).description as ITranslatableObject).translations[lang]
                ? (findScreen(TypeScreenEnum.RECEIVER).description as ITranslatableObject).translations[lang]
                : `Description (${lang.toUpperCase()})`}
            </Description>
          </Background>
        </MobileSchema>
      </div>
    </PreviewContainer>
  );
};

export default ReceiverScreenPreview;

const PreviewContainer = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Background = styled("div")`
  background: url("./images/referralCampaigns/referrals-receiver-screen.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled("span")`
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: ${props => props.theme.palette.darkGrey};
  position: absolute;
  top: 274px;
  width: 210px;
  left: 0;
  right: 0;
  margin: auto;
  word-break: break-all;
`;

const HeaderTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: white;
  position: absolute;
  top: 27px;
  left: 0;
  right: 0;
  margin: auto;
  width: 180px;
  word-break: break-word;
`;

const Description = styled("span")`
  font-family: Vodafone Rg;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: ${props => props.theme.palette.darkGrey};
  position: absolute;
  top: 304px;
  width: 210px;
  left: 0;
  right: 0;
  margin: auto;
  word-break: break-word;
`;

const Image = styled("div")<{ image: string | null }>`
  display: ${props => (props.image ? "inline" : "none")};
  background: ${props => `url("${props.image}"), #ffffff`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 52px;
  height: 52px;
  border-radius: 6px;
  position: absolute;
  top: 193px;
  margin: auto;
  left: 0;
  right: 0;
`;
