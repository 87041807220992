import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isFeedbackTabActive, isProblemReportsTabActive } from "../feedback-and-problems.utils";
import { IStoreInterface } from "../../../../configs/store.config";

/**
 * Custom Hook - useFeedbackAndProblemsCategories
 * [!important] This hook can be used within
 * feedback tab components, problem reports tab components, customer satisfaction survey tab
 * @param sectionId number
 * @param selectedLanguage string
 * @returns categories
 */
export function useFeedbackAndProblemsCategories(sectionId: number, selectedLanguage: string = "en") {
  const [t] = useTranslation();
  const { feedbackCategories, problemReportsCategories } = useSelector(
    (state: IStoreInterface) => state.feedbackAndProblemsReportReducer,
  );

  const categories = useMemo(() => {
    const categoriesList =
      isFeedbackTabActive(sectionId) && feedbackCategories && feedbackCategories.length >= 1
        ? feedbackCategories
        : !isFeedbackTabActive(sectionId) && problemReportsCategories && problemReportsCategories.length >= 1
        ? problemReportsCategories
        : [];
    const defaultOptions = [
      {
        key: "-1",
        label: t("pages.feedbackAndProblemsReport.otherOptions"),
      },
    ];

    if (isFeedbackTabActive(sectionId)) {
      if (!feedbackCategories || !feedbackCategories.length || feedbackCategories.length === 0) {
        return defaultOptions;
      }
    }
    if (isProblemReportsTabActive(sectionId)) {
      if (!problemReportsCategories || !problemReportsCategories.length || problemReportsCategories.length === 0) {
        return defaultOptions;
      }
    }
    const nonDefaultOptions = categoriesList.map(item => {
      return {
        key: item.id,
        label: item.name.translations[selectedLanguage],
      };
    });
    return [...nonDefaultOptions];
  }, [feedbackCategories, problemReportsCategories]);

  return categories;
}
