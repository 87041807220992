// TODO: further detail the models

export interface IPushNotifications {
  notifications: IPushNotification[];
  // hasPreviousVersion: boolean;
  //hasUnpublishedChanges: boolean;
  // status: PushNotificationsStatusEnum;
  // hasInvalids?: boolean;
  // submittedForApproval?: boolean;
}

// export interface PushNotifications {
//   [index: number]: PushNotification;
// }

export interface IPushNotification {
  //externalId: string;
  //hasPreviousVersion: boolean;
  //hasUnpublishedChanges: boolean;
  id: string;
  title: string;
  message: string;
  users: PushNotificationUsersEnum;
  appVersion: PushNotificationAppVersionsEnum;
  versions?: string[];
  linkType?: PushNotificationLinkTypeEnum;
  linkContent?: {
    type: string;
    flow?: string;
    eventId?: string;
    uri?: string;
  };
  date: string;
  status: PushNotificationStatusEnum;
  //invalid: boolean;
}

export interface IPushNotificationLinkContent {
  type: string;
  flow?: string;
  eventId?: string;
  uri?: string;
  name?: object;
}

export enum PushNotificationUsersEnum {
  ALL_USERS = "ALL_USERS",
  ACTIVE_USERS = "ACTIVE_USERS",
  NON_ACTIVE_USERS = "NON_ACTIVE_USERS",
  UPLOADED_USERS = "UPLOADED_USERS",
}

export enum PushNotificationAppVersionsEnum {
  ALL_VERSIONS = "ALL_VERSIONS",
  SUBSET_VERSIONS = "SUBSET_VERSIONS",
}

export enum PushNotificationLinkTypeEnum {
  NATIVE = "NATIVE",
  DYNAMIC_SCREEN = "DYNAMIC_SCREEN",
  DEEP_LINK = "DEEP_LINK",
  EXTERNAL_LINK = "EXTERNAL_LINK",
  MINI_APP = "MINI_APP",
  BUNDLE = "BUNDLE",
}

export enum PushNotificationStatusEnum {
  DRAFT = "DRAFT",
  SCHEDULED = "SCHEDULED",
  PROCESSING = "PROCESSING",
  SENT = "SENT",
  EXPIRED = "SENT",
  NO_DATA = "NO_DATA",
}

// export enum PushNotificationStatusEnum {
//   DELETE = "DELETE",
//   DISABLE = "DISABLE",
//   DISABLED = "DISABLED",
//   EDIT = "EDIT",
//   ENABLE = "ENABLE",
//   LIVE = "LIVE",
// }

export interface IPushNotificationCompletionProgress {
  pushNotification: IPushNotification;
  pushCompleted: number;
  pushFailed: number;
  pushInProgress: number;
  totalPush: number;
}
