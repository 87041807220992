import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ILanguage } from "../models/language.model";
import { ConfigContext } from "../../app.component";

const useLanguageManager = () => {
  const configContext = useContext(ConfigContext);
  const supportedLanguages: ILanguage[] = configContext.config ? configContext.config.supportedLanguages || [] : [];
  const [languages] = useState<ILanguage[]>(supportedLanguages);
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<ILanguage>({} as ILanguage);
  const [showLanguageSelector, setShowLanguageSelector] = useState(false);

  useEffect(() => {
    const findLanguage =
      languages.find(language => i18n.language.toUpperCase() === language.code.toUpperCase()) || ({} as ILanguage);

    setSelectedLanguage(findLanguage);
    setShowLanguageSelector(languages.length > 1);
  }, []);

  const changeLanguage = useCallback((langCode: string) => {
    const selected: ILanguage | undefined = languages.find(
      language => language.code.toUpperCase() === langCode.toUpperCase(),
    );

    if (selected) {
      i18n.changeLanguage(selected.code).then(() => {
        setSelectedLanguage(selected);
      });
    }
  }, []);

  return { languages, selectedLanguage, changeLanguage, showLanguageSelector };
};

export { useLanguageManager };
