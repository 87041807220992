import React from "react";
import styled from "styled-components";
import {
  IServiceManagerService,
  IServiceManagerServiceDetails,
  ServiceManagerStatusEnum,
} from "../../../shared/models/service-manager.model";
import { RatioContainer, IconContainer } from "../../../shared/shared.styled";
import {
  StatusChip,
  ThreeDotsIcon,
  FastActionButton,
  TrashIcon,
  useModal,
  ModalTypeEnum,
  useAlert,
} from "@wit/mpesa-ui-components";
import { useTranslation } from "react-i18next";
import StarRating from "react-svg-star-rating";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import ServiceManagerApi from "../service-manager.api";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { ServiceManagerActions } from "../service-manager.store";
import { useDispatch, useSelector } from "react-redux";
import SafaricomDeleteModal from "../../../shared/components/safaricom-delete-modal/safaricom-delete-modal.component";
import DragRulesDialog from "../../../shared/components/drag-rules.component";
import { ServiceType } from "../service-details/pages/details.interfaces";
import { UserType } from "../section-manager/section-manager.interface";
import LoadingComponent from "../../../shared/components/loading-component/LoadingComponent";
import { FEATURES } from "../../../shared/renderer.utils";
import useCheckFeatureAvailable from "../../../shared/hooks/use-check-available-feature";
import { useHistory, useParams } from "react-router-dom";
import EditMiniAppCategoryModal from "../modals/edit-mini-app-category.modal";
import { IStoreInterface } from "../../../configs/store.config";
import ServiceManagerServiceDetailsApi from "../service-details/service-manager-service-details.api";
import { ICategory } from "../pages/categories/categories.model";

interface IServiceListItemProps {
  item: IServiceManagerService;
  groupName?: string;
  dragprops?: any;
  promoteCall: (item: IServiceManagerService, setIsLoading: (value: boolean) => void) => void;
  unpromoteCall: (item: IServiceManagerService, setIsLoading: (value: boolean) => void) => void;
  serviceType?: ServiceType;
  promotedOption?: boolean;
  userType?: UserType;
  isMiniAppsWithinCategory?: boolean;
  setShouldUpdateLocalCategories?(): void;
}

/** ServiceListItem Component */
const ServiceListItem = ({
  item,
  dragprops,
  groupName,
  serviceType,
  promoteCall,
  unpromoteCall,
  promotedOption = true,
  userType,
  isMiniAppsWithinCategory,
  setShouldUpdateLocalCategories,
}: IServiceListItemProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [isHovering, setIsHovering] = React.useState(false);
  const [showRules, setShowRules] = React.useState(false);
  const [showPromote, setShowPromote] = React.useState(false);
  const [showUnpromote, setShowUnpromote] = React.useState(false);
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const serviceUserTypeEnabled = useCheckFeatureAvailable(FEATURES.SERVICE_USER_TYPE);
  const params = useParams() as { categoryId: string };

  const history = useHistory();
  const { categories } = useSelector((state: IStoreInterface) => state.serviceManagerReducer);
  const [selectedCategory, setSelectedCategory] = React.useState<ICategory>();

  const [showDeleteModal, hideDeleteModal] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <SafaricomDeleteModal
      secondaryAction={() => hideDeleteModal()}
      primaryAction={isMiniAppsWithinCategory ? () => deleteMiniAppFromCategory() : () => deleteService()}
      title={
        isMiniAppsWithinCategory
          ? t("pages.serviceManager.serviceListItem.removeModal.title")
          : t("pages.serviceManager.serviceListItem.deleteModal.title")
      }
      description={
        isMiniAppsWithinCategory
          ? t("pages.serviceManager.serviceListItem.removeModal.description")
          : t("pages.serviceManager.serviceListItem.deleteModal.description")
      }
      primaryBtnLabel={
        isMiniAppsWithinCategory
          ? t("pages.serviceManager.serviceListItem.removeModal.btnLabel")
          : t("pages.serviceManager.serviceListItem.deleteModal.btnLabel")
      }
      isLoading={isDeleting}
    />,
  );

  if (item.status === ServiceManagerStatusEnum.UNDEPLOYED) {
    item = { ...item, status: ServiceManagerStatusEnum.DISABLED };
  }

  const [showEditMiniAppCategoryModal, hidAddModal, setAddProps] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <EditMiniAppCategoryModal
      primaryAction={isMiniAppsWithinCategory ? () => deleteMiniAppFromCategory() : () => deleteService()}
      secondaryAction={() => handleCancelModal()}
      categories={categories}
      categoryID={params.categoryId}
      miniAppID={item.id}
    />,
  );
  React.useEffect(() => {
    setSelectedCategory(categories.find(item => item.id === params.categoryId));
    setAddProps({ modalStyles: { minWidth: 550, overflow: "initial" } });
  }, [setAddProps]);
  const handleCancelModal = () => {
    hidAddModal();
  };
  /**
   * Delete a mini-app from the specified category
   * @function deleteMiniAppFromCategory
   */
  const deleteMiniAppFromCategory = () => {
    hideDeleteModal();
    ServiceManagerApi.methods.deleteMiniAppFromCategory(params.categoryId, item.id).then(
      () => {
        setAlertProps({
          title: t("pages.serviceManager.categories.miniApps.successRemoveMiniApp")
            .replace("{miniAppName}", item.name)
            .replace("{categoryName}", selectedCategory?.categoryName!),
          type: AlertTypeEnum.SUCCESS,
        });
        setShouldUpdateLocalCategories && setShouldUpdateLocalCategories();
        showAlert();
      },
      () => {
        setAlertProps({
          title: t("pages.serviceManager.categories.miniApps.errorDeleteMiniApp"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * handle delete mini-app button click
   * @function handleShowDeleteModal
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} e - click event object
   */
  const handleShowDeleteModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (!isMiniAppsWithinCategory) {
      showDeleteModal();
    } else {
      let miniAppDetails: IServiceManagerServiceDetails;
      ServiceManagerServiceDetailsApi.methods.getServiceManagerServiceDetails(item.id).then(
        res => {
          miniAppDetails = res.data;
          if (miniAppDetails?.categories && miniAppDetails.categories.length < 2) {
            showEditMiniAppCategoryModal();
          } else {
            showDeleteModal();
          }
        },
        error => console.log(error),
      );
    }
  };

  /** function to delete service */
  const deleteService = () => {
    setIsDeleting(true);
    let promotedPosition: number;

    item.groups.forEach(group => {
      if (
        group.name === groupName &&
        group.serviceType === serviceType &&
        (!serviceUserTypeEnabled || group.userType === userType)
      ) {
        if (group.promotedOrder && group.promotedOrder > 0) {
          promotedPosition = group.promotedOrder;
          deleteRequest(
            group.name,
            group.serviceType,
            serviceUserTypeEnabled ? group.userType : undefined,
            promotedPosition,
          );
        } else {
          deleteRequest(group.name, group.serviceType, serviceUserTypeEnabled ? group.userType : undefined);
        }
      }
    });
  };

  /** function to deleteRequest */
  const deleteRequest = (
    groupName: string,
    serviceType: ServiceType,
    userType?: UserType,
    promotedPosition?: number,
  ) => {
    ServiceManagerApi.methods
      .deleteGroupItem(item.id, groupName, userType, serviceType)
      .then(
        () => {
          setAlertProps({
            title: t("pages.serviceManager.serviceListItem.success.deleteGroup"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          ServiceManagerActions.creators.fetchingServicesAction();
          ServiceManagerApi.methods.getServices().then(res => {
            dispatch(ServiceManagerActions.creators.fetchServicesSuccessAction(res.data));
          });
        },
        () => {
          setAlertProps({
            title: t("pages.serviceManager.serviceListItem.errors.deleteGroup"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        hideDeleteModal();
        setIsDeleting(false);
      });
  };

  React.useEffect(() => {
    setShowPromote(false);
    setShowUnpromote(false);
  }, [isLoading]);

  /** function to show promote Dialog */
  const showPromoteDialog = () => {
    return (
      <DialogContainer>
        <TextContainer>
          <Text>{t("pages.serviceManager.serviceListItem.promote")}</Text>
        </TextContainer>
      </DialogContainer>
    );
  };

  /** function to getCorrectStart */
  const getCorrectStar = () => {
    let rating = 0;
    item.groups.forEach(group => {
      if (
        group.name === groupName &&
        group.serviceType === serviceType &&
        (!serviceUserTypeEnabled || group.userType === userType)
      ) {
        if (group.promotedOrder && group.promotedOrder > 0) {
          rating = 1;
        } else {
          rating = 0;
        }
      }
    });

    if (rating === 0) {
      return (
        <StarContainer
          onClick={() => promoteCall(item, setIsLoading)}
          onMouseEnter={() => setShowPromote(true)}
          onMouseLeave={() => setShowPromote(false)}
        >
          <StarRating hoverColor={"#2fc56d"} activeColor={"#dddddd"} count={1} innerRadius={20} initialRating={0} />
          {showPromote ? showPromoteDialog() : null}
        </StarContainer>
      );
    } else {
      return (
        <StarContainer
          onMouseEnter={() => setShowUnpromote(true)}
          onMouseLeave={() => setShowUnpromote(false)}
          onClick={() => unpromoteCall(item, setIsLoading)}
          style={{ position: "relative" }}
        >
          <StarRating hoverColor={"#2fc56d"} activeColor={"#2fc56d"} count={1} innerRadius={20} initialRating={1} />
          {showUnpromote ? (
            <DragRulesDialog
              text={t("pages.serviceManager.serviceListItem.unpromote")}
              height={"35px"}
              width={"80px"}
              top={"-85px"}
              left={"-27px"}
            />
          ) : null}
        </StarContainer>
      );
    }
  };

  return (
    <CustomContainer onClick={() => isMiniAppsWithinCategory && history.push(`/service-manager/${item.id}/details`)}>
      <ServiceListItemContainer
        style={{ position: "relative", border: dragprops.isDragging ? "1px solid #2fc56d" : "0px" }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <Dots onMouseEnter={() => setShowRules(true)} onMouseLeave={() => setShowRules(false)}>
          <ThreeDotsIcon />
          {showRules ? <DragRulesDialog text={t("pages.serviceManager.serviceListItem.reorder")} /> : null}
        </Dots>
        <ServiceImageContainer ratio={0.5 / 12}>
          <ServiceItemImage backgroundImage={item.icon}></ServiceItemImage>
        </ServiceImageContainer>
        <ServiceItemTitle ratio={4 / 12}>{item.name}</ServiceItemTitle>

        <ServiceListNormalText ratio={3 / 12}>{item.creationDate}</ServiceListNormalText>

        <RatioContainer className="toggable-container" ratio={2 / 12}>
          {item.status !== undefined ? (
            <StatusChip type={item.status as any}>{t(`commons.serviceBuilder.enums.status.${item.status}`)}</StatusChip>
          ) : null}
        </RatioContainer>
        <RatioContainer className="toggable-container" ratio={3 / 12}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {isLoading ? (
              <LoadingContainer>
                <LoadingComponent></LoadingComponent>
              </LoadingContainer>
            ) : (
              <>
                {promotedOption && !isMiniAppsWithinCategory ? getCorrectStar() : null}
                {isHovering ? (
                  <>
                    <div style={{ marginLeft: "16px" }}>
                      <FastActionButton
                        iconComponent={
                          <IconContainer size={16} color={styleTheme.palette.vodafoneRed}>
                            <TrashIcon />
                          </IconContainer>
                        }
                        onClick={e => handleShowDeleteModal(e)}
                        label={
                          isMiniAppsWithinCategory
                            ? t("pages.serviceManager.serviceListItem.remove")
                            : t("pages.serviceManager.serviceListItem.delete")
                        }
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </RatioContainer>
      </ServiceListItemContainer>
    </CustomContainer>
  );
};
export default ServiceListItem;

const CustomContainer = styled("div")`
  height: 75px;
  display: flex;
  align-items: center;
`;

const ServiceItemImage = styled("div")<{ backgroundImage: string }>`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: auto;
  margin-right: 20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: solid 1px #ebebeb;
  margin-left: 0;
  background-image: url("data:image/jpeg;base64,${props => props.backgroundImage}");
`;

const StarContainer = styled("button")`
  cursor: pointer !important;
  pointer-events: inherit;
  position: relative;
  border: none;
  background-color: transparent;
`;

const ServiceListItemContainer = styled("li")`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 75px;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  cursor: pointer;
  width: 100%;
  padding: 0 24px 0 15px;

  :hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);

    .toggable-container {
      display: flex;
    }
  }
`;

const Dots = styled("div")`
  display: flex;
  width: 30px;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const ServiceListNormalText = styled(RatioContainer)`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
  padding-right: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ServiceImageContainer = styled(RatioContainer)`
  padding: 0 10px 0 10px;
`;

const ServiceItemTitle = styled(RatioContainer)`
  font-family: Vodafone Rg;
  font-size: 22px;
  font-weight: bold;
  color: ${props => props.theme.palette.black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
`;

const TextContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DialogContainer = styled("div")`
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  background-color: #212121;
  position: absolute;
  width: 80px;
  height: 35px;
  z-index: 9999;
  margin-top: -85px;
  margin-left: -27px;
  &:after {
    top: 100%;
    left: 41%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-right-color: #212121;
    border-width: 10px;
    transform: rotate(-90deg);
  }
`;

const Text = styled("p")`
  font-family: Vodafone Rg;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: white;
  text-align: center;
`;
const LoadingContainer = styled("div")`
  > div {
    width: 32px !important;
    height: 32px !important;
  }
`;
