import React from "react";

function EditIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1102 -271)">
          <path fill="#FFF" d="M0 0H1360V768H0z"></path>
          <g transform="translate(118 259)">
            <path d="M0 0H1160V44H0z"></path>
          </g>
          <g
            stroke="#EB9700"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.833"
            transform="translate(1102 271)"
          >
            <path d="M15.37 7.41l-9.115 9.156L2.5 17.5l.94-3.75 9.157-9.148 2.777 2.805 1.527-1.524c.771-.77.812-2.037.04-2.805a2.007 2.007 0 00-2.818 0l-1.53 1.526"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default EditIcon;
