import { ModalTypeEnum, PageTitle, TabChild, Tabs, useAlert, useModal } from "@wit/mpesa-ui-components";
import { Breadcrumb } from "@wit/mpesa-ui-components/lib/types";

import { TFunction } from "i18next";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { RoutesEnum } from "../../../routes/routes.constants";
import { PageContainer } from "../../../shared/shared.styled";
import DocumentsApps from "./documents-apps.page";
import { ThemeContext } from "styled-components";
import DocumentsApi from "./documents.api";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { IDocumentsList, IDocumentType } from "../../../shared/models/app.model";
import { DocumentsActions } from "./documents.store";
import { useDispatch } from "react-redux";

/**
 *
 * @param t
 * Get breadcrumbs
 */
const getBreadcrumbs = (t: TFunction): Breadcrumb[] => {
  return [
    {
      label: t("pages.documents.configuration"),
      url: RoutesEnum.CONFIGS,
    },
    {
      label: t("pages.documents.title"),
      url: "",
    },
  ];
};

/**
 * Documents page
 */
const DocumentsPage = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const [tab, setTab] = React.useState(Number(query.get("tabIdx")) || 0);
  const [availabeTabs, setAvailableTabs] = React.useState<IDocumentType[]>();
  const [showAlert, , setAlertProps] = useAlert();
  const themeContext = useContext(ThemeContext);
  const [showDeleteModal, hideDeleteModal, setDeleteProps] = useModal(ModalTypeEnum.ConfirmationModal);

  /**
   * Get list of available documents
   */
  React.useEffect(() => {
    getDocuments();
  }, []);

  const getDocuments = () => {
    DocumentsApi.methods.getDocumentList().then(
      res => {
        setAvailableTabs(res.data.documents);
        dispatch(DocumentsActions.creators.fetchDocumentsList(res.data.documents));
      },
      () => {
        setAlertProps({
          title: t("pages.documents.document.getErrorContent"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * Document delete function
   * @param {id} string
   * @param {documentName} string
   */
  const onDocumentDelete = (type: string, documentName: string): void => {
    setDeleteProps({
      secondaryAction: () => {
        hideDeleteModal();
      },
      primaryAction: () => {
        DocumentsApi.methods
          .deleteDocument(type)
          .then(
            res => {
              setAvailableTabs(res.data.documents);
              dispatch(DocumentsActions.creators.fetchDocumentsList(res.data.documents));
              setAlertProps({
                title: t("pages.documents.delete.documentDeleted"),
                type: AlertTypeEnum.SUCCESS,
              });
            },
            () => {
              setAlertProps({
                title: t("pages.documents.delete.deleteDocumentError"),
                type: AlertTypeEnum.ERROR,
              });
            },
          )
          .finally(() => {
            hideDeleteModal();
            showAlert();
          });
      },
      title: t("pages.documents.delete.deleteTitleModal"),
      description: t("pages.documents.delete.deleteDescriptionModal").replace("{document}", documentName),
      primaryBtnLabel: t("pages.documents.delete.deleteLabelBtn"),
      primaryBtnId: "delete-document-confirm",
      secondaryBtnId: "cancel-button",
      primaryBtnMaxWidth: 160,
    });

    showDeleteModal();
  };

  return (
    <PageContainer>
      <PageTitle
        breadcrumbs={getBreadcrumbs(t)}
        title={t("pages.documents.title")}
        navigateFunction={history.push}
        separatorStyle={{ display: "none" }}
        goBackFn={() => history.push(RoutesEnum.CONFIGS)}
        titleContainerStyle={{ marginTop: "13px", marginBottom: "34px" }}
        iconContainerStyle={{ stroke: themeContext.palette.vodafoneRed }}
      />

      {tab !== undefined && availabeTabs ? (
        <Tabs controlledSelectedTab={tab} controlledSetSelectedTab={setTab} isResponsive={true}>
          {availabeTabs.map((availableTab: IDocumentType, index: number) => (
            <TabChild key={availableTab.type} label={availableTab.title}>
              <DocumentsApps
                type={availableTab.type}
                title={availableTab.title}
                isDeletable={availableTab.isDeletable || false}
                onDelete={onDocumentDelete}
                tabId={`${index}`}
              />
            </TabChild>
          ))}
        </Tabs>
      ) : null}
    </PageContainer>
  );
};

export default DocumentsPage;
