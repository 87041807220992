import { produce } from "immer";
import { IPayloadAction } from "../../../shared/shared.interfaces";
import { IReport } from "./request-money.interfaces";

export const ReportsActions = {
  types: {
    SET_REPORT_DETAIL: "SET_REPORT_DETAIL",
  },
  creators: {
    setReportDetailAction: (reportDetail: IReport) => ({
      type: ReportsActions.types.SET_REPORT_DETAIL,
      payload: {
        reportDetail,
      },
    }),
  },
};

export interface IReportsReducerInterface {
  reportDetails: IReport | null;
}

const initialState: IReportsReducerInterface = {
  reportDetails: null,
};

export const reportsReducer = produce((draft: IReportsReducerInterface, action: IPayloadAction<any>) => {
  switch (action.type) {
    case ReportsActions.types.SET_REPORT_DETAIL:
      draft.reportDetails = action.payload.reportDetail as IReport;
      return;
    default:
      return draft;
  }
}, initialState);
