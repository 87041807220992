import { ThreeDotsIcon, FastActionButton, EditIcon } from "@wit/mpesa-ui-components";
import i18next from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Column, FlexContainer, Row, IconContainer } from "../../../../shared/shared.styled";
import { IShortcut, ShortcutsStatusEnum } from "../shortcuts.model";
import ReactTooltip from "react-tooltip";
import { TableButtonsContainer } from "../../../../shared/shared.utils";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import ShortcutStatusChip from "./shortcut-status.component";
import ShortcutDrawer from "./shortcut-drawer";
import { ILanguage } from "../../../../shared/models/language.model";
import { IIconCategory } from "../../categories/general-categories.model";

interface IShortcutItemInterface {
  shortcut: IShortcut;
  getTranslation: (obj: any) => string;
  hasFastActions?: boolean;
  dragging?: boolean;
  getShortcuts: () => void;
  availableLanguages: ILanguage[] | undefined;
  icons: IIconCategory[];
}

/**
 * Shortcut item
 * @param param0
 */
const ShortcutItem = ({
  shortcut,
  getTranslation,
  getShortcuts,
  availableLanguages,
  icons,
  hasFastActions = true,
  dragging = false,
}: IShortcutItemInterface) => {
  const [t] = useTranslation();
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [selectedShortcut, setSelectedShortcut] = useState<IShortcut>(shortcut);

  /**
   * Edit shortcut - open drawer
   */
  const editShortcut = () => {
    setShowDrawer(true);
  };

  /**
   * Get used icon by iconId
   * @param item
   * @returns
   */
  const getIconById = (iconId: string | number) => {
    return iconId && icons && icons.find(icon => icon.id.toString() === iconId?.toString()) ? (
      <img src={icons.find(icon => icon.id.toString() === iconId?.toString())?.base64} alt={"category-icon"} />
    ) : null;
  };

  return (
    <ShortcutItemContainer id={`shortcut-${getTranslation(shortcut.name)}`}>
      <ShortcutDrawer
        setIsVisible={setShowDrawer}
        isVisible={showDrawer}
        selectedShortcut={selectedShortcut}
        getShortcuts={getShortcuts}
        availableLanguages={availableLanguages}
        icons={icons}
      />

      <Row style={{ width: "100%" }}>
        <FlexContainer ratio={1 / 12} style={{ marginRight: 16, height: "100%", display: "flex" }}>
          <Row style={{ width: "100%" }}>
            {hasFastActions ? (
              <>
                <DragContainer className={"handle"} data-tip data-for={`drag-message-${shortcut.id}`}>
                  <ThreeDotsIcon />
                </DragContainer>
                <div style={{ display: dragging ? "none" : "block" }}>
                  <ReactTooltip
                    event="mouseover"
                    eventOff="mouseleave mousedown"
                    place="bottom"
                    type="dark"
                    effect="solid"
                    id={`drag-message-${shortcut.id}`}
                    className={"tooltip-container"}
                  >
                    <TooltipText>{t("pages.shortcuts.dragAndDropMessage")}</TooltipText>
                  </ReactTooltip>
                </div>
              </>
            ) : null}
            <ShortcutIconContainer color="transparent">{getIconById(shortcut?.iconId)}</ShortcutIconContainer>
          </Row>
        </FlexContainer>
        <FlexContainer ratio={9 / 12} style={{ marginRight: 16, height: "100%", display: "flex" }}>
          <Row style={{ width: "100%" }}>
            <Column>
              <Title id={`shortcut-title-${shortcut.id}`}>{getTranslation(shortcut.name)}</Title>
            </Column>
          </Row>
        </FlexContainer>
        <FlexContainer ratio={1 / 12} style={{ marginRight: 16 }}>
          <Column>
            <ShortcutStatusChip
              type={shortcut.status as any}
              id={`${shortcut.status}-${getTranslation(shortcut.name)}`}
              style={{ width: "fit-content" }}
            >
              {shortcut.submittedForApproval &&
              (shortcut.status === ShortcutsStatusEnum.DISABLE ||
                shortcut.status === ShortcutsStatusEnum.ENABLE ||
                shortcut.status === ShortcutsStatusEnum.EDIT)
                ? i18next.t(`commons.shortcuts.shortcutsStatusEnum.PENDING`)
                : i18next.t(`commons.shortcuts.shortcutsStatusEnum.${shortcut.status}`)}
            </ShortcutStatusChip>
          </Column>
        </FlexContainer>
        <FlexContainer ratio={1 / 12} style={{ marginRight: 16, display: "flex", justifyContent: "flex-end" }}>
          <Column style={{ alignItems: "flex-end" }}>
            {hasFastActions ? (
              <Row className="edit-remove-wrapper">
                <TableButtonsContainer style={{ position: "relative", marginTop: 0 }}>
                  {shortcut.status !== "DELETE" && (
                    <>
                      <FastActionButton
                        iconComponent={
                          <IconContainer
                            size={16}
                            color={styleTheme.palette.turquoiseBlue}
                            id={`edit-shortcut-${getTranslation(shortcut.name)}`}
                          >
                            <EditIcon />
                          </IconContainer>
                        }
                        onClick={() => {
                          editShortcut();
                        }}
                        label={t("pages.shortcuts.listButtons.edit")}
                      />
                    </>
                  )}
                </TableButtonsContainer>
              </Row>
            ) : null}
          </Column>
        </FlexContainer>
      </Row>
    </ShortcutItemContainer>
  );
};

const ShortcutItemContainer = styled("div")`
  display: flex;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  margin-top: 24px;
  border-radius: 8px;
  padding: 18px 0px 18px 0px;
  height: 75px;
  width: 100%;
  transition: all 0.5s;

  .edit-remove-wrapper {
    display: none;
  }

  &.sortable-chosen.sortable-ghost {
    img,
    span,
    button,
    div {
      display: none;
    }
  }

  .edit-remove-wrapper {
    display: flex;
  }
`;

const SpanTitleDescription = styled("span")`
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: "Vodafone Rg";
`;

const Title = styled(SpanTitleDescription)`
  font-size: 22px;
  font-weight: bold;
  @media (max-width: 1024px) {
    font-size: 20px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const DragContainer = styled("div")`
  display: flex;
  width: auto;
  padding-left: 16px;
  padding-right: 16px;
  stroke: ${props => props.theme.palette.greyDarkest};
  @media (max-width: 900px) {
    padding-right: 4px;
    padding-left: 4px;
  }

  svg {
    width: 16px;
    height: 16px;
    stroke: ${props => props.theme.palette.greyDarkest};
  }
`;

const TooltipText = styled("div")`
  font-size: 13px;
  font-family: Vodafone Rg;
  color: white;
  display: flex;
  width: 85px;
  text-align: center;
`;

const ShortcutIconContainer = styled("div")<{ color: string }>`
  height: 55px;
  background-color: ${props => (props.color ? props.color : props.theme.palette.midGrey)};
  border-radius: 50%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 32px;
    height: 32px;
  }
`;

export default ShortcutItem;
