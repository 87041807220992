import { Checkbox, PrimaryButton, SecondaryPageTitle, TextInput, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik } from "formik";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { object, string } from "yup";
import { IStoreInterface } from "../../../../configs/store.config";
import { RoutesEnum } from "../../../../routes/routes.constants";
import { IDocumentType } from "../../../../shared/models/app.model";
import { PageContent } from "../../../../shared/responsive-ui.styled";
import { FormLabel, LoadingText, PageContainer } from "../../../../shared/shared.styled";
import { stringToCamelCase } from "../../../../shared/shared.utils";
import DocumentsApi from "../documents.api";

/**
 * Get validation schema of document form
 */
export const getValidationSchema = (type: string, documents: IDocumentType[]) => {
  return object().shape({
    type: string()
      .required(i18next.t("pages.documents.add.errors.mandatory"))
      .test({
        name: "is-type-existant",
        test: value => {
          return !documents.some(doc => doc.type === value);
        },
        message: `${i18next.t("pages.documents.add.errors.typeUsed")}`,
      }),
    title: string().required(i18next.t("pages.documents.add.errors.mandatory")),
  });
};

/**
 * Get validation schema of edit document form
 */
export const getEditValidationSchema = (type: string, documents: IDocumentType[]) => {
  return object().shape({
    title: string().required(i18next.t("pages.documents.add.errors.mandatory")),
  });
};

/**
 * Add Document Page
 */
const AddDocumentPage = () => {
  const [t] = useTranslation();
  const { type, tabId } = useParams<any>();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showAlert, , setAlertProps] = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    type: "",
    title: "",
    sendToApps: false,
  });
  const { documents } = useSelector((state: IStoreInterface) => ({
    documents: state.documentsReducer.documents,
  }));

  useEffect(() => {
    if (type) {
      DocumentsApi.methods
        .getDocumentList(type)
        .then(
          res => {
            const document = res.data.documents[0];
            setInitialValues({
              type: document.type,
              title: document.title,
              sendToApps: document.sendToApps,
            });
          },
          () => {
            setAlertProps({
              title: t("pages.documents.add.errors.get"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          },
        )
        .then(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  /**
   * Submit method
   * @param values
   */
  const submit = async (values: any) => {
    setIsLoading(true);
    const requestData: any = {
      type: values.type,
      title: values.title,
      sendToApps: values.sendToApps,
    };
    if (!type) {
      DocumentsApi.methods.addDocument(requestData).then(
        () => {
          setAlertProps({
            title: t("pages.documents.add.success"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          setIsLoading(false);
          history.push(`${RoutesEnum.DOCUMENTS}?tabIdx=0`);
        },
        () => {
          setAlertProps({
            title: t("pages.documents.add.errors.create"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
          setIsLoading(false);
        },
      );
    } else {
      DocumentsApi.methods.editDocument(requestData).then(
        () => {
          setAlertProps({
            title: t("pages.documents.edit.success"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          setIsLoading(false);
          history.push(`${RoutesEnum.DOCUMENTS}?tabIdx=${tabId}`);
        },
        () => {
          setAlertProps({
            title: t("pages.documents.edit.error"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
          setIsLoading(false);
        },
      );
    }
  };

  /**
   * Check form
   * @param values
   */
  const checkFormSubmitButton = (values: any) => {
    const valuesArr = Object.keys(values);

    if (JSON.stringify(initialValues) === JSON.stringify(values)) {
      return true;
    }

    /*
     * Check each field to make sure all fields are filled with any value to enable the submit button.
     */
    for (let i = 0; i < valuesArr.length; i++) {
      if (!(typeof values[valuesArr[i]] === "boolean") && values[valuesArr[i]] && !values[valuesArr[i]].trim()) {
        return true;
      }
    }

    return false;
  };

  const onIpuntChange = (values: any, setFieldValue: any) => {
    if (!type) {
      setFieldValue("type", stringToCamelCase(values.title));
    }
  };

  return (
    <PageContainer>
      <SecondaryPageTitle
        displayInitialsCircle={false}
        title={type ? t("pages.documents.add.titleEdit") : t("pages.documents.add.title")}
        goBackFn={() => history.push(`${RoutesEnum.DOCUMENTS}?tabIdx=${tabId}`)}
      />
      {loading && <LoadingText>{t("commons.loadingResults")}</LoadingText>}
      {!loading && (
        <FormContainer>
          <Formik
            onSubmit={submit}
            initialValues={initialValues}
            enableReinitialize={true}
            validateOnChange={false}
            validationSchema={!type ? getValidationSchema(type, documents) : getEditValidationSchema(type, documents)}
            render={({ handleChange, values, setFieldValue, handleSubmit, errors }) => {
              return (
                <Form className="main-form" onSubmit={handleSubmit}>
                  <FormSection>
                    <FormSectionContainer>
                      <FormSectionRow key={"title"}>
                        <FormSectionTitle>{t("pages.documents.add.details")}</FormSectionTitle>
                      </FormSectionRow>
                      <FormSectionRow>
                        <SectionColumn>
                          <FormLabel required={true}>{t("pages.documents.add.documentName")}</FormLabel>
                        </SectionColumn>
                        <SectionColumn>
                          <TextInput
                            value={values.title || ""}
                            name="title"
                            onChange={handleChange}
                            onKeyUp={e => onIpuntChange(values, setFieldValue)}
                            error={errors.title}
                            placeholder={`${t("pages.documents.add.documentName")}`}
                            maxLength={100}
                          />
                        </SectionColumn>
                      </FormSectionRow>
                      <FormSectionRow>
                        <SectionColumn>
                          <FormLabel required={!type}>{t("pages.documents.add.documentConfigName")}</FormLabel>
                        </SectionColumn>
                        <SectionColumn>
                          <TextInput
                            value={values.type || ""}
                            name="type"
                            onChange={handleChange}
                            error={errors.type}
                            placeholder={`${t("pages.documents.add.documentConfigName")}`}
                            disabled={!!type}
                            maxLength={100}
                          />
                        </SectionColumn>
                      </FormSectionRow>
                      <FormSectionRow>
                        <div style={{ display: "flex", gap: "8px" }}>
                          <Checkbox name={"sendToApps"} setFieldValue={setFieldValue} value={values.sendToApps} />
                          <Label>{t("pages.documents.add.sendToApps")}</Label>
                        </div>
                      </FormSectionRow>
                      <ButtonsContainer className="buttons-container">
                        {!type && (
                          <div>
                            <PrimaryButton
                              disabled={checkFormSubmitButton(values) || isLoading}
                              id="create-document-card-button"
                              redTheme={true}
                              type="button"
                              onClick={() => handleSubmit()}
                              titleLabel={t("pages.documents.add.createButton")}
                            />
                          </div>
                        )}
                        {type && (
                          <>
                            <div>
                              <PrimaryButton
                                id="cancel-button"
                                redTheme={false}
                                type="button"
                                onClick={() => history.push(`${RoutesEnum.DOCUMENTS}?tabIdx=${tabId}`)}
                                titleLabel={t("pages.documents.add.cancelButton")}
                              />
                            </div>
                            <div>
                              <PrimaryButton
                                disabled={checkFormSubmitButton(values)}
                                id="save-changes-button"
                                redTheme={true}
                                type="button"
                                onClick={() => handleSubmit()}
                                titleLabel={t("pages.documents.add.saveButton")}
                              />
                            </div>
                          </>
                        )}
                      </ButtonsContainer>
                    </FormSectionContainer>
                  </FormSection>
                </Form>
              );
            }}
          />
        </FormContainer>
      )}
    </PageContainer>
  );
};

export default AddDocumentPage;

const FormContainer = styled(PageContent)``;

const Form = styled.form`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const FormSection = styled.div`
  width: 60%;
`;

const Label = styled("div")`
  font-family: Vodafone Rg;
  margin-left: 8px;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 19px;
  letter-spacing: normal;
  color: ${props => props.theme.palette.midGrey};
`;

const RowMinHeight = {
  rowMinHeight: 55,
};

export const FormSectionRow = styled("div")<{ hide?: boolean }>`
  display: ${props => (props.hide ? "none" : "flex")};
  justify-content: space-between;
  align-items: center;
  min-height: ${RowMinHeight.rowMinHeight}px;
  padding-top: 8px;
  padding-bottom: 8px;
  flex: 1;
`;

export const FormSectionContainer = styled("div")`
  margin-bottom: 40px;
  ${FormSectionRow}:not(:first-of-type) {
    border-bottom: 1px solid ${props => props.theme.palette.greyLightest};
  }
`;

export const FormSectionTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.black};
`;

export const SectionColumn = styled("div")`
  flex: 1;
  max-width: 50%;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 80px;

  > div {
    width: "fit-content";

    :first-of-type {
      margin-right: 12px;
    }
  }
`;

export const FormLabelSecondary = styled(FormLabel)`
  font-size: 14px;
  font-weight: inherit;
  display: inherit;
`;
