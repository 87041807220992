import { PrimaryButton } from "@wit/mpesa-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { IStoreInterface } from "../../../../configs/store.config";
import useVersionsDiff from "../../../../shared/hooks/use-version-diff/use-version-diff.hook";
import { Column, Row } from "../../../../shared/shared.styled";
import RateCardsVersionItem from "./version-item.component";

interface IPublishBanksModalProps {
  onCancelClick: () => void;
  submit: () => void;
  isLoading: boolean;
}

const PublishRateCardsModal = ({ onCancelClick, isLoading, submit }: IPublishBanksModalProps) => {
  const [t] = useTranslation();

  const { rateCards, lastVersion } = useSelector((state: IStoreInterface) => state.rateCardsReducer);
  const { prev, current } = useVersionsDiff(lastVersion!.chargeProfiles, rateCards!.chargeProfiles);

  return (
    <ModalContainer>
      <Row style={{ padding: 24 }}>
        <Column>
          <ModalTitle>{t("pages.rateCards.publishModal.publish")}</ModalTitle>
          <ModalSubTitle>{t("pages.rateCards.publishModal.wantPublish")}</ModalSubTitle>
        </Column>
      </Row>
      <Content>
        <Row>
          <ItemColumn>{t("pages.rateCards.publishModal.previousVersion")}</ItemColumn>
          <ItemColumn>{t("pages.rateCards.publishModal.editedVersion")}</ItemColumn>
        </Row>
        <ItemsContainer>
          {prev &&
            current &&
            current.map((edited, index) => {
              return (
                <Item key={edited.id}>
                  {prev[index] ? <RateCardsVersionItem item={prev[index]} /> : <ItemColumn />}
                  <RateCardsVersionItem item={edited} />
                </Item>
              );
            })}
        </ItemsContainer>
      </Content>
      <ButtonsContainer style={{ padding: 24 }}>
        <div>
          <PrimaryButton
            type="button"
            id="cancel-button"
            disabled={isLoading}
            onClick={onCancelClick}
            titleLabel={t("pages.rateCards.publishModal.cancelBtnLabel")}
          />
        </div>
        <div>
          <PrimaryButton
            id="confirm-button"
            redTheme={true}
            type="submit"
            disabled={isLoading}
            onClick={() => submit()}
            titleLabel={t("pages.rateCards.publishModal.saveChangesBtnLabel")}
          />
        </div>
      </ButtonsContainer>
    </ModalContainer>
  );
};

export default PublishRateCardsModal;

const ModalContainer = styled("div")`
  display: flex;
  flex-direction: column;
  font-family: Vodafone Rg;
`;

const ModalTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 21px;
`;

const ModalSubTitle = styled(ModalTitle)`
  font-size: 18px;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 50px;

  > div {
    width: "fit-content";

    :first-of-type {
      margin-right: 12px;
    }
  }
`;

const ItemsContainer = styled.div`
  max-height: 220px;
  overflow: scroll;
  width: 100%;
  overflow-x: hidden;
`;

const Item = styled(Row)`
  display: flex;
  align-items: flex-start;
`;

const ItemColumn = styled(Column)<{ disabled?: boolean }>`
  flex: 1;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  padding: 16px 0px 16px 24px;
  &:first-child {
    padding-right: 74px;
  }

  &:last-child {
    padding-left: 30px;
    padding-right: 44px;
  }
`;

const Content = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${props => props.theme.palette.darkGrey};

  background: ${props => `linear-gradient(90deg, ${props.theme.palette.lightGrey} 50%, transparent 50%)`};
`;
