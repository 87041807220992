import { EditIcon, SmallButton, StatusChip } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";

import i18next from "i18next";
import moment from "moment";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { RoutesEnum } from "../../../../../routes/routes.constants";
import { WrappedRow } from "../../../../../shared/responsive-ui.styled";
import { Column, FlexContainer } from "../../../../../shared/shared.styled";
import { IBundleType } from "../../bundles.model";
import SubBundleStatusChip from "./sub-bundle-status.component";

interface IBundleDetailsComponentInterface {
  bundle: IBundleType;
}

/**
 * Bundle details
 * @param param0
 */
const BundleDetailsComponent = ({ bundle }: IBundleDetailsComponentInterface) => {
  const [t] = useTranslation();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  return (
    <WrappedRow style={{ alignItems: "flex-start", width: "100%", gap: "16px" }}>
      <FlexContainer ratio={2 / 12}>
        <Column>
          <Label>{t("pages.bundleDetails.lastUpdate")}</Label>
          <Value>{moment(bundle.lastUpdate).format("DD MMM YYYY")}</Value>
        </Column>
      </FlexContainer>

      <FlexContainer ratio={2 / 12}>
        <Column>
          <Label>{t("pages.bundleDetails.bundlesEnabled")}</Label>
          <Value style={{ color: themeContext.palette.turquoiseBlue, fontWeight: "bold" }}>
            {bundle.bundlesEnabled}
          </Value>
        </Column>
      </FlexContainer>

      <FlexContainer ratio={2 / 12}>
        <Column>
          <Label>{t("pages.bundleDetails.status")}</Label>
          <SubBundleStatusChip style={{ marginTop: "14px", width: "max-content" }} type={bundle.status as any}>
            {i18next.t(`commons.apps.enums.subBundleStatus.${bundle.status}`)}
          </SubBundleStatusChip>
        </Column>
      </FlexContainer>

      <FlexContainer ratio={4 / 12}>
        <Column>
          <Label>{t("pages.bundleDetails.description")}</Label>
          <Value style={{ color: themeContext.palette.midGrey }}>{bundle.description}</Value>
        </Column>
      </FlexContainer>

      <Column style={{ marginLeft: "auto" }}>
        <SmallButton
          titleLabel={t("pages.bundleDetails.editBundleType")}
          onClick={() => {
            history.push(RoutesEnum.EDIT_BUNDLE.replace(":id", bundle.id));
          }}
          iconComponent={
            <SmallButtonIconContainer color={styleTheme.palette.turquoiseBlue}>
              <EditIcon />
            </SmallButtonIconContainer>
          }
        />
      </Column>
    </WrappedRow>
  );
};

export default BundleDetailsComponent;

const Label = styled("span")`
  font-family: Vodafone Rg;
  font-weight: bold;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
`;

const Value = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.darkGrey};
  padding-top: 14px;
`;

const SmallButtonIconContainer = styled("div")<{ color: string }>`
  width: 16px;
  height: 16px;
  stroke: ${props => props.color};

  svg {
    width: 16px;
    height: 16px;
    stroke: ${props => props.color};
  }
`;
