import React from "react";

/** Render upload-cloud SVG icon */
const UploadCloudIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" stroke="inherit">
      <path
        stroke="inherit"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M24.656 27.5s4.675.18 6.498-.625a7.973 7.973 0 004.642-6.458c.273-2.727-.971-5.723-3.321-7.173-1.169-.719-2.533-1.01-3.898-1.13a16.312 16.312 0 00-2.331.105 9.096 9.096 0 00-7.9-6.35 8.877 8.877 0 00-7.442 3.146 9.167 9.167 0 00-1.856 3.637c-.09.354-.267 1.342-.267 1.342a6.97 6.97 0 00-2.473 1.402c-2.19 1.958-2.723 5.304-1.498 7.937 1.113 2.392 3.503 4.167 6.16 4.167h4.376M20 33.333v-17.5m-5.417 5.834L20 15.833l5.417 5.834"
      ></path>
    </svg>
  );
};

export default UploadCloudIcon;
