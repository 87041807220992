import {
  CheckIcon,
  EditIcon,
  ModalTypeEnum,
  SecondaryPageTitle,
  TabChild,
  Tabs,
  TrashIcon,
  UndoIcon,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { IStoreInterface } from "../../../../../configs/store.config";
import { RoutesEnum } from "../../../../../routes/routes.constants";
import SafaricomDeleteModal from "../../../../../shared/components/safaricom-delete-modal/safaricom-delete-modal.component";
import { PageContent } from "../../../../../shared/responsive-ui.styled";
import { PageContainer } from "../../../../../shared/shared.styled";
import { deepCopyObject, updateUrlParam } from "../../../../../shared/shared.utils";
import VerticalListComponent from "../../../components/vertical-list.component";
import { ServiceManagerActions } from "../../../service-manager.store";
import { ServiceManagerTabNumber } from "../../safaricom-service-manager.page";
import CategoriesApi from "../categories.api";
import { ICategory } from "../categories.model";
import CategoryForm from "../components/category-form.component";
import useCheckFeatureAvailable from "../../../../../shared/hooks/use-check-available-feature";
import { FEATURES } from "../../../../../shared/renderer.utils";
import SideDrawer from "../../../../../shared/components/side-drawer/side-drawer.component";
import AddNewThinCard from "../../../../../shared/components/add-new-thin-card/add-new-thin-card";
import AssignMiniAppToCategoryForm from "./components/assign-mini-apps-to-category-form.component";

/**
 * EditCategoryPage component
 */
const EditCategoryPage = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showAlert, , setAlertProps] = useAlert();
  const { category } = useSelector((state: IStoreInterface) => state.serviceManagerReducer);
  const query = new URLSearchParams(useLocation().search);
  const [isEditing, setIsEditing] = React.useState(query.get("editing") === "true" || false);
  const [defaultSelectedTab, SetDefaultSelectedTab] = React.useState<number>((query.get("tabIndex") || 0) as number);
  const [errorIds, setErrorIds] = React.useState<string[]>([]);
  const [deleteModal, setDeleteModal] = React.useState<ReactNode>();
  const [showDeleteModal, hideDeleteModal] = useModal(ModalTypeEnum.CustomModal, undefined, deleteModal);
  const categoryMIniAppsTabsEnabled = useCheckFeatureAvailable(FEATURES.CATEGORY_MINI_APPS_TABS);
  const { services } = useSelector((state: IStoreInterface) => state.serviceManagerReducer);
  const [isSideDrawer, toggleSideDrawer] = useState(false);
  const [updateLocalCategoriesFlag, setUpdateLocalCategoriesFlag] = useState(false);
  const { categoryId } = useParams() as { categoryId: string };
  const [loadingCategories, toggleLoadingCategories] = useState(false);

  /**
   * function go back
   */
  const goBack = () => {
    history.push(`${RoutesEnum.SERVICE_MANAGER}?tabIdx=${ServiceManagerTabNumber.CATEGORIES}`);
  };

  /**
   * function on submit form
   */
  const onSubmit = (values: ICategory) => {
    const { id, iconUrl, isDefault, ...payload } = values;
    CategoriesApi.methods
      .editCategory(category.id, payload)
      .then(
        res => {
          dispatch(ServiceManagerActions.creators.setCategory(res.data));
          setIsEditing(false);
          setAlertProps({
            title: t("pages.serviceManager.categories.categoryChanged"),
            type: AlertTypeEnum.SUCCESS,
          });
        },
        error => {
          if (error.data.status.httpCode === 409) {
            if (error.data.status.businessCode === 8) {
              if (error.data.additionalInfo && error.data.additionalInfo.invalidExternalCategoryIds) {
                let ids = error.data.additionalInfo.invalidExternalCategoryIds;
                ids = ids.invalidExternalCategoryIds ? ids.invalidExternalCategoryIds : ids;
                setErrorIds(ids.split(","));
              }
              setAlertProps({
                title: t("pages.serviceManager.categories.duplicatedIdsError"),
                type: AlertTypeEnum.ERROR,
              });
            } else if (error.data.status.businessCode === 7) {
              setAlertProps({
                title: t("pages.serviceManager.categories.categoryAlreadyExistsError"),
                type: AlertTypeEnum.ERROR,
              });
            }
          } else {
            setAlertProps({
              title: t("pages.serviceManager.categories.editCategoriesError"),
              type: AlertTypeEnum.ERROR,
            });
          }
        },
      )
      .finally(() => {
        showAlert();
      });
  };

  /**
   * function on delete category
   */
  const onDelete = () => {
    setDeleteModal(
      <SafaricomDeleteModal
        secondaryAction={() => hideDeleteModal()}
        primaryAction={() => {
          CategoriesApi.methods
            .deleteCategory(category.id)
            .then(
              res => {
                setAlertProps({
                  title: t("pages.serviceManager.categories.categoryDeleted"),
                  type: AlertTypeEnum.SUCCESS,
                });
                showAlert();
                goBack();
              },
              () => {
                setAlertProps({
                  title: t("pages.serviceManager.categories.deleteCategoriesError"),
                  type: AlertTypeEnum.ERROR,
                });
                showAlert();
              },
            )
            .finally(() => {
              hideDeleteModal();
            });
        }}
        title={t("pages.serviceManager.categories.categoryForm.deleteTitleModal")}
        description={t("pages.serviceManager.categories.categoryForm.deleteDescriptionModal").replace(
          "{category}",
          category.categoryName,
        )}
        primaryBtnLabel={t("pages.serviceManager.categories.categoryForm.deleteLabelBtn")}
      />,
    );
    showDeleteModal();
  };

  /**
   * function to reset form
   */
  const resetForm = () => {
    const form = document.getElementById("category-form") as HTMLFormElement;
    form.reset();
  };

  /**
   * Get categories API call
   * @function getCategories
   */
  const getCategories = () => {
    toggleLoadingCategories(true);
    CategoriesApi.methods.getCategories().then(
      res => {
        const categoriesSorted = res.data.sort((a, b) => a.categoryName.localeCompare(b.categoryName));
        dispatch(ServiceManagerActions.creators.fetchCategories(categoriesSorted));

        // TODO update this workaround when BE create API for category details
        const _category = categoriesSorted.find(c => c.id === categoryId) || category;
        dispatch(ServiceManagerActions.creators.setCategory(_category));
        toggleLoadingCategories(false);
      },
      () => {
        toggleLoadingCategories(false);
        setAlertProps({
          title: t("pages.serviceManager.categories.getCategoriesError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  useEffect(() => {
    console.log("selected tab index" + defaultSelectedTab);
    console.log("isEditing" + isEditing);
    getCategories();
  }, [updateLocalCategoriesFlag]);

  /**
   * filter mini-apps list by if it assigned to this category
   * @function filterList
   * @param {ICategoryAssignedMiniApps[]} categoryMiniApps mini-apps Ids that are assigned to this category
   */
  const orderedMiniAppsList = useMemo(() => {
    const categoryMiniAppsIds = category.services?.map(item => item.id);
    const filteredMiniApps = services.filter(item => {
      return categoryMiniAppsIds?.includes(item.id);
    });

    filteredMiniApps.sort((a, b) => categoryMiniAppsIds?.indexOf(a.id) - categoryMiniAppsIds?.indexOf(b.id));
    return filteredMiniApps;
  }, [category.services]);

  /**
   * Handle toggle side drawer visibility
   * @function handleToggleSideDrawer
   */
  const handleToggleSideDrawer = () => toggleSideDrawer(toggle => !toggle);

  /**
   * Render CategoryDetails
   * @returns {JSX.Element} JSX Element of category details content
   */
  const renderCategoryDetails = () => (
    <>
      <CategoryForm
        initialValues={deepCopyObject(category)}
        isEditing={isEditing}
        onSubmitFn={onSubmit}
        errorIds={errorIds}
      />

      <SideMenu className="side-menu">
        {!isEditing ? (
          <LinkContainer
            id="edit-category"
            onClick={() => {
              setIsEditing(!isEditing);
            }}
          >
            <IconContainer color={styleTheme.palette.turquoiseBlue}>
              <EditIcon />
            </IconContainer>
            <span>{t("pages.serviceManager.categories.categoryForm.editCategory")}</span>
          </LinkContainer>
        ) : (
          <>
            <LinkContainerSave id="save-changes" type="submit" form={`category-form`}>
              <IconContainer color={styleTheme.palette.successGreen}>
                <CheckIcon />
              </IconContainer>
              <span>{t("pages.serviceManager.categories.categoryForm.saveChanges")}</span>
            </LinkContainerSave>
            <LinkContainer
              id="cancel-changes"
              onClick={() => {
                setIsEditing(false);
                resetForm();
              }}
            >
              <IconContainer color={styleTheme.palette.errorRed}>
                <UndoIcon />
              </IconContainer>
              <span>{t("pages.serviceManager.categories.categoryForm.cancelChanges")}</span>
            </LinkContainer>
          </>
        )}
        {!category.isDefault && (
          <LinkContainer id="delete-category" disabled={isEditing} onClick={onDelete}>
            <IconContainer color={styleTheme.palette.errorRed}>
              <TrashIcon />
            </IconContainer>
            <span>{t("pages.serviceManager.categories.categoryForm.deleteCategory")}</span>
          </LinkContainer>
        )}
      </SideMenu>
    </>
  );

  /**
   * Toggle the flag to hit the categories API to sync the local categories data with the latest BE data
   */
  const toggleUpdateLocalCategoriesFlag = () => setUpdateLocalCategoriesFlag(state => !state);

  /**
   *
   * @param tabIndex
   */
  const onTabChange = (tabIndex: number) => {
    const url = updateUrlParam(window.location.toString(), "tabIndex", tabIndex.toString());
    window.history.pushState({}, "", url);
    SetDefaultSelectedTab(tabIndex);
  };
  return (
    <PageContainer>
      <SecondaryPageTitle
        displayInitialsCircle={false}
        title={`${category.categoryName} ${t("pages.serviceManager.categories.categoryForm.category")}`}
        goBackFn={goBack}
      />

      <PageContent>
        {categoryMIniAppsTabsEnabled ? (
          <Tabs
            isSecondaryTabs={true}
            isSfcTabs={true}
            tabHeaderStyle={{ paddingTop: "25px", textTransform: "uppercase" }}
            controlledSelectedTab={Number(defaultSelectedTab)}
            controlledSetSelectedTab={onTabChange}
          >
            <TabChild label={t("pages.serviceManager.categories.miniApps.detailsTab")}>
              <CategoryDetailsContainer>{renderCategoryDetails()}</CategoryDetailsContainer>
            </TabChild>
            <TabChild label={t("pages.serviceManager.categories.miniApps.miniAppTab")}>
              <DescriptionContainer>
                <span>{t("pages.serviceManager.categories.miniApps.heading")}</span>
                <p>{t("pages.serviceManager.categories.miniApps.description")}</p>
              </DescriptionContainer>
              <AddNewThinCard
                handleAddNew={handleToggleSideDrawer}
                title={t("pages.serviceManager.categories.miniApps.addMiniApp")}
              />
              <VerticalListComponent
                items={orderedMiniAppsList}
                setShouldUpdateLocalCategories={toggleUpdateLocalCategoriesFlag}
                isMiniAppsWithinCategory
                loadingCategories={loadingCategories}
              />
            </TabChild>
          </Tabs>
        ) : (
          renderCategoryDetails()
        )}
      </PageContent>
      <SideDrawer toggleSideDrawer={handleToggleSideDrawer} open={isSideDrawer}>
        <AssignMiniAppToCategoryForm
          hideDrawer={handleToggleSideDrawer}
          toggleUpdateLocalCategoriesFlag={toggleUpdateLocalCategoriesFlag}
          categoryMiniApps={category.services}
        />
      </SideDrawer>
    </PageContainer>
  );
};

export default EditCategoryPage;

const SideMenu = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 75px;
  width: 40%;
  margin-left: 50px;
`;

const LinkContainer = styled("div")<{ disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-family: Vodafone Rg;
  color: ${styleTheme.palette.darkGrey};
  font-size: 16px;
  margin-bottom: 15px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? "none" : "inherit")};

  > div {
    margin-right: 8px;
  }
`;
const LinkContainerSave = styled("button")<{ disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-family: Vodafone Rg;
  color: ${styleTheme.palette.darkGrey};
  font-size: 16px;
  margin-bottom: 15px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? "none" : "inherit")};
  border: none;
  background: none;
  padding: 0;

  > div {
    margin-right: 8px;
  }
`;

const IconContainer = styled("div")<{ color: string }>`
  width: 20px;
  height: 20px;
  > svg {
    stroke: ${props => props.color};
  }
`;

const CategoryDetailsContainer = styled.div`
  display: flex;
`;

const DescriptionContainer = styled.div`
  padding: 16px 0;
  align-items: flex-end;
  font-family: Vodafone Rg;
  color: ${styleTheme.palette.midGrey};
  span {
    font-size: 22px;
  }
  p {
    font-size: 16px;
  }
`;
