import { AxiosPromise } from "axios";
import ApiClient from "../../../configs/axios.config";
import {
  IAddLanguageTranslationRequest,
  IAddLanguageTranslationsVersionRequest,
  IExportLanguageTranslationRequest,
  IImportLanguageTranslationRequest,
  ILanguageTranslations,
  ILanguageTranslationsVersions,
} from "./language-translations.model";

/**
 * Translations API
 * @type {{routes: {languageTranslations: string}, methods: {languageTranslations: (appId: string) => AxiosPromise}}}
 */
const LanguageTranslationsApi = {
  routes: {
    getLanguageTranslations: "/languagetranslations",
    getLastVersion: "/languagetranslations",
    getLanguageTranslationsVersions: "/languagetranslations/translationsversions",
    getWhitelistedVersions: "/languagetranslations/whitelistedversions",
    languageTranslations: "/languagetranslations",
    revertLanguageTranslations: "/languagetranslations/revert",
    exportLanguageTranslations: "/languagetranslations/export",
    importLanguageTranslations: "/languagetranslations/import",
    submitForApproval: "/languagetranslations/submitforapproval",
  },
  methods: {
    getLanguageTranslationsVersions: (): AxiosPromise<ILanguageTranslationsVersions> => {
      return ApiClient.get(LanguageTranslationsApi.routes.getLanguageTranslationsVersions);
    },
    createLanguageTranslationVersion: (request: IAddLanguageTranslationsVersionRequest): AxiosPromise => {
      return ApiClient.post(LanguageTranslationsApi.routes.getLanguageTranslationsVersions, request);
    },
    deleteLanguageTranslationsVersion: (v: string): AxiosPromise => {
      return ApiClient.delete(LanguageTranslationsApi.routes.getLanguageTranslationsVersions, {
        params: { version: v },
      });
    },
    getWhitelistedVersions: (): AxiosPromise<{ versions: string[] }> => {
      return ApiClient.get(LanguageTranslationsApi.routes.getWhitelistedVersions);
    },
    getLanguageTranslations: (v: string): AxiosPromise<ILanguageTranslations> => {
      return ApiClient.get(LanguageTranslationsApi.routes.getLanguageTranslations, { params: { version: v } });
    },
    getLastVersion: (v: string): AxiosPromise<ILanguageTranslations> => {
      return ApiClient.get(LanguageTranslationsApi.routes.getLastVersion, {
        params: { previousVersion: true, version: v },
      });
    },
    revertLanguageTranslations: (v: string): AxiosPromise<ILanguageTranslations> => {
      return ApiClient.put(LanguageTranslationsApi.routes.revertLanguageTranslations, null, { params: { version: v } });
    },
    submitForApproval: (v: string): AxiosPromise<ILanguageTranslations> => {
      return ApiClient.put(LanguageTranslationsApi.routes.submitForApproval, null, { params: { version: v } });
    },
    createLanguageTranslation: (languageTranslation: IAddLanguageTranslationRequest): AxiosPromise => {
      return ApiClient.post(LanguageTranslationsApi.routes.languageTranslations, languageTranslation);
    },
    editLanguageTranslation: (languageTranslation: IAddLanguageTranslationRequest): AxiosPromise => {
      return ApiClient.put(LanguageTranslationsApi.routes.languageTranslations, languageTranslation);
    },
    exportLanguageTranslation: (exportRequest: IExportLanguageTranslationRequest): AxiosPromise => {
      return ApiClient.post(LanguageTranslationsApi.routes.exportLanguageTranslations, exportRequest, {
        responseType: "blob",
      });
    },
    importLanguageTranslation: (requestPayload: IImportLanguageTranslationRequest): AxiosPromise => {
      const formData = new FormData();
      formData.append("version", requestPayload.version);
      formData.append("translationsFile", requestPayload.translationsFile);

      return ApiClient.post(LanguageTranslationsApi.routes.importLanguageTranslations, formData);
    },
    deleteLanguageTranslation: (data: { key: string }, v: string): AxiosPromise => {
      return ApiClient.delete(LanguageTranslationsApi.routes.languageTranslations, {
        params: { version: v, key: data.key },
      });
    },
  },
};

export default LanguageTranslationsApi;
