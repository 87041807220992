import { useFilters, useAlert } from "@wit/mpesa-ui-components";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { IStoreInterface } from "../../../configs/store.config";
import { filterServiceManagerServices } from "../ant-service-manager.utils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ServiceManagerFilters from "../components/ant-service-manager-filters.component";
import ServiceManagerListItem from "./ant-service-list-item.component";
import { AntServiceManagerActions } from "../ant-service-manager.store";
import AntServiceManagerApi from "../ant-service-manager.api";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { LoadingText } from "../../../shared/shared.styled";
import { SelectedView } from "../../../shared/shared.enums";
import RendererUtils from "../../../shared/renderer.utils";
import { UserRolesVDF } from "../../admin/users/users.interfaces";
import { ILoggedUser } from "../../authentication/authentication.interfaces";
import AddServiceManagerItem from "../components/add-service-manager-item.component";
import EmptySearchResult from "../../../shared/components/empty-search-result.component";

/**
 * Page Service List
 */
const ServiceManagerList = () => {
  const {
    filters,
    updateFilter,
    isFilterActive,
    clearFilter,
    resetFilters,
    getFilterValue,
    getFiltersQueryString,
    updateMultipleFilters,
  } = useFilters();

  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [selectedView, setSelectedView] = React.useState<SelectedView>(SelectedView.GRID);
  const { services, isLoadingServices } = useSelector((state: IStoreInterface) => state.antServiceManagerReducer);
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const [showableServices, setShowableServices] = React.useState(services);
  const loggedUser = useSelector((state: IStoreInterface) => state.authenticationReducer.loggedUser) as ILoggedUser;

  React.useEffect(() => {
    dispatch(AntServiceManagerActions.creators.fetchingServicesAction());
    AntServiceManagerApi.methods.getServices().then(
      res => {
        dispatch(AntServiceManagerActions.creators.fetchServicesSuccessAction(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.serviceBuilder.errors.searchServices"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  }, [dispatch, t, showAlert, setAlertProps]);

  React.useEffect(() => {
    setShowableServices(services);
  }, [services]);

  React.useEffect(() => {
    if (filters.size !== 0 && (filters.get("search") !== undefined || filters.get("status") !== undefined)) {
      setShowableServices(filterServiceManagerServices(services, filters));
    } else {
      setShowableServices(services);
    }
  }, [filters, services]);

  function showNoResults(): boolean {
    if (!showableServices || showableServices.length === 0) {
      return !(
        filters.size === 0 ||
        (filters.size === 1 && filters.get("search") !== undefined && (filters.get("search") as string[])[0] === "")
      );
    } else {
      return false;
    }
  }

  function getServiceUser() {
    return RendererUtils.checkRolePermission(
      [
        UserRolesVDF.ADMIN_SERVICE_MANAGEMENT,
        UserRolesVDF.EDITOR_SERVICE_MANAGEMENT,
        UserRolesVDF.APPROVER_SERVICE_MANAGEMENT,
      ],
      loggedUser,
    );
  }

  function renderServices() {
    const serviceUser = getServiceUser();
    return (
      <ServiceItemsContainer>
        {showableServices === services && serviceUser && <AddServiceManagerItem />}
        {showableServices &&
          showableServices.map(service => <ServiceManagerListItem key={service.id} item={service} />)}
      </ServiceItemsContainer>
    );
  }

  return (
    <>
      {isLoadingServices ? (
        <LoadingText>{t("commons.loadingResults")}</LoadingText>
      ) : (
        <>
          <ServiceManagerFilters
            filters={filters}
            isFilterActive={isFilterActive}
            updateFilter={updateFilter}
            clearFilter={clearFilter}
            resetFilters={resetFilters}
            getFilterValue={getFilterValue}
            getFiltersQueryString={getFiltersQueryString}
            updateMultipleFilters={updateMultipleFilters}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
          />
          {showNoResults() ? <EmptySearchResult /> : renderServices()}
        </>
      )}
    </>
  );
};

export default ServiceManagerList;

const ServiceItemsContainer = styled("div")`
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(auto-fill, 406.33px);
  column-gap: 24px;
  row-gap: 24px;
  flex: 1;
  margin-bottom: 45px;
`;
