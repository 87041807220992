import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface IAddPushNotificationItemProps {
  addNewPushNotificationFn: () => void;
}
const AddPushNotificationItem = ({ addNewPushNotificationFn }: IAddPushNotificationItemProps) => {
  const [t] = useTranslation();
  return (
    <AddPushNotificationItemContainer onClick={addNewPushNotificationFn} id={"add-new-push-notification"}>
      <AddPushNotificationItemIconContainer>
        <img src="./images/rounded-plus.svg" alt="add-app button" />
      </AddPushNotificationItemIconContainer>
      <AddPushNotificationItemTitle>{t("pages.pushNotifications.addNewPushNotification")}</AddPushNotificationItemTitle>
    </AddPushNotificationItemContainer>
  );
};

export default AddPushNotificationItem;

const AddPushNotificationItemContainer = styled("div")`
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  padding-left: 24px;
  margin-bottom: 24px;
  :hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  }
`;

const AddPushNotificationItemIconContainer = styled("div")`
  width: 45px;
  height: 45px;
  margin-bottom: 0;
  margin-right: 24px;

  img {
    width: 45px;
    height: 45px;
  }
`;

const AddPushNotificationItemTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  text-align: center;
  color: ${props => props.theme.palette.midGrey};
`;
