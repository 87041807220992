import React from "react";
import { IAnnouncement } from "../announcements.model";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { CancelIcon } from "@wit/mpesa-ui-components";
import StoryPreviewIcon from "../../../shared/icons/story-preview.icon";
import AnnouncementsApi from "../announcements.api";
import useCheckFeatureAvailable from "../../../shared/hooks/use-check-available-feature";
import { FEATURES } from "../../../shared/renderer.utils";

interface IStoryPreviewProps {
  values: IAnnouncement;
  tabStory: number;
}

/**
 * component that will be doing preview from selected story
 * @param param0 receive interface to the data of the stories selected
 */
const StoryPreview = ({ values, tabStory }: IStoryPreviewProps) => {
  const [t] = useTranslation();
  const colorDefaultToTitle = "#00b56a";
  const colorDefaultToDescription = "#1074ff";
  const [backgroundImage, setBackgroundImage] = React.useState("");
  const isUploadCarouselImageFieldEnabled = useCheckFeatureAvailable(FEATURES.NEW_IMAGE_FOR_ANNOUNCEMENT_CAROUSEL);

  /**
   * request image to backend
   * @param tab number of tab
   */
  const getStoryImage = () => {
    setBackgroundImage("");
    AnnouncementsApi.methods.getImage(values.stories[tabStory].backgroundImageUrl).then(res => {
      setBackgroundImage(res.data);
    });
  };

  React.useEffect(() => {
    if (
      values.stories[tabStory] &&
      (values.stories[tabStory].backgroundImage === null || values.stories[tabStory].backgroundImage === "")
    ) {
      getStoryImage();
    } else {
      setBackgroundImage(values.stories[tabStory].backgroundImage);
    }
  }, [values, tabStory]);

  /** Renders story preview icon */
  const rendersStoryPreviewIcon = () => {
    if (isUploadCarouselImageFieldEnabled) {
      return values.icon ? <StoryPreviewIcon /> : null;
    }

    return <StoryPreviewIcon />;
  };

  return (
    <DivBackground background={backgroundImage}>
      <ShadowUpTopScreen />
      <StoryTimelineBar />
      <RowCustom>
        {rendersStoryPreviewIcon()}
        <CancelIcon width={"17px"} height={"17px"} style={{ stroke: "white" }} />
      </RowCustom>
      {values.stories[tabStory] && values.stories[tabStory].title ? (
        <TitleText
          color={
            values.stories[tabStory].colorTitle ? (values.stories[tabStory].colorTitle as string) : colorDefaultToTitle
          }
        >
          {values.stories[tabStory].title
            ? values.stories[tabStory].title
            : t("pages.announcements.addPage.storyDefaultTitle")}
        </TitleText>
      ) : null}
      {values.stories[tabStory] && values.stories[tabStory].description ? (
        <DescriptionText
          color={
            values.stories[tabStory].colorDescription
              ? (values.stories[tabStory].colorDescription as string)
              : colorDefaultToDescription
          }
        >
          {values.stories[tabStory].description
            ? values.stories[tabStory].description
            : t("pages.announcements.addPage.descriptionDefaultTitle")}
        </DescriptionText>
      ) : null}
      {values.stories[tabStory] && values.stories[tabStory].callAction ? (
        <CalllToActionButton>
          {values.stories[tabStory].callToAction.title
            ? values.stories[tabStory].callToAction.title
            : t("pages.announcements.addPage.callToAction")}
        </CalllToActionButton>
      ) : null}
    </DivBackground>
  );
};

const DivBackground = styled("div")<{ background: string }>`
  background: ${props => (props.background ? `url("${props.background}")` : "#e1f2ff")};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
`;

const ShadowUpTopScreen = styled("div")`
  position: absolute;
  width: 100%;
  height: 30%;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0.2;
  background: linear-gradient(black, transparent);
`;

const StoryTimelineBar = styled("span")`
  width: 90%;
  height: 2px;
  opacity: 0.2;
  border-radius: 2.1px;
  background-color: white;
`;

const RowCustom = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin-top: 8px;
`;

const TitleText = styled("span")<{ color: string }>`
  background: ${props => props.color};
  margin-top: 24px;
  color: white;
  padding: 8px 16px;
  font-family: Vodafone Rg;
  text-transform: uppercase;
  font-size: 20px;
`;

const DescriptionText = styled("span")<{ color: string }>`
  background: ${props => props.color};
  color: white;
  padding: 4px 8px;
  font-family: Vodafone Rg;
  font-size: 16px;
`;

const CalllToActionButton = styled("span")`
  width: fit-content;
  height: 29px;
  padding: 6px 23px 6px 24px;
  border-radius: 16px;
  background-color: ${props => props.theme.palette.vodafoneRed};
  font-family: Vodafone Rg;
  font-size: 12.8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: white;
  max-width: 90%;
  position: absolute;
  bottom: 10%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`;
export default StoryPreview;
