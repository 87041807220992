import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { CancelIcon } from "@wit/mpesa-ui-components";
import { useSelector } from "react-redux";
import { IStoreInterface } from "../../../../configs/store.config";
import { Column, Row } from "../../../../shared/shared.styled";
import { getStatusColor } from "../banks.utils";
import { IBank, BanksStatusEnum } from "../bank.model";

interface ILastVersionModalProps {
  onCancelClick: () => void;
}

const LastVersionModal = ({ onCancelClick }: ILastVersionModalProps) => {
  const [t] = useTranslation();

  const { banks } = useSelector((state: IStoreInterface) => state.banksReducer);

  const renderBankItem = (item: IBank) => {
    return (
      <ItemColumn disabled={item.status === BanksStatusEnum.DELETE}>
        <Row>
          <ImageContainer>
            <img
              style={{ height: 20 }}
              src={`./imagesuploaded/${item.urlIcon}`}
              alt={item.name}
              onError={e => ((e.target as HTMLImageElement).src = "./images/bank.png")}
            />
          </ImageContainer>
          <ItemInfo>
            {item.shortCode && (
              <Row>
                {t("pages.banks.lastVersionModal.shortCode")}: {item.shortCode}
              </Row>
            )}
            {item.name && (
              <Row>
                {t("pages.banks.lastVersionModal.name")}: {item.name}
              </Row>
            )}
            {item.accountNumberPrefix && (
              <Row>
                {t("pages.banks.lastVersionModal.prefix")}: {item.name}
              </Row>
            )}
          </ItemInfo>
          <StatusContainer>
            {item.status === BanksStatusEnum.LIVE && (
              <StatusRow color={getStatusColor(BanksStatusEnum.LIVE)}>{t("pages.banks.status.live")}</StatusRow>
            )}
            {item.status === BanksStatusEnum.DELETE && (
              <StatusRow color={getStatusColor(BanksStatusEnum.DELETE)}>{t("pages.banks.status.delete")}</StatusRow>
            )}
            {item.status === BanksStatusEnum.DISABLE && (
              <StatusRow color={getStatusColor(BanksStatusEnum.DISABLE)}>
                {t("pages.banks.status.deactivate")}
              </StatusRow>
            )}
            {item.status === BanksStatusEnum.DISABLED && (
              <StatusRow color={getStatusColor(BanksStatusEnum.DISABLED)}>
                {t("pages.banks.status.deactivated")}
              </StatusRow>
            )}
          </StatusContainer>
        </Row>
      </ItemColumn>
    );
  };

  return (
    <ModalContainer>
      <Header>
        <ModalTitle>{t("pages.banks.lastVersionModal.title")}</ModalTitle>
        <RightContainer>
          <CloseIconContainer onClick={onCancelClick} id="close-last-version-modal">
            <CancelIcon color={"grey"} />
          </CloseIconContainer>
        </RightContainer>
      </Header>
      <ItemsContainer>
        {banks.lastVersion &&
          banks.lastVersion.map((lastVersionItem, index) => {
            return (
              <Item key={lastVersionItem.id}>
                {lastVersionItem ? renderBankItem(lastVersionItem) : <ItemColumn></ItemColumn>}
              </Item>
            );
          })}
      </ItemsContainer>
    </ModalContainer>
  );
};

export default LastVersionModal;

const ModalContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 24px;
  font-family: Vodafone Rg;
`;

const ModalTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 21px;
`;

const ItemsContainer = styled.div`
  max-height: 250px;
  overflow: scroll;
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${props => props.theme.palette.darkGrey};
  overflow-x: hidden;
`;

const Item = styled(Row)`
  display: flex;
  /* justify-content: space-between; */
`;

const ImageContainer = styled(Column)`
  margin-right: 24px;
  display: flex;
  align-self: baseline;
  flex: 1;
  padding: 4px;
`;

const ImageContainerCategory = styled(ImageContainer)`
  background-color: #ebf7fa;
`;

const ItemColumn = styled(Column)<{ disabled?: Boolean }>`
  flex: 1;
  padding: 16px 0px 16px 0;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  border-bottom: 1px solid #d8d8d8;
  margin-right: 34px;
`;

const ItemInfo = styled(Column)`
  flex: 20;
`;
const StatusContainer = styled(Column)`
  padding-left: 24px;
  display: flex;
  align-self: baseline;
  flex: 1;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CloseIconContainer = styled("div")`
  left: 23px;
  margin-top: -10px;
  stroke: ${props => props.theme.palette.midGrey};
  cursor: pointer;
  svg {
    width: 24px;
  }
`;

const RightContainer = styled.div`
  display: flex;
`;

const StatusRow = styled(Row)<{ color: string }>`
  color: ${props => props.color};
  display: flex;
  justify-content: flex-end;
`;
