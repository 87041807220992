import {
  DownloadIcon,
  ModalTypeEnum,
  MultipleOptionsDropdown,
  PlusIcon,
  SearchBar,
  SmallButton,
  Table,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";

import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { IStoreInterface } from "../../../../configs/store.config";
import { RoutesEnum } from "../../../../routes/routes.constants";
import CustomPopper from "../../../../shared/components/custom-popper.component";
import SafaricomDeleteModal from "../../../../shared/components/safaricom-delete-modal/safaricom-delete-modal.component";
import { IUser } from "../../../../shared/models/user.model";
import {
  OtherFiltersContainer,
  SearchBarFilterContainer,
  SmallButtonsContainer,
} from "../../../../shared/responsive-ui.styled";
import { LoadingText, PopperRow, PopperRowText } from "../../../../shared/shared.styled";
import { getRoles, isSFCMarket } from "../../../../shared/shared.utils";
import UsersApi from "../users.api";
import "../users.css";
import { UsersActions } from "../users.store";
import { AdminUtils } from "../users.utils";

/**
 * UsersPage component
 */
const UsersPage = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );

  const persistedUsers = useSelector((store: IStoreInterface) => store.usersReducer.users);
  const isLoadingUsers = useSelector((store: IStoreInterface) => store.usersReducer.isLoadingUsers);

  // State
  const [users, setUsers] = useState(persistedUsers);
  const [searchString, setSearchString] = useState("");
  const [rolesSelected, setRolesSelected] = useState<string[]>([]);
  const [disableModal, setDisableModal] = React.useState<ReactNode>();
  const [showDisableUserModalSFC, hideDisableUserModalSFC] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    disableModal,
  );

  /*
   * Get users effect
   * */
  useEffect(() => {
    getUsers();
  }, [dispatch]);
  const getUsers = () => {
    dispatch(UsersActions.creators.fetchingUsersAction());
    UsersApi.methods.getUsers().then(
      res => {
        dispatch(UsersActions.creators.fetchUsersSuccessAction(res.data));
      },
      err => console.error(err),
    );
  };

  /*
   * Search effect
   * */
  useEffect(() => {
    let visibleUsers = [...persistedUsers];

    if (!!searchString) {
      visibleUsers = visibleUsers.filter(
        pu =>
          pu.fullName.toLowerCase().includes(searchString.toLowerCase()) ||
          pu.username.toLowerCase().includes(searchString.toLowerCase()),
      );
    }
    if (rolesSelected.length > 0) {
      visibleUsers = visibleUsers.filter(pu => rolesSelected.includes(pu.role));
    }
    setUsers(visibleUsers);
  }, [persistedUsers, searchString, rolesSelected]);

  /*
   * Exports the current list of users
   * */
  const exportUsers = () => {
    const fileDownload = require("js-file-download");
    UsersApi.methods.exportUsersCsv().then(
      res => {
        fileDownload(res.data, "export.csv");
      },
      err => console.error(err),
    );
  };

  /*
   * Add new user navigation
   * */
  const addNewUser = () => {
    history.push(RoutesEnum.ADD_USER);
  };

  function toggleOption(opt: SharedDropdownOption) {
    if (isOptionSelected(opt)) {
      setRolesSelected(rolesSelected.filter(c => c !== opt.key));
    } else {
      setRolesSelected([...rolesSelected, opt.key]);
    }
  }

  function resetDropdown() {
    setRolesSelected([]);
  }

  function isOptionSelected(opt: SharedDropdownOption) {
    return rolesSelected.includes(opt.key);
  }

  /*
   * Resends the registration email for a given user
   * */
  const resendRegEmail = (user: IUser) => {
    const el = document.getElementById(`resend-${user.username}`);

    if (el) {
      el.classList.add("disabled");
    }
    UsersApi.methods
      .resendRegEmail(user.username)
      .then(
        () => {
          setAlertProps({
            title: t("pages.users.resendRegistrationEmailSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
        },
        () => {
          setAlertProps({
            title: t("pages.users.resendRegistrationEmailError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        if (el) {
          el.classList.remove("disabled");
        }
      });
  };

  /*
   * Disable user API request
   * */
  const disableUserRequest = (user: IUser) => {
    UsersApi.methods
      .disableUser(user.username)
      .then(
        () => {
          setAlertProps({
            title: t("pages.users.disableUserSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          getUsers();
        },
        () => {
          setAlertProps({
            title: t("pages.users.disableUserError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(hideConfirmationModal);
  };

  /*
   * Disable user request
   * */
  const disableUser = (user: IUser) => {
    if (isSFCMarket()) {
      setDisableModal(
        <SafaricomDeleteModal
          secondaryAction={() => hideDisableUserModalSFC()}
          primaryAction={() => disableUserRequest(user)}
          title={t("pages.users.popperActions.disableUser")}
          description={t("pages.users.disableUserConfirmation", {
            user: user.fullName,
          })}
          primaryBtnLabel={t("pages.users.disableUserBtn")}
        />,
      );
      showDisableUserModalSFC();
    } else {
      setConfirmationProps({
        title: t("pages.users.popperActions.disableUser"),
        description: t("pages.users.disableUserConfirmation", {
          user: user.fullName,
        }),
        primaryBtnId: "confirm-button",
        secondaryBtnId: "cancel-button",
        primaryAction: () => disableUserRequest(user),
        secondaryAction: () => hideConfirmationModal(),
      } as BaseModalProps);
      showConfirmationModal();
    }
  };

  /*
   * Enable user request
   * */
  const enableUser = (user: IUser) => {
    setConfirmationProps({
      title: t("pages.users.popperActions.enableUser"),
      description: t("pages.users.enableUserConfirmation", {
        user: user.fullName,
      }),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      primaryAction: () => {
        UsersApi.methods
          .enableUser(user.username)
          .then(
            () => {
              setAlertProps({
                title: t("pages.users.enableUserSuccess"),
                type: AlertTypeEnum.SUCCESS,
              });
              showAlert();
              getUsers();
            },
            () => {
              setAlertProps({
                title: t("pages.users.enableUserError"),
                type: AlertTypeEnum.ERROR,
              });
              showAlert();
            },
          )
          .finally(hideConfirmationModal);
      },
      secondaryAction: () => hideConfirmationModal(),
    } as BaseModalProps);
    showConfirmationModal();
  };

  /*
   * Render row actions
   * */
  function renderPopperOptions(user: IUser): ReactElement {
    return (
      <>
        {user.status === 1 ? (
          <PopperRow
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <PopperRowText onClick={() => disableUser(user)} id={`disable-${user.username}`}>
              {t("pages.users.popperActions.disableUser")}
            </PopperRowText>
          </PopperRow>
        ) : null}

        {user.status === 0 ? (
          <PopperRow
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <PopperRowText onClick={() => enableUser(user)} id={`enable-${user.username}`}>
              {t("pages.users.popperActions.enableUser")}
            </PopperRowText>
          </PopperRow>
        ) : null}

        {user.status === 2 ? (
          <PopperRow
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <PopperRowText onClick={() => resendRegEmail(user)} id={`resend-${user.username}`}>
              {t("pages.users.popperActions.resendRegistrationEmail")}
            </PopperRowText>
          </PopperRow>
        ) : null}
      </>
    );
  }

  function renderRowActions(rowData: IUser): React.ReactNode {
    return <CustomPopper user={rowData}>{renderPopperOptions(rowData)}</CustomPopper>;
  }

  // Renders
  return (
    <>
      {isLoadingUsers ? (
        <LoadingText>{t("commons.loadingResults")}</LoadingText>
      ) : (
        <>
          <FiltersContainer>
            <SearchBarFilterContainer>
              <SearchBar
                placeholderLabel={t("pages.users.searchBarPlaceholder")}
                value={searchString}
                onChange={e => setSearchString(e.target.value)}
                clearValue={() => setSearchString("")}
              />
            </SearchBarFilterContainer>
            <OtherFiltersContainer>
              <MultipleOptionsDropdown
                label={t("pages.users.rolesDropdownPlaceholder")}
                toggleOption={opt => toggleOption(opt)}
                clearAllFilters={() => resetDropdown()}
                hasValue={false}
                isOptionSelected={opt => isOptionSelected(opt)}
                options={getRoles(t)}
                clearAllFiltersLabel={t("pages.users.clearAllFiltersLabel")}
              />
            </OtherFiltersContainer>
            <SmallButtonsContainer>
              <SmallButton
                onClick={exportUsers}
                titleLabel={t("pages.users.export")}
                iconComponent={
                  <IconContainer>
                    <DownloadIcon />
                  </IconContainer>
                }
              />
              <SmallButton
                onClick={addNewUser}
                titleLabel={t("pages.users.addOperatorBtn")}
                iconComponent={
                  <IconContainer>
                    <PlusIcon />
                  </IconContainer>
                }
              />
            </SmallButtonsContainer>
          </FiltersContainer>
          <Table<IUser>
            columns={AdminUtils.getTableColumns()}
            values={users}
            rowClickAction={(idx, rowData) => {
              dispatch(UsersActions.creators.selectUser(rowData.username));
              history.push(RoutesEnum.USER_PROFILE);
            }}
            displayActions={rowData => renderRowActions(rowData)}
          />
        </>
      )}
    </>
  );
};

export default UsersPage;

const FiltersContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 32px;
  flex-wrap: wrap;
  @media (max-width: 1024px) {
    gap: 24px;
  }
`;

const IconContainer = styled("div")`
  stroke: ${props => props.theme.palette.vodafoneRed};
  width: 20px;
  height: 20px;

  svg {
    height: 20px;
  }
`;
