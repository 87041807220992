import { AppsFiltersEnum } from "./components/apps-filters.component";
import { IApp, AppStatusEnum, AppPlatformEnum, AppTypeEnum } from "../../shared/models/app.model";
import { isSFCMarket } from "../../shared/shared.utils";

/**
 * Filters an array of Apps using a AppStatusEnum[]
 * @param apps App[]
 * @param status AppStatusEnum[]
 */
export const filterAppsByStatus = (apps: IApp[], status: AppStatusEnum[]): IApp[] => {
  if (!!status && !!status.length) {
    return apps.filter(app => status.includes(app.status));
  }
  return apps;
};

export const filterAppsByType = (apps: IApp[], type?: AppTypeEnum): IApp[] => {
  if (type) {
    return apps.filter(app => app.platform === type);
  }
  return apps;
};
export const filterAppsByPlatform = (apps: IApp[], platform?: AppPlatformEnum): IApp[] => {
  if (platform) {
    return apps.filter(app => app.system === platform);
  }
  return apps;
};

/**
 * Filter an array of Apps using a search string
 * @param apps App[]
 * @param searchString string
 */
export const filterAppsBySearchString = (apps: IApp[], searchString: string) => {
  if (!!searchString) {
    return apps.filter(app => app.name.toLowerCase().includes(searchString.toLowerCase()));
  }
  return apps;
};

/**
 * Filter an array of Apps using a search string, an array of AppStatusEnum, and a AppPlatformEnum
 * @param apps App[]
 * @param filters AppFilters
 */
export const filterApps = (apps: IApp[], filters: Map<string, string[]>): IApp[] => {
  let newApps = apps;
  const platforms = filters.get(AppsFiltersEnum.PLATFORM) as any;
  const types = filters.get(AppsFiltersEnum.TYPE) as any;
  const search = filters.get(AppsFiltersEnum.SEARCH);

  newApps = filterAppsByPlatform(newApps, platforms ? platforms[0] : undefined);
  newApps = filterAppsByType(newApps, types ? types[0] : undefined);

  if (search) {
    newApps = filterAppsBySearchString(newApps, search[0]);
  }

  return newApps;
};

/**
 * Checks if name and version are valid before making a new request to create a new App version
 * @param input: { name: string, version: string}
 * @returns boolean
 */
export const validateNewReleaseValues = (value: { name: string; version: string }): boolean => {
  const regex = {
    name: /^[a-zA-Z0-9-_.\s\u0080-\uFFFF/]+$/,
    version: isSFCMarket()
      ? /^([0-9]{1,9}\.[0-9]{1,9}\.[0-9]{1,9}|\d{1,9}\.\d{1,9}\.\d{1,9}\.\d{1,9})$/
      : /^[0-9]+\.[0-9]+\.[0-9]+$/,
  };

  if (value.name.length <= 50 && value.version.length <= 25) {
    return regex.name.test(value.name) && regex.version.test(value.version);
  }
  return false;
};
