import React from "react";

/**
 * empty icon for business table
 * @returns
 */
const EmptyStateBusinessTableIcon = () => {
  return (
    <svg
      width="140"
      height="140"
      viewBox="0 0 140 140"
      xmlnsXlink="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M86.514 99.865h2.628c5.805 0 10.512-4.706 10.512-10.512V34.164c0-5.805-4.707-10.512-10.512-10.512H15.557c-5.806 0-10.512 4.707-10.512 10.512v55.19c0 5.805 4.706 10.511 10.512 10.511h70.957z"
          id="ckgal9k0jb"
        />
        <path
          d="M104.549 23.652c0-.157-.046-.308-.08-.466l-3.074-14.803A10.512 10.512 0 0 0 91.113 0H13.586a10.512 10.512 0 0 0-10.25 8.39L.262 23.166c-.033.164-.06.329-.086.486m0 0a10.407 10.407 0 1 0 20.637 1.722c0 5.805 4.706 10.512 10.512 10.512s10.512-4.707 10.512-10.512c0 5.805 4.707 10.512 10.512 10.512 5.806 0 10.512-4.707 10.512-10.512 0 5.805 4.707 10.512 10.513 10.512 5.805 0 10.512-4.707 10.512-10.512a10.414 10.414 0 1 0 20.663-1.722"
          id="6ion1nddhc"
        />
        <linearGradient x1="-24.296%" y1="33.317%" x2="75.729%" y2="98.192%" id="3khganru0d">
          <stop stop-opacity=".571" offset="0%" />
          <stop stop-opacity="0" offset="100%" />
        </linearGradient>
        <filter x="-3.7%" y="-3.3%" width="107.4%" height="109.2%" filterUnits="objectBoundingBox" id="gm5ctqpe4a">
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0.556640625 0 0 0 0 0.556640625 0 0 0 0 0.556640625 0 0 0 0.23 0"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g transform="translate(17.5 20.052)">
          <use fill="#000" filter="url(#gm5ctqpe4a)" xlinkHref="#ckgal9k0jb" />
          <use fill="#FFF" xlinkHref="#ckgal9k0jb" />
        </g>
        <path
          d="M106.642 119.918c5.805 0 10.512-4.707 10.512-10.513v-1.314c0 5.806-4.707 10.513-10.512 10.513H33.057c-5.806 0-10.512-4.707-10.512-10.513v1.314c0 5.806 4.706 10.513 10.512 10.513h73.585z"
          fill="#000"
          fill-rule="nonzero"
          opacity=".06"
        />
        <g fill-rule="nonzero" transform="translate(17.5 20.052)">
          <use fill="#2FC56D" xlinkHref="#6ion1nddhc" />
          <use fill-opacity=".1" fill="#000" xlinkHref="#6ion1nddhc" />
        </g>
        <path
          d="M38.385 44.966c0-.283-.04-.565-.046-.854a10.512 10.512 0 0 1-15.794 9 10.27 10.27 0 0 1-4.96-8.278A10.216 10.216 0 0 0 27.8 55.86c5.775 0 10.468-4.658 10.512-10.433v-.079l.072-.381zM80.361 44.71a10.512 10.512 0 0 1-20.958 0v.736c0 5.805 4.706 10.512 10.512 10.512s10.512-4.707 10.512-10.512v-.08l-.066-.656zM117.778 52.751a10.512 10.512 0 0 1-16.392-8.64c0 .29 0 .572-.046.861l.046.382v.078a10.512 10.512 0 0 0 16.386 8.64 10.341 10.341 0 0 0 4.395-9.198 10.23 10.23 0 0 1-4.389 7.877z"
          fill="#000"
          fill-rule="nonzero"
          opacity=".06"
        />
        <path
          d="M59.383 44.624v-.519a10.512 10.512 0 0 1-20.998.913l-.072.329v.079c0 5.805 4.706 10.512 10.512 10.512s10.512-4.707 10.512-10.512v-.079l.046-.723zM101.346 45.018a10.512 10.512 0 0 1-20.985-.906v1.32c0 5.806 4.707 10.513 10.513 10.513 5.805 0 10.512-4.707 10.512-10.513v-.078l-.04-.336z"
          fill="#000"
          fill-rule="nonzero"
          opacity=".06"
        />
        <path fill="#F2FCF7" d="M35.685 64.73h68.329v53.957H35.685z" />
        <path
          d="m5.143 32.85 66.26 67.015H89.24c5.806 0 10.512-4.706 10.512-10.512v-65.7H5.143v9.197z"
          fill="url(#3khganru0d)"
          opacity=".15"
          transform="translate(17.5 20.052)"
        />
        <path fill="#2FC56D" fill-rule="nonzero" opacity=".25" d="M104.014 63.415v1.314h-68.33v-1.314z" />
      </g>
    </svg>
  );
};

export default EmptyStateBusinessTableIcon;
