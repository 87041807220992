import React, { useMemo } from "react";
import { ELLIPSIS, ITablePaginationHook } from "./table-pagination.model";

const useTablePagination = ({ totalItems, pageSize, activePage }: ITablePaginationHook) => {
  /**
   * Range generator
   * Generates an array of N numbers from x to y
   * @param start
   * @param end
   */
  const rangeGenerator = (start: number, end: number) => {
    const length = end - start + 1;
    return Array.from({ length }, (x, idx) => idx + start);
  };

  // Returns an array of pagination items that should be displayed on the UI component
  return useMemo(() => {
    const SIBLING_COUNT = 1;
    const TOTAL_PAGES = Math.ceil(totalItems / pageSize);
    // TOTAL_PAGINATION_ITEMS = SIBLING_COUNT + firstPage + lastPage + activePage + 2xELLIPSIS
    const TOTAL_PAGINATION_ITEMS = SIBLING_COUNT + 5;

    /**
     * Path 1:
     * If the number of pages is less than the page numbers we want to show in our
     * paginationComponent, we return the range [1..TOTAL_PAGES]
     */
    if (TOTAL_PAGINATION_ITEMS >= TOTAL_PAGES) {
      return rangeGenerator(1, TOTAL_PAGES);
    }

    // Calculates left and right sibling index and make sure they are within 1 and TOTAL_PAGES
    const leftSiblingIndex = Math.max(activePage - SIBLING_COUNT, 1);
    const rightSiblingIndex = Math.min(activePage + SIBLING_COUNT, TOTAL_PAGES);

    /**
     * We do not show ellipsis when there is just one page number to be inserted between
     * the extremes of siblings and the page limits i.e 1 and TOTAL_PAGES. This way, we are using
     * leftSiblingIndex > 2 and rightSiblingIndex < TOTAL_PAGES - 2
     */
    const shouldShowLeftEllipsis = leftSiblingIndex > 2;
    const shouldShowRightEllipsis = rightSiblingIndex < TOTAL_PAGES - 2;

    /**
     * Path 2: No left ellipsis to show, but rights ellipsis to be shown
     */
    if (!shouldShowLeftEllipsis && shouldShowRightEllipsis) {
      const leftItemCount = 3 + 2 * SIBLING_COUNT;
      const leftRange = rangeGenerator(1, leftItemCount);
      return [...leftRange, ELLIPSIS, TOTAL_PAGES];
    }

    /**
     * Path 3: No right ellipsis to show, but left ellipsis to be shown
     */
    if (shouldShowLeftEllipsis && !shouldShowRightEllipsis) {
      const rightItemCount = 3 + 2 * SIBLING_COUNT;
      const rightRange = rangeGenerator(TOTAL_PAGES - rightItemCount + 1, TOTAL_PAGES);
      return [1, ELLIPSIS, ...rightRange];
    }

    /**
     * Path 4: Both left and right ellipsis to be shown
     */
    if (shouldShowLeftEllipsis && shouldShowRightEllipsis) {
      const middleRange = rangeGenerator(leftSiblingIndex, rightSiblingIndex);
      return [1, ELLIPSIS, ...middleRange, ELLIPSIS, TOTAL_PAGES];
    }
  }, [totalItems, pageSize, activePage]);
};

export default useTablePagination;
