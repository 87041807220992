import React, { useState } from "react";
import { IBusiness } from "../../../shared/models/business.model";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { CancelIcon } from "@wit/mpesa-ui-components";
import { Column, Row } from "../../../shared/shared.styled";
import { useSelector } from "react-redux";
import { IStoreInterface } from "../../../configs/store.config";
import { BusinessStatusEnum, getStatusColor } from "./business-utils";
import { ICategory } from "../../../shared/models/category.model";
import useCheckFeatureAvailable from "../../../shared/hooks/use-check-available-feature";
import { FEATURES } from "../../../shared/renderer.utils";

interface ILastVersionModalProps {
  onCancelClick: () => void;
}

/**
 *Last Version Modal Component
 * @param onCancelClick
 * @returns  {JSX.Element}
 */
const LastVersionModal = ({ onCancelClick }: ILastVersionModalProps) => {
  const [t] = useTranslation();

  const { categories, businesses } = useSelector((state: IStoreInterface) => state.businessesReducer);
  const [tabIndex, setTabIndex] = useState(0);

  const showCategories = useCheckFeatureAvailable(FEATURES.BUSINESS_CATEGORIES);

  const getCategoryName = (id: string) => {
    return categories.data.find(category => category.id === id) || { name: { en: "" } };
  };

  /**
   * Business Item component
   * @param item
   * @returns  {JSX.Element}
   */
  const renderIBusinessItem = (item: IBusiness) => {
    return (
      <ItemColumn disabled={item.status === BusinessStatusEnum.DELETE}>
        <Row>
          <ImageContainer>
            <img
              style={{ height: 20 }}
              src={`./imagesuploaded/${item.urlIcon}`}
              alt={item.name}
              onError={e => ((e.target as HTMLImageElement).src = "./images/organization.png")}
            />
          </ImageContainer>
          <ItemInfo>
            {item.shortCode && (
              <Row>
                {t("pages.configurations.businessTab.publish.shortCode")}: {item.shortCode}
              </Row>
            )}
            {item.name && (
              <Row>
                {t("pages.configurations.businessTab.publish.businessName")}: {item.name}
              </Row>
            )}
            {item.amount && (
              <Row>
                {t("pages.configurations.businessTab.publish.amount")}: {item.amount}
              </Row>
            )}
            {showCategories && getCategoryName(item.categoryId).name.en && (
              <Row>
                {t("pages.configurations.businessTab.publish.category")}: {getCategoryName(item.categoryId).name.en}
              </Row>
            )}
          </ItemInfo>
          <StatusContainer>
            {item.status === BusinessStatusEnum.LIVE && (
              <StatusRow color={getStatusColor(BusinessStatusEnum.LIVE)}>
                {t("pages.configurations.businessTab.status.live")}
              </StatusRow>
            )}
            {item.status === BusinessStatusEnum.DELETE && (
              <StatusRow color={getStatusColor(BusinessStatusEnum.DELETE)}>
                {t("pages.configurations.businessTab.status.delete")}
              </StatusRow>
            )}
            {item.status === BusinessStatusEnum.DISABLE && (
              <StatusRow color={getStatusColor(BusinessStatusEnum.DISABLE)}>
                {t("pages.configurations.businessTab.status.deactivate")}
              </StatusRow>
            )}
            {item.status === BusinessStatusEnum.DISABLED && (
              <StatusRow color={getStatusColor(BusinessStatusEnum.DISABLED)}>
                {t("pages.configurations.businessTab.status.deactivated")}
              </StatusRow>
            )}
            {item.popular && (
              <Row style={{ color: "#00b0ca", justifyContent: "flex-end" }}>
                {t("pages.configurations.businessTab.popular")}
              </Row>
            )}
          </StatusContainer>
        </Row>
      </ItemColumn>
    );
  };

  /**
   * Category Item component
   * @param item
   * @returns  {JSX.Element}
   */
  const renderCategoryItem = (item: ICategory) => (
    <ItemColumn disabled={item.status === BusinessStatusEnum.DELETE}>
      <Row>
        <ImageContainerCategory>
          <img
            style={{ height: 20 }}
            alt={item.urlIcon}
            src={`./images/categoryIcons/${item.urlIcon}`}
            onError={e => ((e.target as HTMLImageElement).src = "./images/organization.png")}
          />
        </ImageContainerCategory>
        <ItemInfo>
          {item.name.en && (
            <Row>
              {t("pages.configurations.businessTab.lastVersion.categoryName")} - Eng: {item.name.en}
            </Row>
          )}
          {item.name.sw && (
            <Row>
              {t("pages.configurations.businessTab.lastVersion.categoryName")} - Swa: {item.name.sw}
            </Row>
          )}
          {showCategories && item.parentCategory && (
            <Row>
              {t("pages.configurations.businessTab.lastVersion.parentCategory")}:{" "}
              {getCategoryName(item.parentCategory).name.en}
            </Row>
          )}
        </ItemInfo>
        <StatusContainer>
          {item.status === BusinessStatusEnum.LIVE && (
            <StatusRow color={getStatusColor(BusinessStatusEnum.LIVE)}>
              {t("pages.configurations.businessTab.status.live")}
            </StatusRow>
          )}
          {item.status === BusinessStatusEnum.DELETE && (
            <StatusRow color={getStatusColor(BusinessStatusEnum.DELETE)}>
              {t("pages.configurations.businessTab.status.delete")}
            </StatusRow>
          )}
          {item.status === BusinessStatusEnum.DISABLE && (
            <StatusRow color={getStatusColor(BusinessStatusEnum.DISABLE)}>
              {t("pages.configurations.businessTab.status.deactivate")}
            </StatusRow>
          )}
          {item.status === BusinessStatusEnum.DISABLED && (
            <StatusRow color={getStatusColor(BusinessStatusEnum.DISABLED)}>
              {t("pages.configurations.businessTab.status.deactivated")}
            </StatusRow>
          )}
        </StatusContainer>
      </Row>
    </ItemColumn>
  );
  return (
    <ModalContainer>
      <Header>
        <ModalTitle>{t("pages.configurations.businessTab.lastVersion.title")}</ModalTitle>
        <RightContainer>
          {showCategories && (
            <>
              <SelectList selected={tabIndex === 0} onClick={() => setTabIndex(0)}>
                {t("pages.configurations.businessTab.lastVersion.businesses")}
              </SelectList>
              <SelectList selected={tabIndex === 1} onClick={() => setTabIndex(1)}>
                {t("pages.configurations.businessTab.lastVersion.categories")}
              </SelectList>
            </>
          )}
          <CloseIconContainer onClick={onCancelClick} id="close-last-version-modal">
            <CancelIcon color={"grey"} />
          </CloseIconContainer>
        </RightContainer>
      </Header>
      {tabIndex === 0 && (
        <ItemsContainer>
          {businesses.lastVersion &&
            businesses.lastVersion.map(lastVersionItem => {
              return (
                <Item key={lastVersionItem.id}>
                  {lastVersionItem ? renderIBusinessItem(lastVersionItem) : <ItemColumn></ItemColumn>}
                </Item>
              );
            })}
        </ItemsContainer>
      )}
      {showCategories && tabIndex === 1 && (
        <ItemsContainer>
          {categories &&
            categories.lastVersion.map((lastVersionItem: ICategory) => {
              return (
                <Item key={lastVersionItem.id}>
                  {lastVersionItem ? renderCategoryItem(lastVersionItem) : <ItemColumn></ItemColumn>}
                </Item>
              );
            })}
        </ItemsContainer>
      )}
    </ModalContainer>
  );
};

export default LastVersionModal;

const ModalContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 24px;
  font-family: Vodafone Rg;
`;

const ModalTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 21px;
`;

const ItemsContainer = styled.div`
  max-height: 250px;
  overflow: scroll;
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${props => props.theme.palette.darkGrey};
  overflow-x: hidden;
`;

const Item = styled(Row)`
  display: flex;
  /* justify-content: space-between; */
`;

const ImageContainer = styled(Column)`
  margin-right: 24px;
  display: flex;
  align-self: baseline;
  flex: 1;
  padding: 4px;
`;

const ImageContainerCategory = styled(ImageContainer)`
  background-color: #ebf7fa;
`;

const ItemColumn = styled(Column)<{ disabled?: Boolean }>`
  flex: 1;
  padding: 16px 0px 16px 0;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  border-bottom: 1px solid #d8d8d8;
  margin-right: 34px;
`;

const ItemInfo = styled(Column)`
  flex: 20;
`;
const StatusContainer = styled(Column)`
  padding-left: 24px;
  display: flex;
  align-self: baseline;
  flex: 1;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CloseIconContainer = styled("div")`
  left: 23px;
  margin-top: -10px;
  stroke: ${props => props.theme.palette.midGrey};
  cursor: pointer;
  svg {
    width: 24px;
  }
`;

const RightContainer = styled.div`
  display: flex;
`;

const SelectList = styled.div<{ selected: Boolean }>`
  height: fit-content;
  padding-bottom: 9px;
  color: ${({ selected, theme }) => (selected ? theme.palette.digitalRed : "#999999")};
  margin-right: 16px;
  cursor: pointer;
  border-bottom: 1px solid;
  border-bottom-color: ${({ selected, theme }) => (selected ? theme.palette.digitalRed : "transparent")};
`;

const StatusRow = styled(Row)<{ color: string }>`
  color: ${props => props.color};
  display: flex;
  justify-content: flex-end;
`;
