import { produce } from "immer";
import { IPayloadAction } from "../../../shared/shared.interfaces";
import { IDynamicScreens, IDynamicScreen } from "./dynamic-screens.model";

export const DynamicScreensActions = {
  types: {
    GET_DYNAMIC_SCREENS_SUCCESS: "GET_DYNAMIC_SCREENS_SUCCESS",
  },
  creators: {
    getDynamicScreensSuccess: (dynamicScreens: IDynamicScreens) => ({
      type: DynamicScreensActions.types.GET_DYNAMIC_SCREENS_SUCCESS,
      payload: {
        dynamicScreens,
      },
    }),
  },
};
export interface IDynamicScreensReducerInterface {
  dynamicScreens: IDynamicScreens;
}

const initialState: IDynamicScreensReducerInterface = {
  dynamicScreens: {} as IDynamicScreens,
};

export const dynamicScreensReducer = produce((draft: IDynamicScreensReducerInterface, action: IPayloadAction<any>) => {
  switch (action.type) {
    case DynamicScreensActions.types.GET_DYNAMIC_SCREENS_SUCCESS:
      draft.dynamicScreens = action.payload.dynamicScreens;
      draft.dynamicScreens.items = action.payload.dynamicScreens.items.sort((a: IDynamicScreen, b: IDynamicScreen) =>
        a.name.translations.en ? a.name.translations.en.localeCompare(b.name.translations.en) : -1,
      );
      return;
    default:
      return draft;
  }
}, initialState);
