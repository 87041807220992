import React from "react";
import i18next from "i18next";
import { ColumnProps } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import { getReportStatusColor } from "../../../shared/shared.utils";
import { IReport } from "./request-money.interfaces";
import moment from "moment";

export const RequestMoneyUtils = {
  getReportedUsersColumns: (): ColumnProps[] => {
    return [
      {
        formKey: "reportID",
        ratio: 2 / 12,
        isEditable: false,
        label: i18next.t("pages.tools.requestMoney.reportedUsers.table.headers.reportID"),
      },
      {
        formKey: "reportedNumber",
        ratio: 2 / 12,
        isEditable: false,
        label: i18next.t("pages.tools.requestMoney.reportedUsers.table.headers.reportedNumber"),
      },
      {
        formKey: "reportedBy",
        ratio: 2 / 12,
        isEditable: false,
        label: i18next.t("pages.tools.requestMoney.reportedUsers.table.headers.reportedBy"),
      },
      {
        formKey: "reason",
        ratio: 2 / 12,
        isEditable: false,
        label: i18next.t("pages.tools.requestMoney.reportedUsers.table.headers.reason"),
        changeFunction: (content, rowData: IReport) => (
          <span>
            {content === "other"
              ? rowData.reasonMessage
              : i18next.t(`pages.tools.requestMoney.reportedUsers.filters.reason.${content}`)}
          </span>
        ),
      },
      {
        formKey: "reportingDate",
        ratio: 2 / 12,
        isEditable: false,
        label: i18next.t("pages.tools.requestMoney.reportedUsers.table.headers.reportingDate"),
      },
      {
        formKey: "updatedOn",
        ratio: 2 / 12,
        isEditable: false,
        label: i18next.t("pages.tools.requestMoney.reportedUsers.table.headers.updatedOn"),
      },
      {
        formKey: "status",
        ratio: 2 / 12,
        isEditable: false,
        label: i18next.t("pages.tools.requestMoney.reportedUsers.table.headers.status"),
        changeFunction: (content, rowData: IReport) => (
          <span style={{ color: getReportStatusColor(rowData.status) }}>
            {i18next.t(`pages.tools.requestMoney.reportedUsers.filters.status.${content}`)}
          </span>
        ),
      },
    ];
  },

  getRelatedReportsColumns: (): ColumnProps[] => {
    return [
      {
        formKey: "reportID",
        ratio: 3 / 12,
        isEditable: false,
        label: i18next.t("pages.tools.requestMoney.reportedUsers.reportDetail.relatedReports.table.headers.reportID"),
      },
      {
        formKey: "reportingDate",
        ratio: 3 / 12,
        isEditable: false,
        label: i18next.t(
          "pages.tools.requestMoney.reportedUsers.reportDetail.relatedReports.table.headers.reportingDate",
        ),
      },
      {
        formKey: "reason",
        ratio: 3 / 12,
        isEditable: false,
        label: i18next.t("pages.tools.requestMoney.reportedUsers.reportDetail.relatedReports.table.headers.reason"),
        changeFunction: (content, rowData: IReport) => (
          <span>
            {content === "other"
              ? rowData.reasonMessage
              : i18next.t(`pages.tools.requestMoney.reportedUsers.filters.reason.${content}`)}
          </span>
        ),
      },
      {
        formKey: "status",
        ratio: 3 / 12,
        isEditable: false,
        label: i18next.t("pages.tools.requestMoney.reportedUsers.reportDetail.relatedReports.table.headers.status"),
        changeFunction: (content, rowData: any) => (
          <span style={{ color: getReportStatusColor(rowData.status) }}>
            {i18next.t(`pages.tools.requestMoney.reportedUsers.filters.status.${content}`)}
          </span>
        ),
      },
    ];
  },

  getBlacklistColumns: (): ColumnProps[] => {
    return [
      {
        formKey: "msisdn",
        ratio: 5 / 12,
        isEditable: false,
        label: i18next.t("pages.tools.requestMoney.blacklist.table.headers.phoneNumber"),
      },
      {
        formKey: "blockedOn",
        ratio: 5 / 12,
        isEditable: false,
        label: i18next.t("pages.tools.requestMoney.blacklist.table.headers.blockedOn"),
        changeFunction: (content, rowData: any) => <span>{moment(content).format("DD-MM-YYYY")}</span>,
      },
    ];
  },
};

export function onEnterPressed(event: React.KeyboardEvent<HTMLInputElement>, fn: { (): void; (): void }) {
  if (event.keyCode === 13) {
    fn();
  }
}
