import { AutoClosingDropdown, PrimaryButton, TextInput, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikHelpers, yupToFormErrors } from "formik";
import { FormikErrors } from "formik/dist/types";
import i18next from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { object, string } from "yup";
import { Column, RatioContainer, Row } from "../../../../../shared/shared.styled";
import BundlesApi from "../../bundles.api";
import {
  IAddMiddlewareSubBundleRequest,
  IBundleType,
  IConfigurationFields,
  BundleTypeProvider,
  BundleSubTypeProvider,
} from "../../bundles.model";
import GenericLanguageSelector from "../../../../../shared/components/generic-language-selector.component";

interface IAddMiddlewareSubBundleModalInterface {
  bundle: IBundleType;
  configurationFields: IConfigurationFields;
  dismissModal: () => void;
  reloadSubBundles: () => void;
}

/**
 * Add Middleware Sub Bundle
 * @param param0
 */
const AddMiddlewareSubBundleModal = ({
  bundle,
  configurationFields,
  dismissModal,
  reloadSubBundles,
}: IAddMiddlewareSubBundleModalInterface) => {
  const [t] = useTranslation();
  const availableLanguages = Object.keys(bundle.name.translations).sort();
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    availableLanguages.includes("en") ? "en" : availableLanguages[0],
  );
  const [formValues, setFormValues] = useState<IAddMiddlewareSubBundleRequest>();
  const [showAlert, , setAlertProps] = useAlert();

  React.useEffect(() => {
    const trans: { [x: string]: string } = {};
    availableLanguages.forEach(lang => {
      trans[lang] = "";
    });
    setFormValues({
      name: { translations: trans },
      providerData: {
        interactiveChannel: "",
        interactionPoint: "",
      },
      recipientType: "SELF",
    });
  }, []);

  /**
   * Validate translations
   * @param values
   * @param errors
   */
  const validateTranslations = (values: IAddMiddlewareSubBundleRequest, errors: any) => {
    if (errors && errors.name) {
      setAlertProps({
        type: AlertTypeEnum.ERROR,
        title: t("pages.bundleDetails.addMiddlewareSubBundleModal.fillTranslationsError"),
      });
      showAlert();
      if (Object.keys(errors.name.translations)[0] && Object.keys(errors.name.translations)[0] !== selectedLanguage) {
        setSelectedLanguage(Object.keys(errors.name.translations)[0]);
      }
    }
  };

  /**
   * Form submit
   * @param values
   * @param actions
   */
  const addSubBundle = (
    values: IAddMiddlewareSubBundleRequest,
    actions: FormikHelpers<IAddMiddlewareSubBundleRequest>,
  ) => {
    actions.setSubmitting(true);
    values.providerData.type =
      bundle.provider === BundleTypeProvider.MIDDLEWARE ? BundleSubTypeProvider.MIDDLEWARE : BundleSubTypeProvider.J4U;
    BundlesApi.methods
      .addSubBundle(values, bundle.id)
      .then(
        () => {
          setAlertProps({
            type: AlertTypeEnum.SUCCESS,
            title: t("pages.bundleDetails.addMiddlewareSubBundleModal.addSubBundleSuccess"),
          });
          showAlert();
          reloadSubBundles();
          dismissModal();
        },
        err => {
          setAlertProps({
            type: AlertTypeEnum.ERROR,
            title:
              err && err.data && err.data.status && err.data.status.message
                ? err.data.status.message
                : t("pages.bundleDetails.addMiddlewareSubBundleModal.addSubBundleError"),
          });
          showAlert();
        },
      )
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  /**
   * Validator helper for the sms, calls and data input
   * @param property - when property to enable or disable the required validation
   */
  const getTranslationValidators = (property: string) => {
    let validators: { [x: string]: any } = {};
    availableLanguages.map((lang: string) => {
      validators = {
        ...validators,
        [lang]: string()
          .when(property, {
            is: true,
            then: string().required(i18next.t("pages.addBundle.translationRequired")),
          })
          .max(255, i18next.t("pages.addBundle.maxLenDescription")),
      };
    });
    return validators;
  };

  /**
   * Form validation schema
   */
  const schema = object().shape({
    providerData: object().shape({
      interactiveChannel: string()
        .required(i18next.t("pages.addBundle.requiredField"))
        .max(255, i18next.t("pages.addBundle.maxLenDescription")),
      interactionPoint: string()
        .required(i18next.t("pages.addBundle.requiredField"))
        .max(255, i18next.t("pages.addBundle.maxLenDescription")),
    }),
    name: object().shape({
      translations: object().shape(getTranslationValidators("$isNameRequired")),
    }),
  });

  /**
   * Translation check function
   * @param values
   * @param property
   */
  const needsTranslation = (values: IAddMiddlewareSubBundleRequest, property: "name") => {
    let result = false;
    Object.keys(values[property].translations).map(o => {
      if (!values[property].translations[o]) {
        result = true;
      }
    });
    return result;
  };

  /**
   * Fn to validate the form every stroke
   * @param values
   */
  const validateSchema = (
    values: IAddMiddlewareSubBundleRequest,
  ): Promise<FormikErrors<IAddMiddlewareSubBundleRequest>> => {
    return new Promise<FormikErrors<IAddMiddlewareSubBundleRequest>>(resolve => {
      const isNameRequired = needsTranslation(values, "name");
      schema
        .validate(values, {
          context: { isNameRequired: isNameRequired },
          abortEarly: false,
        })
        .then(() => {
          resolve({});
        })
        .catch(y => {
          resolve(yupToFormErrors(y));
        });
    }).then(r => {
      return r;
    });
  };

  /**
   * Main form inputs
   * */
  const getMainFormInputs = (
    values: IAddMiddlewareSubBundleRequest,
    handleChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined,
    setFieldValue: any,
    errors: any,
  ) => {
    return (
      <>
        <FormContainer>
          <RatioContainer ratio={6 / 12} style={{ marginRight: "12px" }}>
            <TextInput
              name="providerData.interactionPoint"
              title={t("pages.bundleDetails.addMiddlewareSubBundleModal.interactionPoint")}
              placeholder={t("pages.bundleDetails.addMiddlewareSubBundleModal.interactionPointPlaceholder")}
              onChange={handleChange}
              value={values.providerData.interactionPoint || ""}
              error={
                errors.providerData && errors.providerData.interactionPoint ? errors.providerData.interactionPoint : ""
              }
              required
            />
          </RatioContainer>
          <RatioContainer ratio={6 / 12}>
            <TextInput
              name="providerData.interactiveChannel"
              title={t("pages.bundleDetails.addMiddlewareSubBundleModal.interactiveChannel")}
              placeholder={t("pages.bundleDetails.addMiddlewareSubBundleModal.interactiveChannelPlaceholder")}
              onChange={handleChange}
              value={values.providerData.interactiveChannel || ""}
              error={
                errors.providerData && errors.providerData.interactiveChannel
                  ? errors.providerData.interactiveChannel
                  : ""
              }
              required
            />
          </RatioContainer>
        </FormContainer>
      </>
    );
  };

  /**
   * Form translatable inputs
   * */
  const getTranslatableInputs = (
    values: IAddMiddlewareSubBundleRequest,
    handleChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined,
    setFieldValue: any,
    errors: any,
  ) => {
    return (
      <div>
        <Row>
          <div style={{ marginTop: "auto", marginLeft: "auto" }}>
            <GenericLanguageSelector
              selectedLanguage={selectedLanguage}
              availableLanguages={availableLanguages}
              changeSelectedLanguage={(lang: any) => setSelectedLanguage(lang.key)}
            ></GenericLanguageSelector>
          </div>
        </Row>
        {availableLanguages.map(lang => {
          return (
            <TranslationContainer key={`translation-container-${lang}`} show={lang === selectedLanguage}>
              <FormContainer>
                <RatioContainer key={`name${lang}`} ratio={6 / 12} style={{ marginRight: "12px" }}>
                  <TextInput
                    name={`name.translations[${lang}]`}
                    title={t("pages.bundleDetails.addMiddlewareSubBundleModal.name", { language: lang.toUpperCase() })}
                    placeholder={t("pages.bundleDetails.addMiddlewareSubBundleModal.namePlaceholder")}
                    onChange={e => setFieldValue(`name.translations[${lang}]`, e.target.value)}
                    value={values.name.translations[lang] || ""}
                    error={
                      errors.name && errors.name.translations && errors.name.translations[lang]
                        ? errors.name.translations[lang]
                        : undefined
                    }
                    required
                  />
                </RatioContainer>
                <RatioContainer ratio={6 / 12}>
                  <Column>
                    <LabelRequired>{t("pages.bundleDetails.addMiddlewareSubBundleModal.options")}</LabelRequired>
                    <DropdownContainer id={"options-dropdown"}>
                      <AutoClosingDropdown
                        hasValue={!!(values.recipientType !== "ANY" || "SELF" || "OTHERS")}
                        label={t(`pages.bundleDetails.recipientType.${values.recipientType}`)}
                        options={[
                          {
                            key: "ANY",
                            label: t("pages.bundleDetails.recipientType.ANY"),
                          },
                          {
                            key: "SELF",
                            label: t("pages.bundleDetails.recipientType.SELF"),
                          },
                          {
                            key: "OTHERS",
                            label: t("pages.bundleDetails.recipientType.OTHERS"),
                          },
                        ]}
                        error={errors.recipientType}
                        selectOption={opt => setFieldValue("recipientType", opt.key)}
                      />
                    </DropdownContainer>
                  </Column>
                </RatioContainer>
              </FormContainer>
            </TranslationContainer>
          );
        })}
      </div>
    );
  };

  return (
    <ModalContainer>
      <ModalTitle>{t("pages.bundleDetails.addMiddlewareSubBundleModal.title")}</ModalTitle>
      {formValues ? (
        <Formik
          initialValues={formValues}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={addSubBundle}
          validate={validateSchema}
          render={({ values, handleChange, isSubmitting, handleSubmit, setFieldValue, errors }) => (
            <form onSubmit={handleSubmit}>
              {getTranslatableInputs(values, handleChange, setFieldValue, errors)}
              {getMainFormInputs(values, handleChange, setFieldValue, errors)}
              <ButtonsContainer>
                <div>
                  <PrimaryButton
                    id="cancel-button"
                    type="button"
                    onClick={dismissModal}
                    titleLabel={t("pages.bundleDetails.addMiddlewareSubBundleModal.cancel")}
                  />
                </div>
                <div style={{ width: "156px" }}>
                  <PrimaryButton
                    id="save-changes-button"
                    loading={isSubmitting}
                    redTheme={true}
                    type="submit"
                    onClick={() => validateTranslations(values, errors)}
                    titleLabel={t("pages.bundleDetails.addMiddlewareSubBundleModal.save")}
                    style={{ width: "156px" }}
                  />
                </div>
              </ButtonsContainer>
            </form>
          )}
        />
      ) : null}
    </ModalContainer>
  );
};

export default AddMiddlewareSubBundleModal;

const TranslationContainer = styled.div<{ show: boolean }>`
  display: ${props => (props.show ? "inherit" : "none")};
`;

const ModalContainer = styled("div")`
  border-radius: 6px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  padding: 24px;
`;

const ModalTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 13px;
`;

const FormContainer = styled("div")`
  padding-top: 24px;
  display: flex;
`;

const FormContainerTitle = styled("p")`
  font-family: Vodafone Rg;
  font-size: 18px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 0px;
  margin-top: 34px;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 50px;
  > div {
    width: fit-content;
    :first-of-type {
      margin-right: 12px;
    }
  }
`;

const DropdownContainer = styled("div")`
  button {
    border-radius: 6px;
    border: 1px solid #ebebeb;
  }
`;

const Label = styled("div")`
  font-family: "Vodafone Rg";
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
  margin-bottom: 8px;
  min-height: 21px;
`;

const LabelRequired = styled(Label)`
  ::after {
    content: " *";
    color: ${props => props.theme.palette.errorRed};
  }
`;
