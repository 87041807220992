import React, { useMemo } from "react";
import { PageContainer, PageTitle } from "../../shared/shared.styled";
import { useTranslation } from "react-i18next";
import { TabChild, Tabs } from "@wit/mpesa-ui-components";
import AppLogs from "./components/app-logs/app-logs.tab";
import { RoutesPrivileges } from "../../routes/routes.constants";
import { useSelector } from "react-redux";
import RendererUtils from "../../shared/renderer.utils";
import { ILoggedUser } from "../../features/authentication/authentication.interfaces";
import { IStoreInterface } from "../../configs/store.config";
import ComponentVersionsTab from "./components/component-versions/component-versions.tab";
import GatewayLogsTab from "./components/gateway-logs/gateway-logs.tab";
import EnvironmentPropertiesTab from "./components/environment-properties/environment-properties.tab";
import CommandIdsTab from "./components/command-ids/command-ids.tab";
import MiniAppsLogsTab from "./components/mini-apps-logs/mini-apps-logs.tab";

/**
 * Support page
 * @returns
 */
const SupportPage = () => {
  const [t] = useTranslation();
  const loggedUser = useSelector((state: IStoreInterface) => state.authenticationReducer.loggedUser) as ILoggedUser;

  const getScreenTabs = useMemo(() => {
    const selectableTabs: {
      label: string;
      tabContent: JSX.Element;
    }[] = [];
    const tabsConfig = [
      {
        label: t("pages.support.tabs.gatewayTabTitle"),
        tabContent: <GatewayLogsTab />,
        userRoles: RoutesPrivileges.all,
      },
      {
        label: t("pages.support.tabs.miniAppsTabTitle"),
        tabContent: <MiniAppsLogsTab />,
        userRoles: [...RoutesPrivileges.sbCrud, ...RoutesPrivileges.sbList],
      },
      {
        label: t("pages.support.tabs.appLogs"),
        tabContent: <AppLogs />,
        userRoles: RoutesPrivileges.all,
      },
      {
        label: t("pages.support.tabs.environmentPropertiesTabTitle"),
        tabContent: <EnvironmentPropertiesTab />,
        userRoles: RoutesPrivileges.admin,
      },
      {
        label: t("pages.support.tabs.commandIdsTabTitle"),
        tabContent: <CommandIdsTab />,
        userRoles: RoutesPrivileges.admin,
      },
      {
        label: t("pages.support.tabs.componentVersions"),
        tabContent: <ComponentVersionsTab />,
        userRoles: RoutesPrivileges.all,
      },
    ];

    tabsConfig.forEach(item => {
      if (RendererUtils.checkRolePermission(item.userRoles, loggedUser)) {
        selectableTabs.push({
          label: item.label,
          tabContent: item.tabContent,
        });
      }
    });

    return selectableTabs;
  }, [loggedUser]);

  return (
    <PageContainer>
      <PageTitle style={{ marginBottom: 43 }}>{t("pages.support.title")}</PageTitle>
      <Tabs isResponsive={true}>
        {getScreenTabs.map((t, idx) => (
          <TabChild key={idx} label={t ? t.label : ""}>
            {t && t.tabContent}
          </TabChild>
        ))}
      </Tabs>
    </PageContainer>
  );
};

export default SupportPage;
