import React, { useContext } from "react";
import styled from "styled-components";
import { ConfigContext } from "../../../app.component";
import useCheckFeatureAvailable from "../../../shared/hooks/use-check-available-feature";
import { CLIENTS, FEATURES } from "../../../shared/renderer.utils";
import { AppPlacement, AudienceType, ConsumerAudience, IAnnouncement, IIcon } from "../announcements.model";
import { AnnouncementsUtils } from "../announcements.utils";

interface IHomeScreenDetailInterface {
  valuesAnnouncement: IAnnouncement;
  icon: IIcon;
  tab: number;
  backgroundImage: string;
  audience: AudienceType;
  isCarouselPreview?: boolean;
}

/**
 * component to preview the card of announcement in home screen
 * @param param0 interface with data necessary
 */
const HomeScreenAnnouncementConsumer = ({
  valuesAnnouncement,
  icon,
  tab,
  backgroundImage,
  audience,
  isCarouselPreview,
}: IHomeScreenDetailInterface) => {
  const colorDefault = "#2fc56d";
  const { config } = useContext(ConfigContext);
  const profileTypesEnabled = useCheckFeatureAvailable(FEATURES.GSM_PROFILE);

  /**
   * Renders profile position
   * @returns {string | null}
   */
  const announcementGsmProfilesPosition = () => {
    if (profileTypesEnabled && valuesAnnouncement.appPlacement) {
      if (valuesAnnouncement.appPlacement === AppPlacement.HOMESCREEN) {
        return "55%";
      } else if (valuesAnnouncement.appPlacement === AppPlacement.SAFARICOM_TAB) {
        return "56%";
      }
    }
    return null;
  };

  const announcementPosition = {
    [CLIENTS.SAFARICOM]: audience === ConsumerAudience.CONSUMERAPP_CHILD ? "47%" : isCarouselPreview ? "60%" : "65%",
    [CLIENTS.SAFARICOMET]: announcementGsmProfilesPosition() ?? "45%",
    [CLIENTS.VFGROUP]: "0%",
  };

  /**Check if it is initial render */
  const isInitial = () => {
    if (isCarouselPreview) {
      return (
        !valuesAnnouncement.title &&
        !valuesAnnouncement.smallDescription &&
        !valuesAnnouncement.thumbnailImageCarousel &&
        icon.id === "000"
      );
    }

    return (
      !valuesAnnouncement.title &&
      !valuesAnnouncement.smallDescription &&
      !valuesAnnouncement.thumbnailImage &&
      icon.id === "000"
    );
  };

  /**
   * renders the icon
   * @returns {JSX.Element | null}
   */
  const rendersIcon = (): JSX.Element | null => {
    if (isCarouselPreview && icon.id === "000") {
      return null;
    }

    if (icon.id === "000" || icon.category === null) {
      return (
        <IconDropDownContainer color={valuesAnnouncement.color ? valuesAnnouncement.color : colorDefault} icon={icon} />
      );
    }
    return (
      <IconDefaultBackground color={valuesAnnouncement.color ? valuesAnnouncement.color : colorDefault}>
        <IconDefaultDropDownContainer
          icon={icon}
          color={valuesAnnouncement.color ? valuesAnnouncement.color : colorDefault}
        />
      </IconDefaultBackground>
    );
  };
  return (
    <DivBackground
      initial={isInitial()}
      tab={tab}
      client={config!.client}
      audience={audience}
      appPlacement={valuesAnnouncement.appPlacement}
      id={`container-${config!.client}-${audience}`}
    >
      <AnnouncementDiv
        tab={tab}
        color={valuesAnnouncement.color ? valuesAnnouncement.color : colorDefault}
        backgroundImage={backgroundImage}
        position={announcementPosition[config!.client]}
        showShadow={!!valuesAnnouncement.title || !!valuesAnnouncement.smallDescription}
        id={`content-${config!.client}-${audience}`}
        appPlacement={valuesAnnouncement.appPlacement}
        isCarouselPreview={!!isCarouselPreview}
      >
        {!AnnouncementsUtils.isSafaricomTab(profileTypesEnabled, valuesAnnouncement.appPlacement) && (
          <IconDiv color={valuesAnnouncement.color ? valuesAnnouncement.color : colorDefault}>{rendersIcon()}</IconDiv>
        )}
        <DetailDiv appPlacement={valuesAnnouncement.appPlacement}>
          <TitleDiv appPlacement={valuesAnnouncement.appPlacement}>
            <span>{valuesAnnouncement.title ? valuesAnnouncement.title : ""}</span>
          </TitleDiv>
          <DescriptionDiv appPlacement={valuesAnnouncement.appPlacement}>
            <span>{valuesAnnouncement.smallDescription ? valuesAnnouncement.smallDescription : ""}</span>
          </DescriptionDiv>
        </DetailDiv>
      </AnnouncementDiv>
    </DivBackground>
  );
};

export default HomeScreenAnnouncementConsumer;

const DivBackground = styled("div")<{
  initial: boolean;
  tab: number;
  client: string;
  audience: AudienceType;
  appPlacement: AppPlacement | undefined;
}>`
  background-image: ${props => () => {
    if (props.audience === ConsumerAudience.CONSUMERAPP_CHILD) {
      return `url("./images/screensAppSFC/screenConsumerChild_${props.client}.png")`;
    } else if (props.appPlacement && props.appPlacement === AppPlacement.HOMESCREEN) {
      return `url("./images/screensAppSFC/screenConsumerHomePage.png")`;
    } else if (props.appPlacement && props.appPlacement === AppPlacement.SAFARICOM_TAB) {
      return `url("./images/screensAppSFC/screenConsumerSafaricomTab.png")`;
    } else {
      return `url("./images/screensAppSFC/screenConsumer_${props.client}.png")`;
    }
  }};
  background-size: cover;
  background-color: transparent !important;
  width: 100%;
  height: 100%;
  ${props =>
    props.initial
      ? "webkit-filter: opacity(0.5) drop-shadow(0 0 0 white);filter: opacity(0.5) drop-shadow(0 0 0 white);"
      : null}
`;

const AnnouncementDiv = styled("div")<{
  tab: number;
  backgroundImage: string;
  color: string;
  position: string;
  showShadow: boolean;
  appPlacement: AppPlacement | undefined;
  isCarouselPreview: boolean;
}>`
  position: absolute;
  width: ${props => {
    if (props.appPlacement) {
      if (props.appPlacement === AppPlacement.SAFARICOM_TAB) {
        return "45%";
      } else if (props.appPlacement === AppPlacement.HOMESCREEN) {
        return "83.2px";
      }
    } else if (props.isCarouselPreview) {
      return "180px";
    } else {
      return "45%";
    }
  }};
  margin: auto;
  height: ${props => {
    if (props.appPlacement) {
      if (props.appPlacement === AppPlacement.SAFARICOM_TAB) {
        return "68px";
      } else if (props.appPlacement === AppPlacement.HOMESCREEN) {
        return "124.8px";
      }
    } else if (props.isCarouselPreview) {
      return "90px";
    } else {
      return "85px";
    }
  }};
  top: ${props => props.position};
  left: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: ${props => {
    let backgroundComposition = props.showShadow
      ? `linear-gradient(360deg, rgba(0, 0, 0, 0.6) 0%, rgba(0 0 0/13%) 100%),`
      : `linear-gradient(360deg, rgba(0, 0, 0, 0.1) 0%, rgba(0 0 0/13%) 50%),`;
    backgroundComposition += props.backgroundImage
      ? `url("${props.backgroundImage}")`
      : "url('./images/gallery_filled.png')";
    backgroundComposition += props.isCarouselPreview ? ",#fff" : "";
    return backgroundComposition;
  }};
  background-size: ${props => {
    if (!props.backgroundImage) {
      return "";
    }
    if (props.appPlacement && props.appPlacement === AppPlacement.HOMESCREEN) {
      return "cover";
    }
    if (props.isCarouselPreview) {
      return "100%";
    } else {
      return "100% 80px";
    }
  }};
  background-repeat: no-repeat;
  background-position: center;
`;

const IconDiv = styled("div")<{ color: string }>`
  width: 25px;
  height: 25px;
  margin: 10px 10px 1px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DetailDiv = styled("div")<{
  appPlacement: AppPlacement | undefined;
}>`
  width: 100%;
  padding: 0 5px 0 10px;
  padding-bottom: ${props => (props.appPlacement && props.appPlacement === AppPlacement.HOMESCREEN ? "4px" : "")};
  border-bottom-right-radius: 5.6px;
  border-top-right-radius: 5.6px;
  display: flex;
  flex-direction: column;
  justify-content: ${props =>
    props.appPlacement && props.appPlacement === AppPlacement.HOMESCREEN ? "end" : "center"};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: Vodafone Rg;
  position: relative;
  flex: 2;
`;

const TitleDiv = styled("div")<{
  appPlacement: AppPlacement | undefined;
}>`
  font-family: Vodafone Rg;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: ${props => (props.appPlacement && props.appPlacement === AppPlacement.HOMESCREEN ? "13px" : "12px")};
  color: #fff;
  text-transform: uppercase;
  line-height: ${props => (props.appPlacement && props.appPlacement === AppPlacement.HOMESCREEN ? "15px" : 0.9)};
`;
const DescriptionDiv = styled("div")<{
  appPlacement: AppPlacement | undefined;
}>`
  font-style: normal;
  font-size: ${props => (props.appPlacement && props.appPlacement === AppPlacement.HOMESCREEN ? "13px" : "10px")};
  font-weight: normal;
  color: #fff;
  font-family: Vodafone Rg;
  width: 100%;
  line-height: ${props => (props.appPlacement && props.appPlacement === AppPlacement.HOMESCREEN ? "15px" : 0.9)};
  word-break: break-word;
`;

const IconDropDownContainer = styled("div")<{
  icon: IIcon;
  color: string;
}>`
  background-image: ${props =>
    props.icon && props.icon.base64 && props.id !== "000"
      ? `url("${props.icon.base64}")`
      : "url('./images/gallery_filled_for_icon.png')"};
  background-color: #ecf2f8;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: center;
  width: 25px;
  height: 25px;
`;

const IconDefaultDropDownContainer = styled("div")<{
  icon: IIcon;
  color: string;
}>`
  mask-image: ${props =>
    props.icon && props.icon.base64 && props.id !== "000"
      ? `url("${props.icon.base64}")`
      : "url('./images/gallery_filled_for_icon.png')"};
  background-color: ${props => (props.color && props.color === "#ffffff" ? "#a0a0a0" : "white")};
  mask-repeat: no-repeat;
  mask-size: ${props => (props.icon.category === null ? "100% 100%" : "15px 15px")};
  mask-position: center;
  width: 100%;
  height: 100%;
`;

const IconDefaultBackground = styled("div")<{
  color: string;
}>`
  background-color: ${props => props.color};
  border-radius: 50%;
  width: 25px;
  height: 25px;
`;
