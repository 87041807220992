import { AxiosPromise } from "axios";
import ApiClient from "../../../configs/axios.config";
import {
  IDynamicScreenAddCategory,
  IDynamicScreenCategory,
} from "../../configurations/dynamic-screens/dynamic-screens.model";
import { IIconCategory } from "./general-categories.model";

const GeneralCategoriesApi = {
  routes: {
    categories: "/v1/ipg_services/categories",
    iconsList: "/v1/ipg_services/icons",
    categoryId: "/v1/ipg_services/categories/:category_id",
  },
  methods: {
    getCategories: (): AxiosPromise<any> => {
      return ApiClient.get(GeneralCategoriesApi.routes.categories);
    },
    deleteCategory: (id: string) => {
      return ApiClient.delete(GeneralCategoriesApi.routes.categoryId.replace(":category_id", id));
    },
    createCategory: (payload: IDynamicScreenAddCategory): AxiosPromise<IDynamicScreenCategory> => {
      return ApiClient.post(GeneralCategoriesApi.routes.categories, payload);
    },
    editCategory: (payload: IDynamicScreenCategory): AxiosPromise<IDynamicScreenCategory> => {
      return ApiClient.post(GeneralCategoriesApi.routes.categories, payload);
    },
    getIconsList: (): AxiosPromise<IIconCategory[]> => {
      return ApiClient.get(GeneralCategoriesApi.routes.iconsList);
    },
    getIcon: (iconUrl: string): AxiosPromise<IIconCategory> => {
      return ApiClient.get(GeneralCategoriesApi.routes.categories + iconUrl);
    },
  },
};

export default GeneralCategoriesApi;
