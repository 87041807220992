import React, { useEffect } from "react";
import { Formik } from "formik";
import styled from "styled-components";
import { IAnnouncement, CallToActionType, AnnouncementFormSteps } from "../announcements.model";
import { AnnouncementsMainContent } from "../announcements.styled";
import { useTranslation } from "react-i18next";
import AnnouncementStorys from "../pages/announcement-storys.page";
import { array, boolean, object, string } from "yup";
import i18next from "i18next";

interface IAnnouncementsStepAnouncementDetailsProps {
  announcement: IAnnouncement;
  setAnnoucement: React.Dispatch<React.SetStateAction<IAnnouncement>>;
  isEditing: boolean;
  setEditing?: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmitForm: (values?: IAnnouncement) => void;
  onReady: React.Dispatch<React.SetStateAction<boolean>>;
  hideTitle?: boolean;
  detailpage?: boolean;
  selectedAnnouncementSavedData?: IAnnouncement;
}

/**
 * Component to insert details to announcement
 */
const AnnouncementsStepAnouncementDetails = ({
  announcement,
  setAnnoucement,
  isEditing,
  setEditing = () => null,
  onSubmitForm,
  onReady,
  hideTitle = false,
  detailpage = false,
  selectedAnnouncementSavedData,
}: IAnnouncementsStepAnouncementDetailsProps) => {
  const [t] = useTranslation();

  /**
   * function to call in onsubmit
   * @param values announcement
   */
  const onSubmitStepThree = (values: IAnnouncement) => {
    setAnnoucement(values);
    onSubmitForm(values);
  };

  /**
   * function to validate information about announcement details
   */
  const validateStepThree = () => {
    const validationStoryObject = {
      backgroundImageUrl: string().nullable(),
      backgroundImage: string().when("backgroundImageUrl", backgroundImageUrl => {
        return backgroundImageUrl ? string().nullable() : string().required(i18next.t("commons.mandatoryField"));
      }),
      title: string()
        .max(20, t("pages.announcements.errors.maxTitleStory"))
        .nullable(),
      colorTitle: string()
        .matches(/^#([0-9A-F]{3}){1,2}$/i, i18next.t("commons.invalidColor"))
        .nullable(),
      description: string()
        .max(40, t("pages.announcements.errors.maxDescriptionStory"))
        .nullable(),
      colorDescription: string()
        .matches(/^#([0-9A-F]{3}){1,2}$/i, i18next.t("commons.invalidColor"))
        .nullable(),
      callAction: boolean(),
      callToAction: object().when("callAction", {
        is: true,
        then: object().shape({
          title: string().required(t("commons.mandatoryField")),
          action: object().shape({
            type: string().required(t("commons.mandatoryField")),
            resource: string().when("type", {
              is: type =>
                type === CallToActionType.LINK ||
                type === CallToActionType.MINIAPP ||
                type === CallToActionType.FEATURE,
              then: string()
                .required(t("commons.mandatoryField"))
                .when("type", {
                  is: CallToActionType.LINK,
                  then: string().matches(
                    /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
                    t("commons.invalidURL"),
                  ),
                }),
            }),
          }),
        }),
      }),
    };

    return object().shape({
      stories: array().of(object().shape(validationStoryObject)),
    });
  };

  /**
   * function to call in onChangeValues
   * @param values announcement
   */
  const onChangeValues = (values: IAnnouncement) => {
    setAnnoucement(values);
    onReady(!!values.stories[0].backgroundImage);
  };

  useEffect(() => {
    onChangeValues(announcement);
  }, [announcement]);

  return (
    <Formik
      initialValues={announcement}
      onSubmit={onSubmitStepThree}
      validationSchema={validateStepThree}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, setValues, setFieldValue, handleSubmit, errors, initialValues }) => (
        <form
          id={AnnouncementFormSteps.STEP_FOUR}
          onSubmit={handleSubmit}
          onChange={() => {
            onChangeValues(values);
          }}
          onReset={() => {
            if (selectedAnnouncementSavedData) {
              setAnnoucement(selectedAnnouncementSavedData);
            } else {
              setAnnoucement(initialValues);
            }
          }}
        >
          <AnnouncementsDetailsTitle hidden={hideTitle}>
            {t("pages.announcements.detailPage.rows.thirdTitle")}
          </AnnouncementsDetailsTitle>
          <AnnouncementsMainContent>
            <AnnouncementStorys
              valuesAnnouncementNotEditing={announcement}
              setAnnoucementNotEditing={setAnnoucement}
              valuesAnnouncement={values}
              setAnnoucement={setValues}
              setValues={setFieldValue}
              errors={errors}
              isEditing={isEditing}
              onChangeValues={onChangeValues}
              detailsPage={detailpage}
              setEditing={setEditing}
              selectedAnnouncementSavedData={selectedAnnouncementSavedData}
            />
          </AnnouncementsMainContent>
        </form>
      )}
    </Formik>
  );
};

const AnnouncementsDetailsTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
  margin-top: 4vh;
`;

export default AnnouncementsStepAnouncementDetails;
