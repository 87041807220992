import React from "react";
import moment from "moment";
import { DateRangeDropdown } from "@wit/mpesa-ui-components";
import { RatioContainer } from "../../../../../shared/shared.styled";

interface IDateFilter {
  filters: Map<string, string[]>;
  updateFilter: (key: string, value: string, replace?: boolean | undefined) => void;
  isFilterActive: (key: string, value: string) => boolean;
  clearFilter: (key: string) => void;
  getFilterValue: (key: string, singleValue?: boolean | undefined) => string | string[];
  minSelectableDate: string;
  updateMultipleFilters: (
    vals: {
      key: string;
      values: any[];
    }[],
  ) => void;
  translations: {
    dropdownPlaceholder: string;
    clearFilters: string;
    CUSTOM: string;
    LAST_3_MONTH: string;
    LAST_6_MONTH: string;
    LAST_MONTH: string;
    LAST_WEEK: string;
    LAST_YEAR: string;
  };
}

export const DateFilter = ({
  clearFilter,
  filters,
  isFilterActive,
  updateFilter,
  updateMultipleFilters,
  minSelectableDate,
  translations,
}: IDateFilter) => {
  return (
    <RatioContainer ratio={4 / 10}>
      <DateRangeDropdown
        clearFilter={clearFilter}
        filters={filters}
        isFilterActive={isFilterActive}
        updateMultipleFilters={updateMultipleFilters}
        updateFilter={updateFilter}
        translations={translations}
        firstEnabledDate={moment(minSelectableDate, "DD/MM/YYYY")}
      />
    </RatioContainer>
  );
};

export default DateFilter;
