import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { RoutesEnum } from "../../../../routes/routes.constants";

/**
 * Add bundle
 */
const AddBundleItem = () => {
  const [t] = useTranslation();
  const history = useHistory();
  return (
    <AddBundleItemContainer onClick={() => history.push(RoutesEnum.ADD_BUNDLE)} id={"add-new-bundle"}>
      <AddBundleItemIconContainer>
        <img src="./images/rounded-plus.svg" alt="add-app button" />
      </AddBundleItemIconContainer>
      <AddBundleItemTitle>{t("pages.bundles.addNewBundle")}</AddBundleItemTitle>
    </AddBundleItemContainer>
  );
};

export default AddBundleItem;

const AddBundleItemContainer = styled("div")`
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  padding-left: 24px;
  margin-bottom: 24px;
  :hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  }
`;

const AddBundleItemIconContainer = styled("div")`
  width: 45px;
  height: 45px;
  margin-bottom: 0px;
  margin-right: 24px;

  img {
    width: 45px;
    height: 45px;
  }
`;

const AddBundleItemTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  text-align: center;
  color: ${props => props.theme.palette.midGrey};
`;
