import React from "react";

/**
 * Renders a React component for the SCF push-notifications icon
 */
function PushNotificationIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 95 95" fill="none">
      <path
        d="M72.646 1026.168a1 1 0 00-.985 1.014v3.77h-3.773a1 1 0 100 2h3.773v3.771a1 1 0 102 0v-3.77h3.762a1 1 0 100-2H73.66v-3.771a1 1 0 00-1.015-1.014z"
        enableBackground="accumulate"
        transform="translate(0 -952.362)"
        stroke="inherit"
        strokeWidth={2.5}
      ></path>
      <path
        d="M48.404 959.362c-3.298 0-6.105 2.464-6.105 5.604v4.775c-10.256 2.741-17.873 12.244-17.873 23.454 0 6.648.519 15.218-.513 23.092-1.03 7.874-3.581 14.93-9.538 18.853a1 1 0 00.55 1.835h24.114c.347 4.56 4.286 8.17 9.23 8.17 4.936 0 8.881-3.616 9.23-8.17h2.714c1.993 4.915 6.822 8.387 12.448 8.387 7.391 0 13.414-6.009 13.414-13.41 0-7.203-5.708-13.089-12.83-13.396-1.495-8.505-.936-18.177-.936-25.36 0-11.187-7.583-20.672-17.8-23.436v-4.794c0-3.14-2.806-5.604-6.105-5.604zm0 2c2.316 0 4.105 1.672 4.105 3.604v4.345a23.526 23.526 0 00-8.21-.011v-4.334c0-1.932 1.79-3.604 4.105-3.604zm-.031 9.583c12.059 0 21.936 9.994 21.936 22.25 0 7-.579 16.656.917 25.425-6.73.717-11.99 6.417-11.99 13.333 0 1.039.119 2.05.343 3.022H17.833c4.96-4.528 7.133-11.324 8.064-18.428 1.066-8.143.53-16.81.53-23.352 0-12.256 9.876-22.25 21.946-22.25zm24.288 49.598c6.311 0 11.414 5.09 11.414 11.41 0 6.319-5.103 11.41-11.414 11.41-6.323 0-11.425-5.091-11.425-11.41 0-6.32 5.102-11.41 11.425-11.41zm-31.615 16.432h14.445c-.337 3.443-3.303 6.17-7.222 6.17-3.932 0-6.888-2.716-7.223-6.17z"
        enableBackground="accumulate"
        transform="translate(0 -952.362)"
        stroke="inherit"
        strokeWidth={2.5}
      ></path>
    </svg>
  );
}

export default PushNotificationIcon;
