import React from "react";
import styled from "styled-components";
import { IService } from "../../../shared/models/service-builder.model";
import { RatioContainer } from "../../../shared/shared.styled";
import { StatusChip } from "@wit/mpesa-ui-components";
import { useTranslation } from "react-i18next";
import { ServiceItemTitle } from "../services.styled";
import { RoutesEnum } from "../../../routes/routes.constants";
import { useHistory } from "react-router-dom";

interface IServiceListItemProps {
  item: IService;
  disabled?: boolean;
}

/**
 * ServiceListItem component
 */
const ServiceListItem = ({ item, disabled }: IServiceListItemProps) => {
  const [t] = useTranslation();
  const history = useHistory();

  return (
    <>
      <ServiceListItemContainer
        id={item.name}
        onClick={() => {
          if (!disabled) {
            history.push(RoutesEnum.SERVICE_DETAILS.replace(":serviceId", item.id));
          }
        }}
      >
        <ServiceImageContainer ratio={0.5 / 12}>
          <ServiceItemImage backgroundImage={item.iconUrl}></ServiceItemImage>
        </ServiceImageContainer>
        <ServiceItemTitle ratio={5 / 12}>
          {item.name}
          <ServiceListHighlighted>{item.highlighted ? String.fromCodePoint(9733) : null}</ServiceListHighlighted>
        </ServiceItemTitle>

        <ServiceListNormalText ratio={5 / 12}>{item.description}</ServiceListNormalText>

        <RatioContainer className="toggable-container" ratio={1.5 / 12}>
          {![undefined, null, ""].includes(item.status) ? (
            <StatusChip type={item.status as any}>{t(`commons.serviceBuilder.enums.status.${item.status}`)}</StatusChip>
          ) : (
            <span>NULL STATUS</span>
          )}
        </RatioContainer>
      </ServiceListItemContainer>
    </>
  );
};

export default ServiceListItem;
const ServiceItemImage = styled("div")<{ backgroundImage: string }>`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: auto;
  margin-right: 20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: solid 1px #ebebeb;
  margin-left: 0;
  background-image: url(${props => props.backgroundImage});
`;
const ServiceListItemContainer = styled("li")`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 75px;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  cursor: pointer;

  margin-bottom: 24px;
  padding: 0 35px 0 24px;

  :hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);

    .toggable-container {
      display: flex;
    }
  }
`;

const ServiceListNormalText = styled(RatioContainer)`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
  padding-right: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ServiceListHighlighted = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.freshOrange};
  margin-left: 8px;
  @media (max-width: 768px) {
    font-size: 18px;
    margin-left: 4px;
  }
`;

const ServiceImageContainer = styled(RatioContainer)``;
