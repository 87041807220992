import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { Breadcrumb } from "@wit/mpesa-ui-components/lib/types";
import { TFunction } from "i18next";
import { RoutesEnum } from "../../../routes/routes.constants";
import { PushNotificationsFiltersEnum } from "./components/push-notifications-filters.component";
import { IPushNotification, PushNotificationStatusEnum } from "./push-notifications.model";

export const getBreadcrumbs = (t: TFunction): Breadcrumb[] => {
  return [
    {
      label: t("pages.tools.title"),
      url: RoutesEnum.TOOLS,
    },
    {
      label: t("pages.pushNotifications.pushNotifications"),
      url: "",
    },
  ];
};

/**
 * Filter an array of push notifications using a search string
 */
export const filterPushNotifications = (
  pushNotifications: IPushNotification[],
  filters: Map<string, string[]>,
): IPushNotification[] => {
  let newPushNotifications: IPushNotification[];
  const status = filters.get(PushNotificationsFiltersEnum.STATUS) as any;
  const search = filters.get(PushNotificationsFiltersEnum.SEARCH);

  newPushNotifications = filterPushNotificationsByStatus(pushNotifications, status ? status[0] : undefined);

  if (search) {
    newPushNotifications = filterPushNotificationsBySearch(newPushNotifications, search[0]);
  }

  return newPushNotifications;
};

export const filterPushNotificationsByStatus = (
  pushNotifications: IPushNotification[],
  status?: string,
): IPushNotification[] => {
  if (status) {
    return pushNotifications.filter(c => c.status === status);
  }
  return pushNotifications;
};

export const filterPushNotificationsBySearch = (pushNotifications: IPushNotification[], searchString: string) => {
  if (!!searchString) {
    return pushNotifications.filter(
      dc =>
        dc.title.toLowerCase().includes(searchString.toLowerCase()) ||
        dc.message.toLowerCase().includes(searchString.toLowerCase()),
    );
  }
  return pushNotifications;
};

export const processPushNotificationBackendStatus = (status: PushNotificationStatusEnum) => {
  switch (status) {
    case PushNotificationStatusEnum.NO_DATA:
    case PushNotificationStatusEnum.EXPIRED:
      return PushNotificationStatusEnum.SENT;
    default:
      return status;
  }
};

export const getStatusColor = (status: PushNotificationStatusEnum) => {
  switch (status) {
    case PushNotificationStatusEnum.SENT:
      return styleTheme.palette.successGreen;
    case PushNotificationStatusEnum.SCHEDULED:
      return styleTheme.palette.midGrey;
  }

  return styleTheme.palette.black;
};
