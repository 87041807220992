export interface IClientDocSubmissionsRequest {
  page?: number;
  pageSize?: number;
  searchString?: string;
  dateRangeIni?: number;
  dateRangeEnd?: number;
  status?: string[];
  sort?: ISortParams | undefined;
  bulkName?: string;
}

export interface ISortParams {
  fieldName?: string;
  directionType?: "ASC" | "DESC";
}

export interface IClientDocSubmissionEntry {
  submissionRequestId: string;
  requestId: string;
  msisdn: string;
  firstName: string;
  lastName: string;
  documentNumber: string;
  creationDate: number;
  agent: {
    agentShortCode: number;
  };
  updateStatusDate?: number;
  status: string;
}

export interface IClientDocSubmissionsResponse {
  totalElements: number;
  registrations: IClientDocSubmissionEntry[];
}

export const ColumnKeysToSortParam = {
  fullName: "fullName",
  phoneNumber: "phoneNumber",
  idCardNumber: "idNumber",
  requestTime: "createdAt",
  agentShortcode: "agentShortcode",
  statusTime: "statusDate",
  status: "status",
};

export const SortDirectionToSortParam = {
  1: "ASC" as "ASC",
  2: "DESC" as "DESC",
};

export interface IClientDocSubmission {
  remoteId: string;
  requestId: string;
  fullName: string;
  phoneNumber: string;
  idCardNumber: string;
  requestTime: number;
  agentShortcode: number;
  statusTime?: number;
  status: string;
}
