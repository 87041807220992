import { Magnifier } from "@wit/mpesa-ui-components";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ReactSortable } from "react-sortablejs";
import styled, { ThemeContext } from "styled-components";
import { IStoreInterface } from "../../../../configs/store.config";
import { ListHeader, Row } from "../../../../shared/shared.styled";
import "../shortcuts.css";
import { IShortcut } from "../shortcuts.model";
import ShortcutItem from "./shortcut-item.component";
import { isEqual } from "lodash";
import ReactTooltip from "react-tooltip";
import LanguageSelectorDropdown from "../../../../shared/components/language-selector-dropdown.component";
import { getAvailableLanguagesOptions } from "../shortcuts.utils";
import { ILanguage } from "../../../../shared/models/language.model";
import { IIconCategory } from "../../categories/general-categories.model";

export interface IShortcutsListInterface {
  shortcuts: IShortcut[];
  setShortcuts: (newState: IShortcut[]) => void;
  onLanguageChange: (language: { label: string; key: string }) => void;
  onSortHandler: (event: any) => void;
  selectedLanguage: { label: string; key: string };
  hasSubmittedVersion?: boolean;
  getShortcuts: () => void;
  getTranslation: (obj: any) => string;
  availableLanguages: ILanguage[] | undefined;
  icons: IIconCategory[];
}

/**
 * Shortcuts list
 * @param param0
 */
const ShortcutsList = ({
  shortcuts,
  setShortcuts,
  onLanguageChange,
  onSortHandler,
  selectedLanguage,
  getShortcuts,
  getTranslation,
  availableLanguages,
  icons,
  hasSubmittedVersion = false,
}: IShortcutsListInterface) => {
  const [t] = useTranslation();
  const shortcutsStore = useSelector((state: IStoreInterface) => state.shortcutsReducer.shortcuts);
  const [dragging, setDragging] = useState(false);
  const theme = useContext(ThemeContext);

  /**
   * Move handler
   * @param event
   */
  const onMoveHandler = (event: any) => {
    if (
      shortcutsStore &&
      shortcutsStore.actionItemResponses &&
      shortcuts &&
      shortcutsStore.actionItemResponses.length === shortcuts.length
    ) {
      return event;
    } else {
    }
  };

  /**
   * list equal
   * @param oldValues
   * @param newValues
   */
  const isListEqual = (oldValues: any[], newValues: any[]) => {
    if (!oldValues.length) {
      return true;
    }

    if (!oldValues[0].chosen) {
      oldValues = oldValues.map(v => {
        return { ...v, chosen: false, selected: false };
      });
    }
    return isEqual(oldValues, newValues);
  };

  return (
    <div style={{ marginTop: "46px" }}>
      <ListHeaders>
        <ListHeader ratio={1 / 12} style={{ marginRight: 0 }}>
          {shortcuts ? (
            <LanguageSelectorDropdown
              availableLanguages={getAvailableLanguagesOptions()}
              selectedLanguage={selectedLanguage}
              changeSelectedLanguage={onLanguageChange}
              color={theme.palette.turquoiseBlue}
              id={"language-selector"}
            ></LanguageSelectorDropdown>
          ) : null}
        </ListHeader>
        <ListHeader ratio={9 / 12} style={{ marginRight: 16 }}>
          <span style={{ marginLeft: "16px" }}>{t("pages.shortcuts.quickAction")}</span>
        </ListHeader>
        <ListHeader ratio={1 / 12} style={{ marginRight: 16 }}>
          {t("pages.shortcuts.status")}
        </ListHeader>
        <ListHeader ratio={1 / 12} style={{ marginRight: 16, textAlign: "right" }}></ListHeader>
      </ListHeaders>
      {shortcuts ? (
        <>
          <ReactSortable
            ghostClass={"sortable-ghost"}
            chosenClass={"sortable-chosen"}
            dragClass={"sortable-drag"}
            list={shortcuts}
            handle={".handle"}
            setList={(newState, sortable, store) => {
              if (!isListEqual(shortcuts, newState)) {
                setShortcuts(newState);
              }
            }}
            onMove={onMoveHandler}
            onSort={onSortHandler}
            onChoose={() => {
              setDragging(true);
            }}
            onUnchoose={() => {
              setDragging(false);
              ReactTooltip.hide();
            }}
          >
            {shortcuts.map(card => {
              return (
                <ShortcutItem
                  key={card.id}
                  shortcut={card}
                  getTranslation={getTranslation}
                  hasFastActions={!hasSubmittedVersion}
                  dragging={dragging}
                  getShortcuts={getShortcuts}
                  availableLanguages={availableLanguages}
                  icons={icons}
                />
              );
            })}
          </ReactSortable>
        </>
      ) : null}
      {shortcuts.length === 0 ? (
        <MagnifierContainer>
          <Magnifier />
          <NoResultsFoundText>{t("pages.shortcuts.noSearchResults")}</NoResultsFoundText>
        </MagnifierContainer>
      ) : null}
    </div>
  );
};

const ListHeaders = styled(Row)`
  padding: 0px;
  margin-bottom: 15px;
`;

const MagnifierContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 50px;
  svg {
    width: 140px;
    height: 140px;
    margin-bottom: 24px;
    stroke: red;
  }
`;

const NoResultsFoundText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.midGrey};
`;

export default ShortcutsList;
