import { AxiosPromise } from "axios";
import ApiClient from "../../configs/axios.config";
import { IEnvProperty } from "../../shared/models/env-property.model";
import { IComponentVersion } from "./components/component-versions/component-versions.utils";
import {
  IAppReportLogsRequest,
  IGetAppsLogsResponse,
  IGetGatewayLogsResponse,
  IGetMiniAppsLogsResponse,
  IGwReportLogsRequest,
  IMiniAppsReportLogsRequest,
} from "./support.interfaces";
import { ICommandId } from "../../shared/models/command-id.model";
import SBApiClient from "../../configs/axios.service-builder.config";

const SupportApi = {
  routes: {
    commandIds: "v1/commmandids",
    properties: "/properties",
    gatewayLogs: "v1/logs/gateway",
    reportLog: "v1/logs/apps",
    versions: "versions",
    miniAppsLogs: "reportLog/",
  },
  methods: {
    getCommandIds: (): AxiosPromise<{ commandIds: Array<{ key: string; value: string }> }> => {
      return ApiClient.get(SupportApi.routes.commandIds);
    },
    updateCommandId: (commandId: ICommandId): AxiosPromise<void> => {
      return ApiClient.put(SupportApi.routes.commandIds, {
        key: commandId.commandName,
        value: commandId.commandValue,
      });
    },

    getProperties: (): AxiosPromise<{ properties: IEnvProperty[] }> => {
      return ApiClient.get(SupportApi.routes.properties);
    },
    addProperty: (property: IEnvProperty): AxiosPromise<void> => {
      return ApiClient.post(SupportApi.routes.properties, property);
    },
    updateProperty: (property: IEnvProperty): AxiosPromise<void> => {
      return ApiClient.put(SupportApi.routes.properties, property);
    },
    deleteProperty: (property: IEnvProperty): AxiosPromise<void> => {
      return ApiClient.delete(`${SupportApi.routes.properties}/${property.propertyName}`);
    },

    getGatewayLogs: (params: IGwReportLogsRequest): AxiosPromise<IGetGatewayLogsResponse> => {
      return ApiClient.get(SupportApi.routes.gatewayLogs, {
        params: params,
      });
    },
    getAppLogs: (params: IAppReportLogsRequest): AxiosPromise<IGetAppsLogsResponse> => {
      return ApiClient.get(SupportApi.routes.reportLog, {
        params: params,
      });
    },
    getVersions: (): AxiosPromise<IComponentVersion[]> => {
      return ApiClient.get(SupportApi.routes.versions);
    },
    getMiniAppsLogs: (params: IMiniAppsReportLogsRequest): AxiosPromise<IGetMiniAppsLogsResponse> => {
      return SBApiClient.get(SupportApi.routes.miniAppsLogs, {
        params: params,
      });
    },
  },
};

export default SupportApi;
