import { IServiceManagerServiceDetails } from "../../../shared/models/service-manager.model";
import { AxiosPromise } from "axios";
import ApiClient from "../../../configs/axios.config";
import { IUpdateServiceRequest } from "./service-manager-service-details.model";
import { ServiceType } from "./pages/details.interfaces";
import { UserType } from "../section-manager/section-manager.interface";

const ServiceManagerServiceDetailsApi = {
  routes: {
    serviceDetails: "/services/:serviceId",
    updateServiceDetails: "/services",
    removeGroup: "/services/remove-group",
    miniAppsRoles: "/services/roles",
  },
  methods: {
    getServiceManagerServiceDetails: (serviceId: string): AxiosPromise<IServiceManagerServiceDetails> => {
      const url = `${ServiceManagerServiceDetailsApi.routes.serviceDetails.replace(
        ":serviceId",
        serviceId,
      )}?includeGroups=true`;
      return ApiClient.get(url);
    },
    updateServiceManagerServiceDetails: (
      serviceId: string,
      imageService: File,
      iconService: File,
      values: IUpdateServiceRequest,
    ): AxiosPromise<void> => {
      const data = new FormData();
      data.append("metadata", JSON.stringify(values));

      if (imageService) {
        data.append("imageService", imageService);
      }
      if (iconService) {
        data.append("iconService", iconService);
      }
      return ApiClient.post(`${ServiceManagerServiceDetailsApi.routes.updateServiceDetails}/${serviceId}`, data);
    },
    updateServiceManagerServiceStatus: (serviceId: string): AxiosPromise<void> => {
      return ApiClient.patch(`${ServiceManagerServiceDetailsApi.routes.updateServiceDetails}/${serviceId}/status`);
    },
    updateServiceManagerServiceAddGroup: (
      serviceId: string,
      groupName: string,
      serviceType: ServiceType,
      userType?: UserType,
      promotedOrder?: number,
    ): AxiosPromise<void> => {
      if (promotedOrder) {
        return ApiClient.put(
          `${
            ServiceManagerServiceDetailsApi.routes.updateServiceDetails
          }?serviceIdentifier=${serviceId}&groupName=${groupName}&serviceType=${serviceType}&toPosition=1&promotedPosition=${promotedOrder}${
            userType ? `&userType=${userType}` : ""
          }`,
        );
      } else {
        return ApiClient.put(
          `${
            ServiceManagerServiceDetailsApi.routes.updateServiceDetails
          }?serviceIdentifier=${serviceId}&groupName=${groupName}&serviceType=${serviceType}&toPosition=1${
            userType ? `&userType=${userType}` : ""
          }`,
        );
      }
    },
    updateServiceManagerServiceRemoveGroup: (
      serviceId: string,
      groupName: string,
      serviceType?: ServiceType,
      userType?: UserType,
    ): AxiosPromise<void> => {
      const objectParam = userType
        ? {
            serviceId,
            groupName,
            userType,
          }
        : {
            serviceId,
            groupName,
            serviceType,
          };
      return ApiClient.post(`${ServiceManagerServiceDetailsApi.routes.removeGroup}`, objectParam);
    },
    getMiniAppsRole: (userType: UserType): AxiosPromise<any> => {
      return ApiClient.get(`${ServiceManagerServiceDetailsApi.routes.miniAppsRoles}?userType=${userType}`);
    },
    deleteServiceManagerServiceDetails: (serviceId: string): AxiosPromise<any> => {
      return ApiClient.delete(ServiceManagerServiceDetailsApi.routes.serviceDetails.replace(":serviceId", serviceId));
    },
  },
};

export default ServiceManagerServiceDetailsApi;
