import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface IAddDynamicScreenItemProps {
  addNewDynamicScreenFn: () => void;
}
const AddDynamicScreenItem = ({ addNewDynamicScreenFn }: IAddDynamicScreenItemProps) => {
  const [t] = useTranslation();
  return (
    <AddDynamicScreenItemContainer onClick={addNewDynamicScreenFn} id={"add-new-service"}>
      <AddDynamicScreenItemIconContainer>
        <img src="./images/rounded-plus.svg" alt="Add dynamic screen button" />
      </AddDynamicScreenItemIconContainer>
      <AddDynamicScreenItemTitle>{t("pages.dynamicScreens.addNewService")}</AddDynamicScreenItemTitle>
    </AddDynamicScreenItemContainer>
  );
};

export default AddDynamicScreenItem;

const AddDynamicScreenItemContainer = styled("div")`
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  padding-left: 24px;
  margin-bottom: 24px;
  :hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  }
`;

const AddDynamicScreenItemIconContainer = styled("div")`
  width: 45px;
  height: 45px;
  margin-bottom: 0;
  margin-right: 24px;

  img {
    width: 45px;
    height: 45px;
  }
`;

const AddDynamicScreenItemTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  text-align: center;
  color: ${props => props.theme.palette.midGrey};
`;
