import { ModalTypeEnum, PlusIcon, SearchBar, SmallButton, useModal } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { FilterFuncts } from "@wit/mpesa-ui-components/lib/hooks/use-filters.hook";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { SearchBarFilterContainer } from "../../../../../shared/responsive-ui.styled";
import { RatioContainer, Row } from "../../../../../shared/shared.styled";
import { IBundleType, IConfigurationFields } from "../../bundles.model";
import AddMiddlewareSubBundleModal from "./add-middleware-sub-bundle.modal";

interface IMiddlewareSubBundlesListFiltersInterface extends FilterFuncts {
  configurationFields: IConfigurationFields;
  bundle: IBundleType;
  reloadSubBundles: () => void;
}
export enum MiddlewareSubBundlesFiltersEnum {
  SEARCH = "search",
}

/**
 * Sub bundle list filters
 * @param param0
 */
const MiddlewareSubBundlesListFilters = ({
  filters,
  clearFilter,
  isFilterActive,
  resetFilters,
  updateFilter,
  configurationFields,
  bundle,
  reloadSubBundles,
}: IMiddlewareSubBundlesListFiltersInterface) => {
  const [t] = useTranslation();

  /**
   * Add sub-bundle modal
   * */
  const _hideAddMiddlewareSubBundleModal = () => {
    hideAddMiddlewareSubBundleModal();
  };
  const [showAddMiddlewareSubBundleModal, hideAddMiddlewareSubBundleModal, addMiddlewareSubBundleModalProps] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <AddMiddlewareSubBundleModal
      bundle={bundle}
      configurationFields={configurationFields}
      dismissModal={_hideAddMiddlewareSubBundleModal}
      reloadSubBundles={reloadSubBundles}
    />,
  );
  React.useEffect(() => {
    addMiddlewareSubBundleModalProps({ modalStyles: { width: 825, padding: 0 } });
  }, [addMiddlewareSubBundleModalProps]);

  /**
   * Opens the new sub bundle modal
   * */
  const openAddNewMiddlewareSubBundleModal = () => {
    showAddMiddlewareSubBundleModal();
  };

  return (
    <SubBundlesFiltersContainer>
      <SearchBarFilterContainer>
        <SearchBar
          placeholderLabel={t("pages.bundleDetails.searchMiddlewarePlaceholder")}
          value={
            filters.has(MiddlewareSubBundlesFiltersEnum.SEARCH)
              ? (filters.get(MiddlewareSubBundlesFiltersEnum.SEARCH) as string[])[0]
              : ""
          }
          onChange={e => updateFilter(MiddlewareSubBundlesFiltersEnum.SEARCH, e.target.value, true)}
          clearValue={() => clearFilter(MiddlewareSubBundlesFiltersEnum.SEARCH)}
        />
      </SearchBarFilterContainer>

      <div id="add-new-sub-bundle" style={{ marginLeft: "auto" }}>
        <SmallButton
          titleLabel={t("pages.bundleDetails.addNewSubBundle")}
          onClick={openAddNewMiddlewareSubBundleModal}
          iconComponent={
            <SmallButtonIconContainer color={styleTheme.palette.vodafoneRed}>
              <PlusIcon />
            </SmallButtonIconContainer>
          }
        />
      </div>
    </SubBundlesFiltersContainer>
  );
};

export default MiddlewareSubBundlesListFilters;

const SubBundlesFiltersContainer = styled(Row)`
  margin-bottom: 49px;
  margin-top: 41px;
  flex-wrap: wrap;
  gap: 24px;
  > ${RatioContainer} {
    margin-right: 24px;

    :last-of-type {
      margin-right: 0;
    }
  }
`;
const SmallButtonIconContainer = styled("div")<{ color: string }>`
  width: 16px;
  height: 16px;
  stroke: ${props => props.color};

  svg {
    width: 16px;
    height: 16px;
    stroke: ${props => props.color};
  }
`;
