export enum AppStatusEnum {
  LIVE = "LIVE",
  BETA = "BETA",
  ALPHA = "ALPHA",
  DEPRECATED = "DEPRECATED",
}

export enum AppTypeEnum {
  CONSUMER = "CONSUMER",
  BUSINESS = "BUSINESS",
}

export enum AppPlatformEnum {
  ANDROID = "ANDROID",
  IOS = "IOS",
}

export enum DocumentStatusEnum {
  EDIT = "EDIT",
  LIVE = "LIVE",
}

export interface IDocument {
  id: string;
  status: DocumentStatusEnum;
  hasUnpublishedChanges?: boolean;
  hasPreviousVersion?: boolean;
  content?: any;
}

export interface IReleasedVersion {
  id: string;
  name: string;
  version: string;
  status: DocumentStatusEnum;
}

export interface IReleases {
  status: DocumentStatusEnum;
  hasUnpublishedChanges: boolean;
  hasPreviousVersion: boolean;
  releasedVersions: IReleasedVersion[];
}

export interface IDocumentType {
  type: string;
  title: string;
  sendToApps: boolean;
  isDeletable?: boolean;
}
export interface IDocumentsList {
  documents: IDocumentType[];
}

export interface IApp {
  apkWhiteList?: string[];
  documents?: IDocument;
  icon: string;
  id: string;
  maxArchivedVersions: number;
  name: string;
  platform: string;
  sdk: string;
  status: AppStatusEnum;
  system: string;
  type: string;
  version: number;
  lastUpdate: number;
  releases?: IReleases;
}
