import { TabChild, Tabs, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ConfigContext } from "../../../app.component";
import { IStoreInterface } from "../../../configs/store.config";
import LoadingComponent from "../../../shared/components/loading-component/LoadingComponent";
import useCheckFeatureAvailable from "../../../shared/hooks/use-check-available-feature";
import { IServiceManagerService } from "../../../shared/models/service-manager.model";
import { FEATURES } from "../../../shared/renderer.utils";
import { BackOfficeMarketsEnums } from "../../../shared/shared.enums";
import VerticalListComponent from "../components/vertical-list.component";
import { ServiceType } from "../service-details/pages/details.interfaces";
import ServiceManagerApi from "../service-manager.api";
import { ServiceManagerActions } from "../service-manager.store";
import HorizontalListComponent from "./horizontal-list.component";
import { ServiceGroupbyName, ServiceGroupEnum, UserType } from "./section-manager.interface";

/** ServiceManager Tab */
const SectionManager = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const { services, isLoadingServices } = useSelector((state: IStoreInterface) => state.serviceManagerReducer);
  const agentHubEnabled = useCheckFeatureAvailable(FEATURES.AGENT_HUB);
  const serviceUserTypeEnabled = useCheckFeatureAvailable(FEATURES.SERVICE_USER_TYPE);
  const consumerGrowEnabled = useCheckFeatureAvailable(FEATURES.CONSUMER_GROW);
  const servicesAreaEnabled = useCheckFeatureAvailable(FEATURES.SERVICES_AREA);
  const mySafaricomPrePaidEnabled = useCheckFeatureAvailable(FEATURES.MY_SAFARICOM_PRE_PAID);
  const mySafaricomPostPaidEnabled = useCheckFeatureAvailable(FEATURES.MY_SAFARICOM_POST_PAID);
  const { config } = useContext(ConfigContext);

  const refreshPage = useCallback(() => {
    dispatch(ServiceManagerActions.creators.fetchingServicesAction());
    ServiceManagerApi.methods.getServices().then(
      res => {
        dispatch(ServiceManagerActions.creators.fetchServicesSuccessAction(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.serviceBuilder.errors.searchServices"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  }, [dispatch, t, showAlert, setAlertProps]);

  React.useEffect(() => {
    refreshPage();
    return () => {
      dispatch(ServiceManagerActions.creators.fetchingServicesLeaveAction());
    };
  }, [dispatch, t, showAlert, setAlertProps]);

  /**
   * method to filter list
   */
  const filterList = (groupName: ServiceGroupbyName, serviceType: ServiceType, userType?: UserType) => {
    const list: IServiceManagerService[] = [];
    services.forEach(item => {
      item.groups.forEach(group =>
        group.name === groupName &&
        group.serviceType === serviceType &&
        (!serviceUserTypeEnabled || group.userType === userType) &&
        group.order &&
        group.order > 0
          ? (list[group.order - 1] = item)
          : undefined,
      );
    });

    return list.filter(() => true);
  };

  /**
   * method to filter list by promoted
   */
  const filterPromoted = (groupName: ServiceGroupbyName, serviceType: ServiceType, userType?: UserType) => {
    const promoted: IServiceManagerService[] = [];
    services.forEach(item => {
      item.groups.forEach(group =>
        group.name === groupName &&
        group.serviceType === serviceType &&
        (!serviceUserTypeEnabled || group.userType === userType) &&
        group.promotedOrder &&
        group.promotedOrder > 0
          ? (promoted[group.promotedOrder - 1] = item)
          : undefined,
      );
    });
    return promoted.filter(() => true);
  };

  /**
   * method to render tab
   */
  const renderTab = (groupName: ServiceGroupbyName, serviceType: ServiceType, userType?: UserType) => {
    return (
      <>
        {![
          ServiceGroupbyName.SERVICES_AREA,
          ServiceGroupbyName.MY_SAFARICOM_POST_PAID,
          ServiceGroupbyName.MY_SAFARICOM_PRE_PAID,
        ].includes(groupName) ? (
          <>
            <HorizontalListComponent
              items={filterPromoted(groupName, serviceType, userType)}
              groupName={groupName}
              serviceType={serviceType}
              userType={userType}
            />
            <Separator />
          </>
        ) : null}
        <VerticalListComponent
          items={filterList(groupName, serviceType, userType)}
          groupName={groupName}
          serviceType={serviceType}
          userType={userType}
          promotedOptions={
            ![
              ServiceGroupbyName.SERVICES_AREA,
              ServiceGroupbyName.MY_SAFARICOM_POST_PAID,
              ServiceGroupbyName.MY_SAFARICOM_PRE_PAID,
            ].includes(groupName)
          }
        />
      </>
    );
  };

  return (
    <>
      {isLoadingServices ? (
        <LoadingContainer>
          <div>
            <LoadingComponent />
          </div>
        </LoadingContainer>
      ) : (
        <>
          <Tabs
            isSecondaryTabs={true}
            isSfcTabs={true}
            tabHeaderStyle={{ paddingTop: "20px", textTransform: "uppercase" }}
          >
            <TabChild
              label={
                config?.market === BackOfficeMarketsEnums.ET
                  ? ServiceGroupEnum.CONSUMER_MINI_APPS
                  : ServiceGroupEnum.CONSUMER_SERVICES
              }
            >
              {renderTab(ServiceGroupbyName.DISCOVER, ServiceType.CONSUMER, UserType.CUSTOMER)}
            </TabChild>
            {consumerGrowEnabled ? (
              <TabChild label={ServiceGroupEnum.CONSUMER_GROW}>
                {renderTab(ServiceGroupbyName.GROW, ServiceType.CONSUMER, UserType.CUSTOMER)}
              </TabChild>
            ) : (
              <></>
            )}
            <TabChild label={ServiceGroupEnum.MERCHANT_HUB}>
              {renderTab(ServiceGroupbyName.DISCOVER, ServiceType.ORG, UserType.MERCHANT)}
            </TabChild>
            {agentHubEnabled ? (
              <TabChild label={ServiceGroupEnum.AGENT_HUB}>
                {renderTab(ServiceGroupbyName.DISCOVER, ServiceType.ORG, UserType.AGENT)}
              </TabChild>
            ) : (
              <></>
            )}
            {servicesAreaEnabled ? (
              <TabChild label={ServiceGroupEnum.SERVICES_AREA}>
                {renderTab(ServiceGroupbyName.SERVICES_AREA, ServiceType.CONSUMER, UserType.CUSTOMER)}
              </TabChild>
            ) : (
              <></>
            )}
            {mySafaricomPrePaidEnabled ? (
              <TabChild label={ServiceGroupEnum.MY_SAFARICOM_PRE_PAID}>
                {renderTab(ServiceGroupbyName.MY_SAFARICOM_PRE_PAID, ServiceType.CONSUMER, UserType.CUSTOMER)}
              </TabChild>
            ) : (
              <></>
            )}
            {mySafaricomPostPaidEnabled ? (
              <TabChild label={ServiceGroupEnum.MY_SAFARICOM_POST_PAID}>
                {renderTab(ServiceGroupbyName.MY_SAFARICOM_POST_PAID, ServiceType.CONSUMER, UserType.CUSTOMER)}
              </TabChild>
            ) : (
              <></>
            )}
          </Tabs>
        </>
      )}
    </>
  );
};

export default SectionManager;

const LoadingContainer = styled("div")`
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Separator = styled("div")`
  width: 100%;
  height: 1px;
  border-top: solid 1px ${props => props.theme.palette.aluminium};
  margin: 32px 0;
`;
