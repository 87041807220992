import React, { useContext, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "@wit/mpesa-ui-components";
import { useSelector } from "react-redux";
import { IStoreInterface } from "../../../../configs/store.config";
import useVersionsDiff, { VersionItem } from "../../../../shared/hooks/use-version-diff/use-version-diff.hook";
import VersionItemStatusChip from "../../../../shared/hooks/use-version-diff/version-item-status-chip.component";
import { Row, Column } from "../../../../shared/shared.styled";

import { IShortcut, ShortcutsStatusEnum } from "../shortcuts.model";

import { getAvailableLanguagesOptions } from "../shortcuts.utils";
import LanguageSelectorDropdown from "../../../../shared/components/language-selector-dropdown.component";
import { IIconCategory } from "../../categories/general-categories.model";

interface IPublishShortcutProps {
  onCancelClick: () => void;
  submit: () => void;
  isLoading: boolean;
  icons: IIconCategory[];
}

/**
 * Publish Shortcut component
 */
const PublishShortcut = ({ onCancelClick, isLoading, submit, icons }: IPublishShortcutProps) => {
  const [t] = useTranslation();
  const [language, setLanguage] = useState({ label: t("commons.languages.en"), key: "en" });

  const { shortcuts, lastVersion } = useSelector((state: IStoreInterface) => state.shortcutsReducer);
  const { prev, current } = useVersionsDiff(lastVersion!.actionItemResponses, shortcuts!.actionItemResponses);
  const theme = useContext(ThemeContext);

  /**
   * Render item
   * @param item
   */
  const renderItem = (item: VersionItem<IShortcut>) => {
    return (
      <ItemColumn disabled={false}>
        <Row>
          <ImageContainer>
            {item && item.iconId && icons && icons.find(icon => icon.id.toString() === item.iconId?.toString()) ? (
              <img
                src={icons.find(icon => icon.id.toString() === item.iconId?.toString())?.base64}
                alt={"category-icon"}
              />
            ) : null}
          </ImageContainer>
          <ItemInfo>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {item.name && (
                <Row>
                  <span style={{ fontWeight: 700, wordBreak: "break-word" }}> {item.name[language.key]}</span>
                </Row>
              )}
              <VersionItemStatusChip status={item.versionItemStatus} />
            </div>
            {(item.deepLinkType || item.data.type) && (
              <Row style={{ marginTop: 7 }}>
                {t(`commons.typeOfLinkDropDown.${item.deepLinkType || item.data.type}`)}
              </Row>
            )}
          </ItemInfo>
          <StatusContainer>
            {item.status && (
              <StatusRow type={item.status}>{t(`commons.shortcuts.shortcutsStatusEnum.${item.status}`)}</StatusRow>
            )}
          </StatusContainer>
        </Row>
      </ItemColumn>
    );
  };

  return (
    <ModalContainer>
      <Row style={{ width: "100%", padding: 24 }}>
        <Column style={{ width: "100%" }}>
          <ModalTitle>{t("pages.shortcuts.publishModal.title")}</ModalTitle>
          <Row>
            <ModalSubTitle>{t("pages.shortcuts.publishModal.wantPublish")}</ModalSubTitle>
            <RightContainer>
              <LanguageSelectorDropdown
                availableLanguages={getAvailableLanguagesOptions()}
                selectedLanguage={language}
                changeSelectedLanguage={setLanguage}
                color={theme.palette.turquoiseBlue}
                id={"publish-language-selector"}
              ></LanguageSelectorDropdown>
            </RightContainer>
          </Row>
        </Column>
      </Row>
      <Content>
        <Row>
          <ItemColumn style={{ paddingBottom: "12px" }}>{t("pages.shortcuts.publishModal.previousVersion")}</ItemColumn>
          <ItemColumn style={{ paddingBottom: "12px" }}>{t("pages.shortcuts.publishModal.editedVersion")}</ItemColumn>
        </Row>
        <ItemsContainer>
          {prev &&
            current &&
            current.map((edited, index) => {
              return (
                <Item key={edited.id}>
                  {prev[index] ? renderItem(prev[index]) : <ItemColumn />}
                  {edited ? renderItem(edited) : <ItemColumn />}
                </Item>
              );
            })}
        </ItemsContainer>
      </Content>
      <ButtonsContainer style={{ padding: 24 }}>
        <div>
          <PrimaryButton
            type="button"
            id="cancel-button"
            disabled={isLoading}
            onClick={onCancelClick}
            titleLabel={t("pages.shortcuts.publishModal.cancelBtnLabel")}
          />
        </div>
        <div>
          <PrimaryButton
            id="confirm-button"
            redTheme={true}
            type="submit"
            disabled={isLoading}
            onClick={() => submit()}
            titleLabel={t("pages.shortcuts.publishModal.saveChangesBtnLabel")}
          />
        </div>
      </ButtonsContainer>
    </ModalContainer>
  );
};

export default PublishShortcut;

const ModalContainer = styled("div")`
  display: flex;
  flex-direction: column;
  font-family: Vodafone Rg;
`;

const ModalTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 21px;
`;

const ModalSubTitle = styled(ModalTitle)`
  font-size: 18px;
  margin-bottom: 0;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 50px;

  > div {
    width: "fit-content";

    :first-of-type {
      margin-right: 12px;
    }
  }
`;

const ItemsContainer = styled.div`
  max-height: 220px;
  overflow: scroll;
  width: 100%;
  overflow-x: hidden;
`;

const Item = styled(Row)`
  display: flex;
  align-items: flex-start;
`;

const ImageContainer = styled(Column)`
  margin-right: 24px;
  display: flex;
  align-self: baseline;
  flex: 1;
  padding: 4px;
  width: 40px;
  height: 40px;
`;

const ItemColumn = styled(Column)<{ disabled?: Boolean }>`
  flex: 1;
  padding: 8px 0px 8px 0;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  padding-left: 24px;
  &:first-child {
    padding-right: 74px;
  }

  &:last-child {
    padding-left: 30px;
    padding-right: 44px;
  }
`;

const ItemInfo = styled(Column)`
  flex: 20;
`;
const StatusContainer = styled(Column)`
  padding-left: 24px;
  display: flex;
  align-self: start;
  flex: 1;
`;

const StatusRow = styled("div")<{ type: ShortcutsStatusEnum }>`
  ${props => {
    let color = props.theme.palette.digitalGreen;
    switch (props.type) {
      case ShortcutsStatusEnum.LIVE:
        color = props.theme.palette.digitalGreen;
        break;
      case ShortcutsStatusEnum.DISABLE:
        color = props.theme.palette.freshOrange;
        break;
      case ShortcutsStatusEnum.DISABLED:
        color = props.theme.palette.freshOrange;
        break;
      case ShortcutsStatusEnum.ENABLE:
      case ShortcutsStatusEnum.EDIT:
        color = props.theme.palette.digitalGreen;
        break;
      case ShortcutsStatusEnum.DELETE:
        color = props.theme.palette.vodafoneRed;
        break;
    }
    return `color: ${color};display: flex;justify-content: flex-end;`;
  }}
`;

const Content = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${props => props.theme.palette.darkGrey};

  background: ${props => `linear-gradient(90deg, ${props.theme.palette.lightGrey} 50%, transparent 50%)`};
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: 15px;
`;
