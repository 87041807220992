import { AxiosPromise } from "axios";
import { IUser } from "../../../shared/models/user.model";
import { IPasswordPolicies } from "../../../shared/passwords-utils";
import ApiClient from "../../../configs/axios.config";
import { ISingleUserRequest, IUpdateUserRequest, IUpdateUserResponse } from "./users.interfaces";

const UsersApi = {
  routes: {
    users: "/users",
    exportUsersCsv: "/users/exportCSV",
    resendRegEmail: "/users/user/resetPassword",
    disableUser: "/users/user/disableAccount",
    enableUser: "/users/user/enableAccount",
    passwordPolicies: "/passwordPolicies",
  },
  methods: {
    getUsers: (): AxiosPromise<IUser[]> => {
      return ApiClient.get(UsersApi.routes.users);
    },
    exportUsersCsv: (): AxiosPromise<any> => {
      return ApiClient.get(UsersApi.routes.exportUsersCsv, {
        responseType: "blob",
      });
    },
    createUser: (users: ISingleUserRequest[]): AxiosPromise<IUser> => {
      return ApiClient.post(UsersApi.routes.users, users.length === 1 ? users[0] : users);
    },
    deleteUser: (username: string): AxiosPromise<void> => {
      const body = {
        data: {
          username: username,
        },
      };
      return ApiClient.delete(`${UsersApi.routes.users}/user`, body);
    },
    updateUser: (username: string, values: IUpdateUserRequest): AxiosPromise<IUpdateUserResponse> => {
      const body = {
        ...values,
        username: username,
      };
      return ApiClient.put(`${UsersApi.routes.users}/user`, body);
    },
    resendRegEmail: (username: string): AxiosPromise<void> => {
      const body = {
        language: "en-GB",
        username: username,
      };
      return ApiClient.post(UsersApi.routes.resendRegEmail, body);
    },
    enableUser: (username: string): AxiosPromise<void> => {
      const body = {
        username: username,
      };
      return ApiClient.post(UsersApi.routes.enableUser, body);
    },
    disableUser: (username: string): AxiosPromise<void> => {
      const body = {
        username: username,
      };
      return ApiClient.post(UsersApi.routes.disableUser, body);
    },
    passwordPolicies: (
      type: "pwreset" | "register" | "updateOwnPassword",
      id: string,
    ): AxiosPromise<IPasswordPolicies> => {
      return ApiClient.post(`${UsersApi.routes.passwordPolicies}?type=${type}`, { id: id });
    },
  },
};

export default UsersApi;
