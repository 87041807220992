import { AxiosPromise } from "axios";
import { IServiceManagerService, IServiceManagerServiceDetails } from "../../shared/models/service-manager.model";
import ApiClient from "../../configs/axios.config";
import { ServiceType } from "./service-details/pages/details.interfaces";
import { UserType } from "./section-manager/section-manager.interface";

export interface IReorderMiniAppsProps {
  categoryId: string;
  miniAppId: string;
  fromPosition: number;
  toPosition: number;
}

const ServiceManagerApi = {
  routes: {
    services: "/services/all?includeGroups=true",
    serviceGroupUpdate: "/services",
    removeGroup: "/services/remove-group",
    addService: "/services/create",
  },
  methods: {
    getServices: (): AxiosPromise<IServiceManagerService[]> => {
      return ApiClient.get(ServiceManagerApi.routes.services);
    },
    updateGroupOrder: (
      identifier: string,
      groupName: string,
      toPosition: number,
      serviceType: ServiceType,
      userType?: UserType,
      promotedPosition?: number,
    ): AxiosPromise<void> => {
      if (promotedPosition && toPosition) {
        return ApiClient.put(
          `${
            ServiceManagerApi.routes.serviceGroupUpdate
          }?serviceIdentifier=${identifier}&groupName=${groupName}&toPosition=${toPosition}&promotedPosition=${promotedPosition}&serviceType=${serviceType}${
            userType ? `&userType=${userType}` : ""
          }`,
        );
      } else if (promotedPosition && !toPosition) {
        return ApiClient.put(
          `${
            ServiceManagerApi.routes.serviceGroupUpdate
          }?serviceIdentifier=${identifier}&groupName=${groupName}&promotedPosition=${promotedPosition}&serviceType=${serviceType}${
            userType ? `&userType=${userType}` : ""
          }`,
        );
      } else if (!promotedPosition && toPosition) {
        return ApiClient.put(
          `${
            ServiceManagerApi.routes.serviceGroupUpdate
          }?serviceIdentifier=${identifier}&groupName=${groupName}&toPosition=${toPosition}&serviceType=${serviceType}${
            userType ? `&userType=${userType}` : ""
          }`,
        );
      } else {
        return ApiClient.put(
          `${
            ServiceManagerApi.routes.serviceGroupUpdate
          }?serviceIdentifier=${identifier}&groupName=${groupName}&serviceType=${serviceType}${
            userType ? `&userType=${userType}` : ""
          }`,
        );
      }
    },
    deleteGroupItem: (
      identifier: string,
      groupName: string,
      userType?: UserType,
      serviceType?: ServiceType,
    ): AxiosPromise<void> => {
      const objectParam = userType
        ? {
            serviceId: identifier,
            groupName,
            userType,
          }
        : { serviceId: identifier, groupName, serviceType };
      return ApiClient.post(`${ServiceManagerApi.routes.removeGroup}`, objectParam);
    },
    unpromoteService: (
      identifier: string,
      groupName: string,
      serviceType: ServiceType,
      toPosition: number,
      userType?: UserType,
    ): AxiosPromise<void> => {
      return ApiClient.put(
        `${
          ServiceManagerApi.routes.serviceGroupUpdate
        }?serviceIdentifier=${identifier}&groupName=${groupName}&toPosition=${toPosition}&serviceType=${serviceType}${
          userType ? `&userType=${userType}` : ""
        }`,
      );
    },
    unpromoteDeleteService: (
      identifier: string,
      groupName: string,
      serviceType: ServiceType,
      userType?: UserType,
    ): AxiosPromise<void> => {
      return ApiClient.put(
        `${
          ServiceManagerApi.routes.serviceGroupUpdate
        }?serviceIdentifier=${identifier}&groupName=${groupName}&serviceType=${serviceType}${
          userType ? `&userType=${userType}` : ""
        }`,
      );
    },
    addService: (
      imageService: File,
      iconService: File,
      serviceToAdd: IServiceManagerServiceDetails,
    ): AxiosPromise<void> => {
      const data = new FormData();
      if (imageService) {
        data.append("imageService", imageService);
      }
      if (iconService) {
        data.append("iconService", iconService);
      }
      data.append("metadata", JSON.stringify(serviceToAdd));

      return ApiClient.post(ServiceManagerApi.routes.addService, data);
    },
    addMiniApsToCategory: (categoryId: string, miniAppsList: string[]) => {
      return ApiClient.post(`/service-categories/${categoryId}/mini-apps`, miniAppsList);
    },
    reorderMiniAppWithinCategory: ({ categoryId, miniAppId, fromPosition, toPosition }: IReorderMiniAppsProps) => {
      const data = {
        id: miniAppId,
        fromPosition,
        toPosition,
      };
      return ApiClient.put(`/service-categories/${categoryId}/mini-apps/order`, data);
    },
    deleteMiniAppFromCategory: (categoryId: string, miniAppId: string) => {
      return ApiClient.delete(`/service-categories/${categoryId}/mini-apps/${miniAppId}`);
    },
  },
};
export default ServiceManagerApi;
