export interface IAuditingLog {
  id: string;
  author: string;
  role: string;
  date: number;
  entity: SectionsEnum;
  entityId: string;
  typeOfUpdate: OperationEnum;
}

export interface IAuditingLogsRequest {
  page: number;
  pageSize: number;
}

export enum OperationEnum {
  UPDATE = "UPDATE",
  CREATE = "CREATE",
  DELETE = "DELETE",
  SUBMIT_FOR_APPROVAL = "SUBMIT_FOR_APPROVAL",
  APPROVE = "APPROVE",
  REJECT = "REJECT",
}

export enum SectionsEnum {
  CHARGE_PROFILE = "CHARGE_PROFILE",
  SERVICES = "SERVICES",
  DISCOVER_CARD = "DISCOVER_CARD",
  DYNAMIC_SCREENS = "DYNAMIC_SCREENS",
  PUSH_NOTIFICATION = "PUSH_NOTIFICATION",
}

export interface IAuditingLogDetails {
  id: string | number;
  author: string;
  role: string;
  date: number;
  entity: SectionsEnum;
  entityId: string | number;
  operation: OperationEnum;
  changes: IAuditingLogChanges[];
}

export interface IAuditingLogChanges {
  subEntity: SectionsEnum;
  entityId: string | number;
  minorChanges: IAuditingLogMinorChanges[];
}

export interface IAuditingLogMinorChanges {
  field: string;
  operation: OperationEnum;
  oldValue: null | string | ITranslatableChanges<string | null>;
  newValue: null | string | ITranslatableChanges<string | null>;
}

export interface ITranslatableChanges<T> {
  [lang: string]: T;
}
