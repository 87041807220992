import { CallToActionType } from "../../announcements/announcements.model";

const OnDemandNotificationUtil = {
  mapToCallToAction: (callToActionParameters: ICallToActionResource, action: string) => {
    const callToAction = {
      action: OnDemandNotificationUtil.getCallToActionType(callToActionParameters, action),
      title: callToActionParameters.ACTION_BUTTON_LABEL,
    };

    return callToAction;
  },

  getCallToActionType: (callToActionParameters: ICallToActionResource, actionName: string) => {
    let action = {};
    switch (actionName) {
      case "GO_TO_EXTERNAL_URL":
        action = {
          resource: callToActionParameters.URL,
          type: CallToActionType.EXTERNAL_LINK.toString(),
        };
        break;
      case "GO_TO_INTERNAL_URL":
        action = {
          resource: callToActionParameters.URL,
          type: CallToActionType.INTERNAL_LINK.toString(),
        };
        break;
      case "GO_TO_MINI_APP":
        action = {
          resource: callToActionParameters.APP_ID,
          type: CallToActionType.MINIAPP.toString(),
        };
        break;
      case "GO_TO_FEATURE":
        action = {
          resource: callToActionParameters.FEATURE,
          type: CallToActionType.FEATURE.toString(),
        };
        break;
    }
    return action;
  },

  getImageIdfromImageUrl: (backgroundImageUrl: string): string => {
    const imageUrlItems = backgroundImageUrl.split("/");
    let imageId: string = "";
    if (imageUrlItems && imageUrlItems.length > 0) {
      imageId = imageUrlItems[imageUrlItems.length - 1];
    }
    return imageId;
  },
};

interface ICallToActionResource {
  URL: string;
  ACTION_BUTTON_LABEL: string;
  FEATURE: string;
  APP_ID: string;
}

export default OnDemandNotificationUtil;
