export const processEmptyDocument = (doc: any) => {
  const copy = { ...doc };
  Object.keys(doc).map(k => {
    if (copy[k] !== null) {
      const regex = /(<([^>]+)>)/gi;
      const hasText = !!copy[k].replace(regex, "").length;
      if (!hasText) {
        copy[k] = "";
      }
    }
  });
  return copy;
};
