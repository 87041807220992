import { AxiosPromise } from "axios";
import ApiClient from "../../../configs/axios.config";
import { ILastLogin } from "../../../features/authentication/authentication.interfaces";

const LastLoginsApi = {
  routes: {
    getLastLogins: "/v1/loginhistory",
  },
  methods: {
    getLastLogins: (): AxiosPromise<ILastLogin[]> => {
      return ApiClient.get(LastLoginsApi.routes.getLastLogins, {});
    },
  },
};

export default LastLoginsApi;
