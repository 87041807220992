import React from "react";
import styled from "styled-components";
import {
  IReferralCampaign,
  IReward,
  IScreen,
  ITranslatableObject,
  SenderTypeRewardEnum,
  TargetEnum,
  TypeScreenEnum,
} from "../referral-campaigns.model";
import MobileSchema from "./mobile-schema.component";
import { TabChild, Tabs } from "@wit/mpesa-ui-components";
import { useTranslation } from "react-i18next";

interface ISenderScreenPreviewProps {
  values: IReferralCampaign;
  language: string;
  tab: number;
  setTab: React.Dispatch<React.SetStateAction<number>>;
}

/**
 * SenderScreenPreview component
 */
const SenderScreenPreview = ({ values, language: lang, tab, setTab }: ISenderScreenPreviewProps) => {
  const [t] = useTranslation();

  /**
   * method to find a screen
   */
  const findScreen = (type: TypeScreenEnum): IScreen => {
    return values.screens.find(reward => reward.type === type) as IScreen;
  };

  /**
   * method to get reward invites
   */
  const getNumberOfInvites = () => {
    return values.rewards &&
      values.rewards.find(reward => reward.target === TargetEnum.SENDER) &&
      (values.rewards.find(reward => reward.target === TargetEnum.SENDER) as IReward).maxInvites
      ? ((values.rewards.find(reward => reward.target === TargetEnum.SENDER) as IReward).maxInvites as number)
      : 0;
  };

  return (
    <PreviewContainer>
      {values.rewards &&
      values.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) &&
      (values.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) as IReward).type !==
        SenderTypeRewardEnum.NONE &&
      ((values.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) as IReward)
        .maxInvites as number) > 0 ? (
        <Tabs isSecondaryTabs={true} key={`tabkey${tab}`} controlledSelectedTab={tab} controlledSetSelectedTab={setTab}>
          <TabChild label={t("pages.referralCampaigns.createCampaign.senderScreen.initialScreen")}>
            <MobileSchema>
              <Background
                type={TypeScreenEnum.SENDER_INITIAL}
                reward={
                  !!(
                    values.rewards &&
                    values.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) &&
                    (values.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) as IReward).type !==
                      SenderTypeRewardEnum.NONE
                  )
                }
              >
                <Image
                  image={
                    findScreen(TypeScreenEnum.SENDER_INITIAL) && findScreen(TypeScreenEnum.SENDER_INITIAL).urlImage
                      ? (findScreen(TypeScreenEnum.SENDER_INITIAL).urlImage as string)
                      : null
                  }
                />
                <Title>
                  {values.screens &&
                  findScreen(TypeScreenEnum.SENDER_INITIAL) &&
                  findScreen(TypeScreenEnum.SENDER_INITIAL).title &&
                  (findScreen(TypeScreenEnum.SENDER_INITIAL).title as ITranslatableObject).translations[lang]
                    ? (findScreen(TypeScreenEnum.SENDER_INITIAL).title as ITranslatableObject).translations[lang]
                    : `Title (${lang.toUpperCase()})`}
                </Title>
                <Description>
                  {values.screens &&
                  findScreen(TypeScreenEnum.SENDER_INITIAL) &&
                  findScreen(TypeScreenEnum.SENDER_INITIAL).description &&
                  (findScreen(TypeScreenEnum.SENDER_INITIAL).description as ITranslatableObject).translations[lang]
                    ? (findScreen(TypeScreenEnum.SENDER_INITIAL).description as ITranslatableObject).translations[lang]
                    : `Description (${lang.toUpperCase()})`}
                </Description>
                <RewardNumbers>
                  {`0${
                    getNumberOfInvites() > 0
                      ? `/${
                          (values.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) as IReward)
                            .type !== SenderTypeRewardEnum.AGGREGATED
                            ? getNumberOfInvites()
                            : "1"
                        }`
                      : ""
                  }
                  ${t("pages.referralCampaigns.createCampaign.rewardsWon")}`}
                </RewardNumbers>
                <Division />
                <InvitesContainer>
                  {getNumberOfInvites() > 0 ? (
                    <>
                      {Array(getNumberOfInvites())
                        .fill(1)
                        .map(() => (
                          <EmptyInvitePlaceholder />
                        ))}
                    </>
                  ) : (
                    <EmptyInvitePlaceholder />
                  )}
                </InvitesContainer>
              </Background>
            </MobileSchema>
          </TabChild>

          <TabChild label={t("pages.referralCampaigns.createCampaign.senderScreen.finalScreen")}>
            <MobileSchema>
              <Background type={TypeScreenEnum.SENDER_FINAL} reward={false}>
                <Image
                  image={
                    findScreen(TypeScreenEnum.SENDER_FINAL) && findScreen(TypeScreenEnum.SENDER_FINAL).urlImage
                      ? (findScreen(TypeScreenEnum.SENDER_FINAL).urlImage as string)
                      : null
                  }
                />
                <Title>
                  {values.screens &&
                  findScreen(TypeScreenEnum.SENDER_FINAL) &&
                  findScreen(TypeScreenEnum.SENDER_FINAL).title &&
                  (findScreen(TypeScreenEnum.SENDER_FINAL).title as ITranslatableObject).translations[lang]
                    ? (findScreen(TypeScreenEnum.SENDER_FINAL).title as ITranslatableObject).translations[lang]
                    : `Title (${lang.toUpperCase()})`}
                </Title>
                <Description>
                  {values.screens &&
                  findScreen(TypeScreenEnum.SENDER_FINAL) &&
                  findScreen(TypeScreenEnum.SENDER_FINAL).description &&
                  (findScreen(TypeScreenEnum.SENDER_FINAL).description as ITranslatableObject).translations[lang]
                    ? (findScreen(TypeScreenEnum.SENDER_FINAL).description as ITranslatableObject).translations[lang]
                    : `Description (${lang.toUpperCase()})`}
                </Description>
                <RewardNumbers>
                  {`${
                    getNumberOfInvites() > 0
                      ? `${
                          (values.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) as IReward)
                            .type !== SenderTypeRewardEnum.AGGREGATED
                            ? getNumberOfInvites()
                            : "1"
                        }/${
                          (values.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) as IReward)
                            .type !== SenderTypeRewardEnum.AGGREGATED
                            ? getNumberOfInvites()
                            : "1"
                        }`
                      : null
                  }
                  ${t("pages.referralCampaigns.createCampaign.rewardsWon")}`}
                </RewardNumbers>
                <Division />
                <InvitesContainer>
                  {Array(getNumberOfInvites())
                    .fill(1)
                    .map(() => (
                      <ActiveCheckedPlaceholder />
                    ))}
                </InvitesContainer>
              </Background>
            </MobileSchema>
          </TabChild>
        </Tabs>
      ) : (
        <div style={{ marginTop: "20px" }}>
          <MobileSchema>
            <Background
              type={TypeScreenEnum.SENDER_INITIAL}
              reward={
                !!(
                  values.rewards &&
                  values.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) &&
                  (values.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) as IReward).type !==
                    SenderTypeRewardEnum.NONE
                )
              }
            >
              <Image
                image={
                  findScreen(TypeScreenEnum.SENDER_INITIAL) && findScreen(TypeScreenEnum.SENDER_INITIAL).urlImage
                    ? (findScreen(TypeScreenEnum.SENDER_INITIAL).urlImage as string)
                    : null
                }
              />
              <Title>
                {values.screens &&
                findScreen(TypeScreenEnum.SENDER_INITIAL) &&
                findScreen(TypeScreenEnum.SENDER_INITIAL).title &&
                (findScreen(TypeScreenEnum.SENDER_INITIAL).title as ITranslatableObject).translations[lang]
                  ? (findScreen(TypeScreenEnum.SENDER_INITIAL).title as ITranslatableObject).translations[lang]
                  : `Title (${lang.toUpperCase()})`}
              </Title>
              <Description>
                {values.screens &&
                findScreen(TypeScreenEnum.SENDER_INITIAL) &&
                findScreen(TypeScreenEnum.SENDER_INITIAL).description &&
                (findScreen(TypeScreenEnum.SENDER_INITIAL).description as ITranslatableObject).translations[lang]
                  ? (findScreen(TypeScreenEnum.SENDER_INITIAL).description as ITranslatableObject).translations[lang]
                  : `Description (${lang.toUpperCase()})`}
              </Description>
              {values.rewards &&
              values.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) &&
              (values.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) as IReward).type !==
                SenderTypeRewardEnum.SINGLE ? (
                <RewardNumbers>{t("pages.referralCampaigns.createCampaign.zeroAcceptedInvites")}</RewardNumbers>
              ) : (
                <RewardNumbers>{`0 ${t("pages.referralCampaigns.createCampaign.rewardsWon")}`}</RewardNumbers>
              )}

              <Division />
              <InvitesContainer>
                <EmptyInvitePlaceholder />
              </InvitesContainer>
            </Background>
          </MobileSchema>
        </div>
      )}
    </PreviewContainer>
  );
};

export default SenderScreenPreview;

const PreviewContainer = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Background = styled("div")<{ type: TypeScreenEnum; reward: boolean }>`
  background: url("./images/referralCampaigns/referrals-initial-screen-no-reward.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled("span")`
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: ${props => props.theme.palette.darkGrey};
  position: absolute;
  top: 216px;
  width: 210px;
  left: 0;
  right: 0;
  margin: auto;
  word-break: break-word;
`;

const Description = styled("span")`
  font-family: Vodafone Rg;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: ${props => props.theme.palette.darkGrey};
  position: absolute;
  width: 210px;
  top: 255px;
  left: 0;
  right: 0;
  margin: auto;
  word-break: break-word;
`;

const Image = styled("div")<{ image: string | null }>`
  display: ${props => (props.image ? "inline" : "none")};
  background: ${props => `url("${props.image}"), #ffffff`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 50px;
  border-radius: 6px;
  position: absolute;
  top: 135px;
  margin: auto;
  left: 0;
  right: 0;
`;

const RewardNumbers = styled("span")`
  font-family: Vodafone Rg;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: ${props => props.theme.palette.darkGrey};
  position: absolute;
  top: 329px;
  left: 23px;
`;

const Division = styled("span")`
  border-bottom: 1px solid ${props => props.theme.palette.aluminium};
  position: absolute;
  top: 350px;
  width: 202px;
  left: 23px;
`;

const EmptyInvitePlaceholder = styled("div")`
  width: 34px;
  height: 34px;
  background: url("./images/referralCampaigns/empty.svg");
  background-size: contain;
  display: inline-block;
`;

const ActiveCheckedPlaceholder = styled("div")`
  width: 34px;
  height: 34px;
  background: url("./images/referralCampaigns/active_checked.svg");
  background-size: contain;
  display: inline-block;
`;

const InvitesContainer = styled("div")`
  position: absolute;
  top: 350px;
  width: 205px;
  left: 23px;
  margin-top: 15px;
  overflow: hidden;
  height: 34px;
  div:not(:first-child) {
    margin-left: 8px;
  }
`;
