import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import React from "react";
import styled from "styled-components";

export interface IProgressBarComponentProps {
  label: string;
  description: string;
  percentage: number;
  labelColor?: string;
  barColor?: string;
}

/**
 * Progress Bar component
 * - label is the text on the left side
 * - description is the normal text on the right side
 * - percentage is the percentage of blue line
 *
 * @export
 * @param {IProgressBarComponentProps} {
 *   description,
 *   label,
 *   percentage
 * }
 * @returns
 */
export default function ProgressBar({
  description,
  label,
  labelColor,
  barColor,
  percentage = 100,
}: IProgressBarComponentProps) {
  const percentageToShow = percentage < 0 ? 0 : percentage > 100 ? 100 : percentage;

  return (
    <Container>
      <Label color={labelColor}>{label}</Label>
      <BarContainer color={barColor}>
        <div className={"filled"} style={{ width: `${percentageToShow}%` }} />
      </BarContainer>
      <Description>{description}</Description>
    </Container>
  );
}

const Container = styled("div")`
  width: 100%;
  display: flex;
  gap: 10px;
`;

const Label = styled("span")<{ color?: string }>`
  font-size: 14px;
  color: ${props => (props.color ? props.color : styleTheme.palette.midGrey)};
`;

const Description = styled("span")`
  font-size: 14px;
  color: ${styleTheme.palette.greyDarkest};
`;

const BarContainer = styled("div")<{ color?: string }>`
  background: #ecf5f7;
  border-radius: 5px;
  height: 5px;
  width: 100%;
  margin-top: 6px;

  & .filled {
    height: 5px;
    border-radius: 5px;
    background: ${props => (props.color ? props.color : styleTheme.palette.digitalRed)};
  }
`;
