/**
 * List of language translations version
 */
export interface ILanguageTranslationsVersions {
  versions: ILanguageTranslationsVersion[];
}

/**
 * Language translation version
 */
export interface ILanguageTranslationsVersion {
  version: string;
  lastUpdate: string | number;
  status: LanguageTranslationStatusEnum;
  submittedForApproval: boolean;
}

/**
 * List of app versions that can be used to create new language translation versions
 */
export interface IWhiteListedVersions {
  versions: string[];
}

/**
 * List of language translations
 */
export interface ILanguageTranslations {
  translations: ILanguageTranslation[];
  hasPreviousVersion: boolean;
  hasUnpublishedChanges: boolean;
  submittedForApproval: boolean;
  status: LanguageTranslationStatusEnum;
  version: string;
}

/**
 * Language translation item
 */
export interface ILanguageTranslation {
  key: string;
  translations: {
    [key: string]: string;
  };
  status: LanguageTranslationStatusEnum;
  id: string | number;
}

/**
 * Available status for both language translations list and items
 */
export enum LanguageTranslationStatusEnum {
  EDIT = "EDIT",
  LIVE = "LIVE",
  DELETE = "DELETE",
}

/**
 * Current available language translations screen modals
 */
export enum LanguageTranslationModalsEnum {
  EXPORT = "EXPORT",
  IMPORT = "IMPORT",
}

/**
 * Create language translation request
 */
export interface IAddLanguageTranslationRequest {
  key: string;
  translations: {
    [key: string]: string;
  };
  id: string | number | null;
  version: string;
}

/**
 * Export Language Translation File request
 */
export interface IExportLanguageTranslationRequest {
  ios: boolean;
  android: boolean;
  json: boolean;
  excel: boolean;
  version: string;
}

/**
 * Import Language Translation JSON request
 */
export interface IImportLanguageTranslationRequest {
  translationsFile: any;
  version: string;
}

/**
 * Create new language translation version request
 */
export interface IAddLanguageTranslationsVersionRequest {
  version: string;
  copyFromVersion: string | null;
}
