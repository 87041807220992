import React, { useState, useEffect } from "react";
import { SearchBar, DateRangeDropdown, useFilters, useModal, useAlert, ModalTypeEnum } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { useTranslation } from "react-i18next";
import { RatioContainer } from "../../../../shared/shared.styled";
import styled from "styled-components";
import moment from "moment";
import { AxiosResponse } from "axios";
import FeedbackAndProblemsAPI from "../feedback-and-problems.api";
import fileDownload from "js-file-download";
import { useDispatch } from "react-redux";
import { FeedbackAndProblemsStoreActions } from "../feedback-and-problems.store";
import ExportAndRefreshComponent from "./export-and-refresh.component";
import ExportModalComponent from "../components/export-modal.component";
import FilterDropDownComponent from "./filters-dropdown.component";
import {
  ISectionFiltersComponentProps,
  IStatusDropDownData,
  ICategoriesDropDownData,
  EDateFilterKeys,
  EFormDataStatus,
  IExportFormDataRequest,
  EFormType,
} from "../feedback-and-problems.model";
import {
  isFeedbackTabActive,
  isProblemReportsTabActive,
  feedbacksStringPath,
  problemsStringPath,
  searchInputTextHandler,
  customerStringPath,
} from "../feedback-and-problems.utils";
import { useFeedbackAndProblemsResultCounter } from "../hooks/use-feedback-and-problems-results-counter.hook";
import { useFeedbackAndProblemsCategories } from "../hooks/use-feedback-and-problems-categories.hook";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";

const SectionFiltersComponent = ({
  sectionId,
  searchInputMaxLength,
  selectableStatuses,
  onClickRefresh,
  staticParams,
}: ISectionFiltersComponentProps) => {
  const [t] = useTranslation();
  const [showAlert, , setAlertProps] = useAlert();
  const dispatch = useDispatch();
  const resultSetLength = useFeedbackAndProblemsResultCounter(sectionId);
  const selectableCategories = useFeedbackAndProblemsCategories(sectionId);

  const { filters, resetFilters, updateFilter, isFilterActive, clearFilter, updateMultipleFilters } = useFilters();
  const [searchInput, setSearchInput] = useState<string>("");
  const [categoryInput, setCategoryInput] = useState<ICategoriesDropDownData>({} as ICategoriesDropDownData);
  const [statusInput, setStatusInput] = useState<IStatusDropDownData>({} as IStatusDropDownData);

  /**
   * Hides the modal used for file export
   */
  const hideExportModal = () => hideExportFormDataModal();

  /**
   * Shows the modal to be used by the user to select the file formats and export
   */
  const showExportModal = () => showExportFormDataModal();

  /**
   * Emits to BE the request to execute the file export
   * @param payload IExportFormDataRequest
   */
  const exportFormDataFileRequest = async (payload: IExportFormDataRequest) => {
    if (isFeedbackTabActive(sectionId)) {
      dispatch(FeedbackAndProblemsStoreActions.creators.downloadFeedbacksReportFile());
    } else if (isProblemReportsTabActive(sectionId)) {
      dispatch(FeedbackAndProblemsStoreActions.creators.downloadProblemsReportFile());
    } else {
      dispatch(FeedbackAndProblemsStoreActions.creators.downloadCustomerSurveyFile());
    }

    try {
      const res = await FeedbackAndProblemsAPI.methods.exportFormData(
        isFeedbackTabActive(sectionId)
          ? EFormType.FEEDBACK
          : isProblemReportsTabActive(sectionId)
          ? EFormType.REPORT_PROBLEM
          : EFormType.CUSTOMER_SURVEY,
        payload,
        staticParams,
      );
      handleFileDownload(res.data);
      setAlertProps({
        title: isFeedbackTabActive(sectionId)
          ? t(`${feedbacksStringPath}.success.onExportFormData`)
          : isProblemReportsTabActive(sectionId)
          ? t(`${problemsStringPath}.success.onExportFormData`)
          : t(`${customerStringPath}.success.onExportFormData`),
        type: AlertTypeEnum.SUCCESS,
      });
      showAlert();
    } catch (error) {
      setAlertProps({
        title: isFeedbackTabActive(sectionId)
          ? t(`${feedbacksStringPath}.errors.onExportFormData`)
          : isProblemReportsTabActive(sectionId)
          ? t(`${problemsStringPath}.errors.onExportFormData`)
          : t(`${customerStringPath}.errors.onExportFormData`),
        type: AlertTypeEnum.ERROR,
      });
      showAlert();
    }
  };

  /**
   * Handles File Download
   * @param response AxiosResponse<any>
   */
  const handleFileDownload = (response: AxiosResponse) => {
    const fileDownload = require("js-file-download");
    const sectionName = isFeedbackTabActive(sectionId)
      ? "Feedbacks"
      : isProblemReportsTabActive(sectionId)
      ? "Problem-Reports"
      : "Customer-Satisfation-Survey";
    const date = {
      start: !!staticParams.startDate
        ? moment(Number(staticParams.startDate) * 1000)
            .format("DDMMYYYY")
            .replace(/\//g, "")
        : "",
      end: !!staticParams.endDate
        ? moment(Number(staticParams.endDate) * 1000)
            .subtract(1, "days")
            .format("DDMMYYYY")
            .replace(/\//g, "")
        : "",
    };

    if (date.start && date.end) {
      fileDownload(response, `${sectionName}_${date.start}_${date.end}.zip`);
      hideExportModal();
      return;
    }

    fileDownload(response, `${sectionName}.zip`);
    hideExportModal();
  };

  const [showExportFormDataModal, hideExportFormDataModal] = useModal(
    ModalTypeEnum.CustomModal,
    { modalStyles: { width: 400 } } as any,
    <ExportModalComponent
      closeExportModal={hideExportModal}
      exportFormData={exportFormDataFileRequest}
      sectionId={sectionId}
    />,
  );

  /**
   * Handles Search input-field change event
   * @param eve React.ChangeEvent<HTMLInputElement>
   */
  const searchChangeEventHandler = (eve: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = searchInputTextHandler(eve.currentTarget.value);
    setSearchInput(searchQuery);
    staticParams.searchQuery = searchQuery;
  };

  /**
   * Handles Search input-field change event
   * @param eve React.KeyboardEvent<HTMLInputElement>
   */
  const searchKeyPressEventHandler = (eve: React.KeyboardEvent<HTMLInputElement>) => {
    if (eve.key === "Enter" || eve.keyCode === 13) {
      onClickRefresh();
    }
  };

  /**
   * Handles Category dropdown-input-field change
   * @param option IStatusDropDownData
   */
  const categoryDropDownHandler = (option: IStatusDropDownData) => {
    if (staticParams.categoryId !== option.key && option.key !== -1) {
      setCategoryInput(option);
      staticParams.categoryId = option.key;
      return;
    }

    if (staticParams.categoryId === option.key || option.label.toLocaleLowerCase() === "clear filter") {
      setCategoryInput({
        key: "",
        label: "",
      });
      staticParams.categoryId = "";
    }
  };

  /**
   * Handles Status dropdown-input-field change
   * @param option IStatusDropDownData
   */
  const statusDropDownHandler = (option: IStatusDropDownData) => {
    if (staticParams.status !== (option.key as EFormDataStatus) && option.key !== -1) {
      setStatusInput(option);
      staticParams.status = option.key as EFormDataStatus;
      return;
    }

    if (
      staticParams.status === (option.key as EFormDataStatus) ||
      option.label.toLocaleLowerCase() === "clear filter"
    ) {
      setStatusInput({
        key: "",
        label: "",
      });
      staticParams.status = "" as EFormDataStatus;
    }
  };

  /**
   * Handles date picker criteria change (time)
   */
  const getTimeFilter = () => {
    if (filters.get(EDateFilterKeys.TIME_RANGE)) {
      const startTime = (filters.get(EDateFilterKeys.TIME_RANGE) as string[])[0].split(":");
      const endTime = (filters.get(EDateFilterKeys.TIME_RANGE) as string[])[1].split(":");
      return { startTime: startTime, endTime: endTime };
    }
    return null;
  };

  /**
   * Handles date picker criteria change (date)
   */
  const getDateFilter = () => {
    if (filters.get(EDateFilterKeys.START_DATE)) {
      let startDate,
        endDate = null;
      const timeFilter = getTimeFilter();
      if (timeFilter) {
        startDate = moment((filters.get(EDateFilterKeys.START_DATE) as string[])[0])
          .utcOffset(0)
          .hours(Number(timeFilter.startTime[0]))
          .minutes(Number(timeFilter.startTime[1]))
          .seconds(0);
        endDate = moment((filters.get(EDateFilterKeys.END_DATE) as string[])[0])
          .utcOffset(0)
          .hours(Number(timeFilter.endTime[0]))
          .minutes(Number(timeFilter.endTime[1]))
          .seconds(59);
      } else {
        startDate = moment((filters.get(EDateFilterKeys.START_DATE) as string[])[0])
          .utcOffset(0)
          .hours(0)
          .minutes(0)
          .seconds(0);
        endDate = moment((filters.get(EDateFilterKeys.END_DATE) as string[])[0])
          .utcOffset(0)
          .hours(0)
          .minutes(0)
          .seconds(0)
          .add(1, "d");
      }
      return { startDate: startDate.unix(), endDate: endDate.unix() };
    }
    return null;
  };

  /**
   * Resets the filters params when the user change from one section into another
   */
  useEffect(() => {
    resetFilters();
    setSearchInput("");
    setCategoryInput({} as ICategoriesDropDownData);
    setStatusInput({} as IStatusDropDownData);
    staticParams = {
      searchQuery: "",
      startDate: "",
      endDate: "",
      categoryId: "",
      status: "" as EFormDataStatus,
    };
  }, [sectionId]);

  /**
   * Updates startDate and endDate on temporary filters params when the user
   * picks a date from the calendar
   */
  useEffect(() => {
    const dateFilter = getDateFilter();
    if (dateFilter && Object.keys(dateFilter).length === 2) {
      staticParams.startDate = dateFilter.startDate;
      staticParams.endDate = dateFilter.endDate;
    } else {
      staticParams.startDate = "";
      staticParams.endDate = "";
    }
  }, [filters]);

  return (
    <FiltersRow>
      <RatioContainer ratio={(resultSetLength > 0 ? 6.5 : 5.5) / 12}>
        <DateAndSearchFilterWrapper id="search-filter">
          <SearchBar
            value={searchInput}
            onChange={eve => searchChangeEventHandler(eve)}
            onKeyDown={eve => searchKeyPressEventHandler(eve)}
            placeholderLabel={
              isFeedbackTabActive(sectionId)
                ? t(`${feedbacksStringPath}.filtersArea.search`)
                : isProblemReportsTabActive(sectionId)
                ? t(`${problemsStringPath}.filtersArea.search`)
                : t(`${customerStringPath}.filtersArea.search`)
            }
            maxLength={searchInputMaxLength}
            clearValue={() => {
              setSearchInput("");
              staticParams.searchQuery = "";
            }}
          />
        </DateAndSearchFilterWrapper>
      </RatioContainer>

      {(sectionId === 0 || sectionId === 1) && (
        <RatioContainer ratio={(resultSetLength > 0 ? 3.5 : 2.5) / 12}>
          <DateAndSearchFilterWrapper id="date-filter">
            <DateRangeDropdown
              filters={filters}
              updateFilter={updateFilter}
              isFilterActive={isFilterActive}
              clearFilter={clearFilter}
              updateMultipleFilters={updateMultipleFilters}
              firstEnabledDate={moment(1368691200000, "DD/MM/YYYY")}
              translations={{
                dropdownPlaceholder: isFeedbackTabActive(sectionId)
                  ? t(`${feedbacksStringPath}.filtersArea.date`)
                  : t(`${problemsStringPath}.filtersArea.date`),
                clearFilters: t("components.dateFilter.clearFilters"),
                CUSTOM: isFeedbackTabActive(sectionId)
                  ? t(`${feedbacksStringPath}.filtersArea.dateRange`)
                  : t(`${problemsStringPath}.filtersArea.dateRange`),
                LAST_3_MONTH: t("components.dateFilter.last3Months"),
                LAST_6_MONTH: t("components.dateFilter.last6Months"),
                LAST_MONTH: t("components.dateFilter.lastMonth"),
                LAST_WEEK: t("components.dateFilter.lastWeek"),
                LAST_YEAR: t("components.dateFilter.lastYear"),
              }}
            />
          </DateAndSearchFilterWrapper>
        </RatioContainer>
      )}

      {(sectionId === 0 || sectionId === 1) && (
        <RatioContainer ratio={(resultSetLength > 0 ? 3.5 : 2.5) / 12}>
          <FilterDropDownComponent
            componentId={"category-filter"}
            placeholder={isFeedbackTabActive(sectionId) ? "filtersArea.category" : "filtersArea.category"}
            options={[
              {
                key: -1,
                label: isFeedbackTabActive(sectionId)
                  ? t(`${feedbacksStringPath}.filtersArea.clearFilter`)
                  : t(`${problemsStringPath}.filtersArea.clearFilter`),
              },
              ...selectableCategories,
            ]}
            onChangeSelectedOption={categoryDropDownHandler}
            selectedOption={categoryInput}
          />
        </RatioContainer>
      )}

      {(sectionId === 0 || sectionId === 1) && (
        <RatioContainer ratio={(resultSetLength > 0 ? 3.5 : 2.5) / 12}>
          <FilterDropDownComponent
            componentId={"status-filter"}
            placeholder={isFeedbackTabActive(sectionId) ? "filtersArea.status" : "filtersArea.status"}
            options={[
              {
                key: -1,
                label: isFeedbackTabActive(sectionId)
                  ? t(`${feedbacksStringPath}.filtersArea.clearFilter`)
                  : t(`${problemsStringPath}.filtersArea.clearFilter`),
              },
              ...selectableStatuses,
            ]}
            onChangeSelectedOption={statusDropDownHandler}
            selectedOption={statusInput}
          />
        </RatioContainer>
      )}

      <FiltersDetails>
        {sectionId === 2 && (
          <ResultsFound>
            <NumberResults>{resultSetLength}</NumberResults>
            {t(`${customerStringPath}.filtersArea.totalResultsFound`)}
          </ResultsFound>
        )}
        <ExportAndRefreshComponent
          sectionId={sectionId}
          resultSetLength={resultSetLength}
          onClickExport={showExportModal}
          onClickRefresh={onClickRefresh}
        />
      </FiltersDetails>
    </FiltersRow>
  );
};

export default React.memo(SectionFiltersComponent);

const FiltersRow = styled("div")`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
`;

const FiltersDetails = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
`;

const ResultsFound = styled("p")`
  color: ${styleTheme.palette.greyDarker};
  font-family: Vodafone Rg;
  font-size: 14px;
  display: inline-block;
`;

const NumberResults = styled("span")`
  font-weight: 700;
  margin-right: 5px;
`;

const DateAndSearchFilterWrapper = styled("div")<{ disabled?: Boolean }>`
  width: 100%;
  max-width: 370px;
  height: auto;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
