import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import i18next from "i18next";
import { array, object, string } from "yup";
import { AllowedScopeTypeEnum, AppTypeEnum, IAllowedScope, IIconAllowedScope } from "./allowed-scopes.model";
import { AllowedScopesFilterEnum } from "./components/allowed-scopes-filter.component";

/**
 * get the pastel color of a given color
 */
export const getPastelColor = (color: string) => {
  switch (color) {
    case "#47b933":
      return "#ecf8ea";
    case "#2fc56d":
      return "#e4f9ed";
    case "#12c59f":
      return "#e7f7f3";
    case "#00c3ff":
      return "#e1f8fd";
    case "#1074ff":
      return "#e3f2fd";
    case "#142c52":
      return "#e3f2fd";
    case "#6338eb":
      return "#efebfd";
    case "#b138eb":
      return "#f2e9fe";
    case "#ff7bac":
      return "#ffeaf1";
    case "#ff2a58":
      return "#ffeaf1";
    case "#ff2a2a":
      return "#ffe9e9";
    case "#ff6319":
      return "#ffefd9";
    case "#ffaa0f":
      return "#ffefe8";
    case "#55461b":
      return "#eeece8";
    case "#666666":
      return "#efefef";
    default:
      //return the color with 50% opacity
      return `${color}80`;
  }
};

/**
 * filter allowed scopes by search
 */
const filterAllowedScopesBySearch = (allowedScopes: IAllowedScope[], searchString: string) => {
  if (searchString) {
    return allowedScopes.filter(scope => scope.name.toLowerCase().includes(searchString.toLowerCase()));
  }
  return allowedScopes;
};

/**
 * Filter an array of allowed scopes using a search string
 */
export const filterScopes = (allowedScopes: IAllowedScope[], filters: Map<string, string[]>): IAllowedScope[] => {
  let newScopes = allowedScopes;
  const search = filters.get(AllowedScopesFilterEnum.SEARCH);

  if (search) {
    newScopes = filterAllowedScopesBySearch(allowedScopes, search[0]);
  }

  return newScopes;
};

/**
 * Return apps available
 */
export const getAppTypes = () => {
  return Object.values(AppTypeEnum)
    .sort()
    .map(app => ({
      label: app,
      key: app,
    }));
};

/**
 * Return types of allowed scope availables
 */
export const getAllowedScopeTypes = () => {
  return Object.values(AllowedScopeTypeEnum)
    .sort()
    .map(type => ({
      label: type,
      key: type,
    }));
};

/**
 * Return app type options
 */
export const getAppTypesOptions = (): SharedDropdownOption[] => {
  const options: SharedDropdownOption[] = [];
  Object.values(AppTypeEnum).forEach(app => {
    options.push({
      label: app,
      key: app === AppTypeEnum.CONSUMER ? AppTypeEnum.CONSUMER : AppTypeEnum.ORG,
    });
  });
  return options;
};

/**
 * Create base64 string
 */
export const createBase64 = (icon: IIconAllowedScope) => {
  return `data:image/${icon.format};base64,${icon.content}`;
};

/**
 * Form validation schema
 */
export const validateSchemaAllowedScopes = () =>
  object().shape({
    name: string().required(i18next.t("commons.mandatoryField")),
    scope: string().required(i18next.t("commons.mandatoryField")),
    type: string().required(i18next.t("commons.mandatoryField")),
    iconColor: string()
      .matches(/^#([0-9a-f]{3}|[0-9a-f]{6})$/i, i18next.t("commons.invalidColor"))
      .required(i18next.t("commons.mandatoryField")),
    iconId: string().required(i18next.t("commons.mandatoryField")),
    serviceType: array()
      .required(i18next.t("commons.mandatoryField"))
      .min(1, i18next.t("commons.mandatoryField"))
      .max(2),
  });
