import React from "react";
import styled from "styled-components";

interface IDragRulesDialogProps {
  text: string;
  height?: string;
  width?: string;
  top?: string;
  left?: string;
}

const DragRulesDialog = ({ text, height, width, top, left }: IDragRulesDialogProps) => {
  return (
    <DialogContainer height={height} width={width} top={top} left={left}>
      <RulesContainer>
        <PolicyText>{text}</PolicyText>
      </RulesContainer>
    </DialogContainer>
  );
};

export default DragRulesDialog;

const RulesContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DialogContainer = styled("div")<{ height?: string; width?: string; top?: string; left?: string }>`
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  background-color: #212121;
  position: absolute;
  width: ${props => (props.width ? props.width : "110px")};
  height: ${props => (props.height ? props.height : "50px")};
  z-index: 9999;
  margin-top: ${props => (props.top ? props.top : "-48px")};
  margin-left: ${props => (props.left ? props.left : "0px")}
  &:after {
    top: 100%;
    left: 41%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-right-color: #212121;
    border-width: 10px;
    transform: rotate(-90deg);
  }
`;

const PolicyText = styled("p")`
  font-family: Vodafone Rg;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: white;
  text-align: center;
`;
