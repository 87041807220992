import React, { HTMLProps, ReactNode } from "react";
import styled from "styled-components";
import { LanguageTranslationStatusEnum } from "../language-translations.model";

export interface ISubLanguageTranslationsChipProps extends HTMLProps<HTMLDivElement> {
  type: LanguageTranslationStatusEnum;
  children: ReactNode;
}

const LanguageTranslationsStatusChip = ({ type, children, ref, as, ...rest }: ISubLanguageTranslationsChipProps) => {
  return (
    <LanguageTranslationStatusChip type={type} {...rest}>
      {children}
    </LanguageTranslationStatusChip>
  );
};

const StatusChipContainer = styled("div")<{ type: LanguageTranslationStatusEnum }>`
  border-radius: 10px;
  padding-left: 12px;
  padding-right: 12px;
  height: 22px;
  line-height: 21px;
  font-weight: bold;
  display: inline-block;
  font-size: 14px;
  text-align: center;
  font-family: Vodafone Rg;
`;

const LanguageTranslationStatusChip = styled(StatusChipContainer)`
  ${props => {
    let backgroundColor = "";
    let fontColor = props.theme.palette.white;
    let borderColor = "";
    switch (props.type) {
      case LanguageTranslationStatusEnum.LIVE:
        backgroundColor = props.theme.palette.digitalGreen;
        borderColor = props.theme.palette.digitalGreen;
        break;
      case LanguageTranslationStatusEnum.EDIT:
        backgroundColor = props.theme.palette.white;
        fontColor = props.theme.palette.digitalGreen;
        borderColor = props.theme.palette.digitalGreen;
        break;
      case LanguageTranslationStatusEnum.DELETE:
        backgroundColor = props.theme.palette.white;
        fontColor = props.theme.palette.vodafoneRed;
        borderColor = props.theme.palette.vodafoneRed;
        break;
    }
    return `background-color: ${backgroundColor}; color: ${fontColor}; border: 1px solid ${borderColor}`;
  }}
`;

export default LanguageTranslationsStatusChip;
