import moment from "moment";
import { useTranslation } from "react-i18next";
import React from "react";
import styled from "styled-components";
import { DocValidationUtils } from "../../doc-validation/doc-validation.utils";
import { renderDate } from "../../../../shared/shared.utils";
import { IClientDocStatusHistoryEntry } from "../doc-status-history.model";

interface IDocStatusHistoryListProps {
  historyEntries?: IClientDocStatusHistoryEntry[];
}

const DocStatusHistoryList = ({ historyEntries }: IDocStatusHistoryListProps) => {
  const { t } = useTranslation();
  return (
    <ListContainer>
      {historyEntries &&
        historyEntries.map(entry => {
          return (
            <StatusEntry color={DocValidationUtils.getClientDocStatusColor(`${entry.status}`)}>
              <TextLabel>{renderDate({ date: moment(entry.statusDate) })}</TextLabel>
              <TextLabel>
                {entry.username === "System" ? t("pages.docSubmissionDetails.systemHistoryLabel") : entry.username}
              </TextLabel>
              <TextLabel color={DocValidationUtils.getClientDocStatusColor(`${entry.status}`)}>
                {t(`commons.clientDocSubmissionStatus.${entry.status}`)}
              </TextLabel>
            </StatusEntry>
          );
        })}
    </ListContainer>
  );
};

export default DocStatusHistoryList;

const ListContainer = styled("div")`
  margin-top: 20px;
`;

const StatusEntry = styled("div")<{ color: string }>`
  font-family: Vodafone Rg;
  font-size: 14px;
  line-height: 1.4;
  display: inline-block;
  border-left: 3px solid ${props => props.color};
  padding-right: 35px;
  padding-left: 9px;
  margin-bottom: 15px;
  vertical-align: top;
`;

const TextLabel = styled("div")<{ color?: string }>`
  color: ${props => (props.color ? props.color : props.theme.palette.midGrey)};
`;
