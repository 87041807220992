import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { RoutesEnum } from "../../../routes/routes.constants";
import { IApp } from "../../../shared/models/app.model";
import { RatioContainer, fadeInKeyframe, fadeOutKeyframe } from "../../../shared/shared.styled";
import { useTranslation } from "react-i18next";
import { AppFastActionsContainer } from "./app-fast-actions.component";
import { AppItemTitle } from "../apps.styled";

interface IAppListItemProps {
  item: IApp;
  showFastActions: boolean;
}

const AppListItem = ({ item, showFastActions }: IAppListItemProps) => {
  const [t] = useTranslation();
  const history = useHistory();

  /*
   * Navigates to the correspondent app details
   * */
  const appDetails = () => {
    history.push(RoutesEnum.APP_DETAILS.replace(":id", item.id));
  };
  return (
    <>
      <AppListItemContainer showFastActions={showFastActions} onClick={appDetails}>
        <AppItemTitle ratio={4 / 12}>{item.name}</AppItemTitle>
        <AppListNormalText ratio={2 / 12}>
          {item.lastUpdate ? moment(item.lastUpdate).format("DD-MM-YYYY") : "n.a."}
        </AppListNormalText>
        <AppListNormalText ratio={3 / 12}>{t(`commons.apps.enums.platform.${item.system}`)}</AppListNormalText>
      </AppListItemContainer>
    </>
  );
};

export default AppListItem;

const AppListItemContainer = styled("li")<{ showFastActions: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 75px;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  cursor: pointer;

  margin-bottom: 24px;
  padding: 0 35px 0 24px;

  :hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);

    .toggable-container {
      display: ${props => (props.showFastActions ? "none" : "flex")};
      animation: ${props => (!props.showFastActions ? fadeInKeyframe : fadeOutKeyframe)} 0.25s linear;
    }

    ${AppFastActionsContainer} {
      display: ${props => (props.showFastActions ? "flex" : "none")};
      margin-top: ${props => (props.showFastActions ? "-6px" : 0)};
      animation: ${props => (props.showFastActions ? fadeInKeyframe : fadeOutKeyframe)} 0.25s linear;
      justify-content: flex-end;
      flex: ${3.5 / 12};

      > button {
        margin-right: 12px;

        :last-of-type {
          margin-right: 0;
        }
      }
    }
  }
`;

const AppListNormalText = styled(RatioContainer)`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
`;
