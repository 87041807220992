import { Magnifier, useAlert, useFilters } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { IStoreInterface } from "../../../configs/store.config";
import { RoutesEnum } from "../../../routes/routes.constants";
import { ListHeader, ListHeaders } from "../../../shared/responsive-ui.styled";
import { LoadingText } from "../../../shared/shared.styled";
import Document from "../../configurations/rate-cards/components/document.component";
import { ServiceManagerActions } from "../service-manager.store";
import AllowedScopesApi from "./allowed-scopes.api";
import { IAllowedScope } from "./allowed-scopes.model";
import { filterScopes } from "./allowed-scopes.utils";
import AllowedScopesFilters from "./components/allowed-scopes-filter.component";
import AllowedScopeListItem from "./components/allowed-scopes-list-item.component";

/**
 * Allowed Scopes page component
 */
const AllowedScopesPage = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(true);
  const [showAlert, , setAlertProps] = useAlert();
  const { allowedScopes } = useSelector((state: IStoreInterface) => state.serviceManagerReducer);
  const [showableAllowedScopes, setShowableAllowedScopes] = React.useState<IAllowedScope[]>([]);
  const {
    filters,
    updateFilter,
    isFilterActive,
    clearFilter,
    resetFilters,
    getFilterValue,
    getFiltersQueryString,
    updateMultipleFilters,
  } = useFilters();

  /**
   * method to get the allowed scopes list
   */
  const getAllowedScopes = () => {
    AllowedScopesApi.methods
      .getAllowedScopes()
      .then(
        res => {
          dispatch(ServiceManagerActions.creators.fetchAllowedScopes(res.data));

          if (filters.size > 0) {
            setShowableAllowedScopes(filterScopes(res.data, filters));
          } else {
            setShowableAllowedScopes(res.data);
          }
        },
        () => {
          setAlertProps({
            title: t("pages.serviceManager.allowedScopes.getAllowedScopesError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * method to get the icons list
   */
  const getIcons = () => {
    AllowedScopesApi.methods.getAllowedScopesIcons().then(res => {
      dispatch(ServiceManagerActions.creators.fetchAllowedScopesIcons(res.data));
    });
  };

  React.useEffect(() => {
    if (filters.size > 0) {
      setShowableAllowedScopes(filterScopes(allowedScopes ? [...allowedScopes] : [], filters));
    } else {
      setShowableAllowedScopes(allowedScopes);
    }
  }, [filters]);

  React.useEffect(() => {
    getAllowedScopes();
    getIcons();
  }, []);

  return (
    <>
      <AllowedScopesFilters
        filters={filters}
        isFilterActive={isFilterActive}
        updateFilter={updateFilter}
        clearFilter={clearFilter}
        resetFilters={resetFilters}
        getFilterValue={getFilterValue}
        getFiltersQueryString={getFiltersQueryString}
        updateMultipleFilters={updateMultipleFilters}
      />
      <AllowedScopesListContainer>
        {!isLoading ? (
          <>
            <ListHeaders>
              <ListHeader ratio={4 / 12}>{t("pages.serviceManager.allowedScopes.table.identityDetails")}</ListHeader>
              <ListHeader ratio={2 / 12}>{t("pages.serviceManager.allowedScopes.table.scope")}</ListHeader>
              <ListHeader ratio={2 / 12}>{t("pages.serviceManager.allowedScopes.table.type")}</ListHeader>
              <ListHeader ratio={4 / 12}>{t("pages.serviceManager.allowedScopes.table.app")}</ListHeader>
            </ListHeaders>
            {allowedScopes && allowedScopes.length && showableAllowedScopes ? (
              <>
                {showableAllowedScopes.length > 0 ? (
                  showableAllowedScopes.map((allowedScope: IAllowedScope) => {
                    return (
                      <AllowedScopeListItem
                        onDelete={() => null}
                        onEdit={() => {
                          dispatch(ServiceManagerActions.creators.setAllowedScope(allowedScope));
                          history.push(
                            `${RoutesEnum.SERVICE_MANAGER_SSO_DETAILS.replace(
                              ":allowedScopeId",
                              allowedScope.id,
                            )}?editing=true`,
                          );
                        }}
                        onClickItemFn={() => {
                          dispatch(ServiceManagerActions.creators.setAllowedScope(allowedScope));
                          history.push(
                            RoutesEnum.SERVICE_MANAGER_SSO_DETAILS.replace(":allowedScopeId", allowedScope.id),
                          );
                        }}
                        item={allowedScope}
                        key={allowedScope.id}
                      />
                    );
                  })
                ) : (
                  <MagnifierContainer>
                    <Magnifier />
                    <NoResultsFoundText>
                      {t("pages.serviceManager.allowedScopes.table.noSearchResults")}
                    </NoResultsFoundText>
                  </MagnifierContainer>
                )}
              </>
            ) : (
              <EmptyState>
                <Document />
                <SelectText>{t("pages.serviceManager.allowedScopes.table.emptyState")}</SelectText>
              </EmptyState>
            )}
          </>
        ) : (
          <LoadingText>{t("commons.loadingResults")}</LoadingText>
        )}
      </AllowedScopesListContainer>
    </>
  );
};

export default AllowedScopesPage;

const AllowedScopesListContainer = styled("div")`
  margin-bottom: 50px;
  margin-top: 31px;
`;

const MagnifierContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 50px;
  svg {
    width: 140px;
    height: 140px;
    margin-bottom: 24px;
    stroke: red;
  }
`;

const NoResultsFoundText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.midGrey};
`;

const EmptyState = styled("div")`
  margin-top: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const SelectText = styled("div")`
  font-family: "Vodafone Rg";
  width: 400px;
  height: 51px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  margin-top: 35px;
`;
