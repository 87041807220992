import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { PrimaryButton, TextInput } from "@wit/mpesa-ui-components";
import { Formik, FormikHelpers } from "formik";
import { useDispatch } from "react-redux";
import { object, string } from "yup";
import { Row } from "../../../../shared/shared.styled";
import i18next from "i18next";
import { ICommandId } from "../../../../shared/models/command-id.model";
import { SupportActions } from "../../support.store";
import SupportApi from "../../support.api";

interface IEditCommandIdModalProps {
  commandId: ICommandId;
  hideModal: () => void;
}

const getValidationSchema = () => {
  return object().shape({
    commandName: string().required(i18next.t("pages.support.commandIds.modals.editCommandIdModal.errors.required")),
    commandValue: string().required(i18next.t("pages.support.commandIds.modals.editCommandIdModal.errors.required")),
  });
};

const EditCommandIdModal = ({ commandId, hideModal }: IEditCommandIdModalProps) => {
  // Hooks initializations
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const _persistCommandId = (values: ICommandId, actions: FormikHelpers<ICommandId>) => {
    if (values === commandId) {
      hideModal();
    } else {
      actions.setSubmitting(true);
      SupportApi.methods
        .updateCommandId(values)
        .finally(() => actions.setSubmitting(false))
        .then(
          () => {
            dispatch(SupportActions.Creators.updateCommandId(values));
            hideModal();
          },
          () => {
            // TODO: Error handling
          },
        );
    }
  };

  return (
    <EditModalContainer>
      <EditModalTitle>{t("pages.support.commandIds.modals.editCommandIdModal.title")}</EditModalTitle>
      <div>
        <Formik
          validateOnChange={false}
          initialValues={commandId}
          onSubmit={_persistCommandId}
          validationSchema={getValidationSchema()}
          render={({ handleChange, values, handleSubmit, isSubmitting, errors, setFieldValue }) => {
            return (
              <form onSubmit={handleSubmit}>
                <TextInput
                  value={values.commandName}
                  onChange={handleChange}
                  disabled
                  name="commandName"
                  title={t("pages.support.commandIds.modals.editCommandIdModal.commandName.label")}
                  placeholder={t("pages.support.commandIds.modals.editCommandIdModal.commandName.placeholder")}
                  style={{ marginBottom: 24 }}
                  error={errors.commandName}
                  required={true}
                />
                <TextInput
                  value={values.commandValue}
                  onChange={handleChange}
                  name="commandValue"
                  error={errors.commandValue}
                  title={"New Value"}
                  placeholder={t("pages.support.commandIds.modals.editCommandIdModal.commandValue.placeholder")}
                  style={{ marginBottom: 24 }}
                  required={true}
                />
                <Row style={{ justifyContent: "flex-end" }}>
                  <div style={{ marginRight: 12 }}>
                    <PrimaryButton
                      onClick={hideModal}
                      disabled={isSubmitting}
                      titleLabel={t("pages.support.commandIds.modals.editCommandIdModal.buttons.cancel")}
                    />
                  </div>
                  <div>
                    <PrimaryButton
                      redTheme={true}
                      loading={isSubmitting}
                      type="submit"
                      titleLabel={t("pages.support.commandIds.modals.editCommandIdModal.buttons.saveChanges")}
                    />
                  </div>
                </Row>
              </form>
            );
          }}
        />
      </div>
    </EditModalContainer>
  );
};

export default EditCommandIdModal;

const EditModalContainer = styled("div")`
  border-radius: 6px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  padding: 24px;
`;

const EditModalTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 24px;
`;
