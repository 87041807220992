import { Table } from "@wit/mpesa-ui-components";
import React, { FunctionComponent, ReactNode } from "react";
import LoadingComponent from "../../../../shared/components/loading-component/LoadingComponent";
import { IIdentity } from "../identity-converter.model";
import { IdentityConverterUtils } from "../identity-converter.utils";
import { EmptyTableComponent } from "./empty-table.component";
import { LoadingContainer, TableContainer } from "../identity-converter.styled";

interface IIdentityTableComponentProps {
  tableData: IIdentity[];
  emptyStateComponent: ReactNode;
  isLoading: boolean;
}

/**
 * This returns an identity table
 * @returns
 */
const IdentityTableComponent: FunctionComponent<IIdentityTableComponentProps> = ({
  tableData,
  emptyStateComponent,
  isLoading,
}) => {
  return (
    <TableContainer>
      {isLoading ? (
        <LoadingContainer>
          <div>
            <LoadingComponent />
          </div>
        </LoadingContainer>
      ) : (
        <Table<IIdentity>
          isStripedTable={true}
          columns={IdentityConverterUtils.getTableColumns()}
          values={tableData}
          emptyStateComponent={<EmptyTableComponent>{emptyStateComponent}</EmptyTableComponent>}
        />
      )}
    </TableContainer>
  );
};

export default IdentityTableComponent;
