import produce from "immer";
import { PayloadAction } from "@wit/mpesa-ui-components/lib/hooks/table-state-management";
import { IBank } from "./bank.model";

export const BanksActions = {
  types: {
    GET_BANKS: "GET_BANKS",
    GET_LAST_VERSION: "GET_LAST_VERSION",
  },
  creators: {
    getBanksAction: (banks: IBank[], banksStatus: string) => ({
      type: BanksActions.types.GET_BANKS,
      payload: {
        banks,
        banksStatus,
      },
    }),
    getBanksLastVersionAction: (banks: IBank[]) => ({
      type: BanksActions.types.GET_LAST_VERSION,
      payload: {
        banks,
      },
    }),
  },
};
export interface IBanksReducerInterface {
  banks: IReducerData<IBank[]>;
  banksStatus: string;
}
export interface IReducerData<T> {
  data: T;
  lastVersion: T;
}

const initialState: IBanksReducerInterface = {
  banks: {
    data: [],
    lastVersion: [],
  },
  banksStatus: "",
};

export const banksReducer = produce(
  (draft: IBanksReducerInterface, action: PayloadAction<{ banks: IBank[]; banksStatus: string }>) => {
    switch (action.type) {
      case BanksActions.types.GET_BANKS: {
        const { banks, banksStatus } = action.payload;
        draft.banks.data = banks;
        draft.banksStatus = banksStatus;
        return;
      }
      case BanksActions.types.GET_LAST_VERSION: {
        const { banks } = action.payload;
        draft.banks.lastVersion = banks;
        return;
      }
      default:
        return draft;
    }
  },
  initialState,
);
