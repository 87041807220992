import React, { HTMLProps, ReactNode } from "react";
import styled from "styled-components";

export interface IDynamicScreenStatusChipProps extends HTMLProps<HTMLDivElement> {
  isActive: boolean;
  children: ReactNode;
}

const DynamicScreenStatusChip = ({ isActive, children, ref, as, ...rest }: IDynamicScreenStatusChipProps) => {
  return (
    <StatusChipContainer isActive={isActive} {...rest}>
      {children}
    </StatusChipContainer>
  );
};

export default DynamicScreenStatusChip;

const StatusChipContainer = styled("div")<{ isActive: boolean }>`
  border-radius: 10px;
  padding-left: 12px;
  padding-right: 12px;
  height: 22px;
  line-height: 21px;
  font-weight: bold;
  display: inline-block;
  font-size: 14px;
  text-align: center;
  font-family: Vodafone Rg;
  background-color: ${props => (props.isActive ? props.theme.palette.digitalGreen : props.theme.palette.freshOrange)};
  color: white;
  border: 1px ${props => (props.isActive ? props.theme.palette.digitalGreen : props.theme.palette.freshOrange)} solid;
  @media (max-width: 768px) {
    font-size: 12px;
    padding-left: 6px;
    padding-right: 6px;
  }
`;
