import { AxiosPromise } from "axios";
import ApiClient from "../../../configs/axios.config";
import { IDynamicScreen, IDynamicScreenCategory } from "./dynamic-screens.model";

/*
 * Dynamic screens API methods
 * */
const DynamicScreensApi = {
  routes: {
    getDynamicScreens: "/v1/ipg_services",
    getDynamicScreen: "/v1/ipg_services/:id",
    getSections: "/v1/ipg_services/sections",
    getCategories: "/v1/ipg_services/categories",
    getIcons: "/v1/ipg_services/icons",
    getCanBeHighlighted: "/v1/ipg_services/canbehighlighted",
  },
  methods: {
    getDynamicScreens: (): AxiosPromise => {
      return ApiClient.get(DynamicScreensApi.routes.getDynamicScreens, {});
    },
    getDynamicScreen: (id: string): AxiosPromise<IDynamicScreen> => {
      return ApiClient.get(DynamicScreensApi.routes.getDynamicScreen.replace(":id", id), {});
    },
    getSections: (): AxiosPromise<{ items: string[] }> => {
      return ApiClient.get(DynamicScreensApi.routes.getSections, {});
    },
    getDynamicScreensCategories: (): AxiosPromise<{ items: IDynamicScreenCategory[] }> => {
      return ApiClient.get(DynamicScreensApi.routes.getCategories, {});
    },
    deleteDynamicScreen: (id: string): AxiosPromise => {
      return ApiClient.delete(DynamicScreensApi.routes.getDynamicScreen.replace(":id", id), {});
    },
    editDynamicScreen: (d: any, id: string): AxiosPromise => {
      return ApiClient.put(DynamicScreensApi.routes.getDynamicScreen.replace(":id", id), d);
    },
    createDynamicScreen: (d: any): AxiosPromise => {
      return ApiClient.post(DynamicScreensApi.routes.getDynamicScreen.replace("/:id", ""), d);
    },
    getIcons: (): AxiosPromise => {
      return ApiClient.get(DynamicScreensApi.routes.getIcons);
    },
    getCanBeHighlighted: (): AxiosPromise<{ canBeHighlighted: boolean }> => {
      return ApiClient.get(DynamicScreensApi.routes.getCanBeHighlighted);
    },
  },
};

export default DynamicScreensApi;
