import { EditIcon, FastActionButton, UndoIcon, TrashIcon } from "@wit/mpesa-ui-components";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { ColumnProps } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import i18next from "i18next";
import React from "react";
import { IConfig } from "../../../../app.component";
import { ICurrency } from "../../../../shared/shared.interfaces";
import { IconContainer } from "../../../../shared/shared.styled";
import { deepCopyObject, getDefaultTranslation, TableButtonsContainer } from "../../../../shared/shared.utils";
import {
  IConfigurationFields,
  ISubBundle,
  SubBundleStatus,
  ITranslatableObject,
  IMiddlewareSubBundle,
  IAddSubBundleRequest,
  IEditSubBundleRequest,
} from "../bundles.model";
import SubBundleStatusChip from "./components/sub-bundle-status.component";
import { SubBundlesFiltersEnum } from "./components/sub-bundles-list-filters.component";

/**
 * Filters a list of sub-bundles based on a map of filters
 * @param subBundles
 * @param filters
 */
export const filterSubBundles = (subBundles: ISubBundle[], filters: Map<string, string[]>) => {
  const searchFilter = filters.get(SubBundlesFiltersEnum.SEARCH);
  const validityFilter = filters.get(SubBundlesFiltersEnum.VALIDITY);
  const networkFilter = filters.get(SubBundlesFiltersEnum.NETWORK);
  const filterRedBundles = subBundles;
  subBundles = filterSubBundlesByValidity(subBundles, validityFilter ? validityFilter[0] : undefined);
  subBundles = filterSubBundlesByNetwork(subBundles, networkFilter ? networkFilter[0] : undefined);

  if (searchFilter) {
    subBundles = filterSubBundlesBySearchQuery(subBundles, searchFilter[0]);
  }
  return subBundles;
};

/**
 * Filters a list of middleware sub-bundles based on a map of filters
 * @param subBundles
 * @param filters
 */
export const filterMiddlewareSubBundles = (subBundles: IMiddlewareSubBundle[], filters: Map<string, string[]>) => {
  const searchFilter = filters.get(SubBundlesFiltersEnum.SEARCH);
  if (searchFilter) {
    subBundles = filterMiddlewareSubBundlesBySearchQuery(subBundles, searchFilter[0]);
  }
  return subBundles;
};

/**
 * Filter sub-bundles by search query
 * @param subBundles
 * @param searchQuery
 */
export const filterSubBundlesBySearchQuery = (subBundles: ISubBundle[], searchQuery: string) => {
  if (!!searchQuery) {
    return subBundles.filter(sub => sub.shortcode.toLowerCase().includes(searchQuery.toLowerCase()));
  }
  return subBundles;
};

/**
 * Filter middleware sub-bundles by search query
 */
export const filterMiddlewareSubBundlesBySearchQuery = (subBundles: IMiddlewareSubBundle[], searchQuery: string) => {
  if (!!searchQuery) {
    return subBundles.filter(sub =>
      getDefaultTranslation(sub.name.translations)
        .toLowerCase()
        .includes(searchQuery.toLowerCase()),
    );
  }
  return subBundles;
};

/**
 * Filter sub-bundles by validity
 * @param subBundles
 * @param validity
 */
export const filterSubBundlesByValidity = (subBundles: ISubBundle[], validity: string | undefined) => {
  if (validity) {
    return subBundles.filter(sub => sub.frequency === validity);
  }
  return subBundles;
};

/**
 * Filter sub-bundles by network
 * @param subBundles
 * @param network
 */
export const filterSubBundlesByNetwork = (subBundles: ISubBundle[], network: string | undefined) => {
  if (network) {
    return subBundles.filter(sub => sub.network === network);
  }
  return subBundles;
};

/**
 * Gets a translation of a given configuration field
 * @param str
 * @param translatableObjects
 */
export const getConfigurationFieldTranslation = (str: string, translatableObjects: ITranslatableObject[]): string => {
  let translation = "n.a.";
  if (translatableObjects) {
    const obj = translatableObjects.find(o => o.key === str);
    if (obj) {
      translation = getDefaultTranslation(obj.translations);
    }
  }
  return translation;
};

/**
 * Sub bundles list utils
 */
export const SubBundlesUtils = {
  getSubBundlesListColumns: (configurationFields: IConfigurationFields, config: IConfig | undefined): ColumnProps[] => {
    const defaultEmptyColumn = { formKey: undefined, ratio: 0, isEditable: false, label: "" };

    const dynamicColumns: ColumnProps[] = [{ ...defaultEmptyColumn }];
    const dynamicOperationsColumns: ColumnProps[] = [{ ...defaultEmptyColumn }];
    let identifierCodeColumn: ColumnProps = { ...defaultEmptyColumn };

    let maxRatio = 10;
    // Calculate the ratio based on how many columns will be displayed
    config!.bundles &&
      Object.keys(config!.bundles).map(conf => {
        if (conf !== "useUpsell") {
          maxRatio = config!.bundles[
            (conf as "showCalls") ||
              "showData" ||
              "showFrequency" ||
              "showNetworks" ||
              "showSMS" ||
              "useUpsell" ||
              "showIdentifierCode"
          ]
            ? maxRatio
            : maxRatio - 1;
        }
      });

    if (config!.bundles?.showIdentifierCode) {
      identifierCodeColumn = {
        formKey: "identifierCode",
        ratio: 1 / maxRatio,
        isEditable: false,
        label: i18next.t("pages.bundleDetails.table.identifierCode"),
        changeFunction: (content: React.ReactNode) => {
          return <span id={`cost-${content}`}>{content}</span>;
        },
      };
    }
    if (config!.bundles?.showFrequency) {
      dynamicColumns.push({
        formKey: "frequency",
        ratio: 1 / maxRatio,
        isEditable: false,
        label: i18next.t("pages.bundleDetails.table.validity"),
        changeFunction: (content: string) => {
          return (
            <span id={`validity-${getConfigurationFieldTranslation(content, configurationFields.frequency)}`}>
              {getConfigurationFieldTranslation(content, configurationFields.frequency)}
            </span>
          );
        },
      });
    }
    if (config!.bundles?.showNetworks) {
      dynamicColumns.push({
        formKey: "network",
        ratio: 1 / maxRatio,
        isEditable: false,
        label: i18next.t("pages.bundleDetails.table.network"),
        changeFunction: (content: string) => {
          return (
            <span id={`network-${getConfigurationFieldTranslation(content, configurationFields.plan)}`}>
              {getConfigurationFieldTranslation(content, configurationFields.plan)}
            </span>
          );
        },
      });
    }

    if (config!.bundles?.showSMS) {
      dynamicOperationsColumns.push({
        formKey: "content",
        ratio: 1 / maxRatio,
        isEditable: false,
        label: i18next.t("pages.bundleDetails.table.smsDetails"),
        changeFunction: content => {
          return (
            <span
              id={`sms-details-${
                content &&
                content.sms &&
                content.sms.translations &&
                getDefaultTranslation(content.sms.translations) !== "" &&
                getDefaultTranslation(content.sms.translations)
                  ? getDefaultTranslation(content.sms.translations)
                  : "empty"
              }`}
              style={{ color: styleTheme.palette.turquoiseBlue }}
            >
              {content && content.sms && content.sms.translations
                ? getDefaultTranslation(content.sms.translations)
                : null}
            </span>
          );
        },
      });
    }

    if (config!.bundles?.showCalls) {
      dynamicOperationsColumns.push({
        formKey: "content",
        ratio: 1 / maxRatio,
        isEditable: false,
        label: i18next.t("pages.bundleDetails.table.callDetails"),
        changeFunction: content => {
          return (
            <span
              id={`call-details-${
                content &&
                content.calls &&
                content.calls.translations &&
                getDefaultTranslation(content.calls.translations) !== "" &&
                getDefaultTranslation(content.calls.translations)
                  ? getDefaultTranslation(content.calls.translations)
                  : "empty"
              }`}
              style={{ color: styleTheme.palette.turquoiseBlue }}
            >
              {content && content.calls && content.calls.translations
                ? getDefaultTranslation(content.calls.translations)
                : null}
            </span>
          );
        },
      });
    }

    if (config!.bundles?.showData) {
      dynamicOperationsColumns.push({
        formKey: "content",
        ratio: 1 / maxRatio,
        isEditable: false,
        label: i18next.t("pages.bundleDetails.table.dataDetails"),
        changeFunction: content => {
          return (
            <span
              id={`data-details-${
                content &&
                content.data &&
                content.data.translations &&
                getDefaultTranslation(content.data.translations) !== "" &&
                getDefaultTranslation(content.data.translations)
                  ? getDefaultTranslation(content.data.translations)
                  : "empty"
              }`}
              style={{ color: styleTheme.palette.turquoiseBlue }}
            >
              {content && content.data && content.data.translations
                ? getDefaultTranslation(content.data.translations)
                : null}
            </span>
          );
        },
      });
    }
    return [
      identifierCodeColumn,
      {
        formKey: "cost",
        ratio: 1 / maxRatio,
        isEditable: false,
        label: i18next.t("pages.bundleDetails.table.cost"),
        changeFunction: content => {
          return <span id={`cost-${content}`}>{content}</span>;
        },
      },
      {
        formKey: "shortcode",
        ratio: 1 / maxRatio,
        isEditable: false,
        label: i18next.t("pages.bundleDetails.table.shortCode"),
        changeFunction: content => {
          return <span id={`shortcode-${content}`}>{content}</span>;
        },
      },
      ...dynamicColumns,
      {
        formKey: "recipientType",
        ratio: 1 / maxRatio,
        isEditable: false,
        label: i18next.t("pages.bundleDetails.table.options"),
        changeFunction: content => {
          return (
            <span id={`options-${i18next.t(`pages.bundleDetails.recipientType.${content}`)}`}>
              {i18next.t(`pages.bundleDetails.recipientType.${content}`)}
            </span>
          );
        },
      },
      ...dynamicOperationsColumns,
      {
        formKey: "status",
        ratio: 1 / maxRatio,
        isEditable: false,
        label: i18next.t("pages.bundleDetails.table.status"),
        changeFunction: (content, sub: ISubBundle) => {
          return (
            <div
              className={"status-content"}
              style={{ paddingTop: content !== SubBundleStatus.DELETE ? "10px" : "0px" }}
            >
              {content === SubBundleStatus.DELETE ? (
                <span style={{ color: styleTheme.palette.vodafoneRed }}>
                  {i18next.t(`commons.apps.enums.subBundleStatus.${content}`)}
                </span>
              ) : (
                <SubBundleStatusChip type={content as any} id={`sub-bundle-status-${sub.shortcode}`}>
                  {i18next.t(`commons.apps.enums.subBundleStatus.${content}`)}
                </SubBundleStatusChip>
              )}
            </div>
          );
        },
      },
    ];
  },
  getMiddlewareSubBundlesListColumns: (configurationFields: IConfigurationFields): ColumnProps[] => {
    return [
      {
        formKey: "name",
        ratio: 2 / 9,
        isEditable: false,
        label: i18next.t("pages.bundleDetails.table.name"),
        changeFunction: content => {
          return (
            <span id={`name-${getDefaultTranslation(content.translations)}`}>
              {getDefaultTranslation(content.translations)}
            </span>
          );
        },
      },
      {
        formKey: "providerData",
        ratio: 3 / 9,
        isEditable: false,
        label: i18next.t("pages.bundleDetails.table.interactiveChannel"),
        changeFunction: content => {
          return <span id={`interactive-channel-${content.interactiveChannel}`}>{content.interactiveChannel}</span>;
        },
      },
      {
        formKey: "providerData",
        ratio: 5 / 9,
        isEditable: false,
        label: i18next.t("pages.bundleDetails.table.interactionPoint"),
        changeFunction: content => {
          return <span id={`interaction-point-${content.interactionPoint}`}>{content.interactionPoint}</span>;
        },
      },
      {
        formKey: "status",
        ratio: 2 / 9,
        isEditable: false,
        label: i18next.t("pages.bundleDetails.table.status"),
        changeFunction: (content, sub: IMiddlewareSubBundle) => {
          return (
            <div
              className={"status-content"}
              style={{ paddingTop: content !== SubBundleStatus.DELETE ? "10px" : "0px" }}
            >
              {content === SubBundleStatus.DELETE ? (
                <span style={{ color: styleTheme.palette.vodafoneRed, textOverflow: "ellipsis", overflow: "hidden" }}>
                  {i18next.t(`commons.apps.enums.subBundleStatus.${content}`)}
                </span>
              ) : (
                <SubBundleStatusChip
                  type={content as any}
                  id={`sub-bundle-status-${getDefaultTranslation(sub.name.translations)}`}
                >
                  {i18next.t(`commons.apps.enums.subBundleStatus.${content}`)}
                </SubBundleStatusChip>
              )}
            </div>
          );
        },
      },
    ];
  },
};

/**
 * Sub bundles list quick actions
 * @param value
 * @param buttonActions
 * @param themeContext
 */
export const renderSubBundlesListQuickActions = <T extends unknown>(
  value: T,
  buttonActions: {
    edit: { onClick: (row: T) => any };
    delete: { onClick: (row: T) => any };
    restore: { onClick: (row: T) => any };
  },
  themeContext: any,
  nameId?: string,
) => {
  return (
    <TableButtonsContainer>
      {(value as ISubBundle | IMiddlewareSubBundle).status !== SubBundleStatus.DELETE ? (
        <>
          <FastActionButton
            iconComponent={
              <IconContainer
                size={16}
                color={themeContext.palette.turquoiseBlue}
                id={`edit-sub-bundle-${nameId ? nameId : (value as ISubBundle).shortcode}`}
              >
                <EditIcon />
              </IconContainer>
            }
            onClick={() => buttonActions.edit.onClick(value)}
            label={i18next.t("pages.bundleDetails.table.edit")}
          />
          <FastActionButton
            iconComponent={
              <IconContainer
                size={16}
                color={themeContext.palette.errorRed}
                id={`delete-sub-bundle-${nameId ? nameId : (value as ISubBundle).shortcode}`}
              >
                <TrashIcon />
              </IconContainer>
            }
            onClick={() => buttonActions.delete.onClick(value)}
            label={i18next.t("pages.bundleDetails.table.delete")}
          />
        </>
      ) : (
        <>
          {(value as ISubBundle).livePublished ? (
            <FastActionButton
              iconComponent={
                <IconContainer
                  size={16}
                  color={themeContext.palette.digitalGreen}
                  id={`delete-sub-bundle-${nameId ? nameId : (value as ISubBundle).shortcode}`}
                >
                  <UndoIcon />
                </IconContainer>
              }
              onClick={() => buttonActions.restore.onClick(value)}
              label={i18next.t("pages.bundleDetails.table.restore")}
            />
          ) : null}
        </>
      )}
    </TableButtonsContainer>
  );
};

/**
 * Validity dropdown options
 * @param configurationFields
 * @param includeClearFilter
 */
export const getValidityOptions = (
  configurationFields: IConfigurationFields,
  includeClearFilter?: boolean,
): SharedDropdownOption[] => {
  const options: SharedDropdownOption[] = [];
  configurationFields.frequency.map((obj: ITranslatableObject) => {
    options.push({
      label: getDefaultTranslation(obj.translations),
      key: obj.key,
    });
  });
  if (includeClearFilter) {
    options.push({
      label: i18next.t(`commons.apps.enums.platform.seeAll`),
      key: undefined,
    });
  }
  return options;
};

/**
 * Network dropdown options
 * @param configurationFields
 * @param includeClearFilter
 */
export const getNetworkOptions = (
  configurationFields: IConfigurationFields,
  includeClearFilter?: boolean,
): SharedDropdownOption[] => {
  const options: SharedDropdownOption[] = [];
  configurationFields.plan.map((obj: ITranslatableObject) => {
    options.push({
      label: getDefaultTranslation(obj.translations),
      key: obj.key,
    });
  });
  if (includeClearFilter) {
    options.push({
      label: i18next.t(`commons.apps.enums.platform.seeAll`),
      key: undefined,
    });
  }
  return options;
};

/**
 * Get currencies dropdown
 * @param currencies
 */
export const getCurrenciesDropdown = (currencies: ICurrency[] | undefined) => {
  if (currencies) {
    return currencies.map((i: any) => {
      return {
        key: i,
        label: i,
      };
    });
  } else {
    return [{ key: "", label: "" }];
  }
};

/**
 * Cleans the a sub bundle creation or update request so it no longer sends unused data by the market
 * @param {IAddSubBundleRequest} req
 * @param {IConfig | undefined} config
 * @returns {{voiceAllNet?: number, cost: string, recipientType: "SELF" | "OTHERS" | "ANY", dataAllNet?: number, currency: string,
 *   shortcode: string, voiceOnNet?: number, smsAllNet?: number, content: {type: "BUNDLE_PLAN" | "BUNDLE_DESCRIPTION", sms: {translations:
 *   any}, data: {translations: any}, calls: {translations: any}}, frequency?: string, network?: string}}
 */
export const cleanupSubBundleRequest = (
  req: IAddSubBundleRequest | IEditSubBundleRequest,
  config: IConfig | undefined,
) => {
  const temp = deepCopyObject(req);
  if (!config!.bundles.showCalls) {
    delete temp.content.calls;
  }
  if (!config!.bundles.showSMS) {
    delete temp.content.sms;
  }
  if (!config!.bundles.showData) {
    delete temp.content.data;
  }
  if (!config!.bundles.showNetworks) {
    delete temp.network;
  }
  if (!config!.bundles.showFrequency) {
    delete temp.frequency;
  }
  return temp;
};
