import { ICategory } from "./categories.model";
import { CategoriesFilterEnum } from "./components/categories-filter.component";

/**
 * filter categories by search
 */
const filterCategoriesBySearch = (categories: ICategory[], searchString: string, selectedLanguage: string) => {
  if (searchString) {
    if (selectedLanguage === "en") {
      return categories.filter(category => category.categoryName.toLowerCase().includes(searchString.toLowerCase()));
    } else {
      return categories.filter(category =>
        category.categoryNameTranslations && category.categoryNameTranslations[selectedLanguage]
          ? category.categoryNameTranslations[selectedLanguage].toLowerCase().includes(searchString.toLowerCase())
          : category.categoryName.toLowerCase().includes(searchString.toLowerCase()),
      );
    }
  }
  return categories;
};

/**
 * Filter an array of categories using a search string
 */
export const filterCategories = (
  categories: ICategory[],
  filters: Map<string, string[]>,
  selectedLanguage: string,
): ICategory[] => {
  let newCategories = categories;
  const search = filters.get(CategoriesFilterEnum.SEARCH);

  if (search) {
    newCategories = filterCategoriesBySearch(categories, search[0], selectedLanguage);
  }

  return newCategories;
};
