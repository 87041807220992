import React from "react";
import { Formik, FormikHelpers } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import CampaignProgressAndSubmit from "./campaign-progress-and-submit.component";
import { Row } from "../../../../../shared/shared.styled";
import {
  IReferralCampaign,
  IReward,
  SenderTypeRewardEnum,
  TargetEnum,
  TypeScreenEnum,
  Screen,
  IScreen,
} from "../../referral-campaigns.model";
import SenderScreenForm from "../../components/sender-screen-form.component";
import SenderScreenPreview from "../../components/sender-screen-preview.component";
import { validateSchemaSenderScreen, validateTranslationsSenderScreen } from "../../referral-campaigns.utils";
import { PageContent } from "../../../../../shared/responsive-ui.styled";
import GenericLanguageSelector from "../../../../../shared/components/generic-language-selector.component";
import { ILanguage } from "../../../../../shared/models/language.model";
import useEmptyLanguageWarningModal from "../../../../../shared/hooks/use-empty-language-warning-modal.hook";

interface ICreateSenderScreenProps {
  languages: ILanguage[];
  selectedLanguage: string;
  setSelectedLanguage: React.Dispatch<React.SetStateAction<string>>;
  formValues: IReferralCampaign;
  onSubmit: (values: IReferralCampaign) => void;
  setStep: (step: number, values: IReferralCampaign) => void;
}

/**
 * CreateSenderScreen page component
 */
const CreateSenderScreen = ({
  languages,
  selectedLanguage,
  setSelectedLanguage,
  formValues,
  onSubmit,
  setStep,
}: ICreateSenderScreenProps) => {
  const [t] = useTranslation();
  const [tab, setTab] = React.useState(0);

  const { warningModalOpen, showWarning, setShowWarning } = useEmptyLanguageWarningModal(languages || []);
  /**
   * Submit method wrapper to show warning if needed
   * @param values
   */
  const submitWrapper = (values: IReferralCampaign) => {
    if (showWarning) {
      setShowWarning(false);
      warningModalOpen(handleSubmitSenderScreen, values);
    } else {
      handleSubmitSenderScreen(values);
    }
  };

  /**
   * get initial values
   * this ones could be different depending on last form
   */
  const getInitialValues = () => {
    //if screen were not initializated yet
    if (!formValues.screens) {
      const screens =
        (formValues.rewards &&
          formValues.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) &&
          (formValues.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) as IReward).type ===
            SenderTypeRewardEnum.NONE) ||
        ((formValues.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) as IReward).type ===
          SenderTypeRewardEnum.SINGLE &&
          (formValues.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) as IReward).maxInvites ===
            -1)
          ? [new Screen(TypeScreenEnum.SENDER_INITIAL, languages)]
          : [new Screen(TypeScreenEnum.SENDER_INITIAL, languages), new Screen(TypeScreenEnum.SENDER_FINAL, languages)];

      formValues.screens = screens;
    } else {
      //if the user change backwards and change the sender reward to NONE
      //this value has to be removed
      const receiverScreen = {
        ...(formValues.screens.find(screen => screen.type === TypeScreenEnum.RECEIVER) as IScreen),
      };
      if (
        (formValues.rewards &&
          formValues.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) &&
          (formValues.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) as IReward).type ===
            SenderTypeRewardEnum.NONE) ||
        ((formValues.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) as IReward).type ===
          SenderTypeRewardEnum.SINGLE &&
          (formValues.rewards.find((reward: IReward) => reward.target === TargetEnum.SENDER) as IReward).maxInvites ===
            -1)
      ) {
        formValues.screens = [
          formValues.screens.find(screen => screen.type === TypeScreenEnum.SENDER_INITIAL) as IScreen,
        ];
      } else if (!formValues.screens.find(screen => screen.type === TypeScreenEnum.SENDER_FINAL)) {
        formValues.screens = [
          formValues.screens.find(screen => screen.type === TypeScreenEnum.SENDER_INITIAL) as IScreen,
          new Screen(TypeScreenEnum.SENDER_FINAL, languages),
        ];
      }
      if (
        receiverScreen.type &&
        !(formValues.screens.find(screen => screen.type === TypeScreenEnum.RECEIVER) as IScreen)
      ) {
        formValues.screens = formValues.screens.concat(receiverScreen);
      }
    }
    return formValues;
  };

  /**
   * Performs the create request
   * @param {IReferralCampaign} values
   * @param {FormikHelpers<IReferralCampaign>} actions
   */
  const handleSubmitSenderScreen = (values: IReferralCampaign) => {
    onSubmit(values);
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={submitWrapper}
      validate={values => validateSchemaSenderScreen(values, languages, setShowWarning)}
      render={({ values, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue, errors }) => (
        <PageContent className={"display-column"}>
          <form onSubmit={handleSubmit}>
            <CampaignProgressAndSubmit
              stepTitle={t("pages.referralCampaigns.createCampaign.senderScreen.createSenderScreen")}
              stepDescription={t("pages.referralCampaigns.createCampaign.step").replace("{0}", "3")}
              buttonLabel={t("pages.referralCampaigns.createCampaign.continue")}
              step={3}
              totalOfSteps={4}
              setStep={step => setStep(step, values)}
              onSubmitFn={() => validateTranslationsSenderScreen(values, languages, setSelectedLanguage)}
            />
            <SenderScreenFormPreviewContainer>
              <SenderScreenFormContainer>
                <SenderScreenForm
                  firstTitle={
                    <TitlesContainer>
                      <Row>
                        <FormTitle>{t("pages.referralCampaigns.createCampaign.senderScreen.title")}</FormTitle>
                        <div style={{ marginTop: "auto", marginLeft: "auto" }}>
                          <GenericLanguageSelector
                            selectedLanguage={selectedLanguage}
                            availableLanguages={languages.map(lang => lang.code)}
                            changeSelectedLanguage={(lang: any) => setSelectedLanguage(lang.key)}
                          ></GenericLanguageSelector>
                        </div>
                      </Row>
                      <FormSubTitle>
                        {t("pages.referralCampaigns.createCampaign.senderScreen.initialScreen")}
                      </FormSubTitle>
                    </TitlesContainer>
                  }
                  secondTitle={
                    <FormSubTitle>{t("pages.referralCampaigns.createCampaign.senderScreen.finalScreen")}</FormSubTitle>
                  }
                  isEditing={true}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  language={selectedLanguage}
                  setTab={setTab}
                  isLanguageRequired={languages.find(l => l.code === selectedLanguage)?.mandatory}
                ></SenderScreenForm>
              </SenderScreenFormContainer>
              <SenderScreenPreviewContainer>
                <SenderScreenPreview
                  values={values}
                  language={selectedLanguage}
                  tab={tab}
                  setTab={setTab}
                ></SenderScreenPreview>
              </SenderScreenPreviewContainer>
            </SenderScreenFormPreviewContainer>
          </form>
        </PageContent>
      )}
    />
  );
};

export default CreateSenderScreen;

const SenderScreenFormPreviewContainer = styled("div")`
  display: flex;
  flex-direction: row;
  margin-top: 56px;
  flex-wrap: wrap;
`;
const SenderScreenFormContainer = styled("div")`
  flex: 2;
  > div > div > span {
    width: 100%;
  }
`;

const SenderScreenPreviewContainer = styled("div")`
  flex: 1;
  margin-left: 30px;
`;

const FormTitle = styled("span")`
  font-family: "Vodafone Rg";
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 16px;
`;

const FormSubTitle = styled("span")`
  font-family: "Vodafone Rg";
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.darkGrey};
`;

const TitlesContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;
