import React from "react";

const GatewayIcon = (props: React.HTMLProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7 14.498h10a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5H7a1.5 1.5 0 0 1-1.5-1.5v-3a1.5 1.5 0 0 1 1.5-1.5zm.989-9.34a5.662 5.662 0 0 1 8.007 0h0M9.571 6.486a3.439 3.439 0 0 1 4.864 0h0m-2.426 1.991a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM12 7.998v6.5m6.5 3h3m-19 0h3"
        stroke="#12C59F"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GatewayIcon;
