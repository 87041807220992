import {
  CheckIcon,
  FastActionButton,
  ModalTypeEnum,
  PrimaryButton,
  UndoIcon,
  useAlert,
  useModal,
} from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { IStoreInterface } from "../../../../configs/store.config";
import LoadingIconWhite from "../../../../shared/icons/loading-white.icon";
import { VersionControlButtonsContainer } from "../../../../shared/responsive-ui.styled";
import { Column, LoadingText, RatioContainer, Row } from "../../../../shared/shared.styled";

import AppPropertiesApi from "./app-properties.api";
import { AppPropertiesActions } from "./app-properties.store";
import AppPropertiesLastVersionModal from "./components/app-properties-last-version.modal";
import AppPropertiesList from "./components/app-properties-list.component";
import AppPropertyStatusChip from "./components/app-property-status.component";
import PublishAppProperties from "./components/publish-app-properties.modal";

interface IAppPropertiesPageInterface {
  appId: string;
}

/**
 * App properties page
 * @param param0
 */
const AppPropertiesPage = ({ appId }: IAppPropertiesPageInterface) => {
  const [t] = useTranslation();
  const [showAlert, , setAlertProps] = useAlert();
  const [isLoading, setIsLoading] = React.useState<any>();

  const [lastVersionLoading, setLastVersionLoading] = React.useState(false);
  const [approvalLoading, setApprovalLoading] = React.useState(false);
  const [revertLoading, setRevertLoading] = React.useState(false);

  const dispatch = useDispatch();
  const appProperties = useSelector((state: IStoreInterface) => state.appPropertiesReducer.appProperties);
  const previousVersion = useSelector((state: IStoreInterface) => state.appPropertiesReducer.lastVersion);

  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );

  /**
   * Gets the app properties data
   * */
  const getAppProperties = () => {
    setIsLoading(true);
    AppPropertiesApi.methods
      .getAppProperties(appId)
      .then(
        res => {
          dispatch(AppPropertiesActions.creators.getAppPropertiesSuccess(res.data));
        },
        () => {
          setAlertProps({
            title: t("pages.appProperties.getAppPropertiesError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Revert to last version handler
   * */
  const revertToLastVersion = () => {
    const confirmationProps = {
      title: t("pages.appProperties.confirmRevert"),
      description: t("pages.appProperties.confirmRevertMessage"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      secondaryAction: () => hideConfirmationModal(),
      primaryAction: () => {
        setRevertLoading(true);
        setConfirmationProps({ ...confirmationProps, isLoading: true });
        AppPropertiesApi.methods
          .revertChanges(appId)
          .then(
            () => {
              setAlertProps({
                title: t("pages.appProperties.revertSuccess"),
                type: AlertTypeEnum.SUCCESS,
              });
              showAlert();
              hideConfirmationModal();
              getAppProperties();
            },
            () => {
              setAlertProps({
                title: t("pages.appProperties.revertError"),
                type: AlertTypeEnum.ERROR,
              });
              showAlert();
            },
          )
          .finally(() => {
            setRevertLoading(false);
            setConfirmationProps({ ...confirmationProps, isLoading: false });
          });
      },
    };

    setConfirmationProps(confirmationProps as BaseModalProps);
    showConfirmationModal();
  };

  /*
   * On load effect
   * */
  React.useEffect(() => {
    getAppProperties();
  }, [dispatch]);

  /**
   * Get previous version
   * */
  const getPreviousVersion = (cb: Function) => {
    AppPropertiesApi.methods.getLastVersion(appId).then(
      res => {
        dispatch(AppPropertiesActions.creators.getAppPropertiesLastVersionSuccess(res.data));
        cb();
      },
      () => {
        setLastVersionLoading(true);
        setAlertProps({
          title: t("pages.appProperties.getPreviousVersionError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /*
   * Check last version modal
   * */
  const [showAppPropertiesLastVersion, hideLastVersionModal, appPropertiesLastVersionModalProps] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <AppPropertiesLastVersionModal appProperties={previousVersion} onCancelClick={() => hideLastVersionModal()} />,
  );
  React.useEffect(() => {
    appPropertiesLastVersionModalProps({ modalStyles: { width: 600 } });
  }, [appPropertiesLastVersionModalProps]);

  /**
   * Publish modal
   * */
  const _hidePublishModal = () => {
    hidePublishModal();
  };

  /**
   * Publish changes
   */
  const _publishChanges = () => {
    setLastVersionLoading(true);
    hidePublishModal();
    publishChanges();
  };
  const [showPublishModal, hidePublishModal, publishModalProps] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <PublishAppProperties
      appProperties={appProperties}
      previousAppProperties={previousVersion}
      hideModal={_hidePublishModal}
      isLoading={approvalLoading}
      publishChanges={_publishChanges}
    />,
  );
  React.useEffect(() => {
    publishModalProps({ modalStyles: { width: 825, padding: 0 } });
  }, [publishModalProps]);

  /**
   * Publish changes handler
   * */
  const publishChanges = () => {
    setApprovalLoading(true);
    AppPropertiesApi.methods
      .publish(appId)
      .then(
        () => {
          setAlertProps({
            title: t("pages.appProperties.publishSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          getAppProperties();
        },
        () => {
          setAlertProps({
            title: t("pages.appProperties.publishError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        /**
         * Reset all publishing loading states
         */
        setLastVersionLoading(false);
        setApprovalLoading(false);
      });
  };

  /**
   * Check last version handler
   * */
  const checkLastVersion = () => {
    getPreviousVersion(() => {
      showAppPropertiesLastVersion();
    });
  };

  /**
   * Show publish modal
   * */
  const showPublishConfirmationModal = () => {
    setLastVersionLoading(true);
    getPreviousVersion(() => {
      showPublishModal();
      setLastVersionLoading(false);
    });
  };
  return (
    <>
      {isLoading && !appProperties ? <LoadingText>{t("commons.loadingResults")}</LoadingText> : null}
      {appProperties ? (
        <BundleTypeDetailsContainer>
          <Row>
            <RatioContainer ratio={2 / 12} style={{ marginRight: "24px" }}>
              <Column>
                <Label>{t("pages.appProperties.status")}</Label>
                <AppPropertyStatusChip
                  id={"status-chip"}
                  style={{ marginTop: "14px", width: "max-content" }}
                  type={appProperties.status}
                >
                  {i18next.t(`pages.appProperties.appPropertiesStatusEnum.${appProperties.status}`)}
                </AppPropertyStatusChip>
              </Column>
            </RatioContainer>
            <div style={{ marginLeft: "auto" }}>
              <Column style={{ alignItems: "flex-end" }}>
                <ButtonContainer style={{ position: "relative", top: "10px" }}>
                  <PrimaryButton
                    id={"publish-changes"}
                    disabled={!appProperties.hasUnpublishedChanges || lastVersionLoading}
                    titleLabel={t("pages.appProperties.publishChanges")}
                    type="submit"
                    redTheme={true}
                    style={{ width: "fit-content" }}
                    onClick={showPublishConfirmationModal}
                  />
                </ButtonContainer>
              </Column>
            </div>
          </Row>
          {appProperties.hasUnpublishedChanges && appProperties.hasPreviousVersion ? (
            <Row style={{ marginTop: "31px" }}>
              <VersionControlButtonsContainer>
                <div id={"check-last-version"}>
                  <FastActionButton
                    label={t("pages.appProperties.checkLastVersion")}
                    onClick={checkLastVersion}
                    iconComponent={
                      <FastIconWrapper color={styleTheme.palette.successGreen}>
                        <CheckIcon />
                      </FastIconWrapper>
                    }
                  />
                </div>
                <div id={"revert-to-last-version"}>
                  <FastActionButton
                    label={!revertLoading ? t("pages.languageTranslations.revertChanges") : t("commons.loading")}
                    onClick={revertToLastVersion}
                    iconComponent={
                      <FastIconWrapper color={styleTheme.palette.midGrey}>
                        {revertLoading && <LoadingIconWhite strokeColor={styleTheme.palette.midGrey} />}
                        {!revertLoading && <UndoIcon />}
                      </FastIconWrapper>
                    }
                  />
                </div>
              </VersionControlButtonsContainer>
            </Row>
          ) : null}

          <AppPropertiesList appProperties={appProperties} reloadAppProperties={getAppProperties} appId={appId} />
        </BundleTypeDetailsContainer>
      ) : null}
    </>
  );
};

export default AppPropertiesPage;

const ButtonContainer = styled("div")`
  width: fit-content;
`;

const BundleTypeDetailsContainer = styled("div")`
  margin-bottom: 34px;
  display: flex;
  flex-direction: column;
`;

const FastIconWrapper = styled("span")<{ color: string }>`
  width: 16px;
  height: 16px;
  stroke: ${props => props.color};
  svg {
    width: inherit;
    height: inherit;
  }
`;
const Label = styled("span")`
  font-family: Vodafone Rg;
  font-weight: bold;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
`;
