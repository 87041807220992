import { t } from "i18next";
import * as React from "react";
import styled from "styled-components";
type Props = {
  children: React.ReactNode;
};

/**
 * This returns the emptyTable component
 * @param param0
 * @returns
 */
export const EmptyTableComponent = ({ children }: Props) => {
  return (
    <MagnifierContainer>
      {children}
      <NoResultsFoundText>{t("pages.tools.identityConverter.table.noSearchResults")}</NoResultsFoundText>
    </MagnifierContainer>
  );
};

const NoResultsFoundText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.midGrey};
`;
const MagnifierContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 35px;
  svg {
    width: 140px;
    height: 140px;
    margin-bottom: 20px;
  }
`;
