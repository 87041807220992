import { ModalTypeEnum, PageTitle, useAlert, useModal } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import { Breadcrumb } from "@wit/mpesa-ui-components/lib/types";
import { TFunction } from "i18next";
import { isEqual } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { IStoreInterface } from "../../../configs/store.config";
import { RoutesEnum } from "../../../routes/routes.constants";
import { ListHeader, ListHeaders, LoadingText, PageContainer } from "../../../shared/shared.styled";
import BundlesApi from "./bundles.api";
import "./bundles.css";
import { IBundleType } from "./bundles.model";
import { BundlesActions } from "./bundles.store";
import AddBundleItem from "./components/add-bundle-item.component";
import BundleListItem from "./components/bundle-list-item.component";

/**
 * Get breadcrumbs
 * @param t
 */
const getBreadcrumbs = (t: TFunction): Breadcrumb[] => {
  return [
    {
      label: t("pages.configuration.title"),
      url: RoutesEnum.CONFIGS,
    },
    {
      label: t("pages.bundles.title"),
      url: "",
    },
  ];
};

/**
 * Bundles list
 */
const BundlesList = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(true);
  const [showAlert, , setAlertProps] = useAlert();
  const dispatch = useDispatch();
  const [dragging, setDragging] = React.useState(false);

  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );

  // Redux state
  const bundlesStore = useSelector((state: IStoreInterface) => state.bundlesReducer.bundles);
  const [bundles, setBundles] = React.useState(bundlesStore);

  /**
   * Gets the list of bundle types
   * */
  const getBundles = () => {
    setIsLoading(true);
    BundlesApi.methods
      .getBundles()
      .then(
        res => {
          dispatch(BundlesActions.creators.getBundlesSuccessAction(res.data));
          setBundles(res.data);
        },
        () => {
          setAlertProps({
            title: t("pages.bundles.getBundlesError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => setIsLoading(false));
  };

  /*
   * Get bundles
   * */
  React.useEffect(() => {
    getBundles();
  }, [dispatch]);

  /**
   * Delete bundle confirmation
   * @param bundle
   */
  const showDeleteConfirmation = (bundle: IBundleType) => {
    setConfirmationProps({
      title: t("pages.bundles.deleteBundleModalTitle"),
      description: t("pages.bundles.deleteBundleModalDescription"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      primaryAction: () => deleteBundle(bundle),
      secondaryAction: () => hideConfirmationModal(),
    } as BaseModalProps);
    showConfirmationModal();
  };

  /**
   * Delete bundle type handler
   * @param bundle
   */
  const deleteBundle = (bundle: IBundleType) => {
    BundlesApi.methods
      .deleteBundle(bundle.id)
      .then(
        () => {
          setAlertProps({
            title: t("pages.bundles.deleteBundleSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          getBundles();
        },
        () => {
          setAlertProps({
            title: t("pages.bundles.deleteBundleError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        hideConfirmationModal();
      });
  };

  /**
   * On list change handler
   * */
  const onSortHandler = (event: any) => {
    const ids = bundles.map(b => {
      return b.id;
    });
    BundlesApi.methods.sortBundles(ids).then(
      () => {
        dispatch(BundlesActions.creators.sortBundlesSuccessAction(bundles));
      },
      () => {
        setAlertProps({
          title: t("pages.bundles.sortError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
        setBundles(bundlesStore);
      },
    );
  };

  /**
   * On move handler
   * @param event
   */
  const onMoveHandler = (event: any) => {
    if (bundlesStore && bundles && bundles.length === bundlesStore.length) {
      return event;
    } else {
    }
  };

  /**
   * is list equal
   */
  const isListEqual = (oldValues: any[], newValues: any[]) => {
    if (!oldValues.length) {
      return true;
    }

    if (!oldValues[0].chosen) {
      oldValues = oldValues.map(v => {
        return { ...v, chosen: false, selected: false };
      });
    }
    return isEqual(oldValues, newValues);
  };

  return (
    <PageContainer>
      <PageTitle
        breadcrumbs={getBreadcrumbs(t)}
        title={t("pages.bundles.title")}
        navigateFunction={history.push}
        separatorStyle={{ display: "none" }}
        goBackFn={() => history.push(RoutesEnum.CONFIGS)}
        titleContainerStyle={{ marginTop: "13px", marginBottom: "34px" }}
      />
      <BundlesListContainer>
        {isLoading ? (
          <LoadingText>{t("commons.loadingResults")}</LoadingText>
        ) : (
          <>
            <ListHeaders>
              <ListHeader ratio={3 / 12}>{t("pages.bundles.bundleType")}</ListHeader>
              <ListHeader ratio={3 / 12}>{t("pages.bundles.description")}</ListHeader>
              <ListHeader ratio={2 / 12}>{t("pages.bundles.lastUpdate")}</ListHeader>
              <ListHeader ratio={2 / 12}>{t("pages.bundles.bundlesEnabled")}</ListHeader>
              <ListHeader ratio={2 / 12} style={{ textAlign: "right" }}>
                {t("pages.bundles.status")}
              </ListHeader>
            </ListHeaders>
            <AddBundleItem />
            {bundles ? (
              <>
                <ReactSortable
                  ghostClass={"sortable-ghost"}
                  chosenClass={"sortable-chosen"}
                  dragClass={"sortable-drag"}
                  list={bundles}
                  handle={".handle"}
                  setList={(newState, sortable, store) => {
                    if (!isListEqual(bundles, newState)) {
                      setBundles(newState);
                    }
                  }}
                  onMove={onMoveHandler}
                  onSort={onSortHandler}
                  onChoose={() => {
                    setDragging(true);
                  }}
                  onUnchoose={() => {
                    setDragging(false);
                    ReactTooltip.hide();
                  }}
                >
                  {bundles.map(b => {
                    return (
                      <BundleListItem
                        key={b.id}
                        dragging={dragging}
                        item={b}
                        deleteBundle={showDeleteConfirmation}
                      ></BundleListItem>
                    );
                  })}
                </ReactSortable>
              </>
            ) : null}
          </>
        )}
      </BundlesListContainer>
    </PageContainer>
  );
};

const BundlesListContainer = styled("div")`
  margin-top: 36px;
  margin-bottom: 50px;
`;

export default BundlesList;
