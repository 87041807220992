import * as React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { object, string } from "yup";

import { TextInput, PrimaryButton } from "@wit/mpesa-ui-components";

interface INewVersionModalProps {
  hideModal: () => void;
  handleNewVersionValue: (version: string) => void;
  lastVersion?: string;
}

const NewVersionModal: React.FunctionComponent<INewVersionModalProps> = ({
  lastVersion,
  hideModal,
  handleNewVersionValue,
}) => {
  const [t] = useTranslation();
  const getValidationSchema = () => {
    return object().shape({
      version: string().required(t("pages.serviceBuilder.modals.addServiceVersion.version.errorRequired")),
    });
  };
  return (
    <Formik
      onSubmit={data => {
        handleNewVersionValue(data.version);
        hideModal();
      }}
      validateOnChange={false}
      initialValues={{
        version: "",
      }}
      validationSchema={getValidationSchema()}
      render={({ handleChange, values, handleSubmit, errors, isSubmitting, setFieldValue, setErrors, setValues }) => (
        <AddVersionForm id="addVersionForm" onSubmit={handleSubmit} onChange={handleChange}>
          <ModalTitle>
            <span>{t("pages.serviceBuilder.modals.addServiceVersion.title")}</span>
          </ModalTitle>
          <TopFormDiv>
            <EditServiceFormLeft>
              <div style={{ display: "flex", paddingLeft: "26px", paddingRight: "26px" }}>
                <div style={{ flex: 1 }}>
                  <TextInput
                    title={t("pages.serviceBuilder.modals.addServiceVersion.version.title")}
                    placeholder={t("pages.serviceBuilder.modals.addServiceVersion.version.placeholder")}
                    required
                    name="version"
                    onChange={handleChange}
                    value={values.version}
                    error={errors.version}
                    autoComplete="off"
                    style={{ marginBottom: "0px" }}
                  />
                  {lastVersion !== undefined ? (
                    <FieldHint>
                      <span>Last version: {lastVersion}</span>
                    </FieldHint>
                  ) : null}
                </div>
              </div>
              <div style={{ display: "flex", paddingLeft: "26px", paddingRight: "26px" }}></div>

              <ButtonsWrapper>
                <ButtonDiv style={{ width: "179px" }}>
                  <PrimaryButton
                    id="createVersionButton"
                    loading={isSubmitting}
                    titleLabel={t("pages.serviceBuilder.modals.addServiceVersion.save.title")}
                    type="submit"
                    redTheme={true}
                    style={{ width: "179px" }}
                  />
                </ButtonDiv>
                <ButtonDiv>
                  <PrimaryButton
                    id="cancelButton"
                    titleLabel={t("pages.serviceBuilder.modals.addServiceVersion.cancel.title")}
                    type="button"
                    redTheme={false}
                    onClick={() => {
                      hideModal();
                    }}
                    style={{ width: "117px" }}
                  />
                </ButtonDiv>
              </ButtonsWrapper>
            </EditServiceFormLeft>
          </TopFormDiv>
        </AddVersionForm>
      )}
    />
  );
};

const AddVersionForm = styled("form")`
  display: flex;
  padding: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const EditServiceFormLeft = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: visible;
`;

const TopFormDiv = styled("div")`
  display: flex;
  flex-direction: row;
`;
const ModalTitle = styled("div")`
  font-size: 22px;
  font-family: Vodafone Rg;
  color: inherit;
  margin-bottom: 19px;
  display: flex;
  padding-top: 26px;
  padding-left: 26px;
  padding-right: 26px;
`;

const ButtonsWrapper = styled("div")`
  margin-top: auto;
  width: 100%;
  display: flex;
  padding-left: 26px;
  padding-right: 26px;
  padding-bottom: 26px;
`;
const ButtonDiv = styled("div")`
  margin-right: 12px;
`;

const FieldHint = styled("div")`
  min-height: 21px;
  font-family: Vodafone Rg;
  line-height: 21px;
  font-size: 14px;
  color: #999999;
  margin-top: 3px;
  width: fit-content;
  display: inline-flex;
  width: 100%;
  > span {
    margin-left: auto;
  }
`;

export default NewVersionModal;
