import { safaricomPalette } from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { t } from "i18next";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { getPastelColor } from "../../features/safaricom-service-manager/allowed-scopes/allowed-scopes.utils";
import ReactTooltip, { TooltipProps } from "react-tooltip";

interface IDisplayIconWithTooltipProps {
  children: React.ReactNode;
  toolTipText: string;
  tooltipId: string;
  containerColor?: string;
  tooltipProps?:
    | TooltipProps
    | {
        event: "mouseover";
        place: "bottom";
        eventOff: "mouseleave mousedown";
        type: "dark";
        effect: "solid";
        className: "tooltip-container";
      };
}

/**
 * Component to display an icon with hint text
 * @param
 * @returns an icon with tooltip
 */
const DisplayIconWithTooltip: FunctionComponent<IDisplayIconWithTooltipProps> = ({
  children,
  toolTipText,
  tooltipId,
  tooltipProps,
  containerColor,
}) => {
  return (
    <IconContainer data-tip data-for={tooltipId} color={containerColor || getPastelColor(safaricomPalette.red.normal)}>
      <div>{children}</div>
      <ReactTooltip id={tooltipId} {...tooltipProps}>
        <TooltipText>{t(toolTipText)}</TooltipText>
      </ReactTooltip>
    </IconContainer>
  );
};
const IconContainer = styled("div")`
  display: flex;
  flex-direction: column;
  border-radius: 50%;
  background-color: ${props => props.color};
  margin: auto 4px;
`;
const TooltipText = styled("div")`
  font-size: 12px;
  font-family: "Vodafone Rg";
  color: white;
  display: flex;
  width: 140px;
  text-align: center;
`;

export default DisplayIconWithTooltip;
