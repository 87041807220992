import React, { HTMLProps } from "react";

const EmptyStateIllustration = (props?: HTMLProps<SVGSVGElement>) => {
  return (
    <svg {...props} width="123" height="129" viewBox="0 0 123 129" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_606_2867)">
        <path
          d="M108.833 3H66.5C62.0817 3 58.5 6.58172 58.5 11V111.667C58.5 116.085 62.0817 119.667 66.5 119.667H108.833C113.252 119.667 116.833 116.085 116.833 111.667V11C116.833 6.58172 113.252 3 108.833 3Z"
          fill="black"
        />
      </g>
      <path
        d="M108.833 3H66.5C62.0817 3 58.5 6.58172 58.5 11V111.667C58.5 116.085 62.0817 119.667 66.5 119.667H108.833C113.252 119.667 116.833 116.085 116.833 111.667V11C116.833 6.58172 113.252 3 108.833 3Z"
        fill="#EBEBEB"
      />
      <path
        opacity="0.06"
        d="M111 118.208H64.3333C61.1117 118.208 58.5 115.597 58.5 112.375V113.833C58.5 117.055 61.1117 119.667 64.3333 119.667H111C114.222 119.667 116.833 117.055 116.833 113.833V112.375C116.833 115.597 114.222 118.208 111 118.208Z"
        fill="black"
      />
      <path
        d="M111.918 14.6667H63.418C62.3134 14.6667 61.418 15.5622 61.418 16.6667V101.625C61.418 102.73 62.3134 103.625 63.418 103.625H111.918C113.023 103.625 113.918 102.73 113.918 101.625V16.6667C113.918 15.5622 113.023 14.6667 111.918 14.6667Z"
        fill="white"
      />
      <path
        opacity="0.498907"
        d="M111.918 14.6667H63.418C62.3134 14.6667 61.418 15.5622 61.418 16.6667V101.625C61.418 102.73 62.3134 103.625 63.418 103.625H111.918C113.023 103.625 113.918 102.73 113.918 101.625V16.6667C113.918 15.5622 113.023 14.6667 111.918 14.6667Z"
        fill="url(#paint0_linear_606_2867)"
      />
      <path
        d="M92.7887 8.83325H82.542C82.1499 8.83325 81.832 9.15113 81.832 9.54325V9.58158C81.832 9.97371 82.1499 10.2916 82.542 10.2916H92.7887C93.1808 10.2916 93.4987 9.97371 93.4987 9.58158V9.54325C93.4987 9.15113 93.1808 8.83325 92.7887 8.83325Z"
        fill="#4A4D4E"
      />
      <path
        d="M78.2054 8.83325H78.167C77.7749 8.83325 77.457 9.15113 77.457 9.54325V9.58158C77.457 9.97371 77.7749 10.2916 78.167 10.2916H78.2054C78.5975 10.2916 78.9154 9.97371 78.9154 9.58158V9.54325C78.9154 9.15113 78.5975 8.83325 78.2054 8.83325Z"
        fill="#E60000"
      />
      <path
        opacity="0.799247"
        d="M87.668 115.292C90.0842 115.292 92.043 113.333 92.043 110.917C92.043 108.501 90.0842 106.542 87.668 106.542C85.2517 106.542 83.293 108.501 83.293 110.917C83.293 113.333 85.2517 115.292 87.668 115.292Z"
        fill="white"
      />
      <path
        d="M108.862 40.9167H67.203C66.4242 40.9167 65.793 41.548 65.793 42.3267V42.4234C65.793 43.2021 66.4242 43.8334 67.203 43.8334H108.862C109.641 43.8334 110.272 43.2021 110.272 42.4234V42.3267C110.272 41.548 109.641 40.9167 108.862 40.9167Z"
        fill="#4A4D4E"
      />
      <path
        d="M108.862 52.5833H67.203C66.4242 52.5833 65.793 53.2145 65.793 53.9933V54.0899C65.793 54.8686 66.4242 55.4999 67.203 55.4999H108.862C109.641 55.4999 110.272 54.8686 110.272 54.0899V53.9933C110.272 53.2145 109.641 52.5833 108.862 52.5833Z"
        fill="#4A4D4E"
      />
      <path
        d="M108.862 64.25H67.203C66.4242 64.25 65.793 64.8813 65.793 65.66V65.7567C65.793 66.5354 66.4242 67.1667 67.203 67.1667H108.862C109.641 67.1667 110.272 66.5354 110.272 65.7567V65.66C110.272 64.8813 109.641 64.25 108.862 64.25Z"
        fill="#4A4D4E"
      />
      <path
        d="M108.862 29.25H67.203C66.4242 29.25 65.793 29.8813 65.793 30.66V30.7567C65.793 31.5354 66.4242 32.1667 67.203 32.1667H108.862C109.641 32.1667 110.272 31.5354 110.272 30.7567V30.66C110.272 29.8813 109.641 29.25 108.862 29.25Z"
        fill="#E90000"
      />
      <g filter="url(#filter1_d_606_2867)">
        <path
          d="M105.897 88.3125H70.168C67.7517 88.3125 65.793 90.2713 65.793 92.6875C65.793 95.1037 67.7517 97.0625 70.168 97.0625H105.897C108.313 97.0625 110.272 95.1037 110.272 92.6875C110.272 90.2713 108.313 88.3125 105.897 88.3125Z"
          fill="black"
        />
      </g>
      <path
        d="M105.897 88.3125H70.168C67.7517 88.3125 65.793 90.2713 65.793 92.6875C65.793 95.1037 67.7517 97.0625 70.168 97.0625H105.897C108.313 97.0625 110.272 95.1037 110.272 92.6875C110.272 90.2713 108.313 88.3125 105.897 88.3125Z"
        fill="white"
      />
      <g filter="url(#filter2_d_606_2867)">
        <path
          d="M43.9385 65.2388H10.1388C7.85298 65.2388 6 67.0918 6 69.3775C6 71.6633 7.85298 73.5163 10.1388 73.5163H43.9385C46.2243 73.5163 48.0773 71.6633 48.0773 69.3775C48.0773 67.0918 46.2243 65.2388 43.9385 65.2388Z"
          fill="black"
        />
      </g>
      <path
        d="M43.9385 65.2388H10.1388C7.85298 65.2388 6 67.0918 6 69.3775C6 71.6633 7.85298 73.5163 10.1388 73.5163H43.9385C46.2243 73.5163 48.0773 71.6633 48.0773 69.3775C48.0773 67.0918 46.2243 65.2388 43.9385 65.2388Z"
        fill="#333333"
      />
      <g filter="url(#filter3_d_606_2867)">
        <path
          d="M43.9385 77.655H10.1388C7.85298 77.655 6 79.508 6 81.7938C6 84.0795 7.85298 85.9325 10.1388 85.9325H43.9385C46.2243 85.9325 48.0773 84.0795 48.0773 81.7938C48.0773 79.508 46.2243 77.655 43.9385 77.655Z"
          fill="black"
        />
      </g>
      <path
        d="M43.9385 77.655H10.1388C7.85298 77.655 6 79.508 6 81.7938C6 84.0795 7.85298 85.9325 10.1388 85.9325H43.9385C46.2243 85.9325 48.0773 84.0795 48.0773 81.7938C48.0773 79.508 46.2243 77.655 43.9385 77.655Z"
        fill="#E60000"
      />
      <g filter="url(#filter4_d_606_2867)">
        <path
          d="M42.4013 24.541H11.676C8.54123 24.541 6 27.0822 6 30.217V51.2852C6 54.42 8.54123 56.9612 11.676 56.9612H42.4013C45.5361 56.9612 48.0773 54.42 48.0773 51.2852V30.217C48.0773 27.0822 45.5361 24.541 42.4013 24.541Z"
          fill="black"
        />
      </g>
      <path
        d="M42.4013 24.541H11.676C8.54123 24.541 6 27.0822 6 30.217V51.2852C6 54.42 8.54123 56.9612 11.676 56.9612H42.4013C45.5361 56.9612 48.0773 54.42 48.0773 51.2852V30.217C48.0773 27.0822 45.5361 24.541 42.4013 24.541Z"
        fill="white"
      />
      <path
        d="M26.6933 35.5776H11.5179C11.137 35.5776 10.8281 35.8865 10.8281 36.2674C10.8281 36.6484 11.137 36.9572 11.5179 36.9572H26.6933C27.0743 36.9572 27.3831 36.6484 27.3831 36.2674C27.3831 35.8865 27.0743 35.5776 26.6933 35.5776Z"
        fill="#999999"
      />
      <path
        d="M31.5219 29.3696H11.5179C11.137 29.3696 10.8281 29.6785 10.8281 30.0594C10.8281 30.4404 11.137 30.7492 11.5179 30.7492H31.5219C31.9028 30.7492 32.2117 30.4404 32.2117 30.0594C32.2117 29.6785 31.9028 29.3696 31.5219 29.3696Z"
        fill="#999999"
      />
      <path
        d="M17.0363 41.7859H11.5179C11.137 41.7859 10.8281 42.0947 10.8281 42.4757C10.8281 42.8566 11.137 43.1655 11.5179 43.1655H17.0363C17.4172 43.1655 17.726 42.8566 17.726 42.4757C17.726 42.0947 17.4172 41.7859 17.0363 41.7859Z"
        fill="#999999"
      />
      <defs>
        <filter
          id="filter0_d_606_2867"
          x="52.5"
          y="0"
          width="70.334"
          height="128.667"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.556641 0 0 0 0 0.556641 0 0 0 0 0.556641 0 0 0 0.23 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_606_2867" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_606_2867" result="shape" />
        </filter>
        <filter
          id="filter1_d_606_2867"
          x="59.793"
          y="85.3125"
          width="56.4785"
          height="20.75"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.556641 0 0 0 0 0.556641 0 0 0 0 0.556641 0 0 0 0.23 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_606_2867" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_606_2867" result="shape" />
        </filter>
        <filter
          id="filter2_d_606_2867"
          x="0"
          y="62.2388"
          width="54.0781"
          height="20.2776"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.556641 0 0 0 0 0.556641 0 0 0 0 0.556641 0 0 0 0.23 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_606_2867" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_606_2867" result="shape" />
        </filter>
        <filter
          id="filter3_d_606_2867"
          x="0"
          y="74.655"
          width="54.0781"
          height="20.2776"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.556641 0 0 0 0 0.556641 0 0 0 0 0.556641 0 0 0 0.23 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_606_2867" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_606_2867" result="shape" />
        </filter>
        <filter
          id="filter4_d_606_2867"
          x="0"
          y="21.541"
          width="54.0781"
          height="44.4202"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.556641 0 0 0 0 0.556641 0 0 0 0 0.556641 0 0 0 0.23 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_606_2867" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_606_2867" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_606_2867"
          x1="75.5006"
          y1="24.2115"
          x2="122.602"
          y2="71.3133"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.3" />
          <stop offset="1" stopColor="#F6F6F6" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EmptyStateIllustration;
