import React from "react";
import { ILanguage } from "../../../shared/models/language.model";

export class ReferralCampaign implements IReferralCampaign {
  id?: string | undefined;
  name!: ITranslatableObject;
  creationDate!: number;
  startDate!: string | number;
  endDate!: string | number;
  status!: ReferralCampaignStatus;
  conversionEvent!: ConversionEventEnum;
  inviteMessage!: ITranslatableObject;
  rewards!: IReward[];
  screens!: IScreen[];
  defaultCampaign!: boolean;

  /**
   * constructor to set the values with multiple translations
   */
  constructor(languages: string[]) {
    const translations: { [x: string]: string } = {};
    languages.forEach((lang: string) => {
      translations[lang] = "";
    });
    this.name = { translations };
    this.inviteMessage = { translations };
    this.rewards = [new Reward(TargetEnum.SENDER), new Reward(TargetEnum.RECEIVER)];
  }
}

class Reward implements IReward {
  target: TargetEnum;
  type!: SenderTypeRewardEnum | ReceiverTypeRewardEnum;
  prizeCategory?: PrizeCategoryEnum;
  amount?: string;
  unit?: string;
  distributionMode?: EFormDataDistributionMode;
  maxInvites?: number;

  /**
   * constructor
   */
  constructor(target: TargetEnum) {
    this.target = target;
  }
}

export class Screen implements IScreen {
  type: TypeScreenEnum;
  headerTitle?: ITranslatableObject;
  title!: ITranslatableObject;
  description!: ITranslatableObject;
  urlImage?: string;

  /**
   * constructor
   */
  constructor(type: TypeScreenEnum, languages: ILanguage[]) {
    const translations: { [x: string]: string } = {};
    languages.forEach((lang: ILanguage) => {
      translations[lang.code] = "";
    });
    this.type = type;
    this.headerTitle = type === TypeScreenEnum.RECEIVER ? { translations } : undefined;
    this.title = { translations };
    this.description = { translations };
  }
}

// INTERFACES

export interface IReward {
  id?: string;
  target: TargetEnum;
  type: SenderTypeRewardEnum | ReceiverTypeRewardEnum;
  prizeCategory?: PrizeCategoryEnum;
  amount?: string;
  unit?: string;
  distributionMode?: string;
  maxInvites?: number;
}

export interface IReferralCampaigns {
  status: ReferralCampaignStatus;
  campaigns: IReferralCampaign[];
}

export interface IScreen {
  id?: string;
  type: TypeScreenEnum;
  headerTitle?: ITranslatableObject;
  title?: ITranslatableObject;
  description?: ITranslatableObject;
  urlImage?: string;
}

export interface ITranslatableObject {
  key?: string;
  translations: any;
}

export interface IGalleryImage {
  url: string;
  filename: string;
}

export interface IAvailablePeriodRequest {
  startDate: string | number;
  endDate?: string | number;
  id?: string;
}

export interface IDuplicateReferralRequest {
  startDate: string;
  endDate: string;
}

export interface IRewardList {
  msisdn: string;
  rewardType: string;
  rewardDate: number | string;
}

export interface IInviteList {
  receiverMsisdn: string;
  firstLogin: number | string;
  senderMsisdn: string;
}

export interface IAnalyticsInvitesResponse {
  count: number;
  list: IInviteList[] | ISenderList[];
}

export interface IAnalyticsRewardsResponse {
  count: number;
  list: IRewardList[] | IReceiverList[];
}

export interface IReceiverList {
  id: string;
  receiverMsisdn: string;
  firstLogin: number | string;
  invitedBy: string;
  rewardType: string;
  rewardAmount: string;
  distributionMode: EFormDataDistributionMode;
  rewardStatus: EFormDataStatus | React.ReactNode;
}

export interface ISenderList {
  id: string;
  senderMsisdn: string;
  rewardType: string;
  rewardAmount: string;
  distributionMode: EFormDataDistributionMode;
  rewardStatus: EFormDataStatus | React.ReactNode;
}

export interface IAnalyticsDashboardResponse {
  hasRewards: boolean;
  amountRewards: {
    rejected: number;
    pending: number;
    failed: number;
    sent: number;
    total: number;
  };
  numberRewards: {
    rejected: number;
    pending: number;
    failed: number;
    sent: number;
    total: number;
  };
  inviteCount?: number;
}

export interface IAnalyticsFile extends Blob {
  name: string;
  size: number;
}

export interface IReferralCampaign {
  id?: string;
  name: ITranslatableObject;
  creationDate?: number;
  startDate: string | number | undefined;
  endDate: string | number | undefined;
  status: ReferralCampaignStatus;
  conversionEvent: ConversionEventEnum;
  inviteMessage: ITranslatableObject;
  rewards: IReward[];
  screens: IScreen[];
  defaultCampaign: boolean;
}

export interface IReferralAnalyticsProps {
  sectionTabs: string[];
  referralCampaignID: number;
  referralCampaignStartDate: string;
}

export interface IReferralAnalyticsRequest {
  id?: number;
  type?: string;
  page: number;
  pageSize: number;
  startDate?: number;
  endDate?: number;
  msisdn?: string;
  timeZone?: string;
}

export interface IReferralAnalyticsDashboardRequest {
  id: number;
  type: string;
}

// ENUMS

export enum ConversionEventEnum {
  FIRST_LOGIN = "FIRST_LOGIN",
  FIRST_TRANSACTION = "FIRST_TRANSACTION",
}

export enum PrizeCategoryEnum {
  CASH = "CASH",
  AIRTIME = "AIRTIME",
  SMS = "SMS",
  INTERNET = "INTERNET",
  VOICE = "VOICE",
}

export enum PrizeCategoryUnit {
  USD = "USD",
  TZS = "TZS",
  LSL = "LSL",
  MT = "MT",
  CDF = "CDF",
  MB = "MB",
  GB = "GB",
  SMS = "SMS",
  MIN = "MIN",
}

export enum SenderTypeRewardEnum {
  SINGLE = "SINGLE",
  AGGREGATED = "AGGREGATED",
  NONE = "NONE",
}

export enum ReceiverTypeRewardEnum {
  SINGLE = "SINGLE",
  NONE = "NONE",
}

export enum TargetEnum {
  SENDER = "SENDER",
  RECEIVER = "RECEIVER",
}

export enum ReferralCampaignStatus {
  LIVE = "LIVE",
  SCHEDULED = "SCHEDULED",
  DISABLED = "DISABLED",
}

export enum TypeScreenEnum {
  SENDER_INITIAL = "SENDER_INITIAL",
  SENDER_FINAL = "SENDER_FINAL",
  RECEIVER = "RECEIVER",
}

export enum ReferralAnalyticsEnums {
  REFRESH_DATA = "refresh",
  EXPORT_EXCEL = "export",
}

export enum ReferralAnalyticsDateFilterKey {
  TIME_RANGE = "timeRange",
  START_DATE = "startDate",
  END_DATE = "endDate",
}

export enum EFormAnalyticsType {
  SENDERS = "senders",
  RECEIVERS = "receivers",
}

export enum EFormDataStatus {
  FAILED = "Failed",
  PENDING = "Pending",
  SENT = "Sent",
  REJECTED = "Rejected",
}

export enum EFormDataDistributionMode {
  AUTOMATIC = "Automatic",
  MANUAL = "Manual",
}
