import React from "react";
import styled from "styled-components";
import { Row } from "./../../../shared/shared.styled";
import { SearchBar, MultipleOptionsDropdown, GridViewIcon, ListViewIcon } from "@wit/mpesa-ui-components";
import { useTranslation } from "react-i18next";
import { getServiceStatus } from "../../../shared/shared.utils";
import { FilterFuncts } from "@wit/mpesa-ui-components/lib/hooks/use-filters.hook";
import { SelectedView } from "../../../shared/shared.enums";

export enum ServicesFiltersEnum {
  SEARCH = "search",
  STATUS = "status",
}

interface IServicesFiltersProps extends FilterFuncts {
  selectedView: SelectedView;
  setSelectedView: (selectedView: SelectedView) => any;
}

/**
 * Services filters
 * @param param0
 * @returns
 */
const ServicesFilters = ({
  filters,
  clearFilter,
  isFilterActive,
  resetFilters,
  updateFilter,
  selectedView,
  setSelectedView,
}: IServicesFiltersProps) => {
  // Hook initializations
  const [t] = useTranslation();

  return (
    <ServicesFilterContainer>
      <div style={{ flex: 4 / 12, marginRight: 24 }}>
        <SearchBar
          placeholderLabel={t("pages.serviceBuilder.filters.searchPlaceholder")}
          value={
            filters.has(ServicesFiltersEnum.SEARCH) ? (filters.get(ServicesFiltersEnum.SEARCH) as string[])[0] : ""
          }
          onChange={e => updateFilter(ServicesFiltersEnum.SEARCH, e.target.value, true)}
          clearValue={() => clearFilter(ServicesFiltersEnum.SEARCH)}
        />
      </div>
      <div style={{ flex: 2 / 12, marginRight: 24 }}>
        <MultipleOptionsDropdown
          label={
            !!filters.has(ServicesFiltersEnum.STATUS)
              ? t("pages.serviceBuilder.filters.statusSelected", {
                  numberOfSelectedStatus: (filters.get(ServicesFiltersEnum.STATUS) as string[]).length,
                })
              : t("pages.serviceBuilder.filters.statusDropdownPlaceholder")
          }
          options={getServiceStatus()}
          toggleOption={val => updateFilter(ServicesFiltersEnum.STATUS, val.key)}
          hasValue={filters.has(ServicesFiltersEnum.STATUS)}
          isOptionSelected={val => isFilterActive(ServicesFiltersEnum.STATUS, val.key)}
          clearAllFilters={() => clearFilter(ServicesFiltersEnum.STATUS)}
        />
      </div>

      <ToggleViewContainer>
        <ViewIconContainer
          onClick={() => setSelectedView(SelectedView.GRID)}
          isSelectedView={selectedView === SelectedView.GRID}
        >
          <GridViewIcon />
        </ViewIconContainer>
        <ViewIconContainer
          onClick={() => setSelectedView(SelectedView.LIST)}
          isSelectedView={selectedView === SelectedView.LIST}
        >
          <ListViewIcon />
        </ViewIconContainer>
      </ToggleViewContainer>
    </ServicesFilterContainer>
  );
};

export default ServicesFilters;

const ServicesFilterContainer = styled(Row)`
  margin-bottom: 49px;
  margin-top: 41px;
`;

const ToggleViewContainer = styled(Row)`
  margin-left: auto;

  > div {
    :first-of-type {
      margin-right: 12px;
    }
  }
`;

const ViewIconContainer = styled("div")<{ isSelectedView: boolean }>`
  width: 24px;
  height: 24px;
  cursor: pointer;

  svg {
    stroke: ${props => (props.isSelectedView ? props.theme.palette.black : props.theme.palette.midGrey)};
  }
`;
