import { useContext } from "react";
import { ConfigContext } from "../../../../app.component";
import { FULL_NAME_VALIDATION_RULES } from "../../../../shared/shared.constants";
import { FullNameValidationRuleType } from "../users.interfaces";

const useUsersValidationRules = () => {
  const { config } = useContext(ConfigContext);
  const fullName =
    FULL_NAME_VALIDATION_RULES[
      String(config!.market)
        .toUpperCase()
        .replace("-", "_") as keyof FullNameValidationRuleType
    ];

  return { fullName };
};

export { useUsersValidationRules };
