import React, { useState, useEffect } from "react";
import styled from "styled-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import {
  ModalTypeEnum,
  useModal,
  PrimaryButton,
  useAlert,
  ArrowDownIcon,
  AutoClosingDropdown,
  PlusIcon,
  CancelIcon,
} from "@wit/mpesa-ui-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IStoreInterface } from "../../../../configs/store.config";
import { FeedbackAndProblemsStoreActions } from "../feedback-and-problems.store";
import FeedbackAndProblemsAPI from "../feedback-and-problems.api";
import {
  problemsStringPath,
  feedbacksStringPath,
  isFeedbackTabActive,
  getFormattedMarketLanguages,
} from "../feedback-and-problems.utils";
import {
  EFormType,
  IDetailsDrawerComponentProps,
  IFormDetailsResponse,
  ICategoriesDropDownData,
  ICreateFormCategoryRequest,
} from "../feedback-and-problems.model";
import CategoryCreationModalComponent from "../components/category-creation-modal.component";
import { useFeedbackAndProblemsCategories } from "../hooks/use-feedback-and-problems-categories.hook";
import GenericLanguageSelector from "../../../../shared/components/generic-language-selector.component";

/**
 * Details drawer component
 * @returns
 */
const DetailsDrawerComponent = ({
  sectionId,
  categoryList,
  activeCategories,
  selectableMarketLanguages,
  selectedMarketLanguage,
  onClickSubmitChanges,
  onClickLanguageSelector: setActiveLanguage,
  closeDrawer,
}: IDetailsDrawerComponentProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [showAlert, , setAlertProps] = useAlert();
  const optionCategories = useFeedbackAndProblemsCategories(sectionId, selectedMarketLanguage);

  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState<boolean>(false);
  const [marketLanguages, setMarketLanguages] = useState<string[]>([]);
  const [selectableCategories, setSelectableCategories] = useState<ICategoriesDropDownData[]>([]);
  const [categoryInputErrors, setCategoryInputErrors] = useState<{
    [key: number]: string;
  }>({});
  const [categoryInputRows, setCategoryInputRows] = useState<{
    [key: number]: IFormDetailsResponse;
  }>({});
  const { feedbackCategories, problemReportsCategories } = useSelector(
    (state: IStoreInterface) => state.feedbackAndProblemsReportReducer,
  );

  /**
   * Get from the BE the categories
   * TODO: This can be improved - Need BE support
   * This function can be removed once the BE starts sending in createFormCategory response
   * the updated categories list. That response will then be dispatched to store.
   */
  const getFormCategories = async () => {
    if (isFeedbackTabActive(sectionId)) {
      dispatch(FeedbackAndProblemsStoreActions.creators.getFeedbackCategoriesData());
    } else {
      dispatch(FeedbackAndProblemsStoreActions.creators.getProblemsCategoriesData());
    }

    try {
      const res = await FeedbackAndProblemsAPI.methods.getFormCategories(
        isFeedbackTabActive(sectionId) ? EFormType.FEEDBACK : EFormType.REPORT_PROBLEM,
        true,
      );
      if (isFeedbackTabActive(sectionId)) {
        dispatch(FeedbackAndProblemsStoreActions.creators.setFeedbackCategoriesData(res.data));
      } else {
        dispatch(FeedbackAndProblemsStoreActions.creators.setProblemCategoriesData(res.data));
      }
    } catch (error) {
      setAlertProps({
        title: isFeedbackTabActive(sectionId)
          ? t(`${feedbacksStringPath}.errors.onFetchCategories`)
          : t(`${problemsStringPath}.errors.onFetchCategories`),
        type: AlertTypeEnum.ERROR,
      });
      showAlert();
    }
  };

  /**
   * Create a new category
   * @param payload ICreateFormCategoryRequest
   */
  const createFormCategory = async (payload: ICreateFormCategoryRequest) => {
    if (isFeedbackTabActive(sectionId)) {
      dispatch(FeedbackAndProblemsStoreActions.creators.creatingFeedbacksCategory());
    } else {
      dispatch(FeedbackAndProblemsStoreActions.creators.creatingProblemsReportCategory());
    }

    try {
      const res = await FeedbackAndProblemsAPI.methods.createFormCategory(
        isFeedbackTabActive(sectionId) ? EFormType.FEEDBACK : EFormType.REPORT_PROBLEM,
        payload,
      );
      setAlertProps({
        title: isFeedbackTabActive(sectionId)
          ? t(`${feedbacksStringPath}.success.onCreateCategory`)
          : t(`${problemsStringPath}.success.onCreateCategory`),
        type: AlertTypeEnum.SUCCESS,
      });
      showAlert();
      await getFormCategories(); // TODO: This is connected to above comment on getFormCategories() docs.
      hideCreationModal();
    } catch (error) {
      setAlertProps({
        title: isFeedbackTabActive(sectionId)
          ? t(`${feedbacksStringPath}.errors.onCreateCategory`)
          : t(`${problemsStringPath}.errors.onCreateCategory`),
        type: AlertTypeEnum.ERROR,
      });
      showAlert();
    }
  };

  /**
   * Hides the modal used for category creation
   */
  const hideCreationModal = () => hideCategoryCreationModal();

  /**
   * Show the modal to be used for create a category
   */
  const showCreationModal = () => showCategoryCreationModal();

  const [showCategoryCreationModal, hideCategoryCreationModal] = useModal(
    ModalTypeEnum.CustomModal,
    { modalStyles: { width: 400 } } as any,
    <CategoryCreationModalComponent
      closeCategoryCreationModal={hideCreationModal}
      createFormCategory={createFormCategory}
      categoryList={isFeedbackTabActive(sectionId) ? feedbackCategories : problemReportsCategories}
      marketLanguages={selectableMarketLanguages}
      sectionId={sectionId}
    />,
  );

  /**
   * Verifies if the dropdown-input-fields are filled or not
   * if they are filled and without errors, it will enable the submit button
   */
  const verifyInputFields = () => {
    let errors = Object.values(categoryInputErrors).length;
    Object.values(categoryInputErrors).forEach(error => {
      if (error === "") {
        errors = errors - 1;
      }
    });

    let emptyFields = 0;
    Object.keys(categoryInputRows).forEach(input => {
      if (String(categoryInputRows[Number(input)].id).startsWith("temp-")) {
        emptyFields = emptyFields + 1;
      }
    });

    const tempActiveCategories = activeCategories.reduce((a, v) => {
      return {
        ...a,
        [v.order]: {
          ...v,
        },
      };
    }, {});
    if (emptyFields >= 1 || errors >= 1 || JSON.stringify(tempActiveCategories) === JSON.stringify(categoryInputRows)) {
      setIsSubmitButtonEnabled(false);
    } else {
      setIsSubmitButtonEnabled(true);
    }
  };

  /**
   * Verifies if the visible dropdown-input-field
   * are 3 already or less, if less than 3, it will generate dummy data to be filled for thew first time by the user
   * Example: BO markets that didn't have this feature (feedback and problems reports) enabled
   */
  const emptyInputs = () => {
    const numberOfRowsToGenerate = 3 - activeCategories.length;
    const tempData: { [key: number]: IFormDetailsResponse } = {};
    if (numberOfRowsToGenerate >= 1) {
      for (let index = 0; index < numberOfRowsToGenerate; index++) {
        tempData[index + 1] = {
          name: {
            key: `temp-${index + 1}`,
            translations: {
              en: "",
            },
          },
          id: `temp-${index + 1}`,
          order: index + 1,
        };
      }
      return tempData;
    }
  };

  /**
   * Verifies the data that is coming from BE,
   * sorts and generate an object to be used for generate the filled (not empty) dropdown-input-fields
   */
  const filledInputs = () => {
    if (activeCategories.length === 0) {
      return;
    }

    const inputData: { [key: number]: IFormDetailsResponse } = {};
    [...activeCategories]
      .sort((a, b) => a.order - b.order)
      .forEach((data, index) => {
        inputData[index + 1] = data;
      });
    return inputData;
  };

  /**
   * Generates dropdown-input-fields according to categoryInputRows data
   * @returns JSX.Element[]
   */
  const getDrawerInputFields = () => {
    const keys = Object.keys(categoryInputRows).sort((a, b) => Number(a) - Number(b));
    return keys.map((key, index) => {
      return (
        <BodyRow
          style={Number(key) === 0 ? { marginTop: 16 } : { marginTop: 26 }}
          hasError={!!categoryInputErrors[Number(key)]}
          key={key}
        >
          <TitleElement>
            <span>
              {isFeedbackTabActive(sectionId)
                ? `${t(`${feedbacksStringPath}.detailsArea.${index}_categoryRow`)}`
                : `${t(`${problemsStringPath}.detailsArea.${index}_categoryRow`)}`}
              {index <= 2 ? <RequiredElement /> : null}
            </span>
            <span style={{ display: "flex", alignItems: "center" }}>
              <ErrorElement
                style={!!categoryInputErrors[Number(key)] ? { visibility: "visible" } : { visibility: "hidden" }}
              >
                {categoryInputErrors[Number(key)]}
              </ErrorElement>
              {Number(key) === 4 || Number(key) === 5 ? (
                <RemoveButton onClick={() => onClickRemoveCategoryRow(Number(key))}>
                  <CancelIcon />
                  <span>
                    {isFeedbackTabActive(sectionId)
                      ? `${t(`${feedbacksStringPath}.detailsArea.removeCategoryRow`)}`
                      : `${t(`${problemsStringPath}.detailsArea.removeCategoryRow`)}`}
                  </span>
                </RemoveButton>
              ) : null}
            </span>
          </TitleElement>
          <AutoClosingDropdown
            isExtraButtonEnabled={true}
            label={
              categoryInputRows[Number(key)].name.translations[selectedMarketLanguage]
                ? categoryInputRows[Number(key)].name.translations[selectedMarketLanguage]
                : isFeedbackTabActive(sectionId)
                ? t(`${feedbacksStringPath}.detailsArea.categoryRowPlaceholder`)
                : t(`${problemsStringPath}.detailsArea.categoryRowPlaceholder`)
            }
            extraButtonLabel={
              isFeedbackTabActive(sectionId)
                ? `${t(`${feedbacksStringPath}.detailsArea.createNewCategory`)}`
                : `${t(`${problemsStringPath}.detailsArea.createNewCategory`)}`
            }
            options={selectableCategories}
            selectOption={o => {
              if (JSON.stringify(categoryInputRows).includes(`"id":${o.key}`)) {
                setCategoryInputErrors({
                  ...categoryInputErrors,
                  [Number(key)]: isFeedbackTabActive(sectionId)
                    ? `${t(`${feedbacksStringPath}.errors.onSelectInputDrawerCategory`)}`
                    : `${t(`${problemsStringPath}.errors.onSelectInputDrawerCategory`)}`,
                });
              } else {
                setCategoryInputErrors({
                  ...categoryInputErrors,
                  [Number(key)]: "",
                });
              }
              const tempData = { ...categoryInputRows };
              const targetData = categoryList.filter(listItem => listItem.id === o.key);
              if (targetData.length === 1) {
                tempData[Number(key)] = {
                  order: Number(key),
                  ...targetData[0],
                };
                setCategoryInputRows(tempData);
              }
            }}
            onExtraButtonClick={showCreationModal}
            hasValue={!!categoryInputRows[Number(key)].name.translations[selectedMarketLanguage]}
            dropdownType={DropdownType.RECTANGULAR_NORMAL}
          />
        </BodyRow>
      );
    });
  };

  /**
   * Handles Add-Category button click
   */
  const onClickAddCategory = () => {
    const numberOfRows = Object.keys(categoryInputRows).length;
    if (numberOfRows >= 5) {
      return;
    }

    const tempData = {
      ...categoryInputRows,
      [numberOfRows + 1]: {
        id: `temp-${numberOfRows + 1}`,
        order: numberOfRows + 1,
        name: {
          key: `temp-${numberOfRows + 1}`,
          translations: {
            pt: "",
            en: "",
          },
        },
      },
    };
    setCategoryInputRows(tempData);
  };

  /**
   * Handles Remove-Category-Row button click
   * will remove only if we have 5 or 4 dropdown-input-fields visible
   * @param key number
   */
  const onClickRemoveCategoryRow = (key: number) => {
    const tempData = { ...categoryInputRows };
    if (!!categoryInputRows[5] && key === 4) {
      const tempRowData = categoryInputRows[5];
      delete tempData[5];
      tempData[4] = tempRowData;
      setCategoryInputRows(tempData);
      return;
    }

    delete tempData[key];
    setCategoryInputRows(tempData);
  };

  /**
   * Handles Save-Changes button click
   */
  const onClickSaveChanges = () => {
    const tempData: {
      id: number | string;
      order: number;
    }[] = [];
    Object.keys(categoryInputRows).forEach(input => {
      tempData.push({
        id: categoryInputRows[Number(input)].id,
        order: Number(input),
      });
    });

    onClickSubmitChanges({
      categories: tempData,
    });
  };

  /**
   * On First load or on categoryInputRows changes,
   * check the input fields to see if everything is okay or not
   */
  useEffect(() => {
    verifyInputFields();
  }, [categoryInputRows]);

  /**
   * On First load or on marketLanguages(selectableMarketLanguages) changes,
   * verifies the marketLanguages if we have duplicated values or not, and
   * set to local store (useStore) the market languages language code, and
   * set to local store the current active language
   */
  useEffect(() => {
    const { languages, activeLanguage } = getFormattedMarketLanguages(selectableMarketLanguages);
    setActiveLanguage(activeLanguage);
    setMarketLanguages(languages);
  }, [selectableMarketLanguages]);

  /**
   * On First load or on activeCategories changes,
   * set to local store (useStore) - the data that will be used to generate dropdown-input-fields within details drawer.
   * If the market doesn't have any active category, then we will have 3 dropdown...
   * If the market has 1 active category, we will have 1 filled and 2 empty dropdown-input-fields
   * If the market has 2 active categories, we will have 2 filled and 1 empty dropdown-input-fields
   * If the market has 3 active, we will have only 3 filled dropdown-input-fields
   */
  useEffect(() => {
    const empty = emptyInputs();
    const nonEmpty = filledInputs();
    setCategoryInputRows({
      ...nonEmpty,
      ...empty,
    });
  }, [activeCategories]);

  /**
   * On First load or on optionCategories changes,
   * set to local store (useStore) all categories except those the one with label "Other"
   * if we pass down all without filter, it will brake the drawer.
   */
  useEffect(() => {
    setSelectableCategories(
      [...optionCategories].filter(i => {
        return i.label !== "Other";
      }),
    );
  }, [optionCategories]);

  return (
    <ComponentContainer>
      <HeaderSection>
        <HeaderIcon onClick={closeDrawer} id={"close-drawer"}>
          <ArrowDownIcon />
        </HeaderIcon>
        <HeaderTitle>
          {isFeedbackTabActive(sectionId)
            ? `${t(`${feedbacksStringPath}.detailsArea.drawerTitle`)}`
            : `${t(`${problemsStringPath}.detailsArea.drawerTitle`)}`}
        </HeaderTitle>
      </HeaderSection>
      <BodySection>
        <BodyRow>
          <TitleAndLanguage>
            <TitleWrapper>
              {isFeedbackTabActive(sectionId)
                ? `${t(`${feedbacksStringPath}.detailsArea.drawerCategoriesTitle`)}`
                : `${t(`${problemsStringPath}.detailsArea.drawerCategoriesTitle`)}`}
            </TitleWrapper>
            <GenericLanguageSelector
              selectedLanguage={selectedMarketLanguage}
              availableLanguages={marketLanguages}
              changeSelectedLanguage={(lang: any) => setActiveLanguage(lang.key)}
            ></GenericLanguageSelector>
          </TitleAndLanguage>
          <Subtitle>
            {isFeedbackTabActive(sectionId)
              ? `${t(`${feedbacksStringPath}.detailsArea.drawerCategoriesSubtitle`)}`
              : `${t(`${problemsStringPath}.detailsArea.drawerCategoriesSubtitle`)}`}
          </Subtitle>
        </BodyRow>
        {getDrawerInputFields()}
      </BodySection>
      {Object.keys(categoryInputRows).length <= 4 ? (
        <AddRowCategorySection id={"add-category-row"} onClick={onClickAddCategory}>
          <PlusIcon />
          {isFeedbackTabActive(sectionId)
            ? `${t(`${feedbacksStringPath}.detailsArea.addCategoryRow`)}`
            : `${t(`${problemsStringPath}.detailsArea.addCategoryRow`)}`}
        </AddRowCategorySection>
      ) : null}
      <FooterSection>
        <div>
          <PrimaryButton
            redTheme={false}
            id={"cancel-button"}
            titleLabel={
              isFeedbackTabActive(sectionId)
                ? t(`${feedbacksStringPath}.detailsArea.cancel`)
                : t(`${problemsStringPath}.detailsArea.cancel`)
            }
            onClick={closeDrawer}
            type={"button"}
          />
        </div>
        <div>
          <PrimaryButton
            redTheme={true}
            id={"save-changes-button"}
            titleLabel={
              isFeedbackTabActive(sectionId)
                ? t(`${feedbacksStringPath}.detailsArea.save`)
                : t(`${problemsStringPath}.detailsArea.save`)
            }
            type={"submit"}
            onClick={onClickSaveChanges}
            disabled={!isSubmitButtonEnabled}
          />
        </div>
      </FooterSection>
    </ComponentContainer>
  );
};

export default DetailsDrawerComponent;

const ComponentContainer = styled("div")`
  font-family: Vodafone Rg;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const HeaderSection = styled("div")`
  width: 100%;
  height: 128px;
  padding: 65px 48.71px 35px 48.71px;
  display: flex;
  align-items: center;
  position: relative;
`;

const HeaderIcon = styled("div")`
  cursor: pointer;
  stroke: ${styleTheme.palette.red.normal};
  margin-right: 8px;
  width: 20px;
  height: 20px;
  svg {
    transform: rotate(90deg);
  }
`;

const HeaderTitle = styled("div")`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: ${styleTheme.palette.anthracite};
`;

const BodySection = styled("div")`
  width: 100%;
  height: auto;
  padding: 0 77px;
  margin: 30px 0 21px 0;
  display: flex;
  flex-direction: column;
`;

const BodyRow = styled("div")<{ hasError?: boolean }>`
  width: 100%;
  text-align: left;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  font-family: inherit;

  button {
    border: ${props => props.hasError && "1px solid red"};
  }
`;

const TitleAndLanguage = styled("div")`
  width: 100%;
  height: 29px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TitleWrapper = styled("span")`
  width: 92px;
  font-family: inherit;
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
  color: ${styleTheme.palette.anthracite};
`;

const Subtitle = styled("span")`
  width: 95%;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: justify;
  color: ${styleTheme.palette.greyDarker};
  margin-top: 6px;
`;

const AddRowCategorySection = styled("div")`
  width: 110px;
  height: 20px;
  align-self: flex-end;
  margin-right: 77px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: ${styleTheme.palette.darkGrey};
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
    stroke: ${styleTheme.palette.vodafoneRed};
    margin-right: 8px;
    padding: 0;
  }
`;

const FooterSection = styled("div")`
  width: 100%;
  height: 104px;
  padding: 0 77px;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  div {
    margin-left: 12px;
  }
`;

const TitleElement = styled("span")`
  min-height: 21px;
  font-family: Vodafone Rg;
  font-weight: bold;
  line-height: 21px;
  font-size: 16px;
  color: ${styleTheme.palette.greyDarker};
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ErrorElement = styled("span")`
  height: 19px;
  color: ${styleTheme.palette.errorRed};
  font-family: Vodafone Rg;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  float: right;
  font-weight: 400;
`;

const RequiredElement = styled("span")`
  height: 21px;
  width: 7px;
  color: ${styleTheme.palette.errorRed};
  font-family: Vodafone Rg;
  font-size: 16px;
  line-height: 21px;
  margin-right: 1px;
  font-weight: 400;
  ::before {
    content: " *";
    color: ${styleTheme.palette.errorRed};
  }
`;

const RemoveButton = styled("div")`
  width: 72px;
  height: 14px;
  margin-left: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    font-family: Vodafone Rg;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: ${styleTheme.palette.darkGrey};
    margin: 0 0 0 5.8px;
  }
  svg {
    width: 20px;
    height: 20px;
    stroke: ${styleTheme.palette.vodafoneRed};
  }
`;
