import styled, { keyframes, css } from "styled-components";

export const primaryButtonStyleConstants = {
  animation: 150,
  height: 36,
};

const normal = keyframes`
  0% {
    margin-left: auto;
    margin-right: auto;
    width: ${primaryButtonStyleConstants.height}px;
    opacity: 0;
  }

  1% {
    opacity: 1;
  }

  99% {
    margin-left: auto;
    margin-right: auto;
  }

  100% {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    opacity: 1;
  }
`;

const animating = keyframes`
  0% {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    opacity: 1;
  }

  1% {
    margin-left: auto;
    margin-right: auto;
  }

  99% {
    opacity: 1;
  }

  100% {
    margin-left: auto;
    margin-right: auto;
    width: ${primaryButtonStyleConstants.height}px;
    padding: 0;
    opacity: 0;
  }
`;

export const ActionMenuButton = styled("button")<{
  redTheme?: boolean;
  disabled?: boolean;
  centered: boolean;
  loading?: boolean;
  hasLoaded: boolean;
}>`
  height: ${primaryButtonStyleConstants.height}px;
  border-radius: 20px;
  opacity: 0;
  ${props =>
    props.hasLoaded
      ? css`
          animation: ${props.loading ? animating : normal}
            ${props.loading === undefined ? 1 : primaryButtonStyleConstants.animation}ms ease-in-out forwards;
        `
      : "margin-left: 0;margin-right: 0;width: 100%;opacity: 1;"}
  animation-delay: ${props => (props.loading || props.loading === undefined ? 1 : `50ms`)};
  svg {
    height: 100%;
    stroke: ${props => {
      if (props.redTheme) {
        if (props.disabled) {
          return "#AFAFAF";
        }
        return "#FFFFFF";
      } else {
        if (props.disabled) {
          return "#AFAFAF";
        }
        return "#ff2a58";
      }
    }};
  }

  background-color: ${props => {
    if (props.redTheme) {
      if (props.disabled) {
        return "#EBEBEB";
      }
      return "#ff2a58";
    } else {
      return "#FFFFFF";
    }
  }};
  box-shadow: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => (props.centered ? "center" : "space-between")};
  border: ${props => (props.centered && !props.redTheme ? `solid 1px #AFAFAF;` : "none")};
  padding: 8px ${props => (props.centered ? "30px" : "12px")};
  cursor: ${props => (props.disabled ? "disabled" : "pointer")};
  outline: 0;
  :hover:not(:disabled) {
    ${props => {
      if (!props.redTheme && props.centered) {
        return `
          border: solid 1px #ff2a58;
          span {
            color: #ff2a58;
          }
        `;
      }
      if (props.redTheme || !props.centered) {
        return "box-shadow: 0 3px 6px 0 rgba(142, 142, 142, 0.23), 0 3px 10px 0 rgba(0, 0, 0, 0.16);";
      }
      return "";
    }}
  }

  :active:not(:disabled) {
    background-color: ${props => (props.redTheme ? "#BD0000" : "#FFFFFF")};
    ${props => (props.centered && props.redTheme ? "box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);" : "")}

    ${props =>
      props.centered && !props.redTheme
        ? `
      border: solid 1px #BD0000;
      span {
        color: #ff2a58;
      }
    `
        : ""}
  }

  :focus {
    outline: 0;
  }
`;

export const ActionMenuTitle = styled.span<{
  redTheme?: boolean;
  disabled?: boolean;
  centered: boolean;
}>`
  color: ${props => {
    if (props.redTheme) {
      if (props.disabled) {
        return "#999999";
      }
      return "#FFFFFF";
    } else {
      if (props.disabled) {
        return "#999999";
      }
      if (props.centered) {
        return "#333333";
      } else {
        return "#ff2a58";
      }
    }
  }};
  font-family: Vodafone Rg;
  font-size: 16px;

  ${props =>
    props.centered && !props.redTheme
      ? `
    :hover {
      color: inherit;
    }

    :active {
      color: inherit;
    }

  `
      : ""}
`;

export const AnimationContainer = styled.div`
  margin-bottom: -${primaryButtonStyleConstants.height}px;
  height: ${primaryButtonStyleConstants.height}px;
  z-index: 0;
  width: 100%;
`;
