import { produce } from "immer";
import { IPayloadAction } from "../../../shared/shared.interfaces";
import { AuthenticationActions } from "../../authentication/authentication.store";
import { INetworks } from "./networks.model";

export const NetworksActions = {
  types: {
    GET_NETWORKS_SM_SUCCESS: "GET_NETWORKS_SM_SUCCESS",
    GET_NETWORKS_SM_LAST_VERSION: "GET_NETWORKS_SM_LAST_VERSION",
    GET_NETWORKS_BA_SUCCESS: "GET_NETWORKS_BA_SUCCESS",
    GET_NETWORKS_BA_LAST_VERSION: "GET_NETWORKS_BA_LAST_VERSION",
  },
  creators: {
    getNetworksSMSuccess: (networks: INetworks) => ({
      type: NetworksActions.types.GET_NETWORKS_SM_SUCCESS,
      payload: {
        networks,
      },
    }),
    getNetworksSMLastVersion: (networks: INetworks) => ({
      type: NetworksActions.types.GET_NETWORKS_SM_LAST_VERSION,
      payload: {
        networks,
      },
    }),
    getNetworksBASuccess: (networks: INetworks) => ({
      type: NetworksActions.types.GET_NETWORKS_BA_SUCCESS,
      payload: {
        networks,
      },
    }),
    getNetworksBALastVersion: (networks: INetworks) => ({
      type: NetworksActions.types.GET_NETWORKS_BA_LAST_VERSION,
      payload: {
        networks,
      },
    }),
  },
};
export interface INetworksReducerInterface {
  networksSM: INetworks;
  lastVersionSM: INetworks;
  networksBA: INetworks;
  lastVersionBA: INetworks;
}

const initialState: INetworksReducerInterface = {
  networksSM: {} as INetworks,
  lastVersionSM: {} as INetworks,
  networksBA: {} as INetworks,
  lastVersionBA: {} as INetworks,
};

export const networksReducer = produce((draft: INetworksReducerInterface, action: IPayloadAction<any>) => {
  switch (action.type) {
    case NetworksActions.types.GET_NETWORKS_SM_SUCCESS:
      draft.networksSM = action.payload.networks;
      return;
    case NetworksActions.types.GET_NETWORKS_SM_LAST_VERSION:
      draft.lastVersionSM = action.payload.networks;
      return;
    case NetworksActions.types.GET_NETWORKS_BA_SUCCESS:
      draft.networksBA = action.payload.networks;
      return;
    case NetworksActions.types.GET_NETWORKS_BA_LAST_VERSION:
      draft.lastVersionBA = action.payload.networks;
      return;
    case AuthenticationActions.types.LOGOUT:
      return initialState;
    default:
      return draft;
  }
}, initialState);
