import { AutoClosingDropdown, PrimaryButton, TextInput, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikHelpers, yupToFormErrors } from "formik";
import { FormikErrors } from "formik/dist/types";
import i18next from "i18next";
import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { number, object, string } from "yup";
import { ICurrency } from "../../../../../shared/shared.interfaces";
import { Column, RatioContainer, Row } from "../../../../../shared/shared.styled";
import BundlesApi from "../../bundles.api";
import {
  IBundleType,
  IConfigurationFields,
  IEditSubBundleRequest,
  ISubBundle,
  SubBundleStatus,
} from "../../bundles.model";
import {
  getConfigurationFieldTranslation,
  getCurrenciesDropdown,
  getNetworkOptions,
  getValidityOptions,
  cleanupSubBundleRequest,
} from "../sub-bundles.utils";
import SubBundleStatusChip from "./sub-bundle-status.component";
import { ConfigContext } from "../../../../../app.component";
import GenericLanguageSelector from "../../../../../shared/components/generic-language-selector.component";

interface IEditSubBundleModalInterface {
  bundle: IBundleType;
  configurationFields: IConfigurationFields;
  dismissModal: () => void;
  reloadSubBundles: () => void;
  subBundle: ISubBundle;
}

/**
 * Edit sub bundle
 * @param param0
 */
const EditSubBundleModal = ({
  bundle,
  configurationFields,
  dismissModal,
  reloadSubBundles,
  subBundle,
}: IEditSubBundleModalInterface) => {
  const [t] = useTranslation();
  const availableLanguages = Object.keys(bundle.name.translations).sort();
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    availableLanguages.includes("en") ? "en" : availableLanguages[0],
  );
  const [formValues, setFormValues] = useState<IEditSubBundleRequest>();
  const [currencies, setCurrencies] = useState<ICurrency[]>();
  const [showAlert, , setAlertProps] = useAlert();
  const { config } = useContext(ConfigContext);

  React.useEffect(() => {
    BundlesApi.methods.getCurrencies().then(
      res => {
        const c = res.data.currencies.map((c: ICurrency) => {
          return c.currencyCode;
        });
        setCurrencies(c);
        const trans: { [x: string]: string } = {};
        availableLanguages.forEach(lang => {
          trans[lang] = "";
        });
        setFormValues({
          id: subBundle.id,
          cost: subBundle.cost,
          content: {
            type: subBundle.content.type,
            sms: { translations: subBundle.content.sms ? subBundle.content.sms.translations : trans },
            data: { translations: subBundle.content.data ? subBundle.content.data.translations : trans },
            calls: { translations: subBundle.content.calls ? subBundle.content.calls.translations : trans },
          },
          currency: subBundle.currency,
          frequency: config!.bundles.showFrequency ? subBundle.frequency : undefined,
          network: config!.bundles.showNetworks ? subBundle.network : undefined,
          identifierCode: config!.bundles.showIdentifierCode ? subBundle.identifierCode : undefined,
          recipientType: subBundle.recipientType,
          shortcode: subBundle.shortcode,
          status: subBundle.status,
          voiceOnNet: subBundle.voiceOnNet,
          voiceAllNet: subBundle.voiceAllNet,
          smsAllNet: subBundle.smsAllNet,
          dataAllNet: subBundle.dataAllNet,
        });
      },
      () => {},
    );
  }, []);

  /**
   * Validate translations
   */
  const validateTranslations = (values: IEditSubBundleRequest, errors: any) => {
    if (errors && errors.content) {
      setAlertProps({
        type: AlertTypeEnum.ERROR,
        title: t("pages.bundleDetails.editSubBundleModal.fillTranslationsError"),
      });
      showAlert();
    }
  };

  /**
   * Form submit
   */
  const editSubBundle = (values: IEditSubBundleRequest, actions: FormikHelpers<IEditSubBundleRequest>) => {
    actions.setSubmitting(true);

    /*["sms", "data", "calls"].map(k => {
      // @ts-ignore
      Object.keys(values.content[k].translations).map(o => {
        // @ts-ignore
        if (values.content[k].translations[o] === "") {
          // @ts-ignore
          //TODO: this shouldn't be needed in the future
          values.content[k].translations[o] = " ";
        }
      });
    });*/
    BundlesApi.methods
      .editSubBundle(cleanupSubBundleRequest(values, config) as IEditSubBundleRequest, bundle.id, subBundle.id)
      .then(
        () => {
          setAlertProps({
            type: AlertTypeEnum.SUCCESS,
            title: t("pages.bundleDetails.editSubBundleModal.editSubBundleSuccess"),
          });
          showAlert();
          reloadSubBundles();
          dismissModal();
        },
        err => {
          setAlertProps({
            type: AlertTypeEnum.ERROR,
            title:
              err && err.data && err.data.status && err.data.status.message
                ? err.data.status.message
                : t("pages.bundleDetails.editSubBundleModal.editSubBundleError"),
          });
          showAlert();
        },
      )
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  /**
   * Validator helper for the sms, calls and data input
   * @param property - when property to enable or disable the required validation
   */
  const getTranslationValidators = (property: string) => {
    let validators: { [x: string]: any } = {};
    availableLanguages.map((lang: string) => {
      validators = {
        ...validators,
        [lang]: string()
          .when(property, {
            is: true,
            then: string().required(i18next.t("pages.addBundle.translationRequired")),
          })
          .max(255, i18next.t("pages.addBundle.maxLenDescription")),
      };
    });
    return validators;
  };

  /**
   * Form validation schema
   */
  const schema = object().shape({
    content: object().shape({
      sms: object().shape({
        translations: object().shape(getTranslationValidators("$isSmsRequired")),
      }),
      calls: object().shape({
        translations: object().shape(getTranslationValidators("$isCallsRequired")),
      }),
      data: object().shape({
        translations: object().shape(getTranslationValidators("$isDataRequired")),
      }),
    }),
    identifierCode: string()
      .max(255, i18next.t("pages.addBundle.maxLenDescription"))
      .when("$isIdentifierCodeRequired", {
        is: true,
        then: string().required(i18next.t("pages.addBundle.translationRequired")),
      }),
    cost: number()
      .typeError(i18next.t("pages.addBundle.mustBeNumber"))
      .required(i18next.t("pages.addBundle.requiredField"))
      .min(0, i18next.t("pages.addBundle.positiveNumber")),
    shortcode: string()
      .required(i18next.t("pages.addBundle.requiredField"))
      .max(255, i18next.t("pages.addBundle.maxLenDescription")),
    voiceOnNet: number()
      .when("$isUpsellRequired", {
        is: true,
        then: number().required(i18next.t("pages.addBundle.requiredField")),
      })
      .typeError(i18next.t("pages.addBundle.mustBeNumber")),
    voiceAllNet: number()
      .typeError(i18next.t("pages.addBundle.mustBeNumber"))
      .when("$isUpsellRequired", {
        is: true,
        then: number().required(i18next.t("pages.addBundle.requiredField")),
      }),
    smsAllNet: number()
      .typeError(i18next.t("pages.addBundle.mustBeNumber"))
      .when("$isUpsellRequired", {
        is: true,
        then: number().required(i18next.t("pages.addBundle.requiredField")),
      }),
    dataAllNet: number()
      .typeError(i18next.t("pages.addBundle.mustBeNumber"))
      .when("$isUpsellRequired", {
        is: true,
        then: number().required(i18next.t("pages.addBundle.requiredField")),
      }),
  });

  /**
   * Check if translations are needed
   */
  const needsTranslation = (values: IEditSubBundleRequest, property: "sms" | "data" | "calls") => {
    let result = false;
    Object.keys(values.content[property]?.translations).map(o => {
      if (values.content[property]?.translations[o] !== "") {
        result = true;
      }
    });
    return result;
  };

  /**
   * Fn to validate the form every stroke
   * @param values
   */
  const validateSchema = (values: IEditSubBundleRequest): Promise<FormikErrors<IEditSubBundleRequest>> => {
    return new Promise<FormikErrors<IEditSubBundleRequest>>(resolve => {
      const isSmsRequired = needsTranslation(values, "sms"),
        isDataRequired = needsTranslation(values, "data"),
        isCallsRequired = needsTranslation(values, "calls");
      schema
        .validate(values, {
          context: {
            isSmsRequired: isSmsRequired,
            isDataRequired: isDataRequired,
            isCallsRequired: isCallsRequired,
            isUpsellRequired: bundle.upsellPromo,
            isIdentifierCodeRequired: config!.bundles.showIdentifierCode,
          },
          abortEarly: false,
        })
        .then(() => {
          resolve({});
        })
        .catch(y => {
          resolve(yupToFormErrors(y));
        });
    }).then(r => {
      return r;
    });
  };

  /**
   * Main form inputs
   */
  const getMainFormInputs = (
    values: IEditSubBundleRequest,
    handleChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined,
    setFieldValue: any,
    errors: any,
  ) => {
    return (
      <>
        {config!.bundles.showIdentifierCode && (
          <FormContainer>
            <RatioContainer ratio={4 / 12} style={{ marginRight: "12px" }}>
              <TextInput
                name="identifierCode"
                title={t("pages.bundleDetails.addSubBundleModal.identifierCode")}
                placeholder={t("pages.bundleDetails.addSubBundleModal.identifierCodePlaceholder")}
                onChange={handleChange}
                value={values.identifierCode || ""}
                error={errors.identifierCode}
                maxLength={100}
                required
              />
            </RatioContainer>
          </FormContainer>
        )}
        <FormContainer>
          <RatioContainer ratio={4 / 12} style={{ marginRight: "12px" }}>
            <TextInput
              name="cost"
              title={t("pages.bundleDetails.editSubBundleModal.cost")}
              placeholder={t("pages.bundleDetails.editSubBundleModal.costPlaceholder")}
              onChange={handleChange}
              value={values.cost || ""}
              error={errors.cost}
              maxLength={100}
              required
            />
          </RatioContainer>
          <RatioContainer ratio={2 / 12} style={{ marginRight: "12px" }}>
            <Column>
              <LabelRequired>{t("pages.bundleDetails.editSubBundleModal.currency")}</LabelRequired>
              <DropdownContainer id={"currencies-dropdown"}>
                <AutoClosingDropdown
                  hasValue={values.currency !== ""}
                  label={values.currency}
                  options={getCurrenciesDropdown(currencies)}
                  error={errors.currency}
                  selectOption={opt => setFieldValue("currency", opt.key)}
                />
              </DropdownContainer>
            </Column>
          </RatioContainer>
          <RatioContainer ratio={6 / 12}>
            <TextInput
              name="shortcode"
              title={t("pages.bundleDetails.editSubBundleModal.shortcode")}
              placeholder={t("pages.bundleDetails.editSubBundleModal.shortcodePlaceholder")}
              onChange={handleChange}
              value={values.shortcode || ""}
              error={errors.shortcode}
              maxLength={100}
              required
            />
          </RatioContainer>
        </FormContainer>
        {(config!.bundles.showFrequency || config!.bundles.showNetworks) && (
          <FormContainer>
            {config!.bundles.showFrequency && values.frequency && (
              <RatioContainer ratio={6 / 12}>
                <Column>
                  <LabelRequired>{t("pages.bundleDetails.editSubBundleModal.validity")}</LabelRequired>
                  <DropdownContainer id={"validity-dropdown"}>
                    <AutoClosingDropdown
                      hasValue={values.frequency !== ""}
                      label={getConfigurationFieldTranslation(values.frequency, configurationFields.frequency)}
                      options={getValidityOptions(configurationFields)}
                      error={errors.frequency}
                      selectOption={opt => setFieldValue("frequency", opt.key)}
                    />
                  </DropdownContainer>
                </Column>
              </RatioContainer>
            )}
            {config!.bundles.showNetworks && values.network && (
              <RatioContainer ratio={6 / 12}>
                <Column style={{ paddingLeft: "12px" }}>
                  <LabelRequired>{t("pages.bundleDetails.editSubBundleModal.network")}</LabelRequired>
                  <DropdownContainer id={"network"}>
                    <AutoClosingDropdown
                      hasValue={values.network !== ""}
                      label={getConfigurationFieldTranslation(values.network, configurationFields.plan)}
                      options={getNetworkOptions(configurationFields)}
                      error={errors.network}
                      selectOption={opt => setFieldValue("network", opt.key)}
                    />
                  </DropdownContainer>
                </Column>
              </RatioContainer>
            )}
          </FormContainer>
        )}
        <FormContainer>
          <RatioContainer ratio={6 / 12}>
            <Column>
              <LabelRequired>{t("pages.bundleDetails.editSubBundleModal.options")}</LabelRequired>
              <DropdownContainer id={"options-dropdown"}>
                <AutoClosingDropdown
                  hasValue={!!(values.recipientType !== "ANY" || "SELF" || "OTHERS")}
                  label={t(`pages.bundleDetails.recipientType.${values.recipientType}`)}
                  options={[
                    {
                      key: "ANY",
                      label: t("pages.bundleDetails.recipientType.ANY"),
                    },
                    {
                      key: "SELF",
                      label: t("pages.bundleDetails.recipientType.SELF"),
                    },
                    {
                      key: "OTHERS",
                      label: t("pages.bundleDetails.recipientType.OTHERS"),
                    },
                  ]}
                  error={errors.recipientType}
                  selectOption={opt => setFieldValue("recipientType", opt.key)}
                />
              </DropdownContainer>
            </Column>
          </RatioContainer>
        </FormContainer>
      </>
    );
  };

  /**
   * Form translatable inputs
   */
  const getTranslatableInputs = (
    values: IEditSubBundleRequest,
    handleChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined,
    setFieldValue: any,
    errors: any,
  ) => {
    return (
      <div>
        <Row>
          <FormContainerTitle>{t("pages.bundleDetails.editSubBundleModal.bundleDetails")}</FormContainerTitle>
          <div style={{ marginTop: "auto", marginLeft: "auto" }}>
            <GenericLanguageSelector
              selectedLanguage={selectedLanguage}
              availableLanguages={availableLanguages}
              changeSelectedLanguage={(lang: any) => setSelectedLanguage(lang.key)}
            ></GenericLanguageSelector>
          </div>
        </Row>
        {availableLanguages.map(lang => {
          return (
            <TranslationContainer key={`translation-container-${lang}`} show={lang === selectedLanguage}>
              {(config!.bundles.showSMS || config!.bundles.showCalls) && (
                <FormContainer>
                  {config!.bundles.showSMS && (
                    <RatioContainer key={`sms-${lang}`} ratio={6 / 12}>
                      <Column>
                        <TextInput
                          name={`content.sms.translations[${lang}]`}
                          title={t("pages.bundleDetails.editSubBundleModal.smsDetails", {
                            language: lang.toUpperCase(),
                          })}
                          placeholder={t("pages.bundleDetails.editSubBundleModal.smsDetailsPlaceholder")}
                          onChange={e => setFieldValue(`content.sms.translations[${lang}]`, e.target.value)}
                          value={values.content.sms?.translations[lang] || ""}
                          maxLength={100}
                          error={
                            errors.content &&
                            errors.content.sms &&
                            errors.content.sms.translations &&
                            errors.content.sms.translations[lang]
                              ? errors.content.sms.translations[lang]
                              : undefined
                          }
                        />
                      </Column>
                    </RatioContainer>
                  )}
                  {config!.bundles.showCalls && (
                    <RatioContainer key={`calls-${lang}`} ratio={6 / 12}>
                      <Column style={{ paddingLeft: "12px" }}>
                        <TextInput
                          name={`content.calls.translations[${lang}]`}
                          title={t("pages.bundleDetails.editSubBundleModal.callDetails", {
                            language: lang.toUpperCase(),
                          })}
                          placeholder={t("pages.bundleDetails.editSubBundleModal.callDetailsPlaceholder")}
                          onChange={e => setFieldValue(`content.calls.translations[${lang}]`, e.target.value)}
                          value={values.content.calls?.translations[lang] || ""}
                          maxLength={100}
                          error={
                            errors.content &&
                            errors.content.calls &&
                            errors.content.calls.translations &&
                            errors.content.calls.translations[lang]
                              ? errors.content.calls.translations[lang]
                              : undefined
                          }
                        />
                      </Column>
                    </RatioContainer>
                  )}
                </FormContainer>
              )}
              <FormContainer>
                {config!.bundles.showData && (
                  <RatioContainer key={`data-${lang}`} ratio={6 / 12}>
                    <Column>
                      <TextInput
                        name={`content.data.translations[${lang}]`}
                        title={t("pages.bundleDetails.editSubBundleModal.dataDetails", {
                          language: lang.toUpperCase(),
                        })}
                        placeholder={t("pages.bundleDetails.editSubBundleModal.dataDetailsPlaceholder")}
                        onChange={e => setFieldValue(`content.data.translations[${lang}]`, e.target.value)}
                        value={values.content.data?.translations[lang] || ""}
                        maxLength={100}
                        error={
                          errors.content &&
                          errors.content.data &&
                          errors.content.data.translations &&
                          errors.content.data.translations[lang]
                            ? errors.content.data.translations[lang]
                            : undefined
                        }
                      />
                    </Column>
                  </RatioContainer>
                )}
                <RatioContainer key={`status-${lang}`} ratio={6 / 12}>
                  <div style={{ paddingLeft: "12px" }}>
                    <Label>{t("pages.bundleDetails.editSubBundleModal.status")}</Label>
                    {values.status === SubBundleStatus.DISABLE || values.status === SubBundleStatus.DISABLED ? (
                      <SubBundleStatusChip
                        id="status-switch"
                        type={SubBundleStatus.DISABLE}
                        style={{ marginTop: "8px", cursor: "pointer" }}
                        onClick={() => setFieldValue("status", SubBundleStatus.ENABLE)}
                      >
                        {t(`commons.apps.enums.subBundleStatus.DISABLE`)}
                      </SubBundleStatusChip>
                    ) : (
                      <SubBundleStatusChip
                        id="status-switch"
                        type={SubBundleStatus.ENABLE}
                        style={{ marginTop: "8px", cursor: "pointer" }}
                        onClick={() => setFieldValue("status", SubBundleStatus.DISABLE)}
                      >
                        {t(`commons.apps.enums.subBundleStatus.ENABLE`)}
                      </SubBundleStatusChip>
                    )}
                  </div>
                </RatioContainer>
              </FormContainer>
            </TranslationContainer>
          );
        })}
      </div>
    );
  };

  /**
   * Form upsell inputs
   */
  const getUpsellInputs = (
    values: IEditSubBundleRequest,
    handleChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined,
    setFieldValue: any,
    errors: any,
  ) => {
    return (
      <div>
        <Row>
          <FormContainerTitle>{t("pages.bundleDetails.editSubBundleModal.upsellDetails")}</FormContainerTitle>
        </Row>

        <FormContainer>
          <RatioContainer ratio={6 / 12}>
            <Column>
              <TextInput
                type={"text"}
                name={`voiceOnNet`}
                title={t("pages.bundleDetails.editSubBundleModal.voiceOnNet")}
                placeholder={t("pages.bundleDetails.editSubBundleModal.insertVoiceOnNet")}
                onChange={e => setFieldValue(`voiceOnNet`, e.target.value)}
                value={values.voiceOnNet}
                error={errors.voiceOnNet}
                maxLength={9}
                required
              />
            </Column>
          </RatioContainer>
          <RatioContainer ratio={6 / 12}>
            <Column style={{ paddingLeft: "12px" }}>
              <TextInput
                type={"text"}
                name={`voiceAllNet`}
                title={t("pages.bundleDetails.editSubBundleModal.voiceAllNet")}
                placeholder={t("pages.bundleDetails.editSubBundleModal.insertVoiceAllNet")}
                onChange={e => setFieldValue(`voiceAllNet`, e.target.value)}
                value={values.voiceAllNet}
                error={errors.voiceAllNet}
                maxLength={9}
                required
              />
            </Column>
          </RatioContainer>
        </FormContainer>
        <FormContainer>
          <RatioContainer ratio={6 / 12}>
            <Column>
              <TextInput
                type={"text"}
                name={`smsAllNet`}
                title={t("pages.bundleDetails.editSubBundleModal.smsAllNet")}
                placeholder={t("pages.bundleDetails.editSubBundleModal.insertSmsAllNet")}
                onChange={e => setFieldValue(`smsAllNet`, e.target.value)}
                value={values.smsAllNet}
                error={errors.smsAllNet}
                maxLength={9}
                required
              />
            </Column>
          </RatioContainer>
          <RatioContainer ratio={6 / 12}>
            <Column style={{ paddingLeft: "12px" }}>
              <TextInput
                type={"text"}
                name={`dataAllNet`}
                title={t("pages.bundleDetails.editSubBundleModal.dataAllNet")}
                placeholder={t("pages.bundleDetails.editSubBundleModal.insertDataAllNet")}
                onChange={e => setFieldValue(`dataAllNet`, e.target.value)}
                value={values.dataAllNet}
                error={errors.dataAllNet}
                maxLength={9}
                required
              />
            </Column>
          </RatioContainer>
        </FormContainer>
      </div>
    );
  };

  return (
    <ModalContainer>
      <ModalTitle>{t("pages.bundleDetails.editSubBundleModal.title")}</ModalTitle>
      {formValues ? (
        <Formik
          initialValues={formValues}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={editSubBundle}
          validate={validateSchema}
          render={({ values, handleChange, isSubmitting, handleSubmit, setFieldValue, errors }) => (
            <form onSubmit={handleSubmit}>
              {getMainFormInputs(values, handleChange, setFieldValue, errors)}
              {getTranslatableInputs(values, handleChange, setFieldValue, errors)}
              {bundle.upsellPromo && getUpsellInputs(values, handleChange, setFieldValue, errors)}
              <ButtonsContainer>
                <div>
                  <PrimaryButton
                    id="cancel-button"
                    type="button"
                    onClick={dismissModal}
                    titleLabel={t("pages.bundleDetails.editSubBundleModal.cancel")}
                  />
                </div>
                <div style={{ width: "156px" }}>
                  <PrimaryButton
                    id="save-changes-button"
                    loading={isSubmitting}
                    redTheme={true}
                    type="submit"
                    onClick={() => validateTranslations(values, errors)}
                    titleLabel={t("pages.bundleDetails.editSubBundleModal.save")}
                    style={{ width: "156px" }}
                  />
                </div>
              </ButtonsContainer>
            </form>
          )}
        />
      ) : null}
    </ModalContainer>
  );
};

export default EditSubBundleModal;

const TranslationContainer = styled.div<{ show: boolean }>`
  display: ${props => (props.show ? "inherit" : "none")};
`;

const ModalContainer = styled("div")`
  border-radius: 6px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  padding: 24px;
`;

const ModalTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 13px;
`;

const FormContainer = styled("div")`
  padding-top: 24px;
  display: flex;
`;

const FormContainerTitle = styled("p")`
  font-family: Vodafone Rg;
  font-size: 18px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 0px;
  margin-top: 34px;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 50px;
  > div {
    width: fit-content;
    :first-of-type {
      margin-right: 12px;
    }
  }
`;

const DropdownContainer = styled("div")`
  button {
    border-radius: 6px;
    border: 1px solid #ebebeb;
  }
`;

const Label = styled("div")`
  font-family: "Vodafone Rg";
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
  margin-bottom: 8px;
  min-height: 21px;
`;

const LabelRequired = styled(Label)`
  ::after {
    content: " *";
    color: ${props => props.theme.palette.errorRed};
  }
`;
