import React, { useState, useEffect } from "react";
import { TextInput, SearchIcon, CheckIcon } from "@wit/mpesa-ui-components";
import styled from "styled-components";
import { IconContainer } from "../../../../shared/shared.styled";
import { useTranslation } from "react-i18next";
import { icons, getIconName } from "../business-utils";

interface IIconsDropDownProps {
  onIconSelect: (icon: string) => void;
  initialIcon: string | undefined;
  errors?: string;
}

const getIconString = (name: string | undefined) => {
  if (!name) {
    return "";
  }
  return icons.find(ic => getIconName(ic) === name);
};
const IconsDropDown = ({ onIconSelect, initialIcon, errors }: IIconsDropDownProps) => {
  const [showResult, setShowResult] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string | undefined>("");
  const [iconSelected, setIconSelected] = useState<boolean>(false);
  const [t] = useTranslation();

  const renderList = (searchTerm: string | undefined) => {
    let results = [];

    if (!searchTerm || searchTerm === "") {
      results = icons;
    } else {
      results = icons.filter(icon => icon.toLowerCase().includes(searchTerm.toLowerCase()));
    }
    return (
      <div>
        {results.map((iconName: any) => {
          return (
            <List
              key={iconName}
              onClick={() => {
                setSearchTerm(iconName);
                setShowResult(false);
                onIconSelect(getIconName(iconName));
                setIconSelected(true);
              }}
            >
              <IconWrapper>
                <ResultIcon src={`./images/categoryIcons/${getIconName(iconName)}`} alt={iconName} />
              </IconWrapper>
              <div>{iconName}</div>
            </List>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    if (initialIcon) {
      setIconSelected(true);
      setSearchTerm(getIconString(initialIcon));
    }
  }, [initialIcon]);

  return (
    <>
      <Wrapper
        onBlur={() => {
          // without this timeout  cannot perform onClick event
          setTimeout(() => setShowResult(false), 200);
        }}
      >
        <InputWrapper>
          <TextInput
            title={t("pages.categories.edit.addIcon")}
            placeholder={t("pages.categories.edit.chooseFromLibrary")}
            required
            error={errors || ""}
            value={searchTerm}
            onChange={e => {
              setSearchTerm(e.target.value);
              setShowResult(true);
              setIconSelected(false);
            }}
            onFocus={e => {
              setShowResult(true);
            }}
          />
          <IconContainer size={18} color={iconSelected ? "#00b0ca" : "black"} className="iconContainer">
            {iconSelected ? <CheckIcon></CheckIcon> : <SearchIcon></SearchIcon>}
          </IconContainer>
        </InputWrapper>
      </Wrapper>
      {showResult && <ResultWrapper>{renderList(searchTerm)}</ResultWrapper>}
    </>
  );
};

export default IconsDropDown;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  .iconContainer {
    position: absolute;
    bottom: 9px;
    left: calc(100% - 24px);
  }
`;

const ResultWrapper = styled.div`
  position: absolute;
  z-index: 100;
  width: 426px;
  background-color: white;
  max-height: 100px;
  /* margin-top: 90px; */
  border-radius: 4px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  overflow: scroll;
  overflow-x: hidden;
  font-family: Vodafone Rg;
  margin-top: 0px;
`;

const IconWrapper = styled.div`
  width: 30px;
`;
const ResultIcon = styled.img`
  width: 14px;
`;

const List = styled.div`
  display: flex;
  flex-direction: row;
  opacity: 0.5;
  padding: 9px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
