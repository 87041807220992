import { CancelIcon } from "@wit/mpesa-ui-components";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Column, Row } from "../../../../../shared/shared.styled";
import { IAppProperties, AppPropertyStatusEnum } from "../app-properties.model";
import { StatusText } from "./publish-app-properties.modal";

interface IAppPropertiesLastVersionModalProps {
  appProperties: IAppProperties;
  onCancelClick: () => void;
}

const AppPropertiesLastVersionModal = ({ appProperties, onCancelClick }: IAppPropertiesLastVersionModalProps) => {
  const [t] = useTranslation();

  return (
    <ModalContainer>
      <Header>
        <ModalTitle>{t("pages.appProperties.lastVersionPublished")}</ModalTitle>
        <RightContainer>
          <CloseIconContainer onClick={onCancelClick} id="close-last-version-modal">
            <CancelIcon color={"grey"} />
          </CloseIconContainer>
        </RightContainer>
      </Header>
      <LastVersionContainer>
        {appProperties.properties ? (
          <>
            {appProperties.properties.map(appProp => {
              return (
                <Row key={appProp.id} style={{ marginBottom: "33px", alignItems: "flex-start" }}>
                  <Column>
                    <Label>
                      <b>{appProp.fieldName}</b>
                    </Label>
                    <Label>
                      {t("pages.appProperties.value")}: {appProp.value}
                    </Label>
                  </Column>
                  <Column style={{ flexGrow: 1, alignItems: "flex-end" }}>
                    <StatusText status={appProp.status} style={{ paddingRight: "8px" }}>
                      {t(`pages.appProperties.appPropertiesStatusEnum.${appProp.status}`)}
                    </StatusText>
                  </Column>
                </Row>
              );
            })}
          </>
        ) : null}
      </LastVersionContainer>
    </ModalContainer>
  );
};

export default AppPropertiesLastVersionModal;

const ModalContainer = styled("div")`
  border-radius: 6px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  padding: 24px;
`;

const ModalTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 24px;
`;

const LastVersionContainer = styled("div")`
  font-family: Vodafone Rg;
  height: 400px;
  overflow-y: auto;
  font-size: 16px;
`;

const Label = styled("span")`
  padding-top: 5px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const RightContainer = styled.div`
  display: flex;
`;

const CloseIconContainer = styled("div")`
  left: 23px;
  margin-top: -10px;
  stroke: ${props => props.theme.palette.midGrey};
  cursor: pointer;
  svg {
    width: 24px;
  }
`;
