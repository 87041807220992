import { HelpIcon, NotificationIcon, ProfileIcon, ElementWithTooltip } from "@wit/mpesa-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { IConfig } from "../../app.component";
import { IStoreInterface } from "../../configs/store.config";
import { ILoggedUser } from "../../features/authentication/authentication.interfaces";
import RendererUtils from "../renderer.utils";
import { UnreadIcon } from "./notifications-area/notification.component";
import { useHistory, useLocation } from "react-router-dom";

interface INavigationBarProps {
  setDrawerVisible: (val: boolean) => any;
  setNotificationsVisible: (val: boolean) => any;
  setNotifications: (val: any) => any;
  getNotifications: () => any;
  hasUnreadNotifications: boolean;
  config: IConfig;
}

/**
 * Render the navigation bar items and icons.
 * @function NavigationBar
 * @param {INavigationBarProps} navigationOptions
 * @returns {JSX.Element}
 */
const NavigationBar = ({
  setDrawerVisible,
  config,
  setNotificationsVisible,
  setNotifications,
  getNotifications,
  hasUnreadNotifications,
}: INavigationBarProps) => {
  const loggedUser = useSelector((state: IStoreInterface) => state.authenticationReducer.loggedUser) as ILoggedUser;
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    getNotifications();
  }, [location]);

  return (
    <NavigationBarContainer>
      <NavigationBrandContainer>{RendererUtils.getNavigationMenuLogoUsingConfig(config)}</NavigationBrandContainer>
      {RendererUtils.getNavigationIconsUsingConfig(
        {
          labels: {
            apps: t("components.navigationBar.apps"),
            config: t("components.navigationBar.config"),
            admin: t("components.navigationBar.admin"),
            support: t("components.navigationBar.support"),
            serviceBuilder: t("components.navigationBar.serviceBuilder"),
            serviceManager: t("components.navigationBar.serviceManager"),
            antServiceBuilder: t("components.navigationBar.antServiceBuilder"),
            announcements: t("components.navigationBar.announcements"),
            topics: t("components.navigationBar.topics"),
            notifications: t("components.navigationBar.notifications"),
            pushNotifications: t("components.navigationBar.pushNotifications"),
            docDashboard: t("components.navigationBar.docDashboard"),
            docValidation: t("components.navigationBar.docValidation"),
            docBulkList: t("components.navigationBar.docBulkList"),
            tools: t("components.navigationBar.tools"),
            auditing: t("components.navigationBar.auditing"),
          },
        },
        history,
        config,
        loggedUser,
      )}
      <SecondaryContainer id={"secondary-navigation-container"}>
        <ElementWithTooltip
          marginBottom={10.83}
          tooltipPosition={1}
          tooltipArrow={true}
          tooltipHeight={35}
          tooltipMargin={0}
          containerKeyId={"notifications"}
          tooltipLabel={t("components.navigationBar.notifications")}
        >
          <SecondaryNavLinkContainer onClick={() => setNotificationsVisible(true)} id={"notifications-icon"}>
            <NotificationIcon />
            {hasUnreadNotifications ? (
              <UnreadIcon
                size={"6px"}
                style={{
                  marginTop: "0px",
                  alignSelf: "flex-start",
                  position: "relative",
                  left: "-1px",
                  top: "-19px",
                }}
              ></UnreadIcon>
            ) : null}
          </SecondaryNavLinkContainer>
        </ElementWithTooltip>

        <ElementWithTooltip
          marginBottom={10.83}
          tooltipPosition={1}
          tooltipArrow={true}
          tooltipHeight={35}
          tooltipMargin={0}
          containerKeyId={"help"}
          tooltipLabel={t("components.navigationBar.help")}
        >
          <SecondaryNavLinkContainer id={"help-icon"}>
            <HelpIcon />
          </SecondaryNavLinkContainer>
        </ElementWithTooltip>

        <ElementWithTooltip
          marginBottom={26}
          tooltipPosition={1}
          tooltipArrow={true}
          tooltipHeight={35}
          tooltipMargin={0}
          containerKeyId={"profile"}
          tooltipLabel={t("components.navigationBar.profile")}
        >
          <SecondaryNavLinkContainer onClick={() => setDrawerVisible(true)} id={"profile-icon"}>
            <ProfileIcon />
          </SecondaryNavLinkContainer>
        </ElementWithTooltip>
      </SecondaryContainer>
    </NavigationBarContainer>
  );
};

export default NavigationBar;

const NavigationBarContainer = styled("div")`
  width: 70px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;
  border-right: 1px solid ${props => props.theme.palette.aluminium};
  z-index: 10;
  > span,
  > div {
    cursor: pointer;
  }

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;

const NavigationBrandContainer = styled("div")`
  width: 35px;
  height: 35px;
  margin-top: 45px;
  margin-bottom: 45px;
`;

export const NavigationLinkContainer = styled("span")<{ active?: boolean }>`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  stroke: ${props => (props.active ? props.theme.palette.white : "#8d99a7")};
  border-radius: 50%;
  background-color: ${props => (props.active ? props.theme.palette.vodafoneRed : "inherit")};

  svg {
    width: 24px;
    height: 24px;
  }
`;

const SecondaryContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  width: 100%;
`;

const SecondaryNavLinkContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 35px;
  height: 35px;

  svg {
    width: 24px;
    height: 24px;
    stroke: ${props => props.theme.palette.midGrey};
  }
`;
