import { Checkbox, PrimaryButton, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { AxiosResponse } from "axios";
import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import LanguageTranslationsApi from "../language-translations.api";
import Dropzone from "../../../../shared/components/csv-dropzone.component";
import { IImportLanguageTranslationRequest, LanguageTranslationModalsEnum } from "../language-translations.model";
import { LanguageTranslationsActions } from "../language-translations.store";
import { Formik } from "formik";

interface IImportLanguageTranslationsModalInterface {
  dismissModal: (modal: string) => void;
  version: string;
}

const ExportLanguageTranslationsModal = ({ dismissModal, version }: IImportLanguageTranslationsModalInterface) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [showAlert, , setAlertProps] = useAlert();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [requestPayload, setRequestPayload] = React.useState<IImportLanguageTranslationRequest>({
    translationsFile: null,
    version: version,
  });

  /**
   * Hides modal that is visible on the screen
   * @returns function
   */
  const hideModal = () => dismissModal(LanguageTranslationModalsEnum.IMPORT);

  /**
   * Set request data on json file upload
   */
  const onDrop = React.useCallback(acceptedFile => {
    if (acceptedFile.length > 0) {
      const file: File = acceptedFile[0];
      setRequestPayload({
        ...requestPayload,
        translationsFile: file,
      });
    }
  }, []);

  /**
   * Performs the import request
   */
  const submitRequest = () => {
    setIsSubmitting(true);
    LanguageTranslationsApi.methods.importLanguageTranslation(requestPayload).then(
      res => {
        setIsSubmitting(false);
        setAlertProps({
          title: t("pages.languageTranslations.importModal.importSuccess"),
          type: AlertTypeEnum.SUCCESS,
        });
        showAlert();
        dispatch(LanguageTranslationsActions.creators.getLanguageTranslationsSuccess(res.data));
        hideModal();
      },
      () => {
        setIsSubmitting(false);
        setAlertProps({
          title: t("pages.languageTranslations.importModal.importError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  return (
    <ModalContainer>
      <ModalTitle>{t("pages.languageTranslations.importModal.title")}</ModalTitle>
      <div style={{ minHeight: "107px" }}>
        <Formik
          onSubmit={() => {}}
          validateOnChange={false}
          initialValues={{
            jsonFile: null,
          }}
          render={({ setFieldValue, errors, setErrors }) => (
            <ImportTranslationForm>
              <div>{errors.jsonFile ? <ImportTranslationError>{errors.jsonFile}</ImportTranslationError> : null}</div>
              <div id="translations-file-dropzone">
                <Dropzone
                  fileName={requestPayload.translationsFile ? requestPayload.translationsFile.name : ""}
                  inputName="translationsFile"
                  onDrop={(file: any) => {
                    if (!file[0]) {
                      setErrors({
                        ...errors,
                        jsonFile: t("pages.languageTranslations.importModal.errorFile"),
                      });
                    } else {
                      setErrors({
                        ...errors,
                        jsonFile: "",
                      });
                      setFieldValue("translationsFile", file);
                      onDrop(file);
                    }
                  }}
                  accept={[".json", ".xls", ".xlsx"]}
                  multiple={false}
                  dropTitle={t("pages.languageTranslations.importModal.dropzoneTitle")}
                  dropDescription={t("pages.serviceBuilder.modals.whitelist.dropzone.dropDescription")}
                  releaseText={t("pages.serviceBuilder.modals.whitelist.dropzone.release")}
                />
              </div>
            </ImportTranslationForm>
          )}
        />
      </div>
      <ButtonsContainer>
        <div>
          <PrimaryButton
            redTheme={false}
            id={"cancel-button"}
            titleLabel={t("pages.languageTranslations.importModal.cancel")}
            onClick={hideModal}
            type="button"
          />
        </div>
        <div>
          <PrimaryButton
            redTheme={true}
            id={"import-button"}
            titleLabel={t("pages.languageTranslations.importModal.import")}
            type="submit"
            onClick={submitRequest}
            disabled={!requestPayload.translationsFile}
          />
        </div>
      </ButtonsContainer>
    </ModalContainer>
  );
};

export default ExportLanguageTranslationsModal;

const ModalContainer = styled("div")`
  border-radius: 6px;
  box-shadow: 0px 4px 4px rgba(142, 142, 142, 0.23), 0px 3px 10px rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.palette.white};
  padding: 24px;
`;

const ModalTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 13px;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
  > div {
    :first-of-type {
      margin-right: 12px;
    }
  }
`;

const ImportTranslationForm = styled("form")`
  display: flex;
  padding-top: 10px;
  width: 100%;
  flex-direction: column;
  margin-top: 10px;
`;

const ImportTranslationError = styled("div")`
  width: fit-content;
  display: inline-flex;
  height: 19px;
  color: #ff0000;
  font-family: Vodafone Rg;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  float: right;
  font-weight: 400;
`;
