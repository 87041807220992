import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { EFormDataStatus } from "../feedback-and-problems.model";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { ArrowDownIcon } from "@wit/mpesa-ui-components";
import {
  problemsStringPath,
  feedbacksStringPath,
  isFeedbackTabActive,
  isProblemReportsTabActive,
} from "../feedback-and-problems.utils";
import { IResultsTableDrawerComponentProps, IStatusNodeObject } from "../feedback-and-problems.model";

const ResultsTableDrawerComponent = ({ sectionId, drawerBodyData, closeDrawer }: IResultsTableDrawerComponentProps) => {
  const [t] = useTranslation();
  const status =
    (isFeedbackTabActive(sectionId) || isProblemReportsTabActive(sectionId)) &&
    (drawerBodyData?.status as IStatusNodeObject).props.currentStatus;

  return (
    <ComponentContainer>
      <HeaderSection>
        <Icon onClick={closeDrawer} id={"close-drawer"}>
          <ArrowDownIcon />
        </Icon>
        <Title>
          {isFeedbackTabActive(sectionId)
            ? `${t(feedbacksStringPath + ".detailsArea.title")} ${!!drawerBodyData ? drawerBodyData.feedbackId : ""}`
            : `${t(problemsStringPath + ".detailsArea.title")} ${!!drawerBodyData ? drawerBodyData.feedbackId : ""}`}
        </Title>
      </HeaderSection>
      <BodySection>
        <ColumnRow>
          <div className={"row-title"}>
            {isFeedbackTabActive(sectionId)
              ? t(`${feedbacksStringPath}.resultArea.userMsisdn`)
              : t(`${problemsStringPath}.resultArea.userMsisdn`)}
          </div>
          <div className={"row-description"}>{drawerBodyData?.msisdn}</div>
        </ColumnRow>

        <ColumnRow>
          <div className={"row-title"}>
            {isFeedbackTabActive(sectionId)
              ? t(`${feedbacksStringPath}.resultArea.category`)
              : t(`${problemsStringPath}.resultArea.category`)}
          </div>
          <div className={"row-description"}>{drawerBodyData?.category}</div>
        </ColumnRow>

        <ColumnRow>
          <div className={"row-title"}>
            {isFeedbackTabActive(sectionId)
              ? t(`${feedbacksStringPath}.resultArea.date`)
              : t(`${problemsStringPath}.resultArea.date`)}
          </div>
          <div className={"row-description"}>{drawerBodyData?.date}</div>
        </ColumnRow>

        <ColumnRow>
          <div className={"row-title"}>
            {isFeedbackTabActive(sectionId)
              ? t(`${feedbacksStringPath}.resultArea.feedback`)
              : t(`${problemsStringPath}.resultArea.problemReport`)}
          </div>
          <div className={"row-description"}>{drawerBodyData?.feedback}</div>
        </ColumnRow>

        <ColumnRow>
          <div className={"row-title"}>
            {isFeedbackTabActive(sectionId)
              ? t(`${feedbacksStringPath}.resultArea.status`)
              : t(`${problemsStringPath}.resultArea.status`)}
          </div>
          <div className={"row-description"}>
            <span
              style={
                ((status as unknown) as EFormDataStatus) === EFormDataStatus.RESOLVED
                  ? { color: styleTheme.palette.springGreen }
                  : ((status as unknown) as EFormDataStatus) === EFormDataStatus.IN_PROGRESS
                  ? { color: styleTheme.palette.freshOrange }
                  : { color: styleTheme.palette.red.normal }
              }
            >
              {isFeedbackTabActive(sectionId)
                ? t(`${feedbacksStringPath}.resultArea.statusEnums.${status}`)
                : t(`${problemsStringPath}.resultArea.statusEnums.${status}`)}
            </span>
          </div>
        </ColumnRow>
      </BodySection>
    </ComponentContainer>
  );
};

export default ResultsTableDrawerComponent;

export const ComponentContainer = styled("div")`
  font-family: Vodafone Rg;
  width: 100%;
  padding: 0;
  margin: 0;
`;

export const HeaderSection = styled("div")`
  width: 100%;
  height: auto;
  padding: 65px 48.71px 35px 48.71px;
  display: flex;
  align-items: center;
`;

export const Icon = styled("div")`
  cursor: pointer;
  stroke: ${styleTheme.palette.red.normal};
  margin-right: 8px;
  width: 20px;
  height: 20px;
  svg {
    transform: rotate(90deg);
  }
`;

export const Title = styled("div")`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: ${styleTheme.palette.anthracite};
`;

export const BodySection = styled("div")`
  width: 100%;
  height: auto;
  padding: 0 43px 35px 76px;
  display: flex;
  flex-direction: column;
`;

export const ColumnRow = styled("div")<{ direction?: any }>`
  width: 100%;
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : "row")};
  text-align: left;
  font-size: 16px;
  line-height: 18px;
  border-bottom: 1px solid ${styleTheme.palette.greyLightest};

  div.row-title {
    width: 197px;
    height: 18px;
    display: flex;
    align-items: center;
    color: ${styleTheme.palette.greyDarker};
    font-weight: 700;
    margin: 22px 0 0 0;
  }

  div.row-description {
    width: 233px;
    min-height: 18px;
    display: flex;
    align-items: center;
    color: ${styleTheme.palette.black};
    overflow-wrap: break-word;
    hyphens: auto;
    font-weight: 400;
    margin: 16px 3px 18px 0;
  }
`;
