import { AxiosPromise } from "axios";
import ApiClient from "../../../configs/axios.config";
import SBApiClient from "../../../configs/axios.service-builder.config";
import { IIconAllowedScope, IAllowedScope } from "./allowed-scopes.model";

const AllowedScopesApi = {
  routes: {
    allowedScopes: "/allowed-scopes",
    icons: "/v1/ipg_services/icons",
  },
  methods: {
    getAllowedScopes: (): AxiosPromise<any> => {
      return SBApiClient.get(AllowedScopesApi.routes.allowedScopes);
    },
    getAllowedScope: (scopeId: string): AxiosPromise<any> => {
      return SBApiClient.get(`${AllowedScopesApi.routes.allowedScopes}/${scopeId}`);
    },
    editAllowedScope: (payload: IAllowedScope): AxiosPromise<IAllowedScope> => {
      return SBApiClient.put(AllowedScopesApi.routes.allowedScopes, payload);
    },
    getAllowedScopesIcons: (): AxiosPromise<IIconAllowedScope[]> => {
      return ApiClient.get(AllowedScopesApi.routes.icons);
    },
  },
};

export default AllowedScopesApi;
