import { PrimaryButton } from "@wit/mpesa-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Row } from "../../../../shared/shared.styled";

export interface IDocument {
  [lang: string]: string;
}
interface IPublishDocumentModalProps {
  hideModal: () => void;
  publishChanges: () => void;
  document: IDocument;
  lastDocument: { content: IDocument };
  selectedLanguage: string;
}

/**
 *
 * @param param0
 * Document modal
 */
const PublishDocumentModal = ({
  document,
  lastDocument,
  selectedLanguage,
  publishChanges,
  hideModal,
}: IPublishDocumentModalProps) => {
  const [t] = useTranslation();

  const [isLoading] = React.useState(false);

  /**
   * Publish request
   * */
  const publish = () => {
    publishChanges();
  };

  return (
    <ModalContainer>
      <ModalTitle>{t("pages.privacyPolicy.publish")}</ModalTitle>
      <ModalSubtitle>{t("pages.privacyPolicy.publishChangesModalTitle")}</ModalSubtitle>
      <DocumentContainer>
        <Row style={{ alignItems: "normal" }}>
          <Panel background={true}>
            <PanelTitle>{t("pages.privacyPolicy.previousVersion")}</PanelTitle>
            <div dangerouslySetInnerHTML={{ __html: lastDocument.content[selectedLanguage] }}></div>
          </Panel>
          <Panel>
            <PanelTitle>{t("pages.privacyPolicy.editedVersion")}</PanelTitle>
            <div dangerouslySetInnerHTML={{ __html: document[selectedLanguage] }}></div>
          </Panel>
        </Row>

        <Row
          style={{
            justifyContent: "flex-end",
            width: "100%",
            marginTop: "auto",
            paddingRight: "24px",
            overflow: "visible",
            paddingTop: "24px",
          }}
        >
          <div style={{ marginRight: "12px" }}>
            <PrimaryButton
              disabled={isLoading}
              onClick={hideModal}
              titleLabel={t("pages.privacyPolicy.cancel")}
              id="cancel-button"
            />
          </div>
          <div>
            <PrimaryButton
              redTheme={true}
              type="submit"
              onClick={publish}
              disabled={isLoading}
              titleLabel={t("pages.privacyPolicy.confirm")}
              id="confirm-button"
            />
          </div>
        </Row>
      </DocumentContainer>
    </ModalContainer>
  );
};

export default PublishDocumentModal;

const ModalContainer = styled("div")`
  border-radius: 6px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  padding-top: 24px;
  padding-bottom: 24px;
`;

const ModalTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 13px;
  padding-left: 24px;
`;

const ModalSubtitle = styled("p")`
  font-family: Vodafone Rg;
  font-size: 18px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 24px;
  padding-left: 24px;
`;

const DocumentContainer = styled("div")`
  font-family: Vodafone Rg;
  max-height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const Panel = styled("div")<{ background?: boolean }>`
  font-family: Vodafone Rg;
  overflow: auto;
  display: flex;
  width: 50%;
  padding: 24px;
  background-color: ${props => (props.background ? props.theme.palette.lightGrey : props.theme.palette.white)};
  flex-direction: column;
  max-height: 300px;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  b,
  strong {
    margin: 0;
  }
  table {
    border-collapse: collapse;
    text-align: center;
    font-size: 0.75rem;
    margin-bottom: 10px;
    width: 100%;
  }
  table thead {
    background-color: #f4f4f4;
    color: #000000;
    border-bottom: none;
  }
  table td,
  table th {
    border: 1px solid #ddd;
  }
`;

const PanelTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  margin-bottom: 12px;
`;
