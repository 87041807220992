import { IDynamicScreenCategory } from "../../features/configurations/dynamic-screens/dynamic-screens.model";

export enum ServiceStatusEnum {
  LIVE = "LIVE",
  BETA = "BETA",
  UNDEPLOYED = "UNDEPLOYED",
  LIVE_UNDEPLOYED = "LIVE_UNDEPLOYED",
  BETA_LIVE = "BETA_LIVE",
  BETA_UNDEPLOYED = "BETA_UNDEPLOYED",
  UNDEPLOYED_BETA = "UNDEPLOYED_BETA",
  UNDEPLOYED_LIVE = "UNDEPLOYED_LIVE",
  LIVE_DELETED = "LIVE_DELETED",
  BETA_DELETED = "BETA_DELETED",
  DISABLED = "DISABLED",
}

export enum ServiceStatusSearchEnum {
  LIVE = "LIVE",
  BETA = "BETA",
  UNDEPLOYED = "UNDEPLOYED",
}

export enum VersionLanguagesEnum {
  ENGLISH = "en",
  SWAHILI = "sw",
}

export interface IService {
  id: string;
  creationDate: string;
  name: string;
  highlighted: boolean;
  categories: IDynamicScreenCategory[];
  description: string;
  iconUrl: string;
  imageUrl: string;
  status: ServiceStatusEnum;
}

export interface IServiceDetails {
  id: string;
  creationDate: string;
  name: any;
  description: any;
  iconUrl: string;
  imageUrl: string;
  highlighted: boolean;
  categories: IDynamicScreenCategory[];
  status: ServiceStatusEnum;
  versions?: IServiceVersion[];
  urlWhiteList?: string[];
  submittedForApproval: boolean;
  canBeEdited: boolean;
  nameTranslations?: {
    [lang: string]: string;
  };
  descriptionTranslations?: {
    [lang: string]: string;
  };
  tags?: string[];
  tagsSuggestions?: string[];
}

export interface IServiceUpdateDetails {
  id?: string;
  creationDate?: string;
  name: any;
  description?: any;
  highlighted: boolean;
  categories: string[];
  urlWhiteList?: string[];
  iconService: File;
  imageService: File;
  tags: string[];
}

export interface IServiceAddDetails {
  name: any;
  description?: any;
  highlighted: boolean;
  categories: string[];
  iconService: File;
  imageService: File;
  tags: string[];
}

export interface IVersionHistory {
  status: string;
  date?: any;
  owner?: any;
}

export interface IServiceVersion {
  id?: string;
  landingPage?: string;
  parentAppMinVersion?: string;
  rootUrl?: string;
  serviceId?: string;
  status?: string;
  statusDate?: number;
  useRenderer?: boolean;
  version?: string;
  versionHistories?: IVersionHistory[];
  whiteList?: string[];
  zipPath?: string;
  releaseNotes?: string;
  fileLanguageMapping?: Object;
  canBeEdited?: boolean;
}

export interface IMSISDNValidationResults {
  validMsisdn: string[];
  invalidMsisdn: string[];
  msisdnSuggestion: string[];
}
