import { CancelIcon, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { LoadingText } from "../../../shared/shared.styled";
import AuditingApi from "../auditing.api";
import { IAuditingLogDetails } from "../auditing.model";
import AuditingDetailsBody from "./auditing-details/auditing-details-body.component";
import AuditingDetailsHeader from "./auditing-details/auditing-details-header.component";

interface IAuditingDetailsInterface {
  auditingLogId: string;
  hideDrawer: () => void;
}

const AuditingDetails = ({ auditingLogId, hideDrawer }: IAuditingDetailsInterface) => {
  const [t] = useTranslation();
  const [isLoading, setIsLoading] = React.useState(true);
  const [showAlert, , setAlertProps] = useAlert();
  const [auditingLogDetails, setAuditingLogDetails] = React.useState<IAuditingLogDetails>();

  React.useEffect(() => {
    getAuditingLogDetails();
  }, []);

  /**
   * Retrieves the log details data
   */
  const getAuditingLogDetails = () => {
    setIsLoading(true);
    AuditingApi.methods
      .getAuditingLogDetails(auditingLogId)
      .then(
        res => {
          setAuditingLogDetails(res.data);
        },
        () => {
          setAlertProps({
            title: t("pages.auditing.getAuditingError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <AuditingLogContainer>
        <TitleContainer>
          <CloseIconContainer onClick={hideDrawer}>
            <CancelIcon />
          </CloseIconContainer>
          <Title>
            {`${t("pages.auditing.details.auditId")} `} <span>{auditingLogId}</span>
          </Title>
        </TitleContainer>

        {isLoading ? (
          <MessageContainer>
            <LoadingText>{t("commons.loadingDetails")}</LoadingText>
          </MessageContainer>
        ) : (
          <>
            {!auditingLogDetails ? (
              <MessageContainer>
                <NoDetails>{t("pages.auditing.details.noAuditDetails")}</NoDetails>
              </MessageContainer>
            ) : (
              <>
                <AuditingDetailsHeader details={auditingLogDetails} />
                <AuditingDetailsBody details={auditingLogDetails} />
              </>
            )}
          </>
        )}
      </AuditingLogContainer>
      <AuditingLogOverly onClick={hideDrawer} />
    </>
  );
};

export default AuditingDetails;

const NoDetails = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.vodafoneRed};
  font-weight: bold;
`;

const MessageContainer = styled("div")`
  display: flex;
  padding: 0px 37px 40px 68px;
`;

const TitleContainer = styled("div")`
  display: flex;
  padding: 50px 37px 50px 23px;
  align-items: center;
`;

const AuditingLogOverly = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
`;

const AuditingLogContainer = styled("div")`
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  height: 100%;
  overflow: auto;
`;

const CloseIconContainer = styled("div")`
  stroke: ${props => props.theme.palette.midGrey};
  transform: rotate(90deg);
  cursor: pointer;
  svg {
    width: 24px;
  }
`;

const Title = styled("span")`
  font-family: Vodafone Lt;
  font-size: 24px;
  color: ${props => props.theme.palette.darkGrey};
  margin-left: 18px;
  > span {
    color: ${props => props.theme.palette.midGrey};
  }
`;
