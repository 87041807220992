import React from "react";

function CheckIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1099 -226)">
          <path fill="#FFF" d="M0 0H1360V768H0z"></path>
          <g transform="translate(118 215)">
            <path d="M0 0H1160V44H0z"></path>
            <g stroke="#090" strokeLinecap="round" strokeLinejoin="round" transform="translate(981 11)">
              <path d="M20 6.5L8.92296845 17.5 4 12.6112667"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CheckIcon;
