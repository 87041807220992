import { useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikHelpers } from "formik";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import CampaignDetailsForm from "../../components/campaign-details-form.component";
import ReferralCampaignsApi from "../../referral-campaigns.api";
import { IReferralCampaign, ReferralCampaignStatus } from "../../referral-campaigns.model";
import QuickActionsMenu from "./quick-actions-menu.component";
import { validateSchemaCampaignDetails, validateTranslationsCampaignDetails } from "../../referral-campaigns.utils";
import { useTranslation } from "react-i18next";
import GenericLanguageSelector from "../../../../../shared/components/generic-language-selector.component";
import { ILanguage } from "../../../../../shared/models/language.model";
import useEmptyLanguageWarningModal from "../../../../../shared/hooks/use-empty-language-warning-modal.hook";

interface IEditCampaignDetailsProps {
  languages: ILanguage[];
  selectedLanguage: string;
  setSelectedLanguage: React.Dispatch<React.SetStateAction<string>>;
  referralCampaign: IReferralCampaign;
  onSubmitFn: (values: IReferralCampaign) => void;
  onCancelCampaignFn: () => void;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * EditCampaignDetails component
 */
const EditCampaignDetails = ({
  languages,
  selectedLanguage,
  setSelectedLanguage,
  referralCampaign,
  onSubmitFn,
  onCancelCampaignFn,
  isEditing,
  setIsEditing,
}: IEditCampaignDetailsProps) => {
  const [t] = useTranslation();
  const [showAlert, , setAlertProps] = useAlert();

  const { warningModalOpen, showWarning, setShowWarning } = useEmptyLanguageWarningModal(languages || []);
  /**
   * Submit method wrapper to show warning if needed
   * @param values
   */
  const submitWrapper = (values: IReferralCampaign) => {
    if (showWarning) {
      setShowWarning(false);
      warningModalOpen(handleSubmitCampaignDetail, values);
    } else {
      handleSubmitCampaignDetail(values);
    }
  };

  /**
   * Performs the create request
   * @param {ICampaignDetails} values
   * @param {FormikHelpers<ICampaignDetails>} actions
   */
  const handleSubmitCampaignDetail = (values: IReferralCampaign) => {
    // Verify if the date interval is available or not - only for non default campaigns
    if (!values.defaultCampaign) {
      ReferralCampaignsApi.methods
        .areDatesAvailable({
          startDate: moment(values.startDate, "DD/MM/YYYY").valueOf(),
          endDate: moment(values.endDate, "DD/MM/YYYY").valueOf(),
          id: values.id,
        })
        .then(
          res => {
            if (res.data) {
              onSubmitFn(values);
              setIsEditing(false);
            } else {
              setAlertProps({
                title: t("pages.referralCampaigns.createCampaign.invalidDates"),
                type: AlertTypeEnum.ERROR,
              });
              showAlert();
            }
          },
          () => {
            setAlertProps({
              title: t("pages.referralCampaigns.createCampaign.invalidDates"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          },
        );
    } else {
      onSubmitFn(values);
      setIsEditing(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={referralCampaign}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={submitWrapper}
      validate={values => validateSchemaCampaignDetails(values, languages)}
      render={({
        values,
        handleChange,
        handleBlur,
        isSubmitting,
        handleSubmit,
        setFieldValue,
        errors,
        resetForm,
        setErrors,
      }) => (
        <form onSubmit={handleSubmit}>
          <CampaignDetailsContainer>
            <FormContainer>
              <CampaignDetailsForm
                title={
                  <div style={{ marginTop: "auto", marginLeft: "auto" }}>
                    <GenericLanguageSelector
                      selectedLanguage={selectedLanguage}
                      availableLanguages={languages.map(lang => lang.code)}
                      changeSelectedLanguage={(lang: any) => setSelectedLanguage(lang.key)}
                    ></GenericLanguageSelector>
                  </div>
                }
                isEditing={isEditing}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                language={selectedLanguage}
                setErrors={setErrors}
                campaignStatus={referralCampaign.status}
                isLanguageRequired={languages.find(l => l.code === selectedLanguage)?.mandatory}
              />
            </FormContainer>
            {referralCampaign.status && referralCampaign.status !== ReferralCampaignStatus.DISABLED ? (
              <QuickActionsMenu
                canEdit={true}
                isEditing={isEditing}
                doneFn={() => {
                  handleSubmit();
                  validateTranslationsCampaignDetails(values, languages, setSelectedLanguage, setShowWarning);
                }}
                cancelFn={() => {
                  resetForm();
                  setIsEditing(false);
                }}
                canCancel={referralCampaign.status !== ReferralCampaignStatus.SCHEDULED}
                editCampaignFn={() => setIsEditing(true)}
                cancelCampaignFn={onCancelCampaignFn}
              />
            ) : (
              <EmptySpace />
            )}
          </CampaignDetailsContainer>
        </form>
      )}
    />
  );
};

export default EditCampaignDetails;

const CampaignDetailsContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  > div > div:first-child {
    > div {
      justify-content: flex-end;
    }
  }
`;

const FormContainer = styled("div")`
  flex: 1;
`;

const EmptySpace = styled("div")`
  flex: 1;
`;
