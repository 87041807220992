import { IIcon } from "../../../../shared/components/icon-picker.component";

export interface ICategory {
  id: string;
  externalCategoryIds: string[];
  categoryName: string;
  categoryNameTranslations?: {
    [lang: string]: string;
  };
  color: string;
  iconId: string;
  iconUrl: string;
  isDefault: boolean;
  promotedPosition: number;
  services: ICategoryAssignedMiniApps[];
}

export interface ICategoryEditPayload {
  externalCategoryIds: string[];
  categoryName: string;
  color: string;
  iconId: string;
}

export class Category implements ICategory {
  id!: string;
  externalCategoryIds!: string[];
  categoryName!: string;
  color!: string;
  iconId!: string;
  iconUrl!: string;
  isDefault: boolean = false;
  promotedPosition!: number;
  services!: ICategoryAssignedMiniApps[];
}

export interface IIconCategory extends IIcon {
  format?: string;
  hash?: string;
  hashMatches?: boolean;
  imageType?: string;
}

export interface IOrderObject {
  fromPosition: number | undefined;
  toPosition: number | undefined;
}

interface IMiniAppsCategory {
  id: string;
  externalCategoryIds: string[];
  order: number;
  isDefault: boolean;
}
export interface ICategoryAssignedMiniApps {
  id: string;
  categories: IMiniAppsCategory[];
}
