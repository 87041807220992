import { AutoClosingDropdown, PrimaryButton, TextInput, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikHelpers, yupToFormErrors } from "formik";
import { FormikErrors } from "formik/dist/types";
import i18next from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { number, object } from "yup";
import { Column, RatioContainer } from "../../../../../shared/shared.styled";
import RateCardsApi from "../../rate-cards.api";
import { IChargeProfile, IRange, RateValueTypeEnum } from "../../rate-cards.model";

interface IAddChargeProfileTierModalProps {
  dismissModal: () => void;
  chargeProfile: IChargeProfile;
  reloadData: () => void;
  selectedTier: IRange | undefined;
}

/**
 * Add charge profile tier
 * @param param0
 */
const AddChargeProfileTierModal = ({
  dismissModal,
  chargeProfile,
  reloadData,
  selectedTier,
}: IAddChargeProfileTierModalProps) => {
  const [t] = useTranslation();
  const [formValues, setFormValues] = useState<IRange>();
  const [showAlert, , setAlertProps] = useAlert();

  React.useEffect(() => {
    if (selectedTier) {
      setFormValues({
        id: selectedTier.id,
        rangeBegin: selectedTier.rangeBegin,
        rangeEnd: selectedTier.rangeEnd,
        rateValue: selectedTier.rateValue,
        rateValueType: selectedTier.rateValueType,
      });
    } else {
      setFormValues({
        id: null,
        rangeBegin: 0,
        rangeEnd: 0,
        rateValue: 0,
        rateValueType: RateValueTypeEnum.NUMBER,
      });
    }
  }, [selectedTier]);

  /**
   * Form validation schema
   */

  const schema = chargeProfile.rangeValuesRequired
    ? object().shape({
        rangeBegin: number()
          .required(i18next.t("pages.rateCards.tierModal.requiredField"))
          .typeError(i18next.t("pages.rateCards.tierModal.positiveNumber"))
          .min(0, i18next.t("pages.rateCards.tierModal.positiveNumber"))
          .test("rangeEnd", i18next.t("pages.rateCards.tierModal.tierBeginGreater"), function(val) {
            return this.parent.rangeEnd > val;
          }),
        rangeEnd: number()
          .required(i18next.t("pages.rateCards.tierModal.requiredField"))
          .typeError(i18next.t("pages.rateCards.tierModal.positiveNumber"))
          .min(0, i18next.t("pages.rateCards.tierModal.positiveNumber"))
          .test("rangeBegin", i18next.t("pages.rateCards.tierModal.tierEndLower"), function(val) {
            return this.parent.rangeBegin < val;
          }),
        rateValue: number()
          .required(i18next.t("pages.rateCards.tierModal.requiredField"))
          .typeError(i18next.t("pages.rateCards.tierModal.positiveNumber"))

          .when("rateValueType", {
            is: val => val === RateValueTypeEnum.PERCENTAGE,
            then: number()
              .min(0, i18next.t("pages.rateCards.tierModal.percentageNumber"))
              .max(100, i18next.t("pages.rateCards.tierModal.percentageNumber"))
              .typeError(i18next.t("pages.rateCards.tierModal.positiveNumber")),
            otherwise: number()
              .min(0, i18next.t("pages.rateCards.tierModal.positiveNumber"))
              .typeError(i18next.t("pages.rateCards.tierModal.positiveNumber")),
          }),
      })
    : object().shape({
        rateValue: number()
          .required(i18next.t("pages.rateCards.tierModal.requiredField"))
          .typeError(i18next.t("pages.rateCards.tierModal.positiveNumber"))
          .when("rateValueType", {
            is: val => val === RateValueTypeEnum.PERCENTAGE,
            then: number()
              .min(0, i18next.t("pages.rateCards.tierModal.percentageNumber"))
              .max(100, i18next.t("pages.rateCards.tierModal.percentageNumber"))
              .typeError(i18next.t("pages.rateCards.tierModal.positiveNumber")),
            otherwise: number()
              .min(0, i18next.t("pages.rateCards.tierModal.positiveNumber"))
              .typeError(i18next.t("pages.rateCards.tierModal.positiveNumber")),
          }),
      });

  /**
   * Request to add/edit a tier
   * @param values
   * @param actions
   */
  const addChargeProfile = (values: IRange, actions: FormikHelpers<IRange>) => {
    actions.setSubmitting(true);
    const index = selectedTier
      ? chargeProfile.ranges.findIndex(r => selectedTier.id === r.id)
      : chargeProfile.ranges.length;
    const tempChargeProfile = { ...chargeProfile };
    tempChargeProfile.ranges = [...chargeProfile.ranges];
    tempChargeProfile.ranges[index] = values;
    RateCardsApi.methods
      .updateChargeProfileTiers(tempChargeProfile.id, tempChargeProfile)
      .then(
        () => {
          if (selectedTier) {
            setAlertProps({
              type: AlertTypeEnum.SUCCESS,
              title: t("pages.rateCards.tierModal.editTierSuccess"),
            });
          } else {
            setAlertProps({
              type: AlertTypeEnum.SUCCESS,
              title: t("pages.rateCards.tierModal.addNewTierSuccess"),
            });
          }
          showAlert();
          reloadData();
          dismissModal();
        },
        err => {
          if (selectedTier) {
            setAlertProps({
              type: AlertTypeEnum.ERROR,
              title:
                err && err.data && err.data.status && err.data.status.message
                  ? err.data.status.message
                  : t("pages.rateCards.tierModal.editTierError"),
            });
          } else {
            setAlertProps({
              type: AlertTypeEnum.ERROR,
              title:
                err && err.data && err.data.status && err.data.status.message
                  ? err.data.status.message
                  : t("pages.rateCards.tierModal.addNewTierError"),
            });
          }
          showAlert();
        },
      )
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  /**
   * Returns the list of rate value type options
   */
  const getRateValueTypeOptions = () => {
    return Object.keys(RateValueTypeEnum).map(value => {
      return {
        key: value,
        label: t(`pages.rateCards.rateValueTypes.${value}`),
      };
    });
  };

  /**
   * Retrieves the jsx for the main form
   */
  const getMainFormInputs = (
    values: IRange,
    handleChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined,
    setFieldValue: any,
    errors: any,
  ) => {
    return (
      <>
        {chargeProfile.rangeValuesRequired ? (
          <FormContainer>
            <RatioContainer ratio={6 / 12} style={{ marginRight: "12px" }}>
              <TextInput
                name="rangeBegin"
                title={t("pages.rateCards.tierModal.tierBegin")}
                placeholder={t("pages.rateCards.tierModal.tierBeginPlaceholder")}
                onChange={handleChange}
                value={values.rangeBegin}
                error={errors.rangeBegin}
                type={"number"}
                step="any"
                min={0}
                required
              />
            </RatioContainer>

            <RatioContainer ratio={6 / 12}>
              <TextInput
                name="rangeEnd"
                title={t("pages.rateCards.tierModal.tierEnd")}
                placeholder={t("pages.rateCards.tierModal.tierEndPlaceholder")}
                onChange={handleChange}
                value={values.rangeEnd}
                error={errors.rangeEnd}
                type={"number"}
                step="any"
                min={0}
                required
              />
            </RatioContainer>
          </FormContainer>
        ) : null}

        <FormContainer>
          <RatioContainer ratio={6 / 12} style={{ marginRight: "12px" }}>
            <Column>
              <LabelRequired>{t("pages.rateCards.tierModal.rateValueType")}</LabelRequired>
              <DropdownContainer id={"currencies-dropdown"}>
                <AutoClosingDropdown
                  hasValue={true}
                  label={t(`pages.rateCards.rateValueTypes.${values.rateValueType}`)}
                  options={getRateValueTypeOptions()}
                  error={errors.rateValueType}
                  selectOption={opt => setFieldValue("rateValueType", opt.key)}
                />
              </DropdownContainer>
            </Column>
          </RatioContainer>
          <RatioContainer ratio={6 / 12}>
            <TextInput
              name="rateValue"
              title={t("pages.rateCards.tierModal.rateValue")}
              placeholder={t("pages.rateCards.tierModal.rateValuePlaceholder")}
              onChange={handleChange}
              value={values.rateValue}
              error={errors.rateValue}
              type={"number"}
              step="any"
              min={0}
              required
            />
          </RatioContainer>
        </FormContainer>
      </>
    );
  };

  /**
   * Fn to validate the form every stroke
   * @param values
   */
  const validateSchema = (values: IRange): Promise<FormikErrors<IRange>> => {
    return new Promise<FormikErrors<IRange>>(resolve => {
      schema
        .validate(values, {
          abortEarly: false,
        })
        .then(() => {
          resolve({});
        })
        .catch(y => {
          resolve(yupToFormErrors(y));
        });
    }).then(r => {
      return r;
    });
  };

  return (
    <ModalContainer>
      {selectedTier ? (
        <ModalTitle>{t("pages.rateCards.tierModal.editChargeProfileTier")}</ModalTitle>
      ) : (
        <ModalTitle>{t("pages.rateCards.tierModal.addNewTier")}</ModalTitle>
      )}
      {formValues ? (
        <Formik
          initialValues={formValues}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={addChargeProfile}
          validate={validateSchema}
          render={({ values, handleChange, isSubmitting, handleSubmit, setFieldValue, errors }) => (
            <form onSubmit={handleSubmit}>
              {getMainFormInputs(values, handleChange, setFieldValue, errors)}
              <ButtonsContainer>
                <div>
                  <PrimaryButton
                    id="cancel-button"
                    type="button"
                    disabled={isSubmitting}
                    onClick={dismissModal}
                    titleLabel={t("pages.rateCards.tierModal.cancel")}
                  />
                </div>
                <div>
                  <PrimaryButton
                    id="save-changes-button"
                    redTheme={true}
                    type="submit"
                    disabled={isSubmitting}
                    titleLabel={t("pages.rateCards.tierModal.save")}
                  />
                </div>
              </ButtonsContainer>
            </form>
          )}
        />
      ) : null}
    </ModalContainer>
  );
};

export default AddChargeProfileTierModal;

const ModalContainer = styled("div")`
  border-radius: 6px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  padding: 24px;
`;

const ModalTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 13px;
`;

const FormContainer = styled("div")`
  padding-top: 24px;
  display: flex;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 50px;
  > div {
    width: fit-content;
    :first-of-type {
      margin-right: 12px;
    }
  }
`;

const DropdownContainer = styled("div")`
  button {
    border-radius: 6px;
    border: 1px solid #ebebeb;
  }
`;

const Label = styled("div")`
  font-family: "Vodafone Rg";
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
  margin-bottom: 8px;
  min-height: 21px;
`;

const LabelRequired = styled(Label)`
  ::after {
    content: " *";
    color: ${props => props.theme.palette.errorRed};
  }
`;
