import React from "react";

function UploadImagePlaceHolderIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="48" viewBox="0 0 49 48">
      <path
        fill="none"
        fillRule="evenodd"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.421 5h28a5 5 0 015 5v28a5 5 0 01-5 5h-28a5 5 0 01-5-5V10a5 5 0 015-5zm-5 29.06L15.48 17.295l12.295 12.292 4.47-4.47L43.42 36.293m-9-19.167a3.125 3.125 0 10-.001-6.251 3.125 3.125 0 00.001 6.251z"
        opacity="0.204"
      ></path>
    </svg>
  );
}

export default UploadImagePlaceHolderIcon;
