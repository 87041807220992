import { UserRolesVDF, UserRolesSFC, UserRolesDefault } from "../features/admin/users/users.interfaces";

export const RoutesEnum = {
  ADD_ANNOUNCEMENT: "/announcements/add-announcement",
  ADD_BUNDLE: "/configs/bundles/add",
  ADD_CATEGORY: "/configs/categories/add",
  ADD_DISCOVER_CARD: "/configs/discovercards/add",
  ADD_DOCUMENT: "/configs/documents/:tabId/add",
  ADD_NEW_EXTERNAL_SERVICE: "/service-manager/add-external-service",
  ADD_PUSH_NOTIFICATION: "/tools/pushnotifications/add",
  ADD_USER: "/admin/add-user",
  ADMIN: "/admin",
  ANNOUNCEMENTS: "/announcements",
  ANNOUNCEMENTS_DETAILS: "/announcements/detail-announcement/:announcementID",
  ANT_SERVICE_MANAGER: "/ant-service-manager",
  ANT_SERVICE_MANAGER_DETAILS: "/ant-service-manager/:serviceId/details",
  ANT_SERVICE_MANAGER_ADD_SERVICE: "/ant-service-manager/add",
  ANT_SERVICE_MANAGER_ADD_CATEGORY: "/ant-service-manager/categories/add",
  ANT_SERVICE_MANAGER_CATEGORY_DETAILS: "/ant-service-manager/categories/:categoryId",
  ANT_EXTERNAL_SERVICES_DETAILS: "/ant-service-manager/:externalServiceID",
  ANT_SERVICE_MANAGER_SSO_DETAILS: "/ant-service-manager/sso/:allowedScopeId",
  ADD_NEW_ANT_EXTERNAL_SERVICE: "/ant-service-manager/add-external-service",
  APP_DETAILS: "/apps/:id",
  APP_PROPERTIES: "/configs/appproperties",
  APPS: "/apps",
  AUDITING: "/auditing",
  BANKS: "/configs/banks",
  BUNDLE_DETAILS: "/configs/bundles/bundledetails/:id",
  BUNDLES: "/configs/bundles",
  BUSINESS: "/configs/business",
  CATEGORIES: "/configs/categories",
  CATEGORY_DETAILS: "/configs/categories/details/:categoryId",
  CHARGE_PROFILE: "/configs/ratecards/chargeprofile/:id",
  CHARGE_PROFILE_CREATE: "/configs/ratecards/newchargeprofile",
  CHARGE_PROFILE_EDIT: "/configs/ratecards/chargeprofile/:id/edit",
  CONFIGS: "/configs",
  CREATE_REFERRAL_CAMPAIGN: "/configs/referralcampaigns/add",
  DISCOVER_CARDS: "/configs/discovercards",
  DOC_BULK_DETAILS: "/doc-bulk/details/:bulkName",
  DOC_BULK_LIST: "/doc-bulk",
  DOC_DASHBOARD: "/doc-dashboard",
  DOC_VALIDATION: "/doc-validation",
  DOC_VALIDATION_DETAILS: "/doc-validation/submission-details",
  DOCUMENTS: "/configs/documents",
  DOCUMENT: "/configs/documents/:tabId/:document/:id",
  DYNAMIC_SCREEN: "/configs/dynamicscreens/:id",
  DYNAMIC_SCREEN_CREATE: "/configs/dynamicscreen/add",
  DYNAMIC_SCREENS: "/configs/dynamicscreens",
  EDIT_BUNDLE: "/configs/bundles/edit/:id",
  EDIT_DISCOVER_CARD: "/configs/discovercards/edit/:id",
  EDIT_DOCUMENT: "/configs/documents/:tabId/edit/:type",
  EDIT_PUSH_NOTIFICATION: "/tools/pushnotifications/edit/:id",
  EDIT_REFERRAL_CAMPAIGN: "/configs/referralcampaigns/:id",
  EXPIRED_PASSWORD: "/expired-password",
  EXTERNAL_SERVICES_DETAILS: "/service-manager/:externalServiceID",
  FORGOT_PASSWORD: "/forgot-password",
  FEEDBACK_AND_PROBLEMS_REPORT: "/configs/feedback-and-problem-reports",
  IDENTITY_CONVERTER: "/tools/identityconverter",
  LANGUAGE_TRANSLATIONS: "/configs/languagetranslations",
  LANGUAGE_TRANSLATIONS_VERSION: "/configs/languagetranslations/:version",
  LOGIN: "/",
  NETWORKS: "/configs/networks",
  PUSH_NOTIFICATIONS: "/tools/pushnotifications",
  RATE_CARDS: "/configs/ratecards",
  RECOVERY: "/pwreset/:pwRegId",
  REFERRAL_CAMPAIGNS: "/configs/referralcampaigns",
  REGISTER: "/registeruser/:registerId",
  REQUEST_MONEY: "/tools/requestmoney",
  REQUEST_MONEY_REPORT: "/tools/requestmoney/report/:id",
  REQUEST_PAYMENT: "/tools/requestpayment",
  SERVICE_ADD: "/service-builder/add-service",
  SERVICE_BUILDER: "/service-builder",
  SERVICE_DETAILS: "/service-builder/:serviceId/details",
  SERVICE_DETAILS_EDIT: "/service-builder/:serviceId/details/edit/",
  SERVICE_EDITOR: "/service-builder/service-editor/:versionId/:versionNumber/:serviceId",
  SERVICE_MANAGER: "/service-manager",
  SERVICE_MANAGER_ADD_CATEGORY: "/service-manager/categories/add",
  SERVICE_MANAGER_ADD_SSO: "/service-manager/sso/add",
  SERVICE_MANAGER_CATEGORY_DETAILS: "/service-manager/categories/:categoryId",
  SERVICE_MANAGER_SERVICE_LIST_DETAILS: "/service-manager/:serviceId/details",
  SERVICE_MANAGER_SSO_DETAILS: "/service-manager/sso/allowedScopeId",
  SHORTCUTS: "/configs/shortcuts",
  SUPPORT: "/support",
  SURVEYS: "/tools/surveys",
  TASK_DETAILS: "/admin/task-details/:taskId",
  TOOLS: "/tools",
  USER_PROFILE: "/admin/user",
  VERSION: "/version",
  WALLETS: "/configs/wallets",
  SERVICE_MANAGER_ADD_SERVICE: "/service-manager/service/add",
  TOPICS: "/topics",
  ADD_TOPICS: "/topics/add",
  TOPICS_DETAILS: "/topics/detail/:topicId",
  SFC_PUSH_NOTIFICATIONS: "/push-notifications",
  SFC_ADD_NOTIFICATION: "/push-notifications/add",
  SFC_EDIT_NOTIFICATION: "/push-notifications/details/:notificationId",
};

export const RoutesPrivileges = {
  all: [
    UserRolesDefault.ADMIN,
    UserRolesDefault.DEVELOPER,
    UserRolesDefault.EDITOR,
    UserRolesVDF.ADMIN_SERVICE_MANAGEMENT,
    UserRolesVDF.EDITOR_SERVICE_MANAGEMENT,
    UserRolesDefault.SUPPORT,
    UserRolesDefault.APPROVER,
    UserRolesVDF.APPROVER_SERVICE_MANAGEMENT,
  ],
  apps: [
    UserRolesDefault.ADMIN,
    UserRolesDefault.DEVELOPER,
    UserRolesDefault.EDITOR,
    UserRolesVDF.ADMIN_SERVICE_MANAGEMENT,
    UserRolesVDF.EDITOR_SERVICE_MANAGEMENT,
    UserRolesDefault.APPROVER,
    UserRolesVDF.APPROVER_SERVICE_MANAGEMENT,
  ],
  sbList: [
    UserRolesVDF.ADMIN_SERVICE_MANAGEMENT,
    UserRolesVDF.EDITOR_SERVICE_MANAGEMENT,
    UserRolesVDF.APPROVER_SERVICE_MANAGEMENT,
  ],
  sbCrud: [
    UserRolesVDF.ADMIN_SERVICE_MANAGEMENT,
    UserRolesVDF.EDITOR_SERVICE_MANAGEMENT,
    UserRolesVDF.APPROVER_SERVICE_MANAGEMENT,
  ],
  admin: [
    UserRolesDefault.ADMIN,
    UserRolesDefault.DEVELOPER,
    UserRolesVDF.ADMIN_SERVICE_MANAGEMENT,
    UserRolesDefault.APPROVER,
    UserRolesVDF.APPROVER_SERVICE_MANAGEMENT,
  ],
  configs: [
    UserRolesDefault.ADMIN,
    UserRolesDefault.DEVELOPER,
    UserRolesDefault.EDITOR,
    UserRolesVDF.ADMIN_SERVICE_MANAGEMENT,
    UserRolesVDF.EDITOR_SERVICE_MANAGEMENT,
    UserRolesDefault.APPROVER,
    UserRolesVDF.APPROVER_SERVICE_MANAGEMENT,
  ],
  business: [UserRolesDefault.ADMIN, UserRolesDefault.DEVELOPER, UserRolesDefault.APPROVER, UserRolesDefault.SUPPORT],
  tools: [
    UserRolesDefault.ADMIN,
    UserRolesDefault.DEVELOPER,
    UserRolesDefault.EDITOR,
    UserRolesVDF.ADMIN_SERVICE_MANAGEMENT,
    UserRolesVDF.EDITOR_SERVICE_MANAGEMENT,
    UserRolesDefault.APPROVER,
    UserRolesVDF.APPROVER_SERVICE_MANAGEMENT,
    UserRolesSFC.REQUEST_MONEY_MANAGER,
  ],
  ekyc: [UserRolesDefault.ADMIN, UserRolesDefault.SUPPORT],
  tasks: [UserRolesDefault.APPROVER, UserRolesVDF.APPROVER_SERVICE_MANAGEMENT],
  serviceManager: [UserRolesDefault.ADMIN, UserRolesDefault.DEVELOPER, UserRolesSFC.SERVICES_MANAGER_MANAGER],
  announcements: [UserRolesDefault.ADMIN, UserRolesDefault.DEVELOPER, UserRolesSFC.ANNOUNCEMENTS_MANAGER],
  topics: [UserRolesDefault.ADMIN, UserRolesDefault.DEVELOPER, UserRolesSFC.ANNOUNCEMENTS_MANAGER],
  sfcNotifications:
    process.env.REACT_APP_ENVIRONMENT !== "prod"
      ? [UserRolesDefault.ADMIN, UserRolesDefault.DEVELOPER, UserRolesSFC.ANNOUNCEMENTS_MANAGER]
      : [UserRolesDefault.ADMIN, UserRolesSFC.ANNOUNCEMENTS_MANAGER],
};
