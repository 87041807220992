import React from "react";

const StorageServiceIcon = (props: React.HTMLProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 16.492H4a1.5 1.5 0 0 1-1.5-1.5v-8a1.5 1.5 0 0 1 1.5-1.5h16a1.5 1.5 0 0 1 1.5 1.5h0v10a1.5 1.5 0 0 1-1.5 1.5h-8.625l-1.375-2M7.5 13.5v-5l-2 1.5m6.499-1.5v5m1.626-5h-3.25m5.125 5v-5s2.5-.625 2.5 1c0 0 .125 1.5-2 1.375 0 0 2.25 0 2.375 1.5 0 0 .125 1.5-2.875 1.125z"
        stroke="#FD4169"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StorageServiceIcon;
