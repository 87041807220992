import React, { useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Separator, OrbRow } from "../../../shared/shared.styled";
import { StatusChip } from "@wit/mpesa-ui-components";
import { ServiceItemTitle } from "../service-manager.styled";
import { RoutesEnum } from "../../../routes/routes.constants";
import { IServiceManagerService, ServiceManagerStatusEnum } from "../../../shared/models/service-manager.model";
import { useHistory } from "react-router-dom";
import { ServiceGroupEnum } from "../section-manager/section-manager.interface";
import ServiceAreaIcon from "../../../shared/icons/service-area.icon";
import DisplayIconWithTooltip from "../../../shared/components/display-icon-with-tooltip-component";
import { BackOfficeMarketsEnums } from "../../../shared/shared.enums";
import { ConfigContext } from "../../../app.component";

interface IServiceListItemProps {
  item: IServiceManagerService;
}

/**
 * Component Item for ListItem
 * @param param0 iterface
 */
const ServiceManagerListItem = ({ item }: IServiceListItemProps) => {
  const [t] = useTranslation();
  const history = useHistory();
  const { config } = useContext(ConfigContext);

  if (item.status === ServiceManagerStatusEnum.UNDEPLOYED) {
    item = { ...item, status: ServiceManagerStatusEnum.DISABLED };
  }

  /**
   * This returns service area icon if the service belong to service area
   * @returns
   */
  const showServiceAreaIcon = () => {
    const result = item.groups.find(group => ServiceGroupEnum.SERVICES_AREA === group.name && group.order);
    if (result) {
      return (
        <DisplayIconWithTooltip
          toolTipText={"pages.dynamicScreens.serviceShownOnServicesArea"}
          tooltipId={"tooltip-services-area"}
          tooltipProps={{ place: "bottom" }}
        >
          <ServiceAreaIcon></ServiceAreaIcon>
        </DisplayIconWithTooltip>
      );
    }

    return null;
  };
  return (
    <ServiceGridItemContainer
      onClick={() => {
        const route = RoutesEnum.SERVICE_MANAGER_SERVICE_LIST_DETAILS;
        history.push(route.replace(":serviceId", item.id));
      }}
      id={item.name}
    >
      <HorizontalPaddedContainer>
        <ServiceItemImage backgroundImage={item.icon}></ServiceItemImage>
      </HorizontalPaddedContainer>
      <HorizontalPaddedContainer>
        <ServiceItemTitle ratio={1}>{item.name}</ServiceItemTitle>
        {item.description ? (
          <InfoContainer>
            <Identifier>{t("pages.serviceBuilder.item.description")}</Identifier>
            <Value>{item.description}</Value>
          </InfoContainer>
        ) : (
          <InfoContainer></InfoContainer>
        )}
      </HorizontalPaddedContainer>
      <Separator />
      <HorizontalPaddedContainer style={{ padding: "24px 24px 0 24px" }}>
        <OrbRow>
          <ChipContainer>
            {item.status !== undefined ? (
              <StatusChip type={item.status as any}>
                {t(`commons.serviceBuilder.enums.status.${item.status}`)}
              </StatusChip>
            ) : null}
            {showServiceAreaIcon()}
          </ChipContainer>
        </OrbRow>
      </HorizontalPaddedContainer>
    </ServiceGridItemContainer>
  );
};

export default ServiceManagerListItem;

const ServiceItemImage = styled("div")<{ backgroundImage: string }>`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: solid 1px #ebebeb;
  margin-bottom: 10px;
  background-image: url("data:image/jpeg;base64,${props => props.backgroundImage}");
`;
const HorizontalPaddedContainer = styled("div")`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
`;

const ServiceGridItemContainer = styled("div")`
  max-height: 300px;
  max-width: 415px;
  height: fit-content;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  :hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 6px 6px 0 rgba(0, 0, 0, 0.16);
  }
`;

const InfoContainer = styled("span")`
  margin-top: 8px;
  margin-bottom: 8px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  hyphens: auto;
  min-height: 36px;
  text-align: center;
  :last-of-type {
    margin-bottom: 24px;
  }
`;

const Identifier = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.black};
  margin-right: 3px;
`;

const Value = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.greyDarker};
`;

const ChipContainer = styled("div")`
  margin-left: 0px;
  display: flex;
`;
