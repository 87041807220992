import { FormSection } from "@wit/mpesa-ui-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import MultipleOptionNestedDropdown from "@wit/mpesa-ui-components/lib/components/dropdown/multiple-option-nested-dropdown/multiple-option-nested-dropdown.component";
import { SharedNestedOptionDropdown } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-nested-option-container/shared-dropdown-nested-option-container.component";
import { SharedDropdownOption } from "@wit/mpesa-ui-components/lib/components/dropdown/shared-dropdown-option-container/shared-dropdown-option-container.component";
import { FormSectionRow } from "@wit/mpesa-ui-components/lib/components/form-section/form-section.component";
import { SortDirectionEnum } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import { Formik, FormikErrors } from "formik";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ConfigContext } from "../../../app.component";
import useCheckFeatureAvailable from "../../../shared/hooks/use-check-available-feature";
import { FEATURES } from "../../../shared/renderer.utils";
import { BackOfficeMarketsEnums, FieldTypeEnum } from "../../../shared/shared.enums";
import { LoadingText } from "../../../shared/shared.styled";
import { compareVersions, sortArrayBy } from "../../../shared/shared.utils";
import AnnouncementsApi from "../announcements.api";
import {
  AnnouncementFormSteps,
  AppVersionServiceType,
  AudienceType,
  ConsumerAudience,
  DeviceType,
  IAnnouncement,
  IVersion,
} from "../announcements.model";
import { HomeMainContent, InformativeText } from "../announcements.styled";
import { AnnouncementsUtils } from "../announcements.utils";

interface IAnnouncementsStepAppVersion {
  announcement: IAnnouncement;
  setAnnoucement: React.Dispatch<React.SetStateAction<IAnnouncement>>;
  isEditing: boolean;
  onSubmitForm: () => void;
  onReady: React.Dispatch<React.SetStateAction<boolean>>;
  hideTitle?: boolean;
  setEditingAppVersion?: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * component for the second step of the announcment
 */
const AnnouncementsStepAppVersion = ({
  announcement,
  setAnnoucement,
  isEditing,
  onSubmitForm,
  onReady,
  setEditingAppVersion,
}: IAnnouncementsStepAppVersion) => {
  const [t] = useTranslation();
  const [appVersionsAndroid, setAppVersionsAndroid] = React.useState<IVersion[]>([]);
  const [appVersionsiOS, setAppVersionsiOS] = React.useState<IVersion[]>([]);
  const [selectedVersionsAndroid, setSelectedVersionsAndroid] = React.useState<string[]>(
    announcement.targetVersions ? AnnouncementsUtils.getSelectedVersions(announcement, DeviceType.ANDROID) : [],
  );
  const [selectedVersionsiOS, setSelectedVersionsiOS] = React.useState<string[]>(
    announcement.targetVersions ? AnnouncementsUtils.getSelectedVersions(announcement, DeviceType.IOS) : [],
  );
  const [isLoadingVersions, setIsLoadingVersions] = React.useState(true);
  const [initialValues, setInitialValues] = React.useState<IAnnouncement>({ ...announcement });

  const { config } = useContext(ConfigContext);
  const consumerChildEnabled = useCheckFeatureAvailable(FEATURES.CONSUMER_AUDIENCE_CHILD);

  /**
   * function that will be call for onSubmit form
   * @param values values of the announcemt
   */
  const onSubmitStep = (values: IAnnouncement) => {
    setInitialValues(values);
    setAnnoucement(values);
    onSubmitForm();
  };

  /**
   * function that will be call for onChange form
   * @param values values of announcement
   */
  const onChangeValues = (values: IAnnouncement) => {
    setAnnoucement(values);
    onReady(
      !!values.targetVersions &&
        (!!values.targetVersions.find(target => target.deviceType === "ANDROID") ||
          !!values.targetVersions.find(target => target.deviceType === "IOS")),
    );
  };

  /**
   * get dropdown options
   */
  const getOptionsDropdown = (values: IAnnouncement, versions: IVersion[]): SharedNestedOptionDropdown[] => {
    let options = [] as SharedNestedOptionDropdown[];

    if (versions.length > 0) {
      if (AnnouncementsUtils.isBusinessAudience(values.audience as AudienceType)) {
        options.push({
          label: "All versions",
          key: "all-versions-key",
        });
      }

      options = [
        ...options,
        ...Object.values(versions).map(version => ({
          label: version.appVersion,
          key: version.appVersion,
          children: version.appBuildVersions
            ? Object.values(version.appBuildVersions).map(subVersion => ({ label: subVersion, key: subVersion }))
            : [],
          disabled:
            ((values.audience === ConsumerAudience.CONSUMERAPP_ADULT ||
              values.audience === ConsumerAudience.CONSUMERAPP) &&
              !(selectedVersionsAndroid.length === 0 && selectedVersionsiOS.length === 0) &&
              config &&
              (!!(
                compareVersions(version.appVersion, config.appVersionDependencies.announcementsThumbnail.min) >= 0 &&
                isDeprecated()
              ) ||
                !!(
                  compareVersions(version.appVersion, config.appVersionDependencies.announcementsThumbnail.min) < 0 &&
                  !isDeprecated()
                ))) ||
            ((values.audience === ConsumerAudience.CONSUMERAPP_CHILD ||
              values.audience === ConsumerAudience.ALL_CONSUMERAPPS) &&
              config &&
              (!!(
                compareVersions(version.appVersion, config.appVersionDependencies.childAccount.min) >= 0 &&
                isDeprecated()
              ) ||
                !!(
                  compareVersions(version.appVersion, config.appVersionDependencies.childAccount.min) < 0 &&
                  !isDeprecated()
                ))),
        })),
      ];
    }
    return options;
  };

  /**
   * method to check if there are deprecated versions (<2.1) selected
   */
  const isDeprecated = () => {
    return (
      selectedVersionsAndroid.some(
        version => compareVersions(version, config!.appVersionDependencies.announcementsThumbnail.min) === -1,
      ) ||
      selectedVersionsiOS.some(
        version => compareVersions(version, config!.appVersionDependencies.announcementsThumbnail.min) === -1,
      )
    );
  };

  /**
   * toggle option method for dropdown
   */
  const toggleOption = (
    opt: SharedNestedOptionDropdown,
    appVersion: string[] | undefined,
    setValue: (value: any) => void,
    setFieldValue: (name: string, value: any) => void,
    allAppVersions: IVersion[],
    deviceType: DeviceType,
    values: IAnnouncement,
  ) => {
    if (appVersion && appVersion.includes(opt.key)) {
      const index = appVersion.indexOf(opt.key);
      const newAppVersion = [...appVersion];
      newAppVersion.splice(index, 1);
      const newTargetVersions = getTargetVersionFieldValue(newAppVersion, allAppVersions, deviceType);
      setTargetVersions(setFieldValue, values, newTargetVersions, setValue, newAppVersion);
    } else if (appVersion && opt.children) {
      let nthChildSelected = 0;
      let newAppVersion = [] as string[];
      opt.children.forEach(option => {
        if (appVersion.includes(option.key)) {
          nthChildSelected++;
        }
      });
      if (nthChildSelected < opt.children.length) {
        newAppVersion = AnnouncementsUtils.removeDuplicatesFromArray([
          ...appVersion,
          ...AnnouncementsUtils.getOptionsChildren(opt),
        ]);
      } else {
        newAppVersion = [...appVersion];
        opt.children.forEach(option => {
          const index = newAppVersion.indexOf(option.key);
          newAppVersion.splice(index, 1);
        });
      }
      const newTargetVersions = getTargetVersionFieldValue(newAppVersion, allAppVersions, deviceType);
      setTargetVersions(setFieldValue, values, newTargetVersions, setValue, newAppVersion);
    } else {
      let newAppVersion = appVersion ? appVersion : [];
      newAppVersion = AnnouncementsUtils.removeDuplicatesFromArray([
        ...newAppVersion,
        ...(opt.children ? AnnouncementsUtils.getOptionsChildren(opt) : [opt.key]),
      ]);
      const newTargetVersions = getTargetVersionFieldValue(newAppVersion, allAppVersions, deviceType);
      setTargetVersions(setFieldValue, values, newTargetVersions, setValue, newAppVersion);
    }
  };

  /**
   * method to set the targetVersions
   */
  const setTargetVersions = (
    setFieldValue: (name: string, value: any) => void,
    values: IAnnouncement,
    targetVersions: IVersion[],
    setValue: (value: any) => void,
    newAppVersion: string[],
  ) => {
    setValue(newAppVersion);
    setFieldValue("targetVersions", targetVersions);
    onChangeValues({ ...values, targetVersions });
  };

  /**
   * is dropdown option selected
   */
  const getTargetVersionFieldValue = (newAppVersion: string[], allAppVersions: IVersion[], deviceType: DeviceType) => {
    return [
      ...AnnouncementsUtils.getTargetVersionsObject(newAppVersion, allAppVersions, deviceType),
      ...(deviceType === DeviceType.ANDROID
        ? AnnouncementsUtils.getTargetVersionsObject(selectedVersionsiOS, appVersionsiOS, DeviceType.IOS)
        : AnnouncementsUtils.getTargetVersionsObject(selectedVersionsAndroid, appVersionsAndroid, DeviceType.ANDROID)),
    ];
  };

  /**
   * is dropdown option selected
   */
  const isOptionSelected = (opt: SharedDropdownOption, appVersion: string[] | undefined) => {
    return (appVersion && appVersion.includes(opt.key)) || (appVersion && appVersion.includes("all-versions-key"));
  };

  /**
   * method to return label to be shown when not editing
   */
  const getDropdownLabel = (appVersion: string[] | undefined) => {
    return appVersion && appVersion.length > 0
      ? appVersion.includes("all-versions-key")
        ? t("pages.announcements.detailPage.rows.allVersions")
        : AnnouncementsUtils.sortArrayOfVersions(appVersion).join("; ")
      : t("pages.announcements.detailPage.rows.none");
  };

  /**
   *method to return label to be shown when editing
   */
  const getDropdownLabelEditing = (appVersion: string[] | undefined) => {
    return appVersion && appVersion.length > 0
      ? appVersion.includes("all-versions-key")
        ? t("pages.announcements.detailPage.rows.allVersions")
        : t("pages.announcements.detailPage.rows.selectedNVersions").replace("{number}", appVersion.length.toString())
      : t("pages.announcements.detailPage.rows.chooseVersions");
  };

  /**
   * method to get the available app versions
   */
  const getAppVersion = () => {
    setIsLoadingVersions(true);
    AnnouncementsApi.methods
      .getAppVersions(
        AnnouncementsUtils.isBusinessAudience(announcement.audience as AudienceType)
          ? AppVersionServiceType.ORG
          : AppVersionServiceType.CONSUMER,
      )
      .then(
        res => {
          const appVersions = res.data.appVersions;
          const androidAppVersions = appVersions.find(versions => versions.deviceType === DeviceType.ANDROID);
          const iosAppVersions = appVersions.find(versions => versions.deviceType === DeviceType.IOS);
          setAppVersionsAndroid(
            androidAppVersions && androidAppVersions.versions
              ? sortArrayBy(androidAppVersions.versions, "appVersion", SortDirectionEnum.ASC, FieldTypeEnum.NUMBER)
              : [],
          );
          setAppVersionsiOS(
            iosAppVersions && iosAppVersions.versions
              ? sortArrayBy(iosAppVersions.versions, "appVersion", SortDirectionEnum.ASC, FieldTypeEnum.NUMBER)
              : [],
          );
          setIsLoadingVersions(false);
        },
        () => {},
      );
  };

  /**
   * to return the fields to the first section of the form
   * @param values are the values of the announcement to change
   * @param errors  are to use when in validation the fields are with errors
   * @param setFieldValue function used to update values
   */
  const getAppVersionRows = (
    values: IAnnouncement,
    errors: FormikErrors<IAnnouncement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  ): FormSectionRow[] => {
    return [
      {
        label: t("pages.announcements.detailPage.rows.appVersionAndroid"),
        displayComponent: getDropdownLabel(selectedVersionsAndroid),
        editingComponent: !isLoadingVersions ? (
          <MultipleOptionNestedDropdown
            options={getOptionsDropdown(values, appVersionsAndroid)}
            toggleOption={opt =>
              toggleOption(
                opt,
                selectedVersionsAndroid,
                setSelectedVersionsAndroid,
                setFieldValue,
                appVersionsAndroid,
                DeviceType.ANDROID,
                values,
              )
            }
            isOptionSelected={(opt: SharedDropdownOption) => isOptionSelected(opt, selectedVersionsAndroid)}
            clearAllFilters={() => {
              const clearedFiltersVersions = [
                ...AnnouncementsUtils.getTargetVersionsObject([], appVersionsAndroid, DeviceType.ANDROID),
                ...AnnouncementsUtils.getTargetVersionsObject(selectedVersionsiOS, appVersionsiOS, DeviceType.IOS),
              ];
              setTargetVersions(setFieldValue, values, clearedFiltersVersions, setSelectedVersionsAndroid, []);
            }}
            label={getDropdownLabelEditing(selectedVersionsAndroid)}
            dropdownType={DropdownType.RECTANGULAR_NORMAL}
            hasValue={false}
            error={errors.targetVersions ? t("commons.mandatoryVersion") : undefined}
          />
        ) : (
          <LoadingText>{t("pages.announcements.detailPage.rows.loadingAppVersions")}</LoadingText>
        ),
      },
      {
        label: t("pages.announcements.detailPage.rows.appVersioniOS"),
        displayComponent: getDropdownLabel(selectedVersionsiOS),
        editingComponent: !isLoadingVersions ? (
          <MultipleOptionNestedDropdown
            options={getOptionsDropdown(values, appVersionsiOS)}
            toggleOption={opt =>
              toggleOption(
                opt,
                selectedVersionsiOS,
                setSelectedVersionsiOS,
                setFieldValue,
                appVersionsiOS,
                DeviceType.IOS,
                values,
              )
            }
            isOptionSelected={(opt: SharedDropdownOption) => isOptionSelected(opt, selectedVersionsiOS)}
            clearAllFilters={() => {
              const clearedFiltersVersions = [
                ...AnnouncementsUtils.getTargetVersionsObject([], appVersionsiOS, DeviceType.IOS),
                ...AnnouncementsUtils.getTargetVersionsObject(
                  selectedVersionsAndroid,
                  appVersionsAndroid,
                  DeviceType.ANDROID,
                ),
              ];
              setTargetVersions(setFieldValue, values, clearedFiltersVersions, setSelectedVersionsiOS, []);
            }}
            label={getDropdownLabelEditing(selectedVersionsiOS)}
            dropdownType={DropdownType.RECTANGULAR_NORMAL}
            hasValue={false}
            error={errors.targetVersions ? t("commons.mandatoryVersion") : undefined}
          />
        ) : (
          <LoadingText>{t("pages.announcements.detailPage.rows.loadingAppVersions")}</LoadingText>
        ),
      },
    ];
  };

  useEffect(() => {
    onChangeValues(announcement);
  }, [announcement]);

  useEffect(() => {
    getAppVersion();
  }, []);

  return (
    <Formik
      initialValues={announcement}
      onSubmit={onSubmitStep}
      validationSchema={AnnouncementsUtils.validateStepAppVersion}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, setFieldValue, handleChange, handleSubmit, resetForm, errors }) => (
        <HomeMainContent>
          <HomeScreenMain>
            <SectionForm
              id={AnnouncementFormSteps.STEP_TWO}
              onSubmit={handleSubmit}
              onChange={() => {
                onChangeValues(values);
              }}
              onReset={() => {
                if (initialValues.targetVersions && initialValues.targetVersions.length > 0) {
                  resetForm(initialValues);
                  onChangeValues(initialValues);
                  setSelectedVersionsAndroid(
                    initialValues.targetVersions
                      ? AnnouncementsUtils.getSelectedVersions(initialValues, DeviceType.ANDROID)
                      : [],
                  );
                  setSelectedVersionsiOS(
                    initialValues.targetVersions
                      ? AnnouncementsUtils.getSelectedVersions(initialValues, DeviceType.IOS)
                      : [],
                  );
                } else if (setEditingAppVersion) {
                  setEditingAppVersion(true);
                }
              }}
            >
              <FormSection
                title={
                  <TitleContainer>
                    <FormTitle>{`${
                      AnnouncementsUtils.isConsumerAudience(values.audience as AudienceType)
                        ? t("pages.announcements.detailPage.rows.consumer")
                        : t("pages.announcements.detailPage.rows.business")
                    } ${t("pages.announcements.detailPage.rows.appVersion")}`}</FormTitle>
                    {AnnouncementsUtils.isConsumerAudience(values.audience as AudienceType) &&
                    config?.market !== BackOfficeMarketsEnums.ET ? (
                      <InformativeText>
                        {consumerChildEnabled &&
                        (values.audience === ConsumerAudience.ALL_CONSUMERAPPS ||
                          values.audience === ConsumerAudience.CONSUMERAPP_CHILD)
                          ? t("pages.announcements.detailPage.rows.appVersionDetailsOnChildAccount", {
                              version: config?.appVersionDependencies.childAccount.min,
                            })
                          : t("pages.announcements.detailPage.rows.appVersionDetails")}
                      </InformativeText>
                    ) : null}
                  </TitleContainer>
                }
                isEditing={isEditing}
                rows={getAppVersionRows(values, errors, setFieldValue)}
              />
            </SectionForm>
          </HomeScreenMain>
        </HomeMainContent>
      )}
    </Formik>
  );
};

export default AnnouncementsStepAppVersion;

const HomeScreenMain = styled("div")`
  width: 95%;
`;

const SectionForm = styled("form")`
  margin-right: 20%;
`;

const FormTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const TitleContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;
