import React, { HTMLAttributes } from "react";
import styled from "styled-components";
import { TextArea } from "@wit/mpesa-ui-components";
import { useTranslation } from "react-i18next";

interface ITextAreaWithTagsProps extends HTMLAttributes<HTMLDivElement> {
  error?: string;
  placeholder?: string;
  maxHeight?: string;
  tags: ITag[];
  maxLength?: number;
  onChangeValue: (value: string) => any;
  value: string;
  setErrors: (error: string | undefined) => void;
}

export interface ITag {
  color: string;
  fontColor: string;
  label: string;
}

/**
 * TextAreaWithTags component
 */
const TextAreaWithTags = ({
  error,
  placeholder,
  tags,
  maxHeight = "none",
  onChangeValue,
  value,
  setErrors,
  maxLength = 65,
  ...rest
}: ITextAreaWithTagsProps) => {
  const [usedTags, setUsedTags] = React.useState<string[]>([]);
  const [t] = useTranslation();

  React.useEffect(() => {
    validateTagsSyntax(value);
    validateUsedTags(value);
  }, [value]);

  /**
   * method to add a tag to text
   */
  const onClickTag = (tag: ITag) => {
    const textArea = document.getElementById("textAreaElement") as HTMLTextAreaElement;
    const cursorPosition = textArea.selectionStart;
    const tagLabel = ` [${tag.label}] `;
    onChangeValue(value.slice(0, cursorPosition) + tagLabel + value.slice(cursorPosition));
    usedTags.push(tag.label);
    textArea.focus();
    process.nextTick(() => {
      textArea.selectionEnd = cursorPosition + tagLabel.length;
    });
  };

  /**
   * method to validate if tags are well written
   */
  const validateTags = (newValue: string) => {
    var countStartTag = (newValue.match(/\[/g) || []).length;
    var countEndTag = (newValue.match(/\]/g) || []).length;
    const textArea = document.getElementById("textAreaElement") as HTMLTextAreaElement;
    const cursorPosition = textArea.selectionStart;

    if (countStartTag > 0 || countEndTag > 0) {
      if (countStartTag > countEndTag) {
        newValue = deleteUntilStartTag(newValue, cursorPosition);
      } else if (countEndTag > countStartTag) {
        newValue = deleteUntilEndTag(newValue, cursorPosition);
      } else {
        newValue = !isInsideTag(newValue, cursorPosition) ? newValue : value;
      }
    } else {
      setUsedTags([]);
    }

    onChangeValue(newValue);
  };

  /**
   * method to delete the tag when the end tag symbol ("]") was already deleted
   * called when user delete the last element of the tag (Backspace key)
   */
  const deleteUntilStartTag = (value: string, position: number) => {
    let temp = value.substring(0, position);
    while (temp.length > 0) {
      temp = temp.slice(0, -1);
      if (temp.slice(-1) === "[") {
        temp = temp.slice(0, -1);
        break;
      }
    }
    return temp + value.substring(position);
  };

  /**
   * method to delete the tag when the start tag symbol ("[") was already deleted
   * called when user delete the last element of the tag (Del key)
   */
  const deleteUntilEndTag = (value: string, position: number) => {
    let temp = value.substring(position);
    while (temp.length > 0) {
      temp = temp.substring(1);
      if (temp.charAt(0) === "]") {
        temp = temp.substring(1);
        break;
      }
    }
    return value.substring(0, position) + temp;
  };

  /**
   * method to validate if user is trying to write inside a tag
   */
  const isInsideTag = (value: string, position: number) => {
    const temp = value.substring(position);
    var countStartTag = (temp.match(/\[/g) || []).length;
    var countEndTag = (temp.match(/\]/g) || []).length;
    return countStartTag !== countEndTag ? true : false;
  };

  /**
   * method to validate tags of text exist
   */
  const validateTagsSyntax = (value: string) => {
    const tagsLabels = tags.map(tag => tag.label);
    const parts = value ? value.split(/\[(.*?)\]/g) : [];
    for (let i = 1; i < parts.length; i = i + 2) {
      if (!tagsLabels.includes(parts[i])) {
        setErrors(t(`pages.referralCampaigns.createCampaign.campaignDetails.tagInvalid`));
        return;
      }
    }

    setErrors(undefined);
  };

  /**
   * method to validate used tags
   */
  const validateUsedTags = (value: string) => {
    const tagsLabels = tags.map(tag => tag.label);
    const parts = value ? value.split(/\[(.*?)\]/g) : [];
    const tagsFound = [] as string[];
    for (let i = 1; i < parts.length; i = i + 2) {
      if (tagsLabels.includes(parts[i])) {
        tagsFound.push(parts[i]);
      }
    }
    if (tagsFound.filter((item, index) => tagsFound.indexOf(item) !== index).length > 0) {
      setErrors(t(`pages.referralCampaigns.createCampaign.campaignDetails.tagDuplicated`));
    } else if (!!value && tagsFound.length === 0) {
      setErrors(t(`pages.referralCampaigns.createCampaign.campaignDetails.tagMandatory`));
    } else {
      setErrors(undefined);
    }
    setUsedTags(tagsFound);
  };

  return (
    <>
      <TextAreaWithTagsContainer maxHeight={maxHeight} hasError={!!error}>
        <TextArea
          verticalResize={false}
          horizontalResize={false}
          placeholder={placeholder}
          maxHeight={maxHeight}
          value={value}
          onChange={e => validateTags(e.target.value)}
          id={"textAreaElement"}
          error={error}
          maxLength={maxLength}
        />
        <Separator />
        <TagsContainer>
          {tags.map((tag, i) => (
            <TagChipContainer
              id={`tag-${i}`}
              color={tag.color}
              fontColor={tag.fontColor}
              onClick={() => (usedTags.includes(tag.label) ? null : onClickTag(tag))}
              disable={usedTags.includes(tag.label)}
            >
              <span style={{ fontSize: "18px" }}>{"+"}</span>&nbsp;
              {tag.label}
            </TagChipContainer>
          ))}
        </TagsContainer>
      </TextAreaWithTagsContainer>
      <ErrorMessage data-testid="inviteMessageError">{error}</ErrorMessage>
    </>
  );
};

export default TextAreaWithTags;

const TextAreaWithTagsContainer = styled("div")<{ maxHeight: string; hasError: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${props => props.maxHeight};
  color: ${props => (props.hasError ? props.theme.palette.errorRed : props.theme.palette.darkGrey)};
  border: 1px solid ${props => (props.hasError ? props.theme.palette.errorRed : props.theme.palette.aluminium)};
  border-radius: 6px;
  > div > textarea {
    border: none;
    color: ${props => (props.hasError ? props.theme.palette.errorRed : props.theme.palette.darkGrey)};
  }

  :hover {
    border-color: ${props => props.theme.palette.greyDarker};
  }

  :focus {
    border-color: ${props => props.theme.palette.black};
  }
`;

const TagsContainer = styled("div")`
  display: flex;
  align-items: center;
  padding: 9px 4.5px;
`;

const TagChipContainer = styled("span")<{ color: string; fontColor: string; disable: boolean }>`
  margin-left: 4.5px;
  margin-right: 4.5px;
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-align: center;
  font-family: Vodafone Rg;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  background-color: ${props => (props.disable ? props.theme.palette.aluminium : props.color)};
  color: ${props => (props.disable ? props.theme.palette.midGrey : props.fontColor)};
  cursor: ${props => (props.disable ? "default" : "pointer")};
  width: fit-content;
`;

const ErrorMessage = styled("span")`
  font-family: Vodafone Rg;
  font-size: 14px;
  color: ${props => props.theme.palette.errorRed};
  margin-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Separator = styled("div")`
  width: 100%;
  height: 1px;
  border-top: solid 1px ${props => props.theme.palette.aluminium};
`;
