import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Table, SmallButton, PlusIcon, SearchBar, useModal, ModalTypeEnum } from "@wit/mpesa-ui-components";
import { useTranslation } from "react-i18next";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { TableCell } from "@wit/mpesa-ui-components/lib/components/table/table-row/table-row.styled";
import { IStoreInterface } from "../../../../configs/store.config";
import { SearchBarFilterContainer } from "../../../../shared/responsive-ui.styled";
import { IconContainer, Row } from "../../../../shared/shared.styled";
import { getBankColumns, renderTableBanksButtons } from "../banks.utils";
import EditBankModal from "./add-bank.component";
import useBanksAPI from "../banks.api";
import { IBank } from "../bank.model";

const BanksList = () => {
  const [t] = useTranslation();
  const { banks } = useSelector((state: IStoreInterface) => state.banksReducer);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [results, setResults] = React.useState<any>(undefined);
  const [selectedBank, setSelectedBank] = React.useState<IBank>();
  const { deleteBank, restoreBank } = useBanksAPI();
  const [showAddModal, hidAddModal, setAddProps] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <EditBankModal
      onCancelClick={() => hidAddModal()}
      selectedBank={selectedBank as IBank}
      setSelectedBank={setSelectedBank}
    />,
  );

  React.useEffect(() => {
    setAddProps({ modalStyles: { width: 615, overflow: "initial" } });
  }, [setAddProps]);

  React.useEffect(() => {
    if (searchTerm && searchTerm !== "") {
      setResults(
        banks.data.filter(
          e =>
            e.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            e.shortCode.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
    } else {
      setResults(banks.data);
    }
  }, [searchTerm, banks.data]);

  const bankList = React.useMemo(() => {
    return (
      banks.data && (
        <Table<IBank>
          columns={getBankColumns(t)}
          values={results || banks.data}
          displayActions={bank =>
            renderTableBanksButtons<IBank>(bank, {
              edit: { onClick: editBank },
              delete: { onClick: deleteBank },
              revert: { onClick: restoreBank },
            })
          }
        />
      )
    );
  }, [results, banks.data]);

  const editBank = (bank: IBank) => {
    setSelectedBank(bank);
    showAddModal();
  };

  const createNewBank = () => {
    setSelectedBank(undefined);
    showAddModal();
  };

  return (
    <BanksListContainer>
      <Row style={{ justifyContent: "space-between", gap: "24px", flexWrap: "wrap", marginBottom: "24px" }}>
        <SearchBarFilterContainer>
          <SearchBar
            placeholderLabel={t("pages.banks.search")}
            value={searchTerm || ""}
            onChange={e => setSearchTerm(e.target.value)}
            clearValue={() => setSearchTerm("")}
          />
        </SearchBarFilterContainer>
        <div id={"add-new-bank-button"}>
          <SmallButton
            iconComponent={
              <IconContainer size={16} color={styleTheme.palette.vodafoneRed}>
                <PlusIcon />
              </IconContainer>
            }
            onClick={() => createNewBank()}
            titleLabel={t("pages.banks.addNew")}
          />
        </div>
      </Row>
      <TableWrapper className="tableWrapper">{bankList}</TableWrapper>
    </BanksListContainer>
  );
};

export default BanksList;

const TableWrapper = styled.div`
  > div:first-child {
    > div:last-child {
      > div {
        padding: 0;
        border-bottom: 1px solid;
        border-bottom-color: ${props => props.theme.palette.lightGrey};
      }
    }
  }
`;

const BanksListContainer = styled.div`
  margin-top: 36px;
  margin-bottom: 50px;
  ${TableCell}:last-of-type {
    margin-right: 0 !important;
  }
`;

export const Label = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
`;
