import { StatusType } from "../../shared/shared.enums";

export interface IResponseToGetAnnouncements {
  announcements: IAnnouncement[];
  audienceAvailable: AudienceType;
}

export interface IPushNotificationsDetails {
  title?: string;
  description?: string;
}
export interface IAnnouncement {
  id: string;
  campaignName: string;
  createDate?: string;
  startDate?: any;
  time?: string;
  endDate?: any;
  endTime?: string;
  sendPushNotifications?: boolean;
  pushNotificationDetails?: IPushNotificationsDetails;
  suggested?: boolean;
  dismissible?: boolean;
  audience?: AudienceType;
  profileTypes?: GsmProfile[]; // enabled when audience == CONSUMERAPP
  appPlacement?: AppPlacement; // enabled when audience == CONSUMERAPP
  targetBusinessMerchant?: string[] | null;
  targetBusinessAgent?: string[] | null;
  targetConsumer?: string[] | null;
  fileInfoBusinessMerchant?: IFileInfoInterface;
  fileInfoBusinessAgent?: IFileInfoInterface;
  fileInfoConsumer?: IFileInfoInterface;
  title?: string;
  smallDescription?: string;
  color?: string;
  icon?: string;
  stories: IStoryInterface[];
  status?: StatusType;
  targetVersions?: IVersion[];
  thumbnailImage?: string;
  thumbnailImageUrl?: string;
  thumbnailImageFileInfo?: IFileInfoInterface;
  thumbnailImageCarousel?: string;
  thumbnailImageCarouselUrl?: string;
  thumbnailImageFileInfoCarousel?: IFileInfoInterface;
}

export type AudienceType = BusinessAudience | ConsumerAudience;

export enum BusinessAudience {
  BUSINESSAPP_MERCHANT = "BUSINESSAPP_MERCHANT",
  BUSINESSAPP_AGENT = "BUSINESSAPP_AGENT",
  ALL_BUSINESSAPPS = "ALL_BUSINESSAPPS",
}

export enum ConsumerAudience {
  ALL_CONSUMERAPPS = "ALL_CONSUMERAPPS",
  CONSUMERAPP_ADULT = "CONSUMERAPP_ADULT",
  CONSUMERAPP_CHILD = "CONSUMERAPP_CHILD",
  CONSUMERAPP = "CONSUMERAPP",
}

export enum GsmProfile {
  PRE_PAID_CBU = "PRE_PAID_CBU",
  POST_PAID_CBU = "POST_PAID_CBU",
  PRE_PAID_EBU = "PRE_PAID_EBU",
  POST_PAID_EBU = "POST_PAID_EBU",
}

export enum AppPlacement {
  HOMESCREEN = "HOMESCREEN",
  SAFARICOM_TAB = "SAFARICOM_TAB",
}

export enum BusinessType {
  AGENT = "AGENT",
  MERCHANT = "MERCHANT",
}

export interface IStoryInterface {
  id: string;
  backgroundImage: string;
  backgroundImageUrl: string;
  fileInfo: IFileInfoInterface;
  title: string;
  colorTitle: string | null;
  description: string;
  colorDescription: string | null;
  callAction: boolean;
  callToAction: ICallToActionInterface;
}

export interface IFileInfoInterface {
  name: string;
  size: number;
  invalidTargets?: string[];
  allTargets?: string[];
}

export interface ITargetAudienceDrawerInterface {
  target: string;
  status: ETargetAudienceStatus;
}

export enum ETargetAudienceStatus {
  VALID = "VALID",
  INVALID = "INVALID",
}

export interface ICallToActionInterface {
  title: string;
  action: IActionInterface;
}

export interface IActionInterface {
  type: CallToActionType;
  resource?: FeatureBusiness | FeatureConsumer | string;
}
export enum CallToActionType {
  LINK = "LINK",
  FEATURE = "FEATURE",
  MINIAPP = "MINIAPP",
  EXTERNAL_LINK = "EXTERNAL_LINK",
  INTERNAL_LINK = "INTERNAL_LINK",
}

export enum FeatureBusiness {
  // The values of the enum that are in comment, are to stay like this and to be activated when the APPS support this feature by deep links
  // TRANSACT_TAB = "TRANSACT_TAB",
  // ORGANIZATION_TAB = "ORGANIZATION_TAB",
  // ACCOUNT = "ACCOUNT",
  // SEARCH_TRANSACTIONS = "SEARCH_TRANSACTIONS",
  // CHANGE_ACCOUNT = "CHANGE_ACCOUNT",
  PAY_CUSTOMER = "PAY_CUSTOMER",
  BUY_GOODS = "BUY_GOODS",
  PAY_BILL = "PAY_BILL",
  WITHDRAW_TO_OWNER = "WITHDRAW_TO_OWNER",
  WITHDRAW_TO_BANK = "WITHDRAW_TO_BANK",
  WITHDRAW_AT_AGENT = "WITHDRAW_AT_AGENT",
  BIASHARA_OVERDRAFT = "BIASHARA_OVERDRAFT",
  //  STORES = "STORES",
  //  OPERATORS = "OPERATORS",
  EXPORT_STATEMENTS = "EXPORT_STATEMENTS",
  ROLL_UP_STORE_LEVEL = "ROLL_UP_STORE_LEVEL",
  ROLL_UP_ONE_STORE = "ROLL_UP_ONE_STORE",
  ROLL_UP_ALL_STORES = "ROLL_UP_ALL_STORES",
  SELL_AIRTIME = "SELL_AIRTIME",
  // CALL_SUPPORT = "CALL_SUPPORT",
  PAY_TO_MANY = "PAY_TO_MANY",
  PAY_WITH_MPESA = "PAY_WITH_MPESA",
}

export enum FeatureConsumer {
  MY_SPEND = "MY_SPEND",
  EXPORT_STATEMENT = "EXPORT_STATEMENT",
  SEND_MONEY = "SEND_MONEY",
  SEND_TO_MANY = "SEND_TO_MANY",
  PAY_TO_MOBILE_NUMBER = "PAY_TO_MOBILE_NUMBER",
  PAY_BILL = "PAY_BILL",
  DUE_BILLS = "DUE_BILLS",
  BUY_GOODS = "BUY_GOODS",
  WITHDRAW_AT_AGENT = "WITHDRAW_AT_AGENT",
  WITHDRAW_AT_ATM = "WITHDRAW_AT_ATM",
  BUY_AIRTIME_FOR_MY_NUMBER = "BUY_AIRTIME_FOR_MY_NUMBER",
  BUY_AIRTIME_FOR_OTHER_NUMBER = "BUY_AIRTIME_FOR_OTHER_NUMBER",
  FULIZA = "FULIZA",
  SCAN_QR_CODE = "SCAN_QR_CODE",
  MY_QR = "MY_QR",
  GLOBAL = "GLOBAL",
  GLOBAL_SEND_TO_MOBILE = "GLOBAL_SEND_TO_MOBILE",
  GLOBAL_SEND_TO_BANK = "GLOBAL_SEND_TO_BANK",
  GLOBAL_SEND_TO_WESTERN_UNION = "GLOBAL_SEND_TO_WESTERN_UNION",
  GLOBAL_PAYPAL_TOP_UP = "GLOBAL_PAYPAL_TOP_UP",
  GLOBAL_COST_ESTIMATOR = "GLOBAL_COST_ESTIMATOR",
  GLOBAL_ROAMING_PICKUP = "GLOBAL_ROAMING_PICKUP",
  GROW_TAB = "GROW_TAB",
  DISCOVER_TAB = "DISCOVER_TAB",
  TRANSACT_TAB = "TRANSACT_TAB",
  FAQS = "FAQS",
  ACCOUNT = "ACCOUNT",
  CALL_SUPPORT = "CALL_SUPPORT",
  CHANGE_PIN = "CHANGE_PIN",
  PROFILE_PICTURE = "PROFILE_PICTURE",
  MANAGE_FAVOURITES = "MANAGE_FAVOURITES",
  PAY_WITH_MPESA = "PAY_WITH_MPESA",
}

export enum DurationType {
  ONE_DAY = "ONE_DAY",
  TWO_DAY = "TWO_DAY",
  ONE_WEEK = "ONE_WEEK",
  TWO_WEEK = "TWO_WEEK",
  THREE_WEEK = "THREE_WEEK",
  ONE_MONTH = "ONE_MONTH",
  TWO_MONTH = "TWO_MONTH",
}

export interface IIcon {
  id: string;
  label: string;
  category: string | null;
  base64: string;
}

export interface ICallToActionOptions {
  LINK?: ICallToActionOption;
  FEATURE?: ICallToActionOption;
  MINIAPP?: ICallToActionOption;
  INTERNAL_LINK?: ICallToActionOption;
  EXTERNAL_LINK?: ICallToActionOption;
}

export interface ICallToActionOption {
  description?: string;
  values?: IValueOption[];
  active?: boolean;
}

export interface IValueOption {
  name: string;
  description: string;
}

export interface IAppVersions {
  appVersions: IAppVersion[];
}

interface IAppVersion {
  deviceType: DeviceType;
  versions: IVersion[];
}

export interface IVersion {
  appVersion: string;
  appBuildVersions: string[];
  deviceType?: string;
}

export enum DeviceType {
  IOS = "IOS",
  ANDROID = "ANDROID",
}

export enum AppVersionServiceType {
  ORG = "ORG",
  CONSUMER = "CONSUMER",
}

export enum AnnouncementFormSteps {
  STEP_ONE = "form-campaign-details",
  STEP_TWO = "form-app-versions",
  STEP_THREE = "form-homescreen-card",
  STEP_FOUR = "form-announcement-details",
}
