import { Magnifier, PageTitle, useAlert, useFilters } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { IStoreInterface } from "../../../configs/store.config";
import { RoutesEnum } from "../../../routes/routes.constants";
import { ListHeader, ListHeaders, LoadingText, PageContainer, Row } from "../../../shared/shared.styled";
import AddPushNotificationItem from "./components/add-push-notification-item.component";
import PushNotificationsApi from "./push-notifications.api";
import { PushNotificationsActions } from "./push-notifications.store";
import {
  filterPushNotifications,
  getBreadcrumbs,
  processPushNotificationBackendStatus,
} from "./push-notifications.utils";
import PushNotificationListItem from "./components/push-notification-list-item.component";
import PushNotificationsFilters from "./components/push-notifications-filters.component";
import {
  IPushNotification,
  IPushNotificationCompletionProgress,
  IPushNotifications,
  PushNotificationStatusEnum,
} from "./push-notifications.model";
import moment from "moment";
import EmptySearchResult from "../../../shared/components/empty-search-result.component";
import PushNotificationDrawer from "./components/push-notification.drawer";

/**
 * PushNotificationListPage
 */
const PushNotificationsListPage = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { pushNotifications } = useSelector((state: IStoreInterface) => state.pushNotificationsReducer);

  const [showAlert, , setAlertProps] = useAlert();
  const [filtersResult, setFiltersResult] = useState<IPushNotification[]>();
  const {
    filters,
    updateFilter,
    isFilterActive,
    clearFilter,
    resetFilters,
    getFilterValue,
    getFiltersQueryString,
    updateMultipleFilters,
  } = useFilters();
  const history = useHistory();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [selectedPushNotification, setSelectedPushNotification] = useState<
    IPushNotificationCompletionProgress | undefined
  >(undefined);

  React.useEffect(() => {
    if (filters.size > 0) {
      const newPushNotifications = filterPushNotifications(
        pushNotifications.notifications ? pushNotifications.notifications : [],
        filters,
      );
      setFiltersResult(newPushNotifications);
    } else {
      setFiltersResult(undefined);
    }
  }, [filters, pushNotifications]);

  React.useEffect(() => {
    getPushNotifications();
  }, []);

  /**
   * Get push notifications fn
   * */
  const getPushNotifications = () => {
    PushNotificationsApi.methods.getPushNotifications().then(
      res => {
        // TODO: improve this (hover date to view time in the future?)
        const result = {
          notifications: res.data.map((n: any) => {
            return {
              id: n.id,
              title: n.title.en,
              message: n.message.en,
              users: n.users,
              appVersion: n.appVersion,
              versions: n.versions,
              linkType: n.linkType,
              linkContent: n.linkContent,
              date: moment(n.scheduledDate).format("DD/MM/YYYY"),
              status: processPushNotificationBackendStatus(n.status),
            } as IPushNotification;
          }),
        } as IPushNotifications;

        dispatch(PushNotificationsActions.creators.getPushNotificationsSuccess(result));
      },
      () => {
        setAlertProps({
          title: t("pages.pushNotifications.getPushNotificationsError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * Returns a list of unique push notification status
   * */
  const getUniqueStatusTypes = () => {
    return pushNotifications.notifications
      .map(c => c.status)
      .filter((value, index, self) => self.indexOf(value) === index);
  };

  /**
   * Delete push notification
   * @param id
   */
  const deletePushNotification = (id: string) => {
    PushNotificationsApi.methods.deletePushNotification(id).then(
      () => {
        setAlertProps({
          title: t("pages.pushNotifications.delete.success"),
          type: AlertTypeEnum.SUCCESS,
        });
        showAlert();
        getPushNotifications();
      },
      () => {
        setAlertProps({
          title: t("pages.pushNotifications.delete.error"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * Hides the push notification campaign completion drawer
   */
  const hideDrawer = () => {
    setIsDrawerVisible(false);
  };

  return (
    <PageContainer>
      <PageTitle
        breadcrumbs={getBreadcrumbs(t)}
        title={t("pages.pushNotifications.pushNotifications")}
        navigateFunction={history.push}
        goBackFn={() => history.push(RoutesEnum.TOOLS)}
        separatorStyle={{ display: "none" }}
        titleContainerStyle={{
          marginTop: "13px",
          marginBottom: "20px",
          height: "60px",
        }}
        rightComponentContainerStyle={{
          display: "flex",
          alignContent: "center",
          height: "100%",
          marginRight: "0px",
        }}

        /*rightComponent={
          <>
            {pushNotifications ? (
              <ButtonContainer style={{ position: "relative", top: "10px" }}>
                <PrimaryButton
                  id={"publish-changes"}
                  disabled={!pushNotifications.hasUnpublishedChanges}
                  titleLabel={t("pages.pushNotifications.publishChanges")}
                  type="submit"
                  redTheme={true}
                  style={{ width: "fit-content" }}
                  onClick={() => {
                    /!* TODO *!/
                  }}
                />
              </ButtonContainer>
            ) : null}
          </>
        }*/
      />

      {!pushNotifications.notifications ? (
        <LoadingText>{t("commons.loadingResults")}</LoadingText>
      ) : (
        <PushNotificationsContainer>
          <Row>
            <PushNotificationsFilters
              filters={filters}
              isFilterActive={isFilterActive}
              updateFilter={updateFilter}
              clearFilter={clearFilter}
              resetFilters={resetFilters}
              getFilterValue={getFilterValue}
              getFiltersQueryString={getFiltersQueryString}
              updateMultipleFilters={updateMultipleFilters}
              status={getUniqueStatusTypes()}
            />
          </Row>

          <PushNotificationsListContainer>
            {filtersResult && filtersResult.length === 0 ? (
              <EmptySearchResult />
            ) : (
              <>
                <ListHeaders>
                  <ListHeader ratio={4 / 12}>{t("pages.pushNotifications.pushNotification")}</ListHeader>
                  <ListHeader ratio={3 / 12}>{t("pages.pushNotifications.message")}</ListHeader>
                  <ListHeader ratio={2 / 12}>{t("pages.pushNotifications.users")}</ListHeader>
                  <ListHeader ratio={3 / 12} style={{ textAlign: "right" }}>
                    {t("pages.pushNotifications.status")}
                  </ListHeader>
                </ListHeaders>
                <AddPushNotificationItem
                  addNewPushNotificationFn={() => history.push(RoutesEnum.ADD_PUSH_NOTIFICATION)}
                />
                {(filtersResult || pushNotifications.notifications).map(p => {
                  return (
                    <>
                      <PushNotificationListItem
                        key={p.id}
                        item={p}
                        deletePushNotification={deletePushNotification}
                        hasFastActions={
                          p.status === PushNotificationStatusEnum.DRAFT ||
                          p.status === PushNotificationStatusEnum.SCHEDULED
                        }
                        onClick={() => {
                          //TODO: Uncomment when completion progress was integrated
                          // if (
                          //   p.status === PushNotificationStatusEnum.PROCESSING ||
                          //   p.status === PushNotificationStatusEnum.SENT
                          // ) {
                          //   setSelectedPushNotification({
                          //     pushNotification: p,
                          //     pushCompleted: 10,
                          //     pushFailed: 2,
                          //     pushInProgress: 5,
                          //     totalPush: 20,
                          //   });
                          //   setIsDrawerVisible(true);
                          // }
                        }}
                        isSentOrProcessing={
                          p.status === PushNotificationStatusEnum.PROCESSING ||
                          p.status === PushNotificationStatusEnum.SENT
                        }
                      />
                    </>
                  );
                })}
              </>
            )}
          </PushNotificationsListContainer>
        </PushNotificationsContainer>
      )}
      {isDrawerVisible && (
        <PushNotificationDrawer hideDrawer={hideDrawer} selectedPushNotification={selectedPushNotification} />
      )}
    </PageContainer>
  );
};

export default PushNotificationsListPage;

const PushNotificationsContainer = styled("div")`
  margin-bottom: 34px;
  display: flex;
  flex-direction: column;
`;

/*const ButtonContainer = styled("div")`
  width: fit-content;
`;*/

const PushNotificationsListContainer = styled("div")`
  padding-bottom: 20px;
`;
