import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ConfigContext } from "../../../../app.component";
import { isSFCMarket } from "../../../../shared/shared.utils";

interface IAddLanguageTranslationVersionProps {
  clickFn: () => void;
}

/**
 * AddLanguageTranslationVersion component
 * @param clickFn the handler of add click event
 * @returns {JSX.Element}
 */
const AddLanguageTranslationVersion = ({ clickFn }: IAddLanguageTranslationVersionProps) => {
  const [t] = useTranslation();
  const { config } = useContext(ConfigContext);

  return (
    <Container onClick={clickFn} id={"add-new-language-translation-version"}>
      <IconContainer>
        <img
          src={config && isSFCMarket() ? "./images/safaricom-rounded-plus.svg" : "./images/rounded-plus.svg"}
          alt={t("pages.generalCategories.addCategoryButton")}
        />
      </IconContainer>
      <Title>{t("pages.languageTranslationsVersions.addNew")}</Title>
    </Container>
  );
};

const Container = styled("div")`
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  padding-left: 24px;
  margin-bottom: 24px;
  :hover {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  }
`;

const IconContainer = styled("div")`
  width: 45px;
  height: 45px;
  margin-bottom: 0;
  margin-right: 24px;

  img {
    width: 45px;
    height: 45px;
  }
`;

const Title = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  text-align: center;
  color: ${props => props.theme.palette.midGrey};
`;

export default AddLanguageTranslationVersion;
