import { CancelIcon, TabChild, Tabs } from "@wit/mpesa-ui-components";
import { highlightBlock } from "highlight.js";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import xmlFormatter from "xml-formatter";
import DrawerAccordion from "../../../../shared/components/drawer-accordion.component";
import { IMiniAppsLog } from "../../../../shared/models/mini-apps-log.model";
import { LoadingText } from "../../../../shared/shared.styled";
import SupportApi from "../../support.api";
import { IAppLog } from "../../support.interfaces";
import { getMiniAppsElapsedTime } from "../../support.utils";
import moment from "moment";
import { IGatewayLog } from "../../../../shared/models/gateway-log.model";

interface IRequestDrawerProps {
  miniAppsReportLogId: number;
  miniAppsReportLogRequestId: string;
  hideDrawer: () => void;
}

/**
 * Request drawer component
 * @param param0
 * @returns
 */
const RequestDrawer = ({ miniAppsReportLogId, miniAppsReportLogRequestId, hideDrawer }: IRequestDrawerProps) => {
  // Hooks Initialization
  const [t] = useTranslation();

  // Local state
  const [reportLogs, setReportLogs] = React.useState<IAppLog[] | undefined>(undefined);
  const [gatewayLogs, setGatewayLogs] = React.useState<IGatewayLog[] | undefined>(undefined);
  const [miniAppsReports, setMiniAppsReports] = React.useState<IMiniAppsLog[] | undefined>(undefined);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [isLoadingReport, setIsLoadingReport] = React.useState(false);
  const [miniAppsReportLog, setMiniAppsReportLog] = React.useState<IMiniAppsLog | undefined>(undefined);

  // Effects
  React.useEffect(() => {
    setIsLoadingReport(true);
    SupportApi.methods
      .getAppLogs({
        page: 1,
        pageSize: 10,
        requestId: miniAppsReportLogRequestId,
        fullDetails: true,
      })
      .finally(() => {
        SupportApi.methods
          .getGatewayLogs({
            page: 1,
            pageSize: 10,
            requestId: miniAppsReportLogRequestId,
            fullDetails: true,
          })
          .finally(() => {
            SupportApi.methods
              .getMiniAppsLogs({
                page: 1,
                pageSize: 10,
                requestId: miniAppsReportLogRequestId,
                fullDetails: true,
              })
              .finally(() => {
                setIsLoadingReport(false);
              })
              .then(
                res => {
                  if (res.data.results.length > 0) {
                    const { metadata, results } = res.data;
                    setMiniAppsReports(results);
                    const report = results.find(res => res.id === miniAppsReportLogId);
                    setMiniAppsReportLog(report);
                  }
                },
                () => {},
              );
          })
          .then(
            res => {
              if (res.data.gwReportLogList.length > 0) {
                const { gwReportLogList, count } = res.data;
                setGatewayLogs(gwReportLogList);
              }
            },
            () => {
              // TODO: Error handling
            },
          );
      })
      .then(
        res => {
          if (res.data.reportLogList.length > 0) {
            const { reportLogList, count } = res.data;
            setReportLogs(reportLogList);
          }
        },
        () => {
          // TODO: Error handling
        },
      );
  }, [miniAppsReportLogRequestId]);

  /*
   * Highlights the request and response json and XML
   * */
  React.useEffect(() => {
    document.querySelectorAll("pre code").forEach(block => {
      highlightBlock(block as HTMLElement);
    });
  });

  /**
   * Get highlighted JSON
   * @param data
   * @returns
   */
  const getHighlightedJSONOrXML = (data: string | undefined) => {
    let isJson = false;
    try {
      if (data) {
        JSON.parse(data);
        isJson = true;
      } else {
        isJson = false;
      }
    } catch (e) {
      isJson = false;
    }
    if (data && data.startsWith("<?xml")) {
      return (
        <pre>
          <code className="xml">
            {xmlFormatter(data, {
              indentation: "   ",
            })}
          </code>
        </pre>
      );
    } else if (data && data.startsWith("{") && isJson) {
      return (
        <pre>
          <code
            className="json"
            style={{
              overflow: "auto",
            }}
          >
            {JSON.stringify(data ? (JSON.parse(data) ? JSON.parse(data) : data) : null, undefined, "   ")}
          </code>
        </pre>
      );
    } else {
      return (
        <pre>
          <code
            className="json"
            style={{
              overflow: "auto",
            }}
          >
            {JSON.stringify(data)}
          </code>
        </pre>
      );
    }
  };

  /**
   * Calulates the elapsed time between two dates
   * @param initDate
   * @param endDate
   * @returns
   */
  const elapsedTime = (initDate: string | undefined, endDate: string | undefined) => {
    const momentInit = moment(endDate, "dd/MM/yyyy HH:mm:ss.SSS");
    const diff = momentInit.diff(moment(initDate, "dd/MM/yyyy HH:mm:ss.SSS"));
    if (isNaN(diff)) {
      return "-";
    }
    return `${diff}ms`;
  };

  /**
   * Render trace results tab
   * @returns
   */
  const renderTraceResults = () => {
    return (
      <>
        <DataRow>
          <DataRowLabel>{t("pages.support.miniAppsLogs.drawer.traceResults.msisdn")}</DataRowLabel>
          <DataRowValue>
            {reportLogs && reportLogs[0] && reportLogs[0].msisdn ? reportLogs[0].msisdn : "-"}
          </DataRowValue>
        </DataRow>
        <DataRow>
          <DataRowLabel>{t("pages.support.miniAppsLogs.drawer.traceResults.deviceId")}</DataRowLabel>
          <DataRowValue>
            {reportLogs && reportLogs[0] && reportLogs[0].deviceId ? reportLogs[0].deviceId : "-"}
          </DataRowValue>
        </DataRow>
        <DataRow>
          <DataRowLabel>{t("pages.support.miniAppsLogs.drawer.traceResults.deviceModel")}</DataRowLabel>
          <DataRowValue>
            {reportLogs && reportLogs[0] && reportLogs[0].deviceModel ? reportLogs[0].deviceModel : "-"}
          </DataRowValue>
        </DataRow>
        <DataRow>
          <DataRowLabel>{t("pages.support.miniAppsLogs.drawer.traceResults.apkIpaVersion")}</DataRowLabel>
          <DataRowValue>
            {reportLogs && reportLogs[0] && reportLogs[0].apkVersion ? reportLogs[0].apkVersion : "-"}
          </DataRowValue>
        </DataRow>
        <DataRow>
          <DataRowLabel>{t("pages.support.miniAppsLogs.drawer.traceResults.connectionStatus")}</DataRowLabel>
          <DataRowValue>
            {reportLogs && reportLogs[0] && reportLogs[0].connectionStatus ? reportLogs[0].connectionStatus : "-"}
          </DataRowValue>
        </DataRow>
        <DataRow>
          <DataRowLabel>{t("pages.support.miniAppsLogs.drawer.traceResults.connectionType")}</DataRowLabel>
          <DataRowValue>
            {reportLogs && reportLogs[0] && reportLogs[0].connectionType ? reportLogs[0].connectionType : "-"}
          </DataRowValue>
        </DataRow>
        <DataRow>
          <DataRowLabel>{t("pages.support.miniAppsLogs.drawer.traceResults.cardCarrier")}</DataRowLabel>
          <DataRowValue>
            {reportLogs && reportLogs[0] && reportLogs[0].cardCarrier ? reportLogs[0].cardCarrier : "-"}
          </DataRowValue>
        </DataRow>
        <DataRow>
          <DataRowLabel>{t("pages.support.miniAppsLogs.drawer.traceResults.networkCarrier")}</DataRowLabel>
          <DataRowValue>
            {reportLogs && reportLogs[0] && reportLogs[0].networkCarrier ? reportLogs[0].networkCarrier : "-"}
          </DataRowValue>
        </DataRow>
        <DataRow>
          <DataRowLabel>{t("pages.support.miniAppsLogs.drawer.traceResults.environment")}</DataRowLabel>
          <DataRowValue>
            {reportLogs && reportLogs[0] && reportLogs[0].environment ? reportLogs[0].environment : "-"}
          </DataRowValue>
        </DataRow>
        <DataRow>
          <DataRowLabel>{t("pages.support.miniAppsLogs.drawer.traceResults.appConfigId")}</DataRowLabel>
          <DataRowValue>
            {reportLogs && reportLogs[0] && reportLogs[0].appConfigId ? reportLogs[0].appConfigId : "-"}
          </DataRowValue>
        </DataRow>
        <DataRow>
          <DataRowLabel>{t("pages.support.miniAppsLogs.drawer.traceResults.appConfigVersion")}</DataRowLabel>
          <DataRowValue>
            {reportLogs && reportLogs[0] && reportLogs[0].appConfigVersion ? reportLogs[0].appConfigVersion : "-"}
          </DataRowValue>
        </DataRow>
      </>
    );
  };

  /**
   * Render details tab
   * @returns
   */
  const renderDetails = () => {
    return (
      <>
        <DataRow>
          <DataRowLabel>{t("pages.support.miniAppsLogs.drawer.details.miniAppsOperation")}</DataRowLabel>
          <DataRowValue>{miniAppsReportLog?.serviceOperation ? miniAppsReportLog.serviceOperation : "-"}</DataRowValue>
        </DataRow>
        <DataRow>
          <DataRowLabel>{t("pages.support.miniAppsLogs.drawer.details.appConfigID")}</DataRowLabel>
          <DataRowValue>{miniAppsReportLog?.appConfigId ? miniAppsReportLog.appConfigId : "-"}</DataRowValue>
        </DataRow>
        <DataRow>
          <DataRowLabel>{t("pages.support.miniAppsLogs.drawer.details.identifier")}</DataRowLabel>
          <DataRowValue
            style={{
              wordBreak: "break-all",
            }}
          >
            {miniAppsReportLog?.identifier ? miniAppsReportLog.identifier : "-"}
          </DataRowValue>
        </DataRow>
        <DataRow>
          <DataRowLabel>{t("pages.support.miniAppsLogs.drawer.details.requestTime")}</DataRowLabel>
          <DataRowValue>{miniAppsReportLog?.requestTime ? miniAppsReportLog.requestTime : "-"}</DataRowValue>
        </DataRow>
        <DataRow>
          <DataRowLabel>{t("pages.support.miniAppsLogs.drawer.details.syncResponseTime")}</DataRowLabel>
          <DataRowValue>{miniAppsReportLog?.syncResponseTime ? miniAppsReportLog.syncResponseTime : "-"}</DataRowValue>
        </DataRow>
        <DataRow>
          <DataRowLabel>{t("pages.support.miniAppsLogs.drawer.details.syncElapsedTime")}</DataRowLabel>
          <DataRowValue>
            {miniAppsReportLog?.syncResponseTime ? `${getMiniAppsElapsedTime(miniAppsReportLog, "SYNC")}ms` : "-"}
          </DataRowValue>
        </DataRow>
        <RequestContainer>
          <DataRow>
            <DataRowLabel>{t("pages.support.miniAppsLogs.drawer.details.request")}</DataRowLabel>
          </DataRow>
          <JsonRequestContainer>
            {miniAppsReportLog && getHighlightedJSONOrXML(miniAppsReportLog.request)}
          </JsonRequestContainer>
        </RequestContainer>
        <RequestContainer>
          <DataRow>
            <DataRowLabel>{t("pages.support.miniAppsLogs.drawer.details.syncResponse")}</DataRowLabel>
          </DataRow>
          <JsonRequestContainer>
            {miniAppsReportLog && getHighlightedJSONOrXML(miniAppsReportLog.syncResponse)}
          </JsonRequestContainer>
        </RequestContainer>
      </>
    );
  };

  /**
   * Render the trace results logs for the trace results tab
   * @returns
   */
  const renderTraceResultsLogs = () => {
    return (
      <>
        {reportLogs ? (
          <>
            {reportLogs.map((rp, i) => {
              return (
                <DrawerAccordion
                  key={rp.id}
                  header={[
                    {
                      label: t("pages.support.miniAppsLogs.drawer.traceResults.requestTimeServer"),
                      value: rp.requestTime,
                    },
                    {
                      label: t("pages.support.miniAppsLogs.drawer.traceResults.sdkOperation"),
                      value: rp.sdkOperation,
                    },
                  ]}
                >
                  <LogRow>
                    <Label>{t("pages.support.miniAppsLogs.drawer.traceResults.sid")}</Label>
                    <Value>{rp.sid ? rp.sid : "-"}</Value>
                  </LogRow>

                  <LogRow>
                    <Label>{t("pages.support.miniAppsLogs.drawer.traceResults.appResponseTime")}</Label>
                    <Value>{rp.appResponseTime ? rp.appResponseTime : "-"}</Value>
                  </LogRow>
                  {rp.sdkOperation ? (
                    <LogRow>
                      <Label>{t("pages.support.miniAppsLogs.drawer.traceResults.elapsedTime")}</Label>
                      <Value>{elapsedTime(rp.appRequestTime, rp.appResponseTime)}</Value>
                    </LogRow>
                  ) : null}
                  {rp.request ? (
                    <>
                      <DataRow>
                        <DataRowLabel>{t("pages.support.miniAppsLogs.drawer.traceResults.request")}</DataRowLabel>
                      </DataRow>
                      <JsonRequestContainer>{getHighlightedJSONOrXML(rp.request)}</JsonRequestContainer>
                    </>
                  ) : null}
                  {rp.response ? (
                    <>
                      <DataRow>
                        <DataRowLabel>{t("pages.support.miniAppsLogs.drawer.traceResults.response")}</DataRowLabel>
                      </DataRow>
                      <JsonRequestContainer>{getHighlightedJSONOrXML(rp.response)}</JsonRequestContainer>
                    </>
                  ) : null}
                </DrawerAccordion>
              );
            })}
          </>
        ) : null}

        {gatewayLogs ? (
          <>
            {gatewayLogs.map((rp, i) => {
              return (
                <DrawerAccordion
                  key={rp.id}
                  header={[
                    {
                      label: t("pages.support.gatewayLogs.drawer.traceResults.requestTimeServer"),
                      value: rp.requestTime,
                    },
                    {
                      label: t("pages.support.gatewayLogs.drawer.traceResults.gwOperation"),
                      value: rp.gwOperation,
                    },
                  ]}
                >
                  <LogRow>
                    <Label>{t("pages.support.gatewayLogs.drawer.traceResults.identifier")}</Label>
                    <Value>{rp.identifier ? rp.identifier : "-"}</Value>
                  </LogRow>

                  {rp.gwOperation ? (
                    <>
                      <LogRow>
                        <Label>{t("pages.support.gatewayLogs.drawer.traceResults.syncResponseTime")}</Label>
                        <Value>{rp.syncResponseTime ? rp.syncResponseTime : "-"}</Value>
                      </LogRow>
                      <LogRow>
                        <Label>{t("pages.support.gatewayLogs.drawer.traceResults.asyncResponseTime")}</Label>
                        <Value>{rp.asyncResponseTime ? rp.asyncResponseTime : "-"}</Value>
                      </LogRow>
                      {rp.requestTime ? (
                        <LogRow>
                          <Label>{t("pages.support.gatewayLogs.drawer.traceResults.syncElapsedTime")}</Label>
                          <Value>{elapsedTime(rp.requestTime, rp.syncResponseTime)}</Value>
                        </LogRow>
                      ) : null}
                      {rp.requestTime ? (
                        <LogRow>
                          <Label>{t("pages.support.gatewayLogs.drawer.traceResults.asyncElapsedTime")}</Label>
                          <Value>{elapsedTime(rp.requestTime, rp.asyncResponseTime)}</Value>
                        </LogRow>
                      ) : null}
                    </>
                  ) : null}
                  {rp.request ? (
                    <>
                      <DataRow>
                        <DataRowLabel>{t("pages.support.gatewayLogs.drawer.traceResults.request")}</DataRowLabel>
                      </DataRow>
                      <JsonRequestContainer>{getHighlightedJSONOrXML(rp.request)}</JsonRequestContainer>
                    </>
                  ) : null}
                  {rp.syncResponse ? (
                    <>
                      <DataRow>
                        <DataRowLabel>{t("pages.support.gatewayLogs.drawer.traceResults.syncResponse")}</DataRowLabel>
                      </DataRow>
                      <JsonRequestContainer>{getHighlightedJSONOrXML(rp.syncResponse)}</JsonRequestContainer>
                    </>
                  ) : null}
                  {rp.asyncResponse ? (
                    <>
                      <DataRow>
                        <DataRowLabel>{t("pages.support.gatewayLogs.drawer.traceResults.asyncResponse")}</DataRowLabel>
                      </DataRow>
                      <JsonRequestContainer>{getHighlightedJSONOrXML(rp.asyncResponse)}</JsonRequestContainer>
                    </>
                  ) : null}
                </DrawerAccordion>
              );
            })}
          </>
        ) : null}

        {miniAppsReports ? (
          <>
            {miniAppsReports.map((rp, i) => {
              return (
                <DrawerAccordion
                  key={rp.id}
                  header={[
                    {
                      label: t("pages.support.miniAppsLogs.drawer.traceResults.requestTimeServer"),
                      value: rp.requestTime,
                    },
                    {
                      label: t("pages.support.miniAppsLogs.drawer.traceResults.miniAppsOperation"),
                      value: rp.serviceOperation,
                    },
                  ]}
                >
                  <LogRow>
                    <Label>{t("pages.support.miniAppsLogs.drawer.traceResults.identifier")}</Label>
                    <Value>{rp.identifier ? rp.identifier : "-"}</Value>
                  </LogRow>

                  {rp.serviceOperation ? (
                    <>
                      <LogRow>
                        <Label>{t("pages.support.miniAppsLogs.drawer.traceResults.syncResponseTime")}</Label>
                        <Value>{rp.syncResponseTime ? rp.syncResponseTime : "-"}</Value>
                      </LogRow>
                      {rp.requestTime ? (
                        <LogRow>
                          <Label>{t("pages.support.miniAppsLogs.drawer.traceResults.syncElapsedTime")}</Label>
                          <Value>{elapsedTime(rp.requestTime, rp.syncResponseTime)}</Value>
                        </LogRow>
                      ) : null}
                    </>
                  ) : null}
                  {rp.request ? (
                    <>
                      <DataRow>
                        <DataRowLabel>{t("pages.support.miniAppsLogs.drawer.traceResults.request")}</DataRowLabel>
                      </DataRow>
                      <JsonRequestContainer>{getHighlightedJSONOrXML(rp.request)}</JsonRequestContainer>
                    </>
                  ) : null}
                  {rp.syncResponse ? (
                    <>
                      <DataRow>
                        <DataRowLabel>{t("pages.support.miniAppsLogs.drawer.traceResults.syncResponse")}</DataRowLabel>
                      </DataRow>
                      <JsonRequestContainer>{getHighlightedJSONOrXML(rp.syncResponse)}</JsonRequestContainer>
                    </>
                  ) : null}
                </DrawerAccordion>
              );
            })}
          </>
        ) : null}
      </>
    );
  };

  return (
    <>
      <RequestDrawerContainer>
        <RequestDrawerChildrenContainer>
          <CloseIconContainer onClick={hideDrawer}>
            <CancelIcon />
          </CloseIconContainer>
          <RequestDrawerTitle>
            <span>
              {t("pages.support.miniAppsLogs.drawer.title")}
              &nbsp;
            </span>
            {miniAppsReportLog?.requestId}
          </RequestDrawerTitle>
          {!isLoadingReport ? (
            <Tabs
              tabHeaderStyle={{
                paddingLeft: "68px",
                paddingRight: "68px",
              }}
              controlledSelectedTab={selectedTab}
              controlledSetSelectedTab={setSelectedTab}
              isSecondaryTabs={true}
            >
              <TabChild label={t("pages.support.miniAppsLogs.drawer.tabs.details")}>
                <TabContainer>{renderDetails()}</TabContainer>
              </TabChild>
              <TabChild label={t("pages.support.miniAppsLogs.drawer.tabs.traceResults")}>
                <GreyContainer>{renderTraceResults()}</GreyContainer>
                {renderTraceResultsLogs()}
              </TabChild>
            </Tabs>
          ) : (
            <TabContainer>
              <LoadingText>{t("commons.loadingResults")}</LoadingText>
            </TabContainer>
          )}
        </RequestDrawerChildrenContainer>
      </RequestDrawerContainer>
      <RequestDrawerOverlay onClick={hideDrawer} />
    </>
  );
};

export default RequestDrawer;

const TabContainer = styled("div")`
  padding-left: 68px;
  padding-right: 68px;
`;
const GreyContainer = styled("div")`
  padding-left: 68px;
  padding-right: 68px;
  background-color: ${props => props.theme.palette.greyLight};
`;

const RequestDrawerOverlay = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
`;

const RequestDrawerContainer = styled("div")`
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  width: 718px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  height: 100%;
  overflow: auto;
`;

const RequestDrawerChildrenContainer = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 51px 0px 51px 0px;
`;

const CloseIconContainer = styled("div")`
  position: absolute;
  left: 23px;
  margin-top: 3px;
  stroke: ${props => props.theme.palette.midGrey};
  cursor: pointer;
  svg {
    width: 24px;
  }
`;

const RequestDrawerTitle = styled("span")`
  font-family: Vodafone Lt;
  font-size: 24px;
  margin-bottom: 52px;
  padding-left: 68px;
  padding-right: 68px;
  color: ${props => props.theme.palette.midGrey};
  > span {
    color: ${props => props.theme.palette.darkGrey};
  }
`;

const DataRow = styled("div")`
  min-height: 44px;
  border-bottom: 1px solid ${props => props.theme.palette.aluminium};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LogRow = styled("div")`
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
`;

const Label = styled("span")`
  font-family: Vodafone Rg;
  font-size: 14px;
  color: ${props => props.theme.palette.midGrey};
  font-weight: bold;
  margin-bottom: 14px;
`;
const Value = styled("span")`
  font-family: Vodafone Rg;
  font-size: 14px;
  color: ${props => props.theme.palette.black};
`;

const DataRowLabel = styled("div")`
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
  width: 40%;
`;

const DataRowValue = styled("div")`
  font-family: Vodafone Rg;
  font-size: 14px;
  color: ${props => props.theme.palette.darkGrey};
  width: 60%;
`;

const RequestContainer = styled("div")`
  display: flex;
  flex-direction: column;

  ${DataRow} {
    border: none;
  }
`;

const JsonRequestContainer = styled("div")`
  font-family: Vodafone Rg;
  width: 100%;
  border-radius: 6px;
  border: solid 1px ${props => props.theme.palette.aluminium};
  background-color: ${props => props.theme.palette.white};

  padding: 12px 8px;

  code {
    display: inline-block;
    width: 100%;

    * {
      width: 100%;
      font-size: 14px;
    }
  }

  .xml {
    overflow-wrap: break-word;
    white-space: pre-wrap;

    .hljs-tag {
      white-space: normal;
    }

    .hljs-tag,
    .hljs-name {
      color: ${props => props.theme.palette.redViolet};
    }

    .hljs-string {
      color: ${props => props.theme.palette.black};
    }

    .hljs-number {
      color: ${props => props.theme.palette.turquoiseBlue};
    }
  }

  .json {
    .hljs-attr {
      color: ${props => props.theme.palette.redViolet};
    }

    .hljs-string {
      overflow-wrap: break-word;
      white-space: normal;
      color: ${props => props.theme.palette.black};
    }

    .hljs-number {
      color: ${props => props.theme.palette.turquoiseBlue};
    }
  }
`;
