import React from "react";

/** Render upload-csv-file SVG icon */
const UploadCSVFileIcon = () => {
  return (
    <svg width="76px" height="76px" viewBox="0 0 76 76" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Upload CSV file</title>
      <g id="11-Topics" stroke="none" fill="none" fillRule="evenodd">
        <g id="11.00.06-Create-Topic:-Done" transform="translate(-587.000000, -402.000000)">
          <rect fill="#FFFFFF" x="0" y="0" width="1360" height="768"></rect>
          <g id="Cards-/-Service-/-Version-management-/-Resting-Copy" transform="translate(493.000000, 377.000000)">
            <path
              d="M6,0 L483,0 C486.313708,-6.08718376e-16 489,2.6862915 489,6 L489,120 C489,123.313708 486.313708,126 483,126 L6,126 C2.6862915,126 -7.58779353e-15,123.313708 0,120 L0,6 C-4.05812251e-16,2.6862915 2.6862915,6.08718376e-16 6,0 Z"
              id="Rectangle"
              stroke="#CCCCCC"
              strokeDasharray="4"
            ></path>
            <g id="Icon-/-upload-file-Csv" transform="translate(94.000000, 25.000000)">
              <g id="document-ppt-mid-csv">
                <rect id="Rectangle" x="0" y="0" width="76" height="76"></rect>
                <path
                  d="M44.7857143,7.6 L21.827381,7.6 C19.203006,7.6 16.2857143,10.5172917 16.2857143,13.1416667 L16.2857143,63.8083333 C16.2857143,66.8689113 18.766803,69.35 21.827381,69.35 L53.4940476,69.35 C56.5546256,69.35 59.0357143,66.8689113 59.0357143,63.8083333 L59.0357143,21.85 L44.7857143,7.6 Z M44.7857143,7.6 L44.7857143,20.2666667 C44.7857143,21.1411175 45.4945968,21.85 46.3690476,21.85 L59.0357143,21.85"
                  id="Path-2"
                  stroke="#A1A1A1"
                  strokeWidth="1.26666667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </g>
              <text
                id="CSV"
                fontFamily="VodafoneRg-Regular, Vodafone Rg"
                fontSize="21.5333333"
                fontWeight="normal"
                letterSpacing="0.662564214"
                fill="#4EBF4F"
              >
                <tspan x="20.6240203" y="48.4736842">
                  CSV
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default UploadCSVFileIcon;
