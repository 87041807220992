import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IPasswordPolicies } from "../passwords-utils";

interface IPasswordPoliciesDialogProps {
  policies: IPasswordPolicies;
  topMargin?: string;
}

const PasswordPoliciesDialog = ({ policies, topMargin }: IPasswordPoliciesDialogProps) => {
  const [t] = useTranslation();
  return (
    <DialogContainer top={topMargin}>
      <RulesContainer>
        {policies.minLength ? (
          <PolicyText>- {t("commons.passwordPolicies.minLength", { n: policies.minLength })}</PolicyText>
        ) : null}

        {policies.restrictMinDigits ? (
          <PolicyText>- {t("commons.passwordPolicies.minDigits", { n: policies.minDigits })}</PolicyText>
        ) : null}

        {policies.restrictMinUpperCaseLetters ? (
          <PolicyText>
            -{" "}
            {t("commons.passwordPolicies.minUpperCaseLetters", {
              n: policies.minUpperCaseLetters,
            })}
          </PolicyText>
        ) : null}

        {policies.restrictMinLowerCaseLetters ? (
          <PolicyText>
            -{" "}
            {t("commons.passwordPolicies.minLowerCaseLetters", {
              n: policies.minLowerCaseLetters,
            })}
          </PolicyText>
        ) : null}

        {policies.restrictMinNonAlphanumericCharacters ? (
          <PolicyText>
            -{" "}
            {t("commons.passwordPolicies.minNonAlphanumericCharacters", {
              n: policies.minNonAlphanumericCharacters,
            })}
          </PolicyText>
        ) : null}

        {policies.restrictSuccessiveIdenticalChars ? (
          <PolicyText>
            -{" "}
            {t("commons.passwordPolicies.minSuccessiveIdenticalChars", {
              n: policies.minSuccessiveIdenticalChars,
            })}
          </PolicyText>
        ) : null}

        <PolicyText>- {t("commons.passwordPolicies.email")}</PolicyText>
      </RulesContainer>
    </DialogContainer>
  );
};

export default PasswordPoliciesDialog;

const RulesContainer = styled("div")``;
const DialogContainer = styled("div")<{ top?: string }>`
  padding: 18px 15px;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  background-color: #212121;
  position: absolute;
  width: 300px;
  top: ${props => (props.top ? props.top : "105px")};
  left: 100%;
  z-index: 9999;
  margin-left: 10px;
  &:after {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-right-color: #212121;
    border-width: 10px;
    margin-top: -10px;
  }
`;

const PolicyText = styled("p")`
  font-family: Vodafone Rg;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: white;
`;
