import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { IServiceManagerService } from "../../../shared/models/service-manager.model";
import ServicePromotedItem from "./service-promoted-item.component";
import styled from "styled-components";
import StarRating from "react-svg-star-rating";
import ServiceManagerApi from "../service-manager.api";
import { useDispatch } from "react-redux";
import { ServiceManagerActions } from "../service-manager.store";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { useTranslation } from "react-i18next";
import { useAlert } from "@wit/mpesa-ui-components";
import { ServiceType } from "../service-details/pages/details.interfaces";
import { UserType } from "./section-manager.interface";
import { FEATURES } from "../../../shared/renderer.utils";
import useCheckFeatureAvailable from "../../../shared/hooks/use-check-available-feature";

interface IListItemsProps {
  groupName: string;
  items: IServiceManagerService[];
  serviceType: ServiceType;
  userType?: UserType;
}

/** horizontal List */
const HorizontalListComponent = ({ items, groupName, serviceType, userType }: IListItemsProps) => {
  const serviceUserTypeEnabled = useCheckFeatureAvailable(FEATURES.SERVICE_USER_TYPE);

  /** function to reorder */
  const reorder = (list: ArrayLike<IServiceManagerService>, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /** function DragEnd */
  const onDragEnd = (result: any) => {
    const { source, destination } = result;
    let toPosition: number;

    if (!destination) {
      return;
    }

    items.forEach(element => {
      if (element.id === result.draggableId) {
        element.groups.forEach(group => {
          if (
            group.name === groupName &&
            group.serviceType === serviceType &&
            group.order &&
            (!serviceUserTypeEnabled || group.userType === userType)
          ) {
            toPosition = group.order;
            editServicePromotedOrder(result.draggableId, toPosition, result.destination.index, group.serviceType);
          }
        });
      }
    });

    const itemsList = reorder(items, source.index, destination.index);
    items = [...itemsList];
    return items;
  };

  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [showAlert, hideAlert, setAlertProps] = useAlert();

  /** function edit ServicePromoter */
  const editServicePromotedOrder = (
    identifier: string,
    toPosition: number,
    promotedPosition: number,
    serviceType: ServiceType,
  ) => {
    ServiceManagerApi.methods
      .updateGroupOrder(
        identifier,
        groupName,
        toPosition,
        serviceType,
        serviceUserTypeEnabled ? userType : undefined,
        promotedPosition + 1,
      )
      .then(() => {
        dispatch(ServiceManagerActions.creators.updateSectionManagerPromotedOrderList());
        ServiceManagerActions.creators.fetchingServicesAction();
        ServiceManagerApi.methods.getServices().then(
          res => {
            dispatch(ServiceManagerActions.creators.fetchServicesSuccessAction(res.data));
          },
          () => {
            setAlertProps({
              title: t("pages.serviceBuilder.errors.searchServices"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          },
        );
      });
  };

  function renderEmptyPromotedSpaces(promoted: IServiceManagerService[]) {
    if (promoted.length === 0) {
      return (
        <>
          <EmptyServiceItem>
            <StarRating activeColor={"#2fc56d"} count={1} innerRadius={20} size={40} isReadOnly={true} />
            <StarText>{t("pages.serviceManager.horizontalComponent.selectList")}</StarText>
          </EmptyServiceItem>
          <EmptyServiceItem>
            <StarRating activeColor={"#2fc56d"} count={1} innerRadius={20} size={40} isReadOnly={true} />
            <StarText>{t("pages.serviceManager.horizontalComponent.selectList")}</StarText>
          </EmptyServiceItem>
          <EmptyServiceItem>
            <StarRating activeColor={"#2fc56d"} count={1} innerRadius={20} size={40} isReadOnly={true} />
            <StarText>{t("pages.serviceManager.horizontalComponent.selectList")}</StarText>
          </EmptyServiceItem>
        </>
      );
    } else if (promoted.length === 1) {
      return (
        <>
          <EmptyServiceItem>
            <StarRating activeColor={"#2fc56d"} count={1} innerRadius={20} size={40} isReadOnly={true} />
            <StarText>{t("pages.serviceManager.horizontalComponent.selectList")}</StarText>
          </EmptyServiceItem>
          <EmptyServiceItem>
            <StarRating activeColor={"#2fc56d"} count={1} innerRadius={20} size={40} isReadOnly={true} />
            <StarText>{t("pages.serviceManager.horizontalComponent.selectList")}</StarText>
          </EmptyServiceItem>
        </>
      );
    } else if (promoted.length === 2) {
      return (
        <EmptyServiceItem>
          <StarRating activeColor={"#2fc56d"} count={1} innerRadius={20} size={40} isReadOnly={true} />
          <StarText>{t("pages.serviceManager.horizontalComponent.selectList")}</StarText>
        </EmptyServiceItem>
      );
    } else {
      return null;
    }
  }

  return (
    <DragDropContext onDragEnd={result => onDragEnd(result)}>
      <Droppable droppableId="droppable-horizontal" direction="horizontal">
        {(provided, snapshot) => (
          <ItemsContainer {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((service, index) => (
              <Draggable key={service.id} draggableId={service.id} index={index}>
                {(providedDrag, snapshotDrag) => (
                  <div
                    ref={providedDrag.innerRef}
                    {...providedDrag.draggableProps}
                    {...providedDrag.dragHandleProps}
                    style={{ flex: 1, ...providedDrag.draggableProps.style }}
                  >
                    <ServicePromotedItem
                      key={service.id}
                      item={service}
                      groupName={groupName}
                      userType={userType}
                      dragprops={snapshotDrag}
                      serviceType={serviceType}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            {renderEmptyPromotedSpaces(items)}
          </ItemsContainer>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default HorizontalListComponent;

const ItemsContainer = styled("div")`
  width: 100%;
  display: flex;
  gap: 40px;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const EmptyServiceItem = styled("div")`
  flex: 1;
  height: 258px;
  border-radius: 8px;
  border: dashed 1px #2fc56d;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StarText = styled("span")`
  width: 251px;
  height: 36px;
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #aaaaaa;
  margin-top: 16px;
`;
