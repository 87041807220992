import React, { SyntheticEvent } from "react";
interface IFullScreenImageModalProps {
  photoUrl: string;
}

const FullScreenImageModal = ({ photoUrl }: IFullScreenImageModalProps) => {
  const [imgWidth, setImgWidth] = React.useState(0);

  function onLoadImg(evt: SyntheticEvent) {
    let newImgWidth;
    const imgFactor = 0.9;
    let docWidth = 0;
    let docHeight = 0;

    if (document.documentElement) {
      docWidth = document.documentElement.clientWidth;
      docHeight = document.documentElement.clientHeight;
    }

    const vw = Math.max(docWidth, window.innerWidth || 0);
    const vh = Math.max(docHeight, window.innerHeight || 0);
    const imgElem: HTMLImageElement = evt.target as HTMLImageElement;

    if (imgElem.naturalWidth > imgElem.naturalHeight) {
      newImgWidth = Math.min(imgElem.naturalWidth, vw * imgFactor);
    } else {
      const imgRatio = imgElem.naturalWidth / imgElem.naturalHeight;
      newImgWidth = Math.min(imgElem.naturalHeight, vh * imgFactor * imgRatio);
    }

    setImgWidth(newImgWidth);
  }

  return (
    <>
      <img src={photoUrl} onLoad={onLoadImg} width={imgWidth} data-zoom />
    </>
  );
};

export default FullScreenImageModal;
