import React, { ReactElement, useEffect, useMemo, HTMLProps, RefObject, useState } from "react";
import lottie, { AnimationConfigWithData, AnimationItem } from "lottie-web";
import * as S from "./safaricom-delete-button.styled";

interface IDeletePrimaryButtonProps extends HTMLProps<HTMLButtonElement> {
  titleLabel: string;
  rightComponent?: ReactElement;
  disabled?: boolean;
  redTheme?: boolean;
  type?: "button" | "reset" | "submit";
  ref?: RefObject<HTMLButtonElement>;
  loading?: boolean;
  containerClassName?: string;
  labelClassName?: string;
}

const beginLoadingAnimation = (loaderId: string): AnimationConfigWithData => ({
  container: document.getElementById(loaderId) as HTMLElement, // the dom element
  renderer: "svg",
  loop: false,
  autoplay: true,
  animationData: require("./full-loading.json"), // the animation data
  rendererSettings: {
    clearCanvas: false,
    progressiveLoad: false, // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.
    hideOnTransparent: true, // Boolean, only svg renderer, hides elements when opacity reaches 0 (defaults to true)
  },
});

const generateLoaderId = (() => {
  let count = 0;

  return () => `primary-button-loading-${++count}`;
})();

const DeletePrimaryButton = ({
  titleLabel,
  rightComponent,
  disabled,
  redTheme,
  loading,
  type,
  ref,
  as,
  containerClassName,
  labelClassName,
  onClick,
  ...rest
}: IDeletePrimaryButtonProps) => {
  const safeOnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!loading && onClick) {
      onClick(event);
    }
  };

  const [hasLoaded, setHasLoaded] = useState(!!loading);

  // get loaderId for
  const loaderId = useMemo(generateLoaderId, []);

  useEffect(() => {
    if (loading && !hasLoaded) {
      setHasLoaded(true);
    }
  }, [loading, setHasLoaded, hasLoaded]);

  // manage animation state
  useEffect(() => {
    let animation: AnimationItem;
    let timeout: any = null;

    // If we are now loading place the initial animation and when that ends place the middle on loop
    // If we are not loading place the end animation
    // Since our animation begins with a small dot, we need to animate resizing the button first
    if (loading) {
      timeout = setTimeout(() => {
        animation = lottie.loadAnimation(beginLoadingAnimation(loaderId));
        animation.addEventListener("enterFrame", frame => {
          if (Math.floor(Math.abs(frame.currentTime)) === 89 && loading) {
            animation.goToAndPlay(11, true);
          }
        });
      }, S.primaryButtonStyleConstants.animation);
    }

    // When component is unmounted, destroy any animation and remove any existing timeouts
    return () => {
      if (animation) {
        animation.removeEventListener("enterFrame", () => {});
        animation.destroy();
      }
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [loading, loaderId, setHasLoaded]);

  return (
    <>
      <S.AnimationContainer id={loaderId} />
      <S.ActionMenuButton
        className={containerClassName}
        disabled={disabled}
        redTheme={redTheme}
        onClick={safeOnClick}
        centered={!rightComponent}
        loading={loading}
        type={type}
        ref={ref}
        {...rest}
        hasLoaded={hasLoaded}
      >
        {!loading ? (
          <>
            <S.ActionMenuTitle
              disabled={disabled}
              redTheme={redTheme}
              centered={!rightComponent}
              className={labelClassName}
            >
              {titleLabel}
            </S.ActionMenuTitle>
            {rightComponent}
          </>
        ) : null}
      </S.ActionMenuButton>
    </>
  );
};

export default DeletePrimaryButton;
