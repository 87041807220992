import React, { useEffect, useState } from "react";
import { TextInput, Dropdown, TextArea } from "@wit/mpesa-ui-components";
import { DropdownType } from "@wit/mpesa-ui-components/lib/components/dropdown/dropdown.component";
import { Formik } from "formik";
import styled from "styled-components";
import { AnnouncementFormSteps, AudienceType, IAnnouncement, IIcon } from "../announcements.model";
import {
  DropdownLabelContainer,
  HomeMainContent,
  ColorContainer,
  IconDropDownContainer,
} from "../announcements.styled";
import MobileSchema from "./mobile-schema-component";
import { useTranslation } from "react-i18next";
import ColorOptions from "./color-options.component";
import IconOptions from "./icon-options.component";
import { object, string } from "yup";
import i18next from "i18next";
import HomeScreenAnnouncementBusiness from "./home-screen-announcement-business.component";
import { Row } from "../../../shared/shared.styled";
import { AnnouncementsUtils } from "../announcements.utils";

interface IAnnouncementsStepHomescreenCardBusinessProps {
  announcement: IAnnouncement;
  setAnnoucement: React.Dispatch<React.SetStateAction<IAnnouncement>>;
  isEditing: boolean;
  onSubmitForm: (values?: IAnnouncement) => void;
  onReady: React.Dispatch<React.SetStateAction<boolean>>;
  hideTitle?: boolean;
  setEditingHomescreenCard?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedAnnouncementSavedData?: IAnnouncement;
}

/**
 * function to validate the form
 */
const validateStepTwo = () => {
  return object().shape({
    title: string()
      .required(i18next.t("commons.mandatoryField"))
      .max(60, i18next.t("pages.announcements.errors.maxTitleHome")),
    smallDescription: string()
      .required(i18next.t("commons.mandatoryField"))
      .max(24, i18next.t("pages.announcements.errors.maxDescriptionHome")),
    color: string()
      .required(i18next.t("commons.mandatoryField"))
      .matches(/^#([0-9A-F]{3}){1,2}$/i, i18next.t("commons.invalidColor")),
    icon: string().required(i18next.t("commons.mandatoryField")),
  });
};

/**
 * component for the second step of the announcment
 */
const AnnouncementsStepHomescreenCardBusiness = ({
  announcement,
  setAnnoucement,
  isEditing,
  onSubmitForm,
  onReady,
  hideTitle = false,
  setEditingHomescreenCard,
  selectedAnnouncementSavedData,
}: IAnnouncementsStepHomescreenCardBusinessProps) => {
  const [t] = useTranslation();
  const colorDefault = "#2fc56d";
  const [tabDropColor, setTabDropColor] = React.useState(0);
  const [open, setOpen] = useState(false);
  const iconAnnouncement: IIcon =
    AnnouncementsUtils.getIconSelected(announcement.icon).category === null
      ? AnnouncementsUtils.getIconSelected(undefined)
      : AnnouncementsUtils.getIconSelected(announcement.icon);
  const [iconSelected, setIconSelected] = React.useState<IIcon>(iconAnnouncement);

  /**
   * function that will be call for onSubmit form
   * @param values values of the announcemt
   */
  const onSubmitStepTwo = (values: IAnnouncement) => {
    setAnnoucement(values);
    onSubmitForm(values);
  };

  /**
   * function that will be call for onChange form
   * @param values values of announcement
   */
  const onChangeValues = (values: IAnnouncement) => {
    setAnnoucement(values);
    onReady(!!values.title && !!values.smallDescription && !!values.icon);
  };

  useEffect(() => {
    onChangeValues(announcement);
    if (!isEditing) {
      (document.getElementById(AnnouncementFormSteps.STEP_THREE) as HTMLFormElement).reset();
    }
  }, [announcement]);

  return (
    <Formik
      initialValues={announcement.color ? announcement : { ...announcement, color: colorDefault }}
      onSubmit={onSubmitStepTwo}
      validationSchema={validateStepTwo}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, handleChange, isSubmitting, setFieldValue, handleSubmit, resetForm, errors, setValues }) => (
        <HomeMainContent>
          <HomeScreenMain>
            <HomeScreenTitle hidden={hideTitle}>{`${
              AnnouncementsUtils.isConsumerAudience(values.audience as AudienceType)
                ? t("pages.announcements.detailPage.rows.consumer")
                : t("pages.announcements.detailPage.rows.business")
            } ${t("pages.announcements.detailPage.rows.secondTitle")}`}</HomeScreenTitle>
            <SectionForm
              id={AnnouncementFormSteps.STEP_THREE}
              onSubmit={handleSubmit}
              onChange={() => {
                onChangeValues(values);
              }}
              onReset={() => {
                if (announcement.icon && announcement.title && announcement.smallDescription) {
                  if (selectedAnnouncementSavedData) {
                    setValues(selectedAnnouncementSavedData);
                    setAnnoucement(selectedAnnouncementSavedData);
                  } else {
                    resetForm(announcement);
                  }
                } else if (setEditingHomescreenCard) {
                  setEditingHomescreenCard(true);
                }
              }}
            >
              <TextAreaSection removeMargin={hideTitle}>
                {!isEditing ? (
                  <>
                    <DefineTitle required={false}>{t("pages.announcements.detailPage.rows.title")}</DefineTitle>
                    <NotEditingText>{announcement.title}</NotEditingText>
                  </>
                ) : (
                  <TextArea
                    value={values.title ? values.title : ""}
                    //@ts-ignore
                    title={<DefineTextArea>{t("pages.announcements.detailPage.rows.title")}</DefineTextArea>}
                    placeholder={t("pages.announcements.detailPage.rows.placeholderHomeTitle")}
                    onChange={e => setFieldValue("title", e.target.value)}
                    error={errors.title ? errors.title : undefined}
                    style={{ color: "#333333" }}
                    maxLength={60}
                  />
                )}
              </TextAreaSection>
              <TextInputSection>
                {!isEditing ? (
                  <>
                    <DefineTitle required={false}>
                      {t("pages.announcements.detailPage.rows.smallDescription")}
                    </DefineTitle>
                    <NotEditingText>{announcement.smallDescription}</NotEditingText>
                  </>
                ) : (
                  <TextInput
                    value={values.smallDescription ? values.smallDescription : ""}
                    required={true}
                    title={t("pages.announcements.detailPage.rows.smallDescription")}
                    placeholder={t("pages.announcements.detailPage.rows.placeholderHomeDescription")}
                    onChange={e => setFieldValue("smallDescription", e.target.value)}
                    error={errors.smallDescription ? errors.smallDescription : undefined}
                    maxLength={24}
                  />
                )}
              </TextInputSection>
              <SectionFormRow>
                <ColorSection>
                  <DefineTitle required={isEditing}>{t("pages.announcements.detailPage.rows.color")}</DefineTitle>
                  {!isEditing ? (
                    <Row>
                      <BigBadgeColor color={announcement.color ? announcement.color : colorDefault} />
                      <NotEditingText>{announcement.color ? announcement.color : colorDefault}</NotEditingText>
                    </Row>
                  ) : (
                    <Dropdown
                      hasValue={false}
                      label={
                        <DropdownLabelContainer>
                          <ColorContainer backgroundColor={values.color ? values.color : colorDefault} />{" "}
                          <SpanInput>{values.color ? values.color : colorDefault}</SpanInput>
                        </DropdownLabelContainer>
                      }
                      dropdownType={DropdownType.RECTANGULAR_NORMAL}
                      isOpen={open}
                      controlledToggleOpen={setOpen}
                      error={errors.color}
                    >
                      <ColorOptions
                        tab={tabDropColor}
                        setTab={setTabDropColor}
                        color={values.color !== undefined ? values.color : colorDefault}
                        setColor={setFieldValue}
                        textToSave={"color"}
                        show={open}
                        setShow={setOpen}
                      />
                    </Dropdown>
                  )}
                </ColorSection>
                <IconSection>
                  <DefineTitle required={isEditing}>{t("pages.announcements.detailPage.rows.icon")}</DefineTitle>
                  {!isEditing ? (
                    <Row>
                      <IconBadge logo={iconAnnouncement.base64} />
                      <NotEditingText>{iconAnnouncement.label}</NotEditingText>
                    </Row>
                  ) : (
                    <IconOptionsContainer>
                      <Dropdown
                        hasValue={false}
                        label={
                          <DropdownLabelContainer>
                            <IconDropDownContainer logo={iconSelected.base64} />
                            <SpanInput>{iconSelected.label}</SpanInput>
                          </DropdownLabelContainer>
                        }
                        dropdownType={DropdownType.RECTANGULAR_NORMAL}
                        error={errors.icon}
                      >
                        <IconOptions
                          iconID={values.icon ? values.icon : ""}
                          setIconID={(textToSave, icon) => {
                            setFieldValue(textToSave, icon);
                            onChangeValues({ ...values, icon });
                          }}
                          setIcon={setIconSelected}
                          textToSave={"icon"}
                        />
                      </Dropdown>
                    </IconOptionsContainer>
                  )}
                </IconSection>
              </SectionFormRow>
            </SectionForm>
          </HomeScreenMain>
          <MainSideBarColumn>
            {AnnouncementsUtils.isConsumerAudience(values.audience as AudienceType) ? (
              <AudienceContainer>{t("pages.announcements.detailPage.rows.consumerApp")}</AudienceContainer>
            ) : (
              <AudienceContainer>{t("pages.announcements.detailPage.rows.businessApp")}</AudienceContainer>
            )}
            <div>
              <MobileSchema appPlacement={values.appPlacement}>
                <HomeScreenAnnouncementBusiness valuesAnnouncement={values} icon={iconSelected} />
              </MobileSchema>
            </div>
          </MainSideBarColumn>
        </HomeMainContent>
      )}
    </Formik>
  );
};

const HomeScreenMain = styled("div")`
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const HomeScreenTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme.palette.darkGrey};
`;

const MainSideBarColumn = styled("div")`
  display: flex;
  flex-direction: column;
  width: 40%;
  > div {
    width: 300px;
    margin: auto;
    > div > div {
      margin-left: 4.5%;
      margin-right: 4.5%;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SectionForm = styled("form")`
  margin-right: 20%;
`;

const SectionFormRow = styled("div")`
  margin-top: 3vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ColorSection = styled("div")`
  flex: 0.35;
  > button > div:last-child > div > div > div:first-child > div {
    margin-left: -70px;
  }
`;

const IconSection = styled("div")`
  flex: 0.6;
`;

const TextAreaSection = styled("div")<{ removeMargin: boolean }>`
  margin-top: ${props => (props.removeMargin ? "0" : "3vh")};
  font-family: Vodafone RgBd;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: ${props => props.theme.palette.midGrey};
  position: relative;
  > div > span > span:last-child {
    color: #ff2a58;
    margin-left: 0.2vw;
    font-weight: normal;
  }
`;

const TextInputSection = styled("div")`
  position: relative;
  margin-top: 3vh;
  font-family: Vodafone RgBd;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: ${props => props.theme.palette.midGrey};

  input {
    ::placeholder {
      color: #999999;
    }
  }
`;

const DefineTitle = styled("div")<{ required: boolean }>`
  min-height: 21px;
  font-family: Vodafone Rg;
  font-weight: bold;
  line-height: 21px;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
  margin-bottom: 8px;
  ${props => (props.required ? `::after {content: " *";color: #ff0000;font-weight: 400;padding-left: 2px;}` : null)};
`;

const DefineTextArea = styled("span")`
  min-height: 21px;
  font-family: Vodafone Rg;
  font-weight: bold;
  line-height: 21px;
  font-size: 16px;
  color: #999999;
  margin-bottom: 8px;
  ::after {
    content: " *";
    color: #ff0000;
    font-weight: 400;
    padding-left: 2px;
  }
`;

const SpanInput = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: ${props => props.theme.palette.midGrey};
`;

const NotEditingText = styled("div")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: ${props => props.theme.palette.darkGrey};
`;

const BigBadgeColor = styled("div")<{ color: string }>`
  width: 38px;
  height: 34px;
  border-radius: 5px;
  background-color: ${props => props.color};
  margin-right: 0.5vw;
`;

const IconBadge = styled("div")<{
  logo: string;
}>`
  background-color: #fff;
  background-image: ${props => (props.logo ? `url("${props.logo}")` : "none")};
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: center;
  width: 38px;
  height: 34px;
`;

const AudienceContainer = styled("div")`
  height: 27px;
  background-color: #2fc56d;
  border-radius: 20px;
  padding: 4px 30px;
  cursor: pointer;
  width: fit-content !important;
  color: white;
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  text-align: center;
  margin-bottom: 3vh !important;
`;

const IconOptionsContainer = styled("div")`
  width: 100%;
  > button > div {
    width: 100%;
  }
`;
export default AnnouncementsStepHomescreenCardBusiness;
