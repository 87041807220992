import React from "react";
import styled from "styled-components";
import TablePagination from "../../../../shared/components/table-pagination/table-pagination-component";
import { isFeedbackTabActive, isProblemReportsTabActive } from "../feedback-and-problems.utils";
import { IResultsPaginationComponentProps } from "../feedback-and-problems.model";

const ResultsPaginationComponent = ({
  sectionId,
  currentActivePage,
  numberOfRowsPerPage,
  onPageChange,
  feedbacks,
  problemReports,
  customerSurvey,
}: IResultsPaginationComponentProps) => {
  const feedbackCount = feedbacks.count ?? 0;
  const problemCount = problemReports.count ?? 0;
  const customerCount = customerSurvey.count ?? 0;

  return (
    <Footer>
      <TablePagination
        handlePageChange={onPageChange}
        totalItems={
          isFeedbackTabActive(sectionId)
            ? feedbackCount
            : isProblemReportsTabActive(sectionId)
            ? problemCount
            : customerCount
        }
        activePage={currentActivePage}
        pageSize={numberOfRowsPerPage}
      />
    </Footer>
  );
};

export default ResultsPaginationComponent;

const Footer = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 55px;
  margin: 0 0 10px 0;
  padding: 0 0 0 0 !important;
  & ul {
    height: 35.2px;
  }
`;
