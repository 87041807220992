import styled from "styled-components";
import { RatioContainer, Row } from "./shared.styled";

/** Shared containers **/
export const WrappedRow = styled(Row)`
  flex-wrap: wrap;
`;

/** Filters **/
export const SearchBarFilterContainer = styled.div`
  flex: calc(4 / 12);
  margin-right: 24px;
  @media (max-width: 1024px) {
    margin-right: 0px;
    flex: 100%;
  }
`;

export const OtherFiltersContainer = styled.div`
  flex: calc(2 / 12);
  margin-right: 24px;
  @media (max-width: 1024px) {
    margin-right: 0px;
    flex: 50%;
    gap: 16px;
  }
`;

export const SmallButtonsContainer = styled("div")`
  margin-left: auto;
  display: flex;
  button:first-child {
    margin-right: 20px;
  }
  @media (max-width: 1024px) {
    padding-left: 24px;
    padding-top: 16px;
  }
`;

/** List headers **/
export const ListHeaders = styled(Row)`
  margin-bottom: 15px;
  padding-left: 25px;
  padding-right: 35px;
`;

export const ListHeader = styled(RatioContainer)`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
`;

/** List items **/
export const ItemName = styled("span")`
  padding-right: 8px;
  @media (max-width: 900px) {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 4px;
  }
  @media (max-width: 768px) {
    max-width: 70px;
  }
  @media (max-width: 600px) {
    max-width: 50px;
  }
`;

/** Page of create and edit forms **/
export const PageContent = styled("div")`
  display: flex;
  flex-direction: row;
  margin-top: 106px;
  margin-left: 203px;
  margin-right: 101px;

  form,
  .main-content {
    flex-grow: 1;
    width: 100% !important;
    &.reverse {
      flex-direction: column-reverse;
    }
  }

  &.no-reverse {
    flex-direction: column;
  }

  &.display-column {
    flex-direction: column !important;
  }

  .side-menu {
    @media (max-width: 1024px) {
      margin-top: 0;
      margin-left: auto;
      align-items: flex-end;
      width: 100% !important;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    margin-left: 44px;
    margin-right: 44px;

    // Discover card hack
    .main-form {
      flex-direction: column;
      .side-menu {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        .buttons-container {
          margin-top: 48px;
          padding-bottom: 48px;
        }
      }
    }
  }
`;

/** Version control containers **/
export const VersionControlButtonsContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const VersionControlButtonsRow = styled("div")`
  margin-left: auto;
  align-self: flex-end;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;
