import { AxiosPromise } from "axios";
import ApiClient from "../../configs/axios.service-builder.config";
import SBApiClientBO from "../../configs/axios.config";
import {
  IServiceDetails,
  IServiceVersion,
  IServiceUpdateDetails,
  IMSISDNValidationResults,
  VersionLanguagesEnum,
} from "../../shared/models/service-builder.model";
import { IFileLanguageMapping } from "./service-details-version-management/service-details-version-management.component";

const ServiceDetailsApi = {
  routes: {
    serviceDetails: "/service/:serviceId",
    serviceVersions: "/service/:serviceId/versions",
    deleteService: "/v1/services/:serviceId/submitservicefordelete?completeRequest=:completeRequest",
    disableService: "/v1/services/:serviceId/submitservicefordisable?completeRequest=:completeRequest",
    updateService: "/v1/services/:serviceId",
    addServiceVersion: "/serviceversion",
    updateServiceVersion: "/serviceversion/:versionId",
    deleteServiceVersion: "/serviceversion/:versionId",
    deleteServiceVersionWithApproval: "/v1/services/:serviceId/submitversionfordelete?completeRequest=:completeRequest",
    msisdnCsvValidation: "/serviceversion/uploadcsv",
    msisdnTextValidation: "/serviceversion/validatemsisdnlist",
    updateServiceForApproval: "/v1/services/:serviceId/submitserviceforapproval",
    updateVersionForApproval: "/v1/services/:versionId/submitversionforapproval",
    getCanBeHighlighted: "/v1/ipg_services/canbehighlighted",
  },
  methods: {
    getServiceDetails: (serviceId: string): AxiosPromise<IServiceDetails> => {
      return ApiClient.get(ServiceDetailsApi.routes.serviceDetails.replace(":serviceId", serviceId));
    },
    getServiceVersions: (serviceId: string): AxiosPromise<IServiceVersion[]> => {
      return ApiClient.get(ServiceDetailsApi.routes.serviceVersions.replace(":serviceId", serviceId));
    },
    deleteService: (serviceId: string, completeRequest: boolean): AxiosPromise<void> => {
      return SBApiClientBO.delete(
        ServiceDetailsApi.routes.deleteService
          .replace(":serviceId", serviceId)
          .replace(":completeRequest", JSON.stringify(completeRequest)),
      );
    },
    editService: (
      imageService: string | undefined,
      iconService: string | undefined,
      serviceId: string,
      serviceDetails: IServiceUpdateDetails,
      isLive: boolean,
    ): AxiosPromise<void> => {
      const data = new FormData();
      if (imageService) {
        const image = new Blob([imageService], { type: "text/plain;charset=utf-8" });
        data.append("imageService", image);
      }
      if (iconService) {
        const icon = new Blob([iconService], { type: "text/plain;charset=utf-8" });
        data.append("iconService", icon);
      }
      data.append("metadata", JSON.stringify(serviceDetails));
      data.append("completeRequest", JSON.stringify(isLive));

      return SBApiClientBO.patch(ServiceDetailsApi.routes.updateService.replace(":serviceId", serviceId), data);
    },
    addServiceVersion: (
      files: IFileLanguageMapping[],
      versionDetails: IServiceVersion,
    ): AxiosPromise<IServiceVersion> => {
      const data = new FormData();

      const filesToSend: File[] = [];
      const languageMapping: { [key: string]: string } = {};

      if (files.length > 0) {
        files.map((element: IFileLanguageMapping) => {
          const fileAux = element.file as File;
          const language = element.language as VersionLanguagesEnum;
          filesToSend.push(fileAux);
          languageMapping[fileAux.name] = language;
        });
      }
      versionDetails.fileLanguageMapping = languageMapping;

      filesToSend.map((f: File) => {
        data.append("files", f as File);
      });
      data.append("metadata", JSON.stringify(versionDetails));
      return ApiClient.post(ServiceDetailsApi.routes.addServiceVersion, data);
    },
    updateServiceVersion: (versionId: string, versionDetails: IServiceVersion): AxiosPromise<IServiceVersion> => {
      return ApiClient.patch(
        ServiceDetailsApi.routes.updateServiceVersion.replace(":versionId", versionId),
        versionDetails,
      );
    },
    deleteServiceVersion: (versionId: string, completeRequest: boolean): AxiosPromise<IServiceVersion> => {
      return SBApiClientBO.delete(
        ServiceDetailsApi.routes.deleteServiceVersionWithApproval
          .replace(":serviceId", versionId)
          .replace(":completeRequest", JSON.stringify(completeRequest)),
      );
    },
    disableService: (serviceId: string, completeRequest: boolean): AxiosPromise<void> => {
      return SBApiClientBO.put(
        ServiceDetailsApi.routes.disableService
          .replace(":serviceId", serviceId)
          .replace(":completeRequest", JSON.stringify(completeRequest)),
      );
    },
    msisdnCsvValidation: (file: File): AxiosPromise<IMSISDNValidationResults> => {
      const data = new FormData();
      data.append("csvFile", file);
      return ApiClient.post(ServiceDetailsApi.routes.msisdnCsvValidation, data);
    },
    msisdnTextValidation: (text: String[]): AxiosPromise<IMSISDNValidationResults> => {
      return ApiClient.post(ServiceDetailsApi.routes.msisdnTextValidation, text);
    },
    setUpdateForAproval: (serviceId: string) => {
      return SBApiClientBO.put(ServiceDetailsApi.routes.updateServiceForApproval.replace(":serviceId", serviceId));
    },
    setVersionUpdateForApproval: (versionId: string, data: any) => {
      return SBApiClientBO.put(
        ServiceDetailsApi.routes.updateVersionForApproval.replace(":versionId", versionId),
        data,
      );
    },
    getCanBeHighlighted: (): AxiosPromise<{ canBeHighlighted: boolean }> => {
      return SBApiClientBO.get(ServiceDetailsApi.routes.getCanBeHighlighted);
    },
  },
};

export default ServiceDetailsApi;
