import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { TextInput, PrimaryButton, Checkbox } from "@wit/mpesa-ui-components";
import { Formik, FormikHelpers } from "formik";
import { useDispatch } from "react-redux";
import { object, string } from "yup";
import { IEnvProperty } from "../../../../shared/models/env-property.model";
import SupportApi from "../../support.api";
import { SupportActions } from "../../support.store";
import { Row } from "../../../../shared/shared.styled";
import i18next from "i18next";

interface IEditPropertyModalProps {
  property: IEnvProperty;
  hideModal: () => void;
}

const getValidationSchema = () => {
  return object().shape({
    propertyName: string().required(i18next.t("pages.support.envProperties.newProperty.error")),
    propertyValue: string().required(i18next.t("pages.support.envProperties.newProperty.error")),
  });
};

const EditPropertyModal = ({ property, hideModal }: IEditPropertyModalProps) => {
  // Hooks initializations
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const _persistProperty = (values: IEnvProperty, actions: FormikHelpers<IEnvProperty>) => {
    if (values === property) {
      hideModal();
    } else {
      actions.setSubmitting(true);
      SupportApi.methods
        .updateProperty(values)
        .finally(() => actions.setSubmitting(false))
        .then(
          () => {
            dispatch(SupportActions.Creators.updateProperty(values));
            hideModal();
          },
          () => {
            // TODO: Error handling
          },
        );
    }
  };

  return (
    <EditModalContainer>
      <EditModalTitle>{t("pages.support.envProperties.modals.editPropertyModal.title")}</EditModalTitle>
      <div>
        <Formik
          validateOnChange={false}
          initialValues={property}
          onSubmit={_persistProperty}
          validationSchema={getValidationSchema()}
          render={({ handleChange, values, handleSubmit, isSubmitting, errors, setFieldValue }) => {
            if (property.propertyType !== "PLAIN_TEXT" && values.propertyValue === property.propertyValue) {
              values = { ...values, propertyValue: "" };
            }

            if (property.propertyType === "ENCRYPTED") {
              values = { ...values, propertyType: "TO_ENCRYPT" };
            }

            return (
              <form onSubmit={handleSubmit}>
                <TextInput
                  value={values.propertyName}
                  onChange={handleChange}
                  disabled
                  id="propertyNameEdit"
                  name="propertyName"
                  title={t("pages.support.envProperties.modals.editPropertyModal.propertyName.label")}
                  placeholder={t("pages.support.envProperties.modals.editPropertyModal.propertyName.placeholder")}
                  style={{ marginBottom: 24 }}
                  error={errors.propertyName}
                  required={true}
                />
                <TextInput
                  id="propertyValueEdit"
                  value={property.propertyType !== "PLAIN_TEXT" ? "**********" : property.propertyValue}
                  onChange={handleChange}
                  name="propertyValue"
                  title={t("pages.support.envProperties.modals.editPropertyModal.propertyValue.label")}
                  placeholder={t("pages.support.envProperties.modals.editPropertyModal.propertyValue.placeholder")}
                  style={{ marginBottom: 24 }}
                  required={false}
                  disabled
                />

                <TextInput
                  id="propertyNewValueEdit"
                  value={values.propertyValue}
                  onChange={handleChange}
                  name="propertyValue"
                  error={errors.propertyValue}
                  title={"New Value"}
                  placeholder={t("pages.support.envProperties.modals.editPropertyModal.propertyValue.placeholder")}
                  style={{ marginBottom: 24 }}
                  required={true}
                />
                <Row style={{ marginBottom: 50, alignItems: "end" }}>
                  <Checkbox
                    id="encryptedValueEdit"
                    value={values.propertyType !== "PLAIN_TEXT"}
                    onValueChange={e => setFieldValue("propertyType", e ? "TO_ENCRYPT" : "PLAIN_TEXT")}
                  ></Checkbox>
                  <Label>{t("pages.support.envProperties.modals.editPropertyModal.encryptedValue.label")}</Label>
                </Row>

                <Row style={{ justifyContent: "flex-end" }}>
                  <div style={{ marginRight: 12 }}>
                    <PrimaryButton
                      id="cancelPropertyEdit"
                      onClick={hideModal}
                      titleLabel={t("pages.support.envProperties.modals.editPropertyModal.buttons.cancel")}
                    />
                  </div>
                  <div>
                    <PrimaryButton
                      id="savePropertyEdit"
                      redTheme={true}
                      loading={isSubmitting}
                      type="submit"
                      titleLabel={t("pages.support.envProperties.modals.editPropertyModal.buttons.saveChanges")}
                    />
                  </div>
                </Row>
              </form>
            );
          }}
        />
      </div>
    </EditModalContainer>
  );
};

export default EditPropertyModal;

const EditModalContainer = styled("div")`
  border-radius: 6px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  padding: 24px;
`;

const EditModalTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 24px;
`;

const Label = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
  margin-left: 10px;
`;
