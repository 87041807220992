import { ModalTypeEnum, useModal, DownloadIcon, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { IconContainer } from "../../../../shared/shared.styled";
import { reduceImageSize } from "../../ekyc.shared.utils";
import { useTranslation } from "react-i18next";
import React, { useContext, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import { IDocValidationDetailsImagesReducerInterface, IPhotoInfo } from "../doc-validation-details.interface";
import FullScreenImageModal from "./doc-validation-fullscreen-image.component";

interface IClientDocPhotosProps {
  photoUrls: IDocValidationDetailsImagesReducerInterface[];
  msisdn: string;
}

const _MAX_IMG_SIZE = 2 * 1024 * 1024; // 2MB

const ClientDocPhotos = ({ photoUrls, msisdn }: IClientDocPhotosProps) => {
  const themeContext = useContext(ThemeContext);

  // Hooks init
  const [t] = useTranslation();
  const [showAlert, hideAlert, setAlertProps] = useAlert();

  // Local state definitions
  const [selectedPhotoIdx, setSelectedPhotoIdx] = useState(0);

  const [showPictureModal, hidePictureModal, pictureModalProps] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <FullScreenImageModal photoUrl={photoUrls[selectedPhotoIdx] && photoUrls[selectedPhotoIdx].url} />,
  );

  React.useEffect(() => {
    pictureModalProps({ modalStyles: { width: "auto", overflow: "visible" } });
  }, [pictureModalProps]);

  const downloadImg = function(photoInfo: IPhotoInfo) {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = photoInfo.url;

    setAlertProps({
      title: t("pages.docSubmissionDetails.imageDownloadStart"),
      type: AlertTypeEnum.SUCCESS,
    });
    showAlert();

    img.onload = function(event: any) {
      const canvas = document.createElement("canvas");

      // Set video frame image to canvas
      canvas.width = img.width;
      canvas.height = img.height;
      const canvasCtx = canvas.getContext("2d");
      if (canvasCtx) {
        canvasCtx.drawImage(img, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(imgBlobLoaded.bind(this, photoInfo, img));
      }
    };

    img.onerror = function() {
      hideAlert();
      setAlertProps({
        title: t("pages.docSubmissionDetails.imageDownloadError"),
        type: AlertTypeEnum.ERROR,
      });
      showAlert();
    };
  };

  const imgBlobLoaded = function(photoInfo: IPhotoInfo, img: any, blob: Blob | null) {
    if (!blob) {
      return;
    }

    reduceImageSize(img, "image/jpeg", _MAX_IMG_SIZE).then((blob: any) => {
      const fileDownload = require("js-file-download");
      fileDownload(blob, `${msisdn}_${photoInfo.context}.jpg`);
      hideAlert();
    });
  };

  return (
    <>
      <IdThumbsContainer>
        {photoUrls.map((photoUrl, idx) => (
          <IdThumbPhoto
            url={photoUrl.url}
            selected={selectedPhotoIdx === idx}
            onClick={e => setSelectedPhotoIdx(idx)}
          />
        ))}
      </IdThumbsContainer>

      <SelectedIdPhotoContainer>
        <SelectedIdPhoto
          src={photoUrls[selectedPhotoIdx] && photoUrls[selectedPhotoIdx].url}
          onClick={e => showPictureModal()}
        />

        <DownloadPhotoBtn onClick={e => downloadImg(photoUrls[selectedPhotoIdx])}>
          <IconContainer size={18} color={themeContext.palette.vodafoneRed}>
            <DownloadIcon />
          </IconContainer>
        </DownloadPhotoBtn>
      </SelectedIdPhotoContainer>
    </>
  );
};

export default ClientDocPhotos;

const IdThumbsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 8px;
  width: 245px;
`;

const IdThumbPhoto = styled("div")<{ url: string; selected?: boolean }>`
  background-image: url(${props => props.url});
  background-color: #f6f6f6;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 77px;
  height: 70px;
  border-radius: 6px;
  border: 1px solid ${props => (props.selected ? "#e02020" : props.theme.palette.aluminium)};
  margin-right: 4px;
  cursor: pointer;
`;

const SelectedIdPhotoContainer = styled("div")`
  max-width: 239px;
  border-radius: 6px;
  border: 1px solid #e02020;
  position: relative;
  overflow: hidden;
`;

const SelectedIdPhoto = styled("img")`
  max-width: 100%;
  min-width: 237px;
  cursor: zoom-in;
  display: block;
`;

const DownloadPhotoBtn = styled("div")`
  border-radius: 50%;
  border: solid 1px ${props => props.theme.palette.aluminium};
  background-color: ${props => props.theme.palette.white};
  width: 28px;
  height: 28px;
  position: absolute;
  top: 11px;
  right: 11px;
  cursor: pointer;
  padding-left: 4px;
  padding-top: 4px;
`;
