import { BackOfficeMarketsEnums } from "../../../shared/shared.enums";
import { IFullNameValidation } from "../../../shared/shared.interfaces";

export enum UserRolesDefault {
  ADMIN = "admin",
  DEVELOPER = "developer",
  EDITOR = "editor",
  SUPPORT = "support",
  APPROVER = "approver",
}

export enum UserRolesVDF {
  ADMIN_SERVICE_MANAGEMENT = "admin_service_management",
  EDITOR_SERVICE_MANAGEMENT = "editor_service_management",
  APPROVER_SERVICE_MANAGEMENT = "approver_service_management",
}

export enum UserRolesSFC {
  ANNOUNCEMENTS_MANAGER = "announcements_manager",
  REQUEST_MONEY_MANAGER = "request_money_manager",
  SERVICES_MANAGER_MANAGER = "services_manager",
}

export interface ISingleUserRequest {
  fullName: string;
  language: string;
  role?: UserRolesDefault | UserRolesVDF | UserRolesSFC;
  username: string;
  authenticationType?: AuthenticationTypeEnum;
}

export interface ICreateUserRequest {
  users: Array<ISingleUserRequest>;
}

export interface IUpdateUserRequest {
  fullName: string;
  role: string;
}

export interface IUpdateUserResponse {
  fullName: string;
  role: UserRolesDefault | UserRolesVDF | UserRolesSFC;
  accountEnableTime?: string;
  accountDisableTime?: string;
}

export enum AuthenticationTypeEnum {
  LDAP = "LDAP",
  LOCAL = "LOCAL",
}

export type FullNameValidationRuleType = {
  [key in keyof typeof BackOfficeMarketsEnums]: IFullNameValidation;
};
