import { AutoClosingDropdown, SearchBar } from "@wit/mpesa-ui-components";
import { FilterFuncts } from "@wit/mpesa-ui-components/lib/hooks/use-filters.hook";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { OtherFiltersContainer, SearchBarFilterContainer } from "../../../../shared/responsive-ui.styled";
import { Row } from "../../../../shared/shared.styled";

export enum LanguageTranslationsVersionsFiltersEnum {
  SEARCH = "search",
  STATUS = "status",
}

export enum LanguageTranslationsVersionStatusOptionsEnum {
  LIVE = "live",
  SUBMITTED_FOR_APPROVAL = "submittedForApproval",
}

interface ILanguageTranslationsVersionsFiltersProps extends FilterFuncts {}

const LanguageTranslationsVersionsFilters = ({
  filters,
  clearFilter,
  updateFilter,
}: ILanguageTranslationsVersionsFiltersProps) => {
  const [t] = useTranslation();

  /**
   * Returns the list of status options
   * @returns {{label: string, key: string | undefined}[]}
   */
  const getStatusOptions = () => {
    const options: { label: string; key: string | undefined }[] = Object.values(
      LanguageTranslationsVersionStatusOptionsEnum,
    ).map(o => {
      return { key: o, label: t(`pages.languageTranslationsVersions.statusOptionsEnum.${o}`) };
    });
    options.push({
      label: t(`commons.apps.enums.platform.seeAll`),
      key: undefined,
    });
    return options;
  };

  return (
    <LanguageTranslationsVersionsFiltersContainer>
      <SearchBarFilterContainer id={"search-language-translations-versions"}>
        <SearchBar
          placeholderLabel={t("pages.languageTranslationsVersions.search")}
          value={
            filters.has(LanguageTranslationsVersionsFiltersEnum.SEARCH)
              ? (filters.get(LanguageTranslationsVersionsFiltersEnum.SEARCH) as string[])[0]
              : ""
          }
          onChange={e => {
            if (e.target.value) {
              updateFilter(LanguageTranslationsVersionsFiltersEnum.SEARCH, e.target.value, true);
            } else {
              clearFilter(LanguageTranslationsVersionsFiltersEnum.SEARCH);
            }
          }}
          clearValue={() => clearFilter(LanguageTranslationsVersionsFiltersEnum.SEARCH)}
        />
      </SearchBarFilterContainer>

      <OtherFiltersContainer id={"status-dropdown"}>
        <AutoClosingDropdown
          label={
            filters.has(LanguageTranslationsVersionsFiltersEnum.STATUS)
              ? t(
                  `pages.languageTranslationsVersions.statusOptionsEnum.${
                    (filters.get(LanguageTranslationsVersionsFiltersEnum.STATUS) as string[])[0]
                  }`,
                )
              : t("pages.languageTranslationsVersions.status")
          }
          options={getStatusOptions()}
          selectOption={val => {
            if (val.key) {
              updateFilter(LanguageTranslationsVersionsFiltersEnum.STATUS, val.key, true);
            } else {
              clearFilter(LanguageTranslationsVersionsFiltersEnum.STATUS);
            }
          }}
          hasValue={filters.has(LanguageTranslationsVersionsFiltersEnum.STATUS)}
        />
      </OtherFiltersContainer>
    </LanguageTranslationsVersionsFiltersContainer>
  );
};

const LanguageTranslationsVersionsFiltersContainer = styled(Row)`
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export default LanguageTranslationsVersionsFilters;
