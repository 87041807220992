import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Row } from "../../../../../shared/shared.styled";
import CampaignDetailsForm from "../../components/campaign-details-form.component";
import {
  IReferralCampaign,
  SenderTypeRewardEnum,
  TargetEnum,
  ReceiverTypeRewardEnum,
} from "../../referral-campaigns.model";
import { validateSchemaCampaignDetails, validateTranslationsCampaignDetails } from "../../referral-campaigns.utils";
import CampaignProgressAndSubmit from "./campaign-progress-and-submit.component";
import ReferralCampaignsApi from "../../referral-campaigns.api";
import moment from "moment";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { useAlert } from "@wit/mpesa-ui-components";
import { PageContent } from "../../../../../shared/responsive-ui.styled";
import GenericLanguageSelector from "../../../../../shared/components/generic-language-selector.component";
import { ILanguage } from "../../../../../shared/models/language.model";
import useEmptyLanguageWarningModal from "../../../../../shared/hooks/use-empty-language-warning-modal.hook";

interface ICreateCampaignDetailsProps {
  languages: ILanguage[];
  selectedLanguage: string;
  setSelectedLanguage: React.Dispatch<React.SetStateAction<string>>;
  formValues: IReferralCampaign;
  advanceToNextStep: (values: IReferralCampaign) => void;
  setStep: (step: number, values: IReferralCampaign) => void;
}

/**
 * CreateReferralCampaign page component
 */
const CreateCampaignDetails = ({
  languages,
  selectedLanguage,
  setSelectedLanguage,
  formValues,
  advanceToNextStep,
  setStep,
}: ICreateCampaignDetailsProps) => {
  const [t] = useTranslation();
  const [showAlert, , setAlertProps] = useAlert();

  const { warningModalOpen, showWarning, setShowWarning } = useEmptyLanguageWarningModal(languages || []);
  /**
   * Submit method wrapper to show warning if needed
   * @param values
   */
  const submitWrapper = (values: IReferralCampaign) => {
    if (showWarning) {
      setShowWarning(false);
      warningModalOpen(handleSubmitCampaignDetail, values);
    } else {
      handleSubmitCampaignDetail(values);
    }
  };

  /**
   * Performs the create request
   * @param {ICampaignDetails} values
   * @param {FormikHelpers<ICampaignDetails>} actions
   */
  const handleSubmitCampaignDetail = (values: IReferralCampaign) => {
    if (values.defaultCampaign && values.rewards[0].type === undefined) {
      values.rewards = [
        {
          target: TargetEnum.RECEIVER,
          type: ReceiverTypeRewardEnum.NONE,
        },
        {
          target: TargetEnum.SENDER,
          type: SenderTypeRewardEnum.NONE,
        },
      ];
    }
    // Verify if the date interval is available or not - only for non default campaigns
    if (!values.defaultCampaign) {
      ReferralCampaignsApi.methods
        .areDatesAvailable({
          startDate: moment(values.startDate, "DD/MM/YYYY").valueOf(),
          endDate: moment(values.endDate, "DD/MM/YYYY").valueOf(),
        })
        .then(
          res => {
            if (res.data) {
              advanceToNextStep(values);
            } else {
              setAlertProps({
                title: t("pages.referralCampaigns.createCampaign.invalidDates"),
                type: AlertTypeEnum.ERROR,
              });
              showAlert();
            }
          },
          () => {
            setAlertProps({
              title: t("pages.referralCampaigns.createCampaign.invalidDates"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          },
        );
    } else {
      advanceToNextStep(values);
    }
  };

  return (
    <Formik
      initialValues={formValues}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={submitWrapper}
      validate={values => validateSchemaCampaignDetails(values, languages, setShowWarning)}
      render={({ values, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue, errors, setErrors }) => (
        <PageContent className={"display-column"}>
          <form onSubmit={handleSubmit}>
            <CampaignProgressAndSubmit
              stepTitle={t("pages.referralCampaigns.createCampaign.campaignDetails.insertCampaignDetails")}
              stepDescription={t("pages.referralCampaigns.createCampaign.step").replace("{0}", "1")}
              buttonLabel={t("pages.referralCampaigns.createCampaign.continue")}
              step={1}
              totalOfSteps={4}
              setStep={step => setStep(step, values)}
              onSubmitFn={() => validateTranslationsCampaignDetails(values, languages, setSelectedLanguage)}
            />
            <CampaignFormDetailsContainer>
              <CampaignDetailsForm
                title={
                  <Row>
                    <FormTitle>{t("pages.referralCampaigns.createCampaign.campaignDetails.title")}</FormTitle>
                    <div style={{ marginTop: "auto", marginLeft: "auto" }}>
                      <GenericLanguageSelector
                        selectedLanguage={selectedLanguage}
                        availableLanguages={languages.map(lang => lang.code)}
                        changeSelectedLanguage={(lang: any) => setSelectedLanguage(lang.key)}
                      ></GenericLanguageSelector>
                    </div>
                  </Row>
                }
                isEditing={true}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                language={selectedLanguage}
                setErrors={setErrors}
                isLanguageRequired={languages.find(l => l.code === selectedLanguage)?.mandatory}
              />
            </CampaignFormDetailsContainer>
          </form>
        </PageContent>
      )}
    />
  );
};

export default CreateCampaignDetails;

const CampaignFormDetailsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 56px;

  > div > div > span {
    width: 100%;
  }
`;

const FormTitle = styled("span")`
  font-family: "Vodafone Rg";
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
`;
