import { AxiosPromise } from "axios";
import ApiClient from "../../configs/axios.service-builder.config";

const ServiceEditorApi = {
  routes: {
    serviceConfiguration: "/serviceversion/:serviceId/configFile",
  },
  methods: {
    getServiceConfiguration: (serviceId: string, language: string): AxiosPromise<any> => {
      return ApiClient.get(
        ServiceEditorApi.routes.serviceConfiguration.replace(":serviceId", serviceId) + "?language=" + language,
      );
    },
    setServiceConfiguration: (serviceId: string, language: string, configFile: Blob): AxiosPromise<any> => {
      const data = new FormData();
      if (configFile) {
        data.append("configFile", configFile);
      }
      return ApiClient.put(
        ServiceEditorApi.routes.serviceConfiguration.replace(":serviceId", serviceId) + "?language=" + language,
        data,
      );
    },
  },
};

export default ServiceEditorApi;
