import { ILanguage } from "../models/language.model";
import { ModalTypeEnum, useModal } from "@wit/mpesa-ui-components";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import { t } from "i18next";
import { useState } from "react";

const useEmptyLanguageWarningModal = (availableLanguages: ILanguage[]) => {
  const [showWarning, setShowWarning] = useState(false);
  const [showWarningModal, hideWarningModal, setWarningProps] = useModal(ModalTypeEnum.ConfirmationModal);

  /**
   * Show language warning message
   */
  const warningModalOpen = (submit: any, values: any, actions?: any) => {
    const warningProps = {
      title: t("commons.missingTranslations.title"),
      description: t("commons.missingTranslations.description", {
        defaultLanguage: availableLanguages?.find(l => l.fallback)?.name || "English",
      }),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      secondaryAction: () => hideWarningModal(),
      primaryAction: () => {
        setWarningProps({ ...warningProps, isLoading: true });
        setShowWarning(false);
        hideWarningModal();
        actions ? submit(values, actions) : submit(values);
      },
    };
    setWarningProps(warningProps as BaseModalProps);
    showWarningModal();
  };

  return { warningModalOpen, showWarning, setShowWarning, hideWarningModal };
};

export default useEmptyLanguageWarningModal;
