import { Checkbox, PrimaryButton } from "@wit/mpesa-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IExportFormDataRequest, IExportModalComponentProps } from "../feedback-and-problems.model";
import { feedbacksStringPath, problemsStringPath, isFeedbackTabActive } from "../feedback-and-problems.utils";

const ExportModalComponent = ({ closeExportModal, sectionId, exportFormData }: IExportModalComponentProps) => {
  const [t] = useTranslation();
  const [exportFormat, setExportFormat] = React.useState<IExportFormDataRequest>({
    excel: false,
    csv: false,
  });

  return (
    <ModalContainer>
      <ModalTitle>
        {isFeedbackTabActive(sectionId)
          ? t(`${feedbacksStringPath}.filtersArea.modal.title`)
          : t(`${problemsStringPath}.filtersArea.modal.title`)}
      </ModalTitle>
      <ModalMessage>
        {isFeedbackTabActive(sectionId)
          ? t(`${feedbacksStringPath}.filtersArea.modal.subtitle`)
          : t(`${problemsStringPath}.filtersArea.modal.subtitle`)}
      </ModalMessage>
      <div style={{ minHeight: "107px" }}>
        <CheckboxContainer>
          <Checkbox
            value={exportFormat.excel}
            onValueChange={e => {
              setExportFormat((prev: IExportFormDataRequest) => {
                return { ...prev, excel: e };
              });
            }}
          />
          <CheckboxLabel>
            {isFeedbackTabActive(sectionId)
              ? t(`${feedbacksStringPath}.filtersArea.modal.excel`)
              : t(`${problemsStringPath}.filtersArea.modal.excel`)}
          </CheckboxLabel>
        </CheckboxContainer>

        <CheckboxContainer>
          <Checkbox
            value={exportFormat.csv}
            onValueChange={e => {
              setExportFormat((prev: IExportFormDataRequest) => {
                return { ...prev, csv: e };
              });
            }}
          />
          <CheckboxLabel>
            {isFeedbackTabActive(sectionId)
              ? t(`${feedbacksStringPath}.filtersArea.modal.csv`)
              : t(`${problemsStringPath}.filtersArea.modal.csv`)}
          </CheckboxLabel>
        </CheckboxContainer>
      </div>
      <ButtonsContainer>
        <div>
          <PrimaryButton
            redTheme={false}
            id={"cancel-btn"}
            titleLabel={
              isFeedbackTabActive(sectionId)
                ? t(`${feedbacksStringPath}.filtersArea.modal.cancel`)
                : t(`${problemsStringPath}.filtersArea.modal.cancel`)
            }
            onClick={closeExportModal}
            type={"button"}
          />
        </div>
        <div>
          <PrimaryButton
            redTheme={true}
            id={"create-btn"}
            titleLabel={
              isFeedbackTabActive(sectionId)
                ? t(`${feedbacksStringPath}.filtersArea.modal.export`)
                : t(`${problemsStringPath}.filtersArea.modal.export`)
            }
            type={"submit"}
            onClick={() => exportFormData(exportFormat)}
            disabled={!exportFormat.csv && !exportFormat.excel}
          />
        </div>
      </ButtonsContainer>
    </ModalContainer>
  );
};

export default ExportModalComponent;

const ModalContainer = styled("div")`
  border-radius: 6px;
  box-shadow: 0 4px 4px rgba(142, 142, 142, 0.23), 0 3px 10px rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.palette.white};
  padding: 24px;
`;

const ModalTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 13px;
`;

const ModalMessage = styled("p")`
  font-family: Vodafone Rg;
  font-size: 18px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 21px;
  margin-top: 13px;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  > div {
    :first-of-type {
      margin-right: 12px;
    }
  }
`;

const CheckboxContainer = styled("div")`
  display: flex;
  margin-bottom: 16px;
  :last-of-type {
    margin-bottom: 49px;
  }
`;

const CheckboxLabel = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
  padding-left: 8px;
`;
