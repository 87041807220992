import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { PrimaryButton, ModalTypeEnum, useModal, useAlert } from "@wit/mpesa-ui-components";
import DisableAppsModal from "./disable-apps.modal";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { IAppsStatus } from "../apps.interfaces";
import { AxiosResponse } from "axios";
import AppsApi from "../apps.api";

interface IDisableApps {
  isAppsEnable: boolean | undefined;
  reason: string;
  setIsAppsEnable: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setDisabledReason: React.Dispatch<React.SetStateAction<string>>;
}

/**
 * DisableAppsComponent component
 */
const DisableAppsComponent = ({ isAppsEnable, reason, setIsAppsEnable, setDisabledReason }: IDisableApps) => {
  const [t] = useTranslation();
  const [showAlert, , setAlertProps] = useAlert();
  const [showEnableAppsModal, hideEnableAppsModal, setEnableAppsModal] = useModal(ModalTypeEnum.ConfirmationModal);
  const [showDisableAppsModal, hideDisableAppsModal] = useModal(
    ModalTypeEnum.CustomModal,
    undefined,
    <DisableAppsModal
      cancelAction={() => hideDisableAppsModal()}
      confirmAction={(reason: string) => disableAppsAction(reason)}
    />,
  );

  /**
   * modal to confirm enable apps
   */
  const _showEnableAppsModal = () => {
    setEnableAppsModal({
      title: t("pages.apps.modals.disableApps.titleEnable"),
      description: t("pages.apps.modals.disableApps.descriptionEnable"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      primaryAction: () => {
        hideEnableAppsModal();
        enableAppsAction();
      },
      secondaryAction: () => hideEnableAppsModal(),
    } as BaseModalProps);
    showEnableAppsModal();
  };

  /**
   *  method to confirm disable apps
   */
  const disableAppsAction = (reason: string) => {
    setAppStatus(false, reason);
  };

  /**
   *  method to confirm enable apps
   */
  const enableAppsAction = () => {
    setAppStatus(true);
  };

  /**
   *  method to set the status of the apps
   */
  const setAppStatus = (status: boolean, reason?: string) => {
    AppsApi.methods.setMpesaAppsStatus(status, reason).then(
      (res: AxiosResponse<IAppsStatus>) => {
        setIsAppsEnable(res.data.status);
        setDisabledReason(res.data.reason);
        res.data.status ? hideEnableAppsModal() : hideDisableAppsModal();
      },
      () => {
        status ? hideEnableAppsModal() : hideDisableAppsModal();
        setAlertProps({
          title: t("pages.apps.errors.changeStatusApps"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  return (
    <>
      <Separator />
      <Row>
        <DescriptionContainer>
          <DescriptionTitle>
            {isAppsEnable
              ? t("pages.apps.modals.disableApps.disableDescriptionTitle")
              : t("pages.apps.modals.disableApps.enableDescriptionTitle")}
          </DescriptionTitle>
          <DescriptionDetails>
            {isAppsEnable
              ? t("pages.apps.modals.disableApps.disableDescriptionDetails")
              : t("pages.apps.modals.disableApps.enableDescriptionDetails")}
          </DescriptionDetails>
          {!isAppsEnable && reason ? (
            <DescriptionReason>
              <b>{`${t("pages.apps.modals.disableApps.reasonFor")}: `}</b>
              {reason}
            </DescriptionReason>
          ) : null}
        </DescriptionContainer>
        <div>
          <PrimaryButton
            datatype={"buttonApp"}
            style={{ width: "180px", fontSize: "16px" }}
            titleLabel={
              isAppsEnable
                ? t("pages.apps.modals.disableApps.disableAppButton")
                : t("pages.apps.modals.disableApps.enableAppButton")
            }
            id={isAppsEnable ? "disable-mpesa" : "enable-mpesa"}
            redTheme={true}
            onClick={() => (isAppsEnable ? showDisableAppsModal() : _showEnableAppsModal())}
          ></PrimaryButton>
        </div>
      </Row>
    </>
  );
};

export default DisableAppsComponent;

const Separator = styled("div")`
  width: 100%;
  height: 1px;
  border-top: solid 1px ${props => props.theme.palette.aluminium};
  margin-bottom: 32px;
`;

const Row = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 768px) {
    gap: 48px;
    flex-wrap: wrap;
  }
`;

const DescriptionContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const DescriptionTitle = styled("span")`
  padding-bottom: 14px;
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.black};
  text-align: center;
`;

const DescriptionDetails = styled("span")`
  width: 370px;
  height: 42.5px;
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: ${props => props.theme.palette.midGrey};
`;

const DescriptionReason = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  margin-top: 16px;
  color: ${props => props.theme.palette.midGrey};
`;
