import { AutoClosingDropdown, SearchBar } from "@wit/mpesa-ui-components";
import { FilterFuncts } from "@wit/mpesa-ui-components/lib/hooks/use-filters.hook";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Row } from "../../../../shared/shared.styled";

export enum PushNotificationsFiltersEnum {
  SEARCH = "search",
  STATUS = "status",
}

interface IPushNotificationsFiltersProps extends FilterFuncts {
  status: string[];
}

/**
 * Push notifications filters
 * @param param0
 */
const PushNotificationsFilters = ({ filters, clearFilter, updateFilter, status }: IPushNotificationsFiltersProps) => {
  const [t] = useTranslation();

  /**
   * Returns the charge type options to be used on the filters
   */
  const getChargeTypeOptions = () => {
    const options: { label: string; key: string | undefined }[] = status.map(s => ({
      label: t(`pages.pushNotifications.statusEnum.${s}`),
      key: s,
    }));
    options.push({
      label: i18next.t(`commons.apps.enums.platform.seeAll`),
      key: undefined,
    });
    return options;
  };

  return (
    <PushNotificationsFilterContainer>
      <div style={{ flex: 4 / 12, marginRight: 24 }} id={"search-push-notifications"}>
        <SearchBar
          placeholderLabel={t("pages.pushNotifications.search")}
          value={
            filters.has(PushNotificationsFiltersEnum.SEARCH)
              ? (filters.get(PushNotificationsFiltersEnum.SEARCH) as string[])[0]
              : ""
          }
          onChange={e => {
            if (e.target.value) {
              updateFilter(PushNotificationsFiltersEnum.SEARCH, e.target.value, true);
            } else {
              clearFilter(PushNotificationsFiltersEnum.SEARCH);
            }
          }}
          clearValue={() => clearFilter(PushNotificationsFiltersEnum.SEARCH)}
        />
      </div>

      <div style={{ flex: 2 / 12, marginRight: 24 }} id={"push-notifications-status-dropdown"}>
        <AutoClosingDropdown
          label={
            filters.has(PushNotificationsFiltersEnum.STATUS)
              ? t(
                  `pages.pushNotifications.statusEnum.${
                    (filters.get(PushNotificationsFiltersEnum.STATUS) as string[])[0]
                  }`,
                )
              : t("pages.pushNotifications.pushNotificationStatus")
          }
          options={getChargeTypeOptions()}
          selectOption={val => {
            if (val.key) {
              updateFilter(PushNotificationsFiltersEnum.STATUS, val.key, true);
            } else {
              clearFilter(PushNotificationsFiltersEnum.STATUS);
            }
          }}
          hasValue={filters.has(PushNotificationsFiltersEnum.STATUS)}
        />
      </div>
    </PushNotificationsFilterContainer>
  );
};

export default PushNotificationsFilters;

const PushNotificationsFilterContainer = styled(Row)`
  margin-bottom: 49px;
  margin-top: 48px;
  width: 100%;
`;
