import { UserRolesVDF, UserRolesSFC, UserRolesDefault, AuthenticationTypeEnum } from "../admin/users/users.interfaces";

export interface ILoginRequest {
  username: string;
  password: string;
  reCaptchaTokenResult?: string;
}

export interface ILoginResponse {
  fullName: string;
  role: UserRolesDefault | UserRolesVDF | UserRolesSFC;
  username: string;
  is2FAEnabled: boolean;
  needs2FASetup: boolean;
  daysToPasswordExpiration: number;
  lastLoginTs: number;
  authenticationType?: AuthenticationTypeEnum;
}

export interface ILoggedUser extends ILoginResponse {
  rememberMe?: boolean;
}

export interface IValidateRegisterIdResponse {
  fullName: string;
  username: string;
}

export interface IRegisterUserRequest {
  password: string;
  id: string;
}

export interface ISetNewPasswordRequest {
  password: string;
  id: string;
}

export interface IForgotPasswordRequest {
  email: string;
  language: string;
}

export interface ITwoFactorAuthenticationResponse {
  secret: string;
  image: string;
}

export interface ITwoFactorAuthenticationRequest {
  otpCode: string;
}

export interface ILastLogin {
  deviceOs: string;
  loginDate: number;
  result: LoginResultEnum;
}

export enum LoginResultEnum {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}
