import React, { useState } from "react";
import { useSelector } from "react-redux";

import { ArrowDownIcon, Checkbox, PrimaryButton, SearchBar, StatusChip, useAlert } from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { t } from "i18next";
import styled from "styled-components";
import { Formik } from "formik";
import { useParams } from "react-router-dom";

import { RatioContainer } from "../../../../../../shared/shared.styled";
import { IStoreInterface } from "../../../../../../configs/store.config";
import ServiceManagerApi from "../../../../service-manager.api";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { ICategoryAssignedMiniApps } from "../../categories.model";
import Document from "../../../../../configurations/rate-cards/components/document.component";
import { IServiceManagerService } from "../../../../../../shared/models/service-manager.model";
import { SearchBarFilterContainer } from "../../../../../../shared/responsive-ui.styled";

interface IFormType {
  [key: string]: boolean;
}

interface IPropsTypes {
  hideDrawer(): void;
  toggleUpdateLocalCategoriesFlag(): void;
  categoryMiniApps: ICategoryAssignedMiniApps[];
}

/**
 * Render the assign mini-apps to a category form
 * @function AssignMiniAppToCategoryForm
 * @returns {JSX.Element}
 */
const AssignMiniAppToCategoryForm = ({
  hideDrawer,
  toggleUpdateLocalCategoriesFlag,
  categoryMiniApps,
}: IPropsTypes) => {
  const { services } = useSelector((state: IStoreInterface) => state.serviceManagerReducer);
  const { categoryId } = useParams<{ categoryId: string }>();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const [numberOfCheckedMiniApps, setNumberOfCheckedMiniApps] = useState(0);
  const [filter, setFilter] = useState("");

  /**
   * Submit checked mini-apps
   * @param values - Form data
   */
  const onSubmit = (values: any) => {
    const checkedMiniApps: string[] = [];
    for (const key in values) {
      if (values[key] === true) {
        checkedMiniApps.push(key);
      }
    }
    const data = {
      categoryId,
      miniAppsIds: checkedMiniApps,
    };

    ServiceManagerApi.methods.addMiniApsToCategory(categoryId, checkedMiniApps).then(
      () => {
        setAlertProps({
          title: t("pages.serviceManager.categories.miniApps.successAddMiniApp"),
          type: AlertTypeEnum.SUCCESS,
        });
        showAlert();
        toggleUpdateLocalCategoriesFlag();
        hideDrawer();
      },
      () => {
        setAlertProps({
          title: t("pages.serviceManager.categories.miniApps.errorAddMiniApp"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );

    console.log(data);
  };

  /**
   * Validate form values
   * @function ValidateForm
   * @param {IFormType} values - Form values
   * @returns {Object} - Validation errors
   */
  const validateForm = (values: IFormType) => {
    const errors: any = {};
    const isAtLeastSingleMiniAppChecked = Object.values(values).includes(true);
    if (!isAtLeastSingleMiniAppChecked) {
      errors.noMiniAppChecked = t("pages.serviceManager.categories.miniApps.errorMessage");
    }

    return errors;
  };

  /**
   * Filter out the already assigned mini-apps to the current category
   */
  const filterMiniAppsList = (arrToFilterOutBy: ICategoryAssignedMiniApps[], returnUnassigned: boolean) => {
    const categoryMiniAppsIds = arrToFilterOutBy?.map(app => app.id);
    let filteredService: IServiceManagerService[] = [];

    if (returnUnassigned) {
      filteredService = services.filter(miniApp => !categoryMiniAppsIds?.includes(miniApp.id));
    } else {
      filteredService = services.filter(miniApp => categoryMiniAppsIds?.includes(miniApp.id));
    }

    if (filter) {
      filteredService = filteredService.filter(
        miniApp => miniApp.name.toLowerCase().includes(filter.toLowerCase()) || miniApp.id.includes(filter),
      );
    }
    return filteredService;
  };

  /**
   * Create form values
   * @returns {string[]}
   */
  const formValues = () => {
    const values: IFormType = {};
    const filteredMiniApps = filterMiniAppsList(categoryMiniApps, false);
    filteredMiniApps.forEach(miniApp => {
      values[miniApp.id] = false;
    });

    return values;
  };
  return (
    <Container>
      <TitleContainer>
        <CloseIconContainer onClick={hideDrawer}>
          <ArrowDownIcon />
        </CloseIconContainer>
        <Title>{t("pages.serviceManager.categories.miniApps.addMiniApp")}</Title>
      </TitleContainer>
      <Description>{t("pages.serviceManager.categories.miniApps.addMiniAppDescription")}</Description>
      <SearchBarFilterContainer>
        <SearchBar
          placeholderLabel={t("pages.serviceBuilder.filters.searchPlaceholder")}
          value={filter}
          onChange={event => setFilter(event.target.value)}
          clearValue={() => setFilter("")}
        />
      </SearchBarFilterContainer>
      <SelectText>{`${numberOfCheckedMiniApps} ${t("pages.serviceManager.categories.miniApps.selected")}`}</SelectText>
      {filterMiniAppsList(categoryMiniApps, true).length ? (
        <>
          <div>
            {/* <input
              id="filter"
              name="filter"
              type="text"
              value={filter}
              onChange={event => setFilter(event.target.value)}
            /> */}
            <Formik initialValues={formValues()} validate={validateForm} onSubmit={onSubmit}>
              {({ values, errors, handleSubmit, isSubmitting, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <MiniAppListContainer>
                    {filterMiniAppsList(categoryMiniApps, true).map(miniApp => (
                      <MiniAppItemContainer key={miniApp.id}>
                        <CheckboxContainer id={miniApp.id}>
                          <Checkbox
                            name={miniApp.id}
                            setFieldValue={setFieldValue}
                            value={values[miniApp.id]}
                            onValueChange={(value: boolean) =>
                              setNumberOfCheckedMiniApps(checkedApps => (value ? ++checkedApps : --checkedApps))
                            }
                          />
                          <ServiceImageContainer ratio={0.5 / 12}>
                            <ServiceItemImage backgroundImage={miniApp.icon}></ServiceItemImage>
                          </ServiceImageContainer>
                          <Label>{miniApp.name}</Label>
                        </CheckboxContainer>
                        <StatusChip type={miniApp.status as any}>
                          {t(`commons.serviceBuilder.enums.status.${miniApp.status}`)}
                        </StatusChip>
                      </MiniAppItemContainer>
                    ))}
                  </MiniAppListContainer>
                  <ErrorMessage>{errors.noMiniAppChecked}</ErrorMessage>
                  <FormActions>
                    <PrimaryButton
                      titleLabel={t("pages.serviceManager.categories.miniApps.cancel")}
                      type="button"
                      redTheme={false}
                      style={{ marginRight: "1rem" }}
                      onClick={hideDrawer}
                    />
                    <PrimaryButton
                      titleLabel={t("pages.serviceManager.categories.miniApps.save")}
                      type="submit"
                      redTheme={true}
                    />
                  </FormActions>
                </form>
              )}
            </Formik>
          </div>
        </>
      ) : (
        <EmptyState>
          <Document />
          <SelectText>{t("pages.serviceManager.categories.miniApps.noServicesToAdd")}</SelectText>
        </EmptyState>
      )}
    </Container>
  );
};

export default AssignMiniAppToCategoryForm;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 70px;
`;
const TitleContainer = styled("div")`
  display: flex;
  margin-bottom: 0.6rem;
  align-items: center;
`;

const CloseIconContainer = styled("div")`
  stroke: ${props => props.theme.palette.vodafoneRed};
  transform: rotate(90deg);
  cursor: pointer;
  svg {
    width: 16px;
  }
`;

const Title = styled("span")`
  font-family: Vodafone Rg;
  font-size: 24px;
  color: ${props => props.theme.palette.darkGrey};
  margin-left: 11.5px;
`;
const Description = styled.p`
  font-family: Vodafone Rg;
  color: ${styleTheme.palette.midGrey};
  margin-bottom: 2.4rem;
`;
const SelectText = styled.span`
  padding-top: 0.5rem;
  font-family: Vodafone Rg;
  font-size: 18px;
  color: ${styleTheme.palette.midGrey};
`;
const EmptyState = styled("div")`
  margin-top: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const MiniAppListContainer = styled.div`
  padding: 1rem 0.25rem;
  border-bottom: 1px solid #e0e0e0;
  height: 55vh;
  overflow-y: scroll;
`;
const MiniAppItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0.25rem;
  border-bottom: 1px solid #e0e0e0;
  justify-content: space-between;
`;
const CheckboxContainer = styled("div")`
  display: flex;
  align-items: center;
`;

const ServiceImageContainer = styled(RatioContainer)`
  padding: 0 10px 0 10px;
`;
const ServiceItemImage = styled("div")<{ backgroundImage: string }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin: auto;
  margin-right: 20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: solid 1px #ebebeb;
  margin-left: 0;
  background-image: url("data:image/jpeg;base64,${props => props.backgroundImage}");
`;
const Label = styled("div")`
  font-family: Vodafone Rg;
  margin-left: 8px;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: ${props => props.theme.palette.midGrey};
`;

const FormActions = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: space-around;
  * {
    width: auto;
  }
`;

const ErrorMessage = styled.span`
  font-family: Vodafone Rg;
  font-size: 0.9rem;
  margin-top: 0.3rem;
  color: ${styleTheme.palette.red.normal};
  display: block;
`;
