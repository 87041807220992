import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemeContext } from "styled-components";
import { CancelIcon } from "@wit/mpesa-ui-components";
import { INetworkItem } from "../networks.model";
import { getStatus } from "../networks.page";

interface ILastVersionModalProps {
  onCancelClick: () => void;
  networkList: INetworkItem[];
}

const LastVersionModalPage = ({ onCancelClick, networkList }: ILastVersionModalProps) => {
  const [t] = useTranslation();
  const themeContext = useContext(ThemeContext);
  return (
    <ModalContainer>
      <Header>
        <ModalTitle>{t("pages.networks.lastVersionModal.title")}</ModalTitle>
        <RightContainer>
          <CloseIconContainer onClick={onCancelClick} id="close-last-version-modal">
            <CancelIcon color={"grey"} />
          </CloseIconContainer>
        </RightContainer>
      </Header>
      <ItemsContainer>
        {networkList.map((value: INetworkItem, index) => {
          const status = getStatus(value.status);
          return (
            <NetworkItemDiv key={index} id={value.networkType}>
              <NetworkText>{t(`commons.networks.${value.networkType}`)}</NetworkText>
              <ValueItem color={status ? themeContext.palette.digitalGreen : themeContext.palette.warningYellow}>
                <div>{status ? t("pages.networks.activeLabel") : t("pages.networks.disabledLabel")}</div>
              </ValueItem>
            </NetworkItemDiv>
          );
        })}
      </ItemsContainer>
    </ModalContainer>
  );
};

export default LastVersionModalPage;

const NetworkItemDiv = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;
const NetworkText = styled.span`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
`;

const ValueItem = styled.div<{ color: string }>`
  float: right;
  color: ${props => props.color};
`;

const ModalContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 24px;
  font-family: Vodafone Rg;
`;

const ModalTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 21px;
`;

const ItemsContainer = styled.div`
  max-height: 400px;
  overflow: auto;
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${props => props.theme.palette.darkGrey};
  overflow-x: hidden;
`;

const CloseIconContainer = styled("div")`
  left: 23px;
  margin-top: -10px;
  stroke: ${props => props.theme.palette.midGrey};
  cursor: pointer;
  svg {
    width: 24px;
  }
`;

const RightContainer = styled.div`
  display: flex;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
