import styled from "styled-components";

export const Container = styled("div")`
  margin-top: 44px;
`;

export const TableContainer = styled("div")`
  margin-top: 32px;
  z-index: -1;
`;

export const ButtonContainer = styled("div")`
  width: fit-content;
  margin-top: 29px;
`;

export const DivDateComponents = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
`;

export const DivCalendar = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 0.5;
`;

export const DivTime = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 0.45;
`;
export const InputTitle = styled("span")`
  min-height: 21px;
  font-family: Vodafone Rg;
  font-weight: bold;
  line-height: 21px;
  font-size: 16px;
  color: ${props => props.theme.palette.greyDarker};
  margin-bottom: 8px;
`;
export const FieldSection = styled("div")``;

export const InputError = styled("span")`
  height: 19px;
  color: ${props => props.theme.palette.errorRed};
  font-family: Vodafone Rg;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  float: right;
  font-weight: 400;
`;

export const InputTitleRequired = styled("span")`
  height: 21px;
  width: 7px;
  color: ${props => props.theme.palette.errorRed};
  font-family: Vodafone Rg;
  font-size: 16px;
  line-height: 21px;
  margin-right: 1px;
  font-weight: 400;
  ::before {
    content: " *";
    color: ${props => props.theme.palette.errorRed};
  }
`;
export const IdentityConverterContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 31px 47px;
  margin: 20px -47px 0px -47px;
  background-color: ${props => props.theme.palette.greyLight};
`;

export const IdentityConverterTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.4px;
  color: ${props => props.theme.palette.midGrey};
  text-transform: uppercase;
  margin-bottom: 21px;
`;

export const LoadingContainer = styled("div")`
  height: 50vh;
  width: calc(100% - 70px);
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
`;
