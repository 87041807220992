import {
  CheckIcon,
  FastActionButton,
  ModalTypeEnum,
  PageTitle,
  PrimaryButton,
  UndoIcon,
  useAlert,
  useFilters,
  useModal,
} from "@wit/mpesa-ui-components";
import styleTheme from "@wit/mpesa-ui-components/lib/configs/theme.config";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { BaseModalProps } from "@wit/mpesa-ui-components/lib/context/modal/modal.types";
import { Breadcrumb } from "@wit/mpesa-ui-components/lib/types";
import i18next, { TFunction } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { IStoreInterface } from "../../../configs/store.config";
import { RoutesEnum } from "../../../routes/routes.constants";
import { Column, LoadingText, PageContainer, RatioContainer, Row } from "../../../shared/shared.styled";
import SubBundleStatusChip from "../bundles/bundle-details/components/sub-bundle-status.component";
import DiscoverCardsFilters from "./components/discover-cards-filters.component";
import DiscoverCardsList from "./components/discover-cards-list.component";
import DiscoverCardsApi from "./discover-cards.api";
import { DiscoverCardsActions } from "./discover-cards.store";
import { filterDiscoverCards } from "./discover-cards.utils";
import LastVersionModal from "./components/discover-cards-check-last-version";
import PublishDiscoverCard from "./components/discover-cards-publish";
import { IDiscoverCard, DiscoverCardsStatusEnum, DiscoverCardsErrorCodes } from "./discover-cards.model";
import { isHttp } from "../../../shared/shared.utils";
import { VersionControlButtonsContainer, VersionControlButtonsRow } from "../../../shared/responsive-ui.styled";
import LoadingIconWhite from "../../../shared/icons/loading-white.icon";

/**
 * Gets the Breadcrumbs
 * @param t
 */
const getBreadcrumbs = (t: TFunction): Breadcrumb[] => {
  return [
    {
      label: t("pages.configuration.title"),
      url: RoutesEnum.CONFIGS,
    },
    {
      label: t("pages.discoverCards.discoverCards"),
      url: "",
    },
  ];
};

/**
 * DiscoverCardsPage component
 */
const DiscoverCardsPage = () => {
  const [t] = useTranslation();
  const [showAlert, , setAlertProps] = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();

  const [lastVersionLoading, setLastVersionLoading] = React.useState(false);
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [revertLoading, setRevertLoading] = useState(false);
  const [filtersResult, setFiltersResult] = useState<any>();

  const [showLastVersionModal, hideLastVersionModal] = useModal(
    ModalTypeEnum.CustomModal,
    { modalStyles: { width: 482, overflow: "initial" } } as any,
    <LastVersionModal onCancelClick={() => hideLastVersionModal()} />,
  );

  const [showPublish, hidePublish] = useModal(
    ModalTypeEnum.CustomModal,
    { modalStyles: { width: 1000, overflow: "initial" } } as any,
    <PublishDiscoverCard isLoading={approvalLoading} onCancelClick={() => hidePublish()} submit={() => publish()} />,
  );

  const {
    filters,
    updateFilter,
    isFilterActive,
    clearFilter,
    resetFilters,
    getFilterValue,
    getFiltersQueryString,
    updateMultipleFilters,
  } = useFilters();

  const [showConfirmationModal, hideConfirmationModal, setConfirmationProps] = useModal(
    ModalTypeEnum.ConfirmationModal,
  );

  // Redux state
  const discoverCardsStore = useSelector(
    (state: IStoreInterface) => state.discoverCardsReducer.discoverCards,
    shallowEqual,
  );

  const [selectedLanguage, setSelectedLanguage] = React.useState({ label: t("commons.languages.en"), key: "en" });

  /**
   * Gets the list of discover cards
   */
  const getDiscoverCards = () => {
    DiscoverCardsApi.methods.getDiscoverCards().then(
      res => {
        dispatch(DiscoverCardsActions.creators.getDiscoverCardsSuccess(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.discoverCards.getDiscoverCardsError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );

    DiscoverCardsApi.methods.getLastVersion().then(
      res => {
        dispatch(DiscoverCardsActions.creators.getDiscoverCardsLastVersion(res.data));
      },
      () => {
        setAlertProps({
          title: t("pages.discoverCards.getDiscoverCardsError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /*
   * Get discover cards
   * */
  React.useEffect(() => {
    getDiscoverCards();
  }, [dispatch]);

  /**
   * Revert to last version handler
   */
  const revertToLastVersion = () => {
    const confirmationProps = {
      title: t("pages.discoverCards.confirmRevert"),
      description: t("pages.discoverCards.confirmRevertMessage"),
      primaryBtnId: "confirm-button",
      secondaryBtnId: "cancel-button",
      secondaryAction: () => hideConfirmationModal(),
      primaryAction: () => {
        setRevertLoading(true);
        setConfirmationProps({ ...confirmationProps, isLoading: true });
        DiscoverCardsApi.methods
          .revertChanges()
          .then(
            () => {
              setAlertProps({
                title: t("pages.discoverCards.revertSuccess"),
                type: AlertTypeEnum.SUCCESS,
              });
              showAlert();
              hideConfirmationModal();
              getDiscoverCards();
            },
            () => {
              setAlertProps({
                title: t("pages.discoverCards.revertError"),
                type: AlertTypeEnum.ERROR,
              });
              showAlert();
            },
          )
          .finally(() => {
            setRevertLoading(false);
            setConfirmationProps({ ...confirmationProps, isLoading: false });
          });
      },
    };

    setConfirmationProps(confirmationProps as BaseModalProps);
    showConfirmationModal();
  };

  /**
   * Publish Changes
   */
  const publish = () => {
    setApprovalLoading(true);
    DiscoverCardsApi.methods
      .submitDiscoverCards()
      .then(
        () => {
          setAlertProps({
            title: t("pages.discoverCards.submitSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          hideConfirmationModal();
          getDiscoverCards();
          hidePublish();
        },
        err => {
          if (err.data && err.data.status && err.data.status.businessCode === DiscoverCardsErrorCodes.MANY_LIVE_CARDS) {
            setAlertProps({
              title: t("pages.discoverCards.submitLiveError", {
                max_live:
                  err?.data?.status?.message.split(":").length > 0 ? err?.data?.status?.message.split(":")[1] : "3",
              }),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          } else {
            setAlertProps({
              title: t("pages.discoverCards.submitError"),
              type: AlertTypeEnum.ERROR,
            });
            showAlert();
          }
        },
      )
      .finally(() => {
        /**
         * Reset all publishing loading states
         */
        setLastVersionLoading(false);
        setApprovalLoading(false);
      });
  };

  /**
   * Delete Discover Card
   * @param id
   */
  const deleteDiscoverCard = (id: string) => {
    DiscoverCardsApi.methods.deleteDiscoverCard(id).then(
      () => {
        setAlertProps({
          title: t("pages.discoverCards.deleteSuccess"),
          type: AlertTypeEnum.SUCCESS,
        });
        showAlert();
        hideConfirmationModal();
        getDiscoverCards();
      },
      () => {
        setAlertProps({
          title: t("pages.discoverCards.deleteError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * Restore Discover Card
   * @param id
   */
  const restoreDiscoverCard = (id: string) => {
    DiscoverCardsApi.methods.restoreDiscoverCard(id).then(
      () => {
        setAlertProps({
          title: t("pages.discoverCards.restoreSuccess"),
          type: AlertTypeEnum.SUCCESS,
        });
        showAlert();
        hideConfirmationModal();
        getDiscoverCards();
      },
      () => {
        setAlertProps({
          title: t("pages.discoverCards.restoreError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  /**
   * Change Discover Card Status
   * @param card
   */
  const changeStatus = (card: IDiscoverCard) => {
    const discoverCardData = { ...card };
    if (isHttp(discoverCardData.urlImage) && discoverCardData.imageName) {
      discoverCardData.urlImage = discoverCardData.imageName;
    }

    switch (card.status) {
      case DiscoverCardsStatusEnum.LIVE:
      case DiscoverCardsStatusEnum.ENABLE:
      case DiscoverCardsStatusEnum.EDIT:
        discoverCardData.status = DiscoverCardsStatusEnum.DISABLE;
        break;
      case DiscoverCardsStatusEnum.DISABLE:
      case DiscoverCardsStatusEnum.DISABLED:
        discoverCardData.status = DiscoverCardsStatusEnum.ENABLE;
        break;
      default:
        return;
    }

    DiscoverCardsApi.methods
      .EditDiscoverCard(discoverCardData, card.id)
      .then(
        _res => {
          setAlertProps({
            title: t("pages.discoverCards.statusSuccess"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
        },
        _err => {
          setAlertProps({
            title: t("pages.discoverCards.statusError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      )
      .then(() => getDiscoverCards());
  };

  /**
   * Sets showable discover cards
   * @param e
   */
  const setShowableDiscoverCards = (e: any) => {
    const newDiscover = {
      discoverCards: filterDiscoverCards(e, filters, selectedLanguage.key),
    };
    dispatch(DiscoverCardsActions.creators.getDiscoverCardsSuccess({ ...discoverCardsStore, ...newDiscover }));
  };

  /**
   * On list change handler
   * @param event
   */
  const onSortHandler = (event: any) => {
    const ids = discoverCardsStore.discoverCards.map(dc => {
      return dc.id;
    });
    DiscoverCardsApi.methods.sortDiscoverCards(ids).then(
      () => {},
      () => {
        setAlertProps({
          title: t("pages.discoverCards.sortError"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      },
    );
  };

  React.useEffect(() => {
    if (filters.size > 0) {
      const newDiscover = {
        discoverCards: filterDiscoverCards(
          discoverCardsStore.discoverCards ? discoverCardsStore.discoverCards : [],
          filters,
          selectedLanguage.key,
        ),
      };
      setFiltersResult(newDiscover);
    } else {
      setFiltersResult(undefined);
    }
  }, [filters, discoverCardsStore]);

  return (
    <PageContainer>
      <PageTitle
        breadcrumbs={getBreadcrumbs(t)}
        title={t("pages.discoverCards.discoverCards")}
        navigateFunction={history.push}
        goBackFn={() => history.push(RoutesEnum.CONFIGS)}
        separatorStyle={{ display: "none" }}
        titleContainerStyle={{ marginTop: "13px", marginBottom: "20px", height: "60px" }}
        rightComponentContainerStyle={{ display: "flex", alignContent: "center", height: "100%", marginRight: "0px" }}
        rightComponent={
          <>
            {discoverCardsStore ? (
              <ButtonContainer style={{ position: "relative", top: "10px" }}>
                <PrimaryButton
                  id={"publish-changes"}
                  disabled={
                    !discoverCardsStore.hasUnpublishedChanges ||
                    discoverCardsStore.submittedForApproval ||
                    lastVersionLoading
                  }
                  titleLabel={t("pages.discoverCards.submitChanges")}
                  type="submit"
                  redTheme={true}
                  style={{ width: "fit-content" }}
                  onClick={() => {
                    showPublish();
                  }}
                />
              </ButtonContainer>
            ) : null}
          </>
        }
      />

      {!discoverCardsStore.discoverCards ? <LoadingText>{t("commons.loadingResults")}</LoadingText> : null}
      {discoverCardsStore.discoverCards ? (
        <DiscoverCardsContainer>
          <VersionControlButtonsContainer>
            <RatioContainer ratio={2 / 12} style={{ marginRight: "24px" }}>
              <Column>
                <Label>{t("pages.bundleDetails.status")}</Label>
                <SubBundleStatusChip
                  style={{ marginTop: "14px", width: "max-content" }}
                  type={discoverCardsStore.status as any}
                >
                  {discoverCardsStore.submittedForApproval &&
                  (discoverCardsStore.status === DiscoverCardsStatusEnum.DISABLE ||
                    discoverCardsStore.status === DiscoverCardsStatusEnum.ENABLE ||
                    discoverCardsStore.status === DiscoverCardsStatusEnum.EDIT)
                    ? i18next.t(`commons.discoverCards.discoverCardsStatusEnum.PENDING`)
                    : i18next.t(`commons.discoverCards.discoverCardsStatusEnum.${discoverCardsStore.status}`)}
                </SubBundleStatusChip>
              </Column>
            </RatioContainer>
            {!discoverCardsStore.submittedForApproval &&
            discoverCardsStore.hasUnpublishedChanges &&
            discoverCardsStore.hasPreviousVersion ? (
              <VersionControlButtonsRow>
                <VersionControlButtonsContainer>
                  <div id={"check-last-version"}>
                    <FastActionButton
                      label={t("pages.discoverCards.checkLastVersion")}
                      onClick={showLastVersionModal}
                      iconComponent={
                        <FastIconWrapper color={styleTheme.palette.successGreen}>
                          <CheckIcon />
                        </FastIconWrapper>
                      }
                    ></FastActionButton>
                  </div>
                  <div id={"revert-to-last-version"}>
                    <FastActionButton
                      label={!revertLoading ? t("pages.languageTranslations.revertChanges") : t("commons.loading")}
                      onClick={revertToLastVersion}
                      iconComponent={
                        <FastIconWrapper color={styleTheme.palette.midGrey}>
                          {revertLoading && <LoadingIconWhite strokeColor={styleTheme.palette.midGrey} />}
                          {!revertLoading && <UndoIcon />}
                        </FastIconWrapper>
                      }
                    />
                  </div>
                </VersionControlButtonsContainer>
              </VersionControlButtonsRow>
            ) : null}
          </VersionControlButtonsContainer>
          <Row>
            <DiscoverCardsFilters
              filters={filters}
              isFilterActive={isFilterActive}
              updateFilter={updateFilter}
              clearFilter={clearFilter}
              resetFilters={resetFilters}
              getFilterValue={getFilterValue}
              getFiltersQueryString={getFiltersQueryString}
              updateMultipleFilters={updateMultipleFilters}
              hasSubmittedVersion={discoverCardsStore.submittedForApproval}
            ></DiscoverCardsFilters>
          </Row>

          <DiscoverCardsList
            discoverCards={filtersResult ? filtersResult.discoverCards : discoverCardsStore.discoverCards}
            setDiscoverCards={setShowableDiscoverCards}
            onLanguageChange={setSelectedLanguage}
            selectedLanguage={selectedLanguage}
            onSortHandler={onSortHandler}
            onDelete={deleteDiscoverCard}
            onRestore={restoreDiscoverCard}
            onStatusChange={changeStatus}
            hasSubmittedVersion={discoverCardsStore.submittedForApproval}
          ></DiscoverCardsList>
        </DiscoverCardsContainer>
      ) : null}
    </PageContainer>
  );
};

const ButtonContainer = styled("div")`
  width: fit-content;
`;

const DiscoverCardsContainer = styled("div")`
  margin-bottom: 34px;
  display: flex;
  flex-direction: column;
`;

const FastIconWrapper = styled("span")<{ color: string }>`
  width: 16px;
  height: 16px;
  stroke: ${props => props.color};
  svg {
    width: inherit;
    height: inherit;
  }
`;

const Label = styled("span")`
  font-family: Vodafone Rg;
  font-weight: bold;
  font-size: 16px;
  color: ${props => props.theme.palette.midGrey};
`;

export default DiscoverCardsPage;
