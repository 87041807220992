import { Table, useAlert, useFilters } from "@wit/mpesa-ui-components";
import { ColumnProps, SortDirectionEnum } from "@wit/mpesa-ui-components/lib/components/table/table.component";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IStoreInterface } from "../../../../configs/store.config";
import { IClientDocBulk } from "../doc-bulk.model";
import { DocBulkActions } from "../doc-bulk.store";
import { DocBulkUtils } from "../doc-bulk.utils";
import { PageContainer, PageTitle } from "../../../../shared/shared.styled";
import {
  IDocBulksRequest,
  IDocBulksResponse,
  BulkColumnKeysToSortParam,
  BulkSortDirectionToSortParam,
} from "../doc-bulk.interface";
import { LoadingText } from "../../../../shared/shared.styled";
import styled from "styled-components";
import TablePagination from "../../../../shared/components/table-pagination/table-pagination-component";
import DocBulkListFilters, { DocBulkListFilterKeys } from "../components/doc-bulk-filters.component";
import DocBulkApi from "../doc-bulk.api";
import { RoutesEnum } from "../../../../routes/routes.constants";
import { ClientDocBulkStatusEnum, ClientDocBulkStatusEnumKeys } from "../../ekyc.shared.enums";
import { useHistory } from "react-router-dom";
import EmptyTable from "@wit/mpesa-ui-components/lib/components/table/empty-states/empty-table.component";

const DocBulkListPage = () => {
  const statusSecondFilter = "createdOn ASC";
  const history = useHistory();

  // Hooks init
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [showAlert, hideAlert, setAlertProps] = useAlert();
  const {
    filters,
    updateFilter,
    isFilterActive,
    clearFilter,
    resetFilters,
    getFilterValue,
    getFiltersQueryString,
    updateMultipleFilters,
  } = useFilters();

  const previousFilters = {} as any;

  // Local state definitions
  const [isFetchingBulks, setIsFetchingBulks] = React.useState(false);
  const [firstLoadRequest, setFirstLoadRequest] = React.useState(true);
  const [pageSize, setPageSize] = React.useState(10);
  const [activePage, setActivePage] = React.useState((previousFilters && previousFilters.activePage) || 1);
  const [sortColumnFormKey, setSortColumnFormKey] = React.useState(
    (previousFilters && previousFilters.sortColKey) || "status",
  );
  const [sortColumnDirection, setSortColumnDirection] = React.useState(
    (previousFilters && previousFilters.sortDirection) || SortDirectionEnum.ASC,
  );
  const [requestParams, setRequestParams] = React.useState<IDocBulksRequest>({
    page: 1,
    pageSize: pageSize,
    sort: `status ASC, ${statusSecondFilter}`,
  });
  const [requestParamsTemp, setRequestParamsTemp] = React.useState<IDocBulksRequest>({
    page: 1,
    pageSize: pageSize,
    sort: `status ASC, ${statusSecondFilter}`,
  });

  // Redux state
  const { bulkEntries, totalBulks } = useSelector((store: IStoreInterface) => store.docBulksListReducer);

  /*
   * Queries the server for the filtered docs and updates the table,
   * every time the "requestParams" change.
   */
  React.useEffect(() => {
    if (firstLoadRequest) {
      setFirstLoadRequest(false);

      if (previousFilters) {
        updateMultipleFilters(previousFilters.uiFilters || []);
        setRequestParams(previousFilters.request);
        return;
      }
    }

    getDocBulks();
  }, [requestParams]);

  /*
   * Updates the temporary request parameters, to be used to query the server,
   * every time the filters are changed
   */
  React.useEffect(() => {
    let request: IDocBulksRequest = { page: activePage, pageSize };

    request = {
      ...request,
      ...getSearchFilterParams(),
      ...getStatusFilterParams(),
      ...getDateFilterParams(),
      ...getSortParam(sortColumnFormKey, sortColumnDirection),
    };
    setRequestParamsTemp(request);
  }, [filters]);

  /*
   * Handles the pagination change
   */
  const handlePageChange = (pageNumber: number) => {
    refreshResults(undefined, undefined, undefined, pageNumber);
  };

  /*
   * Handles sort changes
   */
  const handleSortChange = (sortColumn: ColumnProps, sortDirection: SortDirectionEnum) => {
    const sortCol = sortColumn.formKey || "";
    setSortColumnFormKey(sortCol);
    setSortColumnDirection(sortDirection);

    let request: IDocBulksRequest = { page: activePage, pageSize };
    request = {
      ...request,
      ...getSearchFilterParams(),
      ...getStatusFilterParams(),
      ...getDateFilterParams(),
      ...getSortParam(sortCol, sortDirection),
    };

    setRequestParamsTemp(request);
    refreshResults(request, sortCol, sortDirection);
  };

  /*
   * Evaluates the free text search filter parameters for the server request
   */
  const getSearchFilterParams = () => {
    if (!filters.get(DocBulkListFilterKeys.SEARCH)) {
      return null;
    }

    const searchValue = ((filters.get(DocBulkListFilterKeys.SEARCH) || []) as string[])[0];
    return { bulkName: searchValue };
  };

  /*
   * Evaluates the status filter parameters for the server request
   */
  const getStatusFilterParams = () => {
    if (!filters.get(DocBulkListFilterKeys.STATUS)) {
      return null;
    }

    const multipleStatusStr = filters.get(DocBulkListFilterKeys.STATUS) as ClientDocBulkStatusEnum[];

    const multipleStatus = multipleStatusStr.map(function(status: string) {
      return ClientDocBulkStatusEnum[status as ClientDocBulkStatusEnumKeys];
    });

    return { status: multipleStatus };
  };

  /*
   * Evaluates the date filter parameters for the server request
   */
  const getDateFilterParams = () => {
    if (!filters.get(DocBulkListFilterKeys.START_DATE) && !filters.get(DocBulkListFilterKeys.END_DATE)) {
      return null;
    }

    const startDateStr = filters.get(DocBulkListFilterKeys.START_DATE) as string[];
    let startTimestamp;
    if (startDateStr) {
      const startDate: Date = new Date(Number.parseInt(startDateStr[0]));
      startDate.setMilliseconds(0);
      startDate.setSeconds(0);
      startDate.setMinutes(0);
      startDate.setHours(0);
      startTimestamp = startDate.getTime();
    }

    const endDateStr = filters.get(DocBulkListFilterKeys.END_DATE) as string[];
    let endTimestamp;

    if (endDateStr) {
      const endDate: Date = new Date(Number.parseInt(endDateStr[0]));
      endDate.setMilliseconds(999);
      endDate.setSeconds(59);
      endDate.setMinutes(59);
      endDate.setHours(23);
      endTimestamp = endDate.getTime();
    }

    return {
      dateRangeIni: startTimestamp,
      dateRangeEnd: endTimestamp,
    };
  };

  /*
   * Evaluates the sort parameter for the server request
   */
  const getSortParam = (sortColumnFormKey?: string, sortDirection?: SortDirectionEnum) => {
    let sortParam = requestParamsTemp.sort;

    if (sortColumnFormKey && sortDirection) {
      sortParam = `${BulkColumnKeysToSortParam[sortColumnFormKey || ""]} ${
        BulkSortDirectionToSortParam[sortDirection]
      }`;

      if (sortColumnFormKey === "status") {
        sortParam += `, ${statusSecondFilter}`;
      }
    }

    return {
      sort: sortParam,
    };
  };

  /*
   * Get bulks list
   */
  const getDocBulks = () => {
    setIsFetchingBulks(true);

    DocBulkApi.methods
      .getBulks(requestParams)
      .finally(() => setIsFetchingBulks(false))
      .then(
        res => {
          const response: IDocBulksResponse = res.data;
          dispatch(DocBulkActions.creators.fetchBulksSuccessAction(response.bulkRegistrations, response.totalElements));
        },
        () => {
          setAlertProps({
            title: t("pages.docBulk.getBulksError"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
  };

  /*
   * Refresh results handler
   */
  const refreshResults = (
    customRequestParams?: IDocBulksRequest,
    customSortColumnFormKey?: string,
    customSortColumnDirection?: SortDirectionEnum,
    pageNumber?: number,
  ) => {
    let params = customRequestParams || requestParamsTemp;
    params = { ...params, page: pageNumber || 1 };
    history.push(RoutesEnum.DOC_BULK_LIST);

    setActivePage(pageNumber || 1);
    setRequestParams(params);
  };

  const getUiFiltersArray = () => {
    const filtersArray = [];
    const entries = filters.entries();
    let entry = entries.next();

    if (!entry.done) {
      do {
        filtersArray.push({
          key: entry.value[0],
          values: entry.value[1],
        });
        entry = entries.next();
      } while (!entry.done);
    }

    return filtersArray;
  };

  // Renders
  return (
    <PageContainer>
      <PageTitle style={{ marginBottom: 43 }}>{t("pages.docBulk.title")}</PageTitle>
      <DocBulkListContainer style={{ position: "relative" }}>
        <DocBulkListFilters
          filters={filters}
          isFilterActive={isFilterActive}
          updateFilter={updateFilter}
          clearFilter={clearFilter}
          resetFilters={resetFilters}
          getFilterValue={getFilterValue}
          getFiltersQueryString={getFiltersQueryString}
          updateMultipleFilters={updateMultipleFilters}
          entriesCount={totalBulks}
          refreshResults={refreshResults}
        />
        {isFetchingBulks ? (
          <LoadingText>{t("commons.loadingResults")}</LoadingText>
        ) : (
          <>
            <Table<IClientDocBulk>
              columns={DocBulkUtils.getTableColumns()}
              values={bulkEntries}
              emptyStateComponent={<EmptyTable text={t("commons.emptyTable")} />}
              rowClickAction={(idx, rowData) =>
                history.replace(RoutesEnum.DOC_BULK_DETAILS.replace(":bulkName", rowData.bulkName))
              }
              sortable={true}
              initialSortColumnFormKey={sortColumnFormKey}
              initialSortDirection={sortColumnDirection}
              sortClickAction={(sortColumn: ColumnProps, sortDirection: SortDirectionEnum) => {
                handleSortChange(sortColumn, sortDirection);
              }}
            />
            {totalBulks ? (
              <TablePagination
                handlePageChange={handlePageChange}
                totalItems={totalBulks}
                activePage={activePage}
                pageSize={pageSize}
              />
            ) : null}
          </>
        )}
      </DocBulkListContainer>
    </PageContainer>
  );
};

export default DocBulkListPage;

const DocBulkListContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;
