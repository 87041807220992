import { Magnifier, useAlert, useFilters } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import React, { useContext } from "react";
import Document from "../../configurations/rate-cards/components/document.component";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled, { ThemeContext } from "styled-components";
import { IStoreInterface } from "../../../configs/store.config";
import { ListHeader, ListHeaders, LoadingText } from "../../../shared/shared.styled";
import AllowedScopesApi from "./allowed-scopes.api";
import { RoutesEnum } from "../../../routes/routes.constants";
import { useHistory } from "react-router-dom";
import { IAllowedScope, IIconAllowedScope } from "./allowed-scopes.model";
import AllowedScopesListItem from "./components/allowed-scopes-list-item.component";
import AllowedScopesFilters from "./components/allowed-scopes-filter.component";
import { allowedScopesFilter, getAvailableLanguagesOptions } from "./allowed-scopes.utils";
import { AntServiceManagerActions } from "../ant-service-manager.store";
import LanguageSelectorDropdown from "../../../shared/components/language-selector-dropdown.component";

/**
 * Allowed Scopes component
 */
const AllowedScopePage = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showAlert, , setAlertProps] = useAlert();
  const [isLoading, setIsLoading] = React.useState(true);
  const [itemIcons, setItemIcons] = React.useState<IIconAllowedScope[]>([]);
  const { allowedScopes } = useSelector((state: IStoreInterface) => state.antServiceManagerReducer);
  const { icons } = useSelector((state: IStoreInterface) => state.serviceManagerReducer);
  const [selectedLanguage, setSelectedLanguage] = React.useState({
    label: t("commons.languages.en"),
    key: "en",
  });
  const [showableAllowedScopes, setShowableAllowedScopes] = React.useState<IAllowedScope[]>([]);
  const {
    filters,
    updateFilter,
    isFilterActive,
    clearFilter,
    resetFilters,
    getFilterValue,
    getFiltersQueryString,
    updateMultipleFilters,
  } = useFilters();
  const theme = useContext(ThemeContext);

  /**
   * method to get the allowed scopes list
   */
  const getAllowedScopes = (): void => {
    AllowedScopesApi.methods
      .getAllowedScopes()
      .then(
        res => {
          dispatch(AntServiceManagerActions.creators.fetchAllowedScopes(res.data));

          if (filters.size > 0) {
            setShowableAllowedScopes(allowedScopesFilter(res.data, filters, selectedLanguage["key"]));
          } else {
            setShowableAllowedScopes(res.data);
          }
        },
        () => {
          setAlertProps({
            title: t("pages.antServiceManager.allowedScopes.getAllowedScopesError"),
            type: AlertTypeEnum.ERROR,
          });

          showAlert();
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Get translations based on selected language
   * @param obj object with all translations
   * @returns translation that matches the selected language
   */
  const getObjectTranslation = (obj: { [x: string]: any }) => {
    return obj !== null ? (obj[selectedLanguage.key] ? obj[selectedLanguage.key] : "n.a.") : "n.a";
  };

  /**
   * method to get the icons list
   */
  const getIcons = (): void => {
    AllowedScopesApi.methods.getAllowedScopesIcons().then(res => {
      setItemIcons(res.data);
      dispatch(AntServiceManagerActions.creators.fetchAllowedScopesIcons(res.data));
    });
  };

  React.useEffect(() => {
    if (!itemIcons.length && !icons.length) {
      getIcons();
    }
    if (!itemIcons.length && icons.length) {
      setItemIcons(icons);
    }

    getAllowedScopes();
  }, []);

  React.useEffect(() => {
    if (filters.size > 0) {
      setShowableAllowedScopes(
        allowedScopesFilter(allowedScopes ? [...allowedScopes] : [], filters, selectedLanguage["key"]),
      );
    } else {
      setShowableAllowedScopes(allowedScopes);
    }
  }, [filters]);

  return (
    <>
      {!isLoading ? (
        <>
          <AllowedScopesFilters
            filters={filters}
            updateFilter={updateFilter}
            isFilterActive={isFilterActive}
            clearFilter={clearFilter}
            resetFilters={resetFilters}
            getFilterValue={getFilterValue}
            getFiltersQueryString={getFiltersQueryString}
            updateMultipleFilters={updateMultipleFilters}
          />
          <AllowedScopesListContainer>
            <ListHeaders>
              <ListHeader ratio={2 / 12}>{t("pages.antServiceManager.allowedScopes.title")}</ListHeader>
              <ListHeader ratio={4 / 12} style={{ marginRight: 0 }}>
                {allowedScopes ? (
                  <LanguageSelectorDropdown
                    availableLanguages={getAvailableLanguagesOptions()}
                    selectedLanguage={selectedLanguage}
                    changeSelectedLanguage={setSelectedLanguage}
                    color={theme.palette.turquoiseBlue}
                    id={"language-selector"}
                  />
                ) : null}
              </ListHeader>
              <ListHeader ratio={6 / 12}>{t("pages.antServiceManager.allowedScopes.scope")}</ListHeader>
            </ListHeaders>

            {allowedScopes && allowedScopes.length && showableAllowedScopes ? (
              <>
                {showableAllowedScopes.length > 0 ? (
                  showableAllowedScopes.map((allowedScope: IAllowedScope) => {
                    const itemIcon = itemIcons.find(icon => icon.id.toString() === allowedScope.iconId?.toString());
                    return (
                      <AllowedScopesListItem
                        onEdit={() => {
                          dispatch(AntServiceManagerActions.creators.setAllowedScope(allowedScope));
                          history.push(
                            RoutesEnum.ANT_SERVICE_MANAGER_SSO_DETAILS.replace(":allowedScopeId", allowedScope.id),
                          );
                        }}
                        onClickItemFn={() => {
                          dispatch(AntServiceManagerActions.creators.setAllowedScope(allowedScope));
                          history.push(
                            RoutesEnum.ANT_SERVICE_MANAGER_SSO_DETAILS.replace(":allowedScopeId", allowedScope.id),
                          );
                        }}
                        item={allowedScope}
                        getTranslation={getObjectTranslation}
                        itemIcon={itemIcon}
                        key={allowedScope.id}
                      />
                    );
                  })
                ) : (
                  <MagnifierContainer>
                    <Magnifier />
                    <NoResultsFoundText>
                      {t("pages.antServiceManager.allowedScopes.search.noSearchResults")}
                    </NoResultsFoundText>
                  </MagnifierContainer>
                )}
              </>
            ) : (
              <EmptyState>
                <Document />
                <SelectText>{t("pages.antServiceManager.allowedScopes.search.emptyState")}</SelectText>
              </EmptyState>
            )}
          </AllowedScopesListContainer>
        </>
      ) : (
        <LoadingText>{t("commons.loadingResults")}</LoadingText>
      )}
    </>
  );
};

export default AllowedScopePage;

const AllowedScopesListContainer = styled("div")`
  margin-bottom: 50px;
  margin-top: 31px;
`;

const MagnifierContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 50px;

  svg {
    width: 140px;
    height: 140px;
    margin-bottom: 24px;
    stroke: red;
  }
`;

const NoResultsFoundText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.midGrey};
`;

const EmptyState = styled("div")`
  margin-top: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const SelectText = styled("div")`
  font-family: "Vodafone Rg";
  width: 400px;
  height: 51px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  margin-top: 35px;
`;
