/* eslint-disable @typescript-eslint/no-explicit-any */
import { Error403Page, Error404Page } from "@wit/mpesa-ui-components";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { ConfigContext, IConfig } from "../app.component";
import { IStoreInterface } from "../configs/store.config";
import AdminPage from "../features/admin/admin.page";
import TaskDetailsPage from "../features/admin/tasks/components/task-details.component";
import AddUserPage from "../features/admin/users/pages/add-user.page";
import UserProfilePage from "../features/admin/users/pages/user-profile.page";
import { UserRolesVDF, UserRolesSFC, UserRolesDefault } from "../features/admin/users/users.interfaces";
import AppDetailsPage from "../features/apps/app-details/app-details.page";
import AppsPage from "../features/apps/pages/apps.page";
import { ILoggedUser } from "../features/authentication/authentication.interfaces";
import ForgotPasswordPage from "../features/authentication/pages/forgot-password.page";
import LoginPage from "../features/authentication/pages/login.page";
import RecoveryPage from "../features/authentication/pages/recover-password.page";
import RegisterPage from "../features/authentication/pages/register.page";
import BanksPage from "../features/configurations/banks/banks.page";
import AddBundlePage from "../features/configurations/bundles/add-bundle/add-bundle.page";
import BundleDetails from "../features/configurations/bundles/bundle-details/bundle-details.page";
import BundlesList from "../features/configurations/bundles/bundles-list.page";
import EditBundlePage from "../features/configurations/bundles/edit-bundle/edit-bundle.page";
import BusinessPage from "../features/configurations/business/business.page";
import ConfigurationsPage from "../features/configurations/configurations.page";
import AddDiscoverCardPage from "../features/configurations/discover-cards/add-discover-card/add-discover-card.page";
import DiscoverCardsPage from "../features/configurations/discover-cards/discover-cards.page";
import DocumentsPage from "../features/configurations/documents/documents.page";
import AddDocumentPage from "../features/configurations/documents/document/add-document.page";
import DynamicScreenPage from "../features/configurations/dynamic-screens/edit-dynamic-screen/dynamic-screen.page";
import LanguageTranslationsListOfVersionsPage from "../features/configurations/language-translations/language-translations-versions-list.page";
import LanguageTranslationsVersionPage from "../features/configurations/language-translations/language-translations.page";
import ChargeProfileDetails from "../features/configurations/rate-cards/charge-profile-details/charge-profile-details.page";
import EditChargeProfilePage from "../features/configurations/rate-cards/edit-charge-profile.page";
import RateCardsListPage from "../features/configurations/rate-cards/rate-cards-list.page";
import ServiceAddPage from "../features/service-add/pages/service-add.page";
import ServiceDetailsEditPage from "../features/service-details/pages/service-details-edit.page";
import ServiceDetailsPage from "../features/service-details/pages/service-details.page";
import ServiceEditorPage from "../features/service-editor/pages/service-editor.page";

import SupportPage from "../features/support/support.page";
import SurveysPage from "../features/tools/surveys/pages/surveys.page";
import ToolsPage from "../features/tools/tools.page";
import RequestMoneyPage from "../features/tools/request-money/request-money.page";
import ReportDetailsPage from "../features/tools/request-money/components/reported-users/report-details/report-details.page";
import RendererUtils, { AREAS, FEATURES } from "../shared/renderer.utils";
import AuthenticatedRoutes from "./authenticated-routes.component";
import { RoutesEnum, RoutesPrivileges } from "./routes.constants";
import UnAuthenticatedRoutes from "./unauthenticated-routes.component";
import ServiceBuilderPage from "../features/service-builder/pages/services.page";
import NetworksPage from "../features/configurations/networks/networks.page";
import name from "../../package.json";
import version from "../../package.json";
import ServiceManagerPage from "../features/safaricom-service-manager/pages/safaricom-service-manager.page";
import ExternalServicesAddAndEditPage from "../features/safaricom-service-manager/external-services/pages/external-services-add-edit.page";
import PushNotificationsListPage from "../features/tools/push-notifications/push-notifications-list.page";
import AddPushNotificationPage from "../features/tools/push-notifications/add-push-notification/add-push-notification";
import AnnouncementsPage from "../features/announcements/pages/announcements.page";
import AnnouncementsContentPage from "../features/announcements/pages/announcements-content.page";
import AnnouncementDetails from "../features/announcements/pages/announcement-details.page";
import DynamicScreensList from "../features/configurations/dynamic-screens/dynamic-screens-list.page";
import ReferralCampaigns from "../features/configurations/referral-campaigns/referral-campaigns.page";
import CreateReferralCampaign from "../features/configurations/referral-campaigns/create-referral/create-referral-campaign.page";
import EditReferralCampaign from "../features/configurations/referral-campaigns/edit-referral/edit-referral-campaign.page";
import ExpiredPasswordPage from "../features/authentication/pages/expired-password.page";
import AuditingPage from "../features/auditing/auditing.page";
import AddCategoryPage from "../features/safaricom-service-manager/pages/categories/add-category/add-category.page";
import EditCategoryPage from "../features/safaricom-service-manager/pages/categories/edit-category/edit-category.page";
import AddAllowedScopePage from "../features/safaricom-service-manager/allowed-scopes/add-allowed-scope/add-allowed-scope.page";
import EditAllowedScopePage from "../features/safaricom-service-manager/allowed-scopes/edit-allowed-scope/edit-allowed-scope.page";
import EditAntAllowedScopePage from "../features/ant-service-manager/allowed-scope/edit-allowed-scope/edit-allowed-scope.page";
import DocDashboardPage from "../features/ekyc/doc-dashboard/pages/doc-dashboard.page";
import DocBulkListPage from "../features/ekyc/doc-bulk/pages/doc-bulk-list.page";
import DocValidationPage from "../features/ekyc/doc-validation/pages/doc-validation-list.page";
import AntExternalServicesProfile from "../features/ant-service-manager/external-services/pages/ant-external-services-profile.page";
import AntExternalServiceAddPage from "../features/ant-service-manager/external-services/external-services-add/ant-external-service-add.page";
import AntServiceManagerPage from "../features/ant-service-manager/pages/ant-service-manager.page";
import AntServiceManagerDetailsPage from "../features/ant-service-manager/service-details/pages/ant-service-manager-service-details.page";
import AntServiceManagerAddPage from "../features/ant-service-manager/service-details/pages/ant-service-manager-add-service.page";
import { IdentityConverterPage } from "../features/tools/identity-converter/identity-converter.page";
import GeneralCategoriesPage from "../features/configurations/categories/general-categories.page";
import EditGeneralCategoryPage from "../features/configurations/categories/edit-category/general-edit-category.page";
import AddGeneralCategoryPage from "../features/configurations/categories/add-category/general-add-category.page";
import DocValidationDetailsPage from "../features/ekyc/doc-validation-details/pages/doc-validation-details.page";
import RequestPaymentPage from "../features/tools/request-payment/request-payment.page";
import DocumentPage from "../features/configurations/documents/document/document.page";
import CreateTopicPage from "../features/topics/pages/create-topic.page";
import ShortcutsPage from "../features/configurations/shortcuts/shortcuts.page";
import FeedbackAndProblemsReportPage from "../features/configurations/feedback-and-problems/feedback-and-problems.page";
import ServiceManagerServiceDetailsPage from "../features/safaricom-service-manager/service-details/pages/service-manager-service-details.page";
import TopicsListPage from "../features/topics/pages/topics-list.page";
import CreateNotificationPage from "../features/safaricom-push-notifications/pages/create-notification.page";
import SFCPushNotificationsListPage from "../features/safaricom-push-notifications/pages/notifications-list.page";
import EditNotificationPage from "../features/safaricom-push-notifications/pages/edit-notification.page";

interface IPrivateRouteInterface {
  children: React.ReactNode;
  config: IConfig;
  roles: Array<UserRolesDefault | UserRolesVDF | UserRolesSFC>;
  area: string;
  feature?: string;
  path?: string | string[];
  exact?: boolean;
  sensitive?: boolean;
  strict?: boolean;
}

/**
 * Private route
 * @param param0
 */
const PrivateRoute = ({ children, config, area, roles, feature = "", ...rest }: IPrivateRouteInterface) => {
  const loggedUser = useSelector((state: IStoreInterface) => state.authenticationReducer.loggedUser) as ILoggedUser;

  const { availableAreas } = config;
  const isAreaAvailable = availableAreas?.includes(area) ?? false;
  const isFeatureEnabled = RendererUtils.checkFeatureAvailable(config, feature);
  const hasRolePermissions = roles ? RendererUtils.checkRolePermission(roles, loggedUser) : true;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        loggedUser && isAreaAvailable && isFeatureEnabled && hasRolePermissions ? (
          <AuthenticatedRoutes>{children}</AuthenticatedRoutes>
        ) : (
          <Redirect
            to={{
              pathname: "/403",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

/**
 * Routes
 * @param config
 * @param loggedUser
 * @param history
 * @param t
 */
const routes = (config: IConfig, loggedUser: ILoggedUser | undefined, history: any, t: any) => {
  return (
    <Switch>
      {/* Public routes */}
      <Route exact path="/">
        <UnAuthenticatedRoutes config={config}>
          <LoginPage config={config} />
        </UnAuthenticatedRoutes>
      </Route>
      <Route exact path={RoutesEnum.FORGOT_PASSWORD}>
        <UnAuthenticatedRoutes config={config}>
          <ForgotPasswordPage />
        </UnAuthenticatedRoutes>
      </Route>
      <Route exact path={RoutesEnum.EXPIRED_PASSWORD}>
        <UnAuthenticatedRoutes config={config}>
          <ExpiredPasswordPage />
        </UnAuthenticatedRoutes>
      </Route>
      <Route exact path={RoutesEnum.REGISTER}>
        <UnAuthenticatedRoutes config={config}>
          <RegisterPage />
        </UnAuthenticatedRoutes>
      </Route>
      <Route exact path={RoutesEnum.RECOVERY}>
        <UnAuthenticatedRoutes config={config}>
          <RecoveryPage />
        </UnAuthenticatedRoutes>
      </Route>
      <Route exact path={RoutesEnum.VERSION}>
        <>
          {name} {version}
        </>
      </Route>
      {/* Private routes */}
      <PrivateRoute config={config} area={AREAS.APPS} roles={RoutesPrivileges.apps} path={RoutesEnum.APPS} exact>
        <AppsPage />
      </PrivateRoute>
      <PrivateRoute config={config} area={AREAS.APPS} roles={RoutesPrivileges.apps} path={RoutesEnum.APP_DETAILS} exact>
        <AppDetailsPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SERVICE_BUILDER}
        roles={RoutesPrivileges.sbList}
        path={RoutesEnum.SERVICE_BUILDER}
        exact
      >
        <ServiceBuilderPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SERVICE_BUILDER}
        roles={RoutesPrivileges.sbCrud}
        path={RoutesEnum.SERVICE_DETAILS}
        exact
      >
        <ServiceDetailsPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SERVICE_BUILDER}
        roles={RoutesPrivileges.sbCrud}
        path={RoutesEnum.SERVICE_DETAILS_EDIT}
        exact
      >
        <ServiceDetailsEditPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SERVICE_BUILDER}
        roles={RoutesPrivileges.sbCrud}
        path={RoutesEnum.SERVICE_ADD}
        exact
      >
        <ServiceAddPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SERVICE_BUILDER}
        roles={RoutesPrivileges.sbCrud}
        path={RoutesEnum.SERVICE_EDITOR}
        exact
      >
        <ServiceEditorPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.ANT_SERVICE_MANAGER}
        roles={RoutesPrivileges.sbCrud}
        path={RoutesEnum.ANT_SERVICE_MANAGER}
        exact
      >
        <AntServiceManagerPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.ANT_SERVICE_MANAGER}
        roles={RoutesPrivileges.sbCrud}
        path={RoutesEnum.ANT_SERVICE_MANAGER_DETAILS}
        exact
      >
        <AntServiceManagerDetailsPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.ANT_SERVICE_MANAGER}
        roles={RoutesPrivileges.sbCrud}
        path={RoutesEnum.ANT_SERVICE_MANAGER_ADD_SERVICE}
        exact
      >
        <AntServiceManagerAddPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.ANT_SERVICE_MANAGER}
        roles={RoutesPrivileges.sbCrud}
        path={RoutesEnum.ANT_SERVICE_MANAGER_CATEGORY_DETAILS}
        exact
      >
        <EditGeneralCategoryPage isAntCategories={true} />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.ANT_SERVICE_MANAGER}
        roles={RoutesPrivileges.sbCrud}
        path={RoutesEnum.ANT_SERVICE_MANAGER_SSO_DETAILS}
        exact
      >
        <EditAntAllowedScopePage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.CATEGORIES}
        exact
      >
        <GeneralCategoriesPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.CATEGORY_DETAILS}
        exact
      >
        <EditGeneralCategoryPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.ADD_CATEGORY}
        exact
      >
        <AddGeneralCategoryPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.ANT_SERVICE_MANAGER}
        roles={RoutesPrivileges.sbCrud}
        path={RoutesEnum.ADD_NEW_ANT_EXTERNAL_SERVICE}
        exact
      >
        <AntExternalServiceAddPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.ANT_SERVICE_MANAGER}
        roles={RoutesPrivileges.sbCrud}
        path={RoutesEnum.ANT_EXTERNAL_SERVICES_DETAILS}
        exact
      >
        <AntExternalServicesProfile />
      </PrivateRoute>
      <PrivateRoute config={config} area={AREAS.ADMIN} roles={RoutesPrivileges.admin} path={RoutesEnum.ADD_USER} exact>
        <AddUserPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.ADMIN}
        roles={RoutesPrivileges.admin}
        path={RoutesEnum.USER_PROFILE}
        exact
      >
        <UserProfilePage />
      </PrivateRoute>
      <PrivateRoute config={config} area={AREAS.ADMIN} roles={RoutesPrivileges.admin} path={RoutesEnum.ADMIN} exact>
        <AdminPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.ADMIN}
        roles={RoutesPrivileges.tasks}
        path={RoutesEnum.TASK_DETAILS}
        exact
      >
        <TaskDetailsPage />
      </PrivateRoute>
      <PrivateRoute config={config} area={AREAS.SUPPORT} roles={RoutesPrivileges.all} path={RoutesEnum.SUPPORT} exact>
        <SupportPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.CONFIGS}
        exact
      >
        <ConfigurationsPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.NETWORKS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.NETWORKS}
        exact
      >
        <NetworksPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.NETWORKS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.WALLETS}
        exact
      >
        <NetworksPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.BUNDLES}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.BUNDLES}
        exact
      >
        <BundlesList />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.BUNDLES}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.BUNDLE_DETAILS}
        exact
      >
        <BundleDetails />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.BUNDLES}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.ADD_BUNDLE}
        exact
      >
        <AddBundlePage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.BUNDLES}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.EDIT_BUNDLE}
        exact
      >
        <EditBundlePage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.LANGUAGE_TRANSLATIONS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.LANGUAGE_TRANSLATIONS}
        exact
      >
        <LanguageTranslationsListOfVersionsPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.LANGUAGE_TRANSLATIONS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.LANGUAGE_TRANSLATIONS_VERSION}
        exact
      >
        <LanguageTranslationsVersionPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.FEEDBACK_AND_PROBLEMS_REPORT}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.FEEDBACK_AND_PROBLEMS_REPORT}
        exact
      >
        <FeedbackAndProblemsReportPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.DOCUMENTS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.DOCUMENTS}
        exact
      >
        <DocumentsPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.DOCUMENTS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.ADD_DOCUMENT}
        exact
      >
        <AddDocumentPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.DOCUMENTS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.EDIT_DOCUMENT}
        exact
      >
        <AddDocumentPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.DOCUMENTS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.DOCUMENT}
        exact
      >
        <DocumentPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.DYNAMIC_SCREENS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.DYNAMIC_SCREENS}
        exact
      >
        <DynamicScreensList />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.DYNAMIC_SCREENS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.DYNAMIC_SCREEN}
        exact
      >
        <DynamicScreenPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.DYNAMIC_SCREENS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.DYNAMIC_SCREEN_CREATE}
        exact
      >
        <DynamicScreenPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.DISCOVER_CARDS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.DISCOVER_CARDS}
        exact
      >
        <DiscoverCardsPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.DISCOVER_CARDS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.ADD_DISCOVER_CARD}
        exact
      >
        <AddDiscoverCardPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.DISCOVER_CARDS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.EDIT_DISCOVER_CARD}
        exact
      >
        <AddDiscoverCardPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.RATE_CARDS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.RATE_CARDS}
        exact
      >
        <RateCardsListPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.REFERRAL_CAMPAIGNS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.REFERRAL_CAMPAIGNS}
        exact
      >
        <ReferralCampaigns />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.REFERRAL_CAMPAIGNS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.CREATE_REFERRAL_CAMPAIGN}
        exact
      >
        <CreateReferralCampaign />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.REFERRAL_CAMPAIGNS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.EDIT_REFERRAL_CAMPAIGN}
        exact
      >
        <EditReferralCampaign />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.TOOLS}
        feature={FEATURES.PUSH_NOTIFICATIONS}
        roles={RoutesPrivileges.tools}
        path={RoutesEnum.PUSH_NOTIFICATIONS}
        exact
      >
        <PushNotificationsListPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.TOOLS}
        feature={FEATURES.PUSH_NOTIFICATIONS}
        roles={RoutesPrivileges.tools}
        path={RoutesEnum.ADD_PUSH_NOTIFICATION}
        exact
      >
        <AddPushNotificationPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.TOOLS}
        feature={FEATURES.PUSH_NOTIFICATIONS}
        roles={RoutesPrivileges.tools}
        path={RoutesEnum.EDIT_PUSH_NOTIFICATION}
        exact
      >
        <AddPushNotificationPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.RATE_CARDS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.CHARGE_PROFILE}
        exact
      >
        <ChargeProfileDetails />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.RATE_CARDS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.CHARGE_PROFILE_CREATE}
        exact
      >
        <EditChargeProfilePage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.RATE_CARDS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.CHARGE_PROFILE_EDIT}
        exact
      >
        <EditChargeProfilePage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.BUSINESS}
        roles={RoutesPrivileges.business}
        path={RoutesEnum.BUSINESS}
        exact
      >
        <BusinessPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.BANKS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.BANKS}
        exact
      >
        <BanksPage />
      </PrivateRoute>
      <PrivateRoute config={config} area={AREAS.TOOLS} roles={RoutesPrivileges.tools} path={RoutesEnum.TOOLS} exact>
        <ToolsPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.TOOLS}
        feature={FEATURES.SURVEYS}
        roles={RoutesPrivileges.tools}
        path={RoutesEnum.SURVEYS}
        exact
      >
        <SurveysPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.TOOLS}
        feature={FEATURES.IDENTITY_CONVERTER}
        roles={RoutesPrivileges.tools}
        path={RoutesEnum.IDENTITY_CONVERTER}
        exact
      >
        <IdentityConverterPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.TOOLS}
        feature={FEATURES.REQUEST_MONEY}
        roles={RoutesPrivileges.tools}
        path={RoutesEnum.REQUEST_MONEY}
        exact
      >
        <RequestMoneyPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.TOOLS}
        feature={FEATURES.REQUEST_PAYMENT}
        roles={RoutesPrivileges.tools}
        path={RoutesEnum.REQUEST_PAYMENT}
        exact
      >
        <RequestPaymentPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.AUDITING}
        roles={RoutesPrivileges.admin}
        path={RoutesEnum.AUDITING}
        exact
      >
        <AuditingPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SERVICE_MANAGER}
        roles={RoutesPrivileges.serviceManager}
        path={RoutesEnum.SERVICE_MANAGER}
        exact
      >
        <ServiceManagerPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SERVICE_MANAGER}
        roles={RoutesPrivileges.serviceManager}
        path={RoutesEnum.SERVICE_MANAGER_SERVICE_LIST_DETAILS}
        exact
      >
        <ServiceManagerServiceDetailsPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SERVICE_MANAGER}
        roles={RoutesPrivileges.serviceManager}
        path={RoutesEnum.ADD_NEW_EXTERNAL_SERVICE}
        exact
      >
        <ExternalServicesAddAndEditPage isAddMode />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SERVICE_MANAGER}
        roles={RoutesPrivileges.serviceManager}
        path={RoutesEnum.EXTERNAL_SERVICES_DETAILS}
        exact
      >
        <ExternalServicesAddAndEditPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.TOOLS}
        feature={FEATURES.REQUEST_MONEY}
        roles={RoutesPrivileges.tools}
        path={RoutesEnum.REQUEST_MONEY_REPORT}
        exact
      >
        <ReportDetailsPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SERVICE_MANAGER}
        roles={RoutesPrivileges.serviceManager}
        path={RoutesEnum.SERVICE_MANAGER_ADD_CATEGORY}
        exact
      >
        <AddCategoryPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SERVICE_MANAGER}
        roles={RoutesPrivileges.serviceManager}
        path={RoutesEnum.SERVICE_MANAGER_CATEGORY_DETAILS}
        exact
      >
        <EditCategoryPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SERVICE_MANAGER}
        roles={RoutesPrivileges.serviceManager}
        path={RoutesEnum.SERVICE_MANAGER_ADD_SSO}
        exact
      >
        <AddAllowedScopePage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SERVICE_MANAGER}
        roles={RoutesPrivileges.serviceManager}
        path={RoutesEnum.SERVICE_MANAGER_SSO_DETAILS}
        exact
      >
        <EditAllowedScopePage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.TOOLS}
        roles={RoutesPrivileges.tools}
        path={RoutesEnum.REQUEST_MONEY_REPORT}
        exact
      >
        <ReportDetailsPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.ANNOUNCEMENTS}
        roles={RoutesPrivileges.announcements}
        path={RoutesEnum.ANNOUNCEMENTS}
        exact
      >
        <AnnouncementsPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.ANNOUNCEMENTS}
        roles={RoutesPrivileges.announcements}
        path={RoutesEnum.ADD_ANNOUNCEMENT}
        exact
      >
        <AnnouncementsContentPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.ANNOUNCEMENTS}
        roles={RoutesPrivileges.announcements}
        path={RoutesEnum.ANNOUNCEMENTS_DETAILS}
        exact
      >
        <AnnouncementDetails />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.ON_DEMAND_NOTIFICATIONS}
        roles={RoutesPrivileges.sfcNotifications}
        path={RoutesEnum.SFC_PUSH_NOTIFICATIONS}
        exact
      >
        <SFCPushNotificationsListPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.ON_DEMAND_NOTIFICATIONS}
        roles={RoutesPrivileges.sfcNotifications}
        path={RoutesEnum.SFC_ADD_NOTIFICATION}
        exact
      >
        <CreateNotificationPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.ON_DEMAND_NOTIFICATIONS}
        roles={RoutesPrivileges.sfcNotifications}
        path={RoutesEnum.SFC_EDIT_NOTIFICATION}
        exact
      >
        <EditNotificationPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.TOPICS}
        roles={RoutesPrivileges.announcements}
        path={RoutesEnum.TOPICS}
        exact
      >
        <TopicsListPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.TOPICS}
        roles={RoutesPrivileges.announcements}
        path={RoutesEnum.ADD_TOPICS}
        exact
      >
        <CreateTopicPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.DOC_DASHBOARD}
        roles={RoutesPrivileges.ekyc}
        path={RoutesEnum.DOC_DASHBOARD}
        exact
      >
        <DocDashboardPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.DOC_VALIDATION}
        roles={RoutesPrivileges.ekyc}
        path={RoutesEnum.DOC_VALIDATION}
        exact
      >
        <DocValidationPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.DOC_VALIDATION}
        roles={RoutesPrivileges.ekyc}
        path={RoutesEnum.DOC_VALIDATION_DETAILS}
        exact
      >
        <DocValidationDetailsPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.DOC_BULKS}
        roles={RoutesPrivileges.admin}
        path={RoutesEnum.DOC_BULK_LIST}
        exact
      >
        <DocBulkListPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SERVICE_MANAGER}
        roles={RoutesPrivileges.admin}
        path={RoutesEnum.SERVICE_MANAGER_ADD_SERVICE}
        exact
      >
        <ServiceManagerServiceDetailsPage />
      </PrivateRoute>
      <PrivateRoute
        config={config}
        area={AREAS.SETTINGS}
        feature={FEATURES.SHORTCUTS}
        roles={RoutesPrivileges.configs}
        path={RoutesEnum.SHORTCUTS}
        exact
      >
        <ShortcutsPage />
      </PrivateRoute>
      {/* 403 */}
      <Route path={"/403"} exact>
        <Error403Page
          title={t("pages.403.title")}
          description={t("pages.403.message")}
          buttonLabel={t("pages.403.goBackToHomePage")}
          onButtonClick={() => (loggedUser ? history.push(getDefaultRoute(loggedUser, config)) : history.push("/"))}
        />
      </Route>
      {/* 404 */}
      <Route path={"/404"}>
        <Error404Page
          title={t("pages.404.title")}
          description={t("pages.404.description")}
          buttonLabel={t("pages.403.goBackToHomePage")}
          onButtonClick={() => (loggedUser ? history.push(getDefaultRoute(loggedUser, config)) : history.push("/"))}
        />
      </Route>
      <Redirect to="/404" />
    </Switch>
  );
};

/**
 * Get default route
 * @param loggedUser
 * @param config
 */
export const getDefaultRoute = (loggedUser: ILoggedUser, config: IConfig) => {
  if (loggedUser && loggedUser.role === UserRolesDefault.SUPPORT) {
    return RoutesEnum.SUPPORT;
  } else if (loggedUser && loggedUser.role === UserRolesSFC.SERVICES_MANAGER_MANAGER) {
    return RoutesEnum.SERVICE_MANAGER;
  } else if (loggedUser && loggedUser.role === UserRolesSFC.ANNOUNCEMENTS_MANAGER) {
    return RoutesEnum.ANNOUNCEMENTS;
  } else if (loggedUser && loggedUser.role === UserRolesSFC.REQUEST_MONEY_MANAGER) {
    return RoutesEnum.TOOLS;
  } else {
    return config.defaultRoute || "/";
  }
};

interface IRoutesComponentProps {
  config: IConfig;
}

/**
 * Routes component
 */
const RoutesComponent = () => {
  const [t] = useTranslation();
  const loggedUser = useSelector((state: IStoreInterface) => state.authenticationReducer.loggedUser) as ILoggedUser;
  const history = useHistory();
  const configContext = useContext(ConfigContext);

  const currentPath =
    process.env.REACT_APP_ROUTER_BASE !== undefined && process.env.REACT_APP_ROUTER_BASE !== "/"
      ? window.location.pathname.replace(process.env.REACT_APP_ROUTER_BASE as string, "")
      : window.location.pathname;
  if (
    loggedUser &&
    localStorage.getItem("rememberMe") &&
    localStorage.getItem("rememberMe") === "true" &&
    currentPath === "/"
  ) {
    if (
      (loggedUser.is2FAEnabled &&
        localStorage.getItem("twoFactorAuthenticated") &&
        localStorage.getItem("twoFactorAuthenticated") === "true") ||
      !loggedUser.is2FAEnabled
    ) {
      history.push(getDefaultRoute(loggedUser, configContext.config as IConfig));
    }
  }
  return routes(configContext.config as IConfig, loggedUser, history, t);
};

export default RoutesComponent;
