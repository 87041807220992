import styled, { keyframes, css } from "styled-components";

export const PageContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 50px 48px 0 118px;
  height: 100%;
  overflow: auto;
  > div {
    flex-shrink: 0;
  }
  @media (max-width: 1024px) {
    padding: 50px 24px 0 94px !important;
  }
`;

export const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const OrbRow = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const PageTitle = styled("h1")`
  font-family: Vodafone Lt;
  font-size: 42px;
  color: #333;
  margin: 0;
  font-weight: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: clip;
`;

export const PopperRow = styled("div")`
  height: 30px;
  padding: 5px 12px 6px 12px;
  background-color: ${props => props.theme.palette.white};

  :hover {
    background-color: ${props => props.theme.palette.lightGrey};
  }
`;

export const PopperRowText = styled("span")`
  font-family: Vodafone Rg;
  font-size: 14px;
  color: #666;
`;

export const Separator = styled("div")`
  width: 100%;
  height: 1px;
  border: solid 1px ${props => props.theme.palette.aluminium};
`;

export const RatioContainer = styled("div")<{ ratio: number }>`
  flex: ${props => props.ratio};
`;

export const ListHeaders = styled(Row)`
  padding: 0 35px 0 25px;
  margin-bottom: 15px;
`;

export const FlexContainer = styled("div")<{ ratio: number }>`
  flex: ${props => props.ratio * 100}%;
`;

export const WidthContainer = styled("div")<{ ratio: number }>`
  width: ${props => props.ratio * 100}%;
`;

export const ListHeader = styled(WidthContainer)`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const Column = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const fadeInKeyframe = keyframes`
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
`;

export const fadeOutKeyframe = keyframes`
   100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  0% {
    opacity: 0;
  }
`;

export const IconContainer = styled("div")<{ color: string; size: number }>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  svg {
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    stroke: ${props => props.color};
  }
`;

export const LoadingText = styled("span")`
  font-family: Vodafone Rg;
  color: #333333;
  font-size: 16px;
  padding: 20px 0px 20px 0px;
`;

export const DropDownWrapper = styled("div")`
  width: 100%;
  &div {
    width: 100%;
  }
`;

export const DragDropText = styled("span")`
  font-family: "Vodafone Rg";
  font-size: 16px;
  color: ${props => props.theme.palette.greyDarker};
  text-align: center;
  margin-top: 12px;
`;

export const DragDropTextInfo = styled("span")`
  font-weight: bold;
  cursor: pointer;
  color: ${props => props.theme.palette.turquoiseBlue};
  text-decoration: underline;
`;

export const DragDropFileName = styled("span")`
  font-family: "Vodafone Rg";
  font-size: 16px;
  color: ${props => props.theme.palette.greyDarker};
`;

export const IconWrapper = styled("span")<{ color: string }>`
  width: 24px;
  height: 24px;
  stroke: ${props => props.color};
  cursor: pointer;
  svg {
    width: inherit;
    height: inherit;
  }
`;

export const ImageDetailsHelper = styled("div")<{ width?: string; marginBottom?: string }>`
  font-size: 13px;
  font-family: "Vodafone Rg";
  color: ${props => props.color};
  width: ${props => props.width || "200px"};
  margin-bottom: ${props => props.marginBottom || "0px"};
  line-height: 24px;
  margin-top: 16px;
`;

export const ButtonContainer = styled("div")`
  width: fit-content;
`;
export const CalendarHeader = styled("span")`
  font-family: "Vodafone Rg";
  color: #333333;
  font-size: 16px;
`;

export const EnforceTextLinesBrake = css`
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

export const FormLabel = styled.span<{ required?: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.greyDarker};

  ${props =>
    props.required
      ? `
::after {
    content: ' *';
    font-family: Vodafone Rg;
    font-size: 16px;
    font-weight: normal;
    color: ${props.theme.palette.errorRed};
}
`
      : ""}
`;

export const FormSectionTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.black};
`;
