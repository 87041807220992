import { PrimaryButton, TextInput, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikHelpers } from "formik";
import i18next from "i18next";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { object, string } from "yup";
import { ConfigContext } from "../../../../app.component";
import { IApp, IReleasedVersion } from "../../../../shared/models/app.model";
import { Row } from "../../../../shared/shared.styled";
import { isSFCMarket } from "../../../../shared/shared.utils";
import AppsApi from "../../apps.api";
import { validateNewReleaseValues } from "../../apps.utils";

interface IAddNewAppReleaseVersionComponentInterface {
  refetchApp: () => void;
  app: IApp;
}

/**
 *
 *Add new App release version component
 */
const AddNewAppReleaseVersionComponent = ({ refetchApp, app }: IAddNewAppReleaseVersionComponentInterface) => {
  const [t] = useTranslation();
  const [showAlert, , setAlertProps] = useAlert();
  const { config } = useContext(ConfigContext);

  /**
   * validation schema
   * @returns
   */
  const getValidationSchema = () => {
    return object().shape({
      name:
        config && isSFCMarket()
          ? string().max(50, i18next.t("pages.appDetails.releaseVersions.maxLenName"))
          : string()
              .required(i18next.t("pages.appDetails.releaseVersions.requiredField"))
              .max(50, i18next.t("pages.appDetails.releaseVersions.maxLenName")),
      version: string()
        .required(i18next.t("pages.appDetails.releaseVersions.requiredField"))
        .max(25, i18next.t("pages.appDetails.releaseVersions.maxLenVersion")),
    });
  };

  /**
   * Adds a new app release
   * @param values
   * @param actions
   */
  const addNewReleaseVersion = (values: any, actions: FormikHelpers<any>) => {
    values.version = values.version.trim();
    values.name =
      config && isSFCMarket()
        ? `${app.name} ${t(`commons.apps.enums.platform.${app.system}`)} ${values.version}`
        : values.name.trim();

    if (validateNewReleaseValues(values)) {
      if (!versionAlreadyExists(values)) {
        AppsApi.methods
          .createReleaseVersion(values, app.id)
          .finally(() => actions.setSubmitting(false))
          .then(
            () => {
              setAlertProps({
                title: t("pages.appDetails.releaseVersions.saveSuccess"),
                type: AlertTypeEnum.SUCCESS,
              });
              actions.resetForm();
              showAlert();
              refetchApp();
            },
            err => {
              const errorMsg = err && err.data && err.data.status ? err.data.status.message : null;
              const modalMsg = errorMsg ? errorMsg : t("pages.appDetails.releaseVersions.saveError");
              setAlertProps({
                title: modalMsg,
                type: AlertTypeEnum.ERROR,
              });
              showAlert();
            },
          );
      } else {
        actions.setSubmitting(false);
        setAlertProps({
          title: t("pages.appDetails.releaseVersions.releaseAlreadyExistsTitle"),
          content: t("pages.appDetails.releaseVersions.releaseAlreadyExistsMessage"),
          type: AlertTypeEnum.ERROR,
        });
        showAlert();
      }
    } else {
      actions.setSubmitting(false);
      setAlertProps({
        title: t("pages.appDetails.releaseVersions.saveError"),
        content: t("pages.appDetails.releaseVersions.saveErrorRelease"),
        type: AlertTypeEnum.ERROR,
      });
      showAlert();
    }
  };

  /*
   * Verify if the version already exists
   * */
  const versionAlreadyExists = (values: { name: string; version: string }) => {
    if (app.releases && app.releases.releasedVersions) {
      const index = app.releases.releasedVersions.findIndex((rv: IReleasedVersion) => {
        return rv.name === values.name || rv.version === values.version;
      });
      return index === -1 ? false : true;
    }
    return false;
  };

  return (
    <FormContainer>
      <FormContainerTitle>{t("pages.appDetails.releaseVersions.newReleaseVersion")}</FormContainerTitle>
      <Formik
        onSubmit={addNewReleaseVersion}
        initialValues={{ name: "", version: "" }}
        validationSchema={getValidationSchema()}
        render={({ handleChange, values, handleSubmit, errors, isSubmitting, resetForm }) => (
          <form onSubmit={handleSubmit}>
            <AddNewAppRow>
              <div style={{ flex: 4 / 12, flexGrow: 1, flexShrink: 0 }}>
                <FieldSection>
                  <TextInput
                    title={t("pages.appDetails.releaseVersions.versionName")}
                    placeholder={t("pages.appDetails.releaseVersions.versionNamePlaceholder")}
                    required={config && !isSFCMarket()}
                    name="name"
                    maxLength={50}
                    onChange={handleChange}
                    value={
                      config && isSFCMarket()
                        ? `${app.name} ${t(`commons.apps.enums.platform.${app.system}`)} ${values.version}`
                        : values.name
                    }
                    error={errors.name}
                    autoComplete="off"
                    disabled={config && isSFCMarket()}
                  />
                </FieldSection>
              </div>
              <div style={{ flex: 4 / 12, flexGrow: 1, flexShrink: 0 }}>
                <FieldSection>
                  <TextInput
                    title={t("pages.appDetails.releaseVersions.versionNumber")}
                    placeholder={t("pages.appDetails.releaseVersions.versionNumberPlaceholder")}
                    required
                    name="version"
                    maxLength={25}
                    onChange={handleChange}
                    value={values.version}
                    error={errors.version}
                    autoComplete="off"
                  />
                </FieldSection>
              </div>
              <div style={{ flex: 4 / 12, display: "flex", justifyContent: "flex-end", flexGrow: 1, flexShrink: 0 }}>
                <ButtonContainer
                  style={{
                    marginRight: "12px",
                  }}
                >
                  <PrimaryButton
                    disabled={isSubmitting}
                    titleLabel={t("pages.appDetails.releaseVersions.cancel")}
                    type="button"
                    onClick={() => resetForm()}
                    style={{ width: "fit-content" }}
                    id={"cancel-button"}
                  />
                </ButtonContainer>
                <ButtonContainer>
                  <PrimaryButton
                    disabled={isSubmitting}
                    titleLabel={t("pages.appDetails.releaseVersions.addNew")}
                    onClick={() => {}}
                    type="submit"
                    redTheme={true}
                    style={{ width: "fit-content" }}
                    id={"add-new-button"}
                  />
                </ButtonContainer>
              </div>
            </AddNewAppRow>
          </form>
        )}
      />
    </FormContainer>
  );
};

export default AddNewAppReleaseVersionComponent;

const AddNewAppRow = styled(Row)`
  flex-wrap: wrap;
  gap: 24px;
  align-items: flex-end;
  @media (max-width: 768px) {
    > div {
      flex: 50% !important;
    }
  }
`;

const FormContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 31px 47px;
  margin: 0px -47px 0px -47px;
  @media (max-width: 1024px) {
    margin-right: -24px;
    padding-right: 24px;
  }
  background-color: ${props => props.theme.palette.greyLight};
`;

const FormContainerTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.4px;
  color: ${props => props.theme.palette.midGrey};
  text-transform: uppercase;
  margin-bottom: 21px;
`;

const FieldSection = styled("div")``;

const ButtonContainer = styled("div")`
  width: fit-content;
`;
