import { DownloadCsvIcon, PlusIcon, PrimaryButton, TextInput, TrashIcon, useAlert } from "@wit/mpesa-ui-components";
import { AlertTypeEnum } from "@wit/mpesa-ui-components/lib/context/alert/alert.context";
import { Formik, FormikHelpers } from "formik";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled, { ThemeContext } from "styled-components";
import { IconContainer, Row } from "../../../../../shared/shared.styled";
import { formatBytes } from "../../../../../shared/shared.utils";
import SurveysApi, { ISurvey } from "../../surveys.api";
import { SurveysActions } from "../../surveys.store";
import { SurveysUtils } from "../../surveys.utils";
import { getValidationSchema } from "../create-survey.component";

interface IEditSurveyModalProps {
  survey: ISurvey;
  hideModal: (survey?: ISurvey) => void;
}

/**
 * Edit survey modal
 */
const EditSurveyModal = ({ survey, hideModal }: IEditSurveyModalProps) => {
  const [t] = useTranslation();
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const [showAlert, , setAlertProps] = useAlert();

  const [csvFile, setFile] = React.useState<any>();
  const [fileError, setFileError] = React.useState("");
  const [willPublish, setWillPublish] = React.useState(false);

  const MAX_FILE_SIZE = 1000000;

  /*Selected survey*/
  const [selectedSurvey, setSelectedSurvey] = React.useState<ISurvey>({
    ...survey,
  });

  /**
   * function to handle the form submission
   * @param values
   * @param actions
   * @returns
   */
  const editSurvey = (values: any, actions: FormikHelpers<any>) => {
    if (!isFileValid()) {
      actions.setSubmitting(false);
      return;
    }

    SurveysApi.methods
      .editSurvey({ ...values, id: selectedSurvey.id }, csvFile)
      .finally(() => actions.setSubmitting(false))
      .then(
        () => {
          setAlertProps({
            title: t("pages.tools.surveys.modal.edit.success"),
            type: AlertTypeEnum.SUCCESS,
          });
          showAlert();
          SurveysApi.methods.getSurveys().then(res => {
            dispatch(SurveysActions.creators.fetchSurveysSuccessAction(res.data));
            if (willPublish) {
              const auxSurvey = res.data[res.data.findIndex(s => s.id === selectedSurvey.id)];
              hideModal(auxSurvey);
            } else {
              hideModal();
            }
          });
        },
        () => {
          setAlertProps({
            title: t("pages.tools.surveys.modal.edit.error"),
            type: AlertTypeEnum.ERROR,
          });
          showAlert();
        },
      );
  };

  /**
   * Max file size error message
   */
  const setMaxSizeFileError = () => {
    setFileError(t("pages.tools.surveys.modal.edit.maxSize"));
  };

  /**
   * File required error message
   */
  const setFileRequiredError = () => {
    setFileError(t("pages.tools.surveys.modal.edit.requiredFile"));
  };

  /**
   * Verifies if the file is valid
   */
  const isFileValid = () => {
    if (selectedSurvey.fileName && selectedSurvey.fileSize) {
      setFileError("");
      return true;
    } else if (!csvFile) {
      setFileRequiredError();
      return false;
    } else if (csvFile.size > MAX_FILE_SIZE) {
      setMaxSizeFileError();
      return false;
    }
    setFileError("");
    return true;
  };

  /**
   *  Removes the file
   */
  const removeFile = () => {
    setFileError(t("pages.tools.surveys.modal.edit.requiredFile"));
    setFile(null);
  };

  /**
   * Removes the survey file
   */
  const removeSurveyFile = () => {
    setFileError(t("pages.tools.surveys.modal.edit.requiredFile"));
    const copy = selectedSurvey;
    copy.fileName = "";
    copy.fileSize = "";
    setSelectedSurvey(copy);
  };

  /**
   * Publish action
   */
  const publish = () => {
    if (isFileValid()) {
      setWillPublish(true);
    }
  };

  return (
    <ModalContainer>
      <ModalTitle>{t("pages.tools.surveys.modal.edit.title")}</ModalTitle>
      <Formik
        onSubmit={editSurvey}
        initialValues={{ name: selectedSurvey.name, sms: selectedSurvey.sms }}
        validationSchema={getValidationSchema()}
        render={({ handleChange, values, handleSubmit, errors, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Row style={{ alignItems: "flex-end" }}>
              <FieldSection>
                <TextInput
                  title={t("pages.tools.surveys.modal.edit.surveyName.label")}
                  placeholder={t("pages.tools.surveys.modal.edit.surveyName.placeholder")}
                  required
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  error={errors.name}
                  autoComplete="off"
                />
              </FieldSection>
            </Row>
            <Row style={{ marginTop: "26px", alignItems: "flex-end" }}>
              <FieldSection>
                <TextInput
                  title={t("pages.tools.surveys.modal.edit.surveySms.label")}
                  placeholder={t("pages.tools.surveys.modal.edit.surveySms.placeholder")}
                  required
                  name="sms"
                  onChange={handleChange}
                  value={values.sms}
                  error={errors.sms}
                  autoComplete="off"
                />
              </FieldSection>
            </Row>
            <Row style={{ marginTop: "26px", alignItems: "flex-end" }}>
              <FieldSection>
                <FileInputContainer>
                  <FileInputTitle>
                    {t("pages.tools.surveys.modal.edit.csvFileUpload")}
                    <span>*</span>

                    {fileError ? <FormError>{fileError}</FormError> : null}
                  </FileInputTitle>
                  {!csvFile && !selectedSurvey.fileName ? (
                    <TextButton style={{ marginRight: "auto" }}>
                      <IconContainer style={{ marginRight: "5px" }} size={16} color={themeContext.palette.vodafoneRed}>
                        <PlusIcon />
                      </IconContainer>
                      {t("pages.tools.surveys.modal.edit.uploadNewCsvFile")}
                      <input
                        type="file"
                        onChange={event => {
                          SurveysUtils.handleFileChange(
                            event,
                            setMaxSizeFileError,
                            setFileError,
                            setFile,
                            MAX_FILE_SIZE,
                          );
                        }}
                        accept=".csv"
                      />
                    </TextButton>
                  ) : null}
                  {csvFile ? (
                    <FileNameContainer>
                      <IconContainer size={16} color={themeContext.palette.vodafoneRed}>
                        <DownloadCsvIcon />
                      </IconContainer>
                      <FileNameText>{csvFile.name}</FileNameText>
                      <FileSizeText>{formatBytes(csvFile.size)}</FileSizeText>
                      <IconContainer size={16} color={themeContext.palette.errorRed}>
                        <TrashIcon onClick={removeFile} style={{ marginLeft: "5px", cursor: "pointer" }} />
                      </IconContainer>
                    </FileNameContainer>
                  ) : null}

                  {selectedSurvey.fileName ? (
                    <FileNameContainer>
                      <IconContainer size={16} color={themeContext.palette.vodafoneRed}>
                        <DownloadCsvIcon />
                      </IconContainer>
                      <FileNameText>{selectedSurvey.fileName}</FileNameText>
                      <FileSizeText>{selectedSurvey.fileSize}</FileSizeText>
                      <IconContainer size={16} color={themeContext.palette.errorRed}>
                        <TrashIcon onClick={removeSurveyFile} style={{ marginLeft: "5px", cursor: "pointer" }} />
                      </IconContainer>
                    </FileNameContainer>
                  ) : null}
                </FileInputContainer>
              </FieldSection>
            </Row>
            <Row style={{ justifyContent: "flex-end", marginTop: "50px" }}>
              <div style={{ marginRight: "12px" }}>
                <PrimaryButton
                  onClick={() => hideModal()}
                  disabled={isSubmitting}
                  titleLabel={t("pages.tools.surveys.modal.edit.cancel")}
                />
              </div>
              <div style={{ marginRight: "12px" }}>
                <PrimaryButton
                  redTheme={false}
                  type="submit"
                  disabled={isSubmitting}
                  titleLabel={t("pages.tools.surveys.modal.edit.save")}
                />
              </div>
              <div>
                <PrimaryButton
                  disabled={isSubmitting}
                  titleLabel={t("pages.tools.surveys.modal.edit.saveAndPublish")}
                  onClick={publish}
                  type="submit"
                  redTheme={true}
                  style={{ width: "fit-content" }}
                />
              </div>
            </Row>
          </form>
        )}
      />
    </ModalContainer>
  );
};

export default EditSurveyModal;

const ModalContainer = styled("div")`
  border-radius: 6px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(142, 142, 142, 0.23);
  background-color: ${props => props.theme.palette.white};
  padding: 24px;
  width: 600px;
`;

const ModalTitle = styled("div")`
  font-family: Vodafone Rg;
  font-size: 22px;
  color: ${props => props.theme.palette.darkGrey};
  margin-bottom: 24px;
`;

const FieldSection = styled("div")`
  width: 100%;
`;

const TextButton = styled("label")`
  display: flex;
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.darkGrey};
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
  > input {
    display: none;
  }
`;

const FileInputContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;

const FileInputTitle = styled("span")`
  font-family: Vodafone Rg;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.palette.midGrey};
  margin-bottom: 15px;
  > span {
    font-weight: normal;
    margin-left: 1px;
    color: ${props => props.theme.palette.errorRed};
  }
`;

const FileNameContainer = styled("div")`
  display: flex;
`;

const FileSizeText = styled("span")`
  color: ${props => props.theme.palette.grey};
  font-family: Vodafone Rg;
  font-size: 16px;
  margin-left: 3px;
`;

const FileNameText = styled("span")`
  color: ${props => props.theme.palette.grey};
  font-family: Vodafone Rg;
  font-size: 16px;
  color: ${props => props.theme.palette.greyDarkest};
  margin-left: 8px;
`;

const FormError = styled("span")`
  height: 19px;
  color: ${props => props.theme.palette.errorRed};
  font-family: Vodafone Rg;
  font-size: 14px;
  text-align: right;
  float: right;
  font-weight: 400;
`;
