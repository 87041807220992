import { isSFCMarket } from "./../../../shared/shared.utils";
import Axios, { AxiosPromise } from "axios";
import ApiClient, { AxiosConstants } from "../../../configs/axios.config";
import { ICategory } from "../../../shared/models/category.model";
import { IBusiness, IBusinessesResponse } from "../../../shared/models/business.model";

export const MocksClient = Axios.create({
  baseURL: "https://demo3487186.mockable.io/",
  timeout: AxiosConstants.TIMEOUT,
  headers: {
    "Security-Cookie": `${localStorage.getItem("securityCookie")}`,
    "XSRF-TOKEN": `${localStorage.getItem("xsrfToken")}`,
  },
});

export const BusinessesAPI = {
  routes: {
    BUSINESS: "businesses",
    CATEGORIES: "categories",
  },
  methods: {
    createCategories: (category: ICategory): AxiosPromise<void> => {
      return ApiClient.post(`/v1/categories`, category);
    },
    editCategories: (values: ICategory): AxiosPromise<void> => {
      return ApiClient.put(`/v1/categories/${values.id}`, values);
    },
    deleteCategories: (category: ICategory): AxiosPromise<void> => {
      return ApiClient.delete(`/v1/categories/${category.id}`);
    },
    restoreCategories: (category: ICategory): AxiosPromise<void> => {
      return ApiClient.put(`/v1/categories/${category.id}/restore`);
    },
    getBusinesses: (_search?: string): AxiosPromise<IBusinessesResponse> => {
      return ApiClient.get(`v1/businesses_manager?previousVersion=false`);
    },
    getLastVersion: (search?: string): AxiosPromise<IBusinessesResponse> => {
      return ApiClient.get(`v1/businesses_manager?previousVersion=true`);
    },
    createBusiness: (business: IBusiness): AxiosPromise<void> => {
      return ApiClient.post("/v1/businesses", business);
    },
    editBusiness: (id: number, values: IBusiness): AxiosPromise<void> => {
      return ApiClient.put(`/v1/businesses/${id}`, values);
    },
    deleteBusiness: (business: IBusiness): AxiosPromise<void> => {
      return ApiClient.delete(`/v1/businesses/${business.id}`);
    },
    restoreBusiness: (business: IBusiness): AxiosPromise<void> => {
      return ApiClient.put(`/v1/businesses/${business.id}/restore`);
    },
    revertLastVersion: (): AxiosPromise<void> => {
      return ApiClient.put(`/v1/businesses_manager/revert`);
    },
    publishVersion: (): AxiosPromise<void> => {
      return ApiClient.put(`/v1/businesses_manager/publish`);
    },
  },
};
