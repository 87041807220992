export interface IShortcuts {
  status: ShortcutsStatusEnum;
  hasUnpublishedChanges: boolean;
  hasPreviousVersion: boolean;
  actionItemResponses: IShortcut[];
  submittedForApproval?: boolean;
}

export interface IShortcut {
  id: string;
  name: {
    [key: string]: string;
  };
  status: ShortcutsStatusEnum;
  deepLinkType: ShortcutActionTypeEnum;
  iconId: string;
  submittedForApproval: boolean;
  data: {
    type: ShortcutActionTypeEnum;
    flow?: string; //NATIVE
    eventId?: string; //DYNAMIC_SCREEN
    uri?: string; //DEEPLINK & EXTERNAL_LINK
  };
  type: ShortcutsDeepLinkTypeEnum;
}

export enum ShortcutsStatusEnum {
  DISABLE = "DISABLE",
  DISABLED = "DISABLED",
  EDIT = "EDIT",
  ENABLE = "ENABLE",
  LIVE = "LIVE",
  DELETE = "DELETE",
}

export enum ShortcutsDeepLinkTypeEnum {
  NATIVE = "NATIVE",
  DYNAMIC_SCREEN = "DYNAMIC_SCREEN",
  DEEP_LINK = "DEEP_LINK",
  EXTERNAL_LINK = "EXTERNAL_LINK",
}

export enum ShortcutActionTypeEnum {
  NATIVE = "NATIVE",
  DYNAMIC_SCREEN = "DYNAMIC_SCREEN",
  DEEP_LINK = "DEEP_LINK",
  EXTERNAL_LINK = "EXTERNAL_LINK",
  NONE = "NONE",
  BUNDLE = "BUNDLE",
  MINI_APP = "MINI_APP",
}

export enum ShortcutFlowEnum {
  SERVICES = "SERVICES",
  QR_CODE = "QR_CODE",
  PAY_PERSON = "PAY_PERSON",
  WITHDRAW_CASH = "WITHDRAW_CASH",
  WITHDRAW_AGENT = "WITHDRAW_AGENT",
  MPESA_POINTS = "MPESA_POINTS",
  PAY_MERCHANT = "PAY_MERCHANT",
  PAY_BUSINESS = "PAY_BUSINESS",
  PAY_LUKU = "PAY_LUKU",
  INTERNATIONAL_TRANSFERS = "INTERNATIONAL_TRANSFERS",
  BANK_TRANSFERS = "BANK_TRANSFERS",
  PAY_GOVERNMENT = "PAY_GOVERNMENT",
  AIRTIME_TOP_UP = "AIRTIME_TOP_UP",
  AIRTIME_TOP_UP_SELF = "AIRTIME_TOP_UP_SELF",
  AIRTIME_BUY_BUNDLE = "AIRTIME_BUY_BUNDLE",
  REVERSE_TRANSACTION = "REVERSE_TRANSACTION",
  SETTINGS = "SETTINGS",
  REFER_A_FRIEND = "REFER_A_FRIEND",
  CUSTOMIZE_MENUS = "CUSTOMIZE_MENUS",
  MPESA_FEES = "MPESA_FEES",
  CONTACT_VODACOM = "CONTACT_VODACOM",
  // Un-used not supported on the apps (remove translations in the future and move this to a shared enum)
  TOP_UP = "TOP_UP",
  WITHDRAW_ATM = "WITHDRAW_ATM",
  PAY_INTO_BANK = "PAY_INTO_BANK",
  BUNDLE = "BUNDLE",
  LUKU = "LUKU",
  DSTV = "DSTV",
  GOVERNMENT_BILL = "GOVERNMENT_BILL",
}

export enum ShortcutsErrorCodes {
  MANY_LIVE_CARDS = 40032,
}
